import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { createHttpParams } from '@app/shared/helpers';
import { PagedResponse } from '@app/shared/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PbxOnRdaQuery, PbxOnRdaRequest, PbxOnRdaResponse } from '../models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({
    providedIn: 'root'
})
export class PbxOnRdaApiService extends ApiService {
    private readonly headers = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
        super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1']);
    }

    getPage(
        page: number = 0,
        size: number = 10,
        query?: PbxOnRdaQuery,
    ): Observable<PagedResponse<PbxOnRdaResponse>> {
        let params: HttpParams = createHttpParams({
            connectedRdaUid: query?.connectedRdaUid,
            rdaUid: query?.rdaUid
        });
        params = params.append('page', page.toString());
        params = params.append('size', size.toString());

        return this.get<PagedResponse<PbxOnRdaResponse>>('/pbx_on_rdas', 1, { params });
    }

    createPbxOnRda(request: PbxOnRdaRequest): Observable<PbxOnRdaResponse> {
        return this.post<PbxOnRdaRequest, PbxOnRdaResponse>(`/pbx_on_rdas`, 1, request, { headers: this.headers });
    }

    updatePbxOnRda(pbxOnRdaUid: string, request: PbxOnRdaRequest): Observable<void> {
        return this.patch<PbxOnRdaRequest, void>(`/pbx_on_rdas/${pbxOnRdaUid}`, 1, request, { headers: this.headers });
    }

    deletePbxOnRda(pbxOnRdaUid: string): Observable<void> {
        return this.delete<void>(`/pbx_on_rdas/${pbxOnRdaUid}`, 1);
    }

    connectToRda(pbxOnRdaUid: string, rdaUidForConnect: string): Observable<void> {
        return this.put<void>(`/pbx_on_rdas/${pbxOnRdaUid}/connected_rdas/${rdaUidForConnect}`, 1);
    }

    disconnectFromRda(pbxOnRdaUid: string, rdaUidForDisconnect: string): Observable<void> {
        return this.delete<void>(`/pbx_on_rdas/${pbxOnRdaUid}/connected_rdas/${rdaUidForDisconnect}`, 1);
    }

    changeRda(oldPbxOnRdaUid: string, newPbxOnRda: string, rdaUid: string): Observable<void> {
        return this.disconnectFromRda(oldPbxOnRdaUid, rdaUid)
            .pipe(
                switchMap(() => this.connectToRda(newPbxOnRda, rdaUid))
            );
    }
}
