import { TZ_OFFSET_IN_MILLISECONDS } from '../../video-player.constants';

export class PlyrTimeHelper {
    static absoluteTime: number;
    static relativeTime: number;
    static leftBoundTimestampWithOffset: number;
    static rightBoundTimestampWithOffset: number;
    static minTimestampWithOffset: number;
    static maxTimestampWithOffset: number;
    static sourceDate: number;

    static prepareProgressBarBounds(minTimestamp: number, maxTimestamp: number) {
        PlyrTimeHelper.maxTimestampWithOffset = maxTimestamp - TZ_OFFSET_IN_MILLISECONDS;
        PlyrTimeHelper.minTimestampWithOffset = minTimestamp - TZ_OFFSET_IN_MILLISECONDS;

        PlyrTimeHelper.leftBoundTimestampWithOffset = minTimestamp;
        PlyrTimeHelper.rightBoundTimestampWithOffset = maxTimestamp;

        const boundDate = new Date(this.sourceDate);
        boundDate.setHours(0);
        boundDate.setMinutes(0);
        boundDate.setSeconds(0);
        boundDate.setMilliseconds(0);

        if (PlyrTimeHelper.minTimestampWithOffset < boundDate.getTime()) {
            PlyrTimeHelper.leftBoundTimestampWithOffset = PlyrTimeHelper.minTimestampWithOffset;
        } else {
            PlyrTimeHelper.leftBoundTimestampWithOffset = boundDate.getTime();
        }

        boundDate.setHours(23);
        boundDate.setMinutes(59);
        boundDate.setSeconds(59);

        if (PlyrTimeHelper.maxTimestampWithOffset > boundDate.getTime()) {
            PlyrTimeHelper.rightBoundTimestampWithOffset = PlyrTimeHelper.maxTimestampWithOffset;
        } else {
            PlyrTimeHelper.rightBoundTimestampWithOffset = boundDate.getTime();
        }
    }

    static clear() {
        this.absoluteTime = null;
        this.relativeTime = 0;
        this.leftBoundTimestampWithOffset = null;
        this.rightBoundTimestampWithOffset = null;
        this.minTimestampWithOffset = null;
        this.maxTimestampWithOffset = null;
    }
}
