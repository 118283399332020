import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { TopBarService } from '@app/core/services';
import { ResolutionBreakpoint, ResolutionService, UrlService } from '@app/shared/services';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopBarComponent implements OnInit, OnDestroy {
  title: string;
  showTopBar: boolean;
  backAction: boolean;
  mdWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.MD_W_DOWN);

  @Output() private menuClick: EventEmitter<void> = new EventEmitter();
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    public location: Location,
    private resolution: ResolutionService,
    private topBarService: TopBarService,
    private urlService: UrlService,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.topBarService.backBtnEnabled$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(state => {
        this.backAction = state;
        this.changeDetectorRef.markForCheck();
      });

    this.topBarService.title$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(title => {
        this.title = title;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onClick() {
    this.menuClick.emit();
  }

  public get hasLink(): boolean {
    return this.urlService && this.urlService.links[this.title] && this.urlService.links[this.title]?.length > 0;
  }

  public titleTranslated(title: string): string {
    const key = 'core.route.' + title;
    const translated = this.translate.instant(key);
    return translated === key ? title : translated;
  }

  openLink() {
    window.open(this.urlService.links[this.title], '_blank');
  }
}
