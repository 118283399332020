export enum CompanyPageType {
  Profile = 'profile',
  Integration = 'integration',
  UserAndRoles = 'userAndRoles'
}

export interface IRoleResponse {
  id: number;
  name: string;
  translate?: string;
  description?: string;
}

export interface IUserResponse {
  id: number;
  name: string;
  email: string;
  password: string;
  companyId: number;
  roleNames: string[];
  blocked: boolean;
}

export interface IUserRequest {
  name: string;
  email: string;
  password: string;
  companyId: number;
  roleNames: string[];
}
