import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenuItem } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ChipModule } from 'primeng/chip';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  IConnection,
  IDelegationsOverLimitReport,
  ServiceApiService,
  ServiceInfoResponse
} from '@app/shared/entities/rd';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { catchError, switchMap, takeUntil } from 'rxjs/operators';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TableModule } from 'primeng/table';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentLoaderModule } from '@app/shared/ui';
import { ButtonModule } from 'primeng/button';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from '@app/shared/components';
import { AddressFormatter } from '@app/shared/services';

export enum DelegationAction {
  Camera = 'Camera',
  CallAndKey = 'CallAndKey',
  Chats = 'Chats',
  Payments = 'Payments',
  Delegation = 'Delegation'
}

export enum DelegationConfig {
  Unlimited,
  Limited,
  Service
}

@Component({
  selector: 'app-delegation',
  templateUrl: './delegation.component.html',
  styleUrls: ['./delegation.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TabMenuModule,
    ChipModule,
    NgClass,
    TranslateModule,
    CheckboxModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    OverlayPanelModule,
    TableModule,
    ComponentLoaderModule,
    AsyncPipe,
    ButtonModule
  ]
})
export class DelegationComponent implements OnInit, OnDestroy {
  public items: MenuItem[];
  public activeItem: MenuItem;
  public personControl: FormControl = new FormControl<number>(0, []);
  public unlimitedControl = true;
  public limitedControl = false;
  public delegationsOverLimitReport: IDelegationsOverLimitReport[] = [];
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  public progress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public readonly DelegationConfig = DelegationConfig;

  protected serviceId: number = null;
  protected unSubscribe$: Subject<void> = new Subject<void>();

  constructor(
    protected translate: TranslateService,
    protected service: ServiceApiService,
    protected activatedRoute: ActivatedRoute,
    protected snackbarService: SnackbarService,
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected addressFormatter: AddressFormatter
  ) {}

  get label(): string {
    if (this.isUnrestricted) {
      return this.translate.instant(
        'service-navbar.tab.action.addition.unrestricted_text'
      );
    }

    if (this.isLimited) {
      return `${this.translate.instant(
        'service-navbar.tab.action.addition.contractor_text'
      )} ${this.personControl.value}`;
    }

    if (this.isForbidden) {
      return `${this.translate.instant(
        'service-navbar.tab.action.addition.cant_delegation'
      )}`;
    }

    throw new Error('Error label type');
  }

  get isUnrestricted(): boolean {
    return this.unlimitedControl;
  }

  get isLimited(): boolean {
    return this.limitedControl && this.personControl.value > 0;
  }

  get isForbidden(): boolean {
    return (
      this.limitedControl &&
      (this.personControl.value === 0 ||
        this.personControl.value === undefined ||
        this.personControl.value === null)
    );
  }

  ngOnInit() {
    this.activatedRoute.params
      .pipe(
        switchMap((res: { id: number }) => {
          this.serviceId = res.id;
          return this.service.getInfo(res.id);
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((service: ServiceInfoResponse) => {
        if (service.delegationTunings.limit !== null) {
          this.unlimitedControl = false;
          this.limitedControl = true;
          this.personControl.setValue(service.delegationTunings.limit);
        }
        this.getDelegationInfo(this.serviceId, service.delegationTunings.limit);
        this.initControlListener();
        this.initActionMenu();
      });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public onModeChange(config: DelegationConfig, connection?: IConnection): void {
    if (config === DelegationConfig.Limited) {
      this.limitedControl = true;
      this.unlimitedControl = false;
      this.getDelegationInfo(this.serviceId, this.personControl.value);
    }
    if (config === DelegationConfig.Unlimited) {
      this.limitedControl = false;
      this.unlimitedControl = true;
      this.personControl.setValue(0);
    }
  }

  public onAbonentView(id: number): void {
    this.router.navigate([`/abonents/subscriber/${id}`]);
  }

  public onSetUpConfiguration(): void {
    this.progress$.next(true);
    this.service
      .delegationTunings(
        this.serviceId,
        this.limitedControl ? this.personControl.value : null
      )
      .pipe(catchError((error: HttpErrorResponse) => of(error)))
      .subscribe((response) => {
        this.progress$.next(false);
        if (response instanceof HttpErrorResponse) {
          this.snackbarService.showMessage(
            this.translate.instant(
              this.translate.instant(
                'service-navbar.tab.action.addition.limit_update_error'
              ),
              {
                text: response.message
              }
            ),
            'error'
          );
          return;
        }

        this.snackbarService.showMessage(
          this.translate.instant(
            'service-navbar.tab.action.addition.limit_update_success'
          ),
          'success'
        );
      });
  }

  protected getDelegationInfo(serviceId: number, limit: number = null): void {
    this.service
      .delegationsOverLimitReport(serviceId, limit)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((response: IDelegationsOverLimitReport[]) => {
        this.delegationsOverLimitReport = response;
        this.loading$.next(false);
      });
  }

  protected initControlListener(): void {
    this.personControl.valueChanges
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((value: number) => {
        if (value < 0) {
          this.personControl.setValue(0);
        }
        this.getDelegationInfo(this.serviceId, value);
      });
  }

  protected initActionMenu(): void {
    this.items = [
      // { label: this.translate.instant('service-navbar.tab.action.camera'), disabled: true, id: DelegationAction.Camera },
      // { label: this.translate.instant('service-navbar.tab.action.callAndKey'), disabled: true, id: DelegationAction.CallAndKey },
      // { label: this.translate.instant('service-navbar.tab.action.chats'), disabled: true, id: DelegationAction.Chats },
      // { label: this.translate.instant('service-navbar.tab.action.payments'), disabled: true, id: DelegationAction.Payments },
      {
        label: this.translate.instant('service-navbar.tab.action.delegation'),
        id: DelegationAction.Delegation
      }
    ];
    this.activeItem = this.items.find(
      (item) => item.id === DelegationAction.Delegation
    );
  }
}
