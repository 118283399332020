import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper/dialog-wrapper-data.model';

@Component({
  selector: 'app-service-intercom-update-popup',
  templateUrl: './service-intercom-update-popup.component.html',
  styleUrls: ['./service-intercom-update-popup.component.scss']
})
export class ServiceIntercomUpdatePopupComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<{ intercomUid: string }, null>,
  ) { }
}
