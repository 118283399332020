import { Injectable } from '@angular/core';
import { LocalStorageGeneralKey, LocalStorageHelper } from '@app/shared/entities/common';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LoggerService {
    private _enabled: boolean;

    constructor() {
        this._enabled = LocalStorageHelper.getItem(LocalStorageGeneralKey.LOGGER_ENABLED) === 'true';

        if (this.enabled === null || this.enabled === undefined) {
            this._enabled = !environment.production;
        }
    }

    get enabled(): boolean {
        return this._enabled;
    }

    log(message: string, ...args: any[]) {
        if (this.enabled) {
            console.log(message, ...args);
        }
    }

    error(message: string, ...args: any[]) {
        if (this.enabled) {
            console.error(message, ...args);
        }
    }

    warn(message: string, ...args: any[]) {
        if (this.enabled) {
            console.warn(message, ...args);
        }
    }

    assert(message: string, ...args: any[]) {
        if (this.enabled) {
            console.assert(message, ...args);
        }
    }
}
