import { ServiceIntercomComponent } from './service-intercom/service-intercom.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { IntercomUpdateToolModule } from '@app/shared/components';
import { SharedPipesModule } from '@app/shared/pipes';
import { ButtonLinkModule, NavbarModule } from '@app/shared/ui';
import { ButtonWithSpinnerLoaderModule, DialogWrapperModule, InfoBlockModule } from '@app/shared/ui';
import { ServiceDeleteIntercomPopupModule, ServiceIntercomKeyPopupEditModule, ServiceRdaFormModule } from '../../popups';
import { ServiceIntercomUpdateButtonComponent } from './service-intercom-update-button';
import { ServiceIntercomUpdatePopupComponent } from './service-intercom-update-popup';
import { ServiceIntercomsComponent } from './service-intercoms.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        ServiceIntercomKeyPopupEditModule,
        DialogWrapperModule,
        MaterialModule,
        IntercomUpdateToolModule,
        ButtonWithSpinnerLoaderModule,
        ServiceDeleteIntercomPopupModule,
        InfoBlockModule,
        NavbarModule,
        SharedPipesModule,
        ServiceRdaFormModule,
        ButtonLinkModule,
        NgxTranslateModule
    ],
    declarations: [
        ServiceIntercomUpdateButtonComponent,
        ServiceIntercomUpdatePopupComponent,
        ServiceIntercomsComponent,
        ServiceIntercomComponent
    ],
    exports: [
        ServiceIntercomsComponent,
        ServiceIntercomUpdateButtonComponent,
        ServiceIntercomUpdatePopupComponent,
    ]
})
export class ServiceIntercomsModule { }
