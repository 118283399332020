export interface CompanyRegisterResponse {
    id: number;
    supportPhone: string;
    paymentLink: string;
    shortName: string;
    name: string;
    supportChatEnabled: boolean;
    ogrn: string;
    inn: string;
}
