import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

export const getBatchAdaptersState = createSelector(
    intercomModuleSelector,
    (state: IntercomModuleState) => state.getBatchAdapters
);

export const getBatchAdapters = createSelector(
    getBatchAdaptersState,
    state => state
);

export const getBatchAdaptersSuccess = createSelector(
    getBatchAdaptersState,
    state => state.adapters
);

export const getBatchAdaptersLoading = createSelector(
    getBatchAdaptersState,
    state => state.loading
);

export const getBatchAdaptersError = createSelector(
    getBatchAdaptersState,
    state => state.error
);
