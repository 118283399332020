import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { IntercomTypeHandsetLockParams } from '@app/shared/entities/rd/intercom-type';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep, isEqual } from 'lodash';

@Component({
  selector: 'app-ip-intercom-handset-lock-params-container',
  templateUrl: './ip-intercom-handset-lock-params-container.component.html',
  styleUrls: ['./ip-intercom-handset-lock-params-container.component.scss'],
})
export class IpIntercomHandsetLockParamsContainerComponent implements OnInit, OnDestroy {
  @Input() set formData(formData: IntercomTypeHandsetLockParams) {
    this.initFormValue(formData);
  }
  @Output() submitParams = new EventEmitter<IntercomTypeHandsetLockParams>();

  public hidePassword: boolean;
  public form: UntypedFormGroup;
  public isActualState: boolean;
  private firstFormState: IntercomTypeHandsetLockParams;
  private unSubscribe$: Subject<boolean>;

  constructor() {
    this.form = new UntypedFormGroup({
      host: new UntypedFormControl(null, Validators.required),
      port: new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(65535),
      ]),
      login: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required),
    });
    this.unSubscribe$ = new Subject<boolean>();
    this.isActualState = true;
    this.hidePassword = true;
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((value: IntercomTypeHandsetLockParams) => {
      this.isActualState = isEqual(value, this.firstFormState);
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  }

  private initFormValue(formData: IntercomTypeHandsetLockParams): void {
    this.form.patchValue(formData);
    this.firstFormState = cloneDeep(formData);
  }
}
