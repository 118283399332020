import { createSelector } from '@ngrx/store';
import { SharedModuleStates } from '../../reducers/reducers';
import { sharedModuleSelector } from '../shared-module.state';

export const addCameraState = createSelector(
  sharedModuleSelector,
  (state: SharedModuleStates) => state.addCamera
);

export const addCameraStateSuccess = createSelector(
  addCameraState,
  state => state.camera
);

export const addCameraStateFailure = createSelector(
  addCameraState,
  state => state.error
);

export const addCameraStateLoading = createSelector(
  addCameraState,
  state => state.loading
);
