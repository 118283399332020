export enum SignUpsPageMode {
    UNPROCESSED = 'unprocessed',
    PROCESSED = 'processed',
    WAIT_ABONENT_CONFIRMATION = 'wait_abonent_confirmation',
    COMPLETE = 'complete',
    BUILDING = 'building',
    BY_REGION = 'byRegion',
    ALL = 'all',
    ACTIVE = 'active'
}
