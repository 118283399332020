export enum ServicesActionTypes {
    // General actions
    GetService = '[Services] GetService',
    GetServiceSuccess = '[Services] GetService Success',
    GetServiceFailure = '[Services] GetService Failure',

    GetDependantService = '[Services] GetDependantService',
    GetDependantServiceSuccess = '[Services] GetDependantService Success',

    UpdateService = '[Services] UpdateService',
    UpdateServiceSuccess = '[Services] UpdateService Success',
    UpdateServiceFailure = '[Services] UpdateService Failure',

    GetKeysLocations = '[Services] GetKeysLocations',
    GetKeysLocationsSuccess = '[Services] GetKeysLocations Success',
    GetKeysLocationsFailure = '[Services] GetKeysLocations Failure',

    GetServiceCompany = '[Services] GetServiceCompany',
    GetServiceCompanySuccess = '[Services] GetServiceCompany Success',
    GetServiceCompanyFailure = '[Services] GetServiceCompany Failure',

    GetIntercomTypes = '[Services] GetIntercomTypes',
    GetIntercomTypesSuccess = '[Services] GetIntercomTypes Success',
    GetIntercomTypesFailure = '[Services] GetIntercomTypes Failure',

    GetComponentsVersions = '[Services] GetComponentsVersions',
    GetComponentsVersionsSuccess = '[Services] GetComponentsVersions Success',
    GetComponentsVersionsFailure = '[Services] GetComponentsVersions Failure',

    GetCamerasLocations = '[Services] GetCamerasLocations',
    GetCamerasLocationsSuccess = '[Services] GetCamerasLocations Success',
    GetCamerasLocationsFailure = '[Services] GetCamerasLocations Failure',

    ServiceSendEmergencyAlert = '[Services] ServiceSendEmergencyAlert',
    ServiceSendEmergencyAlertSuccess = '[Services] ServiceSendEmergencyAlert Success',
    ServiceSendEmergencyAlertFailure = '[Services] ServiceSendEmergencyAlert Failure',

    AddDependantService = '[Services] AddDependantService',
    ClearService = '[Services] ClearService',
    SetPageMode = '[Services] SetPageMode',

    // Entrances actions
    AddServiceEntrance = '[Services] AddServiceEntrance',
    AddServiceEntranceSuccess = '[Services] AddServiceEntrance Success',
    AddServiceEntranceFailure = '[Services] AddServiceEntrance Failure',

    UpdateServiceEntrance = '[Services] UpdateServiceEntrance',
    UpdateServiceEntranceSuccess = '[Services] UpdateServiceEntrance Success',
    UpdateServiceEntranceFailure = '[Services] UpdateServiceEntrance Failure',

    UpdateServiceEntranceWithPrefix = '[Services] UpdateServiceEntranceWithPrefix',
    UpdateServiceEntranceWithPrefixSuccess = '[Services] UpdateServiceEntranceWithPrefix Success',
    UpdateServiceEntranceWithPrefixFailure = '[Services] UpdateServiceEntranceWithPrefix Failure',

    DeleteServiceEntrance = '[Services] DeleteServiceEntrance ',
    DeleteServiceEntranceSuccess = '[Services] DeleteServiceEntrance Success',
    DeleteServiceEntranceFailure = '[Services] DeleteServiceEntrance Failure',

    AddAdditionalFlatRange = '[Services] AddAdditionalFlatRange',
    AddAdditionalFlatRangeSuccess = '[Services] AddAdditionalFlatRangeSuccess',
    AddAdditionalFlatRangeFailure = '[Services] AddAdditionalFlatRangeFailure',

    UpdateAdditionalFlatRange = '[Services] UpdateAdditionalFlatRange',
    UpdateAdditionalFlatRangeSuccess = '[Services] UpdateAdditionalFlatRangeSuccess',
    UpdateAdditionalFlatRangeFailure = '[Services] UpdateAdditionalFlatRangeFailure',

    DeleteAdditionalFlatRange = '[Services] DeleteAdditionalFlatRange',
    DeleteAdditionalFlatRangeSuccess = '[Services] DeleteAdditionalFlatRangeSuccess',
    DeleteAdditionalFlatRangeFailure = '[Services] DeleteAdditionalFlatRangeFailure',

    // Intercom actions
    GetServiceRdas = '[Services] GetServiceRdas',
    GetServiceRdasSuccess = '[Services] GetServiceRdas Success',
    GetServiceRdasFailure = '[Services] GetServiceRdas Failure',

    AddServiceRda = '[Services] AddServiceRda',
    AddServiceRdaSuccess = '[Services] AddServiceRda Success',
    AddServiceRdaFailure = '[Services] AddServiceRda Failure',

    UpdateServiceRda = '[Services] UpdateServiceRda',
    UpdateServiceRdaSuccess = '[Services] UpdateServiceRda Success',
    UpdateServiceRdaFailure = '[Services] UpdateServiceRda Failure',

    DeleteServiceRda = '[Services] DeleteServiceRda',
    DeleteServiceRdaSuccess = '[Services] DeleteServiceRda Success',
    DeleteServiceRdaFailure = '[Services] DeleteServiceRda Failure',

    ChangeServiceRda = '[Services] ChangeServiceRda',
    ChangeServiceRdaSuccess = '[Services] ChangeServiceRda Success',
    ChangeServiceRdaFailure = '[Services] ChangeServiceRda Failure',

    GetServicePbxOnRda = '[Services] GetServicePbxOnRda',
    GetServicePbxOnRdaSuccess = '[Services] GetServicePbxOnRda Success',
    GetServicePbxOnRdaFailure = '[Services] GetServicePbxOnRda Failure',

    ConnectServicePbxOnRda = '[Services] ConnectServicePbxOnRda',
    ConnectServicePbxOnRdaSuccess = '[Services] ConnectServicePbxOnRda Success',
    ConnectServicePbxOnRdaFailure = '[Services] ConnectServicePbxOnRda Failure',

    DisconnectServicePbxOnRda = '[Services] DisconnectServicePbxOnRda',
    DisconnectServicePbxOnRdaSuccess = '[Services] DisconnectServicePbxOnRda Success',
    DisconnectServicePbxOnRdaFailure = '[Services] DisconnectServicePbxOnRda Failure',

    ChangeServicePbxOnRda = '[Services] ChangeServicePbxOnRda',
    ChangeServicePbxOnRdaSuccess = '[Services] ChangeServicePbxOnRda Success',
    ChangeServicePbxOnRdaFailure = '[Services] ChangeServicePbxOnRda Failure',

    GetTranslationTunings = '[Services] GetTranslationTunings',
    GetTranslationTuningsSuccess = '[Services] GetTranslationTunings Success',
    GetTranslationTuningsFailure = '[Services] GetTranslationTunings Failure',

    AddTranslationTuning = '[Services] AddTranslationTuning',
    AddTranslationTuningSuccess = '[Services] AddTranslationTuning Success',
    AddTranslationTuningFailure = '[Services] AddTranslationTuning Failure',

    DeleteTranslationTuning = '[Services] DeleteTranslationTuning',
    DeleteTranslationTuningSuccess = '[Services] DeleteTranslationTuning Success',
    DeleteTranslationTuningFailure = '[Services] DeleteTranslationTuning Failure',

    // Intercom panels actions
    AddServiceIntercomPanel = '[Services] AddServiceIntercomPanel',
    AddServiceIntercomPanelSuccess = '[Services] AddServiceIntercomPanel Success',
    AddServiceIntercomPanelFailure = '[Services] AddServiceIntercomPanel Failure',

    UpdateServiceIntercomPanel = '[Services] UpdateServiceIntercomPanel',
    UpdateServiceIntercomPanelSuccess = '[Services] UpdateServiceIntercomPanel Success',
    UpdateServiceIntercomPanelFailure = '[Services] UpdateServiceIntercomPanel Failure',

    DeleteServiceIntercomPanel = '[Services] DeleteServiceIntercomPanel',
    DeleteServiceIntercomPanelSuccess = '[Services] DeleteServiceIntercomPanel Success',
    DeleteServiceIntercomPanelFailure = '[Services] DeleteServiceIntercomPanel Failure',

    // Intercom keys actions
    GetServiceKeys = '[Services] GetServiceKeys',
    GetServiceKeysSuccess = '[Services] GetServiceKeys Success',
    GetServiceKeysFailure = '[Services] GetServiceKeys Failure',

    AddServiceKey = '[Services] AddServiceKey',
    AddServiceKeySuccess = '[Services] AddServiceKey Success',
    AddServiceKeyFailure = '[Services] AddServiceKey Failure',

    UpdateServiceKey = '[Services] UpdateServiceKey',
    UpdateServiceKeySuccess = '[Services] UpdateServiceKey Success',
    UpdateServiceKeyFailure = '[Services] UpdateServiceKey Failure',

    DeleteServiceKey = '[Services] DeleteServiceKey',
    DeleteServiceKeySuccess = '[Services] DeleteServiceKey Success',
    DeleteServiceKeyFailure = '[Services] DeleteServiceKey Failure',

    // Cameras actions
    GetServiceCameras = '[Services] GetServiceCameras',
    GetServiceCamerasSuccess = '[Services] GetServiceCameras Success',
    GetServiceCamerasFailure = '[Services] GetServiceCameras Failure',

    AddServiceCamera = '[Services] AddServiceCamera',
    AddServiceCameraSuccess = '[Services] AddServiceCamera Success',
    AddServiceCameraFailure = '[Services] AddServiceCamera Failure',

    ConnectServiceCamera = '[Services] ConnectServiceCamera',
    ConnectServiceCameraSuccess = '[Services] ConnectServiceCamera Success',
    ConnectServiceCameraFailure = '[Services] ConnectServiceCamera Failure',

    UpdateServiceCamera = '[Services] UpdateServiceCamera',
    UpdateServiceCameraSuccess = '[Services] UpdateServiceCamera Success',
    UpdateServiceCameraFailure = '[Services] UpdateServiceCamera Failure',

    DeleteServiceCamera = '[Services] DeleteServiceCamera',
    DeleteServiceCameraSuccess = '[Services] DeleteServiceCamera Success',
    DeleteServiceCameraFailure = '[Services] DeleteServiceCamera Failure',

    ResetServiceCamera = '[Services] ResetServiceCamera',
    ResetServiceCameraSuccess = '[Services] ResetServiceCamera Success',
    ResetServiceCameraFailure = '[Services] ResetServiceCamera Failure',

    // ONVIF

    ActiveServiceOnvif = '[Services] ActiveServiceOnvif',
    ActiveServiceOnvifSuccess = '[Services] ActiveServiceOnvif Success',
    ActiveServiceOnvifFailure = '[Services] ActiveServiceOnvif Failure',

    TuneServiceOnvif = '[Services] TuneServiceOnvif',
    TuneServiceOnvifSuccess = '[Services] TuneServiceOnvif Success',
    TuneServiceOnvifFailure = '[Services] TuneServiceOnvif Failure',


    // Connections actions
    GetServiceConnectionsInit = '[Services] GetServiceConnectionsInit',
    GetServiceConnections = '[Services] GetServiceConnections',
    GetServiceConnectionsSuccess = '[Services] GetServiceConnections Success',
    ServiceConnectionsPrepared = '[Services] ServiceConnectionsPrepared Success',
    GetServiceConnectionsFailure = '[Services] GetServiceConnections Failure',

    AddServiceConnectionsFromEntrances = '[Services] AddServiceConnectionsFromEntrances',
    AddServiceConnectionsFromEntrancesSuccess = '[Services] AddServiceConnectionsFromEntrances Success',
    AddServiceConnectionsFromEntrancesFailure = '[Services] AddServiceConnectionsFromEntrances Failure',

    CreateServiceConnections = '[Services] CreateServiceConnections',
    CreateServiceConnectionsSuccess = '[Services] CreateServiceConnections Success',
    CreateServiceConnectionsFailure = '[Services] CreateServiceConnections Failure',

    BlockServiceConnection = '[Services] BlockServiceConnection',
    BlockServiceConnectionSuccess = '[Services] BlockServiceConnectionSuccess',
    BlockServiceConnectionFailure = '[Services] BlockServiceConnectionFailure',

    UnblockServiceConnection = '[Services] UnblockServiceConnection',
    UnblockServiceConnectionSuccess = '[Services] UnblockServiceConnectionSuccess',
    UnblockServiceConnectionFailure = '[Services] UnblockServiceConnectionFailure',

    DeleteServiceConnection = '[Services] DeleteServiceConnection',
    DeleteServiceConnectionSuccess = '[Services] DeleteServiceConnectionSuccess',
    DeleteServiceConnectionFailure = '[Services] DeleteServiceConnectionFailure',

    AddServicePhysicalTube = '[Services] AddServicePhysicalTube',
    AddServicePhysicalTubeSuccess = '[Services] AddServicePhysicalTubeSuccess',
    AddServicePhysicalTubeFailure = '[Services] AddServicePhysicalTubeFailure',

    DeleteServicePhysicalTube = '[Services] DeleteServicePhysicalTube',
    DeleteServicePhysicalTubeSuccess = '[Services] DeleteServicePhysicalTubeSuccess',
    DeleteServicePhysicalTubeFailure = '[Services] DeleteServicePhysicalTubeFailure',

    BlockServicePhysicalTube = '[Services] BlockServicePhysicalTube',
    BlockServicePhysicalTubeSuccess = '[Services] BlockServicePhysicalTubeSuccess',
    BlockServicePhysicalTubeFailure = '[Services] BlockServicePhysicalTubeFailure',

    UnblockServicePhysicalTube = '[Services] UnblockServicePhysicalTube',
    UnblockServicePhysicalTubeSuccess = '[Services] UnblockServicePhysicalTubeSuccess',
    UnblockServicePhysicalTubeFailure = '[Services] UnblockServicePhysicalTubeFailure',

    DeleteServiceFlat = '[Services] DeleteServiceFlat',
    DeleteServiceFlatSuccess = '[Services] DeleteServiceFlatSuccess',
    DeleteServiceFlatFailure = '[Services] DeleteServiceFlatFailure',

    DeleteServiceAbonentAccess = '[Services] DeleteServiceAbonentAccess',
    DeleteServiceAbonentAccessSuccess = '[Services] DeleteServiceAbonentAccessSuccess',
    DeleteServiceAbonentAccessFailure = '[Services] DeleteServiceAbonentAccessFailure',

    DelegateServiceAbonentAccess = '[Services] DelegateServiceAbonentAccess',
    DelegateServiceAbonentAccessSuccess = '[Services] DelegateServiceAbonentAccessSuccess',
    DelegateServiceAbonentAccessFailure = '[Services] DelegateServiceAbonentAccessFailure',

    FilterServiceFlats = '[Services] FilterServiceFlats',
    FilterServiceFlatsSuccess = '[Services] FilterServiceFlatsSuccess',
    SetServiceFillEmptyFlats = '[Services] SetServiceFillEmptyFlats',

    // Activity actions
    DeleteServiceKeySource = '[Services] DeleteServiceKeySource',
    GetServiceKeysSources = '[Services] GetServiceKeysSources',
    GetServiceKeysSourcesSuccess = '[Services] GetServiceKeysSources Success',
    GetServiceKeysSourcesFailure = '[Services] GetServiceKeysSources Failure',

    DeleteServiceCameraSource = '[Services] DeleteServiceCameraSource',
    GetServiceCamerasSources = '[Services] GetServiceCamerasSources',
    GetServiceCamerasSourcesSuccess = '[Services] GetServiceCamerasSources Success',
    GetServiceCamerasSourcesFailure = '[Services] GetServiceCamerasSources Failure',

    DeleteServiceRdaSource = '[Services] DeleteServiceRdaSource',
    GetServiceRdasSources = '[Services] GetServiceRdasSources',
    GetServiceRdasSourcesSuccess = '[Services] GetServiceRdasSources Success',
    GetServiceRdasSourcesFailure = '[Services] GetServiceRdasSources Failure',

    GetServiceCameraActivityBefore = '[Services] GetServiceCameraActivityBefore',
    GetServiceCameraActivity = '[Services] GetServiceCameraActivity',
    GetServiceCameraActivitySuccess = '[Services] GetServiceCameraActivity Success',
    GetServiceCameraActivityFailure = '[Services] GetServiceCameraActivity Failure',

    GetServiceRdaActivityBefore = '[Services] GetServiceRdaActivityBefore',
    GetServiceRdaActivity = '[Services] GetServiceRdaActivity',
    GetServiceRdaActivitySuccess = '[Services] GetServiceRdaActivity Success',
    GetServiceRdaActivityFailure = '[Services] GetServiceRdaActivity Failure',

    GetServiceRdaLogs = '[Services] GetServiceRdaLogs',
    GetServiceRdaLogsSuccess = '[Services] GetServiceRdaLogs Success',
    GetServiceRdaLogsFailure = '[Services] GetServiceRdaLogs Failure',

    GetServiceCameraLogs = '[Services] GetServiceCameraLogs',
    GetServicePrivateCameraLogs = '[Services] GetServicePrivateCameraLogs',
    GetServiceCameraLogsSuccess = '[Services] GetServiceCameraLogs Success',
    GetServiceCameraLogsFailure = '[Services] GetServiceCameraLogs Failure',

    SetServiceLogsPage = '[Services] SetServiceLogsPage',
    SetServiceBlocksCount = '[Services] SetServiceBlocksCount',
    SetServiceComponentType = '[Services] SetServiceComponentType',
    SetServiceLogsSource = '[Services] SetServiceLogsSource',
    SetServiceLogsStream = '[Services] SetServiceLogsStream',
    SetServiceLogsTimeRange = '[Services] SetServiceLogsTimeRange',

    SetTariff = '[Services] SetTariff'
}
