import { Camera, Company, RdaResponse, ServicesList } from '@app/shared/entities/rd';
import { ServicesTypes } from '@app/shared/models';
import {FormControl} from '@angular/forms';

export interface ServiceInfoResponse {
  id: number;
  autoconnectionEnabled: boolean;
  name: string;
  type: ServicesTypes;
  status: string;
  customName: string;
  delegationTunings: {
   limit: number | null;
  };
  dependantServices: ServicesList[];
  rdas: Pick<RdaResponse, 'id' | 'active' | 'uid' | 'intercoms'>[];
  cameras: Pick<Camera, 'id' | 'uri' | 'active'>[];
  company: Pick<Company, 'id' | 'shortName' | 'licenseAgreement'>;
  tariff: number;

  control?: FormControl;
}
