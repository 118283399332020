<app-dialog-wrapper
  [componentName]="data.componentName"
  [progress]="(popupService.state$ | async) === 'loading'"
  [title]="data.title"
>
  <div class="flat-abonents">
    <div class="flat-abonents__item flat-abonents__item_main">
      <div class="flat-abonents__number">
        {{data.body.flat.account.owner.phone.toString() | phone}}
        <span class="flat-abonents__type">
          {{ 'services.popups.abonents.flat.general' | translate }}
        </span>
      </div>
      <a
        type="button"
        [routerLink]="'/abonents/subscriber/' + data.body.flat.account.owner.id"
        (click)="dialogRef.close()"
        color="primary-inverse"
        mat-mini-fab
      >
        <mat-icon svgIcon="right-arrow"></mat-icon>
      </a>
    </div>
    <div class="flat-abonents__item" *ngFor="let abonent of data.body.flat.sharedAbonents">
      <div class="flat-abonents__number">
        {{abonent.number.toString() | phone}}
      </div>
      <a
        type="button"
        (click)="dialogRef.close()"
        [routerLink]="'/abonents/subscriber/' + abonent.abonentId"
        color="primary-inverse"
        mat-mini-fab
      >
        <mat-icon svgIcon="right-arrow"></mat-icon>
      </a>
    </div>
  </div>
</app-dialog-wrapper>
