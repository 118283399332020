import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { IntercomsListContentComponent } from './intercoms-list-content.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        SharedPipesModule,
        MaterialModule,
        NgxTranslateModule,
    ],
    declarations: [
        IntercomsListContentComponent
    ],
    exports: [
        IntercomsListContentComponent
    ]
})
export class IntercomsListContentModule { }
