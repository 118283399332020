import { Injectable } from '@angular/core';
import { ServiceInfoResponse } from '@app/shared/entities/rd';
import { ServicePageServiceModel } from '@app/views/services/base';
import { VideoSurveillancePageModel } from './../models';

@Injectable()
export class VideoSurveillancePageService implements ServicePageServiceModel<VideoSurveillancePageModel> {
    preparePageResponse(serviceInfoList: ServiceInfoResponse[]): VideoSurveillancePageModel[] {
        return serviceInfoList.map(gate => ({
            id: gate.id,
            name: gate.customName ?? gate.name,
            companyId: gate.company?.id,
            camerasStates: {
                active: gate.cameras.filter(camera => camera.active).length,
                inactive: gate.cameras.filter(camera => !camera.active).length
            }
        }));
    }
}
