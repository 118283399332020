import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { InfoBlockModule } from '@app/shared/ui';
import { ServiceRdaLogsComponent } from './service-rda-logs.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        InfoBlockModule,
        NgxSkeletonLoaderModule,
        NgxTranslateModule
    ],
    declarations: [
        ServiceRdaLogsComponent,
    ],
    exports: [
        ServiceRdaLogsComponent
    ]
})
export class ServiceRdaLogsModule { }
