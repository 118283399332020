
export const SUPPORT_LINK = 'https://rosdomofon.atlassian.net/servicedesk/customer/portal/1/group/1/create/1';
export const RDOS_IMAGES_STORAGE = 'https://apt.rosdomofon.com/images/rdos/';
export const CAMERAS_GUIDE_LINK = 'https://wiki.rosdomofon.com/ru/Поддержка/Техникам_и_администраторам/Камеры/Требования_и_советы_по_настройкам_и_проверке_видеокамер';
export const IP_INTERCOMS_GUIDE_LINK = 'https://wiki.rosdomofon.com/ru/Поддержка/Техникам_и_администраторам/IP_домофоны/Подключение_IP_домофона_к_облаку_РосДомофон';
export const UNSUPPORTED_INTERCOM_TYPE_LINK = 'https://wiki.rosdomofon.com/ru/Поддержка/Техникам_и_администраторам/Список_поддерживаемого_оборудования_и_схемы_подключений/Не_нашли_свое_оборудование_в_списке';
export const TARRIFS_PAGE_LINK = 'https://rosdomofon.com/tariffs-ru';
export const PERSONAL_AGREEMENT_PAGE_LINK_RU = 'https://rosdomofon.com/terms-of-use-ru/';
export const PERSONAL_AGREEMENT_PAGE_LINK_KZ = 'https://moydomofon.kz/terms_of_use/';
export const PRIVACY_POLICY_PAGE_LINK_RU = 'https://rosdomofon.com/privacy-policy/';
export const PRIVACY_POLICY_PAGE_LINK_KZ = 'https://moydomofon.kz/privacy_policy/';
export const TRANSLATIONS_TUTORIAL_VIDEO_LINK = 'https://www.youtube.com/embed/uA1s1Urolmk';
export const ABOUT_PAYMENT_CONNECTION = 'https://wiki.rosdomofon.com/ru/Поддержка/Возможности_Системы_РосДомофон/Сбор_платежей';
export const ABOUT_PAYMENT_CONNECTION_YOOKASSA = 'https://wiki.rosdomofon.com/ru/Поддержка/Возможности_Системы_РосДомофон/Сбор_платежей/ЮKassa';
export const ABOUT_PAYMENT_CONNECTION_ROBOKASSA = 'https://wiki.rosdomofon.com/ru/Поддержка/Возможности_Системы_РосДомофон/Сбор_платежей/Робокасса';
export const OFFER_LICENSE_AGREEMENT_RU = 'https://rosdomofon.com/license-agreement';
export const OFFER_LICENSE_AGREEMENT_KZ = 'https://moydomofon.kz/license_agreement';
export class Constants {
  static readonly DEFAULT_ERROR_MESSAGE: string = 'Что-то пошло не так, повторите попытку';
  static readonly CUSTOM_DIALOG_CLASS: string = 'cdk-overlay-pane-custom';
  static readonly FULLSCREEN_DIALOG_CLASS: string = 'cdk-overlay-pane-fullscreen';
  static readonly PLAYER_DIALOG_CLASS: string = 'cdk-overlay-pane-player';
  static readonly PLAYER_DIALOG_BACKDROP_CLASS: string = 'cdk-overlay-backdrop-player';
  static readonly MAX_MOBILE_WIDTH: string = '(max-width: 800px)';
  static readonly MAX_TABLET_WIDTH: string = '(max-width: 1200px)';
  static readonly CAMERA_LOADING_IMG_PATH = './assets/images/photoshot-loading.gif';
  static readonly CAMERA_ERROR_IMG_PATH = './assets/images/no-photoshot.png';
  static readonly PAGE_SIZE: number = 10;
  static readonly ABONENTS_MINUTE = 60000;
  static readonly dateDiffs: Array<{ value: number, name: string }> = [
    { value: 5, name: '5 минут' },
    { value: 15, name: '15 минут' },
    { value: 30, name: '30 минут' },
    { value: 60, name: '1 час' },
    { value: 60 * 2, name: '2 часа' },
    { value: 60 * 8, name: '8 часов' },
    { value: 60 * 24, name: '1 день' },
    { value: 60 * 24 * 2, name: '2 дня' },
    { value: 60 * 24 * 5, name: '5 дней' },
    { value: 60 * 24 * 7, name: '7 дней' },
    { value: 60 * 24 * 14, name: '14 дней' },
    { value: 60 * 24 * 30, name: '30 дней' },
    { value: Math.floor(((new Date()).getTime() - (new Date('January 1, 2018')).getTime()) / 60000), name: 'Все время' },
  ];
}
