<div class="page-container">
  <div class="mailing-block">
    <app-mailing-history
      [selectedMailingIdx]="selectedMailingIdx"
      (selected)="onSelectMailing($event)"
    ></app-mailing-history>

    <app-mailing-new
      *ngIf="!isMobile()"
      [mailing]="mailing"
      (submitted)="onSubmit()"
      (repeated)="onRepeatMailing($event)"
    ></app-mailing-new>
  </div>
</div>
