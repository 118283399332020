import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {BreadcrumbService} from '@app/core';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {ServiceApiService} from '@app/shared/entities/rd';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {PersonalSurveillance} from '@app/shared/entities/rd/services/models/personal-surveillance.model';
import {NavbarLink} from '@app/shared/ui';

export namespace ApartmentVideoServicePage {
  export enum PageTabs {
    activity = 'activity',
    settings = 'settings',
    access = 'access',
  }
}

@Component({
  selector: 'app-apartment-video-service-page',
  templateUrl: './apartment-video-service-page.component.html',
  styleUrls: ['./apartment-video-service-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ApartmentVideoServicePageComponent implements OnInit, OnDestroy {
  public readonly PageTabs = ApartmentVideoServicePage.PageTabs;
  public readonly links: NavbarLink[] = [
    {
      name: ApartmentVideoServicePage.PageTabs.activity,
      text: this.translateService.instant('apartments_video_surveillance.page.activity')
    },
    {
      name: ApartmentVideoServicePage.PageTabs.settings,
      text: this.translateService.instant('apartments_video_surveillance.page.settings')
    },
    {
      name: ApartmentVideoServicePage.PageTabs.access,
      text: this.translateService.instant('apartments_video_surveillance.page.access')
    },
  ];
  public activeLink: ApartmentVideoServicePage.PageTabs = ApartmentVideoServicePage.PageTabs.settings;
  public loaded = false;

  public serviceInfo: PersonalSurveillance.ServiceByIdResponse;

  private destroy = new Subject<void>();

  constructor(
    private serviceApiService: ServiceApiService,
    private breadcrumbService: BreadcrumbService,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  public ngOnInit(): void {
    this.activatedRoute.data
      .pipe(takeUntil(this.destroy))
      .subscribe((response) => {
        this.breadcrumbService.title = response.serviceInfo?.customName || response.serviceInfo.name;
        this.serviceInfo = response?.serviceInfo;
        this.loaded = true;
        this.changeDetectorRef.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public onChangePage(pageIdx: number): void {
    if (pageIdx < 0 || pageIdx > this.links.length - 1) {
      return;
    }
    this.activeLink = this.links[pageIdx].name;
  }

  public onReloadData($event: boolean) {
    this.loaded = false;
    this.serviceApiService.getPersonalSurveillance(this.serviceInfo.id)
      .pipe(takeUntil(this.destroy))
      .subscribe((response) => {
        this.breadcrumbService.title = response?.customName || response.name;
        this.serviceInfo = response;
        this.loaded = true;
        this.changeDetectorRef.markForCheck();
      });
  }
}
