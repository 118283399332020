import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { ConfirmComponentConnectionModule, CreateServicePopupModule, IntercomEditContentModule, IntercomUpdateToolModule } from '@app/shared/components';
import { SharedPipesModule } from '@app/shared/pipes';
import { RdeaStepperModule } from '@app/shared/templates';
import { InfoBlockModule, PaginatedPageWrapperModule } from '@app/shared/ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ServiceActivityModule, ServiceCamerasModule, ServiceCamerasWarningModule, ServiceConfigModule, ServiceEntrancesModule, ServiceIntercomKeysModule, ServiceIntercomsModule, ServiceNavbarModule, ServiceRdaWarningModule } from '../../components';
import { ServicesHelper } from '../../services';
import { ServiceFacade } from '../../store';
import { GatePageComponent, GateFlatsModule, GatesPageComponent, GateWizardPopupModule } from './components';
import { GatesPageService } from './services';
import { GatesPageEffects, GatesPageFacade, gatesReducers } from './store';
import {NgxTranslateModule} from '@app/translate/translate.module';
import {DelegationComponent} from '@app/views/services/submodules/shared/delegation/delegation.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        RdeaStepperModule,
        SharedPipesModule,
        ServiceIntercomKeysModule,
        IntercomEditContentModule,
        ServiceCamerasModule,
        GateWizardPopupModule,
        ServiceConfigModule,
        RouterModule,
        ConfirmComponentConnectionModule,
        ServiceEntrancesModule,
        ServiceActivityModule,
        GateFlatsModule,
        ServiceIntercomsModule,
        ServiceNavbarModule,
        ServiceCamerasWarningModule,
        ServiceRdaWarningModule,
        CreateServicePopupModule,
        PaginatedPageWrapperModule,
        NgxSkeletonLoaderModule,
        IntercomUpdateToolModule,
        InfoBlockModule,
        StoreModule.forFeature('gates', gatesReducers),
        EffectsModule.forFeature([
            GatesPageEffects
        ]),
        NgxTranslateModule,
        DelegationComponent,
    ],
    declarations: [
        GatesPageComponent,
        GatePageComponent
    ],
    providers: [
        ServiceFacade,
        GatesPageFacade,
        ServicesHelper,
        GatesPageService
    ]
})
export class GatesModule { }
