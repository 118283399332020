import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PermissionsTreeKey } from '@app/security';
import { openCloseH } from '@app/shared/animations/animations';
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  IAbonentConnection,
  IAbonentDelegation,
  IConnection,
  IToAbonent,
  ServiceApiService
} from '@app/shared/entities/rd';
import { AddressFormatter } from '@app/shared/services';
import { ServiceType } from '@app/views/services/submodules/shared/abonents-delegation/pipes/service-converter.pipe';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog/dynamicdialog-ref';
import {
  AbonentEditConnectionComponent
} from '@app/views/abonents/components/pages/abonent-page/abonent-shared/abonent-edit-connection/abonent-edit-connection.component';
import {MessageService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {
  DeleteConnectionConfirmComponent
} from '@app/views/abonents/components/pages/abonent-page/abonent-shared/abonent-edit-connection/delete-connection-confirm/delete-connection-confirm.component';
import { BaseInfoPanelComponent } from '@app/shared/components/info-panel/components/base-info-panel/base-info-panel.component';
import { NativeDialogService } from '@app/shared/components/info-panel/services/native-dialog.service';
import {Response} from '@app/shared/components/info-panel/models/response';
import { parseError } from '@app/shared/helpers/parse-error';

@Component({
  selector: 'app-abonent-shared',
  templateUrl: './abonent-shared.component.html',
  styleUrls: ['./abonent-shared.component.scss'],
  providers: [DialogService, MessageService],
  animations: [openCloseH]
})
export class AbonentSharedComponent extends BaseInfoPanelComponent implements OnInit, OnDestroy {
  readonly permissionsTreeKey: typeof PermissionsTreeKey = PermissionsTreeKey;
  protected readonly ServiceType = ServiceType;

  @Input() abonentId: number = null;

  public abonentConnection: IConnection[] = [];
  public abonentDelegations: IConnection[] = [];
  public fromAbonent: IToAbonent[];
  public openedPanels: number[] = [];
  public confirmDynamicDialogRef: DynamicDialogRef = null;
  public confirmConnectionDialogRef: DynamicDialogRef = null;
  public loading = true;

  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private apiService: ServiceApiService,
    public addressFormatter: AddressFormatter,
    private primeDialogService: DialogService,
    private translate: TranslateService,
    private router: Router,
    protected dialogService: NativeDialogService
  ) {
    super(dialogService)
  }

  ngOnInit(): void {
    this.makeRequest();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onPanelOpen(abonent: IToAbonent): void {
    if (this.openedPanels.includes(abonent.id)) {
      this.openedPanels = this.openedPanels.filter(
        (panel) => panel !== abonent.id
      );
      return;
    }
    this.openedPanels.push(abonent.id);
  }

  public isPanelOpen(id: number): boolean {
    return this.openedPanels.includes(id);
  }

  public onConnectionEdit(abonent: IToAbonent): void {
    this.confirmDynamicDialogRef = this.primeDialogService.open(
      AbonentEditConnectionComponent,
      {
        header: this.translate.instant('abonent.sign_ups.field.services_settings'),
        width: '520px',
        data: {
          abonent: abonent,
          ownerId: this.abonentId,
          request: this.makeRequest.bind(this)
        }
      }
    );
  }

  public onAbonentCardNavigate(abonent: IToAbonent): void {
    this.router.navigate([`/abonents/subscriber/${abonent.id}`]);
  }

  public onDelegationDelete(abonent: IToAbonent): void {
    this.confirmConnectionDialogRef = this.primeDialogService.open(
      DeleteConnectionConfirmComponent,
      {
        header: this.translate.instant('abonent.sign_ups.field.services_warning'),
        width: '500px',
        data: {
          request: () => {
            this.loading = true;
            this.confirmConnectionDialogRef.close();
            this.apiService.deleteDelegation(this.abonentId, abonent.id).pipe(takeUntil(this.onDestroy$))
              .subscribe(() => {
                this.makeRequest();
                this.showResponseStatus(
                  this.translate.instant('abonent.delegations.field.success'),
                  this.translate.instant('abonent.delegations.field.success_delete_abonent_access_message'),
                  Response.success
                )}, 
                (error) => {
                  this.loading = false;
                  this.showResponseStatus(
                    this.translate.instant('abonent.delegations.field.error'),
                    this.translate.instant('abonent.delegations.field.error_delete_abonent_access_message', {
                      text: parseError(error)
                    }),
                    Response.error
                  );
                }
              );
          }
        }
      }
    );
  }

  public onServicedNavigate(connection: IConnection): void {
    this.router.navigate([`/services/software-intercoms/${connection.service.id}`]);
  }

  private getMergedConnections(
    connections: IAbonentConnection[]
  ): IConnection[] {
    const tempArr: IConnection[] = [];
    connections.forEach((services) => {
      tempArr.push(
        ...services.connections.map((s) => {
          return {
            ...s,
            address: services.flat.address
          };
        })
      );
    });
    return tempArr;
  }

  private getMergedDelegations(
    delegations: IAbonentDelegation[]
  ): IConnection[] {
    const tempArr = [];
    delegations.forEach((services: IAbonentDelegation) => {
      tempArr.push({
        ...services.connection,
        toAbonent: services.toAbonent
      });
    });
    return tempArr;
  }

  private makeRequest(): void {
    forkJoin({
      fromAbonent: this.apiService.fromAbonentDelegation(this.abonentId),
      connection: this.apiService.abonentConnection(this.abonentId)
    })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((response) => {
        this.fromAbonent = Array.from(
          new Map(
            response.fromAbonent.map((item) => [item.toAbonent.phone, item])
          ).values()
        ).map((delegation) => delegation.toAbonent);
        this.abonentDelegations = this.getMergedDelegations(
          response.fromAbonent
        );
        this.abonentConnection = this.getMergedConnections(response.connection);

        this.loading = false;
      });
  }
}
