import { Pipe, PipeTransform } from '@angular/core';
import { Address } from '@app/shared/models';
import { AddressFormatter } from '../services';

@Pipe({
  name: 'address'
})
export class AddressPipe implements PipeTransform {
  constructor(
    private addressFormatter: AddressFormatter
  ) {}

  transform(value: Address, args?: any): any {
    return this.addressFormatter.formatAddress(value);
  }
}
