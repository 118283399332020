import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslationTuningService extends ApiService {
    constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
        super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1']);
    }

    deleteTranslationTuning(id: number): Observable<null> {
        return this.delete(`/translation_tunings/${id}`, 1);
    }
}
