import { Country } from '@app/shared/models';

export interface Company {
    id: number;
    inn: string;
    name: string;
    isBlocked: boolean;
    supportPhone?: string;
    abonentsBillingEnabled?: boolean;
    country?: Country;
    licenseAgreement?: string;
    managerEmail?: string;
    managerName?: string;
    licenseeShort?: string;
    shortName?: string;
}
