import { IntercomUpdateToolIntecomsState, IntercomUpdateToolModuleState } from '@app/shared/components/rda/intercom-update-tool/store/intercom-update-tool.state';
import { createSelector } from '@ngrx/store';
import { IntercomUpdateToolStatus } from '../intercom-update-tool-status.model';
import { intercomUpdateToolModuleSelector } from './intercom-update-tool-module.state';

const selectState = createSelector(
    intercomUpdateToolModuleSelector,
    (state: IntercomUpdateToolModuleState) => state.intercomUpdateTool
);

const selectUpdateRdaaJob = createSelector(
    selectState,
    (state: IntercomUpdateToolIntecomsState, props: { intercomUid: string }) =>
        state.intercomUpdateState[props.intercomUid]?.rdaaUpdateJob
);

const selectUpdateRdosJob = createSelector(
    selectState,
    (state: IntercomUpdateToolIntecomsState, props: { intercomUid: string }) =>
        state.intercomUpdateState[props.intercomUid]?.rdosUpdateJob
);

const selectUpdateRdaaJobLogs = createSelector(
    selectState,
    (state: IntercomUpdateToolIntecomsState, props: { intercomUid: string }) =>
        state.intercomUpdateState[props.intercomUid]?.rdaaUpdateJobLogs
);

const selectUpdateRdosJobLogs = createSelector(
    selectState,
    (state: IntercomUpdateToolIntecomsState, props: { intercomUid: string }) =>
        state.intercomUpdateState[props.intercomUid]?.rdosUpdateJobLogs
);

const selectJobsStatus = createSelector(
    selectState,
    (state: IntercomUpdateToolIntecomsState, props: { intercomUid: string }) =>
        state.intercomUpdateState[props.intercomUid]?.status ?? IntercomUpdateToolStatus.NOT_STARTED
);

const selectErrorsCount = createSelector(
    selectState,
    (state: IntercomUpdateToolIntecomsState, props: { intercomUid: string }) =>
        state.intercomUpdateState[props.intercomUid]?.errorsCount
);

const selectUpdateState = createSelector(
    selectState,
    (state: IntercomUpdateToolIntecomsState, props: { intercomUid: string }) => ({
        listening: state.intercomUpdateState[props.intercomUid]?.listening,
        rdaaUpdateJobId: state.intercomUpdateState[props.intercomUid]?.rdaaUpdateJob?.id,
        rdosUpdateJobId: state.intercomUpdateState[props.intercomUid]?.rdosUpdateJob?.id
    })
);

export const intercomUpdateToolSelectors = {
    selectUpdateRdaaJob,
    selectUpdateState,
    selectUpdateRdosJob,
    selectUpdateRdaaJobLogs,
    selectUpdateRdosJobLogs,
    selectJobsStatus,
    selectErrorsCount,
};
