<mat-card>
  <div class="order-card">
    <mat-card-title-group>

      <mat-card-title *ngIf="isManufacturer(); else customerTitle">
        {{ 'intercom.orders.card.title' | translate }} "{{order.customerCompany.name}}"
      </mat-card-title>
      <ng-template #customerTitle>
        <mat-card-title>
          {{ 'intercom.orders.card.title' | translate }} {{order.createdAt | date: 'dd.MM.yyyy'}}
        </mat-card-title>
      </ng-template>

      <mat-card-subtitle *ngIf="order.manufacturerName">
        {{ 'intercom.orders.card.subtitle' | translate }}: {{order.manufacturerName}}
      </mat-card-subtitle>
    </mat-card-title-group>

    <mat-chip
      class="status"
      [disabled]="true"
      [selectable]="false"
      color="{{ isReceived() ? 'success' : isTodo() ? 'neutral' : 'primary' }}"
    >{{ order.status | orderStatus }}</mat-chip>

    <mat-card-content>
      <ng-container *ngIf="order.manufacturerName && !isTodo(); else freeOrder">
        <mat-chip
          name="adapters-count"
          [disabled]="true"
          [selectable]="false"
          color="{{
            isAllAdaptersLoaded() ? 'success' :
            isAdaptersLoaded() ? 'warn' :
            isTodo() ? 'primary' : 'error'
          }}"
        >
          {{ 'intercom.orders.card.field.adapters' | translate }}: {{order.rdasList?.length || 0}} /
          {{order.rdaCount}}
        </mat-chip>
      </ng-container>
      <ng-template #freeOrder>
        <mat-chip name="adapters-count" class="neutral-chip" [disabled]="true" [selectable]="false">
          {{ 'intercom.orders.card.field.need_adapters' | translate }}: {{order.rdaCount}}
        </mat-chip>
      </ng-template>
    </mat-card-content>

    <mat-card-actions>
      <ng-container *ngIf="isManufacturer() && !isAwaitingReciept() && !isReceived()">
        <button
          *ngIf="order.manufacturerName && !isTodo(); else assignOrder"
          [class.mt-5]="isMobile()"
          mat-raised-button
          color="primary"
          (click)="onAddRdas()"
        >
          {{ 'intercom.orders.card.button.add_rdas' | translate }}
        </button>
        <ng-template #assignOrder>
          <button
            [class.mt-5]="isMobile()"
            mat-raised-button
            color="primary"
            (click)="onAssign()"
          >
            {{
              order.manufacturerName
                ? ('intercom.orders.card.button.start_working' | translate)
                : ('intercom.orders.card.button.accept_order' | translate)
            }}
          </button>
        </ng-template>
        <button
          *ngIf="isAllAdaptersLoaded()"
          [class.mt-5]="isMobile()"
          [class.d-block]="isMobile()"
          mat-raised-button
          color="primary"
          (click)="onSendOrder()"
        >
          {{ 'intercom.orders.card.button.send_order' | translate}}
        </button>
      </ng-container>

      <ng-container *ngIf="isAwaitingReciept()">
        <button
          mat-raised-button
          color="primary"
          (click)="onPutScans()"
        >
          {{ 'intercom.orders.card.button.put_scans' | translate }}
        </button>
        <button
          [class.mt-5]="isMobile()"
          [class.d-block]="isMobile()"
          *ngIf="isScansLoaded()"
          mat-raised-button
          color="primary"
          (click)="onClose()"
        >
          {{ 'intercom.orders.card.button.close' | translate }}
        </button>
      </ng-container>
    </mat-card-actions>

    <mat-card-footer>
      <p class="date-field-large">
        {{ 'intercom.orders.card.field.created' | translate }} {{order.createdAt | date: 'dd.MM.yyyy HH:mm:ss'}}
      </p>
    </mat-card-footer>

    <div class="actions-menu">
      <button class="ml-5" mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
        <mat-icon svgIcon="submenu"></mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <div class="triggered-menu-content">
          <button
            class="triggered-menu-content__button"
            mat-button color="primary"
            (click)="onHistory()"
          >
            {{ 'intercom.orders.card.button.history' | translate }}
          </button>

          <button
            class="triggered-menu-content__button"
            mat-button
            color="primary"
            *ngIf="order.orderScanResponseList?.length > 0"
            (click)="onScansSave()"
          >
            {{ 'intercom.orders.card.button.scans_save' | translate }}
          </button>

          <button
            class="triggered-menu-content__button"
            mat-button
            color="primary"
            *ngIf="isAdaptersLoaded()"
            (click)="onPrint()"
          >
            {{ 'intercom.orders.card.button.print' | translate }}
          </button>

          <ng-container *ngIf="!isAwaitingReciept() && !isReceived()">
            <button
              class="triggered-menu-content__button"
              mat-button
              color="primary"
              (click)="onEdit()"
            >
              {{ 'intercom.orders.card.button.edit' | translate }}
            </button>

            <button
              class="triggered-menu-content__button"
              mat-button
              color="error"
              (click)="onDelete()"
            >
              {{ 'intercom.orders.card.button.delete' |  translate }}
            </button>
          </ng-container>
        </div>
      </mat-menu>
    </div>
  </div>
</mat-card>
