import { Component, EventEmitter, Input, Output } from '@angular/core';
import {ButtonModule} from 'primeng/button';
import {NgIf} from '@angular/common';

@Component({
  selector: 'app-filter-button',
  templateUrl: './filter-button.component.html',
  styleUrls: ['./filter-button.component.scss'],
  imports: [
    ButtonModule,
    NgIf
  ],
  standalone: true
})
export class FilterButtonComponent {
  @Input() icon = 'pi-filter';
  @Input() text = 'Фильтры';
  @Input() style = '';
  @Input() badgeContent = '';

  @Output() action: EventEmitter<void> = new EventEmitter<void>();

  public onClick(): void {
    this.action.emit();
  }
}
