import { MaterialModule } from '@app/material.module';
import { ComponentLoaderComponent } from './component-loader.component';
import { NgModule } from '@angular/core';
import { InfoBlockModule } from '../info-block';

const components = [
    ComponentLoaderComponent
];

@NgModule({
    imports: [
        MaterialModule,
        InfoBlockModule
    ],
    declarations: components,
    exports: components
})
export class ComponentLoaderModule { }
