import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RdaResponse } from '@app/shared/entities/rd';

@Component({
  selector: 'app-service-intercoms-list-content',
  templateUrl: './service-intercoms-list-content.component.html',
  styleUrls: ['./service-intercoms-list-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceIntercomsListContentComponent {
  @Input() intercoms: RdaResponse[];
  @Input() controlsHidden: boolean;

  @Output() private editIntercom: EventEmitter<{ intercomIdx: number }> = new EventEmitter();
  @Output() private deleteIntercom: EventEmitter<{ intercomIdx: number }> = new EventEmitter();

  onEdit(intercomIdx: number) {
    this.editIntercom.next({ intercomIdx });
  }

  onDelete(intercomIdx: number) {
    this.deleteIntercom.next({ intercomIdx });
  }
}
