export interface Protocol {
  id: number;
  number: number;
  sinceStmDriverVersion: string;
  sinceStmVersion: string;
  ipType: boolean;
  rda3SinceRdviVersion: string;
  rda3SinceStmDriverVersion: string;
  intercomIndexesRequired: boolean;
}
