import { createSelector } from '@ngrx/store';
import { SharedModuleStates } from '../../reducers/reducers';
import { sharedModuleSelector } from '../shared-module.state';

export const disconnectDefaultCameraState = createSelector(
    sharedModuleSelector,
    (state: SharedModuleStates) => state.disconnectDefaultCamera
);

export const disconnectDefaultCameraStateSuccess = createSelector(
    disconnectDefaultCameraState,
    state => state.success
);

export const disconnectDefaultCameraStateFailure = createSelector(
    disconnectDefaultCameraState,
    state => state.error
);

export const disconnectDefaultCameraStateLoading = createSelector(
    disconnectDefaultCameraState,
    state => state.loading
);
