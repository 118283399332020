import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import {
  IntercomInfoContainerBody,
  IntercomInfoPopupComponent,
  SnackbarService
} from '@app/shared/components';
import { LoaderService, ResolutionService } from '@app/shared/entities/common';
import { Company, RdaNewResponse, RdaResponse, RdaUpdateRequest, RdaUtilsService } from '@app/shared/entities/rd';
import { parseError } from '@app/shared/helpers';
import { Constants } from '@app/shared/helpers/constants';
import { GetIntercomTypes, GetNewIntercoms, UpdateIntercom } from '@app/shared/store/actions/intercom.actions';
import { getNewIntercomsLoading, getNewIntercomsSuccess, updateIntercomStateFailure, updateIntercomStateLoading, updateIntercomStateSuccess } from '@app/shared/store/states/index';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper';
import { State } from '@app/store/reducers';
import { BatchResponse } from '@app/views/intercom/models';
import { GetAllBatches, GetAllCompanies, GetAllCompaniesClear } from '@app/views/intercom/store/actions';
import { getAllBatchesStateSuccess, getAllCompaniesSuccess } from '@app/views/intercom/store/states';
import { select, Store } from '@ngrx/store';
import { combineLatest, interval, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, skip, takeUntil } from 'rxjs/operators';
import { PrintAdapterUidPopupComponent } from './print-adapter-uid-popup/print-adapter-uid-popup.component';
import {
  GroupDataModificationBottomSheetComponent
} from '@app/views/intercom/components/pages/new-adapters-page/group-data-modification/group-data-modification-bottom-sheet/group-data-modification-bottom-sheet.component';
import { MatDialogRef } from '@angular/material/dialog/dialog-ref';
import {TranslateService} from '@ngx-translate/core';

export interface IGroupModificationWizard<T> {
  title: string;
  batchesList: BatchResponse[];
  companyList: Observable<Company[]>;
  onSubmit: (data: T) => void;
}

@Component({
  selector: 'app-new-adapters-page',
  templateUrl: './new-adapters-page.component.html',
  styleUrls: ['./new-adapters-page.component.scss']
})
export class NewAdaptersPageComponent implements OnInit, OnDestroy {
  readonly displayedColumns: string[] = ['createdAt', 'validation', 'active', 'rdaUid', 'shieldUid', 'shieldNumber', 'batchNumber', 'companyName', 'actions'];
  readonly secondsToUpdate: number = 10;
  readonly barcodeLength: number = 11;
  readonly pageSize: number = 10;

  adapters$: Observable<{ dataSource: MatTableDataSource<RdaNewResponse>, totalCount: number }>;
  companies$: Observable<Company[]>;
  filterInput: UntypedFormControl;
  dataSource: MatTableDataSource<RdaNewResponse>;
  batches: BatchResponse[];
  isAutoupdate: boolean;
  updateAdaptersSub: Subscription;
  curSeconds: number;
  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;
  private onDestroy$: Subject<void>;

  constructor(
    public resolution: ResolutionService,
    private store: Store<State>,
    private dialog: MatDialog,
    private rdaService: RdaUtilsService,
    private snackbar: SnackbarService,
    private loaderService: LoaderService,
    private translate: TranslateService
  ) {
    this.curSeconds = 0;
  }

  ngOnInit() {
    this.initLoading();
    this.initFilterInput();
    this.initPagination();
    this.initStore();
  }

  ngOnDestroy() {
    this.destroyStoreListeners();
  }

  loadAdapters(page: number = 0) {
    this.store.dispatch(new GetNewIntercoms(page, this.pageSize, this.filterInput.value));
  }

  onPrintRdaUid(adapter: RdaNewResponse) {
    if (!adapter.batch.number) {
      this.snackbar.showMessage(
        this.translate.instant('intercom.new.adapters.page.print_rda_uid.message.empty_batch_number')
      );
    } else {
      const data: DialogWrapperData<{ adapter: RdaNewResponse, batch: BatchResponse }, null> = {
        title: this.translate.instant('intercom.new.adapters.page.print_rda_uid.title'),
        componentName: 'PrintRdaUid',
        body: {
          adapter,
          batch: this.batches.find(batch => batch.id === adapter.batch.id)
        }
      };

      this.dialog.open(PrintAdapterUidPopupComponent, {
        panelClass: Constants.CUSTOM_DIALOG_CLASS,
        width: this.resolution.isMobile ? '100%' : '200px',
        data
      });
    }
  }

  onInfo(intercom: RdaResponse) {
    const data: DialogWrapperData<IntercomInfoContainerBody, null> = {
      title: this.translate.instant('intercom.new.adapters.page.info.title'),
      componentName: 'NewRdaInfo',
      body: {
        intercom
      }
    };

    this.dialog.open(IntercomInfoPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '500px',
      data
    });
  }

  showErrorStatus(adapter: RdaNewResponse): boolean {
    if (!adapter) {
      return true;
    }
    return this.rdaService.checkAdapterErrors(adapter) && this.rdaService.ipType(adapter.intercomType) === false;
  }

  prepareNumber(currentNumber: number): string {
    if (currentNumber) {
      let preparedShieldNumber = '';
      for (let i = 0; i < this.barcodeLength - currentNumber.toString().length; ++i) {
        preparedShieldNumber += '0';
      }
      preparedShieldNumber += currentNumber.toString();
      return preparedShieldNumber;
    } else {
      return '';
    }
  }

  onSelectCompany(event: { companyId: number }, rda: RdaNewResponse) {
    const intercom: RdaUpdateRequest = this.initEmptyIntercomRegistrationData();
    intercom.uid = rda.uid;
    intercom.companyId = event.companyId ? event.companyId : null;
    this.store.dispatch(new UpdateIntercom(intercom));
  }

  onSelectBatch(event: { batchId: number }, rda: RdaNewResponse) {
    const intercom: RdaUpdateRequest = this.initEmptyIntercomRegistrationData();
    intercom.uid = rda.uid;
    intercom.batchId = event.batchId ? event.batchId : null;
    this.store.dispatch(new UpdateIntercom(intercom));
  }

  startAutoupdate() {
    this.isAutoupdate = true;

    this.updateAdaptersSub = interval(1000).subscribe(n => {
      this.curSeconds = n % this.secondsToUpdate;
      if (n % this.secondsToUpdate === 0) {
        this.loadAdapters(0);
      }
    });
  }

  stopAutoupdate() {
    this.isAutoupdate = false;
    this.curSeconds = 0;
    this.updateAdaptersSub?.unsubscribe();
  }

  public onGroupDataModification(): void {
    let dialogRef: MatDialogRef<
      GroupDataModificationBottomSheetComponent,
      IGroupModificationWizard<Observable<boolean>>
      > = null;

    const dialogWizard: IGroupModificationWizard<Observable<boolean>> = {
      title: this.translate.instant('intercom.new.adapters.page.group_data_modification.title'),
      companyList: this.companies$,
      batchesList: this.batches,
      onSubmit: (data) => {
        dialogRef.close();
        data.pipe(takeUntil(this.onDestroy$)).subscribe(res => {
          this.loadAdapters(0);
        });
      },
    };

    dialogRef = this.dialog.open(GroupDataModificationBottomSheetComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data: dialogWizard
    });
  }

  private initEmptyIntercomRegistrationData(): RdaUpdateRequest {
    return {
      id: null,
      uid: null,
      intercomTypeId: null,
      translation: null,
      address: null,
      entrance: null,
      range: null,
      cameraId: null,
      companyId: null,
      speakerVolume: null,
      micSensitivity: null,
      batchNumber: null,
      shieldId: null
    };
  }

  private initStore() {
    this.onDestroy$ = new Subject();
    this.initStoreDataSelectors();
    this.initStoreListeners();
    this.getStoreData();
  }

  private initStoreDataSelectors() {
    this.store.pipe(select(getAllBatchesStateSuccess), takeUntil(this.onDestroy$))
      .subscribe(data => {
        if (data?.length > 0) {
          this.batches = data;
        }
      });
    this.companies$ = this.store.select(getAllCompaniesSuccess);

    this.adapters$ = this.store.pipe(
      select(getNewIntercomsSuccess),
      map(data => {
        if (data.newAdapters && data.totalCount > 0) {
          return ({ dataSource: new MatTableDataSource(data.newAdapters), totalCount: data.totalCount });
        }
      })
    );
  }

  private initStoreListeners() {
    this.store.pipe(select(updateIntercomStateSuccess), takeUntil(this.onDestroy$), skip(1))
      .subscribe(resp => resp && this.snackbar.showMessage(
        this.translate.instant('intercom.new.adapters.page.update.intercom.message.success'),
        'success'
      ));

    this.store.pipe(select(updateIntercomStateFailure), takeUntil(this.onDestroy$), skip(1))
      .subscribe(error => error && this.snackbar.showMessage(
        this.translate.instant('intercom.new.adapters.page.update.intercom.message.failed', {
          text: parseError(error)
        })
      ));
  }

  private getStoreData() {
    this.store.dispatch(new GetAllBatches());
    this.store.dispatch(new GetAllCompanies());
    this.store.dispatch(new GetIntercomTypes());
    this.loadAdapters(0);
  }

  private destroyStoreListeners() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.store.dispatch(new GetAllCompaniesClear());
  }

  private initFilterInput() {
    this.filterInput = new UntypedFormControl();
    this.filterInput.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.paginator.pageIndex = 0;
        this.loadAdapters(this.paginator.pageIndex);
      });
  }

  private initPagination() {
    this.paginator.page.subscribe((e: PageEvent) => this.loadAdapters(e.pageIndex));
  }

  private initLoading() {
    combineLatest([
      this.store.pipe(select(getNewIntercomsLoading)),
      this.store.pipe(select(updateIntercomStateLoading))
    ])
      .subscribe((values: boolean[]) => this.loaderService.loaderState = { state: values.some((x: boolean) => x) });
  }
}
