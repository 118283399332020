import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { BreadcrumbService } from '@app/core/services';
import { Camera, CameraApiService, ServiceApiService } from '@app/shared/entities/rd';

@Injectable()
export class EntrancePageHelper {
    constructor(
        private cameraApiService: CameraApiService,
        private serviceApiService: ServiceApiService,
        private breadcrumbService: BreadcrumbService,
    ) { }

    updateTitle(entranceId: number, address: string) {
        this.breadcrumbService.title = address;
    }

    getEntranceIdFromActivatedRoute(snapshot: ActivatedRouteSnapshot): number {
        const entranceIdMatches: string[] = snapshot.params['id'].match(/([\d]+)/);

        if (entranceIdMatches === null) {
            return -1;
        }

        return Number.parseInt(entranceIdMatches[0], 10);
    }

    async getCameras(entranceId: number, serviceId: number): Promise<Camera[]> {
        let defaultCameras: Camera[] = [];

        if (serviceId) {
            defaultCameras = await this.serviceApiService.getCameras(serviceId).toPromise();
            defaultCameras.forEach(camera => camera.serviceId = serviceId);
        }

        const allCameras = await this.cameraApiService.getCamerasList(entranceId).toPromise();
        const defaultCamerasIds = defaultCameras.map(camera => camera.id);

        return defaultCameras.concat(allCameras.filter(camera => defaultCamerasIds.indexOf(camera.id) === -1));
    }
}
