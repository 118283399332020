<div class="popup camera-edit">
  <form
    class="camera-edit-content"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
  >
    <h3 class="camera-edit-content__title">
      {{ 'shared.camera.edit.content.message.indicate_camera_parameters' | translate }}
    </h3>

    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'shared.camera.edit.content.field.address' | translate }}
      </mat-label>
      <mat-select (selectionChange)="onSelectAddress($event.value)" formControlName="entrance">
        <mat-option *ngFor="let address of addresses" [value]="address.entrance.id">
          {{ address | address }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'shared.camera.edit.content.field.uri' | translate }}
      </mat-label>
      <input
        appCameraUriValidation
        id="cameraURI"
        matInput
        type="url"
        placeholder="{{ 'shared.camera.edit.content.placeholder.uri' | translate }}"
        formControlName="uri"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        required
      />
      <mat-error>
        {{ 'shared.camera.edit.content.error.uri' | translate }}
      </mat-error>
    </mat-form-field>

    <div class="camera-edit-content-credentials">
      <div class="camera-edit-content-credentials-inputs">
        <mat-form-field appearance="outline" class="none-padding">
          <mat-label>
            {{ 'shared.camera.edit.content.field.login' | translate }}
          </mat-label>
          <input
            type="text"
            matInput
            placeholder="{{ 'shared.camera.edit.content.placeholder.login' | translate }}"
            formControlName="user"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="none-padding">
          <mat-label>
            {{ 'shared.camera.edit.content.field.password' | translate }}
          </mat-label>
          <input
            type="text"
            matInput
            placeholder="{{ 'shared.camera.edit.content.placeholder.password' | translate }}"
            formControlName="password"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
        </mat-form-field>
      </div>

      <p class="camera-edit-content-credentials__hint">
        {{ 'shared.camera.edit.content.hint.credentials' | translate }}
      </p>
    </div>

    <!-- RDFT-685
    <div class="camera-edit-content-credentials">
      <div class="camera-edit-content-credentials-inputs">
        <mat-form-field appearance="outline" class="none-padding">
          <mat-label>ONVIF Порт</mat-label>
          <input
            type="text"
            matInput
            placeholder="80"
            formControlName="onvifPort"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
        </mat-form-field>
        <div class="onvif-wrapper" *ngIf="enableOnvif">
          <button
            [disabled]="!onvif"
            (click)="onSubmitOnvif()"
            mat-raised-button
            color="primary"
            class="onvif-wrapper__button"
          >
            Автонастроить камеру
          </button>
        </div>
    </div>
    </div>
    <p *ngIf="enableOnvif" class="camera-edit-content-credentials__hint">
      <span class="{{ onvif ? '' : 'error-ic' }}">
        {{ onvif ? 'ONVIF соединение установлено и готово к автонастройке камеры' : 'ONVIF соединение не установлено, проверьте порт, логин и пароль' }}
      </span>
    </p>
    -->

    <mat-form-field appearance="outline" class="selected-keys relative-size" *ngIf="!isVideoService">
      <mat-label>
        {{ 'shared.camera.edit.content.field.selected_key' | translate }}
      </mat-label>
      <mat-select
        placeholder="{{ 'shared.camera.edit.content.placeholder.selected_key' | translate }}"
        formControlName="selectedKey"
        panelClass="mat-select-custom"
      >
        <mat-select-trigger>
          <div class="camera-edit-content-selector-preview">
            <ng-container *ngIf="form.get('selectedKey').value then hasKay else withoutKey"></ng-container>
            <ng-template #withoutKey></ng-template>
            <ng-template #hasKay>
              <div class="camera-edit-content-selector-preview">
                <img
                  class="camera-edit-content-selector-preview__icon"
                  [src]="intercomKeyRecords[form.get('selectedKey').value.type]?.icon"
                />
                <p class="camera-edit-content-selector-preview__text">
                  {{
                    form.get('selectedKey').value.location ?
                      form.get('selectedKey').value.location?.name :
                      intercomKeyRecords[form.get('selectedKey').value.type].name | translate
                  }}
                </p>
              </div>
            </ng-template>
          </div>
        </mat-select-trigger>

        <mat-option [value]="null">--</mat-option>
        <mat-option
          [value]="key"
          *ngFor="let key of keys"
        >
          <div class="camera-edit-content-selector">
            <img
              class="camera-edit-content-selector__icon"
              [src]="intercomKeyRecords[key.type].icon"
            />
            <p class="camera-edit-content-selector__text">
              <span>{{  key.location ? key.location?.name : intercomKeyRecords[key.type].name | translate }}</span>
            </p>
          </div>
        </mat-option>
      </mat-select>
    </mat-form-field>

    <app-select-search
      [title]="('shared.camera.edit.content.field.location_id' | translate) + ' ' +
        (locationRequired ? '' : ('shared.camera.edit.content.hint.optional' | translate))"
      [valuesList]="locationsMapped$ | async"
      [selectedValue]="selectedLocation"
      [isFilterActive]="true"
      [isSortedActive]="true"
      [showEmptyOption]="true"
      (valueSelected)="onLocationSelected($event)"
    ></app-select-search>

    <div class="camera-edit-content-audio">
      <div class="camera-edit-content-audio-slider-block">
        <mat-slide-toggle
          class="camera-edit-content-audio-slider-block__slider"
          formControlName="audio"
        ></mat-slide-toggle>
        <div class="camera-edit-content-audio-slider-block-description">
          <p class="camera-edit-content-audio-slider-block-description__text">
            {{ 'shared.camera.edit.content.message.sound_recording_and_broadcast' | translate }}
          </p>
          <p  class="camera-edit-content-audio-slider-block-description__hint">
            {{ 'shared.camera.edit.content.message.check_sound_is_activated_in_equipment_settings' | translate }}
          </p>
        </div>
      </div>
    </div>

    <div class="camera-edit-content-private">
      <div class="camera-edit-content-private-slider-block">
        <mat-slide-toggle
          class="camera-edit-content-private-slider-block__slider"
          formControlName="isPrivate"
          (change)="onChangePrivateState()"
        ></mat-slide-toggle>

        <div class="camera-edit-content-private-slider-block-description">
          <p class="camera-edit-content-private-slider-block-description__text">
            {{ 'shared.camera.edit.content.message.private_camera' | translate }}
            {{ rdaSelectSearch?.value && form.get('isPrivate').value ? ('shared.camera.edit.content.rda' | translate:{ value: rdaSelectSearch.value }) : '' }}
          </p>

          <p class="camera-edit-content-private-slider-block-description__hint">
            {{ 'shared.camera.edit.content.message.turn_on_if_camera_has_dynamic_ip' | translate }}
          </p>
        </div>
      </div>

      <div class="camera-edit-content-private-intercom-block" [hidden]="!form.get('isPrivate').value">
        <app-rda-search-select
          [initialIntercoms]="intercomSearchDisabled || rdaSelectSearch ? rdas : []"
          [disabled]="intercomSearchDisabled || loading"
          [initialIntercomSearchSelect]="rdaSelectSearch"
          [withoutDescription]="true"
          [params]="{ ipType: false, inactive: null }"
          (rdaSelected)="onRdaSelected($event.select)"
        ></app-rda-search-select>
        <p class="camera-edit-content-private-intercom-block__hint">
          {{ 'shared.camera.edit.content.message.select_adapter_through_which_chamber_traffic_will_be_transmitted' | translate }}
        </p>
      </div>
    </div>


    <div class="camera-edit-actions">
      <button
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="loading"
        class="camera-edit-actions__button"
      >
        {{ 'shared.camera.edit.content.button.submit' | translate }}
      </button>
    </div>
  </form>

  <div class="camera-edit-info" *ngIf="camera">
    <div class="camera-edit-info-status">
      <div
        class="camera-edit-info-status__indicator"
        [class.camera-edit-info-status__indicator--online]="camera.active"
        [class.camera-edit-info-status__indicator--offline]="!camera.active"
      ></div>

      <p
        class="camera-edit-info-status__text"
        [class.camera-edit-info-status__text--online]="camera.active"
        [class.camera-edit-info-status__text--offline]="!camera.active"
      >
        {{ 'shared.camera.edit.content.field.camera' | translate }}
        {{
          camera.active
            ? ('shared.camera.edit.content.enum.status.online' | translate | lowercase)
            : ('shared.camera.edit.content.enum.status.offline' | translate | lowercase)
        }}
      </p>
    </div>

    <app-camera-info-container [camera]="camera"></app-camera-info-container>

    <div class="preview">
      <app-camera-thumbnail [camera]="camera" [mode]="videoMode"></app-camera-thumbnail>
    </div>

  </div>
</div>
