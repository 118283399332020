import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

export enum FileExtension {
  jpg = 'jpg',
  png = 'png',
  pdf = 'pdf',
  gif = 'gif',
  jpeg = 'jpeg',
  docx = 'docx',
  xlsx = 'xlsx',
  xls = 'xls',
  mp4 = 'mp4',
  mp3 = 'mp3',
  txt = 'txt',
}

export interface IFileConfigDetector {
  extension: string;
  icon: string;
  name: string;
}

const DEFAULT_VALUE: IFileConfigDetector = {
  extension: null,
  icon: null,
  name: null,
};

const DOT_LENGTH = 1;

@Pipe({
  name: 'fileTypeDetector',
})
export class FileTypeDetectorPipe implements PipeTransform {
  private extensionComparator: { [key: string]: string } = {
    jpg: this.translate.instant('shared.upload.document.types.img'),
    png: this.translate.instant('shared.upload.document.types.img'),
    pdf: this.translate.instant('shared.upload.document.types.doc'),
    docx: this.translate.instant('shared.upload.document.types.doc'),
    xlsx: this.translate.instant('shared.upload.document.types.doc'),
    gif: this.translate.instant('shared.upload.document.types.img'),
    jpeg: this.translate.instant('shared.upload.document.types.img'),
    mp4: this.translate.instant('shared.upload.document.types.video'),
    mp3: this.translate.instant('shared.upload.document.types.video'),
  };

  constructor(private translate: TranslateService) {}

  public transform(fileName: string): IFileConfigDetector {
    const config: IFileConfigDetector = { ...DEFAULT_VALUE };
    const fileExtension = fileName.slice(fileName.indexOf('.') + DOT_LENGTH) as FileExtension;
    config.extension = this.getFileExtension(fileExtension);
    config.icon = this.getIconName(fileExtension);

    return {
      ...config,
      name: fileName,
    };
  }

  private getFileExtension(fileExtension: FileExtension): string {
    const extension = this.extensionComparator[fileExtension];
    return extension ? extension : this.translate.instant('shared.upload.document.types.doc');
  }

  private getIconName(fileExtension: FileExtension): string {
    switch (fileExtension.toLowerCase()) {
      case FileExtension.mp4:
      case FileExtension.mp3:
        return 'assets/icons/ic-hd-label.svg';
      case FileExtension.jpg:
      case FileExtension.png:
      case FileExtension.jpeg:
      case FileExtension.gif:
        return 'assets/icons/ic-img-type.svg';
      case FileExtension.pdf:
      case FileExtension.docx:
        return 'assets/icons/ic-pdf-type.svg';
      case FileExtension.xlsx:
      case FileExtension.xls:
        return 'assets/icons/ic-xls-type.png';
      default:
        return 'assets/icons/ic-pdf-type.svg';
    }
  }
}
