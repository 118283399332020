import { HttpErrorResponse } from '@angular/common/http';
import { MailingResponse } from '@app/views/abonents/models';
import { Actions, ActionTypes } from '../actions/mailing.actions';

export interface MailingState {
  mailings: MailingResponse[];
  totalCount: number;
  sendedMailing: MailingResponse;
  error: HttpErrorResponse;
  mailingsLoading: boolean;
  sendMailingLoading: boolean;
}

export const mailingInitialState: MailingState = {
  mailings: [],
  totalCount: null,
  sendedMailing: null,
  error: null,
  mailingsLoading: false,
  sendMailingLoading: false
};

export function mailingReducer(state = mailingInitialState, action: Actions): MailingState {
  switch (action.type) {
    case ActionTypes.GetMailings: {
      return {
        ...state,
        error: null,
        totalCount: null,
        mailingsLoading: true
      };
    }

    case ActionTypes.SendMailing: {
      return {
        ...state,
        error: null,
        sendMailingLoading: true
      };
    }

    case ActionTypes.GetMailingsSuccess: {
      return {
        ...state,
        mailings: state.mailings.concat(action.payload),
        totalCount: action.totalCount,
        error: null,
        mailingsLoading: false
      };
    }

    case ActionTypes.SendMailingSuccess: {
      state.mailings.unshift(action.mailing);
      state.mailings.pop();
      state.totalCount++;

      return {
        ...state,
        sendedMailing: action.mailing,
        error: null,
        sendMailingLoading: false
      };
    }

    case ActionTypes.SendMailingFailure: {
      return {
        ...state,
        error: action.payload,
        sendMailingLoading: false
      };
    }

    case ActionTypes.GetMailingsFailure: {
      return {
        ...state,
        error: action.payload,
        mailingsLoading: false
      };
    }

    case ActionTypes.SendMailingClear: {
      return {
        ...state,
        sendedMailing: null,
        sendMailingLoading: null,
        error: null
      };
    }

    case ActionTypes.GetMailingsClear: {
      return {
        ...state,
        mailings: [],
        totalCount: null,
        error: null,
        mailingsLoading: null
      };
    }

    default: {
      return state;
    }
  }
}
