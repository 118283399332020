import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-button-with-spinner-loader',
  templateUrl: './button-with-spinner-loader.component.html',
  styleUrls: ['./button-with-spinner-loader.component.scss']
})
export class ButtonWithSpinnerLoaderComponent {
  @Input() color = 'primary-inverse';
  @Input() spinnerSize = 25;
  @Input() showSpinner = false;
  @Output() clickEvent: EventEmitter<void> = new EventEmitter();
}
