import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LogsResponse } from '@app/shared/models';
import { GraylogResponse } from '../models';
import { GraylogApiService, IGraylogQuery } from './graylog-api.service';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class GraylogPhoneCallsApiService extends GraylogApiService {
    constructor(
        httpClient: HttpClient,
        humanErrorTranslationService: HumanErrorTranslationService
    ) {
        super(httpClient, humanErrorTranslationService);
    }

    protected prepareQuery(sourceId: string): IGraylogQuery {
      return {
        rdaUid: sourceId
      };
    }

    protected prepareParsedResponse(graylogMessages: GraylogResponse): LogsResponse[] {
        console.warn('Method prepareParsedResponse not implemented');
        return null;
    }
}
