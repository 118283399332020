import { Injectable, OnDestroy } from '@angular/core';
import { SnackbarService } from '@app/shared/components';
import { GatesActionTypes } from '@app/views';
import { Actions, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class GateWizardContentIntercomTypeService implements OnDestroy {
    private onDestroy$: Subject<void> = new Subject();

    constructor(
        private actions$: Actions
    ) { }

    addIntercomSelectedListener(cb: () => void) {
        this.actions$
            .pipe(
                ofType(GatesActionTypes.SetGateIntercomType),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => cb());
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
