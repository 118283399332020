import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PermissionsTreeKey, TokenService } from '@app/security';
import { CompanyReportPopupComponent, LicenseeReportPopupComponent, PenetrationReportPopupComponent, SignUpsReportPopupComponent } from '@app/shared/components';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { CompanyReportRequest, LicenseeReportRequest, PenetrationReportRequest, SignUpsReportRequest } from '@app/shared/entities/rd';
import { Constants } from '@app/shared/helpers';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper';
import { DashboardFacade } from '@app/views/dashboard/store';
import { DashboardReportsHelperService } from './dashboard-reports-helper.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard-reports',
  templateUrl: './dashboard-reports.component.html',
  styleUrls: ['./dashboard-reports.component.scss'],
  providers: [DashboardReportsHelperService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardReportsComponent implements OnInit {
  readonly permissionsTreeKey: typeof PermissionsTreeKey = PermissionsTreeKey;

  rosDomofonCompany: boolean;
  offerLicenseAgreement: boolean;

  constructor(
    public resolution: ResolutionService,
    private dialog: MatDialog,
    private tokenService: TokenService,
    private dashboardFacade: DashboardFacade,
    private dashboardReportsHelperService: DashboardReportsHelperService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.offerLicenseAgreement = this.dashboardReportsHelperService.offerLicenseAgreement();
    this.rosDomofonCompany = this.dashboardReportsHelperService.rosDomofonCompany();
  }

  onPenetrationReport() {
    const data: DialogWrapperData<{ companyId: number }, PenetrationReportRequest> = {
      title: this.translate.instant('dashboard.reports.penetration.title'),
      componentName: 'GetPenetrationReport',
      body: { companyId: this.tokenService.getCompanyId() },
      submit: (request: PenetrationReportRequest) =>
        this.dashboardFacade.getPenetrationReport(request)
    };

    this.dialog.open(PenetrationReportPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  onLicenseeReport() {
    const data: DialogWrapperData<null, LicenseeReportRequest> = {
      title: this.translate.instant('dashboard.reports.licensee.title'),
      componentName: 'GetLicenseeReport',
      submit: (request: LicenseeReportRequest) =>
        this.dashboardFacade.getLicenseeReport(request)
    };

    this.dialog.open(LicenseeReportPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  onUsageStatisticsReport() {
    const data: DialogWrapperData<null, LicenseeReportRequest> = {
      title: this.translate.instant('dashboard.reports.statistics.title'),
      componentName: 'GetUsageStatisticsReport',
      submit: (request: LicenseeReportRequest) =>
        this.dashboardFacade.getUsageStatisticsReport(request)
    };

    this.dialog.open(LicenseeReportPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  onCompanyReport() {
    const data: DialogWrapperData<null, CompanyReportRequest> = {
      title: this.translate.instant('dashboard.reports.company.title'),
      componentName: 'GetCompanyReport',
      submit: (request: CompanyReportRequest) =>
        this.dashboardFacade.getCompanyReport(request)
    };

    this.dialog.open(CompanyReportPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  onCompanyPeriodicReport() {
    const data: DialogWrapperData<{}, CompanyReportRequest> = {
      title: this.translate.instant('dashboard.reports.company_periodic.title'),
      componentName: 'GetCompanyPeriodicReport',
      body: { startReportDateEnabled: true },
      submit: (request: CompanyReportRequest) =>
        this.dashboardFacade.getCompanyPeriodicReport(request)
    };

    this.dialog.open(CompanyReportPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  onSignUpsReport() {
    const data: DialogWrapperData<null, SignUpsReportRequest> = {
      title: this.translate.instant('dashboard.reports.signups.title'),
      componentName: 'GetSignUpsReport',
      submit: (request: SignUpsReportRequest) =>
        this.dashboardFacade.getSignUpsReport(request)
    };

    this.dialog.open(SignUpsReportPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }
}
