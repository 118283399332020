import { Injectable, OnDestroy } from '@angular/core';
import { DialogWrapperStateHelper } from '@app/shared/ui';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { AddServiceKey, AddServiceKeyFailure, AddServiceKeySuccess, DeleteServiceKey, DeleteServiceKeyFailure, DeleteServiceKeySuccess, ServicesActionTypes, UpdateServiceKey, UpdateServiceKeyFailure, UpdateServiceKeySuccess } from './../../../../store';

@Injectable()
export class ServiceIntercomKeyPopupService extends DialogWrapperStateHelper<'loading' | 'close' | 'loaded'> implements OnDestroy {
    constructor(
        private actions$: Actions
    ) {
        super();
        this.enableStateChangeListener();
    }

    ngOnDestroy() {
        this.destroy();
    }

    private enableStateChangeListener() {
        this.actions$
            .pipe(
                ofType<
                    | AddServiceKey
                    | UpdateServiceKey
                    | DeleteServiceKey
                >(...[
                    ServicesActionTypes.AddServiceKey,
                    ServicesActionTypes.UpdateServiceKey,
                    ServicesActionTypes.DeleteServiceKey
                ]), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loading');

        this.actions$
            .pipe(
                ofType<AddServiceKeySuccess>(ServicesActionTypes.AddServiceKeySuccess), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'close');

        this.actions$
            .pipe(
                ofType<
                    | AddServiceKeyFailure
                    | UpdateServiceKeySuccess
                    | UpdateServiceKeyFailure
                    | DeleteServiceKeySuccess
                    | DeleteServiceKeyFailure
                >(...[
                    ServicesActionTypes.AddServiceKeyFailure,
                    ServicesActionTypes.UpdateServiceKeySuccess,
                    ServicesActionTypes.UpdateServiceKeyFailure,
                    ServicesActionTypes.DeleteServiceKeySuccess,
                    ServicesActionTypes.DeleteServiceKeyFailure
                ]), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loaded');
    }
}
