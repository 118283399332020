import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IntercomsListContentModel, SnackbarService } from '@app/shared/components';
import { RdaResponse } from '@app/shared/entities/rd';
import { Address } from '@app/shared/models';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-gate-service-entrances-intercoms',
  templateUrl: './gate-service-entrances-intercoms.component.html',
  styleUrls: ['./gate-service-entrances-intercoms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GateServiceEntrancesIntercomsComponent {
  @Input() connectedIntercoms: RdaResponse[];
  @Input() avaliableIntercoms: Address[];
  @Input() loading: boolean;
  selectedIntercoms: IntercomsListContentModel[];

  @Output() private editIntercom: EventEmitter<{ intercomIdx: number }> = new EventEmitter();
  @Output() private deleteIntercom: EventEmitter<{ intercomIdx: number }> = new EventEmitter();
  @Output() private submitIntercoms: EventEmitter<{ intercomsIds: number[] }> = new EventEmitter();
  private _index: number;

  constructor(
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) { }

  get index(): number {
    return this._index;
  }

  onIntercomsSelected(intercoms: IntercomsListContentModel[]) {
    this.selectedIntercoms = intercoms;
  }

  onEdit(intercomIdx: number) {
    this.editIntercom.emit({ intercomIdx });
  }

  onDelete(intercomIdx: number) {
    this.deleteIntercom.emit({ intercomIdx });
  }

  onSubmit() {
    if (!this.selectedIntercoms.length) {
      this.snackbar.showMessage(
        this.translate.instant('services.gates.gate.entrances.intercoms.message.submit.failed'),
        'info'
      );
      return;
    }

    this.submitIntercoms.emit({
      intercomsIds: this.selectedIntercoms.map(intercom => intercom.id)
    });
  }
}
