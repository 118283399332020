import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ServiceEditContentModule } from '@app/shared/components';
import { DialogWrapperModule } from '@app/shared/ui';
import { ServiceEditPopupComponent } from './service-edit-popup.component';

@NgModule({
    imports: [
        CommonModule,
        DialogWrapperModule,
        ServiceEditContentModule,
    ],
    declarations: [
        ServiceEditPopupComponent
    ],
    exports: [
        ServiceEditPopupComponent
    ]
})
export class ServiceEditPopupModule { }
