import { IntercomType, RdaResponse } from '@app/shared/entities/rd';
import * as root from '@app/store/reducers';
import { GateMode, GatesPageModel } from '../models';
import { PageData } from '@app/shared/models';

export interface GatesModuleState {
    gates: GatesState;
}

export interface GatesRootState extends root.State {
    'gates': GatesModuleState;
}

export interface GatesState {
    gates: GatesPageModel[];
    intercomType: IntercomType;
    loading: boolean;
    pageData: PageData;

    entrancesIntercoms: RdaResponse[];
    intercom: RdaResponse;
    index: string;
    mode: GateMode;
}

export const gatesPageInitialState: GatesState = {
    gates: undefined,
    intercomType: undefined,
    loading: undefined,
    pageData: undefined,

    entrancesIntercoms: undefined,
    intercom: undefined,
    index: undefined,
    mode: undefined
};
