<form [formGroup]="form" #formDirective="ngForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'intercom.models.page.form.field.name' | translate }}
    </mat-label>
    <input
      type="text"
      matInput
      placeholder="{{ 'intercom.models.page.form.placeholder.name' | translate }}"
      formControlName="name"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      required
    />
    <mat-error>
      {{ 'intercom.models.page.form.error.name' | translate }}
    </mat-error>
  </mat-form-field>

  <div class="action-block">
    <button
      type="submit"
      mat-raised-button
      color="primary"
    >
      {{ 'intercom.models.page.form.button.submit' | translate }}
    </button>
  </div>
</form>
