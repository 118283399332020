import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';

import { FlatCardComponent } from './flat-card.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule
    ],
    declarations: [FlatCardComponent],
    exports: [FlatCardComponent]
})
export class FlatCardModule { }
