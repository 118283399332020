import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { BottomSheetWrapperComponent } from './bottom-sheet-wrapper.component';
import { ComponentLoaderModule } from '@app/shared/ui';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ComponentLoaderModule
    ],
    declarations: [BottomSheetWrapperComponent],
    exports: [BottomSheetWrapperComponent]
})
export class BottomSheetWrapperModule { }
