import {
  ChangeDetectorRef,
  Component,
  ElementRef, EventEmitter, Input,
  OnDestroy, OnInit, Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import { Address, AddressInfo } from '@app/shared/models';
import { AddressFormatter } from '@app/shared/services';

export interface IBrazilAddress {
  address: AddressInfo;
  entrance: string;
}

const BASE_ZOOM = 13;
const BASE_LAT = -22.910559417635874;
const BASE_LNG = -43.1974489994911;
const ADDRESS_DEFAULT: IBrazilAddress = {
  address: {
    country: {
      shortName: 'BR',
      name: 'Brazil',
      currency: 'BRL',
    },
    city: null,
    street: null,
    house: null,
    building: null,
    housing: null,
    block: null,
    fiasCode: null,
    kladrCode: null,
    universalCode: null,
  },
  entrance: null,
};

enum MODE {
  EDIT,
  CREATE
}

enum SELECT_ADDRESS_MODE {
  CLICK,
  ENTER,
}

@Component({
  selector: 'app-google-map-form',
  templateUrl: './google-map-form.component.html',
  styleUrls: ['./google-map-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GoogleMapFormComponent implements OnInit, OnDestroy {
  public readonly MODE = MODE;
  public readonly AddressFormatter = AddressFormatter;

  @ViewChild('search') searchElementRef: ElementRef;
  @ViewChild('googleMap') googleMap: GoogleMap;

  @Input() set address(address: Address) {
    this.mode = address ? MODE.EDIT : MODE.CREATE;
    this._address = address;
    if (address) {
      this.complemento.setValue(address.house.block);
      this.entrance.setValue(address.entrance.number);
    }
  }
  public complemento: FormControl<string> = new FormControl<string>('', []);
  public entrance: FormControl<string> = new FormControl<string>('', []);

  public options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: false,
    center: { lat: BASE_LAT, lng: BASE_LNG },
    disableDoubleClickZoom: true,
    disableDefaultUI: true,
    maxZoom: 20,
    minZoom: 1,
  };
  public zoom: number = BASE_ZOOM;
  public marker: google.maps.LatLngLiteral | google.maps.LatLng = {
    lat: BASE_LAT,
    lng: BASE_LNG,
  };
  public mode: number = null;

  private initializerSubject$: Subject<void> = new Subject<void>();
  private unSubscribe$: Subject<void> = new Subject<void>();
  private autocompleteService: google.maps.places.AutocompleteService = null;
  private addressRequest: IBrazilAddress = ADDRESS_DEFAULT;
  private _address: Address;

  @Output() addressChange: EventEmitter<IBrazilAddress> = new EventEmitter<IBrazilAddress>();

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public addressFormatter: AddressFormatter
  ) {
    this.autocompleteService = new google.maps.places.AutocompleteService();
  }

  get address(): Address {
    return this._address;
  }

  ngOnInit(): void {
    this.complemento.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((value) => {
      this.addressRequest.address.block = value;
      this.addressChange.emit(this.addressRequest);
    });
    this.entrance.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((value) => {
      this.addressRequest.entrance = value;
      this.addressChange.emit(this.addressRequest);
    });

    this.initializerSubject$.pipe(debounceTime(1000)).subscribe(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement
      );
      this.googleMap.controls.push(
        this.searchElementRef.nativeElement
      );
      autocomplete.addListener('place_changed', () => {
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }
        this.marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.options = {
          ...this.options,
          center: {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          },
        };
        this.changeDetectorRef.detectChanges();
        console.log('__', place);
        this.setUpRequestObject(place, SELECT_ADDRESS_MODE.ENTER);
      });
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public zoomIn($event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
    if (this.options.maxZoom > this.zoom) {
      this.zoom += 1;
    }
  }

  public zoomOut($event: MouseEvent): void {
    $event.stopPropagation();
    $event.preventDefault();
    if (this.options.minZoom < this.zoom) {
      this.zoom -= 1;
    }
  }

  public onMapClick($event: google.maps.MapMouseEvent | google.maps.IconMouseEvent): void {
    this.marker = {
      lat: $event.latLng.lat(),
      lng: $event.latLng.lng(),
    };
    this.getAddress(this.marker.lat, this.marker.lng).then((res => {
      this.searchElementRef.nativeElement.value = res;
      const options = {
        input: res,
        types: ['geocode']
      };

      this.autocompleteService.getPlacePredictions(options, (predictions: google.maps.places.AutocompletePrediction[], status: google.maps.places.PlacesServiceStatus) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && predictions) {
          this.setUpRequestObject(predictions[0], SELECT_ADDRESS_MODE.CLICK);
        }
      });
    }));
  }

  public initMapEvents(): void {
    this.initializerSubject$.next();
  }

  public getAddress(lat: number, lng: number): Promise<string> {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      const latlng = { lat, lng };

      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status === 'OK') {
          if (results[0]) {
            resolve(results[0].formatted_address);
          } else {
            reject('No results found');
          }
        } else {
          reject('Geocoder failed due to: ' + status);
        }
      });
    });
  }

  private setUpRequestObject(place: google.maps.places.AutocompletePrediction | google.maps.places.PlaceResult, mode: SELECT_ADDRESS_MODE): void {
    if (mode === SELECT_ADDRESS_MODE.CLICK) {
      /*
        [0] - улица
        [1] - номер дома
        [2] - название района
        [3] - город
        [4] - сокращение города
        [5] - почтовый индекс
        [6] - Страна
       */
      const mapResult = place as google.maps.places.AutocompletePrediction;
      this.addressRequest.address.rawAddress = mapResult.description;
      this.addressRequest.address.street = mapResult.terms[0].value;
      this.addressRequest.address.house = mapResult.terms[1].value;
      this.addressRequest.address.city = mapResult.terms[3].value;
    }
    if (mode === SELECT_ADDRESS_MODE.ENTER) {
      /*
        [0] - номер дома
        [1] - название Улицы
        [2] - название района
        [3] - название города
        [4] - название штата
        [5] - название страны
        [6] - почтовый индекс
      */
      const mapResult = place as google.maps.places.PlaceResult;
      this.addressRequest.address.rawAddress = mapResult.formatted_address;
      this.addressRequest.address.street = mapResult.address_components[1].long_name;
      this.addressRequest.address.house = mapResult.address_components[0].long_name;
      this.addressRequest.address.city = mapResult.address_components[3].long_name;
    }

    this.addressChange.emit(this.addressRequest);
  }
}
