import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ScheduleForPaymentComponent
} from '@app/shared/components/payments/schedule-for-payment/schedule-for-payment.component';
import {
  PossibleCronSolutionsComponent
} from '@app/shared/components/payments/possible-cron-solutions/possible-cron-solutions.component';
import { PaymentInstructionPipe } from './schedule-for-payment/payment-instruction.pipe';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
  declarations: [ScheduleForPaymentComponent, PossibleCronSolutionsComponent, PaymentInstructionPipe],
  exports: [
    PossibleCronSolutionsComponent,
    ScheduleForPaymentComponent
  ],
    imports: [
        CommonModule,
        NgxTranslateModule
    ]
})
export class PaymentsToolsModule { }
