import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Camera, IntercomPanelResponse, KeysResponse } from '@app/shared/entities/rd';
import { LocationResponse } from '@app/shared/models';
import { DialogWrapperData } from '@app/shared/ui';
import { ServiceCameraPopupBody, ServiceCameraPopupComponent, ServiceCameraPopupService } from '@app/views/services/components';
import { ServiceFacade } from '@app/views/services/store';
import { Observable } from 'rxjs';
import {
  CameraAlreadyExistsPopupService
} from '@app/shared/components/camera/camera-already-exists/service-camera-already-exists-popup/camera-already-exists-popup.service';

@Component({
  selector: 'app-software-intercom-camera-popup',
  templateUrl: './software-intercom-camera-popup.component.html',
  styleUrls: ['./software-intercom-camera-popup.component.scss'],
  providers: [ServiceCameraPopupService, CameraAlreadyExistsPopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomCameraPopupComponent extends ServiceCameraPopupComponent {
  avaliableIntercomPanelsLocations$: Observable<LocationResponse[]> = this.serviceFacade.avaliableIntercomPanelsLocations$;
  camerasLocations$: Observable<LocationResponse[]> = this.serviceFacade.camerasLocations$;
  intercomPanels$: Observable<IntercomPanelResponse[]> = this.serviceFacade.intercomPanels$;
  onvif$: Observable<boolean> = this.serviceFacade.isActiveOnvif$;
  keys$: Observable<KeysResponse[]> = this.serviceFacade.keys$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<ServiceCameraPopupBody, { camera: Camera, intercomPanelId?: number }>,
    popupService: ServiceCameraPopupService,
    serviceFacade: ServiceFacade,
    dialogRef: MatDialogRef<ServiceCameraPopupComponent>,
    cameraAlreadyExistsPopupService: CameraAlreadyExistsPopupService
  ) {
    super(data, popupService, serviceFacade, dialogRef, cameraAlreadyExistsPopupService);
  }

  onSubmit(camera: Camera, intercomPanelId?: number) {
    this.data.submit({ camera, intercomPanelId });
  }

  onSubmitOnvif(camera: Camera) {
    this.data.submitOnvif({ camera });
  }
}
