export enum ResolutionBreakpoint {
    XSM_W_DOWN = '(max-width: 350px)',
    SM_W_DOWN = '(max-width: 575px)',
    MD_W_DOWN = '(max-width: 800px)',
    LG_W_DOWN = '(max-width: 991px)',
    XLG_W_DOWN = '(max-width: 1185px)',
    XL_W_DOWN = '(max-width: 1319px)',
    XXL_W_DOWN = '(max-width: 1499px)',

    XSM_W_UP = '(min-width: 301px)',
    SM_W_UP = '(min-width: 576px)',
    MD_W_UP = '(min-width: 801px)',
    LG_W_UP = '(min-width: 992px)',
    XLG_W_UP = '(min-width: 1186px)',
    XL_W_UP = '(min-width: 1320px)',
    XXL_W_UP = '(min-width: 1500px)',

    SM_H_UP = '(min-width: 701px)',
    SM_H_DOWN = '(max-width: 700px)'
}
