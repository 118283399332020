import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LicenseeReportRequest } from '@app/shared/entities/rd';
import { DialogWrapperData, DialogWrapperService } from '@app/shared/ui/dialog-wrapper';

@Component({
  selector: 'app-licensee-report-popup',
  templateUrl: './licensee-report-popup.component.html',
  styleUrls: ['./licensee-report-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseeReportPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogWrapperData<null, LicenseeReportRequest>,
    public dialogWrapperService: DialogWrapperService
  ) { }

  onSubmit(request: LicenseeReportRequest) {
    this.data.submit(request);
  }
}
