<div class="user-status">
  <div
    class="user-status__icon"
    [ngClass]="{
      active: blocked === false,
      blocked: blocked === true
    }"></div>
  <span class="user-status__text">
    {{
      blocked
        ? ('company.roles.user.status.blocked' | translate)
        : ('company.roles.user.status.active' | translate)
    }}
  </span>
</div>
