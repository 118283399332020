import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { YandexApiService } from '@app/shared/entities/integrations/yandex';
import { parseError } from '@app/shared/helpers';
import { AddressInfo } from '@app/shared/models';
import { YandexMaps } from '@app/shared/models/maps/yandex-maps.model';
import { environment } from 'environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import ymaps from 'ymaps';

@Injectable()
export class YandexAddressHelper {
    private onDestroy$: Subject<void> = new Subject();

    constructor(
        private yandexApiService: YandexApiService
    ) { }

    async getMapSuggestions(
        handleSuggestView: (suggestView: any) => void,
        handleResponse: (
            address: { kind: string, name: string }[],
            addressString: string,
        ) => void,
        handleError: (error: string) => void,
        handleFinally: () => void
    ) {
        let maps: typeof YandexMaps;

        try {
            maps = await ymaps.load(`https://api-maps.yandex.ru/2.1/?apikey=${environment.yandexApiKey}&lang=ru_RU`);
        } catch (e) {
            handleError(parseError(e));
        }

        const suggestView = new maps['SuggestView']('suggest1');
        handleSuggestView(suggestView);

        suggestView.events.add('select', async (event: any) => {
            suggestView.options.set('zIndex', -40000);

            try {
                const data = await maps.geocode(event.originalEvent.item.value);
                const geocoderMetaData = data['geoObjects'].get(0).properties.get('metaDataProperty').GeocoderMetaData;
                const address: { kind: string, name: string }[] = geocoderMetaData.Address.Components;
                const addressString: string = geocoderMetaData.text;

                handleResponse(address, addressString);
            } catch (e) {
                handleError(parseError(e));
            } finally {
                handleFinally();
            }
        });
    }

    getAddress(cb: (success: boolean, error?: string) => void) {
        this.yandexApiService.getAddressFromMap()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                (address: string) => address && cb(true),
                (error: HttpErrorResponse) => cb(false, parseError(error))
            );
    }

    getAddressInfo(
        address: { kind: string, name: string }[]
    ): { addressInfo: AddressInfo, entrance: string } {
        const { addressInfo, entrance } = this.yandexApiService.getAddressInfo(address);
        return { addressInfo, entrance };
    }
}
