import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { SnackbarService } from '@app/shared/components/snackbar';
import { LoaderService } from '@app/shared/entities/common/loader';
import { GraylogPhoneCallsApiService } from '@app/shared/entities/integrations';
import { parseError } from '@app/shared/helpers';
import { FlatRange, LogsResponse } from '@app/shared/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RdaTranslationFlatsItem } from './rda-translation-flats-item.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-rda-translation-flats',
  templateUrl: './rda-translation-flats.component.html',
  styleUrls: ['./rda-translation-flats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RdaTranslationFlatsComponent implements OnDestroy {
  totalRangesFlats: RdaTranslationFlatsItem[] = [];
  @Input() intercomUid: string;
  @Input() set range(range: [number, number]) {
    this._range = range;

    if (!range) {
      return;
    }

    this.rangeFlats = this.calculateFlatsFromRange(range);
    this.totalRangesFlats = [...this.rangeFlats, ...this.additionalRangesFlats];
  }
  @Input() set additionalRanges(additionalRanges: FlatRange[]) {
    if (!additionalRanges) {
      return;
    }

    this.additionalRangesFlats = [];

    for (const additionalRange of additionalRanges) {
      this.additionalRangesFlats = [...this.additionalRangesFlats, ...this.calculateFlatsFromRange([additionalRange.flatStart, additionalRange.flatEnd])];
    }

    this.totalRangesFlats = [...this.rangeFlats, ...this.additionalRangesFlats];
  }
  @Input() loading: boolean;

  private onDestroy$: Subject<void> = new Subject<void>();
  private rangeFlats: RdaTranslationFlatsItem[] = [];
  private additionalRangesFlats: RdaTranslationFlatsItem[] = [];
  private _range: [number, number];

  constructor(
    private loader: LoaderService,
    private snackbar: SnackbarService,
    private graylogPhoneCallsApiService: GraylogPhoneCallsApiService,
    private translate: TranslateService
  ) { }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  get range(): [number, number] {
    return this._range;
  }

  onCheckCall(checkedFlatIdx: number) {
    this.loader.loaderState = { state: true, type: 'component' };

    this.graylogPhoneCallsApiService.getExtendedLogs(this.intercomUid, null)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: LogsResponse[]) => {
          this.loader.loaderState = { state: false };

          if (response?.length > 0) {
            const dialMatch = (response[0].message as string).match(/DIAL ([\d]+)/);
            if (dialMatch.length > 1) {
              this.totalRangesFlats[checkedFlatIdx].responsedFlatNumber = Number.parseInt(dialMatch[1], 10);
            }
          } else {
            this.snackbar.showMessage(
              this.translate.instant('shared.rda.translations.editor.flats.message.check.not_found'),
              'info');
          }
        },
        (error: HttpErrorResponse) => {
          this.loader.loaderState = { state: false };
          this.snackbar.showMessage(
            this.translate.instant('shared.rda.translations.editor.flats.message.check.failed', {
              text: parseError(error)
            })
          );
        }
      );
  }

  private calculateFlatsFromRange(range: [number, number]): RdaTranslationFlatsItem[] {
    if (!range) {
      return [];
    }

    const flats: RdaTranslationFlatsItem[] = [];

    if (range[0] !== range[1]) {
      for (let i = range[0]; i < range[0] + 2 && i < range[1] - 1; ++i) {
        flats.push({ number: flats.length + this.totalRangesFlats.length + 1, flatNumber: i, checked: false });
      }

      const hundreds = Math.floor(range[0] / 100);

      for (let i = hundreds * 100 + 31; i < range[1]; i = i + 100) {
        if (i - 1 < range[1] && i - 1 > range[0]) {
          flats.push({ number: flats.length + this.totalRangesFlats.length + 1, flatNumber: i - 1, checked: false });
          flats.push({ number: flats.length + this.totalRangesFlats.length + 1, flatNumber: i, checked: false });
          flats.push({ number: flats.length + this.totalRangesFlats.length + 1, flatNumber: i + 1, checked: false });
        }

        if (i + 25 < range[1] && i + 25 > range[0]) {
          flats.push({ number: flats.length + this.totalRangesFlats.length + 1, flatNumber: i + 25, checked: false });
          flats.push({ number: flats.length + this.totalRangesFlats.length + 1, flatNumber: i + 26, checked: false });
          flats.push({ number: flats.length + this.totalRangesFlats.length + 1, flatNumber: i + 27, checked: false });
        }

        if (i + 68 < range[1] && i + 68 > range[0]) {
          flats.push({ number: flats.length + this.totalRangesFlats.length + 1, flatNumber: i + 68, checked: false });
          flats.push({ number: flats.length + this.totalRangesFlats.length + 1, flatNumber: i + 69, checked: false });
          flats.push({ number: flats.length + this.totalRangesFlats.length + 1, flatNumber: i + 70, checked: false });
        }
      }

      for (let i = range[1] - 1; i <= range[1]; ++i) {
        flats.push({ number: flats.length + this.totalRangesFlats.length + 1, flatNumber: i, checked: false });
      }
    } else {
      flats.push({ number: flats.length + this.totalRangesFlats.length + 1, flatNumber: range[0], checked: false });
    }

    return flats;
  }
}
