import { Actions, ActionTypes } from '../../actions/shields.actions';

export interface State {
  success: boolean;
  error: string | null;
  loading: boolean;
}

export const initialState: State = {
  success: null,
  error: null,
  loading: false,
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.UpdateShield: {
      return {
        ...state,
        success: null,
        error: null,
        loading: true,
      };
    }

    case ActionTypes.UpdateShieldSuccess: {
      return {
        ...state,
        success: true,
        error: null,
        loading: false
      };
    }

    case ActionTypes.UpdateShieldFailure: {
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
