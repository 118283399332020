import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, inject, Injector,
  OnDestroy,
  OnInit, ProviderToken,
  ViewChild,
  ViewRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import {NavigationEnd, Params, Router} from '@angular/router';
import { AmplitudeHelper, ServiceWorkerHelper } from '@app/core/helpers';
import { UserRoleType } from '@app/core/models';
import {
  BreadcrumbService,
  MenuService,
  TopBarService
} from '@app/core/services';
import { CoreFacade } from '@app/core/store';
import { PermissionsService } from '@app/security/permissions';
import {
  ChangePasswordPopupComponent,
  SnackbarService
} from '@app/shared/components';
import {
  LoaderService,
  LocalStorageGeneralKey,
  LocalStorageHelper,
  ResolutionService,
  ResourcePath,
  ResourcesHelper
} from '@app/shared/entities/common';
import { ChangePasswordRequest } from '@app/shared/entities/rd';
import { Constants } from '@app/shared/helpers';
import {SnapshotData, SnapshotDataParentRoute} from '@app/shared/models';
import {
  ResolutionBreakpoint,
  ResolutionService as ResolutionBreakpointsService
} from '@app/shared/services/resolution';
import { DialogWrapperData } from '@app/shared/ui';
import { AuthFacade } from '@app/views/auth/store';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, Subject } from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import { AppHelper, INavbarLogoConfig } from './app.helper';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {TitleBarComponentN} from '@app/core';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [AppHelper]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  readonly resourcePath: typeof ResourcePath = ResourcePath;

  authenticated: boolean;
  activeSignUpsCount$: Observable<number>;
  unresolvedIssuesCount$: Observable<number>;
  mdWDownBreakpoint$: Observable<boolean>;
  public additionalActionsService: TitleBarComponentN.TitleBarActionsService;
  public snapshotDataParentRoute: SnapshotDataParentRoute;

  @ViewChild('sidenav') private sidenav: MatSidenav;
  private onDestroy$: Subject<void> = new Subject();
  public logoOption: INavbarLogoConfig = null;

  constructor(
    public resolution: ResolutionService,
    public resourcesHelper: ResourcesHelper,
    private menu: MenuService,
    private dialog: MatDialog,
    private topBar: TopBarService,
    private loader: LoaderService,
    private coreFacade: CoreFacade,
    private authFacade: AuthFacade,
    private snackbar: SnackbarService,
    private jwtHelper: JwtHelperService,
    private breadcrumb: BreadcrumbService,
    private permissions: PermissionsService,
    private amplitudeHelper: AmplitudeHelper,
    private changeDetectorRef: ChangeDetectorRef,
    private resolutionBreakpoints: ResolutionBreakpointsService,
    private serviceWorkerHelper: ServiceWorkerHelper,
    private appHelper: AppHelper,
    private translate: TranslateService,
    private readonly router: Router,
    private readonly titleService: Title,
    private injector: Injector
  ) {
   this.logoOption = this.appHelper.initNavbarAppLogo();
  }

  ngOnInit() {
    this.enableRouterActivationEndEventListener();
    this.calculateRelativeHeight();
    this.initMenuOpening();
    this.initResolutionQueries();
    this.initServiceWorker();
    this.amplitudeHelper.initAmplitude();
    this.initAuthStore();
    this.initCoreStore();

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.titleService.setTitle(this.getNestedRouteTitles().join(' | '));
      }
    );
  }

  ngAfterViewInit(): void {
    this.serviceWorkerHelper.forceUpdate().then(() => {});
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private initResolutionQueries() {
    this.resolution.initMobileQuery(
      () =>
        !(this.changeDetectorRef as ViewRef).destroyed &&
        this.changeDetectorRef.detectChanges()
    );

    this.resolution.initTabletQuery(
      () =>
        !(this.changeDetectorRef as ViewRef).destroyed &&
        this.changeDetectorRef.detectChanges()
    );

    this.resolutionBreakpoints.initResolutionChangeDetection();
    this.mdWDownBreakpoint$ = this.resolutionBreakpoints.getBreakpoint(
      ResolutionBreakpoint.MD_W_DOWN
    );
  }

  private initServiceWorker() {
    this.serviceWorkerHelper
      .addUpdateListener()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.snackbar.showAction(
          this.translate.instant('core.new_version.message.text'),
          'success',
          this.translate.instant('core.new_version.button.submit'),
          (resp) => {
            if (resp) {
              // tslint:disable-next-line: deprecation
              window.location.reload();
            }
          }
        );
      });
  }

  private enableRouterActivationEndEventListener() {
    this.appHelper.addRouterActivationEndEventListener(
      (data: SnapshotData | null, queryParams: Params) => {
        this.breadcrumb.title = data.name;
        this.topBar.title = data.name;
        if (data?.additionalActionsService) {
          this.additionalActionsService = this.injector.get(data.additionalActionsService);
        } else {
          this.additionalActionsService = null;
        }
        this.snapshotDataParentRoute = data?.parentRoute;

        this.amplitudeHelper.createAmplitudeEvent(data, queryParams);

        this.menu.loaded$
          .pipe(takeUntil(this.onDestroy$))
          .subscribe(() => this.menu.prepareMenuButtons(data.menuBtnId));
      }
    );
  }

  private initMenuOpening() {
    this.menu.clicked$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        () =>
          this.resolutionBreakpoints.getBreakpointState(
            ResolutionBreakpoint.MD_W_DOWN
          ) && this.sidenav.close()
      );
  }

  private initAuthStore() {
    this.authFacade.authenticated$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((authenticated: boolean) => {
        if (authenticated === undefined) {
          return;
        }

        this.authenticated = authenticated;
        this.permissions.preparePermissions();

        if (this.authenticated) {
          this.getCounters();
          this.checkSetingsPopup();
          this.amplitudeHelper.updateAmplitudeClient();
        } else {
          this.loader.loaderState = { state: false };
          this.dialog.closeAll();
          this.amplitudeHelper.clearAmplitudeClient();
        }
      });

    this.authenticated = !!LocalStorageHelper.getItem(
      LocalStorageGeneralKey.AUTH_TOKEN
    );
    this.authFacade.loginAuthenticated(this.authenticated);
  }

  private initCoreStore() {
    this.activeSignUpsCount$ = this.coreFacade.activeSugnUpsCount$;
    this.unresolvedIssuesCount$ = this.coreFacade.unresolvedIssuesCountState$;
    this.coreFacade.loadValuesFromLocalStorage();
  }

  private getCounters() {
    if (
      this.permissions.someRoleContains([
        UserRoleType.ROLE_ENGINEER,
        UserRoleType.ROLE_ENGINEER_SIGNUPS,
        UserRoleType.ROLE_ENGINEER_ABONENTS
      ])
    ) {
      if (this.permissions.someRoleContains([UserRoleType.ROLE_ENGINEER])) {
        this.coreFacade.getActiveSignUpsCount();
        this.coreFacade.getUnresolvedIssuesCount();
      } else {
        if (
          this.permissions.someRoleContains([
            UserRoleType.ROLE_ENGINEER_SIGNUPS
          ])
        ) {
          this.coreFacade.getActiveSignUpsCount();
        }
        if (
          this.permissions.someRoleContains([
            UserRoleType.ROLE_ENGINEER_ABONENTS
          ])
        ) {
          this.coreFacade.getUnresolvedIssuesCount();
        }
      }
    }
  }

  private checkSetingsPopup() {
    const decodedToken: Object = this.jwtHelper.decodeToken(
      LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN)
    );
    const hidePasswordExpired: string = sessionStorage.getItem(
      'hidePasswordExpired'
    );

    if (
      decodedToken &&
      decodedToken['isPasswordExpired'] &&
      !hidePasswordExpired
    ) {
      sessionStorage.setItem('hidePasswordExpired', 'true');
      this.openSettingsPopup();
    }
  }

  private openSettingsPopup() {
    const data: DialogWrapperData<null, ChangePasswordRequest> = {
      title: this.translate.instant('core.settings.popup.title'),
      componentName: 'ChangePassword',
      submit: (request: ChangePasswordRequest) =>
        this.coreFacade.changePassword(request)
    };

    this.dialog.open(ChangePasswordPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolutionBreakpoints.getBreakpointState(
        ResolutionBreakpoint.MD_W_DOWN
      )
        ? '100%'
        : '350px',
      data
    });
  }

  private calculateRelativeHeight() {
    document.documentElement.style.setProperty(
      '--vh',
      `${window.innerHeight * 0.01}px`
    );

    window.addEventListener('resize', () => {
      document.documentElement.style.setProperty(
        '--vh',
        `${window.innerHeight * 0.01}px`
      );
    });
  }

  private getNestedRouteTitles(): string[] {
    let currentRoute = this.router.routerState.root.firstChild;
    const titles: string[] = [];

    while (currentRoute) {
      if (currentRoute.snapshot.routeConfig.data?.name) {
        titles.push(
          this.translate.instant('core.route.' + currentRoute.snapshot.routeConfig.data.name)
        );
      }

      currentRoute = currentRoute.firstChild;
    }

    titles.push(this.translate.instant('core.route.title'));

    return titles;
  }
}
