<mat-form-field
  appearance="outline"
  class="select-search relative-size"
  [class.none-padding]="nonePadding"
  [class.none-margin]="noneMargin"
>
  <mat-label>{{ title }}</mat-label>
  <input
    matInput
    type="text"
    autocorrect="off"
    spellcheck="false"
    autocomplete="off"
    autocapitalize="off"
    placeholder="{{ 'shared.select.search.placeholder.search' | translate }}"
    class="select-search-field__input"
    (keydown)="$event.stopPropagation()"
    [formControl]="filterInput"
    [matAutocomplete]="auto"
  />
</mat-form-field>

<mat-autocomplete #auto [displayWith]="displayFn" (optionSelected)="onSelect($event.option.value)">
  <div class="sticky-progress-bar">
    <mat-progress-bar
      *ngIf="loading"
      mode="indeterminate"
    ></mat-progress-bar>
  </div>
  <mat-option *ngIf="showEmptyOption" [value]="emptySelectSearch" class="select-search-trigger">--</mat-option>
  <mat-option *ngFor="let optionItem of filteredOptions$ | async" [value]="optionItem">
    <div [class.select-search-trigger-item]="optionItem.badge">
      <span class="select-search-trigger-wrapper__text">{{ optionItem.text }}</span>
      <div *ngIf="optionItem.badge" class="select-search-trigger-item-bagde">
        <p class="select-search-trigger-item-bagde__paragraph">
          {{ optionItem.badge }}
        </p>
      </div>
    </div>
  </mat-option>
</mat-autocomplete>
