<app-dialog-wrapper
  [componentName]="data.componentName"
  [progress]="(popupService.state$ | async) === 'loading'"
  [title]="data.title"
>
  <app-entrance-edit-container
    [address]="data.body.address"
    [showPrefix]="data.body.showPrefix"
    [label]="data.body.label"
    [initialMode]="data.body.initialMode"
    [loading]="(popupService.state$ | async) === 'loading'"
    (createEntrance)="onCreate($event.entrance, $event.prefix)"
    (addRange)="onAddRange($event.range, $event.entranceId)"
    (updateRange)="onUpdateRange($event.rangeId, $event.range, $event.entranceId)"
    (deleteRange)="onDeleteRange($event.rangeId, $event.entranceId)"
  ></app-entrance-edit-container>
</app-dialog-wrapper>
