<div class="apartment-video-remove-service__wrapper">
  <div class="apartment-video-remove-service__content__wrapper">
    <ng-container [ngSwitch]="confirmType">
      <ng-container *ngSwitchCase="ConfirmType.RemovingAVService">
        <div class="apartment-video-remove-service--title">
          {{ 'apartments_video_surveillance.popup.disable_service_title' | translate }} {{ name }}
        </div>
        <div class="apartment-video-remove-service--text">
          {{ 'apartments_video_surveillance.popup.removing_service_is_known' | translate }}
        </div>
        <div class="apartment-video-remove-service__text__wrapper">
          <i class="pi pi-times-circle apartment-video-remove-service__text--icon" aria-hidden="true"></i>
          <span
            class="apartment-video-remove-service__text--text">{{ 'apartments_video_surveillance.popup.removing_service_is_known_1' | translate }}</span>
        </div>
        <div class="apartment-video-remove-service__text__wrapper">
          <i class="pi pi-times-circle apartment-video-remove-service__text--icon" aria-hidden="true"></i>
          <span
            class="apartment-video-remove-service__text--text">{{ 'apartments_video_surveillance.popup.removing_service_is_known_2' | translate }}</span>
        </div>
        <div class="apartment-video-remove-service__text__wrapper">
          <i class="pi pi-exclamation-circle apartment-video-remove-service__text--icon " aria-hidden="true"></i>
          <span class="apartment-video-remove-service__text--text text-accepted">
            {{ 'apartments_video_surveillance.popup.removing_service_is_known_3' | translate }}
          </span>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ConfirmType.RemovingCamera">
        <div class="apartment-video-remove-service--title">
          {{ 'apartments_video_surveillance.popup.are_you_sure_to_delete_camera' | translate : {id: name} }}
        </div>
        <div class="apartment-video-remove-service__text__wrapper">
          <i class="pi pi-exclamation-circle apartment-video-remove-service__text--icon " aria-hidden="true"></i>
          <span class="apartment-video-remove-service__text--text text-accepted">
            {{ 'apartments_video_surveillance.popup.removing_service_is_known_3' | translate }}
          </span>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ConfirmType.BlockingAVService">
        <div class="apartment-video-remove-service--title">
          {{ 'apartments_video_surveillance.popup.block_service' | translate }}: {{ name }}
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="ConfirmType.UnblockingAVService">
        <div class="apartment-video-remove-service--title">
          {{ 'apartments_video_surveillance.popup.unblock_service' | translate }}: {{ name }}
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="apartment-video-remove-service__footer__wrapper">
    <p-button [label]="confirmButtonLabel" [styleClass]="confirmButtonType"
              class="apartment-video-remove-service__footer--fw-action"
              (onClick)="onConfirmAction()"></p-button>
  </div>
</div>
