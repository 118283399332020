<div>
  <app-navbar
    [links]="links"
    [neutral]="false"
    [active]="activeLink"
    (select)="onChangePage($event.idx)"></app-navbar>

  <div class="page-container">
    <div>
      <ng-container [ngSwitch]="activeLink">
        <ng-container *ngSwitchCase="'about'">
          <app-abonent-info
            [eventName]="eventName"
            [stat]="stat$ | async"
            [abonent]="abonent$ | async"
            [voipTokens]="voipTokens$ | async"
            [selectedVoipToken]="selectedVoipToken$ | async"
            [selectedDateDiff]="selectedDateDiff$ | async"
            (voipTokenChanged)="onVoipTokenChanged($event.voipToken)"
            (dateDiffTypeChanged)="onDateDiffTypeChanged($event.dateDiff)"
            (eventTypeSelected)="
              onEventTypeSelected($event.eventName)
            "></app-abonent-info>
        </ng-container>

        <ng-container *ngSwitchCase="'owner'">
          <app-abonent-owner
            [abonentId]="abonentId"
            [owner]="owner$ | async"
            [accountServices]="accountServices$ | async"
            (abonentChange)="onChangePage(1)"></app-abonent-owner>
        </ng-container>

        <ng-container *ngSwitchCase="'shared'">
          <app-abonent-shared [abonentId]="abonentId"></app-abonent-shared>
        </ng-container>

        <ng-container *ngSwitchCase="'access'">
          <app-abonents-delegation></app-abonents-delegation>
        </ng-container>
      </ng-container>

      <div
        class="info-loader-block"
        *ngIf="loading">
        <svg
          width="65px"
          height="65px"
          viewBox="0 0 66 66"
          xmlns="http://www.w3.org/2000/svg">
          <circle
            class="path"
            fill="none"
            stroke-width="6"
            stroke-linecap="round"
            cx="33"
            cy="33"
            r="30"></circle>
        </svg>
      </div>
    </div>

    <div>
      <div
        #background
        class="background"
        *ngIf="resolution.isTablet"
        (click)="onCloseChat()"></div>

      <div
        #chatBlock
        id="chatBlock"
        class="chat-block">
        <app-abonent-chat
          [abonentId]="abonentId"
          [chat]="(chat$ | async)?.content"
          [chatPage]="(chat$ | async)?.number"
          [resolved]="(abonent$ | async)?.resolved"
          (submittedClose)="$event && onCloseChat()"
          [totalElements]="(chat$ | async)?.totalElements"></app-abonent-chat>
      </div>
    </div>
  </div>
</div>

<button
  mat-fab
  color="primary"
  class="open-chat__button"
  matTooltip="{{ 'abonent.page.tooltip.chat_open' | translate }}"
  (click)="onOpenChat()"
  matBadge
  [matBadgeColor]="'warn'"
  [matBadgePosition]="'above before'"
  [matBadge]="'!'"
  [matBadgeHidden]="(abonent$ | async)?.resolved">
  <mat-icon>chat</mat-icon>
</button>
