import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConfirmComponentConnectionHelper, IntercomEditFilter } from '@app/shared/components';
import { IntercomType, RdaResponse, RdaUpdateRequest } from '@app/shared/entities/rd';
import { ServiceFacade } from '@app/views/services/store';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-software-intercom-wizard-content-intercom',
  templateUrl: './software-intercom-wizard-content-intercom.component.html',
  styleUrls: ['./software-intercom-wizard-content-intercom.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomWizardContentIntercomComponent {
  @Input() intercom: RdaResponse;
  @Input() loading: boolean;
  @Input() intercomTypes: IntercomType[];
  @Input() serviceId: number;
  @Input() filter: IntercomEditFilter;

  intercomUid: string;

  constructor(
    private serviceFacade: ServiceFacade,
    private confirmComponentConnectionHelper: ConfirmComponentConnectionHelper
  ) { }

  onUpdateIntercom(request: RdaUpdateRequest) {
    if (!this.intercom && environment.vivotec === false) {
      this.confirmComponentConnectionHelper.showBottomSheet(() =>
        this.serviceFacade.addServiceRda(request)
      );
      return;
    }
    if (!this.intercom && environment.vivotec === true) {
      this.serviceFacade.addServiceRda(request);
      return;
    }

    if (this.intercom && this.intercom.uid !== request.uid) {
      request.translation = this.intercom.translation;
      this.serviceFacade.changeServiceRda(request, this.intercom.id);
      return;
    }

    this.serviceFacade.updateServiceRda(request.uid, request);
  }
}
