<mat-card class="dialog-wrapper" [style]="{width: size}">
  <mat-card-header class="dialog-wrapper-header">
    <button
      *ngIf="hasBackButton"
      (click)="back.emit()"
      class="dialog-wrapper-header__back-button"
      mat-fab
      color="primary-inverse"
    >
      <mat-icon svgIcon="back"></mat-icon>
    </button>
    <mat-card-title class="dialog-wrapper-header__title">
      {{ title }}
    </mat-card-title>
    <button
      mat-fab
      mat-dialog-close
      color="primary-inverse"
      class="dialog-wrapper-header__button"
      (click)="close.emit()"
    >
      <mat-icon class="dialog-wrapper-header__button__icon">close</mat-icon>
    </button>
  </mat-card-header>
  <app-button-link *ngIf="link?.length > 0" [link]="link" class="dialog-wrapper__link">
    {{ 'shared.ui.dialog_wrapper.how_to' | translate:{ text: linkText } }}
  </app-button-link>
  <div class="dialog-wrapper-progress-bar" *ngIf="progress ?? (dialogWrapperService.pendingState$ | async)">
    <mat-progress-bar
      [mode]="mode"
    ></mat-progress-bar>
  </div>

  <mat-card-content class="dialog-wrapper-content" cdkScrollable>
    <ng-content></ng-content>
  </mat-card-content>

  <app-component-loader
    *ngIf="loading"
    [background]="true"
  ></app-component-loader>
</mat-card>
