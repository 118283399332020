import { Component, Inject } from '@angular/core';
import { PopupBasicComponent } from '@app/shared/templates';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-order-send-popup',
  templateUrl: './order-send-popup.component.html',
  styleUrls: ['./order-send-popup.component.scss']
})
export class OrderSendPopupComponent extends PopupBasicComponent {
  constructor(@Inject('subject') private subject: Subject<any>) {
    super();
  }

  onSubmit() {
    this.subject.next(true);
  }
}
