import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SnackbarService } from '@app/shared/components/snackbar';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { FlatRange } from '@app/shared/models';
import { AdditionalRangesFormHelper } from './additional-ranges-form.helper';
import {TranslateService} from '@ngx-translate/core';
import { EntranceEditContainerHelper } from '../entrance-edit-container.helper';

@Component({
  selector: 'app-additional-ranges-form',
  templateUrl: './additional-ranges-form.component.html',
  styleUrls: ['./additional-ranges-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalRangesFormComponent {
  @Input() entranceId: number;
  @Input() entranceRange: [number, number];
  @Input() additionalRanges: FlatRange[];
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  form: UntypedFormGroup = this.initForm();
  mdWDownBreakpoint$ = this.resolution.getBreakpoint(ResolutionBreakpoint.MD_W_DOWN);

  @Output() private addRange: EventEmitter<{ range: FlatRange }> = new EventEmitter();
  @Output() private updateRange: EventEmitter<{ rangeId: number, range: FlatRange }> = new EventEmitter();
  @Output() private deleteRange: EventEmitter<{ rangeId: number }> = new EventEmitter();
  private _loading: boolean;

  constructor(
    private resolution: ResolutionService,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) { }

  get loading(): boolean {
    return this._loading;
  }

  onCreate() {
    if (!this.checkRange(this.form.get('flatStart').value, this.form.get('flatEnd').value)) {
      return;
    }

    this.addRange.emit({ range: this.form.getRawValue() });
    this.form.setValue({ flatStart: null, flatEnd: null });
  }

  onUpdate(range: FlatRange) {
    if (!this.checkRange(range.flatStart, range.flatEnd, range.id)) {
      return;
    }

    this.updateRange.emit({ rangeId: range.id, range });
  }

  onRemove(rangeId: number) {
    this.deleteRange.emit({ rangeId });
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      flatStart: new UntypedFormControl(null, [Validators.required]),
      flatEnd: new UntypedFormControl(null, [Validators.required])
    });
  }

  private checkRange(flatStart: number, flatEnd: number, rangeId?: number): boolean {
    if(!EntranceEditContainerHelper.isRangeLimitValid([flatStart, flatEnd])){
      this.snackbar.showMessage(this.translate.instant('shared.additional.ranges.form.message.range_limit_exceeded', {
        limit: EntranceEditContainerHelper.RANGE_LIMIT
      }), 'info');
      return false;
    }

    const rangeValid: boolean = AdditionalRangesFormHelper.rangeValid(
      rangeId,
      [flatStart, flatEnd],
      this.additionalRanges,
      this.entranceRange
    );

    if (flatStart && flatEnd && rangeValid) {
      const range: FlatRange = { flatStart: flatStart, flatEnd: flatEnd };
      return true;
    }

    const additionalRangeInvalid: boolean = !AdditionalRangesFormHelper.additionalRangeValid(
      rangeId,
      [flatStart, flatEnd],
      this.additionalRanges,
      this.entranceRange
    );

    if (additionalRangeInvalid) {
      this.snackbar.showMessage(this.translate.instant('shared.additional.ranges.form.message.range_of_apartments_intersects_with_other_ranges'), 'info');
    } else {
      this.snackbar.showMessage(this.translate.instant('shared.additional.ranges.form.message.indicate_correct_apartment_range'), 'info');
    }

    return false;
  }
}
