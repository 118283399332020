import { HttpErrorResponse } from '@angular/common/http';
import { RdvaPlaylistItem } from '@app/shared/entities/integrations';
import * as root from '@app/store/reducers';
import { VideoshotPopupState, VideoshotTimeData } from '../models';

export interface VideoPlayerState {
    currentSource: string;
    playlists: RdvaPlaylistItem[];
    videoshotPopupState: VideoshotPopupState;
    videoshotError: HttpErrorResponse;
    videoshotTimeData: VideoshotTimeData;
}

export const videoPlayerInitialState: VideoPlayerState = {
    currentSource: undefined,
    playlists: undefined,
    videoshotPopupState: VideoshotPopupState.CREATE,
    videoshotError: undefined, 
    videoshotTimeData: undefined
};

export interface VideoPlayerModuleState {
    videoPlayer: VideoPlayerState;
}

export interface VideoPlayerModuleRootState extends root.State {
    'video-player': VideoPlayerModuleState;
}
