import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

export const selectGetBatchesPageState = createSelector(
  intercomModuleSelector,
  (state: IntercomModuleState) => state.getBatchesPage
);

export const getBatchesPage = createSelector(
  selectGetBatchesPageState,
  state => state
);

export const getBatchesPageSuccess = createSelector(
  selectGetBatchesPageState,
  state => ({ batches: state.batches, totalCount: state.totalCount })
);

export const getBatchesPageLoading = createSelector(
  selectGetBatchesPageState,
  state => state.loading
);

export const getBatchesPageError = createSelector(
  selectGetBatchesPageState,
  state => state.error
);
