import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { CompanyApiService, ReportsUtils, UploadPaymentTemplateHistoryResponse } from '@app/shared/entities/rd';
import { Subject } from 'rxjs';
import { ResolutionService } from '@app/shared/entities';
import { FileResponse } from '@app/shared/api';

@Component({
  selector: 'app-payments-upload-history',
  templateUrl: './payments-upload-history.component.html',
  styleUrls: ['./payments-upload-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentsUploadHistoryComponent implements OnInit, OnDestroy {
  public items: UploadPaymentTemplateHistoryResponse[];
  public isLoading: boolean;

  readonly tableColumns: string[] = [
    'createdAt', 'email', 'file'
  ];

  private unSubscribe$: Subject<boolean>;

  constructor(
    private readonly companyService: CompanyApiService,
    public resolution: ResolutionService
  ) {}

  ngOnInit(): void {
    this.unSubscribe$ = new Subject<boolean>();

    this.loadData();
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next(true);
    this.unSubscribe$.unsubscribe();
  }

  loadData(): void {
    this.isLoading = true;

    this.companyService.getPaymentsManualTemplateHistory()
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res: UploadPaymentTemplateHistoryResponse[]) => {
          this.isLoading = false;
          this.handleLoadDataAction(res);
        }
      );
  }

  onDownload(id: number): void {
    this.companyService.downloadPaymentsManualTemplateHistory(id)
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((res: FileResponse) => {
          this.isLoading = false;
          this.handleDownloadAction(res);
        }
      );
  }

  private handleLoadDataAction(response: UploadPaymentTemplateHistoryResponse[]): void {
      this.items = response;
  }

  private handleDownloadAction(fileResponse: FileResponse): void {
    ReportsUtils.downLoadFile(fileResponse.arrayBuffer, decodeURI(fileResponse.fileName));
  }
}
