import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { IntercomEditContentModule, PbxOnRdaEditContentModule, RdaTranslationsEditorModule } from '@app/shared/components';
import { DialogWrapperModule, InfoBlockModule, NavbarModule } from '@app/shared/ui';
import { ServiceIntercomPanelsModule } from '../../../containers/service-intercom-panels';
import { ServiceIntercomPopupContentComponent } from './service-intercom-popup-content';
import { ServiceIntercomPopupComponent } from './service-intercom-popup.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        IntercomEditContentModule,
        RdaTranslationsEditorModule,
        PbxOnRdaEditContentModule,
        DialogWrapperModule,
        NavbarModule,
        ServiceIntercomPanelsModule,
        InfoBlockModule
    ],
    declarations: [
        ServiceIntercomPopupComponent,
        ServiceIntercomPopupContentComponent
    ],
    exports: [
        ServiceIntercomPopupComponent
    ]
})
export class ServiceRdaFormModule { }
