import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogWrapperData } from '@app/shared/ui';
import { ServiceFacade } from '@app/views/services/store';
import { Observable } from 'rxjs';
import { ServiceDelegationPopupData } from './service-delegation-popup-data.model';
import { ServiceDelegationPopupService } from './service-delegation-popup.service';

@Component({
  selector: 'app-service-delegation-popup',
  templateUrl: './service-delegation-popup.component.html',
  styleUrls: ['./service-delegation-popup.component.scss'],
  providers: [ServiceDelegationPopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceDelegationPopupComponent implements OnInit {
  readonly popupState$: Observable<'loading' | 'close' | 'loaded'> = this.popupService.state$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<ServiceDelegationPopupData, null>,
    private dialogRef: MatDialogRef<ServiceDelegationPopupComponent>,
    private popupService: ServiceDelegationPopupService,
    private serviceFacade: ServiceFacade
  ) { }

  ngOnInit() {
    this.popupService.addStateChangeListener((state: 'loading' | 'close' | 'loaded') =>
      state === 'close' && this.dialogRef.close()
    );
  }

  onDelegate(phone: number) {
    this.serviceFacade.delegateAbonentAccess(
      this.data.body.entranceId,
      this.data.body.flatNumber,
      this.data.body.owner.id,
      phone
    );
  }
}
