import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { PreviosQueryParams, PreviosQueryType } from '../models';

@Injectable({
  providedIn: 'root'
})
export class RouteDetectionService {
  private previousUrl: string;
  private currentUrl: string;
  private params: URLSearchParams;

  constructor(private router: Router) {
    this.currentUrl = this.router.url;
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = decodeURIComponent(this.currentUrl);

        const { params: currentParams } = this.splitUrlToUrlAndQueryParams(
          event.url
        );

        if (currentParams.get('queryMode') === 'current') {
          this.params = currentParams;
        } else {
          const { params: previousParams } = this.splitUrlToUrlAndQueryParams(
            this.previousUrl
          );
          this.params = previousParams;
        }

        this.currentUrl = decodeURIComponent(event.url);
      }
    });
  }

  getQueryParams(): PreviosQueryParams {
    return this.extractQueryParams(this.params);
  }

  private splitUrlToUrlAndQueryParams(url: string): {
    url: string;
    params: URLSearchParams;
  } {
    return {
      url: url?.slice(0, url?.indexOf('?')),
      params: new URLSearchParams(url?.slice(url?.indexOf('?') + 1))
    };
  }

  private extractQueryParams(params: URLSearchParams): PreviosQueryParams {
    const query: string = params.get('query');
    const address: string = params.get('address');
    const status: string = params.get('status');
    const type: PreviosQueryType = params.get('type') as PreviosQueryType;
    const page: number = Number.parseInt(params.get('page'), 10) ?? null;
    const sort = params.get('sort');
    return { query, address, status, type, page, sort };
  }
}
