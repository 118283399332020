import { InfoBlockModule } from './../../../ui/info-block/info-block.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { DialogWrapperModule } from '@app/shared/ui';
import { DeleteServiceContentComponent } from './delete-service-content';
import { DeleteServicePopupComponent } from './delete-service-popup.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        DialogWrapperModule,
        InfoBlockModule,
        NgxTranslateModule
    ],
    declarations: [
        DeleteServiceContentComponent,
        DeleteServicePopupComponent
    ],
    exports: [
        DeleteServiceContentComponent,
        DeleteServicePopupComponent
    ],
})
export class DeleteServicePopup { }
