export type DurationString = `${string}:${string}`;

export class DurationRange {
  private _minDuration!: DurationString;
  private _maxDuration!: DurationString;
  
  constructor(minDuration: DurationString, maxDuration: DurationString) {
    this._minDuration = minDuration;
    this._maxDuration = maxDuration;
  }

  get firstDigitMaximum(): string {
    return this._maxDuration.split(':')[0][0];
  }

  get firstDigitMinimum(): string {
    return this._minDuration.split(':')[0][0];
  }

  get secondDigitMaximum(): string {
    return this._maxDuration.split(':')[0][1];
  }

  get secondDigitMinimum(): string {
    return this._minDuration.split(':')[0][1];
  }
}