import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { Constants } from '@app/shared/helpers';
import { EditPopupComponentData, SignUpRequestV2, SignUpResponse, SignUpStatus } from '@app/shared/models';
import { PhonePipe } from '@app/shared/pipes';
import { RequestsService } from '@app/shared/services';
import { EditPopupComponent } from '@app/shared/templates';
import { DialogWrapperData } from '@app/shared/ui';
import { AbonentsDelegationPopupBody, AbonentsDelegationPopupComponent, AbonentsDelegationPopupResponse, RejectRequestPopupComponent, SignUpsInfoPopupBody, SignUpsInfoPopupComponent } from '../../popups';
import { AbonentConnectionPopupBody, AbonentConnectionPopupComponent } from '@app/views/abonents/components';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-abonent-sign-ups',
  templateUrl: './abonent-sign-ups.component.html',
  styleUrls: ['./abonent-sign-ups.component.scss'],
  providers: [PhonePipe]
})
export class AbonentSignUpsComponent {
  readonly displayedColumns: string[] = ['address', 'flatNumber', 'status', 'isVirtual', 'progress', 'createdAt', 'actions'];

  @Input() signUps!: SignUpResponse[];
  @Input() activeLink!: string;

  @Output() private updateSignUp: EventEmitter<{ signUpId: number, request: SignUpRequestV2 }> = new EventEmitter();

  constructor(
    public signUpsService: RequestsService,
    private dialog: MatDialog,
    private resolution: ResolutionService,
    private readonly phonePipe: PhonePipe,
    private translate: TranslateService
  ) { }

  onConnect(signUp: SignUpResponse) {
    const data: DialogWrapperData<AbonentConnectionPopupBody, null> = {
      title: this.translate.instant('abonent.sign_ups.connect.title'),
      componentName: `AddSignUpServices`,
      body: {
        addresses: [signUp.address],
        flatNumber: signUp.address.flat,
        phone: this.phonePipe.transform(String(signUp.phone)),
        hasPhysicalTube: !signUp.isVirtual,
        companyId: signUp.companyId
      },
      submit: () => {
        const request: SignUpRequestV2 = {
          isVirtual: null,
          rejectedReason: null,
          status: SignUpStatus.CONNECTED
        };
        this.updateSignUp.emit({ signUpId: signUp.signUpId, request });
      }
    };

    this.dialog.open(AbonentConnectionPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '600px',
      data
    });
  }

  onDelegate(signUp: SignUpResponse) {
    const data: DialogWrapperData<AbonentsDelegationPopupBody, AbonentsDelegationPopupResponse> = {
      title: this.translate.instant('abonent.sign_ups.delegate.title'),
      componentName: 'DelegateSignUp',
      body: { signUp },
      submit: (event: AbonentsDelegationPopupResponse) => {
        if (!event.status) {
          return;
        }

        const request: SignUpRequestV2 = {
          status: event.status
        };

        if (event.status === SignUpStatus.REJECTED) {
          request.rejectedReason = this.translate.instant('abonent.sign_ups.delegate.message.rejected_reason');
        }

        this.updateSignUp.emit({
          signUpId: signUp.signUpId,
          request
        });
      }
    };

    this.dialog.open(AbonentsDelegationPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '500px',
      data
    });
  }

  onProcessed(signUpResponse: SignUpResponse) {
    const request: SignUpRequestV2 = {
      isVirtual: null,
      rejectedReason: null,
      status: SignUpStatus.PROCESSED
    };
    this.updateSignUp.emit({ signUpId: signUpResponse.signUpId, request });
  }

  onReject(signUpResponse: SignUpResponse) {
    const data: EditPopupComponentData = {
      title: this.translate.instant('abonent.sign_ups.reject.title'),
      providedData: { number: signUpResponse.phone },
      component: RejectRequestPopupComponent,
      componentName: 'RejectSignUp',
      submit: (reason: string) => {
        if (reason) {
          const request: SignUpRequestV2 = {
            isVirtual: null,
            rejectedReason: reason,
            status: SignUpStatus.REJECTED
          };

          this.updateSignUp.emit({ signUpId: signUpResponse.signUpId, request });
        }
      }
    };

    this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '650px',
      data
    });
  }

  onInfo(signUpResponse: SignUpResponse) {
    const data: DialogWrapperData<SignUpsInfoPopupBody, null> = {
      title: this.translate.instant('abonent.sign_ups.info.title'),
      componentName: 'InfoSignUp',
      body: {
        signUp: signUpResponse,
        showPersonalInfo: this.activeLink !== 'building' && this.activeLink !== 'byRegion'
      }
    };

    this.dialog.open(SignUpsInfoPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '350px',
      data
    });
  }
}
