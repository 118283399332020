import {Clipboard} from '@angular/cdk/clipboard';
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {
  AwxJobLogResponse,
  CreateAwxUpdateJobRequest,
  CreateAwxUpdateJobResponse
} from '@app/shared/entities/rd/awx-jobs/models';
import {AwxJobStatus} from '@app/shared/entities/rd/awx-jobs/models/awx-job-status.enum';
import {AwxJobsApiService} from '@app/shared/entities/rd/awx-jobs/services';
import {parseError} from '@app/shared/helpers';
import {PagedResponse} from '@app/shared/models';
import {combineLatest, from, Observable, Subject, Subscriber} from 'rxjs';
import {delay, map, takeUntil} from 'rxjs/operators';
import {IntercomUpdateToolStatus} from './intercom-update-tool-status.model';
import {IntercomUpdateToolFacade} from './store/intercom-update-tool.facade';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class IntercomUpdateToolHelper implements OnDestroy {
  readonly timeoutBetweenDownloads = 5000;
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private clipboard: Clipboard,
    private awxJobsApi: AwxJobsApiService,
    private intercomUpdateToolFacade: IntercomUpdateToolFacade,
    private translate: TranslateService
  ) {
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  copyLogsToClipboard(logs: AwxJobLogResponse[], cb: () => void) {
    let remainingAttempts = 3;

    const copyAttempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(copyAttempt);
      } else {
        cb();
        pending.destroy();
      }
    };

    const logsStrings: string[] = logs.map(log => {
      const date = new Date(log.date);
      return `${date.toLocaleString()}\t ${log.message}`;
    });
    const pending = this.clipboard.beginCopy(logsStrings.join('\n'));

    copyAttempt();
  }

  createUpdateRdaaJob(
    intercomUid: string,
    request: CreateAwxUpdateJobRequest,
    cb: (success: boolean, error?: string) => void
  ) {
    this.intercomUpdateToolFacade.createUpdateRdaaJob(intercomUid, request);

    from(this.awxJobsApi.createRdaaUpdateJob(request))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: CreateAwxUpdateJobResponse) => {
          this.intercomUpdateToolFacade.createUpdateRdaaJobSuccess(intercomUid, response);
          cb(true);
        },
        (error: HttpErrorResponse) => cb(false, parseError(error))
      );
  }

  createUpdateRdosJob(
    intercomUid: string,
    request: CreateAwxUpdateJobRequest,
    cb: (success: boolean, error?: string) => void
  ) {
    this.intercomUpdateToolFacade.createUpdateRdosJob(intercomUid, request);

    from(this.awxJobsApi.createRdosUpdateJob(request))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: CreateAwxUpdateJobResponse) => {
          this.intercomUpdateToolFacade.createUpdateRdosJobSuccess(intercomUid, response);
          cb(true);
        },
        (error: HttpErrorResponse) => cb(false, parseError(error))
      );
  }

  addIntercomUpdateLogsListener(cb: (logs: AwxJobLogResponse[]) => void) {
    combineLatest([
      this.intercomUpdateToolFacade.updateRdaaJobLogs$,
      this.intercomUpdateToolFacade.updateRdosJobLogs$
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([updateRdaaJobLogs, updateRdosJobLogs]) => {
        let response: AwxJobLogResponse[] = [];

        if (updateRdaaJobLogs?.length) {
          updateRdaaJobLogs = updateRdaaJobLogs.map(log => ({date: log.date, message: `RDAA: ${log.message}`}));
          response = response.concat(updateRdaaJobLogs);
        }

        if (updateRdosJobLogs?.length) {
          updateRdosJobLogs = updateRdosJobLogs.map(log => ({date: log.date, message: `RDOS: ${log.message}`}));
          response = response.concat(updateRdosJobLogs);
        }

        cb(response.sort((a: AwxJobLogResponse, b: AwxJobLogResponse) => b.date - a.date));
      });
  }

  initUpdateTool(
    intercomUid: string,
    cb?: (updateRdaaJobSuccess: boolean, updateRdosJobSuccess: boolean) => void
  ): Observable<IntercomUpdateToolStatus> {
    this.intercomUpdateToolFacade.initSelectors(intercomUid);

    this.intercomUpdateToolFacade.state$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (state: { listening: boolean, rdaaUpdateJobId: number, rdosUpdateJobId: number }) => {
          if (state.listening && state.rdaaUpdateJobId) {
            this.getUpdateRdaaJobWithTimeout(intercomUid, state.rdaaUpdateJobId);
            if (state.rdosUpdateJobId) {
              this.getUpdateRdosJobWithTimeout(intercomUid, state.rdosUpdateJobId);
            }
          }
        }
      );

    this.addIntercomUpdateListener((
      started: boolean,
      finished: boolean,
      updateRdaaJobSuccess: boolean,
      updateRdosJobSuccess: boolean
    ) => {
      if (!started || !finished) {
        return;
      }

      this.intercomUpdateToolFacade.finishUpdateJobs(
        intercomUid,
        updateRdaaJobSuccess,
        updateRdosJobSuccess
      );
      if (cb) {
        cb(updateRdaaJobSuccess, updateRdosJobSuccess);
      }
    });

    return combineLatest([this.intercomUpdateToolFacade.status$, this.intercomUpdateToolFacade.updateRdaaJob$]).pipe(
      map(([status, updateRdaaJob]) => {
        if (updateRdaaJob?.status === AwxJobStatus.FAILED) {
          return IntercomUpdateToolStatus.FAILED;
        } else {
          return status;
        }
      })
    );
  }

  private addIntercomUpdateListener(
    cb: (started: boolean, finished: boolean, updateRdaaJobSuccess: boolean, updateRdosJobSuccess: boolean) => void
  ) {
    combineLatest([
      this.intercomUpdateToolFacade.updateRdaaJob$,
      this.intercomUpdateToolFacade.updateRdosJob$,
      this.intercomUpdateToolFacade.status$
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: [CreateAwxUpdateJobResponse, CreateAwxUpdateJobResponse, IntercomUpdateToolStatus]) => {
          if (!response[0]) {
            return;
          }

          const rdaaUpdateJobFinished = response[0].status === AwxJobStatus.SUCCESSFUL || response[0].status === AwxJobStatus.FAILED;
          const rdosUpdateJobFinished = response[1]?.status === AwxJobStatus.SUCCESSFUL || response[1]?.status === AwxJobStatus.FAILED;

          const updateStarted =
            (response[0].status !== undefined || response[1]?.status !== undefined) &&
            (response[2] !== IntercomUpdateToolStatus.SUCCESS && response[2] !== IntercomUpdateToolStatus.FAILED);

          const updateFinished = rdaaUpdateJobFinished && rdosUpdateJobFinished;

          cb(
            updateStarted,
            updateFinished,
            response[0].status === AwxJobStatus.SUCCESSFUL,
            response[1]?.status === AwxJobStatus.SUCCESSFUL
          );
        }
      );
  }

  private getUpdateRdaaJob(
    intercomUid: string,
    jobId: number,
    cb: (success: boolean, finished: number, error?: string) => void
  ) {
    this.intercomUpdateToolFacade.getUpdateRdaaJob(intercomUid, jobId);

    from(this.awxJobsApi.getAwxJob(jobId))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: CreateAwxUpdateJobResponse) => {
          this.intercomUpdateToolFacade.getUpdateRdaaJobSuccess(intercomUid, response);
          cb(true, response.finished);
        },
        (error: HttpErrorResponse) => cb(false, null, parseError(error))
      );
  }

  private getUpdateRdaaJobLogs(
    intercomUid: string,
    jobId: number,
    cb?: (success: boolean, error?: string) => void
  ) {
    this.intercomUpdateToolFacade.getUpdateRdaaJobLogs(intercomUid, jobId);

    from(this.awxJobsApi.getAwxJobLogs(jobId))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: PagedResponse<AwxJobLogResponse>) => {
          this.intercomUpdateToolFacade.getUpdateRdaaJobLogsSuccess(
            intercomUid,
            jobId,
            response.content,
            response.totalElements
          );
        },
        (error: HttpErrorResponse) =>
          cb(
            false,
            this.translate.instant('shared.rda.intercom.update.tool.message.get_update_rdaa_job_logs.failed', {
              text: parseError(error)
            })
          )
      );
  }

  private getUpdateRdaaJobWithTimeout = (intercomUid: string, jobId: number) => {
    this.getUpdateRdaaJob(
      intercomUid,
      jobId,
      (success: boolean, finished: number, _: string) => {
        if (success && !finished) {
          new Observable((sub: Subscriber<void>) => sub.next())
            .pipe(delay(this.timeoutBetweenDownloads), takeUntil(this.onDestroy$))
            .subscribe(() => {
              this.getUpdateRdaaJobWithTimeout(intercomUid, jobId);
            });
        }

        this.getUpdateRdaaJobLogs(intercomUid, jobId);
      }
    );
  };

  private getUpdateRdosJob(
    intercomUid: string,
    jobId: number,
    cb: (success: boolean, finished: number, error?: string) => void
  ) {
    this.intercomUpdateToolFacade.getUpdateRdosJob(intercomUid, jobId);

    from(this.awxJobsApi.getAwxJob(jobId))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: CreateAwxUpdateJobResponse) => {
          this.intercomUpdateToolFacade.getUpdateRdosJobSuccess(intercomUid, response);
          cb(true, response.finished);
        },
        (error: HttpErrorResponse) => cb(false, null, parseError(error))
      );
  }

  private getUpdateRdosJobLogs(
    intercomUid: string,
    jobId: number,
    cb?: (success: boolean, error?: string) => void
  ) {
    this.intercomUpdateToolFacade.getUpdateRdosJobLogs(intercomUid, jobId);

    from(this.awxJobsApi.getAwxJobLogs(jobId))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: PagedResponse<AwxJobLogResponse>) => {
          this.intercomUpdateToolFacade.getUpdateRdosJobLogsSuccess(
            intercomUid,
            jobId,
            response.content,
            response.totalElements
          );
        },
        (error: HttpErrorResponse) =>
          cb(
            false,
            this.translate.instant('shared.rda.intercom.update.tool.message.get_update_rdos_job_logs.failed', {
              text: parseError(error)
            })
          )
      );
  }

  private getUpdateRdosJobWithTimeout = (intercomUid: string, jobId: number) => {
    this.getUpdateRdosJob(
      intercomUid,
      jobId,
      (success: boolean, finished: number, _: string) => {
        if (success && !finished) {
          new Observable((sub: Subscriber<void>) => sub.next())
            .pipe(delay(this.timeoutBetweenDownloads), takeUntil(this.onDestroy$))
            .subscribe(() => {
              this.getUpdateRdosJobWithTimeout(intercomUid, jobId);
            });
        }

        this.getUpdateRdosJobLogs(intercomUid, jobId);
      }
    );
  };

  async checkIfExistStartedJob(intercomId): Promise<CreateAwxUpdateJobResponse> {
    const jobList = await this.awxJobsApi.getRdaaJobList(intercomId).toPromise();

    return jobList.find(job => job.name === 'update-rdaa' || job.name === 'update-rdos-components');
  }
}
