import { KzPostAddressType } from './kz-post-address-type';
import { KzPostAddressPart } from './kz-post-address-part';

export interface KzPostFullAddress {
    actual: string;
    additional: string;
    additionalType: string;
    address: string;
    corpus: string;
    corpusNumber: string;
    delivery_index: string;
    maintain_index: string;
    number: string;
    oldPostcode: string;
    postcode: string;
    receive_index: string;
    rka: string;
    parts: KzPostAddressPart[];
    type: KzPostAddressType;
}
