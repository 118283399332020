import {Injectable} from '@angular/core';
import {ComponentStore} from '@ngrx/component-store';
import {Observable} from 'rxjs';
import {GateWizardPopupStepper, GateWizardUpdateStep} from '../models';
import {GateWizardPopupStep} from '../models/gate-wizard-popup-step.enum';
import {GateWizardPopupState, initialGateWizardSteps} from './gate-wizard-popup.state';

@Injectable()
export class GateWizardPopupStore extends ComponentStore<GateWizardPopupState> {
  readonly steps$: Observable<GateWizardPopupStepper[]> = this.select((state: GateWizardPopupState) => state.steps);
  readonly showStepButtons$: Observable<boolean> = this.select((state: GateWizardPopupState) => state.showStepButtons);
  readonly showMenu$: Observable<boolean> = this.select((state: GateWizardPopupState) => state.showMenu);
  readonly wizardStep$: Observable<GateWizardPopupStep> = this.select((state: GateWizardPopupState) => state.wizardStep);

  readonly updateStepState = this.updater((
    state: GateWizardPopupState,
    {step, stepState}: { step: GateWizardPopupStep, stepState?: GateWizardUpdateStep }
  ) => {
    if (stepState.complete !== undefined) {
      state.steps[step].complete = stepState.complete;
    }

    if (stepState.disabled !== undefined) {
      state.steps[step].disabled = stepState.disabled;
    }

    if (stepState.hidden !== undefined) {
      state.steps[step].hidden = stepState.hidden;
    }

    if (stepState.initialized !== undefined) {
      state.steps[step].initialized = stepState.initialized;
    }

    return {
      ...state,
      steps: state.steps.slice()
    };
  });

  readonly selectStep = this.updater((state: GateWizardPopupState, wizardStep: GateWizardPopupStep) =>
    ({...state, wizardStep})
  );

  readonly changeStepButtonsVisible = this.updater((state: GateWizardPopupState, show: boolean) =>
    ({...state, showStepButtons: !!show})
  );

  readonly changeMenuVisible = this.updater((state: GateWizardPopupState, show: boolean) =>
    ({...state, showMenu: !!show})
  );

  constructor() {
    super({
      steps: JSON.parse(JSON.stringify(initialGateWizardSteps)),
      showStepButtons: true,
      showMenu: true,
      wizardStep: null,
      intercomsStep: null
    });
  }
}
