import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { RdaTranslationFlatsComponent } from './rda-translation-flats';
import { RdaTranslationsEditorComponent } from './rda-translations-editor.component';
import { RdaTranslationsFormComponent } from './rda-translations-form';
import { RdaTranslationsInstructionComponent } from './rda-translations-instruction';
import {NgxTranslateModule} from '@app/translate/translate.module';

const components = [
    RdaTranslationFlatsComponent,
    RdaTranslationsFormComponent,
    RdaTranslationsInstructionComponent,
    RdaTranslationsEditorComponent
];

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        SharedPipesModule,
        NgxTranslateModule,
    ],
    declarations: components,
    exports: components
})
export class RdaTranslationsEditorModule { }
