import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogWrapperData } from '@app/shared/ui';
import { UpdateServiceRequest } from '@app/views/services/models';
import { ServiceEditPopupService } from './service-edit-popup.service';

@Component({
  selector: 'app-service-edit-popup',
  templateUrl: './service-edit-popup.component.html',
  styleUrls: ['./service-edit-popup.component.scss'],
  providers: [ServiceEditPopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceEditPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<{ serviceName: string }, UpdateServiceRequest>,
    public popupService: ServiceEditPopupService,
    private dialogRef: MatDialogRef<ServiceEditPopupComponent>,
  ) { }

  ngOnInit() {
    this.popupService.addStateChangeListener(state => state === 'close' && this.dialogRef.close());
  }

  onEdit(request: UpdateServiceRequest) {
    this.data.submit(request);
  }
}
