import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { DurationString } from "./duration-input.models";

const ALL_NUMBERS_LENGTH = 4;

export function minDuration(minimum: DurationString): ValidatorFn {
  const minMinutes = parseInt(minimum.split(':')[0]);
  const minSeconds = parseInt(minimum.split(':')[1]);

  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.getRawValue();

    //If user hasn't entered all the numbers, do not check
    if (value.length < ALL_NUMBERS_LENGTH) {
      return null;
    }

    const [minutes, seconds] = getSplittedTime(value);
    if (minutes > minMinutes || (minutes === minMinutes && seconds >= minSeconds)) {
      return null;
    }

    return { minDuration: true };
  }
}

export function maxDuration(maximum: DurationString): ValidatorFn {
  const maxMinutes = parseInt(maximum.split(':')[0]);
  const maxSeconds = parseInt(maximum.split(':')[1]);
  
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.getRawValue();
    
    //If user hasn't entered all the numbers, do not check
    if (value.length < ALL_NUMBERS_LENGTH) {
      return null;
    }

    const [minutes, seconds] = getSplittedTime(value);
    if (minutes < maxMinutes || (minutes === maxMinutes && seconds === maxSeconds)) {
      return null;
    }

    return { maxDuration: true };
  }
}

export function enterAllNumbers(control: AbstractControl): ValidationErrors | null {
  const value = control.getRawValue();

  if (value.length > 0 && value.length < ALL_NUMBERS_LENGTH) {
    return { enterAllNumbers: true };
  }
  
  const [minutes, seconds] = getSplittedTime(value);
  if (isNaN(minutes) && isNaN(seconds)) {
    return { enterAllNumbers: true };
  }

  return null;
}

function getSplittedTime(value: string): [minutes: number, seconds: number] {
  if (value.includes(':')) {
    const splitedValues = value.split(':');
    return [parseInt(splitedValues[0]), parseInt(splitedValues[1])];
  }

  const splittedValue = value.split('');
  return [
    parseInt(splittedValue[0] + splittedValue[1]), 
    parseInt(splittedValue[2] + splittedValue[3])
  ];
}