import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AddressInputMode } from '@app/shared/components';
import { Address, EntranceRegistration, EntranceUpdateRequest, FlatRange } from '@app/shared/models';
import { ServiceFacade } from '@app/views/services/store';

@Component({
  selector: 'app-software-intercom-wizard-content-entrance',
  templateUrl: './software-intercom-wizard-content-entrance.component.html',
  styleUrls: ['./software-intercom-wizard-content-entrance.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomWizardContentEntranceComponent {
  readonly addressInputMode: typeof AddressInputMode = AddressInputMode;

  @Input() set addresses(addresses: Address[]) {
    this.address = addresses?.[0];
  }
  @Input() serviceId!: number;
  @Input() loading: boolean;
  address: Address;
  addressId: number;
  editContentLabel: string;

  constructor(
    private serviceFacade: ServiceFacade
  ) { }

  onCreate(entrance: EntranceRegistration, prefix: string) {
    if (this.address) {
      const entranceUpdateRequest: EntranceUpdateRequest = {
        flatStart: entrance.range[0],
        flatEnd: entrance.range[1]
      };

      this.serviceFacade.updateServiceEntrance(this.address.entrance.id, entranceUpdateRequest);
    } else {
      this.serviceFacade.addServiceEntrance(entrance, prefix);
    }
  }

  onAddRange(range: FlatRange, entranceId: number) {
    this.serviceFacade.addAdditionalFlatRange(entranceId, range);
  }

  onUpdateRange(rangeId: number, rangeToUpdate: FlatRange, entranceId: number) {
    this.serviceFacade.updateAdditionalFlatRange(entranceId, rangeId, rangeToUpdate);
  }

  onDeleteRange(rangeId: number, entranceId: number) {
    this.serviceFacade.deleteAdditionalFlatRange(entranceId, rangeId);
  }

  prepareInitialMode(): AddressInputMode {
    if (!this.address) {
      return AddressInputMode.CREATED;
    }

    return null;
  }
}
