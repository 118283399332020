import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { DialogWrapperModule } from '@app/shared/ui';
import { LicenseeReportContentComponent } from './licensee-report-content';
import { LicenseeReportPopupComponent } from './licensee-report-popup.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        DialogWrapperModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        NgxMatDatetimePickerModule,
        NgxTranslateModule
    ],
    declarations: [
        LicenseeReportContentComponent,
        LicenseeReportPopupComponent
    ],
    exports: [LicenseeReportPopupComponent]
})
export class LicenseeReportPopupModule { }
