import { Component, EventEmitter, Output } from '@angular/core';
import { ABOUT_PAYMENT_CONNECTION } from '@app/shared/helpers';

@Component({
  selector: 'app-payments-confirm',
  templateUrl: './payments-confirm.component.html',
  styleUrls: ['./payments-confirm.component.scss']
})
export class PaymentsConfirmComponent {
  public readonly paymentConnectionLink: string = ABOUT_PAYMENT_CONNECTION;
  @Output() risksAccepts: EventEmitter<void> = new EventEmitter<void>();

  public onRisksAccepts(): void {
    this.risksAccepts.emit();
  }
}
