import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ChipModule} from 'primeng/chip';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {isEmpty} from 'lodash';

export namespace TextWithAction {
  export interface Action {
    label: string;
    icon: string;
    action: () => void;
    disabled?: () => boolean;
    tooltip?: string;
    tooltipPosition?: 'left' | 'right' | 'top' | 'bottom';
    className?: string;
    styleClass?: string;
  }
}

@Component({
  selector: 'app-text-with-action',
  standalone: true,
  imports: [CommonModule, ChipModule, ButtonModule, TooltipModule],
  templateUrl: './text-with-action.component.html',
  styleUrls: ['./text-with-action.component.scss']
})
export class TextWithActionComponent {
  protected readonly isEmpty = isEmpty;
  public text: string;
  public actionsRight: TextWithAction.Action[];
  public actionsLeft: TextWithAction.Action[];
}
