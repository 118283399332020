<div class="rda-translation-flats" *ngIf="range[0] && range[1]; else emptyRange">
  <div class="rda-translation-flats-header">
    <h3 class="rda-translation-flats-header__title">
      {{ 'shared.rda.translations.editor.flats.message.determining_the_offset_for_square' | translate }} {{ range[0] }} - {{ range[1] }}
    </h3>
    <p class="rda-translation-flats-header__description">
      {{ 'shared.rda.translations.editor.flats.message.call_sequentially_to_apartments' | translate }}
    </p>
  </div>

  <div class="rda-translation-flats-content">
    <p></p>
    <p class="rda-translation-flats-content__text">
      {{ 'shared.rda.translations.editor.flats.flat' | translate }}
    </p>
    <p></p>
    <p class="rda-translation-flats-content__text">
      {{ 'shared.rda.translations.editor.flats.call' | translate }}
    </p>
    <p class="rda-translation-flats-content__text">
      {{ 'shared.rda.translations.editor.flats.offset' | translate }}
    </p>
    <p></p>
  </div>

  <div
    *ngFor="let flat of totalRangesFlats; let idx= index"
    class="rda-translation-flats-content">
    <div class="rda-translation-flats-content-index">
      <p class="rda-translation-flats-content__text">{{ flat.number }}</p>
    </div>

    <div class="rda-translation-flats-content-flat-number">
      <p class="rda-translation-flats-content-flat-number__text">
        {{ flat.flatNumber }}
      </p>
    </div>

    <p class="rda-translation-flats-content__divider">&#8212;</p>

    <div class="rda-translation-flats-content-flat-responsed">
      <p
        *ngIf="flat.responsedFlatNumber"
        class="rda-translation-flats-content-flat-responsed__text"
      >
        {{ flat.responsedFlatNumber }}
      </p>
    </div>

    <div class="rda-translation-flats-content-calculated-translation">
      <p
        class="rda-translation-flats-content-calculated-translation__text"
        *ngIf="flat.responsedFlatNumber && flat.flatNumber"
      >
        {{ flat.flatNumber - flat.responsedFlatNumber }}
      </p>
    </div>

    <div
      matTooltip="{{ 'shared.rda.translations.editor.flats.tooltip.check_call' | translate }}"
      [matTooltipPosition]="'above'"
      class="rda-translation-flats-content-action"
      [disabled]="loading"
      (click)="onCheckCall(idx)"
    >
      <img
        class="rda-translation-flats-content-action__image"
        src="../../../../../../assets/images/img-call.png">
    </div>
  </div>
</div>
<ng-template #emptyRange>
  <div class="rda-translation-flats-header">
    <h3 class="rda-translation-flats-header__title">
      {{ 'shared.rda.translations.editor.flats.message.define_range_of_apartments_for_address' | translate }}
    </h3>
    <p class="rda-translation-flats-header__description">
      {{ 'shared.rda.translations.editor.flats.message.go_to_settings_of_address_connected' | translate }}
    </p>
  </div>
</ng-template>
