import { FlatRange } from '@app/shared/models';

export class AdditionalRangesFormHelper {
    static rangeValid(
        id: number,
        range: [number, number],
        additionalRanges: FlatRange[],
        entranceRange: [number, number]
    ): boolean {
        return this.rangeBoundsValid(range) &&
            this.additionalRangeValid(id, range, additionalRanges, entranceRange);
    }

    static additionalRangeValid(
        id: number,
        range: [number, number],
        additionalRanges: FlatRange[],
        entranceRange: [number, number]
    ): boolean {
        if (range[0] == null || range[1] === null) {
            return false;
        }

        for (const additionalRange of additionalRanges) {
            if (id !== null && additionalRange.id === id) {
                continue;
            }

            const rangesCrossing: boolean = this.rangesCrossing(
                range[0],
                range[1],
                additionalRange.flatStart,
                additionalRange.flatEnd
            );

            if (rangesCrossing) {
                return false;
            }
        }

        if (this.rangesCrossing(range[0], range[1], entranceRange[0], entranceRange[1])) {
            return false;
        }

        return true;
    }

    private static rangeBoundsValid(range: [number, number]): boolean {
        if (range[0] !== null && range[1] != null) {
            if (range[0] > range[1]) {
                return false;
            }
        }

        return true;
    }

    private static rangesCrossing(as: number, ae: number, bs: number, be: number) {
        return as <= be && ae >= bs;
    }
}
