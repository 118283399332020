<div class="entrance-warnings">
  <div class="entrance-warnings-info-block">
    <h1 class="entrance-warnings-info-block__title">
      {{ 'entrance.page.warnings.title' | translate }}
    </h1>

    <div class="entrance-warnings-info-block-decription">
      <p>{{ 'entrance.page.warnings.message.failed_get_following_information_about_adapter' | translate }}:</p>

      <div class="entrance-warnings-info-block-decription-parameters">
        <p
          class="entrance-warnings-info-block-decription-parameters__text"
          *ngFor="let param of unavaliableParams"
        >
          <strong>{{ param }}</strong>
        </p>
      </div>

      <p>
        <i>
          {{ 'entrance.page.warnings.message.failed_get_following_information_about_adapter' | translate }}
          <span>
            <strong>({{ 'entrance.page.warnings.message.pay_attention_rdaa_update_in_first_place' | translate }})</strong>
          </span>
        </i>
      </p>
    </div>

    <button
      mat-raised-button
      color="primary"
      (click)="onCreateSupportRequest()"
    >
      {{ 'entrance.page.warnings.button.create_support' | translate }}
    </button>
  </div>

  <div class="entrance-warnings-image-block">
    <img
      class="entrance-warnings-image-block__image"
      [src]="resourcePath.ENTRANCE_ERROR | safeUrl"
    >
  </div>
</div>
