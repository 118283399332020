import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@app/shared/components/snackbar';
import { LocalStorageHelper, LocalStorageLoginKey, ResourcePath, ResourcesHelper } from '@app/shared/entities/common';
import { LoginRequest } from '@app/shared/entities/rd';
import { DialogWrapperData } from '@app/shared/ui';
import { Constants } from '@app/shared/helpers';
import { MatDialog } from '@angular/material/dialog';
import {
  ResetPasswordPopupComponent
} from '@app/views/auth/components/reset-password-popup/reset-password-popup.component';
import { Observable, Subject } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog/dialog-ref';
import { takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'environments/environment';

const BRAZIL_HOME_LINK = '/assets/logo/logo-ic-vivo.home.svg';
const ROSDOMOFON_HOME_LINK = '/assets/logo/logo-ic-home-page.svg';
const BRAZIL_LOGO_LINK = 'assets/logo/logo-vivo.main.svg';
const KZ_LOGO_LINK = 'assets/logo/logo-ic-kz.svg';
const ROSDOMOFON_LOGO_LINK = 'assets/logo/logo-ic-main.svg';

@Component({
  selector: 'app-login-content',
  templateUrl: './login-content.component.html',
  styleUrls: ['./login-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginContentComponent implements OnDestroy {
  readonly resourcePath: typeof ResourcePath = ResourcePath;
  public readonly environment = environment;
  public homeImg: string = null;
  public logoImg: string = null;

  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  form: UntypedFormGroup = this.initForm();

  @Output() private login: EventEmitter<{ request: LoginRequest }> = new EventEmitter();
  private _loading: boolean;
  private onDestroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private dialog: MatDialog,
    public resourcesHelper: ResourcesHelper,
    private translate: TranslateService,
    private snackbar: SnackbarService
  ) {
    this.initHomePageConfig();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  get loading(): boolean {
    return this._loading;
  }

  onSubmit() {
    if (!this.form.valid) {
      this.snackbar.showMessage(this.translate.instant('login.page.content.message.empty_data'), 'info');
      return;
    }
    if (this.form.get('saveLogin').value) {
      LocalStorageHelper.setItem(LocalStorageLoginKey.USER_SAVED_LOGIN, this.form.get('username').value);
    }

    this.login.emit({ request: this.form.getRawValue() });
  }

  public onResetPassword(): void {
    let dialogRef: MatDialogRef<
      ResetPasswordPopupComponent,
      DialogWrapperData<null, Observable<void>>
      > = null;

    const data: DialogWrapperData<null, Observable<void>> = {
      title: this.translate.instant('login.page.content.reset.title'),
      componentName: 'ResetPassword',
      submit: (response: Observable<void>) => {
        response.pipe(takeUntil(this.onDestroy$))
          .subscribe(
          () => {},
          (error: HttpErrorResponse ) => {
            this.snackbar.showMessage(
              error?.error
                ? error.error
                : this.translate.instant('login.page.content.reset.message.submit.failed')
              , 'info'
            );
          },
          () => {
            dialogRef.close();
            this.snackbar.showMessage(
              this.translate.instant('login.page.content.reset.message.submit.success'),
              'success'
            );
          });
      }
    };

    dialogRef = this.dialog.open(ResetPasswordPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      username: new UntypedFormControl(LocalStorageHelper.getItem(LocalStorageLoginKey.USER_SAVED_LOGIN), Validators.required),
      password: new UntypedFormControl('', Validators.required),
      saveLogin: new UntypedFormControl(true)
    });
  }

  private initHomePageConfig(): void {
    this.homeImg = environment.vivotec ? BRAZIL_HOME_LINK : ROSDOMOFON_HOME_LINK;
    if (environment.vivotec) {
      this.logoImg = BRAZIL_LOGO_LINK;
      return;
    }
    if (environment.kz) {
      this.logoImg = KZ_LOGO_LINK;
      return;
    }
    this.logoImg = ROSDOMOFON_LOGO_LINK;
  }
}
