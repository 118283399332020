<div class="service-cameras-warning" *ngIf="camerasIdsWithErrors">
  <div class="service-cameras-warning-info-block">
    <h1>{{ 'service.cameras.warning.title' | translate }}</h1>
    <div>
      <p>
        {{ 'service.cameras.warning.cameras_with_id' | translate }}
        <span>
          <strong>{{ camerasIdsWithErrors }}</strong>
        </span>
        {{ 'service.cameras.warning.must_correspond_to_characteristics_video_stream' | translate }}
      </p>

      <div class="service-cameras-warning-info-block-description">
        <div class="service-cameras-warning-info-block-description-criteria">
          <p class="service-cameras-warning-info-block-description-criteria__text">
            <strong>{{ 'service.cameras.warning.bitrate_no_more_than_1_mbps' | translate }}</strong>
          </p>

          <p class="service-cameras-warning-info-block-description-criteria__text">
            <i>{{ 'service.cameras.warning.reduce_number_frames_per_second' | translate }}</i>
          </p>
        </div>

        <div class="service-cameras-warning-info-block-description-criteria">
          <p class="service-cameras-warning-info-block-description-criteria__text">
            <strong>{{ 'service.cameras.warning.maximum_delay_is_not_more_than' | translate }}</strong>
          </p>

          <p class="service-cameras-warning-info-block-description-criteria__text">
            <i>{{ 'service.cameras.warning.set_frequency_support_frame' | translate }}</i>
          </p>
        </div>

      </div>
    </div>
    <div class="service-cameras-warning-info-block-instruction">
      <a
        mat-raised-button
        class="service-cameras-warning-info-block-instruction__link"
        [href]="CAMERAS_GUIDE_LINK | safeUrl"
        target="_blank"
      >
        {{ 'service.cameras.warning.instructions_with_examples_and_explanations' | translate }}
      </a>
    </div>
  </div>

  <div class="service-cameras-warning-image-block">
    <img
      class="service-cameras-warning-image-block__image"
      src="assets/images/cameras-warning.png">
  </div>
</div>
