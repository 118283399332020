import { ActionReducerMap } from '@ngrx/store';
import * as abonentPage from './abonent-page.reducer';
import * as abonentsPage from './abonents-page.reducer';
import * as mailing from './mailing.reducer';

export interface AbonentsModuleState {
  abonentsPage: abonentsPage.AbonentsPageState;
  mailing: mailing.MailingState;
  abonentPage: abonentPage.AbonentPageState;
}

export const abonentsModuleReducers: ActionReducerMap<AbonentsModuleState> = {
  abonentsPage: abonentsPage.abonentsPageReducer,
  mailing: mailing.mailingReducer,
  abonentPage: abonentPage.abonentPageReducer
};
