import {Component, EventEmitter, Input, Output} from '@angular/core';
import { WaitAbonentSignUpsCount } from '@app/shared/models';

@Component({
  selector: 'app-sign-ups-warnings',
  templateUrl: './sign-ups-warnings.component.html',
  styleUrls: ['./sign-ups-warnings.component.scss']
})
export class SignUpsWarningsComponent {
  @Input() waitAbonentSignUpsCount: WaitAbonentSignUpsCount;
  @Output() confirmationOwners: EventEmitter<void> = new EventEmitter<void>();
}
