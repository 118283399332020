import { GitlabComponentsVersions } from '@app/shared/entities/integrations';
import { Camera, Company, KeysResponse, PbxOnRdaResponse, RdaResponse } from '@app/shared/entities/rd';
import { EntrancePageResponse, Flat, LogsResponse, PageData } from '@app/shared/models';
import { NavbarLink } from '@app/shared/ui';
import * as root from '@app/store/reducers';
import { EntrancesPageModel } from '../models';

export interface EntrancesModuleState {
    entrances: EntrancesState;
}

export interface EntrancesRootState extends root.State {
    'entrances': EntrancesModuleState;
}

export interface EntrancesState {
    entrancesLoading: boolean;
    countsLoading: boolean;
    entrances: EntrancesPageModel[];
    counts: NavbarLink[];
    pageData: PageData;

    loadingEntrance: boolean;
    entrance: EntrancePageResponse;
    rda: RdaResponse;
    company: Company;
    loadingKeys: boolean;
    keys: KeysResponse[];
    loadingFlats: boolean;
    flats: Flat[];
    pbxOnRda: PbxOnRdaResponse;
    gitlabVersions: GitlabComponentsVersions;
    loadingCameras: boolean;
    cameras: Camera[];
    camerasWithErrors: Camera[];
    loadingLogs: boolean;
    logs: LogsResponse[];

    incorrectId: boolean;
}

export const entrancesInitialState: EntrancesState = {
    entrancesLoading: true,
    countsLoading: true,
    entrances: undefined,
    counts: undefined,
    pageData: undefined,

    loadingEntrance: undefined,
    rda: undefined,
    company: undefined,
    entrance: undefined,
    loadingKeys: undefined,
    keys: undefined,
    loadingFlats: undefined,
    flats: undefined,
    pbxOnRda: undefined,
    gitlabVersions: undefined,
    loadingCameras: undefined,
    cameras: undefined,
    camerasWithErrors: undefined,
    loadingLogs: undefined,
    logs: undefined,

    incorrectId: undefined
};
