import { Injectable } from '@angular/core';
import { OrdersService } from '@app/views/intercom/services';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { GetOrdersPage, GetOrdersPageFailure, GetOrdersPageSuccess } from '../actions';
import { ActionTypes, AddRdasToOrder, AddRdasToOrderClear, AddRdasToOrderFailure, AddRdasToOrderSuccess, AddScansToOrder, AddScansToOrderClear, AddScansToOrderFailure, AddScansToOrderSuccess, CreateOrder, CreateOrderClear, CreateOrderFailure, CreateOrderSuccess, DeleteOrder, DeleteOrderClear, DeleteOrderFailure, DeleteOrderSuccess, GetOrdersPageClear, UpdateOrder, UpdateOrderClear, UpdateOrderFailure, UpdateOrderSuccess } from '../actions/orders.actions';

@Injectable()
export class OrdersEffects {
    @Effect()
    GetOrdersPage$ = this.actions$.pipe(
        ofType<GetOrdersPage>(ActionTypes.GetOrdersPage),
        switchMap(action =>
            this.ordersService.getOrdersPage(action.page, action.size, action.withoutManufacturer, action.search).pipe(
                map(resp => new GetOrdersPageSuccess(resp.content, resp.totalElements)),
                catchError(error => of(new GetOrdersPageFailure(error)))
            )
        )
    );

    @Effect({ dispatch: false })
    GetOrdersPageClear$ = this.actions$.pipe(
        ofType<GetOrdersPageClear>(ActionTypes.GetOrdersPageClear),
        tap(() => new GetOrdersPageClear())
    );

    @Effect()
    CreateOrder$ = this.actions$.pipe(
        ofType<CreateOrder>(ActionTypes.CreateOrder),
        switchMap(action =>
            this.ordersService.createOrder(action.request).pipe(
                map(resp => new CreateOrderSuccess(resp)),
                catchError(error => of(new CreateOrderFailure(error)))
            )
        )
    );

    @Effect({ dispatch: false })
    CreateOrderClear$ = this.actions$.pipe(
        ofType<CreateOrderClear>(ActionTypes.CreateOrderClear),
        tap(() => new CreateOrderClear())
    );

    @Effect()
    UpdateOrder$ = this.actions$.pipe(
        ofType<UpdateOrder>(ActionTypes.UpdateOrder),
        switchMap(action =>
            this.ordersService.updateOrder(action.orderId, action.request).pipe(
                map(() => new UpdateOrderSuccess(action.orderId, action.request)),
                catchError(error => of(new UpdateOrderFailure(error)))
            )
        )
    );

    @Effect({ dispatch: false })
    UpdateOrderClear$ = this.actions$.pipe(
        ofType<UpdateOrderClear>(ActionTypes.UpdateOrderClear),
        tap(() => new UpdateOrderClear())
    );

    @Effect()
    DeleteOrder$ = this.actions$.pipe(
        ofType<DeleteOrder>(ActionTypes.DeleteOrder),
        switchMap(action =>
            this.ordersService.deleteOrder(action.orderId).pipe(
                map(() => new DeleteOrderSuccess()),
                catchError(error => of(new DeleteOrderFailure(error)))
            )
        )
    );

    @Effect({ dispatch: false })
    DeleteOrderClear$ = this.actions$.pipe(
        ofType<DeleteOrderClear>(ActionTypes.DeleteOrderClear),
        tap(() => new DeleteOrderClear())
    );

    @Effect()
    AddRdasToOrder$ = this.actions$.pipe(
        ofType<AddRdasToOrder>(ActionTypes.AddRdasToOrder),
        switchMap(action =>
            this.ordersService.addRdas(action.orderId, action.addRdasToOrderRequest).pipe(
                map(() => new AddRdasToOrderSuccess()),
                catchError(error => of(new AddRdasToOrderFailure(error)))
            )
        )
    );

    @Effect({ dispatch: false })
    AddRdasToOrderClear$ = this.actions$.pipe(
        ofType<AddRdasToOrderClear>(ActionTypes.AddRdasToOrderClear),
        tap(() => new AddRdasToOrderClear())
    );

    @Effect()
    AddScansToOrder$ = this.actions$.pipe(
        ofType<AddScansToOrder>(ActionTypes.AddScansToOrder),
        switchMap(action =>
            this.ordersService.addScans(action.orderId, action.addScansToOrderRequest).pipe(
                map(() => new AddScansToOrderSuccess()),
                catchError(error => of(new AddScansToOrderFailure(error)))
            )
        )
    );

    @Effect({ dispatch: false })
    AddScansToOrderClear$ = this.actions$.pipe(
        ofType<AddScansToOrderClear>(ActionTypes.AddScansToOrderClear),
        tap(() => new AddScansToOrderClear())
    );

    constructor(private actions$: Actions, private ordersService: OrdersService) { }
}
