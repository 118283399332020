import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { MailingService } from '@app/views/abonents/services';
import { MailingResponse } from '@app/views/abonents/models';
import { GetMailings, GetMailingsClear } from '@app/views/abonents/store/actions';
import { getMailingsStateLoading, getMailingsStateSuccess } from '@app/views/abonents/store/states';
import { Constants } from '@app/shared/helpers/constants';
import { State } from '@app/store/reducers';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';

@Component({
  selector: 'app-mailing-history',
  templateUrl: './mailing-history.component.html',
  styleUrls: ['./mailing-history.component.scss']
})
export class MailingHistoryComponent implements OnInit, OnDestroy {
  readonly pageSize: number = Constants.PAGE_SIZE;

  @Output() selected: EventEmitter<{ mailing: MailingResponse, idx: number }>;
  @Input() selectedMailingIdx: number;
  mailings$: Observable<MailingResponse[]>;
  loading$: Observable<boolean>;
  private isCanBeLoaded: boolean;
  private currentPage: number;

  constructor(
    private store: Store<State>,
    private mailingService: MailingService,
    private router: Router,
    private resolutionService: ResolutionService
  ) {
    this.isCanBeLoaded = false;
    this.currentPage = 0;
    this.initEmitters();
  }

  ngOnInit() {
    this.initLoading();
    this.initStore();
  }

  ngOnDestroy() {
    this.store.dispatch(new GetMailingsClear());
  }

  onRoute(mailing: MailingResponse) {
    const routePath: string = mailing ? ('/mailing/' + mailing.id) : '/mailing/new';
    this.router.navigate([routePath], { state: mailing });
  }

  onSelect(mailing: MailingResponse, idx: number) {
    this.selected.emit({ mailing, idx });
  }

  onCreate() {
    const mailing: MailingResponse = this.mailingService.initEmptyMailingResponse();
    this.selected.emit({ mailing, idx: -1 });
  }

  onLoad() {
    if (this.isCanBeLoaded) {
      this.currentPage++;
      this.getStoreData();
    }
  }

  isMobile(): boolean {
    return this.resolutionService.isMobile;
  }

  private initEmitters() {
    this.selected = new EventEmitter<{ mailing: MailingResponse, idx: number }>();
  }

  private initStore() {
    this.initStoreSelectors();
    this.getStoreData();
  }

  private initStoreSelectors() {
    this.mailings$ = this.store.pipe(
      select(getMailingsStateSuccess),
      map(data => {
        if (data?.mailings.length > 0) {
          this.isCanBeLoaded = this.pageSize * (this.currentPage + 1) / data.totalCount < 1;
          return data.mailings;
        }
      })
    );
  }

  private getStoreData() {
    this.store.dispatch(new GetMailings(this.currentPage, this.pageSize));
  }

  private initLoading() {
    this.loading$ = this.store.select(getMailingsStateLoading);
  }
}
