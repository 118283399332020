import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SoftwareIntercomsModuleState, SoftwareIntercomsPageState, SoftwareIntercomsRootState } from './software-intercoms-page.state';

export const softwareIntercomsModuleSelector =
    createFeatureSelector<SoftwareIntercomsRootState, SoftwareIntercomsModuleState>('software-intercoms');

const selectState = createSelector(
    softwareIntercomsModuleSelector,
    (state: SoftwareIntercomsModuleState) => state.softwareIntercoms
);

const selectSoftwareIntercoms = createSelector(
    selectState,
    (state: SoftwareIntercomsPageState) => state.softwareIntercoms
);

const selectPageData = createSelector(
    selectState,
    (state: SoftwareIntercomsPageState) => state.pageData
)

const selectLoading = createSelector(
    selectState,
    (state: SoftwareIntercomsPageState) => state.loading
);

export const softwareIntercomsPageSelectors = {
    selectState,
    selectSoftwareIntercoms,
    selectLoading, 
    selectPageData,
};
