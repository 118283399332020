import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { DialogWrapperModule } from '@app/shared/ui';

import { FlatAbonentsPopupComponent } from './flat-abonents-popup.component';
import { FlatAbonentsPopupService } from './flat-abonents-popup.service';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        DialogWrapperModule,
        RouterModule,
        SharedPipesModule,
        MaterialModule,
        NgxTranslateModule
    ],
  declarations: [FlatAbonentsPopupComponent],
  exports: [FlatAbonentsPopupComponent],
  providers: [FlatAbonentsPopupService]
})
export class FlatAbonentsPopupModule {}
