import { Injectable } from '@angular/core';
import { PbxOnRdaResponse } from '@app/shared/entities/rd';
import { SelectSearch } from '@app/shared/models';
import { State } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PbxOnRdaSearchSelectResponse } from '..';
import { PbxOnRdaSearchSelectGetPbxOnRda, PbxOnRdaSearchSelectInitUsingPbxOnRdaList, PbxOnRdaSearchSelectInitUsingSelectedPbxOnRda, SelectPbxOnRdaFromPbxOnRdaList } from './pbx-on-rda-search-select.actions';
import { pbxOnRdaSearchSelectSelectors } from './pbx-on-rda-search-select.selectors';

@Injectable()
export class PbxOnRdaSearchSelectFacade {
    constructor(
        private store: Store<State>
    ) { }

    selectedPbxOnRda$: Observable<SelectSearch> = this.store.select(pbxOnRdaSearchSelectSelectors.selectSelectedPbxRda);
    loading$: Observable<boolean> = this.store.select(pbxOnRdaSearchSelectSelectors.selectLoading);

    getPbxOnRdaPage(value?: string) {
        this.store.dispatch(new PbxOnRdaSearchSelectGetPbxOnRda(value));
    }

    initUsingSelectedPbxOnRda(selectedPbxOnRda: SelectSearch) {
        this.store.dispatch(new PbxOnRdaSearchSelectInitUsingSelectedPbxOnRda(selectedPbxOnRda));
    }

    initUsingPbxOnRdaList(pbxOnRdaList: PbxOnRdaResponse[]) {
        this.store.dispatch(new PbxOnRdaSearchSelectInitUsingPbxOnRdaList(pbxOnRdaList));
    }

    selectPbxOnRdaFromPbxOnRdaList(pbxOnRdaIdx: number) {
        this.store.dispatch(new SelectPbxOnRdaFromPbxOnRdaList(pbxOnRdaIdx));
    }

    async addPbxOnRdaRdaListener(
        handleResponse: (rdas: PbxOnRdaSearchSelectResponse[]) => void
    ) {
        await this.store
            .select(pbxOnRdaSearchSelectSelectors.selectPbxOnRdaList)
            .pipe(
                map((pbxOnRdaList: PbxOnRdaSearchSelectResponse[]) => handleResponse(pbxOnRdaList))
            )
            .toPromise();
    }
}
