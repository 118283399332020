<div class="header">
  <h2>
    {{ 'services.popups.flat.filter.title' | translate }}
  </h2>
  <div>
    <button
      mat-fab
      (click)="onClose()"
      color="primary-inverse">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<app-service-flats-filters
  [services]="services"
  [flatsFilters]="flatsFilters"
></app-service-flats-filters>
<div class="filters-buttons">
  <button
    mat-stroked-button
    color="warn"
    (click)="onReset()"
    class="reset-button"
  >
    {{ 'services.popups.flat.filter.button.reset' | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    (click)="onClose()"
    class="apply-button"
  >
    {{ 'services.popups.flat.filter.button.apply' | translate }}
  </button>
</div>
