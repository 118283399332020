import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Camera } from '@app/shared/entities/rd';

@Component({
  selector: 'app-camera-info-container',
  templateUrl: './camera-info-container.component.html',
  styleUrls: ['./camera-info-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CameraInfoContainerComponent {
  @Input() camera: Camera;
}
