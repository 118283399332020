import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  CronDateInputService,
  DEFAULT_POST_PAYMENT,
  DEFAULT_PRE_PAYMENT,
  IPaymentFormModel,
  SnackbarService
} from '@app/shared/components';
import {LocalStorageHelper, LocalStoragePaymentsKey} from '@app/shared/entities';
import {
  CreatePaymentConfigurationRequest,
} from '@app/shared/entities/rd';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {takeUntil, tap} from 'rxjs/operators';

import {PaymentsWizardStore} from '../../store';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';
import {
  PaymentsConfirmBottomSheetComponent
} from '@app/views/payments/components/containers/payments-confirm/payments-confirm-bottom-sheet/payments-confirm-bottom-sheet.component';
import {
  ABOUT_PAYMENT_CONNECTION_ROBOKASSA,
  ABOUT_PAYMENT_CONNECTION_YOOKASSA,
  Constants
} from '@app/shared/helpers';
import {IPaymentsConfirmDialogWizard, PaymentsWizardService} from '@app/views/payments';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {
  PaymentsWizardRobokassaUri
} from '@app/views/payments/components/popups/payments-wizard/models/payments-wizard-robokassa-uri';
import {
  PaymentsWizardRobokassaCulture
} from '@app/views/payments/components/popups/payments-wizard/models/payments-wizard-robokassa-culture';

@Component({
  selector: 'app-payments-wizard-integration',
  templateUrl: './payments-wizard-integration.component.html',
  styleUrls: ['./payments-wizard-integration.component.scss'],
  providers: [CronDateInputService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentsWizardIntegrationComponent implements OnInit, AfterViewInit, OnDestroy {
  public readonly paymentConnectionLinkYookassa: string = ABOUT_PAYMENT_CONNECTION_YOOKASSA;
  public readonly paymentConnectionLinkRobokassa: string = ABOUT_PAYMENT_CONNECTION_ROBOKASSA;

  public readonly paymentTypes = [
    {
      path: 'assets/icons/ic-post-payment.svg',
      name: this.translate.instant('payments.popups.wizard.navbar.postpay'),
      value: false
    },
    {
      path: 'assets/icons/ic-pre-payment.svg',
      name: this.translate.instant('payments.popups.wizard.navbar.prepay'),
      value: true
    }
  ];

  public activePath: BehaviorSubject<string> = new BehaviorSubject<string>('assets/icons/ic-pre-payment.svg');
  public isRisksAccepts: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public inputsSuggestionExpanded = false;
  public readonly loading$: Observable<boolean> = this.store.loading$;
  public readonly cronErrors$: Subject<string[]> = new Subject();
  public form: UntypedFormGroup;

  @Output() private readonly success: EventEmitter<void> = new EventEmitter();
  private readonly onDestroy$: Subject<void> = new Subject();
  private integrationSuccess: boolean;
  public readonly serviceType: typeof PaymentsWizardService = PaymentsWizardService;
  public readonly robokassaUriType: typeof PaymentsWizardRobokassaUri = PaymentsWizardRobokassaUri;
  public readonly robokassaCultureType: typeof PaymentsWizardRobokassaCulture = PaymentsWizardRobokassaCulture;
  public service: PaymentsWizardService;

  constructor(
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private store: PaymentsWizardStore,
    private cronDateInput: CronDateInputService,
    private translate: TranslateService
  ) {
    store.service$.subscribe((service) => {
      this.service = service;
      this.form = this.getForm();
    });
  }

  ngOnInit(): void {
    this.store.integrationSuccess
      .pipe(
        takeUntil(this.onDestroy$),
        tap(() => {
          this.integrationSuccess = true;

          LocalStorageHelper.deleteItem(
            LocalStoragePaymentsKey.INTEGRATION_FORM
          );
          LocalStorageHelper.deleteItem(LocalStoragePaymentsKey.STEPPER);

          this.success.emit();
        })
      )
      .subscribe();

    this.loading$
      .pipe(
        takeUntil(this.onDestroy$),
        tap((loading: boolean) =>
          loading ? this.form.disable() : this.form.enable()
        )
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.form.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        tap((values: IPaymentFormModel) => {
          const errorMessages: string[] = this.cronDateInput.prepareCronErrors(values, this.getFormValue('paymentPeriodShift'));
          this.cronErrors$.next(errorMessages);
        })
      )
      .subscribe();

    this.loadFormFromLocalStorage();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();

    if (!this.integrationSuccess) {
      const data = this.form.getRawValue();
      data.service = this.service;

      LocalStorageHelper.setItem(
        LocalStoragePaymentsKey.INTEGRATION_FORM,
        data
      );
    }
  }

  public onSubmit(cronErrorsExists: boolean): void {
    if (this.cronDateInput.checkErrorAfterSubmit(
      cronErrorsExists, {form: this.form, fieldsToChecking: ['invoicingDate', 'reminingDate', 'blockingDate']}
    )) {
      return;
    } else {
      const {
        apiKey,
        shopId,
        login,
        pass1,
        pass2,
        uri,
        culture,
        invoicingDate,
        reminingDate,
        blockingDate,
        paymentPeriodShift
      }: {
        apiKey: string,
        shopId: string,
        login: string;
        pass1: string;
        pass2: string
        uri: string,
        culture: string,
        invoicingDate: string;
        reminingDate: string;
        blockingDate: string;
        paymentPeriodShift: boolean;
      } = this.form.getRawValue();

      const payload: CreatePaymentConfigurationRequest = {
        serviceType: this.service,
        paymentPeriodShift,
        schedule: {
          billCronExpr: invoicingDate,
          blockCronExpr: blockingDate,
          reminderCronExpr: reminingDate
        },
        configYookassa: {
          apiKey,
          shopId
        },
        configRobokassa: {
          uri,
          login,
          pass1,
          pass2,
          culture
        }
      };

      this.store.createIntegration(payload);
    }
  }

  private loadFormFromLocalStorage(): void {
    const formValues: {
      service: string
      apiKey: string;
      shopId: string;
      login: string,
      pass1: string,
      pass2: string,
      uri: string,
      culture: string,
      blockingDate: string;
      invoicingDate: string;
      paymentPeriodShift: boolean;
      reminingDate: string;
    } | null = LocalStorageHelper.getItem(
      LocalStoragePaymentsKey.INTEGRATION_FORM
    );

    if (formValues !== null) {
      if (formValues.service === this.serviceType.YOOKASSA) {
        this.form.patchValue({
          apiKey: formValues.apiKey,
          shopId: formValues.shopId
        });
      } else if (formValues.service === this.serviceType.ROBOKASSA) {
        this.form.patchValue({
          uri: formValues.uri,
          login: formValues.login,
          pass1: formValues.pass1,
          pass2: formValues.pass2,
          culture: formValues.culture,
        });
      }
    }

    this.onSetAutomatically();
  }

  public onEdit(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.onRisksAccepts();
  }

  public onActivePathChange(value: boolean): void {
    this.activePath.next(value ? 'assets/icons/ic-pre-payment.svg' : 'assets/icons/ic-post-payment.svg');
    value ? this.form.patchValue(DEFAULT_PRE_PAYMENT) : this.form.patchValue(DEFAULT_POST_PAYMENT);
  }

  public getFormValue(controlName: string) {
    return this.form.get(controlName)?.value;
  }

  public onSetAutomatically(): void {
    this.getFormValue('paymentPeriodShift') ?
      this.form.patchValue(DEFAULT_PRE_PAYMENT) :
      this.form.patchValue(DEFAULT_POST_PAYMENT);
  }

  private onRisksAccepts(): void {
    let dialogRef: MatDialogRef<
      PaymentsConfirmBottomSheetComponent,
      IPaymentsConfirmDialogWizard
      > = null;

    const paymentsConfirmDialogWizard: IPaymentsConfirmDialogWizard = {
      title: this.translate.instant('payments.popups.wizard.risks_accepts.title'),
      componentName: 'PaymentsConfirmDialog',
      risksAccepts: () => {
        this.isRisksAccepts.next(true);
        dialogRef.close();
      }
    };

    dialogRef = this.dialog.open(PaymentsConfirmBottomSheetComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data: paymentsConfirmDialogWizard
    });
  }

  private getForm(): UntypedFormGroup {
    let fields: {} = {
      invoicingDate: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      reminingDate: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      blockingDate: new UntypedFormControl(null, {
        validators: [Validators.required]
      }),
      paymentPeriodShift: new UntypedFormControl(true, {
        validators: [Validators.required]
      })
    };

    if (this.service === PaymentsWizardService.YOOKASSA) {
      fields = {...fields, ...{
        apiKey: new UntypedFormControl(null, {
          validators: [Validators.required]
        }),
        shopId: new UntypedFormControl(null, {
          validators: [Validators.required]
        }),
      }};
    } else if (this.service === PaymentsWizardService.ROBOKASSA) {
      fields = {...fields, ...{
          uri: new UntypedFormControl(this.robokassaUriType.RU, {
            validators: [Validators.required]
          }),
          login: new UntypedFormControl(null, {
            validators: [Validators.required]
          }),
          pass1: new UntypedFormControl(null, {
            validators: [Validators.required]
          }),
          pass2: new UntypedFormControl(null, {
            validators: [Validators.required]
          }),
          culture: new UntypedFormControl(this.robokassaCultureType.RU, {
            validators: [Validators.required]
          }),
        }};
    }

    return new UntypedFormGroup(fields);
  }

  public get paymentConnectionLink(): string {
    switch (this.service) {
      case PaymentsWizardService.YOOKASSA:
        return this.paymentConnectionLinkYookassa;
      case PaymentsWizardService.ROBOKASSA:
        return this.paymentConnectionLinkRobokassa;
      default:
        return null;
    }
  }
}
