import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConnectionCreateRequest, ServiceResponse } from '@app/shared/entities/rd';
import { DialogWrapperData } from '@app/shared/ui';
import { Observable } from 'rxjs';
import { AbonentConnectionPopupBody } from './models';
import { AbonentConnectionPopupStore } from './store';

@Component({
  selector: 'app-abonent-connection-popup',
  templateUrl: './abonent-connection-popup.component.html',
  styleUrls: ['./abonent-connection-popup.component.scss'],
  providers: [AbonentConnectionPopupStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbonentConnectionPopupComponent implements OnInit {
  status$: Observable<'loading' | 'loaded' | 'close'> = this.store.status$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<AbonentConnectionPopupBody, null>,
    private dialogRef: MatDialogRef<AbonentConnectionPopupComponent>,
    private store: AbonentConnectionPopupStore
  ) { }

  ngOnInit() {
    this.store.addStatusChangeListener((status => {
      if (status === 'close') {
        this.data.submit(null);
        this.dialogRef.close();
      }
    }));
  }

  onSubmitConnections(request: ConnectionCreateRequest) {
    this.store.createConnections({ request });
  }
}
