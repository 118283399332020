import { Shield } from '@app/views/intercom/models';
import { Actions, ActionTypes } from '../../actions/shields.actions';

export interface State {
  shields: Shield[];
  error: string | null;
  loading: boolean;
  totalCount: number;
}

export const initialState: State = {
  shields: [],
  error: null,
  loading: false,
  totalCount: 0
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GetAllShields: {
      return {
        ...state,
        shields: [],
        error: null,
        loading: true,
      };
    }
    case ActionTypes.GetAllShieldsSuccess: {
      return {
        ...state,
        shields: action.payload,
        totalCount: action.totalCount,
        error: null,
        loading: false
      };
    }
    case ActionTypes.GetAllShieldsFailure: {
      return {
        ...state,
        shields: [],
        error: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
