import { HttpErrorResponse } from '@angular/common/http';
import { CityResponse, EntranceResponse, HouseResponse, StreetResponse } from '@app/shared/models';
import { Actions, ActionTypes } from '../actions/address.actions';

export interface State {
  cities: CityResponse[];
  streets: StreetResponse[];
  houses: HouseResponse[];
  entrances: EntranceResponse[];
  error: string | HttpErrorResponse;
  loading: boolean;
}

export const initialState: State = {
  cities: [],
  streets: [],
  houses: [],
  entrances: [],
  error: null,
  loading: false
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GetCitiesList: {
      return {
        ...state,
        cities: [],
        error: null,
        loading: true
      };
    }

    case ActionTypes.GetCitiesListSuccess: {
      return {
        ...state,
        cities: action.payload,
        error: null,
        loading: false
      };
    }

    case ActionTypes.GetCitiesListFailure: {
      return {
        ...state,
        cities: [],
        error: action.payload,
        loading: false
      };
    }

    case ActionTypes.GetStreetsList: {
      return {
        ...state,
        streets: [],
        error: null,
        loading: true
      };
    }

    case ActionTypes.GetStreetsListSuccess: {
      return {
        ...state,
        streets: action.payload,
        error: null,
        loading: false
      };
    }

    case ActionTypes.GetStreetsListFailure: {
      return {
        ...state,
        streets: [],
        error: action.payload,
        loading: false
      };
    }

    case ActionTypes.GetHousesList: {
      return {
        ...state,
        houses: [],
        error: null,
        loading: true
      };
    }

    case ActionTypes.GetHousesListSuccess: {
      return {
        ...state,
        houses: action.payload,
        error: null,
        loading: false
      };
    }

    case ActionTypes.GetHousesListFailure: {
      return {
        ...state,
        houses: [],
        error: action.payload,
        loading: false
      };
    }

    case ActionTypes.GetEntrancesList: {
      return {
        ...state,
        entrances: [],
        error: null,
        loading: true
      };
    }

    case ActionTypes.GetEntrancesListSuccess: {
      return {
        ...state,
        entrances: action.payload,
        error: null,
        loading: false
      };
    }

    case ActionTypes.GetEntrancesListFailure: {
      return {
        ...state,
        entrances: [],
        error: action.payload,
        loading: false
      };
    }

    case ActionTypes.ClearAddress: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
