import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {camerasPageStateLoading, camerasPageStateSuccess} from '@app/shared/store/states';
import {Observable, Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {State} from '@app/store';
import {TranslateService} from '@ngx-translate/core';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import {Camera} from '@app/shared/entities/rd';
import {FormControl, FormGroup} from '@angular/forms';
import {VideoType} from '@app/shared/containers/camera/camera-thumbnail/models/videoType';
import {
  CustomPaginatorComponent,
  IDefaultOption,
  IPageChange,
  IPaginatorConfig
} from '@app/shared/components/prime-base-table/custom-paginator/custom-paginator.component';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {GetCamerasPage} from '@app/shared/store/actions';
import {VideoManagerN} from '@app/views/services/submodules/video-manager/models/view-manager';
import AddCameraModalInput = VideoManagerN.AddCameraModalInput;
import AddCameraModalOutput = VideoManagerN.AddCameraModalOutput;

@Component({
  selector: 'app-video-manager-add-camera-modal',
  templateUrl: './video-manager-add-camera-modal.component.html',
  styleUrls: ['./video-manager-add-camera-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoManagerAddCameraModalComponent implements OnInit, OnDestroy {
  protected readonly videoMode = VideoType;

  public skeletons: number[] = [];
  public isLoading = true;
  public isGlobalLoading = true;

  public filterForm: FormGroup;
  public pageSize: number;
  public paginatorConfig: IPaginatorConfig<IDefaultOption> = null;

  public cameras$: Observable<{ dataSource: Camera[], totalCount: number }>;
  @ViewChild(CustomPaginatorComponent<{ label: number, value: number }>) public paginator: CustomPaginatorComponent<{
    label: number,
    value: number
  }>;

  private get addCameraModalInput(): AddCameraModalInput {
    return this.dialogConfig?.data || null;
  }

  private destroy$ = new Subject<void>();

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private store: Store<State>,
    private translate: TranslateService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
  ) {
  }

  public ngOnInit(): void {
    this.calculatePageSize();
    this.initLoading();
    this.initFilterArea();
    this.initStore();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onPageChange(event: IPageChange): void {
    this.loadCameras(event.page);
  }

  public isCameraSelected(camera: Camera): boolean {
    return this.filterForm?.get('selectedCamera').value?.id === camera?.id;
  }

  public onPageSizeChange(event: number): void {
    this.pageSize = event;
    this.setSkeletons();
  }

  public onClose(): void {
    this.dialogRef.close(null);
  }

  public onSelectAndClose(): void {
    this.dialogRef.close({
      originalInput: this.addCameraModalInput,
      selectedCamera: this.filterForm?.get('selectedCamera').value
    } as AddCameraModalOutput);
  }

  public selectCamera(camera: Camera): void {
    this.filterForm?.get('selectedCamera').setValue(camera);
  }

  private calculatePageSize(): void {
    this.pageSize = 25;
    this.setSkeletons();
    this.paginatorConfig = {
      options: [
        {label: 10, value: 10},
        {label: 15, value: 15},
        {label: 25, value: 25},
        {label: 100, value: 100},
      ],
      optionLabel: 'label',
      optionValue: 'value',
      first: 0,
      rows: 0,
      totalRecords: 0,
      pageLinkSize: 3,
      pageSize: this.pageSize,
      page: 0,
      currentPageReportTemplate: '{first} - {last} ' + this.translate.instant('shared.prime_base_table.of') + ' {totalRecords}'
    };
  }

  private setSkeletons(): void {
    this.skeletons = [];
    for (let i = 0; i < this.pageSize; i++) {
      this.skeletons.push(i);
    }
  }

  private initLoading(): void {
    this.store.select(camerasPageStateLoading)
      .subscribe((state: boolean) => this.isLoading = state);
  }

  private loadCameras(page: number = 0): void {
    this.store.dispatch(new GetCamerasPage(
      page,
      this.pageSize,
      'createdAt,desc',
      this.filterForm.get('cameraTitle').value,
    ));
  }

  private initFilterArea(): void {
    this.filterForm = new FormGroup<any>({
      cameraTitle: new FormControl<string>(''),
      selectedCamera: new FormControl<Camera>(this.addCameraModalInput?.camera),
      selectedCameraMark: new FormControl<boolean>({value: true, disabled: true}),
    });
    this.filterForm.get('cameraTitle').valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.paginatorConfig.page = 0;
        this.paginator.paginator.changePage(0);
        this.loadCameras(0);
      });
  }

  private initStore(): void {
    this.cameras$ = this.store.pipe(
      select(camerasPageStateSuccess),
      map(resp => {
        if (resp.totalCount !== null) {
          this.paginatorConfig.totalRecords = resp.totalCount;
          this.paginatorConfig.rows = resp.cameras.length;
          this.isGlobalLoading = false;
        }
        return {dataSource: resp.cameras, totalCount: resp.totalCount};
      }),
    );
    this.loadCameras(0);
  }
}
