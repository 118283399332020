import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { SnackbarService } from '@app/shared/components/snackbar';
import {
  AbonentUtilsService,
  CreateAbonentAndGetAccountsResponse,
  ServiceApiService,
  ServiceInfoResponse,
  ServiceResponse,
  ServicesList
} from '@app/shared/entities/rd';
import { Dictionary, parseError } from '@app/shared/helpers';
import { ServicesTypes } from '@app/shared/models';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { from, Observable, Subject } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { AbonentConnectionToolPageMode } from '../models';
import { AbonentConnectionToolService } from './../abonent-connection-tool.service';
import {
  abonentConnectionToolInitialState,
  AbonentConnectionToolState
} from './abonent-connection-tool.state';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AbonentConnectionToolStore extends ComponentStore<AbonentConnectionToolState> implements OnDestroy {
  readonly services$: Observable<ServiceInfoResponse[]> = this.select(
    (state: AbonentConnectionToolState) => state.services
  );
  readonly servicesLoading$: Observable<boolean> = this.select(
    (state: AbonentConnectionToolState) => state.servicesLoading
  );
  readonly abonentDataDisabled$: Observable<boolean> = this.select(
    (state: AbonentConnectionToolState) => state.abonentDataDisabled
  );
  readonly pageMode$: Observable<AbonentConnectionToolPageMode> = this.select(
    (state: AbonentConnectionToolState) => state.pageMode
  );
  readonly abonentAndAccountsResponse$: Observable<CreateAbonentAndGetAccountsResponse> =
    this.select(
      (state: AbonentConnectionToolState) => state.abonentAndAccountsResponse
    );
  readonly selectedServices$: Observable<
    Pick<ServiceResponse, 'id' | 'type'>[]
  > = this.select(
    (state: AbonentConnectionToolState) => state.selectedServices
  );
  readonly connectedServices$: Observable<Dictionary<boolean>> = this.select(
    (state: AbonentConnectionToolState) => state.connectedServices
  );
  readonly servicesForDelete$: Observable<
    Pick<ServiceResponse, 'id' | 'type'>[]
  > = this.select(
    (state: AbonentConnectionToolState) => state.servicesForDelete
  );
  readonly getFlatServices = this.effect(
    (
      options$: Observable<{
        entranceId: number;
        flatId: number;
        hasPhysicalTube: boolean;
      }>
    ) =>
      options$.pipe(
        switchMap((options) => {
          this.updateAbonentDataDisabled(true);
          this.updateServicesLoading(true);

          return this.serviceApiService
            .getEntranceServices(options.entranceId)
            .pipe(
              tapResponse(
                (response: ServiceInfoResponse[]) => {
                  this.updateServices(response);

                  if (options.flatId) {
                    this.getConnectedServices({
                      flatId: options.flatId,
                      services: response
                    });
                  }
                  this.updateServicesLoading(false);
                },
                (error: HttpErrorResponse) => {
                  this.updateServicesLoading(false);
                  this.updateAbonentDataDisabled(false);
                  this.snackbar.showMessage(
                    this.translate.instant('shared.connections.tool.content.message.get_flat_services.failed', {
                      text: parseError(error)
                    })
                  );
                }
              )
            );
        })
      )
  );

  readonly getConnectedServices = this.effect(
    (
      options$: Observable<{ flatId: number; services: ServiceInfoResponse[] }>
    ) =>
      options$.pipe(
        switchMap((options) => {
          this.updateServicesLoading(true);

          return from(
            this.abonentConnectionToolService.getConnectedServices(
              options.flatId,
              options.services
            )
          ).pipe(
            tapResponse(
              (response: ServiceInfoResponse[]) => {
                const connectedServices: Dictionary<boolean> = {};
                let hasHardwareIntercom = false;
                response.forEach((service: ServiceInfoResponse) => {
                  if (
                    !hasHardwareIntercom &&
                    service.dependantServices.filter(
                      (dependantService: ServicesList) =>
                        dependantService.type ===
                        ServicesTypes.HARDWARE_INTERCOM
                    ).length > 0
                  ) {
                    hasHardwareIntercom = true;
                  }
                  return (connectedServices[service.id] = true);
                });
                this.updateConnectedServices({
                  connectedServices,
                  hasHardwareIntercom
                });
                this.updateServicesLoading(false);
              },
              (error: HttpErrorResponse) => {
                this.updateServicesLoading(false);
                this.snackbar.showMessage(
                  this.translate.instant('shared.connections.tool.content.message.get_connected_services.failed', {
                    text: parseError(error)
                  })
                );
              }
            )
          );
        })
      )
  );

  readonly createAbonentAndGetAccounts = this.effect(
    (options$: Observable<{ phone: number }>) =>
      options$.pipe(
        switchMap((options) => {
          this.updateServicesLoading(true);

          return from(
            this.abonentUtilsSerice.createAbonentAndGetAccounts(options.phone)
          ).pipe(
            tapResponse(
              (response: CreateAbonentAndGetAccountsResponse) => {
                this.updateAbonentAndAccountsResponse(response);
              },
              (error: HttpErrorResponse) => {
                this.snackbar.showMessage(
                  this.translate.instant('shared.connections.tool.content.message.create_abonent_and_get_accounts.failed', {
                    text: parseError(error)
                  })
                );
              }
            )
          );
        })
      )
  );

  readonly updateServicesLoading = this.updater(
    (state: AbonentConnectionToolState, servicesLoading: boolean) => {
      return { ...state, servicesLoading };
    }
  );

  readonly updateAbonentDataDisabled = this.updater(
    (state: AbonentConnectionToolState, abonentDataDisabled: boolean) => {
      return { ...state, abonentDataDisabled };
    }
  );

  readonly updateServices = this.updater(
    (state: AbonentConnectionToolState, services: ServiceInfoResponse[]) => {
      return { ...state, services: services };
    }
  );

  readonly updateSelectedServices = this.updater(
    (
      state: AbonentConnectionToolState,
      selectedServices: Pick<ServiceResponse, 'id' | 'type'>[]
    ) => {
      return { ...state, selectedServices };
    }
  );

  readonly updateConnectedServices = this.updater(
    (
      state: AbonentConnectionToolState,
      {
        connectedServices,
        hasHardwareIntercom
      }: {
        connectedServices: Dictionary<boolean>;
        hasHardwareIntercom: boolean;
      }
    ) => {
      return { ...state, connectedServices, hasHardwareIntercom };
    }
  );

  readonly updateServiceForDelete = this.updater(
    (
      state: AbonentConnectionToolState,
      servicesForDelete: Pick<ServiceResponse, 'id' | 'type'>[]
    ) => {
      return { ...state, servicesForDelete };
    }
  );

  readonly updatePageMode = this.updater(
    (
      state: AbonentConnectionToolState,
      pageMode: AbonentConnectionToolPageMode
    ) => {
      return { ...state, pageMode };
    }
  );

  readonly updateAbonentAndAccountsResponse = this.updater(
    (
      state: AbonentConnectionToolState,
      abonentAndAccountsResponse: CreateAbonentAndGetAccountsResponse
    ) => {
      return { ...state, abonentAndAccountsResponse, servicesLoading: false };
    }
  );

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private snackbar: SnackbarService,
    private serviceApiService: ServiceApiService,
    private abonentUtilsSerice: AbonentUtilsService,
    private abonentConnectionToolService: AbonentConnectionToolService,
    private translate: TranslateService
  ) {
    super(abonentConnectionToolInitialState);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  addAbonentAccountsListener(
    cb: (response: CreateAbonentAndGetAccountsResponse) => void
  ) {
    this.select(
      (state: AbonentConnectionToolState) => state.abonentAndAccountsResponse
    )
      .pipe(
        takeUntil(this.onDestroy$),
        filter((response) => response?.accounts?.length !== undefined)
      )
      .subscribe((response) => cb(response));
  }
}
