import {Injectable, OnDestroy} from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {LocalStorageGeneralKey, LocalStorageHelper} from '@app/shared/entities/common';
import {Constants} from '@app/shared/helpers';
import {DialogWrapperData} from '@app/shared/ui/dialog-wrapper';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ConfirmComponentConnectionBottomSheetComponent} from './confirm-component-connection-bottom-sheet';
import {ConfirmComponentConnectionDialogComponent} from './confirm-component-connection-dialog';
import {TranslateService} from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class ConfirmComponentConnectionHelper implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  private dialogRef: MatDialogRef<ConfirmComponentConnectionDialogComponent>;
  private bottomSheetRef: MatBottomSheetRef<ConfirmComponentConnectionBottomSheetComponent>;

  constructor(
    private dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    private translate: TranslateService
  ) {
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  showBottomSheet(resolve: () => void) {
    if (this.isHidden()) {
      resolve();
      return;
    }

    this.bottomSheetRef = this.bottomSheet.open(ConfirmComponentConnectionBottomSheetComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data: this.prepareData(() => this.bottomSheetRef.dismiss())
    });

    this.bottomSheetRef
      .afterDismissed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => resolve());
  }

  showDialog(resolve: () => void) {
    if (this.isHidden()) {
      resolve();
      return;
    }

    this.dialogRef = this.dialog.open(ConfirmComponentConnectionDialogComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data: this.prepareData(() => this.dialogRef.close())
    });

    this.dialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => resolve());
  }

  private prepareData(resolve: () => void) {
    return {
      title: this.translate.instant('shared.confirm.component.connection.title'),
      componentName: 'ConfirmComponentConnection',
      submit: (confirmed: boolean) => {
        if (confirmed) {
          LocalStorageHelper.setItem(LocalStorageGeneralKey.HIDE_CONNECT_CONFIRMATION_POPUP, true);
        }

        resolve();
      }
    } as DialogWrapperData<null, boolean>;
  }

  private isHidden() {
    return !!LocalStorageHelper.getItem(LocalStorageGeneralKey.HIDE_CONNECT_CONFIRMATION_POPUP);
  }
}
