import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@app/shared/api';
import { GitlabComponentsVersions } from '@app/shared/entities/integrations/gitlab';
import { environment } from 'environments/environment';
import { GitlabTag } from '@app/shared/entities/integrations/gitlab/models/gitlab-tag.model';
import { GitlabTagReleaseHelper } from '@app/shared/entities/integrations/gitlab/helpers/gitlab-tag-release.helper';
import { GitlabTagType } from '@app/shared/entities/integrations/gitlab/enums/gitlab-tag-type.enum';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class GitlabService extends ApiService {
  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.projectsVersionsUrl, ['v1']);
  }

  getLatestVersions(): Observable<GitlabComponentsVersions> {

    const projects = ['rdaa', 'rdos', 'stm_driver'];
    const projectVersions: Observable<GitlabTag[]>[] = projects.map(project =>
      this.get(`/project_versions/${project}`, 1)
    );

    return forkJoin(projectVersions).pipe(
      map((resps: GitlabTag[][]): GitlabComponentsVersions => {

        const rdosLatest: GitlabTag = GitlabTagReleaseHelper.findLastRelease(resps, GitlabTagType.RDOS);
        const rdaaLatest: GitlabTag = GitlabTagReleaseHelper.findLastRelease(resps, GitlabTagType.RDAA);
        const stmDriverLatest: GitlabTag = GitlabTagReleaseHelper.findLastRelease(resps, GitlabTagType.STM_DRIVER);

        return {
          rdosLatest: rdosLatest ? rdosLatest.name : null,
          rdaaLatest: rdaaLatest ? rdaaLatest.name : null,
          stmDriverLatest: stmDriverLatest ? stmDriverLatest.name : null,
        };
      })
    );
  }
}


