import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedDirectivesModule } from '@app/shared/directives';
import { InfoTableModule } from '@app/shared/ui';
import { ServiceEditPopupModule } from '../../popups';
import { ServiceSupportRequestPopupModule } from './../../popups';
import { ServiceConfigComponent } from './service-config.component';
import {NgxTranslateModule} from '@app/translate/translate.module';
import {InputSwitchModule} from 'primeng/inputswitch';
import {ReactiveFormsModule} from '@angular/forms';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {SkeletonModule} from 'primeng/skeleton';
import {TooltipModule} from 'primeng/tooltip';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        InfoTableModule,
        ServiceEditPopupModule,
        SharedDirectivesModule,
        ServiceSupportRequestPopupModule,
        NgxTranslateModule,
        InputSwitchModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,
        SkeletonModule,
        TooltipModule
    ],
    declarations: [ServiceConfigComponent],
    exports: [ServiceConfigComponent]
})
export class ServiceConfigModule { }
