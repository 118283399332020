import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { InfoTableRecordComponent } from './info-table-record/info-table-record.component';
import { InfoTableComponent } from './info-table.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule
    ],
    declarations: [InfoTableComponent, InfoTableRecordComponent],
    exports: [InfoTableComponent, InfoTableRecordComponent]
})
export class InfoTableModule { }
