import { Injectable } from '@angular/core';
import { LoginRequest } from '@app/shared/entities/rd';
import { State } from '@app/store/reducers';
import { Login, LoginAuthenticated, Logout } from '@app/views/auth/store/auth.actions';
import { authSelector } from '@app/views/auth/store/auth.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable()
export class AuthFacade {
    loading$: Observable<boolean> = this.store.select(authSelector.getLoading);
    authenticated$: Observable<boolean> = this.store.select(authSelector.getAuthenticated);

    constructor(
        private store: Store<State>
    ) { }

    loginAuthenticated(authenticated: boolean) {
        this.store.dispatch(new LoginAuthenticated(authenticated));
    }

    login(request: LoginRequest) {
        this.store.dispatch(new Login(request));
    }

    logout(redirectUri?: string) {
        this.store.dispatch(new Logout(redirectUri));
    }
}
