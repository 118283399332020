import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  ConfirmComponentConnectionHelper,
  IntercomKeysEditContainerData,
  IntercomKeysEditContainerResponse,
  SnackbarService
} from '@app/shared/components';
import {ResolutionService} from '@app/shared/entities/common';
import {KeysResponse, RdaResponse} from '@app/shared/entities/rd';
import {Constants} from '@app/shared/helpers';
import {LocationResponse, ServicesTypes} from '@app/shared/models';
import {DialogWrapperData} from '@app/shared/ui/dialog-wrapper';
import {
  ServiceDeleteKeyPopupComponent,
  ServiceDeleteKeyPopupData,
  ServiceIntercomKeyEditPopupComponent
} from '@app/views/services/components/popups';
import {ServiceFacade} from '../../../store';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-service-intercom-keys',
  templateUrl: './service-intercom-keys.component.html',
  styleUrls: ['./service-intercom-keys.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceIntercomKeysComponent {
  @Input() keys!: KeysResponse[];
  @Input() serviceType!: ServicesTypes;
  @Input() locations!: LocationResponse[];
  @Input() rdas: RdaResponse[];
  @Input() options: { disabledActions: boolean, intercomRequired: boolean } = {
    disabledActions: false,
    intercomRequired: false
  };

  constructor(
    private dialog: MatDialog,
    private resolution: ResolutionService,
    private snackbar: SnackbarService,
    private serviceFacade: ServiceFacade,
    private confirmComponentConnectionHelper: ConfirmComponentConnectionHelper,
    private translate: TranslateService
  ) {
  }

  onAdd() {
    if (this.serviceType === ServicesTypes.SOFTWARE_INTERCOM) {
      if (this.rdas.findIndex(rda => rda.intercomType) === -1) {
        this.snackbar.showMessage(this.translate.instant('service.intercom.keys.message.indicate_type_of_adapter_to_select_key'), 'info');
        return;
      }
    }

    const data: DialogWrapperData<Partial<IntercomKeysEditContainerData>, IntercomKeysEditContainerResponse> = {
      title: this.translate.instant('service.intercom.keys.add.title'),
      componentName: `Add${this.serviceType}RdaKey`,
      body: {},
      submit: (event: IntercomKeysEditContainerResponse) => {
        if (this.serviceType === ServicesTypes.GATE) {
          this.confirmComponentConnectionHelper.showBottomSheet(
            () => this.serviceFacade.addServiceKey(event.intercomInfo, event.request)
          );
        } else {
          this.serviceFacade.addServiceKey(event.intercomInfo, event.request);
        }
      }
    };

    this.dialog.open(ServiceIntercomKeyEditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '500px',
      autoFocus: false,
      data
    });
  }

  onEdit(key: KeysResponse) {
    const data: DialogWrapperData<Partial<IntercomKeysEditContainerData>, IntercomKeysEditContainerResponse> = {
      title: this.translate.instant('service.intercom.keys.edit.title'),
      body: {key},
      componentName: `Edit${this.serviceType}RdaKey`,
      submit: (event: IntercomKeysEditContainerResponse) =>
        this.serviceFacade.updateServiceKey(event.keyId, event.request, event.locationName)
    };

    this.dialog.open(ServiceIntercomKeyEditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '500px',
      autoFocus: false,
      data
    });
  }

  onDelete(key: KeysResponse) {
    const data: DialogWrapperData<ServiceDeleteKeyPopupData, null> = {
      title: this.translate.instant('service.intercom.keys.delete.title'),
      componentName: `Delete${this.serviceType}${key.adapterInfo.ipType ? 'IpIntercom' : 'Rda'}Key`,
      body: {location: key.location, intercomKeyType: key.type},
      submit: () => this.serviceFacade.deleteServiceKey(key.id, key.adapterId)
    };

    this.dialog.open(ServiceDeleteKeyPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }
}
