<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'add.intercom.form.field.uid' | translate }}
    </mat-label>
    <input
      type="text"
      placeholder="{{ 'add.intercom.form.placeholder.uid' | translate }}"
      formControlName="uid"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      matInput
      required
    />
  </mat-form-field>

  <div class="action-block">
    <button type="submit" mat-raised-button color="primary">
      {{ 'add.intercom.form.button.submit' | translate }}
    </button>
  </div>
</form>
