import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';
import { RdosImage } from '@app/views/dashboard/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RdosImageApiService extends ApiService {
    constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
        super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1']);
    }

    getRdosImagesList(): Observable<RdosImage[]> {
        return this.get<RdosImage[]>('/rdos_image', 1);
    }
}
