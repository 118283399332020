import { GitlabComponentsVersions } from '@app/shared/entities/integrations';
import { ActiveHistoryResponse, Camera, Company, IntercomType, KeysResponse, PbxOnRdaResponse, RdaResponse, ServiceConnection, ServiceEntranceFlatResponse, ServiceResponse, ServiceSignUpsResponse, ServicesList } from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';
import {
  Address,
  LocationResponse,
  LogsComponentType,
  LogsResponse,
  ServicesTypes,
  TranslationTuningResponse
} from '@app/shared/models';
import * as root from '@app/store/reducers';
import { ServiceFlatsFilters } from '../components';
import { ServiceActivitySource, ServiceConnectionWithType, ServicePageMode } from '../models';
import { ServiceEntrancesFlats } from './../components/containers/service-flats/models/service-entrances-flats.model';

export interface ServicesModuleState {
    general: Partial<{
        service: Partial<Pick<ServiceResponse, 'id' | 'name' | 'customName' | 'type' | 'tariff'>>;
        company: Company;
        pageMode: ServicePageMode;
        intercomTypes: IntercomType[];
        keysLocations: LocationResponse[];
        camerasLocations: LocationResponse[];
        componentsVersions: GitlabComponentsVersions;
        dependantServices: Pick<ServiceResponse, 'id' | 'type'>[];
        dependantServicesExtended: ServiceResponse[]
    }>;

    activity: Partial<{
        activeHistoryCameras: Dictionary<ActiveHistoryResponse[]>;
        activeHistoryIntercoms: Dictionary<ActiveHistoryResponse[]>;
        logs: Partial<{
            timeRange: number;
            totalCount: number;
            currentCount: number;
            blocksCount: number;
            data: LogsResponse[];
            loading: boolean;
            componentType: LogsComponentType;
            extendedMode: boolean;
            savedData: LogsResponse[];
            source: ServiceActivitySource;
            keysSources: ServiceActivitySource[];
            rdasSources: ServiceActivitySource[];
            camerasSources: ServiceActivitySource[];
            sources: ServiceActivitySource[];
        }>;
    }>;

    abonents: Partial<{
        loading: boolean;
        fillEmptyFlats: boolean;
        flats: ServiceEntrancesFlats;
        filteredFlats: ServiceEntrancesFlats;
        filters: ServiceFlatsFilters;
    }>;

    settings: Partial<{
        entrances: Address[];
        rdas: RdaResponse[];
        cameras: Camera[];
        keys: KeysResponse[];
        pbxOnRda: Dictionary<PbxOnRdaResponse>;
        translationTunings: Dictionary<TranslationTuningResponse[]>;
    }>;

    onvif: Partial<{
      isActive: boolean;
    }>;
}

export interface ServicesRootState extends root.State {
    'services': ServicesModuleState;
}

export const servicesInitialState: ServicesModuleState = {
    general: {
        service: {},
        pageMode: ServicePageMode.ACTIVITY
    },

    activity: {
        activeHistoryCameras: {},
        activeHistoryIntercoms: {},
        logs: {}
    },

    abonents: {
        fillEmptyFlats: true,
        filters: {
          activeAbonent: false,
          blockedAbonent: false,
          phone: '',
          emptyFlat: false,
          blockedServices: [],
          activeServices: [],
          stoppedServices: []
        }
    },

    settings: {
        pbxOnRda: {},
        translationTunings: {},
    },

    onvif: {
      isActive: false
    }
};
