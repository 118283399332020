import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConfirmComponentConnectionHelper } from '@app/shared/components';
import { Camera, IntercomPanelResponse, RdaResponse } from '@app/shared/entities/rd';
import { Address, LocationResponse } from '@app/shared/models';
import { ServiceFacade } from '@app/views/services/store';

@Component({
  selector: 'app-software-intercom-wizard-content-camera',
  templateUrl: './software-intercom-wizard-content-camera.component.html',
  styleUrls: ['./software-intercom-wizard-content-camera.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomWizardContentCameraComponent {
  @Input() set cameras(cameras: Camera[]) {
    this.camera = cameras?.[0];
  }
  @Input() intercoms: RdaResponse[];
  @Input() loading: boolean;
  @Input() addresses: Address[];
  @Input() camerasLocations: LocationResponse[];
  @Input() avaliableIntercomPanelsLocations: LocationResponse[];
  @Input() intercomPanels: IntercomPanelResponse[];
  @Input() onvif: boolean;
  camera: Camera;

  constructor(
    private serviceFacade: ServiceFacade,
    private connectionHelper: ConfirmComponentConnectionHelper
  ) { }

  onSubmit(camera: Camera, intercomPanelId: number) {
    if (this.camera) {
      this.serviceFacade.updateServiceCamera(camera);
      this.serviceFacade.activeServiceOnvif(camera);
      return;
    }

    this.connectionHelper.showBottomSheet(() => {
        this.serviceFacade.addServiceCamera(camera, intercomPanelId);
        this.serviceFacade.activeServiceOnvif(camera);
      }
    );
  }

  onSubmitOnvif(camera: Camera) {
    this.serviceFacade.tuneServiceOnvif(camera);
  }
}
