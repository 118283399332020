import { Account } from '@app/shared/entities/rd';
import { Flat } from '@app/shared/models';
import { ServiceConnectionServiceResponse } from './service-connection-service-response.model';

export interface ServiceConnection {
    id: number;
    flat: Flat;
    account: Account;
    blocked?: boolean;
    service: ServiceConnectionServiceResponse;
    tariff: number;
}
