import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {SnackbarService} from '@app/shared/components/snackbar';
import {AbonentUtilsService, CreateAbonentAndGetAccountsResponse} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {ComponentStore, tapResponse} from '@ngrx/component-store';
import {from, Observable, Subject} from 'rxjs';
import {filter, switchMap, takeUntil} from 'rxjs/operators';
import {ServiceBlockPhysicalTubePopupMode} from '../models';
import {
  serviceBlockPhysicalTubeContentInitialState,
  ServiceBlockPhysicalTubeContentState
} from './service-block-physical-tube-content.state';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServiceBlockPhysicalTubeContentStore extends ComponentStore<ServiceBlockPhysicalTubeContentState> implements OnDestroy {
  readonly abonentAndAccountsLoading$: Observable<boolean> = this.select((state: ServiceBlockPhysicalTubeContentState) => state.abonentAndAccountsLoading);
  readonly pageMode$: Observable<ServiceBlockPhysicalTubePopupMode> = this.select((state: ServiceBlockPhysicalTubeContentState) => state.pageMode);
  readonly abonentAndAccountsResponse$: Observable<CreateAbonentAndGetAccountsResponse> = this.select((state: ServiceBlockPhysicalTubeContentState) => state.abonentAndAccountsResponse);

  readonly createAbonentAndGetAccounts = this.effect((options$: Observable<{ phone: number }>) =>
    options$.pipe(
      switchMap((options) => {
        this.updateLoading(true);

        return from(this.abonentUtilsSerice.createAbonentAndGetAccounts(options.phone)).pipe(
          tapResponse(
            (response: CreateAbonentAndGetAccountsResponse) => {
              this.updateLoading(false);
              this.updateAbonentAndAccountsResponse(response);
            },
            (error: HttpErrorResponse) => {
              this.updateLoading(false);
              this.snackbar.showMessage(
                this.translate.instant('services.popups.block.physical.tube.content.message.create_abonent_and_get_accounts.failed', {
                  text: parseError(error)
                })
              );
            }
          )
        );
      })
    )
  );

  readonly updateLoading = this.updater((state: ServiceBlockPhysicalTubeContentState, loading: boolean) => {
    return {...state, abonentAndAccountsLoading: loading};
  });

  readonly updatePageMode = this.updater((state: ServiceBlockPhysicalTubeContentState, pageMode: ServiceBlockPhysicalTubePopupMode) => {
    return {...state, pageMode};
  });

  readonly updateAbonentAndAccountsResponse = this.updater((state: ServiceBlockPhysicalTubeContentState, abonentAndAccountsResponse: CreateAbonentAndGetAccountsResponse) => {
    return {...state, abonentAndAccountsResponse, servicesLoading: false};
  });

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private snackbar: SnackbarService,
    private abonentUtilsSerice: AbonentUtilsService,
    private translate: TranslateService
  ) {
    super(serviceBlockPhysicalTubeContentInitialState);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  addAbonentAccountsListener(cb: (response: CreateAbonentAndGetAccountsResponse) => void) {
    this.select((state: ServiceBlockPhysicalTubeContentState) => state.abonentAndAccountsResponse)
      .pipe(
        takeUntil(this.onDestroy$),
        filter(response => response?.accounts?.length !== undefined)
      )
      .subscribe(response => cb(response));
  }
}
