<form
  [formGroup]="form"
  (submit)="onEdit()"
  autocomplete="off"
  class="popup"
>
  <mat-form-field appearance="outline" class="relative-size">
    <mat-label>
      {{ 'service.edit.content.field.name' | translate }}
    </mat-label>

    <input
      matInput
      placeholder="{{ 'service.edit.content.placeholder.name' | translate }}"
      [formControlName]="'customName'"
      autocomplete="off"
    >

    <mat-hint>
      {{ 'service.edit.content.hint.name' | translate }}
    </mat-hint>
  </mat-form-field>

  <div class="popup-actions">
    <button
      mat-raised-button
      color="primary"
      type="submit"
      [disabled]="loading"
      class="popup-actions__button"
    >
      {{ 'service.edit.content.button.submit' | translate }}
    </button>
  </div>
</form>
