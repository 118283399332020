import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserRoleType } from '@app/core/models';
import { MenuService } from '@app/core/services';
import { CoreFacade } from '@app/core/store';
import { PermissionsService, PermissionsTreeKey } from '@app/security/permissions';
import { ChangePasswordPopupComponent, LicenseeReportPopupComponent, SnackbarService } from '@app/shared/components';
import {
  ChangePasswordRequest,
  CompanyUtilsService,
  LicenseeReportRequest,
  ReportsUtils
} from '@app/shared/entities/rd';
import { Constants } from '@app/shared/helpers';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData } from '@app/shared/ui';
import { AuthFacade } from '@app/views/auth/store';
import { Observable } from 'rxjs';
import { MenuHelper } from '@app/core';
import { ServiceFacade } from '@app/views/services';
import { Links } from '@app/shared/helpers/wiki-links.enum';
import { TokenService } from '@app/security';
import { environment } from 'environments/environment';
import {TranslateService} from '@ngx-translate/core';
const { version: appVersion } = require('../../../../../package.json');

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuContainerComponent implements OnInit, AfterViewInit {
  readonly environment = environment;
  readonly userRoleType: typeof UserRoleType = UserRoleType;
  readonly permissionsTreeKey: typeof PermissionsTreeKey = PermissionsTreeKey;
  protected companyId: number;

  @Input() activeRequests: number;
  @Input() authenticated: boolean;
  @Input() unresolvedIssues: number;
  companyName: string;
  licenseAgreement: string;
  appVersion: string;
  mdWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.MD_W_DOWN);

  constructor(
    public permissions: PermissionsService,
    private router: Router,
    private menu: MenuService,
    private dialog: MatDialog,
    private coreFacade: CoreFacade,
    private authFacade: AuthFacade,
    private resolution: ResolutionService,
    private companyUtilsService: CompanyUtilsService,
    private serviceFacade: ServiceFacade,
    private snackbar: SnackbarService,
    private tokenService: TokenService,
    private translate: TranslateService
  ) {
    this.appVersion = appVersion;
  }

  async ngOnInit() {
    this.companyName = MenuHelper.getCompanyName() ?? this.translate.instant('core.menu.profile');
    this.licenseAgreement = MenuHelper.getLicenseAgreement();
    this.companyId = MenuHelper.getCompanyId();
    if (this.companyId) {
      this.serviceFacade.getServiceCompany(this.companyId);
    } else {
      this.snackbar.showMessage(this.translate.instant('core.menu.message.cross_profile'));
    }
  }

  get howToBuy(): string {
    return Links.HowToBuy;
  }

  get documentationLink(): string {
    return Links.Inst;
  }

  get newsLink(): string {
    return Links.News;
  }

  get telegramLink(): string {
    return Links.Telegram;
  }

  ngAfterViewInit() {
    this.menu.loaded = false;
  }

  get notBlockedUser(): boolean {
    return !this.permissions.someRoleContains([UserRoleType.BLOCKED_USER]);
  }

  onOpenSettingsPopup() {
    this.onMenuClick();

    const data: DialogWrapperData<null, ChangePasswordRequest> = {
      title: this.translate.instant('core.menu.change_password.title'),
      componentName: 'ChangePassword',
      submit: (request: ChangePasswordRequest) =>
        this.coreFacade.changePassword(request)
    };

    this.dialog.open(ChangePasswordPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ? '100%' : '350px',
      data
    });
  }

  onLogout() {
    this.onMenuClick();
    this.authFacade.logout();
  }

  onMenuClick() {
    this.menu.click();
  }

  onSelectEstimateReport() {
    if (this.companyUtilsService.rosDomofonCompany()) {
      this.router.navigate(['dashboard']);
      return;
    }

    const offerLicenseAgreement: boolean = ReportsUtils.offerLicenseAgreement(this.licenseAgreement);

    if (offerLicenseAgreement === null) {
      return;
    }

    offerLicenseAgreement ?
      this.getUsageStatisticsReport() : this.getLicenseeReport();
  }

  private getLicenseeReport() {
    const data: DialogWrapperData<null, LicenseeReportRequest> = {
      title: this.translate.instant('core.menu.licensee_report.title'),
      componentName: 'GetLicenseeReport',
      submit: (request: LicenseeReportRequest) =>
        this.coreFacade.getLicenseeReport(request)
    };

    this.dialog.open(LicenseeReportPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  private getUsageStatisticsReport() {
    const data: DialogWrapperData<null, LicenseeReportRequest> = {
      title: this.translate.instant('core.menu.usage_statistics_report.title'),
      componentName: 'GetUsageStatisticsReport',
      submit: (request: LicenseeReportRequest) =>
        this.coreFacade.getUsageStatisticsReport(request)
    };

    this.dialog.open(LicenseeReportPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  get isShowLanguageSwitch(): boolean {
    return this.environment.languages.length > 1;
  }
}
