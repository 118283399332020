export interface CompanyDataResponse {
    id: number;
    isBlocked: boolean;
    name: string;
    shortName: string;
    licenseAgreement: string;
    inn: string;
    ogrn?: string;
    address: string;
    paymentAccount?: string;
    paymentBank?: string;
    paymentBic?: string;
    hasEdm: boolean;
    paymentCorrespondentAccount: string;
    managerId: number;
    smsGateOwner: boolean;
    applicationLink: string;
    baseSum: number;
    subscribersLimit: number;
    subscribersOverLimitSum: number;
    surveillanceHistDepth: number;
    intercomHistDepth: number;
    surveillanceSum: number;
    intercomCameraSum: number;
    gateBaseSum: number;
    gateCameraSum: number;
    gateHistDepth: number;
    bannerSum: number;
    signUpWebView: boolean;
    brandName: string;
    supportPhone: string;
    supportChatEnabled: boolean;
    banner: Blob;
    paymentLink: string;
    personalAccountLink: string;
    autoSignOffer: string;
    autoSignRequireContract: boolean;
    signUpNotification: boolean;
    adaptersStatesNotification: boolean;
    camerasStatesNotification: boolean;
    delegationsNotification: boolean;
    country: {
        shortName: string,
        name: string,
        currency: string
    };
    managerEmail: string;
    licenseeShort: string;
    licensor: string;
    licensee: string;
    mailToList: string;
}

export interface UpdateCompanyRequest {
    company: Partial<CompanyDataResponse>;
    image?: File;
}


export interface CompanyPageState {
    company: Partial<CompanyDataResponse> | null;
    loading: boolean;
}

export const companyPageInitialState: CompanyPageState = {
    company: null,
    loading: false
};
