import { CreateAbonentAndGetAccountsResponse } from '@app/shared/entities/rd';
import { ServiceBlockPhysicalTubePopupMode } from '../models';

export interface ServiceBlockPhysicalTubeContentState {
    abonentAndAccountsLoading: boolean;
    pageMode: ServiceBlockPhysicalTubePopupMode;
    abonentAndAccountsResponse: CreateAbonentAndGetAccountsResponse;
}

export const serviceBlockPhysicalTubeContentInitialState = {
    abonentAndAccountsLoading: undefined,
    pageMode: ServiceBlockPhysicalTubePopupMode.CONFIRM,
    abonentAndAccountsResponse: undefined
};
