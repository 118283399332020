import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { PermissionsTreeKey } from '@app/security/permissions';
import { CompanyDataResponse } from '@app/views/company/store/company-page.state';

@Component({
    selector: 'app-company-tarifs',
    templateUrl: './company-tarifs.component.html',
    styleUrls: ['./company-tarifs.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyTarifsComponent {
    readonly permissionsTreeKey: typeof PermissionsTreeKey = PermissionsTreeKey;
    @Input() company: CompanyDataResponse;
    @Input() payment: number;
    @Input() loading: boolean;
    @Input() smWDownBreakpoint: boolean;

}
