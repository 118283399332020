import { ActionReducerMap } from '@ngrx/store';
import * as address from './address.reducer';
import * as addCamera from './cameras/add-camera.reducer';
import * as deleteCamera from './cameras/delete-camera.reducer';
import * as disconnectDefaultCamera from './cameras/disconnect-default-camera.reducer';
import * as getCamerasPage from './cameras/get-cameras-page.reducer';
import * as setCameraAsDefault from './cameras/set-camera-as-default.reducer';
import * as updateCamera from './cameras/update-camera.reducer';
import * as availableIntercomsV1 from './intercom/available-intercoms-v1.reducer';
import * as availableIntercoms from './intercom/available-intercoms.reducer';
import * as disconnectAdapter from './intercom/disconnect-adapter.reducer';
import * as newAdapters from './intercom/get-new-intercoms.reducer';
import * as intercomTypes from './intercom/intercom-types.reducer';
import * as updateIntercom from './intercom/update-intercom.reducer';

export interface SharedModuleStates {
  availableIntercoms: availableIntercoms.State;
  availableIntercomsV1: availableIntercomsV1.State;
  updateIntercom: updateIntercom.State;
  intercomTypes: intercomTypes.State;
  newAdapters: newAdapters.State;
  addCamera: addCamera.State;
  deleteCamera: deleteCamera.State;
  getCamerasPage: getCamerasPage.State;
  updateCamera: updateCamera.State;
  address: address.State;
  disconnectAdapter: disconnectAdapter.State;
  setCameraAsDefault: setCameraAsDefault.State;
  disconnectDefaultCamera: disconnectDefaultCamera.State;
}

export const reducers: ActionReducerMap<SharedModuleStates> = {
  availableIntercoms: availableIntercoms.reducer,
  availableIntercomsV1: availableIntercomsV1.reducer,
  updateIntercom: updateIntercom.reducer,
  intercomTypes: intercomTypes.reducer,
  newAdapters: newAdapters.reducer,
  addCamera: addCamera.reducer,
  deleteCamera: deleteCamera.reducer,
  getCamerasPage: getCamerasPage.reducer,
  updateCamera: updateCamera.reducer,
  address: address.reducer,
  disconnectAdapter: disconnectAdapter.reducer,
  setCameraAsDefault: setCameraAsDefault.reducer,
  disconnectDefaultCamera: disconnectDefaultCamera.reducer
};
