import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SnackbarService } from '@app/shared/components/snackbar';
import {
  AwxJobLogResponse,
  AwxJobStatus,
  CreateAwxUpdateJobRequest,
  CreateAwxUpdateJobResponse
} from '@app/shared/entities/rd/awx-jobs/models';
import { SUPPORT_LINK } from '@app/shared/helpers';
import { Observable } from 'rxjs';
import { IntercomUpdateToolStatus } from './intercom-update-tool-status.model';
import { IntercomUpdateToolHelper } from './intercom-update-tool.helper';
import { IntercomUpdateToolFacade } from './store/intercom-update-tool.facade';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-intercom-update-tool',
  templateUrl: './intercom-update-tool.component.html',
  styleUrls: ['./intercom-update-tool.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntercomUpdateToolComponent implements OnInit, OnDestroy {
  readonly intercomUpdateToolStatus = IntercomUpdateToolStatus;
  readonly supportLink: string = SUPPORT_LINK;
  readonly AwxJobStatus: typeof AwxJobStatus = AwxJobStatus;

  @Input() intercomUid: string;
  logsExpanded = false;
  copyDisabled = false;
  alreadyStartedJobExist = false;
  logs: AwxJobLogResponse[];
  errorsCount$: Observable<number>;
  status$: Observable<IntercomUpdateToolStatus>;
  updateRdaaJob$: Observable<CreateAwxUpdateJobResponse>;
  updateRdosJob$: Observable<CreateAwxUpdateJobResponse>;

  constructor(
    private snackbar: SnackbarService,
    private changeDetectorRef: ChangeDetectorRef,
    private intercomUpdateToolHelper: IntercomUpdateToolHelper,
    private intercomUpdateToolFacade: IntercomUpdateToolFacade,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initStateForIntercom();
    this.addLogsListener();
  }

  ngOnDestroy() {
    this.intercomUpdateToolFacade.deleteUpdateJobStateIfSuccess(this.intercomUid);
  }

  onStartUpdate() {
    this.intercomUpdateToolHelper.checkIfExistStartedJob(this.intercomUid).then((job) => {
      if (!job || job.finished) {
        const request: CreateAwxUpdateJobRequest = {
          jobTags: null,
          rdaUid: this.intercomUid
        };

        this.intercomUpdateToolFacade.clearUpdateJobs(this.intercomUid);

        this.intercomUpdateToolHelper.createUpdateRdaaJob(
          this.intercomUid,
          request,
          (success: boolean, error: string) => {
            if (success === false) {
              this.snackbar.showMessage(
                this.translate.instant('shared.rda.intercom.update.tool.message.start.failed', {
                  text: error
                })
              );
            }
          }
        );
      } else {
        this.intercomUpdateToolFacade.setIntercomSatus(this.intercomUid, IntercomUpdateToolStatus.PROGRESS);
        this.intercomUpdateToolFacade.createUpdateRdaaJobSuccess(this.intercomUid, job);
        this.intercomUpdateToolHelper.initUpdateTool(this.intercomUid);
      }
    });
  }

  onChangeExpandLogsState() {
    this.logsExpanded = !this.logsExpanded;
  }

  onCopyLogs() {
    this.copyDisabled = true;
    this.intercomUpdateToolHelper.copyLogsToClipboard(
      this.logs,
      () => {
        this.copyDisabled = false;
        this.snackbar.showMessage(
          this.translate.instant('shared.rda.intercom.update.tool.message.copy_logs.success'),
          'success'
        );
      }
    );
  }

  private initStateForIntercom() {
    this.intercomUpdateToolFacade.initUpdateJobs(this.intercomUid);
    this.intercomUpdateToolFacade.initSelectors(this.intercomUid);

    this.errorsCount$ = this.intercomUpdateToolFacade.errorsCount$;
    this.status$ = this.intercomUpdateToolFacade.status$;
    this.updateRdaaJob$ = this.intercomUpdateToolFacade.updateRdaaJob$;
    this.updateRdosJob$ = this.intercomUpdateToolFacade.updateRdosJob$;
  }

  private addLogsListener() {
    this.intercomUpdateToolHelper.addIntercomUpdateLogsListener(
      (logs: AwxJobLogResponse[]) => {
        this.logs = logs;
        this.changeDetectorRef.markForCheck();
      }
    );
    const updateRdaSubscription = this.updateRdaaJob$.subscribe(job => {
      if (job?.status === AwxJobStatus.FAILED) {
        this.snackbar.showMessage(
          this.translate.instant('shared.rda.intercom.update.tool.message.update.failed')
        );
        updateRdaSubscription?.unsubscribe();
      } else if (job?.status === AwxJobStatus.SUCCESSFUL) {
        const request: CreateAwxUpdateJobRequest = {
          jobTags: null,
          rdaUid: this.intercomUid
        };

        this.intercomUpdateToolHelper.createUpdateRdosJob(
          this.intercomUid,
          request,
          (success: boolean, error: string) => {
            if (success === false) {
              this.snackbar.showMessage(
                this.translate.instant('shared.rda.intercom.update.tool.message.update.failed', {
                  text: error
                })
              );
            }
          }
        );
        updateRdaSubscription?.unsubscribe();
      }
    });
  }
}
