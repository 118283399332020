import { RdaSelectSearchResponse } from '../models';

export interface RdaSelectSearchState {
    intercoms: RdaSelectSearchResponse[];
    initialIntercoms: RdaSelectSearchResponse[];
    selectedIntercom: RdaSelectSearchResponse;
    loading: boolean;
}

export const intercomSelectSearchInitialState: RdaSelectSearchState = {
    intercoms: undefined,
    initialIntercoms: undefined,
    selectedIntercom: undefined,
    loading: undefined
};
