import { VideoPlayerCustomClickEventType } from '../../../models';
import { PlyrTemplateHelper } from '../../plyr-globals';
import { PlyrControlBasicHelper } from './../base';

/**
 * Create videoshot custom button
 */
export class PlyrVideoshotButtonControlHelper extends PlyrControlBasicHelper<VideoPlayerCustomClickEventType> {
    constructor() {
        super();
        this.enableClickListener();
    }

    /**
     * Enable listener for get videoshot button
     */
    private enableClickListener() {
        const getVideoshotButton = document.getElementById(PlyrTemplateHelper.GET_VIDEOSHOT_ID);

        if (getVideoshotButton) {
            getVideoshotButton.addEventListener('click', () =>
                this.clickListener$.next({ type: VideoPlayerCustomClickEventType.GET_VIDEOSHOT })
            );
        }
    }
}
