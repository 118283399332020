import {Injectable, OnDestroy} from "@angular/core";
import {DialogWrapperStateHelper} from "@app/shared/ui";

@Injectable()
export class FlatFilterPopupService extends DialogWrapperStateHelper<'loading' | 'close' | 'loaded'> implements OnDestroy {
  constructor() {
    super();
  }
  ngOnDestroy() {
    this.destroy();
  }
}
