<mat-card>
  <mat-card-header>
    <mat-card-title>{{data.title}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngComponentOutlet="data.component; injector: dataInjector"></ng-container>
  </mat-card-content>
  <mat-dialog-actions>
    <button mat-fab mat-dialog-close color="primary-inverse">
      <mat-icon>close</mat-icon>
    </button>
  </mat-dialog-actions>
  <app-component-loader *ngIf="loading" [background]="true"></app-component-loader>
</mat-card>