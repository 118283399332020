import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable, Subscription} from 'rxjs';

import {NavbarLink} from '@app/shared/ui';
import {ServicePageMode} from '@app/views/services/models';
import {TopBarService, UserRoleType} from '@app/core';
import {PermissionsService} from '@app/security';
import {Links} from '@app/shared/helpers/wiki-links.enum';
import {ResolutionBreakpoint, ResolutionService, UrlService} from '@app/shared/services';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-service-navbar',
  templateUrl: './service-navbar.component.html',
  styleUrls: ['./service-navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceNavbarComponent implements OnInit {
  private topBarServiceSub$: Subscription;
  public smWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.SM_W_DOWN);
  public activeTabName: string;
  public pageName: string;

  @Input() pageMode!: ServicePageMode;
  @Input() pageModes: NavbarLink[] = this.defaultNavbar;
  @Output() private pageModeChange: EventEmitter<{ pageMode: ServicePageMode }> = new EventEmitter();

  constructor(
    private resolution: ResolutionService,
    private permissions: PermissionsService,
    private topBarService: TopBarService,
    private urlService: UrlService,
    public translate: TranslateService
  ) {}

  async ngOnInit() {
    this.activeTabName = this.translate.instant('service-navbar.tab.activity');

    this.topBarServiceSub$ = this.topBarService.title$
      .subscribe(pageName => {
        this.pageName = pageName;
      });
  }
  get defaultNavbar(): NavbarLink[] {
    let tabs: NavbarLink[] = [];
    if (this.isOnlyRoleForLogMonitoring()) {
      tabs = [
        {
          name: ServicePageMode.ACTIVITY,
          text: this.translate.instant('service-navbar.tab.activity')
        },
      ];

      return tabs;
    }

    tabs = [
      {
        name: ServicePageMode.ACTIVITY,
        text: this.translate.instant('service-navbar.tab.activity')
      },
      {
        name: ServicePageMode.ABONENTS,
        text: this.translate.instant('service-navbar.tab.abonents')
      },
      {
        name: ServicePageMode.SETTINGS,
        text: this.translate.instant('service-navbar.tab.settings')
      }
    ];
    if (this.hasAdminOrOwnerRole()) {
      tabs.push({
        name: ServicePageMode.DELEGATION,
        text: this.translate.instant('service-navbar.tab.delegation')
      });
    }

    return tabs;
  }

  onChangeTab(pageIdx: number) {
    const pageMode: ServicePageMode = this.pageModes[pageIdx].name;
    this.activeTabName = this.pageModes[pageIdx].text;
    this.pageModeChange.emit({ pageMode });
  }

  private isOnlyRoleForLogMonitoring(): boolean {
    if (this.permissions.userRoles?.length > 1) {
      return false;
    }
    return this.permissions.someRoleContains([UserRoleType.ROLE_MONITORING_ENTRANCES]);
  }

  private hasAdminOrOwnerRole(): boolean {
    return this.permissions.someRoleContains([UserRoleType.ROLE_OWNER, UserRoleType.ROLE_ADMINISTRATOR, UserRoleType.ROLE_ENGINEER, UserRoleType.ROLE_DEMO]);
  }

  public get offlineRDALink(): string {
    return Links.Offline;
  }

  public get showOfflineLink(): boolean {
    return this.pageMode === ServicePageMode.ACTIVITY;
  }

  public get link(): string {
    return this.urlService.links[this.pageName];
  }


  get text(): string {
    switch (this.pageMode) {
      case ServicePageMode.SETTINGS:
        return this.resolution.getBreakpointState(ResolutionBreakpoint.SM_W_DOWN)
          ? this.translate.instant('service-navbar.message.how_to_configure_correctly')
          : this.translate.instant('service-navbar.message.how_to_configure');
      case ServicePageMode.ABONENTS:
        return this.translate.instant('service-navbar.message.how_to_manage');
      default:
        return this.translate.instant('service-navbar.message.how_it_works');
    }
  }
}
