<form [formGroup]="form">
  <mat-form-field appearance="outline" class="none-padding">
    <mat-select
      placeholder="{{title}}"
      formControlName="modelId"
      (selectionChange)="onSelectModel($event)"
      (openedChange)="onOpenedChange($event)"
    >
      <input
        matInput
        placeholder="{{ 'intercom.pages.batches.page.add.form.model_list.placeholder.search' | translate }}"
        type="text"
        formControlName="filter"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
      />
      <mat-option
        *ngFor="let model of (filteredModels | async)"
        [value]="model.id"
      >
        {{ model.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
