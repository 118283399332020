export enum ErrorCodes {
  // Abonents Service
  '007' = 'shared.error_codes.007',

  // Companies service
  'API_KEY_OR_SHOP_ID_INCORRECT' = 'shared.error_codes.API_KEY_OR_SHOP_ID_INCORRECT',
  'companies001' = 'shared.error_codes.companies001',
  'companies002' = 'shared.error_codes.companies002',
  'companies003' = 'shared.error_codes.companies003',
  'companies004' = 'shared.error_codes.companies004'
}
