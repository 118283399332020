<div class="warnings" *ngIf="waitAbonentSignUpsCount.olderThanMonth || waitAbonentSignUpsCount.olderThanWeek">
  <div class="warnings-container">
    <div class="warnings-container-left">
      <div class="warnings-container-left-count">
        <span class="warnings-container-left-count__text">
          {{ waitAbonentSignUpsCount.olderThanWeek > 0 ? waitAbonentSignUpsCount.olderThanWeek : waitAbonentSignUpsCount.olderThanMonth }}
        </span>
      </div>
      <div class="warnings-container-left-message">
        <span class="warnings-container-left-message__text">
          {{(waitAbonentSignUpsCount.olderThanMonth ? 'abonents.signups.delegate.warnings.text.older_than_month' : 'abonents.signups.delegate.warnings.text.older_than_week') | translate}}
        </span>
      </div>
    </div>
    <div class="warnings-container-right">
      <div class="warnings-container-right-header">
        <span class="warnings-container-right-header__text">
          {{'abonents.signups.delegate.warnings.text.1' | translate}}
        </span>
      </div>
      <div class="warnings-container-right-info">
        <span class="warnings-container-right-info__text">
          {{'abonents.signups.delegate.warnings.text.2' | translate}}
        </span>
      </div>
      <div class="warnings-container-right-action zammad-banner-content-actions">
        <button
          pButton
          [label]="'abonents.signups.delegate.warnings.text.3' | translate"
          style="height: 39px; width: 200px;"
          class="p-button-info p-button-text"
          (click)="confirmationOwners.emit()"
        ></button>
      </div>
    </div>
  </div>
</div>
