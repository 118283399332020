import { AwxJobLogResponse, CreateAwxUpdateJobResponse } from '@app/shared/entities/rd/awx-jobs/models';
import { Dictionary } from '@app/shared/helpers';
import * as root from '@app/store/reducers';
import { IntercomUpdateToolStatus } from '../intercom-update-tool-status.model';

export interface IntercomUpdateToolModuleState {
    intercomUpdateTool: IntercomUpdateToolIntecomsState;
}

export interface IntercomUpdateToolModuleRootState extends root.State {
    'intercom-update-tool': IntercomUpdateToolModuleState;
}

export interface IntercomUpdateToolIntecomsState {
    intercomUpdateState: Dictionary<IntercomUpdateToolState>;
}

export interface IntercomUpdateToolState {
    listening: boolean;
    errorsCount: number;
    status: IntercomUpdateToolStatus;
    rdaaUpdateJob: CreateAwxUpdateJobResponse;
    rdosUpdateJob: CreateAwxUpdateJobResponse;
    rdaaUpdateJobLogs: AwxJobLogResponse[];
    rdosUpdateJobLogs: AwxJobLogResponse[];
}

export const intercomUpdateToolInitialState: IntercomUpdateToolIntecomsState = {
    intercomUpdateState: {}
};
