import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { UpdateCompanyRequest } from '@app/views/company/store/company-page.state';
import { CompanyPageStore } from '@app/views/company/store/company-page.store';

@Component({
    selector: 'app-company-contacts-edit',
    templateUrl: './company-contacts-edit.component.html',
    styleUrls: ['./company-contacts-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyContactsEditComponent implements OnInit {

    @Input() mails: string = '';

    @Output() private toggleEdit: EventEmitter<boolean> = new EventEmitter();

    public mailInputs: string[] = [];
    public showContactsEdit: boolean = false;
    public form: UntypedFormGroup;

    constructor(private store: CompanyPageStore) { }


    public ngOnInit(): void {
        if (this.mails?.length > 0) {
            this.mailInputs = this.mails.split(',').filter(mail => mail !== 'invoice@rosdomofon.com');
            const data = this.mailInputs.map(mail => new UntypedFormControl(mail, [Validators.required, Validators.email]));
            this.form = new UntypedFormGroup({
                mailsList: new UntypedFormArray(data)
            });
        } else {
            this.form = new UntypedFormGroup({
                mailsList: new UntypedFormArray([])
            });
        }
    }

    public addInput() {
        (<UntypedFormArray>this.form.get('mailsList')).push(new UntypedFormControl(null, [Validators.required, Validators.email]));
    }

    public deleteInput(index: number) {
        (<UntypedFormArray>this.form.get('mailsList')).removeAt(index);
    }

    public onSubmit() {
        if (!this.form.valid) {
            return;
        }
        const mails = this.form.get('mailsList').value;
        const payload: UpdateCompanyRequest = {
            company: {
                mailToList: mails.join(',') + ',invoice@rosdomofon.com',
            }
        };
        this.store.updateCompanyInfo(payload);
        this.toggleContactsEdit();
    }

    public toggleContactsEdit(): void {
        this.toggleEdit.emit(false);
    }
}
