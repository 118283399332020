import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-flat-tariff',
  templateUrl: './flat-tariff.component.html',
  styleUrls: ['./flat-tariff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatTariffComponent {
  @Input() content!: string | number;
  @Input() serviceId!: number;

  @ViewChild('contentKeeper') contentKeeper!: ElementRef;

  public isContentEditable = false;

  public toggleContentEditableState(newState: boolean): void {
    this.isContentEditable = newState;

    if (newState) {
      this.contentKeeper?.nativeElement.focus();
    }
  }
}
