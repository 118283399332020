import { CoreModuleRootState, CoreModuleState } from '@app/core/store/core.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const coreFeatureSelector = createFeatureSelector<CoreModuleRootState, CoreModuleState>('core');

const getState = createSelector(
    coreFeatureSelector,
    (state: CoreModuleState) => state.core
);

const activeSignUpsState = createSelector(
    getState,
    state => state.activeSignUps
);

const unresolvedIssuesState = createSelector(
    getState,
    state => state.unresolvedIssues
);

const paymentState = createSelector(
    getState,
    state => state.payment
);

const activeSignUpsCountState = createSelector(
    getState,
    state => state.activeSignUps
);

const unresolvedIssuesCountState = createSelector(
    getState,
    state => state.unresolvedIssues
);

const paymentValueState = createSelector(
    getState,
    state => state.payment
);

const paymentLoadingState = createSelector(
    getState,
    state => state.paymentLoading
)

export const coreSelector = {
    activeSignUpsState,
    unresolvedIssuesState,
    paymentState,
    activeSignUpsCountState,
    unresolvedIssuesCountState,
    paymentValueState,
    paymentLoadingState
};
