<ng-container *ngIf="accounts?.length > 0; else emptyAccounts">
  <mat-expansion-panel *ngFor="let account of accounts; let index = index; let last = last" [disabled]="true"
    [expanded]="expandedIdx === index" [ngClass]="{ 'account-panel': !last }">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{
          account.number
            ? ('abonent.accounts.field.account' | translate) + ' ' + account.number
            : ('abonent.accounts.message.account_not_specified' | translate)
        }}
      </mat-panel-title>

      <mat-panel-description>
        <mat-chip
          [disabled]="true"
          [selectable]="false"
          color="{{ account?.blocked ? 'error' : 'success' }}"
        >
          {{ { isBlocked: !account?.blocked, blockReason: account?.blockReason } | accountStatus }}
        </mat-chip>

        <div class="actions-panel">
          <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
            <mat-icon svgIcon="submenu"></mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <div class="triggered-menu-content">
              <button class="triggered-menu-content__button" mat-button
                (click)="onEdit(account)"
              >
                <mat-icon>mode_edit</mat-icon>
                {{ 'abonent.accounts.button.edit' | translate }}
              </button>

              <button mat-button class="triggered-menu-content__button"
                [color]="account?.blocked ? 'primary' : 'warn'"
                (click)="onBlock(account?.id, account?.blocked, account?.blockReason)"
              >
                <mat-icon>{{ account?.blocked ? 'autorenew' : 'blocked' }}</mat-icon>
                {{
                  account?.blocked
                    ? ('abonent.accounts.button.unblock' | translate)
                    : ('abonent.accounts.button.block' | translate)
                }}
              </button>

              <button mat-button class="triggered-menu-content__button" color="error"
                (click)="onDelete(account.id)"
              >
                <mat-icon aria-label="Delete">delete</mat-icon>
                {{ 'abonent.accounts.button.delete' | translate }}
              </button>
            </div>
          </mat-menu>

          <button mat-icon-button (click)="onSelect(account, index)">
            <mat-icon>{{ expandedIdx === index ? 'expand_less' : 'expand_more' }}</mat-icon>
          </button>
        </div>

      </mat-panel-description>
    </mat-expansion-panel-header>
    <ng-container *ngIf="expandedIdx === index">
      <app-abonent-services
        [services]="services"
        [account]="currentAccount"
      ></app-abonent-services>
    </ng-container>
  </mat-expansion-panel>
</ng-container>

<ng-template #emptyAccounts>
  <app-empty-data></app-empty-data>
</ng-template>
