import { Component, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { PopupBasicComponent } from '@app/shared/templates';

@Component({
  selector: 'app-remove-popup',
  templateUrl: './remove-popup.component.html',
  styleUrls: ['./remove-popup.component.scss']
})
export class RemovePopupComponent extends PopupBasicComponent {
  constructor(
    @Inject('data') public data: { text: string, description: string, id: string, btnText: string },
    @Inject('subject') private subject: Subject<any>
  ) {
    super();
  }

  onSubmit(): void {
    this.subject.next(true);
  }
}
