import { Injectable } from '@angular/core';
import { GitlabComponentsVersions } from '@app/shared/entities/integrations';
import { Camera, Company, EntrancesStatus, KeysResponse, PbxOnRdaResponse, RdaResponse } from '@app/shared/entities/rd';
import { EntrancePageResponse, Flat, LogsResponse, PageData } from '@app/shared/models';
import { NavbarLink } from '@app/shared/ui';
import { State } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { EntrancesPageModel } from '../models';
import { EntrancesClearEntranceStore, EntrancesLoadCameras, EntrancesLoadCamerasSuccess, EntrancesLoadCamerasWithErrorsSuccess, EntrancesLoadCompany, EntrancesLoadCounts, EntrancesLoadEntrance, EntrancesLoadFlats, EntrancesLoadGitlabVersions, EntrancesLoadKeys, EntrancesLoadKeysSuccess, EntrancesLoadLogs, EntrancesLoadPage, EntrancesLoadPbxOnRda, EntrancesLoadRdaSuccess, EntrancesLoadServiceId, EntrancesSetIncorrectId } from './entrances.actions';
import { entrancesSelectors } from './entrances.selectors';

@Injectable()
export class EntrancesFacade {
    entrancesLoading$: Observable<boolean> = this.store.select(entrancesSelectors.selectEntrancesLoading);
    countsLoading$: Observable<boolean> = this.store.select(entrancesSelectors.selectCountsLoading);
    entrances$: Observable<EntrancesPageModel[]> = this.store.select(entrancesSelectors.selectEntrances);
    pageData$: Observable<PageData> = this.store.select(entrancesSelectors.selectPageData);
    counts$: Observable<NavbarLink[]> = this.store.select(entrancesSelectors.selectCounts);

    entrance$: Observable<EntrancePageResponse> = this.store.select(entrancesSelectors.selectEntrance);
    entranceLoading$: Observable<boolean> = this.store.select(entrancesSelectors.selectEntranceLoading);
    rda$: Observable<RdaResponse> = this.store.select(entrancesSelectors.selectRda);
    company$: Observable<Company> = this.store.select(entrancesSelectors.selectCompany);
    keys$: Observable<KeysResponse[]> = this.store.select(entrancesSelectors.selectKeys);
    keysLoading$: Observable<boolean> = this.store.select(entrancesSelectors.selectKeysLoading);
    flats$: Observable<Flat[]> = this.store.select(entrancesSelectors.selectFlats);
    flatsLoading$: Observable<boolean> = this.store.select(entrancesSelectors.selectFlatsLoading);
    gitlabVersions$: Observable<GitlabComponentsVersions> = this.store.select(entrancesSelectors.selectGitlabVersions);
    pbxOnRda$: Observable<PbxOnRdaResponse> = this.store.select(entrancesSelectors.selectPbxOnRda);
    cameras$: Observable<Camera[]> = this.store.select(entrancesSelectors.selectCameras);
    camerasLoading$: Observable<boolean> = this.store.select(entrancesSelectors.selectCamerasLoading);
    camerasWithErrors$: Observable<Camera[]> = this.store.select(entrancesSelectors.selectCamerasWithErrors);
    logs$: Observable<LogsResponse[]> = this.store.select(entrancesSelectors.selectLogs);
    logsLoading$: Observable<boolean> = this.store.select(entrancesSelectors.selectLogsLoading);
    incorrectId$: Observable<boolean> = this.store.select(entrancesSelectors.selectIncorrectId);

    constructor(
        private store: Store<State>
    ) { }

    getPage(page: number, size: number, status: EntrancesStatus, address?: string): void {
        this.store.dispatch(new EntrancesLoadPage(page, size, status, address));
    }

    loadCounts() {
        this.store.dispatch(new EntrancesLoadCounts());
    }

    loadEntrance(entranceId: number) {
        this.store.dispatch(new EntrancesLoadEntrance(entranceId));
    }

    loadRdaSuccess(rda: RdaResponse) {
        this.store.dispatch(new EntrancesLoadRdaSuccess(rda));
    }

    loadCompany(companyId: number) {
        this.store.dispatch(new EntrancesLoadCompany(companyId));
    }

    loadEntranceKeys(rdaUid: string) {
        this.store.dispatch(new EntrancesLoadKeys(rdaUid));
    }

    loadEntranceKeysSuccess(keys: KeysResponse[]) {
        this.store.dispatch(new EntrancesLoadKeysSuccess(keys));
    }

    loadEntranceFlats(entranceId: number) {
        this.store.dispatch(new EntrancesLoadFlats(entranceId));
    }

    loadGitlabVersions() {
        this.store.dispatch(new EntrancesLoadGitlabVersions());
    }

    loadPbxOnRda(rdaUid: string) {
        this.store.dispatch(new EntrancesLoadPbxOnRda(rdaUid));
    }

    loadServiceComponents(entranceId: number) {
        this.store.dispatch(new EntrancesLoadServiceId(entranceId));
    }

    loadCameras(entranceId: number, serviceId: number) {
        this.store.dispatch(new EntrancesLoadCameras(entranceId, serviceId));
    }

    loadCamerasSuccess(cameras: Camera[]) {
        this.store.dispatch(new EntrancesLoadCamerasSuccess(cameras));
    }

    loadCamerasWithErrorsSuccess(cameras: Camera[]) {
        this.store.dispatch(new EntrancesLoadCamerasWithErrorsSuccess(cameras));
    }

    loadLogs(rdaUid: string, timeRange: number) {
        this.store.dispatch(new EntrancesLoadLogs(rdaUid, timeRange));
    }

    clearEntranceStore() {
        this.store.dispatch(new EntrancesClearEntranceStore());
    }

    setIncorrectId(incorrectId: boolean) {
        this.store.dispatch(new EntrancesSetIncorrectId(incorrectId));
    }
}
