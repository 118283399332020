import { createSelector } from '@ngrx/store';
import { SharedModuleStates } from '../../reducers/reducers';
import { sharedModuleSelector } from '../shared-module.state';

export const selectNewIntercomsState = createSelector(
  sharedModuleSelector,
  (state: SharedModuleStates) => state.newAdapters
);
export const getNewIntercoms = createSelector(
  selectNewIntercomsState,
  state => state
);
export const getNewIntercomsSuccess = createSelector(
  selectNewIntercomsState,
  state => ({ newAdapters: state.newAdapters, totalCount: state.totalCount })
);
export const getNewIntercomsLoading = createSelector(
  selectNewIntercomsState,
  state => state.loading
);
export const getNewIntercomsError = createSelector(
  selectNewIntercomsState,
  state => state.error
);
