import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Camera, CameraApiService, CameraUtilsService } from '@app/shared/entities/rd';
import { DialogWrapperData } from '@app/shared/ui';
import { Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { ServicesIcons } from '@app/shared/components/camera/camera-already-exists/models/services-icons';
import { ServiceFacade } from '@app/views/services';
import { VideoType } from '@app/shared/containers/camera/camera-thumbnail/models/videoType';
import { TranslateService } from '@ngx-translate/core';

interface ICameraInfo {
  title: string;
  data: string;
}

interface ICameraServices {
  address: string;
  services: {
    serviceSvgIcon: string;
    serviceName: string;
  }[];
}

export interface IConnectionInfo {
  speed: number;
  delay: number;
}

const ON_LINE = 10000;
const OFF_LINE = 5000;

@Component({
  selector: 'app-camera-already-exists',
  templateUrl: './camera-already-exists.component.html',
  styleUrls: ['./camera-already-exists.component.scss']
})
export class CameraAlreadyExistsComponent implements OnInit, OnDestroy  {
  public link: string;
  public color: string;
  public connectionInfo: IConnectionInfo;
  public mode: VideoType;
  private _cameraInfo: ICameraInfo[];
  private _servicesInfo: ICameraServices[];
  private _timeSubj: Subject<void> = new Subject();
  private onDestroy$: Subject<boolean> = new Subject<boolean>();

  @Input() wrapperData: DialogWrapperData<Camera, Camera>;
  @Input() set connectionResponse(res: 'Failure' | 'Success' | Camera) {
    if (res === 'Success') {
      this.serviceFacade.getServiceCameras();
      this.wrapperData.submit(this.wrapperData.body);
    }
  }

  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private cameraUtilsService: CameraUtilsService,
    private cameraApiService: CameraApiService,
    private serviceFacade: ServiceFacade,
    private translate: TranslateService,
    private serviceIcons: ServicesIcons
  ) {
      this.link = '';
      this.color = 'red';
      this.mode = VideoType.advanced;
  }

  ngOnInit(): void {
    this.initListener();
    this.servicesInfo = this.serviceConverter(this.wrapperData.body.services);
    this.serviceConverter(this.wrapperData.body.services);
    this.cameraInfo = [
      {
        title: this.translate.instant('shared.camera.already.exists.archive_hour'),
        data: this.cameraUtilsService.getDepthInHoursFromConfig(this.wrapperData.body.configuration).toString()
      },
      {
        title: this.translate.instant('shared.camera.already.exists.sound'),
        data: this.wrapperData.body.audio
          ? this.translate.instant('enum.bool.yes')
          : this.translate.instant('enum.bool.no')
      },
      {
        title: this.translate.instant('shared.camera.already.exists.adapter'),
        data: this.wrapperData.body.rdaUid
      },
      {
        title: this.translate.instant('shared.camera.already.exists.video_archive'),
        data: this.wrapperData.body.rdva.description
      },
    ];
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  get cameraInfo(): ICameraInfo[] {
    return this._cameraInfo;
  }

  set cameraInfo(value: ICameraInfo[]) {
    this._cameraInfo = value;
  }

  get servicesInfo(): ICameraServices[] {
    return this._servicesInfo;
  }

  set servicesInfo(value: ICameraServices[]) {
    this._servicesInfo = value;
  }

  public onSubmit(): void {
    this.serviceFacade.connectServiceCamera(this.wrapperData.body);
  }

  public isCameraCorrect(): boolean {
    return this.cameraUtilsService.isCameraCorrect(this.wrapperData.body);
  }

  private serviceConverter(services: Array<{type: string; address: string}>): ICameraServices[] {
    const dictionaryOfAddresses: { address: string }[] = [];

    services?.forEach((service) => {
      if (!dictionaryOfAddresses.some(mappedService => mappedService.address === service.address)) {
        dictionaryOfAddresses.push({ address: service.address });
      }
    });

    return dictionaryOfAddresses.map(dictionary => {
      return {
        address: dictionary.address,
        services: services.filter(service => service.address === dictionary.address).map((origin) => {
          return {
            serviceSvgIcon: this.serviceIcons.icons[origin.type]?.serviceSvgIcon,
            serviceName: this.serviceIcons.icons[origin.type]?.serviceName
          };
        })
      };
    });
  }

  private initListener(): void {
    this._timeSubj.pipe(
      debounceTime(
      this.connectionInfo?.speed ?
        ON_LINE : OFF_LINE)
    )
      .pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.request();
    });
    this.request();
  }

  private request(): void {
    this._timeSubj.next();

    this.cameraApiService.getCameraById(this.wrapperData.body.id).pipe(
      map((camera) => {
      const bitrate = camera.bitrate ?
        camera.bitrate :
        null;
      const delay = bitrate && camera.segmentLength ?
        this.cameraUtilsService.isLatencyError(camera.bitrate, camera.segmentLength) :
        null;

      return {
        speed: bitrate,
        delay: delay
      };

    }), takeUntil(this.onDestroy$)).subscribe((res: IConnectionInfo) => {
      this.connectionInfo = res;
    });
  }
}
