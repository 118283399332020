import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { ComponentLoaderModule } from '@app/shared/ui';
import { EditPopupComponent } from './edit-popup.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ComponentLoaderModule
    ],
    declarations: [EditPopupComponent],
    exports: [EditPopupComponent]
})
export class EditPopupModule { }
