<div class="video-manager-add-edit-page__wrapper">
  <p-card class="video-manager-add-edit-page__card">
    <div *ngIf="!isLoaded || !(videoManagerPageDataService.loaded | async)" class="card__content__wrapper">
      <p-skeleton width="20%" height="2rem"></p-skeleton>
      <div [formGroup]="formGroup" class="card__content__options__wrapper">
        <div class="card__content__options__field__wrapper">
          <p-skeleton width="100%" height="2rem"></p-skeleton>
        </div>
        <p-skeleton width="5rem" height="2rem"></p-skeleton>
      </div>
      <div cdkDropListGroup class="card__content__preview__wrapper" [ngClass]="GetViewScheme(selectedView)">
        <p-skeleton width="100%" height="100%"></p-skeleton>
      </div>
    </div>

    <div *ngIf="isLoaded || (videoManagerPageDataService.loaded | async)" class="card__content__wrapper">
      <div class="card__content__header">{{ 'video.manager.common.set_name_and_layout' | translate }}</div>
      <div [formGroup]="formGroup" class="card__content__options__wrapper">
        <div class="card__content__options__field__wrapper">
          <input type="text" pInputText formControlName="name" [placeholder]="'video.manager.common.screen_name' | translate"/>
        </div>
        <div class="card__content__options__layout-switcher__wrapper">
          <p-selectButton [options]="layoutOptions" formControlName="layoutOriginal" optionValue="value" (onOptionClick)="onOptionClick($event)">
            <ng-template let-item>
              <div class="card__content__options__layout-switcher__selector-icon view-scheme--layout-like-icon"
                   [ngClass]="GetViewScheme(item.value, true)">
                <ng-container [ngSwitch]="item.value">
                  <ng-container *ngSwitchCase="ViewVariants.fourByFour">
                    <div class="card__content__options__layout-switcher__selector-icon--item">4x4</div>
                  </ng-container>
                  <ng-container *ngSwitchCase="ViewVariants.fiveByFive">
                    <div class="card__content__options__layout-switcher__selector-icon--item">5x5</div>
                  </ng-container>
                  <ng-container *ngSwitchCase="ViewVariants.sixBySix">
                    <div class="card__content__options__layout-switcher__selector-icon--item">6x6</div>
                  </ng-container>
                  <ng-container *ngSwitchCase="ViewVariants.sevenBySeven">
                    <div class="card__content__options__layout-switcher__selector-icon--item">7x7</div>
                  </ng-container>
                  <ng-container *ngSwitchCase="ViewVariants.eightByEight">
                    <div class="card__content__options__layout-switcher__selector-icon--item">8x8</div>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <ng-container *ngFor="let camera of defaultGridsCell; let cameraIndex = index">
                      <ng-container *ngIf="cameraIndex + 1 <= GetMaxBlockByViewScheme(item.value)">
                        <div class="card__content__options__layout-switcher__selector-icon--item"></div>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ng-container>
              </div>
            </ng-template>
          </p-selectButton>
        </div>
        <p-button label="{{'video.manager.common.save' | translate}}" [disabled]="!isAllOk" (onClick)="saveLayout()"></p-button>
      </div>
      <div cdkDropListGroup class="card__content__preview__wrapper" [ngClass]="GetViewScheme(selectedView)">
        <ng-container *ngFor="let camera of screen?.cameras; let cameraIndex = index">
          <ng-container *ngIf="cameraIndex + 1 <= GetMaxBlockByViewScheme(selectedView)">
            <div cdkDropList (cdkDropListDropped)="drop($event, camera)" class="card__content__preview__item__wrapper">
              <div cdkDrag [cdkDragData]="camera" [cdkDragDisabled]="!camera?.camera || simpleLayout" class="card__content__preview__item__preview__wrapper">
                <div class="card__content__preview__item__preview">
                  <div class="card__content__preview__item__preview__action">
                    <div *ngIf="!!camera?.camera" class="card__content__preview__item__preview__action__top">
                      <p-button icon="pi pi-times" styleClass="p-button-raised p-button-rounded p-button-danger p-button-sm"
                                (click)="unselectCamera(camera)"></p-button>
                    </div>
                    <div class="card__content__preview__item__preview__action__center">
                      <p-button label="{{'video.manager.common.select_camera' | translate}}"
                                styleClass="p-button-raised p-button-text p-button-info p-button-sm"
                                (click)="selectCamera(camera)"></p-button>
                    </div>
                  </div>
                  <div *ngIf="!!camera?.camera" class="card__content__preview__item__preview--content">
                    <app-camera-thumbnail *ngIf="!!camera?.camera" style="width: 100%; height: 100%;"
                                          [showInfo]="true" [noActionOnClick]="true"
                                          [camera]="camera?.camera" [mode]="videoMode.basic"></app-camera-thumbnail>
                    <p class="card__content__preview__item__preview--content--title">{{ camera?.camera?.address | address }}</p>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </p-card>
</div>

<p-confirmDialog #cd [style]="{'max-width': '650px'}" key="confDialog">
  <ng-template pTemplate="footer">
    <div class="video-manager-add-edit-page__confirmation__footer__wrapper">
      <div class="video-manager-add-edit-page__confirmation__footer__left__wrapper">
        <p-checkbox *ngIf="confirmationChangeLayout" [(ngModel)]="rememberChoice.layout"
                    [binary]="true" inputId="rememberChoice"></p-checkbox>
        <p-checkbox *ngIf="!confirmationChangeLayout" [(ngModel)]="rememberChoice.unselectCamera"
                    [binary]="true" inputId="rememberChoice"></p-checkbox>
        <label for="rememberChoice">{{'video.manager.common.remember_choice' | translate}}</label>
      </div>
      <div class="video-manager-add-edit-page__confirmation__footer__right__wrapper">
        <p-button icon="pi pi-times" styleClass="p-button-outlined" [label]="'video.manager.common.no' | translate"
                  (click)="cd.reject()"></p-button>
        <p-button icon="pi pi-check" [label]="'video.manager.common.yes' | translate"
                  (click)="cd.accept()"></p-button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>
