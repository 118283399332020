import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedPipesModule } from '@app/shared';
import { InfoTableModule } from '@app/shared/ui';
import { CameraInfoContainerComponent } from './camera-info-container.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        SharedPipesModule,
        InfoTableModule,
        TranslateModule,
    ],
    declarations: [CameraInfoContainerComponent],
    exports: [CameraInfoContainerComponent]
})
export class CameraInfoContainerModule { }
