<div class="popup confirm-mailing-send-popup">
  <app-info-block contentWidth="50">
    <ng-container info-block-text>
      {{ 'mailing.page.new.confirm.info' | translate }}
    </ng-container>
  </app-info-block>

  <div class="popup-actions">
    <button
      mat-raised-button
      color="primary"
      class="popup-actions__button"
      (click)="onSubmit()"
    >
      {{ 'mailing.page.new.confirm.button.submit' | translate }}
    </button>
  </div>
</div>
