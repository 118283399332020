import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PbxOnRdaPageModuleState, PbxOnRdaPageRootState, PbxOnRdaPageState } from './pbx-on-rda.state';

const pbxOnRdaFeatureSelector = createFeatureSelector<PbxOnRdaPageRootState, PbxOnRdaPageModuleState>('pbx-on-rda');

const getState = createSelector(
  pbxOnRdaFeatureSelector,
  (state: PbxOnRdaPageModuleState) => state.pbxOnRdaPage
);

const getSuccess = createSelector(
  getState,
  (state: PbxOnRdaPageState) => ({ content: state.content, totalElements: state.totalElements })
);

const getFailure = createSelector(
  getState,
  (state: PbxOnRdaPageState) => state.error
);

const getLoading = createSelector(
  getState,
  (state: PbxOnRdaPageState) => state.loading
);

export const pbxOnRdaPageSelector = {
  getState,
  getSuccess,
  getFailure,
  getLoading
};
