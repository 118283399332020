import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { Shield, ShieldTests } from '@app/views/intercom/models';

export enum ActionTypes {
  GetShieldsByBatchNumber = '[Intercom] GetShieldsByBatchNumber',
  GetShieldsByBatchNumberSuccess = '[Intercom] GetShieldsByBatchNumber Success',
  GetShieldsByBatchNumberFailure = '[Intercom] GetShieldsByBatchNumber Failure',
  GetShieldsByBatchNumberClear = '[Intercom] GetShieldsByBatchNumber Clear',

  GetShieldTests = '[Intercom] GetShieldTests',
  GetShieldTestsSuccess = '[Intercom] GetShieldTests Success',
  GetShieldTestsFailure = '[Intercom] GetShieldTests Failure',

  GetAllShields = '[Intercom] GetAllShields',
  GetAllShieldsSuccess = '[Intercom] GetAllShieldsSuccess Success',
  GetAllShieldsFailure = '[Intercom] GetAllShieldsFailure Failure',

  UpdateShield = '[Intercom] UpdateShield',
  UpdateShieldSuccess = '[Intercom] UpdateShield Success',
  UpdateShieldFailure = '[Intercom] UpdateShield Failure'
}

export class GetShieldsByBatchNumber implements Action {
  readonly type = ActionTypes.GetShieldsByBatchNumber;

  constructor(public companyId: Number, public batchNumber: Number, public isInstalled: Boolean) { }
}

export class GetShieldsByBatchNumberSuccess implements Action {
  readonly type = ActionTypes.GetShieldsByBatchNumberSuccess;

  constructor(public payload: Shield[]) { }
}

export class GetShieldsByBatchNumberFailure implements Action {
  readonly type = ActionTypes.GetShieldsByBatchNumberFailure;

  constructor(public payload: string) { }
}

export class GetShieldsByBatchNumberClear implements Action {
  readonly type = ActionTypes.GetShieldsByBatchNumberClear;

  constructor() { }
}

export class GetShieldTests implements Action {
  readonly type = ActionTypes.GetShieldTests;

  constructor(public shieldId: number) { }
}

export class GetShieldTestsSuccess implements Action {
  readonly type = ActionTypes.GetShieldTestsSuccess;

  constructor(public payload: ShieldTests[]) { }
}

export class GetShieldTestsFailure implements Action {
  readonly type = ActionTypes.GetShieldTestsFailure;

  constructor(public payload: HttpErrorResponse) { }
}

export class GetAllShields implements Action {
  readonly type = ActionTypes.GetAllShields;

  constructor(public page: number = 0, public size: number = 10, public search?: string) { }
}

export class GetAllShieldsSuccess implements Action {
  readonly type = ActionTypes.GetAllShieldsSuccess;

  constructor(public payload: Shield[], public totalCount: number) { }
}

export class GetAllShieldsFailure implements Action {
  readonly type = ActionTypes.GetAllShieldsFailure;

  constructor(public payload: string) { }
}

export class UpdateShield implements Action {
  readonly type = ActionTypes.UpdateShield;

  constructor(public payload: Shield) { }
}

export class UpdateShieldSuccess implements Action {
  readonly type = ActionTypes.UpdateShieldSuccess;

  constructor() { }
}

export class UpdateShieldFailure implements Action {
  readonly type = ActionTypes.UpdateShieldFailure;

  constructor(public payload: string) { }
}

export type Actions =
  | GetShieldsByBatchNumber
  | GetShieldsByBatchNumberSuccess
  | GetShieldsByBatchNumberFailure
  | GetShieldsByBatchNumberClear
  | GetShieldTests
  | GetShieldTestsSuccess
  | GetShieldTestsFailure
  | GetAllShields
  | GetAllShieldsSuccess
  | GetAllShieldsFailure
  | UpdateShield
  | UpdateShieldSuccess
  | UpdateShieldFailure;
