import {Pipe, PipeTransform} from '@angular/core';
import {ITicketResponse, IUserDaoResponse} from '@app/views/support-service/services/support-api.service';
import {ISupportFormValue} from '@app/views/support-service/components/requests/requests.component';
import {isEqual} from 'lodash';
import {BehaviorSubject} from 'rxjs';
import * as moment from 'moment';

export const dateFormatShort = 'YYYY-MM-DD';

export enum AllRdStatuses {
  'New' = 'New',
  'In progress' = 'In progress',
  'Waiting for client' = 'Waiting for client',
  'Planned' = 'Planned',
  'Waiting for support' = 'Waiting for support',
  'Development' = 'Development',
  'Manufacture' = 'Manufacture',
  'Solved' = 'Solved',
  'Closed' = 'Closed'
}

export enum ACTIVE_STATUS {
  'Waiting for support' = 'Waiting for support',
  'Waiting for client' = 'Waiting for client',
  'New' = 'New',
  'In progress' = 'In progress',
  'Planned' = 'Planned',
}

export enum WAITING_STATUS {
  'Development' = 'Development',
  'Manufacture' = 'Manufacture'
}

export enum CLOSED_STATUS {
  'Solved' = 'Solved',
  'Closed' = 'Closed'
}

@Pipe({
  name: 'ticketsFilter'
})
export class TicketsFilterPipe implements PipeTransform {
  transform(
    tickets: ITicketResponse[],
    option: { filters: ISupportFormValue; user: IUserDaoResponse; loader: BehaviorSubject<boolean>; }
  ): ITicketResponse[] {
    const filters = option?.filters;
    const user = option?.user;
    const loader = option?.loader;
    return tickets.filter((ticket) => {
      let result = true;

      if (filters?.user?.length && filters?.isShowOnlyOwnerTasks && result) {
        result = !!filters.user.find((u) => isEqual(u, ticket.customer_id)) || isEqual(user.zammadUserId, ticket.customer_id);
      }
      if (filters?.isShowOnlyOwnerTasks && !filters?.user?.length) {
        result = isEqual(user.zammadUserId, ticket.customer_id);
      }
      if (filters?.user?.length && !filters?.isShowOnlyOwnerTasks && result) {
        result = !!filters.user.find((u) => isEqual(u, ticket.customer_id));
      }
      if (filters?.dateOfCreating && result) {
        const firstDate: Date = filters?.dateOfCreating[0];
        const secondDate: Date = filters?.dateOfCreating[1];
        if (secondDate === null) {
          if (moment(new Date(ticket.origin_created_at)).format(dateFormatShort) !== moment(firstDate).format(dateFormatShort)) {
            result = false;
          }
        } else {
          if (!this.isWithinRange(ticket.origin_created_at, filters?.dateOfCreating as Date[])) {
            result = false;
          }
        }
      }

      this.stopLoading(loader);
      return result !== false;
    });
  }

  private stopLoading(loader: BehaviorSubject<boolean>): void {
    setTimeout(() => {
      loader.next(false);
    }, 300);
  }

  private isWithinRange(date: string, range: Date[]): boolean {
    const startDate = moment(new Date(range[0])).format(dateFormatShort);
    const endDate = moment(new Date(range[1])).format(dateFormatShort);
    const targetDate = moment(new Date(date)).format(dateFormatShort);

    return (targetDate >= startDate && targetDate <= endDate);
  }
}
