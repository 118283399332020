<p>{{ data.body.text }}</p>

<div class="action-block">
  <button
    mat-raised-button
    color="primary-inverse"
    (click)="onSubmit(true)"
  >
    {{ 'shared.rda.intercom.edit.incorrect_type.bottom.sheet.button.continue' | translate }}
  </button>

  <button
    mat-raised-button
    color="error"
    (click)="onSubmit(false)"
  >
    {{ 'shared.rda.intercom.edit.incorrect_type.bottom.sheet.button.cancel' | translate }}
  </button>
</div>
