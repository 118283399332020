interface Sorted {
  sorted: boolean;
  unsorted: boolean;
}

interface Pageable {
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  sort: Sorted;
  unpaged: boolean;
}

export interface PagedResponse<T> {
  content: T[];
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: Pageable;
  size: number;
  sort: Sorted;
  totalElements: number;
  totalPages: number;
}

export type PageData = Omit<PagedResponse<unknown>, 'content'>;