import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogWrapperData } from '@app/shared/ui';
import { UserService } from '@app/shared/entities/rd';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-reset-password-popup',
  templateUrl: './reset-password-popup.component.html',
  styleUrls: ['./reset-password-popup.component.scss']
})
export class ResetPasswordPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<null, Observable<void>>,
    private userService: UserService
  ) { }

  onResetPassword(email: string) {
    this.data.submit(this.userService.resetPassword(email));
  }
}
