import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-possible-cron-solutions',
  templateUrl: './possible-cron-solutions.component.html',
  styleUrls: ['./possible-cron-solutions.component.scss']
})
export class PossibleCronSolutionsComponent {

  @Input() title: string;
  @Input() solutionDescription: string[];

  @Output() setAutomatically: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private translate: TranslateService
  ) {
    this.title = this.translate.instant('shared.payments.possible.cron.solutions.title');
    this.solutionDescription = [];
  }

  public onSetAutomatically(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.setAutomatically.emit();
  }
}
