<mat-card class="card-container">
  <mat-card-header class="header">
    <div class="title-block">
      <mat-card-title>
        {{ 'service.intercom.keys.title' | translate }}
      </mat-card-title>
    </div>
    <button
      (click)="onAdd()"
      mat-raised-button
      color="primary-inverse"
      matTooltip="{{ 'service.intercom.keys.tooltip.add' | translate }}"
      [matTooltipPosition]="'before'"
    >
      <mat-icon>add</mat-icon>
    </button>
  </mat-card-header>

  <hr>

  <mat-card-content>
    <app-intercom-keys-container
      [keys]="keys"
      (addKey)="onAdd()"
      (editKey)="onEdit($event.key)"
      (deleteKey)="onDelete($event.key)"
      *ngIf="keys?.length > 0; else emptyData"
    ></app-intercom-keys-container>

    <ng-template #emptyData>
      <ng-container *ngIf="options?.intercomRequired && options?.disabledActions; else noKeys">
        <app-info-block>
          <ng-container info-block-text>
            {{ 'service.intercom.keys.message.empty_data' | translate }}
          </ng-container>
        </app-info-block>
      </ng-container>

      <ng-template #noKeys>
        <app-info-block>
          <ng-container info-block-text>
            {{ 'service.intercom.keys.message.no_keys' | translate }}
          </ng-container>
        </app-info-block>
      </ng-template>
    </ng-template>
  </mat-card-content>

</mat-card>
