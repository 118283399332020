import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { DialogWrapperModule, InfoBlockModule } from '@app/shared/ui';
import { ServiceDeletePhysicalTubePopupComponent } from './service-delete-physical-tube-popup.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        InfoBlockModule,
        SharedPipesModule,
        DialogWrapperModule,
        NgxTranslateModule
    ],
    declarations: [
        ServiceDeletePhysicalTubePopupComponent
    ],
    exports: [
        ServiceDeletePhysicalTubePopupComponent
    ]
})
export class ServiceDeletePhysicalTubePopupModule { }
