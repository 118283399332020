<app-dialog-wrapper
  [title]="wrapperData.title"
  [componentName]="wrapperData.componentName"
>
  <app-unblock-non-payment-account
    [wrapperData]="wrapperData"
    (close)="onClose()"
  >
  </app-unblock-non-payment-account>
</app-dialog-wrapper>
