import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {SnackbarService} from '@app/shared/components';
import {
  ConnectionService,
  CreateDelegationRequest,
  CreateDelegationResponse,
  ServiceApiService,
  ServiceEntranceFlatResponse,
} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {Abonent} from '@app/shared/models';
import {ComponentStore, tapResponse} from '@ngrx/component-store';
import {from, Observable, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {AbonentsDelegationPopupMode} from '../models';
import {abonentsDelegationPopupInitialState, AbonentsDelegationPopupState} from '@app/views/abonents/components';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AbonentsDelegationPopupStore extends ComponentStore<AbonentsDelegationPopupState> implements OnDestroy {
  readonly status$: Observable<'loading' | 'loaded' | 'close'> = this.select((state: AbonentsDelegationPopupState) => state.status);
  readonly mode$: Observable<AbonentsDelegationPopupMode> = this.select((state: AbonentsDelegationPopupState) => state.mode);
  readonly owner$: Observable<Abonent> = this.select((state: AbonentsDelegationPopupState) => state.owner);

  readonly checkDelegation = this.effect((options$: Observable<
      { entranceId: number, flat: number, phone: number | string, companyId: number }
    >) =>
      options$.pipe(
        switchMap((options) => {
          this.updateStatusState('loading');

          return from(this.findAbonent(options.entranceId, options.flat, options.phone))
            .pipe(
              tapResponse(
                (owner: Abonent) => {
                  this.updateStatusState('loaded');

                  if (owner) {
                    this.updateModeState('confirm');
                    this.updateOwner(owner);
                    return;
                  }

                  this.updateModeState('close');
                },
                (error: HttpErrorResponse) => {
                  this.updateStatusState('loaded');
                  this.snackbar.showMessage(
                    this.translate.instant('abonents.delegation.popup.check.message.failed', {
                      text: parseError(error)
                    })
                  );
                }
              )
            );
        })
      )
  );

  readonly createDelegation = this.effect((options$: Observable<{ request: CreateDelegationRequest }>) =>
    options$.pipe(
      switchMap((options) => {
        this.updateStatusState('loading');

        return from(this.connectionService.createDelegation(options.request))
          .pipe(
            tapResponse(
              (response: CreateDelegationResponse) => {
                this.snackbar.showMessage(
                  this.translate.instant('abonents.delegation.popup.create.message.success'),
                  'success'
                );
                this.updateStatusState('close');
              },
              (error: HttpErrorResponse) => {
                this.snackbar.showMessage(
                  this.translate.instant('abonents.delegation.popup.create.message.failed', {
                    text: parseError(error)
                  }),
                );
                this.updateStatusState('loaded');
              }
            )
          );
      })
    )
  );

  readonly updateModeState = this.updater((state: AbonentsDelegationPopupState, mode: AbonentsDelegationPopupMode) => {
    return {...state, mode};
  });

  readonly updateStatusState = this.updater((state: AbonentsDelegationPopupState, status: 'loading' | 'loaded' | 'close') => {
    return {...state, status};
  });

  readonly updateOwner = this.updater((state: AbonentsDelegationPopupState, owner: Abonent) => {
    return {...state, owner};
  });

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private serviceApiService: ServiceApiService,
    private connectionService: ConnectionService,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {
    super(JSON.parse(JSON.stringify(abonentsDelegationPopupInitialState)));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  addStatusChangeListener(cb: (status: 'loading' | 'loaded' | 'close') => void) {
    this.status$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(status => cb(status));
  }

  private async findAbonent(
    entranceId: number,
    flatNumber: number,
    phone: number | string
  ): Promise<Abonent> {
    let owner: Abonent;

    try {
      const entrancesFlats: ServiceEntranceFlatResponse[] = await this.serviceApiService.getEntrancesFlats(entranceId).toPromise();
      const flat: ServiceEntranceFlatResponse = entrancesFlats.find(entranceFlat => entranceFlat.address.flat === flatNumber);

      if (flat.owner) {
        owner = flat.owner.phone !== phone ? flat.owner : null;
      }
    } catch (error) {
      throw error;
    }

    return owner;
  }
}
