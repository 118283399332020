import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InfoBlockComponent } from './info-block.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        NgxTranslateModule,
    ],
    declarations: [
        InfoBlockComponent
    ],
    exports: [
        InfoBlockComponent
    ]
})
export class InfoBlockModule { }
