import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from '@app/shared/components/snackbar';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { RdaModel } from '@app/shared/entities/rd/rda';
import { parseError } from '@app/shared/helpers';
import { Constants } from '@app/shared/helpers/constants';
import { EditPopupComponentData } from '@app/shared/models';
import { LoaderService } from '@app/shared/entities/common/loader';
import { EditPopupComponent } from '@app/shared/templates';
import { State } from '@app/store/reducers';
import { ShieldModel } from '@app/views/intercom/models';
import { CreateRdaModel, GetRdaModels } from '@app/views/intercom/store/actions';
import { CreateShieldModel, GetShieldModels } from '@app/views/intercom/store/actions/shield-models.actions';
import { getCreateRdaModelFailure, getCreateRdaModelLoading, getCreateRdaModelSuccess, getCreateShieldModelFailure, getCreateShieldModelLoading, getCreateShieldModelSuccess } from '@app/views/intercom/store/states';
import { getRdaModelsLoading, getRdaModelsSuccess } from '@app/views/intercom/store/states/rda-models/get-rda-models.selector';
import { getShieldModelsLoading, getShieldModelsSuccess } from '@app/views/intercom/store/states/shield-models/get-shield-models.selector';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, skip, takeUntil } from 'rxjs/operators';
import { AddModelFormComponent } from './add-model-form/add-model-form.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-models-page',
  templateUrl: './models-page.component.html',
  styleUrls: ['./models-page.component.scss']
})
export class ModelsPageComponent implements OnInit, OnDestroy {
  readonly displayedColumns: string[] = ['name'];
  shieldModels$: Observable<MatTableDataSource<ShieldModel>>;
  rdaModels$: Observable<MatTableDataSource<RdaModel>>;
  private onDestroy$: Subject<void>;
  private dialogRef: MatDialogRef<EditPopupComponent>;

  constructor(
    public resolution: ResolutionService,
    private store: Store<State>,
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initLoading();
    this.initStore();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onAddModel(modelType: string) {
    const type = this.translate.instant(
      modelType === 'rda'
        ? 'intercom.models.page.add.enum.type.rda'
        : 'intercom.models.page.add.enum.type.shield'
    );
    const data: EditPopupComponentData = {
      title: this.translate.instant('intercom.models.page.add.title', {
        type: type
      }),
      providedData: { modelType },
      component: AddModelFormComponent,
      componentName: 'AddModel',
      submit: (modelName: string) => {
        if (modelType === 'rda') {
          this.store.dispatch(new CreateRdaModel(modelName));
        } else if (modelType === 'shield') {
          this.store.dispatch(new CreateShieldModel(modelName));
        }
      }
    };

    this.dialogRef = this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '400px',
      data
    });
  }

  private initLoading() {
    combineLatest([
      this.store.pipe(select(getShieldModelsLoading)),
      this.store.pipe(select(getRdaModelsLoading)),
      this.store.pipe(select(getCreateRdaModelLoading)),
      this.store.pipe(select(getCreateShieldModelLoading))
    ])
      .subscribe((values: boolean[]) => this.loaderService.loaderState = { state: values.some((x: boolean) => x) });
  }

  private initStore() {
    this.onDestroy$ = new Subject();
    this.initStoreListeners();
    this.initStoreSelectors();
    this.getStoreData();
  }

  private initStoreSelectors() {
    this.shieldModels$ = this.store.pipe(select(getShieldModelsSuccess), map(data => new MatTableDataSource(data)));
    this.rdaModels$ = this.store.pipe(select(getRdaModelsSuccess), map(data => new MatTableDataSource(data)));
  }

  private getStoreData() {
    this.getShieldModelsData();
    this.getRdaModelsData();
  }

  private getShieldModelsData() {
    this.store.dispatch(new GetShieldModels());
  }

  private getRdaModelsData() {
    this.store.dispatch(new GetRdaModels());
  }

  private initStoreListeners() {
    this.store.pipe(select(getCreateRdaModelSuccess), takeUntil(this.onDestroy$), skip(1))
      .subscribe(data => {
        if (data) {
          if (this.dialogRef) { this.dialogRef.close(); }
          this.snackbar.showMessage(
            this.translate.instant('intercom.models.page.message.create.success'),
            'success'
          );
          this.getRdaModelsData();
        }
      });

    this.store.pipe(select(getCreateRdaModelFailure), takeUntil(this.onDestroy$), skip(1))
      .subscribe(error => this.snackbar.showMessage(
        this.translate.instant('intercom.models.page.message.create.failed', {
          text: parseError(error)
        })
      ));

    this.store.pipe(select(getCreateShieldModelSuccess), takeUntil(this.onDestroy$), skip(1))
      .subscribe(data => {
        if (data) {
          if (this.dialogRef) { this.dialogRef.close(); }
          this.snackbar.showMessage(
            this.translate.instant('intercom.models.page.message.create_shield.success'),
            'success'
          );
          this.getShieldModelsData();
        }
      });

    this.store.pipe(select(getCreateShieldModelFailure), takeUntil(this.onDestroy$), skip(1))
      .subscribe(error => this.snackbar.showMessage(
        this.translate.instant('intercom.models.page.message.create_shield.failed', {
          text: parseError(error)
        })
      ));
  }
}
