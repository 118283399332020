import { PaymentsWizardStepper } from '../models';
import {
  PaymentsWizardService
} from '@app/views/payments/components/popups/payments-wizard/models/payments-wizard-service';

export interface PaymentsWizardState {
  steps: PaymentsWizardStepper[];
  loading: boolean;
  service: PaymentsWizardService;
}

export const paymentsWizardInitialState = {
  steps: [
    {
      id: 'service',
      text: '',
      complete: false,
      active: true,
      hidden: false,
      disabled: false
    },
    {
      id: 'register',
      text: '',
      complete: false,
      active: false,
      hidden: false,
      disabled: true
    },
    {
      id: 'integration',
      text: '',
      complete: false,
      active: false,
      hidden: false,
      disabled: true
    }
  ],
  loading: false,
  service: PaymentsWizardService.YOOKASSA
};
