import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SnowflakesToArr'
})
export class SnowflakesToArrPipe implements PipeTransform {
  transform(value: number): number[] {
    const res: number[] = [];
    for (let i = 0; i < value; i++) {
      res.push(i);
    }
    return res;
  }
}
