import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbonentStat, ActiveHistoryResponse } from '../models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class MonitoringService extends ApiService {

  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.monitoringApiUrl, ['v1']);
  }

  getAbonentStat(id: number, voipToken: string, dateStartDiff: number): Observable<AbonentStat> {
    const params: any = { dateFrom: (new Date()).getTime() - dateStartDiff };
    if (id) { params['abonentId'] = id; }
    if (voipToken) { params['pushToken'] = voipToken; }

    return this.get<AbonentStat[]>(`/abonents`, 1, { params }).pipe(map(data => data.length > 0 ? data[0] : null));
  }

  getIntercomActiveHistory(rdaId: number, startDate: Date, endDate: Date, stepMilliseconds: number): Observable<ActiveHistoryResponse[]> {
    const start: string = Math.floor(startDate.getTime() / 1000).toString();
    const end: string = Math.floor(endDate.getTime() / 1000).toString();
    const step: string = stepMilliseconds.toString() + 'ms';
    const params: HttpParams = new HttpParams({ fromObject: { start, end, step } });

    return this
      .get<ActiveHistoryResponse[]>(`/adapters/${rdaId}/metrics/status`, 1, { params })
      .pipe(
        map((result: ActiveHistoryResponse[]) => this.prepareActiveHistoryResponse(result))
      );
  }

  getCameraActiveHistory(cameraId: number, startDate: Date, endDate: Date, stepMilliseconds: number): Observable<ActiveHistoryResponse[]> {
    const start: string = Math.floor(startDate?.getTime() / 1000).toString();
    const end: string = Math.floor(endDate?.getTime() / 1000).toString();
    const step: string = stepMilliseconds?.toString() + 'ms';
    const params: HttpParams = new HttpParams({ fromObject: { start, end, step } });

    return this
      .get<ActiveHistoryResponse[]>(`/cameras/${cameraId}/metrics/speed`, 1, { params })
      .pipe(
        map((result: ActiveHistoryResponse[]) => this.prepareActiveHistoryResponse(result))
      );
  }

  private prepareActiveHistoryResponse(activeHistory: ActiveHistoryResponse[]) {
    return activeHistory.map((activeHistoryItem: ActiveHistoryResponse) => ({
      timestamp: activeHistoryItem.timestamp * 1000,
      value: activeHistoryItem.value
    }));
  }
}
