import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';

import { Dictionary } from '@app/shared/helpers';
import { ServicesTypes } from '@app/shared/models';
import {
  FlatConnectionResponse,
  ServiceInfoResponse,
  ServiceResponse
} from '@app/shared/entities/rd';
import { ServiceFlat } from '@app/views/services/components';
import { ServiceSelectedArgs } from '../../models';

@Component({
  selector: 'app-abonent-connection-tool-services',
  templateUrl: './abonent-connection-tool-services.component.html',
  styleUrls: ['./abonent-connection-tool-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbonentConnectionToolServicesComponent {
  @Input() flatConnections: FlatConnectionResponse[][];
  @Input() serviceId: number;
  @Input() loading: boolean;
  @Input() avaliableServices: ServiceInfoResponse[];
  @Input() connectedServices: Dictionary<boolean>;
  @Input() defaultService: Pick<ServiceResponse, 'id' | 'type'>;
  @Input() showTubeCheckbox = false;
  @Input() flatList: ServiceFlat[];
  @Input() disableServices: false;
  @Input() noPhoneChecked: boolean;

  tubeState: boolean;
  servicesForDelete: Pick<ServiceResponse, 'id' | 'type'>[] = [];
  servicesToAdd: Pick<ServiceResponse, 'id' | 'type'>[] = [];

  readonly ServicesTypes: typeof ServicesTypes = ServicesTypes;

  @Output() private serviceSelected = new EventEmitter<ServiceSelectedArgs>();
  @Output() private tubeChanged: EventEmitter<{ checked: boolean }> =
    new EventEmitter();

  onSelectService(checked: boolean, service: ServiceInfoResponse) {
    if (checked) {
      this.servicesToAdd.push({ id: service.id, type: service.type });
      this.servicesForDelete = this.servicesForDelete.filter(
        (s) => s.id !== service.id
      );
    } else {
      this.servicesForDelete.push({ id: service.id, type: service.type });
      this.servicesToAdd = this.servicesToAdd.filter(
        (s) => s.id !== service.id
      );
    }
    this.serviceSelected.emit({
      servicesToAdd: this.servicesToAdd,
      servicesForDelete: this.servicesForDelete
    });
  }

  public hasCurrentService(serviceId: number): boolean {
    return this.flatConnections?.some((connection) => connection.some((flat) => flat.service.id === serviceId));
  }

  public hasAnyService(serviceType: ServicesTypes): boolean {
    const count = this.servicesCountInFlats(serviceType);
    return count > 0 && count !== this.flatList?.length;
  }

  public hasAllService(serviceType: ServicesTypes): boolean {
    const count = this.servicesCountInFlats(serviceType);
    return count === this.flatList?.length;
  }

  public hasNoService(serviceType: ServicesTypes): boolean {
    const count = this.servicesCountInFlats(serviceType);
    return count === 0;
  }

  public hasAnyFlatsTube(): boolean {
    const count = this.physicalTubeCountInFlats();
    return count > 0 && count !== this.flatList?.length;
  }

  public hasAllFlatsTube(): boolean {
    const count = this.physicalTubeCountInFlats();
    return count === this.flatList?.length;
  }

  public hasNoFlatsTube(): boolean {
    const count = this.physicalTubeCountInFlats();
    return count === 0;
  }

  public onChangePhysicalTube(event: MatCheckboxChange) {
    this.tubeState = event.checked;
    this.tubeChanged.emit({ checked: event.checked });
  }

  public showTube(service: ServiceResponse): boolean {
    return (
      this.showTubeCheckbox && service.type === ServicesTypes.SOFTWARE_INTERCOM
    );
  }

  public hideService(service: ServiceResponse|ServiceInfoResponse): boolean {
    return (
      (this.disableServices || this.noPhoneChecked) && service.type !== ServicesTypes.HARDWARE_INTERCOM
    );
  }

  public get allAvailableServices(): number {
    return this.avaliableServices.reduce((count, service) => {
      return !this.hideService(service) && service.id !== this.defaultService?.id ? count + 1 : count;
    }, 0);
  }

  private physicalTubeCountInFlats(): number {
    return this.flatList?.filter((flat) => !!flat.flat && !flat.flat.virtual)
      .length;
  }

  private servicesCountInFlats(serviceType: ServicesTypes): number {
    return this.flatConnections?.filter((connection) =>
      connection.some((flat) => flat.service.type === serviceType)
    ).length;
  }

}
