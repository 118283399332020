<div #playerWrapperElement class="video-manager-player2__wrapper" [ngClass]="ngClassMainWrapper">
  <p *ngIf="!isSmallSize" class="video-manager-player2--title">{{ camera?.camera?.id }} {{ camera?.camera?.address | address }}</p>
  <i *ngIf="isSmallSize" class="video-manager-player2--title video-manager-player2--title__like-icon pi pi-info-circle"
     pTooltip="{{ camera?.camera?.id }} {{ camera?.camera?.address | address }}"></i>
  <video #videoElement controls [hidden]="!loaded" class="video-manager-player2--video"></video>
  <div *ngIf="!isSmallSize" #actionsLarge class="video-manager-player2__actions video-manager-player2__actions__wrapper">
    <div class="video-manager-player2__actions__top">
      <ng-container *ngIf="active"></ng-container>
      <ng-container *ngIf="!active">
        <div class="video-manager-player2__actions__left">
          <ng-container *ngIf="isCanChangeToPrevEvent"
                        [ngTemplateOutlet]="changeEventBtn" [ngTemplateOutletContext]="{next: false}"></ng-container>
        </div>
        <div class="video-manager-player2__actions__center"></div>
        <div class="video-manager-player2__actions__right">
          <ng-container *ngIf="isCanChangeToNextEvent"
                        [ngTemplateOutlet]="changeEventBtn" [ngTemplateOutletContext]="{next: true}"></ng-container>
        </div>
      </ng-container>
    </div>
    <div class="video-manager-player2__actions__bottom">
      <ng-container *ngIf="active">
        <div class="video-manager-player2__actions__left"></div>
        <div class="video-manager-player2__actions__center"></div>
        <div class="video-manager-player2__actions__right">
          <ng-container [ngTemplateOutlet]="openDoorBtn"></ng-container>
          <ng-container [ngTemplateOutlet]="changeModeBtn" [ngTemplateOutletContext]="{active: true}"></ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!active">
        <div class="video-manager-player2__actions__left">
          <ng-container *ngIf="isCanMinus10Sec" [ngTemplateOutlet]="changeSomeSecBtn" [ngTemplateOutletContext]="{plus: false}"></ng-container>
        </div>
        <div class="video-manager-player2__actions__center">
          <ng-container [ngTemplateOutlet]="changeModeBtn" [ngTemplateOutletContext]="{active: false}"></ng-container>

          <p-button *ngIf="!isEmpty(eventsList)" [label]="selectedEvent ? selectedEvent.label : ('abonent.page.info.header.events' | translate)"
                    styleClass="p-button-info p-button-text"
                    (onClick)="events.toggle($event)"></p-button>
          <p-tieredMenu #events styleClass="video-manager-player2__actions__events-list video-manager-player2__tired-actions"
                        [hidden]="true" [model]="eventsList" [popup]="true" appendTo="body"></p-tieredMenu>

          <p-button *ngIf="!isEmpty(playlists)" [label]="archivePlaylist?.label ? archivePlaylist.label : ('abonent.page.info.header.events' | translate)"
                    styleClass="p-button-info p-button-text"
                    (onClick)="playlistArchive.toggle($event)"></p-button>
          <p-tieredMenu #playlistArchive styleClass="video-manager-player2__tired-actions"
                        [hidden]="true"[model]="playlists" [popup]="true" appendTo="body"></p-tieredMenu>

          <p-button *ngIf="(!errorLoadingPlaylist || !errorLoadingVideo) && !isEmpty(playlists)"
                    [label]="'shared.video_player.plyr.template.button.get_video_shot' | translate"
                    styleClass="p-button-info p-button-text"
                    (onClick)="downloadVideoShot()"></p-button>
        </div>
        <div class="video-manager-player2__actions__right">
          <ng-container *ngIf="isCanAdd10Sec" [ngTemplateOutlet]="changeSomeSecBtn" [ngTemplateOutletContext]="{plus: true}"></ng-container>
        </div>
      </ng-container>
    </div>
  </div>
  <div *ngIf="isSmallSize && !hideSmallActions" #actionsSmall class="video-manager-player2__actions video-manager-player2__actions__wrapper">
    <div class="video-manager-player2__actions__top"></div>
    <div class="video-manager-player2__actions__bottom">
      <div class="video-manager-player2__actions__left">
        <ng-container *ngIf="active" [ngTemplateOutlet]="changeModeBtn" [ngTemplateOutletContext]="{active: true}"></ng-container>

        <p-button *ngIf="!active" [label]="'shared.video_player.plyr.template.button.live' | translate"
                  [icon]="'pi pi-circle-fill pi-icon--red'" iconPos="left" styleClass="p-button-info p-button-text"
                  (onClick)="changeMode()"></p-button>
        <p-button *ngIf="!active" icon="pi pi-chevron-down" styleClass="p-button-info p-button-text"
                  (onClick)="smallScreenMenu.toggle($event)"></p-button>
        <p-tieredMenu #smallScreenMenu styleClass="video-manager-player2__tired-actions"
                      [hidden]="true" [model]="smallScreenActions" [popup]="true" appendTo="body"></p-tieredMenu>
      </div>
    </div>
  </div>

  <ng-template #openDoorBtn>
    <p-button *ngIf="isCanOpenDoor" [label]="'shared.video_player.plyr.template.button.open_door'| translate"
              icon="pi pi-sign-in pi-icon--blue" iconPos="left"
              styleClass="p-button-info p-button-text"
              (onClick)="openDoor()"></p-button>
  </ng-template>

  <ng-template #changeModeBtn let-active="active">
    <p-button *ngIf="!errorLoadingPlaylist" [label]="(active ? 'shared.video_player.plyr.template.button.archive' : 'shared.video_player.plyr.template.button.live') | translate"
              styleClass="p-button-info p-button-text" [icon]="active ? 'pi pi-save' : 'pi pi-circle-fill pi-icon--red'"
              (onClick)="changeMode()"></p-button>
  </ng-template>

  <ng-template #changeEventBtn let-next="next">
    <p-button [label]="(next ? 'shared.video_player.plyr.template.button.next_event2' : 'shared.video_player.plyr.template.button.prev_event2') | translate"
              [icon]="next ? 'pi pi-angle-right' : 'pi pi-angle-left'" [iconPos]="next ? 'right' : 'left'"
              styleClass="p-button-info p-button-text"
              (onClick)="changeEvent(next)"></p-button>
  </ng-template>

  <ng-template #changeSomeSecBtn let-plus="plus">
    <p-button [label]="(plus ? 'shared.video_player.plyr.template.button.plus_10_second' : 'shared.video_player.plyr.template.button.minus_10_second') | translate"
              [icon]="plus ? 'pi pi-angle-double-right' : 'pi pi-angle-double-left'" [iconPos]="plus ? 'right' : 'left'"
              styleClass="p-button-info p-button-text"
              (onClick)="changeTimelineOnSomeSec(plus)"></p-button>
  </ng-template>
</div>
