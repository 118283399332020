import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CreateOrderRequest, OrderResponse } from '@app/views/intercom/models';
import { PopupBasicComponent } from '@app/shared/templates';
import { Company } from '@app/shared/entities/rd/company/models';
import { SnackbarService } from '@app/shared/components/snackbar';
import { Subject } from 'rxjs';
import { orderCreateFormGroup } from './order-create-form.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-order-create-form',
  templateUrl: './order-create-form.component.html',
  styleUrls: ['./order-create-form.component.scss']
})
export class OrderCreateFormComponent extends PopupBasicComponent {
  form: UntypedFormGroup;
  order: OrderResponse;
  isManufacturer: boolean;
  companies: Company[];

  constructor(
    private snackbar: SnackbarService,
    private translate: TranslateService,
    @Inject('subject') private subject: Subject<any>,
    @Inject('data') private data: { order: OrderResponse, isManufacturer: boolean, companies: Company[] }
  ) {
    super();
    this.initVariables();
    this.initForm();
  }

  onSubmit() {
    if (this.form.valid) {
      const { rdaCount, customerCompanyId } = this.form.getRawValue();
      const request: CreateOrderRequest = { rdaCount };
      if (!this.order && this.isManufacturer) {
        request.customerCompanyId = customerCompanyId;
      }
      this.subject.next(request);
    } else {
      this.snackbar.showMessage(
        this.translate.instant('intercom.orders.card.form.create.message.indicate_number_adapters') +
        (
          !this.order && this.isManufacturer
            ? ' ' + this.translate.instant('intercom.orders.card.form.create.message.and_select_company')
            : ''
        ));
    }
  }

  onSelectCompany(event: { companyId: number }) {
    this.form.get('customerCompanyId').setValue(event.companyId);
  }

  private initVariables() {
    this.order = this.data.order;
    this.isManufacturer = this.data.isManufacturer;
    this.companies = this.data.companies;
  }

  private initForm() {
    this.form = orderCreateFormGroup().form;
    if (this.order) {
      this.form.get('rdaCount').setValue(this.order.rdaCount);
    }
    if (!this.isManufacturer || this.order) {
      this.form.get('customerCompanyId').clearValidators();
    }
  }
}
