import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { CameraEditContentModule, DeleteEntranceContentModule, DeleteIntercomContentModule, EntranceEditContainerModule, IntercomEditContentModule, RdaSelectSearchModule } from '@app/shared/components';
import { SharedPipesModule } from '@app/shared/pipes';
import { RdeaStepperModule } from '@app/shared/templates';
import { ComponentLoaderModule, DialogWrapperModule, InfoBlockModule } from '@app/shared/ui';
import { EntrancesListContentModule, ServiceIndiciesModeContentModule, ServiceIntercomsListContentModule } from '@app/views/services/components';
import { GateServiceEntrancesIntercomsModule } from '../../containers';
import { GateWizardContentCameraComponent, GateWizardContentChecklistComponent, GateWizardContentComponent, GateWizardContentEntrancesComponent, GateWizardContentIntercomsComponent, GateWizardContentIntercomsDefaultComponent, GateWizardContentIntercomsSelectionComponent, GateWizardContentIntercomsWithIndiciesComponent, GateWizardContentIntercomTypeComponent } from './gate-wizard-content';
import { GateWizardContentIntercomsReuseComponent } from './gate-wizard-content/gate-wizard-content-intercoms/gate-wizard-content-intercoms-reuse';
import { GateWizardContentTariffComponent } from './gate-wizard-content/gate-wizard-content-tariff';
import { GateWizardPopupComponent } from './gate-wizard-popup.component';
import { SoftwareIntercomTariffPopupContentModule } from '@app/views/services/submodules/software-intercoms/components/software-intercom-page/popups/software-intercom-tariff-popup';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        SharedPipesModule,
        DialogWrapperModule,
        RdeaStepperModule,
        InfoBlockModule,
        EntrancesListContentModule,
        EntranceEditContainerModule,
        DeleteEntranceContentModule,
        ServiceIntercomsListContentModule,
        IntercomEditContentModule,
        DeleteIntercomContentModule,
        CameraEditContentModule,
        GateServiceEntrancesIntercomsModule,
        ServiceIndiciesModeContentModule,
        ComponentLoaderModule,
        RdaSelectSearchModule,
        SoftwareIntercomTariffPopupContentModule,
        NgxTranslateModule,
    ],
  declarations: [
    GateWizardPopupComponent,
    GateWizardContentComponent,
    GateWizardContentEntrancesComponent,
    GateWizardContentIntercomsComponent,
    GateWizardContentCameraComponent,
    GateWizardContentChecklistComponent,
    GateWizardContentIntercomTypeComponent,
    GateWizardContentIntercomsWithIndiciesComponent,
    GateWizardContentIntercomsDefaultComponent,
    GateWizardContentIntercomsSelectionComponent,
    GateWizardContentIntercomsReuseComponent,
    GateWizardContentTariffComponent
  ],
  exports: [
    GateWizardPopupComponent
  ]
})
export class GateWizardPopupModule { }
