import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IntercomPanelResponse } from '@app/shared/entities/rd';

@Component({
  selector: 'app-intercom-panel-delete-content',
  templateUrl: './intercom-panel-delete-content.component.html',
  styleUrls: ['./intercom-panel-delete-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntercomPanelDeleteContentComponent {
  @Input() loading: boolean;
  @Input() intercomPanel: IntercomPanelResponse;

  @Output() private deleteIntercomPanel: EventEmitter<void> = new EventEmitter();

  onDelete() {
    this.deleteIntercomPanel.emit();
  }
}
