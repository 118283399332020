import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntrancePageResponse } from '@app/shared/models';
import { DialogWrapperData } from '@app/shared/ui';

@Component({
  selector: 'app-entrance-support-request-popup',
  templateUrl: './entrance-support-request-popup.component.html',
  styleUrls: ['./entrance-support-request-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntranceSupportRequestPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<EntrancePageResponse, null>
  ) { }
}
