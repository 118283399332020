import {
  AfterViewInit,
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output, ViewChild
} from '@angular/core';
import { Address } from '@app/shared/models';
import { ServiceFlat } from '@app/views/services/components';

@Component({
  selector: 'app-flat-phone',
  templateUrl: './flat-phone.component.html',
  styleUrls: ['./flat-phone.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatPhoneComponent implements AfterViewInit {
  @ViewChild('phonesBlock', { static: false}) phonesBlock;

  @Input() flat: ServiceFlat;
  @Input() address: Address;
  @Input() expandAdditionalPhones: boolean;

  @Output() addPhone: EventEmitter<{
    address: Address;
    flat: Partial<ServiceFlat>;
  }> = new EventEmitter();

  constructor(private ch: ChangeDetectorRef) {}

  public onAddPhone(): void {
    const { address, flat } = this;
    this.addPhone.emit({ address, flat });
  }

  ngAfterViewInit(): void {
    this.phonesBlock?.nativeElement.setAttribute('aria-expanded', this.expandAdditionalPhones);
    this.ch.detectChanges();
  }
}
