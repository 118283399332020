<app-top-bar
  (swiperight)="sidenav.open()"
  (swipeleft)="sidenav.close()"
  (menuClick)="sidenav.opened ? sidenav.close() : sidenav.open()"
  *ngIf="authenticated"></app-top-bar>

<mat-sidenav-container
  [class.mobile]="authenticated && (mdWDownBreakpoint$ | async)">
  <mat-sidenav
    #sidenav
    [mode]="(mdWDownBreakpoint$ | async) ? 'over' : 'side'"
    [opened]="authenticated && !(mdWDownBreakpoint$ | async)">
    <a
      [routerLink]="'/'"
      *ngIf="authenticated && !(mdWDownBreakpoint$ | async)">
      <img
        alt="rd-logo"
        [src]="resourcesHelper.getResource(logoOption.path, logoOption.srcset, logoOption.type) | safeUrl"
        [srcset]="
          resourcesHelper.getResource(logoOption.path, logoOption.srcset, logoOption.type) | safeUrl
        " />
    </a>

    <mat-nav-list *ngIf="authenticated">
      <app-menu
        [authenticated]="authenticated"
        [activeRequests]="activeSignUpsCount$ | async"
        [unresolvedIssues]="unresolvedIssuesCount$ | async"></app-menu>
    </mat-nav-list>

  </mat-sidenav>

  <mat-sidenav-content>
    <div class="sidenav-content__wrapper">
      <app-title-bar *ngIf="authenticated && !(mdWDownBreakpoint$ | async)"
                     [additionalActions]="additionalActionsService?.actions"
                     [snapshotDataParentRoute]="snapshotDataParentRoute"></app-title-bar>
      <div class="sidenav-content__content__wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>

    <app-splash-loader [fullScreen]="!authenticated"></app-splash-loader>
  </mat-sidenav-content>
</mat-sidenav-container>
