<div class="warning-container">
  <div class="warning-container-img">
    <img src="assets/icons/ic-alert.svg" alt="alert">
  </div>
  <div class="warning-container-description">
    <span>
      У вас есть необработанные заявки на отключение. Перейдите к ним во избежание недовольства со стороны абонентов.
    </span>
  </div>
  <div class="warning-container-action">
    <p-button (onClick)="navigate()">
      Перейти к заявкам
    </p-button>
  </div>
</div>
