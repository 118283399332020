import { ActionReducerMap } from '@ngrx/store';
import { GatesActions, GatesActionTypes } from './gates-page.actions';
import { gatesPageInitialState, GatesState } from './gates-page.state';

function reducer(state: GatesState = gatesPageInitialState, action: GatesActions): GatesState {
    switch (action.type) {
        case GatesActionTypes.GetGatesPage: {
            return {
                ...state,
                gates: null,
                loading: true
            };
        }

        case GatesActionTypes.GetGatesPageSuccess: {
            return {
                ...state,
                gates: action.response,
                loading: false, 
                pageData: action.pageData
            };
        }

        case GatesActionTypes.GetGatesPageFailure: {
            return {
                ...state,
                gates: null,
                loading: false
            };
        }

        case GatesActionTypes.GetGateEntrancesIntercoms: {
            return {
                ...state,
                entrancesIntercoms: null
            };
        }

        case GatesActionTypes.GetGateEntrancesIntercomsSuccess: {
            return {
                ...state,
                entrancesIntercoms: action.intercoms
            };
        }

        case GatesActionTypes.AddGateEntranceIntercomsSuccess: {
            return {
                ...state,
                entrancesIntercoms: [...state.entrancesIntercoms ?? [], ...action.intercoms]
            };
        }

        case GatesActionTypes.RemoveGateEntranceIntercoms: {
            action.intercomsIds.forEach((intercomId: number) => {
                state.entrancesIntercoms.splice(
                    state.entrancesIntercoms.findIndex(intercom => intercom.id === intercomId), 1
                );
            });

            return {
                ...state,
                entrancesIntercoms: state.entrancesIntercoms.slice()
            };
        }

        case GatesActionTypes.UpdateGateEntranceIntercomsSuccess: {
            state.index = action.index;

            return {
                ...state,
            };
        }

        case GatesActionTypes.SetGateIntercomType: {
            return {
                ...state,
                intercomType: action.intercomType
            };
        }

        case GatesActionTypes.SetGateIntercom: {
            return {
                ...state,
                intercom: action.intercom
            };
        }

        case GatesActionTypes.SetGateIndex: {
            return {
                ...state,
                index: action.index
            };
        }

        case GatesActionTypes.SetGateMode: {
            return {
                ...state,
                mode: action.mode
            };
        }

        case GatesActionTypes.ClearGateState: {
            return gatesPageInitialState;
        }

        default: {
            return state;
        }
    }
}

export const gatesReducers: ActionReducerMap<{ gates: GatesState }> = {
    gates: reducer
};
