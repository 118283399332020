import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PbxOnRdaResponse } from '@app/shared/entities/rd';
import { SelectSearch } from '@app/shared/models';
import { Observable } from 'rxjs';
import { PbxOnRdaSearchSelectResponse } from './models';
import { PbxOnRdaSearchSelectService } from './pbx-on-rda-search-select.service';
import { PbxOnRdaSearchSelectFacade } from './store';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-pbx-on-rda-search-select',
  templateUrl: './pbx-on-rda-search-select.component.html',
  styleUrls: ['./pbx-on-rda-search-select.component.scss']
})
export class PbxOnRdaSearchSelectComponent implements OnInit {
  @Input() pbxOnRdaList: PbxOnRdaResponse[];
  @Input() initPbxOnRdaSelect: SelectSearch;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.title = disabled
      ? this.translate.instant('shared.pbx_on_rda.search.select.pbx_on_rda')
      : this.translate.instant('shared.pbx_on_rda.search.select.find_and_select_pbx_on_rda')
    ;
  }
  @Input() withoutDescription: boolean;
  title: string;
  pbxOnRdaUid: string;
  loading$: Observable<boolean> = this.pbxOnRdaSearchSelectFacade.loading$;
  selectedPbxOnRda$: Observable<SelectSearch> = this.pbxOnRdaSearchSelectFacade.selectedPbxOnRda$;
  pbxOnRdas: PbxOnRdaSearchSelectResponse[];

  @Output() private pbxOnRdaSelected: EventEmitter<PbxOnRdaSearchSelectResponse> = new EventEmitter();
  private _disabled: boolean;

  constructor(
    private pbxOnRdaSearchSelectFacade: PbxOnRdaSearchSelectFacade,
    private pbxOnRdaSearchSelectService: PbxOnRdaSearchSelectService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.pbxOnRdaSearchSelectFacade.addPbxOnRdaRdaListener((pbxOnRdas: PbxOnRdaSearchSelectResponse[]) =>
      this.pbxOnRdas = pbxOnRdas
    );

    if (this.pbxOnRdaList?.length > 0) {
      this.initUsingIntercoms();
    }

    this.onGetPbxOnRdaList();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  initUsingSelectedPbxOnRda(selectedPbxOnRda: SelectSearch) {
    this.pbxOnRdaUid = selectedPbxOnRda.text;
    this.pbxOnRdaSearchSelectFacade.initUsingSelectedPbxOnRda(selectedPbxOnRda);
    this.pbxOnRdaSelected.emit({ value: null, select: selectedPbxOnRda });
  }

  initUsingIntercoms() {
    if (!this.pbxOnRdaList) {
      return;
    }

    this.pbxOnRdaSearchSelectFacade.initUsingPbxOnRdaList(this.pbxOnRdaList);

    if (this.pbxOnRdaList?.length > 0) {
      this.pbxOnRdas = this.pbxOnRdaSearchSelectService.preparePbxOnRdasList(this.pbxOnRdaList);
      this.pbxOnRdaUid = this.pbxOnRdas[0].value.pbxOnRdaUid;

      this.pbxOnRdaSelected.emit({
        value: this.pbxOnRdaList[0],
        select: {
          value: this.pbxOnRdaList[0].pbxOnRdaUid,
          text: this.pbxOnRdaList[0].pbxOnRdaUid
        }
      });
    }
  }

  onGetPbxOnRdaList(value?: string) {
    this.pbxOnRdaSearchSelectFacade.getPbxOnRdaPage(value);
  }

  onSelectPbxOnRda(value: string) {
    const selectedPbxRdaIdx: number = this.pbxOnRdas.findIndex((pbxOnRda: PbxOnRdaSearchSelectResponse) =>
      pbxOnRda.select.text === value
    );

    if (selectedPbxRdaIdx < 0) {
      this.pbxOnRdaSelected.emit({ value: null, select: null });
      return;
    }

    this.pbxOnRdaSearchSelectFacade.selectPbxOnRdaFromPbxOnRdaList(selectedPbxRdaIdx);

    this.pbxOnRdaSelected.emit({
      value: this.pbxOnRdas[selectedPbxRdaIdx].value,
      select: this.pbxOnRdas[selectedPbxRdaIdx].select
    });
  }
}
