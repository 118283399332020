import { HttpErrorResponse } from '@angular/common/http';
import { ServiceCreateRequest } from '@app/shared/entities/rd';
import { Action } from '@ngrx/store';
import { VideoSurveillancePageModel } from './../models';
import { PageData } from '@app/shared/models';

export enum VideoSurveillanceListActionTypes {
    GetVideoSurveillanceList = '[Video] GetVideoSurveillanceList',
    GetVideoSurveillanceListSuccess = '[Video] GetVideoSurveillanceListSuccess Success',
    GetVideoSurveillanceListFailure = '[Video] GetVideoSurveillanceListFailure Failure',

    CreateVideoSurveillance = '[Video] CreateVideoSurveillance',
    CreateVideoSurveillanceSuccess = '[Video] CreateVideoSurveillance Success',
    CreateVideoSurveillanceFailure = '[Video] CreateVideoSurveillance Failure',

    DeleteVideoSurveillance = '[Video] DeleteVideoSurveillance',
    DeleteVideoSurveillanceSuccess = '[Video] DeleteVideoSurveillance Success',
    DeleteVideoSurveillanceFailure = '[Video] DeleteVideoSurveillance Failure'
}

export class GetVideoSurveillanceList implements Action {
    readonly type = VideoSurveillanceListActionTypes.GetVideoSurveillanceList;

    constructor(public page: number, public size: number, public search: string) { }
}

export class GetVideoSurveillanceListSuccess implements Action {
    readonly type = VideoSurveillanceListActionTypes.GetVideoSurveillanceListSuccess;

    constructor(
        public response: VideoSurveillancePageModel[], 
        public pageData: PageData
    ) { }
}

export class GetVideoSurveillanceListFailure implements Action {
    readonly type = VideoSurveillanceListActionTypes.GetVideoSurveillanceListFailure;

    constructor(public error: HttpErrorResponse) { }
}

export class CreateVideoSurveillance implements Action {
    readonly type = VideoSurveillanceListActionTypes.CreateVideoSurveillance;

    constructor(public request: ServiceCreateRequest, public options: { filterValue: string, pageIndex: number }) { }
}

export class CreateVideoSurveillanceSuccess implements Action {
    readonly type = VideoSurveillanceListActionTypes.CreateVideoSurveillanceSuccess;

    constructor() { }
}

export class CreateVideoSurveillanceFailure implements Action {
    readonly type = VideoSurveillanceListActionTypes.CreateVideoSurveillanceFailure;

    constructor() { }
}

export class DeleteVideoSurveillance implements Action {
    readonly type = VideoSurveillanceListActionTypes.DeleteVideoSurveillance;

    constructor(public serviceId: number, public options: { pageIndex: number, pageSize: number, filterValue: string }) { }
}

export class DeleteVideoSurveillanceSuccess implements Action {
    readonly type = VideoSurveillanceListActionTypes.DeleteVideoSurveillanceSuccess;

    constructor() { }
}

export class DeleteVideoSurveillanceFailure implements Action {
    readonly type = VideoSurveillanceListActionTypes.DeleteVideoSurveillanceFailure;

    constructor() { }
}

export type VideoSurveillanceActions =
    | GetVideoSurveillanceList
    | GetVideoSurveillanceListSuccess
    | GetVideoSurveillanceListFailure

    | CreateVideoSurveillance
    | CreateVideoSurveillanceSuccess
    | CreateVideoSurveillanceFailure

    | DeleteVideoSurveillance
    | DeleteVideoSurveillanceSuccess
    | DeleteVideoSurveillanceFailure;
