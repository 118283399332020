import { Pipe, PipeTransform } from '@angular/core';
import {IAddressConnections} from '@app/shared/entities/rd';
import {AddressFormatter} from '@app/shared/services';

@Pipe({
  name: 'connectionsSearch',
  standalone: true
})
export class ConnectionsSearchPipe implements PipeTransform {

  constructor(private addressFormatter: AddressFormatter) {}

  transform(addressConnections: IAddressConnections[], search: string): IAddressConnections[] {
    if (search === null || !search) { return addressConnections; }
    return this.searchInAddressConnections(addressConnections, search);
  }

  public searchInAddressConnections(data: IAddressConnections[], query: string): IAddressConnections[] {
    return data.filter(item => {
      const { flat, connections } = item;
      const address = `${this.removeAllSpaces(this.addressFormatter?.formatAddress(flat?.address))},кв.${flat.address.flat}`.toLowerCase();
      query = this.removeAllSpaces(query).toLowerCase();
      if (address?.includes(query)) {
        return true;
      }
      return !!connections?.find((c) => c.service?.customName?.includes(query));
    });
  }

  private removeAllSpaces(str: string): string {
    return str.replace(/\s+/g, '');
  }
}
