import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete-entrance-content',
  templateUrl: './delete-entrance-content.component.html',
  styleUrls: ['./delete-entrance-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteEntranceContentComponent {
  @Input() loading: boolean;
  @Input() entranceId: number;

  @Output() private deleteEntrance: EventEmitter<void> = new EventEmitter();

  onDelete() {
    this.deleteEntrance.emit();
  }
}
