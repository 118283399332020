import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DateService {

  constructor(
    private translate: TranslateService
  ) {}

  get dateDiffs(): Array<{ value: number, name: string }> {
    return [
      { value: 5, name: this.translate.instant('plural.minute', { count: 5 }) },
      { value: 15, name: this.translate.instant('plural.minute', { count: 15 }) },
      { value: 30, name: this.translate.instant('plural.minute', { count: 30 }) },
      { value: 60, name: this.translate.instant('plural.hour', { count: 1 }) },
      { value: 60 * 2, name: this.translate.instant('plural.hour', { count: 2 }) },
      { value: 60 * 8, name: this.translate.instant('plural.hour', { count: 8 }) },
      { value: 60 * 24, name: this.translate.instant('plural.day', { count: 1 }) },
      { value: 60 * 24 * 2, name: this.translate.instant('plural.day', { count: 2 }) },
      { value: 60 * 24 * 5, name: this.translate.instant('plural.day', { count: 5 }) },
      { value: 60 * 24 * 7, name: this.translate.instant('plural.day', { count: 7 }) },
      { value: 60 * 24 * 14, name: this.translate.instant('plural.day', { count: 14 }) },
      { value: 60 * 24 * 30, name: this.translate.instant('plural.day', { count: 30 }) },
      { value: Math.floor(((new Date()).getTime() - (new Date('January 1, 2018')).getTime()) / 60000), name: this.translate.instant('time.all') },
    ];
  }
}
