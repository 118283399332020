import { FlatCreateResponse } from './flat-create-response.model';
import { Account } from '@app/shared/entities/rd';
import { FlatConnectionServiceResponse } from './flat-connection-service-response.model';

export interface FlatConnectionResponse {
    account: Account;
    blocked: boolean;
    flat: FlatCreateResponse;
    id: number;
    service: FlatConnectionServiceResponse;
}
