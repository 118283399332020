<div class="c-container">
  <div class="c-container-header">
   <div class="c-container-header-left">
     <label>
       {{'abonent.sign_ups.field.abonent_number' | translate}}
     </label>
     <span>
      +{{dynamicDialogConfig.data.abonent.phone}}
    </span>
   </div>
    <div class="c-container-header-right">
      <input
        pInputText
        [placeholder]="'shared.select.search.placeholder.search' | translate"
        style="height: 30px"
        (input)="onSearch($event.target)"
      >
      <div class="c-container-header-right-all">
        <label>{{allControl.value ? 'Выключить все' : 'Включить все'}}</label>
        <p-checkbox
          binary="true"
          [formControl]="allControl"
          pTooltip="Без учета поиска, для всех услуг"
        ></p-checkbox>
      </div>
    </div>
  </div>
  <hr style="margin: 8px 0">
  <div class="c-container-content">
    <ng-container *ngFor="let delegation of (abonentDelegationMobileVersion?.addressConnections | connectionsSearch: searchString)">
      <div class="c-container-content-card">
        <div class="c-container-content-card-address">
          <span class="c-container-content-card-address__text">
            {{delegation.flat.address | address}}, кв. {{delegation.flat.address?.flat}}
          </span>
        </div>
        <div *ngFor="let connection of delegation.connections" class="c-container-content-card-services">
          <div class="row-display">
            <div class="flex-columns">
              <span class="c-container-content-card-services__type">
                {{'service-navbar.tab.action.addition.' + ServiceType[connection.service.type] | translate}}
                <span *ngIf="connection.service?.customName">, {{connection.service?.customName}}</span>
              </span>
              <span class="c-container-content-card-services__count">
                <span style="color:#EF5E67;">
                  {{connection.delegationTunings.limit === 0 ? ('service-navbar.tab.action.addition.cant_delegation' | translate) : ''}}
                </span>
                <span style="color:#a7a7a9;">
                  {{connection.delegationTunings.limit === null ? ('service-navbar.tab.action.addition.unrestricted_text' | translate) : ''}}
                </span>
                <span style="color:#a7a7a9;">
                  {{connection.delegationTunings.limit !== null && connection.delegationTunings.limit !== 0 && connection.delegationsLeft && connection.delegationsLeft > 0 ? 'Можно поделиться ещё ' + connection.delegationsLeft + ' раз' : ''}}
                </span>
                <span style="color:#EF5E67;">
                  {{connection.delegationTunings.limit !== null && connection.delegationTunings.limit !== 0 && connection.delegationsLeft && connection.delegationsLeft < 0 ? 'лимит доступов исчерпан' : ''}}
                </span>
              </span>
            </div>
            <p-inputSwitch
              [formControl]="connection['control']"
            ></p-inputSwitch>
          </div>
        </div>
      </div>
      <hr>
    </ng-container>
  </div>
  <div class="c-container-actions">
    <button
      pButton
      label="Предоставить доступ"
      styleClass="p-button-raised"
      style="width: 100%"
      (click)="onAccessChange()"
    ></button>
  </div>
</div>

<app-component-loader
  *ngIf="loading"
  [overTheComponent]="true"
  [sizePx]="40"
></app-component-loader>
