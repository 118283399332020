import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SplashLoaderComponent } from './splash-loader.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        SplashLoaderComponent
    ],
    exports: [
        SplashLoaderComponent
    ]
})
export class SplashLoaderModule { }
