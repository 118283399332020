import { Flat } from '@app/shared/models';
import { Abonent } from './../../../../models/abonent';
import { Account } from './../../account/models/account';
import { ServiceConnection } from './service-connection.model';
import { ServiceResponse } from './service-response.model';

export interface ServiceBlockPhysicalTubeResponse {
    connection: ServiceConnection;
    dependantService: Pick<ServiceResponse, 'id' | 'type'>;
    abonent: Abonent;
    account: Partial<Account>;
    flat: Partial<Flat>;
}
