import { Pipe, PipeTransform } from '@angular/core';
import { IConnection } from '@app/shared/entities/rd';
import { ServiceType } from '@app/views/services/submodules/shared/abonents-delegation/pipes/service-converter.pipe';
import { TranslateService } from '@ngx-translate/core';
import {AddressFormatter} from '@app/shared/services';

@Pipe({
  name: 'ownServicesAdapter',
  standalone: true
})
export class OwnServicesAdapterPipe implements PipeTransform {
  constructor(
    private translate: TranslateService,
    private addressFormatter: AddressFormatter,
  ) {}

  transform(connections: IConnection[], search: string): IConnection[] {
    search = search && search.toLowerCase();
    return connections
      .map((c) => {
        return {
          ...c,
          serviceType: this.translate.instant(
            `service-navbar.tab.action.addition.${ServiceType[c.service.type]}`
          ),
          fullAddress: c['address'] ? `${this.addressFormatter.formatAddress(c['address'])}, кв. ${c['address']?.flat}` :  c?.service?.name,
          accountNumber: c.account.number
            ? c.account.number
            : this.translate.instant(
                'abonent.accounts.message.account_not_specified'
              ),
          fromAbonentPhone: `+${c['fromAbonent']?.phone}`
        };
      })
      .filter((result) =>
        search
          ? result.serviceType?.toLowerCase().includes(search) ||
            result.fullAddress?.toLowerCase()?.includes(search) ||
            result?.accountNumber === +search ||
            result.fromAbonentPhone?.toLowerCase()?.includes(search)
          : result
      );
    // TODO result?.toAbonent?.phone?.toString().toLowerCase()?.includes(search)
  }
}
