<app-dialog-wrapper
  [componentName]="data.componentName"
  [title]="data.title">
  <div class="popup barcode-block">
    <div class="barcode-model-block">
      <div class="canvas-block">
        <canvas id="qrCodeCanvas"></canvas>
      </div>
      <div class="rda-info-block">
        <p class="rda-model">
          {{ 'intercom.new.adapters.page.print_adapter_uid.field.rda_model' | translate }}
        </p>
        <p class="rda-version">{{ data.body.adapter.rdaModel }}</p>
        <p class="uid-data">{{ data.body.adapter.uid }}</p>
      </div>
    </div>

    <div class="data-block" id="printDataBlock">
      <p class="batch-data">VS: {{ data.body.batch.shieldModelName }}</p>
      <p class="date-data">{{ preparedDate }}</p>
    </div>

    <div class="bend-line-block">
      <hr />
      <p class="bend-line-text">
        {{ 'intercom.new.adapters.page.print_adapter_uid.bend_line' | translate }}
      </p>
    </div>

    <div class="bend-line-block cut">
      <div class="line"></div>
    </div>

    <div class="bend-line-block-uid">
      <p class="uid-data uid-data_center">{{ data.body.adapter.uid }}</p>
    </div>

    <div id="err"></div>
  </div>
</app-dialog-wrapper>
