import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'bool'
})
export class BoolPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) {}

  transform(value: boolean) {
    return this.translate.instant(value ? 'enum.bool.yes' : 'enum.bool.no');
  }
}
