import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Constants } from '@app/shared/helpers';
import { EntrancePageResponse } from '@app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData } from '@app/shared/ui';
import { EntranceSupportRequestPopupComponent } from './entrance-support-request-popup';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'environments/environment';

@Component({
  selector: 'app-entrance-card',
  templateUrl: './entrance-card.component.html',
  styleUrls: ['./entrance-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntranceCardComponent {
  public readonly environment = environment;
  @Input() entrance: EntrancePageResponse;
  @Input() loading: boolean;

  constructor(
    private dialog: MatDialog,
    private resolution: ResolutionService,
    private translate: TranslateService
  ) { }

  onCreateSupportRequest() {
    const data: DialogWrapperData<EntrancePageResponse, null> = {
      title: this.translate.instant('entrance.page.card.support.title'),
      body: this.entrance,
      componentName: 'SoftwareIntercomSupportRequest'
    };

    this.dialog.open(EntranceSupportRequestPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ? '100%' : '500px',
      data
    });
  }
}
