import { ActionReducerMap } from '@ngrx/store';
import * as getAllBatches from './batches/get-all-batches.reducer';
import * as getBatchAdapters from './batches/get-batch-adapters.reducer';
import * as getBatchesPage from './batches/get-batches-page.reducer';
import * as putNewBatch from './batches/put-new-batch.reducer';
import * as companies from './companies.reducer';
import * as addRdasToOrder from './orders/add-rdas-to-order.reducer';
import * as addScansToOrder from './orders/add-scans-to-order.reducer';
import * as createOrder from './orders/create-order.reducer';
import * as deleteOrder from './orders/delete-order.reducer';
import * as getOrdersPage from './orders/get-orders-page.reducer';
import * as updateOrder from './orders/update-order.reducer';
import * as createRdaModel from './rdva-models/create-rda-model.reducer';
import * as getRdaModels from './rdva-models/get-rda-models.reducer';
import * as createShieldModel from './shield-models/create-shield-model.reducer';
import * as getShieldModels from './shield-models/get-shield-models.reducer';
import * as getAllShields from './shields/get-all-shields.reducer';
import * as getShieldTests from './shields/get-shield-tests.reducer';
import * as getShieldsByBatchNumber from './shields/get-shields-by-batch-number.reducer';
import * as updateShield from './shields/update-shield.reducer';
import * as updateCamera from './update-camera.reducer';

export interface IntercomModuleState {
  getBatchesPage: getBatchesPage.State;
  getAllBatches: getAllBatches.State;
  putNewBatch: putNewBatch.State;
  companies: companies.State;
  getAllShields: getAllShields.State;
  getShieldsByBatchNumber: getShieldsByBatchNumber.State;
  updateShield: updateShield.State;
  getShieldTests: getShieldTests.State;
  getRdaModels: getRdaModels.State;
  createRdaModel: createRdaModel.State;
  getShieldModels: getShieldModels.State;
  createShieldModel: createShieldModel.State;
  updateCamera: updateCamera.State;
  addRdasToOrder: addRdasToOrder.State;
  addScansToOrder: addScansToOrder.State;
  createOrder: createOrder.State;
  deleteOrder: deleteOrder.State;
  updateOrder: updateOrder.State;
  getOrdersPage: getOrdersPage.State;
  getBatchAdapters: getBatchAdapters.State;
}

export const reducers: ActionReducerMap<IntercomModuleState> = {
  getBatchesPage: getBatchesPage.reducer,
  getAllBatches: getAllBatches.reducer,
  putNewBatch: putNewBatch.reducer,
  companies: companies.reducer,
  getAllShields: getAllShields.reducer,
  getShieldsByBatchNumber: getShieldsByBatchNumber.reducer,
  updateShield: updateShield.reducer,
  getShieldTests: getShieldTests.reducer,
  getRdaModels: getRdaModels.reducer,
  createRdaModel: createRdaModel.reducer,
  getShieldModels: getShieldModels.reducer,
  createShieldModel: createShieldModel.reducer,
  updateCamera: updateCamera.reducer,
  addRdasToOrder: addRdasToOrder.reducer,
  addScansToOrder: addScansToOrder.reducer,
  createOrder: createOrder.reducer,
  deleteOrder: deleteOrder.reducer,
  updateOrder: updateOrder.reducer,
  getOrdersPage: getOrdersPage.reducer,
  getBatchAdapters: getBatchAdapters.reducer
};
