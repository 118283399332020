<app-select-search
  [title]="title"
  [required]="true"
  [(ngModel)]="entranceId"
  [valuesList]="addresses$ | async | map:['select']"
  [selectedValue]="selectSearch$ | async"
  [nonePadding]="withoutDescription"
  [loading]="loading$ | async"
  (searchChanged)="onGetAddresses($event.value)"
  (valueSelected)="onSelectAddress($event.value)"
></app-select-search>