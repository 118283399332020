import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

const deleteOrderState = createSelector(
    intercomModuleSelector,
    (state: IntercomModuleState) => state.deleteOrder
);

export const deleteOrder = createSelector(
    deleteOrderState,
    state => state
);
export const deleteOrderLoading = createSelector(
    deleteOrderState,
    state => state.loading
);
export const deleteOrderSuccess = createSelector(
    deleteOrderState,
    state => state.success
);

export const deleteOrderFailed = createSelector(
    deleteOrderState,
    state => state.error
);
