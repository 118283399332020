import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { Observable } from 'rxjs';

import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { ServicesTypes } from '@app/shared/models';

@Component({
  selector: 'app-flat-card',
  templateUrl: './flat-card.component.html',
  styleUrls: ['./flat-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatCardComponent {
  @ViewChild('phoneBlock', { static: true }) phoneBlock: ElementRef;
  @ContentChild(TemplateRef) serviceStateTemplate: TemplateRef<any>;
  @Input() serviceType: ServicesTypes = ServicesTypes.SOFTWARE_INTERCOM;
  @Input() checked: boolean = false;

  get highlighted() {
    return this.checked ? 'highlighted' : '';
  }

  xlWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(
    ResolutionBreakpoint.XL_W_DOWN
  );
  mdWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(
    ResolutionBreakpoint.MD_W_DOWN
  );
  public readonly ServicesTypes = ServicesTypes;

  constructor(private resolution: ResolutionService) {}
}
