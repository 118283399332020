<div class="reset-password">
  <div class="reset-password-info">
    <div class="reset-password-info__icon">
      <img src="assets/icons/ic-lock.svg" alt="error">
    </div>
    <h3>
      {{ 'reset.password.content.title' | translate }}
    </h3>
  </div>
  <form (ngSubmit)="onResetPassword()" class="reset-password-form">
    <p>
      {{ 'reset.password.content.text' | translate }}
    </p>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'reset.password.content.field.email' | translate }}
      </mat-label>
      <input
        matInput
        type="email"
        placeholder="{{ 'reset.password.content.placeholder.email' | translate }}"
        [formControl]="email"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        required
      />
    </mat-form-field>
    <div>
      <button
        mat-raised-button
        type="submit"
        color="primary"
        [disabled]="email.invalid"
      >
        {{ 'reset.password.content.button.submit' | translate }}
      </button>
    </div>
  </form>
</div>
