import { Account } from '@app/shared/entities/rd';
import { ServicesTypes } from '@app/shared/models';

export interface ServiceConnectionServiceResponse {
    accounts: Account[];
    customName: string;
    id: number;
    name: string;
    type: ServicesTypes;
}
