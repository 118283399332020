<app-dialog-wrapper
  [componentName]="data.componentName"
  [title]="data.title"
  [progress]="(popupState$ | async) === 'loading'"
>
  <app-info-block>
    <img
      class="service-abonents-disconnect-popup-content-info__image"
      info-block-image
      [src]="resourcesHelper.getResource(resourcePath.INFO_LG) | safeUrl"
      [srcset]="resourcesHelper.getResource(resourcePath.INFO_LG, true) | safeUrl"
      alt="Info"
    >

    <ng-container info-block-text>
      {{ 'services.popups.connections.abonents.disconnect.info.0' | translate }}
      <br>
      <br>
      <strong>
        {{ 'services.popups.connections.abonents.disconnect.info.1' | translate }}
      </strong>
    </ng-container>
  </app-info-block>
  <ng-container *ngFor="let flat of selectedFlats">
    <h5>
      {{ 'services.popups.connections.abonents.disconnect.flat_number' | translate }}&nbsp;{{flat.flatNumber}}</h5>
    <app-service-abonents-disconnect-popup-content
      class="popup"
      [owner]="flat.flat?.owner"
      [sharedAbonents]="flat.sharedAbonents"
      [loading]="(popupState$ | async) === 'loading'"
      (disconnectSharedAbonent)="onDisconnectSharedAbonent(flat.flat?.address?.entrance.id, $event.delegationId)"
    ></app-service-abonents-disconnect-popup-content>
  </ng-container>
</app-dialog-wrapper>
