<div class="services-container">
  <hr>
    <span class="services-container__header">
      {{ 'abonent.accounts.services.title' | translate }}
    </span>
  <hr>

  <ng-container *ngIf="services?.length > 0; else emptyServices">
    <ng-container *ngFor="let service of services; let last = last">
      <div class="service-block">
        <p [ngClass]="{'service-block__active': service.isBlocked === false, 'service-block__blocked': service.isBlocked === true}"></p>
        <p class="name-block">
          <ng-container *ngIf="shouldBeLink(service.type); else notLink">
            <a (appMiddleClick)="onRoute(service, $event)" (click)="onRoute(service, $event)">
              <ng-container *ngTemplateOutlet="serviceTitle; context: { $implicit: service}"></ng-container>
            </a>
          </ng-container>
          
          <ng-template #notLink>
            <span>
              <ng-container *ngTemplateOutlet="serviceTitle; context: { $implicit: service}"></ng-container>
            </span>
          </ng-template>
          
          <span class="tariff" *ngIf="service?.tariff || service?.tariff === 0">
            {{ 'abonent.accounts.services.field.tariff' | translate }}:
            {{ service?.tariff }} {{ 'abonent.accounts.services.field.currency' | translate }}
          </span>
        </p>

        <div class="actions-block" [class.chat]="service.type === HOUSE_CHAT_SERVICE">
          <button mat-icon-button
            (click)="onBlock(service)"
            *ngIf="service.type !== HOUSE_CHAT_SERVICE; else emptyButton"
            color="{{ service.isBlocked ? 'primary' : 'warn' }}"
            matTooltip="{{
              service.isBlocked
              ? ('abonent.accounts.services.tooltip.unblock' | translate)
              : ('abonent.accounts.services.tooltip.block' | translate)
            }}"
          >
            <mat-icon aria-label="Block">{{ service.isBlocked ? 'autorenew' : 'blocked' }}</mat-icon>
          </button>
          <ng-template #emptyButton>
            <button mat-icon-button disabled>
            </button>
          </ng-template>

          <button
            mat-icon-button
            color="error"
            matTooltip="{{ 'abonent.accounts.services.tooltip.delete' | translate }}"
            (click)="onDisconnect(service)"
          >
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button>
        </div>
      </div>
      <hr *ngIf="!last">
    </ng-container>

  </ng-container>
  <ng-template #emptyServices>
    <ng-container *ngIf="services.length === 0">
      <app-empty-data></app-empty-data>
    </ng-container>
  </ng-template>
</div>

<ng-template #serviceTitle let-service>
  <ng-container *ngIf="!service.flat?.translated; else translated">
    {{ service.customName || service.name }}
    {{
      service.flat?.address.flat
        ? ', ' + ('abonent.accounts.services.field.flat' | translate) + ' ' + service.flat.address.flat
        : ''
    }}
  </ng-container>
  <ng-template #translated>
    {{ service.customName || service.name }}
    {{
      service.flat?.translated
        ? ', ' + ('abonent.accounts.services.field.flat' | translate) + ' ' + service.flat.translated
        : ''
    }}
  </ng-template>
</ng-template>
