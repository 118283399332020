import { RdeaDate } from '@app/shared/entities/common';
import { VideoPlayerFragmentShortDate } from '../../../models';
import { PlyrTemplateHelper } from '../../plyr-globals';
import { PlyrControlBasicHelper } from '../base';
import { PlyrEventsControlsEventType } from './plyr-events-controls-event-type.enum';
import {TranslateService} from '@ngx-translate/core';

/**
 * Class for event controls button
 */
export class PlyrEventControlsHelper extends PlyrControlBasicHelper<PlyrEventsControlsEventType> {
    constructor(
      private translate: TranslateService
    ) {
        super();
        this.enableEventsMenuButtonClickListener();
        this.enableEventsItemsClickListener();
    }

    /**
     * Set events next and previous buttons and events menu buttons using timestamps
     * @param eventsTimestamps events timestamps from Hls fragments
     */
    prepareEvents(eventsTimestamps: VideoPlayerFragmentShortDate[]) {
        !eventsTimestamps?.length ? this.removeEventButtons() : this.addEventButtons();
        this.prepareEventsItemsList(eventsTimestamps);
    }

    /**
     * Enable listeners for click on events menu button and event button
     */
    private enableEventsMenuButtonClickListener() {
        const eventsMenuButton: HTMLButtonElement =
            document.getElementById(PlyrTemplateHelper.EVENTS_MENU_BUTTON_ID) as HTMLButtonElement;

        eventsMenuButton.addEventListener('click', (e: MouseEvent) => {
            const expanded: boolean = eventsMenuButton.getAttribute('aria-expanded') === 'true';
            this.clickListener$.next({ type: PlyrEventsControlsEventType.CLICK_MENU, e: expanded });
        });
    }

    /**
     * Enable listeners for click on events menu button and event button
     */
    private enableEventsItemsClickListener() {
        const eventsSelectorList: HTMLButtonElement =
            document.getElementById(PlyrTemplateHelper.EVENTS_SELECTOR_LIST_ID) as HTMLButtonElement;

        eventsSelectorList.addEventListener('click', (e: MouseEvent) => {
            this.clickListener$.next({
                type: PlyrEventsControlsEventType.CLICK_ITEM,
                e: Number((e.target as HTMLButtonElement).getAttribute('attr-date'))
            });
        });
    }

    /**
     * Prepare events items list using timestamps from Hls fragments
     * @param eventsTimestamps timestamps from Hls fragments
     */
    private prepareEventsItemsList(eventsTimestamps: VideoPlayerFragmentShortDate[]) {
        const eventsSelectorList: HTMLDivElement =
            document.getElementById(PlyrTemplateHelper.EVENTS_SELECTOR_LIST_ID) as HTMLDivElement;

        if (!eventsSelectorList) {
            return;
        }

        if (!eventsTimestamps?.length) {
            eventsSelectorList.innerHTML = PlyrTemplateHelper.getEmptyEventsContent(this.translate);
            return;
        }

        eventsSelectorList.innerHTML = '';

        eventsTimestamps.forEach((eventTimestamps: VideoPlayerFragmentShortDate) =>
            eventsSelectorList.innerHTML += PlyrTemplateHelper.getEventMenuButton(
                new RdeaDate(eventTimestamps.startTimestamp)
            )
        );
    }

    /**
     * Add next and previous events buttons if it's not created
     */
    private addEventButtons() {
        let eventNextButton: HTMLElement = document.getElementById(PlyrTemplateHelper.EVENTS_ACTION_NEXT_ID);
        let eventPreviousButton: HTMLElement = document.getElementById(PlyrTemplateHelper.EVENTS_ACTION_PREVIOUS_ID);

        if (eventNextButton && eventPreviousButton) {
            return;
        }

        const captionsWrapper: HTMLDivElement =
            document.getElementsByClassName(PlyrTemplateHelper.CAPTIONS_WRAPPER_CLASS)[0] as HTMLDivElement;

        eventPreviousButton = document.createElement('button');
        eventPreviousButton.id = PlyrTemplateHelper.EVENTS_ACTION_NEXT_ID;
        eventPreviousButton.classList.add(PlyrTemplateHelper.EVENTS_BUTTON_CLASS);
        eventPreviousButton.classList.add(PlyrTemplateHelper.EVENTS_BUTTON_CLASS_LEFT);
        eventPreviousButton.innerHTML = PlyrTemplateHelper.getPreviousEventButtonContent(this.translate);
        captionsWrapper.appendChild(eventPreviousButton);

        eventNextButton = document.createElement('button');
        eventNextButton.id = PlyrTemplateHelper.EVENTS_ACTION_PREVIOUS_ID;
        eventNextButton.classList.add(PlyrTemplateHelper.EVENTS_BUTTON_CLASS);
        eventNextButton.classList.add(PlyrTemplateHelper.EVENTS_BUTTON_CLASS_RIGHT);
        eventNextButton.innerHTML = PlyrTemplateHelper.getNextEventButtonContent(this.translate);
        captionsWrapper.appendChild(eventNextButton);

        eventNextButton.addEventListener('click', (e: MouseEvent) =>
            this.clickListener$.next({ type: PlyrEventsControlsEventType.CLICK_NEXT, e })
        );

        eventPreviousButton.addEventListener('click', (e: MouseEvent) =>
            this.clickListener$.next({ type: PlyrEventsControlsEventType.CLICK_PREVIOUS, e })
        );
    }

    /**
     * Remove next and previous events buttons if it's created
     */
    private removeEventButtons() {
        const eventsActionNext: HTMLButtonElement =
            document.getElementById(PlyrTemplateHelper.EVENTS_ACTION_NEXT_ID) as HTMLButtonElement;

        if (eventsActionNext) {
            eventsActionNext.remove();
        }

        const eventsActionPrevious: HTMLButtonElement =
            document.getElementById(PlyrTemplateHelper.EVENTS_ACTION_PREVIOUS_ID) as HTMLButtonElement;

        if (eventsActionPrevious) {
            eventsActionPrevious.remove();
        }
    }
}
