import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export const orderCloseFormGroup = () => {
    const controls = {
        warrantyStartDate: new UntypedFormControl(null, Validators.required)
    };
    return {
        form: new UntypedFormGroup(controls),
        controls
    };
};
