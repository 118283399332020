<app-dialog-wrapper
  [componentName]="data.componentName"
  [title]="data.title"
  [progress]="(popupState$ | async) === 'loading'"
>
  <div class="popup">
    <app-software-intercom-tariff-popup-content
      class="abonent-connection-tool-content-services"
      [defaultValue]="defaultValue"
      [defaultHardwareValue]="defaultHardwareValue"
      [showCheckbox]="this.data.body.showCheckboxForAllAbonents !== undefined ? this.data.body.showCheckboxForAllAbonents : true"
      [serviceType]="data.body.serviceType"
      (contentSaved)="tariffSaved($event)"
    >
    </app-software-intercom-tariff-popup-content>
  </div>
</app-dialog-wrapper>
