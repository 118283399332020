<ng-container *ngIf="{
  integrationCheck: integrationCheck$ | async,
  configuration: configuration$ | async,
  errorHandled: errorHandled$ | async
} as state">
  <ng-container *ngIf="state.integrationCheck === false">
    <ng-container *ngIf="state.configuration !== null">
      <app-navbar
        [links]="navbarLinks"
        [active]="activeLink"
        [neutral]="false"
        (select)="onChangeTab($event.idx)"
      ></app-navbar>

      <ng-container [ngSwitch]="activeLink">
        <ng-container *ngSwitchCase="pageType.PAYMENTS">
          <app-payments-list></app-payments-list>
        </ng-container>

        <ng-container *ngSwitchCase="pageType.SETTINGS">
          <app-payments-settings
            [configuration]="state.configuration"
          ></app-payments-settings>
        </ng-container>

        <ng-container *ngSwitchCase="pageType.UPLOAD">
          <app-payments-upload></app-payments-upload>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="state.configuration === null">
      <app-payments-connection
        *ngIf="!state.errorHandled"
      ></app-payments-connection>

      <app-payments-error
        *ngIf="state.errorHandled"
      ></app-payments-error>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="state.integrationCheck === true">
    <app-component-loader [sizePx]="60"></app-component-loader>
  </ng-container>
</ng-container>
