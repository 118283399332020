import { Injectable } from '@angular/core';
import { CompanyApiService } from '@app/shared/entities/rd/company/services';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ActionTypes, GetAllCompanies, GetAllCompaniesClear, GetAllCompaniesFailure, GetAllCompaniesSuccess } from '../actions/companies.actions';

@Injectable()
export class CompaniesEffects {
    constructor(
        private actions$: Actions,
        private companyApiService: CompanyApiService
    ) { }

    @Effect()
    GetAllCompanies$ = this.actions$.pipe(
        ofType<GetAllCompanies>(ActionTypes.GetAllCompanies),
        switchMap(() =>
            this.companyApiService.getAllCompanies().pipe(
                map(data => new GetAllCompaniesSuccess(data)),
                catchError(error => of(new GetAllCompaniesFailure(error)))
            )
        )
    );

    @Effect({ dispatch: false })
    GetAllCompaniesClear$ = this.actions$.pipe(
        ofType<GetAllCompaniesClear>(ActionTypes.GetAllCompaniesClear),
        tap(() => new GetAllCompaniesClear())
    );
}
