import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

export const companyFormGroup = () => {
    const company = {
        companyId: new UntypedFormControl(null),
        filter: new UntypedFormControl()
    };
    return {
        form: new UntypedFormGroup(company),
        company: company
    };
};
