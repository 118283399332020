<div class="service-rda-logs">
  <div class="service-rda-logs-options">
    <mat-form-field appearance="outline" class="none-padding none-margin">
      <mat-label>
        {{ 'service.logs.field.interval' | translate }}
      </mat-label>
      <mat-select
        (selectionChange)="onTimeRangeChanged()"
        [(ngModel)]="selectedDateDiff"
      >
        <mat-option
          [value]="dateDiff.value"
          *ngFor="let dateDiff of dateDiffs"
        >{{ dateDiff.name }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="service-rda-logs-options-actions">
      <button
        mat-raised-button
        color="primary-inverse"
        (click)="isUpdate ? onStopUpdate() : onStartUpdate()"
      >
        {{ 'service.logs.button.auto_update' | translate }} {{ isUpdate ? '(' + (10 - currentSeconds % 10) + ')' : '' }}
      </button>

      <button
        mat-raised-button
        (click)="onLoad()"
        [disabled]="isUpdate"
        color="primary-inverse"
      >
        {{ 'service.logs.button.load' | translate }}
      </button>
    </div>
  </div>

  <table class="service-rda-logs-table" *ngIf="logs?.length > 0">
    <tbody class="service-rda-logs-table-body">
      <tr *ngFor="let log of logs; let last = last" class="service-rda-logs-table-body-message">
        <p class="service-rda-logs-table-body-message__time">
          {{ log.createdAt | date: 'dd.MM.yyyy HH:mm:ss' }}
        </p>

        <p class="service-rda-logs-table-body-message__text">
          {{ log.message }}
        </p>

        <hr class="service-rda-logs-table-body-message__hr" *ngIf="!last">
      </tr>
    </tbody>
  </table>

  <ngx-skeleton-loader
    *ngIf="loading"
    count="5"
    [theme]="{
      'border-radius': '8px',
      'margin-top': '0px',
      'height': '93px'
    }"
  ></ngx-skeleton-loader>

  <app-info-block *ngIf="!loading && logs?.length === 0">
    <ng-container info-block-text>
      {{ 'service.logs.message.empty' | translate }}
    </ng-container>
  </app-info-block>
</div>
