import { ReducerName } from '@app/store';
import { DashboardActions, DashboardActionTypes } from '@app/views/dashboard/store/dashboard.actions';
import { dashboardInitialState, DashboardState } from '@app/views/dashboard/store/dashboard.state';
import { ActionReducerMap } from '@ngrx/store';

function reducer(
    state = dashboardInitialState,
    action: DashboardActions
): DashboardState {
    switch (action.type) {
        case DashboardActionTypes.DashboardGetRdasCounts: {
            return {
                ...state,
                loading: true
            };
        }

        case DashboardActionTypes.DashboardGetRdasCountsSuccess: {
            return {
                ...state,
                rdasCounts: action.response,
                loading: false,
            };
        }

        case DashboardActionTypes.DashboardGetRdasCountsFailure: {
            return {
                ...state,
                loading: false,
            };
        }

        case DashboardActionTypes.DashboardGetCamerasCounts: {
            return {
                ...state,
                loading: true
            };
        }

        case DashboardActionTypes.DashboardGetCamerasCountsSuccess: {
            return {
                ...state,
                camerasCounts: action.response,
                loading: false,
            };
        }

        case DashboardActionTypes.DashboardGetCamerasCountsFailure: {
            return {
                ...state,
                loading: false,
            };
        }

        case DashboardActionTypes.DashboardGetSoftwareIntercomsCounts: {
            return {
                ...state,
                loading: true
            };
        }

        case DashboardActionTypes.DashboardGetSoftwareIntercomsCountsSuccess: {
            return {
                ...state,
                softwareIntercomsCounts: action.response,
                loading: false,
            };
        }

        case DashboardActionTypes.DashboardGetSoftwareIntercomsCountsFailure: {
            return {
                ...state,
                loading: false,
            };
        }

        case DashboardActionTypes.DashboardGetVideoSurveillanceCounts: {
            return {
                ...state,
                loading: true
            };
        }

        case DashboardActionTypes.DashboardGetVideoSurveillanceCountsSuccess: {
            return {
                ...state,
                videoSurveillanceCounts: action.response,
                loading: false,
            };
        }

        case DashboardActionTypes.DashboardGetVideoSurveillanceCountsFailure: {
            return {
                ...state,
                loading: false,
            };
        }

        case DashboardActionTypes.DashboardGetGatesCounts: {
            return {
                ...state,
                loading: true
            };
        }

        case DashboardActionTypes.DashboardGetGatesCountsSuccess: {
            return {
                ...state,
                gatesCounts: action.response,
                loading: false,
            };
        }

        case DashboardActionTypes.DashboardGetGatesCountsFailure: {
            return {
                ...state,
                loading: false,
            };
        }

        case DashboardActionTypes.DashboardGetRdosImages: {
            return {
                ...state,
                loading: true
            };
        }

        case DashboardActionTypes.DashboardGetRdosImagesSuccess: {
            return {
                ...state,
                rdosImages: action.response,
                loading: false,
            };
        }

        case DashboardActionTypes.DashboardGetRdosImagesFailure: {
            return {
                ...state,
                loading: false,
            };
        }

        case DashboardActionTypes.DashboardGetBlockedUserInvoiceReport: {
            return {
                ...state,
                loading: true
            };
        }

        case DashboardActionTypes.DashboardGetBlockedUserInvoiceReportSuccess: {
            return {
                ...state,
                loading: false,
            };
        }

        case DashboardActionTypes.DashboardGetBlockedUserInvoiceReportFailure: {
            return {
                ...state,
                loading: false,
            };
        }

        case DashboardActionTypes.ClearDashboardStore:
            return dashboardInitialState;

        default: {
            return state;
        }
    }
}

export const dashboardReducers: ActionReducerMap<{ [ReducerName.DASHBOARD]: DashboardState }> = {
    [ReducerName.DASHBOARD]: reducer
};
