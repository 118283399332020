import { Component, Inject, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DialogWrapperData, DialogWrapperSize } from '@app/shared/ui';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmComponentConnectionHelper, RdaSelectSearchResponse, SnackbarService } from '@app/shared/components';
import {
  AwxJobLogResponse,
  AwxJobsApiService,
  AwxJobStatus,
  Camera,
  CameraApiService,
  CreateAwxUpdateJobResponse,
  RdaApiService,
  RdaResponse
} from '@app/shared/entities/rd';
import { FindCamerasPopupService } from '@app/views/services/submodules/mutual/find-cameras-popup/find-cameras-popup.service';
import { Address, LocationResponse, PagedResponse, SelectSearch, ServicesTypes } from '@app/shared/models';
import { IpRdaConnectionTypePipe } from '@app/shared/pipes';
import { CameraLease } from '@app/shared/entities/rd/camera/services/camera-lease.model';
import { ServiceCameraPopupBody, ServiceCameraPopupComponent } from '@app/views/services/components';
import { Constants } from '@app/shared/helpers';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { ServiceFacade, ServicesActionTypes } from '@app/views/services';
import { DHCPEnabledString } from '@app/views/services/submodules/mutual/find-cameras-popup/constants';
import { Actions, ofType } from '@ngrx/effects';
import {TranslateService} from '@ngx-translate/core';

const LOADING_SPINNER_DIAMETER = 38;

@Component({
  selector: 'app-find-cameras-popup',
  templateUrl: './find-cameras-popup.component.html',
  styleUrls: ['./find-cameras-popup.component.scss']
})
export class FindCamerasPopupComponent implements OnInit {
  private dhcpJob: CreateAwxUpdateJobResponse;
  private rdaJobList: CreateAwxUpdateJobResponse[];
  private logTimeout: NodeJS.Timeout;
  private serviceType: ServicesTypes;
  private autoTuneCameraRequest: Camera;
  private selectedLocationId: number;
  private selectedLease: CameraLease;

  public selectedRda?: RdaResponse;
  public cameraListVisible = false;
  public isLoading = false;
  public readyCameraFormState = false;
  public selectedEntranceId: number;
  public latestLog: AwxJobLogResponse;
  public busyLeasesList: CameraLease[] = [];
  public freeLeasesList: CameraLease[] = [];
  public locations: LocationResponse[];
  public addresses: Address[];

  // Watchers
  public readonly camerasLocations$: Observable<LocationResponse[]> = this.serviceFacade.camerasLocations$;
  public readonly addresses$: Observable<Address[]> = this.serviceFacade.entrances$;
  public readonly popupState$: Observable<'loading' | 'close' | 'loaded'> = this.popupService.state$;

  // Constants
  public readonly loadingSpinnerDiameter = LOADING_SPINNER_DIAMETER;
  public readonly DialogWrapperSize = DialogWrapperSize;

  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: DialogWrapperData<{ rdas: RdaResponse[] }, {}>,
    protected readonly popupService: FindCamerasPopupService,
    protected readonly rdaApiService: RdaApiService,
    protected readonly actions$: Actions,
    protected readonly awxJobsApiService: AwxJobsApiService,
    protected readonly dialogRef: MatDialogRef<FindCamerasPopupComponent>,
    protected readonly ipRdaConnectionTypePipe: IpRdaConnectionTypePipe,
    protected readonly snackbar: SnackbarService,
    protected readonly cameraService: CameraApiService,
    protected readonly serviceFacade: ServiceFacade,
    protected readonly dialog: MatDialog,
    protected readonly confirmComponentConnectionHelper: ConfirmComponentConnectionHelper,
    protected readonly resolution: ResolutionService,
    protected readonly translate: TranslateService
  ) {
  }

  ngOnInit() {
    this.popupService.addStateChangeListener(state => state === 'close' && this.dialogRef.close());
    this.selectedRda = this.data.body.rdas && this.data.body.rdas.length > 0 ? this.data.body.rdas[0] : null;
    if (!this.data.body.rdas || this.data.body.rdas.length === 0) {
      this.isLoading = true;
      this.rdaApiService.getAdaptersList(0, 20, null, false, null, null).toPromise().then(rdasResponse => {
        this.data.body.rdas = rdasResponse.content;
        this.isLoading = false;
      });
    }
    this.serviceFacade.serviceType$.subscribe(type => {
      this.serviceType = type;
    });
    this.camerasLocations$.subscribe(locations => {
      this.locations = locations;
    });
    this.addresses$.subscribe(addresses => {
      this.addresses = addresses;
    });

    // Если камера была подключена успешно
    this.actions$
      .pipe(ofType(ServicesActionTypes.AddServiceCameraSuccess))
      .subscribe(() => {
        this.getCameras();
      });

    // Если камера не была подключена успешно
    this.actions$
      .pipe(ofType(ServicesActionTypes.AddServiceCameraFailure))
      .subscribe(() => {
        this.isLoading = false;
      });
  }

  public getToPrevious(): void {
    if (this.cameraListVisible) {
      this.readyCameraFormState = false;
      this.cameraListVisible = false;
    } else if (this.readyCameraFormState) {
      this.readyCameraFormState = false;
      this.cameraListVisible = true;
    }
  }

  public async rdaSelected(selectedRda: RdaSelectSearchResponse): Promise<void> {
    if (selectedRda?.select?.value && this.selectedRda?.uid !== selectedRda.select.value) {
      this.selectedRda = selectedRda.value;
    }
  }

  public async enableDHCPForSelectedAdapter(): Promise<void> {
    this.isLoading = true;
    this.rdaJobList = await this.awxJobsApiService.getRdaaJobList(this.selectedRda.uid).toPromise();
    const selectedRdaData: RdaResponse = await this.rdaApiService.getAdapter(this.selectedRda.uid).toPromise();
    const filteredJobs = this.rdaJobList.filter(job => job.name === DHCPEnabledString && job.finished === null);
    const pendingDhcpRdaJob: CreateAwxUpdateJobResponse = filteredJobs[filteredJobs.length - 1];
    /*
      selectedRdaData должен всегда существовать, так как ищем по uid уже существующего адаптера, которого мы выбираем (selectedRda).
      Из-за этого можно быть уверенным, что существует selectedRdaData.
      Проверка была поставлена в целях не получать ошибку на фронте, если что-то сломается с бэкенда и, например, не придёт selectedRdaData[0].

      dhcpEnabled может иметь только три состояния null, false и true, либо этого поля нет совсем (тогда dhcpEnabled === undefined).
      Если поля нет, значит ПО РДА не обновлён до нужной версии.
    */


    // if pendingDhcpRdaJob exist and finished == null -> show Logs
    // else if dhcpEnabled == false -> show enable. Start job enable dhcp
    // else if dhcpEnabled == true -> get cameras
    // else show error message

    if (pendingDhcpRdaJob && pendingDhcpRdaJob.finished === null) {
      this.dhcpJob = pendingDhcpRdaJob;
      this.setLogsListener();
    } else if (selectedRdaData && (selectedRdaData.dhcpEnabled === null || selectedRdaData.dhcpEnabled === false)) {
      this.dhcpJob = await this.awxJobsApiService.createRdaaEnableDHCPJob({rdaUid: this.selectedRda.uid}).toPromise();
      this.setLogsListener();
    } else if (selectedRdaData && selectedRdaData.dhcpEnabled === true) {
      this.getCameras();
    } else {
      this.snackbar.showMessage(
        this.translate.instant('services.gates.mutual.find.cameras.popup.message.need_update'),
        'error'
      );
      this.isLoading = false;
      this.dialogRef.close();
    }
  }

  private setLogsListener(): void {
    this.logTimeout = setTimeout(async () => {
        await this.awxJobsApiService.getAwxJobLogs(this.dhcpJob.id).toPromise().then(
          async (logs: PagedResponse<AwxJobLogResponse>) => {
            const log = logs.content.reverse().find(lastLog => !!lastLog.message);
            this.latestLog = {
              message: this.translate.instant('services.gates.mutual.find.cameras.popup.message.enable_dhcp') + `\n${
                  log ? unescape(encodeURIComponent(log.message))
                      .split('*')[0]
                      .match(/[A-Za-z \[\]]/gm)
                      .join('') : ''
                }`,
              date: Date.now()
            };
         await this.awxJobsApiService.getAwxJob(this.dhcpJob.id).toPromise().then((dhcpJob) => {
              const dhcpJobSuccess = dhcpJob.finished && dhcpJob.status === AwxJobStatus.SUCCESSFUL;
              const dhcpJobFailure = dhcpJob.finished && dhcpJob.status === AwxJobStatus.FAILED;

              if (dhcpJobSuccess || dhcpJobFailure) {
                this.removeLogsListener();
              } else {
                this.setLogsListener();
              }

              if (dhcpJobFailure) {
                this.snackbar.showMessage(
                  this.translate.instant('services.gates.mutual.find.cameras.popup.message.dhcp_job_failure', {
                    text: this.latestLog?.message
                  }),
                  'error'
                );
                this.isLoading = false;
              } else if (dhcpJobSuccess) {
                this.latestLog = {
                  message: this.translate.instant('services.gates.mutual.find.cameras.popup.message.dhcp_job_success'),
                  date: Date.now()
                };
                this.rdaApiService.getAdapter(this.selectedRda.uid).toPromise().then((rda) => {
                  if (rda.dhcpEnabled) {
                    this.latestLog = {
                      message: this.translate.instant('services.gates.mutual.find.cameras.popup.message.dhcp_enabled.success'),
                      date: Date.now()
                    };
                    this.getCameras();
                  } else {
                    this.snackbar.showMessage(
                      this.translate.instant('services.gates.mutual.find.cameras.popup.message.dhcp_enabled.failed'),
                      'error'
                    );
                  }
                });
              }
            });
          });
      }, 1000);
  }

  private getCameras() {
    this.latestLog = {
      message: this.translate.instant('services.gates.mutual.find.cameras.popup.message.get_cameras.connect'),
      date: Date.now()
    };
    this.isLoading = true;
    this.cameraService.getLeases(this.selectedRda.uid).toPromise().then(leaseList => {
      this.freeLeasesList = leaseList.filter(lease => !lease.id);
      this.busyLeasesList = leaseList.filter(lease => !!lease.id);
      this.isLoading = false;
      this.cameraListVisible = true;
      this.latestLog = {
        message: this.translate.instant('services.gates.mutual.find.cameras.popup.message.get_cameras.success'),
        date: Date.now()
      };
    }).catch((errorResponse) => {
      this.isLoading = false;
      this.latestLog = {
        message: errorResponse.error.error,
        date: Date.now()
      };
      this.snackbar.showMessage(
        this.translate.instant('services.gates.mutual.find.cameras.popup.message.get_cameras.failed', {
          log: this.latestLog?.message,
          text: errorResponse.status + (errorResponse.ok ? 'OK' : '')
        }),
        'error'
      );
    });
  }

  private removeLogsListener(): void {
    clearTimeout(this.logTimeout);
  }

  public async connectReadyCamera(lease: CameraLease): Promise<void> {
    this.readyCameraFormState = true;
    this.cameraListVisible = false;
    this.selectedLease = lease;
  }

  public tuneAndConnectCamera(lease: CameraLease): void {
    this.serviceFacade.getCamerasLocations();
    const data: DialogWrapperData<Partial<ServiceCameraPopupBody>, { camera: Camera }> = {
      title: this.translate.instant('services.gates.mutual.find.cameras.popup.tune_and_connect_camera.title'),
      componentName: `Add${this.serviceType}Camera`,
      body: {
        camera: {...lease, connectedToRda: true, rdaUid: this.selectedRda?.uid}
      },
      submit: (event: { camera: Camera }) => {
        this.confirmComponentConnectionHelper.showBottomSheet(() => {
          this.latestLog = {
            message: this.translate.instant('services.gates.mutual.find.cameras.popup.tune_and_connect_camera.message.submit'),
            date: Date.now()
          };
          this.isLoading = true;
          this.serviceFacade.addServiceCamera(event.camera);
        });

        cameraDialogRef.close();
      }
    };

    const cameraDialogRef = this.dialog.open(ServiceCameraPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.SM,
      data
    });
  }

  public onSelectAddress(selectedAddress): void {
    this.selectedEntranceId = selectedAddress;
  }

  public onSelectLocation(selectedLocation): void {
    this.selectedLocationId = selectedLocation;
  }

  private prepareCameraResponse(
    user: string,
    password: string,
    uri: string,
    locationId: number,
    entrance: number,
    isPrivate: boolean,
    rdaUid: string,
    onvifPort: number,
    macAddress: string,
    model: {id: number, name: string}
  ): Camera {
    let addressForUpdate: Address = null;
    const selectedAddress: Address = this.addresses.find(address => address.entrance.id === entrance);

    if (selectedAddress) {
      addressForUpdate = JSON.parse(JSON.stringify(selectedAddress));
      delete addressForUpdate.entrance.additionalFlatRanges;
    }

    const camera: Camera = new Camera();
    camera.address = addressForUpdate;
    camera.user = user;
    camera.password = password;
    camera.uri = uri;
    camera.rdaUid = rdaUid;
    camera.private = isPrivate;
    camera.onvifPort = onvifPort;
    camera.macAddress = macAddress;
    camera.model = model;
    camera.connectedToRda = true;

    if (locationId !== undefined) {
      camera.location = locationId ? {
        id: locationId,
        name: this.locations.find(location => location.id === locationId)?.name
      } : null;
    }

    return camera;
  }

  public submitCameraData(): void {
    this.isLoading = true;
    this.autoTuneCameraRequest = this.prepareCameraResponse(
      this.selectedLease.user,
      this.selectedLease.password,
      this.selectedLease.uri,
      this.selectedLocationId,
      this.selectedEntranceId,
      true,
      this.selectedRda.uid,
      this.selectedLease.onvifPort,
      this.selectedLease.macAddress,
      this.selectedLease.model
    );
    this.readyCameraFormState = false;
    this.serviceFacade.addServiceCamera(this.autoTuneCameraRequest);
  }

  get initialSelectedRda(): SelectSearch {
    return {
      value: this.selectedRda?.uid,
      text: this.selectedRda?.uid,
      badge: this.selectedRda?.mode ? this.ipRdaConnectionTypePipe.transform(this.selectedRda.mode) : null
    };
  }
}
