
<div class="popup component">
  <h3 class="component__title">
    {{ 'services.gates.page.popups.wizard.content.intercoms.selection.title' | translate }}
  </h3>

  <mat-form-field appearance="outline" class="relative-size">
    <mat-label>
      {{ 'services.gates.page.popups.wizard.content.intercoms.selection.message.select_intercom_type' | translate }}
    </mat-label>

    <mat-select
      required
      disableOptionCentering
      panelClass="mat-select-custom"
      [value]="intercomType?.id"
      [disabled]="true"
    >
      <mat-option>--</mat-option>
      <mat-option
        [value]="intercomType?.id"
        *ngFor="let intercomType of intercomTypes"
      >
        {{ intercomType?.name }}
      </mat-option>
    </mat-select>

    <mat-hint>
      {{ 'services.gates.page.popups.wizard.content.intercoms.selection.message.have_you_found_right_configuration' | translate }}
      <a [href]="UNSUPPORTED_INTERCOM_TYPE_LINK | safeUrl" target="_blank">
        {{ 'services.gates.page.popups.wizard.content.intercoms.selection.link.unsupported_intercom_type' | translate }}
      </a>
    </mat-hint>
  </mat-form-field>

  <div class="component-selection">
    <app-info-block>
      <img
        class="component-selection-info__image"
        info-block-image
        [src]="resourcesHelper.getResource(resourcePath.INFO_LG) | safeUrl"
        [srcset]="resourcesHelper.getResource(resourcePath.INFO_LG, true) | safeUrl"
      >

      <ng-container info-block-text>
        {{ 'services.gates.page.popups.wizard.content.intercoms.selection.message.do_you_plan_to_install_adapter_on_gate' | translate }}
        <br>
        {{ 'services.gates.page.popups.wizard.content.intercoms.selection.message.if_so_then_choose_it_if_not_miss_this_step' | translate }}
      </ng-container>

      <ng-container info-block-content>
        <div class="component-selection-info-content">
          <h4 class="component-selection-info-content__title">
            {{ 'services.gates.page.popups.wizard.content.intercoms.selection.message.how_your_service_will_work_depends_on_this' | translate }}:
          </h4>
          <p>
            {{ 'services.gates.page.popups.wizard.content.intercoms.selection.message.if_you_plan_install_adapter_on_gate' | translate }}
          </p>

          <p>
            {{ 'services.gates.page.popups.wizard.content.intercoms.selection.message.if_you_not_plan_to_install_your_adapter' | translate }}
          </p>
        </div>
      </ng-container>
    </app-info-block>

    <app-rda-search-select
      [disabled]="loading"
      [initialIntercoms]="[]"
      [initialIntercomSearchSelect]="null"
      [params]="{ ipType: false, inactive: true }"
      (rdaSelected)="onIntercomSelected($event.value)"
    ></app-rda-search-select>

    <div class="component-selection-actions">
      <button
        class="component-selection-actions__button mat-button-lg"
        mat-raised-button
        [disabled]="loading"
        color="primary"
        (click)="onSkipIntercomSelection()"
      >
        {{ 'services.gates.page.popups.wizard.content.intercoms.selection.button.skip' | translate }}
      </button>
    </div>
  </div>
</div>
