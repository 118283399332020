import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { ChangePasswordRequest } from '@app/shared/entities/rd/users';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import {
  IRoleResponse,
  IUserRequest,
  IUserResponse
} from '@app/views/company/models/company-page';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class UserService extends ApiService {
  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.authserverApiUrl, ['v1']);
  }

  changePassword(request: ChangePasswordRequest): Observable<void> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post(`/users/password`, 1, request, { headers });
  }

  changePasswordByReset(request: ChangePasswordRequest): Observable<void> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.patch(`/users/password`, 1, request, { headers });
  }

  resetPassword(email: string): Observable<void> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post(`/users/reset`, 1, email, { headers });
  }

  getRoleList(): Observable<IRoleResponse[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.get(`/roles/available`, 1, { headers });
  }

  getCompanyUsers(): Observable<IUserResponse[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.get(`/users/company-users`, 1, { headers });
  }

  blockUser(userId: number): Observable<IUserResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.delete(`/users/${userId}`, 1, { headers });
  }

  unlockUser(userId: number): Observable<IUserResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.put(`/users/${userId}/unBlock`, 1, null, { headers });
  }

  createUser(user: IUserRequest): Observable<IUserResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post(`/users`, 1, { ...user }, { headers });
  }

  addRoles(userId: number, rolesIds: number[]): Observable<void> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post(`/roles`, 1, { userId, rolesIds }, { headers });
  }

  deleteRoles(userId: number, rolesIds: number[]): Observable<void> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.delete(`/roles`, 1, { userId, rolesIds }, { headers });
  }
}
