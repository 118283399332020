import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogWrapperData } from '@app/shared/ui';
import { ServiceDeleteIntercomPopupService } from './service-delete-intercom-popup.service';

@Component({
  selector: 'app-service-delete-intercom-popup',
  templateUrl: './service-delete-intercom-popup.component.html',
  styleUrls: ['./service-delete-intercom-popup.component.scss'],
  providers: [ServiceDeleteIntercomPopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceDeleteIntercomPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<{ rdaUid: string }, null>,
    public popupService: ServiceDeleteIntercomPopupService,
    private dialogRef: MatDialogRef<ServiceDeleteIntercomPopupComponent>,
  ) { }

  ngOnInit() {
    this.popupService.addStateChangeListener(state => state === 'close' && this.dialogRef.close());
  }

  onDelete() {
    this.data.submit(null);
  }
}
