import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogWrapperData } from '@app/shared/ui';
import { Camera } from '@app/shared/entities/rd';
import {
  CameraAlreadyExistsPopupService
} from 'src/app/shared/components/camera/camera-already-exists/service-camera-already-exists-popup/camera-already-exists-popup.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-service-camera-already-exists-popup',
  templateUrl: './service-camera-already-exists-popup.component.html',
  styleUrls: ['./service-camera-already-exists-popup.component.scss'],
  providers: [CameraAlreadyExistsPopupService]
})
export class ServiceCameraAlreadyExistsPopupComponent implements OnInit {
  public connectionResponse$: Subject<'Failure' | 'Success' | Camera> = new Subject<'Failure' | 'Success' | Camera>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public wrapperData: DialogWrapperData<Camera, Camera>,
    protected dialogRef: MatDialogRef<ServiceCameraAlreadyExistsPopupComponent>,
    private cameraAlreadyExistsPopupService: CameraAlreadyExistsPopupService
  ) {}

  ngOnInit(): void {
    this.cameraAlreadyExistsPopupService.addStateChangeListener((response) =>
      this.connectionResponse$.next(response)
    );
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
