import { Component, Inject } from '@angular/core';
import { PopupBasicComponent } from '@app/shared/templates';
import { RdaResponse } from '@app/shared/entities/rd/rda';
import { Subject } from 'rxjs';

@Component({
  selector: 'rdea-delete-address-popup',
  templateUrl: './delete-address-popup.component.html',
  styleUrls: ['./delete-address-popup.component.scss']
})
export class DeleteAddressPopupComponent extends PopupBasicComponent {
  constructor(
    @Inject('data') public data: { adapter: RdaResponse },
    @Inject('subject') private subject: Subject<any>
  ) {
    super();
  }

  onDelete() {
    this.subject.next(true);
  }
}
