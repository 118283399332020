<div class="popup component">
  <h3 class="component__title">
    {{ 'services.gates.page.popups.wizard.content.intercom.type.title' | translate }}
  </h3>

  <div class="component-body">
    <app-info-block *ngIf="disabled">
      <ng-container info-block-text>
        {{ 'services.gates.page.popups.wizard.content.intercom.type.message.disconnect_adapters_change_type_intercom' | translate }}
      </ng-container>
    </app-info-block>

    <mat-form-field appearance="outline" class="relative-size">
      <mat-label>
        {{ 'services.gates.page.popups.wizard.content.intercom.type.message.select_intercom_type' | translate }}
      </mat-label>

      <mat-select
        required
        disableOptionCentering
        panelClass="mat-select-custom"
        [value]="intercomType?.id"
        [disabled]="disabled"
        (selectionChange)="onSelectIntercomType($event.value)"
      >
        <mat-option>--</mat-option>
        <mat-option
          [value]="intercomType?.id"
          *ngFor="let intercomType of intercomTypes"
        >
          {{ intercomType?.name }}
        </mat-option>
      </mat-select>

      <mat-hint>
        {{ 'services.gates.page.popups.wizard.content.intercom.type.message.have_you_found_right_configuration' | translate }}
        <a [href]="UNSUPPORTED_INTERCOM_TYPE_LINK | safeUrl" target="_blank">
          {{ 'services.gates.page.popups.wizard.content.intercom.type.link.unsupported_intercom_type' | translate }}
        </a>
      </mat-hint>
    </mat-form-field>
  </div>
</div>
