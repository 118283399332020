import { Injectable } from '@angular/core';
import { Camera } from '@app/shared/entities/rd';
import { Address } from '@app/shared/models';
import { ServicePageListeners } from '@app/views/services';
import { ServiceFacade } from '@app/views/services/store';
import { combineLatest } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';

@Injectable()
export class VideoSurveillancePageListeners extends ServicePageListeners {
    constructor(serviceFacade: ServiceFacade) {
        super(serviceFacade);
    }

    addServiceEmptyListener(cb: () => void) {
        combineLatest([
            this.serviceFacade.serviceId$,
            this.serviceFacade.cameras$,
            this.serviceFacade.entrances$,
        ])
            .pipe(
                takeUntil(this.onDestroy$),
                map(([serviceId, cameras, addresses]: [number, Camera[], Address[]]) =>
                    serviceId &&
                    cameras?.length === 0 &&
                    addresses?.length === 0
                ),
                filter((value: boolean) => value)
            )
            .subscribe(() => {
                cb();
                this.onDestroy$.next();
                this.onDestroy$.complete();
            });
    }
}
