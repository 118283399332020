<ng-container *ngIf="generateUrlsMode; else generateIpIntercomMode">
  <ng-container
    *ngIf="
      protocolType === ProtocolTypes.Sputnik;
      then sputnik;
      else intercom
    "></ng-container>
  <ng-template #intercom>
    <app-ip-intercom-generate-urls-container
      [intercom]="intercomType"
      [loading]="loading"
      (urlsGenerated)="
        onGenerated($event)
      "></app-ip-intercom-generate-urls-container>
  </ng-template>

  <ng-template #sputnik>
    <app-ip-sputnik-generate-urls-container
      [intercom]="intercomType"
      [isUIDGenerateMode]="true"
      (urlsGenerated)="onGenerated($event)"
    ></app-ip-sputnik-generate-urls-container>
  </ng-template>

  <button
    type="button"
    mat-raised-button
    [disabled]="loading"
    color="primary-inverse"
    class="back-button"
    (click)="onChangeGenerateUrlsMode()"
  >
    {{ 'shared.rda.intercom.generate.content.button.back' | translate }}
  </button>
</ng-container>

<ng-template #generateIpIntercomMode>
  <form
    class="popup"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    autocomplete="off">
    <mat-form-field
      appearance="outline"
      class="relative-size"
    >
      <mat-label>
        {{ 'shared.rda.intercom.generate.content.field.ip_rda_connection_types' | translate }}
      </mat-label>
      <mat-select formControlName="mode">
        <mat-option
          *ngFor="let type of ipRdaConnectionTypes"
          [value]="type">
          {{ type | ipRdaConnectionType }}
        </mat-option>
      </mat-select>
      <mat-hint [innerHTML]="'shared.rda.intercom.generate.content.field.ip_rda_connection_types' | translate: {
        url: IP_INTERCOMS_GUIDE_LINK | safeUrl
      }"></mat-hint>
    </mat-form-field>

    <mat-form-field appearance="outline" class="relative-size">
      <input
        #uidInput
        matInput
        type="text"
        placeholder="UID"
        autocorrect="off"
        autocomplete="off"
        spellcheck="false"
        autocapitalize="off"
        formControlName="uid" />
      <span
        matPrefix
        *ngIf="sipTrunkConnectionType === form.get('mode').value">
        siptrunk_
      </span>
      <mat-hint>
        {{ 'shared.rda.intercom.generate.content.hint.sip_trunk_connection_type' | translate }}
      </mat-hint>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="relative-size"
      *ngIf="sipTrunkConnectionType !== form.get('mode').value">
      <mat-label>
        {{ 'shared.rda.intercom.generate.content.field.password' | translate }}
      </mat-label>
      <input
        matInput
        autocorrect="off"
        spellcheck="false"
        autocapitalize="off"
        autocomplete="new-password"
        formControlName="privateKey"
        [type]="hidePassword ? 'password' : 'text'" />
      <mat-icon
        matSuffix
        (click)="hidePassword = !hidePassword"
        class="password-show-icon"
        [svgIcon]="hidePassword ? 'invisible' : 'visible'"></mat-icon>
      <mat-hint>
        {{ 'shared.rda.intercom.generate.content.hint.password' | translate }}
      </mat-hint>
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="relative-size">
      <input
        matInput
        #uidInput
        type="text"
        autocorrect="off"
        spellcheck="false"
        autocomplete="off"
        autocapitalize="off"
        formControlName="livenessUrl"
        placeholder="{{ 'shared.rda.intercom.generate.content.placeholder.liveness_url' | translate }}"
      />
    </mat-form-field>

    <h3 class="links-header">
      {{ 'shared.rda.intercom.generate.content.message.specify_links' | translate: {
        link: ('plural.links' | translate: { count: basIpIntercom ? 5 : 1 })
      } }}
    </h3>

    <mat-form-field
      appearance="outline"
      class="relative-size"
      *ngIf="this.basIpIntercom || this.sputnikIntercom">
      <input
        matInput
        #uidInput
        type="text"
        autocorrect="off"
        spellcheck="false"
        autocomplete="off"
        autocapitalize="off"
        formControlName="authUrl"
        placeholder="{{ 'shared.rda.intercom.generate.content.placeholder.auth_url' | translate }}" />
    </mat-form-field>

    <mat-form-field
      appearance="outline"
      class="relative-size">
      <input
        #uidInput
        matInput
        type="text"
        placeholder="{{ 'shared.rda.intercom.generate.content.placeholder.open_door_url' | translate }}"
        formControlName="openDoorUrl"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false" />
    </mat-form-field>

    <div class="popup-actions">
      <div
        class="uid-generate-block"
        *ngIf="!generateUrlsMode">
        <p [innerHTML]="'shared.rda.intercom.generate.content.message.uid_generate' | translate "></p>
        <button
          type="button"
          mat-raised-button
          color="primary-inverse"
          class="popup-actions__button uid-generate-block__action-button"
          [disabled]="loading"
          (click)="onChangeGenerateUrlsMode()"
        >
          {{ 'shared.rda.intercom.generate.content.button.generate_url' | translate }}
        </button>
      </div>

      <button
        type="submit"
        color="primary"
        mat-raised-button
        [disabled]="!form.valid || loading"
        class="popup-actions__button"
      >
        {{ 'shared.rda.intercom.generate.content.button.generate_uid' | translate }}
      </button>
    </div>
  </form>
</ng-template>
