import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper';
import { IntercomInfoContainerBody } from './intercom-info-container-body.model';

@Component({
  selector: 'app-intercom-info-popup',
  templateUrl: './intercom-info-popup.component.html',
  styleUrls: ['./intercom-info-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntercomInfoPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<IntercomInfoContainerBody, null>
  ) { }
}
