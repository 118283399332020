import {NgModule} from '@angular/core';
import {SharedPipesModule} from '@app/shared';
import {CameraInfoPopupModule} from '@app/shared/components';
import {SharedModule} from '@app/shared/shared.module';
import {ServicePageActionsService} from '@app/views/services/services';
import {
  GatesModule,
  SoftwareIntercomsModule,
  VideoModule
} from '@app/views/services/submodules';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {
  ServicesAdditionalFlatRangesEffects,
  ServicesCamerasEffects,
  ServicesConnectionsEffects,
  ServicesEntrancesEffects,
  ServicesGeneralEffects,
  ServicesIntercomKeysEffects,
  ServicesIntercomPanelEffects,
  ServicesIntercomsEffects,
  ServicesLocationsEffects,
  ServicesLogsEffects,
  ServicesPbxOnRdaEffects,
  servicesReducer,
  ServicesTranslationTuningsEffects
} from './store';
import {VideoManagerModule} from '@app/views/services/submodules/video-manager';
import {
  ApartmentVideoSurveillanceModule
} from '@app/views/services/submodules/apartment-video-surveillance/apartment-video-surveillance.module';

@NgModule({
  imports: [
    SharedModule,
    GatesModule,
    VideoModule,
    SoftwareIntercomsModule,
    CameraInfoPopupModule,
    VideoManagerModule,
    ApartmentVideoSurveillanceModule,
    SharedPipesModule,
    StoreModule.forFeature('services', servicesReducer),
    EffectsModule.forFeature([
      ServicesGeneralEffects,
      ServicesAdditionalFlatRangesEffects,
      ServicesConnectionsEffects,
      ServicesEntrancesEffects,
      ServicesIntercomKeysEffects,
      ServicesIntercomPanelEffects,
      ServicesLocationsEffects,
      ServicesPbxOnRdaEffects,
      ServicesTranslationTuningsEffects,
      ServicesCamerasEffects,
      ServicesIntercomsEffects,
      ServicesLogsEffects
    ])
  ],
  providers: [ServicePageActionsService],
})
export class ServicesModule {
}
