import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnonymousGuard } from '@app/security';
import { CompanyRegisterPageComponent, CompanySignUpRequestPageComponent } from './pages';
import { CompanyUserProfileComponent } from '@app/views/company/pages/company-roles-page/company-user-profile/company-user-profile.component';

const routes: Routes = [
  {
      path: 'request',
      component: CompanySignUpRequestPageComponent,
      canActivate: [AnonymousGuard],
      data: { page: 'CompanySignUpRequest' }
  },
  {
      path: 'register',
      component: CompanyRegisterPageComponent,
      canActivate: [AnonymousGuard],
      data: { page: 'CompanySignUpRegister' }
  },
  {
    path: 'company-user-profile',
    component: CompanyUserProfileComponent,
    data: { link: 'company-user-profile', page: 'Profile', name: 'profile', menuBtnId: 'about' }
  },
  {
      path: 'about',
      loadChildren: () => import('./pages/company-about-page/company-about-page.module').then(m => m.CompanyAboutPageModule),
      data: { link: 'partners/about', page: 'About', name: 'company', menuBtnId: 'about', isBackBtnEnabled: true }
  }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})

export class CompanyRoutingModule { }
