<mat-card class="dashboard-blocked-user">
  <mat-card-header>
    <mat-card-title>
      {{ 'dashboard.blocked.user.title' | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="dashboard-blocked-user-content">
    <h3>{{ 'dashboard.blocked.user.subtitle' | translate }}</h3>

    <p>
      {{ 'dashboard.blocked.user.text.1' | translate }}
    </p>

    <p [innerHTML]="'dashboard.blocked.user.text.2' | translate: {
        link1_href: 'mailto:' + managerEmail,
        link1_text: managerEmail,
        link2_href: 'mailto:sales@rosdomofon.com',
        link2_text: 'sales@rosdomofon.com'
      }">
    </p>

    <br>

    <button
      color="primary"
      class="dashboard-blocked-user-content__button"
      mat-raised-button
      [disabled]="loading$ | async"
      (click)="onGetPaymentReport()"
    >

      <app-component-loader
        *ngIf="loading$ | async; else btnText"
        [whiteCircle]="true"
      ></app-component-loader>
    <ng-template #btnText>
      {{ 'dashboard.blocked.user.button.download' | translate }}
    </ng-template>
    </button>
  </mat-card-content>
</mat-card>
