import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { DadataCompanyResponse } from '@app/shared/entities/integrations';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DadataApiService extends ApiService {
    constructor(httpClient: HttpClient, handler: HttpBackend, humanErrorTranslationService: HumanErrorTranslationService) {
        httpClient = new HttpClient(handler);
        super(httpClient, humanErrorTranslationService, environment.dadataApiUrl, []);
    }

    getCompany(query: string): Observable<DadataCompanyResponse> {
        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json')
            .set('Authorization', `Token ${environment.dadataToken}`);

        return this.post('party', 0, { query, status: ['ACTIVE'] }, { headers });
    }
}
