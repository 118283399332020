import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/shared/components';
import {LoaderService} from '@app/shared/entities/common';
import {RdvaApiService} from '@app/shared/entities/integrations';
import {
  Camera,
  CameraApiService,
  ServiceApiService,
  ServiceCamerasV2Response,
  ServiceResponse
} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {
  ServiceFacade,
  ActiveServiceOnvif,
  ActiveServiceOnvifSuccess,
  ActiveServiceOnvifFailure,
  TuneServiceOnvif,
  TuneServiceOnvifSuccess,
  TuneServiceOnvifFailure, ConnectServiceCamera, ConnectServiceCameraSuccess, ConnectServiceCameraFailure,
} from '@app/views/services/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {forkJoin, from, Observable, of, zip} from 'rxjs';
import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {ServicePageActionsService} from '../../services';
import {
  AddServiceCamera,
  AddServiceCameraFailure,
  AddServiceCameraSuccess,
  DeleteServiceCamera,
  DeleteServiceCameraFailure,
  DeleteServiceCameraSuccess,
  GetServiceCameras,
  GetServiceCamerasFailure,
  GetServiceCamerasSuccess,
  ResetServiceCamera,
  ResetServiceCameraFailure,
  ResetServiceCameraSuccess,
  ServicesActionTypes,
  UpdateServiceCamera,
  UpdateServiceCameraFailure,
  UpdateServiceCameraSuccess
} from '../actions';
import {OnvifService} from '@app/shared/entities/rd/services/services/onvif.service';
import {ServiceOnvifActiveResponse} from '@app/shared/entities/rd/services/models/service-onvif-active-response.model';
import {ServiceOnvifTuneResponse} from '@app/shared/entities/rd/services/models/service-onvif-tune-response.model';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServicesCamerasEffects {
  constructor(
    private actions$: Actions,
    private loader: LoaderService,
    private snackbar: SnackbarService,
    private rdvaApiService: RdvaApiService,
    private serviceApiService: ServiceApiService,
    private cameraApiService: CameraApiService,
    private servicePageService: ServicePageActionsService,
    private onvifService: OnvifService,
    private serviceFacade: ServiceFacade,
    private translate: TranslateService
  ) {}

  GetServiceCameras$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<GetServiceCameras>(ServicesActionTypes.GetServiceCameras),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [GetServiceCameras, number]) => {
        this.loader.loaderState = {state: true};

        const requests: Observable<any>[] = [
          this.serviceApiService.getCamerasV2(serviceId),
          this.cameraApiService.getCamerasList(null, null, null, null, serviceId)
        ];

        const mergeCameras = (v2Cameras: ServiceCamerasV2Response[], cameras: Camera[], withIntercomId: boolean): Camera[] => {
          return v2Cameras.map(camerasResponse => {
            const v2Camera = withIntercomId === true ? Object.assign(camerasResponse.camera, camerasResponse.intercomId) : camerasResponse.camera;
            const camera = cameras.find(c => c.id === v2Camera.id);

            if (camera === undefined) {
              return v2Camera;
            }

            v2Camera.bitrate = camera.bitrate;
            v2Camera.segmentLength = camera.segmentLength;
            v2Camera.segmentsCount = camera.segmentsCount;
            v2Camera.onvifPort = camera.onvifPort;

            return v2Camera;
          });
        };

        return forkJoin(requests).pipe(
          map((response: [ServiceCamerasV2Response[], Camera[]]) => {
            const cameras = mergeCameras(response[0], response[1], true);
            const camerasWithoutIntercomId = mergeCameras(response[0], response[1], false);
            this.loader.loaderState = {state: false};
            this.serviceFacade.getCamerasSources(cameras);
            return new GetServiceCamerasSuccess(camerasWithoutIntercomId);
          }),
          catchError((error: HttpErrorResponse) => {
            this.loader.loaderState = {state: false};
            this.snackbar.showMessage(this.translate.instant('service.cameras.message.camera_loading_error', {
              text: parseError(error)
            }));
            return of(new GetServiceCamerasFailure());
          })
        );

      })
    );
  });

  ConnectServiceCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ConnectServiceCamera>(ServicesActionTypes.ConnectServiceCamera),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [ConnectServiceCamera, number]) =>
        from(this.serviceApiService.connectCamera(serviceId, action.camera.id))
          .pipe(
            map((response: void) => {
              this.snackbar.showMessage(this.translate.instant('service.cameras.message.connect.success'), 'success');
              return new ConnectServiceCameraSuccess(response);
            }),
            catchError((httpResponse: HttpErrorResponse) => {
              this.snackbar.showMessage(httpResponse.message);
              return of(new ConnectServiceCameraFailure(httpResponse.message));
            })
          )
      )
    ));

  AddServiceCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddServiceCamera>(ServicesActionTypes.AddServiceCamera),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [AddServiceCamera, number]) =>
        from(this.servicePageService.addCamera(serviceId, action.camera, action.intercomPanelId))
          .pipe(
            map((response: Camera) => {
              this.snackbar.showMessage(this.translate.instant('service.cameras.message.add.success'), 'success');
              // The Back-end does not return deviceId
              response.deviceId = action.camera.deviceId;
              return new AddServiceCameraSuccess(response);
            }),
            catchError((httpResponse: HttpErrorResponse) => {
              if (httpResponse.status !== 409) {
                this.snackbar.showMessage(this.translate.instant('service.cameras.message.add.failed', {
                  text: parseError(httpResponse)
                }), 'error');
              } else {
                return of(new AddServiceCameraFailure(httpResponse.error));
              }
            })
          )
      )
    )
  );

  AddServiceCameraSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddServiceCameraSuccess>(ServicesActionTypes.AddServiceCameraSuccess),
      withLatestFrom(this.serviceFacade.cameras$),
      tap(([action, cameras]: [AddServiceCameraSuccess, Camera[]]) =>
        this.serviceFacade.getCamerasSources(cameras)
      )
    ), {dispatch: false}
  );

  UpdateServiceCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateServiceCamera>(ServicesActionTypes.UpdateServiceCamera),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [UpdateServiceCamera, number]) =>
        zip(
          this.cameraApiService.updateCamera(action.camera),
          this.serviceApiService.updateCameraConnection(serviceId, action.camera.id, {
            intercomId: null,
            deviceId: action.camera?.deviceId ? action.camera?.deviceId : null
          })
        ).pipe(
          map(() => {
            this.snackbar.showMessage(this.translate.instant('service.cameras.message.edit.success'), 'success');
            return new UpdateServiceCameraSuccess(action.camera);
          }),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.showMessage(this.translate.instant('service.cameras.message.edit.failed', {
              text: parseError(error)
            }), 'error');
            return of(new UpdateServiceCameraFailure());
          })
        )
      )
    )
  );

  DeleteServiceCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceCamera>(ServicesActionTypes.DeleteServiceCamera),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [DeleteServiceCamera, number]) =>
        from(this.cameraApiService.getCameraServices(action.cameraId))
          .pipe(
            mergeMap(list => {
              if (list.length <= 1) {
                return this.cameraApiService.deleteCamera(action.cameraId);
              }
              return this.serviceApiService.disconnectCamera(serviceId, action.cameraId);
            }),
            map(() => {
              this.snackbar.showMessage(this.translate.instant('service.cameras.message.delete.success'), 'success');
              return new DeleteServiceCameraSuccess(action.cameraId);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(this.translate.instant('service.cameras.message.delete.failed', {
                text: parseError(error)
              }), 'error');
              return of(new DeleteServiceCameraFailure());
            })
          )
      )
    )
  );

  DeleteServiceCameraSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceCameraSuccess>(ServicesActionTypes.DeleteServiceCameraSuccess),
      tap((action: DeleteServiceCameraSuccess) =>
        this.serviceFacade.deleteCameraSource(action.cameraId)
      )
    ), {dispatch: false}
  );

  ResetServiceCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ResetServiceCamera>(ServicesActionTypes.ResetServiceCamera),
      switchMap((action: ResetServiceCamera) => {
        this.loader.loaderState = {state: true};

        return this.rdvaApiService.resetCamera(action.rdvaUri, action.cameraId)
          .pipe(
            map(() => {
              this.snackbar.showMessage(this.translate.instant('service.cameras.message.reboot.success'), 'success');
              this.loader.loaderState = {state: false};
              return new ResetServiceCameraSuccess();
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(this.translate.instant('service.cameras.message.reboot.failed', {
                text: parseError(error)
              }), 'error');
              return of(new ResetServiceCameraFailure());
            })
          );

      })
    )
  );

  ActiveServiceOnvif = createEffect(() =>
    this.actions$.pipe(
      ofType<ActiveServiceOnvif>(ServicesActionTypes.ActiveServiceOnvif),
      switchMap((action: ActiveServiceOnvif) => {
        this.loader.loaderState = { state: false };
        // TODO it will be delete by RD Development October 23 23:17 МСК
        return this.onvifService.activeServiceOnvif(action.camera)
          .pipe(
            map((response: ServiceOnvifActiveResponse) => {
              this.loader.loaderState = {state: false};
              return new ActiveServiceOnvifSuccess(response.success);
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              return of(new ActiveServiceOnvifFailure(false));
            })
          );
      })
    )
  );

  TuneServiceOnvif = createEffect(() =>
    this.actions$.pipe(
      ofType<TuneServiceOnvif>(ServicesActionTypes.TuneServiceOnvif),
      switchMap((action: TuneServiceOnvif) => {
        this.loader.loaderState = {state: true};

        return this.onvifService.tuneServiceOnvif(action.camera)
          .pipe(
            map((response: ServiceOnvifTuneResponse) => {
              this.loader.loaderState = {state: false};
              if (response.success) {
                this.snackbar.showMessage(this.translate.instant('service.cameras.message.tune.success'), 'success');
              } else {
                this.snackbar.showMessage(this.translate.instant('service.cameras.message.tune.failed', {
                  text: response.error
                }), 'error');
              }
              return new TuneServiceOnvifSuccess(response.success);
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(this.translate.instant('service.cameras.message.tune.failed', {
                text: parseError(error)
              }), 'error');
              return of(new TuneServiceOnvifFailure(false));
            })
          );
      })
    )
  );
}
