import { Company } from '@app/shared/entities/rd/company/models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
    GetAllCompanies = '[Intercom] GetAllCompanies',
    GetAllCompaniesSuccess = '[Intercom] GetAllCompanies Success',
    GetAllCompaniesFailure = '[Intercom] GetAllCompanies Failure',
    GetAllCompaniesClear = '[Intercom] GetAllCompanies Clear',
}

export class GetAllCompanies implements Action {
    readonly type = ActionTypes.GetAllCompanies;

    constructor() { }
}

export class GetAllCompaniesSuccess implements Action {
    readonly type = ActionTypes.GetAllCompaniesSuccess;

    constructor(public payload: Company[]) { }
}

export class GetAllCompaniesFailure implements Action {
    readonly type = ActionTypes.GetAllCompaniesFailure;

    constructor(public payload: string) { }
}

export class GetAllCompaniesClear implements Action {
    readonly type = ActionTypes.GetAllCompaniesClear;

    constructor() { }
}

export type Actions =
    | GetAllCompanies
    | GetAllCompaniesSuccess
    | GetAllCompaniesFailure
    | GetAllCompaniesClear;
