<div class="rda-warnings-content popup">
  <app-info-block>
    <ng-container info-block-text>
      {{ 'shared.rda.warnings.content.text' | translate }}
    </ng-container>
  </app-info-block>

  <div class="rda-warnings-content-data">
    <div
      *ngFor="let supportRequestLine of supportRequestLines"
      class="rda-warnings-content-data-info"
    >
      <p class="rda-warnings-content-data-info__text">
        {{ supportRequestLine }}
      </p>
    </div>
  </div>

  <div class="popup-actions">
    <a
      mat-raised-button
      color="primary"
      class="popup-actions__button"
      (click)="supportRequest()"
    >
      {{ 'shared.rda.warnings.content.button.submit' | translate }}
    </a>
  </div>
</div>
