import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ActiveHistoryChartLineComponent } from './active-history-chart-line/active-history-chart-line.component';
import { ActiveHistoryChartComponent } from './active-history-chart.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        NgxSkeletonLoaderModule
    ],
    declarations: [ActiveHistoryChartComponent, ActiveHistoryChartLineComponent],
    exports: [ActiveHistoryChartComponent]
})
export class ActiveHistoryChartModule { }
