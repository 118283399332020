import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-counts-card',
  templateUrl: './counts-card.component.html',
  styleUrls: ['./counts-card.component.scss']
})
export class CountsCardComponent {
  @Input() title!: string;
  @Input() percent!: number;
  @Input() firstLabel!: string;
  @Input() firstValue!: number;
  @Input() secondLabel: string;
  @Input() secondValue: number;
  @Input() showSecondLabel: boolean;
}
