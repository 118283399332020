import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { ServiceTariffPopupService } from './service-tariff-popup.service';
import { Tariff } from '@app/shared/entities/rd/tariff';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogWrapperData } from '@app/shared/ui';
import { ServiceTariffPopupModel } from '@app/views/services/components/popups/connections/service-tariff-popup/service-tariff-popup.model';
import { ServiceApiService } from '@app/shared/entities/rd';
import { SnackbarService } from '@app/shared/components';
import { ServicesTypes } from '@app/shared/models';
import { ServiceFacade } from '@app/views/services';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-service-tariff-delete-popup',
  templateUrl: './service-tariff-popup.component.html',
  styleUrls: ['./service-tariff-popup.component.scss'],
  providers: [ServiceTariffPopupService, SnackbarService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceTariffPopupComponent {
  popupState$: Observable<'loading' | 'close' | 'loaded'> = this.popupService.state$;

  public async tariffSaved(tariff: Tariff): Promise<void> {
    if (this.data.body.flats?.length > 0) {
      for (const flat of this.data.body.flats) {
        if (flat.services) {
          Object.keys(flat.services).map(async (key) => {
            flat.services[key].tariff = tariff[`tariff${key}`];
            await this.serviceApiService.updateConnectionTariff(flat.services[key].id, {
              tariff: +tariff[`tariff${key}`],
              applyTariffForConnections: tariff.applyTariffForConnections
            }).toPromise();
          });
        }
      }
    } else {
      if (this.data.body.hardwareId) {
        await this.serviceApiService.updateServiceTariff(this.data.body.hardwareId, {
          tariff: +tariff[`tariff${ServicesTypes.HARDWARE_INTERCOM}`],
          applyTariffForConnections: tariff.applyTariffForConnections
        }).toPromise();
      }
      if (this.data.body.serviceId) {
        await this.serviceApiService.updateServiceTariff(this.data.body.serviceId, {
          tariff: +tariff[`tariff${this.data.body.serviceType}`],
          applyTariffForConnections: tariff.applyTariffForConnections
        }).toPromise();
        await this.serviceFacade.getService(this.data.body.serviceId);
      }
    }
    await this.serviceFacade.getConnectionsInit();

    this.snackbar.showMessage(
      this.translate.instant('services.popups.connections.tariff.popup.message.saved.success'),
      'success'
    );
    this.data.submit();
    this.dialogRef.close();
  }

  public get defaultValue(): number {
    if (!!this.data.body.flats) {
      const firstValue = this.data.body?.flats[0].services[this.data.body.serviceType]?.tariff;
      const isAllValuesSame = this.data.body.flats.every(flat => flat.services[this.data.body.serviceType]?.tariff === firstValue);
      if (this.data.body.flats?.length === 1 || isAllValuesSame) {
        return firstValue;
      }
    }
  }

  public get defaultHardwareValue(): number {
    if (!!this.data.body.flats) {
      const firstValue = this.data.body?.flats[0].services[ServicesTypes.HARDWARE_INTERCOM]?.tariff;
      const isAllValuesSame = this.data.body.flats.every(flat => flat.services[ServicesTypes.HARDWARE_INTERCOM]?.tariff === firstValue);
      if (this.data.body.flats?.length === 1 || isAllValuesSame) {
        return firstValue;
      }
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<ServiceTariffPopupModel, void>,
    private readonly serviceApiService: ServiceApiService,
    private readonly popupService: ServiceTariffPopupService,
    private readonly dialogRef: MatDialogRef<any>,
    private readonly snackbar: SnackbarService,
    private readonly serviceFacade: ServiceFacade,
    private translate: TranslateService
  ) { }
}
