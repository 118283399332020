<div class="table-page-container">
  <mat-card>
    <mat-card-content>
      <div class="input-field">
        <mat-form-field appearance="outline" class="none-padding none-margin">
          <input
            matInput
            placeholder="{{ 'intercom.batches.page.placeholder.search' | translate }}"
            [formControl]="filterInput"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          >
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>

        <button mat-raised-button color="primary-inverse" (click)="onCreate()">
          <mat-icon>add</mat-icon>
          <ng-container *ngIf="!resolution.isMobile">
            {{ 'intercom.batches.page.button.add' | translate }}
          </ng-container>
        </button>
      </div>

      <hr>

      <mat-table [dataSource]="(batches$ | async)?.dataSource"
        *ngIf="(batches$ | async)?.totalCount > 0; else emptyData">
        <ng-container matColumnDef="createdAt">
          <mat-header-cell data-label="createdAt" *matHeaderCellDef>
            {{ 'intercom.batches.page.field.created_at' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="createdAt"
            label-name="{{ 'intercom.batches.page.field.created_at' | translate }}"
            *matCellDef="let element"
          >
            <p>
              {{ element.createdAt | date: 'dd.MM.yyyy HH:mm:ss' }}
            </p>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell data-label="number" *matHeaderCellDef>
            {{ 'intercom.batches.page.field.created_at' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="number"
            label-name="{{ 'intercom.batches.page.field.created_at' | translate }}"
            *matCellDef="let element"
          >
            {{ element.number}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="count">
          <mat-header-cell data-label="count" *matHeaderCellDef>
            {{ 'intercom.batches.page.field.count' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="count"
            label-name=" {{ 'intercom.batches.page.field.count' | translate }}"
            *matCellDef="let element"
          >
            {{ element.count ? element.count : '0' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="companyName">
          <mat-header-cell data-label="companyName" *matHeaderCellDef>
            {{ 'intercom.batches.page.field.company_name' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="companyName"
            label-name="{{ 'intercom.batches.page.field.company_name' | translate }}"
            *matCellDef="let element"
          >
            {{ element?.companyName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="rdaModelName">
          <mat-header-cell data-label="rdaModelName" *matHeaderCellDef>
            {{ 'intercom.batches.page.field.rda_model_name' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="rdaModelName"
            label-name="{{ 'intercom.batches.page.field.rda_model_name' | translate }}"
            *matCellDef="let element"
          >
            {{ element?.rdaModelName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="shieldModelName">
          <mat-header-cell data-label="shieldModelName" *matHeaderCellDef>
            {{ 'intercom.batches.page.field.shield_model_name' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="shieldModelName"
            label-name="{{ 'intercom.batches.page.field.shield_model_name' | translate }}"
            *matCellDef="let element"
          >
            {{ element?.shieldModelName }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="userName">
          <mat-header-cell data-label="userName" *matHeaderCellDef>
            {{ 'intercom.batches.page.field.user_name' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="userName"
            label-name="{{ 'intercom.batches.page.field.user_name' | translate }}"
            *matCellDef="let element"
          >
            {{ element?.userName || '' }}
          </mat-cell>
        </ng-container>

        <mat-header-row [style.display]="resolution.isTablet ? 'none' : 'flex'" *matHeaderRowDef="displayedColumns">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <ng-template #emptyData>
        <app-empty-data></app-empty-data>
      </ng-template>

      <mat-paginator [length]="(batches$ | async).totalCount" [pageSize]="pageSize" [hidePageSize]="true"
        [showFirstLastButtons]="true">
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
