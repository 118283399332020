import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PbxOnRdaRequest, PbxOnRdaResponse } from '@app/shared/entities/rd/pbx-on-rda/models';
import { RdaResponse, RdaUtilsService } from '@app/shared/entities/rd/rda';
import { SipAdapterResponse, SipAdapterUtilsService } from '@app/shared/entities/rd/sip-adapter';
import { PagedResponse, SelectSearch } from '@app/shared/models';
import { IpRdaConnectionTypePipe } from '@app/shared/pipes';
import { SnackbarService } from '@app/shared/components/snackbar';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper/dialog-wrapper-data.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-create-pbx-on-rda-form',
  templateUrl: './create-pbx-on-rda-form.component.html',
  styleUrls: ['./create-pbx-on-rda-form.component.scss'],
  providers: [IpRdaConnectionTypePipe]
})
export class CreatePbxOnRdaFormComponent implements OnInit {
  form: UntypedFormGroup;

  rdasList: SelectSearch[] = [];
  selectedRda: SelectSearch;
  rdasLoading: boolean;

  sipAdaptersList: SelectSearch[] = [];
  filteredSipAdaptersList: SelectSearch[] = [];
  selectedSipAdapter: SelectSearch;
  sipAdapterLoading: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<PbxOnRdaResponse, PbxOnRdaRequest>,
    private snackbar: SnackbarService,
    private rdaUtilsService: RdaUtilsService,
    private sipAdapterUtils: SipAdapterUtilsService,
    private sipAdapterUtilsService: SipAdapterUtilsService,
    private ipRdaConnectionTypePipe: IpRdaConnectionTypePipe,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      rdaUid: new UntypedFormControl(null, Validators.required),
      sipAdapterUid: new UntypedFormControl(null, Validators.required)
    });

    if (this.data.body?.pbxOnRdaUid) {
      this.form.get('rdaUid').setValue(this.data.body.pbxOnRdaUid);
      this.selectedRda = {
        text: this.data.body.pbxOnRdaUid,
        value: this.data.body.pbxOnRdaUid
      };
      this.rdasList = [this.selectedRda];
    }

    if (this.data.body?.sipAdapter?.uid) {
      this.form.get('sipAdapterUid').setValue(this.data.body.sipAdapter.uid);
      this.selectedSipAdapter = {
        text: this.data.body.sipAdapter.uid,
        value: this.data.body.sipAdapter.uid,
        badge: this.sipAdapterUtilsService.prepareSipAdapterSocket(this.data.body.sipAdapter)
      };
      this.sipAdaptersList = [this.selectedSipAdapter];
    }

    this.onSearchRdas();
    this.onGetSipAdapters();
  }

  onSubmit() {
    const { rdaUid, sipAdapterUid }: PbxOnRdaRequest = this.form.getRawValue();
    this.data.submit({ rdaUid, sipAdapterUid });
  }

  onSearchRdas(value?: string) {
    this.rdasLoading = true;

    this.rdaUtilsService.findRdaByUid(
      value,
      (response: PagedResponse<RdaResponse>, error: string) => {
        this.rdasLoading = false;

        if (error) {
          this.snackbar.showMessage(this.translate.instant('pbx-on-rda.create.message.search.error', {
            value: error
          }));
        } else if (response.content.length > 0) {
          this.rdasList = response.content.map(
            rda => ({
              text: rda.uid,
              value: rda.uid,
              badge: rda.mode ? this.ipRdaConnectionTypePipe.transform(rda.mode) : null
            })
          );

          const rdaUid: string = this.form.get('rdaUid').value;
          if (rdaUid !== null && this.rdasList.findIndex(rda => rda.value === rdaUid) === -1) {
            this.rdasList = this.rdasList.slice();
            this.rdasList.unshift({
              text: this.selectedRda.text,
              value: this.selectedRda.value,
              badge: this.selectedRda.badge
            });
          }
        }
      }
    );
  }

  onSelectRda(value: string) {
    const selectedRda: SelectSearch = this.rdasList.find(rda => rda.value === value);
    this.selectedRda = {
      text: selectedRda.text,
      value: selectedRda.value,
      badge: selectedRda.badge,
    };

    this.onSearchRdas();
  }

  onFilterSipAdapters(value?: string) {
    if (this.sipAdaptersList.length === 0) {
      return;
    }

    this.filteredSipAdaptersList = this.sipAdaptersList.filter(
      sipAdapter => (sipAdapter.value as string).toLowerCase().includes(value.toLowerCase())
    );

    if (
      this.selectedSipAdapter &&
      this.filteredSipAdaptersList.findIndex(sipAdapter =>
        sipAdapter.value === this.selectedSipAdapter.value
      ) === -1
    ) {
      this.filteredSipAdaptersList.unshift(this.selectedSipAdapter);
    }
  }

  onGetSipAdapters() {
    this.sipAdapterLoading = true;

    this.sipAdapterUtils.getList(
      (response: SipAdapterResponse[], error: string) => {
        this.sipAdapterLoading = false;

        if (error) {
          this.snackbar.showMessage(this.translate.instant('pbx-on-rda.create.message.get_sip_adapters.error', {
            value: error
          }));
        } else if (response.length > 0) {
          this.sipAdaptersList = response.map(
            sipAdapter => ({
              text: sipAdapter.uid,
              value: sipAdapter.uid,
              badge: this.sipAdapterUtilsService.prepareSipAdapterSocket(sipAdapter)
            })
          );
          this.filteredSipAdaptersList = this.sipAdaptersList;
        }
      }
    );
  }

  onSelectSipAdapter(value: string) {
    this.selectedSipAdapter = this.filteredSipAdaptersList.find(sipAdapter => sipAdapter.value === value);
    this.filteredSipAdaptersList = this.sipAdaptersList;
  }
}
