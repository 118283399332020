import {
  GetDelegationResponse,
  ServiceEntranceFlatResponse,
  ServiceSignUpsResponse
} from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';
import { Address, FlatRange, ServicesTypes } from '@app/shared/models';
import { AddressFormatter } from '@app/shared/services';
import { ServiceConnectionWithType } from '@app/views/services/models';
import {
  ServiceEntranceFlats,
  ServiceEntrancesFlats,
  ServiceFlat,
  ServiceFlatsFilters
} from './models';

export class ServiceFlatsFormatter {

  static prepareFlatsFromConnections(
    connections: ServiceConnectionWithType[],
    entrancesFlats: ServiceEntranceFlatResponse[],
    addresses: Address[],
    signUps: ServiceSignUpsResponse[],
    options?: { fillEmptyFlats?: boolean },
    addressFormatter?: AddressFormatter
  ): ServiceEntrancesFlats {
    const serviceEntrancesFlats: ServiceEntrancesFlats = {};

    addresses?.forEach((address: Address) => {
      const entranceFlats: ServiceEntranceFlats = {
        addressString: addressFormatter.formatAddress(address),
        flatRangesInfo: [],
        virtualFlatsCount: 0,
        flatsCount: 0,
        tariff: 0,
        virtualFlats: {},
        flats: {}
      };

      if (options?.fillEmptyFlats) {
        for (
          let flatNumber = address.entrance.flatStart;
          flatNumber <= address.entrance.flatEnd;
          ++flatNumber
        ) {
          entranceFlats.flats[flatNumber] = { flatNumber };
        }
      }

      entranceFlats.flatRangesInfo.push({
        totalFlatsCount:
          address.entrance.flatEnd - address.entrance.flatStart + 1,
        range: [address.entrance.flatStart, address.entrance.flatEnd]
      });

      address.entrance.additionalFlatRanges.forEach((flatRange: FlatRange) => {
        if (options?.fillEmptyFlats) {
          for (
            let flatNumber = flatRange.flatStart;
            flatNumber <= flatRange.flatEnd;
            ++flatNumber
          ) {
            entranceFlats.flats[flatNumber] = { flatNumber };
          }
        }

        entranceFlats.flatRangesInfo.push({
          totalFlatsCount: flatRange.flatEnd - flatRange.flatStart + 1,
          range: [flatRange.flatStart, flatRange.flatEnd]
        });
      });

      serviceEntrancesFlats[address.entrance.id] = entranceFlats;
    });

    entrancesFlats.forEach((entranceFlat: ServiceEntranceFlatResponse) => {
      const entranceFlats: ServiceEntranceFlats =
        serviceEntrancesFlats[entranceFlat.address.entrance.id];

      const rangeIdx: number = entranceFlats?.flatRangesInfo?.findIndex(
        (rangeInfo) =>
          entranceFlat.address.flat >= rangeInfo.range[0] &&
          entranceFlat.address.flat <= rangeInfo.range[1]
      );

      const flats: Dictionary<Partial<ServiceFlat>> =
        rangeIdx !== -1 ? entranceFlats?.flats : entranceFlats?.virtualFlats;
      let flat: Partial<ServiceFlat> = flats[entranceFlat?.address?.flat];

      if (!flat) {
        flat = {};
      }

      flat.flatNumber = entranceFlat.address.flat;

      if (entranceFlat) {
        flat.flat = entranceFlat;
      }

      if (entranceFlat.owner) {
        if (!flat.account) {
          flat.account = {};
        }

        flat.account.owner = entranceFlat.owner;
      }

      if (entranceFlat.owner?.delegations) {
        flat.sharedAbonents = entranceFlat.owner.delegations.map(
          (delegation: GetDelegationResponse) => ({
            delegationId: delegation.id,
            number: delegation.toAbonent.phone,
            abonentId: delegation.toAbonent.id
          })
        );
      }

      flats[entranceFlat.address.flat] = flat;
    });

    connections.forEach((connection: ServiceConnectionWithType) => {
      const entranceFlats: ServiceEntranceFlats =
        serviceEntrancesFlats[connection.flat.address.entrance.id];

      if (!entranceFlats) {
        return;
      }

      const rangeIdx: number = entranceFlats.flatRangesInfo.findIndex(
        (rangeInfo) =>
          connection.flat.address.flat >= rangeInfo.range[0] &&
          connection.flat.address.flat <= rangeInfo.range[1]
      );

      let flats: Dictionary<Partial<ServiceFlat>>;

      if (rangeIdx !== -1) {
        flats = entranceFlats.flats;
      } else {
        flats = entranceFlats.virtualFlats;
      }

      let flat: Partial<ServiceFlat> = flats[connection.flat.address.flat];

      if (!flat) {
        flat = {};
      }

      if (!flat.account?.id && connection.account) {
        flat.account = connection.account;
      }

      if (!flat.flat && connection.flat) {
        flat.flat = connection.flat;
        flat.flatNumber = connection.flat.address.flat;
      }

      if (!flat?.services) {
        flat.services = {};
      }

      flat.services[connection.type] = connection;

      flats[connection.flat.address.flat] = flat;
    });

    signUps.forEach((signUp: ServiceSignUpsResponse) => {
      if (
        !serviceEntrancesFlats[signUp.address.entrance.id].flats[
        signUp.address.flat
        ]
      ) {
        return;
      }

      if (
        !serviceEntrancesFlats[signUp.address.entrance.id].flats[
          signUp.address.flat
        ].signUps
      ) {
        serviceEntrancesFlats[signUp.address.entrance.id].flats[
          signUp.address.flat
        ].signUps = {
          count: 0
        };
      }

      serviceEntrancesFlats[signUp.address.entrance.id].flats[
        signUp.address.flat
      ].signUps.count++;

      serviceEntrancesFlats[signUp.address.entrance.id].flats[
        signUp.address.flat
      ].signUps.content = signUp;
    });

    Object.values(serviceEntrancesFlats).forEach((entranceFlats) => {
      entranceFlats.flatsCount = Object.keys(entranceFlats.flats).length;
      entranceFlats.virtualFlatsCount = Object.keys(
        entranceFlats.virtualFlats
      ).length;

      entranceFlats.flatRangesInfo = entranceFlats.flatRangesInfo.map(
        (flatRangeInfo) => {
          flatRangeInfo.totalFlatsCount = Object.values(
            entranceFlats.flats
          ).reduce(
            (prev, flat) =>
              prev +
              (flat.flatNumber >= flatRangeInfo.range[0] &&
                flat.flatNumber <= flatRangeInfo.range[1]
                ? 1
                : 0),
            0
          );

          return flatRangeInfo;
        }
      );
    });

    return serviceEntrancesFlats;
  }

  static filterFlats(
    flats: ServiceEntrancesFlats,
    filters: ServiceFlatsFilters
  ): ServiceEntrancesFlats {
    const filteredEntrancesFlats: ServiceEntrancesFlats = {};
    if (flats) {
      for (const [entranceIdStr, entranceFlats] of Object.entries(flats)) {
        const flatsEntries: [string, Partial<ServiceFlat>][] = Object.entries(
          entranceFlats.flats
        ).filter(([_, flat]: [string, Partial<ServiceFlat>]) =>
          this.filterFlat(flat, filters)
        );

        const virtualFlatsEntries: [string, Partial<ServiceFlat>][] =
          Object.entries(entranceFlats.virtualFlats).filter(
            ([_, flat]: [string, Partial<ServiceFlat>]) =>
              this.filterFlat(flat, filters)
          );

        const filteredEntranceFlats: ServiceEntranceFlats = {
          addressString: entranceFlats.addressString,
          flatRangesInfo: entranceFlats.flatRangesInfo,
          virtualFlatsCount: entranceFlats.virtualFlatsCount,
          flatsCount: entranceFlats.flatsCount,
          tariff: entranceFlats.tariff,
          virtualFlats: {},
          flats: {},
          filters,
        };

        for (const [flatId, flat] of virtualFlatsEntries) {
          filteredEntranceFlats.virtualFlats[flatId] = flat;
        }

        for (const [flatId, flat] of flatsEntries) {
          filteredEntranceFlats.flats[flatId] = flat;
        }

        filteredEntrancesFlats[entranceIdStr] = filteredEntranceFlats;

        filteredEntrancesFlats[entranceIdStr].flatsCount = Object.keys(
          filteredEntrancesFlats[entranceIdStr].flats
        ).length;

        filteredEntrancesFlats[entranceIdStr].virtualFlatsCount = Object.keys(
          filteredEntrancesFlats[entranceIdStr].virtualFlats
        ).length;

        filteredEntrancesFlats[entranceIdStr].flatRangesInfo =
          filteredEntrancesFlats[entranceIdStr].flatRangesInfo.map(
            (flatRangeInfo) => {
              flatRangeInfo.totalFlatsCount = flatsEntries.reduce(
                (prev, [_, flat]) =>
                  prev +
                  (flat.flatNumber >= flatRangeInfo.range[0] &&
                    flat.flatNumber <= flatRangeInfo.range[1]
                    ? 1
                    : 0),
                0
              );

              return flatRangeInfo;
            }
          );
      }
    }

    return filteredEntrancesFlats;
  }

  private static filterFlat(
    flat: Partial<ServiceFlat>,
    filters: ServiceFlatsFilters
  ): boolean {
    // Filter by Empty Flat
    if (filters.emptyFlat) {
      const isFlatEmpty = !flat.flat && !flat.account;

      if (!isFlatEmpty) {
        return false;
      }
    }

    // Filter by start range
    if (filters.rangeStart) {
      const isFlatInRange = flat?.flatNumber >= filters.rangeStart;

      if (!isFlatInRange) {
        return false;
      }
    }

    // Filter by end range
    if (filters.rangeEnd) {
      const isFlatInRange = flat?.flatNumber <= filters.rangeEnd;

      if (!isFlatInRange) {
        return false;
      }
    }

    // Filter by phone numbers
    if (filters.phone) {
      const phone = filters.phone.replace('+', '');
      const isPhoneSimilar = flat.account?.owner?.phone
        .toString()
        .includes(phone);

      const isPhoneAdditional = flat
        .sharedAbonents?.filter(sa => sa.number.toString().includes(phone)).length > 0;

      if (!isPhoneSimilar && !isPhoneAdditional) {
        return false;
      }
    }

    // Filter by active Abonent
    if (filters.activeAbonent) {

      if (!flat?.flat) {
        return false;
      }
      const isAbonentActive = (!flat.account || flat.account?.blocked !== true);
      if (!isAbonentActive) {
        return false;
      }
    }

    // Filter by blocked Abonent
    if (filters.blockedAbonent) {
      const isAbonentBlocked = flat?.account && flat.account.blocked === true;

      if (!isAbonentBlocked) {
        return false;
      }
    }

    // Filter by blocked services
    if (filters.blockedServices?.length) {
      if (!flat.services) {
        return false;
      }

      const otherServicesNotBlocked = filters.blockedServices.reduce(
        (prev, cur) =>
          prev &&
          !flat.services[cur]?.blocked &&
          !flat.services[cur]?.account?.blocked,
        filters.blockedServices.length !== 0
      );

      // Some of blocked conditionals is true
      if (otherServicesNotBlocked) {
        return false;
      }
    }

    // Filter by active services
    if (filters.activeServices?.length) {
      if (!flat.services) {
        return false;
      }

      const allServiceNotBlocked = filters.activeServices.reduce(
        (prev, cur) =>
          prev &&
          flat.services[cur]?.blocked === false &&
          (!flat.account || flat.account?.blocked === false),
        true
      );

      if (!allServiceNotBlocked) {
        return false;
      }
    }
    // Filter by stopped services
    if (filters.stoppedServices?.length) {

      const hasActiveOrBlockedService = filters.stoppedServices.some(
        (serviceName) =>
          filters.stoppedServices.includes(
            flat.services && flat.services[serviceName]?.type
          )
      );

      if (hasActiveOrBlockedService) {
        return false;
      }
    }
    return true;
  }
}
