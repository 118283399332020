import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateDelegationRequest } from '@app/shared/entities/rd';
import { Abonent, SignUpStatus } from '@app/shared/models';
import { DialogWrapperData } from '@app/shared/ui';
import { Observable } from 'rxjs';
import { AbonentsDelegationPopupBody, AbonentsDelegationPopupMode, AbonentsDelegationPopupResponse } from './models';
import { AbonentsDelegationPopupStore } from './store';

@Component({
  selector: 'app-abonents-delegation-popup',
  templateUrl: './abonents-delegation-popup.component.html',
  styleUrls: ['./abonents-delegation-popup.component.scss'],
  providers: [AbonentsDelegationPopupStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbonentsDelegationPopupComponent implements OnInit {
  status$: Observable<'loading' | 'loaded' | 'close'> = this.store.status$;
  mode$: Observable<AbonentsDelegationPopupMode> = this.store.mode$;
  owner$: Observable<Abonent> = this.store.owner$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<AbonentsDelegationPopupBody, AbonentsDelegationPopupResponse>,
    private dialogRef: MatDialogRef<AbonentsDelegationPopupComponent>,
    private store: AbonentsDelegationPopupStore
  ) { }

  ngOnInit() {
    this.store.addStatusChangeListener((status => {
      if (status === 'close') {
        this.data.submit({ status: SignUpStatus.DELEGATED });
        this.dialogRef.close();
      }
    }));

    this.store.checkDelegation({
      entranceId: this.data.body.signUp.entranceId,
      flat: this.data.body.signUp.address.flat,
      phone: this.data.body.signUp.phone,
      companyId: this.data.body.signUp.companyId
    });
  }

  onDelegate(fromAbonentId: number) {
    const request: CreateDelegationRequest = {
      fromAbonentId,
      toAbonentPhone: this.data.body.signUp.phone,
      companyId: this.data.body.signUp.companyId
    };

    this.store.createDelegation({ request });
  }

  onClose() {
    this.data.submit({ status: SignUpStatus.REJECTED });
  }
}
