import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { ResourcePath, ResourcesHelper } from '@app/shared/entities';
import { DialogWrapperData } from '@app/shared/ui';
import { ServiceFacade } from '@app/views/services/store';
import { ServiceAbonentsDisconnectPopupBody } from './service-abonents-disconnect-popup-data.model';
import { ServiceAbonentsDisconnectPopupService } from './service-abonents-disconnect-popup.service';
import { ServiceFlat } from '@app/views/services/components';

@Component({
  selector: 'app-service-abonents-disconnect-popup',
  templateUrl: './service-abonents-disconnect-popup.component.html',
  styleUrls: ['./service-abonents-disconnect-popup.component.scss'],
  providers: [ServiceAbonentsDisconnectPopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceAbonentsDisconnectPopupComponent implements OnInit {
  constructor(
    public resourcesHelper: ResourcesHelper,
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<ServiceAbonentsDisconnectPopupBody, void>,
    private serviceFacade: ServiceFacade,
    private popupService: ServiceAbonentsDisconnectPopupService
  ) { 
    this.popupService.entranceId = this.entranceId;
  }

  popupState$: Observable<'loading' | 'close' | 'loaded'> = this.popupService.state$;
  selectedFlats: ServiceFlat[] = this.data.body.flats;
  entranceId: number = this.selectedFlats.find( flat => flat?.flat?.address).flat.address.entrance.id;
  selectedFlatsIds: number[] = this.selectedFlats.map( flat => flat.flat.id);
  readonly resourcePath: typeof ResourcePath = ResourcePath;

  onDisconnectSharedAbonent(entranceId: number, delegationId: number) {
    this.serviceFacade.deleteAbonentAccess(entranceId, delegationId);
  }

  ngOnInit(): void {
   this.popupState$.subscribe( () => {
    if (this.popupService.flats) {
      this.selectedFlats = this.popupService.flats.filter( flat => flat.flat?.id && this.selectedFlatsIds.includes(flat.flat.id));
    }
   })
  }

}
