import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { SharedDirectivesModule } from '@app/shared/directives';
import { PhoneInputComponent } from './phone-input.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedDirectivesModule,
        MaterialModule,
        NgxTranslateModule
    ],
    declarations: [
        PhoneInputComponent
    ],
    exports: [
        PhoneInputComponent
    ]
})
export class PhoneInputModule { }
