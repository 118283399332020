import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackbarService } from '@app/shared/components';
import {
  LocalStorageHelper,
  LocalStoragePaymentsKey
} from '@app/shared/entities';
import {
  Company,
  CompanyApiService,
  CreatePaymentConfigurationRequest
} from '@app/shared/entities/rd';
import { parseError } from '@app/shared/helpers';
import { ServiceFacade } from '@app/views/services';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable, Subject } from 'rxjs';
import {
  filter,
  finalize,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import {
  PaymentsWizardStep,
  PaymentsWizardStepper,
  PaymentsWizardUpdateStep
} from '../models';
import {
  paymentsWizardInitialState,
  PaymentsWizardState
} from './payments-wizard.state';
import {TranslateService} from '@ngx-translate/core';
import {
  PaymentsWizardService
} from '@app/views/payments/components/popups/payments-wizard/models/payments-wizard-service';

@Injectable()
export class PaymentsWizardStore extends ComponentStore<PaymentsWizardState> {
  public readonly integrationSuccess: Subject<boolean> = new Subject();

  public readonly loading$: Observable<boolean> = this.select(
    (state: PaymentsWizardState) => state.loading
  );
  public readonly steps$: Observable<PaymentsWizardStepper[]> = this.select(
    (state: PaymentsWizardState) => state.steps
  );
  public readonly service$: Observable<PaymentsWizardService> = this.select(
    (state: PaymentsWizardState) => state.service
  );

  constructor(
    private snackbar: SnackbarService,
    private serviceFacade: ServiceFacade,
    private companyApiService: CompanyApiService,
    private translate: TranslateService
  ) {

    paymentsWizardInitialState.steps.find(s => s.id === 'service')
      .text = translate.instant('payments.popups.wizard.state.service');
    paymentsWizardInitialState.steps.find(s => s.id === 'register')
      .text = translate.instant('payments.popups.wizard.state.register');
    paymentsWizardInitialState.steps.find(s => s.id === 'integration')
      .text = translate.instant('payments.popups.wizard.state.integration');

    super({
      ...paymentsWizardInitialState,
      steps:
        LocalStorageHelper.getItem(LocalStoragePaymentsKey.STEPPER) ??
        paymentsWizardInitialState.steps,
      service: LocalStorageHelper.getItem(LocalStoragePaymentsKey.SERVICE) ?? PaymentsWizardService.YOOKASSA
    });
  }

  public readonly createIntegration = this.effect(
    (options$: Observable<CreatePaymentConfigurationRequest>) =>
      options$.pipe(
        tap(() => this.setLoading(true)),
        withLatestFrom(this.serviceFacade.company$),
        filter(
          ([, company]: [CreatePaymentConfigurationRequest, Company]) =>
            company?.shortName !== undefined
        ),
        switchMap(
          ([payload, company]: [CreatePaymentConfigurationRequest, Company]) =>
            this.companyApiService
              .createPaymentConfigurationV2(company.shortName, payload)
              .pipe(
                tapResponse(
                  () => this.integrationSuccess.next(true),
                  (error: HttpErrorResponse) =>
                    this.snackbar.showMessage(
                      this.translate.instant('payments.popups.wizard.message.create.failed', {
                        text: parseError(error)
                      })
                    )
                ),
                finalize(() => this.setLoading(false))
              )
        )
      )
  );

  private readonly setLoading = this.updater(
    (state: PaymentsWizardState, loading: boolean) => ({
      ...state,
      loading
    })
  );

  readonly updateService = this.updater(
    (
      state: PaymentsWizardState,
      service: PaymentsWizardService
    ) => ({
      ...state,
      service
    })
  );

  readonly updateStepState = this.updater(
    (
      state: PaymentsWizardState,
      {
        step,
        stepState
      }: {
        step: PaymentsWizardStep;
        stepState?: Partial<PaymentsWizardUpdateStep>;
      }
    ) => {
      if (stepState.complete !== undefined) {
        state.steps[step].complete = stepState.complete;
      }

      if (stepState.disabled !== undefined) {
        state.steps[step].disabled = stepState.disabled;
      }

      if (stepState.active !== undefined) {
        state.steps[step].active = stepState.active;
      }

      return {
        ...state,
        steps: state.steps.slice()
      };
    }
  );
}
