<ngx-skeleton-loader
  *ngIf="loading; else contentBlock"
  count="2"
  [theme]="{
    'border-radius': '8px',
    height: '45px'
  }"
></ngx-skeleton-loader>

<ng-template #contentBlock>
  <ng-container *ngFor="let key of keys; let last = last">
    <app-intercom-keys-container-item
      class="intercom-keys-container-item"
      [key]="key"
      [readonly]="readonly"
      (edit)="onEdit($event.key)"
      (delete)="onDelete($event.key)"
    ></app-intercom-keys-container-item>
  
    <hr *ngIf="!last && !resolution.isTablet">
  </ng-container>
</ng-template>