<div class="checkbox-block">
  <mat-checkbox
    [(ngModel)]="value"
    (change)="onSelect($event)"
    class="checkbox-block__slide-field"
  ></mat-checkbox>
  <div class="checkbox-block-text">
    <p class="checkbox-block-text__title">
      {{ 'shared.checkbox.free_flat' | translate }}
    </p>

    <p class="checkbox-block-text__description">
      {{ 'shared.checkbox.disable_abonent_services' | translate }}
    </p>
  </div>
</div>
