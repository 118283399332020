<div class="table-page-container">
  <mat-card>
    <mat-card-content>
      <div *ngIf="loading" class="table-page-container__loaders__wrapper">
        <p-skeleton width="100%" height="4rem"></p-skeleton>
        <hr class="table-page-container__loaders--devider">
        <p-skeleton width="100%" height="15rem"></p-skeleton>
        <p-skeleton width="100%" height="4rem"></p-skeleton>
      </div>

      <ng-container *ngIf="!loading">
        <mat-form-field appearance="outline" class="none-padding">
          <input
            matInput
            [formControl]="filterInput"
            placeholder="{{ 'cameras.page.placeholder.search' | translate }}"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          >
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <hr>
        <div *ngIf="(cameras$ | async)?.totalCount > 0" class="cameras-block">
          <app-camera-thumbnail
            *ngFor="let camera of (cameras$ | async)?.dataSource"
            [showInfo]="true"
            [camera]="camera"
            [mode]="videoMode"
          >
          </app-camera-thumbnail>
        </div>
        <ng-template #emptyData>
          <app-empty-data *ngIf="(cameras$ | async)?.totalCount !== undefined"></app-empty-data>
        </ng-template>
      </ng-container>

      <div [hidden]="loading">
        <mat-paginator [length]="(cameras$ | async)?.totalCount" [pageSize]="pageSize" [hidePageSize]="true"
                       [showFirstLastButtons]="true">
        </mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
