export interface Shield {
  id: number;
  uid: string;
  number: number;
  versionInfoStm: string;
  versionInfoShield: string;
  rdaUid: string;
  errorsCount: number;
  createdAt: string;
}
