<app-component-loader
  *ngIf="loading"
  [overTheComponent]="true"
  [sizePx]="40"
  [background]="true"></app-component-loader>

<div class="coowners">
  <p-panel
    *appRdeaCanAccess="permissionsTreeKey.ABONENTS_SIGN_UPS"
    [header]="'abonent.sign_ups.field.co_owners' | translate"
    [toggleable]="true"
    [collapsed]="false"
    [expandIcon]="'pi pi-angle-down'">
    <div class="coowners-header">
      <div class="coowners-header-column1">
        <span>{{'abonent.sign_ups.field.abonent' | translate}}</span>
      </div>
      <div class="coowners-header-column2">
        <span>{{'abonent.sign_ups.field.request_status' | translate}}</span>
      </div>
    </div>
    <hr />
    <ng-container *ngFor="let abonent of fromAbonent">
      <div class="coowners-card">
        <div class="coowners-card-phone">
          <span>+{{ abonent.phone }}</span>
          <mat-icon
            [style]="'height: 25px; width: 18px;'"
            svgIcon="right-arrow"
            (click)="onAbonentCardNavigate(abonent)"
          ></mat-icon>
        </div>
        <div class="coowners-card-left">
          <div class="coowners-card-left-status">
            <div
              [ngClass]="{
                active: abonent.resolved === true,
                blocked: abonent.resolved === false
              }"></div>
            <span>
              {{ abonent.resolved ? ('abonent.sign_ups.field.request_processed' | translate) : ('abonent.sign_ups.field.request_not_processed' | translate) }}
            </span>
          </div>
          <div class="coowners-card-left-actions">
            <i class="pi pi-pencil" (click)="onConnectionEdit(abonent)"></i>
            <i class="pi pi-trash" style="color: #EF5E67" (click)="onDelegationDelete(abonent)"></i>
            <i class="pi pi-angle-{{isPanelOpen(abonent.id) ? 'up' : 'down'}}"
              (click)="onPanelOpen(abonent)"
            ></i>
          </div>
        </div>
      </div>
      <div class="coowners-info">
        <div class="coowners-info-tips">
          <span>
            {{'abonent.sign_ups.field.services' | translate}} {{abonentDelegations| delegatedServices: { connections: abonentConnection, abonent: abonent } }}
            {{'abonent.sign_ups.field.from' | translate}} {{ abonentConnection.length }}
          </span>
        </div>
      </div>
      <ng-container *ngFor="let connection of abonentConnection">
        <div
          *ngIf="isPanelOpen(abonent.id)"
          class="coowners-services"
          @openCloseH>
          <div class="coowners-services-separator"></div>
          <div class="coowners-services-container">
            <div class="coowners-services-container-info">
              <img
                [src]="connection| stateUrlImg: { connections: abonentDelegations, abonent: abonent }
                "
                alt="error" />
              <span>
                {{
                  "service-navbar.tab.action.addition." +
                    ServiceType[connection.service.type] | translate
                }}
              </span>
              <mat-icon
                [style]="'height: 25px; width: 18px;'"
                svgIcon="right-arrow"
                (click)="onServicedNavigate(connection)"
              ></mat-icon>
            </div>
            <div
              class="coowners-services-container-address"
              [pTooltip]="connection.service.name">
              <span>
                {{connection['address'] | address}}, {{'abonent.sign_ups.field.flat' | translate}} {{connection['address'].flat}}
              </span>
            </div>
          </div>
        </div>
      </ng-container>
      <hr />
    </ng-container>
  </p-panel>
</div>
