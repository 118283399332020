import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import {ApiService, FileResponse} from '@app/shared/api';
import {
  Company,
  CompanyRegisterRequest,
  CompanyRegisterResponse,
  CompanySignUpRequest,
  CompanyTokenVerifyResponse,
  UpdatePaymentConfigurationRequest,
  UploadPaymentTemplateResponse,
  UploadPaymentTemplateHistoryResponse
} from '@app/shared/entities/rd/company/models';
import {
  PaymentsInterface,
  PaymentsParamsInterface
} from '@app/shared/entities/rd/payments/interfaces';
import { Country } from '@app/shared/models';
import { CompanyDataResponse, UpdateCompanyRequest } from '@app/views/company/store/company-page.state';
import { environment } from 'environments/environment';
import { UpdateBannerRequest } from '../models/company-banner-request';

import {
  CreatePaymentConfigurationRequest,
  PaymentConfigurationResponse
} from './../models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class CompanyApiService extends ApiService {
  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.companiesApiUrl, ['v1', 'v2']);
  }

  getAllCompanies(): Observable<Company[]> {
    return this.get(`/companies`, 1);
  }

  getCompanyById(id: number): Observable<Company> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.get(`/companies/${id}`, 1, { headers });
  }

  getCompanyByIdV2(id: number): Observable<CompanyDataResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.get(`/companies/${id}`, 2, { headers });
  }

  updateBanner(request: UpdateBannerRequest): Observable<void> {
    const formData = new FormData();

    formData.append('banner', request.image);

    return this.sendFiles(`/companies/${request.shortName}/banner`, 1, formData);
  }

  updateCompanyInfo(id: number, request: UpdateCompanyRequest): Observable<void> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.put(`/companies/${id}`, 1, request.company, { headers });

  }

  createCompanySignUp(request: CompanySignUpRequest): Observable<void> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post('/sign_ups', 1, request, { headers });
  }

  verifyCompanySignUpToken(
    token: string
  ): Observable<CompanyTokenVerifyResponse> {
    return this.get(`/sign_ups/verify?token=${token}`, 1);
  }

  registerCompany(
    request: CompanyRegisterRequest
  ): Observable<CompanyRegisterResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post('/companies?create_user=true', 1, request, { headers });
  }

  foreignCompany(country: Country): boolean {
    return (
      country?.shortName &&
      country.shortName !== 'RU' &&
      country.shortName !== 'KZ'
    );
  }

  createPaymentConfiguration(
    companyShortname: string,
    payload: CreatePaymentConfigurationRequest
  ): Observable<PaymentConfigurationResponse> {
    return this.put(
      `/companies/${companyShortname}/payment_config`,
      1,
      payload,
      {
        headers: ApiService.CONTENT_TYPE_JSON_HEADER
      }
    );
  }

  createPaymentConfigurationV2(
    companyShortname: string,
    payload: CreatePaymentConfigurationRequest
  ): Observable<PaymentConfigurationResponse> {
    return this.put(
      `/companies/${companyShortname}/payment_config`,
      2,
      payload,
      {
        headers: ApiService.CONTENT_TYPE_JSON_HEADER
      }
    );
  }

  updatePaymentConfiguration(
    companyShortname: string,
    payload: UpdatePaymentConfigurationRequest
  ): Observable<void> {
    return this.patch(
      `/companies/${companyShortname}/payment_config`,
      2,
      payload,
      {
        headers: ApiService.CONTENT_TYPE_JSON_HEADER
      }
    );
  }

  getPaymentConfiguration(
    companyShortname: string
  ): Observable<PaymentConfigurationResponse> {
    return this.get(`/companies/${companyShortname}/payment_config`, 1);
  }

  getPaymentsPeriod(
    companyId: number,
    filters: PaymentsParamsInterface
  ): Observable<PaymentsInterface[]> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        fromDate: filters.startDate ? filters.startDate : 0,
        toDate: filters.endDate ? filters.endDate : new Date().getTime(),
        searchString: filters.searchString ? filters.searchString : ''
      }
    });

    return this.get(`/tariffication/accounts`, 1, { params });
  }

  getAccountsDownload(
    filters: PaymentsParamsInterface
  ): Observable<FileResponse> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        fromDate: filters.startDate ? filters.startDate : 0,
        toDate: filters.endDate ? filters.endDate : new Date().getTime(),
        searchString: filters.searchString ? filters.searchString : ''
      }
    });

    return this.getFile(`/tariffication/accounts/download`, 1, { params });
  }

  getPaymentsManualTemplate(): Observable<FileResponse> {
    return this.getFile(`/payments/manual/template`, 1);
  }

  uploadPaymentsManualTemplate(file: File, isConfirm: Boolean): Observable<UploadPaymentTemplateResponse[]> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('isConfirm', isConfirm ? 'true' : 'false');

    return this.sendFiles('/payments/manual/template', 1, formData);
  }

  getPaymentsManualTemplateHistory(): Observable<UploadPaymentTemplateHistoryResponse[]> {
    return this.get('/payments/manual/template/history', 1);
  }

  downloadPaymentsManualTemplateHistory(id: number): Observable<FileResponse> {
    return this.getFile(`/payments/manual/template/history/` + id, 1);
  }

  markPaymentPaid(confirmationKey: string): Observable<PaymentsInterface[]> {
    return this.put(`/payments/${confirmationKey}/manual_confirmation`, 1);
  }
}
