<div class="text-with-action__wrapper">
  <ng-container *ngIf="!isEmpty(actionsLeft)">
    <p-button *ngFor="let action of actionsLeft" [label]="action?.label" [icon]="action?.icon"
              [styleClass]="action?.styleClass || ''" [class]="action?.className || ''"
              [disabled]="action?.disabled ? action?.disabled() : false"
              [pTooltip]="action?.tooltip || ''" [tooltipPosition]="action?.tooltipPosition || 'top'"
              (onClick)="action?.action()"></p-button>
  </ng-container>
  <div class="text-with-action--text">{{text}}</div>
  <ng-container *ngIf="!isEmpty(actionsRight)">
    <p-button *ngFor="let action of actionsRight" [label]="action?.label" [icon]="action?.icon"
              [styleClass]="action?.styleClass || ''" [class]="action?.className || ''"
              [disabled]="action?.disabled ? action?.disabled() : false"
              [pTooltip]="action?.tooltip || ''" [tooltipPosition]="action?.tooltipPosition || 'top'"
              (onClick)="action?.action()"></p-button>
  </ng-container>
</div>
