import {LogsResponseMessage} from '@app/shared/models';
import {RdvaCameraLogsPatterns} from '../models';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';

export class RdvaCameraLogsParser {

  static STARTING_CONNECTION(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(RdvaCameraLogsPatterns.STARTING_CONNECTION);

    if (messageItems?.length !== 3) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.rdva.camera.logs.starting_connection.text'),
      description: translate.instant('shared.entities.integrations.rdva.camera.logs.starting_connection.description', {
        ip: messageItems[1],
        port: messageItems[2]
      })
    };
  }

  static SUCCESSFULLY_CONNECTED(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(RdvaCameraLogsPatterns.SUCCESSFULLY_CONNECTED);

    if (messageItems?.length !== 3) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.rdva.camera.logs.successfully_connected.text'),
      description: translate.instant('shared.entities.integrations.rdva.camera.logs.successfully_connected.description', {
        ip: messageItems[1],
        port: messageItems[2]
      })
    };
  }

  static FAILED_CONNECTION_TIMEOUT(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(RdvaCameraLogsPatterns.FAILED_CONNECTION_TIMEOUT);

    if (!messageItems?.length) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.rdva.camera.logs.failed_connection_timeout.text'),
      description: translate.instant('shared.entities.integrations.rdva.camera.logs.failed_connection_timeout.description')
    };
  }

  static FAILED_CONNECTION_REFUSED(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(RdvaCameraLogsPatterns.FAILED_CONNECTION_REFUSED);

    if (!messageItems?.length) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.rdva.camera.logs.failed_connection_refused.text'),
      description: translate.instant('shared.entities.integrations.rdva.camera.logs.failed_connection_refused.description')
    };
  }

  static FAILED_NO_ROUTE_TO_HOST(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(RdvaCameraLogsPatterns.FAILED_NO_ROUTE_TO_HOST);

    if (!messageItems?.length) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.rdva.camera.logs.failed_no_route_to_host.text'),
      description: translate.instant('shared.entities.integrations.rdva.camera.logs.failed_no_route_to_host.description')
    };
  }

  static FAILED_NETWORK_IS_UNREACHEABLE(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(RdvaCameraLogsPatterns.FAILED_NETWORK_IS_UNREACHEABLE);

    if (!messageItems?.length) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.rdva.camera.logs.failed_network_is_unreacheable.text'),
      description: translate.instant('shared.entities.integrations.rdva.camera.logs.failed_network_is_unreacheable.description')
    };
  }

  static FAILED_UNAUTHORIZED(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(RdvaCameraLogsPatterns.FAILED_UNAUTHORIZED);

    if (!messageItems?.length) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.rdva.camera.logs.failed_unauthorized.text'),
      description: translate.instant('shared.entities.integrations.rdva.camera.logs.failed_unauthorized.description')
    };
  }

  static FAILED_AUTHORIZATION_REQUIRED(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(RdvaCameraLogsPatterns.FAILED_AUTHORIZATION_REQUIRED);

    if (!messageItems?.length) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.rdva.camera.logs.failed_authorization_required.text'),
      description: translate.instant('shared.entities.integrations.rdva.camera.logs.failed_authorization_required.description')
    };
  }

  static FAILED(message: string, translate: TranslateService): LogsResponseMessage {
    if (!message) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.rdva.camera.logs.failed.text', { text: message}),
      description: translate.instant('shared.entities.integrations.rdva.camera.logs.failed.description')
    };
  }
}
