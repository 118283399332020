import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RdaResponse } from '@app/shared/entities/rd/rda';
import { FlatRange, TranslationTuningRequest, TranslationTuningResponse } from '@app/shared/models';

@Component({
  selector: 'app-rda-translations-editor',
  templateUrl: './rda-translations-editor.component.html',
  styleUrls: ['./rda-translations-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RdaTranslationsEditorComponent {
  @Input() rda: RdaResponse;
  @Input() loading: boolean;
  @Input() range?: [number, number];
  @Input() additionalRanges: FlatRange[];
  @Input() translationTunings: TranslationTuningResponse[];

  @Output() private saveTranslation: EventEmitter<{ translation: number }> = new EventEmitter();
  @Output() private addTranslationTuning: EventEmitter<{ request: TranslationTuningRequest }> = new EventEmitter();
  @Output() private deleteTranslationTuning: EventEmitter<{ translationTuningId: number }> = new EventEmitter();

  onSaveTranslation(event: { translation: number }) {
    this.saveTranslation.emit({ translation: event.translation });
  }

  onAddTranslationTuning(event: TranslationTuningRequest) {
    this.addTranslationTuning.emit({ request: event });
  }

  onDeleteTranslationTuning(event: { id: number }) {
    this.deleteTranslationTuning.emit({ translationTuningId: event.id });
  }
}
