import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import {ComponentLoaderModule, DialogWrapperModule, InfoBlockModule} from '@app/shared/ui';
import { ServiceManagePopupComponent } from './service-manage-popup.component';
import {AbonentConnectionToolModule} from '@app/shared/components';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        InfoBlockModule,
        SharedPipesModule,
        DialogWrapperModule,
        AbonentConnectionToolModule,
        ComponentLoaderModule,
        NgxTranslateModule
    ],
    declarations: [
        ServiceManagePopupComponent
    ],
    exports: [
        ServiceManagePopupComponent
    ]
})
export class ServiceManagePopupModule { }
