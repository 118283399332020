import { Injectable } from '@angular/core';
import { IntercomType, IntercomTypeHandsetLockParams } from '@app/shared/entities/rd/intercom-type/models';
import { compareVersions, parseError } from '@app/shared/helpers';
import { Address, PagedResponse } from '@app/shared/models';
import { Protocol } from '@app/views/intercom/models';
import { forkJoin, Observable } from 'rxjs';
import { ProtocolTypes } from '../../protocols';
import { IpRdaConnectionTypes } from '../models/ip-rda-connection-types.enum';
import { RdaNewResponse } from '../models/rda-new-response.model';
import { RdaResponse } from '../models/rda-response.model';
import { RdaUpdateRequest } from '../models/rda-update-request.model';
import { RdaApiService } from './rda-api.service';

@Injectable({ providedIn: 'root' })
export class RdaUtilsService {
  constructor(
    private rdaApiService: RdaApiService
  ) { }

  connectRdaToAddress(rdaUid: string, address: Address, request: RdaUpdateRequest): Observable<any[]> {
    return forkJoin([this.rdaApiService.addAddress(rdaUid, address), this.rdaApiService.updateAdapter(rdaUid, request)]);
  }

  checkAdapterErrors(adapter: RdaResponse | RdaNewResponse): boolean {
    if (this.ipType(adapter?.intercomType)) {
      return false;
    }

    if (!adapter) {
      return null;
    }

    return !adapter.versionInfoOs ||
      !adapter.versionInfoRdaa ||
      !adapter.versionInfoStm ||
      !adapter.versionInfoShield;
  }

  ipType(intercomType: IntercomType): boolean {
    if (!intercomType?.protocol) {
      return null;
    }
    return intercomType.protocol.ipType ? true : false;
  }

  newShieldType(versionInfoShield: string): boolean {
    if (!versionInfoShield) {
      return null;
    }

    return compareVersions(versionInfoShield, '30') > - 1 ? true : false;
  }

  supportedIntercom(rda: RdaResponse, intercomType: IntercomType) {
    if (this.ipType(intercomType)) {
      return true;
    }

    if (!intercomType?.protocol || !rda?.versionInfoShield) {
      return null;
    }

    let intercomSupported: boolean;
    const protocol: Protocol = intercomType.protocol;
    const newShieldType: boolean = this.newShieldType(rda?.versionInfoShield);

    if (newShieldType) {
      intercomSupported = !!protocol.rda3SinceRdviVersion && !!protocol.rda3SinceStmDriverVersion;
    } else if (newShieldType === false) {
      intercomSupported = !!protocol.sinceStmVersion && !!protocol.sinceStmDriverVersion;
    } else {
      intercomSupported = null;
    }

    return intercomSupported;
  }

  correctedVersions(rda: RdaResponse, intercomType: IntercomType): boolean {
    let correctedMinStmVersion: boolean;
    let correctedMinStmDriverVersion: boolean;

    if (this.ipType(intercomType)) {
      return true;
    }

    const newShieldType: boolean = this.newShieldType(rda?.versionInfoShield);

    if (newShieldType === null) {
      return null;
    }

    let sinceStmVersion: string;
    let sinceStmDriverVersion: string;

    if (newShieldType) {
      sinceStmVersion = intercomType?.protocol?.rda3SinceRdviVersion;
      sinceStmDriverVersion = intercomType?.protocol?.rda3SinceStmDriverVersion;
    } else if (newShieldType === false) {
      sinceStmVersion = intercomType?.protocol?.sinceStmVersion;
      sinceStmDriverVersion = intercomType?.protocol?.sinceStmDriverVersion;
    } else {
      sinceStmVersion = null;
      sinceStmDriverVersion = null;
    }

    if (!sinceStmVersion || !sinceStmDriverVersion) {
      return null;
    }

    correctedMinStmVersion = this.checkMinVersion(rda?.versionInfoStm, sinceStmVersion);
    correctedMinStmDriverVersion = this.checkMinVersion(rda?.versionInfoStmDriver, sinceStmDriverVersion);

    if (correctedMinStmVersion == null || correctedMinStmDriverVersion == null) {
      return null;
    }

    return correctedMinStmVersion && correctedMinStmDriverVersion;
  }

  stmDriverUpgradeSupported(versionInfoOs): boolean {
    const comparedVersions: number = compareVersions(versionInfoOs, '4.5.5');

    if (comparedVersions === null) {
      return null;
    }

    return comparedVersions >= 0;
  }

  stmDriverAvaliable(versionInfoRdaa): boolean {
    const comparedVersions: number = compareVersions(versionInfoRdaa, '1.41.11');

    if (comparedVersions === null) {
      return null;
    }

    return comparedVersions >= 0;
  }

  public convertIpRdaConfigStr(
    intercomType: IntercomType,
    configStr: string,
    livenessUrl: string,
    opendoorUrl: string,
    authURL: string,
    mode?: IpRdaConnectionTypes,
    handsetLockParams?: IntercomTypeHandsetLockParams,
    bearerToken?: string,
    hikvision?: {password: string; login: string;}
    ): string {
    let configStrParams: string[] = [];
    if (configStr) {
      configStrParams = configStr.split(';');
    }

    const urls = [
      { value: livenessUrl, urlType: 'liveness' },
      { value: opendoorUrl, urlType: 'opendoor' },
      { value: authURL, urlType: 'authURL' },
      { value: bearerToken, urlType: 'bearerToken' }
    ];

    for (const param of urls) {
      let foundParamIdx = -1;
      for (let idx = 0; idx < configStrParams.length; ++idx) {
        if (configStrParams[idx].indexOf(param.urlType + ':') !== -1) {
          foundParamIdx = idx;
          if (param.value) {
            configStrParams[idx] = `${param.urlType}:${param.value}`;
          }
          break;
        }
      }
      if (foundParamIdx === -1 && param.value) {
        configStrParams.unshift(`${param.urlType}:${param.value}`);
      } else if (foundParamIdx > -1 && !param.value) {
        configStrParams.splice(foundParamIdx, 1);
      }
    }

    if (mode && !configStr?.includes(mode)) {
      configStrParams.push(`mode:${mode}`);
    }

    const controlModeIndex = configStrParams.findIndex(x => x.startsWith('control-mode:'));
    if (controlModeIndex !== -1) {
      configStrParams.splice(controlModeIndex, 1);
    }

    Object.entries(handsetLockParams)
      .forEach(([k]) => {
        const paramIndex = configStrParams.findIndex(x => x.startsWith(k + ':'));
        if (paramIndex !== -1) {
          configStrParams.splice(paramIndex, 1);
        }
      });

    if (intercomType?.protocol.number === ProtocolTypes.BEWARD) {
      if (handsetLockParams?.host) {
        configStrParams.push('control-mode:lite');
        Object.entries(handsetLockParams)
          .filter(x => x[1])
          .forEach(([k, v]) => configStrParams.push(`${k}:${v || ''}`));
      } else {
        configStrParams.push('control-mode:default');
      }
    }

    if (hikvision) {
      configStrParams.push(`login:${hikvision.login};password:${hikvision.password}`);
    }

    const result = configStrParams.filter(x => x.trim()).join(';');
    return result ? result + ';' : '';
  }

  convertConfigStrToParams(configStr: string): {
    liveness?: string;
    bearerToken?: string;
    ['control-mode']?: string,
  } & Partial<IntercomTypeHandsetLockParams> {
    if (!configStr) { return {}; }

    const config = configStr
      .split(';')
      .filter(x => x)
      .map(x => x.split(/\:(.*)/))
      .reduce((o, [k, v]) => (o[k] = v, o), {});

    return config;
  }

  async findRdaByUid(value: string, cb: (rdas: PagedResponse<RdaResponse>, error: string) => void) {
    try {
      const response = await this.rdaApiService.getAdaptersList(0, 20, null, false, null, value).toPromise();
      cb(response, null);
    } catch (error) {
      cb(null, parseError(error));
    }
  }

  private checkMinVersion(versionInfoStm: string, sinceStmVersion: string): boolean {
    if (!versionInfoStm || !sinceStmVersion) {
      return null;
    }

    return compareVersions(versionInfoStm, sinceStmVersion) >= 0 ? true : false;
  }
}
