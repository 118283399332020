import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CameraEditContentModule } from '@app/shared/components';
import { SoftwareIntercomTariffPopupContentComponent } from './software-intercom-tariff-popup-content.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MaterialModule} from '@app/material.module';
import {NgxMaskModule} from 'ngx-mask';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        CameraEditContentModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MaterialModule,
        NgxMaskModule.forRoot(),
        NgxTranslateModule
    ],
    declarations: [
        SoftwareIntercomTariffPopupContentComponent
    ],
    exports: [
        SoftwareIntercomTariffPopupContentComponent
    ]
})
export class SoftwareIntercomTariffPopupContentModule { }
