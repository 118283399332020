import { Injectable, OnDestroy } from '@angular/core';
import { DialogWrapperStateHelper } from '@app/shared/ui';
import { ServicesActionTypes } from '@app/views/services/store';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { UpdateService, UpdateServiceFailure, UpdateServiceSuccess } from '../../../../store';

@Injectable()
export class ServiceEditPopupService extends DialogWrapperStateHelper<'loading' | 'close' | 'loaded'> implements OnDestroy {
    constructor(
        private actions$: Actions
    ) {
        super();
        this.enableStateListeners();
    }

    ngOnDestroy() {
        this.destroy();
    }

    private enableStateListeners() {
        this.actions$
            .pipe(
                ofType<UpdateService>(ServicesActionTypes.UpdateService), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loading');

        this.actions$
            .pipe(
                ofType<UpdateServiceSuccess>(ServicesActionTypes.UpdateServiceSuccess), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'close');

        this.actions$
            .pipe(
                ofType<UpdateServiceFailure>(ServicesActionTypes.UpdateServiceFailure), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loaded');
    }
}
