import { Injectable } from '@angular/core';
import { Camera } from '@app/shared/entities/rd/camera/models';
import { CameraApiService } from '@app/shared/entities/rd/camera/services';
import { ServiceApiService } from '@app/shared/entities/rd/services/services';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import {
  ActionTypes,
  AddCamera,
  AddCameraFailure,
  AddCameraSuccess,
  DeleteCamera,
  DeleteCameraClear,
  DeleteCameraFailure,
  DeleteCameraSuccess,
  DisconnectDefaultCamera,
  DisconnectDefaultCameraFailure,
  DisconnectDefaultCameraSuccess,
  GetCamerasPage,
  GetCamerasPageFailure,
  GetCamerasPageSuccess,
  SetCameraAsDefault,
  SetCameraAsDefaultFailure,
  SetCameraAsDefaultSuccess,
  UpdateCamera,
  UpdateCameraClear,
  UpdateCameraFailure,
  UpdateCameraSuccess
} from '../actions/cameras.actions';

@Injectable()
export class CamerasEffects {
  constructor(
    private actions$: Actions,
    private camerasApiService: CameraApiService,
    private serviceApiService: ServiceApiService
  ) { }

  @Effect()
  GetCamerasPage$ = this.actions$.pipe(
    ofType<GetCamerasPage>(ActionTypes.GetCamerasPage),
    switchMap(action =>
      this.camerasApiService.getCamerasPage(action.page, action.size, action.sort, action.search).pipe(
        map(data => new GetCamerasPageSuccess(
          action?.filterBy && action?.filterBy?.length ?
            data.content.filter((camera) => action?.filterBy?.includes(camera?.id)) :
            data.content,
          data.totalElements)),
        catchError(error => of(new GetCamerasPageFailure(error)))
      )
    )
  );

  @Effect()
  AddCamera$ = this.actions$.pipe(
    ofType<AddCamera>(ActionTypes.AddCamera),
    switchMap(action =>
      this.camerasApiService.addCamera(action.camera).pipe(
        map(camera => new AddCameraSuccess(camera)),
        catchError(error => of(new AddCameraFailure(error)))
      )
    )
  );

  @Effect()
  UpdateCamera$ = this.actions$.pipe(
    ofType<UpdateCamera>(ActionTypes.UpdateCamera),
    switchMap(action =>
      this.camerasApiService.updateCamera(new Camera(action.payload)).pipe(
        map(() => new UpdateCameraSuccess()),
        catchError(error => of(new UpdateCameraFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  UpdateCameraClear$ = this.actions$.pipe(
    ofType<UpdateCameraClear>(ActionTypes.UpdateCameraClear),
    tap(() => new UpdateCameraClear())
  );

  @Effect()
  DeleteCamera$ = this.actions$.pipe(
    ofType<DeleteCamera>(ActionTypes.DeleteCamera),
    switchMap(action =>
      this.camerasApiService.deleteCamera(action.id).pipe(
        map(() => new DeleteCameraSuccess()),
        catchError(error => of(new DeleteCameraFailure(error)))
      ))
  );

  @Effect({ dispatch: false })
  DeleteCameraClear$ = this.actions$.pipe(
    ofType<DeleteCameraClear>(ActionTypes.DeleteCameraClear),
    tap(() => new DeleteCameraClear())
  );

  @Effect()
  SetCameraAsDefault$ = this.actions$.pipe(
    ofType<SetCameraAsDefault>(ActionTypes.SetCameraAsDefault),
    switchMap(action =>
      this.serviceApiService.connectCamera(action.serviceId, action.cameraId).pipe(
        map(() => new SetCameraAsDefaultSuccess(action.serviceId, action.cameraId)),
        catchError(error => of(new SetCameraAsDefaultFailure(error)))
      ))
  );

  @Effect()
  DisconnectDefaultCamera$ = this.actions$.pipe(
    ofType<DisconnectDefaultCamera>(ActionTypes.DisconnectDefaultCamera),
    switchMap(action =>
      this.serviceApiService.disconnectCamera(action.cameraId, action.serviceId).pipe(
        map(() => new DisconnectDefaultCameraSuccess(action.cameraId, action.serviceId)),
        catchError(error => of(new DisconnectDefaultCameraFailure(error)))
      ))
  );
}
