import { SoftwareIntercomWizardPopupStep, SoftwareIntercomWizardPopupStepper } from '../models';

export const initialSoftwareIntercomWizardSteps: SoftwareIntercomWizardPopupStepper[] = [
    { text: 'software.intercom.wizard.stepper.indicate_addresses', complete: false, active: false, hidden: false, disabled: false, initialized: false },
    { text: 'software.intercom.wizard.stepper.configure_adapter', complete: false, active: false, hidden: false, disabled: true, initialized: false },
    { text: 'software.intercom.wizard.stepper.call_panel', complete: false, active: false, hidden: true, disabled: true, initialized: false },
    { text: 'software.intercom.wizard.stepper.configure_displacement', complete: false, active: false, hidden: true, disabled: true, initialized: false },
    { text: 'software.intercom.wizard.stepper.configure_camera', complete: false, active: false, hidden: false, disabled: true, initialized: false },
    { text: 'software.intercom.wizard.stepper.tariff_plan', complete: false, active: false, hidden: false, disabled: true, initialized: false },
    { text: 'software.intercom.wizard.stepper.completion_of_setting', complete: false, active: false, hidden: false, disabled: true, initialized: true }
];

export interface SoftwareIntercomWizardPopupState {
    steps: SoftwareIntercomWizardPopupStepper[];
    showStepButtons: boolean;
    showMenu: boolean;
    wizardStep: SoftwareIntercomWizardPopupStep;
}
