<app-info-table>
  <app-info-table-record>
    <p title>{{ 'shared.camera.info.container.field.id' | translate }}</p>
    <p value>{{ camera.id }}</p>
  </app-info-table-record>

  <app-info-table-record>
    <p title>{{ 'shared.camera.info.container.field.bitrate' | translate }}</p>
    <p value>{{ camera.bitrate }}</p>
  </app-info-table-record>

  <app-info-table-record>
    <p title>{{ 'shared.camera.info.container.field.segments_count' | translate }}</p>
    <p value>{{ camera.segmentsCount }}</p>
  </app-info-table-record>

  <app-info-table-record>
    <p title>{{ 'shared.camera.info.container.field.segment_length' | translate }}</p>
    <p value>{{ camera.segmentLength }}</p>
  </app-info-table-record>

  <app-info-table-record>
    <p title>{{ 'shared.camera.info.container.field.address' | translate }}</p>
    <p value>{{ camera.address | address }}</p>
  </app-info-table-record>

  <app-info-table-record>
    <p title>{{ 'shared.camera.info.container.field.location' | translate }}</p>
    <p value>{{ camera.location ? camera.location?.name : '' }}</p>
  </app-info-table-record>

  <app-info-table-record>
    <p title>{{ 'shared.camera.info.container.field.rdva' | translate }}</p>
    <p value>{{ camera.rdva?.uri || camera.rdvaUri || '' }} {{ camera.rdva?.description ? " (" +
      camera.rdva.description + ")" : "" }}</p>
  </app-info-table-record>

  <app-info-table-record>
    <p title>{{ 'shared.camera.info.container.field.configuration' | translate }}</p>
    <p value>{{ camera.configuration }}</p>
  </app-info-table-record>
</app-info-table>
