import 'suggestions-jquery';
import * as $ from 'jquery';
import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { DadataSuggestionResponse, SuggestionsRequestOptions, SuggestionsStatus } from '@app/shared/entities/integrations/dadata';
import { environment } from 'environments/environment';

@Directive({
  selector: '[appDadataSuggestions]'
})
export class DadataSuggestionsDirective implements AfterViewInit {
  @Input() bounds?: 'city' | 'street' | 'house';
  @Input() constraints?: ElementRef | any;
  @Input() type: string;
  @Output() valueSelected = new EventEmitter<DadataSuggestionResponse>();

  constructor(
    private el: ElementRef
  ) { }

  ngAfterViewInit() {
    const element = this.el.nativeElement;
    const options: SuggestionsRequestOptions = {
      token: environment.dadataToken,
      type: this.type,
      geoLocation: true,
      bounds: this.bounds,
      params: { status: [SuggestionsStatus.ACTIVE] },
      onSelect: (suggestion: DadataSuggestionResponse) => {
        this.valueSelected.emit(suggestion);
      }
    };

    if (this.constraints instanceof ElementRef) {
      options.constraints = $(this.constraints);
    } else if (this.constraints) {
      options.constraints = this.constraints;
    } else if (this.bounds === 'city') {
      options.constraints = { label: '' };
    }

    (<any>$(element)).suggestions(options);
  }
}
