import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private readonly activeStyle = '--active';
  private readonly navigationButtonClass = 'menu-nav-block-item-link';
  private readonly navigationButtonIconClass = `${this.navigationButtonClass}__icon`;

  private _loaded: BehaviorSubject<boolean>;
  private _clicked: Subject<void>;

  constructor() {
    this._loaded = new BehaviorSubject<boolean>(false);
    this._clicked = new Subject<void>();
  }

  set loaded(value: boolean) {
    this._loaded.next(value);
  }

  click() {
    this._clicked.next();
  }

  get loaded$(): Observable<boolean> {
    return this._loaded.asObservable();
  }

  get clicked$(): Observable<void> {
    return this._clicked.asObservable();
  }

  prepareMenuButtons(menuButtonId: string) {
    this.setActive(menuButtonId);
    this.clearLinksFocus(menuButtonId);
  }

  clearLinksFocus(menuButtonId: string) {
    const linksElements: HTMLCollection = document.getElementsByClassName(`js-${this.navigationButtonClass}`) as HTMLCollection;

    for (let i = 0; i < linksElements.length; ++i) {
      if (linksElements[i].id !== menuButtonId) {
        linksElements[i].classList.remove(`${this.navigationButtonClass}${this.activeStyle}`);
        linksElements[i].children[0].children[0].classList.remove(`${this.navigationButtonIconClass}${this.activeStyle}`);
      }
    }
  }

  private setActive(menuButtonId: string) {
    if (document.getElementById(menuButtonId)) {
      document.getElementById(menuButtonId).classList.add(`${this.navigationButtonClass}${this.activeStyle}`);
      document.getElementById(menuButtonId).children[0].children[0].classList.add(`${this.navigationButtonIconClass}${this.activeStyle}`);
    }
  }
}
