import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {PersonalSurveillance} from '@app/shared/entities/rd/services/models/personal-surveillance.model';
import {Observable} from 'rxjs';
import {LogsComponentType, LogsResponse, ServicesTypes} from '@app/shared/models';
import {ServiceActivitySource} from '@app/views/services/models';
import {Dictionary} from '@app/shared/helpers';
import {ActiveHistoryResponse} from '@app/shared/entities/rd';
import {ServiceFacade} from '@app/views/services';

@Component({
  selector: 'app-apartment-video-service-activity',
  templateUrl: './apartment-video-service-activity.component.html',
  styleUrls: ['./apartment-video-service-activity.component.scss']
})
export class ApartmentVideoServiceActivityComponent implements OnInit, OnDestroy {
  readonly serviceType: ServicesTypes = ServicesTypes.PERSONAL_SURVEILLANCE;

  @Input() public serviceInfo: PersonalSurveillance.ServiceByIdResponse;

  logs$: Observable<LogsResponse[]> = this.serviceFacade.logsData$;
  logsLoading$: Observable<boolean> = this.serviceFacade.logsLoading$;
  blocksCount$: Observable<number> = this.serviceFacade.blocksCount$;
  sources$: Observable<ServiceActivitySource[]> = this.serviceFacade.sources$;
  timeRange$: Observable<number> = this.serviceFacade.timeRange$;
  extendedMode$: Observable<boolean> = this.serviceFacade.extendedMode$;
  totalLogsCount$: Observable<number> = this.serviceFacade.totalLogsCount$;
  currentLogsCount$: Observable<number> = this.serviceFacade.currentLogsCount$;
  logsComponentType$: Observable<LogsComponentType> = this.serviceFacade.logsComponentType$;
  selectedLogsSource$: Observable<ServiceActivitySource> = this.serviceFacade.selectedLogsSource$;
  activeHistoryCameras$: Observable<Dictionary<ActiveHistoryResponse[]>> = this.serviceFacade.activeHistoryCameras$;

  constructor(
    private serviceFacade: ServiceFacade
  ) { }

  public ngOnInit(): void {
    this.serviceFacade.getService(this.serviceInfo.id);
    this.serviceFacade.getCamerasLocations();
  }

  public ngOnDestroy(): void {
    this.serviceFacade.clearService();
  }
}
