import { Fragment } from 'hls.js';
import { VideoPlayerFragmentShortDate } from '../models';

export class HlsFragParseHelper {
    private static readonly START_DATE_REGEX: RegExp = /START-DATE=\"([\w\d-:.]+)"/;
    private static readonly END_DATE_REGEX: RegExp = /END-DATE=\"([\w\d-:.]+)"/;
    private static readonly INFO_CLASS: string = 'CLASS="INFO"';
    private static readonly EVENT_CLASS: string = 'CLASS="EVENT"';
    private static readonly RANGE_TAG: string = 'EXT-X-DATERANGE';

    /**
     * Extract ranges and events timestamps from hls fragments
     * @param fragments hls fragments
     * @returns ranges and events timestamps from hls fragments
     */
    static extractDateRangesFromFragments(
        fragments: Fragment[]
    ): { rangeTimestamps: VideoPlayerFragmentShortDate[], eventTimestamps: VideoPlayerFragmentShortDate[] } {
        const rangeTimestamps: VideoPlayerFragmentShortDate[] = [];
        const eventTimestamps: VideoPlayerFragmentShortDate[] = [];

        for (const fragment of fragments) {
            if (!fragment) {
                continue;
            }

            const fragmentTimestamps = this.getShortFragmentData(fragment);

            if (!fragmentTimestamps) {
                continue;
            }

            if (!fragment.tagList?.length) {
                continue;
            }

            for (const tag of fragment.tagList) {
                if (tag[0].indexOf(this.RANGE_TAG) === -1) {
                    continue;
                }

                if (tag[1].indexOf(this.INFO_CLASS) !== -1) {
                    rangeTimestamps.push(fragmentTimestamps);
                } else if (tag[1].indexOf(this.EVENT_CLASS) !== -1) {
                    eventTimestamps.push(fragmentTimestamps);
                }
            }
        }

        return { rangeTimestamps, eventTimestamps };
    }

    /**
     * Extract time from fragment's tagList (without year, month and day)
     * @param {Fragment} fragment hls fragment
     * @returns time range from hls fragment's tag list
     */
    static getShortFragmentData(fragment: Fragment): VideoPlayerFragmentShortDate {
        if (!fragment?.tagList?.length || fragment?.start === null || fragment?.start === undefined) {
            return null;
        }

        const dateStringFromFragment: string = fragment.tagList.find(tag => tag[0] === 'EXT-X-DATERANGE')?.[1];

        if (!dateStringFromFragment) {
            return null;
        }

        return {
            startTimestamp: new Date(dateStringFromFragment.match(this.START_DATE_REGEX)?.[1]).getTime(),
            endTimestamp: new Date(dateStringFromFragment.match(this.END_DATE_REGEX)?.[1]).getTime(),
            startPlayerTime: fragment.start
        };
    }
}
