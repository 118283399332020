import { RdaResponse } from '@app/shared/entities/rd/rda';
import { Actions, ActionTypes } from '../../actions/intercom.actions';

export interface State {
  availableIntercoms: RdaResponse[];
  error: string | null;
  loading: boolean;
  totalCount: number;
}

export const initialState: State = {
  availableIntercoms: [],
  error: null,
  loading: false,
  totalCount: 0
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GetAvaliableIntercoms: {
      return {
        ...state,
        availableIntercoms: [],
        error: null,
        loading: true,
      };
    }

    case ActionTypes.GetAvaliableIntercomsSuccess: {
      return {
        ...state,
        availableIntercoms: action.payload,
        error: null,
        loading: false,
        totalCount: action.totalCount
      };
    }

    case ActionTypes.GetAvaliableIntercomsFailure: {
      return {
        ...state,
        availableIntercoms: [],
        error: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
