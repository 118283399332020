import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { EmergencyAlertResponse } from '../models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({
    providedIn: 'root'
})
export class RDNPCService extends ApiService {
    constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService, handler: HttpBackend) {
        httpClient = new HttpClient(handler);
        super(httpClient, humanErrorTranslationService, environment.RDPNClientUrl, []);
    }

    sendEmergencyAlert(rdaUid: string): Observable<EmergencyAlertResponse> {
        return this.get<EmergencyAlertResponse>(`?adapterId=${rdaUid}&alarm=true`, 0);
    }
}
