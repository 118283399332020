<div class="roles">
  <div
    class="roles-management"
    *ngFor="let config of configs">
      <p-checkbox
        #checkbox
        [binary]="true"
        [formControl]="config.control"
        (onChange)="onRoleAction($event.checked, config)"
      ></p-checkbox>
      <span
        (click)="onRoleAction(checkbox.model, config, true)"
        [pTooltip]="config.description"
      >{{ config.translate }} </span>
  </div>
</div>
