import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CameraUtilsService } from '@app/shared/entities/rd/camera/services';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper/dialog-wrapper-data.model';
import { CameraVideoPopupBody } from './camera-video-popup.model';

@Component({
  selector: 'app-camera-video-popup',
  templateUrl: './camera-video-popup.component.html',
  styleUrls: ['./camera-video-popup.component.scss']
})
export class CameraVideoPopupComponent implements OnInit {
  depthInHours: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<CameraVideoPopupBody, { message: string, type: 'info' | 'error' | 'success' }>,
    private cameraUtilsService: CameraUtilsService
  ) { }

  ngOnInit() {
    this.depthInHours = this.cameraUtilsService.getDepthInHoursFromConfig(this.data.body.configuration);
  }
}
