import { AbonentStat, Account, GetDelegationResponse } from '@app/shared/entities/rd';
import { Abonent, SignUpRequestV2, SignUpResponse } from '@app/shared/models';
import { AccountServiceData, BlockAccountServiceRequest, Chat, ChatContent, DisconnectAccountServiceRequest, UpdateAbonentRequest, VoipTokenInfo } from '@app/views/abonents/models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
    GetAbonentPageInfo = '[Abonents] GetAbonentPageInfo',
    GetAbonentPageInfoSuccess = '[Abonents] GetAbonentPageInfo Success',
    GetAbonentPageInfoFailure = '[Abonents] GetAbonentPageInfo Failure',

    GetAbonentPageVoipTokens = '[Abonents] GetAbonentPageVoipTokens',
    GetAbonentPageVoipTokensSuccess = '[Abonents] GetAbonentPageVoipTokens Success',
    GetAbonentPageVoipTokensFailure = '[Abonents] GetAbonentPageVoipTokens Failure',
    ChangeVoipToken = '[Abonents] ChangeVoipToken',
    ChangeDateDiff = '[Abonents] ChangeDateDiff',

    GetAbonentPageStat = '[Abonents] GetAbonentPageStat',
    GetAbonentPageStatSuccess = '[Abonents] GetAbonentPageStat Success',
    GetAbonentPageStatFailure = '[Abonents] GetAbonentPageStat Failure',

    UpdateAbonentPageInfo = '[Abonents] UpdateAbonentPageInfo',
    UpdateAbonentPageInfoSuccess = '[Abonents] UpdateAbonentPageInfo Success',
    UpdateAbonentPageInfoFailure = '[Abonents] UpdateAbonentPageInfo Failure',

    GetAbonentPageMessages = '[Abonents] GetAbonentPageMessages',
    GetAbonentPageMessagesSuccess = '[Abonents] GetAbonentPageMessages Success',
    GetAbonentPageMessagesFailure = '[Abonents] GetAbonentPageMessages Failure',

    LoadAbonentPageMessages = '[Abonents] LoadAbonentPageMessages',
    LoadAbonentPageMessagesSuccess = '[Abonents] LoadAbonentPageMessages Success',
    LoadAbonentPageMessagesFailure = '[Abonents] LoadAbonentPageMessages Failure',

    SendAbonentPageMessage = '[Abonents] SendAbonentPageMessage',
    SendAbonentPageMessageSuccess = '[Abonents] SendAbonentPageMessage Success',
    SendAbonentPageMessageFailure = '[Abonents] SendAbonentPageMessage Failure',

    GetAbonentPageOwnerDelegations = '[Abonents] GetAbonentPageOwnerDelegations',
    GetAbonentPageOwnerDelegationsSuccess = '[Abonents] GetAbonentPageOwnerDelegations Success',
    GetAbonentPageOwnerDelegationsFailure = '[Abonents] GetAbonentPageOwnerDelegations Failure',

    GetAbonentPageSharedDelegations = '[Abonents] GetAbonentPageSharedDelegations',
    GetAbonentPageSharedDelegationsSuccess = '[Abonents] GetAbonentPageSharedDelegations Success',
    GetAbonentPageSharedDelegationsFailure = '[Abonents] GetAbonentPageSharedDelegations Failure',

    DeleteAbonentPageDelegation = '[Abonents] DeleteAbonentPageDelegation',
    DeleteAbonentPageDelegationSuccess = '[Abonents] DeleteAbonentPageDelegation Success',
    DeleteAbonentPageDelegationFailure = '[Abonents] DeleteAbonentPageDelegation Failure',

    GetAbonentPageSignUps = '[Abonents] GetAbonentPageSignUps',
    GetAbonentPageSignUpsSuccess = '[Abonents] GetAbonentPageSignUps Success',
    GetAbonentPageSignUpsFailure = '[Abonents] GetAbonentPageSignUps Failure',

    GetAbonentPageAccounts = '[Abonents] GetAbonentPageAccounts',
    GetAbonentPageAccountsSuccess = '[Abonents] GetAbonentPageAccounts Success',
    GetAbonentPageAccountsFailure = '[Abonents] GetAbonentPageAccounts Failure',

    UpdateAbonentPageAccount = '[Abonents] UpdateAbonentPageAccount',
    UpdateAbonentPageAccountSuccess = '[Abonents] UpdateAbonentPageAccount Success',
    UpdateAbonentPageAccountFailure = '[Abonents] UpdateAbonentPageAccount Failure',

    DeleteAbonentPageAccount = '[Abonents] DeleteAbonentPageAccount',
    DeleteAbonentPageAccountSuccess = '[Abonents] DeleteAbonentPageAccount Success',
    DeleteAbonentPageAccountFailure = '[Abonents] DeleteAbonentPageAccount Failure',

    BlockAbonentPageAccount = '[Abonents] BlockAbonentPageAccount',
    BlockAbonentPageAccountSuccess = '[Abonents] BlockAbonentPageAccount Success',
    BlockAbonentPageAccountFailure = '[Abonents] BlockAbonentPageAccount Failure',

    UnblockAbonentPageAccount = '[Abonents] UnblockAbonentPageAccount',
    UnblockAbonentPageAccountSuccess = '[Abonents] UnblockAbonentPageAccount Success',
    UnblockAbonentPageAccountFailure = '[Abonents] UnblockAbonentPageAccount Failure',

    GetAbonentPageAccountServices = '[Abonents] GetAbonentPageAccountServices',
    GetAbonentPageAccountServicesSuccess = '[Abonents] GetAbonentPageAccountServices Success',
    GetAbonentPageAccountServicesFailure = '[Abonents] GetAbonentPageAccountServices Failure',

    DisconnectAbonentPageService = '[Abonents] DisconnectAbonentPageService',
    DisconnectAbonentPageServiceSuccess = '[Abonents] DisconnectAbonentPageService Success',
    DisconnectAbonentPageServiceFailure = '[Abonents] DisconnectAbonentPageService Failure',

    BlockAbonentPageService = '[Abonents] BlockAbonentPageService',
    BlockAbonentPageServiceSuccess = '[Abonents] BlockAbonentPageService Success',
    BlockAbonentPageServiceFailure = '[Abonents] BlockAbonentPageService Failure',

    UnblockAbonentPageService = '[Abonents] UnblockAbonentPageService',
    UnblockAbonentPageServiceSuccess = '[Abonents] UnblockAbonentPageService Success',
    UnblockAbonentPageServiceFailure = '[Abonents] UnblockAbonentPageService Failure',

    UpdateAbonentPageSignUp = '[Abonents] UpdateAbonentPageSignUp',
    UpdateAbonentPageSignUpSuccess = '[Abonents] UpdateAbonentPageSignUpSuccess Success',
    UpdateAbonentPageSignUpFailure = '[Abonents] UpdateAbonentPageSignUpFailure Failure',
}

export class GetAbonentPageInfo implements Action {
    readonly type = ActionTypes.GetAbonentPageInfo;

    constructor(public abonentId: number) { }
}

export class GetAbonentPageInfoSuccess implements Action {
    readonly type = ActionTypes.GetAbonentPageInfoSuccess;

    constructor(public response: Abonent) { }
}

export class GetAbonentPageInfoFailure implements Action {
    readonly type = ActionTypes.GetAbonentPageInfoFailure;

    constructor() { }
}

export class GetAbonentPageVoipTokens implements Action {
    readonly type = ActionTypes.GetAbonentPageVoipTokens;

    constructor(public abonentId: number) { }
}

export class GetAbonentPageVoipTokensSuccess implements Action {
    readonly type = ActionTypes.GetAbonentPageVoipTokensSuccess;

    constructor(public response: VoipTokenInfo[]) { }
}

export class GetAbonentPageVoipTokensFailure implements Action {
    readonly type = ActionTypes.GetAbonentPageVoipTokensFailure;

    constructor() { }
}

export class ChangeVoipToken implements Action {
    readonly type = ActionTypes.ChangeVoipToken;

    constructor(
        public voipToken: VoipTokenInfo,
        public abonentId: number
        ) { }
}

export class ChangeDateDiff implements Action {
    readonly type = ActionTypes.ChangeDateDiff;

    constructor(
        public dateDiff: number,
        public abonentId: number
    ) { }
}

export class GetAbonentPageStat implements Action {
    readonly type = ActionTypes.GetAbonentPageStat;

    constructor(public abonentId: number, public voipToken: string, public dateStartDiff: number) { }
}

export class GetAbonentPageStatSuccess implements Action {
    readonly type = ActionTypes.GetAbonentPageStatSuccess;

    constructor(public response: AbonentStat) { }
}

export class GetAbonentPageStatFailure implements Action {
    readonly type = ActionTypes.GetAbonentPageStatFailure;

    constructor() { }
}

export class UpdateAbonentPageInfo implements Action {
    readonly type = ActionTypes.UpdateAbonentPageInfo;

    constructor(public abonentId: number, public request: UpdateAbonentRequest) { }
}

export class UpdateAbonentPageInfoSuccess implements Action {
    readonly type = ActionTypes.UpdateAbonentPageInfoSuccess;

    constructor(public request: UpdateAbonentRequest) { }
}

export class UpdateAbonentPageInfoFailure implements Action {
    readonly type = ActionTypes.UpdateAbonentPageInfoFailure;

    constructor() { }
}

export class GetAbonentPageMessages implements Action {
    readonly type = ActionTypes.GetAbonentPageMessages;

    constructor(public id: number, public channel: string, public size?: number, public page?: number) { }
}

export class GetAbonentPageMessagesSuccess implements Action {
    readonly type = ActionTypes.GetAbonentPageMessagesSuccess;

    constructor(public chat: Chat) { }
}

export class GetAbonentPageMessagesFailure implements Action {
    readonly type = ActionTypes.GetAbonentPageMessagesFailure;

    constructor() { }
}

export class LoadAbonentPageMessages implements Action {
    readonly type = ActionTypes.LoadAbonentPageMessages;

    constructor(public id: number, public channel: string, public size?: number, public page?: number) { }
}

export class LoadAbonentPageMessagesSuccess implements Action {
    readonly type = ActionTypes.LoadAbonentPageMessagesSuccess;

    constructor(public chat: Chat) { }
}

export class LoadAbonentPageMessagesFailure implements Action {
    readonly type = ActionTypes.LoadAbonentPageMessagesFailure;

    constructor() { }
}

export class SendAbonentPageMessage implements Action {
    readonly type = ActionTypes.SendAbonentPageMessage;

    constructor(public abonentId: number, public message: string) { }
}

export class SendAbonentPageMessageSuccess implements Action {
    readonly type = ActionTypes.SendAbonentPageMessageSuccess;

    constructor(public response: ChatContent) { }
}

export class SendAbonentPageMessageFailure implements Action {
    readonly type = ActionTypes.SendAbonentPageMessageFailure;

    constructor() { }
}

export class GetAbonentPageOwnerDelegations implements Action {
    readonly type = ActionTypes.GetAbonentPageOwnerDelegations;

    constructor(public fromAbonentId: number) { }
}

export class GetAbonentPageOwnerDelegationsSuccess implements Action {
    readonly type = ActionTypes.GetAbonentPageOwnerDelegationsSuccess;

    constructor(public response: GetDelegationResponse[]) { }
}

export class GetAbonentPageOwnerDelegationsFailure implements Action {
    readonly type = ActionTypes.GetAbonentPageOwnerDelegationsFailure;

    constructor() { }
}

export class GetAbonentPageSharedDelegations implements Action {
    readonly type = ActionTypes.GetAbonentPageSharedDelegations;

    constructor(public toAbonentId: number) { }
}

export class GetAbonentPageSharedDelegationsSuccess implements Action {
    readonly type = ActionTypes.GetAbonentPageSharedDelegationsSuccess;

    constructor(public response: GetDelegationResponse[]) { }
}

export class GetAbonentPageSharedDelegationsFailure implements Action {
    readonly type = ActionTypes.GetAbonentPageSharedDelegationsFailure;

    constructor() { }
}

export class DeleteAbonentPageDelegation implements Action {
    readonly type = ActionTypes.DeleteAbonentPageDelegation;

    constructor(public delegationId: number) { }
}

export class DeleteAbonentPageDelegationSuccess implements Action {
    readonly type = ActionTypes.DeleteAbonentPageDelegationSuccess;

    constructor(public delegationId: number) { }
}

export class DeleteAbonentPageDelegationFailure implements Action {
    readonly type = ActionTypes.DeleteAbonentPageDelegationFailure;

    constructor() { }
}

export class GetAbonentPageSignUps implements Action {
    readonly type = ActionTypes.GetAbonentPageSignUps;

    constructor(public abonentId: number) { }
}

export class GetAbonentPageSignUpsSuccess implements Action {
    readonly type = ActionTypes.GetAbonentPageSignUpsSuccess;

    constructor(public response: SignUpResponse[]) { }
}

export class GetAbonentPageSignUpsFailure implements Action {
    readonly type = ActionTypes.GetAbonentPageSignUpsFailure;

    constructor() { }
}

export class GetAbonentPageAccounts implements Action {
    readonly type = ActionTypes.GetAbonentPageAccounts;

    constructor(public abonentId: number) { }
}

export class GetAbonentPageAccountsSuccess implements Action {
    readonly type = ActionTypes.GetAbonentPageAccountsSuccess;

    constructor(public response: Account[]) { }
}

export class GetAbonentPageAccountsFailure implements Action {
    readonly type = ActionTypes.GetAbonentPageAccountsFailure;

    constructor() { }
}

export class UpdateAbonentPageAccount implements Action {
    readonly type = ActionTypes.UpdateAbonentPageAccount;

    constructor(public accountId: number, public accountNumber: string) { }
}

export class UpdateAbonentPageAccountSuccess implements Action {
    readonly type = ActionTypes.UpdateAbonentPageAccountSuccess;

    constructor(public accountId: number, public accountNumber: string) { }
}

export class UpdateAbonentPageAccountFailure implements Action {
    readonly type = ActionTypes.UpdateAbonentPageAccountFailure;

    constructor() { }
}

export class DeleteAbonentPageAccount implements Action {
    readonly type = ActionTypes.DeleteAbonentPageAccount;

    constructor(public accountId: number) { }
}

export class DeleteAbonentPageAccountSuccess implements Action {
    readonly type = ActionTypes.DeleteAbonentPageAccountSuccess;

    constructor(public accountId: number) { }
}

export class DeleteAbonentPageAccountFailure implements Action {
    readonly type = ActionTypes.DeleteAbonentPageAccountFailure;

    constructor() { }
}

export class BlockAbonentPageAccount implements Action {
    readonly type = ActionTypes.BlockAbonentPageAccount;

    constructor(public accountId: number) { }
}

export class BlockAbonentPageAccountSuccess implements Action {
    readonly type = ActionTypes.BlockAbonentPageAccountSuccess;

    constructor(public accountId: number) { }
}

export class BlockAbonentPageAccountFailure implements Action {
    readonly type = ActionTypes.BlockAbonentPageAccountFailure;

    constructor() { }
}

export class UnblockAbonentPageAccount implements Action {
    readonly type = ActionTypes.UnblockAbonentPageAccount;

    constructor(public accountId: number) { }
}

export class UnblockAbonentPageAccountSuccess implements Action {
    readonly type = ActionTypes.UnblockAbonentPageAccountSuccess;

    constructor(public accountId: number) { }
}

export class UnblockAbonentPageAccountFailure implements Action {
    readonly type = ActionTypes.UnblockAbonentPageAccountFailure;

    constructor() { }
}

export class GetAbonentPageAccountServices implements Action {
    readonly type = ActionTypes.GetAbonentPageAccountServices;

    constructor(public accountId: number) { }
}

export class GetAbonentPageAccountServicesSuccess implements Action {
    readonly type = ActionTypes.GetAbonentPageAccountServicesSuccess;

    constructor(public response: AccountServiceData[]) { }
}

export class GetAbonentPageAccountServicesFailure implements Action {
    readonly type = ActionTypes.GetAbonentPageAccountServicesFailure;

    constructor() { }
}

export class DisconnectAbonentPageService implements Action {
    readonly type = ActionTypes.DisconnectAbonentPageService;

    constructor(
        public accountId: number,
        public serviceId: number,
        public request: DisconnectAccountServiceRequest
    ) { }
}

export class DisconnectAbonentPageServiceSuccess implements Action {
    readonly type = ActionTypes.DisconnectAbonentPageServiceSuccess;

    constructor(public serviceId: number, public flatId: number) { }
}

export class DisconnectAbonentPageServiceFailure implements Action {
    readonly type = ActionTypes.DisconnectAbonentPageServiceFailure;

    constructor() { }
}

export class BlockAbonentPageService implements Action {
    readonly type = ActionTypes.BlockAbonentPageService;

    constructor(
        public accountId: number,
        public serviceId: number,
        public request: BlockAccountServiceRequest
    ) { }
}

export class BlockAbonentPageServiceSuccess implements Action {
    readonly type = ActionTypes.BlockAbonentPageServiceSuccess;

    constructor(public serviceId: number, public flatId: number) { }
}

export class BlockAbonentPageServiceFailure implements Action {
    readonly type = ActionTypes.BlockAbonentPageServiceFailure;

    constructor() { }
}

export class UnblockAbonentPageService implements Action {
    readonly type = ActionTypes.UnblockAbonentPageService;

    constructor(
        public accountId: number,
        public serviceId: number,
        public request: BlockAccountServiceRequest
    ) { }
}

export class UnblockAbonentPageServiceSuccess implements Action {
    readonly type = ActionTypes.UnblockAbonentPageServiceSuccess;

    constructor(public serviceId: number, public flatId: number) { }
}

export class UnblockAbonentPageServiceFailure implements Action {
    readonly type = ActionTypes.UnblockAbonentPageServiceFailure;

    constructor() { }
}

export class UpdateAbonentPageSignUp implements Action {
    readonly type = ActionTypes.UpdateAbonentPageSignUp;

    constructor(public id: number, public request: SignUpRequestV2) { }
}

export class UpdateAbonentPageSignUpSuccess implements Action {
    readonly type = ActionTypes.UpdateAbonentPageSignUpSuccess;

    constructor(public id: number, public request: SignUpRequestV2) { }
}

export class UpdateAbonentPageSignUpFailure implements Action {
    readonly type = ActionTypes.UpdateAbonentPageSignUpFailure;

    constructor() { }
}

export type Actions =
    | GetAbonentPageInfo
    | GetAbonentPageInfoSuccess
    | GetAbonentPageInfoFailure

    | GetAbonentPageVoipTokens
    | GetAbonentPageVoipTokensSuccess
    | GetAbonentPageVoipTokensFailure
    | ChangeVoipToken
    | ChangeDateDiff

    | GetAbonentPageStat
    | GetAbonentPageStatSuccess
    | GetAbonentPageStatFailure

    | UpdateAbonentPageInfo
    | UpdateAbonentPageInfoSuccess
    | UpdateAbonentPageInfoFailure

    | GetAbonentPageMessages
    | GetAbonentPageMessagesSuccess
    | GetAbonentPageMessagesFailure

    | SendAbonentPageMessage
    | SendAbonentPageMessageSuccess
    | SendAbonentPageMessageFailure

    | LoadAbonentPageMessages
    | LoadAbonentPageMessagesSuccess
    | LoadAbonentPageMessagesFailure

    | GetAbonentPageOwnerDelegations
    | GetAbonentPageOwnerDelegationsSuccess
    | GetAbonentPageOwnerDelegationsFailure

    | GetAbonentPageSharedDelegations
    | GetAbonentPageSharedDelegationsSuccess
    | GetAbonentPageSharedDelegationsFailure

    | DeleteAbonentPageDelegation
    | DeleteAbonentPageDelegationSuccess
    | DeleteAbonentPageDelegationFailure

    | GetAbonentPageSignUps
    | GetAbonentPageSignUpsSuccess
    | GetAbonentPageSignUpsFailure

    | GetAbonentPageAccounts
    | GetAbonentPageAccountsSuccess
    | GetAbonentPageAccountsFailure

    | UpdateAbonentPageAccount
    | UpdateAbonentPageAccountSuccess
    | UpdateAbonentPageAccountFailure

    | DeleteAbonentPageAccount
    | DeleteAbonentPageAccountSuccess
    | DeleteAbonentPageAccountFailure

    | BlockAbonentPageAccount
    | BlockAbonentPageAccountSuccess
    | BlockAbonentPageAccountFailure

    | UnblockAbonentPageAccount
    | UnblockAbonentPageAccountSuccess
    | UnblockAbonentPageAccountFailure

    | GetAbonentPageAccountServices
    | GetAbonentPageAccountServicesSuccess
    | GetAbonentPageAccountServicesFailure

    | DisconnectAbonentPageService
    | DisconnectAbonentPageServiceSuccess
    | DisconnectAbonentPageServiceFailure

    | BlockAbonentPageService
    | BlockAbonentPageServiceSuccess
    | BlockAbonentPageServiceFailure

    | UnblockAbonentPageService
    | UnblockAbonentPageServiceSuccess
    | UnblockAbonentPageServiceFailure

    | UpdateAbonentPageSignUp
    | UpdateAbonentPageSignUpSuccess
    | UpdateAbonentPageSignUpFailure;
