import { Address, LocationResponse, Rdva } from '@app/shared/models';

export class Camera {
  audio: boolean;
  address: Address;
  configuration: string;
  password: string;
  rdaUid: string;
  rdvaId: number;
  rdva: Rdva;
  rdvaUri?: string;
  id: number;
  uri: string;
  user: string;
  active: boolean;
  location: LocationResponse;
  private: boolean;
  isPrivate?: boolean;
  bitrate: number;
  segmentLength: number;
  segmentsCount: number;
  serviceId?: number;
  intercomId?: number;
  onvifPort?: number;
  deviceId?: number;
  model?: {
    id: number,
    name: string
  };
  macAddress?: string;
  connectedToRda: boolean;
  services?: Array<{ type: string; address: string }>;
  releId?: number;

  constructor(values?: Partial<Camera>) {
    Object.assign(this, values);
  }
}

