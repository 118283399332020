<mat-card class="page-template">
  <mat-card-content class="page-template-content">
    <div class="page-template-content-search-actions-block">
      <mat-form-field
        appearance="outline"
        class="none-padding none-margin">
        <mat-label>
          {{ 'shared.ui.paginated_page_wrapper.search' | translate }}
        </mat-label>
        <input
          matInput
          [placeholder]="filterInputPlaceholder"
          [formControl]="filterInput"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false" />
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>

      <ng-content select="[page-template-actions]"></ng-content>
    </div>

    <ng-content select="[page-template-selection]"></ng-content>

    <hr class="page-template-content__divider" />

    <ng-content select="[page-template-content]"></ng-content>

    <mat-paginator
      [length]="paginatorData?.totalElements"
      [pageSize]="paginatorData?.size"
      [pageIndex]="paginatorData?.pageable?.pageNumber"
      [hidePageSize]="true"
      [showFirstLastButtons]="true">
    </mat-paginator>
  </mat-card-content>
</mat-card>
