import { Component, Inject, OnInit } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA
} from '@angular/material/bottom-sheet';
import { ServicesTypes } from '@app/shared/models';
import { ServiceFacade } from '@app/views/services';
import { ServiceFlatsFilters } from '../../../containers';

@Component({
  selector: 'app-flat-filter-bottom-sheet',
  templateUrl: './flat-filter-bottom-sheet.component.html',
  styleUrls: ['./flat-filter-bottom-sheet.component.scss']
})
export class FlatFilterBottomSheetComponent {
  public services = this.data.services;
  public flatsFilters = this.data.flatsFilters;
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: {
      services: ServicesTypes[];
      flatsFilters: ServiceFlatsFilters;
    },
    private _bottomSheetRef: MatBottomSheetRef<FlatFilterBottomSheetComponent>,
    private serviceFacade: ServiceFacade
  ) {}

  public onClose(): void {
    this._bottomSheetRef.dismiss();
  }

  public onReset(): void {
    this.flatsFilters = Object.assign(this.flatsFilters, {
      activeAbonent: false,
      activeServices: [],
      blockedAbonent: false,
      emptyFlat: false,
      blockedServices: [],
      phone: '',
      stoppedServices: []
    });

    this.serviceFacade.filterFlats(this.flatsFilters);
  }
}
