<app-dialog-wrapper
  [componentName]="data.componentName"
  [title]="data.title"
>
  <app-rda-warnings-content
    [serviceName]="data.body.serviceName"
    [rda]="data.body.rda"
    (close)="onClose()"
  ></app-rda-warnings-content>
</app-dialog-wrapper>
