<div class="component">
  <div
    class="component-item"
    [class.component-item--without-controls]="controlsHidden"
    *ngFor="let intercom of intercoms; let idx = index"
  >
    <div class="component-item-actions-block" *ngIf="!controlsHidden">
      <button
        mat-fab
        color="primary-inverse"
        class="component-item-actions-block__button"
        (click)="onEdit(idx)"
      >
        <mat-icon>settings</mat-icon>
      </button>

      <button
        mat-fab
        color="error"
        class="component-item-actions-block__button"
        (click)="onDelete(idx)"
      >
        <mat-icon>remove</mat-icon>
      </button>
    </div>

    <div class="component-item-info">
      <p class="component-item-info__text" *ngIf="intercom.uid">
        {{ 'services.popups.intercoms.list.content.uid' | translate }}: {{ intercom.uid }}
      </p>

      <p class="component-item-info__text">
        {{ 'services.popups.intercoms.list.content.intercom_type' | translate }}: {{ intercom.intercomType.name }}
      </p>
    </div>
  </div>
</div>
