import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { FlatStatusComponent } from './flat-status.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, MaterialModule, RouterModule, SharedPipesModule, TranslateModule],
  declarations: [FlatStatusComponent],
  exports: [FlatStatusComponent]
})
export class FlatStatusModule {}
