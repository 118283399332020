import { Injectable } from '@angular/core';
import { UserRoleType } from '@app/core/models';
import { LocalStorageGeneralKey, LocalStorageHelper } from '@app/shared/entities/common';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    constructor(
        private jwtHelper: JwtHelperService
    ) { }

    getAuthorities(): UserRoleType[] {
        const token: string = LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN);

        if (!token) {
            return null;
        }

        return this.jwtHelper.decodeToken(token)?.authorities ?? [];
    }

    getCompanyId(): number {
        const token: string = LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN);

        if (!token) {
            return null;
        }

        return this.jwtHelper.decodeToken(token)?.company ?? null;
    }
}
