<app-dialog-wrapper
  [componentName]="wrapperData.componentName"
  [title]="wrapperData.title"
  [hasBackButton]="true"
  (back)="onClose()"
>
  <app-camera-already-exists
    [wrapperData]="wrapperData"
    [connectionResponse]="connectionResponse$ | async"
  ></app-camera-already-exists>
</app-dialog-wrapper>
