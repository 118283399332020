<div class="popup-warning">
  <div class="popup-warning-img">
    <img src="assets/icons/ic-warning-delegations.svg" alt="error">
  </div>
  <div class="popup-warning-description">
    <span>
      Вы уверены, что хотите удалить совладельца? У него отключатся все услуги и это действие необратимо.
    </span>
  </div>
  <div class="popup-warning-actions">
    <p-button label="Отключить" styleClass="p-button-text" (click)="onDisconnection()"></p-button>
  </div>
</div>
