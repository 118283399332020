import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({ name: 'types' })
export class TypesPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) {}

  transform(value: number) {
    switch (value) {
      case 1:
        return this.translate.instant('enum.type.door');
      case 2:
        return this.translate.instant('enum.type.barrier');
      case 3:
        return this.translate.instant('enum.type.gates');
      case 4:
        return this.translate.instant('enum.type.gate');
      default:
        return '';
    }
  }
}
