import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DeleteCameraContentModule } from '@app/shared/components';
import { DialogWrapperModule } from '@app/shared/ui';
import { ServiceCameraDeletePopupComponent } from './service-camera-delete-popup.component';

@NgModule({
    imports: [
        CommonModule,
        DialogWrapperModule,
        DeleteCameraContentModule
    ],
    declarations: [
        ServiceCameraDeletePopupComponent,
    ],
    exports: [
        ServiceCameraDeletePopupComponent
    ]
})
export class ServiceCameraDeletePopupModule { }
