import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { environment } from 'environments/environment';
import { CompanyReportRequest } from '../models/company-report-request.model';
import { LicenseeReportRequest } from '../models/licensee-report-request.model';
import { PenetrationReportRequest } from '../models/penetration-report-request.model';
import { SignUpsReportRequest } from '../models/sign-ups-report-request.model';
import { RdeaDate } from '@app/shared/entities';
import { Observable } from 'rxjs';
import { PaymentInfoResponse } from '@app/shared/entities/rd';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class ReportsApiService extends ApiService {

  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.companiesReportsApiUrl, ['v1']);
  }

  getUsageStatisticsReport(request?: LicenseeReportRequest) {
    let params = new HttpParams();

    if (request?.reportDate) { params = params.append('tarifficationDate', request.reportDate); }

    return this.getFile('/tariffication/services/xlsx', 1, { params });
  }

  getLicenseeReport(request?: LicenseeReportRequest) {
    let params = new HttpParams();

    if (request?.reportDate) { params = params.append('reportDate', request.reportDate); }

    return this.getFile('/reports/licensee', 1, { params });
  }

  getCompanyReport(request?: CompanyReportRequest) {
    let params = new HttpParams();

    if (request?.startReportDate) { params = params.append('startReportDate', request.startReportDate); }
    if (request?.finishReportDate) { params = params.append('finishReportDate', request.finishReportDate); }

    return this.getFile('/reports/company/xlsx', 1, { params });
  }

  getSignUpsReport(request?: SignUpsReportRequest) {
    let params = new HttpParams();

    if (request?.fromDate) { params = params.append('fromDate', request.fromDate); }
    if (request?.toDate) { params = params.append('toDate', request.toDate); }

    return this.getFile('/reports/sign_ups', 1, { params });
  }

  getPenetrationReport(request?: PenetrationReportRequest) {
    let params = new HttpParams();

    if (request?.firstDate) { params = params.append('firstDate', request.firstDate); }
    if (request?.secondDate) { params = params.append('secondDate', request.secondDate); }
    if (request?.thirdDate) { params = params.append('thirdDate', request.thirdDate); }
    if (request?.servicesLimitDate) { params = params.append('servicesLimitDate', request.servicesLimitDate); }

    return this.getFile('/reports/penetration', 1, { params });
  }

  getPaymentInfo(date: RdeaDate): Observable<PaymentInfoResponse> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        date: new RdeaDate(Date.parse(date.toString()) - RdeaDate.dayInMilliseconds).toDateTimeString({
          time: false, timeWithoutOneSecond: true
        })
      }
    });

    return this.get<PaymentInfoResponse>(`/reports/company/payment_info`, 1, {
      params
    });
  }
}
