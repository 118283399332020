import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { SUPPORT_LINK } from '@app/shared/helpers';
import { TranslationTuningResponse } from '@app/shared/models';
import { RdaTranslationsFormMode } from './rda-translations-form-mode.type';
import {Router} from '@angular/router';

@Component({
  selector: 'app-rda-translations-form',
  templateUrl: './rda-translations-form.component.html',
  styleUrls: ['./rda-translations-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RdaTranslationsFormComponent {
  readonly SUPPORT_LINK: string = SUPPORT_LINK;

  @Input() translation: number;
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  @Input() translationTunings: TranslationTuningResponse[];
  form: UntypedFormGroup = this.initForm();
  mode: RdaTranslationsFormMode = 'simple';

  @Output() private addTranslationTuning: EventEmitter<{ fromNum: number, toNum: number, translation: number }> = new EventEmitter();
  @Output() private saveTranslation: EventEmitter<{ translation: number }> = new EventEmitter();
  @Output() private deleteTranslationTuning: EventEmitter<{ id: number }> = new EventEmitter();
  private _loading: boolean;

  constructor(
    public resolution: ResolutionService,
    private router: Router
  ) { }

  get loading(): boolean {
    return this._loading;
  }

  onChangeMode() {
    this.mode = this.mode === 'simple' ? 'advanced' : 'simple';
  }

  onSaveTranslation() {
    this.saveTranslation.emit({ translation: this.translation });
  }

  onAddAdvancedTranslation() {
    if (this.form.valid) {
      this.addTranslationTuning.emit(this.form.getRawValue());
      this.form.setValue({ fromNum: null, toNum: null, translation: null, });
    }
  }

  onDeleteTranslationTuning(id: number) {
    this.deleteTranslationTuning.emit({ id });
  }

  public onSupportNavigate(): void {
    this.router.navigate([`/support`]);
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      fromNum: new UntypedFormControl(null, Validators.required),
      toNum: new UntypedFormControl(null, Validators.required),
      translation: new UntypedFormControl(null, Validators.required),
    });
  }
}
