<app-dialog-wrapper
  [componentName]="data.componentName"
  [progress]="(popupService.state$ | async) === 'loading'"
  [title]="data.title"
>
  <app-delete-entrance-content
    [loading]="(popupService.state$ | async) === 'loading'"
    [entranceId]="data.body.entranceId"
    (deleteEntrance)="onDelete()"
  ></app-delete-entrance-content>
</app-dialog-wrapper>