import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

const selectShieldModelsState = createSelector(
  intercomModuleSelector,
  (state: IntercomModuleState) => state.getShieldModels
);
export const getShieldModels = createSelector(
  selectShieldModelsState,
  state => state
);
export const getShieldModelsSuccess = createSelector(
  selectShieldModelsState,
  state => state.models
);
export const getShieldModelsLoading = createSelector(
  selectShieldModelsState,
  state => state.loading
);
export const getShieldModelsError = createSelector(
  selectShieldModelsState,
  state => state.error
);
