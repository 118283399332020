<mat-card class="dashboard-reports">
  <mat-card-header>
    <mat-card-title>
      {{ 'dashboard.reports.title' | translate }}
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="dashboard-reports-content">

    <button color="primary" mat-raised-button (click)="onPenetrationReport()">
      {{ 'dashboard.reports.button.penetration' | translate }}
    </button>

    <button
      color="primary"
      mat-raised-button
      (click)="onCompanyReport()"
      *appRdeaCanAccess="permissionsTreeKey.BILLS"
    >
      {{ 'dashboard.reports.button.company' | translate }}
    </button>

    <button
      color="primary"
      mat-raised-button
      (click)="onCompanyPeriodicReport()"
      *appRdeaCanAccess="permissionsTreeKey.BILLS"
    >
      {{ 'dashboard.reports.button.company_periodic' | translate }}
    </button>

    <ng-container *appRdeaCanAccess="permissionsTreeKey.BILLS">
      <button
        color="primary"
        mat-raised-button
        (click)="onLicenseeReport()"
        *ngIf="(!offerLicenseAgreement || rosDomofonCompany)"
      >
        {{ 'dashboard.reports.button.licensee' | translate }}
      </button>

      <button
        color="primary"
        mat-raised-button
        (click)="onUsageStatisticsReport()"
        *ngIf="offerLicenseAgreement || rosDomofonCompany"
      >
        {{ 'dashboard.reports.button.statistics' | translate }}
      </button>
    </ng-container>

    <ng-container *appRdeaCanAccess="permissionsTreeKey.ABONENTS_SIGN_UPS">
      <button color="primary" mat-raised-button (click)="onSignUpsReport()">
        {{ 'dashboard.reports.button.signups' | translate }}
      </button>
    </ng-container>
  </mat-card-content>
</mat-card>
