export enum ResourcePath {
    LOGO_NAVBAR = '/assets/logo/logo-navbar',
    LOGO_VIVO_NAVBAR = '/assets/logo/logo-vivo.main',
    LOGO_KZ_NAVBAR = '/assets/logo/logo-ic-kz',
    LOGO_MAIN = '/assets/logo/logo-main',
    CHECK_LG = '/assets/images/check-lg',
    ERROR_LG = '/assets/images/error-lg',
    INFO_LG = '/assets/images/info-lg',
    WARNING_LG = '/assets/images/warning-lg',
    CREATE_SOFTWARE_INTERCOM_BTN = '/assets/buttons/btn-create-intercom',
    CREATE_VIDEO_SURVEILLANCE_BTN = '/assets/buttons/btn-create-video',
    CREATE_GATE_BTN = '/assets/buttons/btn-create-gate',
    ENTRANCE_ERROR = '/assets/images/entrance-error',
}
