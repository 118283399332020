<mat-select
  class="language-switch"
  name="currentLang"
  [(ngModel)]="currentLang"
  (selectionChange)="setLanguage()"
>
  <mat-option *ngFor="let lang of getLanguages()" [value]="lang">
    {{ 'language.' + lang | translate }}
  </mat-option>
</mat-select>
