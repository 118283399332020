import { Abonent, Address } from '@app/shared/models';
import { GetDelegationResponse } from '../../delegations';

export interface ServiceEntranceFlatResponse {
    accountId: number;
    adapters: {
        rdaUid: string;
        intercomIndex: number;
        cameraIds: number[];
    };
    address: Address;
    blocked: boolean;
    cameraId: number;
    hardwareIntercomId: number;
    id: number;
    owner: Abonent & { delegations: GetDelegationResponse[] };
    rdaUid: string;
    softwareIntercomId: number;
    translated: number;
    virtual: boolean;
}
