import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LocalStorageGeneralKey, LocalStorageHelper } from '@app/shared/entities/common';
import { YandexApiService, YandexHelper, YandexMapCountry } from '@app/shared/entities/integrations';
import { AddressInfo } from '@app/shared/models';
import { YandexMaps } from '@app/shared/models/maps/yandex-maps.model';
import { environment } from 'environments/environment';
import ymaps from 'ymaps';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-yandex-map-form',
  templateUrl: './yandex-map-form.component.html',
  styleUrls: ['./yandex-map-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YandexMapFormComponent implements OnInit, OnDestroy {
  @Input() displayed = true;
  @Input() set rawAddress(rawAddress: string) {
    this._rawAddress = rawAddress;
    this.initRawAddress();
  }
  @Input() clickable: boolean;

  @Output() private submitted: EventEmitter<{ addressInfo: AddressInfo, rawAddress: string, entrance: string }> = new EventEmitter();
  private _rawAddress: string;
  private map: YandexMaps.Map;
  private maps: typeof YandexMaps;
  private myPlacemark: YandexMaps.Placemark;

  constructor(
    private yandexApiService: YandexApiService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    const country = LocalStorageHelper.getItem(LocalStorageGeneralKey.COUNTRY);
    const center = YandexHelper.capitalsCoords[country?.shortName ?? YandexMapCountry.RU];

    ymaps.load(`https://api-maps.yandex.ru/2.1/?apikey=${environment.yandexApiKey}&lang=ru_RU`)
      .then((maps: typeof YandexMaps) => {
        this.maps = maps;

        if (this.rawAddress) {
          this.getMapLocationByAddress(this.rawAddress, (geoObjects: any) => {
            const xy = geoObjects.get(0).geometry.getCoordinates();
            this.map = new maps.Map('map', { center: [xy[0], xy[1]], zoom: 16, controls: ['zoomControl', 'fullscreenControl'] });
          });
        } else {
          this.map = new maps.Map('map', { center, zoom: 7, controls: ['zoomControl', 'fullscreenControl'] });
        }

        if (this.clickable) {
          this.map.events.add('click', (event: YandexMaps.Event) => {
            const coords = event.get('coords');

            if (this.myPlacemark) {
              this.map.geoObjects.removeAll();
              this.myPlacemark.geometry['setCoordinates'](coords);
              this.map.geoObjects.add(this.myPlacemark);
            } else {
              this.myPlacemark = this.createPlacemark(coords);
              this.map.geoObjects.add(this.myPlacemark);
            }
            this.getMapLocationByCoords(coords);
          });
        }
      });
  }

  ngOnDestroy() {
    this.map?.destroy();
  }

  get rawAddress(): string {
    return this._rawAddress;
  }

  private createPlacemark(coords) {
    return new this.maps.Placemark(coords, {
      iconCaption: this.translate.instant('shared.entrances.edit.yandex.map.form.search')
    }, { draggable: true });
  }

  private getMapLocationByCoords(coords) {
    this.maps.geocode(coords).then(res => {
      const geoObject = res['geoObjects'].get(0);
      const address = geoObject.properties.get('metaDataProperty').GeocoderMetaData.Address.Components;
      const rawAddress = geoObject.getAddressLine();
      this.myPlacemark.properties.set({ iconCaption: rawAddress, balloonContent: rawAddress }, rawAddress);
      const { addressInfo, entrance } = this.yandexApiService.getAddressInfo(address);
      this.submitted.emit({ addressInfo, rawAddress, entrance });
    });
  }

  private getMapLocationByAddress(address: any, cb: (response: object) => void) {
    this.maps.geocode(address)
      .then(resp => cb(resp['geoObjects']))
      .catch(error => new Error(error));
  }

  private initRawAddress() {
    if (!this.rawAddress) {
      return;
    }

    if (this.map) {
      this.getMapLocationByAddress(this.rawAddress, (geoObjects: any) => {
        this.map.geoObjects.removeAll();
        this.map.geoObjects.add(geoObjects);
        const xy = geoObjects.get(0).geometry.getCoordinates();
        this.map.setCenter([xy[0], xy[1]], 16);
      });
    }
  }
}
