import { LocationResponse } from '@app/shared/models';
import { IntercomPanelConnectRequest, IntercomPanelResponse, IntercomPanelUpdateRequest } from '@app/shared/entities/rd';
import { Action } from '@ngrx/store';
import { ServicesActionTypes } from './services-action-types.actions';

export class AddServiceIntercomPanel implements Action {
    readonly type = ServicesActionTypes.AddServiceIntercomPanel;

    constructor(public request: IntercomPanelConnectRequest) { }
}

export class AddServiceIntercomPanelSuccess implements Action {
    readonly type = ServicesActionTypes.AddServiceIntercomPanelSuccess;

    constructor(public request: IntercomPanelConnectRequest) { }
}

export class AddServiceIntercomPanelFailure implements Action {
    readonly type = ServicesActionTypes.AddServiceIntercomPanelFailure;

    constructor() { }
}

export class UpdateServiceIntercomPanel implements Action {
    readonly type = ServicesActionTypes.UpdateServiceIntercomPanel;

    constructor(
        public intercomPanelId: number,
        public rdaId: number,
        public request: Partial<IntercomPanelUpdateRequest>
    ) { }
}

export class UpdateServiceIntercomPanelSuccess implements Action {
    readonly type = ServicesActionTypes.UpdateServiceIntercomPanelSuccess;

    constructor(
        public intercomPanelBeforeChange: IntercomPanelResponse,
        public intercomPanelAfterChange: IntercomPanelResponse,
        public newLocation: LocationResponse,
        public rdaId: number
    ) { }
}

export class UpdateServiceIntercomPanelFailure implements Action {
    readonly type = ServicesActionTypes.UpdateServiceIntercomPanelFailure;

    constructor() { }
}

export class DeleteServiceIntercomPanel implements Action {
    readonly type = ServicesActionTypes.DeleteServiceIntercomPanel;

    constructor(public intercomPanelId: number, public rdaId: number) { }
}

export class DeleteServiceIntercomPanelSuccess implements Action {
    readonly type = ServicesActionTypes.DeleteServiceIntercomPanelSuccess;

    constructor(public intercomPanelId: number, public rdaId: number) { }
}

export class DeleteServiceIntercomPanelFailure implements Action {
    readonly type = ServicesActionTypes.DeleteServiceIntercomPanelFailure;

    constructor() { }
}

export type ServicesIntercomPanelsActions =
    | AddServiceIntercomPanel
    | AddServiceIntercomPanelSuccess
    | AddServiceIntercomPanelFailure

    | UpdateServiceIntercomPanel
    | UpdateServiceIntercomPanelSuccess
    | UpdateServiceIntercomPanelFailure

    | DeleteServiceIntercomPanel
    | DeleteServiceIntercomPanelSuccess
    | DeleteServiceIntercomPanelFailure;
