import {HttpErrorResponse} from '@angular/common/http';

export const parseError = (error: HttpErrorResponse): string => {
  if (!error) {
    return null;
  }

  if (error?.status === 0) {
    return 'Не удалось соединиться с сервером. Попробуйте повторить действие';
  }

  if (error.error?.['error'] === 'invalid_grant' || error.error?.['error'] === 'unauthorized') {
    return 'Неверный логин или пароль';
  }

  if (error instanceof HttpErrorResponse) {
    if (error.error) {
      return error.error['errorLocalized'] || 'Неизвестная ошибка';
    } else {
      return error.statusText;
    }
  } else {
    return error;
  }
};
