import { PlyrTemplateHelper } from '../../plyr-globals';
import { PlyrControlBasicHelper } from '../base';
import { PlyrSeekingControlsEventType } from './plyr-seeking-controls-event-type.enum';
import {TranslateService} from '@ngx-translate/core';

export class PlyrSeekingControlsHelper extends PlyrControlBasicHelper<PlyrSeekingControlsEventType> {
    constructor(
      private translate: TranslateService
    ) {
        super();
    }

    /**
     * Add next and previous events buttons if it's not created
     */
    addEventButtons() {
        let seekingForwardButton: HTMLButtonElement =
            document.getElementById(PlyrTemplateHelper.SEEKING_FORWARD_ID) as HTMLButtonElement;

        let seekingBackwardButton: HTMLButtonElement =
            document.getElementById(PlyrTemplateHelper.SEEKING_BACKWARD_ID) as HTMLButtonElement;

        if (seekingForwardButton && seekingBackwardButton) {
            return;
        }

        const captionsWrapper: HTMLDivElement =
            document.getElementsByClassName(PlyrTemplateHelper.CAPTIONS_WRAPPER_CLASS)[0] as HTMLDivElement;

        // captionsWrapper.innerHTML += PlyrTemplateHelper.createRewindButtons();

        seekingBackwardButton = document.createElement('button');
        seekingBackwardButton.id = PlyrTemplateHelper.SEEKING_BACKWARD_ID;
        seekingBackwardButton.classList.add(PlyrTemplateHelper.SEEKING_BUTTON_CLASS);
        seekingBackwardButton.classList.add(PlyrTemplateHelper.SEEKING_BUTTON_CLASS_LEFT);
        seekingBackwardButton.innerHTML = PlyrTemplateHelper.getSeekBackwardButtonContent(this.translate);
        captionsWrapper.appendChild(seekingBackwardButton);

        seekingForwardButton = document.createElement('button');
        seekingForwardButton.id = PlyrTemplateHelper.SEEKING_FORWARD_ID;
        seekingForwardButton.classList.add(PlyrTemplateHelper.SEEKING_BUTTON_CLASS);
        seekingForwardButton.classList.add(PlyrTemplateHelper.SEEKING_BUTTON_CLASS_RIGHT);
        seekingForwardButton.innerHTML = PlyrTemplateHelper.getSeekForwardButtonContent(this.translate);
        captionsWrapper.appendChild(seekingForwardButton);

        seekingForwardButton.addEventListener('click', (e: MouseEvent) =>
            this.clickListener$.next({ type: PlyrSeekingControlsEventType.CLICK_FORWARD, e })
        );

        seekingBackwardButton.addEventListener('click', (e: MouseEvent) =>
            this.clickListener$.next({ type: PlyrSeekingControlsEventType.CLICK_BACKWARD, e })
        );
    }

    /**
     * Remove next and previous events buttons if it's created
     */
    removeEventButtons() {
        const rewindForwardButton: HTMLButtonElement =
            document.getElementById(PlyrTemplateHelper.SEEKING_FORWARD_ID) as HTMLButtonElement;

        if (rewindForwardButton) {
            rewindForwardButton.remove();
        }

        const backwardForwardButton: HTMLButtonElement =
            document.getElementById(PlyrTemplateHelper.SEEKING_BACKWARD_ID) as HTMLButtonElement;

        if (backwardForwardButton) {
            backwardForwardButton.remove();
        }
    }
}
