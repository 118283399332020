import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AmplitudeComponentTrackType, AmplitudeEvents, AmplitudeService } from '@app/shared/entities/integrations/amplitude';
import { LoaderService } from '@app/shared/entities/common/loader';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProgressBarMode } from '@angular/material/progress-bar';

@Component({
  selector: 'app-bottom-sheet-wrapper',
  templateUrl: './bottom-sheet-wrapper.component.html',
  styleUrls: ['./bottom-sheet-wrapper.component.scss']
})
export class BottomSheetWrapperComponent implements OnInit, OnDestroy {
  readonly mode: ProgressBarMode = 'indeterminate';

  @Input() title!: string;
  @Input() componentName!: string;
  @Input() progress: boolean;
  loading: boolean;

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private loader: LoaderService,
    private amplitude: AmplitudeService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.trackOpenedPopup();
    this.initLoaderListener();
  }

  ngOnDestroy() {
    this.trackClosedPopup();

    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private trackOpenedPopup() {
    this.amplitude.trackComponent(
      this.componentName,
      AmplitudeComponentTrackType.BOTTOM_SHEET,
      AmplitudeEvents.OPEN,
      null
    );
  }

  private trackClosedPopup() {
    this.amplitude.trackComponent(
      this.componentName,
      AmplitudeComponentTrackType.BOTTOM_SHEET,
      AmplitudeEvents.CLOSE,
      null
    );
  }

  private initLoaderListener() {
    this.loader.getBottomSheetLoaderState()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(state => {
        this.loading = state;
        this.changeDetectorRef.detectChanges();
      });
  }
}
