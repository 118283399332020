import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { DialogWrapperModule } from '@app/shared/ui';
import { CompanyReportContentComponent } from './company-report-content';
import { CompanyReportPopupComponent } from './company-report-popup.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        DialogWrapperModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        NgxTranslateModule,
    ],
    declarations: [
        CompanyReportContentComponent,
        CompanyReportPopupComponent
    ],
    exports: [CompanyReportPopupComponent]
})
export class CompanyReportPopupModule { }
