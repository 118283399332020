import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Account } from '@app/shared/entities/rd';
import { Abonent } from '@app/shared/models';
import { AccountSelectionResponse } from './account-selection-response.model';
import { AccountSelectionType } from './account-selection-type.enum';

@Component({
  selector: 'app-account-selection',
  templateUrl: './account-selection.component.html',
  styleUrls: ['./account-selection.component.scss'],
})
export class AccountSelectionComponent {
  @Input() isNewControl: boolean;
  @Input() abonent: Abonent;
  @Input() set accounts(accounts: Account[]) {
    this._accounts = accounts;
    this.handleAccountSetter();
  }
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  @Input() canBeSkipped: boolean;
  form: UntypedFormGroup = this.initForm();

  @Output() private accountSelected: EventEmitter<AccountSelectionResponse> = new EventEmitter();
  @Output() private skipSelection: EventEmitter<void> = new EventEmitter();
  private _accounts: Account[];
  private _loading: boolean;

  get accounts(): Account[] {
    return this._accounts;
  }

  get loading(): boolean {
    return this._loading;
  }

  get selectedAccount(): Account {
    return this.form.get('accountId').value;
  }

  onSkip() {
    this.skipSelection.emit();
  }

  onSubmit() {
    const accountId: number = this.isNewControl ? this.form.get('accountId').value.id : this.form.get('accountId').value;
    let type: AccountSelectionType = null;

    if (this.form.get('newAccount').value) {
      type = AccountSelectionType.NEW_ACCOUNT;
    } else if (accountId) {
      type = AccountSelectionType.SELCTED_ACCOUNT;
    }

    this.accountSelected.emit({
      abonent: this.abonent,
      account: this.accounts.find(account => account.id === accountId),
      type
    });
  }

  onChange(event: MatCheckboxChange) {
    this.form.get('accountId')[event.checked ? 'disable' : 'enable']();
  }

  private handleAccountSetter() {
    if (this.accounts.length > 0) {
      this.form.get('accountId').setValue(this.accounts[0].id);
    }
    if (this.isNewControl) {
      this.form.get('accountId').setValue(this.accounts[0]);
    }

    this.form.get('accountId').enable();
    this.form.get('newAccount').setValue(null);
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      accountId: new UntypedFormControl(null),
      newAccount: new UntypedFormControl(null)
    });
  }
}
