<app-camera-edit-content
  [camera]="camera"
  [rdas]="rdas"
  [keys]="keys"
  [addresses]="addresses"
  [onvif]="onvif"
  [locations]="avaliableIntercomPanelsLocations?.length ? avaliableIntercomPanelsLocations : camerasLocations"
  [locationRequired]="!!avaliableIntercomPanelsLocations?.length"
  [loading]="loading"
  (submitCamera)="onSubmit($event.camera)"
  (submitOnvif)="onSubmitOnvif($event.camera)"
></app-camera-edit-content>
