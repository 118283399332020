import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { DialogWrapperModule, InfoBlockModule, ComponentLoaderModule } from '@app/shared/ui';
import { AbonentsDelegationPopupComponent } from './abonents-delegation-popup.component';
import { AbonentsDelegationPopupContentCloseComponent, AbonentsDelegationPopupContentConfirmComponent } from './components';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        InfoBlockModule,
        SharedPipesModule,
        DialogWrapperModule,
        ComponentLoaderModule,
        NgxTranslateModule
    ],
    declarations: [
        AbonentsDelegationPopupComponent,
        AbonentsDelegationPopupContentConfirmComponent,
        AbonentsDelegationPopupContentCloseComponent
    ],
    exports: [
        AbonentsDelegationPopupComponent
    ]
})
export class AbonentsDelegationPopupModule { }
