import {Injectable} from '@angular/core';
import {
  Camera,
  GateService,
  RdaResponse,
  ServiceApiService,
  ServiceCreateRequest,
  ServiceCreateResponse,
  ServiceInfoResponse,
  SoftwareIntercomService,
  VideoSurveillanceService
} from '@app/shared/entities/rd';
import {PagedResponse, ServicesTypes} from '@app/shared/models';
import {AddressFormatter} from '@app/shared/services';
import {
  DashboardCamerasCounts,
  DashboardGatesCounts,
  DashboardRdasCounts,
  DashboardSoftwareIntercomsCounts,
  DashboardVideoSurveillanceCounts
} from '@app/views/dashboard/models';
import {from, Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class DashboardHelperService {
  constructor(
    private gateService: GateService,
    private softwareIntercomService: SoftwareIntercomService,
    private videoSurveillanceService: VideoSurveillanceService,
    private translate: TranslateService
  ) {}

  getSoftwareIntercomsCounts(): Observable<ServiceInfoResponse[]> {
    return from(this.getServicesInfoListFromPageableRequest(this.softwareIntercomService));
  }

  getVideoSurveillanceCounts(): Observable<ServiceInfoResponse[]> {
    return from(this.getServicesInfoListFromPageableRequest(this.videoSurveillanceService));
  }

  getGatesCounts(): Observable<ServiceInfoResponse[]> {
    return from(this.getServicesInfoListFromPageableRequest(this.gateService));
  }

  calculateSoftwareIntercomsCounts(softwareIntercoms: ServiceInfoResponse[]): DashboardSoftwareIntercomsCounts {
    if (!softwareIntercoms) {
      return null;
    }

    const counts: DashboardSoftwareIntercomsCounts = {online: 0, inOperation: 0};

    softwareIntercoms.forEach((softwareIntercom: ServiceInfoResponse) => {
      if (softwareIntercom.rdas.length > 0 && softwareIntercom.cameras.length > 0) {
        counts.inOperation += 1;

        const rdasOnline: number = softwareIntercom.rdas.filter(rdas => rdas.active).length;
        const camerasOnline: number = softwareIntercom.cameras.filter(rdas => rdas.active).length;

        if (rdasOnline === softwareIntercom.rdas.length && camerasOnline === softwareIntercom.cameras.length) {
          counts.online += 1;
        }
      }
    });

    counts.online = counts.inOperation > 0 ?
      Math.trunc((counts.online / counts.inOperation) * 100) : 0;

    return counts;
  }

  calculateVideoSurveillanceCounts(videoSurveillanceList: ServiceInfoResponse[]): DashboardVideoSurveillanceCounts {
    if (!videoSurveillanceList) {
      return null;
    }

    const counts: DashboardVideoSurveillanceCounts = {online: 0, inOperation: 0};

    videoSurveillanceList.forEach((video: ServiceInfoResponse) => {
      if (video.cameras.length > 0) {
        counts.inOperation += 1;

        if (video.cameras.filter(camera => camera.active).length === video.cameras.length) {
          counts.online += 1;
        }
      }
    });

    counts.online = counts.inOperation > 0 ?
      Math.trunc((counts.online / counts.inOperation) * 100) : 0;

    return counts;
  }

  calculateGatesCounts(gates: ServiceInfoResponse[]): DashboardGatesCounts {
    if (!gates) {
      return null;
    }

    const counts: DashboardGatesCounts = {online: 0, inOperation: 0};

    gates.forEach((gate: ServiceInfoResponse) => {
      if (gate.rdas.length > 0 && gate.cameras.length > 0) {
        counts.inOperation += 1;

        const rdasOnline: number = gate.rdas.filter(rdas => rdas.active).length;
        const camerasOnline: number = gate.cameras.filter(rdas => rdas.active).length;

        if (rdasOnline === gate.rdas.length && camerasOnline === gate.cameras.length) {
          counts.online += 1;
        }
      }
    });

    counts.online = counts.inOperation > 0 ?
      Math.trunc((counts.online / counts.inOperation) * 100) : 0;

    return counts;
  }

  calculateRdasCounts(rdas: RdaResponse[]): DashboardRdasCounts {
    if (!rdas) {
      return null;
    }

    const counts: DashboardRdasCounts = {
      total: 0,
      inStock: 0,
      inConstruction: 0,
      inOperation: 0,
      active: 0,
      online: 0
    };

    rdas.forEach((rda: RdaResponse) => {
      counts.total += 1;
      if (rda.active) {
        counts.active += 1;
        counts.inOperation += 1;
      } else {
        if (rda['in_operation']) {
          counts.inOperation += 1;
        } else {
          counts[AddressFormatter.isAddressEmpty(rda.address) ? 'inStock' : 'inOperation'] += 1;
        }
      }
    });

    counts.online = counts.inOperation > 0 ?
      Math.trunc((counts.active / counts.inOperation) * 100) : 0;

    console.log('counts', counts);
    return counts;
  }

  calculateCamerasCounts(cameras: Camera[]): DashboardCamerasCounts {
    if (!cameras) {
      return null;
    }

    const counts: DashboardCamerasCounts = {
      total: 0,
      inOperation: 0,
      active: 0,
      online: 0,
    };

    cameras.forEach((camera: Camera) => {
      counts.total += 1;
      if (camera.active) {
        counts.active += 1;
        counts.inOperation += 1;
      } else {
        if (camera['in_operation']) {
          counts.inOperation += 1;
        } else if (camera.address) {
          counts.inOperation += 1;
        }
      }
    });

    counts.online = counts.inOperation > 0 ?
      Math.trunc((counts.active / counts.inOperation) * 100) : 0;

    return counts;
  }

  createService(serviceType: ServicesTypes, request: ServiceCreateRequest): Observable<ServiceCreateResponse> {
    switch (serviceType) {
      case ServicesTypes.SOFTWARE_INTERCOM:
        return this.softwareIntercomService.create(request);
      case ServicesTypes.GATE:
        return this.gateService.create(request);
      case ServicesTypes.VIDEO_SURVEILLANCE:
        return this.videoSurveillanceService.create(request);
      default:
        throw Error(this.translate.instant('dashboard.page.helper.message.create.failed'));
    }
  }

  private async getServicesInfoListFromPageableRequest(
    serviceApi: ServiceApiService, size: number = 2000
  ): Promise<ServiceInfoResponse[]> {
    let pageIdx = 0;
    let serviceInfoResponseList: PagedResponse<ServiceInfoResponse>;

    const servicesInfoList: ServiceInfoResponse[] = [];

    try {
      do {
        serviceInfoResponseList = await serviceApi.getPage(pageIdx, size).toPromise();
        serviceInfoResponseList.content.forEach(serviceInfo => servicesInfoList.push(serviceInfo));
        pageIdx = ++serviceInfoResponseList.number;
      } while (!serviceInfoResponseList.last);
    } catch (error) {
      throw new Error(error);
    }

    return servicesInfoList;
  }
}
