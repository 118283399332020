<mat-card class="card-container settings">
  <div class="header">
    <div class="title-block">
      {{ 'company.about.page.settings.form.title' | translate }}
    </div>
  </div>
  <mat-card-content>
    <form class="form" [formGroup]="form">
      <div class="form-content">
        <span class="text-small">
          {{ 'company.about.page.settings.form.field.banner' | translate }}
        </span>
        <div *ngIf="hasBannerPrice" class="banner-warning">
          {{ 'company.about.page.settings.form.hint.banner' | translate: {
            sum: this.company.bannerSum
          } }}
        </div>
        <div class="logo" #logo></div>

        <app-document-upload class="file-uploader" (emitReadData)="onEmitReadData($event)" />

        <div class="settings-image settings-image--mobile">
          <div
            class="logo-mobile"
            #logoMobile></div>
        </div>
        <mat-form-field flats-filters-search appearance="outline">
          <mat-label>
            {{ 'company.about.page.settings.form.field.brand_name' | translate }}
          </mat-label>
          <input
            formControlName="brandName"
            matInput
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false" />
        </mat-form-field>

        <mat-form-field flats-filters-search appearance="outline">
          <mat-label>
            {{ 'company.about.page.settings.form.field.payment_link' | translate }}
          </mat-label>
          <input
            formControlName="paymentLink"
            matInput
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false" />
        </mat-form-field>
        <mat-form-field flats-filters-search appearance="outline">
          <mat-label>
            {{ 'company.about.page.settings.form.field.app_link' | translate }}
          </mat-label>
          <input
            formControlName="personalAccountLink"
            matInput
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false" />
        </mat-form-field>

        <div class="support">
          <span class="sub-heading">
            {{ 'company.about.page.settings.form.header.support' | translate }}
          </span>
          <div class="support-content">
            <mat-checkbox formControlName="supportChatEnabled">
              {{ 'company.about.page.settings.form.field.support_chat_enabled' | translate }}
            </mat-checkbox>
            <app-phone-input
              [disabledPrefix]="true"
              [required]="false"
              formGroupName="supportPhone"></app-phone-input>
          </div>
        </div>
        <!--  Блок закоментирован до выяснения подробностей
        <div class="request">
          <span class="sub-heading">Заявки на подключение</span>
          <div class="request-content">
            <mat-form-field
              flats-filters-search
              appearance="outline">
              <mat-label>Ссылка на оферту для пользователей</mat-label>
              <input
                formControlName="autoSignOffer"
                matInput
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false" />
            </mat-form-field>
            <mat-checkbox
              class="request-checkbox"
              formControlName="autoSignRequireContract">
              Требовать логин(ЛС) при составлении заявки
            </mat-checkbox>
          </div>
        </div> -->
      </div>
      <div class="settings-image">
        <div
          #logoDesktop
          class="logo-mobile"></div>
      </div>
      <div class="btn-group">
        <button
          mat-raised-button
          color="primary-inverse"
          (click)="onToggleEdit()"
        >
          {{ 'company.about.page.settings.form.button.cancel' | translate }}
        </button>
        <button
          id="submit"
          type="button"
          mat-raised-button
          (click)="showBannerAlert ? showConfirmBanner() : onSubmit()"
          color="primary"
        >
          {{ 'company.about.page.settings.form.button.submit' | translate }}
        </button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
