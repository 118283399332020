import { AwxJobStatus } from '@app/shared/entities/rd/awx-jobs/models/awx-job-status.enum';

export interface CreateAwxUpdateJobResponse {
    created: number;
    finished: number;
    id: number;
    name: string;
    started: number;
    status: AwxJobStatus;
}
