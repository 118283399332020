import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { SoftwareIntercomWizardPopupStep, SoftwareIntercomWizardPopupStepper, SoftwareIntercomWizardUpdateStep } from '../models';
import { initialSoftwareIntercomWizardSteps, SoftwareIntercomWizardPopupState } from './software-intercom-wizard-popup.state';

@Injectable()
export class SoftwareIntercomWizardPopupStore extends ComponentStore<SoftwareIntercomWizardPopupState> {
    readonly steps$: Observable<SoftwareIntercomWizardPopupStepper[]> = this.select((state: SoftwareIntercomWizardPopupState) => state.steps);
    readonly showMenu$: Observable<boolean> = this.select((state: SoftwareIntercomWizardPopupState) => state.showMenu);
    readonly showStepButtons$: Observable<boolean> = this.select((state: SoftwareIntercomWizardPopupState) => state.showStepButtons);
    readonly wizardStep$: Observable<SoftwareIntercomWizardPopupStep> = this.select((state: SoftwareIntercomWizardPopupState) => state.wizardStep);

    readonly updateStepState = this.updater((
        state: SoftwareIntercomWizardPopupState,
        { step, stepState }: { step: SoftwareIntercomWizardPopupStep, stepState?: SoftwareIntercomWizardUpdateStep }
    ) => {
        if (stepState.complete !== undefined) {
            state.steps[step].complete = stepState.complete;
        }

        if (stepState.disabled !== undefined) {
            state.steps[step].disabled = stepState.disabled;
        }

        if (stepState.hidden !== undefined) {
            state.steps[step].hidden = stepState.hidden;
        }

        if (stepState.initialized !== undefined) {
            state.steps[step].initialized = stepState.initialized;
        }

        return {
            ...state,
            steps: state.steps.slice()
        };
    });

    readonly selectStep = this.updater((state: SoftwareIntercomWizardPopupState, wizardStep: SoftwareIntercomWizardPopupStep) =>
        ({ ...state, wizardStep })
    );

    readonly changeStepButtonsVisible = this.updater((state: SoftwareIntercomWizardPopupState, showStepButtons: boolean) =>
        ({ ...state, showStepButtons })
    );

    readonly changeMenuVisible = this.updater((state: SoftwareIntercomWizardPopupState, showMenu: boolean) =>
        ({ ...state, showMenu })
    );

    constructor() {
        super({
            steps: JSON.parse(JSON.stringify(initialSoftwareIntercomWizardSteps)),
            showStepButtons: true,
            showMenu: true,
            wizardStep: null
        });
    }
}
