import { Injectable } from '@angular/core';
import { compareVersions } from '@app/shared/helpers';

@Injectable()
export class ServiceIntercomsHelper {
    checkLatestVersion(rdaVersion: string, latestVersion: string): boolean {
        const comparedVersions: number = compareVersions(rdaVersion, latestVersion);

        if (comparedVersions === null) {
            return null;
        }

        return comparedVersions >= 0;
    }
}
