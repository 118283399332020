import { Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-delete-camera-content',
  templateUrl: './delete-camera-content.component.html',
  styleUrls: ['./delete-camera-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteCameraContentComponent {
  @Input() cameraId: number;
  @Input() loading: boolean;
  @Output() private deleteCamera: EventEmitter<void> = new EventEmitter();

  onDelete() {
    this.deleteCamera.emit();
  }
}
