import { Address } from '@app/shared/models';
import { ServiceFlat } from '@app/views/services/components/containers/service-flats/models/service-flat-connection.model';

export interface AbonentConnectionPopupBody {
    addresses: Address[];
    flatNumber?: number;
    flatId?: number;
    companyId?: number;
    phone?: number | string;
    hasPhysicalTube?: boolean;
    flatList?: ServiceFlat[];
}
