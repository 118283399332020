export interface DadataCompanySuggestionData {
    management: {
        name: string,
        post: string
    };
    inn: string;
    ogrn: string;
    kpp: string;
    type: string;
    name: {
        full_with_opf: string,
        short_with_opf: string,
        latin: string,
        full: string,
        short: string
    };
    address: {
        value: string
        unrestricted_value: string
        data: {
            postal_code: string
            country: string,
            country_iso_code: string,
            federal_district: string
            region_fias_id: string
            region_kladr_id: string
            region_type: string
            region: string
            area_fias_id: string
            area_kladr_id: string
            area_type: string
            area: string
            city_fias_id: string
            city_kladr_id: string
            city_type: string
            city: string
            city_area: string
            city_district_fias_id: string
            city_district_kladr_id: string
            city_district_type: string
            city_district: string
            settlement_fias_id: string
            settlement_kladr_id: string
            settlement_type: string
            settlement: string
            street_fias_id: string
            street_kladr_id: string
            street_type: string
            street: string
            house_fias_id: string
            house_kladr_id: string
            house_type: string
            house: string
            block_type: string
            block: string
            flat_type: string
            flat: string
            flat_area: string
            square_meter_price: string
            flat_price: string
            postal_box: string
            fias_id: string
            fias_code: string
            fias_level: string
            fias_actuality_state: string
            kladr_id: string
            geoname_id: string
            capital_marker: string
            okato: string
            oktmo: string
            tax_office: string
            tax_office_legal: string
            timezone: string
            geo_lat: string
            geo_lon: string
            beltway_hit: string
            beltway_distance: string
            metro: string
            qc_geo: string
            qc_complete: string
            qc_house: string
            history_values: string
            unparsed_parts: string
            source: string
            qc: string
        }
    };
    ogrn_date: number;
}
