import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { PagedResponse } from '@app/shared/models/paged-response';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AwxJobLogResponse, CreateAwxUpdateJobRequest } from '../models';
import { CreateAwxUpdateJobResponse } from './../models/create-awx-update-job-response.model';
import {CreateAwxDHCPJobRequest} from '@app/shared/entities/rd/awx-jobs/models/create-awx-dhcp-job-request.model';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class AwxJobsApiService extends ApiService {
    private headers = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
        super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1']);
    }

    getRdaaJobList(rdaUid: string): Observable<CreateAwxUpdateJobResponse[]> {
      return this.get<CreateAwxUpdateJobResponse[]>('/awx_jobs', 1, {
        params: {
          rda_uid: rdaUid
        }
      });
    }

    createRdaaUpdateJob(request: CreateAwxUpdateJobRequest): Observable<CreateAwxUpdateJobResponse> {
        return this.post<
            CreateAwxUpdateJobRequest,
            CreateAwxUpdateJobResponse
        >('/awx_jobs/update_rdaa', 1, request, { headers: this.headers });
    }

    createRdaaEnableDHCPJob(request: CreateAwxDHCPJobRequest): Observable<CreateAwxUpdateJobResponse> {
        return this.post<
            CreateAwxDHCPJobRequest,
            CreateAwxUpdateJobResponse
        >('/awx_jobs/enable_dhcp', 1, request, { headers: this.headers });
    }

    createRdosUpdateJob(request: CreateAwxUpdateJobRequest): Observable<CreateAwxUpdateJobResponse> {
        return this.post<
            CreateAwxUpdateJobRequest,
            CreateAwxUpdateJobResponse
        >('/awx_jobs/update_rdos', 1, request, { headers: this.headers });
    }

    getAwxJob(jobId: number): Observable<CreateAwxUpdateJobResponse> {
        return this.get<CreateAwxUpdateJobResponse>(`/awx_jobs/${jobId}`, 1);
    }

    getAwxJobLogs(
        jobId: number,
        page: number = 0,
        size: number = 100
    ): Observable<PagedResponse<AwxJobLogResponse>> {
        const params: HttpParams = new HttpParams({
            fromObject: {
                page: page.toString(),
                size: size.toString()
            }
        });

        return this.get<PagedResponse<AwxJobLogResponse>>(`/awx_jobs/${jobId}/logs`, 1, { params });
    }
}
