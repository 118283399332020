import { ReducerName } from '@app/store';
import * as root from '@app/store/reducers';
import { DashboardCamerasCounts, DashboardGatesCounts, DashboardRdasCounts, DashboardSoftwareIntercomsCounts, DashboardVideoSurveillanceCounts, RdosImage } from '@app/views/dashboard/models';

export interface DashboardModuleState {
    [ReducerName.DASHBOARD]: DashboardState;
}

export interface DashboardModuleRootState extends root.State {
    [ReducerName.DASHBOARD]: DashboardModuleState;
}

export interface DashboardState {
    rdasCounts: DashboardRdasCounts;
    gatesCounts: DashboardGatesCounts;
    camerasCounts: DashboardCamerasCounts;
    softwareIntercomsCounts: DashboardSoftwareIntercomsCounts;
    videoSurveillanceCounts: DashboardVideoSurveillanceCounts;
    rdosImages: RdosImage[];
    loading: boolean;
}

export const dashboardInitialState: DashboardState = {
    rdasCounts: null,
    gatesCounts: null,
    camerasCounts: null,
    softwareIntercomsCounts: null,
    videoSurveillanceCounts: null,
    rdosImages: null,
    loading: false
};
