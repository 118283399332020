<ng-container *ngIf="mode === 'advanced'; else simpleMod">
  <div class="camera-thumbnail camera-thumbnail--active">
    <div *ngIf="showHover" class="camera-thumbnail-hover camera-thumbnail-revoke-transparency">
      <div class="camera-thumbnail-hover-info">
        <div class="camera-thumbnail-hover-info-id">
          <span>{{ 'shared.camera.thumbnail.id' | translate }} {{camera?.id}}</span>
        </div>
        <div class="camera-thumbnail-hover-info-connection" *ngIf="(connectionInfo?.speed) || (connectionInfo?.delay)">
          <div class="camera-thumbnail-hover-info-connection-speed">
            <img src="assets/icons/ic-connection.svg" alt="error">
            <span>{{connectionInfo?.speed}} {{ 'shared.camera.thumbnail.kbit_s' | translate }}</span>
          </div>
          <div class="camera-thumbnail-hover-info-connection-delay">
            <img src="assets/icons/ic-low-speed.svg" alt="error">
            <span>{{connectionInfo?.delay}} {{ 'shared.camera.thumbnail.seconds' | translate }}</span>
            <img src="assets/icons/ic-error.svg" alt="error">
          </div>
        </div>
      </div>
    </div>

    <img
      class="camera-thumbnail__image"
      [lazyLoad]="cameraApiService.getImageThumbnail(camera.rdva?.uri || camera.rdvaUri, camera.id)"
      alt="{{ 'shared.camera.thumbnail.inaccessible' | translate }}"
    />
  </div>
</ng-container>

<ng-template #simpleMod>
  <div class="camera-thumbnail camera-thumbnail--active">

    <div *ngIf="showHover" class="camera-thumbnail-hover">
      <mat-icon
        class="camera-thumbnail-hover__icon"
        [class.camera-thumbnail-hover__icon--live]="camera.active"
        [class.camera-thumbnail-hover__icon--archive]="!camera.active"
      >
        {{ camera.active ? 'play_arrow' : 'archive' }}
      </mat-icon>

      <p
        class="camera-thumbnail-hover__text"
        *ngIf="camera.address && showInfo"
      >
        {{ camera.address | address }}
      </p>

      <div class="camera-thumbnail-surface" (click)="noActionOnClick ? onClick.next($event) : onVideo()"></div>

      <button
        mat-icon-button
        *ngIf="showInfo"
        (click)="onInfo()"
        class="camera-thumbnail__button"
      >
        <mat-icon class="camera-thumbnail__icon camera-thumbnail__icon--info">info</mat-icon>
      </button>
    </div>

    <img
      class="camera-thumbnail__image"
      [lazyLoad]="cameraApiService.getImageThumbnail(camera.rdva?.uri || camera.rdvaUri, camera.id)"
      alt="{{ 'shared.camera.thumbnail.inaccessible' | translate }}"
    />
  </div>
</ng-template>
