import { FlatRange } from './flat-ranges.model';
import { AddressInfo } from '@app/shared/models';

export class EntranceRegistration {
  public address: AddressInfo;
  public entrance: string;
  public range?: [number, number];
  public additionalFlatRanges?: FlatRange[];
  public prefix?: string;

  constructor(values?: Partial<EntranceRegistration>) {
    Object.assign(this, values);
  }
}
