import { ServicesTypes } from '@app/shared/models';

export interface ServiceFlatsFilters {
    activeAbonent: boolean;
    blockedAbonent: boolean;
    rangeStart?: number;
    rangeEnd?: number;
    phone: string;
    emptyFlat: boolean;
    blockedServices: ServicesTypes[];
    activeServices: ServicesTypes[];
    stoppedServices: ServicesTypes[];
}
