import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { PopupBasicComponent } from '@app/shared/templates';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper';

@Component({
  selector: 'app-incorrect-intercom-type-bottom-sheet',
  templateUrl: './incorrect-intercom-type-bottom-sheet.component.html',
  styleUrls: ['./incorrect-intercom-type-bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IncorrectIntercomTypeBottomSheetComponent extends PopupBasicComponent {

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogWrapperData<{ text: string }, { success: boolean }>
  ) {
    super();
  }

  onSubmit(success: boolean) {
    this.data.submit({ success });
  }
}
