import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResourcePath, ResourcesHelper } from '@app/shared/entities';
import { DialogWrapperData } from '@app/shared/ui';
import { Observable } from 'rxjs';
import { ServiceDeletePhysicalTubePopupService } from './service-delete-physical-tube-popup.service';

@Component({
  selector: 'app-service-delete-physical-tube-popup',
  templateUrl: './service-delete-physical-tube-popup.component.html',
  styleUrls: ['./service-delete-physical-tube-popup.component.scss'],
  providers: [ServiceDeletePhysicalTubePopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceDeletePhysicalTubePopupComponent implements OnInit {
  readonly resourcePath: typeof ResourcePath = ResourcePath;
  readonly popupState$: Observable<'loading' | 'close' | 'loaded'> = this.popupService.state$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<void, void>,
    public resourcesHelper: ResourcesHelper,
    private popupService: ServiceDeletePhysicalTubePopupService,
    private dialogRef: MatDialogRef<ServiceDeletePhysicalTubePopupComponent>
  ) { }

  ngOnInit() {
    this.popupService.addStateChangeListener((state: 'loading' | 'close' | 'loaded') =>
      state === 'close' && this.dialogRef.close()
    );
  }

  onDisconnect() {
    this.data.submit();
  }
}
