import { RdeaDate } from '@app/shared/entities/common';
import { PlyrConvertersHelper, PlyrTemplateHelper, PlyrTimeHelper } from '../../plyr-globals';
import { PlyrControlBasicHelper } from './../base';
import { PlyrPickerControlEventType } from './plyr-picker-control-event-type.enum';
import { PlyrTooltipOptions } from './plyr-tooltip-options.model';

/**
 * Class for time picker
 */
export class PlyrPickerControlsHelper extends PlyrControlBasicHelper<PlyrPickerControlEventType> {
    pickerDown: boolean;
    pickerOvered: boolean;

    private picker: HTMLDivElement;
    private tooltip: HTMLSpanElement;
    private progressBarWrapper: HTMLDivElement;
    private mousePosition: { x: number };
    private offset = 0;

    constructor() {
        super();

        this.picker = document.getElementById(PlyrTemplateHelper.PROGRESS_BAR_PICKER_ID) as HTMLDivElement;
        this.tooltip = document.getElementById(PlyrTemplateHelper.PROGRESS_BAR_TOOLTIP_ID);
        this.progressBarWrapper = document.getElementById(PlyrTemplateHelper.PROGRESS_BAR_WRAPPER_ID) as HTMLDivElement;

        this.enablePickerMouseEventListeners();
        this.enablePickerTouchEventListeners();
    }

    /**
     * Set licker left position in percent or pixels
     * @param {number} percent left position percent
     */
    setPicker(percent: number) {
        if (this.picker.style.display !== 'block') {
            this.picker.style.display = 'block';
        }

        this.picker.style.left = `calc(${percent}% - ${this.picker.clientWidth / 2}px)`;
    }

    /**
     * Set value and position for tooltip HTML element
     * @param {object} param0 PlyrTooltipOptions object with tooltip params
     */
    setTooltip({ percent, width, hide }: PlyrTooltipOptions = { percent: null, width: null }) {
        this.tooltip.style.opacity = (hide ? 0 : 1).toString();

        if (percent === null || percent === undefined) {
            return;
        }

        const currentProgressBarDate = new RdeaDate(PlyrConvertersHelper.calculateAbsoluteTimeFromPercent(percent));
        this.tooltip.innerText = currentProgressBarDate.getTimeString({ format: '2-digit' });

        if (width) {
            this.tooltip.style.left = `${percent / 100.0 * width}px`;
        }
    }

    /**
     * Enable picker mouse events for sliding
     */
    private enablePickerMouseEventListeners() {
        this.progressBarWrapper = document.getElementById(PlyrTemplateHelper.PROGRESS_BAR_WRAPPER_ID) as HTMLInputElement;

        this.picker.addEventListener('mousedown', (e: MouseEvent) => {
            // Set picker down state is true when clicked on the picker
            if (!this.pickerOvered) {
                return;
            }

            this.pickerDown = true;
            this.offset = this.picker.offsetLeft - e.clientX;
        }, true);

        document.addEventListener('mouseup', (e: MouseEvent) => {
            // Send stop selection event when click ended

            if (this.pickerDown) {
                this.clickListener$.next({
                    type: PlyrPickerControlEventType.STOP_SELECTION,
                    e: e.pageX - this.picker.clientWidth
                });
            }

            this.pickerDown = false;
        }, true);

        this.picker.addEventListener('mouseover', (e: MouseEvent) => {
            // Set picker overed state is true and show tooltip with current time

            this.pickerOvered = true;
            const clientRect: DOMRect = this.progressBarWrapper.getBoundingClientRect();

            if (!this.pickerDown) {
                this.setTooltip({
                    percent: this.pickerDown ?
                        PlyrConvertersHelper.calculatePercentFromProgressBarRect(this.picker.offsetLeft + clientRect.left, clientRect) :
                        PlyrConvertersHelper.calculateAbsolutePercent(PlyrTimeHelper.absoluteTime),
                    width: clientRect.width
                });
            }
        }, true);

        this.picker.addEventListener('mouseout', (e: MouseEvent) => {
            // Set picker overed state is false and hide tooltip

            this.pickerOvered = false;
            this.setTooltip({ percent: null, width: null, hide: true });
        }, true);

        document.addEventListener('mousemove', (e: MouseEvent) => {
            // Change current time in tooltip if picker state is overed

            e.preventDefault();

            if (!this.pickerDown) {
                return;
            }

            this.mousePosition = { x: e.clientX };
            let pickerLeftPosition: number = this.mousePosition.x + this.offset;

            // Check picker bounds
            if (pickerLeftPosition < 0) {
                pickerLeftPosition = 0;
            } else if (pickerLeftPosition > this.progressBarWrapper.clientWidth) {
                pickerLeftPosition = this.progressBarWrapper.clientWidth;
            }

            const clientRect: DOMRect = this.progressBarWrapper.getBoundingClientRect();
            const percent: number = PlyrConvertersHelper.calculatePercentFromProgressBarRect(
                e.pageX - this.picker.clientWidth,
                clientRect
            );

            this.setTooltip({ percent, width: clientRect.width });
            this.setPicker(percent);
        }, true);
    }

    private enablePickerTouchEventListeners() {
        this.picker.addEventListener('touchstart', (e: TouchEvent) => {
            this.pickerDown = true;
            this.offset = this.picker.offsetLeft - e.touches[0].pageX;
        }, false);

        document.addEventListener('touchend', (e: TouchEvent) => {
            if (this.pickerDown) {
                this.clickListener$.next({
                    type: PlyrPickerControlEventType.STOP_SELECTION,
                    e: e.changedTouches[0].pageX - this.picker.clientWidth
                });
            }

            this.pickerDown = false;
        }, false);

        document.addEventListener('touchmove', (e: TouchEvent) => {
            if (!this.pickerDown) {
                return;
            }

            this.mousePosition = { x: e.touches[0].pageX };
            let pickerLeftPosition: number = this.mousePosition.x + this.offset;

            if (pickerLeftPosition < 0) {
                pickerLeftPosition = 0;
            } else if (pickerLeftPosition > this.progressBarWrapper.clientWidth) {
                pickerLeftPosition = this.progressBarWrapper.clientWidth;
            }

            const clientRect: DOMRect = this.progressBarWrapper.getBoundingClientRect();
            const percent: number = PlyrConvertersHelper.calculatePercentFromProgressBarRect(e.touches[0].pageX - this.picker.clientWidth, clientRect);

            this.setTooltip({ percent, width: clientRect.width });
            this.setPicker(percent);
        }, false);
    }
}
