<div class="popup component">
  <h3 class="component__title">
    {{ 'services.gates.page.entrances.intercoms.title' | translate }}
  </h3>

  <app-service-intercoms-list-content
    [intercoms]="connectedIntercoms"
    (editIntercom)="onEdit($event.intercomIdx)"
    (deleteIntercom)="onDelete($event.intercomIdx)"
  ></app-service-intercoms-list-content>

  <app-intercoms-list-content
    *ngIf="avaliableIntercoms?.length > 0"
    [loading]="loading"
    [intercoms]="avaliableIntercoms"
    (intercomsSelected)="onIntercomsSelected($event.intercoms)"
  ></app-intercoms-list-content>

  <app-info-block *ngIf="!avaliableIntercoms">
    <app-component-loader
      info-block-image
      sizePx="50"
      [overTheComponent]="false"
    ></app-component-loader>

    <ng-container info-block-text>
      {{ 'services.gates.page.entrances.intercoms.message.check_adapters' | translate }}
    </ng-container>
  </app-info-block>

  <div class="popup-actions">
    <button
      mat-raised-button
      color="primary"
      class="popup-actions__button"
      [disabled]="loading || avaliableIntercoms?.length === 0"
      (click)="onSubmit()"
    >
      {{ 'services.gates.page.entrances.intercoms.button.submit' | translate }}
    </button>
  </div>
</div>
