import { EntrancePageResponse, EntranceRegistration, EntranceUpdateRequest } from '@app/shared/models';
import { Action } from '@ngrx/store';
import { ServicesActionTypes } from './services-action-types.actions';

export class AddServiceEntrance implements Action {
    readonly type = ServicesActionTypes.AddServiceEntrance;

    constructor(public entrance: EntranceRegistration, public prefix: string) { }
}

export class AddServiceEntranceSuccess implements Action {
    readonly type = ServicesActionTypes.AddServiceEntranceSuccess;

    constructor(public entrance: EntrancePageResponse, public prefix: string) { }
}

export class AddServiceEntranceFailure implements Action {
    readonly type = ServicesActionTypes.AddServiceEntranceFailure;

    constructor() { }
}

export class UpdateServiceEntrance implements Action {
    readonly type = ServicesActionTypes.UpdateServiceEntrance;

    constructor(public entranceId: number, public request: EntranceUpdateRequest) { }
}

export class UpdateServiceEntranceSuccess implements Action {
    readonly type = ServicesActionTypes.UpdateServiceEntranceSuccess;

    constructor(public entranceId: number, public response: EntranceUpdateRequest) { }
}

export class UpdateServiceEntranceFailure implements Action {
    readonly type = ServicesActionTypes.UpdateServiceEntranceFailure;

    constructor() { }
}

export class UpdateServiceEntranceWithPrefix implements Action {
    readonly type = ServicesActionTypes.UpdateServiceEntranceWithPrefix;

    constructor(public entranceId: number, public request: EntranceUpdateRequest) { }
}

export class UpdateServiceEntranceWithPrefixSuccess implements Action {
    readonly type = ServicesActionTypes.UpdateServiceEntranceWithPrefixSuccess;

    constructor(public entranceId: number, public response: EntranceUpdateRequest) { }
}

export class UpdateServiceEntranceWithPrefixFailure implements Action {
    readonly type = ServicesActionTypes.UpdateServiceEntranceWithPrefixFailure;

    constructor() { }
}

export class DeleteServiceEntrance implements Action {
    readonly type = ServicesActionTypes.DeleteServiceEntrance;

    constructor(public entranceId: number) { }
}

export class DeleteServiceEntranceSuccess implements Action {
    readonly type = ServicesActionTypes.DeleteServiceEntranceSuccess;

    constructor(public entranceId: number) { }
}

export class DeleteServiceEntranceFailure implements Action {
    readonly type = ServicesActionTypes.DeleteServiceEntranceFailure;

    constructor() { }
}

export type ServicesEntrancesActions =
    | AddServiceEntrance
    | AddServiceEntranceSuccess
    | AddServiceEntranceFailure

    | UpdateServiceEntrance
    | UpdateServiceEntranceSuccess
    | UpdateServiceEntranceFailure

    | UpdateServiceEntranceWithPrefix
    | UpdateServiceEntranceWithPrefixSuccess
    | UpdateServiceEntranceWithPrefixFailure

    | DeleteServiceEntrance
    | DeleteServiceEntranceSuccess
    | DeleteServiceEntranceFailure

    | DeleteServiceEntranceSuccess;
