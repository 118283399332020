import { Flat } from '@app/shared/models/flat';
import { ServiceEntrancesFlats } from '../components';
import { ServiceConnectionWithType } from './service-connection-with-type.type';

export interface ServiceBlockPhysicalTubeHandlerData {
    flats: ServiceEntrancesFlats;
    filteredFlats: ServiceEntrancesFlats;
    entranceId: number;
    flat: Partial<Flat>;
    connection: ServiceConnectionWithType;
}
