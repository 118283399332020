<form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
  <app-google-map-form *ngIf="environment.vivotec" [address]="address" (addressChange)="onAddressChange($event)"></app-google-map-form>

  <app-yandex-map-form
    *ngIf="environment.vivotec !== true"
    [clickable]="mapClickable"
    [rawAddress]="rawAddress"
    (submitted)="onSelectAddressInMap($event)"
  ></app-yandex-map-form>

  <div class="entrance-edit-container popup">
    <app-info-block *ngIf="manualInputSuggestion && !address">
      <ng-container info-block-text>
        <a
          *ngIf="mode === addressInputMode.CUSTOM; else moveManualInput"
          (click)="onModeChange(addressInputMode.CUSTOM)"
          class="link"
          color="primary"
        >
          {{ 'shared.entrance.edit.container.link.to_search_for_addresses' | translate }}
        </a>

        <ng-template #moveManualInput>
          <span>
            {{ 'shared.entrance.edit.container.message.cant_find_right_address' | translate }}
            <a
              (click)="onModeChange(addressInputMode.CUSTOM)"
              class="link"
              color="primary"
            >
              {{ 'shared.entrance.edit.container.link.indicate_it_manually' | translate }}
            </a>
          </span>
        </ng-template>
      </ng-container>
    </app-info-block>

    <div
      class="entrance-edit-container-address"
      [class.entrance-edit-container-address--custom-input]="mode === addressInputMode.CUSTOM"
      [class.entrance-edit-container-address--with-prefix]="mode !== addressInputMode.CUSTOM && showPrefix"
    >
      <ng-container *ngIf="!environment.vivotec">
        <app-custom-address-form
          *ngIf="mode === addressInputMode.CUSTOM; else defaultAddressInput"
          [country]="country"
          (addressChanged)="onChangedCustomAddress($event)"
          formGroupName="address"
        ></app-custom-address-form>

        <ng-template #defaultAddressInput>
          <app-address-input
            formGroupName="address"
            [mode]="mode"
            [country]="country"
            [label]="label"
            [options]="options"
            [initialAddress]="addressInfo"
            (changeMode)="onModeChange(addressInputMode.CREATED)"
            (submittedAddress)="onSelectAddress($event)"
          ></app-address-input>
        </ng-template>
      </ng-container>

      <div
        class="entrance-edit-container-address-prefix-entrance"
        [class.entrance-edit-container-address-prefix-entrance--with-prefix]="showPrefix"
      >
        <mat-form-field
          *ngIf="!environment.vivotec"
          appearance="outline"
          class="none-padding"
        >
          <mat-label>
            {{ 'shared.entrance.edit.container.field.entrance' | translate }}
          </mat-label>
          <input
            type="text"
            matInput
            formControlName="entrance"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            required
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="none-padding" *ngIf="showPrefix">
          <mat-label>
            {{ 'shared.entrance.edit.container.field.prefix' | translate }}
          </mat-label>
          <input
            type="text"
            matInput
            formControlName="prefix"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
        </mat-form-field>
      </div>
    </div>

    <app-info-block *ngIf="mode !== addressInputMode.CREATED && mode !== addressInputMode.CUSTOM && !address && !environment.vivotec">
      <ng-container info-block-text>
        <span>
          {{ 'shared.entrance.edit.container.message.have_already_address' | translate }}
          <a
            (click)="onModeChange(addressInputMode.CREATED)"
            class="link"
            color="primary"
          >
            {{ 'shared.entrance.edit.container.link.choose_it' | translate }}
          </a>
        </span>
      </ng-container>
    </app-info-block>

    <div class="entrance-edit-container-flat-range" formArrayName="range">
      <h4 class="entrance-edit-container-flat-range__title">
        {{ 'shared.entrance.edit.container.field.range_apartments' | translate }}
      </h4>

      <div class="entrance-edit-container-flat-range-input-block">
        <mat-form-field appearance="outline" class="none-padding">
          <mat-label>
            {{ 'shared.entrance.edit.container.field.range_apartments_start' | translate }}
          </mat-label>
          <input
            type="number"
            min="0"
            matInput
            formControlName="0"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
        </mat-form-field>

        <span *ngIf="!resolution.isMobile">&#8212;</span>

        <mat-form-field appearance="outline" class="none-padding">
          <mat-label>
            {{ 'shared.entrance.edit.container.field.range_apartments_end' | translate }}
          </mat-label>
          <input
            type="number"
            min="0"
            matInput
            formControlName="1"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
        </mat-form-field>
      </div>
    </div>

    <div class="entrance-edit-container-actions">
      <button
        class="entrance-edit-container-actions__action-button"
        mat-raised-button
        color="primary"
        type="submit"
        [disabled]="loading"
      >
        {{ 'shared.entrance.edit.container.button.submit' | translate }}
      </button>
    </div>

    <app-additional-ranges-form
      *ngIf="address?.entrance?.id; else notAvaliable"
      [loading]="loading"
      [entranceId]="address.entrance.id"
      [entranceRange]="entranceRange"
      [additionalRanges]="address.entrance.additionalFlatRanges"
      (addRange)="onAddRange($event.range)"
      (updateRange)="onUpdateRange($event.rangeId, $event.range)"
      (deleteRange)="onDeleteRange($event.rangeId)"
    ></app-additional-ranges-form>

    <ng-template #notAvaliable>
      <app-info-block>
        <ng-container info-block-text>
          {{ 'shared.entrance.edit.container.message.to_specify_additional_ranges_connect_entrance' | translate }}
        </ng-container>
      </app-info-block>
    </ng-template>
  </div>
</form>
