import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogWrapperModule } from '@app/shared/ui';
import { ServiceCameraPopupModule } from '@app/views/services/components';
import { SoftwareIntercomCameraPopupContentModule } from './software-intercom-camera-popup-content';
import { SoftwareIntercomCameraPopupComponent } from './software-intercom-camera-popup.component';

@NgModule({
    imports: [
        CommonModule,
        DialogWrapperModule,
        ServiceCameraPopupModule,
        SoftwareIntercomCameraPopupContentModule
    ],
    declarations: [
        SoftwareIntercomCameraPopupComponent
    ],
    exports: [
        SoftwareIntercomCameraPopupComponent
    ]
})
export class SoftwareIntercomCameraPopupModule { }
