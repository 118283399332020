<div *ngIf="cameras$ | async" [formGroup]="filterForm" class="add-camera-modal__wrapper">
  <div class="add-camera-modal__content__wrapper">
    <ng-container *ngIf="isGlobalLoading">
      <div class="add-camera-modal__field__wrapper">
        <p-skeleton height="3rem" width="100%"></p-skeleton>
      </div>
      <div class="add-camera-modal__cameras__wrapper">
        <p-skeleton *ngFor="let i of skeletons" height="240px" width="100%"></p-skeleton>
      </div>
      <p-skeleton height="4rem" width="100%"></p-skeleton>
    </ng-container>

    <ng-container *ngIf="!isGlobalLoading">
      <div class="add-camera-modal__field__wrapper">
        <input type="text" pInputText formControlName="cameraTitle"
               [placeholder]="'video.manager.common.camera_name' | translate"/>
      </div>
      <div class="add-camera-modal__cameras__wrapper">
        <ng-container *ngIf="isLoading">
          <p-skeleton *ngFor="let i of skeletons" height="240px" width="15rem"></p-skeleton>
        </ng-container>

        <ng-container *ngIf="!isLoading">
          <ng-container *ngIf="(cameras$ | async)?.totalCount > 0 && !isLoading">
            <div *ngFor="let camera of (cameras$ | async)?.dataSource" class="add-camera-modal__cameras__camera__wrapper">
              <div [hidden]="!isCameraSelected(camera)" class="add-camera-modal__cameras__camera__selector">
                <p-checkbox [binary]="true" formControlName="selectedCameraMark"></p-checkbox>
              </div>
              <app-camera-thumbnail [showInfo]="true" [noActionOnClick]="true" [camera]="camera" [mode]="videoMode.basic"
                                    (onClick)="selectCamera(camera)"></app-camera-thumbnail>

            </div>
          </ng-container>
          <ng-container *ngIf="(cameras$ | async)?.totalCount === 0 && !isLoading">
            <div class="add-camera-modal__no-data__wrapper">
              <p>{{'video.manager.common.no_cameras' | translate}}</p>
              <p-button [label]="'video.manager.common.close' | translate" (click)="onClose()"></p-button>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <app-custom-paginator *ngIf="!!paginatorConfig" [paginatorConfig]="paginatorConfig"
                            (pageChange)="onPageChange($event)" (pageSizeChange)="onPageSizeChange($event)"></app-custom-paginator>
    </ng-container>
  </div>

  <div class="add-camera-modal__footer__wrapper">
    <p-button [label]="'video.manager.common.close' | translate" (click)="onClose()"></p-button>
    <p-button [label]="'video.manager.common.continue' | translate"
              [disabled]="!filterForm?.get('selectedCamera')?.value"
              (click)="onSelectAndClose()"></p-button>
  </div>
</div>
