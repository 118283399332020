import { SelectSearch } from '@app/shared/models';
import * as root from '@app/store/reducers';
import { PbxOnRdaSearchSelectResponse } from '../models';

export interface PbxOnRdaSearchSelectState {
    pbxOnRdaList: PbxOnRdaSearchSelectResponse[];
    selectedPbxOnRda: SelectSearch;
    loading: boolean;
}

export const pbxOnRdaSearchSelectInitialState: PbxOnRdaSearchSelectState = {
    pbxOnRdaList: undefined,
    selectedPbxOnRda: undefined,
    loading: undefined
};

export interface PbxOnRdaSearchSelectModuleState {
    pbxOnRdaSearchSelect: PbxOnRdaSearchSelectState;
}

export interface PbxOnRdaSearchSelectModuleRootState extends root.State {
    'pbx-on-rda-search-select': PbxOnRdaSearchSelectModuleState;
}
