import { Injectable, OnDestroy } from '@angular/core';
import { Camera, RdaResponse } from '@app/shared/entities/rd';
import { Address } from '@app/shared/models';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { ServiceFacade } from '../store';

@Injectable()
export class ServicePageListeners implements OnDestroy {
    protected onDestroy$: Subject<void> = new Subject();

    constructor(
        protected serviceFacade: ServiceFacade
    ) { }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    addServiceEmptyListener(cb: () => void) {
        combineLatest([
            this.serviceFacade.serviceId$,
            this.serviceFacade.rdas$,
            this.serviceFacade.cameras$,
            this.serviceFacade.entrances$,
        ])
            .pipe(
                takeUntil(this.onDestroy$),
                map(([serviceId, rdas, cameras, addresses]: [number, RdaResponse[], Camera[], Address[]]) =>
                    serviceId &&
                    rdas?.length === 0 &&
                    cameras?.length === 0 &&
                    addresses?.length === 0
                ),
                filter((value: boolean) => value)
            )
            .subscribe(() => {
                cb();
                this.onDestroy$.next();
                this.onDestroy$.complete();
            });
    }
}
