import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { AddressInfo, Country } from '@app/shared/models';
import { AddressFormatter } from '@app/shared/services';

@Component({
  selector: 'app-custom-address',
  templateUrl: './custom-address.component.html',
  styleUrls: ['./custom-address.component.scss']
})
export class CustomAddressComponent implements OnInit, OnDestroy {
  @Input() label: string;
  @Input() country: Country;
  @Input() set initialAddress(initialAddress: AddressInfo) {
    if (!initialAddress) {
      return;
    }

    this._initialAddress = initialAddress;
    this.setValue('rawAddress', this.addressFormatter.formatAddressInfo(this.initialAddress));
  }

  private _initialAddress: AddressInfo;

  constructor(
    public controlContainer: ControlContainer,
    private addressFormatter: AddressFormatter
  ) { }

  ngOnInit() {
    this.getControl('rawAddress').disable();

    if (!this.initialAddress) {
      return;
    }

    this.setValue('country', this.initialAddress.country);
    this.setValue('city', this.initialAddress.city);
    this.setValue('street', this.initialAddress.street);
    this.setValue('house', this.initialAddress.house);
    this.setValue('block', this.initialAddress.block);
    this.setValue('housing', this.initialAddress.housing);
    this.setValue('universalCode', this.initialAddress.universalCode);
    this.setValue('rawAddress', this.addressFormatter.formatAddressInfo(this.initialAddress));
  }

  ngOnDestroy() {
    this.getControl('rawAddress').enable();
  }

  get initialAddress(): AddressInfo {
    return this._initialAddress;
  }

  private setValue(name: keyof AddressInfo | 'rawAddress', value: string | Country, touched?: boolean) {
    const control = this.getControl(name);

    if (touched) {
      control.markAsTouched();
    }

    control.setValue(value);
  }

  private getControl(name: keyof AddressInfo | 'rawAddress'): AbstractControl {
    return this.controlContainer.control.get(name as string);
  }
}
