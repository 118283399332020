<app-service-intercom-panels
  [mode]="mode"
  [intercom]="intercom"
  [locations]="locations"
  [intercomPanels]="intercomPanels"
  [loading]="loading"
  [editHidden]="editHidden"
  [addHidden]="addHidden"
  [deleteHidden]="deleteHidden"
  (addIntercomPanel)="onAdd($event.request)"
  (updateIntercomPanel)="onEdit($event.request, $event.intercomPanelId, $event.rdaId)"
  (deleteIntercomPanel)="onDelete($event.intercomPanelId, $event.rdaId)"
  (changePageMode)="onChangePageMode($event.mode)"
></app-service-intercom-panels>