import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/shared/components/snackbar';
import {GraylogCameraApiService, GraylogRdaApiService, RdvaApiService} from '@app/shared/entities/integrations';
import {ActiveHistoryResponse, Camera, MonitoringService, RdaResponse} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {LogsComponentType, LogsResponse} from '@app/shared/models';
import {ServicePageActionsService} from '@app/views/services/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, concatMap, map, mergeMap, switchMap} from 'rxjs/operators';
import {
  GetServiceCameraActivity,
  GetServiceCameraActivityFailure,
  GetServiceCameraActivitySuccess,
  GetServiceCameraLogs,
  GetServiceCameraLogsFailure,
  GetServiceCameraLogsSuccess,
  GetServiceCamerasSources,
  GetServiceCamerasSourcesSuccess,
  GetServiceKeysSources,
  GetServiceKeysSourcesFailure,
  GetServiceKeysSourcesSuccess,
  GetServicePrivateCameraLogs,
  GetServiceRdaActivity,
  GetServiceRdaActivityFailure,
  GetServiceRdaActivitySuccess,
  GetServiceRdaLogs,
  GetServiceRdaLogsFailure,
  GetServiceRdaLogsSuccess,
  GetServiceRdasSources,
  GetServiceRdasSourcesSuccess,
  ServicesActionTypes
} from '../actions';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServicesLogsEffects {
  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private servicePageService: ServicePageActionsService,
    private graylogRdaApiService: GraylogRdaApiService,
    private graylogCameraApiService: GraylogCameraApiService,
    private monitoringService: MonitoringService,
    private rdvaApiService: RdvaApiService,
    private translate: TranslateService
  ) {
  }

  GetServiceKeysSources$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceKeysSources>(ServicesActionTypes.GetServiceKeysSources),
      switchMap((action: GetServiceKeysSources) =>
        from(this.servicePageService.getRdasForKeys(action.request.map(val => val.intercomUid)))
          .pipe(
            map((response: RdaResponse[]) =>
              new GetServiceKeysSourcesSuccess(
                response.map((intercom: RdaResponse) => ({
                  id: intercom.id,
                  label: this.translate.instant('services.logs.get_service_keys_sources.label') + ' ' + action.request.find(val => val.intercomUid === intercom.uid).key.location?.name ?? intercom.uid,
                  type: LogsComponentType.RDA,
                  loading: false,
                  status: intercom.active,
                  component: intercom
                }))
              )
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.logs.get_service_keys_sources.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetServiceKeysSourcesFailure());
            })
          )
      )
    )
  );

  GetServiceCamerasSources$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceCamerasSources>(ServicesActionTypes.GetServiceCamerasSources),
      map((action: GetServiceCamerasSources) =>
        new GetServiceCamerasSourcesSuccess(
          action.cameras.map((camera: Camera) => ({
            id: camera.id,
            label: camera.location?.name ? camera.location?.name : `ID ${camera.id}`,
            type: LogsComponentType.CAMERA,
            loading: false,
            status: camera.active,
            component: camera
          }))
        )
      )
    )
  );

  GetServiceRdasSources$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceRdasSources>(ServicesActionTypes.GetServiceRdasSources),
      map((action: GetServiceRdasSources) =>
        new GetServiceRdasSourcesSuccess(
          action.rdas.map((rda: RdaResponse) => ({
            id: rda.id,
            label: rda.intercomType?.name ?? this.translate.instant('services.logs.get_service_rdas_sources.label') + ' ' + rda.uid,
            type: LogsComponentType.RDA,
            status: rda.active,
            loading: true,
            component: rda
          }))
        )
      )
    )
  );

  GetServiceRdaLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceRdaLogs>(ServicesActionTypes.GetServiceRdaLogs),
      switchMap((action: GetServiceRdaLogs) =>
        this.graylogRdaApiService.getMixedLogs(action.rdaUid, null, action.timeRange, action.limit, action.limit * action.page)
          .pipe(
            map((response: {
                logs: LogsResponse[];
                extendedLogs: LogsResponse[];
                totalCount: number;
                currentCount: number;
              }) =>
                new GetServiceRdaLogsSuccess(
                  response.logs,
                  response.extendedLogs,
                  response.totalCount,
                  response.currentCount,
                  action.page
                )
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.logs.get_service_rdas_sources.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetServiceRdaLogsFailure());
            })
          )
      )
    )
  );

  GetServicePrivateCameraLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServicePrivateCameraLogs>(ServicesActionTypes.GetServicePrivateCameraLogs),
      concatMap((action: GetServicePrivateCameraLogs) =>
        this.graylogCameraApiService.getMixedLogs(action.rdaUid, action.cameraId, action.timeRange, action.limit, action.limit * action.page)
          .pipe(
            map((response: {
                logs: LogsResponse[];
                extendedLogs: LogsResponse[];
                totalCount: number;
                currentCount: number;
              }) =>
                new GetServiceCameraLogsSuccess(
                  response.logs,
                  response.extendedLogs,
                  response.totalCount,
                  response.currentCount,
                  action.page
                )
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.logs.get_service_private_camera_logs.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetServiceCameraLogsFailure());
            })
          )
      )
    )
  );

  GetServiceCameraLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceCameraLogs>(ServicesActionTypes.GetServiceCameraLogs),
      switchMap((action: GetServiceCameraLogs) =>
        this.rdvaApiService.getMixedLogs(action?.rdvaUri, action?.cameraId)
          .pipe(
            map((response: {
                logs: LogsResponse[];
                extendedLogs: LogsResponse[];
                totalCount: number;
                currentCount: number;
              }) =>
                new GetServiceCameraLogsSuccess(
                  response?.logs,
                  response?.extendedLogs,
                  response?.logs.length,
                  response?.logs.length,
                  0
                )
            ),
            catchError((error: HttpErrorResponse) => {
              if ((error?.message || error?.status) && JSON.stringify(error).includes('TypeError')) {
                return;
              }
              this.snackbar.showMessage(
                this.translate.instant('services.logs.get_service_camera_logs.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetServiceCameraLogsFailure());
            })
          )
      )
    )
  );

  GetServiceRdaActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceRdaActivity>(ServicesActionTypes.GetServiceRdaActivity),
      mergeMap((action: GetServiceRdaActivity) =>
        this.monitoringService.getIntercomActiveHistory(action.rdaId, action.startDate, action.endDate, action.stepMilliseconds)
          .pipe(
            map((response: ActiveHistoryResponse[]) => {
              this.checkAndFixActivityItemsOrder(response);
              return new GetServiceRdaActivitySuccess(action.rdaId, response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.logs.get_service_rda_activity.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetServiceRdaActivityFailure(action.rdaId));
            })
          )
      )
    )
  );

  GetServiceCameraActivity$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceCameraActivity>(ServicesActionTypes.GetServiceCameraActivity),
      mergeMap((action: GetServiceCameraActivity) =>
        this.monitoringService.getCameraActiveHistory(action?.cameraId, action?.startDate, action?.endDate, action?.stepMilliseconds)
          .pipe(
            map((response: ActiveHistoryResponse[]) => {
              this.checkAndFixActivityItemsOrder(response);
              return new GetServiceCameraActivitySuccess(action?.cameraId, response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.logs.get_service_camera_activity.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetServiceCameraActivityFailure(action?.cameraId));
            })
          )
      )
    )
  );

  private checkAndFixActivityItemsOrder(activeHistory: ActiveHistoryResponse[]) {
    if (activeHistory?.length < 1) {
      return;
    }

    if (activeHistory[activeHistory?.length - 1]?.timestamp - activeHistory[0]?.timestamp >= 0) {
      return;
    }

    activeHistory?.sort((activityItem1, activityItem2) =>
      activityItem1?.timestamp - activityItem2?.timestamp
    );
  }
}
