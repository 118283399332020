<div class="custom-address-form" [formGroup]="controlContainer.control">
  <mat-form-field
    appearance="outline"
    class="none-padding none-margin custom-address-form-city-field"
  >
    <mat-label>
      {{ 'shared.entrances.edit.custom.address.form.field.city' | translate }}
    </mat-label>
    <input
      (keyup)="onKey($event, 'city')"
      type="text"
      matInput
      formControlName="city"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      required
    />
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="none-padding none-margin custom-address-form-street-field"
  >
    <mat-label>
      {{ 'shared.entrances.edit.custom.address.form.field.street' | translate }}
    </mat-label>
    <input
      (keyup)="onKey($event, 'street')"
      type="text"
      matInput
      formControlName="street"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      required
    />
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="none-padding none-margin custom-address-form-house-field"
  >
    <mat-label>
      {{ 'shared.entrances.edit.custom.address.form.field.house' | translate }}
    </mat-label>
    <input
      (keyup)="onKey($event, 'house')"
      type="text"
      matInput
      formControlName="house"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      required
    />
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="none-padding none-margin custom-address-form-block-field"
  >
    <mat-label>
      {{ 'shared.entrances.edit.custom.address.form.field.block' | translate }}
    </mat-label>
    <input
      (keyup)="onKey($event, 'block')"
      type="text"
      matInput
      formControlName="block"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
    />
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="none-padding none-margin custom-address-form-building-field"
  >
    <mat-label>
      {{ 'shared.entrances.edit.custom.address.form.field.building' | translate }}
    </mat-label>
    <input
      (keyup)="onKey($event, 'building')"
      type="text"
      matInput
      formControlName="building"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
    />
  </mat-form-field>

  <mat-form-field
    appearance="outline"
    class="none-padding none-margin custom-address-form-housing-field"
  >
    <mat-label>
      {{ 'shared.entrances.edit.custom.address.form.field.housing' | translate }}
    </mat-label>
    <input
      (keyup)="onKey($event, 'housing')"
      type="text"
      matInput
      formControlName="housing"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
    />
  </mat-form-field>
</div>
