import {Component, Inject} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {DialogWrapperData} from "@app/shared/ui";
import {ServiceFlat} from "@app/views/services/components";
import {FlatAbonentsPopupService} from "./flat-abonents-popup.service";

@Component({
  selector: 'app-flat-abonents-popup',
  templateUrl: './flat-abonents-popup.component.html',
  styleUrls: ['./flat-abonents-popup.component.scss']
})
export class FlatAbonentsPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<{ flat: ServiceFlat }, { flat: ServiceFlat }>,
    public readonly popupService: FlatAbonentsPopupService,
    public readonly dialogRef: MatDialogRef<FlatAbonentsPopupComponent>,
  ) { }
}
