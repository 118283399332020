import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonLinkModule, NavbarModule } from '@app/shared/ui';
import { ServiceNavbarComponent } from './service-navbar.component';
import { MaterialModule } from '@app/material.module';
import { NgxTranslateModule } from '@app/translate/translate.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        NavbarModule,
        MaterialModule,
        ButtonLinkModule,
        NgxTranslateModule
    ],
    declarations: [ServiceNavbarComponent],
    exports: [ServiceNavbarComponent]
})
export class ServiceNavbarModule { }
