import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilter',
  standalone: true
})
export class TableFilterPipe<T> implements PipeTransform {
  transform(value: T[], pageConfig: { currentPage: number; pageSize: number; showSkeleton: boolean }): T[] {
    if (pageConfig.showSkeleton) { return []; }
    let skip = null;

    pageConfig.currentPage > 1 ? (skip = pageConfig.currentPage * pageConfig.pageSize) : (skip = 0);

    const paginationSettings = this.calculationForSkipping(skip, pageConfig.pageSize);

    return value.slice(paginationSettings.from, paginationSettings.to);
  }

  private calculationForSkipping(skip: number, pageSize: number) {
    return skip - pageSize < 0 ? { from: 0, to: pageSize } : { from: skip - pageSize, to: skip };
  }
}
