import { Component } from '@angular/core';
import { MatDatepickerIntl } from '@angular/material/datepicker';

@Component({
  selector: 'app-mat-datepicker-custom',
  templateUrl: './mat-datepicker-custom.component.html',
  styleUrls: ['./mat-datepicker-custom.component.scss']
})
export class MatDatepickerCustomComponent extends MatDatepickerIntl {
  calendarLabel: string;
  openCalendarLabel: string;
  prevMonthLabel: string;
  nextMonthLabel: string;
  prevYearLabel: string;
  nextYearLabel: string;
  prevMultiYearLabel: string;
  nextMultiYearLabel: string;
  switchToMonthViewLabel: string;
}
