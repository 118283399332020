<mat-card class="company-sign-up-request-form">
  <div class="company-sign-up-request-form-header">
    <div class="company-sign-up-request-form-header-back">
      <img src="assets/icons/ic-new-back.svg" alt="error" [routerLink]="'/login'">
    </div>
    <div class="company-sign-up-request-form-header-logo">
      <img src="assets/logo/logo-ic-main.svg" alt="error">
    </div>
  </div>
  <mat-card-header>
    <mat-card-title>
      {{ 'company.signup.request.form.title' | translate }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ 'company.signup.request.form.subtitle' | translate }}
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <form
      [formGroup]="form"
      autocomplete="off"
      #formDirective="ngForm"
      (ngSubmit)="onSubmit()"
      class="company-sign-up-request-form-content"
    >
      <mat-form-field appearance="outline" class="relative-size">
        <mat-label>
          {{ 'company.signup.request.form.field.company_name' | translate }}
        </mat-label>
        <input
          required
          matInput
          type="text"
          [type]="'PARTY'"
          id="companyName"
          [readonly]="false"
          autocomplete="off"
          autocorrect="off"
          spellcheck="false"
          autocapitalize="off"
          appDadataSuggestions
          formControlName="companyName"
          (valueSelected)="onValueSelected($event)"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" class="relative-size">
        <mat-label>
          {{ 'company.signup.request.form.field.ogrn' | translate }}
        </mat-label>
        <input
          matInput
          required
          id="ogrn"
          type="text"
          autocorrect="off"
          autocomplete="off"
          spellcheck="false"
          autocapitalize="off"
          formControlName="ogrn"
        />
      </mat-form-field>

      <mat-form-field appearance="outline" class="relative-size">
        <mat-label>
          {{ 'company.signup.request.form.field.email' | translate }}
        </mat-label>
        <input
          matInput
          required
          id="email"
          type="email"
          autocorrect="off"
          spellcheck="false"
          autocomplete="off"
          autocapitalize="off"
          formControlName="email"
          [control]="getFormControl('email')"
          appLowerCase
        />
        <mat-hint>
          {{ 'company.signup.request.form.hint.email' | translate }}
        </mat-hint>
      </mat-form-field>

      <div>
        <div class="company-sign-up-request-form-content-checkbox-block">
          <mat-checkbox
            required
            id="accept"
            formControlName="accept"
            class="company-sign-up-request-form-content-checkbox-block__checkbox"
          ></mat-checkbox>
          <p class="company-sign-up-request-form-content-checkbox-block__text">
            {{ 'company.signup.request.form.hint.accept' | translate }}
          </p>
        </div>

        <div class="company-sign-up-request-form-content-checkbox-block">
          <mat-checkbox
            required
            id="acceptAgreements"
            formControlName="acceptAgreements"
            class="company-sign-up-request-form-content-checkbox-block__checkbox"
          ></mat-checkbox>
          <p class="company-sign-up-request-form-content-checkbox-block__text">
            {{ 'company.signup.request.form.message.i_accept_conditions' | translate }}
            <a [href]="personalAgreementPageLink">
              {{ 'company.signup.request.form.link.personal_agreement' | translate | lowercase }}
            </a>
            {{ 'company.signup.request.form.message.and' | translate }}
            <a [href]="privacyPolicyPageLink">
              {{ 'company.signup.request.form.link.privacy_policy' | translate | lowercase }}
            </a>
          </p>
        </div>
      </div>

      <button
        type="submit"
        mat-raised-button
        [disabled]="loading"
        class="company-sign-up-request-form-content__button"
      >
        <app-component-loader *ngIf="loading; else btnText"></app-component-loader>
        <ng-template #btnText>
          {{ 'company.signup.request.form.button.submit' | translate }}
        </ng-template>
      </button>
    </form>
  </mat-card-content>
</mat-card>
