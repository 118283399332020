import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { FlatPhoneComponent } from './flat-phone.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [CommonModule, RouterModule, MaterialModule, SharedPipesModule, NgxTranslateModule],
  declarations: [FlatPhoneComponent],
  exports: [FlatPhoneComponent]
})
export class FlatPhoneModule {}
