import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { LoaderService } from '@app/shared/entities/common/loader';
import { State } from '@app/store/reducers';
import { Shield } from '@app/views/intercom/models';
import { GetAllShields } from '@app/views/intercom/store/actions';
import { getAllShieldsLoading, getAllShieldsSuccess } from '@app/views/intercom/store/states';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-shields-page',
  templateUrl: './shields-page.component.html',
  styleUrls: ['./shields-page.component.scss']
})
export class ShieldsPageComponent implements OnInit {
  readonly pageSize = 10;
  readonly displayedColumns = ['createdAt', 'number', 'shieldUid', 'versionInfoShield', 'versionInfoStm', 'rdaUid', 'errorsCount', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  filterInput: UntypedFormControl;
  shields$: Observable<{ dataSource: Shield[], totalCount: number }>;
  loading$: Observable<boolean>;

  constructor(
    public resolution: ResolutionService,
    private store: Store<State>,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.initFilter();
    this.initLoading();
    this.initPagination();
    this.initStore();
    this.loadShields(0);
  }

  private loadShields(page: number) {
    this.store.dispatch(new GetAllShields(page, this.pageSize, this.filterInput.value));
  }

  private initPagination() {
    this.paginator.page.subscribe((e: PageEvent) => this.loadShields(e.pageIndex));
  }

  private initFilter() {
    this.filterInput = new UntypedFormControl();
    this.filterInput.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.paginator.pageIndex = 0;
        this.loadShields(0);
      });
  }

  private initStore() {
    this.shields$ = this.store.select(getAllShieldsSuccess);
  }

  private initLoading() {
    this.store.select(getAllShieldsLoading)
      .subscribe((state: boolean) => this.loaderService.loaderState = { state });
  }
}
