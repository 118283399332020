<app-info-table *ngIf="intercom; let intercom">
  <app-info-table-record>
    <p title>{{ 'shared.rda.intercom.info.content.field.status' | translate }}<p>

    <div value>
      <mat-chip
        [disabled]="true"
        [selectable]="false"
        [color]="intercom.active ? 'success' : 'error'"
      >
        {{
          intercom.active
            ? ('shared.rda.intercom.info.content.enum.status.online' | translate)
            : ('shared.rda.intercom.info.content.enum.status.offline' | translate)
        }}
      </mat-chip>
    </div>
  </app-info-table-record>

  <app-info-table-record>
    <p title>{{ 'shared.rda.intercom.info.content.field.uid' | translate }}</p>
    <p value>{{ intercom.uid }}</p>
  </app-info-table-record>

  <app-info-table-record *ngIf="intercom.intercomType">
    <p title>{{ 'shared.rda.intercom.info.content.field.adapter_type' | translate }}</p>
    <p value>{{ intercom.intercomType?.name }}</p>
  </app-info-table-record>

  <app-info-table-record>
    <p title>{{ 'shared.rda.intercom.info.content.field.ip' | translate }}</p>
    <p value>{{ intercom.ipAddress }}</p>
  </app-info-table-record>

  <app-info-table-record *ngIf="intercom.companyName">
    <p title>{{ 'shared.rda.intercom.info.content.field.company' | translate }}</p>
    <p value>{{ intercom.companyName }}</p>
  </app-info-table-record>

  <ng-container *ngIf="isIpIntercom; else rdaFields">
    <app-info-table-record>
      <p title>{{ 'shared.rda.intercom.info.content.field.connection_method' | translate }}</p>
      <p value>{{ intercom.mode | ipRdaConnectionType }}</p>
    </app-info-table-record>

    <app-info-table-record *ngIf="pbxOnRda">
      <p title>{{ 'shared.rda.intercom.info.content.field.pbx_on_rda' | translate }}</p>
      <p value>{{ pbxOnRda.pbxOnRdaUid }}</p>
    </app-info-table-record>

    <app-info-table-record *ngIf="livenessUrl">
      <p title>{{ 'shared.rda.intercom.info.content.field.liveness_url' | translate }}</p>
      <p value>{{ livenessUrl }}</p>
    </app-info-table-record>
  </ng-container>

  <ng-template #rdaFields>
    <app-info-table-record>
      <p title>{{ 'shared.rda.intercom.info.content.field.version_rdos' | translate }}</p>
      <div value>
        <p *ngIf="intercom.versionInfoOs; else emptyVersionInfoOs">
          <ng-container *ngIf="intercom.active" [ngSwitch]="rdosLatest">
            <app-text-badge color="warn" *ngSwitchCase="false">
              {{ 'shared.rda.intercom.info.content.message.update_available' | translate | lowercase }}
              {{ gitlabComponentsVersions?.rdosLatest }}
            </app-text-badge>

            <app-text-badge color="success" *ngSwitchCase="true">
              {{ 'shared.rda.intercom.info.content.message.last_version' | translate | lowercase }}
            </app-text-badge>
          </ng-container>
          &nbsp;
          {{ intercom.versionInfoOs }}
        </p>

        <ng-template #emptyVersionInfoOs>
          <mat-icon class="intercom-info-container__error-icon">error</mat-icon>
        </ng-template>
      </div>
    </app-info-table-record>

    <app-info-table-record>
      <p title>{{ 'shared.rda.intercom.info.content.field.version_rdaa' | translate }}</p>
      <div value>
        <p *ngIf="intercom.versionInfoRdaa; else emptyVersionInfoRdaa">
          <ng-container *ngIf="intercom.active" [ngSwitch]="rdaaLatest">
            <app-text-badge color="warn" *ngSwitchCase="false">
              {{ 'shared.rda.intercom.info.content.message.update_available' | translate | lowercase }}
              {{ gitlabComponentsVersions?.rdaaLatest }}
            </app-text-badge>

            <app-text-badge color="success" *ngSwitchCase="true">
              {{ 'shared.rda.intercom.info.content.message.last_version' | translate | lowercase }}
            </app-text-badge>
          </ng-container>
          &nbsp;
          {{ intercom.versionInfoRdaa }}
        </p>

        <ng-template #emptyVersionInfoRdaa>
          <mat-icon class="intercom-info-container__error-icon">error</mat-icon>
        </ng-template>
      </div>
    </app-info-table-record>

    <app-info-table-record>
      <p title>{{ 'shared.rda.intercom.info.content.field.version_stm' | translate }}</p>
      <div value>
        <p *ngIf="intercom.versionInfoStm; else emptyVersionInfoStm">
          {{ intercom.versionInfoStm }}
        </p>

        <ng-template #emptyVersionInfoStm>
          <mat-icon class="intercom-info-container__error-icon">error</mat-icon>
        </ng-template>
      </div>
    </app-info-table-record>

    <app-info-table-record>
      <p title>{{ 'shared.rda.intercom.info.content.field.version_stm_driver' | translate }}</p>
      <div value>
        <p *ngIf="intercom.versionInfoStmDriver; else emptyVersionInfoStmDriver">
          {{ intercom.versionInfoStmDriver }}
        </p>

        <ng-template #emptyVersionInfoStmDriver>
          <mat-icon class="intercom-info-container__error-icon">error</mat-icon>
        </ng-template>
      </div>
    </app-info-table-record>

    <app-info-table-record>
      <p title>{{ 'shared.rda.intercom.info.content.field.version_shield' | translate }}</p>
      <div value>
        <p *ngIf="intercom.versionInfoShield; else emptyVersionInfoShield">
          {{ intercom.versionInfoShield }}
        </p>

        <ng-template #emptyVersionInfoShield>
          <mat-icon class="intercom-info-container__error-icon">error</mat-icon>
        </ng-template>
      </div>
    </app-info-table-record>

    <app-info-table-record *ngIf="intercom.rdaModel">
      <p title>{{ 'shared.rda.intercom.info.content.field.rda_model' | translate }}</p>
      <p value>{{ intercom.rdaModel }}</p>
    </app-info-table-record>

    <app-info-table-record *ngIf="intercom.shieldModel">
      <p title>{{ 'shared.rda.intercom.info.content.field.shield_model' | translate }}</p>
      <p value>{{ intercom.shieldModel }}</p>
    </app-info-table-record>

    <app-info-table-record *ngIf="intercom.batch">
      <p title>{{ 'shared.rda.intercom.info.content.field.batch_number' | translate }}</p>
      <p value>{{ intercom.batch.number }}</p>
    </app-info-table-record>
  </ng-template>

  <app-info-table-record>
    <p title>{{ 'shared.rda.intercom.info.content.field.created_at' | translate }}</p>
    <p value>{{ intercom.createdAt | date: 'dd.MM.yyyy HH:mm:ss' }}</p>
  </app-info-table-record>

  <app-info-table-record>
    <p title>{{ 'shared.rda.intercom.info.content.field.last_heart_beat_time' | translate }}</p>
    <p value>{{ intercom.lastHeartBeatTime | date: 'dd.MM.yyyy HH:mm:ss' }}</p>
  </app-info-table-record>
</app-info-table>
