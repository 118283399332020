import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { DialogWrapperModule } from '@app/shared/ui/dialog-wrapper';
import { CreateServiceContentComponent } from './create-service-content';
import { CreateServicePopupComponent } from './create-service-popup.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        SharedPipesModule,
        ReactiveFormsModule,
        MaterialModule,
        DialogWrapperModule,
        NgxTranslateModule
    ],
    declarations: [
        CreateServicePopupComponent,
        CreateServiceContentComponent,
    ],
    exports: [
        CreateServicePopupComponent
    ]
})
export class CreateServicePopupModule { }
