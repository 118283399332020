import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/shared/components/snackbar';
import {EntranceService, ServiceApiService} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {EntrancePageResponse} from '@app/shared/models';
import {ServicePageActionsService} from '@app/views/services/services';
import {ServiceFacade} from '@app/views/services/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
  AddServiceEntrance,
  AddServiceEntranceFailure,
  AddServiceEntranceSuccess,
  DeleteServiceEntrance,
  DeleteServiceEntranceFailure,
  DeleteServiceEntranceSuccess,
  ServicesActionTypes,
  UpdateServiceEntrance,
  UpdateServiceEntranceFailure,
  UpdateServiceEntranceSuccess,
  UpdateServiceEntranceWithPrefix,
  UpdateServiceEntranceWithPrefixFailure,
  UpdateServiceEntranceWithPrefixSuccess
} from '../actions';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServicesEntrancesEffects {
  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private entranceService: EntranceService,
    private serviceApiService: ServiceApiService,
    private servicePageService: ServicePageActionsService,
    private serviceFacade: ServiceFacade,
    private translate: TranslateService
  ) {
  }

  AddServiceEntrance$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddServiceEntrance>(ServicesActionTypes.AddServiceEntrance),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [AddServiceEntrance, number]) =>
        from(this.servicePageService.addEntrance(serviceId, action.entrance, action.prefix))
          .pipe(
            map((response: EntrancePageResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.entrances.message.create.success'),
                'success'
              );
              response.address.entrance.prefix = action.prefix;
              return new AddServiceEntranceSuccess(response, action.prefix);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.entrances.message.create.failed', {
                  text: parseError(error)
                }),
              );
              return of(new AddServiceEntranceFailure());
            })
          ))
    )
  );

  AddServiceEntranceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddServiceEntranceSuccess>(ServicesActionTypes.AddServiceEntranceSuccess),
      tap((action: AddServiceEntranceSuccess) => {
        this.serviceFacade.getConnectionsInit();
      })
    ), {dispatch: false}
  );

  UpdateServiceEntrance$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateServiceEntrance>(ServicesActionTypes.UpdateServiceEntrance),
      switchMap((action: UpdateServiceEntrance) =>
        this.entranceService.updateEntrance(action.entranceId, action.request)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.entrances.message.update.success'),
                'success'
              );
              return new UpdateServiceEntranceSuccess(action.entranceId, action.request);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.entrances.message.update.failed', {
                  text: parseError(error)
                }),
              );
              return of(new UpdateServiceEntranceFailure());
            })
          ))
    )
  );

  UpdateServiceEntranceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateServiceEntranceSuccess>(ServicesActionTypes.UpdateServiceEntranceSuccess),
      tap((action: UpdateServiceEntranceSuccess) => {
        this.serviceFacade.getConnectionsInit();
      })
    ), {dispatch: false}
  );

  UpdateServiceEntranceWithPrefix$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateServiceEntranceWithPrefix>(ServicesActionTypes.UpdateServiceEntranceWithPrefix),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [UpdateServiceEntranceWithPrefix, number]) =>
        from(this.servicePageService.updateEntranceWithPrefix(serviceId, action.entranceId, action.request))
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.entrances.message.update.success'),
                'success'
              );
              return new UpdateServiceEntranceWithPrefixSuccess(action.entranceId, action.request);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.entrances.message.update.failed', {
                  text: parseError(error)
                }),
              );
              return of(new UpdateServiceEntranceWithPrefixFailure());
            })
          ))
    )
  );

  UpdateServiceEntranceWithPrefixSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateServiceEntranceWithPrefixSuccess>(ServicesActionTypes.UpdateServiceEntranceWithPrefixSuccess),
      tap((action: UpdateServiceEntranceWithPrefixSuccess) => {
        this.serviceFacade.getConnectionsInit();
      })
    ), {dispatch: false}
  );

  DeleteServiceEntrance$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceEntrance>(ServicesActionTypes.DeleteServiceEntrance),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [DeleteServiceEntrance, number]) =>
        this.serviceApiService.disconnectEntrance(serviceId, action.entranceId)
          .pipe(
            map(() => {
              this.snackbar.showMessage(this.translate.instant(
                'services.entrances.message.delete.success'),
                'success'
              );
              return new DeleteServiceEntranceSuccess(action.entranceId);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.entrances.message.delete.failed', {
                  text: parseError(error)
                }),
              );
              return of(new DeleteServiceEntranceFailure());
            })
          ))
    )
  );

  DeleteServiceEntranceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceEntranceSuccess>(ServicesActionTypes.DeleteServiceEntranceSuccess),
      tap((action: DeleteServiceEntranceSuccess) => {
        this.serviceFacade.getConnectionsInit();
      })
    ), {dispatch: false}
  );
}
