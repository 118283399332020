import { ServiceResponse } from '@app/shared/entities/rd';

export interface AbonentConnectionToolData {
  entranceId: number;
  services: any;
  hasPhysicalTube: boolean;
  withoutPhoneNumber: boolean;
  loadAbonentAndAccounts: boolean;
  phone?: number;
  servicesForDelete: Pick<ServiceResponse, 'id' | 'type'>[];
}

export interface ServiceSelectedArgs {
  servicesToAdd: Pick<ServiceResponse, 'id' | 'type'>[];
  servicesForDelete: Pick<ServiceResponse, 'id' | 'type'>[];
}
