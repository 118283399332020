<div
  class="service-flats"
  [class.service-flats--divided-content]="addresses?.length > 0">
  <ng-container *ngIf="addresses?.length > 0; else emptyAddresses">
    <app-service-flats-menu
      [filterRowState]="filterRowState"
      [selectedFilterCount]="selectedFilterCount"
      [tariffActiveState]="tariffActiveState"
      [selectedFlatList]="selectedFlatList"
      [manageActiveState]="manageActiveState"
      [deleteActiveState]="deleteActiveState"
      [deleteAbonentsActiveState]="deleteAbonentsActiveState"
      [isVideoSurveillance]="true"
      [xlgWDownBreakpoint]="xlgWDownBreakpoint$ | async"
      (toggleFilterRow)="onToggleFilterRow()"
      (resetFilters)="onResetFilters()"
      (addPhone)="onAddPhone()"
      (delegate)="onDelegate()"
      (addConnections)="onAddConnections()"
      (setTarrif)="onTariff()"
      (manage)="onManage()"
      (deleteFlat)="onDeleteFlat()"
      (deleteSharedAbonents)="onDeleteSharedAbonents()"
      (addPhone)="
        onAddPhone($event.address, $event.flat)
      "></app-service-flats-menu>
    <app-service-title-row>
      <div
        class="services"
        services>
        <div class="service-flats__title-keeper">
          <h4 class="service-flats__title">
            {{ 'services.video_surveillance.flats.video_surveillance' | translate }}
          </h4>
          <a
            class="service-flats__title-icon td-tariff"
            matTooltipClass="tooltip"
            matTooltip="{{ 'services.video_surveillance.flats.tooltip.activate' | translate }}"
            target="_blank">
            <mat-icon svgIcon="info"></mat-icon>
          </a>
          <span
            class="service-flats__title-tariff"
            *ngIf="abonentsBillingEnabled$ | async"
          >
            {{ 'services.video_surveillance.flats.tariff' | translate }}
          </span>
        </div>
        <div></div>
      </div>
    </app-service-title-row>
    <div *ngIf="filterRowState">
      <app-service-flats-filters
        [services]="services"
        [flatsFilters]="flatsFilters"></app-service-flats-filters>
    </div>
    <div class="service-flats-list">
      <ngx-skeleton-loader
        *ngIf="loading && addresses?.length > 0; else contentBlock"
        count="10"
        [theme]="{
          'border-radius': '8px',
          height: '45px'
        }"></ngx-skeleton-loader>

      <ng-template #contentBlock>
        <div class="service-flats-list-wrapper">
          <ng-container
            *ngIf="addresses && addresses.length > 0; else emptyFlats">
            <ng-container *ngFor="let address of addresses">
              <ng-container
                *ngIf="
                  flats &&
                  (flats[address.entrance.id]?.flatsCount ||
                    flats[address.entrance.id]?.virtualFlatsCount)
                ">
                <app-flat-cards-table
                  class="service-flats-list-wrapper"
                  #flatCardsTable
                  [address]="address"
                  [entranceFlats]="flats[address.entrance.id]"
                  [servicesTemplate]="servicesTemplate"
                  [serviceType]="ServicesTypes.VIDEO_SURVEILLANCE"
                  [abonentsBillingEnabled]="abonentsBillingEnabled$ | async"
                  [tariff]="tariff$ | async"
                  (flatChecked)="flatChecked($event)"
                  (flatUnchecked)="flatUnchecked($event)"
                  (handleFlatsToManage)="handleFlatsToManage($event)"
                  (addPhone)="onAddPhone()"></app-flat-cards-table>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>

<ng-template
  #servicesTemplate
  let-flat="flat"
  let-address="address"
  let-account="account">
  <app-flat-service
    [flat]="flat"
    [address]="address"
    [account]="account"
    [serviceType]="ServicesTypes.VIDEO_SURVEILLANCE"
    [abonentsBillingEnabled]="abonentsBillingEnabled$ | async"
    [xlgWDownBreakpoint]="xlgWDownBreakpoint$ | async"
    [mdWUpBreakpoint]="mdWUpBreakpoint$ | async"
    (addService)="onAddVideoSurveillance($event.address, $event.flat)"
    (blockService)="onBlockService($event.flat)"></app-flat-service>
</ng-template>

<ng-template #emptyFlats>
  <app-info-block>
    <ng-container info-block-text>
      {{ 'services.video_surveillance.flats.message.empty_flats' | translate }}
    </ng-container>
  </app-info-block>
</ng-template>

<ng-template #emptyAddresses>
  <app-info-block>
    <ng-container info-block-text>
      {{ 'services.video_surveillance.flats.message.empty_addresses' | translate }}
    </ng-container>
  </app-info-block>
</ng-template>
