import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {
  VideoManagerPageDataService
} from '@app/views/services/submodules/video-manager/services/video-manager-page-data.service';
import {VideoManagerN} from '@app/views/services/submodules/video-manager/models/view-manager';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {VideoType} from '@app/shared/containers/camera/camera-thumbnail/models/videoType';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {
  VideoManagerPageActionsService
} from '@app/views/services/submodules/video-manager/services/video-manager-page-actions.service';
import {Router} from '@angular/router';
import {isEmpty} from 'lodash';
import {TitleBarComponentN} from '@app/core';

@Component({
  selector: 'app-video-manager-page',
  templateUrl: './video-manager-page.component.html',
  styleUrls: ['./video-manager-page.component.scss', '../../styles/video-manager-shared.styles.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VideoManagerPageComponent implements OnInit, OnDestroy {
  protected readonly videoMode = VideoType;
  public readonly GetViewScheme = VideoManagerN.GetViewScheme;
  public readonly GetMaxBlockByViewScheme = VideoManagerN.GetMaxBlockByViewScheme;

  public grids: VideoManagerN.Screen[] = [];
  public showAllInWindow = false;

  public get showAllAction(): TitleBarComponentN.TitleBarAction {
    return {
      label: this.showAllInWindow ? this.translate.instant('core.title_bar.close_all') : this.translate.instant('core.title_bar.open_all'),
      icon: 'pi pi-desktop',
      disabled: false,
      action: () => this.showAllInWindow ? this.closeAll() : this.openAll(),
    };
  }

  private showedGrid: Record<number, any> = {};
  private destroy = new Subject<void>();

  constructor(
    public videoManagerPageDataService: VideoManagerPageDataService,
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private videoManagerPageActionsService: VideoManagerPageActionsService,
    private router: Router,
  ) {
  }

  public ngOnInit(): void {
    this.videoManagerPageDataService.loaded.pipe(takeUntil(this.destroy)).subscribe((loaded) => {
      if (loaded) {
        this.loadScreens();
        if (!isEmpty(this.grids)) {
          this.updateHeaderActiuons();
        }
      }
    });
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public updateHeaderActiuons(): void {
    this.videoManagerPageActionsService.actions = [this.showAllAction];
  }

  public removeScreen(screen: VideoManagerN.Screen): void {
    this.confirmationService.confirm({
      header: this.translate.instant('video.manager.common.removing_screen'),
      message: this.translate.instant('video.manager.common.are_you_sure'),
      icon: 'pi pi-info-circle',
      accept: () => {
        this.videoManagerPageDataService.removeScreen(screen.id);
        this.loadScreens();
      },
      reject: () => {
      },
      key: 'confDialog'
    });
  }

  public getUrl(grid: VideoManagerN.Screen): string {
    return this.router.serializeUrl(this.router.createUrlTree(['/services/video-manager/show', grid?.id]));
  }

  public closedWindow(grid: VideoManagerN.Screen, data: boolean): void {
    this.showedGrid[grid.id] = false;
    const isAllClosed = Object.values(this.showedGrid).every((value) => value === false);
    if (isAllClosed) {
      this.showAllInWindow = false;
      this.updateHeaderActiuons();
    }
  }

  private loadScreens(): void {
    this.grids = this.videoManagerPageDataService.getScreens();
    this.grids.forEach((g) => this.showedGrid[g.id] = false);
  }

  private openAll(): void {
    this.showAllInWindow = !this.showAllInWindow;
    this.grids.forEach((g) => {
      this.showedGrid[g.id] = {
        state: true,
        window: window.open(this.getUrl(g), '', `width=${screen.width}, height=${screen.height}, fullscreen=yes, directories=no, location=no, menubar=no, resizable=no, scrollbars=no, status=no, toolbar=no`),
        cheker: setInterval(() => {
          if (this.showedGrid[g.id]?.window && this.showedGrid[g.id]?.window?.closed) {
            this.showedGrid[g.id].window = null;
            this.closedWindow(g, true);
            clearInterval(this.showedGrid[g.id]?.cheker);
            this.updateHeaderActiuons();
          }
        }, 500)
      };
    });
    this.updateHeaderActiuons();
  }

  private closeAll(): void {
    this.showAllInWindow = !this.showAllInWindow;
    this.grids.forEach((g) => {
      this.showedGrid[g.id].window?.close();
      clearInterval(this.showedGrid[g.id]?.cheker);
    });
    this.updateHeaderActiuons();
  }

}
