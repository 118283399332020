import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { LocationResponse } from '@app/shared/models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShieldModelService extends ApiService {
    constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
        super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1']);
    }

    public getModels(): Observable<LocationResponse[]> {
        return this.get(`/shield_models`, 1);
    }

    public createModel(name: string): Observable<void> {
        const body: Object = { name: name };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.post('/shield_models', 1, body, { headers });
    }
}
