import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import { Observable } from 'rxjs';
import { CompanyDataResponse } from '../../store/company-page.state';
import {environment} from 'environments/environment';

@Component({
  selector: 'app-company-about-page',
  templateUrl: './company-about-page.component.html',
  styleUrls: ['./company-about-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyAboutPageComponent {
  public readonly environment = environment;
  @Input() payment$: Observable<number>;
  @Input() paymentLoading$: Observable<boolean>;
  @Input() company$: Observable<Partial<CompanyDataResponse>>;
  @Input() loading$: Observable<boolean>;
  @Input() smWDownBreakpoint$: Observable<boolean>;

  public showSettingsEdit = false;
  public showContactsEdit = false;

  public toggleSettingsEdit(value: boolean): void {
    this.showSettingsEdit = value;
  }

  public toggleContactsEdit(value: boolean): void {
    this.showContactsEdit = value;
  }
}
