import { Address } from './address';
import {IServicesConnection} from '@app/shared/entities/rd';
import { SignUpType } from './sign-ups/sign-ups-types.enum';

export interface SignUpResponse {
  signUpId: number;
  abonentId: number;
  phone: number;
  houseId: number;
  isVirtual: boolean;
  isFreeAddress: boolean;
  isOwner: boolean;
  isDelegated: boolean;
  entranceId: number;
  address: Address;
  createdAt: number;
  updatedAt: number;
  companyName: string;
  companyId: number;
  addressStr: string;
  adCampaign: string;
  adSource: string;
  signUpType: SignUpType;
  status: string;
  services: IServicesConnection[];
  disconnectionReason?: string;
}
