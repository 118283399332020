<mat-card class="card-container links">
  <div class="header">
    <div class="title-block">
      {{ 'company.about.page.links.title' | translate }}
    </div>
  </div>
  <mat-card-content
    class="links-content"
    *ngIf="links">
    <div class="links-item">
      <span>Android</span>
      <a [href]="links.linkAndroid" target="_blank">
        {{ links.linkAndroid }}
      </a>
    </div>
    <div class="links-item">
      <span>iOS</span>
      <a [href]="links.linkiOS" target="_blank">
        {{ links.linkiOS }}
      </a>
    </div>
    <div class="links-item" *ngIf="links.linkHuawei">
      <span>Huawei</span>
      <a [href]="links.linkHuawei" target="_blank">
        {{ links.linkHuawei }}
      </a>
    </div>
  </mat-card-content>
</mat-card>
