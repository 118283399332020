<div class="apartment-video-service-page__wrapper">
  <app-navbar [links]="links" [neutral]="false" [active]="activeLink" (select)="onChangePage($event.idx)"></app-navbar>
  <p-card *ngIf="!loaded" class="apartment-video-service-page--skeleton">
    <p-skeleton width="100%" height="5rem"></p-skeleton>
  </p-card>
  <p-card *ngIf="!loaded" class="apartment-video-service-page--skeleton">
    <p-skeleton width="100%" height="5rem"></p-skeleton>
  </p-card>
  <ng-container *ngIf="loaded">
    <ng-container [ngSwitch]="activeLink">
      <app-apartment-video-service-activity *ngSwitchCase="PageTabs.activity" [serviceInfo]="serviceInfo"></app-apartment-video-service-activity>
      <app-apartment-video-service-settings *ngSwitchCase="PageTabs.settings" [serviceInfo]="serviceInfo" (reloadData)="onReloadData($event)"></app-apartment-video-service-settings>
      <app-delegation *ngSwitchCase="PageTabs.access"></app-delegation>
      <app-apartment-video-service-settings *ngSwitchDefault [serviceInfo]="serviceInfo" (reloadData)="onReloadData($event)"></app-apartment-video-service-settings>
    </ng-container>
  </ng-container>
</div>
