import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConnectionCreateRequest } from '@app/shared/entities/rd';
import { DialogWrapperData } from '@app/shared/ui';
import { Observable } from 'rxjs';
import { ServiceConnectionPopupBody, ServiceConnectionPopupResponse } from './models';
import { ServciceConnectionPopupService } from './service-connection-popup.service';

@Component({
  selector: 'app-service-connection-popup',
  templateUrl: './service-connection-popup.component.html',
  styleUrls: ['./service-connection-popup.component.scss'],
  providers: [ServciceConnectionPopupService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceConnectionPopupComponent implements OnInit {
  state$: Observable<'loading' | 'close' | 'loaded'> = this.popupService.state$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<ServiceConnectionPopupBody, Partial<ServiceConnectionPopupResponse>>,
    private popupService: ServciceConnectionPopupService,
    private dialogRef: MatDialogRef<ServiceConnectionPopupComponent>
  ) { }

  ngOnInit() {
    this.popupService.addStateChangeListener((state: 'loading' | 'close' | 'loaded') => {
      if (state === 'close') {
        this.dialogRef.close();
      }
    });
  }

  onSubmitConnections(request: ConnectionCreateRequest) {
    this.data.submit({ request });
  }
}
