import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPaymentsConfirmDialogWizard } from '@app/views/payments';

@Component({
  selector: 'app-payments-confirm-bottom-sheet',
  templateUrl: './payments-confirm-bottom-sheet.component.html',
  styleUrls: ['./payments-confirm-bottom-sheet.component.scss']
})
export class PaymentsConfirmBottomSheetComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public paymentsConfirmDialogWizard: IPaymentsConfirmDialogWizard) { }

  public onRisksAccepts(): void {
    this.paymentsConfirmDialogWizard.risksAccepts();
  }
}
