import { Camera, RdaResponse } from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';
import { TranslationTuningResponse } from '@app/shared/models';
import { SoftwareIntercomWizardPopupStepper } from './software-intercom-wizard-popup-stepper.type';

export type SoftwareIntercomWizardUpdateStep = Partial<Pick<SoftwareIntercomWizardPopupStepper, 'complete' | 'disabled' | 'hidden' | 'initialized'>>;

export type SoftwareIntercomWizardIntercomStepData = [RdaResponse[], SoftwareIntercomWizardUpdateStep];
export type SoftwareIntercomWizardIntercomPanelStepData = [RdaResponse[], SoftwareIntercomWizardUpdateStep];
export type SoftwareIntercomWizardTranslationsStepData = [RdaResponse[], SoftwareIntercomWizardUpdateStep, Dictionary<TranslationTuningResponse[]>];
export type SoftwareIntercomWizardCameraStepData = [Camera[], SoftwareIntercomWizardUpdateStep];
export type SoftwareIntercomWizardTariffStepData = [number, RdaResponse[], SoftwareIntercomWizardUpdateStep];
export type SoftwareIntercomWizardChecklistStepData = [SoftwareIntercomWizardUpdateStep, SoftwareIntercomWizardUpdateStep, SoftwareIntercomWizardUpdateStep, SoftwareIntercomWizardUpdateStep, SoftwareIntercomWizardUpdateStep];
