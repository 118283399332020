import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Camera, IntercomPanelResponse, KeysResponse, RdaResponse } from '@app/shared/entities/rd';
import { Address, LocationResponse } from '@app/shared/models';
import { MatDialogRef } from '@angular/material/dialog/dialog-ref';
import {
  ServiceCameraAlreadyExistsPopupComponent
} from '@app/shared/components/camera/camera-already-exists/service-camera-already-exists-popup/service-camera-already-exists-popup.component';
import { DialogWrapperData } from '@app/shared/ui';
import { Constants } from '@app/shared/helpers';
import { MatDialog } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-software-intercom-camera-popup-content',
  templateUrl: './software-intercom-camera-popup-content.component.html',
  styleUrls: ['./software-intercom-camera-popup-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomCameraPopupContentComponent {
  @Input() camera: Camera;
  @Input() rdas: RdaResponse[];
  @Input() keys: KeysResponse[];
  @Input() addresses: Address[];
  @Input() avaliableIntercomPanelsLocations: LocationResponse[];
  @Input() camerasLocations: LocationResponse[];
  @Input() set loading(state: boolean) {
    this._loading = state;
  }
  @Input() onvif: boolean;
  @Input() intercomPanels: IntercomPanelResponse[];

  @Input() set cameraAlreadyExists(camera: Camera | null) {
    if (camera) {
      this.showAlreadyExistsCamera(camera);
    }
  }

  private _loading: boolean;

  @Output() private submitCamera: EventEmitter<{ camera: Camera, intercomPanelId: number }> = new EventEmitter();
  @Output() private submitOnvif: EventEmitter<{ camera: Camera}> = new EventEmitter();

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  get loading(): boolean {
    return this._loading;
  }

  onSubmit(camera: Camera) {
    let intercomPanelId: number;

    if (!this.camera && camera.location?.id) {
      const intercomPanelForCamera: IntercomPanelResponse = this.intercomPanels.find(
        (intercomPanel: IntercomPanelResponse) => intercomPanel.location?.id === camera.location.id
      );

      if (intercomPanelForCamera) {
        intercomPanelId = intercomPanelForCamera.id;
      }
    }

    this.submitCamera.emit({ camera, intercomPanelId });
  }

  onSubmitOnvif(camera: Camera) {
    this.submitOnvif.emit({camera});
  }

  private showAlreadyExistsCamera(camera: Camera) {
    let dialogRef: MatDialogRef<
      ServiceCameraAlreadyExistsPopupComponent,
      DialogWrapperData<Camera, void>
      > = null;

    const dialogWizard: DialogWrapperData<Camera, void> = {
      title: this.translate.instant('software.intercom.camera.popup.content.show_already_exits.title'),
      componentName: 'ServiceCameraAlreadyExistsPopupComponent',
      body: camera,
      submit: (event) => {
        dialogRef.close();
      },
    };

    dialogRef = this.dialog.open(ServiceCameraAlreadyExistsPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data: dialogWizard
    });
  }
}
