import { Pipe, PipeTransform } from '@angular/core';
import { SignUpStatus } from '../models';
import {TranslateService} from '@ngx-translate/core';
import { SignUpType } from '../models/sign-ups/sign-ups-types.enum';

interface SignUp {
  status: SignUpStatus, 
  signUpType: SignUpType
}

@Pipe({ name: 'signUpStatus' })
export class SignUpStatusPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) {}

  transform(signUp: SignUp): string {
    switch (signUp.status.toLowerCase()) {
      case SignUpStatus.CONNECTED:
        if (signUp.signUpType === SignUpType.DISCONNECTION) {
          return this.translate.instant('enum.sign_up_status.disconnected');
        }

        return this.translate.instant('enum.sign_up_status.connected');
      case SignUpStatus.DELEGATED:
        return this.translate.instant('enum.sign_up_status.delegated');
      case SignUpStatus.PROCESSED:
        return this.translate.instant('enum.sign_up_status.processed');
      case SignUpStatus.REJECTED:
        return this.translate.instant('enum.sign_up_status.rejected');
      case SignUpStatus.UNPROCESSED:
        return this.translate.instant('enum.sign_up_status.unprocessed');
      case SignUpStatus.WAIT_ABONENT_CONFIRMATION:
        return this.translate.instant('enum.sign_up_status.wait_abonent_information');
      default:
        return '';
    }
  }
}
