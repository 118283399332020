import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { InfoBlockModule } from '../../../ui';
import { IpIntercomHandsetLockParamsContainerComponent } from './ip-intercom-handset-lock-params-container.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, MaterialModule, InfoBlockModule, TranslateModule],
  declarations: [IpIntercomHandsetLockParamsContainerComponent],
  exports: [IpIntercomHandsetLockParamsContainerComponent],
})
export class IpIntercomHandsetLockParamsContainerModule {}
