import { createSelector } from '@ngrx/store';
import { SharedModuleStates } from '../../reducers/reducers';
import { sharedModuleSelector } from '../shared-module.state';

export const getDeleteCameraState = createSelector(
  sharedModuleSelector,
  (state: SharedModuleStates) => state.deleteCamera
);

export const getDeleteCameraSuccess = createSelector(
  getDeleteCameraState,
  state => state.success
);

export const getDeleteCameraFailure = createSelector(
  getDeleteCameraState,
  state => state.error
);

export const getDeleteCameraLoading = createSelector(
  getDeleteCameraState,
  state => state.loading
);
