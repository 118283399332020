import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { PhoneInputModule } from '@app/shared/components';
import { SharedPipesModule } from '@app/shared/pipes';
import { DialogWrapperModule } from '@app/shared/ui';
import { ServiceDelegationPopupContentComponent } from './service-delegation-popup-content';
import { ServiceDelegationPopupComponent } from './service-delegation-popup.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PhoneInputModule,
        SharedPipesModule,
        FormsModule,
        ReactiveFormsModule,
        DialogWrapperModule,
        NgxTranslateModule
    ],
    declarations: [
        ServiceDelegationPopupComponent,
        ServiceDelegationPopupContentComponent
    ],
    exports: [
        ServiceDelegationPopupComponent
    ]
})
export class ServiceDelegationPopupModule { }
