import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {ServiceApiService} from '@app/shared/entities/rd';
import {PersonalSurveillance} from '@app/shared/entities/rd/services/models/personal-surveillance.model';

@Injectable({
  providedIn: 'root'
})
export class ApartmentVideoSurveillanceByIdResolver implements Resolve<PersonalSurveillance.ServiceByIdResponse> {

  constructor(
    private serviceApiService: ServiceApiService,
  ) {
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<PersonalSurveillance.ServiceByIdResponse | null> {
    const id = +route.params['id'];
    if (!id) {
      return of(null);
    }
    return this.serviceApiService.getPersonalSurveillance(id);
  }
}
