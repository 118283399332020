import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { RdaResponse } from '@app/shared/entities/rd/rda';
import { PagedResponse } from '@app/shared/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { BatchRequest, BatchResponse } from '../models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class BatchService extends ApiService {
  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1']);
  }

  getBatchesPage(page: number = 0, size: number = 10, search: string): Observable<PagedResponse<BatchResponse>> {
    const params = { page: page, size: size } as any;
    if (search) { params.search = search; }

    return this.get(`/batches/?sort=createdAt,desc`, 1, { params: params });
  }

  getAllBatches(search: string): Observable<BatchResponse[]> {
    const params = {} as any;
    if (search) { params.search = search; }

    return this.get<BatchResponse[]>(`/batches/all`, 1, { params });
  }

  putNewBatch(request: BatchRequest): Observable<boolean> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.post('/batches', 1, request, { headers });
  }

  getBatchAdapters(batchId: number, search?: string): Observable<RdaResponse[]> {
    const params = {} as any;
    if (search) { params.search = search; }

    return this.get<RdaResponse[]>(`/batches/${batchId}/rdas`, 1, { params: params });
  }
}
