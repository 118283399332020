import { Injectable } from '@angular/core';
import { IntercomType, RdaResponse, ServiceCreateRequest } from '@app/shared/entities/rd';
import { State } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { GateMode } from '../models';
import { GatesPageModel } from '../models/gates-page.model';
import { AddGateEntranceIntercoms, ConnectGateEntranceIntercoms, CreateGate, DeleteGate, GetGateEntrancesIntercoms, GetGatesPage, RemoveGateEntranceIntercoms, SetGateIndex, SetGateIntercom, SetGateIntercomType, SetGateMode, UpdateGateEntranceIntercoms, ClearGateState } from './gates-page.actions';
import { gatesPageSelectors } from './gates-page.selectors';
import { PageData } from '@app/shared/models';

@Injectable()
export class GatesPageFacade {
    readonly gates$: Observable<GatesPageModel[]> = this.store.select(gatesPageSelectors.selectGates);
    readonly loading$: Observable<boolean> = this.store.select(gatesPageSelectors.selectLoading);
    readonly intercomType$: Observable<IntercomType> = this.store.select(gatesPageSelectors.selectIntercomType);
    readonly entrancesIntercoms$: Observable<RdaResponse[]> = this.store.select(gatesPageSelectors.selectEntrancesIntercoms);
    readonly intercom$: Observable<RdaResponse> = this.store.select(gatesPageSelectors.selectIntercom);
    readonly index$: Observable<string> = this.store.select(gatesPageSelectors.selectIndex);
    readonly mode$: Observable<GateMode> = this.store.select(gatesPageSelectors.selectMode);
    readonly pageData$: Observable<PageData> = this.store.select(gatesPageSelectors.selectPageData);

    constructor(
        private store: Store<State>
    ) { }

    getPage(page: number = 0, size: number = 10, name?: string) {
        this.store.dispatch(new GetGatesPage(page, size, name));
    }

    create(request: ServiceCreateRequest, options: { filterValue: string, pageIndex: number }) {
        this.store.dispatch(new CreateGate(request, options));
    }

    delete(serviceId: number, options: { pageIndex: number, pageSize: number, filterValue: string }) {
        this.store.dispatch(new DeleteGate(serviceId, options));
    }

    getEntrancesIntercoms(entrancesIds: number[]) {
        this.store.dispatch(new GetGateEntrancesIntercoms(entrancesIds));
    }

    addEntranceIntercoms(entrancesId: number) {
        this.store.dispatch(new AddGateEntranceIntercoms(entrancesId));
    }

    updateEntranceIntercoms(index: string) {
        this.store.dispatch(new UpdateGateEntranceIntercoms(index));
    }

    connectEntranceIntercoms(index: string, intercomsIds: number[]) {
        this.store.dispatch(new ConnectGateEntranceIntercoms(index, intercomsIds));
    }

    removeEntranceIntercoms(intercomsIds: number[]) {
        this.store.dispatch(new RemoveGateEntranceIntercoms(intercomsIds));
    }

    setIntercomType(intercomType: IntercomType) {
        this.store.dispatch(new SetGateIntercomType(intercomType));
    }

    setIntercom(intercom: RdaResponse) {
        this.store.dispatch(new SetGateIntercom(intercom));
    }

    setIndex(index: string) {
        this.store.dispatch(new SetGateIndex(index));
    }

    setMode(mode: GateMode) {
        this.store.dispatch(new SetGateMode(mode));
    }

    clearState() {
        this.store.dispatch(new ClearGateState());
    }
}
