import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isValidUrl } from '@app/shared/helpers/isValidLink';

import { CompanyDataResponse } from '@app/views/company/store/company-page.state';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-company-info',
    templateUrl: './company-info.component.html',
    styleUrls: ['./company-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyInfoComponent {
    @Input() company: CompanyDataResponse;

    constructor(
      private translate: TranslateService
    ) {}

    public get inn(): string {
        return this.company.inn ? this.company.inn : '-';
    }

    public get ogrn(): string {
        return this.company.ogrn ? this.company.ogrn : '-';
    }

    public get paymentBic(): string {
        return this.company.paymentBic ? this.company.paymentBic : '-';
    }

    public get paymentBank(): string {
        return this.company.paymentBank ? this.company.paymentBank : '-';
    }

    public get paymentCorrespondentAccount(): string {
        return this.company.paymentCorrespondentAccount ? this.company.paymentCorrespondentAccount : '-';
    }

    public get licenseeShort(): string {
        return this.company ? this.company.licenseeShort : '-';
    }

    public get paymentAccount(): string {
        return this.company.paymentAccount ? this.company.paymentAccount : '-';
    }

    public get licensee(): string {
        return this.company.licensee ? this.company.licensee : '-';
    }

    public get licenseAgreement(): string {
        if (isValidUrl(this.company.licenseAgreement)) {
            return '';
        }
        return this.company.licenseAgreement ? this.company.licenseAgreement : '-';
    }
    public get address(): string {
        return this.company.address ? this.company.address : '-';
    }

    public get edm(): string {
        return this.translate.instant(this.company.hasEdm ? 'enum.bool.yes' : 'enum.bool.no');
    }
}
