import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../reducers/reducers';
import { intercomModuleSelector } from './intercom-module.state';

export const selectAllCompaniesState = createSelector(
  intercomModuleSelector,
  (state: IntercomModuleState) => state.companies
);
export const getAllCompanies = createSelector(
  selectAllCompaniesState,
  state => state
);
export const getAllCompaniesSuccess = createSelector(
  selectAllCompaniesState,
  state => state.companies
);
export const getAllCompaniesLoading = createSelector(
  selectAllCompaniesState,
  state => state.loading
);
export const getAllCompaniesError = createSelector(
  selectAllCompaniesState,
  state => state.error
);
