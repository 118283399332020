import { Camera } from '@app/shared/entities/rd/camera/models';
import { RdaResponse } from '@app/shared/entities/rd/rda';
import { LogsComponentType } from '@app/shared/models';

export interface ServiceActivitySource {
    id: number;
    label: string;
    type: LogsComponentType;
    status: boolean;
    loading: boolean;
    component: RdaResponse | Camera;
}
