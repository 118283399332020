import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { KeysResponse } from '@app/shared/entities/rd/keys/models';

@Component({
  selector: 'app-intercom-keys-container',
  templateUrl: './intercom-keys-container.component.html',
  styleUrls: ['./intercom-keys-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntercomKeysContainerComponent {
  @Input() keys: KeysResponse[];
  @Input() readonly: boolean;
  @Input() loading: boolean;

  @Output() private editKey: EventEmitter<{ key: KeysResponse }> = new EventEmitter();
  @Output() private addKey: EventEmitter<void> = new EventEmitter();
  @Output() private deleteKey: EventEmitter<{ key: KeysResponse }> = new EventEmitter();

  constructor(
    public resolution: ResolutionService
  ) { }

  onAdd() {
    this.addKey.emit();
  }

  onEdit(key: KeysResponse) {
    this.editKey.emit({ key });
  }

  onDelete(key: KeysResponse) {
    this.deleteKey.emit({ key });
  }
}
