<app-dialog-wrapper
  [componentName]="data.componentName"
  [progress]="(popupState$ | async) === 'loading'"
  [title]="data.title"
>
  <app-software-intercom-camera-popup-content
    [camera]="data.body.camera"
    [rdas]="rdas$ | async"
    [keys]="keys$ | async"
    [addresses]="addresses$ | async"
    [avaliableIntercomPanelsLocations]="avaliableIntercomPanelsLocations$ | async"
    [camerasLocations]="camerasLocations$ | async"
    [intercomPanels]="intercomPanels$ | async"
    [onvif]="onvif$ | async"
    [loading]="(popupState$ | async) === 'loading'"
    [cameraAlreadyExists]="cameraAlreadyExists$ | async"
    (submitCamera)="onSubmit($event.camera, $event.intercomPanelId)"
    (submitOnvif)="onSubmitOnvif($event.camera)"
  ></app-software-intercom-camera-popup-content>
</app-dialog-wrapper>
