<div class="page-container entrance-page-component">
  <div class="entrance-page-component-empty" *ngIf="incorrectId$ | async; else contentBlock">
    <app-info-block>
      <ng-container info-block-text>
        {{ 'entrance.page.message.no_data' | translate }}
      </ng-container>
    </app-info-block>
  </div>

  <ng-template #contentBlock>
    <mat-card class="card-container">
      <mat-card-content>
        <app-info-block>
          <img
            info-block-image
            class="entrance-page-component-empty__img"
            [src]="resourcesHelper.getResource(resourcePath.WARNING_LG) | safeUrl"
          >
          <ng-container info-block-text>
            {{ 'entrance.page.message.read_only' | translate }}
          </ng-container>
        </app-info-block>
      </mat-card-content>
    </mat-card>

    <ng-container
      #entrance="var"
      [appVar]="entrance$ | async"
    >
      <app-entrance-warnings
        *ngIf="showEntranceWarning(entrance.appVar)"
        [entrance]="entrance$ | async"
      ></app-entrance-warnings>
    </ng-container>

    <app-service-cameras-warning
      [cameras]="camerasWithErrors$ | async"
      *ngIf="(camerasWithErrors$ | async)?.length > 0"
    ></app-service-cameras-warning>

    <div class="entrance-page-component-cards-grid">
      <app-entrance-card
        [entrance]="entrance$ | async"
        [loading]="loadingEntrance$ | async"
      ></app-entrance-card>

      <app-rda-card
        [rda]="rda$ | async"
        [company]="company$ | async"
        [pbxOnRda]="pbxOnRda$ | async"
        [loading]="loadingEntrance$ | async"
        [gitlabComponentsVersions]="gitlabComponentsVersions$ | async"
      ></app-rda-card>
    </div>

    <app-cameras-card
      [cameras]="cameras$ | async"
      [loading]="camerasLoading$ | async"
    ></app-cameras-card>

    <app-rda-keys-card
      [keys]="keys$ | async"
      [rda]="rda$ | async"
      [loading]="keysLoading$ | async"
    ></app-rda-keys-card>

    <app-flats-card
      [flats]="flats$ | async"
      [rdaExists]="!!(rda$ | async)"
      [loading]="flatsLoading$ | async"
    ></app-flats-card>

    <ng-container
      #rda="var"
      [appVar]="rda$ | async"
    >
      <mat-card
        class="card-container"
        *ngIf="showServiceInfo(rda.appVar)"
      >
        <mat-card-header class="header">
          <div class="title-block">
            <mat-card-title>
              {{ 'entrance.page.header.service_info' | translate }}
            </mat-card-title>
          </div>
        </mat-card-header>
        <hr>

        <mat-card-content>
          <app-service-rda-logs
            [logs]="logs$ | async"
            [loading]="logsLoading$ | async"
            (loadEmit)="onLoadLogs(rda.appVar.uid, $event.timeRange)"
            (timeRangeChanged)="onLoadLogs(rda.appVar.uid, $event.timeRange)"
          ></app-service-rda-logs>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </ng-template>
</div>
