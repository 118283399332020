import { PlyrTemplateHelper } from '../../plyr-globals';

export class PlyrProgressBarControlCanvasHelper {
    private readonly CANVAS_WIDTH_MULTIPLIER = 200;
    private canvas: HTMLCanvasElement;

    constructor() {
        this.canvas = document.getElementById(PlyrTemplateHelper.PROGRESS_GAPS_ID) as HTMLCanvasElement;
        this.canvas.width = 100 * this.CANVAS_WIDTH_MULTIPLIER;
        this.canvas.height = 15;

        const context = this.canvas.getContext('2d');
        context.lineWidth = 1;
        context.lineCap = 'butt';
        context.save();
    }

    /**
     * Fill timeline using white color
     */
    clear() {
        this.drawGap(0, 100, '#ffffff');
    }

    /**
     * Draw gap using canvas rendering 2D context for gaps and events
     * @param {number} left start position in px
     * @param {number} width width in px
     * @param {string} color color in correct format (#000 or #000000)
     */
    drawGap(left: number, width: number, color: string) {
        const context = this.canvas.getContext('2d');

        context.beginPath();

        context.fillStyle = color;
        context.strokeStyle = color;

        context.moveTo(left * this.CANVAS_WIDTH_MULTIPLIER, 0);
        context.lineTo(left * this.CANVAS_WIDTH_MULTIPLIER, 15);
        context.lineTo((left + width) * this.CANVAS_WIDTH_MULTIPLIER, 15);
        context.lineTo((left + width) * this.CANVAS_WIDTH_MULTIPLIER, 0);
        context.lineTo(left * this.CANVAS_WIDTH_MULTIPLIER, 0);

        context.fill();
        context.stroke();
    }
}
