import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { GetDelegationResponse } from '@app/shared/entities/rd/delegations/models';
import { Constants } from '@app/shared/helpers';
import { EditPopupComponent } from '@app/shared/templates';
import { DeleteDelegationPopupComponent } from '../../pages/abonent-page/abonent-owner/delete-delegation-popup/delete-delegation-popup.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-abonent-delegations',
  templateUrl: './abonent-delegations.component.html',
  styleUrls: ['./abonent-delegations.component.scss']
})
export class AbonentDelegationsComponent {
  readonly displayedAbonentsColumns: string[] = ['value', 'resolved', 'actions'];
  @Input() delegations!: GetDelegationResponse[];
  @Input() deleteEnabled: boolean;
  @Input() changeActiveEnabled: boolean;
  @Input() toAbonent: boolean;
  @Output() private deleteDelegation: EventEmitter<{ delegationId: number }> = new EventEmitter();
  @Output() private changeDelegated: EventEmitter<{ abonentId: number }> = new EventEmitter();

  constructor(
    public resolution: ResolutionService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) { }

  onDeleteDelegation(delegationId: number) {
    this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '650px',
      data: {
        title: this.translate.instant('abonent.delegations.delete.title'),
        providedData: {},
        component: DeleteDelegationPopupComponent,
        componentName: 'DeleteAbonentDelegation',
        submit: (admit: boolean) => admit && this.deleteDelegation.emit({ delegationId })
      }
    });
  }

  changeDelegatedAbonent(abonentId: number) {
    this.changeDelegated.emit({ abonentId });
  }
}
