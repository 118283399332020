import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  LOCALE_ID,
  OnDestroy,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {CommonModule} from '@angular/common';
import {appearDisappear} from '@app/shared/animations/animations';
import {MenuItem} from 'primeng/api';
import {
  CustomPaginatorComponent,
  IPaginatorConfig
} from '@app/shared/components/prime-base-table/custom-paginator/custom-paginator.component';
import {SkeletonModule} from 'primeng/skeleton';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {Table, TableModule} from 'primeng/table';
import {InputSwitchModule} from 'primeng/inputswitch';
import {FormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {BehaviorSubject, Subject} from 'rxjs';
import {cloneDeep} from 'lodash';
import {GetSignUpsV2Query} from '@app/shared/models';
import {TruncatePipe} from '@app/shared/components/table-with-server-pagination/truncate.pipe';
import {GridRenderAction, RenderComponent} from '@app/shared/components/render/render.component';
import {OverlayPanel, OverlayPanelModule} from 'primeng/overlaypanel';
import {ServiceType} from '@app/views/services/submodules/shared/abonents-delegation/pipes/service-converter.pipe';
import {NgxTranslateModule} from '@app/translate/translate.module';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';

registerLocaleData(localeRu);
export interface IGridState {
  lastSavedFilters: GetSignUpsV2Query;
  lastSelectedPageSize: number;
  lastSelectedPage: number;
  sortBy: string;
  sortOrder: 'desc' | 'asc';
  nameFilter?: string;
}

export enum BodyAdditionField {
  base,
  status,
  requestType
}

export interface IPrimeTableConfig {
  key?: string;
  showActionColumn?: boolean;
  showCustomActionColumn?: boolean;
  showSwitchColumn?: boolean;
  showCheckboxColumn?: boolean;
  showDoublePaginator?: boolean;
  showStatusColumn?: boolean;
  hideDelete?: boolean;
  disableEdit?: boolean;
  disableDelete?: boolean;
  disableSwitch?: boolean;
  showRenderActionColumn?: boolean;
  renderOptions?: GridRenderAction<any, any, any> ;
  header: {
    name: string;
    field: string;
    className?: string;
    sort: boolean;
    tooltip?: string;
  }[];
  body: {
    isLink?: boolean;
    showArrow?: boolean;
    field: string;
    additionField?: {
      type: BodyAdditionField;
      field: string;
    };
    className: string;
    isListView?: boolean;
    isRequestStatus?: boolean;
    isStatus?: boolean;
    isSwitch?: boolean;
    isRight?: boolean;
    icon?: string;
    style?: string;
    truncateLength?: number;
    useRender?: boolean;
    renderOptions?: GridRenderAction<any, any, any> ;
  }[];
}

export interface ICustomSort<TableModal> {
  data: TableModal;
  mode: 'single' | 'multiply';
  sortField: string;
  sortOrder: 1 | -1;
}

export interface MenuItemExpanded extends MenuItem {
  value?: string;
  format?: string;
}

export interface IMenuItems<T> {
  name: ((value: T) => string) | string;
  action: (value: T) => void;
  style?: string;
}

const BASE_SCROLL_HEIGHT = '100%';

@Component({
  selector: 'app-table-with-server-pagination',
  templateUrl: './table-with-server-pagination.component.html',
  styleUrls: ['./table-with-server-pagination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [appearDisappear],
  imports: [CommonModule,
    ProgressSpinnerModule,
    TableModule,
    InputSwitchModule,
    FormsModule,
    CustomPaginatorComponent,
    ButtonModule,
    TooltipModule,
    SkeletonModule,
    TruncatePipe,
    OverlayPanelModule,
    NgxTranslateModule,
    MatButtonModule,
    MatIconModule,
    RenderComponent,
  ],
  standalone: true,
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' }
  ]
})
export class TableWithServerPaginationComponent <TableModal, PaginatorModel> implements OnDestroy {
  @ViewChild('table', { static: false }) table: Table;

  public readonly BodyAdditionField = BodyAdditionField;
  protected readonly ServiceType = ServiceType;
  public totalRecords: number;
  public initDataMode = true;
  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public truncateLength = 25;

  private unSubscribe$: Subject<void> = new Subject<void>();

  @Input() set tableData(data: TableModal[]) {
    if (data) {
      this._tableData = cloneDeep(data);
      this.totalRecords = this._tableData.length;
    }
  }

  @Input() menuItems: IMenuItems<TableModal>[] = [];
  @Input() primeTableConfig: IPrimeTableConfig;
  @Input() resizableColumns = true;
  @Input() scrollable = true;
  @Input() styleClass: string;
  @Input() scrollTableHeight: string = BASE_SCROLL_HEIGHT;
  @Input() linkNameField: string;
  @Input() customSort = true;
  @Input() set paginatorConfig(paginatorConfig: IPaginatorConfig<PaginatorModel>) {
    this._paginatorConfig = paginatorConfig;
  }
  @Input() set waiting(wait: boolean) {
    this.loading$.next(wait);
  }
  @Input() loading = true;

  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() edit: EventEmitter<TableModal> = new EventEmitter<TableModal>();
  @Output() delete: EventEmitter<TableModal> = new EventEmitter<TableModal>();
  @Output() navigate: EventEmitter<TableModal> = new EventEmitter<TableModal>();
  @Output() statusChange: EventEmitter<TableModal> = new EventEmitter<TableModal>();
  @Output() selectionChange: EventEmitter<TableModal[]> = new EventEmitter<TableModal[]>();
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() sort: EventEmitter<ICustomSort<TableModal>> = new EventEmitter<ICustomSort<TableModal>>();
  @Output() showCode: EventEmitter<TableModal> = new EventEmitter<TableModal>();
  @Output() arrowClick: EventEmitter<TableModal> = new EventEmitter<TableModal>();

  private _tableData: TableModal[] = [];
  private _paginatorConfig: IPaginatorConfig<PaginatorModel>;
  get paginatorConfig(): IPaginatorConfig<PaginatorModel> {
    return this._paginatorConfig;
  }
  private _selectedIds: number[];

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  get hasTableDataAndLoading(): boolean {
    return this.loading ? true : !!this.tableData.length;
  }

  get tableData(): TableModal[] {
    return this._tableData;
  }

  get selectedTableData(): number[] {
    return this._selectedIds;
  }

  get mockRows(): number[] {
    return new Array(10);
  }

  get mockHeaders(): number[] {
    return new Array(this.primeTableConfig.header.length + 1);
  }

  set selectedTableData(value: number[]) {
    this._selectedIds = value;
  }

  public onSelectionChange(entities: TableModal[]): void {
    this.selectionChange.emit(entities);
  }

  public onNavigate(entity: TableModal): void {
    this.navigate.emit(entity);
  }

  public onEdit(entity: TableModal): void {
    this.edit.emit(entity);
  }

  public onDelete(entity: TableModal): void {
    this.delete.emit(entity);
  }

  public onStatusChange(entity: TableModal): void {
    this.statusChange.emit(entity);
  }

  public sortFunction(config: ICustomSort<TableModal>): void {
    this.sort.emit(config);
  }

  public onPageChange(page: number): void {
    this.pageChange.emit(page);
  }

  public onPageSizeChange(entity: number): void {
    this.pageSizeChange.emit(entity);
  }

  public initLazyListener(res): void {
    // TODO ugly solution
    if (this.initDataMode === true) {
      this.initDataMode = false;
    } else {
      this.sortFunction(res);
    }
  }

  public onMenuItemSelect(item: IMenuItems<TableModal>, op: OverlayPanel, data: TableModal): void {
    item.action(data);
    op.toggle(false);
  }
}
