import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ActionTypes } from '../../actions/cameras.actions';

export interface State {
    success: boolean;
    error: string | HttpErrorResponse;
    loading: boolean;
}

export const initialState: State = {
    success: null,
    error: null,
    loading: false
};

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case ActionTypes.DisconnectDefaultCamera: {
            return {
                ...state,
                success: null,
                error: null,
                loading: true
            };
        }

        case ActionTypes.DisconnectDefaultCameraSuccess: {
            return {
                ...state,
                success: true,
                loading: false
            };
        }

        case ActionTypes.DisconnectDefaultCameraFailure: {
            return {
                ...state,
                error: action.error,
                loading: false
            };
        }

        default: {
            return state;
        }
    }
}
