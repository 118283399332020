import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IntercomKeyRecord, intercomKeyRecords, IntercomKeyType } from '@app/shared/entities/rd';
import { LocationResponse } from '@app/shared/models';

@Component({
  selector: 'app-intercom-key-delete-container',
  templateUrl: './intercom-key-delete-container.component.html',
  styleUrls: ['./intercom-key-delete-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntercomKeyDeleteContainerComponent {
  readonly intercomKeyRecords: Record<IntercomKeyType, IntercomKeyRecord> = intercomKeyRecords;

  @Input() location: LocationResponse;
  @Input() intercomKeyType: IntercomKeyType;
  @Input() loading: boolean;
  @Output() private submit: EventEmitter<void> = new EventEmitter();

  onDelete() {
    this.submit.emit();
  }
}
