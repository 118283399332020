import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LogsResponse, LogsResponseMessage} from '@app/shared/models';
import {GraylogMessage, GraylogRdaPatterns, GraylogResponse} from '../models';
import {GraylogApiService, IGraylogQuery} from './graylog-api.service';
import {GraylogRdaLogsParser} from './graylog-rda-logs-parser.service';
import {TranslateService} from '@ngx-translate/core';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({providedIn: 'root'})
export class GraylogRdaApiService extends GraylogApiService {
  private readonly translate: TranslateService;

  constructor(
    httpClient: HttpClient,
    humanErrorTranslationService: HumanErrorTranslationService,
    translate: TranslateService
  ) {
    super(httpClient, humanErrorTranslationService);

    this.translate = translate;
  }

  protected prepareQuery(sourceId: string): IGraylogQuery {
    return {
      rdaUid: sourceId
    };
  }

  protected prepareParsedResponse(graylogMessages: GraylogResponse): LogsResponse[] {
    const parsedMessages: LogsResponse[] = [];

    graylogMessages.messages.forEach(
      (graylogMessage: GraylogMessage) => {
        let graylogParsedMessage: LogsResponseMessage = null;

        for (const graylogRdaPatternsKey of Object.keys(GraylogRdaPatterns)) {
          const regExp: RegExp = new RegExp(GraylogRdaPatterns[graylogRdaPatternsKey]);
          const message: string = graylogMessage.message.message;

          if (regExp.test(message)) {
            graylogParsedMessage = GraylogRdaLogsParser[graylogRdaPatternsKey](message, this.translate);
            break;
          }
        }

        if (graylogParsedMessage) {
          parsedMessages.push({
            message: graylogParsedMessage,
            createdAt: graylogMessage.message.timestamp
          });
        }
      }
    );

    return parsedMessages;
  }
}
