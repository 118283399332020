import { Injectable } from '@angular/core';
import { ServicesTypes } from '@app/shared/models';
import { ServiceConnectionWithType } from '@app/views/services/models';
import { forkJoin, from } from 'rxjs';
import { FlatApiService } from '../../flat';
import { ServiceBlockPhysicalTubeRequest, ServiceBlockPhysicalTubeResponse, ServiceConnection, ServiceCreateConnectionRequest, ServiceCreateResponse, ServiceResponse } from '../models';
import { AccountApiService } from './../../account/services/account-api.service';
import { HardwareIntercomApiService } from './hardware-intercom-api.service';
import { ServiceApiService } from './service-api.service';

@Injectable({
    providedIn: 'root'
})
export class ServiceUtilsService {
    constructor(
        private serviceApiService: ServiceApiService,
        private hardwareIntercomApiService: HardwareIntercomApiService,
        private flatApiService: FlatApiService,
        private accountApiService: AccountApiService
    ) { }

    loadConnectionsWithData(
        primaryService: Pick<ServiceResponse, 'id' | 'type'>,
        dependantServices: Pick<ServiceResponse, 'id' | 'type'>[],
        entrancesIds: number[]
    ) {
        return forkJoin([
            from(this.getConnectoins(primaryService, dependantServices)),
            forkJoin([
                ...entrancesIds.map((entranceId: number) => from(this.serviceApiService.getEntrancesFlats(entranceId)))
            ]),
            this.serviceApiService.getSignUps(primaryService.id)
        ]);
    }

    async getConnectoins(
        primaryService: Pick<ServiceResponse, 'id' | 'type'>,
        dependantServices?: Pick<ServiceResponse, 'id' | 'type'>[],
    ): Promise<ServiceConnectionWithType[]> {
        let connections: ServiceConnectionWithType[] = [];

        const primaryServiceConnections: ServiceConnection[] =
            await this.serviceApiService.getConnections(primaryService.id).toPromise();

        if (primaryServiceConnections?.length) {
            connections = [
                ...primaryServiceConnections.map(connection =>
                    Object.assign(connection, { type: primaryService.type })
                )
            ];
        }

        for (const dependantService of dependantServices) {
            const dependantServiceConnections: ServiceConnection[] =
                await this.serviceApiService.getConnections(dependantService.id).toPromise();

            if (dependantServiceConnections?.length) {
                connections = [
                    ...connections,
                    ...dependantServiceConnections.map(connection =>
                        Object.assign(connection, { type: dependantService.type })
                    )
                ];
            }
        }

        return connections;
    }

    async blockPhysicalTube(request: ServiceBlockPhysicalTubeRequest): Promise<ServiceBlockPhysicalTubeResponse> {
        if (!request.dependantService) {
            const dependantService: ServiceCreateResponse = await this.hardwareIntercomApiService.create().toPromise();

            await this.serviceApiService.connectDependantService(request.serviceId, dependantService.id).toPromise();
            request.dependantService = { id: dependantService.id, type: ServicesTypes.HARDWARE_INTERCOM };
        }

        if (!request.connection) {
            const connectionRequest: ServiceCreateConnectionRequest = { flatId: request.flat.id };

            if (request.abonent) {
                if (!request.account) {
                    request.account = await this.accountApiService.createAbonentAndAccount(request.abonent.phone, request.companyId).toPromise();
                }
            }

            if (request.account) {
                connectionRequest.accountId = request.account.id;
            }

            const connection = await this.serviceApiService.createConnection(request.dependantService.id, connectionRequest).toPromise();
            request.connection = { ...connection, type: ServicesTypes.HARDWARE_INTERCOM };
        }

        await this.serviceApiService.blockServiceConnection(request.connection.id).toPromise();

        return {
            connection: request.connection,
            dependantService: request.dependantService,
            abonent: request.abonent,
            account: request.account,
            flat: request.flat
        };
    }

    async unblockPhysicalTube(connectionId: number) {
        await this.serviceApiService.unblockServiceConnection(connectionId).toPromise();
    }

    async deletePhysicalTube(
        flatId: number,
        connectionId: number
    ) {
        if (connectionId) {
            await this.serviceApiService.deleteServiceConnection(connectionId).toPromise();
        }

        await this.flatApiService.update(flatId, { virtual: true }).toPromise();
    }
}
