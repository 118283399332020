import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js';

@Pipe({ name: 'phone' })
export class PhonePipe implements PipeTransform {
  transform(value: string, args?: any): any {
    if (!value) {
      return value;
    }

    let countryShortName: string;
    let prefixWithoutPlus: string;
    if (value.startsWith('7') || value.startsWith('+7')) {
      countryShortName = 'RU';
      prefixWithoutPlus = '7';
    } else if (value.startsWith('380') || value.startsWith('+380')) {
      countryShortName = 'UA';
      prefixWithoutPlus = '380';
    } else if (value.startsWith('375') || value.startsWith('+375')) {
      countryShortName = 'BY';
      prefixWithoutPlus = '375';
    } else if (value.startsWith('55') || value.startsWith('+55')) {
      countryShortName = 'BR';
      prefixWithoutPlus = '55';
    }

    value = value.startsWith(prefixWithoutPlus) ? `+${value}` : value;
    if (value !== '7' && value !== '+7') {
      return parsePhoneNumber(value, 'RU').formatInternational();
    } else {
      return '';
    }
  }
}
