import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackbarService } from '@app/shared/components/snackbar';
import { parseError } from '@app/shared/helpers';
import { LocationResponse } from '@app/shared/models/location.model';
import { LocationsService } from '@app/shared/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GetCamerasLocations, GetCamerasLocationsFailure, GetCamerasLocationsSuccess, GetKeysLocations, GetKeysLocationsFailure, GetKeysLocationsSuccess, ServicesActionTypes } from '../actions';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServicesLocationsEffects {
    constructor(
        private actions$: Actions,
        private snackbar: SnackbarService,
        private locationsService: LocationsService,
        private translate: TranslateService
    ) { }

    GetKeysLocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetKeysLocations>(ServicesActionTypes.GetKeysLocations),
            switchMap(() => {
                return this.locationsService.getLocations('keys')
                    .pipe(
                        map((response: LocationResponse[]) =>
                            new GetKeysLocationsSuccess(response)
                        ),
                        catchError((error: HttpErrorResponse) => {
                            this.snackbar.showMessage(
                              this.translate.instant('services.locations.message.get_keys.failed', {
                                text: parseError(error)
                              })
                            );
                            return of(new GetKeysLocationsFailure());
                        })
                    );
            })
        )
    );

    GetCamerasLocations$ = createEffect(() =>
        this.actions$.pipe(
            ofType<GetCamerasLocations>(ServicesActionTypes.GetCamerasLocations),
            switchMap(() => {
                return this.locationsService.getLocations('cameras')
                    .pipe(
                        map((response: LocationResponse[]) =>
                            new GetCamerasLocationsSuccess(response)
                        ),
                        catchError((error: HttpErrorResponse) => {
                          this.translate.instant('services.locations.message.get_cameras.failed', {
                            text: parseError(error)
                          })
                            return of(new GetCamerasLocationsFailure());
                        })
                    );
            })
        )
    );
}
