import { Component } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Component({
  selector: 'app-mat-paginator-custom',
  templateUrl: './mat-paginator-custom.component.html',
  styleUrls: ['./mat-paginator-custom.component.scss']
})
export class MatPaginatorCustomComponent extends MatPaginatorIntl {
  firstPageLabel = 'Первая страница';
  lastPageLabel = 'Последняя страница';
  itemsPerPageLabel = 'Записей на страницу';
  nextPageLabel = 'Следующая страница';
  previousPageLabel = 'Предыдущая страница';

  getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (length === 0 || pageSize === 0) {
      return '0 из ' + length;
    }
    length = Math.max(length, 0);
    const startIndex: number = page * pageSize;
    const endIndex: number = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return startIndex + 1 + ' - ' + endIndex + ' из ' + length;
  }
}
