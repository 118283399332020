import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-company-user-status',
  templateUrl: './company-user-status.component.html',
  styleUrls: ['./company-user-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyUserStatusComponent {
  private _blocked: boolean;

  @Input() set blocked(blocked: boolean) {
    this._blocked = blocked;
  }

  get blocked(): boolean {
    return this._blocked;
  }
}
