export * from './company-about-page.component';
export * from './company-settings-form/company-settings-form.component';
export * from './company-tarifs/company-tarifs.component';
export * from './company-info/company-info.component';
export * from './company-settings/company-settings.component';
export * from './company-contacts/company-contacts.component';
export * from './company-profile/company-profile.component';
export * from './company-notifications/company-notifications.component';
export * from './company-links/company-links.component';
export * from './company-contacts-edit/company-contacts-edit.component';
export * from './company-docs/company-docs.component';