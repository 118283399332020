import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { DialogWrapperModule, InfoBlockModule } from '@app/shared/ui';
import { ServiceTariffPopupComponent } from './service-tariff-popup.component';
import {AbonentConnectionToolModule} from '@app/shared/components';
import {SoftwareIntercomTariffPopupContentModule} from '@app/views/services/submodules/software-intercoms/components/software-intercom-page/popups/software-intercom-tariff-popup';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    InfoBlockModule,
    SharedPipesModule,
    DialogWrapperModule,
    AbonentConnectionToolModule,
    SoftwareIntercomTariffPopupContentModule
  ],
    declarations: [
        ServiceTariffPopupComponent
    ],
    exports: [
        ServiceTariffPopupComponent
    ]
})
export class ServiceTariffPopupModule { }
