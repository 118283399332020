import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { PbxOnRdaResponse, SipAdapterUtilsService } from '@app/shared/entities/rd';
import { SelectSearch } from '@app/shared/models';
import { PbxOnRdaSearchSelectComponent } from '../pbx-on-rda-search-select';

@Component({
  selector: 'app-pbx-on-rda-edit-content',
  templateUrl: './pbx-on-rda-edit-content.component.html',
  styleUrls: ['./pbx-on-rda-edit-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PbxOnRdaEditContentComponent implements AfterViewInit {
  @ViewChild(PbxOnRdaSearchSelectComponent, { static: true }) private pbxOnRdaSearchSelect: PbxOnRdaSearchSelectComponent;
  @Input() loading: boolean;
  @Input() set pbxOnRda(pbxOnRda: PbxOnRdaResponse) {
    this._pbxOnRda = pbxOnRda;
    this.initialPbxOnRda = pbxOnRda;
  }
  initialPbxOnRda: PbxOnRdaResponse;

  private _pbxOnRda: PbxOnRdaResponse;

  @Output() private connectPbxOnRda: EventEmitter<{ pbxOnRda: PbxOnRdaResponse }> = new EventEmitter();
  @Output() private changePbxOnRda: EventEmitter<{ oldPbxOnRda: PbxOnRdaResponse, newPbxOnRda: PbxOnRdaResponse }> = new EventEmitter();
  @Output() private disconnectPbxOnRda: EventEmitter<{ pbxOnRda: PbxOnRdaResponse }> = new EventEmitter();

  constructor(
    private sipAdapterUtilsService: SipAdapterUtilsService
  ) { }

  ngAfterViewInit() {
    if (!this.pbxOnRda) {
      return;
    }

    this.pbxOnRdaSearchSelect.initUsingSelectedPbxOnRda({
      text: this.pbxOnRda.pbxOnRdaUid,
      value: this.pbxOnRda.pbxOnRdaUid,
      badge: this.sipAdapterUtilsService.prepareSipAdapterSocket(this.pbxOnRda.sipAdapter)
    });
  }

  get pbxOnRda(): PbxOnRdaResponse {
    return this._pbxOnRda;
  }

  onSelectPbxOnRda(value: PbxOnRdaResponse, select: SelectSearch) {
    this._pbxOnRda = value;
  }

  onSubmit() {
    if (!this.initialPbxOnRda && this.pbxOnRda) {
      this.connectPbxOnRda.emit({ pbxOnRda: this.pbxOnRda });
      return;
    }

    if (this.initialPbxOnRda && !this.pbxOnRda) {
      this.disconnectPbxOnRda.emit({ pbxOnRda: this.initialPbxOnRda });
    }

    if (this.initialPbxOnRda?.id !== this.pbxOnRda?.id) {
      this.changePbxOnRda.emit({ oldPbxOnRda: this.initialPbxOnRda, newPbxOnRda: this.pbxOnRda });
    }
  }
}
