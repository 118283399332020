import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {KeysApiService, ProtocolTypes} from '@app/shared/entities/rd';
import {IntercomKeyRelay, KeysResponse} from '@app/shared/entities/rd/keys/models';
import {parseError} from '@app/shared/helpers';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {IntercomMetacomKeyRelayType} from '@app/shared/entities/rd/keys/models/intercom-metacom-key-relay.type';

@Injectable()
export class IntercomKeysEditContainerHelper implements OnDestroy {
  relays: number[] = Object.values(IntercomKeyRelay).filter(value => typeof (value) === 'number') as number[];

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private keysApiService: KeysApiService
  ) {
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  initRelaysList(keys: KeysResponse[], protocolId: ProtocolTypes): Record<number, boolean> {
    if (protocolId === ProtocolTypes.MetacomWithManyPanels || protocolId === ProtocolTypes.Metacom) {
      this.relays = Object.values(IntercomMetacomKeyRelayType).filter(relay => !isNaN(Number(relay))) as number[];
    }

    if (keys?.length < 1) {
      return {};
    }

    const connectedRelays: Partial<Record<number, boolean>> = {};

    this.relays.forEach((relay: IntercomKeyRelay) => {
      if (keys.findIndex((key: KeysResponse) => (key.releId === relay || key.relay === relay) && key.service) > -1) {
        connectedRelays[relay] = true;
      }
    });

    return connectedRelays;
  }

  getRelaysListForAdapter(
    rdaUid: string,
    protocolId: ProtocolTypes,
    cb: (success: boolean, connectedRelays: Record<number, boolean>, error?: string) => void
  ) {
    this.keysApiService.getKeys(rdaUid)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: KeysResponse[]) => cb(true, this.initRelaysList(response, protocolId)),
        (error: HttpErrorResponse) => cb(false, null, parseError(error))
      );
  }

  /**
   * Set 3-rd relay if it's free and set first free relay if isn't
   */
  getFreeRelay(connectedRelays: Record<number, boolean>, protocolId: ProtocolTypes): number {
    let freeRelay: number = null;
    if (protocolId === ProtocolTypes.MetacomWithManyPanels || protocolId === ProtocolTypes.Metacom) {
      this.relays = Object.values(IntercomMetacomKeyRelayType).filter(relay => !isNaN(Number(relay))) as number[];
    }

    for (let idx = this.relays.length - 1; idx >= 0; --idx) {
      if (!connectedRelays[this.relays[idx]]) {
        freeRelay = this.relays[idx];

        if (freeRelay === 3) {
          return freeRelay;
        }
      }
    }

    return freeRelay;
  }
}
