import {ChangeDetectionStrategy, Component, Input, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {isEmpty} from 'lodash';
import {TableAVSCameraStatuses} from '@app/views/services/submodules/apartment-video-surveillance/pipes';
import {ChipModule} from 'primeng/chip';
import {TranslateModule} from '@ngx-translate/core';

@Component({
  selector: 'app-list-statuses-cell',
  standalone: true,
  imports: [CommonModule, ChipModule, TranslateModule],
  templateUrl: './list-statuses-cell.component.html',
  styleUrls: ['./list-statuses-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ListStatusesCellComponent {
  @Input() public useSingleValue = false;
  @Input() public set statuses(data: TableAVSCameraStatuses[]) {
    this.renderInfo = {active: 0, inactive: 0};
    if (!isEmpty(data)) {
      data.forEach(status => {
        if (status.active) {
          this.renderInfo.active++;
        } else {
          this.renderInfo.inactive++;
        }
      });
    }
  }

  public renderInfo: {
    active: number;
    inactive: number;
  };
}
