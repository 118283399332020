import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { InfoBlockModule } from '@app/shared/ui';
import { DeleteEntranceContentComponent } from './delete-entrance-content.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        InfoBlockModule,
        NgxTranslateModule
    ],
    declarations: [
        DeleteEntranceContentComponent
    ],
    exports: [
        DeleteEntranceContentComponent
    ]
})
export class DeleteEntranceContentModule { }
