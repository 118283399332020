import { Injectable } from '@angular/core';
import { ApiService, RequestOptions } from '@app/shared/api';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { ICommonDataProtocol } from '@app/views/support-service/models/commonDataProtocol';
import { map } from 'rxjs/operators';
import { ObjectManagerAttributes } from '@app/views/support-service/components/requests/requests.component';
import { RateLocalState } from '@app/views/support-service/components/chat-page/chat-page.component';
import { AllRdStatuses } from '@app/views/support-service/components/requests/tickets-filter.pipe';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

export interface IUserDaoResponse {
  id: number;
  email: string;
  name: string;
  companyId: number;
  isZammadUserExists: boolean;
  zammadUserId: number;
}

export interface IZammadUserResponse {
  department: string;
  firstname: string;
  id: number;
  lastname: string;
  login: string;
  organization_id: string;
  phone: string;
}

export enum ZammadRequestType {
  Incident = 'Incident',
  RequestForChange = 'Request for Change',
  Consultation = 'Consultation',
  SuggestImprovement = 'Suggest Improvement'
}

export const SenderDecoder = {
  Customer: 'Пользователь',
  Agent: 'Поддержка',
  System: 'Система'
};

export enum Sender {
  Customer = 'Customer',
  Agent = 'Agent',
  System = 'System'
}

export interface ICreateTicketRequest {
  reference?: {
    address: string;
    data: ICommonDataProtocol[];
  };
  problem?: string;
  address?: string;
  entity?: string;
  symptoms?: string[];
  problemSymptoms?: string[];
  problemSymptomsText?: string;
  requestPoints?: string[];
  requestPointsText?: string;
  consultationThemes?: string[];
  consultationThemesText?: string;
  description?: string;
  problemReason?: string;
  deviceIdentifier?: string;
  serviceId?: number | string;
  phone?: string;
  title?: string;
  simpleData?: string;
  type?:
    | ZammadRequestType.Incident
    | ZammadRequestType.RequestForChange
    | ZammadRequestType.Consultation
    | ZammadRequestType.SuggestImprovement;
  userName?: string;
  companyName?: string;
  email?: string;
  rootStep?: string;
  tableColumneName?: string;
}

export interface IUpdateTicketRequest {
  rd_state?: AllRdStatuses;
  rate?: string;
  user_feedback?: string;
}

export interface IOrganizationRequest {
  name: string;
  shared: boolean;
  domain?: string;
  domain_assignment: boolean;
  active: boolean;
  note: string;
  members: string[];
  rdbaid: number;
}

export interface IOrganizationResponse {
  id: number;
  name: string;
  shared: boolean;
  domain: string;
  domainAssignment: boolean;
  active: boolean;
  note: string;
  updated_by_id: number;
  created_by_id: number;
  created_at: string;
  updated_at: string;
  member_ids: string[];
}

export interface IAttachFile {
  ticket_id: number;
  to?: string;
  cc?: string;
  subject?: string;
  body: string;
  content_type?: string;
  type?: string;
  internal?: boolean;
  time_unit?: string;
  sender?: Sender;
  attachments?: IAttachments[];
}

export interface IAttachments {
  filename: string;
  data: string;
  'mime-type': string;
}

export interface IQueryTicketResponse {
  assets: {
    Ticket: ITicketResponse
  };
}

export interface ITicketResponse {
  id: number;
  customer_id: number;
  group_id: number;
  note: string;
  number: string;
  organization_id: number;
  owner_id: number;
  priority_id: number;
  state_id: number;
  title: string;
  created_at: string;
  rd_state: string;
  address: string;
  state: string; // Mapping value
  readableState: string; // Mapping value
  rate: RateLocalState;
  origin_created_at: string;
  creator: string;
}

export interface ITicketsArticles {
  id: number;
  ticket_id: number;
  type_id: number;
  sender_id: number;
  from: string;
  to: string;
  subject: string;
  body: string;
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
  type: string;
  sender: Sender;
  attachments: {
    filename: string;
    id: number;
    size: string;
    store_file_id: number;
  }[];
  internal: boolean;
  deliverDate?: string; // Mapping value
}

export interface IDataOption {
  options: {
    name: string;
    value: string;
  }[];
}

export interface IObjectManagerAttributes {
  id: number;
  data_option: IDataOption;
}

@Injectable({
  providedIn: 'root'
})
export class SupportApiService extends ApiService {
  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.companiesApiUrl, ['v1']);
  }

  public createTicket(
    params: ICreateTicketRequest
  ): Observable<HttpErrorResponse | ITicketResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post('/zammad/ticket', 1, params, { headers });
  }

  public updateTicket(
    params: IUpdateTicketRequest,
    id: number,
  ): Observable<HttpErrorResponse | ITicketResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.put(`/zammad/ticket/${id}`, 1, params, { headers });
  }

  public getTicketById(
    ticketId: number
  ): Observable<HttpErrorResponse | ITicketResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.get(`/zammad/ticket/${ticketId}`, 1, { headers });
  }

  public getTicketsWithSearch(
    query: string
  ): Observable<HttpErrorResponse | ITicketResponse[]> {
    if (!query) {
      query = `2024`;
    }

    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.get(`/zammad/ticket/search?limit=200&query=${query}`, 1, {
      headers
    }).pipe(
      map((response: IQueryTicketResponse | HttpErrorResponse) => {
        if (response instanceof HttpErrorResponse) {
          return;
        }
        if (response?.assets?.Ticket) {
          return Object.values(response?.assets?.Ticket);
        }
        return [];
      })
    );
  }

  public getTicketsArticles(
    ticketId: number
  ): Observable<HttpErrorResponse | ITicketsArticles[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.get(`/zammad/ticket_articles?ticketId=${ticketId}`, 1, {
      headers
    });
  }

  public objectManagerAttributes(id: ObjectManagerAttributes): Observable<HttpErrorResponse | IObjectManagerAttributes> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.get(`/zammad/object_manager_attributes?id=${id}`, 1, { headers });
  }

  public createUser(): Observable<void> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post(`/zammad/user`, 1, null, { headers });
  }

  public userSearch(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.get(`/zammad/user/company`, 1, { headers });
  }

  public getUserInfoByToken(): Observable<IUserDaoResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.get(`/zammad/user`, 1, { headers });
  }

  public getOrganizationsByName(
    organizationName: string
  ): Observable<IOrganizationResponse[]> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.get(
      `/zammad/organizations?organizationName=${organizationName}`,
      1,
      { headers }
    );
  }

  public createOrganization(
    organization: IOrganizationRequest
  ): Observable<IOrganizationResponse> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post(
      `/zammad/organization`,
      1,
      { ...organization },
      { headers }
    );
  }

  public attachComment(organization: IAttachFile): Observable<void> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post(`/zammad/attachment`, 1, { ...organization }, { headers });
  }

  public getAttachment(ticketId: number, commentId: number, fileId: number): Observable<Blob> {
    return this.get(
      `/zammad/attachment?ticketId=${ticketId}&commentId=${commentId}&fileId=${fileId}`,
      1,
      { responseType: 'blob' } as Partial<RequestOptions>);
  }
}
