<div class="table-page-container">
  <mat-card>
    <mat-card-content>
      <app-navbar
        [links]="links"
        [active]="activeLink"
        (select)="onChangePage($event.idx)"
      ></app-navbar>

      <div class="input-field">
        <mat-form-field appearance="outline" class="none-padding none-margin">
          <input
            matInput
            placeholder="{{ 'intercom.orders.page.placeholder.search' | translate }}"
            [formControl]="filterInput"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>

        <button mat-raised-button color="primary-inverse" (click)="onCreate()">
          <mat-icon>add</mat-icon>
          <ng-container *ngIf="!resolution.isMobile">
            {{ 'intercom.orders.page.button.create' | translate }}
          </ng-container>
        </button>
      </div>

      <hr>

      <div class="orders-list" *ngIf="(orders$ | async)?.totalCount > 0; else emptyData"
        [class.manufacturer-list]="isManufacturer()" [class.customer-list]="!isManufacturer()">
        <app-order-card
          *ngFor="let order of (orders$ | async).data"
          [order]="order"
          [authorities]="authorities"
        ></app-order-card>
      </div>
      <ng-template #emptyData>
        <app-empty-data></app-empty-data>
      </ng-template>

      <mat-paginator [length]="(orders$ | async)?.totalCount" [pageSize]="pageSize" [hidePageSize]="true"
        [showFirstLastButtons]="true">
      </mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
