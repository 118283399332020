<a
  *ngIf="!environment.vivotec"
  [href]="link"
  target="_blank"
  class="button-link"
  [ngClass]="color">
  <span>
    <ng-content></ng-content>
  </span>
</a>
