import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { GitlabComponentsVersions } from '@app/shared/entities/integrations';
import { ActiveHistoryResponse, Camera, Company, KeysResponse, RdaResponse, ServiceResponse } from '@app/shared/entities/rd';
import { Constants, Dictionary } from '@app/shared/helpers';
import { Address, LocationResponse, LogsComponentType, LogsResponse, ServicesTypes } from '@app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData, DialogWrapperSize } from '@app/shared/ui';
import { ServiceEntrancesFlats, ServiceFlatsFilters } from '@app/views/services/components';
import { ServiceActivitySource, ServicePageMode } from '@app/views/services/models';
import { ServicePageListeners, ServicesHelper } from '@app/views/services/services';
import { ServiceFacade } from '@app/views/services/store';
import { GateMode } from '../../models';
import { GatesPageFacade } from '../../store';
import { GateWizardPopupComponent } from './popups';
import {TokenService} from '@app/security';
import {UserRoleType} from '@app/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-gate-page',
  templateUrl: './gate-page.component.html',
  styleUrls: ['./gate-page.component.scss'],
  providers: [ServicesHelper, ServicePageListeners],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GatePageComponent implements OnInit, OnDestroy {
  readonly pageMode: typeof ServicePageMode = ServicePageMode;
  readonly gateMode: typeof GateMode = GateMode;
  readonly serviceType: ServicesTypes = ServicesTypes.GATE;

  pageMode$: Observable<ServicePageMode> = this.serviceFacade.pageMode$;
  mode$: Observable<GateMode> = this.gatesPageFacade.mode$;

  // Service general data
  serviceId$: Observable<number> = this.serviceFacade.serviceId$;
  serviceName$: Observable<string> = this.serviceFacade.serviceName$;
  serviceCustomName$: Observable<string> = this.serviceFacade.serviceCustomName$;
  serviceTariff$: Observable<number> = this.serviceFacade.serviceTariff$;
  dependantServices$: Observable<Pick<ServiceResponse, 'id' | 'type'>[]> = this.serviceFacade.dependantServices$;
  entrances$: Observable<Address[]> = this.serviceFacade.entrances$;
  rdas$: Observable<RdaResponse[]> = this.serviceFacade.rdas$;
  cameras$: Observable<Camera[]> = this.serviceFacade.cameras$;
  keys$: Observable<KeysResponse[]> = this.serviceFacade.keys$;

  // Service additional data
  keysLocations$: Observable<LocationResponse[]> = this.serviceFacade.keysLocations$;
  componentsVersions$: Observable<GitlabComponentsVersions> = this.serviceFacade.componentsVersions$;
  company$: Observable<Company> = this.serviceFacade.company$;
  abonentsLoading$: Observable<boolean> = this.serviceFacade.abonentsLoading$;

  // Flats data
  flats$: Observable<ServiceEntrancesFlats> = this.serviceFacade.filteredFlats$;
  flatsFilters$: Observable<ServiceFlatsFilters> = this.serviceFacade.flatsFilters$;

  // Service activity
  logs$: Observable<LogsResponse[]> = this.serviceFacade.logsData$;
  logsLoading$: Observable<boolean> = this.serviceFacade.logsLoading$;
  blocksCount$: Observable<number> = this.serviceFacade.blocksCount$;
  sources$: Observable<ServiceActivitySource[]> = this.serviceFacade.sources$;
  timeRange$: Observable<number> = this.serviceFacade.timeRange$;
  extendedMode$: Observable<boolean> = this.serviceFacade.extendedMode$;
  totalLogsCount$: Observable<number> = this.serviceFacade.totalLogsCount$;
  currentLogsCount$: Observable<number> = this.serviceFacade.currentLogsCount$;
  logsComponentType$: Observable<LogsComponentType> = this.serviceFacade.logsComponentType$;
  selectedLogsSource$: Observable<ServiceActivitySource> = this.serviceFacade.selectedLogsSource$;
  activeHistoryCameras$: Observable<Dictionary<ActiveHistoryResponse[]>> = this.serviceFacade.activeHistoryCameras$;
  activeHistoryIntercoms$: Observable<Dictionary<ActiveHistoryResponse[]>> = this.serviceFacade.activeHistoryIntercoms$;

  constructor(
    private dialog: MatDialog,
    private resolution: ResolutionService,
    private serviceFacade: ServiceFacade,
    private serviceHelper: ServicesHelper,
    private pageListeners: ServicePageListeners,
    private gatesPageFacade: GatesPageFacade,
    private changeDetectorRef: ChangeDetectorRef,
    private tokenService: TokenService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initializeComponent();
  }

  ngOnDestroy() {
    this.serviceFacade.clearService();
    this.gatesPageFacade.clearState();
  }

  get haveUserSupportAccess(): boolean {
    return this.tokenService.getAuthorities().includes(UserRoleType.ROLE_SUPPORT);
  }


  initializeComponent(): void {
    this.loadServiceDate();
    this.loadGeneralData();
    this.prepareWizard();
  }

  onManageClose() {
    this.loadServiceDate();
  }

  onChangePageMode(pageMode: ServicePageMode) {
    this.serviceFacade.setPageMode(pageMode);
  }

  onOpenWizard() {
    const data: DialogWrapperData<null, null> = {
      title: this.translate.instant('services.gates.open_wizard.title'),
      componentName: 'ConfigGateService'
    };

    this.dialog.open(GateWizardPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      autoFocus: false,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.MD,
      data
    });
  }

  loadServiceDate() {
    const serviceId = this.serviceHelper.getServiceIdFromParams();
    this.serviceFacade.getService(serviceId);
  }

  loadGeneralData() {
    this.serviceFacade.getKeysLocations();
    this.serviceFacade.getCamerasLocations();
    this.serviceFacade.getIntercomTypes();
    this.serviceFacade.getComponentVersions();
  }

  prepareWizard() {
    this.pageListeners.addServiceEmptyListener(() => {
      this.serviceFacade.setPageMode(ServicePageMode.SETTINGS);
      this.changeDetectorRef.markForCheck();
      this.onOpenWizard();
    });
  }
}
