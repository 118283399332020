import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

export const customAddressFormGroup = () => {
    const controls = {
        country: new UntypedFormControl(null),
        city: new UntypedFormControl(null, Validators.required),
        street: new UntypedFormControl(null, Validators.required),
        house: new UntypedFormControl(null, Validators.required),
        housing: new UntypedFormControl(null),
        building: new UntypedFormControl(null),
        block: new UntypedFormControl(null)
    };

    return {
        form: new UntypedFormGroup(controls),
        controls: controls
    };
};
