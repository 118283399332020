<div class="service-flats__action-keeper">
  <div class="service-flats__filter-actions">
    <div class="service-flats__filter-opener-keeper">
      <button
        mat-raised-button
        (click)="onToggleFilterRow()"
        [color]="filterRowState || selectedFilterCount > 0 ? 'primary' : 'basic'"
        class="service-flats__filter-opener"
        [class.service-flats__filter-opener_active]="
          filterRowState || selectedFilterCount > 0
        ">
        <ng-container *ngIf="!xlgWDownBreakpoint; else filterIcon">
          {{ 'service.flats.menu.button.filter' | translate }}
        </ng-container>
      </button>
      <span
        class="service-flats__filter-badge"
        *ngIf="selectedFilterCount > 0">
        {{ selectedFilterCount }}
      </span>
    </div>
    <ng-container *ngIf="selectedFilterCount > 0">
      <button
        *ngIf="!xlgWDownBreakpoint; else mobileVersion"
        mat-button
        color="primary"
        class="service-flats__filter-reset"
        (click)="onResetFilters()">
        {{ 'service.flats.menu.button.reset' | translate }}
      </button>
    </ng-container>
  </div>
  <div class="service-flats__dropdown-actions">
    <div class="service-flats__dropdown-action-keeper">
      <button
        class="service-flats__dropdown-action"
        mat-stroked-button
        color="primary"
        [matMenuTriggerFor]="addMenu">
        <ng-container *ngIf="!xlgWDownBreakpoint; else addIcon">
          {{ 'service.flats.menu.button.add.default' | translate }}
          <mat-icon svgIcon="triangle-down" class="triangle-down"></mat-icon>
        </ng-container>
      </button>
      <mat-menu #addMenu="matMenu">
        <button
          class="service-flats__dropdown-item"
          color="primary"
          mat-menu-item
          (click)="onAddPhone()"
        >
          {{ 'service.flats.menu.button.add.phone' | translate }}
        </button>
        <button
          class="service-flats__dropdown-item"
          color="primary"
          [disabled]="
            selectedFlatList.length !== 1 ||
            !selectedFlatList[0].flat?.owner?.phone
          "
          mat-menu-item
          (click)="onDelegate(selectedFlatList[0])"
        >
          {{ 'service.flats.menu.button.add.delegate' | translate }}
        </button>
        <button
          *ngIf="!isVideoSurveillance"
          class="service-flats__dropdown-item"
          color="primary"
          mat-menu-item
          (click)="onAddVirtualFlat()"
        >
          {{ 'service.flats.menu.button.add.virtual_flat' | translate }}
        </button>
        <button
          class="service-flats__dropdown-item"
          color="primary"
          mat-menu-item
          (click)="onAddConnections()"
        >
          {{ 'service.flats.menu.button.add.connections' | translate }}
        </button>
      </mat-menu>
    </div>
    <div class="service-flats__dropdown-action-keeper">
      <button
        class="service-flats__dropdown-action"
        mat-stroked-button
        color="primary"
        [matMenuTriggerFor]="editMenu"
      >
        <ng-container *ngIf="!xlgWDownBreakpoint; else editIcon">
          {{ 'service.flats.menu.button.edit' | translate }}
          <mat-icon svgIcon="triangle-down" class="triangle-down"></mat-icon>
        </ng-container>
        <ng-template #editIcon>
          <mat-icon size="24" svgIcon="edit"></mat-icon>
        </ng-template>
      </button>
      <mat-menu #editMenu="matMenu">
        <button
          *ngIf="!environment.vivotec"
          class="service-flats__dropdown-item"
          mat-menu-item
          [disabled]="!tariffActiveState"
          (click)="onTariff()"
        >
          {{ 'service.flats.menu.button.tariffs' | translate }}
        </button>
        <button
          class="service-flats__dropdown-item"
          mat-menu-item
          [disabled]="!manageActiveState"
          (click)="onManage()"
        >
          {{ 'service.flats.menu.button.services' | translate }}
        </button>
        <button
          class="service-flats__dropdown-item service-flats__dropdown-item_warn"
          mat-menu-item
          color="warn"
          [disabled]="!deleteActiveState"
          (click)="onDeleteFlat()"
        >
          {{ 'service.flats.menu.button.delete.flat' | translate }}
        </button>
      </mat-menu>
    </div>
    <div class="service-flats__dropdown-action-keeper">
      <button
        class="service-flats__dropdown-action"
        mat-stroked-button
        color="warn"
        [disabled]="!deleteAbonentsActiveState"
        [matMenuTriggerFor]="removeMenu"
      >
        <ng-container *ngIf="!xlgWDownBreakpoint; else removeIcon">
          {{ 'service.flats.menu.button.delete.abonents_active_state' | translate }}
          <mat-icon *ngIf="deleteAbonentsActiveState" svgIcon="triangle-down-warn" class="triangle-down"></mat-icon>
        </ng-container>
      </button>
    </div>
  </div>
</div>

<ng-template #mobileVersion>
  <button
    mat-stroked-button
    color="primary"
    class="service-flats__dropdown-action"
    (click)="onResetFilters()"
  >
    <mat-icon size="24" svgIcon="close"></mat-icon>
  </button>
</ng-template>

<ng-template #addIcon>
  <mat-icon size="24" svgIcon="plus"></mat-icon>
</ng-template>

<ng-template #removeIcon>
  <mat-icon size="24" svgIcon="block"></mat-icon>
</ng-template>

<ng-template #filterIcon>
  <mat-icon size="24" [svgIcon]="filterRowState || selectedFilterCount > 0 ? 'filter-active' : 'filter'"></mat-icon>
</ng-template>

<mat-menu #removeMenu="matMenu">
  <button
    class="service-flats__dropdown-item service-flats__dropdown-item_warn"
    mat-menu-item
    color="warn"
    (click)="onDeleteSharedAbonents()"
  >
    {{ 'service.flats.menu.button.delete.shared_abonents' | translate }}
  </button>
</mat-menu>
