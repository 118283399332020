import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatus } from '@app/views/intercom/models/orders/order-status.enum';
import {TranslateService} from '@ngx-translate/core';

@Pipe({ name: 'orderStatus' })
export class OrderStatusPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) {}

  transform(status: string): string {
    switch (status.toUpperCase()) {
      case OrderStatus.TODO:
        return this.translate.instant('enum.order_status.todo');
      case OrderStatus.IN_PROGRESS:
        return this.translate.instant('enum.order_status.in_progress');
      case OrderStatus.AWAITING_RECEIPT:
        return this.translate.instant('enum.order_status.awaiting_receipt');
      case OrderStatus.RECEIVED:
        return this.translate.instant('enum.order_status.received');
      default: return '';
    }
  }
}
