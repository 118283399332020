<app-dialog-wrapper [title]="data.title" [componentName]="data.componentName">
  <div class="popup">
    <p>
      {{ 'pbx-on-rda.delete.content.message.confirm' | translate:{
        uid: data.body.pbxOnRdaUid
      } }}
    </p>

    <div class="popup-actions">
      <button
        mat-raised-button
        class="popup-actions__button"
        type="submit"
        color="error"
        (click)="onSubmit()"
      >
        {{ 'pbx-on-rda.delete.content.button.submit' | translate }}
      </button>
    </div>
  </div>
</app-dialog-wrapper>
