<div class="title-row">
  <div class="title-row__item">
    <h4 class="title">
      {{ 'service.title.row.flat' | translate }}
    </h4>
  </div>
  <div class="title-row__item">
    <h4 class="title">
      {{ 'service.title.row.abonent' | translate }}
    </h4>
  </div>
  <ng-content select="[services]"></ng-content>
  <div class="title-row__item">
    <h4 class="title">
      {{ 'service.title.row.status' | translate }}
    </h4>
  </div>
</div>
