import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RdaResponse } from '@app/shared/entities/rd';
import { ServicePageMode } from '@app/views/services/models';
import { ServiceFacade } from '@app/views/services/store';
import { GateWizardPopupComponent } from '../../gate-wizard-popup.component';
import { GateWizardPopupStep } from './../../models';
import { GateWizardContentChecklistStep } from './gate-wizard-content-checklist-step.enum';

@Component({
  selector: 'app-gate-wizard-content-checklist',
  templateUrl: './gate-wizard-content-checklist.component.html',
  styleUrls: ['./gate-wizard-content-checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GateWizardContentChecklistComponent {
  readonly checklistSteps: typeof GateWizardContentChecklistStep = GateWizardContentChecklistStep;

  @Input() intercoms: RdaResponse[];
  selectedCheckpoint = 1;
  lastCheckpointStep = 1;

  @Output() private changeStep: EventEmitter<{ stepIdx: number }> = new EventEmitter();

  constructor(
    private serviceFacade: ServiceFacade,
    private dialogRef: MatDialogRef<GateWizardPopupComponent>
  ) { }

  onNextStep() {
    this.selectedCheckpoint++;
  }

  onNextLastCheckpointStep() {
    this.lastCheckpointStep++;
  }

  onChangeStep(step: GateWizardContentChecklistStep) {
    switch (step) {
      case GateWizardContentChecklistStep.INTERCOMS:
        this.changeStep.emit({ stepIdx: GateWizardPopupStep.INTERCOMS });
        break;
      case GateWizardContentChecklistStep.PHYSICAL_TUBE:
        this.serviceFacade.setPageMode(ServicePageMode.ABONENTS);
        this.dialogRef.close();
        break;
      case GateWizardContentChecklistStep.TEST_ABONENT:
        this.serviceFacade.setPageMode(ServicePageMode.ABONENTS);
        this.dialogRef.close();
        break;
    }
  }
}
