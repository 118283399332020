import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IntercomPanelConnectRequest, IntercomPanelResponse, IntercomPanelUpdateRequest, RdaResponse } from '@app/shared/entities/rd';
import { LocationResponse } from '@app/shared/models';
import { ServiceIntercomPanelsMode } from '@app/views/services/components';
import { ServiceFacade } from '@app/views/services/store';
import { SoftwareIntercomWizardPopupService } from '../../software-intercom-wizard-popup.service';
import { SoftwareIntercomWizardPopupStore } from './../../store';

@Component({
  selector: 'app-software-intercom-wizard-content-intercom-panels',
  templateUrl: './software-intercom-wizard-content-intercom-panels.component.html',
  styleUrls: ['./software-intercom-wizard-content-intercom-panels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomWizardContentIntercomPanelsComponent implements OnInit {
  readonly modes: typeof ServiceIntercomPanelsMode = ServiceIntercomPanelsMode;

  @Input() editHidden = true;
  @Input() deleteHidden = true;
  @Input() addHidden = true;
  @Input() intercom: RdaResponse;
  @Input() loading: boolean;
  @Input() intercomPanels: IntercomPanelResponse[];
  @Input() locations: LocationResponse[];
  mode: ServiceIntercomPanelsMode = ServiceIntercomPanelsMode.LIST;

  constructor(
    private popupService: SoftwareIntercomWizardPopupService,
    private popupStore: SoftwareIntercomWizardPopupStore,
    private serviceFacade: ServiceFacade
  ) { }

  ngOnInit() {
    this.popupService.addStateChangeListener((state: 'loading' | 'loaded' | 'done') => {
      if (state === 'done') {
        this.mode = ServiceIntercomPanelsMode.LIST;
      }
    });
  }

  onBack() {
    this.mode = ServiceIntercomPanelsMode.LIST;
  }

  onAdd(request: IntercomPanelConnectRequest) {
    this.serviceFacade.connectIntercomPanel(request);
  }

  onEdit(request: Partial<IntercomPanelUpdateRequest>, intercomPanelId: number, rdaId: number) {
    this.serviceFacade.updateIntercomPanel(intercomPanelId, rdaId, request);
  }

  onDelete(intercomPanelId: number, rdaId: number) {
    this.serviceFacade.disconnectIntercomPanel(intercomPanelId, rdaId);
  }

  onChangePageMode(mode: ServiceIntercomPanelsMode) {
    this.mode = mode;
    this.popupStore.changeMenuVisible(mode === ServiceIntercomPanelsMode.LIST);
    this.popupStore.changeStepButtonsVisible(mode === ServiceIntercomPanelsMode.LIST);
  }
}
