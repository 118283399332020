import { FlatRange } from '@app/shared/models';
import { Action } from '@ngrx/store';
import { ServicesActionTypes } from './services-action-types.actions';

export class AddAdditionalFlatRange implements Action {
    readonly type = ServicesActionTypes.AddAdditionalFlatRange;

    constructor(public entranceId: number, public range: FlatRange) { }
}

export class AddAdditionalFlatRangeSuccess implements Action {
    readonly type = ServicesActionTypes.AddAdditionalFlatRangeSuccess;

    constructor(public entranceId: number, public range: FlatRange) { }
}

export class AddAdditionalFlatRangeFailure implements Action {
    readonly type = ServicesActionTypes.AddAdditionalFlatRangeFailure;

    constructor() { }
}

export class UpdateAdditionalFlatRange implements Action {
    readonly type = ServicesActionTypes.UpdateAdditionalFlatRange;

    constructor(public entranceId: number, public rangeId: number, public range: FlatRange) { }
}

export class UpdateAdditionalFlatRangeSuccess implements Action {
    readonly type = ServicesActionTypes.UpdateAdditionalFlatRangeSuccess;

    constructor(public entranceId: number, public rangeId: number, public range: FlatRange) { }
}

export class UpdateAdditionalFlatRangeFailure implements Action {
    readonly type = ServicesActionTypes.UpdateAdditionalFlatRangeFailure;

    constructor() { }
}

export class DeleteAdditionalFlatRange implements Action {
    readonly type = ServicesActionTypes.DeleteAdditionalFlatRange;

    constructor(public entranceId: number, public rangeId: number) { }
}

export class DeleteAdditionalFlatRangeSuccess implements Action {
    readonly type = ServicesActionTypes.DeleteAdditionalFlatRangeSuccess;

    constructor(public entranceId: number, public rangeId: number) { }
}

export class DeleteAdditionalFlatRangeFailure implements Action {
    readonly type = ServicesActionTypes.DeleteAdditionalFlatRangeFailure;

    constructor() { }
}

export type ServicesAdditionalFlatRangesActions =
    | AddAdditionalFlatRange
    | AddAdditionalFlatRangeSuccess
    | AddAdditionalFlatRangeFailure

    | UpdateAdditionalFlatRange
    | UpdateAdditionalFlatRangeSuccess
    | UpdateAdditionalFlatRangeFailure

    | DeleteAdditionalFlatRange
    | DeleteAdditionalFlatRangeSuccess
    | DeleteAdditionalFlatRangeFailure;
