import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserRoleType } from '@app/core/models';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { RdaResponse } from '@app/shared/entities/rd/rda';
import { Constants } from '@app/shared/helpers';
import { EditPopupComponentData } from '@app/shared/models';
import { EditPopupComponent } from '@app/shared/templates';
import { DialogWrapperData } from '@app/shared/ui';
import { State } from '@app/store/reducers';
import { AddRdasToOrderRequest, AddScansToOrderRequest, CreateOrderRequest, OrderResponse, OrderStatus, OrderStatusHistoryResponse, UpdateOrderRequest } from '@app/views/intercom/models';
import { AddRdasToOrder, AddRdasToOrderClear, AddScansToOrder, AddScansToOrderClear, DeleteOrder, DeleteOrderClear, UpdateOrder, UpdateOrderClear } from '@app/views/intercom/store/actions';
import { addRdasToOrderSuccess, addScansToOrderSuccess, createOrderSuccess, deleteOrderSuccess } from '@app/views/intercom/store/states';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OrderCloseFormComponent } from './forms/order-close-form/order-close-form.component';
import { OrderCreateFormComponent } from './forms/order-create-form/order-create-form.component';
import { OrderRdasFormComponent } from './forms/order-rdas-form/order-rdas-form.component';
import { OrderScansFormComponent } from './forms/order-scans-form/order-scans-form.component';
import { OrderAssignPopupComponent } from './popups/order-assign-popup/order-assign-popup.component';
import { OrderDeletePopupComponent } from './popups/order-delete-popup/order-delete-popup.component';
import { OrderHistoryPopupComponent } from './popups/order-history-popup/order-history-popup.component';
import { OrderSendPopupComponent } from './popups/order-send-popup/order-send-popup.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-order-card',
  templateUrl: './order-card.component.html',
  styleUrls: ['./order-card.component.scss']
})
export class OrderCardComponent implements OnInit, OnDestroy {
  @Input() order: OrderResponse;
  @Input() private authorities: UserRoleType[];
  private onDestroy$: Subject<void>;
  private dialogRef: MatDialogRef<EditPopupComponent>;

  constructor(
    private resolution: ResolutionService,
    private dialog: MatDialog,
    private store: Store<State>,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initStoreListeners();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.store.dispatch(new AddScansToOrderClear());
    this.store.dispatch(new AddRdasToOrderClear());
    this.store.dispatch(new DeleteOrderClear());
    this.store.dispatch(new UpdateOrderClear());
  }

  onHistory() {
    const data: DialogWrapperData<{ orderStatusHistory: Array<OrderStatusHistoryResponse> }, null> = {
      title: this.translate.instant('intercom.orders.card.history.title'),
      body: { orderStatusHistory: this.order.orderStatusHistoryResponseList },
      componentName: 'OrderHistory'
    };

    this.dialog.open(OrderHistoryPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '650px',
      data
    });
  }

  onAddRdas() {
    const data: DialogWrapperData<{ adapters: RdaResponse[], rdaCount: number }, { request: AddRdasToOrderRequest }> = {
      title: this.translate.instant('intercom.orders.card.add_rdas.title'),
      body: { adapters: this.order.rdasList, rdaCount: this.order.rdaCount },
      componentName: 'OrderRdas',
      submit: (event: { request: AddRdasToOrderRequest }) => {
        if (event.request) {
          this.store.dispatch(new AddRdasToOrder(this.order.id, event.request));
        }
      }
    };

    this.dialog.open(OrderRdasFormComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '650px',
      data
    });
  }

  onPutScans() {
    const data: EditPopupComponentData = {
      title: this.translate.instant('intercom.orders.card.put_scans.title'),
      providedData: {},
      component: OrderScansFormComponent,
      componentName: 'OrderScans',
      submit: (addScansToOrderRequest: AddScansToOrderRequest) => {
        this.store.dispatch(new AddScansToOrder(this.order.id, addScansToOrderRequest));
      }
    };

    this.dialogRef = this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '300px',
      data
    });
  }

  onEdit() {
    const data: EditPopupComponentData = {
      title: this.translate.instant('intercom.orders.card.edit.title'),
      providedData: { order: this.order, isManufacturer: this.isManufacturer() },
      component: OrderCreateFormComponent,
      componentName: 'OrderEdit',
      submit: (request: CreateOrderRequest) => {
        const updateRequest: UpdateOrderRequest = { rdaCount: request.rdaCount };
        this.store.dispatch(new UpdateOrder(this.order.id, updateRequest));
      }
    };

    this.dialogRef = this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '300px',
      data
    });
  }

  onAssign() {
    const data: EditPopupComponentData = {
      title: this.translate.instant('intercom.orders.card.assign.title'),
      providedData: {},
      component: OrderAssignPopupComponent,
      componentName: 'OrderAssign',
      submit: (isAssign: boolean) => {
        if (isAssign) {
          const request: UpdateOrderRequest = { status: OrderStatus.IN_PROGRESS };
          this.dialogRef.close();
          this.store.dispatch(new UpdateOrder(this.order.id, request));
        }
      }
    };

    this.dialogRef = this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '450px',
      data
    });
  }

  onSendOrder() {
    const data: EditPopupComponentData = {
      title: this.translate.instant('intercom.orders.card.send_order.title'),
      providedData: {},
      component: OrderSendPopupComponent,
      componentName: 'OrderSend',
      submit: (isSended: boolean) => {
        if (isSended) {
          const request: UpdateOrderRequest = { status: OrderStatus.AWAITING_RECEIPT };
          this.store.dispatch(new UpdateOrder(this.order.id, request));
        }
      }
    };

    this.dialogRef = this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '650px',
      data
    });
  }

  onScansSave() {
    const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
    document.body.appendChild(a);
    a.style.display = 'none';

    for (const file of this.order.orderScanResponseList) {
      const url = `data:${file.type};base64,${file.data}`;
      a.href = url;
      a.download = file.name;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }

  onClose() {
    const data: EditPopupComponentData = {
      title: this.translate.instant('intercom.orders.card.close.title'),
      providedData: {},
      component: OrderCloseFormComponent,
      componentName: 'CloseOrder',
      submit: (warrantyStartDate: number) => {
        if (warrantyStartDate) {
          const updateRequest: UpdateOrderRequest = { status: OrderStatus.RECEIVED, warrantyStartDate };
          this.store.dispatch(new UpdateOrder(this.order.id, updateRequest));
        }
      }
    };

    this.dialogRef = this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '450px',
      data
    });
  }

  onPrint() {
    const adaptersList: string[] =
      this.order.rdasList?.map((adapter, idx) => `<tr><td>${idx + 1}</td><td>${adapter.uid}</td></tr>`);

    const mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(
      `<html>
        <head>
        <style type="text/css">
          .table {
              width: 100%;
              max-width: 100%;
              margin-bottom: 1rem;
              background-color: transparent;
              border-collapse: collapse;
              border: solid 1px #e0e0e0e0;
          }
          .table .thead-light th {
              text-align: left;
              color: #495057;
              background-color: #e9ecef;
              border-color: #dee2e6;
              font-style: bold;
          }
          .table thead th {
              vertical-align: bottom;
              border-bottom: 2px solid #dee2e6;
          }
          .table td, .table th {
              padding: .75rem;
              vertical-align: top;
              border-top: 1px solid #dee2e6;
          }
        </style>
        </head>
        <body>
          <h1>${this.translate.instant('intercom.orders.card.print.title')}</h1>
          <table class="table">
            <thead class="thead-light">
              <tr>
                <th scope="col">#</th>
                <th scope="col">UID</th>
              </tr>
            </thead>
            <tbody>
            ${adaptersList.join('\n')}
            </tbody>
          </table>
        </body>
      </html>
    `);

    mywindow.document.close();
    mywindow.focus();
    mywindow.print();
  }

  onDelete() {
    const data: EditPopupComponentData = {
      title: this.translate.instant('intercom.orders.card.delete.title'),
      providedData: {},
      component: OrderDeletePopupComponent,
      componentName: 'DeleteOrder',
      submit: (isConfirm: boolean) => {
        if (isConfirm) {
          this.store.dispatch(new DeleteOrder(this.order.id));
        }
      }
    };

    this.dialogRef = this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '350px',
      data
    });
  }

  isCompleteOrder(): boolean {
    return this.order.status === OrderStatus.RECEIVED;
  }

  isTodo(): boolean {
    return this.order.status === OrderStatus.TODO;
  }

  isAwaitingReciept(): boolean {
    return this.order.status === OrderStatus.AWAITING_RECEIPT;
  }

  isReceived(): boolean {
    return this.order.status === OrderStatus.RECEIVED;
  }

  isManufacturer(): boolean {
    return this.authorities?.includes(UserRoleType.ROLE_MANUFACTURER);
  }

  isAdaptersLoaded(): boolean {
    return this.order.rdasList?.length > 0;
  }

  isAllAdaptersLoaded(): boolean {
    return this.order.rdasList?.length === this.order.rdaCount;
  }

  isScansLoaded(): boolean {
    return this.order.orderScanResponseList?.length > 0;
  }

  isMobile(): boolean {
    return this.resolution.isMobile;
  }

  private initStoreListeners() {
    this.onDestroy$ = new Subject<void>();

    this.store.pipe(select(createOrderSuccess), takeUntil(this.onDestroy$))
      .subscribe(resp => resp && this.dialogRef && this.dialogRef.close());

    this.store.pipe(select(addRdasToOrderSuccess), takeUntil(this.onDestroy$))
      .subscribe(resp => resp && this.dialogRef && this.dialogRef.close());

    this.store.pipe(select(addScansToOrderSuccess), takeUntil(this.onDestroy$))
      .subscribe(resp => resp && this.dialogRef && this.dialogRef.close());

    this.store.pipe(select(deleteOrderSuccess), takeUntil(this.onDestroy$))
      .subscribe(resp => resp && this.dialogRef && this.dialogRef.close());
  }
}
