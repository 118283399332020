import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { SnackbarService } from '@app/shared/components';
import {
  Company,
  CompanyApiService,
  PaymentConfigurationResponse,
  UpdatePaymentConfigurationRequest
} from '@app/shared/entities/rd';
import { ErrorCodes, parseError } from '@app/shared/helpers';
import { ServiceFacade } from '@app/views/services';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import {
  filter,
  finalize,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';

import {
  paymentsPageInitialState,
  PaymentsPageState
} from './payments-page.state';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class PaymentsPageStore
  extends ComponentStore<PaymentsPageState>
  implements OnDestroy {
  public readonly configuration$: Observable<PaymentConfigurationResponse | null> =
    this.select((state: PaymentsPageState) => state.configuration);

  public readonly integrationCheck$: Observable<boolean> = this.select(
    (state: PaymentsPageState) => state.integrationCheck
  );

  public readonly updateConfigurationPending$: Observable<boolean> =
    this.select((state: PaymentsPageState) => state.updateConfigurationPending);

  public readonly errorHandled$: Observable<boolean> = this.select(
    (state: PaymentsPageState) => state.errorHandled
  );

  public readonly configurationChanged$: Observable<boolean> = this.select(
    (state: PaymentsPageState) => state.configurationChanged
  );

  constructor(
    private snackbar: SnackbarService,
    private serviceFacade: ServiceFacade,
    private companyApiService: CompanyApiService,
    private translate: TranslateService
  ) {
    super(paymentsPageInitialState);
  }

  public readonly checkPaymentConnection = this.effect(
    (options$: Observable<string>) =>
      options$.pipe(
        tap(() => this.setIntegrationCheck(true)),
        switchMap((companyShortName: string) =>
          this.companyApiService.getPaymentConfiguration(companyShortName).pipe(
            tapResponse(
              (response: PaymentConfigurationResponse | null) => {
                this.setConfiguration(response);
                this.setConfigurationChanged(false);
                this.setErrorHandled(false);
              },
              (error: HttpErrorResponse) => {
                if (ErrorCodes?.[error.error?.['errorCode']]) {
                  return;
                }

                this.setErrorHandled(true);

                this.snackbar.showMessage(
                  this.translate.instant('payments.page.message.check_connection.failed', {
                    text: parseError(error)
                  })
                );
              }
            ),
            finalize(() => this.setIntegrationCheck(false))
          )
        )
      )
  );

  public readonly updatePaymentSettings = this.effect(
    (options$: Observable<UpdatePaymentConfigurationRequest>) =>
      options$.pipe(
        tap(() => this.setUpdateConfigurationPending(true)),
        withLatestFrom(this.serviceFacade.company$),
        filter(
          ([, company]: [UpdatePaymentConfigurationRequest, Company]) =>
            company?.shortName !== undefined
        ),
        switchMap(
          ([payload, company]: [UpdatePaymentConfigurationRequest, Company]) =>
            this.companyApiService
              .updatePaymentConfiguration(company.shortName, payload)
              .pipe(
                tapResponse(
                  () => {
                    this.snackbar.showMessage(
                      this.translate.instant('payments.page.message.update_settings.success'),
                      'success'
                    );

                    this.updateConfiguration(payload);
                    this.setConfigurationChanged(false);
                  },
                  (error: HttpErrorResponse) => {
                    this.snackbar.showMessage(
                      this.translate.instant('payments.page.message.update_settings.failed', {
                        text: parseError(error)
                      }),
                    );
                  }
                ),
                finalize(() => {
                  this.setUpdateConfigurationPending(false);
                })
              )
        )
      )
  );

  public readonly setConfigurationChanged = this.updater(
    (state: PaymentsPageState, configurationChanged: boolean) => ({
      ...state,
      configurationChanged
    })
  );

  private readonly updateConfiguration = this.updater(
    (state: PaymentsPageState, request: UpdatePaymentConfigurationRequest) => {
      state.configuration.isRecurringEnabled = request.isRecurringEnabled;
      state.configuration.schedule = request.schedule;

      return {
        ...state
      };
    }
  );

  private readonly setErrorHandled = this.updater(
    (state: PaymentsPageState, errorHandled: boolean) => ({
      ...state,
      errorHandled
    })
  );

  private readonly setConfiguration = this.updater(
    (
      state: PaymentsPageState,
      configuration: PaymentConfigurationResponse | null
    ) => ({
      ...state,
      configuration
    })
  );

  private readonly setUpdateConfigurationPending = this.updater(
    (state: PaymentsPageState, updateConfigurationPending: boolean) => ({
      ...state,
      updateConfigurationPending
    })
  );

  private readonly setIntegrationCheck = this.updater(
    (state: PaymentsPageState, integrationCheck: boolean) => ({
      ...state,
      integrationCheck
    })
  );
}
