import { Injectable } from '@angular/core';
import { PbxOnRdaApiService } from './pbx-on-rda-api.service';

@Injectable({
    providedIn: 'root'
})
export class PbxOnRdaUtilsService {
    constructor(
        private pbxOnRdaApiService: PbxOnRdaApiService
    ) { }

    async changeRda(oldPbxOnRdaUid: string, newPbxOnRdaUid: string, rdaUid: string): Promise<void> {
        try {
            await this.pbxOnRdaApiService.disconnectFromRda(oldPbxOnRdaUid, rdaUid).toPromise();
            await this.pbxOnRdaApiService.connectToRda(newPbxOnRdaUid, rdaUid).toPromise();
        } catch (error) {
            throw error;
        }
    }
}
