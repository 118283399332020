<div class="service-navbar">
  <app-navbar
    [neutral]="false"
    [links]="pageModes"
    [active]="pageMode"
    (select)="onChangeTab($event.idx)"></app-navbar>
  <div class="button-group">
    <app-button-link
      *ngIf="showOfflineLink"
      [link]="offlineRDALink"
      class="link"
      color="red">
      {{
        (smWDownBreakpoint$ | async)
          ? this.translate.instant('service-navbar.message.why_offline')
          : this.translate.instant('service-navbar.message.why_offline_adapter')
      }}
    </app-button-link>
    <app-button-link
      [link]="link"
      class="link">
      {{ text }}
    </app-button-link>
  </div>
</div>
