import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectSearchModule } from '@app/shared/containers/select-search';
import { IpRdaConnectionTypePipe, SharedPipesModule } from '@app/shared/pipes';
import { RdaSearchSelectComponent } from './rda-search-select.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SelectSearchModule,
        SharedPipesModule,
    ],
    declarations: [RdaSearchSelectComponent],
    providers: [
        IpRdaConnectionTypePipe
    ],
    exports: [RdaSearchSelectComponent]
})
export class RdaSelectSearchModule { }
