import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ResourcePath, ResourcesHelper} from '@app/shared/entities';
import {DialogWrapperData} from '@app/shared/ui';
import {ServiceFacade} from '@app/views/services/store';
import {Observable} from 'rxjs';
import {ServiceFlatDeletePopupBody} from './service-flat-delete-popup-body.model';
import {ServiceFlatDeletePopupService} from './service-flat-delete-popup.service';
import {ServiceFlat} from '@app/views/services/components';

@Component({
  selector: 'app-service-flat-delete-popup',
  templateUrl: './service-flat-delete-popup.component.html',
  styleUrls: ['./service-flat-delete-popup.component.scss'],
  providers: [ServiceFlatDeletePopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceFlatDeletePopupComponent implements OnInit {
  readonly resourcePath: typeof ResourcePath = ResourcePath;
  popupState$: Observable<'loading' | 'close' | 'loaded'> = this.popupService.state$;

  constructor(
    public resourcesHelper: ResourcesHelper,
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<ServiceFlatDeletePopupBody, void>,
    private serviceFacade: ServiceFacade,
    private dialogRef: MatDialogRef<ServiceFlatDeletePopupComponent>,
    private popupService: ServiceFlatDeletePopupService,
  ) {}

  ngOnInit() {
    this.popupService.addStateChangeListener(
      (state: 'loading' | 'close' | 'loaded') => state === 'close' && this.dialogRef.close(state),
    );
  }

  onDelete() {
    this.data.body.flats.forEach((flat: ServiceFlat) => {
      if (flat?.flat?.address?.entrance?.id) {
        this.serviceFacade.deleteFlat(flat.flat.address.entrance.id, flat.flatNumber, flat.flat.id);
      }
    });
  }

  public get listOfFlats(): string {
    return this.data.body.flats.map(flat => '№' + flat.flatNumber.toString()).join(', ');
  }

  public get countFlats(): number {
    return this.data.body.flats.length;
  }
}
