import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { ActiveHistoryResponse, Camera, Company, RdaResponse, ServiceResponse } from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';
import { Address, LogsComponentType, LogsResponse, ServicesTypes } from '@app/shared/models';
import { ServiceEntrancesFlats, ServiceFlatsFilters } from '@app/views/services/components';
import { ServiceActivitySource, ServicePageMode } from '@app/views/services/models';
import { ServicesHelper } from '@app/views/services/services';
import { ServiceFacade } from '@app/views/services/store';
import { VideoSurveillancePageListeners } from '../../services';

@Component({
  selector: 'app-video-surveillance-page',
  templateUrl: './video-surveillance-page.component.html',
  styleUrls: ['./video-surveillance-page.component.scss'],
  providers: [ServicesHelper, VideoSurveillancePageListeners],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoSurveillancePageComponent implements OnInit, OnDestroy {
  readonly pageMode = ServicePageMode;
  readonly serviceType: ServicesTypes = ServicesTypes.VIDEO_SURVEILLANCE;

  pageMode$: Observable<ServicePageMode> = this.serviceFacade.pageMode$;

  // Service general data
  serviceId$: Observable<number> = this.serviceFacade.serviceId$;
  serviceName$: Observable<string> = this.serviceFacade.serviceName$;
  serviceTariff$: Observable<number> = this.serviceFacade.serviceTariff$;
  serviceCustomName$: Observable<string> = this.serviceFacade.serviceCustomName$;
  dependantServices$: Observable<Pick<ServiceResponse, 'id' | 'type'>[]> = this.serviceFacade.dependantServices$;
  entrances$: Observable<Address[]> = this.serviceFacade.entrances$;
  cameras$: Observable<Camera[]> = this.serviceFacade.cameras$;
  rdas$: Observable<RdaResponse[]> = this.serviceFacade.rdas$;

  // Service additional data
  company$: Observable<Company> = this.serviceFacade.company$;
  abonentsLoading$: Observable<boolean> = this.serviceFacade.abonentsLoading$;

  // Flats data
  flats$: Observable<ServiceEntrancesFlats> = this.serviceFacade.filteredFlats$;
  flatsFilters$: Observable<ServiceFlatsFilters> = this.serviceFacade.flatsFilters$;

  // Service activity
  logs$: Observable<LogsResponse[]> = this.serviceFacade.logsData$;
  logsLoading$: Observable<boolean> = this.serviceFacade.logsLoading$;
  blocksCount$: Observable<number> = this.serviceFacade.blocksCount$;
  sources$: Observable<ServiceActivitySource[]> = this.serviceFacade.sources$;
  timeRange$: Observable<number> = this.serviceFacade.timeRange$;
  extendedMode$: Observable<boolean> = this.serviceFacade.extendedMode$;
  totalLogsCount$: Observable<number> = this.serviceFacade.totalLogsCount$;
  currentLogsCount$: Observable<number> = this.serviceFacade.currentLogsCount$;
  logsComponentType$: Observable<LogsComponentType> = this.serviceFacade.logsComponentType$;
  selectedLogsSource$: Observable<ServiceActivitySource> = this.serviceFacade.selectedLogsSource$;
  activeHistoryCameras$: Observable<Dictionary<ActiveHistoryResponse[]>> = this.serviceFacade.activeHistoryCameras$;

  constructor(
    private serviceFacade: ServiceFacade,
    private serviceHelper: ServicesHelper,
    private pageListeners: VideoSurveillancePageListeners,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    const serviceId: number = this.serviceHelper.getServiceIdFromParams();
    this.serviceFacade.getService(serviceId);
    this.serviceFacade.getCamerasLocations();
    this.enableEmptyServiceListener();
  }

  ngOnDestroy() {
    this.serviceFacade.clearService();
  }

  onManageClose() {
    this.loadServiceDate();
  }

  loadServiceDate() {
    const serviceId = this.serviceHelper.getServiceIdFromParams();
    this.serviceFacade.getService(serviceId);
  }

  onChangePageMode(pageMode: ServicePageMode) {
    this.serviceFacade.setPageMode(pageMode);
  }

  private enableEmptyServiceListener() {
    this.pageListeners.addServiceEmptyListener(() => {
      this.serviceFacade.setPageMode(ServicePageMode.SETTINGS);
      this.changeDetectorRef.markForCheck();
    });
  }
}
