import { Injectable } from '@angular/core';
import { LocalStorageGeneralKey, LocalStorageHelper } from '@app/shared/entities/common';
import { CompanyUtilsService, ReportsUtils } from '@app/shared/entities/rd';

@Injectable()
export class DashboardReportsHelperService {
    constructor(
        private companyUtilsService: CompanyUtilsService,
    ) { }

    offerLicenseAgreement(): boolean {
        const licenseAgreement = LocalStorageHelper.getItem(LocalStorageGeneralKey.LICENSE_AGREEMENT);
        return ReportsUtils.offerLicenseAgreement(licenseAgreement);
    }

    rosDomofonCompany(): boolean {
        return this.companyUtilsService.rosDomofonCompany();
    }
}
