import { Camera } from '@app/shared/entities/rd/camera/models';
import { RdaResponse } from '@app/shared/entities/rd/rda';
import { Address } from '@app/shared/models';

export class EntrancePageResponse {
    public address: Address;
    public addressString: string;
    public id: number;
    public rda: RdaResponse;
    public cameras: Camera[];
}
