<div class="page-container">
  <mat-table class="card-container" *ngIf="(shieldTests$ | async)?.length > 0; else emptyTests"
    [dataSource]="(shieldTests$ | async)">
    <ng-container matColumnDef="error">
      <mat-header-cell *matHeaderCellDef>
        {{ 'intercom.pages.shield.tests.field.error' | translate }}
      </mat-header-cell>
      <mat-cell
        data-label="error"
        label-name="{{ 'intercom.pages.shield.tests.field.error' | translate }}"
        *matCellDef="let element"
      >
        {{ element.error }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="description">
      <mat-header-cell *matHeaderCellDef>
        {{ 'intercom.pages.shield.tests.field.description' | translate }}
      </mat-header-cell>
      <mat-cell
        data-label="description"
        label-name="{{ 'intercom.pages.shield.tests.field.description' | translate }}"
        *matCellDef="let element"
      >
        {{ element.description }}
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="createdAt">
      <mat-header-cell *matHeaderCellDef>
        {{ 'intercom.pages.shield.tests.field.created_at' | translate }}
      </mat-header-cell>
      <mat-cell
        data-label="createdAt"
        label-name="{{ 'intercom.pages.shield.tests.field.created_at' | translate }}"
        *matCellDef="let element"
      >
        {{ element.createdAt | date: 'dd.MM.yyyy HH:mm:ss' }}
      </mat-cell>
    </ng-container>

    <mat-header-row [style.display]="resolution.isTablet ? 'none' : 'flex'" *matHeaderRowDef="displayedColumns"
    ></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>
  <ng-template #emptyTests>
    <app-empty-data></app-empty-data>
  </ng-template>
</div>
