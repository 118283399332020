import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Output
} from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {
  LocalStorageCompanyKey,
  LocalStorageHelper
} from '@app/shared/entities';
import { IUserRequest } from '@app/views/company/models/company-page';
import { IRoleConfig } from '@app/views/company/pages/company-roles-page/company-user-profile/company-user-profile.component';
import { RolesComponentStoreService } from '@app/views/company/pages/company-roles-page/roles-component-store.service';
import {TranslateService} from '@ngx-translate/core';
import {
  UserManagementMode
} from '@app/views/company/pages/company-roles-page/company-roles-management/company-roles-management.component';

@Component({
  selector: 'app-company-create-user',
  templateUrl: './company-create-user.component.html',
  styleUrls: ['./company-create-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyCreateUserComponent {
  public userForm: FormGroup = this.initForm();
  public ROLE_WARN_MESSAGE: string = this.translate.instant('company.roles.create.message.need_select_role');

  protected readonly allAvailableRoles = this.rolesComponentStoreService.allAvailableRoles;
  protected readonly RolesMode = UserManagementMode;

  constructor(
    public rolesComponentStoreService: RolesComponentStoreService,
    private translate: TranslateService
  ) {}

  @Output() submit: EventEmitter<IUserRequest> =
    new EventEmitter<IUserRequest>();

  public onAccess(roles: IRoleConfig[]): void {
    this.userForm.patchValue({
      roleNames: roles.map((r) => r.name)
    });
  }

  public onSubmit(): void {
    if (this.userForm.valid) {
      this.submit.emit(this.userForm.getRawValue());
    }
  }

  public getFormControl(controlName: string): UntypedFormControl {
    return this.userForm?.get(controlName) as UntypedFormControl;
  }

  private initForm(): FormGroup {
    return new FormGroup({
      name: new FormControl(null, []),
      password: new FormControl(null, []),
      email: new FormControl(null, [Validators.required, Validators.email]),
      companyId: new FormControl(
        +LocalStorageHelper.getItem(LocalStorageCompanyKey.ID),
        []
      ),
      roleNames: new FormControl([], [Validators.required])
    });
  }
}
