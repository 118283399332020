import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { IntercomsListContentModel } from './intercoms-list-content.model';
import { IntercomsListContentStore } from './store';

@Component({
  selector: 'app-intercoms-list-content',
  templateUrl: './intercoms-list-content.component.html',
  styleUrls: ['./intercoms-list-content.component.scss'],
  providers: [IntercomsListContentStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntercomsListContentComponent implements OnInit {
  @Input() selectedAllByDefault = true;
  @Input() loading: boolean;
  @Input() set intercoms(intercoms: IntercomsListContentModel[]) {
    if (!intercoms) {
      return;
    }

    this.store.setIntercoms(intercoms);
  }

  selectedAll$: Observable<boolean> = this.store.selectedAll$;
  intercoms$: Observable<IntercomsListContentModel[]> = this.store.intercoms$;
  required: boolean;

  @Output() private intercomsSelected: EventEmitter<{ intercoms: IntercomsListContentModel[] }> = new EventEmitter();

  constructor(
    private store: IntercomsListContentStore
  ) { }

  ngOnInit() {
    this.store.addSelectedIntercomsListener((intercoms: IntercomsListContentModel[]) =>
      this.intercomsSelected.emit({ intercoms })
    );

    this.store.setSelectedAll(this.selectedAllByDefault);
  }

  onSelectIntercom(value: number | boolean) {
    if (typeof value === 'boolean') {
      this.store.changeSelectedAll();
      return;
    }

    this.store.selectIntercom(value);
  }

  filterFunc(item: (IntercomsListContentModel)): boolean {
    return item.selected;
  }
}
