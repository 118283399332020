<mat-card class="card-container profile">
  <div class="header">
    <div class="title-block">
      {{ name }}
    </div>
    <div class="status">
      <mat-icon [svgIcon]="isBlocked ? 'offline' : 'active'" class="icon" />
      <span class="status-text">
        {{
          isBlocked
            ? ('company.about.page.profile.enum.status.blocked' | translate )
            : ('company.about.page.profile.enum.status.active' | translate )
        }}
      </span>
    </div>
  </div>
  <mat-card-content>
    <div class="profile-item">
      <span>{{ username }}</span>
      <!-- <span class="text">Админ</span> -->
    </div>
    <div class="btn-group">
      <button (click)="onOpenSettingsPopup()">
        {{ 'company.about.page.profile.button.password' | translate }}
      </button>
      <button (click)="onLogout()">
        {{ 'company.about.page.profile.button.logout' | translate }}
      </button>
    </div>
  </mat-card-content>
</mat-card>
