import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class LoaderService {
    private pageLoaderSubject: BehaviorSubject<boolean>;
    private pageLoaderState: boolean;
    private componentLoaderSubject: BehaviorSubject<boolean>;
    private componentLoaderState: boolean;
    private bottomSheetLoaderSubject: BehaviorSubject<boolean>;
    private bottomSheetLoaderState: boolean;

    constructor() {
        this.pageLoaderSubject = new BehaviorSubject<boolean>(false);
        this.componentLoaderSubject = new BehaviorSubject<boolean>(false);
        this.bottomSheetLoaderSubject = new BehaviorSubject<boolean>(false);
    }

    set loaderState(event: { state: boolean, type?: 'page' | 'component' | 'bottom-sheet' }) {
        if (event.state === false) {
            this.componentLoaderState = event.state;
            this.componentLoaderSubject.next(event.state);
            this.pageLoaderState = event.state;
            this.pageLoaderSubject.next(event.state);
            this.bottomSheetLoaderState = event.state;
            this.bottomSheetLoaderSubject.next(event.state);
            return;
        }

        if (event.type === 'component' && !this.bottomSheetLoaderState && !this.pageLoaderState) {
            this.componentLoaderState = event.state;
            this.componentLoaderSubject.next(event.state);
        } else if (event.type === 'bottom-sheet' && !this.pageLoaderState && !this.componentLoaderState) {
            this.bottomSheetLoaderState = event.state;
            this.bottomSheetLoaderSubject.next(event.state);
        } else if (!this.componentLoaderState && !this.bottomSheetLoaderState) {
            this.pageLoaderState = event.state;
            this.pageLoaderSubject.next(event.state);
        }
    }

    getPageLoaderState(): Observable<boolean> {
        return this.pageLoaderSubject.asObservable();
    }

    getComponentLoaderState(): Observable<boolean> {
        return this.componentLoaderSubject.asObservable();
    }

    getBottomSheetLoaderState(): Observable<boolean> {
        return this.bottomSheetLoaderSubject.asObservable();
    }
}
