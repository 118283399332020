import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {PersonalSurveillance} from '@app/shared/entities/rd/services/models/personal-surveillance.model';
import {of, Subject} from 'rxjs';
import {ServiceApiService} from '@app/shared/entities/rd';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {SnackbarService} from '@app/shared/components';
import {AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {ResolutionBreakpoint, ResolutionService} from '@app/shared/services';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {catchError, map, takeUntil} from 'rxjs/operators';
import {InputNumberModule} from 'primeng/inputnumber';
import {TranslateModule} from '@ngx-translate/core';
import {parseError} from '@app/shared/helpers';

export namespace ApartmentVideoChangeTariff {
  export interface FormOutput {
    tariff: number;
  }

  export interface Form {
    tariff: AbstractControl<number>;
  }

  export interface MInput {
    serviceInfo: PersonalSurveillance.ServiceByIdResponse;
  }

  export interface MOutput {
    serviceInfo: PersonalSurveillance.ServiceByIdResponse;
    form: FormOutput;
  }
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    ProgressSpinnerModule,
    InputNumberModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  selector: 'app-apartment-video-change-tariff',
  templateUrl: './apartment-video-change-tariff.component.html',
  styleUrls: ['./apartment-video-change-tariff.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ApartmentVideoChangeTariffComponent implements OnInit, OnDestroy {
  public loaded = false;
  public showLoading = false;
  public modalForm: FormGroup<ApartmentVideoChangeTariff.Form>;

  public get serviceInfo(): PersonalSurveillance.ServiceByIdResponse {
    return this.dynamicDialogConfig?.data?.serviceInfo;
  }

  public get isFormValid(): boolean {
    return this.modalForm?.valid;
  }

  private destroy = new Subject<void>();

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private resolutionService: ResolutionService,
    private serviceApiService: ServiceApiService,
    private snackbar: SnackbarService,
  ) {
    this.dynamicDialogConfig.styleClass = 'apartment-video-change-tariff__modal';
    if (this.resolutionService.getBreakpointState(ResolutionBreakpoint.SM_W_DOWN)) {
      this.dynamicDialogConfig.height = '100%';
      this.dynamicDialogConfig.width = '100%';
      this.dynamicDialogConfig.styleClass = `${this.dynamicDialogConfig.styleClass} apartment-video-change-tariff__modal--full-screen`;
    }
  }


  public ngOnInit(): void {
    this.modalForm = new FormGroup<ApartmentVideoChangeTariff.Form>({
      tariff: new FormControl<number>({value: this.serviceInfo?.tariff, disabled: false}, [Validators.required]),
    });
    this.loaded = true;
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public onSaveAction(): void {
    if (this.isFormValid) {
      this.showLoading = true;
      this.serviceApiService.updateServiceTariff(
        this.serviceInfo.id,
        {
          tariff: this.modalForm.get('tariff').value,
          applyTariffForConnections: false
        }
      )
        .pipe(
          takeUntil(this.destroy),
          map((response) => true),
          catchError((err) => {
            this.snackbar.showMessage(parseError(err));
            this.showLoading = false;
            return of(null);
          })
        )
        .subscribe((response) => {
          if (response) {
            this.dynamicDialogRef.close(<ApartmentVideoChangeTariff.MOutput>{
              form: this.modalForm.getRawValue(),
              serviceInfo: this.serviceInfo
            });
          }
        });
    }
  }
}
