import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '@app/shared/components';
import { LoaderService } from '@app/shared/entities/common/loader';
import { DadataApiService, DadataCompanyResponse, DadataCompanySuggestion } from '@app/shared/entities/integrations/dadata';
import { CompanyApiService, CompanyRegisterRequest, CompanyTokenVerifyResponse, PhonesUtils, ReportsUtils } from '@app/shared/entities/rd';
import { parseError } from '@app/shared/helpers';
import { from, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-company-register-form',
  templateUrl: './company-register-form.component.html',
  styleUrls: ['./company-register-form.component.scss']
})
export class CompanyRegisterFormComponent implements OnInit, OnDestroy {

  firstFormGroup: UntypedFormGroup;
  secondFormGroup: UntypedFormGroup;
  thirdFormGroup: UntypedFormGroup;
  licenseAgreementUrl: string;
  companyNameWithOpf: string;

  private email: string;
  private kpp: string;
  private country: string;
  private companyType: string;
  private licenseeShort: string;
  private managementName: string;
  private managementPost: string;
  private shortCompanyName: string;
  private onDestroy$: Subject<void> = new Subject();
  @Output() private submitted: EventEmitter<{ request: CompanyRegisterRequest, errorMessage: string }> = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private loader: LoaderService,
    private snackbar: SnackbarService,
    private companyApiService: CompanyApiService,
    private dadataApiService: DadataApiService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.licenseAgreementUrl = ReportsUtils.OFFER_LICENSE_AGREEMENT;
    this.initForms();
    this.verifyToken();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSubmit() {
    if (!this.thirdFormGroup.get('accept').value) {
      this.snackbar.showMessage(
        this.translate.instant('company.register.page.form.message.need_accept'),
        'info'
      );
      return;
    }

    this.submitted.emit({ request: this.prepareRequest(), errorMessage: null });
  }

  private prepareRequest() {
    const { companyName, ogrn, individualNumber, legalAddress } = this.firstFormGroup.getRawValue();
    const { email, phone } = this.secondFormGroup.getRawValue();

    const request: CompanyRegisterRequest = {
      shortName: this.shortCompanyName,
      name: companyName,
      licenseeShort: this.licenseeShort,
      ogrn,
      email,
      inn: individualNumber,
      kpp: this.kpp,
      managementName: this.managementName,
      managementPost: this.managementPost,
      companyType: this.companyType,
      country: this.country,
      address: legalAddress,
      supportPhone: PhonesUtils.toString(phone, true),
      paymentLink: null,
      paymentDetails: {
        account: null,
        bank: null,
        bic: null,
        corrAcc: null
      }
    };

    return request;
  }

  private initForms() {
    this.firstFormGroup = new UntypedFormGroup({
      companyName: new UntypedFormControl('', Validators.required),
      ogrn: new UntypedFormControl('', Validators.required),
      individualNumber: new UntypedFormControl('', Validators.required),
      legalAddress: new UntypedFormControl('', Validators.required)
    });
    this.secondFormGroup = new UntypedFormGroup({
      actualAddress: new UntypedFormControl('', Validators.required),
      email: new UntypedFormControl('', Validators.required),
      phone: new UntypedFormGroup({
        number: new UntypedFormControl(null, Validators.required),
        prefix: new UntypedFormControl(null, Validators.required)
      })
    });
    this.thirdFormGroup = new UntypedFormGroup({
      accept: new UntypedFormControl('', Validators.required)
    });
  }

  private verifyToken() {
    const token: string = this.route.snapshot.queryParamMap.get('token');

    this.loader.loaderState = { state: true };

    from(this.companyApiService.verifyCompanySignUpToken(token))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: CompanyTokenVerifyResponse) => {
          this.email = response.email;
          this.getCompanyFromData(response.ogrn);
        },
        (error: HttpErrorResponse) => {
          this.loader.loaderState = { state: false };
          this.submitted.emit({ request: null, errorMessage: parseError(error) });
        }
      );
  }

  private getCompanyFromData(query: string) {
    from(this.dadataApiService.getCompany(query))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response: DadataCompanyResponse) => {
          this.loader.loaderState = { state: false };

          this.initVariables(response.suggestions[0]);
          this.fillForms(response.suggestions[0]);
        },
        (error: HttpErrorResponse) => {
          this.loader.loaderState = { state: false };
          this.submitted.emit({ request: null, errorMessage: parseError(error) });
        }
      );
  }

  private initVariables(companyData: DadataCompanySuggestion) {
    this.companyNameWithOpf = companyData.data.name.full_with_opf ?? companyData.data.name.short_with_opf;
    this.companyType = companyData.data.type;

    if (companyData.data.type === 'LEGAL') {
      this.kpp = companyData.data.kpp;
      this.shortCompanyName = companyData.data.name.short ?? companyData.data.name.full;

      if (companyData.data.management) {
        this.managementName = companyData.data.management.name;
        this.managementPost = companyData.data.management.post;
      }
    } else {
      this.shortCompanyName = this.companyNameWithOpf;
    }

    this.licenseeShort = this.companyNameWithOpf;
    this.country = companyData.data.address.data.country || companyData.data.address.data.country_iso_code;
  }

  private fillForms(companyData: DadataCompanySuggestion) {
    this.firstFormGroup.setValue({
      companyName: companyData.data.name.full ?? companyData.data.name.short,
      ogrn: companyData.data.ogrn,
      individualNumber: companyData.data.inn,
      legalAddress: companyData.data.address.data.source,
    });
    this.firstFormGroup.disable();

    this.secondFormGroup.get('actualAddress').setValue(companyData.data.address.value);
    this.secondFormGroup.get('actualAddress').disable();
    this.secondFormGroup.get('email').setValue(this.email);
    this.secondFormGroup.get('email').disable();
  }
}
