import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LocalStorageGeneralKey, LocalStorageHelper } from '@app/shared/entities/common';

@Injectable({
  providedIn: 'root'
})
export class AnonymousGuard implements CanActivate {
  constructor(
    private router: Router
  ) { }

  canActivate(): boolean {
    if (!LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN)) {
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }
}
