import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { SnackbarService } from '@app/shared/components/snackbar';
import {
  IntercomType,
  IntercomTypeGeneratorUrls,
  IpRdaConnectionTypes,
  IpRdaRequest,
  ProtocolTypes
} from '@app/shared/entities/rd';
import { IP_INTERCOMS_GUIDE_LINK } from '@app/shared/helpers';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-ip-intercom-generate-content',
  templateUrl: './ip-intercom-generate-content.component.html',
  styleUrls: ['./ip-intercom-generate-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IpIntercomGenerateContentComponent implements OnInit {
  readonly IP_INTERCOMS_GUIDE_LINK: string = IP_INTERCOMS_GUIDE_LINK;
  readonly sipTrunkConnectionType: IpRdaConnectionTypes =
    IpRdaConnectionTypes.SIPTRUNK;
  readonly ipRdaConnectionTypes: IpRdaConnectionTypes[] = [
    IpRdaConnectionTypes.DIRECT,
    IpRdaConnectionTypes.SIPTRUNK
  ];
  public readonly ProtocolTypes = ProtocolTypes;

  @Input() intercomType!: IntercomType;
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  form = this.initForm();
  generateUrlsMode = false;
  hidePassword: boolean;
  basIpIntercom: boolean;
  sputnikIntercom: boolean;

  @Output() private createIpIntercom: EventEmitter<{ request: IpRdaRequest }> =
    new EventEmitter();
  private _loading: boolean;

  constructor(
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.basIpIntercom =
      this.intercomType.protocol.number === ProtocolTypes.BAS_IP;
    this.sputnikIntercom =
      this.intercomType.protocol.number === ProtocolTypes.Sputnik;
    if (this.basIpIntercom || this.sputnikIntercom) {
      this.form.addControl('authUrl', new UntypedFormControl(null));
    }

    this.form.get('mode').setValue(IpRdaConnectionTypes.DIRECT);
    this.hidePassword = true;
  }

  get loading(): boolean {
    return this._loading;
  }

  onSubmit() {
    const { uid, privateKey, mode, authUrl, openDoorUrl, livenessUrl } =
      this.form.getRawValue();
    if (this.isBeward && uid?.length > 17) {
      this.snackbar.showMessage(
        this.translate.instant('shared.rda.intercom.generate.content.message.uid_max_symbols', {
          length: 17
        }),
        'info');
      return;
    }
    if (!privateKey && mode === IpRdaConnectionTypes.DIRECT) {
      this.snackbar.showMessage(
        this.translate.instant('shared.rda.intercom.generate.content.message.need_password'),
        'info'
      );
      return;
    }

    const configStr = this.setConfigStr(livenessUrl, authUrl);

    const request: IpRdaRequest = {
      uid,
      privateKey,
      mode,
      authUrl,
      openDoorUrl,
      configStr,
      intercomTypeId: this.intercomType.id
    };
    this.createIpIntercom.emit({ request });
  }

  onChangeGenerateUrlsMode() {
    this.generateUrlsMode = !this.generateUrlsMode;
  }

  onGenerated(data: Partial<IntercomTypeGeneratorUrls>) {
    if (this.intercomType.protocol.number === ProtocolTypes.BAS_IP) {
      this.form.get('authUrl').setValue(data.authUrl);
    }

    if (this.intercomType.protocol.number === ProtocolTypes.Sputnik) {
      this.form.get('authUrl').setValue(data.bearerToken);
    }

    if (this.intercomType.protocol.number === ProtocolTypes.Hikvision) {
      this.form.addControl('login', new FormControl(data.login));
      this.form.addControl('password', new FormControl(data.password));
    }

    if (this.intercomType.protocol.number === ProtocolTypes.Rubitek) {
      this.form.addControl('login', new FormControl(data.login));
      this.form.addControl('password', new FormControl(data.password));
    }

    this.form.get('livenessUrl').setValue(data.livenessUrl);
    this.form.get('openDoorUrl').setValue(data.openDoorUrl);
    this.generateUrlsMode = false;
  }

  get protocolType() {
    return this.intercomType.protocol.number;
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      uid: new UntypedFormControl(),
      privateKey: new UntypedFormControl(null),
      livenessUrl: new UntypedFormControl(null),
      openDoorUrl: new UntypedFormControl(null),
      mode: new UntypedFormControl(null, Validators.required)
    });
  }

  private setConfigStr(livenessUrl: string, bearerToken: string): string {
    if (livenessUrl && bearerToken) {
      return `liveness:${livenessUrl};bearerToken:${bearerToken}`;
    }
    if (livenessUrl) {
      const protocolNumber = this.intercomType.protocol.number;
      let configStr = `liveness:${livenessUrl}`;
      if ((protocolNumber === ProtocolTypes.Hikvision || protocolNumber === ProtocolTypes.Rubitek) && this.form.get('password') && this.form.get('login')) {
        configStr += `;password:${this.form.get('password').value};login:${this.form.get('login').value}`;
      }
      return configStr;
    }
    if (bearerToken) {
      return `bearerToken:${bearerToken}`;
    }
    return '';
  }

  private get isBeward(): boolean {
    return this.intercomType.name.includes('Beward');
  }
}
