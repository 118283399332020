import { Injectable } from '@angular/core';
import { IntercomTypeApiService } from '@app/shared/entities/rd';
import { RdaApiService } from '@app/shared/entities/rd/rda';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { ActionTypes, DisconnectAdapter, DisconnectAdapterClear, DisconnectAdapterFailure, DisconnectAdapterSuccess, GetAvaliableIntercoms, GetAvaliableIntercomsFailure, GetAvaliableIntercomsSuccess, GetAvaliableIntercomsV1, GetAvaliableIntercomsV1Failure, GetAvaliableIntercomsV1Success, GetIntercomTypes, GetIntercomTypesFailure, GetIntercomTypesSuccess, GetNewIntercoms, GetNewIntercomsClear, GetNewIntercomsFailure, GetNewIntercomsSuccess, UpdateIntercom, UpdateIntercomFailure, UpdateIntercomSuccess } from '../actions/intercom.actions';

@Injectable()
export class IntercomEffects {
  constructor(
    private actions$: Actions,
    private rdaApiService: RdaApiService,
    private intercomTypeApiService: IntercomTypeApiService
  ) { }

  @Effect()
  UpdateIntercom$ = this.actions$.pipe(
    ofType<UpdateIntercom>(ActionTypes.UpdateIntercom),
    exhaustMap(action =>
      this.rdaApiService.updateAdapter(action.request.uid, action.request).pipe(
        map(() => new UpdateIntercomSuccess(action.request)),
        catchError(error => of(new UpdateIntercomFailure(error)))
      )
    )
  );

  @Effect()
  GetAvaliableIntercoms$ = this.actions$.pipe(
    ofType<GetAvaliableIntercoms>(ActionTypes.GetAvaliableIntercoms),
    concatMap(action =>
      this.rdaApiService.getAdaptersList(action.page, action.size, action.inactive, action.ipType, action.mode, action.address, action.sort).pipe(
        map(data => new GetAvaliableIntercomsSuccess(data.content, data.totalElements)),
        catchError(error => of(new GetAvaliableIntercomsFailure(error)))
      )
    )
  );

  @Effect()
  GetAvaliableIntercomsV1$ = this.actions$.pipe(
    ofType<GetAvaliableIntercomsV1>(ActionTypes.GetAvaliableIntercomsV1),
    switchMap(action =>
      this.rdaApiService.getAdaptersListV1(action.inactive).pipe(
        map(data => new GetAvaliableIntercomsV1Success(data)),
        catchError(error => of(new GetAvaliableIntercomsV1Failure(error)))
      )
    )
  );

  @Effect()
  GetNewIntercoms$ = this.actions$.pipe(
    ofType<GetNewIntercoms>(ActionTypes.GetNewIntercoms),
    switchMap(action =>
      this.rdaApiService.getNewIntercoms(action.page, action.size, action.uid).pipe(
        map(data => new GetNewIntercomsSuccess(data.content, data.totalElements)),
        catchError(error => of(new GetNewIntercomsFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  GetNewIntercomsClear = this.actions$.pipe(
    ofType<GetNewIntercomsClear>(ActionTypes.GetNewIntercomsClear),
    tap(() => new GetNewIntercomsClear())
  );

  @Effect()
  GetIntercomTypes$ = this.actions$.pipe(
    ofType<GetIntercomTypes>(ActionTypes.GetIntercomTypes),
    switchMap(_ =>
      this.intercomTypeApiService.getAdaptersTypes().pipe(
        map(types => new GetIntercomTypesSuccess(types)),
        catchError(error => of(new GetIntercomTypesFailure(error)))
      )
    )
  );

  @Effect()
  DisconnectAdapter$ = this.actions$.pipe(
    ofType<DisconnectAdapter>(ActionTypes.DisconnectAdapter),
    switchMap(action =>
      this.rdaApiService.deleteAddress(action.adapter).pipe(
        map(() => new DisconnectAdapterSuccess()),
        catchError(error => of(new DisconnectAdapterFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  DisconnectAdapterClear = this.actions$.pipe(
    ofType<DisconnectAdapterClear>(ActionTypes.DisconnectAdapterClear),
    tap(() => new DisconnectAdapterClear())
  );
}
