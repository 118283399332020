import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { ComponentLoaderModule, InfoBlockModule } from '@app/shared/ui';
import { AccountSelectionModule } from '../account-selection';
import { PhoneInputModule } from './../../phone-input';
import { AbonentConnectionToolComponent } from './abonent-connection-tool.component';
import { AbonentConnectionToolService } from './abonent-connection-tool.service';
import { AbonentConnectionToolContentComponent, AbonentConnectionToolServicesComponent } from './containers';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        PhoneInputModule,
        InfoBlockModule,
        SharedPipesModule,
        ComponentLoaderModule,
        AccountSelectionModule,
        NgxTranslateModule
    ],
    declarations: [
        AbonentConnectionToolComponent,
        AbonentConnectionToolContentComponent,
        AbonentConnectionToolServicesComponent
    ],
    providers: [
        AbonentConnectionToolService
    ],
    exports: [
        AbonentConnectionToolComponent,
        AbonentConnectionToolServicesComponent
    ]
})
export class AbonentConnectionToolModule { }
