import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ActivatedRoute } from '@angular/router';
import { SnackbarService } from '@app/shared/components/snackbar';
import { ResourcePath, ResourcesHelper } from '@app/shared/entities';
import {
  IntercomType,
  IntercomTypeGeneratorUrls,
  IntercomTypeHandsetLockParams,
  IpRdaConnectionTypes,
  PbxOnRdaResponse,
  ProtocolTypes,
  RdaResponse,
  RdaUpdateRequest,
  RdaUtilsService
} from '@app/shared/entities/rd';
import { Constants, UNSUPPORTED_INTERCOM_TYPE_LINK } from '@app/shared/helpers';
import { SelectSearch } from '@app/shared/models';
import { IpRdaConnectionTypePipe } from '@app/shared/pipes';
import { DialogWrapperData } from '@app/shared/ui';
import { RdaSearchSelectComponent } from '../rda-search-select';
import { IncorrectIntercomTypeBottomSheetComponent } from './incorrect-intercom-type-bottom-sheet';
import { IpIntercomGenerateBottomSheetComponent } from './ip-intercom-generate-bottom-sheet';
import { IpIntercomHandsetLockBottomSheetComponent } from './ip-intercom-handset-lock-bottom-sheet';
import { IpIntercomUrlsBottomSheetComponent } from './ip-intercom-urls-bottom-sheet';
import { IntercomEditFilter } from './models';
import { specialIntercom } from '@app/shared/components/rda/intercom-edit-content/specialIntercom';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-intercom-edit-content',
  templateUrl: './intercom-edit-content.component.html',
  styleUrls: ['./intercom-edit-content.component.scss'],
  providers: [IpRdaConnectionTypePipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IntercomEditContentComponent implements OnInit {
  readonly intercomEditFilter: typeof IntercomEditFilter = IntercomEditFilter;
  readonly UNSUPPORTED_INTERCOM_TYPE_LINK: string = UNSUPPORTED_INTERCOM_TYPE_LINK;
  protected readonly ProtocolTypes = ProtocolTypes;
  readonly ipRdaConnectionTypes: { type: IpRdaConnectionTypes, name: string }[] = [
    { type: IpRdaConnectionTypes.DIRECT, name: '' },
    { type: IpRdaConnectionTypes.SIPTRUNK, name: '' }
  ];

  @ViewChild(RdaSearchSelectComponent, { static: true }) rdaSelectSearch: RdaSearchSelectComponent;
  @Input() set rda(rda: RdaResponse) {
    this._rda = rda;

    if (!this.rda) {
      return;
    }

    this.prepareInitialValues();
  }
  @Input() set loading(loading: boolean) {
    this._loading = loading;

    if (this.loading) {
      this.rdaForm.disable();
      this.ipRdaForm.disable();
    } else {
      this.rdaForm.enable();
      this.ipRdaForm.enable();
    }
  }
  @Input() set intercomType(intercomType: IntercomType) {
    this._intercomType = intercomType;

    if (intercomType) {
      this.selectedIntercomTypeId = intercomType.id;
      this.afterIntercomTypeSelect();
      this.incorrectIntercomType = false;
      this.intercomTypeReplaced = false;
    }
  }
  @Input() intercomTypes: IntercomType[];
  @Input() pbxOnRda: PbxOnRdaResponse;
  @Input() filter: IntercomEditFilter;
  ipIntercom: boolean;
  incorrectIntercomType: boolean;
  selectedRda: SelectSearch;
  initialSelectedRda: SelectSearch;
  selectedIntercomTypeId: number;
  rdaForm: UntypedFormGroup = this.initRdaForm();
  ipRdaForm: UntypedFormGroup = this.initIpRdaForm();

  @Output() private updateIntercom: EventEmitter<{ request: RdaUpdateRequest }> = new EventEmitter();
  @Output() private changeIntercom: EventEmitter<{ rda: RdaResponse }> = new EventEmitter();
  @Output() private changeIntercomType: EventEmitter<{ intercomType: IntercomType }> = new EventEmitter();
  public readonly resourcePath: typeof ResourcePath = ResourcePath;
  private initialIntercomType: IntercomType;
  private initialRda: RdaResponse;
  private intercomTypeReplaced: boolean;
  private handsetLockParams?: IntercomTypeHandsetLockParams;
  private _rda: RdaResponse;
  private _loading: boolean;
  private _intercomType: IntercomType;

  constructor(
    public readonly resourcesHelper: ResourcesHelper,
    private rdaUtilsService: RdaUtilsService,
    private snackbar: SnackbarService,
    private bottomSheet: MatBottomSheet,
    private ipRdaConnectionTypePipe: IpRdaConnectionTypePipe,
    private readonly route: ActivatedRoute,
    private translate: TranslateService
  ) {
    this.ipRdaConnectionTypes
      .find(t => t.type === IpRdaConnectionTypes.DIRECT)
      .name = this.translate.instant('shared.rda.intercom.edit.content.enum.connection_type.direct');
    this.ipRdaConnectionTypes
      .find(t => t.type === IpRdaConnectionTypes.SIPTRUNK)
      .name = this.translate.instant('shared.rda.intercom.edit.content.enum.connection_type.siptrank');
  }

  ngOnInit(): void {
    if (
      this.rda.intercomType?.protocol?.number === ProtocolTypes.Hikvision ||
      this.rda.intercomType?.protocol?.number === ProtocolTypes.Rubitek ||
      this.rda.intercomType?.protocol?.number === ProtocolTypes.SipCda
    ) {
      this.initLoginAndPassword();
    }
  }

  get rda(): RdaResponse {
    return this._rda;
  }

  get loading(): boolean {
    return this._loading;
  }

  get intercomType(): IntercomType {
    return this._intercomType;
  }

  get isGate(): boolean {
    return this.route.snapshot.queryParams.type === 'gates';
  }

  get isBewardIntercomType(): boolean {
    if (!this.intercomType) {
      return false;
    }
    return this.intercomType.protocol.number === ProtocolTypes.BEWARD || false;
  }

  get isSpecialIntercomType(): boolean {
    if (!this.intercomType) {
      return false;
    }
    return specialIntercom.includes(this.intercomType.name);
  }

  get isSputnikIntercomType(): boolean {
    if (!this.intercomType) {
      return false;
    }
    return this.intercomType.protocol.number === ProtocolTypes.Sputnik || false;
  }

  get isHickIntercomType(): boolean {
    if (!this.intercomType) {
      return false;
    }
    return this.intercomType.protocol.number === ProtocolTypes.Hikvision || false;
  }

  get isRubitekIntercomType(): boolean {
    if (!this.intercomType) {
      return false;
    }
    return this.intercomType.protocol.number === ProtocolTypes.Rubitek || false;
  }

  get isSipCdaIntercomType(): boolean {
    if (!this.intercomType) {
      return false;
    }
    return this.intercomType.protocol.number === ProtocolTypes.SipCda || false;
  }

  get isSoftwareIntercoms(): boolean | null {
    return this.route.snapshot.queryParams.type === 'software-intercoms' ?
      true : null;
  }

  onSubmitRda() {
    if (!this.intercomType) {
      this.snackbar.showMessage(this.translate.instant('shared.rda.intercom.edit.content.error.indicate_intercom_type'));
      return;
    }

    if (!this.selectedRda?.value) {
      this.snackbar.showMessage(this.translate.instant('shared.rda.intercom.edit.content.error.choose_adapter'));
      return;
    }

    if (!this.rdaForm.valid) {
      this.snackbar.showMessage(this.translate.instant('shared.rda.intercom.edit.content.error.indicate_all_necessary_data'));
      return;
    }

    const { micSensitivity, speakerVolume } = this.rdaForm.getRawValue();
    const request: RdaUpdateRequest = {
      id: this.rda?.id,
      uid: this.selectedRda.value as string,
      intercomTypeId: this.intercomType.id,
      protocolNumber: this.intercomType.protocol.number,
      micSensitivity,
      speakerVolume
    };

    this.updateIntercom.emit({ request });
  }

  onSubmitIpRda() {
    if (!this.intercomType) {
      this.snackbar.showMessage(this.translate.instant('shared.rda.intercom.edit.content.error.indicate_intercom_type'));
      return;
    }

    if (!this.selectedRda?.value) {
      this.snackbar.showMessage(this.translate.instant('shared.rda.intercom.edit.content.error.choose_uid'));
      return;
    }

    if (!this.ipRdaForm.valid) {
      this.snackbar.showMessage(this.translate.instant('shared.rda.intercom.edit.content.error.indicate_all_necessary_data'));
      return;
    }

    const { livenessUrl, opendoorUrl, authURL, bearerToken } = this.ipRdaForm.getRawValue();

    const configStr: string = this.rdaUtilsService.convertIpRdaConfigStr(
      this.intercomType,
      this.rda?.configStr,
      livenessUrl,
      opendoorUrl,
      this.intercomType?.protocol?.number === ProtocolTypes.BAS_IP ? authURL : null,
      this.rda?.mode,
      this.handsetLockParams,
      this.intercomType?.protocol?.number === ProtocolTypes.Sputnik ? bearerToken : null,
      this.isIpAdapter() ? {
        login: this.ipRdaForm.get('login').value,
        password: this.ipRdaForm.get('password').value
      } : null
    );

    const request: RdaUpdateRequest = {
      id: this.rda?.id,
      uid: this.selectedRda.value as string,
      intercomTypeId: this.intercomType.id,
      protocolNumber: this.intercomType.protocol.number,
      configStr
    };

    this.updateIntercom.emit({ request });
  }

  onSelectIntercomType() {
    this.intercomType = this.intercomTypes.find(intercomType => intercomType.id === this.selectedIntercomTypeId);

    if (!this.intercomType) {
      this.afterIntercomTypeSelect();
      this.incorrectIntercomType = false;
      this.intercomTypeReplaced = false;
      return;
    }

    const currentAndInitialIntercomTypesExists = this.intercomType && this.initialIntercomType;

    let incorrectIpType: boolean;
    let replacedIntercomType: boolean;
    let equalsIpTypes: boolean;

    if (currentAndInitialIntercomTypesExists && this.initialIntercomType) {
      incorrectIpType = this.initialIntercomType.protocol.ipType !== this.intercomType.protocol.ipType && !this.incorrectIntercomType;
      replacedIntercomType = this.initialIntercomType.id !== this.intercomType.id && !this.intercomTypeReplaced;
      equalsIpTypes = this.intercomType.protocol.ipType === this.initialIntercomType.protocol.ipType;
    }

    if (incorrectIpType) {
      this.openIncorrectTypeBottomSheet();
      return;
    }

    if (replacedIntercomType) {
      this.openReplacedIntercomTypeBottomSheet();
      return;
    }

    if (equalsIpTypes) {
      this.incorrectIntercomType = false;
    }

    this.afterIntercomTypeSelect();
  }

  onSelectRda(response: RdaResponse, selectedRda: SelectSearch) {
    response = response ?? this.rda;

    if (response) {
      this.rda = response;
      this.selectedRda = selectedRda;

      if (!this.intercomType) {
        this.intercomType = this.intercomTypes.find(type => type.id === this.rda?.intercomTypeId || this.rda?.intercomType?.id);
      }

      this.ipIntercom = this.rdaUtilsService.ipType(this.intercomType);
      this.ipIntercom ? this.prepareIpRdaForm() : this.prepareRdaForm();
    } else {
      this.rda = null;

      if (this.ipIntercom) {
        this.ipRdaForm.get('livenessUrl').setValue(null);
        this.ipRdaForm.get('opendoorUrl').setValue(null);
        this.ipRdaForm.get('authURL').setValue(null);
      } else {
        this.rdaForm.get('micSensitivity').setValue(null);
        this.rdaForm.get('speakerVolume').setValue(null);
      }
    }

    this.incorrectIntercomType = false;
    this.intercomTypeReplaced = false;

    this.changeIntercom.emit({ rda: this.rda });
  }

  intercomTypeWithError(intercomType: IntercomType) {
    return this.unsupportedIntercom(intercomType) || this.incorrectedVersions(intercomType);
  }

  unsupportedIntercom(intercomType: IntercomType): boolean {
    return this.rdaUtilsService.supportedIntercom(this.rda, intercomType) === false;
  }

  incorrectedVersions(intercomType: IntercomType) {
    return this.rdaUtilsService.correctedVersions(this.rda, intercomType) === false;
  }

  intercomTypeErrorText(intercomType: IntercomType) {
    if (this.unsupportedIntercom(intercomType)) {
      return this.translate.instant('shared.rda.intercom.edit.error.not_supported_replace_rda');
    } else if (this.incorrectedVersions(intercomType)) {
      return this.translate.instant('shared.rda.intercom.edit.error.update_software_adapter');
    }

    return '';
  }

  onOpenIpRdaGeneratior() {
    const data: DialogWrapperData<{ intercomType: IntercomType }, { response: RdaResponse }> = {
      title: this.translate.instant('shared.rda.intercom.edit.ip.title'),
      componentName: 'GenerateIpIntercom',
      body: { intercomType: this.intercomType },
      submit: (event: { response: RdaResponse }) => {
        this.rdaSelectSearch.initUsingIntercom(event.response);
        bottomSheetRef.dismiss();
      }
    };

    const bottomSheetRef: MatBottomSheetRef<IpIntercomGenerateBottomSheetComponent> = this.bottomSheet.open(
      IpIntercomGenerateBottomSheetComponent,
      { panelClass: Constants.CUSTOM_DIALOG_CLASS, data }
    );
  }

  onOpenLivenessUrlGenerator() {
    const data: DialogWrapperData<{ params: Array<keyof IntercomTypeGeneratorUrls>, intercom: IntercomType }, Partial<IntercomTypeGeneratorUrls>> = {
      title: this.translate.instant('shared.rda.intercom.edit.liveness_url.title'),
      componentName: 'GenerateIpIntercomLivenessUrl',
      body: {
        params: ['livenessUrl'],
        intercom: this.intercomType
      },
      submit: (event: Partial<IntercomTypeGeneratorUrls>) => {
        this.ipRdaForm.get('livenessUrl').setValue(event.livenessUrl);
        bottomSheetRef.dismiss();
      }
    };

    const bottomSheetRef: MatBottomSheetRef<IpIntercomUrlsBottomSheetComponent> = this.bottomSheet.open(
      IpIntercomUrlsBottomSheetComponent,
      {
        panelClass: Constants.CUSTOM_DIALOG_CLASS,
        data
      }
    );
  }

  onOpenHandsetLock() {
    const data: DialogWrapperData<IntercomTypeHandsetLockParams, IntercomTypeHandsetLockParams> = {
      title: this.translate.instant('shared.rda.intercom.edit.handset_lock.title'),
      componentName: 'HandsetLock',
      body: this.handsetLockParams,
      submit: (event: IntercomTypeHandsetLockParams) => {
        this.handsetLockParams = event;
        bottomSheetRef.dismiss();
      }
    };

    const bottomSheetRef = this.bottomSheet.open(
      IpIntercomHandsetLockBottomSheetComponent,
      {
        panelClass: Constants.CUSTOM_DIALOG_CLASS,
        data
      }
    );
  }

  private isIpAdapter(): boolean {
    return this.intercomType?.protocol?.number === ProtocolTypes.Hikvision
      || this.intercomType?.protocol?.number === ProtocolTypes.Rubitek
      || this.intercomType?.protocol?.number === ProtocolTypes.SipCda;
  }

  private openIncorrectTypeBottomSheet() {
    const data: DialogWrapperData<{ text: string }, { success: boolean }> = {
      title: this.translate.instant('shared.rda.intercom.edit.incorrect_type.title'),
      componentName: 'IncorrectIntercomTypeFormComponent',
      body: {
        text: this.translate.instant('shared.rda.intercom.edit.incorrect_type.message.you_changed_type_intercom') +
          ' ' + this.translate.instant('shared.rda.intercom.edit.incorrect_type.message.you_changed_type_intercom_with') + ' ' +
          (
            this.ipIntercom
              ? this.translate.instant('shared.rda.intercom.edit.incorrect_type.message.you_changed_type_intercom_ip_ordinary')
              : this.translate.instant('shared.rda.intercom.edit.incorrect_type.message.you_changed_type_intercom_ordinary_ip')
          ) + '! ' + this.translate.instant('shared.rda.intercom.edit.incorrect_type.message.if_this_parameter_is_incorrectly')
      },
      submit: (event: { success: boolean }) => {
        if (event.success !== null) {
          if (event.success === true) {
            this.incorrectIntercomType = true;
            this.intercomTypeReplaced = true;
          } else if (event.success === false) {
            this.intercomType = Object.assign({}, this.initialIntercomType);
          }

          this.afterIntercomTypeSelect();
        }
        bottomSheetRef.dismiss();
      }
    };

    const bottomSheetRef: MatBottomSheetRef<IncorrectIntercomTypeBottomSheetComponent> = this.bottomSheet.open(
      IncorrectIntercomTypeBottomSheetComponent,
      { panelClass: Constants.CUSTOM_DIALOG_CLASS, data }
    );
  }

  private openReplacedIntercomTypeBottomSheet() {
    const data: DialogWrapperData<{ text: string }, { success: boolean }> = {
      title: this.translate.instant('shared.rda.intercom.edit.incorrect_type.title'),
      componentName: 'IncorrectIntercomTypeFormComponent',
      body: {
        text: this.translate.instant('shared.rda.intercom.edit.incorrect_type.message.you_changed_type_intercom') +
          '. ' +
          this.translate.instant('shared.rda.intercom.edit.incorrect_type.message.if_this_parameter_is_incorrectly')
      },
      submit: (event: { success: boolean }) => {
        if (event.success !== null) {
          if (event.success === true) {
            this.intercomTypeReplaced = true;
          } else if (event.success === false) {
            this.intercomType = Object.assign({}, this.initialIntercomType);
          }

          this.afterIntercomTypeSelect();
        }

        bottomSheetRef.dismiss();
      }
    };

    const bottomSheetRef: MatBottomSheetRef<IncorrectIntercomTypeBottomSheetComponent> = this.bottomSheet.open(
      IncorrectIntercomTypeBottomSheetComponent,
      { panelClass: Constants.CUSTOM_DIALOG_CLASS, data }
    );
  }

  private initRdaForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      micSensitivity: new UntypedFormControl(null),
      speakerVolume: new UntypedFormControl(null)
    });
  }

  private initIpRdaForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      livenessUrl: new UntypedFormControl(null),
      bearerToken: new UntypedFormControl(null),
      login: new UntypedFormControl(null),
      password: new UntypedFormControl(null),
    });
  }

  private afterIntercomTypeSelect() {
    const prevIpIntercom: boolean = this.ipIntercom;
    this.ipIntercom = this.rdaUtilsService.ipType(this.intercomType);

    if (prevIpIntercom !== this.ipIntercom) {
      this.ipIntercom ? this.prepareIpRdaForm() : this.prepareRdaForm();
      this.rdaSelectSearch.onGetIntercoms({ params: { ipType: this.ipIntercom } });
    }

    this.changeIntercomType.emit({ intercomType: this.intercomType });
  }

  private prepareRdaForm() {
    this.rdaForm.setValue({
      speakerVolume: this.rda?.speakerVolume ?? 0,
      micSensitivity: this.rda?.micSensitivity ?? 0
    });
  }

  private prepareIpRdaForm() {
    const params = this.rdaUtilsService.convertConfigStrToParams(this.rda?.configStr);
    const livenessToParams = params.liveness?.match(/\/\/[\w\.\:@]+/);

    this.ipRdaForm.patchValue({
      login: params.login || '',
      password: params.password || '',
      livenessUrl: params.liveness || ''
    });
    if (this.isSputnikIntercomType) {
      this.ipRdaForm.patchValue({ bearerToken: params.bearerToken || '' });
    }

    if (this.intercomType.protocol.number === 200 && livenessToParams && params['control-mode'] !== 'lite') {
      const [login, password, host, port] = livenessToParams[0].match(/[\w\.]+/g);
      params.login = login;
      params.password = password;
      params.host = host;
      params.port = port;
    }

    this.handsetLockParams = {
      host: params.host,
      port: params.port,
      login: params.login,
      password: params.password
    };
  }

  private prepareInitialValues() {
    if (!this.rda) {
      return;
    }

    if (!this.isSputnikIntercomType && this.ipRdaForm.contains('bearerToken')) {
      this.ipRdaForm.removeControl('bearerToken');
    }

    if (this.isSputnikIntercomType) {
      this.ipRdaForm.addControl('bearerToken', new UntypedFormControl({
        value: null,
      }, [Validators.required]));
    }

    this.initialRda = Object.assign({}, this.rda);
    this.initialSelectedRda = {
      value: this.initialRda.uid,
      text: this.initialRda.uid,
      badge: this.initialRda.mode ? this.ipRdaConnectionTypePipe.transform(this.initialRda.mode) : null
    };

    if (!this.selectedIntercomTypeId && this.initialRda.intercomType) {
      this.intercomType = this.initialRda.intercomType;
      this.selectedIntercomTypeId = this.intercomType?.id;
    }

    if (this.intercomType) {
      this.initialIntercomType = Object.assign({}, this.intercomType);
    }

    this.ipIntercom = this.rda?.intercomType?.protocol?.ipType;
    this.incorrectIntercomType = false;
    this.intercomTypeReplaced = false;
  }

  private initLoginAndPassword(): void {
    this.ipRdaForm.patchValue({
      login: getSlicedString(this._rda.configStr, 'login:'),
      password: getSlicedString(this._rda.configStr, 'password:')
    });

    function getSlicedString(configStr: string, pattern: string): string {
      if (configStr.indexOf(pattern) === -1) { return; }
      const patter = configStr.slice(configStr.indexOf(pattern) + pattern.length, configStr.length);
      let result = '';
      for (let i = 0; i < patter.length; i++) {
        if (patter[i] === ';') {
          break;
        }
        result += patter[i];
      }
      return result;
    }
  }
}
