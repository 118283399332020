import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';

import { ServiceFlatsMenuComponent } from './service-flats-menu.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [CommonModule, MaterialModule, NgxTranslateModule],
  declarations: [ServiceFlatsMenuComponent],
  exports: [ServiceFlatsMenuComponent]
})
export class ServiceFlatsMenuModule {}
