export enum IntercomMetacomKeyRelayType {
    NIL,
    FIRST,
    SECOND,
    THIRD,
    FOURTH,
    FIFTH,
    SIXTH,
    SEVENTH,
    EIGHTH,
    NINTH,
    TENTH,
    ELEVENTH,
    TWELFTH,
    THIRTEENTH,
    FOURTEENTH,
    FIFTEENTH,
    SIXTEENTH,
    SEVENTEENTH,
    EIGHTEENTH,
    NINETEENTH,
    TWENTIETH,
    TWENTY_FIRST,
    TWENTY_SECOND,
    TWENTY_THIRD,
    TWENTY_FOURTH,
    TWENTY_FIFTH,
    TWENTY_SIXTH,
    TWENTY_SEVENTH,
    TWENTY_EIGHTH,
    TWENTY_NINTH,
    THIRTIETH,
    THIRTY_FIRST
}
