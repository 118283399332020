import { Injectable } from '@angular/core';
import { PbxOnRdaPageResponse } from '@app/views/pbx-on-rda/models';
import { PbxOnRdaQuery, PbxOnRdaRequest } from '@app/shared/entities/rd/pbx-on-rda/models';
import { State } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CreatePbxOnRdaPage, DeletePbxOnRdaPage, GetPbxOnRdaPage, UpdatePbxOnRdaPage } from './pbx-on-rda.actions';
import { pbxOnRdaPageSelector } from './pbx-on-rda.selectors';
import { PbxOnRdaPageState } from './pbx-on-rda.state';

@Injectable()
export class PbxOnRdaListPageFacade {
    pbxOnRdaListPageState$: Observable<PbxOnRdaPageState> = this.store.select(pbxOnRdaPageSelector.getState);
    pbxOnRdaListPage$: Observable<PbxOnRdaPageResponse> = this.store.select(pbxOnRdaPageSelector.getSuccess);
    loading$: Observable<boolean> = this.store.select(pbxOnRdaPageSelector.getLoading);

    constructor(private store: Store<State>) { }

    loadPage(page: number = 0, size: number = 10, query?: PbxOnRdaQuery) {
        this.store.dispatch(new GetPbxOnRdaPage(page, size, query));
    }

    create(request: PbxOnRdaRequest) {
        this.store.dispatch(new CreatePbxOnRdaPage(request));
    }

    update(pbxOnRdUid: string, request: PbxOnRdaRequest) {
        this.store.dispatch(new UpdatePbxOnRdaPage(pbxOnRdUid, request));
    }

    delete(pbxOnRdUid: string) {
        this.store.dispatch(new DeletePbxOnRdaPage(pbxOnRdUid));
    }
}
