import { Injectable } from '@angular/core';
import { BlockAccountServiceRequest, DisconnectAccountServiceRequest } from '@app/views/abonents/models';
import { AccountApiService } from '@app/shared/entities/rd/account/services/account-api.service';
import { forkJoin, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AccountUtilsService {
    constructor(
        private accountApiService: AccountApiService
    ) { }

    batchBlockAccountServices(
        accountId: number,
        servicesIdsBlocked: number[],
        servicesIdsUnblocked: number[],
        request: BlockAccountServiceRequest
    ): Observable<void[]> {
        const requestsBlocked: Observable<void>[] = servicesIdsBlocked.map(serviceId =>
            this.accountApiService.blockAccountService(accountId, serviceId, request)
        );

        const requestsUnblocked: Observable<void>[] = servicesIdsUnblocked.map(serviceId =>
            this.accountApiService.unblockAccountService(accountId, serviceId, request)
        );

        return forkJoin(Object.assign(requestsBlocked, requestsUnblocked));
    }

    batchUnblockAccountServices(accountId: number, servicesIds: number[], request: BlockAccountServiceRequest) {
        const requests: Observable<void>[] = servicesIds.map(serviceId =>
            this.accountApiService.unblockAccountService(accountId, serviceId, request)
        );

        return forkJoin(requests);
    }

    async batchDisconnectAccountServices(accountId: number, servicesIds: number[], request: DisconnectAccountServiceRequest) {
        for (const serviceId of servicesIds) {
            await this.accountApiService.disconnectAccountFromService(accountId, serviceId, request).toPromise();
        }
    }
}
