import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

const selectAllShieldsState = createSelector(
  intercomModuleSelector,
  (state: IntercomModuleState) => state.getAllShields
);

export const getAllShields = createSelector(
  selectAllShieldsState,
  state => state
);

export const getAllShieldsSuccess = createSelector(
  selectAllShieldsState,
  state => ({ dataSource: state.shields, totalCount: state.totalCount })
);

export const getAllShieldsFailure = createSelector(
  selectAllShieldsState,
  state => state.error
);

export const getAllShieldsLoading = createSelector(
  selectAllShieldsState,
  state => state.loading
);
