import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

export const selectGetAllBatchesState = createSelector(
  intercomModuleSelector,
  (state: IntercomModuleState) => state.getAllBatches
);

export const getAllBatchesStateSuccess = createSelector(
  selectGetAllBatchesState,
  state => state.batches
);

export const getAllBatchesStateLoading = createSelector(
  selectGetAllBatchesState,
  state => state.loading
);

export const getAllBatchesStateError = createSelector(
  selectGetAllBatchesState,
  state => state.error
);
