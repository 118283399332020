import { AddressInputMode } from '@app/shared/components';
import { Address, ServicesTypes } from '@app/shared/models';

export interface ServiceEntrancePopupData {
    label: string;
    showPrefix: boolean;
    initialMode: AddressInputMode;
    address?: Address;
    serviceId?: number;
    hardwareTariff?: number;
    serviceType?: ServicesTypes;
}
