import { Pipe, PipeTransform } from '@angular/core';
import { AddressFormatter } from '@app/shared/services';
import { SignUpResponse, SignUpStatus } from '@app/shared/models';
import { ReadableTimeFormat } from '@app/views/support-service/components/requests/requests.component';
import { ViewportRuler } from '@angular/cdk/overlay';
import { TranslateService } from '@ngx-translate/core';
import { SignUpType } from '@app/shared/models/sign-ups/sign-ups-types.enum';

@Pipe({
  name: 'singUps'
})
export class SingUpsPipe implements PipeTransform {
  constructor(
    public addressFormatter: AddressFormatter,
    private viewportRuler: ViewportRuler,
    private translate: TranslateService
  ) {}

  transform(value: SignUpResponse[]): unknown {
    return value.map((v) => {
      return {
        ...v,
        addressStr: this.addressFormatter.formatAddress(v.address),
        flat: v.address.flat,
        adaptiveStatus: this.adaptiveStatus(v)
      };
    });
  }

  private getAdaptiveDateConfig(): Partial<Intl.DateTimeFormatOptions> {
    return (
      this.viewportRuler.getViewportSize().width > 450
        ? ReadableTimeFormat.pcConfig
        : ReadableTimeFormat.mobileConfig
    ) as Partial<Intl.DateTimeFormatOptions>;
  }

  adaptiveStatus(signUp: SignUpResponse): string {
    switch (signUp.status.toLowerCase()) {
      case SignUpStatus.CONNECTED:
        if (signUp.signUpType === SignUpType.DISCONNECTION) {
          return this.translate.instant('enum.sign_up_status.disconnected');
        }
        
        return this.translate.instant('enum.sign_up_status.connected');
      case SignUpStatus.DELEGATED:
        return this.translate.instant('enum.sign_up_status.delegated');
      case SignUpStatus.PROCESSED:
        return this.translate.instant('enum.sign_up_status.processed');
      case SignUpStatus.REJECTED:
        return this.translate.instant('enum.sign_up_status.rejected');
      case SignUpStatus.UNPROCESSED:
        return this.translate.instant('enum.sign_up_status.unprocessed');
      case SignUpStatus.WAIT_ABONENT_CONFIRMATION:
        return this.translate.instant(
          'enum.sign_up_status.wait_abonent_information'
        );
      default:
        throw new Error('Status not implemented');
    }
  }
}
