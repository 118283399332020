<div class="popup component">
  <h3 class="component__title">
    {{ 'services.gates.page.popups.wizard.content.intercoms.reuse.title' | translate }}
  </h3>

  <div class="component-content">
    <app-info-block class="component-content-info">
      <img
        info-block-image
        class="component-content-info__image"
        [src]="resourcesHelper.getResource(resourcePath.INFO_LG) | safeUrl"
        [srcset]="resourcesHelper.getResource(resourcePath.INFO_LG, true) | safeUrl"
      >

      <ng-container info-block-text>
        {{ 'services.gates.page.popups.wizard.content.intercoms.reuse.message.gate_works_without_its_adapter' | translate }}
      </ng-container>

      <ng-container info-block-content>
        <p class="component-content-info-content">
          {{ 'services.gates.page.popups.wizard.content.intercoms.reuse.message.when_calling_the_mobile_phone_2_cameras_will_be_displayed' | translate }}:
        </p>
        <ul>
          <li>
            {{ 'services.gates.page.popups.wizard.content.intercoms.reuse.message.camera_from_gate' | translate }}
          </li>
          <li>
            {{ 'services.gates.page.popups.wizard.content.intercoms.reuse.message.camera_from_entrance_in_which_subscriber_lives' | translate }}
          </li>
        </ul>
      </ng-container>
    </app-info-block>

    <app-service-intercoms-list-content
      [intercoms]="intercoms"
      [controlsHidden]="true"
    ></app-service-intercoms-list-content>
  </div>
</div>
