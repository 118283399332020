import { HttpErrorResponse } from '@angular/common/http';
import { PbxOnRdaResponse } from '@app/shared/entities/rd';
import { SelectSearch } from '@app/shared/models';
import { Action } from '@ngrx/store';
import { PbxOnRdaSearchSelectResponse } from '../models';

export enum PbxOnRdaSearchSelectActionTypes {
    PbxOnRdaSearchSelectGetPbxOnRda = '[PbxOnRdaSearchSelect] PbxOnRdaSearchSelectGetPbxOnRda',
    PbxOnRdaSearchSelectGetPbxOnRdaSuccess = '[PbxOnRdaSearchSelect] PbxOnRdaSearchSelectGetPbxOnRdaSuccess',
    PbxOnRdaSearchSelectGetPbxOnRdaFailure = '[PbxOnRdaSearchSelect] PbxOnRdaSearchSelectGetPbxOnRdaFailure',

    PbxOnRdaSearchSelectInitUsingSelectedPbxOnRda = '[PbxOnRdaSearchSelect] PbxOnRdaSearchSelectInitUsingSelectedPbxOnRda',
    PbxOnRdaSearchSelectInitUsingPbxOnRdaList = '[PbxOnRdaSearchSelect] PbxOnRdaSearchSelectInitUsingPbxOnRdaList',
    SelectPbxOnRdaFromPbxOnRdaList = '[PbxOnRdaSearchSelect] SelectPbxOnRdaFromPbxOnRdaList',
}

export class PbxOnRdaSearchSelectGetPbxOnRda implements Action {
    readonly type = PbxOnRdaSearchSelectActionTypes.PbxOnRdaSearchSelectGetPbxOnRda;

    constructor(public value: string) { }
}

export class PbxOnRdaSearchSelectGetPbxOnRdaSuccess implements Action {
    readonly type = PbxOnRdaSearchSelectActionTypes.PbxOnRdaSearchSelectGetPbxOnRdaSuccess;

    constructor(public response: PbxOnRdaSearchSelectResponse[]) { }
}

export class PbxOnRdaSearchSelectGetPbxOnRdaFailure implements Action {
    readonly type = PbxOnRdaSearchSelectActionTypes.PbxOnRdaSearchSelectGetPbxOnRdaFailure;

    constructor(public error: HttpErrorResponse) { }
}

export class PbxOnRdaSearchSelectInitUsingSelectedPbxOnRda implements Action {
    readonly type = PbxOnRdaSearchSelectActionTypes.PbxOnRdaSearchSelectInitUsingSelectedPbxOnRda;

    constructor(public selectedPbxOnRda: SelectSearch) { }
}

export class PbxOnRdaSearchSelectInitUsingPbxOnRdaList implements Action {
    readonly type = PbxOnRdaSearchSelectActionTypes.PbxOnRdaSearchSelectInitUsingPbxOnRdaList;

    constructor(public pbxOnRdaList: PbxOnRdaResponse[]) { }
}

export class SelectPbxOnRdaFromPbxOnRdaList implements Action {
    readonly type = PbxOnRdaSearchSelectActionTypes.SelectPbxOnRdaFromPbxOnRdaList;

    constructor(public rdaIdx: number) { }
}

export type PbxOnRdaSearchSelectActions =
    | PbxOnRdaSearchSelectGetPbxOnRda
    | PbxOnRdaSearchSelectGetPbxOnRdaSuccess
    | PbxOnRdaSearchSelectGetPbxOnRdaFailure

    | PbxOnRdaSearchSelectInitUsingSelectedPbxOnRda
    | PbxOnRdaSearchSelectInitUsingPbxOnRdaList
    | SelectPbxOnRdaFromPbxOnRdaList;
