import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';
import { DadataAddressSuggestionData, DadataSuggestionResponse } from '@app/shared/entities/integrations/dadata';
import { AddressInfo, Country } from '@app/shared/models';
import { AddressFormatter } from '@app/shared/services';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-dadata-address',
  templateUrl: './dadata-address.component.html',
  styleUrls: ['./dadata-address.component.scss']
})
export class DadataAddressComponent implements OnInit {
  @Input() label: string;

  @Input() private initialAddress: AddressInfo;
  @Input() private country;
  @Output() private submittedAddress: EventEmitter<{ address: string }> = new EventEmitter();

  constructor(
    public controlContainer: ControlContainer,
    private addressFormatter: AddressFormatter,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (!this.initialAddress) {
      return;
    }

    this.setValue('country', this.initialAddress.country);
    this.setValue('city', this.initialAddress.city);
    this.setValue('street', this.initialAddress.street);
    this.setValue('house', this.initialAddress.house);
    this.setValue('building', this.initialAddress.building);
    this.setValue('housing', this.initialAddress.housing);
    this.setValue('block', this.initialAddress.block);
    this.setValue('fiasCode', this.initialAddress.fiasCode);
    this.setValue('kladrCode', this.initialAddress.kladrCode);
    this.setValue('rawAddress', this.addressFormatter.formatAddressInfo(this.initialAddress));
    this.getControl('rawAddress').disable();
  }

  onValueSelected(suggestion: DadataSuggestionResponse) {
    const address = suggestion.data as DadataAddressSuggestionData;

    let city: string = address.city || address.settlement || null;
    let street: string = address.street || (city !== address.settlement ? address.settlement : null) || null;

    if (street && !city) {
      city = this.translate.instant('shared.address.input.message.no_city');
    }

    const fiasCode: string = (street === address.street ? address.street_fias_id : null) || (street === address.settlement ? address.settlement_fias_id : null) || null;
    const kladrCode: string = (street === address.street ? address.street_kladr_id : null) || (street === address.settlement ? address.settlement_kladr_id : null) || null;

    this.setValue('country', this.country, true);
    this.setValue('city', city, true);
    this.setValue('street', street, true);
    this.setValue('fiasCode', fiasCode, true);
    this.setValue('kladrCode', kladrCode, true);

    if (address.block_type) {
      switch (address.block_type) {
        case this.translate.instant('shared.address.input.block_type.housing'):
          this.setValue('housing', address.block, true);
          break;
        case this.translate.instant('shared.address.input.block_type.building'):
          this.setValue('building', address.block, true);
          break;
        default: break;
      }
    }

    this.setValue('house', address.house, true);

    if (address.house && !street) {
      street = this.translate.instant('shared.address.input.message.no_street');
      this.setValue('street', street);
    }

    if (address.house && !city) {
      city = this.translate.instant('shared.address.input.message.no_city');
      this.setValue('city', city);
    }

    this.setValue('rawAddress', suggestion.value, true);
    this.submittedAddress.emit({ address: suggestion.value });
  }

  getError(): string {
    const control = this.getControl('rawAddress');

    if (control.valid) {
      return null;
    }

    if (control.hasError('city')) {
      return this.translate.instant('shared.address.input.message.specify_city');
    }

    if (control.hasError('street')) {
      return this.translate.instant('shared.address.input.message.specify_street');
    }

    if (control.hasError('house')) {
      return this.translate.instant('shared.address.input.message.specify_house');
    }

    return this.translate.instant('shared.address.input.message.specify_address');
  }

  private setValue(name: keyof AddressInfo | 'rawAddress', value: string | Country, touched?: boolean) {
    const control = this.getControl(name);

    if (touched) {
      control.markAsTouched();
    }

    control.setValue(value);
  }

  private getControl(name: keyof AddressInfo | 'rawAddress'): AbstractControl {
    return this.controlContainer.control.get(name as string);
  }
}
