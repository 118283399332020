import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import { IntercomEditFilter } from '@app/shared/components';
import { IntercomPanelResponse } from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';
import { LocationResponse, TranslationTuningResponse } from '@app/shared/models';
import { ServiceWizardPopupContentComponent } from '@app/views/services/components';
import { SoftwareIntercomWizardPopupStep, SoftwareIntercomWizardUpdateStep } from '../models';
import { SoftwareIntercomWizardPopupStore } from '../store';
import {ServiceFacade} from '@app/views/services';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-software-intercom-wizard-content',
  templateUrl: './software-intercom-wizard-content.component.html',
  styleUrls: ['./software-intercom-wizard-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomWizardContentComponent extends ServiceWizardPopupContentComponent implements OnDestroy{
  readonly intercomEditFilter: typeof IntercomEditFilter = IntercomEditFilter;
  readonly step: typeof SoftwareIntercomWizardPopupStep = SoftwareIntercomWizardPopupStep;
  public abonentsBillingEnabled$: Observable<boolean> = this.serviceFacade.abonentsBillingEnabled$;
  private abonentsBillingEnabledSubscription: Subscription;

  @Input() set addressStepState(stepState: SoftwareIntercomWizardUpdateStep) {
    this.popupStore.updateStepState({ step: SoftwareIntercomWizardPopupStep.ADDRESSES, stepState });
  }
  @Input() set intercomsStepState(stepState: SoftwareIntercomWizardUpdateStep) {
    this.popupStore.updateStepState({ step: SoftwareIntercomWizardPopupStep.INTERCOMS, stepState });
  }
  @Input() set intercomPanelStepState(stepState: SoftwareIntercomWizardUpdateStep) {
    this.popupStore.updateStepState({ step: SoftwareIntercomWizardPopupStep.INTERCOM_PANEL, stepState });
  }
  @Input() set translationStepState(stepState: SoftwareIntercomWizardUpdateStep) {
    this.popupStore.updateStepState({ step: SoftwareIntercomWizardPopupStep.TRANSLATIONS, stepState });
  }
  @Input() set camerasStepState(stepState: SoftwareIntercomWizardUpdateStep) {
    this.popupStore.updateStepState({ step: SoftwareIntercomWizardPopupStep.CAMERA, stepState });
  }
  @Input() set tariffStepState(stepState: SoftwareIntercomWizardUpdateStep) {
    this.abonentsBillingEnabledSubscription = this.abonentsBillingEnabled$.subscribe(abonentsBillingEnabled => {
      this.popupStore.updateStepState({ step: SoftwareIntercomWizardPopupStep.TARIFF, stepState: {...stepState, hidden: !abonentsBillingEnabled} });
    });
  }
  @Input() set checklistStepState(stepState: SoftwareIntercomWizardUpdateStep) {
    this.popupStore.updateStepState({ step: SoftwareIntercomWizardPopupStep.CHECKLIST, stepState });
  }
  @Input() set initialWizardStep(initialWizardStep: SoftwareIntercomWizardPopupStep) {
    if (this.wizardStep === undefined || this.wizardStep === null) {
      // WARN: change detector don't detect changes after updateStepState running
      setTimeout(() => this.popupStore.selectStep(initialWizardStep), 0);
    }
  }

  @Input() translationTunings: Dictionary<TranslationTuningResponse[]>;
  @Input() camerasLocations: LocationResponse[];
  @Input() avaliableIntercomPanelsLocations: LocationResponse[];
  @Input() intercomPanels: IntercomPanelResponse[];

  constructor(
    private popupStore: SoftwareIntercomWizardPopupStore,
    private serviceFacade: ServiceFacade
  ) {
    super();
  }

  allIntercomsIsIp(): boolean {
    return this.intercoms?.reduce((prev, cur) => prev && cur?.intercomType?.protocol?.ipType, true);
  }

  onNextStep() {
    const wizardStep: SoftwareIntercomWizardPopupStep =
      this.getNextStep(SoftwareIntercomWizardPopupStep.CHECKLIST);

    if (wizardStep === null) {
      return;
    }

    setTimeout(() => this.popupStore.selectStep(wizardStep), 0);
  }

  isNextStepEnabled(): boolean {
    return this.getNextStep(SoftwareIntercomWizardPopupStep.CHECKLIST) !== null;
  }

  onPreviousStep() {
    const wizardStep: SoftwareIntercomWizardPopupStep = this.getPreviousStep();

    if (wizardStep === null) {
      return;
    }

    setTimeout(() => this.popupStore.selectStep(wizardStep), 0);
  }

  isPreviousStepEnabled(): boolean {
    return this.getPreviousStep() !== null;
  }

  onChangeStep(wizardStep: SoftwareIntercomWizardPopupStep) {
    setTimeout(() => this.popupStore.selectStep(wizardStep), 0);
  }

  ngOnDestroy(): void {
    this.abonentsBillingEnabledSubscription.unsubscribe();
  }
}
