import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ActionTypes } from '../../actions/cameras.actions';

export interface State {
  success: any;
  error: string | HttpErrorResponse;
  loading: boolean;
}

export const initialState: State = {
  success: null,
  error: null,
  loading: false
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.DeleteCamera: {
      return {
        ...state,
        success: null,
        error: null,
        loading: true
      };
    }

    case ActionTypes.DeleteCameraSuccess: {
      return {
        ...state,
        success: true,
        loading: false
      };
    }

    case ActionTypes.DeleteCameraFailure: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }

    case ActionTypes.DeleteCameraClear: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
