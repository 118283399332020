import { createFeatureSelector, createSelector } from '@ngrx/store';
import { GatesModuleState, GatesRootState, GatesState } from './gates-page.state';

const gatesFeatureSelector = createFeatureSelector<GatesRootState, GatesModuleState>('gates');

const selectState = createSelector(
    gatesFeatureSelector,
    (state: GatesModuleState) => state.gates
);

const selectGates = createSelector(
    selectState,
    (state: GatesState) => state.gates
);

const selectLoading = createSelector(
    selectState,
    state => state.loading
);

const selectIntercomType = createSelector(
    selectState,
    state => state.intercomType
);

const selectEntrancesIntercoms = createSelector(
    selectState,
    state => state.entrancesIntercoms
);

const selectIntercom = createSelector(
    selectState,
    state => state.intercom
);

const selectIndex = createSelector(
    selectState,
    state => state.index
);

const selectMode = createSelector(
    selectState,
    state => state.mode
);

const selectPageData = createSelector(
    selectState,
    state => state.pageData
)

export const gatesPageSelectors = {
    selectGates,
    selectLoading,
    selectIntercomType,
    selectEntrancesIntercoms,
    selectIntercom,
    selectIndex,
    selectMode, 
    selectPageData
};
