import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/shared/components';
import {PbxOnRdaApiService, PbxOnRdaResponse, PbxOnRdaUtilsService} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {PagedResponse} from '@app/shared/models';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, concatMap, map, switchMap} from 'rxjs/operators';
import {
  ChangeServicePbxOnRda,
  ChangeServicePbxOnRdaFailure,
  ChangeServicePbxOnRdaSuccess,
  ConnectServicePbxOnRda,
  ConnectServicePbxOnRdaFailure,
  ConnectServicePbxOnRdaSuccess,
  DisconnectServicePbxOnRda,
  DisconnectServicePbxOnRdaFailure,
  DisconnectServicePbxOnRdaSuccess,
  GetServicePbxOnRda,
  GetServicePbxOnRdaFailure,
  GetServicePbxOnRdaSuccess,
  ServicesActionTypes
} from '../actions';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServicesPbxOnRdaEffects {
  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private pbxOnRdaApiService: PbxOnRdaApiService,
    private pbxOnRdaUtilsService: PbxOnRdaUtilsService,
    private translate: TranslateService
  ) {
  }

  GetServicePbxOnRda$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServicePbxOnRda>(ServicesActionTypes.GetServicePbxOnRda),
      concatMap(action =>
        this.pbxOnRdaApiService.getPage(action.page, action.size, {connectedRdaUid: action.rdaUid})
          .pipe(
            map((response: PagedResponse<PbxOnRdaResponse>) =>
              new GetServicePbxOnRdaSuccess(response.content?.[0], action.rdaUid)
            ),
            catchError(error => of(new GetServicePbxOnRdaFailure(error)))
          )
      )
    )
  );

  ConnectServicePbxOnRda$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ConnectServicePbxOnRda>(ServicesActionTypes.ConnectServicePbxOnRda),
      switchMap(action =>
        this.pbxOnRdaApiService.connectToRda(action.pbxOnRda.pbxOnRdaUid, action.rdaUidForConnect)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.pbx_on_rda.connect_service_pbx_on_rda.success'),
                'success'
              );
              return new ConnectServicePbxOnRdaSuccess(action.pbxOnRda, action.rdaUidForConnect);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.pbx_on_rda.connect_service_pbx_on_rda.failed', {
                  text: parseError(error)
                })
              );
              return of(new ConnectServicePbxOnRdaFailure(error));
            })
          )
      )
    )
  );

  DisconnectServicePbxOnRda$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DisconnectServicePbxOnRda>(ServicesActionTypes.DisconnectServicePbxOnRda),
      switchMap(action =>
        this.pbxOnRdaApiService.disconnectFromRda(action.pbxOnRda.pbxOnRdaUid, action.rdaUidForConnect)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.pbx_on_rda.disconnect_service_pbx_on_rda.success'),
                'success'
              );
              return new DisconnectServicePbxOnRdaSuccess(action.pbxOnRda, action.rdaUidForConnect);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.pbx_on_rda.disconnect_service_pbx_on_rda.failed', {
                  text: parseError(error)
                })
              );
              return of(new DisconnectServicePbxOnRdaFailure(error));
            })
          )
      )
    )
  );

  ChangeServicePbxOnRda$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ChangeServicePbxOnRda>(ServicesActionTypes.ChangeServicePbxOnRda),
      switchMap(action =>
        from(this.pbxOnRdaUtilsService.changeRda(
          action.oldPbxOnRda.pbxOnRdaUid,
          action.newPbxOnRda.pbxOnRdaUid,
          action.rdaUidForConnect
        ))
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.pbx_on_rda.change_service_pbx_on_rda.success'),
                'success'
              );
              return new ChangeServicePbxOnRdaSuccess(action.newPbxOnRda, action.rdaUidForConnect);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.pbx_on_rda.change_service_pbx_on_rda.failed', {
                  text: parseError(error)
                })
              );
              return of(new ChangeServicePbxOnRdaFailure(error));
            })
          )
      )
    )
  );
}
