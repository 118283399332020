<app-dialog-wrapper
  [componentName]="data.componentName"
  [title]="data.title"
  [progress]="(popupState$ | async) === 'loading'"
>
  <div class="popup service-connection-delete-popup">
    <app-info-block>
      <img
        class="service-connection-delete-popup-info__image"
        info-block-image
        [src]="resourcesHelper.getResource(resourcePath.INFO_LG) | safeUrl"
        [srcset]="resourcesHelper.getResource(resourcePath.INFO_LG, true) | safeUrl"
      >

      <ng-container info-block-text>
        <ng-container *ngIf="data.body.text; else defaultText">
          {{ data.body.text }}
        </ng-container>
        <ng-template #defaultText>
          {{ 'services.popups.connections.block.message.confirm' | translate:{
            text: data.body.blocked
              ? ('services.popups.connections.block.message.confirm_unblock' | translate)
              : ('services.popups.connections.block.message.confirm_block' | translate)
          } }}
        </ng-template>
      </ng-container>
    </app-info-block>

    <div class="popup-actions">
      <button
        mat-raised-button
        [color]="data.body.blocked ? 'primary' : 'error'"
        [attr.ng-reflect-color]="data.body.blocked ? 'primary' : 'error'"
        class="popup-actions__button"
        (click)="onSubmit()"
        [disabled]="(popupState$ | async) === 'loading'"
      >
      {{
        data.body.blocked
          ? ('services.popups.connections.block.button.unblock' | translate)
          : ('services.popups.connections.block.button.block' | translate)
        }}
      </button>
    </div>
  </div>
</app-dialog-wrapper>
