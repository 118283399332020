import { Pipe, PipeTransform } from '@angular/core';
import { CurrencySymbols } from '../models/currency-symbol.enum';

@Pipe({
  name: 'currencyType'
})
export class CurrencyTypePipe implements PipeTransform {
  transform(value: number | null, type: string): string | number {
    if (!value) {
      return '-';
    }
    return `${value} ${CurrencySymbols.RUB}`;

    // switch (type) {
    //   case "RUB":
    //     return `${value} ${CurrencySymbols.RUB}`
    //   case "KZT":
    //     return `${value} ${CurrencySymbols.KZT}`
    //   case "UAH":
    //     return `${value} ${CurrencySymbols.UAH}`
    //   case "BYN":
    //     return `${value} ${CurrencySymbols.BYN}`
    //   case "NOK":
    //     return `${value} ${CurrencySymbols.NOK}`
    //   case "US":
    //     return `${value} ${CurrencySymbols.US}`
    //   default:
    //     return value

    // }
  }
}
