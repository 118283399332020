import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared';
import { ConfirmComponentConnectionModule, CreateServicePopupModule, RdaTranslationsEditorModule } from '@app/shared/components';
import { IntercomEditContentModule } from '@app/shared/components/rda/intercom-edit-content';
import { RdeaStepperModule } from '@app/shared/templates';
import { InfoBlockModule, PaginatedPageWrapperModule } from '@app/shared/ui';
import { ServiceActivityModule, ServiceCamerasModule, ServiceCamerasWarningModule, ServiceConfigModule, ServiceEntrancePopupModule, ServiceEntrancesModule, ServiceIntercomKeysModule, ServiceIntercomsModule, ServiceNavbarModule, ServiceRdaWarningModule } from '@app/views/services/components';
import { ServiceFacade } from '@app/views/services/store/services.facade';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SoftwareIntercomPageComponent, SoftwareIntercomsPageComponent, SoftwareIntercomWizardPopupModule } from './components';
import { SoftwareIntercomCamerasModule, SoftwareIntercomFlatsModule } from './components/software-intercom-page/containers';
import { SoftwareIntercomsPageService } from './services';
import { SoftwareIntercomsPageEffects, SoftwareIntercomsPageFacade, softwareIntercomsReducers } from './store';
import {NgxTranslateModule} from '@app/translate/translate.module';
import {DelegationComponent} from '@app/views/services/submodules/shared/delegation/delegation.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    RouterModule,
    RdeaStepperModule,
    ReactiveFormsModule,
    SharedPipesModule,
    ServiceIntercomKeysModule,
    IntercomEditContentModule,
    ConfirmComponentConnectionModule,
    ServiceCamerasModule,
    ServiceConfigModule,
    ServiceEntrancesModule,
    ServiceActivityModule,
    SoftwareIntercomFlatsModule,
    ServiceIntercomsModule,
    ServiceNavbarModule,
    PaginatedPageWrapperModule,
    ServiceCamerasWarningModule,
    ServiceRdaWarningModule,
    CreateServicePopupModule,
    RdaTranslationsEditorModule,
    ServiceEntrancePopupModule,
    NgxSkeletonLoaderModule,
    InfoBlockModule,
    SoftwareIntercomWizardPopupModule,
    SoftwareIntercomCamerasModule,
    StoreModule.forFeature('software-intercoms', softwareIntercomsReducers),
    EffectsModule.forFeature([
      SoftwareIntercomsPageEffects
    ]),
    NgxTranslateModule,
    DelegationComponent,
  ],
    declarations: [
        SoftwareIntercomPageComponent,
        SoftwareIntercomsPageComponent,
    ],
    providers: [
        ServiceFacade,
        SoftwareIntercomsPageService,
        SoftwareIntercomsPageFacade
    ]
})
export class SoftwareIntercomsModule { }
