import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { IntercomKeysEditContainerModule } from '@app/shared/components';
import { DialogWrapperModule } from '@app/shared/ui';
import { ServiceIntercomKeyEditPopupComponent } from './service-intercom-key-edit-popup.component';

@NgModule({
    imports: [
        CommonModule,
        IntercomKeysEditContainerModule,
        DialogWrapperModule,
        MaterialModule
    ],
    declarations: [
        ServiceIntercomKeyEditPopupComponent
    ],
    exports: [
        ServiceIntercomKeyEditPopupComponent
    ]
})
export class ServiceIntercomKeyPopupEditModule { }
