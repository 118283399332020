<div class="popup service-delegation-popup-content">
  <h3 class="service-delegation-popup-content__title">
    {{ 'services.popups.connections.delegation.content.title' | translate }}
    {{ owner.phone.toString() | phone }}
  </h3>

  <form [formGroup]="form" autocomplete="off">
    <app-phone-input
      formGroupName="phone"
    ></app-phone-input>
  </form>

  <div class="popup-actions">
    <button
      mat-raised-button
      color="primary"
      class="popup-actions__button"
      (click)="onDelegate()"
      [disabled]="loading"
    >
      {{ 'services.popups.connections.delegation.content.button.delegate' | translate }}
    </button>
  </div>
</div>
