<div class="block-user">
  <div>
    <img
      ngSrc="assets/icons/ic-alert.svg"
      alt="error"
      height="48"
      width="48" />
  </div>
  <div class="block-user-question">
    <span>
      {{ 'company.roles.block.user.confirm.user' | translate }}&nbsp;
      <span class="block-user-question__email">
        {{ email }}
      </span>&nbsp;
      {{
      (
        type === ActionType.block ?
        'company.roles.block.user.confirm.will_be_blocked_are_you_sure' :
        'company.roles.block.user.confirm.will_be_unlocked_are_you_sure'
      ) | translate }}
    </span>
  </div>
  <div class="block-user-actions">
    <p-button
      label="{{ 'company.roles.block.user.confirm.label.cancel' | translate }}"
      styleClass="p-button-info p-button-text"
      (click)="submit.emit(false)"
    ></p-button>
    <p-button
      label="{{ (type === ActionType.block ? 'company.roles.block.user.confirm.label.block' : 'company.roles.block.user.confirm.label.unlock') | translate }}"
      styleClass="p-button-danger p-button-text"
      (click)="submit.emit(true)"
    ></p-button>
  </div>
</div>
