import { SnackbarService } from '@app/shared/components/snackbar';
import { PhonesUtils } from '@app/shared/entities/rd';
import { Component, Input, OnInit, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Abonent } from '@app/shared/models';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-service-delegation-popup-content',
  templateUrl: './service-delegation-popup-content.component.html',
  styleUrls: ['./service-delegation-popup-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceDelegationPopupContentComponent implements OnInit {
  @Input() owner: Abonent;
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  form: UntypedFormGroup = new UntypedFormGroup({
    phone: new UntypedFormGroup({
      number: new UntypedFormControl(null, Validators.required),
      prefix: new UntypedFormControl(null, Validators.required)
    })
  });

  @Output() private delegateAccess: EventEmitter<{ phone: number }> = new EventEmitter();
  private _loading: boolean;

  constructor(
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
  }

  get loading(): boolean {
    return this._loading;
  }

  onDelegate() {
    if (!this.form.valid) {
      this.snackbar.showMessage(
        this.translate.instant('services.popups.connections.delegation.content.message.delegate.failed'),
        'info'
      );
      return;
    }

    const phone: number = PhonesUtils.toNumber(this.form.get('phone').value);
    this.delegateAccess.emit({ phone });
  }
}
