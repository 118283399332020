import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogWrapperData, DialogWrapperService } from '@app/shared/ui';

@Component({
  selector: 'app-change-virtual-state-popup',
  templateUrl: './change-virtual-state-popup.component.html',
  styleUrls: ['./change-virtual-state-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeVirtualStatePopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<null, null>,
    public dialogWrapperService: DialogWrapperService
  ) { }

  onSubmit() {
    this.data.submit(null);
  }
}
