import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { ResourcePath } from './resource-path.enum';
import { ResourceType } from './resource.type';

@Injectable({
  providedIn: 'root'
})
export class ResourcesHelper {
  private trustedResources: readonly { name: string; path: string }[] = [
    { name: 'home', path: '/assets/icons/ic-home.svg' },
    { name: 'adapter', path: '/assets/icons/ic-adapter.svg' },
    { name: 'camera', path: '/assets/icons/ic-camera.svg' },
    { name: 'domofon', path: '/assets/icons/ic-domofon.svg' },
    { name: 'entrance', path: '/assets/icons/ic-door.svg' },
    { name: 'invite', path: '/assets/icons/ic-invite.svg' },
    { name: 'notif', path: '/assets/icons/ic-notif.svg' },
    { name: 'ticket', path: '/assets/icons/ic-ticket.svg' },
    { name: 'user', path: '/assets/icons/ic-user.svg' },
    { name: 'video', path: '/assets/icons/ic-video.svg' },
    { name: 'shield', path: '/assets/icons/ic-shield.svg' },
    { name: 'batch', path: '/assets/icons/ic-consignment.svg' },
    { name: 'plus', path: '/assets/icons/ic-plus.svg' },
    { name: 'close', path: '/assets/icons/ic-close.svg' },
    { name: 'close-circle', path: '/assets/icons/ic-close-circle.svg' },
    { name: 'edit', path: '/assets/icons/ic-edit.svg' },
    { name: 'model', path: '/assets/icons/ic-models.svg' },
    { name: 'order', path: '/assets/icons/ic-order.svg' },
    { name: 'wallet', path: '/assets/icons/ic-wallet.svg' },
    { name: 'chevron-down', path: '/assets/icons/ic-chevron-down.svg' },
    { name: 'checked', path: '/assets/icons/ic-checked.svg' },
    { name: 'checked-circle', path: '/assets/icons/ic-checked-circle.svg' },
    { name: 'back', path: '/assets/icons/ic-back.svg' },
    { name: 'ok', path: '/assets/icons/ic-ok.svg' },
    { name: 'gates', path: '/assets/icons/ic-gate.svg' },
    { name: 'visible', path: '/assets/icons/ic-visible.svg' },
    { name: 'invisible', path: '/assets/icons/ic-invisible.svg' },
    { name: 'services', path: '/assets/icons/ic-service.svg' },
    { name: 'pbx', path: '/assets/icons/ic-ate.svg' },
    { name: 'chat', path: '/assets/icons/ic-chat.svg' },
    { name: 'domofon-old', path: '/assets/icons/ic-domofon-old.svg' },
    { name: 'submenu', path: '/assets/icons/ic-submenu.svg' },
    { name: 'info', path: '/assets/icons/ic-info.svg' },
    { name: 'info-white', path: '/assets/icons/ic-info-white.svg' },
    { name: 'refresh', path: '/assets/icons/ic-refresh.svg' },
    { name: 'settings', path: '/assets/icons/ic-settings.svg' },
    { name: 'mobile', path: '/assets/icons/ic-mobile.svg' },
    { name: 'tube', path: '/assets/icons/ic-tube.svg' },
    { name: 'tube-dark', path: '/assets/icons/ic-tube-dark.svg' },
    { name: 'add', path: '/assets/icons/ic-add.svg' },
    { name: 'right-arrow', path: '/assets/icons/ic-right-arrow.svg' },
    { name: 'sign-ups', path: '/assets/icons/ic-sign-ups.svg' },
    { name: 'add-sm', path: '/assets/icons/ic-add-sm.svg' },
    { name: 'triangle-down', path: '/assets/icons/ic-triangle-down.svg' },
    {
      name: 'triangle-down-warn',
      path: '/assets/icons/ic-triangle-down-warn.svg'
    },
    { name: 'star', path: '/assets/icons/ic-star.svg' },
    { name: 'question', path: '/assets/icons/ic-question.svg' },
    { name: 'active', path: '/assets/icons/ic-active.svg' },
    { name: 'offline', path: '/assets/icons/ic-offline.svg' },
    { name: 'alert', path: '/assets/icons/ic-alert.svg' },
    { name: 'block', path: '/assets/icons/ic-block.svg' },
    { name: 'filter', path: '/assets/icons/ic-filter.svg' },
    { name: 'filter-active', path: '/assets/icons/ic-filter-active.svg' },
    { name: 'coins', path: '/assets/icons/ic-coins.svg' },
    { name: 'telegram', path: '/assets/icons/ic-telegram.svg' },
    { name: 'download', path: '/assets/icons/ic-download.svg' },
    { name: 'upload', path: '/assets/icons/ic-upload.svg' },
    { name: 'export', path: '/assets/icons/ic-export.svg' },
    { name: 'warning-round-white', path: '/assets/icons/ic-warning-round-white.svg' },
    { name: 'video2', path: '/assets/icons/ic-video-2.svg' },
  ];

  constructor(
    private sanitizer: DomSanitizer,
    private iconRegistry: MatIconRegistry
  ) {
    this.initTrustedResources();
  }

  getResource(
    path: ResourcePath,
    srcset?: boolean,
    type: ResourceType = ResourceType.PNG
  ) {
    return `${path}${srcset ? '@2x' : ''}.${type}`;
  }

  private initTrustedResources() {
    for (const trustedResource of this.trustedResources) {
      this.iconRegistry.addSvgIcon(
        trustedResource.name,
        this.sanitizer.bypassSecurityTrustResourceUrl(trustedResource.path)
      );
    }
  }
}
