export const compareVersions = (v1: string, v2: string): number => {
    if (!v1 || !v2) {
        return null;
    }

    const v1parts: string[] = v1.split('.');
    const v2parts: string[] = v2.split('.');

    const maxLength: number = v1parts.length > v2parts.length ? v1parts.length : v2parts.length;

    if (maxLength === 1) {
        const num1 = Number.parseInt(v1, 10);
        const num2 = Number.parseInt(v2, 10);

        if (num1 > num2) {
            return 1;
        } else if (num1 === num2) {
            return 0;
        }
        return -1;
    }

    for (let i = 0; i < maxLength; ++i) {
        const num1 = Number.parseInt(v1parts[i], 10);
        const num2 = Number.parseInt(v2parts[i], 10);

        if (isNaN(num1)) {
            if (num2 !== 0) {
                return -1;
            }
            continue;
        }
        if (isNaN(num2)) {
            if (num1 !== 0) {
                return 1;
            }
            continue;
        }

        if (num1 === num2) {
            continue;
        } else if (num1 > num2) {
            return 1;
        } else {
            return -1;
        }
    }

    return 0;
};
