import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { IntercomTypeHandsetLockParams } from '@app/shared/entities/rd';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper';

@Component({
  selector: 'app-ip-intercom-handset-lock-bottom-sheet',
  templateUrl: './ip-intercom-handset-lock-bottom-sheet.component.html',
  styleUrls: ['./ip-intercom-handset-lock-bottom-sheet.component.scss'],
})
export class IpIntercomHandsetLockBottomSheetComponent {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public data: DialogWrapperData<IntercomTypeHandsetLockParams, IntercomTypeHandsetLockParams>,
  ) {}
}
