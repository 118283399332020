import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { PopupBasicComponent } from '@app/shared/templates';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-reject-request-popup',
  templateUrl: './reject-request-popup.component.html',
  styleUrls: ['./reject-request-popup.component.scss']
})
export class RejectRequestPopupComponent extends PopupBasicComponent implements OnInit {
  reasonControl = new UntypedFormControl();
  filteredReasons: Observable<string[]>;
  reasons: string[] = [
    this.translate.instant('reject.request.popup.reason.1'),
    this.translate.instant('reject.request.popup.reason.2')
  ];

  constructor(
    @Inject('data') public data: { number: number },
    @Inject('subject') private subject: Subject<any>,
    private translate: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this.filteredReasons = this.reasonControl.valueChanges.pipe(startWith(''), map(value => this._filter(value)));
  }

  onReject() {
    this.subject.next(this.reasonControl.value);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.reasons.filter(option => option.toLowerCase().includes(filterValue.toLowerCase().replace(/ /g, '')));
  }
}
