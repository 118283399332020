import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { NavbarComponent } from './navbar.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        NgxTranslateModule,
    ],
    declarations: [NavbarComponent],
    exports: [NavbarComponent]
})
export class NavbarModule { }
