import { AddressInputMode } from '@app/shared/components/address/address-input';
import { Country } from '@app/shared/models';

export class EntranceEditContainerHelper {
    static RANGE_LIMIT = 3000;

    static isRangeBoundsValid(range: [number, number]): boolean {
        if (range[0] !== null && range[1] != null) {
            if (range[0] > range[1]) {
                return false;
            }
        }

        return true;
    }

    static setModeByCountry(country: Country): AddressInputMode {
        if (country?.shortName === null || country?.shortName === 'RU') {
            return AddressInputMode.DADATA;
        } else if (country?.shortName === 'KZ') {
            return AddressInputMode.KZ_POST;
        }

        return AddressInputMode.YANDEX;
    }

    static isRangeLimitValid(range: [number, number]): boolean {
      if (range[0] !== null && range[1] != null) {
          if (range[1] - range[0] > this.RANGE_LIMIT) {
            return false;
          }
      }
      return true;
  }
}
