import { Component, EventEmitter, Input, Output } from '@angular/core';

export interface IFileActions {
  delete: boolean;
  download: boolean;
}

@Component({
  selector: 'app-file-card',
  templateUrl: './file-card.component.html',
  styleUrls: ['./file-card.component.scss']
})
export class FileCardComponent {
  @Input() classToOverride: string = null;
  @Input() fileConfig: File = null;
  @Input() fileName: string = null;
  @Input() actions: IFileActions = {
    delete: false,
    download: false,
  };
  @Output() delete: EventEmitter<File | string> = new EventEmitter<File | string>();
  @Output() download: EventEmitter<File | string> = new EventEmitter<File | string>();
}
