import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServicesIcons {
  constructor(
    private translate: TranslateService
  ) {}

  get icons() {
    return {
      [this.translate.instant('shared.camera.already.exists.icons.video_surveillance.title')]: {
        serviceSvgIcon: 'video',
        serviceName: this.translate.instant('shared.camera.already.exists.icons.video_surveillance.name')
      },
      [this.translate.instant('shared.camera.already.exists.icons.gate.title')]: {
        serviceSvgIcon: 'gates',
        serviceName: this.translate.instant('shared.camera.already.exists.icons.gate.name')
      },
      [this.translate.instant('shared.camera.already.exists.icons.intercom.name')]: {
        serviceSvgIcon: 'domofon',
        serviceName: this.translate.instant('shared.camera.already.exists.icons.intercom.title')
      }
    };
  }
}
