import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {NgModule} from '@angular/core';
import {SharedPipesModule} from '@app/shared';
import {SignUpsReportPopupModule} from '@app/shared/components';
import {SharedDirectivesModule} from '@app/shared/directives';
import {SharedModule} from '@app/shared/shared.module';
import {
  ComponentLoaderModule,
  InfoBlockModule,
  InfoTableModule,
  NavbarModule,
  PaginatedPageWrapperModule
} from '@app/shared/ui';
import {ServiceConnectionPopupModule, ServiceDelegationPopupModule} from '@app/views/services/components';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {
  AbonentAccountsComponent,
  AbonentChatComponent,
  AbonentDelegationsComponent,
  AbonentInfoComponent,
  AbonentOwnerComponent,
  AbonentPageComponent,
  AbonentsDelegationPopupModule,
  AbonentServicesComponent,
  AbonentSharedComponent,
  AbonentSignUpsComponent,
  AbonentsPageComponent,
  DeleteDelegationPopupComponent,
  EditAccountFormComponent,
  MailingHistoryComponent,
  MailingNewComponent,
  MailingPageComponent,
  RejectRequestPopupComponent,
  SignUpsInfoPopupComponent,
  SignUpsPageComponent,
  SignUpsWarningsComponent
} from './components';
import {ConfirmMailingSendPopupComponent} from '@app/views/abonents/components';
import {SignUpsPageService} from './services';
import {AbonentPageEffects, AbonentsEffects, MailingEffects} from './store/effects/index';
import {abonentsModuleReducers} from './store/reducers/reducers';
import {AccountStatusPipe} from './components/containers/abonent-accounts/account-status.pipe';
import {
  UnblockNonPaymentAccountComponent
} from './components/containers/abonent-accounts/unblock-non-payment-account/unblock-non-payment-account.component';
import {
  ServiceUnblockAccountPopupComponent
} from './components/containers/abonent-accounts/unblock-non-payment-account/service-unblock-account-popup/service-unblock-account-popup.component';
import {PaymentsModule} from '@app/views/payments';
import {
  BlockedPaymentsPipe
} from './components/containers/abonent-accounts/unblock-non-payment-account/blocked-payments.pipe';
import {TableModule} from 'primeng/table';
import {TagModule} from 'primeng/tag';
import {ButtonModule} from 'primeng/button';
import {NgxTranslateModule} from '@app/translate/translate.module';
import {DelegationComponent} from '@app/views/services/submodules/shared/delegation/delegation.component';
import {
  AbonentsDelegationComponent
} from '@app/views/services/submodules/shared/abonents-delegation/abonents-delegation.component';
import {InputTextModule} from 'primeng/inputtext';
import {SelectButtonModule} from 'primeng/selectbutton';
import {DelegatedServicesPipe} from './components/pages/abonent-page/abonent-owner/pipes/delegated-services.pipe';
import {PanelModule} from 'primeng/panel';
import {PrimeBaseTableComponent} from '@app/shared/components/prime-base-table/prime-base-table.component';
import {
  OwnServicesAdapterPipe
} from '@app/views/abonents/components/pages/abonent-page/abonent-owner/pipes/own-services-adapter.pipe';
import {AccordionModule} from 'primeng/accordion';
import {TooltipModule} from 'primeng/tooltip';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {InputSwitchModule} from 'primeng/inputswitch';
import {DialogModule} from 'primeng/dialog';
import {
  TableWithServerPaginationComponent
} from '@app/shared/components/table-with-server-pagination/table-with-server-pagination.component';
import {DropdownModule} from 'primeng/dropdown';
import {
  StateUrlImgPipe
} from '@app/views/abonents/components/pages/abonent-page/abonent-owner/pipes/state-url-img.pipe';
import {SingUpsPipe} from '@app/views/abonents/components/pages/abonent-page/abonent-owner/pipes/sing-ups.pipe';
import {DataSourceConverterPipe} from '@app/views/abonents/components/pages/sign-ups-page/data-sourse-converter.pipe';
import {SkeletonModule} from 'primeng/skeleton';
import {
  DeleteConnectionConfirmComponent
} from './components/pages/abonent-page/abonent-shared/abonent-edit-connection/delete-connection-confirm/delete-connection-confirm.component';

const components = [
  AbonentsPageComponent,
  AbonentPageComponent,
  AbonentSharedComponent,
  AbonentOwnerComponent,
  AbonentChatComponent,
  AbonentInfoComponent,
  SignUpsPageComponent,
  AbonentServicesComponent,
  AbonentAccountsComponent,
  AbonentDelegationsComponent,
  AbonentSignUpsComponent,
  SignUpsWarningsComponent,
  MailingPageComponent,
  MailingHistoryComponent,
  MailingNewComponent,
  AccountStatusPipe,
  UnblockNonPaymentAccountComponent,
  BlockedPaymentsPipe,
  DelegatedServicesPipe,
  DataSourceConverterPipe
];

const modalForms = [
  RejectRequestPopupComponent,
  EditAccountFormComponent,
  ConfirmMailingSendPopupComponent,
  DeleteDelegationPopupComponent,
  SignUpsInfoPopupComponent,
  ServiceUnblockAccountPopupComponent
];

const effects = [AbonentsEffects, MailingEffects, AbonentPageEffects];

const providers = [SignUpsPageService];

@NgModule({
  imports: [
    SharedModule,
    InfiniteScrollModule,
    ComponentLoaderModule,
    SharedPipesModule,
    NavbarModule,
    SharedDirectivesModule,
    InfoTableModule,
    InfoBlockModule,
    ServiceConnectionPopupModule,
    ServiceDelegationPopupModule,
    SignUpsReportPopupModule,
    AbonentsDelegationPopupModule,
    PaginatedPageWrapperModule,
    NgxSkeletonLoaderModule,
    InfoTableModule,
    TableModule,
    TagModule,
    ButtonModule,
    StoreModule.forFeature('abonents', abonentsModuleReducers),
    EffectsModule.forFeature(effects),
    PaymentsModule,
    NgxTranslateModule,
    DelegationComponent,
    AbonentsDelegationComponent,
    PanelModule,
    PrimeBaseTableComponent,
    OwnServicesAdapterPipe,
    AccordionModule,
    SelectButtonModule,
    InputTextModule,
    TooltipModule,
    OverlayPanelModule,
    InputSwitchModule,
    DialogModule,
    TableWithServerPaginationComponent,
    DropdownModule,
    SkeletonModule
  ],
  providers,
  declarations: [...components, ...modalForms, SingUpsPipe, StateUrlImgPipe, DataSourceConverterPipe, DeleteConnectionConfirmComponent],
  exports: components
})
export class AbonentsModule {
}
