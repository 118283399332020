import { Injectable } from '@angular/core';
import { PbxOnRdaResponse } from '@app/shared/entities/rd/pbx-on-rda/models';
import { PbxOnRdaApiService } from '@app/shared/entities/rd/pbx-on-rda/services';
import { PagedResponse } from '@app/shared/models';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { CreatePbxOnRdaPage, CreatePbxOnRdaPageFailure, CreatePbxOnRdaPageSuccess, DeletePbxOnRdaPage, DeletePbxOnRdaPageFailure, DeletePbxOnRdaPageSuccess, GetPbxOnRdaPage, GetPbxOnRdaPageFailure, GetPbxOnRdaPageSuccess, PbxOnRdaPageActionTypes, UpdatePbxOnRdaPage, UpdatePbxOnRdaPageFailure, UpdatePbxOnRdaPageSuccess } from './pbx-on-rda.actions';


@Injectable()
export class PbxOnRdaPageEffects {
    constructor(
        private actions$: Actions,
        private pbxApiService: PbxOnRdaApiService
    ) { }

    @Effect()
    GetPbxOnRdaPage$ = this.actions$.pipe(
        ofType<GetPbxOnRdaPage>(PbxOnRdaPageActionTypes.GetPbxOnRdaPage),
        switchMap(action =>
            this.pbxApiService.getPage(action.page, action.size, action.query)
                .pipe(
                    map((response: PagedResponse<PbxOnRdaResponse>) =>
                        new GetPbxOnRdaPageSuccess(response.content, response.totalElements)
                    ),
                    catchError(error => of(new GetPbxOnRdaPageFailure(error)))
                )
        )
    );

    @Effect()
    CreatePbxOnRdaPage$ = this.actions$.pipe(
        ofType<CreatePbxOnRdaPage>(PbxOnRdaPageActionTypes.CreatePbxOnRdaPage),
        switchMap(action =>
            this.pbxApiService.createPbxOnRda(action.request)
                .pipe(
                    map((response: PbxOnRdaResponse) => new CreatePbxOnRdaPageSuccess(response)),
                    catchError(error => of(new CreatePbxOnRdaPageFailure(error)))
                )
        )
    );

    @Effect()
    UpdatePbxOnRdaPage$ = this.actions$.pipe(
        ofType<UpdatePbxOnRdaPage>(PbxOnRdaPageActionTypes.UpdatePbxOnRdaPage),
        switchMap(action =>
            this.pbxApiService.updatePbxOnRda(action.pbxOnRdaUid, action.request)
                .pipe(
                    map(() =>
                        new UpdatePbxOnRdaPageSuccess(action.pbxOnRdaUid, action.request)
                    ),
                    catchError(error => of(new UpdatePbxOnRdaPageFailure(error)))
                )
        )
    );

    @Effect()
    DeletePbxOnRdaPage$ = this.actions$.pipe(
        ofType<DeletePbxOnRdaPage>(PbxOnRdaPageActionTypes.DeletePbxOnRdaPage),
        switchMap(action =>
            this.pbxApiService.deletePbxOnRda(action.pbxOnRdaUid)
                .pipe(
                    map(() =>
                        new DeletePbxOnRdaPageSuccess(action.pbxOnRdaUid)
                    ),
                    catchError(error => of(new DeletePbxOnRdaPageFailure(error)))
                )
        )
    );
}
