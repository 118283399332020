import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
  IntercomType,
  IntercomTypeGeneratorUrls,
  IntercomTypeLinkGenerator
} from '@app/shared/entities/rd/intercom-type';
import {ProtocolTypes} from '@app/shared/entities/rd/protocols/models';

@Component({
  selector: 'app-ip-intercom-generate-urls-container',
  templateUrl: './ip-intercom-generate-urls-container.component.html',
  styleUrls: ['./ip-intercom-generate-urls-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IpIntercomGenerateUrlsContainerComponent implements OnInit {
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  @Input() set intercom(intercom: IntercomType) {
    this._protocolType = intercom.protocol.number;
    this._intercom = intercom;
  }
  @Input() params: string[] = ['authUrl', 'livenessUrl', 'openDoorUrl'];
  form: UntypedFormGroup = this.initForm();
  hidePassword = true;

  @Output() private urlsGenerated: EventEmitter<
    Partial<IntercomTypeGeneratorUrls>
  > = new EventEmitter();
  private _loading: boolean;
  private _protocolType: ProtocolTypes;
  private _intercom: IntercomType;

  ngOnInit() {
    if (
      [
        ProtocolTypes.QTECH,
        ProtocolTypes.Akuvox,
        ProtocolTypes.Rubetek,
        ProtocolTypes.Sokol,
        ProtocolTypes.Hikvision,
        ProtocolTypes.Rubitek
      ].includes(this.protocolType)
    ) {
      this.form.addControl(
        'doorNum',
        new UntypedFormControl(null, Validators.required)
      );
    }
  }

  get loading(): boolean {
    return this._loading;
  }

  get protocolType(): ProtocolTypes {
    return this._protocolType;
  }

  get intercom(): IntercomType {
    return this._intercom;
  }

  onSubmit() {
    const response: Partial<IntercomTypeGeneratorUrls> =
      IntercomTypeLinkGenerator.generateIntercomTypeLinks(
        this.intercom,
        this.form.getRawValue(),
        this.params
      );

    this.urlsGenerated.emit(response);
  }

  removeAttribute(input: HTMLInputElement) {
    input.readOnly = false;
    input.focus();
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      ip: new UntypedFormControl(null, Validators.required),
      port: new UntypedFormControl(null, Validators.required),
      login: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, Validators.required)
    });
  }
}
