import { IntercomType, RdaResponse, ServiceCreateRequest } from '@app/shared/entities/rd';
import { Action } from '@ngrx/store';
import { GateMode, GatesPageModel } from '../models';
import { PageData } from '@app/shared/models';

export enum GatesActionTypes {
    GetGatesPage = '[Gates] GetGatesPage',
    GetGatesPageSuccess = '[Gates] GetGatesPage Success',
    GetGatesPageFailure = '[Gates] GetGatesPage Failure',

    CreateGate = '[Gates] CreateGate',
    CreateGateSuccess = '[Gates] CreateGate Success',
    CreateGateFailure = '[Gates] CreateGate Failure',

    DeleteGate = '[Gates] DeleteGate',
    DeleteGateSuccess = '[Gates] DeleteGate Success',
    DeleteGateFailure = '[Gates] DeleteGate Failure',

    GetGateEntrancesIntercoms = '[Gates] GetGateEntrancesIntercoms',
    GetGateEntrancesIntercomsSuccess = '[Gates] GetGateEntrancesIntercoms Success',
    GetGateEntrancesIntercomsFailure = '[Gates] GetGateEntrancesIntercoms Failure',

    AddGateEntranceIntercoms = '[Gates] AddGateEntranceIntercoms',
    AddGateEntranceIntercomsSuccess = '[Gates] AddGateEntranceIntercoms Success',
    AddGateEntranceIntercomsFailure = '[Gates] AddGateEntranceIntercoms Failure',

    ConnectGateEntranceIntercoms = '[Services] ConnectGateEntranceIntercoms',
    ConnectGateEntranceIntercomsSuccess = '[Services] ConnectGateEntranceIntercoms Success',
    ConnectGateEntranceIntercomsFailure = '[Services] ConnectGateEntranceIntercoms Failure',

    UpdateGateEntranceIntercoms = '[Services] UpdateGateEntranceIntercoms',
    UpdateGateEntranceIntercomsSuccess = '[Services] UpdateGateEntranceIntercoms Success',
    UpdateGateEntranceIntercomsFailure = '[Services] UpdateGateEntranceIntercoms Failure',

    RemoveGateEntranceIntercoms = '[Gates] RemoveGateEntranceIntercoms',
    RemoveGateEntranceIntercomsSuccess = '[Gates] RemoveGateEntranceIntercoms Success',
    RemoveGateEntranceIntercomsFailure = '[Gates] RemoveGateEntranceIntercoms Failure',

    SetGateIntercomType = '[Gates] SetGateIntercomType',
    SetGateIntercom = '[Gates] SetGateIntercom',
    SetGateIndex = '[Gates] SetGateIndex',
    SetGateMode = '[Gates] SetGateMode',
    ClearGateState = '[Gates] ClearGateState',
}

export class GetGatesPage implements Action {
    readonly type = GatesActionTypes.GetGatesPage;

    constructor(public page: number = 0, public size: number = 10, public name?: string) { }
}

export class GetGatesPageSuccess implements Action {
    readonly type = GatesActionTypes.GetGatesPageSuccess;

    constructor(
        public response: GatesPageModel[], 
        public pageData: PageData
    ) { }
}

export class GetGatesPageFailure implements Action {
    readonly type = GatesActionTypes.GetGatesPageFailure;

    constructor() { }
}

export class CreateGate implements Action {
    readonly type = GatesActionTypes.CreateGate;

    constructor(
        public request: ServiceCreateRequest,
        public options: { filterValue: string, pageIndex: number }
    ) { }
}

export class CreateGateSuccess implements Action {
    readonly type = GatesActionTypes.CreateGateSuccess;

    constructor() { }
}

export class CreateGateFailure implements Action {
    readonly type = GatesActionTypes.CreateGateFailure;

    constructor() { }
}

export class DeleteGate implements Action {
    readonly type = GatesActionTypes.DeleteGate;

    constructor(
        public serviceId: number,
        public options: { pageIndex: number, pageSize: number, filterValue: string }
    ) { }
}

export class DeleteGateSuccess implements Action {
    readonly type = GatesActionTypes.DeleteGateSuccess;

    constructor() { }
}

export class DeleteGateFailure implements Action {
    readonly type = GatesActionTypes.DeleteGateFailure;

    constructor() { }
}

export class GetGateEntrancesIntercoms implements Action {
    readonly type = GatesActionTypes.GetGateEntrancesIntercoms;

    constructor(public entrancesIds: number[]) { }
}

export class GetGateEntrancesIntercomsSuccess implements Action {
    readonly type = GatesActionTypes.GetGateEntrancesIntercomsSuccess;

    constructor(public intercoms: RdaResponse[]) { }
}

export class GetGateEntrancesIntercomsFailure implements Action {
    readonly type = GatesActionTypes.GetGateEntrancesIntercomsFailure;

    constructor() { }
}

export class AddGateEntranceIntercoms implements Action {
    readonly type = GatesActionTypes.AddGateEntranceIntercoms;

    constructor(public entrancesId: number) { }
}

export class AddGateEntranceIntercomsSuccess implements Action {
    readonly type = GatesActionTypes.AddGateEntranceIntercomsSuccess;

    constructor(public intercoms: RdaResponse[], public options?: { connectIntercoms: boolean }) { }
}

export class AddGateEntranceIntercomsFailure implements Action {
    readonly type = GatesActionTypes.AddGateEntranceIntercomsFailure;

    constructor() { }
}

export class ConnectGateEntranceIntercoms implements Action {
    readonly type = GatesActionTypes.ConnectGateEntranceIntercoms;

    constructor(public index: string, public intercomsIds: number[]) { }
}

export class ConnectGateEntranceIntercomsSuccess implements Action {
    readonly type = GatesActionTypes.ConnectGateEntranceIntercomsSuccess;

    constructor() { }
}

export class ConnectGateEntranceIntercomsFailure implements Action {
    readonly type = GatesActionTypes.ConnectGateEntranceIntercomsFailure;

    constructor() { }
}

export class UpdateGateEntranceIntercoms implements Action {
    readonly type = GatesActionTypes.UpdateGateEntranceIntercoms;

    constructor(public index: string) { }
}

export class UpdateGateEntranceIntercomsSuccess implements Action {
    readonly type = GatesActionTypes.UpdateGateEntranceIntercomsSuccess;

    constructor(public index: string) { }
}

export class UpdateGateEntranceIntercomsFailure implements Action {
    readonly type = GatesActionTypes.UpdateGateEntranceIntercomsFailure;

    constructor() { }
}

export class RemoveGateEntranceIntercoms implements Action {
    readonly type = GatesActionTypes.RemoveGateEntranceIntercoms;

    constructor(public intercomsIds: number[]) { }
}

export class RemoveGateEntranceIntercomsSuccess implements Action {
    readonly type = GatesActionTypes.RemoveGateEntranceIntercomsSuccess;

    constructor(public intercomsIds: number[]) { }
}

export class RemoveGateEntranceIntercomsFailure implements Action {
    readonly type = GatesActionTypes.RemoveGateEntranceIntercomsFailure;

    constructor() { }
}

export class SetGateIntercomType implements Action {
    readonly type = GatesActionTypes.SetGateIntercomType;

    constructor(public intercomType: IntercomType) { }
}

export class SetGateIntercom implements Action {
    readonly type = GatesActionTypes.SetGateIntercom;

    constructor(public intercom: RdaResponse) { }
}

export class SetGateIndex implements Action {
    readonly type = GatesActionTypes.SetGateIndex;

    constructor(public index: string) { }
}

export class SetGateMode implements Action {
    readonly type = GatesActionTypes.SetGateMode;

    constructor(public mode: GateMode) { }
}

export class ClearGateState implements Action {
    readonly type = GatesActionTypes.ClearGateState;

    constructor() { }
}

export type GatesActions =
    | GetGatesPage
    | GetGatesPageSuccess
    | GetGatesPageFailure

    | CreateGate
    | CreateGateSuccess
    | CreateGateFailure

    | DeleteGate
    | DeleteGateSuccess
    | DeleteGateFailure

    | GetGateEntrancesIntercoms
    | GetGateEntrancesIntercomsSuccess
    | GetGateEntrancesIntercomsFailure

    | AddGateEntranceIntercoms
    | AddGateEntranceIntercomsSuccess
    | AddGateEntranceIntercomsFailure

    | ConnectGateEntranceIntercoms
    | ConnectGateEntranceIntercomsSuccess
    | ConnectGateEntranceIntercomsFailure

    | UpdateGateEntranceIntercoms
    | UpdateGateEntranceIntercomsSuccess
    | UpdateGateEntranceIntercomsFailure

    | RemoveGateEntranceIntercoms
    | RemoveGateEntranceIntercomsSuccess
    | RemoveGateEntranceIntercomsFailure

    | SetGateIntercomType
    | SetGateIntercom
    | SetGateIndex
    | SetGateMode
    | ClearGateState;
