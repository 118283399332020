import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SnackbarService } from '@app/shared/components/snackbar';
import { AddressInfo, Country, EntrancePageResponse } from '@app/shared/models';
import { AddressInputMode } from './address-input-mode';
import { AddressSelectResponse } from './address-select-response.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressInputComponent {
  readonly addressInputMode: typeof AddressInputMode = AddressInputMode;

  @Input() initialAddress: AddressInfo;
  @Input() label: string;
  @Input() mode: AddressInputMode;
  @Input() country: Country;
  @Input() options: { intercomIndexesRequired?: boolean };

  @Output() private changeMode: EventEmitter<void> = new EventEmitter();
  @Output() private submittedAddress: EventEmitter<AddressSelectResponse> = new EventEmitter();

  constructor(
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) { }

  onSelectAddress(event: { address: string, entrance: string | number }) {
    this.submittedAddress.emit({
      addressStr: event.address,
      entranceNumber: event.entrance,
      entrance: null
    });
  }

  onSelectEntrance(entrance: EntrancePageResponse) {
    this.submittedAddress.emit({
      addressStr: entrance.addressString,
      entranceNumber: entrance.address.entrance.number,
      entrance: entrance
    });
  }

  onChangeMode() {
    this.changeMode.emit();
  }

  onError(error: string) {
    this.snackbar.showMessage(
      this.translate.instant('shared.address.input.message.error', {
        text: error
      })
    );
  }
}
