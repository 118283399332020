import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { State } from '@app/store/reducers';
import { ChatContent, UpdateAbonentRequest } from '@app/views/abonents/models';
import { GetAbonentPageMessages, LoadAbonentPageMessages, SendAbonentPageMessage, UpdateAbonentPageInfo } from '@app/views/abonents/store/actions';
import { abonentPageStateChatLoading, abonentPageStateSendMessageLoading } from '@app/views/abonents/store/states';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AbonentChatSerivice } from './abonent-chat.service';

@Component({
  selector: 'app-abonent-chat',
  templateUrl: './abonent-chat.component.html',
  styleUrls: ['./abonent-chat.component.scss'],
  providers: [AbonentChatSerivice]
})
export class AbonentChatComponent implements OnInit, OnDestroy {
  readonly selector: string = '.chat-container';

  @Input() resolved!: boolean;
  @Input() abonentId!: number;
  @Input() chat!: ChatContent[];
  message: string;
  sendMessageLoading$: Observable<boolean> = this.store.select(abonentPageStateSendMessageLoading);
  loading$: Observable<boolean> = this.store.select(abonentPageStateChatLoading);

  @Input() private totalElements!: number;
  @Input() private chatPage!: number;
  @Output() private submittedClose: EventEmitter<boolean> = new EventEmitter(false);
  private chatBlockHeight = 0;
  private scrolledObserver: MutationObserver;

  constructor(
    public resolution: ResolutionService,
    private chatSerivice: AbonentChatSerivice,
    private store: Store<State>
  ) { }

  ngOnInit() {
    const target = document.getElementById('chatsContent');

    if (target) {
      this.scrolledObserver = new MutationObserver(() => {
        const curChatBlockHeight: number = document.getElementById('chatsContent').offsetHeight - this.chatBlockHeight;
        document.getElementById('scrollBlock').scrollTo(0, curChatBlockHeight);
        this.chatBlockHeight = document.getElementById('chatsContent').offsetHeight;
      });

      const config = { characterData: true, subtree: true };
      this.scrolledObserver.observe(target, config);
    }

    this.chatSerivice.addSendMessageListener(() => this.message = '');
  }

  ngOnDestroy() {
    if (this.scrolledObserver) {
      this.scrolledObserver.disconnect();
    }
  }

  onResolve() {
    const updateAbonent: UpdateAbonentRequest = { resolved: true };
    this.store.dispatch(new UpdateAbonentPageInfo(this.abonentId, updateAbonent));
  }

  onSendMessage() {
    if (this.message) {
      this.chatBlockHeight = 0;
      this.store.dispatch(new SendAbonentPageMessage(this.abonentId, this.message));
    }
  }

  prepareDate(date: number): string {
    return (date ? new Date(date) : new Date()).toLocaleString();
  }

  onScroll() {
    this.loadNextPage();
  }

  onLoadFirstPage() {
    this.store.dispatch(new GetAbonentPageMessages(this.abonentId, 'support', 15, 0));
  }

  onCloseChat() {
    this.submittedClose.emit(true);
  }

  private loadNextPage() {
    if (this.totalElements === undefined || this.totalElements > this.chat.length) {
      this.store.dispatch(new LoadAbonentPageMessages(this.abonentId, 'support', 15, this.chatPage + 1));
    }
  }
}
