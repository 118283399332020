import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RdaNewResponse } from '@app/shared/entities/rd/rda';
import { DialogWrapperData } from '@app/shared/ui';
import { BatchResponse } from '@app/views/intercom/models';
import bwipjs from 'bwip-angular2';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-print-adapter-uid-popup',
  templateUrl: './print-adapter-uid-popup.component.html',
  styleUrls: ['./print-adapter-uid-popup.component.scss']
})
export class PrintAdapterUidPopupComponent implements OnInit, AfterViewInit {
  readonly barcodeLength: number = 11;
  preparedDate: string;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogWrapperData<
      { adapter: RdaNewResponse; batch: BatchResponse },
      null
    >,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.preparedDate = this.prepareDate(
      this.data.body.adapter.batch.createdAt
    );
  }

  ngAfterViewInit() {
    this.prepareSticker().then(
      (isPrepared) => isPrepared && this.printSticker()
    );
  }

  prepareDate(dateStr: string): string {
    const now = Date.now();
    const pipe = new DatePipe('en-US');
    const myFormattedDate = pipe.transform(
      dateStr ? dateStr : now,
      'dd.MM.yyyy HH:mm:ss'
    );
    return myFormattedDate;
  }

  prepareNumber(currentNumber: Number): string {
    if (currentNumber) {
      let preparedShieldNumber = '';
      for (
        let i = 0;
        i < this.barcodeLength - currentNumber.toString().length;
        ++i
      ) {
        preparedShieldNumber += '0';
      }
      preparedShieldNumber += currentNumber.toString();
      return preparedShieldNumber;
    } else {
      return '';
    }
  }

  printSticker(): void {
    const mywindow = window.open('', 'PRINT', 'height=400,width=600');
    const qrCodeDataUrl = (
      document.getElementById('qrCodeCanvas') as HTMLCanvasElement
    ).toDataURL();
    console.log('qrCodeDataUrl', qrCodeDataUrl);
    const qrCodeImage = new Image();
    qrCodeImage.src = qrCodeDataUrl;
    Promise.all([this.loadImage(qrCodeImage)]).then(() => {
      mywindow.document.write(
        `<html>
            <head>
              <style type="text/css">
                @page {
                  margin: 0 !important;
                }

                body {
                  margin: 8px 3px 0px 3px !important;
                }

                .barcode-block {
                  margin: 50px;
                }

                p {
                  font-size: 8pt;
                  font-family: Helvetica, Arial, sans-serif;
                }

                hr {
                  border: solid rgb(123, 123, 123);
                  border-width: 2px 0 0 0;
                  background: none;
                }

                .print-adapter-container {
                    height: max-content;
                }

                .barcode-model-block {
                    display: flex;
                }

                .bend-line-block {
                    margin-top: 37px;
                    text-align: center;
                }

                .canvas-block {
                    float: left;
                }

                .rda-info-block {
                    float: right;
                    margin: 0 0 0 auto;
                }

                .uid-data {
                    text-align: right;
                    letter-spacing: .9px;
                    margin: 9px 0 0;
                    font-size: 8pt;
                    font-weight: 700;
                    font-family: Consolas;
                }

                .uid-data_center {
                    text-align: center;
                }

                .rda-model,
                .rda-version {
                    text-align: right;
                    margin: 0;
                }

                .batch-data,
                .shield-data,
                .date-data {
                    margin: 0;
                }

                .data-block {
                    margin-top: 5px;
                }

                .bend-line-text {
                    margin: 0;
                    margin-top: -8px;
                }

                .block-data {
                  height: 58mm;
                  width: 38mm;
                }

                .bend-line-block-uid {
                  margin-top: 10px;
                  text-align: center;
                }

                .bend-line-block.cut {
                  margin-top: 2mm;
                  position: relative;
                }
                .line {
                  border-top: 2px dashed rgb(71 71 71);
                  width: 100%;
                }

              </style>
            </head>
            <body>
              <div class="block-data">
                <div class="barcode-model-block">
                  <div class="canvsas-block">
                    <img src="${qrCodeDataUrl}" />
                  </div>
                    <div class="rda-info-block">
                      <p class="rda-model">РДА модель</p>
                      <p class="rda-version">${
                        this.data.body.adapter.rdaModel
                      }</p>
                      <p class="uid-data">${this.data.body.adapter.uid}</p>
                    </div>
                  </div>

                  <div class="data-block" id="printDataBlock">
                    <p class="batch-data">VS: ${
                      this.data.body.batch.shieldModelName
                    }</p>
                    <p class="date-data">${this.prepareDate(
                      this.data.body.adapter.batch.createdAt
                    )}</p>
                  </div>

                  <div class="bend-line-block">
                    <hr />
                    <p class="bend-line-text">линия изгиба</p>
                  </div>

                  <div class="bend-line-block cut">
                    <div class="line"> </div>
                  </div>

                  <div class="bend-line-block-uid">
                    <p class="uid-data uid-data_center">${
                      this.data.body.adapter.uid
                    }</p>
                  </div>
                </div>
              </div>
            </body>
          </html>
        `
      );

      mywindow.document.close();
      mywindow.focus();
      mywindow.print();
    });
  }

  private prepareSticker(): Promise<boolean> {
    return new Promise((resolve) => {
      const preparedBatchNumber: string = this.prepareNumber(
        this.data.body.batch.number
      );

      return this.renderCanvas(
        'qrCodeCanvas',
        'qrcode',
        `${this.data.body.adapter.uid}`,
        8,
        8,
        3
      )
        .then(() => resolve(true))
        .catch((err) => console.log(err));
    });
  }

  private renderCanvas(
    canvasType: string,
    bcid: string,
    text: string,
    height: number,
    width: number,
    scale: number
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const data: Object = {
        bcid: bcid,
        text,
        scale: scale,
        height,
        width,
        includetext: true,
        textxalign: 'center'
      };

      bwipjs(canvasType, data, (err) => (err ? reject(err) : resolve(true)));
    });
  }

  private loadImage(element: HTMLImageElement): Promise<boolean> {
    return new Promise((resolve) => {
      element.onload = function () {
        resolve(true);
      };
    });
  }
}
