import { BatchResponse } from '@app/views/intercom/models';
import { Actions, ActionTypes } from '../../actions/batches.actions';

export interface State {
  batches: BatchResponse[];
  error: string | null;
  loading: boolean;
  totalCount: number;
}

export const initialState: State = {
  batches: null,
  error: null,
  loading: false,
  totalCount: null
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GetBatchesPage: {
      return {
        ...state,
        batches: null,
        error: null,
        loading: true,
        totalCount: null,
      };
    }

    case ActionTypes.GetBatchesPageSuccess: {
      return {
        ...state,
        batches: action.payload,
        error: null,
        loading: false,
        totalCount: action.totalCount
      };
    }

    case ActionTypes.GetBatchesPageFailure: {
      return {
        ...state,
        batches: [],
        error: action.payload,
        loading: false,
        totalCount: null
      };
    }

    default: {
      return state;
    }
  }
}
