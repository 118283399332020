import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

const addRdasToOrderState = createSelector(
    intercomModuleSelector,
    (state: IntercomModuleState) => state.addRdasToOrder
);

export const addRdasToOrder = createSelector(
    addRdasToOrderState,
    state => state
);
export const addRdasToOrderLoading = createSelector(
    addRdasToOrderState,
    state => state.loading
);
export const addRdasToOrderSuccess = createSelector(
    addRdasToOrderState,
    state => state.success
);

export const addRdasToOrderFailed = createSelector(
    addRdasToOrderState,
    state => state.error
);
