<div class="file-card" [ngClass]="classToOverride" *ngIf="((fileName || fileConfig?.name) | fileTypeDetector) as file">
  <div class="file-card-content">
    <div class="file-card-content-img">
      <img [src]="file.icon" alt="error">
    </div>
    <div class="file-card-content-description">
      <div class="file-card-content-description-name">
        <span>{{file.name}}</span>
      </div>
      <div class="file-card-content-description-type">
        <span>{{file.extension}}</span>
      </div>
    </div>
  </div>
  <div class="file-card-actions">
    <div class="file-card-actions-delete">
      <i *ngIf="actions.delete" class="pi pi-trash file-card-actions-delete__icon" (click)="delete.emit(fileConfig || fileName)"></i>
    </div>
    <div class="file-card-actions-download">
      <i *ngIf="actions.download" class="pi pi-download file-card-actions-download__icon" (click)="download.emit(fileConfig || fileName)"></i>
    </div>
  </div>
</div>
