import { Actions, ActionTypes } from '../actions/abonents-page.actions';
import { Abonent } from '@app/shared/models';

export interface AbonentsPageState {
  abonents: Abonent[];
  totalCount: number;
  error: string | null;
  loading: boolean;
}

export const abonentsPageInitialState: AbonentsPageState = {
  abonents: null,
  error: null,
  totalCount: null,
  loading: false
};

export function abonentsPageReducer(state = abonentsPageInitialState, action: Actions): AbonentsPageState {
  switch (action.type) {
    case ActionTypes.GetAbonentsPage: {
      return {
        ...state,
        abonents: null,
        totalCount: null,
        error: null,
        loading: true
      };
    }
    case ActionTypes.GetAbonentsPageSuccess: {
      return {
        ...state,
        abonents: action.payload,
        totalCount: action.totalCount,
        error: null,
        loading: false
      };
    }
    case ActionTypes.GetAbonentsPageFailure: {
      return {
        ...state,
        abonents: null,
        totalCount: null,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
