import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VideoPlayerModuleRootState, VideoPlayerModuleState, VideoPlayerState } from './video-player.state';

export const videoPlayerSelector = createFeatureSelector<
    VideoPlayerModuleRootState, VideoPlayerModuleState
>('video-player');

const selectState = createSelector(
    videoPlayerSelector,
    (state: VideoPlayerModuleState) => state.videoPlayer
);

const selectPlaylists = createSelector(
    selectState,
    (state: VideoPlayerState) => state.playlists
);

const selectVideoshotState = createSelector(
    selectState,
    (state: VideoPlayerState) => state.videoshotPopupState
);

const selectCurrentSource = createSelector(
    selectState,
    (state: VideoPlayerState) => state.currentSource
);

const selectVideoshotTimeData = createSelector(
    selectState, 
    (state: VideoPlayerState) => state.videoshotTimeData
)

export const videoPlayerSelectors = {
    selectPlaylists,
    selectVideoshotState,
    selectCurrentSource, 
    selectVideoshotTimeData
};
