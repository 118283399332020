import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { BatchService } from '../../services/batch.service';
import { GetAllBatches, GetAllBatchesFailure, GetAllBatchesSuccess, GetBatchAdapters, GetBatchAdaptersClear, GetBatchAdaptersFailure, GetBatchAdaptersSuccess, GetBatchesPage, GetBatchesPageFailure, GetBatchesPageSuccess, PutNewBatch, PutNewBatchClear, PutNewBatchFailure, PutNewBatchSuccess } from '../actions';
import { ActionTypes } from '../actions/batches.actions';

@Injectable()
export class BatchesEffects {
  @Effect()
  GetBatchesPage$ = this.actions$.pipe(
    ofType<GetBatchesPage>(ActionTypes.GetBatchesPage),
    switchMap(action =>
      this.batchService.getBatchesPage(action.page, action.size, action.search).pipe(
        map(data => new GetBatchesPageSuccess(data.content, data.totalElements)),
        catchError(error => of(new GetBatchesPageFailure(error)))
      )
    )
  );

  @Effect()
  GetAllBatches$ = this.actions$.pipe(
    ofType<GetAllBatches>(ActionTypes.GetAllBatches),
    switchMap(action =>
      this.batchService.getAllBatches(action.search).pipe(
        map(data => new GetAllBatchesSuccess(data)),
        catchError(error => of(new GetAllBatchesFailure(error)))
      )
    )
  );

  @Effect()
  PutNewBatch$ = this.actions$.pipe(
    ofType<PutNewBatch>(ActionTypes.PutNewBatch),
    switchMap(action =>
      this.batchService.putNewBatch(action.request).pipe(
        map(() => new PutNewBatchSuccess()),
        catchError(error => of(new PutNewBatchFailure(error)))
      ))
  );

  @Effect({ dispatch: false })
  PutNewBatchClear$ = this.actions$.pipe(
    ofType<PutNewBatchClear>(ActionTypes.PutNewBatchClear),
    tap(() => new PutNewBatchClear())
  );

  @Effect()
  GetBatchAdapters$ = this.actions$.pipe(
    ofType<GetBatchAdapters>(ActionTypes.GetBatchAdapters),
    switchMap(action =>
      this.batchService.getBatchAdapters(action.batchId, action.search)
        .pipe(
          map(payload => new GetBatchAdaptersSuccess(payload)),
          catchError(error => of(new GetBatchAdaptersFailure(error)))
        )
    )
  );

  GetBatchAdaptersClear$ = this.actions$.pipe(
    ofType<GetBatchAdaptersClear>(ActionTypes.GetBatchAdaptersClear),
    tap(() => new GetBatchAdaptersClear())
  );

  constructor(private actions$: Actions, private batchService: BatchService) { }
}
