import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-payments-wizard-registration-checkpoint',
  templateUrl: './payments-wizard-registration-checkpoint.component.html',
  styleUrls: ['./payments-wizard-registration-checkpoint.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentsWizardRegistrationCheckpointComponent {
  @Input() public readonly number: number;
  @Input() public readonly current: number;
  @Input() public readonly target: number;
}
