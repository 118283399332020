import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponentConnectionHelper } from '@app/shared/components';
import { Camera } from '@app/shared/entities/rd';
import { Constants } from '@app/shared/helpers';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData, DialogWrapperSize } from '@app/shared/ui';
import { ServiceCameraPopupBody, ServiceCamerasComponent } from '@app/views/services/components';
import { ServiceFacade } from '@app/views/services/store';
import { SoftwareIntercomCameraPopupComponent } from '../../popups';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-software-intercom-cameras',
  templateUrl: './software-intercom-cameras.component.html',
  styleUrls: ['./software-intercom-cameras.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomCamerasComponent extends ServiceCamerasComponent {
  constructor(
    dialog: MatDialog,
    resolution: ResolutionService,
    serviceFacade: ServiceFacade,
    confirmComponentConnectionHelper: ConfirmComponentConnectionHelper,
    translate: TranslateService
  ) {
    super(dialog, resolution, serviceFacade, confirmComponentConnectionHelper, translate);
  }

  onAdd() {
    const data: DialogWrapperData<Partial<ServiceCameraPopupBody>, { camera: Camera, intercomPanelId?: number }> = {
      title: this.translate.instant('software.intercom.cameras.add.title'),
      componentName: `Add${this.serviceType}Camera`,
      body: {},
      submit: (event: { camera: Camera, intercomPanelId: number }) =>
        this.confirmComponentConnectionHelper.showBottomSheet(() =>
          this.serviceFacade.addServiceCamera(event.camera, event.intercomPanelId)
        )
    };

    this.dialog.open(SoftwareIntercomCameraPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.SM,
      data
    });
  }

  onEdit(camera: Camera) {

    this.serviceFacade.activeServiceOnvif(camera);

    const data: DialogWrapperData<Partial<ServiceCameraPopupBody>, { camera: Camera }> = {
      title: this.translate.instant('software.intercom.cameras.edit.title'),
      componentName: `Edit${this.serviceType}Camera`,
      body: { camera },
      submit: (event: { camera: Camera }) => {
        this.serviceFacade.updateServiceCamera(event.camera);
        this.serviceFacade.activeServiceOnvif(event.camera);
      },
      submitOnvif: (event: { camera: Camera }) => this.serviceFacade.tuneServiceOnvif(event.camera)
    };

    this.dialog.open(SoftwareIntercomCameraPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.SM,
      data
    });
  }
}
