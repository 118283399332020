import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PbxOnRdaResponse } from '@app/shared/entities/rd/pbx-on-rda/models';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper/dialog-wrapper-data.model';

@Component({
  selector: 'app-delete-pbx-on-rda-content',
  templateUrl: './delete-pbx-on-rda-content.component.html',
  styleUrls: ['./delete-pbx-on-rda-content.component.scss']
})
export class DeletePbxOnRdaContentComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<PbxOnRdaResponse, boolean>,
  ) { }

  onSubmit() {
    this.data.submit(true);
  }
}
