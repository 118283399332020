import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IntercomType } from '../models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({
    providedIn: 'root'
})
export class IntercomTypeApiService extends ApiService {
    constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
        super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1']);
    }

    getAdaptersTypes(): Observable<IntercomType[]> {
        return this.get<IntercomType[]>('/intercom_types', 1).pipe(
            map(response => response
                .map(item => ({
                    id: item.id,
                    name: item.name,
                    protocol: item.protocol,
                    instructionUrl: item.instructionUrl
                }))
                .sort((a, b) => a.name.localeCompare(b.name))
            )
        );
    }
}
