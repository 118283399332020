import {Component, ViewEncapsulation} from '@angular/core';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';

@Component({
  selector: 'app-delete-connection-confirm',
  templateUrl: './delete-connection-confirm.component.html',
  styleUrls: ['./delete-connection-confirm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteConnectionConfirmComponent {
  constructor( public dynamicDialogConfig: DynamicDialogConfig<{ request: Function }>) {}
  public onDisconnection(): void {
    this.dynamicDialogConfig.data.request();
  }
}
