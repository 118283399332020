<div class="snackbar">

  <ng-container [ngSwitch]="data.action">
    <ng-container *ngSwitchCase="'info'">
      <mat-icon class="snackbar__icon--info">info_outline</mat-icon>
    </ng-container>
    <ng-container *ngSwitchCase="'success'">
      <mat-icon class="snackbar__icon--success">error_outline</mat-icon>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <mat-icon class="snackbar__icon--error">error_outline</mat-icon>
    </ng-container>
  </ng-container>

  <div class="snackbar-content">
    <span class="snackbar-content__text">{{ data.errorText }}</span>
  </div>

  <div class="snackbar-action">
    <button
      color="warn"
      mat-stroked-button
      (click)="onDismiss()"
      class="snackbar-action__button"
    >
      {{ data.btnText ? data.btnText : 'OK' }}
    </button>
  </div>
</div>
