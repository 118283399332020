import { FlatRange } from '@app/shared/models';

export interface EntranceResponse {
  id: number;
  flatEnd: number;
  flatStart: number;
  number: string;
  additionalFlatRanges: FlatRange[];
  prefix?: string;
}
