import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  ActionType
} from '@app/views/company/pages/company-roles-page/company-block-user-confirm/company-block-user-popup/company-block-user-popup.component';

@Component({
  selector: 'app-company-block-user-confirm',
  templateUrl: './company-block-user-confirm.component.html',
  styleUrls: ['./company-block-user-confirm.component.scss']
})
export class CompanyBlockUserConfirmComponent {
  public readonly ActionType = ActionType;
  @Input() email: string;
  @Input() type: ActionType = ActionType.block;
  @Output() submit: EventEmitter<boolean> = new EventEmitter<boolean>();
}
