<div
  *ngIf="
    flat?.account?.owner?.phone
      ? !!(flat.account.owner.phone.toString() | phone)
      : false;
    else noPhones
  ">
  <div
    aria-expanded="false"
    class="phones"
    #phonesBlock>
    <div class="flat-phone-block">
      <button
        class="user-link"
        [routerLink]="'/abonents/subscriber/' + flat.account.owner.id"
        mat-icon-button>
        <mat-icon
          svgIcon="user"
          class="user-icon"></mat-icon>
      </button>
      <span>{{ flat.account.owner.phone.toString() | phone }}</span>
      <a
        [routerLink]="'/abonents/subscriber/' + flat.account.owner.id"
        color="primary-inverse"
        class="flat-phone-link arrow"
        mat-mini-fab>
        <mat-icon svgIcon="right-arrow"></mat-icon>
      </a>
    </div>

    <span
      *ngIf="phonesBlock.getAttribute('aria-expanded') === 'true'"
      class="phone-type">
      {{ 'shared.flat.phone.general' | translate }}
    </span>

    <div *ngIf="!!flat.sharedAbonents?.length">
      <span
        (click)="phonesBlock.setAttribute('aria-expanded', 'true')"
        *ngIf="
          phonesBlock.getAttribute('aria-expanded') === 'false';
          else expandedSharedPhones
        "
        class="flat-phone__more"
      >
        {{ 'shared.flat.phone.more' | translate }} {{ flat.sharedAbonents.length }}
      </span>

      <ng-template #expandedSharedPhones>
        <div class="flat-phone-list">
          <ng-container *ngFor="let abonent of flat.sharedAbonents">
            <ng-container>
              <div class="flat-phone-list-item">
                <span>{{ abonent.number.toString() | phone }}</span>
                <a
                  type="button"
                  [routerLink]="'/abonents/subscriber/' + abonent.abonentId"
                  color="primary-inverse"
                  mat-mini-fab>
                  <mat-icon svgIcon="right-arrow"></mat-icon>
                </a>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #noPhones>
  <div class="no-phone">
    <button
      mat-button
      color="primary-inverse"
      (click)="onAddPhone()"
    >
      <mat-icon svgIcon="add-sm" class="add-icon-large"></mat-icon>
      {{ 'shared.flat.phone.button.add' | translate }}
    </button>
  </div>
</ng-template>
