import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { CreateDelegationResponse, GetDelegationResponse } from '@app/shared/entities/rd/delegations/models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DelegationsApiService extends ApiService {

    constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
        super(httpClient, humanErrorTranslationService, environment.abonentsApiUrl, ['v1']);
    }

    createDelegation(fromAbonentId: number, toAbonentId: number): Observable<CreateDelegationResponse> {
        const body: Object = {
            fromAbonent: fromAbonentId,
            toAbonent: toAbonentId
        };
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.post('/delegations', 1, body, { headers: headers });
    }

    getDelegations(fromAbonentId: number, toAbonentId: number): Observable<GetDelegationResponse[]> {
        let params = new HttpParams();
        if (fromAbonentId) { params = params.append('fromAbonentId', fromAbonentId.toString()); }
        if (toAbonentId) { params = params.append('toAbonentId', toAbonentId.toString()); }

        return this.get(`/delegations`, 1, { params });
    }

    deleteDelegation(delegationId: number): Observable<void> {
        return this.delete(`/delegations/${delegationId}`, 1);
    }
}
