import {GraylogRdaPatterns} from '@app/shared/entities/integrations/graylog/models';
import {LogsResponseMessage} from '@app/shared/models';
import {TranslateService} from '@ngx-translate/core';

export class GraylogRdaLogsParser {

  static NETWORK_UPDATE(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(GraylogRdaPatterns.NETWORK_UPDATE);
    const parsedItems: string[] = [];

    if (!messageItems?.length) {
      return null;
    }

    const startIndex = message.indexOf(messageItems[0]);
    const cuttedMessage = message.substring(startIndex);

    const updatedItems: string[] = cuttedMessage.match(/[\d]+:[\d]+:[\d]+:[\d]+/g);

    let description = '';

    if (updatedItems?.length > 0) {
      updatedItems.forEach((updatedItem: string) => {
        const splittedItem: string[] = updatedItem.split(':');

        if (splittedItem?.length !== 4) {
          return;
        }

        const physicalTube = Number.parseInt(splittedItem[2], 10) === 0
          ? translate.instant('shared.entities.integrations.graylog.rda.logs.network_update.description.physical.yes')
          : translate.instant('shared.entities.integrations.graylog.rda.logs.network_update.description.physical.no')
        ;

        let blockedFlat = translate.instant('shared.entities.integrations.graylog.rda.logs.network_update.description.blocked.0');
        switch (Number.parseInt(splittedItem[3], 10)) {
          case 1:
            blockedFlat = translate.instant('shared.entities.integrations.graylog.rda.logs.network_update.description.blocked.1');
            break;
          case 2:
            blockedFlat = translate.instant('shared.entities.integrations.graylog.rda.logs.network_update.description.blocked.2');
            break;
          case 3:
            blockedFlat = translate.instant('shared.entities.integrations.graylog.rda.logs.network_update.description.blocked.3');
            break;
        }

        const parsedItem = translate.instant('shared.entities.integrations.graylog.rda.logs.network_update.description.text', {
          actual: splittedItem[1],
          call: splittedItem[0],
          physical: physicalTube,
          blocked: blockedFlat
        });

        parsedItems.push(parsedItem);
      });

      description = parsedItems.join('\n');
    }

    return {
      text: translate.instant('shared.entities.integrations.graylog.rda.logs.network_update.text'),
      description
    };
  }

  static INTERCOM_DIAL(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(GraylogRdaPatterns.INTERCOM_DIAL);

    if (messageItems?.length !== 2) {
      return;
    }

    let description: string;
    if (message.indexOf('another call in progres, dropped') !== -1) {
      description = translate.instant('shared.entities.integrations.graylog.rda.logs.intercom_dial.description.0');
    } else if (message.indexOf('missing or wrong formatted flags parameter (disableReason)') !== -1) {
      description = translate.instant('shared.entities.integrations.graylog.rda.logs.intercom_dial.description.1');
    } else if (message.indexOf('known, virtual call is disabled, ignored') !== -1) {
      description = translate.instant('shared.entities.integrations.graylog.rda.logs.intercom_dial.description.2');
    } else if (message.indexOf('known enabled nophone') !== -1) {
      description = translate.instant('shared.entities.integrations.graylog.rda.logs.intercom_dial.description.3');
    } else if (message.indexOf('known enabled hasphone') !== -1) {
      description = translate.instant('shared.entities.integrations.graylog.rda.logs.intercom_dial.description.4');
    } else if (message.indexOf('unknown ignored') !== -1) {
      description = translate.instant('shared.entities.integrations.graylog.rda.logs.intercom_dial.description.5');
    } else {
      description = message;
    }

    return {
      text: translate.instant('shared.entities.integrations.graylog.rda.logs.intercom_dial.text', {
        text: messageItems[1]
      }),
      description
    };
  }

  static NETWORK_CALL_SETUP(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(GraylogRdaPatterns.NETWORK_CALL_SETUP);
    if (!messageItems?.length) {
      return null;
    }

    const startIndex = message.indexOf(messageItems[0]);
    const cuttedMessage = message.substring(startIndex + messageItems[0].length + 1);

    const messageParams: string[] = cuttedMessage.match(/([\d\w]+)/g);

    if (!messageParams?.length) {
      return null;
    }

    let dialedMessage = '';
    if (messageParams.length === 3) {
      dialedMessage = translate.instant('shared.entities.integrations.graylog.rda.logs.network_call_setup.description.dialed', {
        text: messageParams[2]
      });
    }

    return {
      text: translate.instant('shared.entities.integrations.graylog.rda.logs.network_call_setup.text', {
        text: messageParams[0]
      }),
      description: translate.instant('shared.entities.integrations.graylog.rda.logs.network_call_setup.description.text', {
        text: messageParams[1]
      }) + dialedMessage
    };
  }

  static INTERCOM_PICKUP(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(GraylogRdaPatterns.INTERCOM_PICKUP);
    if (!messageItems) {
      return null;
    }

    let description = '';
    const startIndex = message.indexOf(messageItems[0]);
    const cuttedMessage = message.substring(startIndex + messageItems[0].length + 1);

    if (cuttedMessage.indexOf('ignored, no call in progress') !== -1) {
      description = '';
    } else if (cuttedMessage.indexOf('accepted call released') !== -1) {
      description = translate.instant('shared.entities.integrations.graylog.rda.logs.intercom_pickup.description');
    }

    return {
      text: translate.instant('shared.entities.integrations.graylog.rda.logs.intercom_pickup.text'),
      description
    };
  }

  static INTERCOM_DROP(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(GraylogRdaPatterns.INTERCOM_DROP);

    if (!messageItems) {
      return null;
    }

    let description = '';
    const startIndex = message.indexOf(messageItems[0]);
    const cuttedMessage = message.substring(startIndex + messageItems[0].length + 1);

    if (cuttedMessage.indexOf('ignored no call in progress') !== -1) {
      description = '';
    } else {
      const cuttedMessageMatch = cuttedMessage.match(/accepted call ([\w]+) released/);

      if (cuttedMessageMatch?.length === 2) {
        description = translate.instant('shared.entities.integrations.graylog.rda.logs.intercom_drop.description', {
          text: cuttedMessageMatch[1]
        });
      }
    }

    return {
      text: translate.instant('shared.entities.integrations.graylog.rda.logs.intercom_drop.text'),
      description
    };
  }

  static NETWORK_RELEASE_RECEIVED(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(GraylogRdaPatterns.NETWORK_RELEASE_RECEIVED);
    if (messageItems?.length !== 3) {
      return null;
    }

    let description = '';
    const startIndex = message.indexOf(messageItems[0]);
    const cuttedMessage = message.substring(startIndex + messageItems[0].length + 1);

    if (cuttedMessage.indexOf('on connected') !== -1) {
      description = translate.instant('shared.entities.integrations.graylog.rda.logs.network_release_received.description.0');
    } else if (cuttedMessage.indexOf('on alerted') !== -1) {
      description = translate.instant('shared.entities.integrations.graylog.rda.logs.network_release_received.description.1');
    }

    description += translate.instant('shared.entities.integrations.graylog.rda.logs.network_release_received.description.2', {
      text: messageItems[1]
    });

    return {
      text: translate.instant('shared.entities.integrations.graylog.rda.logs.network_release_received.text'),
      description
    };
  }

  static NETWORK_CONNECT_RECEIVED(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(GraylogRdaPatterns.NETWORK_CONNECT_RECEIVED);
    if (messageItems?.length !== 5) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.graylog.rda.logs.network_connect_received.text'),
      description: translate.instant('shared.entities.integrations.graylog.rda.logs.network_connect_received.description', {
        id: messageItems[1],
        ip: messageItems[2],
        port: messageItems[3],
        settings: messageItems[4]
      })
    };
  }

  static NETWORK_ACTIVATE_RECEIVED(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(GraylogRdaPatterns.NETWORK_ACTIVATE_RECEIVED);
    if (messageItems?.length !== 3) {
      return null;
    }

    const description = translate.instant('shared.entities.integrations.graylog.rda.logs.network_activate_received.description', {
      id: messageItems[1],
      command: messageItems[2]
    });

    const command = messageItems[2];

    if (command === 'opendoor') {
      return {
        text: translate.instant('shared.entities.integrations.graylog.rda.logs.network_activate_received.text.0'),
        description
      };
    }

    const commandMatch: RegExpMatchArray = command.match(/openrel(\d{1,2})/);

    if (commandMatch?.length === 2) {
      return {
        text: translate.instant('shared.entities.integrations.graylog.rda.logs.network_activate_received.text.1', {
          relay: commandMatch[1]
        }),
        description
      };
    } else {
      return {
        text: translate.instant('shared.entities.integrations.graylog.rda.logs.network_activate_received.text.1', {
          relay: command
        }),
        description
      };
    }
  }

  static NETWORK_VIDEOSOURCES_RECEIVED(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(GraylogRdaPatterns.NETWORK_VIDEOSOURCES_RECEIVED);
    if (!messageItems) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.graylog.rda.logs.network_video_sources_received.text'),
      description: ''
    };
  }

  static NETWORK_CONFIGURATION_RECEIVED(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(GraylogRdaPatterns.NETWORK_CONFIGURATION_RECEIVED);
    if (messageItems?.length !== 2) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.graylog.rda.logs.network_configuration_received.text'),
      description: `${messageItems[1]}`
    };
  }

  static NETWORK_ACTIVATED_SENT(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(GraylogRdaPatterns.NETWORK_ACTIVATED_SENT);

    if (messageItems?.length !== 3) {
      return null;
    }

    const description = translate.instant('shared.entities.integrations.graylog.rda.logs.network_activated_sent.description', {
      id: messageItems[1],
      command: messageItems[2]
    });

    const command = messageItems[2];

    if (command === 'opendoor_complete') {
      return {
        text: translate.instant('shared.entities.integrations.graylog.rda.logs.network_activated_sent.text.0'),
        description
      };
    }

    let text = '';
    const commandMatch: RegExpMatchArray = command.match(/openrel(\d{1,2})/);

    if (commandMatch?.length === 2) {
      const relay: number = Number.parseInt(commandMatch[1], 10);

      if (relay >= 0 && relay <= 31) {
        if (command.indexOf('complete') !== -1) {
          text = translate.instant('shared.entities.integrations.graylog.rda.logs.network_activated_sent.text.1', {
            relay: commandMatch[1]
          });
        } else if (command.indexOf('failed') !== -1) {
          text = translate.instant('shared.entities.integrations.graylog.rda.logs.network_activated_sent.text.2', {
            relay: commandMatch[1]
          });
        }
      } else {
        text = translate.instant('shared.entities.integrations.graylog.rda.logs.network_activated_sent.text.2', {
          relay: commandMatch[1]
        });
      }

      return {text, description};
    }

    if (command.match(/(failed|complete)/)?.length) {
      if (command.indexOf('complete') !== -1) {
        text = translate.instant('shared.entities.integrations.graylog.rda.logs.network_activated_sent.text.4');
      } else if (command.indexOf('failed') !== -1) {
        text = translate.instant('shared.entities.integrations.graylog.rda.logs.network_activated_sent.text.5');
      }

      return {text, description};
    }

    return {
      text: translate.instant('shared.entities.integrations.graylog.rda.logs.network_activated_sent.text.6'),
      description
    };
  }

  static NETWORK_CONNECTION_CLOSED(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(GraylogRdaPatterns.NETWORK_CONNECTION_CLOSED);
    if (!messageItems?.length) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.graylog.rda.logs.network_connection_closed.text'),
      description: ''
    };
  }

  static NETWORK_CONNECTING(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(GraylogRdaPatterns.NETWORK_CONNECTING);
    if (messageItems?.length !== 3) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.graylog.rda.logs.network_connecting.text'),
      description: translate.instant('shared.entities.integrations.graylog.rda.logs.network_connecting.description', {
        address: messageItems[1],
        port: messageItems[2]
      })
    };
  }

  static NETWORK_CONNECTED(message: string, translate: TranslateService): LogsResponseMessage {
    const messageItems: RegExpMatchArray = message.match(GraylogRdaPatterns.NETWORK_CONNECTED);
    if (messageItems?.length !== 4) {
      return null;
    }

    return {
      text: translate.instant('shared.entities.integrations.graylog.rda.logs.network_connected.text'),
      description: translate.instant('shared.entities.integrations.graylog.rda.logs.network_connected.description', {
        ip: messageItems[1],
        port: messageItems[2],
        local: messageItems[3]
      })
    };
  }
}
