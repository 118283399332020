<div class="table-page-container">
  <mat-card>
    <mat-card-content>
        <mat-form-field appearance="outline" class="none-padding">
          <input
            matInput
            placeholder="{{ 'abonents.page.placeholder.search' | translate }}"
            [formControl]="filterInput"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false" />
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <div class="navbar">
          <app-navbar
            [links]="links"
            [active]="activeLink"
            (select)="onChangeTab($event.idx)"></app-navbar>
          <div
            *ngIf="resolution.isTablet"
            class="navbar-sort-icon">
            <ng-container *ngTemplateOutlet="sortIcon"></ng-container>
          </div>
        </div>

        <hr />
        <div *ngIf="loading" class="table-page-container__loaders__wrapper">
          <p-skeleton width="100%" height="15rem"></p-skeleton>
          <p-skeleton width="100%" height="4rem"></p-skeleton>
        </div>

        <div [hidden]="loading">
          <p-table
          [value]="(abonents$ | async).data"
          [customSort]="true"
          *ngIf="(abonents$ | async)?.totalCount > 0; else emptyData">
          <ng-template pTemplate="header">
            <tr *ngIf="!resolution.isTablet">
              <th>
                {{ 'abonents.page.field.phone' | translate }}
              </th>
              <th>
                {{ 'abonents.page.field.status' | translate }}
              </th>
              <th>
                {{ 'abonents.page.field.date' | translate }}
                <div class="th-sort">
                  <ng-container *ngTemplateOutlet="sortIcon"></ng-container>
                </div>
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-abonent>
            <tr
              [routerLink]="'../subscriber/' + abonent.id"
              [queryParams]="{
              query: filterInput.value,
              page: paginator.pageIndex,
              phone: abonent.phone?.toString() || abonent.id,
              status: activeLink || savedActiveLink,
              type: 'abonents'
            }">
              <td>
              <span class="text-grey" *ngIf="resolution.isTablet">
                {{ 'abonents.page.field.phone' | translate }}
              </span>
                <span>{{ abonent.phone?.toString() | phone }}</span>
              </td>
              <td>
              <span class="text-grey" *ngIf="resolution.isTablet">
               {{ 'abonents.page.field.status' | translate }}
              </span>

                <p-tag
                  [rounded]="true"
                  severity="{{ abonent.resolved ? 'success' : 'danger' }}"
                  [value]="
                  abonent.resolved
                    ? ('abonents.page.enum.resolved.processed' | translate)
                    : ('abonents.page.enum.resolved.not_processed' | translate)
                "></p-tag>
              </td>
              <td>
              <span class="text-grey" *ngIf="resolution.isTablet">
                {{ 'abonents.page.field.date' | translate }}
              </span>
                <span>
                {{ abonent.supportRequestDate | date : "dd.MM.yyyy HH:mm:ss" }}
              </span>
              </td>
              <td>
                <span></span>
                <p-button
                  icon="pi pi-angle-right"
                  styleClass="p-button-rounded p-button-link p-button-text"></p-button>
              </td>
            </tr>
          </ng-template>
        </p-table>
        </div>

        <ng-template #emptyData>
          <app-empty-data></app-empty-data>
        </ng-template>

      <div [hidden]="loading">
        <mat-paginator [length]="(abonents$ | async).totalCount" [pageSize]="pageSize"
                       [hidePageSize]="true" [showFirstLastButtons]="true"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #sortIcon>
  <div
    [ngSwitch]="sort"
    class="sort-icon">
    <i
      *ngSwitchCase="'asc'"
      (click)="onClickSort('desc')"
      class="pi pi-sort-amount-up-alt"></i>
    <i
      *ngSwitchCase="'desc'"
      (click)="onClickSort('asc')"
      class="pi pi-sort-amount-down-alt"></i>
    <i
      *ngSwitchDefault
      (click)="onClickSort('asc')"
      class="pi pi-sort-alt"></i>
  </div>
</ng-template>
