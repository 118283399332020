<div class="popup component">
  <app-info-block>
    <ng-container info-block-text>
      {{ 'shared.intercom.panels.delete.content.info.0' | translate }}
      <strong *ngIf="intercomPanel.location?.name">"{{ intercomPanel.location.name }}"</strong>
      {{ 'shared.intercom.panels.delete.content.info.1' | translate }}
    </ng-container>
  </app-info-block>

  <div class="popup-actions">
    <button
      color="error"
      [disabled]="loading"
      mat-raised-button
      (click)="onDelete()"
      class="popup-actions__button"
    >
      {{ 'shared.intercom.panels.delete.content.button.delete' | translate }}
    </button>
  </div>
</div>
