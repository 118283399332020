import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackbarService } from '@app/shared/components';
import {
  LocalStorageCompanyKey,
  LocalStorageHelper
} from '@app/shared/entities';
import { CompanyApiService } from '@app/shared/entities/rd';
import { parseError } from '@app/shared/helpers';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { forkJoin, Observable } from 'rxjs';
import { finalize, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  CompanyDataResponse,
  companyPageInitialState,
  CompanyPageState,
  UpdateCompanyRequest
} from './company-page.state';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class CompanyPageStore extends ComponentStore<CompanyPageState> {
  public readonly company$: Observable<Partial<CompanyDataResponse> | null> =
    this.select((state: CompanyPageState) => state.company);

  public readonly loading$: Observable<boolean> = this.select(
    (state: CompanyPageState) => state.loading
  );

  constructor(
    private companyApiService: CompanyApiService,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {
    super(companyPageInitialState);
  }

  readonly loadCompanyData = this.effect(($) => {
    return $.pipe(
      tap(() => this.setLoading(true)),
      switchMap(() =>
        this.companyApiService
          .getCompanyByIdV2(
            LocalStorageHelper.getItem(LocalStorageCompanyKey.ID)
          )
          .pipe(
            tapResponse(
              (company) => {
                this.setLoading(false);
                this.setCompanyState(company);
              },
              (error: HttpErrorResponse) => {
                this.snackbar.showMessage(
                  this.translate.instant('company.message.load.failed', {
                    text: parseError(error)
                  })
                );
              }
            ),
            finalize(() => this.setLoading(false))
          )
      )
    );
  });

  readonly updateCompanyInfo = this.effect(
    (options$: Observable<UpdateCompanyRequest>) =>
      options$.pipe(
        tap(() => {
          this.setLoading(true);
        }),
        withLatestFrom(this.company$),
        switchMap(
          ([payload, company]: [UpdateCompanyRequest, CompanyDataResponse]) => {
            const apiCallsArr = [
              this.companyApiService.updateCompanyInfo(company.id, payload)
            ];
            if (payload.image?.name) {
              apiCallsArr.push(
                this.companyApiService.updateBanner({
                  shortName: company.shortName,
                  image: payload.image
                })
              );
            }

            return forkJoin(apiCallsArr).pipe(
              tapResponse(
                () => {
                  const obj = { ...company, ...payload.company };

                  this.setLoading(false);
                  this.setCompanyState(obj);
                  if (payload.image) {
                    this.loadCompanyData();
                  }
                },
                (error: HttpErrorResponse) => {
                  this.snackbar.showMessage(
                    this.translate.instant('company.message.update.failed', {
                      text: parseError(error)
                    })
                  );
                }
              ),
              finalize(() => this.setLoading(false))
            );
          }
        )
      )
  );

  readonly updateWithoutLoader = this.effect(
    (options$: Observable<UpdateCompanyRequest>) =>
      options$.pipe(
        withLatestFrom(this.company$),
        switchMap(
          ([payload, company]: [UpdateCompanyRequest, CompanyDataResponse]) =>
            this.companyApiService.updateCompanyInfo(company.id, payload).pipe(
              tapResponse(
                () => {
                  const obj = { ...company, ...payload.company };
                  this.setCompanyState(obj);
                },
                (error: HttpErrorResponse) => {
                  this.snackbar.showMessage(
                    this.translate.instant('company.message.update.failed', {
                      text: parseError(error)
                    })
                  );
                }
              )
            )
        )
      )
  );

  private readonly setCompanyState = this.updater(
    (state, company: CompanyDataResponse) => ({
      ...state,
      company
    })
  );

  private readonly setLoading = this.updater(
    (state: CompanyPageState, loading: boolean) => ({
      ...state,
      loading
    })
  );
}
