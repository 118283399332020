import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RdaResponse, RdaUpdateRequest } from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';
import { Address, TranslationTuningRequest, TranslationTuningResponse } from '@app/shared/models';
import { ServiceFacade } from '@app/views/services/store';

@Component({
  selector: 'app-software-intercom-wizard-content-translations',
  templateUrl: './software-intercom-wizard-content-translations.component.html',
  styleUrls: ['./software-intercom-wizard-content-translations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomWizardContentTranslationsComponent implements OnChanges {
  @Input() loading: boolean;
  @Input() intercom: RdaResponse;
  @Input() set addresses(addresses: Address[]) {
    this.address = addresses?.[0];
  }
  @Input() translationTuningsDict: Dictionary<TranslationTuningResponse[]>;
  address: Address;
  translationTunings: TranslationTuningResponse[];

  constructor(
    private serviceFacade: ServiceFacade
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['intercom']?.currentValue && changes['translationTuningsDict']?.currentValue) {
      this.translationTunings = this.translationTuningsDict[this.intercom?.uid];
    }
  }

  onSaveTranslation(translation: number) {
    const request: RdaUpdateRequest = { id: this.intercom?.id, translation };
    this.serviceFacade.updateServiceRda(this.intercom?.uid, request);
  }

  onAddTranslationTuning(request: TranslationTuningRequest) {
    this.serviceFacade.addTranslationTuning(this.intercom?.uid, request);
  }

  onDeleteTranslationTuning(translationTuningId: number) {
    this.serviceFacade.deleteTranslationTuning(this.intercom?.uid, translationTuningId);
  }
}
