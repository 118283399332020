import { ServicesAdditionalFlatRangesActions } from './services-additional-flat-ranges.actions';
import { ServicesCamerasActions } from './services-cameras.actions';
import { ServicesConnectionsActions } from './services-connections.actions';
import { ServicesEntrancesActions } from './services-entrances.actions';
import { ServicesGeneralActions } from './services-general.actions';
import { ServicesIntercomKeysActions } from './services-intercom-keys.actions';
import { ServicesIntercomPanelsActions } from './services-intercom-panel.actions';
import { ServicesIntercomsActions } from './services-intercoms.actions';
import { ServicesLocationsActions } from './services-locations.actions';
import { ServicesLogsActions } from './services-logs.actions';
import { ServicesPbxOnRdaActions } from './services-pbx-on-rda.actions';
import { ServicesTranslationTuningsActions } from './services-translation-tunings.actions';

export type ServicesActions =
    | ServicesAdditionalFlatRangesActions
    | ServicesCamerasActions
    | ServicesConnectionsActions
    | ServicesEntrancesActions
    | ServicesGeneralActions
    | ServicesIntercomKeysActions
    | ServicesIntercomsActions
    | ServicesIntercomPanelsActions
    | ServicesLocationsActions
    | ServicesLogsActions
    | ServicesPbxOnRdaActions
    | ServicesTranslationTuningsActions;
