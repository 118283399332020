import { Directive, ElementRef, Input, OnChanges, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';
import { PhonesUtils, PhoneValidatorModel, PrefixType } from '@app/shared/entities/rd';

@Directive({
  selector: '[appPhoneValidation]',
  providers: [{ provide: NG_VALIDATORS, useExisting: PhoneValidationDirective, multi: true }]
})
export class PhoneValidationDirective implements OnInit, OnChanges {
  @Input() prefix: PrefixType;
  private validator: ValidatorFn;
  private phoneValidatorModel: PhoneValidatorModel;

  constructor(private el: ElementRef) { }

  ngOnInit() {
    this.phoneValidatorModel = PhonesUtils.initPhoneValidator(this.prefix);
    this.validator = this.phoneValidator();
  }

  ngOnChanges(changes: SimpleChanges) {
    const change: SimpleChange = changes['prefix'];
    if (change.currentValue && change.currentValue !== change.previousValue) {
      this.phoneValidatorModel = PhonesUtils.initPhoneValidator(this.prefix);
    }
  }

  validate(control: UntypedFormControl): ValidationErrors {
    return this.validator(control);
  }

  private phoneValidator(): ValidatorFn {
    const returnObj = { emailvalidator: { valid: false } };

    return (control: UntypedFormControl) => {
      let text: string = control.value;

      let isValid: boolean;
      if (this.phoneValidatorModel) {
        isValid = PhonesUtils.validatePhone(text, this.phoneValidatorModel.regTemplate);
      } else {
        isValid = true;
      }

      if (!isValid && text && text !== '') {
        text = this.removeInvalidCharacters(text);
        text = this.prepareNumberOfString(text);

        isValid = PhonesUtils.validatePhone(text, this.phoneValidatorModel.regTemplate);
        isValid ? control.setValue(text) : (this.el.nativeElement.value = text);
      }

      return isValid ? null : returnObj;
    };
  }

  private prepareNumberOfString(text: string): string {
    if (text.length > 0) {
      text = '(' + text.substr(0);
    }
    if (text.length > this.phoneValidatorModel.prepareIndices[0]) {
      text = text.substr(0, this.phoneValidatorModel.prepareIndices[0]) + ')' + text.substr(this.phoneValidatorModel.prepareIndices[0]);
    }

    if (text.length > this.phoneValidatorModel.prepareIndices[1]) {
      text = text.substr(0, this.phoneValidatorModel.prepareIndices[1]) + '-' + text.substr(this.phoneValidatorModel.prepareIndices[1]);
    }

    if (text.length > this.phoneValidatorModel.prepareIndices[2] && this.phoneValidatorModel.prepareIndices[2] !== null) {
      text = text.substr(0, this.phoneValidatorModel.prepareIndices[2]) + '-' + text.substr(this.phoneValidatorModel.prepareIndices[2]);
    }

    if (text.length > this.phoneValidatorModel.phoneNumberLength) {
      text = text.substr(0, this.phoneValidatorModel.phoneNumberLength);
    }

    return text;
  }

  private removeInvalidCharacters(text: string): string {
    return text.replace(/[^0-9]/g, '');
  }
}
