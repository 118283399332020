import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { VideoPlayerModule } from '@app/shared/components/video-player/video-player.module';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CameraThumbnailComponent } from './camera-thumbnail.component';
import { CameraVideoPopupComponent } from './camera-video-popup/camera-video-popup.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

const components = [
    CameraThumbnailComponent,
    CameraVideoPopupComponent
];

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        LazyLoadImageModule,
        VideoPlayerModule,
        SharedPipesModule,
        NgxTranslateModule,
    ],
    declarations: components,
    exports: components
})
export class CameraThumbnailModule { }
