<div class="list-statuses-cell__wrapper">
  <ng-container *ngIf="renderInfo.active !== 0 || renderInfo.inactive !== 0">
    <p-chip *ngIf="renderInfo?.active > 0" class="list-statuses-cell--chip-active">
          <span *ngIf="!useSingleValue" class="list-statuses-cell--chip--text">
            {{'shared.ui.twsp_cell_components.online' | translate}}: {{renderInfo.active}}
          </span>
      <span *ngIf="useSingleValue" class="list-statuses-cell--chip--text">
            {{'shared.ui.twsp_cell_components.online' | translate}}
          </span>
    </p-chip>
    <p-chip *ngIf="renderInfo?.inactive > 0" class="list-statuses-cell--chip-inactive">
          <span *ngIf="!useSingleValue" class="list-statuses-cell--chip--text">
            {{'shared.ui.twsp_cell_components.offline' | translate}}: {{renderInfo.inactive}}
          </span>
      <span *ngIf="useSingleValue" class="list-statuses-cell--chip--text">
            {{'shared.ui.twsp_cell_components.offline' | translate}}
          </span>
    </p-chip>
  </ng-container>
</div>
