import { Component, Output, EventEmitter, Input } from '@angular/core';

import { ServicesTypes } from '@app/shared/models';
import { ServiceFlatsFilters } from '../../service-flats/models';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-service-filter-item',
  templateUrl: './service-filter-item.component.html',
  styleUrls: ['./service-filter-item.component.scss']
})
export class ServiceFilterItemComponent {
  @Input() serviceType: ServicesTypes;
  @Input() flatsFilters: ServiceFlatsFilters;

  @Output() filterByActiveServiceType: EventEmitter<ServicesTypes> = new EventEmitter();
  @Output() filterByStoppedServiceType: EventEmitter<ServicesTypes> = new EventEmitter();
  @Output() filterByBlockedServiceType: EventEmitter<ServicesTypes> = new EventEmitter();

  constructor(
    private translate: TranslateService
  ) {}

  public onFilterByActiveServiceType(): void {
    this.filterByActiveServiceType.emit(this.serviceType);
  }

  public onFilterByStoppedServiceType(): void {
    this.filterByStoppedServiceType.emit(this.serviceType);
  }
  public onFilterByBlockedServiceType(): void {
    this.filterByBlockedServiceType.emit(this.serviceType);
  }

  public get serviceName(): string {
    switch (this.serviceType) {
      case ServicesTypes.GATE:
        return this.translate.instant('service.filter.item.enum.type.gate');
      case ServicesTypes.HARDWARE_INTERCOM:
        return this.translate.instant('service.filter.item.enum.type.hardware_intercom');
      case ServicesTypes.SOFTWARE_INTERCOM:
        return this.translate.instant('service.filter.item.enum.type.software_intercom');
      case ServicesTypes.VIDEO_SURVEILLANCE:
        return this.translate.instant('service.filter.item.enum.type.video_surveillance');
      default:
        throw new Error('Wrong serviceType');
    }
  }
}
