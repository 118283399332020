import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject } from 'rxjs';
import { CreateServicePopupBody, CreateServicePopupComponent, CreateServicePopupResponse, DeleteServicePopupComponent, DeleteServicePopupModel } from '@app/shared/components';
import { LoaderService, PreviosQueryType, RouteDetectionService } from '@app/shared/entities/common';
import { Constants } from '@app/shared/helpers';
import { ServicesTypes, PageData } from '@app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData, PaginatedPageWrapperComponent } from '@app/shared/ui';
import { SoftwareIntercomsPageModel } from '@app/views';
import { SoftwareIntercomsPageFacade } from '@app/views';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-software-intercoms-page',
  templateUrl: './software-intercoms-page.component.html',
  styleUrls: ['./software-intercoms-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomsPageComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject();
  protected pageData!: PageData;
  readonly previosQueryType: typeof PreviosQueryType = PreviosQueryType;
  readonly displayedColumns: string[] = ['name', 'rdasState', 'camerasStates', 'actions'];

  @ViewChild(PaginatedPageWrapperComponent, { static: true }) paginatedPageWrapper: PaginatedPageWrapperComponent;
  pageData$: Observable<PageData> = this.softwareIntercomsPageFacade.pageData$;
  softwareIntercoms$: Observable<SoftwareIntercomsPageModel[]> = this.softwareIntercomsPageFacade.softwareIntercoms$;

  loading$: Observable<boolean> = this.softwareIntercomsPageFacade.loading$;
  xlWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.XL_W_DOWN);

  constructor(
    private dialog: MatDialog,
    private loader: LoaderService,
    private resolution: ResolutionService,
    private routeDetection: RouteDetectionService,
    private softwareIntercomsPageFacade: SoftwareIntercomsPageFacade,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.loader.loaderState = { state: false };
    this.prepareQueryParams();
    this.loadServices();

    this.pageData$.pipe(takeUntil(this.onDestroy$))
      .subscribe(pageData => this.pageData = pageData);
  }

  onFilterInputChanged() {
    this.paginatedPageWrapper.paginator.pageIndex = 0;
    this.loadServices();
  }

  onPageChanged() {
    this.loadServices();
  }

  onCreate() {
    const data: DialogWrapperData<CreateServicePopupBody, CreateServicePopupResponse> = {
      title: this.translate.instant('software.intercoms.title.create'),
      componentName: 'CreateSoftwareIntercom',
      body: { serviceType: ServicesTypes.SOFTWARE_INTERCOM },
      submit: (event: CreateServicePopupResponse) =>
        this.softwareIntercomsPageFacade.create(
          event.request,
          {
            filterValue: this.paginatedPageWrapper.filterInput.value,
            pageIndex: this.paginatedPageWrapper.paginator.pageIndex
          }
        )
    };

    this.dialog.open(CreateServicePopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  onDelete(serviceId: number, serviceName: string) {
    const pageIndex = this.pageData.numberOfElements === 1 ? 
      this.paginatedPageWrapper.paginator.pageIndex - 1 : this.paginatedPageWrapper.paginator.pageIndex;

    const data: DialogWrapperData<DeleteServicePopupModel, null> = {
      title: this.translate.instant('software.intercoms.title.delete'),
      componentName: 'DeleteSoftwareIntercom',
      body: { serviceType: ServicesTypes.SOFTWARE_INTERCOM, serviceName: serviceName },
      submit: () =>
        this.softwareIntercomsPageFacade.delete(
          serviceId,
          {
            pageIndex: pageIndex,
            pageSize: this.paginatedPageWrapper.paginator.pageSize,
            filterValue: this.paginatedPageWrapper.filterInput.value
          }
        )
    };

    this.dialog.open(DeleteServicePopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ? '100%' : '600px',
      data
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next('');
    this.onDestroy$.complete();
  }

  private prepareQueryParams() {
    const queryParams = this.routeDetection.getQueryParams();

    if (queryParams.type !== PreviosQueryType.SOFTWARE_INTERCOMS) {
      return;
    }

    this.paginatedPageWrapper.paginator.pageIndex = queryParams.page ?? 0;

    if (queryParams.query) {
      this.paginatedPageWrapper.filterInput.setValue(queryParams.query, { emitEvent: false });
    }
  }

  private loadServices() {
    this.softwareIntercomsPageFacade.getPage(
      this.paginatedPageWrapper.paginator.pageIndex,
      this.paginatedPageWrapper.paginator.pageSize,
      this.paginatedPageWrapper.filterInput.value
    );
  }
}
