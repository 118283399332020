import { FlatRange } from '@app/shared/models';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'arrayMap',
  pure: false
})
export class ArrayMapPipe implements PipeTransform {
  transform(inputs: FlatRange[], pattern: string): any {
    let string = '';

    const ranges: [number, number][] = inputs.map((range: FlatRange) => [range.flatStart, range.flatEnd]);

    ranges.forEach((range, idx) => {
      string += pattern.replace('{0}', range[0].toString()).replace('{1}', range[1].toString());
      string += (idx === inputs.length - 1 ? '' : ', ');
    });

    return string;
  }
}
