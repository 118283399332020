import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProtocolTypes } from '@app/shared/entities/rd';
import { intercomKeyRecords, IntercomKeyType, KeysResponse } from '@app/shared/entities/rd/keys/models';

@Component({
  selector: 'app-intercom-keys-container-item',
  templateUrl: './intercom-keys-container-item.component.html',
  styleUrls: ['./intercom-keys-container-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntercomKeysContainerItemComponent {
  readonly intercomKeyRecords = intercomKeyRecords;
  readonly intercomKeyType = IntercomKeyType;

  @Input() key!: KeysResponse;
  @Input() readonly: boolean;

  @Output() private edit: EventEmitter<{ key: KeysResponse }> = new EventEmitter();
  @Output() private delete: EventEmitter<{ key: KeysResponse }> = new EventEmitter();

  basIp() {
    return this.key.adapterInfo.protocolNumber === ProtocolTypes.BAS_IP;
  }

  sputnikIp() {
    return this.key.adapterInfo.protocolNumber === ProtocolTypes.Sputnik;
  }

  onEdit() {
    this.edit.emit({ key: this.key });
  }

  onDelete() {
    this.delete.emit({ key: this.key });
  }
}
