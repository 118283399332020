<mat-selection-list
  class="intercoms-list-content bordered-list none-margin"
  (selectionChange)="onSelectIntercom($event.option.value)"
>
  <div mat-subheader>
    {{ 'shared.rda.intercom.list.content.selected_adapters' | translate }}:&nbsp;
    {{ ( intercoms$ | async | filter:filterFunc)?.length }}
  </div>

  <ng-container>
    <mat-list-option
      [disabled]="loading"
      [value]="selectedAll$ | async"
      [selected]="selectedAll$ | async"
    >
      {{
        (selectedAll$ | async)
          ? ('shared.rda.intercom.list.content.deselect_all' | translate)
          : ('shared.rda.intercom.list.content.select_all' | translate)
      }}
    </mat-list-option>
  </ng-container>

  <mat-list-option
    [disabled]="loading"
    [value]="intercom.id"
    [selected]="!!intercom.selected"
    *ngFor="let intercom of intercoms$ | async"
  >
    <div class="intercoms-list-content-option">
      <p class="intercoms-list-content-option__phone">
        {{ intercom.uid }}
      </p>
      <p class="intercoms-list-content-option__address">
        {{ intercom.intercomTypeName }}
      </p>
    </div>
  </mat-list-option>
</mat-selection-list>
