<ng-container
  *ngIf="loading$ | async; then loader; else content"></ng-container>

<ng-template #loader>
  <app-component-loader
    *ngIf="loading$ | async"
    [sizePx]="60"></app-component-loader>
</ng-template>

<ng-template #content>
  <div class="management">
    <div class="management-action">
      <div class="management-action-roles">
        <div class="management-action-search">
          <span class="p-input-icon-left ml-auto">
            <i class="pi pi-search"></i>
            <input
              pInputText
              (input)="onSearchByEmail($event)"
              type="text"
              placeholder="{{ 'company.roles.placeholder.email' | translate }}"
            />
            </span>
        </div>
        <p-multiSelect
          [options]="rolesComponentStoreService.allAvailableRoles"
          [filter]="false"
          [defaultLabel]="'company.roles.label.all_roles' | translate"
          [optionLabel]="optionLabel"
          [showToggleAll]="false"
          [showHeader]="false"
          (onChange)="onRoleChange($event.value)"
        ></p-multiSelect>
      </div>
      <div class="management-action-create">
        <p-button
          label="{{ 'company.roles.button.create' | translate }}"
          class="management-action-create__button"
          styleClass="p-button-raised p-button-text"
          (onClick)="onUserCreate()"
        ></p-button>
      </div>
    </div>
    <div class="management-table">
      <app-prime-base-table
        [primeTableConfig]="tableConfig"
        [tableData]="userResponse | userResponsePipe : filteredRoles : searchingText"
        (edit)="onOpenUserProfile($event)"
        (delete)="onBlockUser($event)"
      ></app-prime-base-table>
    </div>
  </div>
</ng-template>
