import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';

@Component({
  selector: 'app-single-status-cell',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './single-status-cell.component.html',
  styleUrls: ['./single-status-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleStatusCellComponent {
  @Input() public isActive = false;
}
