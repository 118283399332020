import { Injectable, OnDestroy } from '@angular/core';
import { DialogWrapperStateHelper } from '@app/shared/ui';
import { GatesActionTypes } from '@app/views';
import { ServicesActionTypes } from '@app/views/services/store';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class GateWizardPopupService extends DialogWrapperStateHelper<'loading' | 'close' | 'loaded'> implements OnDestroy {
    constructor(
        private actions$: Actions
    ) {
        super();
        this.enableStateListeners();
    }

    ngOnDestroy() {
        this.destroy();
    }

    private enableStateListeners() {
        this.actions$
            .pipe(
                ofType(...[
                    ServicesActionTypes.AddServiceEntrance,
                    ServicesActionTypes.UpdateServiceEntrance,
                    ServicesActionTypes.UpdateServiceEntranceWithPrefix,
                    ServicesActionTypes.DeleteServiceEntrance,
                    ServicesActionTypes.AddAdditionalFlatRange,
                    ServicesActionTypes.UpdateAdditionalFlatRange,
                    ServicesActionTypes.DeleteAdditionalFlatRange,

                    ServicesActionTypes.AddTranslationTuning,
                    ServicesActionTypes.DeleteTranslationTuning,
                    ServicesActionTypes.AddServiceRda,
                    ServicesActionTypes.ChangeServiceRda,
                    ServicesActionTypes.UpdateServiceRda,
                    ServicesActionTypes.DeleteServiceRda,

                    ServicesActionTypes.AddServiceCamera,
                    ServicesActionTypes.UpdateServiceCamera,

                    GatesActionTypes.UpdateGateEntranceIntercoms,
                    GatesActionTypes.ConnectGateEntranceIntercoms,
                ]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loading');

        this.actions$
            .pipe(
                ofType(...[
                    ServicesActionTypes.AddServiceEntranceSuccess,
                    ServicesActionTypes.AddServiceRdaSuccess,
                    ServicesActionTypes.DeleteServiceEntranceSuccess,
                ]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'close');

        this.actions$
            .pipe(
                ofType(...[
                    ServicesActionTypes.AddServiceEntranceFailure,
                    ServicesActionTypes.UpdateServiceEntranceSuccess,
                    ServicesActionTypes.UpdateServiceEntranceFailure,
                    ServicesActionTypes.DeleteServiceEntranceFailure,
                    ServicesActionTypes.UpdateServiceEntranceWithPrefixSuccess,
                    ServicesActionTypes.UpdateServiceEntranceWithPrefixFailure,
                    ServicesActionTypes.AddAdditionalFlatRangeSuccess,
                    ServicesActionTypes.AddAdditionalFlatRangeFailure,
                    ServicesActionTypes.UpdateAdditionalFlatRangeSuccess,
                    ServicesActionTypes.UpdateAdditionalFlatRangeFailure,
                    ServicesActionTypes.DeleteAdditionalFlatRangeSuccess,
                    ServicesActionTypes.DeleteAdditionalFlatRangeFailure,

                    ServicesActionTypes.AddTranslationTuningFailure,
                    ServicesActionTypes.AddTranslationTuningSuccess,
                    ServicesActionTypes.DeleteTranslationTuningSuccess,
                    ServicesActionTypes.DeleteTranslationTuningFailure,
                    ServicesActionTypes.AddServiceRdaFailure,
                    ServicesActionTypes.ChangeServiceRdaSuccess,
                    ServicesActionTypes.ChangeServiceRdaFailure,
                    ServicesActionTypes.UpdateServiceRdaSuccess,
                    ServicesActionTypes.UpdateServiceRdaFailure,
                    ServicesActionTypes.DeleteServiceRdaSuccess,
                    ServicesActionTypes.DeleteServiceRdaFailure,

                    ServicesActionTypes.AddServiceCameraSuccess,
                    ServicesActionTypes.AddServiceCameraFailure,
                    ServicesActionTypes.UpdateServiceCameraSuccess,
                    ServicesActionTypes.UpdateServiceCameraFailure,

                    GatesActionTypes.UpdateGateEntranceIntercomsSuccess,
                    GatesActionTypes.UpdateGateEntranceIntercomsFailure,
                    GatesActionTypes.ConnectGateEntranceIntercomsSuccess,
                    GatesActionTypes.ConnectGateEntranceIntercomsFailure
                ]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loaded');
    }
}
