<div class="popup entrances-list-content">
  <h3>
    {{ 'services.popups.entrances.list.content.title' | translate }}
  </h3>

  <div class="entrances-list-content-data" *ngIf="addresses?.length > 0">
    <div class="entrances-list-content-data-item" *ngFor="let address of addresses; let idx = index">
      <div class="entrances-list-content-data-item-actions-block">
        <button
          mat-fab
          color="primary-inverse"
          class="entrances-list-content-data-item-actions-block__button"
          (click)="onEdit(idx)"
        >
          <mat-icon>settings</mat-icon>
        </button>

        <button
          *ngIf="addresses?.length > 1"
          mat-fab
          color="error"
          class="entrances-list-content-data-item-actions-block__button"
          (click)="onDelete(address.entrance.id)"
        >
          <mat-icon>remove</mat-icon>
        </button>
      </div>

      <div class="entrances-list-content-data-item-info">
        <p class="entrances-list-content-data-item-info__text">
          {{ address | address }}
        </p>

        <p class="entrances-list-content-data-item-info__text" *ngIf="address.entrance.prefix">
          {{ 'services.popups.entrances.list.content.prefix' | translate }}:&nbsp;
          {{ address.entrance.prefix }}
        </p>

        <p class="entrances-list-content-data-item-info__text">
          {{ 'services.popups.entrances.list.content.range' | translate }}:&nbsp;
          {{ address.entrance.flatStart }} - {{ address.entrance.flatEnd }}
        </p>

        <p class="entrances-list-content-data-item-info__text" *ngIf="address.entrance.additionalFlatRanges?.length > 0">
          {{ 'services.popups.entrances.list.content.additional_range' | translate }}:&nbsp;
          {{ address.entrance.additionalFlatRanges | arrayMap: '{0} - {1}' }}
        </p>
      </div>
    </div>
  </div>

  <div class="popup-actions">
    <button
      mat-raised-button
      color="primary-inverse"
      (click)="onAdd()"
      class="popup-actions__button mat-button-lg"
    >
      {{ 'services.popups.entrances.list.content.button.add' | translate }}
    </button>
  </div>
</div>
