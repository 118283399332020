import { Directive, Input, OnInit } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, ValidationErrors, ValidatorFn } from '@angular/forms';

@Directive({
  selector: '[appTimepicker]',
  providers: [{ provide: NG_VALIDATORS, useExisting: TimepickerDirective, multi: true }]
})
export class TimepickerDirective implements OnInit {
  @Input() min: string;
  @Input() max: string;
  private validator: ValidatorFn;

  ngOnInit() {
    this.validator = this.timeValidator();
  }

  validate(control: UntypedFormControl): ValidationErrors {
    return this.validator(control);
  }

  private timeValidator(): ValidatorFn {
    const returnObj = { emailvalidator: { valid: false } };
    return (control: UntypedFormControl) => {
      const text: string = control.value;
      const isValid: boolean = this.validateTime(text);
      return isValid ? null : returnObj;
    };
  }

  private validateTime(time: string): boolean {
    const currentTime = this.getTimeFromString(time);
    const minTime = this.getTimeFromString(this.min);
    const maxTime = this.getTimeFromString(this.max);
    return currentTime >= minTime && currentTime <= maxTime;
  }

  private getTimeFromString(time: string) {
    const splittedTime: string[] = time.split(':');
    if (splittedTime.length !== 2) {
      return null;
    }

    const minute: number = Number.parseInt(splittedTime[0], 10);
    const second: number = Number.parseInt(splittedTime[1], 10);
    if (isNaN(minute) || isNaN(second)) {
      return null;
    }

    return minute * 60 + second;
  }
}
