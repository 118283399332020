<h3 class="transation-instruction__title">
  {{ 'shared.rda.translations.editor.instruction.title' | translate }}
</h3>

<iframe
    height="290px"
    width="100%"
    title="{{ 'shared.rda.translations.editor.instruction.title' | translate }}"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    [src]="translationsTutorialVideoLink | safeUrl"
    allowfullscreen
></iframe>
