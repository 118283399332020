<div>
  <form [formGroup]="tariffGroup" (ngSubmit)="onSubmit()">
    <mat-form-field #softwareTariff appearance="outline" class="none-padding">
      <mat-label>
        {{ 'software.intercom.tariff.popup.content.tariff' | translate }}
        <ng-container [ngSwitch]="serviceType">
          <ng-container *ngSwitchCase="ServicesTypes.SOFTWARE_INTERCOM">
            {{ 'software.intercom.tariff.popup.content.software_intercom' | translate }}
          </ng-container>
          <ng-container *ngSwitchCase="ServicesTypes.GATE">
            {{ 'software.intercom.tariff.popup.content.gate' | translate }}
          </ng-container>
          <ng-container *ngSwitchCase="ServicesTypes.VIDEO_SURVEILLANCE">
            {{ 'software.intercom.tariff.popup.content.video_surveillance' | translate }}
          </ng-container>
        </ng-container>
      </mat-label>
      <input
        matInput
        type="text"
        placeholder="0.00"
        mask="0*.00"
        autocomplete="off"
        autocorrect="off"
        spellcheck="false"
        autocapitalize="off"
        min="0"
        #tariffInput
        [formControlName]="'tariff' + serviceType"
        (input)="onTariffChange($event, serviceType)"
      >
    </mat-form-field>
    <mat-form-field #hardwareTariff appearance="outline" class="none-padding" *ngIf="serviceType === ServicesTypes.SOFTWARE_INTERCOM">
      <mat-label>
        {{ 'software.intercom.tariff.popup.content.tariff_for_intercom' | translate }}
      </mat-label>
      <input
        matInput
        type="text"
        placeholder="0.00"
        mask="0*.00"
        [formControlName]="'tariff' + ServicesTypes.HARDWARE_INTERCOM"
        autocomplete="off"
        autocorrect="off"
        spellcheck="false"
        autocapitalize="off"
        min="0"
        (input)="onTariffChange($event, ServicesTypes.HARDWARE_INTERCOM)"
      >
    </mat-form-field>
    <mat-checkbox
      *ngIf="showCheckbox"
      formControlName="applyTariffForConnections"
    >
      {{ 'software.intercom.tariff.popup.content.apply_for_all_subscribers' | translate }}
    </mat-checkbox>
    <div class="popup-actions">
      <button
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="isButtonDisabled"
        class="popup-actions__button"
      >
        {{ 'software.intercom.tariff.popup.content.button.submit' | translate }}
      </button>
    </div>
  </form>
</div>
