import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  Injector,
  Type
} from '@angular/core';

@Injectable()
export class NativeDialogService {
  constructor(private componentFactoryResolver: ComponentFactoryResolver, private appRef: ApplicationRef, private injector: Injector) { }

  showDialog<TDialog, TResult>(component: Type<TDialog>, updater: (instance: TDialog, close: (res: TResult) => void) => void): Promise<TResult> {
    return new Promise<TResult>(resolve => {
      const componentRef = this.createComponentInBody(component);
      updater(componentRef.instance, (res: TResult) => {
        this.close(componentRef);
        resolve(res);
      });
    });
  }

  private close = (dialog: any): void => {
    this.removeComponentFromBody(dialog);
  }

  private removeComponentFromBody = <T>(componentRef: ComponentRef<T>): void => {
    if (componentRef) {
      componentRef.destroy();
    }
  }

  private createComponentInBody<T>(component: Type<T>, initializer?: (instance: T) => void): ComponentRef<T> {
    // 1. Create a component reference from the component
    const componentRef = this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector);

    // 1.1 set component inputs
    if (initializer) {
      initializer(componentRef.instance);
    }

    // 2. Attach component to the appRef so that it's inside the ng component tree
    this.appRef.attachView(componentRef.hostView);

    // 3. Get DOM element from component
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

    // 4. Append DOM element to the body
    document.body.appendChild(domElem);

    return componentRef;
  }
}
