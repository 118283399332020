import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared';
import { ServiceCamerasWarningComponent } from './service-cameras-warning.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        SharedPipesModule,
        NgxTranslateModule
    ],
    declarations: [ServiceCamerasWarningComponent],
    exports: [ServiceCamerasWarningComponent]
})
export class ServiceCamerasWarningModule { }
