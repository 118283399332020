<div class="popup delete-intercom-content">
  <app-info-block>
    <ng-container info-block-text>
      <span [innerHTML]="'shared.rda.delete.intercom.content.text' | translate:{ value: rdaUid } "></span>
    </ng-container>
  </app-info-block>

  <div class="popup-actions">
    <button
      color="error"
      [disabled]="loading"
      mat-raised-button
      (click)="onDelete()"
      class="popup-actions__button"
    >
      <span [innerHTML]="'shared.rda.delete.intercom.content.button.submit' | translate:{ value: rdaUid } "></span>
    </button>
  </div>
</div>
