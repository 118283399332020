import { Injectable } from '@angular/core';
import { PbxOnRdaResponse, SipAdapterUtilsService } from '@app/shared/entities/rd';
import { PbxOnRdaSearchSelectResponse } from './models';

@Injectable()
export class PbxOnRdaSearchSelectService {
    constructor(
        private sipAdapterUtilsService: SipAdapterUtilsService
    ) { }

    preparePbxOnRdasList(pbxOnRdaList: PbxOnRdaResponse[]): PbxOnRdaSearchSelectResponse[] {
        let preparedRdasResponse: PbxOnRdaSearchSelectResponse[] = [];

        preparedRdasResponse = pbxOnRdaList.map(pbxOnRda => ({
            value: pbxOnRda,
            select: {
                text: pbxOnRda.pbxOnRdaUid,
                value: pbxOnRda.pbxOnRdaUid,
                badge: this.sipAdapterUtilsService.prepareSipAdapterSocket(pbxOnRda.sipAdapter)
            }
        }));

        return preparedRdasResponse;
    }
}
