<app-dialog-wrapper
  [title]="data.title"
  [progress]="(popupService.state$ | async) === 'loading'"
  [componentName]="data.componentName"
>
  <app-service-edit-content
    (editService)="onEdit($event.request)"
    [loading]="(popupService.state$ | async) === 'loading'"
    [serviceName]="data.body.serviceName"
  ></app-service-edit-content>
</app-dialog-wrapper>