<app-bottom-sheet-wrapper
  [title]="data.title"
  [componentName]="data.componentName"
>
  <ng-container *ngIf="data.body.protocolType === ProtocolTypes.Sputnik; then sputnik; else intercom"></ng-container>
  <ng-template #sputnik>
    <app-ip-sputnik-generate-urls-container
      [isUIDGenerateMode]="true"
      [intercom]="data.body.intercom"
      (urlsGenerated)="onGenerated($event)">
    </app-ip-sputnik-generate-urls-container>
  </ng-template>
  <ng-template #intercom>
    <app-ip-intercom-generate-urls-container
      [intercom]="data.body.intercom"
      [params]="data.body.params"
      (urlsGenerated)="onGenerated($event)"
    ></app-ip-intercom-generate-urls-container>
  </ng-template>
</app-bottom-sheet-wrapper>
