import { ServiceResponse } from '@app/shared/entities/rd';
import { Address } from '@app/shared/models';
import { ServiceEntranceFlats } from '@app/views/services/components';
import { Dictionary } from '@app/shared/helpers';

export interface ServiceConnectionPopupBody {
    addresses: Address[];
    defaultService?: Pick<ServiceResponse, 'id' | 'type'>;
    flatNumber?: number;
    flatId?: number;
    noPhone?: boolean;
    companyId?: number;
    phone?: number;
    hasPhysicalTube?: boolean;
    virtualFlat?: boolean;
    servicesSelectionRequired?: boolean;
    hasPhysicalTubeDefault?: boolean;
    hasPhysicalTubeHidden?: boolean;
    abonentAndAccountLoadingForced?: boolean;
    responseMessage?: string;
    flatList?: Dictionary<ServiceEntranceFlats>;
}
