import {TitleBarComponentN} from '@app/core';

export interface SnapshotDataParentRoute {
  link: string;
  label: string;
  icon?: string;
}

export interface SnapshotData {
  link: string;
  page: string;
  name: string;
  menuBtnId: string;
  isBackBtnEnabled: boolean;
  additionalActionsService?: {} & TitleBarComponentN.TitleBarActionsService;
  parentRoute?: SnapshotDataParentRoute;
}
