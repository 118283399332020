import {Component, Inject, TemplateRef} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogWrapperData} from '@app/shared/ui';
import {FlatFilterPopupService} from './flat-filter-popup.service';

@Component({
  selector: 'app-flat-filter-popup',
  templateUrl: './flat-filter-popup.component.html',
  styleUrls: ['./flat-filter-popup.component.scss']
})
export class FlatFilterPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<{ template: TemplateRef<any> }, { template: TemplateRef<any> }>,
    public readonly popupService: FlatFilterPopupService,
    public readonly dialogRef: MatDialogRef<FlatFilterPopupComponent>,
  ) { }
}
