import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ResourcePath } from '@app/shared/entities';
import { ResourcesHelper } from '@app/shared/entities/common';
import { Abonent } from '@app/shared/models';
import { ServiceFlatSharedAbonent } from '@app/views/services/components';

@Component({
  selector: 'app-service-abonents-disconnect-popup-content',
  templateUrl: './service-abonents-disconnect-popup-content.component.html',
  styleUrls: ['./service-abonents-disconnect-popup-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceAbonentsDisconnectPopupContentComponent {
  readonly resourcePath: typeof ResourcePath = ResourcePath;

  @Input() loading: boolean;
  @Input() owner: Pick<Abonent, 'id' | 'phone'>;
  @Input() sharedAbonents: ServiceFlatSharedAbonent[];

  @Output() private disconnectSharedAbonent: EventEmitter<{ delegationId: number }> = new EventEmitter();

  constructor(
    public resourcesHelper: ResourcesHelper
  ) { }

  onDisconnectSharedAbonent(delegationId: number) {
    this.disconnectSharedAbonent.emit({ delegationId });
  }
}
