<p style="text-align: center;">
  {{ 'reject.request.popup.message' | translate: { number: data.number } }}
</p>

<mat-form-field appearance="outline">
  <input
    matInput
    type="text"
    placeholder="{{ 'reject.request.popup.placeholder.reason' | translate }}"
    aria-label="Number"
    [formControl]="reasonControl"
    [matAutocomplete]="auto"
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"
    required
  />
  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let reason of filteredReasons | async" [value]="reason">
      {{ reason }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<div class="action-block">
  <button mat-raised-button color="error" [disabled]="!reasonControl.valid" (click)="onReject()">
    {{ 'reject.request.popup.button.submit' | translate }}
  </button>
</div>
