import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { RdaSelectSearchModule } from '@app/shared/components/rda/rda-search-select';
import { CameraInfoContainerModule } from '@app/shared/containers/camera/camera-info-container';
import { CameraThumbnailModule } from '@app/shared/containers/camera/camera-thumbnail';
import { SharedPipesModule } from '@app/shared/pipes';
import { InfoTableModule } from '@app/shared/ui/info-table';
import { CameraEditContentComponent } from './camera-edit-content.component';
import { CameraEditContentService } from './camera-edit-content.service';
import {SharedDirectivesModule} from '@app/shared/directives';
import {NgxTranslateModule} from '@app/translate/translate.module';
import { SelectSearchModule } from '@app/shared/containers';

@NgModule({
    imports: [
        SelectSearchModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        SharedPipesModule,
        InfoTableModule,
        CameraThumbnailModule,
        CameraInfoContainerModule,
        RdaSelectSearchModule,
        SharedDirectivesModule,
        NgxTranslateModule
    ],
    declarations: [
        CameraEditContentComponent
    ],
    providers: [
        CameraEditContentService
    ],
    exports: [
        CameraEditContentComponent
    ]
})
export class CameraEditContentModule { }
