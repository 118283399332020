<app-cameras-table-container
  [cameras]="cameras"
  [disabled]="disabled"
  [emptyCamerasCaption]="disabled ? ('service.cameras.message.add_addresses_to_connect_cameras' | translate) : ('service.cameras.message.connect_first_camera' | translate)"
  (addCamera)="onAdd()"
  (editCamera)="onEdit($event.camera)"
  (deleteCamera)="onDelete($event.id)"
  (resetCamera)="onReset($event.rdvaUri, $event.cameraId)"
  (refreshCameras)="onRefreshCameras()"
  (cameraInfo)="onInfo($event.camera)"
></app-cameras-table-container>
