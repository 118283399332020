import { Abonent, Flat } from '@app/shared/models';
import { ServiceConnectionWithType } from '@app/views/services/models';
import { Account } from './../../account';
import { ServiceResponse } from './service-response.model';

export interface ServiceBlockPhysicalTubeRequest {
    abonent: Abonent;
    account: Account;
    flat: Partial<Flat>;
    entranceId: number;
    dependantService: Pick<ServiceResponse, 'id' | 'type'>;
    connection: ServiceConnectionWithType;
    serviceId: number;
    companyId: number;
}
