import {Injectable} from '@angular/core';
import {
  AbonentService,
  Account,
  ConnectionService,
  ServiceApiService,
  ServiceConnection,
  ServiceInfoResponse
} from '@app/shared/entities/rd';
import {Dictionary} from '@app/shared/helpers';
import {EntranceResponse} from '@app/shared/models';
import {AddressFormatter} from '@app/shared/services';
import {forkJoin, Observable, throwError} from 'rxjs';
import {AbonentsFromEntrancesResponse} from './models';
import {AbonentsFromEntrancesFacade} from './store/abonents-from-entrances.facade';

@Injectable()
export class AbonentsFromEntrancesHelper {
  constructor(
    private abonentService: AbonentService,
    private serviceApiService: ServiceApiService,
    private connectionService: ConnectionService,
    private storeFacade: AbonentsFromEntrancesFacade,
    private addressFormatter: AddressFormatter
  ) {
  }

  async getEntrancesAccounts(
    entrances: Pick<EntranceResponse, 'id'>[], ignoredServices: number[]
  ): Promise<ServiceConnection[]> {
    const ignoredFlats: Dictionary<boolean> = {};
    const connections: ServiceConnection[] = [];
    const connectionsDict: Dictionary<ServiceConnection> = {};

    try {
      for (const entrance of entrances) {
        const servicesList: ServiceInfoResponse[] = await this.serviceApiService
          .getEntranceServices(entrance.id)
          .toPromise();

        for (const service of servicesList) {
          const connectionsResponse = connections.concat(
            await this.serviceApiService
              .getConnections(service.id, entrance.id)
              .toPromise()
          );

          if (ignoredServices.includes(service.id)) {
            connectionsResponse.forEach((connection: ServiceConnection) =>
              ignoredFlats[connection.flat.id] = true
            );
          } else {
            connectionsResponse.forEach((connection: ServiceConnection) =>
              connectionsDict[connection.flat.id] = connection
            );
          }
        }
      }
    } catch (e) {
      throwError(e);
    }

    Object.keys(connectionsDict).forEach((flatId: string) => {
      const notIgnoredAndCorrectEntrance = !ignoredFlats[connectionsDict[flatId].flat.id] &&
        Object.values(entrances)
          .map(entranceValue => entranceValue.id)
          .includes(connectionsDict[flatId].flat.address.entrance.id);

      if (notIgnoredAndCorrectEntrance) {
        connections.push(connectionsDict[flatId]);
      }
    });

    return connections
      .sort((a, b) => (this.addressFormatter.formatAddress(a.flat.address) + a.flat.address.flat)
        .localeCompare(
          this.addressFormatter.formatAddress(b.flat.address) + b.flat.address.flat,
          undefined,
          {numeric: true, sensitivity: 'base'}
        )
      )
      .filter(connection => !!connection.account?.owner);
  }

  async getFirstAccountsValidForAccountCheck(
    connections: AbonentsFromEntrancesResponse[],
    handleResponse: (accounts: Account[], idx: number) => void,
    idx: number = 0
  ) {
    try {
      while (idx < connections.length) {
        const accounts: Account[] =
          await this.abonentService
            .getAbonentAccounts(connections[idx].abonent.id)
            .toPromise();

        idx += 1;

        if (accounts.length > 1) {
          handleResponse(accounts, idx);
        }
      }
    } catch (error) {
      throw error;
    }

    return;
  }

  async batchConnection(
    serviceId: number,
    connections: AbonentsFromEntrancesResponse[],
    companyId?: number
  ): Promise<void> {
    this.storeFacade.startConnectionProgress(connections.length);

    let firstIdx = 0;
    let lastIdx = 0;

    while (lastIdx + 10 < connections.length) {
      lastIdx = firstIdx + 10;

      const connectionRequests: Promise<void | Observable<never>>[] = this.prepareConnectionsRequestList(
        serviceId, connections.slice(firstIdx, lastIdx), companyId
      );

      try {
        await forkJoin(connectionRequests).toPromise();
      } catch (error) {
        this.storeFacade.changeStatus('accounts');
        throw error;
      }

      firstIdx = lastIdx;
    }

    if (connections.length - lastIdx !== 0) {
      const connectionRequests: Promise<void | Observable<never>>[] = this.prepareConnectionsRequestList(
        serviceId, connections.slice(lastIdx, connections.length), companyId
      );

      try {
        await forkJoin(connectionRequests).toPromise();
      } catch (error) {
        this.storeFacade.changeStatus('accounts');
        throw error;
      }
    }

    this.storeFacade.finishConnectionProgress();
  }

  private prepareConnectionsRequestList(
    serviceId: number,
    connections: AbonentsFromEntrancesResponse[],
    companyId: number
  ) {
    const connectionRequests: Promise<void | Observable<never>>[] = [];

    for (const connection of connections) {
      const request = {
        phone: connection.abonent.phone,
        abonentId: connection.abonent.id,
        accountId: connection.account?.id ?? null,
        servicesIds: [serviceId],
        entranceId: connection.flat.address.entrance.id,
        flatNumber: connection.flat.translated,
        flatId: connection.flat.id,
        companyId: companyId
      };

      connectionRequests.push(
        this.connectionService.createConnection(request, () =>
          this.storeFacade.updateConnectionProgress(1, connection.id)
        )
      );
    }

    return connectionRequests;
  }
}
