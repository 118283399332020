import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {SnackbarService} from '@app/shared/components/snackbar';
import {IpRdaRequest, RdaApiService, RdaResponse} from '@app/shared/entities/rd';
import {indicate, parseError} from '@app/shared/helpers';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class IpIntercomGenerateBottomSheetService implements OnDestroy {
  private _loading$: Subject<boolean> = new Subject();
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private rdaApiService: RdaApiService,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this._loading$.next();
    this._loading$.complete();
  }

  get loading$(): Observable<boolean> {
    return this._loading$.asObservable();
  }

  generateIpIntercom(request: IpRdaRequest, handleResponse: (response: RdaResponse) => void) {
    this.rdaApiService.createIpRda(request)
      .pipe(
        takeUntil(this.onDestroy$),
        indicate(this._loading$)
      )
      .subscribe(
        (response: RdaResponse) => handleResponse(response),
        (error: HttpErrorResponse) => this.snackbar.showMessage(
          this.translate.instant('shared.rda.intercom.edit.content.ip.generate.bottom.sheet.message.failed', {
            text: parseError(error)
          })
        )
      );
  }
}
