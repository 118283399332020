import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BatchRequest } from '@app/views/intercom/models';
import { GetAllCompanies, GetAllCompaniesClear } from '@app/views/intercom/store/actions';
import { getAllCompaniesSuccess } from '@app/views/intercom/store/states';
import { PopupBasicComponent } from '@app/shared/templates';
import { Company } from '@app/shared/entities/rd/company/models';
import { State } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { addBatchForm } from './add-batch-form.model';

@Component({
  selector: 'app-add-batch-form',
  templateUrl: './add-batch-form.component.html',
  styleUrls: ['./add-batch-form.component.scss']
})
export class AddBatchFormComponent extends PopupBasicComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  companies$: Observable<Company[]>;
  private onDestroy$: Subject<void>;

  constructor(
    @Inject('subject') private subject: Subject<BatchRequest>,
    private store: Store<State>
  ) {
    super();
    this.form = addBatchForm();
  }

  ngOnInit() {
    this.initStore();
  }

  ngOnDestroy() {
    this.destroyStoreListeners();
  }

  onSubmit() {
    if (this.form.valid) {
      const value = this.form.getRawValue();
      const request: BatchRequest = {
        shieldModelId: value.shieldModelId,
        rdaModelId: value.rdaModelId,
        companyId: value.companyId
      };
      this.subject.next(request);
    }
  }

  onSelectCompany(event: { companyId: number }) {
    this.form.get('companyId').setValue(event.companyId);
  }

  onShieldModelSelected(event: { modelId: number }) {
    this.form.get('shieldModelId').setValue(event.modelId);
  }

  onRdaModelSelected(event: { modelId: number }) {
    this.form.get('rdaModelId').setValue(event.modelId);
  }

  private initStore() {
    this.onDestroy$ = new Subject();
    this.initStoreSelectors();
    this.getStoreData();
  }

  private initStoreSelectors() {
    this.companies$ = this.store.select(getAllCompaniesSuccess);
  }

  private getStoreData() {
    this.store.dispatch(new GetAllCompanies());
  }

  private destroyStoreListeners() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.store.dispatch(new GetAllCompaniesClear());
  }
}
