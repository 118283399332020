<div class="popup delete-service-content">
  <app-info-block>
    <ng-container info-block-text>
      <p [innerHTML]="'software.intercoms.delete.title' | translate: { name: serviceName }"></p>

      <p
        class="info-block__text-small"
        [innerHTML]="'software.intercoms.delete.text' | translate: {
          and_1: serviceType === SOFTWARE_INTERCOM || serviceType === GATE ? ',' : ' ' + ('software.intercoms.delete.and' | translate),
          and_2: serviceType === SOFTWARE_INTERCOM || serviceType === GATE ? ' ' + ('software.intercoms.delete.and_keys' | translate) : ''
        }"
      ></p>
    </ng-container>
  </app-info-block>

  <div class="popup-actions">
    <button
      mat-raised-button
      color="error"
      type="submit"
      class="popup-actions__button"
      (click)="onDelete()"
      [disabled]="loading"
    >
      {{ 'software.intercoms.delete.button.submit' | translate }}
    </button>
  </div>
</div>
