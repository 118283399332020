import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { AddressInputModule } from '@app/shared/components/address/address-input';
import { SharedDirectivesModule } from '@app/shared/directives';
import { InfoBlockModule } from '@app/shared/ui';
import { AdditionalRangesFormComponent } from './additional-ranges-form';
import { CustomAddressFormComponent } from './custom-address-form';
import { EntranceEditContainerComponent } from './entrance-edit-container.component';
import { YandexMapFormComponent } from './yandex-map-form';
import { NgxTranslateModule } from '@app/translate/translate.module';
import { GoogleMapFormComponent } from './google-map-form/google-map-form.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedDirectivesModule,
    AddressInputModule,
    InfoBlockModule,
    NgxTranslateModule,
    GoogleMapsModule,
    ButtonModule,
    InputTextModule,
  ],
  declarations: [
      CustomAddressFormComponent,
      YandexMapFormComponent,
      EntranceEditContainerComponent,
      AdditionalRangesFormComponent,
      GoogleMapFormComponent
  ],
  exports: [
      EntranceEditContainerComponent
  ]
})
export class EntranceEditContainerModule { }
