import { Directive, ElementRef } from '@angular/core';
import { ValidatorFn, UntypedFormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[appCameraUriValidation]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CameraUriValidationDirective, multi: true }]
})
export class CameraUriValidationDirective {
  readonly minUriLength: number = 8;
  private validator: ValidatorFn;

  constructor(private el: ElementRef) {
    this.validator = this.cameraUriValidator();
  }

  validate(control: UntypedFormControl) {
    return this.validator(control);
  }

  private cameraUriValidator(): ValidatorFn {
    const returnObj = { emailvalidator: { valid: false } };

    return (control: UntypedFormControl) => {
      let text: string = control.value;
      let isValid: boolean = this.validateCameraUri(text);

      if (!isValid) {
        text = this.prepareCameraUriPrefix();
        isValid = this.validateCameraUri(text);
        isValid ? control.setValue(text) : (this.el.nativeElement.value = text);
      }

      return isValid ? null : returnObj;
    };
  }

  private prepareCameraUriPrefix(): string {
    return 'rtsp://';
  }

  private validateCameraUri(text: string): boolean {
    return text.startsWith('rtsp://') && text.length >= this.minUriLength;
  }
}
