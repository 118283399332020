import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class DialogWrapperStateHelper<State> {
    private _state$: Subject<State> = new Subject();
    private _onDestroy$: Subject<void> = new Subject();

    protected destroy() {
        this._onDestroy$.next();
        this._onDestroy$.complete();
    }

    get state$(): Observable<State> {
        return this._state$;
    }

    protected set state(state: State) {
        this._state$.next(state);
    }

    protected get onDestroy$(): Observable<void> {
        return this._onDestroy$.asObservable();
    }

    addStateChangeListener(cb: (state: State) => void) {
        this._state$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((state: State) => cb(state));
    }
}
