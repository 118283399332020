import { NgModule } from '@angular/core';
import { SharedPipesModule } from '@app/shared';
import { AddressInputModule, CameraInfoPopupModule, ConfirmComponentConnectionModule, IntercomEditContentModule, IntercomInfoPopupModule, IntercomKeyDeleteContainerModule, IntercomKeysContainerModule, PhoneInputModule, RdaTranslationsEditorModule } from '@app/shared/components';
import { CamerasTableContainerModule } from '@app/shared/containers';
import { CameraThumbnailModule } from '@app/shared/containers/camera/camera-thumbnail';
import { SharedDirectivesModule } from '@app/shared/directives';
import { SharedModule } from '@app/shared/shared.module';
import { NavbarModule, TextBadgeModule } from '@app/shared/ui';
import { reducers } from '@app/views/intercom/store/reducers/reducers';
import { ServiceCamerasWarningModule, ServiceConnectionPopupModule, ServiceIntercomKeyPopupEditModule, ServiceIntercomsModule, ServiceRdaLogsModule } from '@app/views/services/components';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxUploaderModule } from 'ngx-uploader';
import { AdaptersPageComponent, AddBatchFormComponent, AddIntercomFormComponent, AddModelFormComponent, BatchesFormGroupComponent, BatchesPageComponent, CamerasPageComponent, CompaniesFormGroupComponent, DeleteAddressPopupComponent, ModelListComponent, ModelsPageComponent, NewAdaptersPageComponent, OrderAssignPopupComponent, OrderCardComponent, OrderCloseFormComponent, OrderCreateFormComponent, OrderDeletePopupComponent, OrderHistoryPopupComponent, OrderRdasFormComponent, OrderScansFormComponent, OrderSendPopupComponent, OrdersPageComponent, PrintAdapterUidPopupComponent, ShieldsPageComponent, ShieldTestsComponent } from './components';
import { BatchesEffects, CompaniesEffects, OrdersEffects, RdaModelsEffects, ShieldsEffects, ShieldsModelsEffects } from './store/effects';
import { SafeUrlPipe } from '@app/shared/pipes';
import { GroupDataModificationBottomSheetComponent } from './components/pages/new-adapters-page/group-data-modification/group-data-modification-bottom-sheet/group-data-modification-bottom-sheet.component';
import { GroupDataModificationComponent } from './components/pages/new-adapters-page/group-data-modification/group-data-modification.component';
import { NgxTranslateModule } from '@app/translate/translate.module';
import { NativeDialogService } from '@app/shared/components/info-panel/services/native-dialog.service';
import {SkeletonModule} from 'primeng/skeleton';

const components = [
  AdaptersPageComponent,
  NewAdaptersPageComponent,
  CamerasPageComponent,
  BatchesPageComponent,
  ShieldsPageComponent,
  ModelListComponent,
  ModelsPageComponent,
  ShieldTestsComponent,
  OrdersPageComponent,
  OrderCardComponent,
  GroupDataModificationComponent
];

const modalForms = [
  CompaniesFormGroupComponent,
  BatchesFormGroupComponent,
  AddIntercomFormComponent,
  PrintAdapterUidPopupComponent,
  AddBatchFormComponent,
  AddModelFormComponent,
  DeleteAddressPopupComponent,
  OrderHistoryPopupComponent,
  OrderRdasFormComponent,
  OrderScansFormComponent,
  OrderCreateFormComponent,
  OrderAssignPopupComponent,
  OrderSendPopupComponent,
  OrderCloseFormComponent,
  OrderDeletePopupComponent,
  GroupDataModificationBottomSheetComponent
];

const effects = [
  BatchesEffects,
  CompaniesEffects,
  ShieldsEffects,
  RdaModelsEffects,
  ShieldsModelsEffects,
  OrdersEffects
];

@NgModule({
    imports: [
        SharedModule,
        InfiniteScrollModule,
        NgxUploaderModule,
        CameraInfoPopupModule,
        IntercomKeyDeleteContainerModule,
        IntercomKeysContainerModule,
        ServiceIntercomKeyPopupEditModule,
        SharedPipesModule,
        IntercomInfoPopupModule,
        IntercomEditContentModule,
        ConfirmComponentConnectionModule,
        SharedDirectivesModule,
        CamerasTableContainerModule,
        AddressInputModule,
        NavbarModule,
        PhoneInputModule,
        ServiceIntercomsModule,
        ServiceRdaLogsModule,
        ServiceCamerasWarningModule,
        ServiceConnectionPopupModule,
        CameraThumbnailModule,
        RdaTranslationsEditorModule,
        StoreModule.forFeature('intercom', reducers),
        EffectsModule.forFeature(effects),
        TextBadgeModule,
        NgxTranslateModule,
        SkeletonModule
    ],
  declarations: [...components, ...modalForms],
  providers: [
    SafeUrlPipe,
    NativeDialogService
  ]
})
export class IntercomModule { }
