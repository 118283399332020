<div class="popup component">
  <h3 class="component__title">
    {{ 'intercom.panels.title' | translate }}
  </h3>

  <ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="modes.LIST">
      <app-intercom-panels-list-content
        [loading]="loading"
        [editDisabled]="editHidden"
        [deleteDisabled]="deleteHidden"
        [intercomPanels]="intercomPanels"
        (deleteIntercomPanel)="onShowDelete($event.intercomPanel)"
        (editIntercomPanel)="onShowEdit($event.intercomPanel)"
      ></app-intercom-panels-list-content>

      <div class="popup-actions">
        <button
          *ngIf="!addHidden"
          mat-raised-button
          color="primary-inverse"
          (click)="onShowAdd()"
          class="popup-actions__button mat-button-lg"
        >
          {{ 'intercom.panels.button.show_add' | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="modes.ADD">
      <app-intercom-panel-edit-content
        [loading]="loading"
        [locations]="locations"
        [indexHidden]="!intercom?.intercomType?.protocol?.intercomIndexesRequired"
        (submitIntercomPanel)="onAdd($event.location, $event.index)"
      ></app-intercom-panel-edit-content>
    </ng-container>

    <ng-container *ngSwitchCase="modes.EDIT">
      <app-intercom-panel-edit-content
        [loading]="loading"
        [locations]="locations"
        [intercomPanel]="intercomPanel"
        [indexHidden]="!intercom?.intercomType?.protocol?.intercomIndexesRequired"
        (submitIntercomPanel)="onEdit($event.location, $event.index)"
      ></app-intercom-panel-edit-content>
    </ng-container>

    <ng-container *ngSwitchCase="modes.DELETE">
      <app-intercom-panel-delete-content
        [loading]="loading"
        [intercomPanel]="intercomPanel"
        (deleteIntercomPanel)="onDelete()"
      ></app-intercom-panel-delete-content>
    </ng-container>
  </ng-container>

  <button
    mat-fab
    (click)="onBack()"
    color="primary-inverse"
    *ngIf="mode === modes.EDIT || mode === modes.ADD || mode === modes.DELETE"
    class="component__button component__button--back"
  >
    <mat-icon>arrow_back</mat-icon>
  </button>
</div>
