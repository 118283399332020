import { Pipe, PipeTransform } from '@angular/core';
import { AccountServiceData } from '@app/views/abonents/models';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'accountAddress'
})
export class AccountAddressPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) {}

  transform(value: AccountServiceData, args?: any): any {
    return value.name + ', ' + this.translate.instant('enum.account_address.flat') + value.flat?.address.flat;
  }
}
