<div class="table-page-container">
  <mat-card>
    <mat-card-content>
      <div *ngIf="loading" class="table-page-container__loaders__wrapper">
        <p-skeleton width="100%" height="4rem"></p-skeleton>
        <hr class="table-page-container__loaders--devider">
        <p-skeleton width="100%" height="15rem"></p-skeleton>
        <p-skeleton width="100%" height="4rem"></p-skeleton>
      </div>

      <div [hidden]="loading">
        <div class="input-field">
          <mat-form-field appearance="outline" class="none-padding none-margin">
            <input
              matInput
              [formControl]="filterInput"
              placeholder="{{ 'adapters.page.placeholder.search' | translate }}"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
            >
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        </div>

        <hr>

        <div [hidden]="(intercoms$ | async)?.totalCount === 0">
          <mat-table 
            [dataSource]="(intercoms$ | async)?.dataSource"
            matSort 
            (matSortChange)="onSort($event)"
          >
            <ng-container matColumnDef="active">
              <mat-header-cell mat-sort-header *matHeaderCellDef data-label="active">
                {{ 'adapters.page.field.status' | translate }}
              </mat-header-cell>
              <mat-cell 
                data-label="active" 
                label-name="{{ 'adapters.page.field.status' | translate }}"
                *matCellDef="let element"
              >
                <mat-chip 
                  [disabled]="true" 
                  [selectable]="false" 
                  color="{{ element.active ? 'success' : 'error' }}"
                >
                  {{
                  element.active
                  ? ('adapters.page.enum.state.online' | translate)
                  : ('adapters.page.enum.state.offline' | translate)
                  }}
                </mat-chip>
              </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="uid">
              <mat-header-cell mat-sort-header *matHeaderCellDef data-label="uid">
                {{ 'adapters.page.field.uid' | translate }}
              </mat-header-cell>
              <mat-cell 
                data-label="uid" 
                label-name="{{ 'adapters.page.field.uid' | translate }}" 
                *matCellDef="let element"
              >
                <p>{{ element.uid }}</p>
              </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="ipAddress">
              <mat-header-cell mat-sort-header data-label="ipAddress" *matHeaderCellDef>
                {{ 'adapters.page.field.ip' | translate }}
              </mat-header-cell>
              <mat-cell 
                data-label="ipAddress" 
                label-name="{{ 'adapters.page.field.ip' | translate }}"
                *matCellDef="let element"
              >
                <p>{{ element.ipAddress }}</p>
              </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="address">
              <mat-header-cell data-label="address" *matHeaderCellDef>
                {{ 'adapters.page.field.address' | translate }}
              </mat-header-cell>
              <mat-cell 
                data-label="address" 
                label-name="{{ 'adapters.page.field.address' | translate }}"
                *matCellDef="let element"
              >
                {{ element.addressString }}
              </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="lastHeartBeatTime">
              <mat-header-cell mat-sort-header data-label="lastHeartBeatTime" *matHeaderCellDef>
                {{ 'adapters.page.field.last_heart_beat_time' | translate }}
              </mat-header-cell>
              <mat-cell 
                data-label="lastHeartBeatTime" 
                label-name="{{ 'adapters.page.field.last_heart_beat_time' | translate }}"
                *matCellDef="let element"
              >
                <p>{{ element.lastHeartBeatTime | date:'dd.MM.yyyy HH:mm:ss':'-0' }}</p>
              </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="versionInfoRdaa">
              <mat-header-cell mat-sort-header data-label="versionInfoRdaa" *matHeaderCellDef>
                {{ 'adapters.page.field.version_rdaa' | translate }}
              </mat-header-cell>
              <mat-cell 
                data-label="versionInfoRdaa" 
                label-name="{{ 'adapters.page.field.version_rdaa' | translate }}"
                *matCellDef="let element"
              >
                <p>{{ element.versionInfoRdaa }}</p>
              </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="versionInfoShield">
              <mat-header-cell mat-sort-header data-label="versionInfoShield" *matHeaderCellDef>
                {{ 'adapters.page.field.version_shield' | translate }}
              </mat-header-cell>
              <mat-cell 
                data-label="versionInfoShield" 
                label-name="{{ 'adapters.page.field.version_shield' | translate }}"
                *matCellDef="let element"
              >
                <p>{{ element.versionInfoShield }}</p>
              </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="versionInfoOs">
              <mat-header-cell mat-sort-header data-label="versionInfoOs" *matHeaderCellDef>
                {{ 'adapters.page.field.version_rdos' | translate }}
              </mat-header-cell>
              <mat-cell 
                data-label="versionInfoOs" 
                label-name="{{ 'adapters.page.field.version_rdos' | translate }}"
                *matCellDef="let element"
              >
                <p>{{ element.versionInfoOs }}</p>
              </mat-cell>
            </ng-container>
        
            <ng-container matColumnDef="actions">
              <mat-header-cell data-label="actions" *matHeaderCellDef></mat-header-cell>
              <mat-cell data-label="actions" *matCellDef="let element">
                <button 
                  class="action-btn" 
                  *ngIf="element.address"
                  mat-icon-button 
                  color="error" 
                  (click)="onDisconnect(element)"
                >
                  <mat-icon aria-label="Delete">clear</mat-icon>
                </button>
              </mat-cell>
            </ng-container>
        
            <mat-header-row *matHeaderRowDef="displayedColumns"
              [style.display]="resolution.isTablet ? 'none' : 'flex'"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
          </mat-table>
        </div>

        <div [hidden]="(intercoms$ | async)?.totalCount > 0">
          <app-empty-data></app-empty-data>
        </div>
      </div>

      <div [hidden]="loading">
        <mat-paginator [length]="(intercoms$ | async)?.totalCount" [pageSize]="pageSize"
                       [hidePageSize]="true" [showFirstLastButtons]="true"></mat-paginator>
      </div>
    </mat-card-content>
  </mat-card>
</div>
