import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {
  IAbonentDelegationMobileVersion, IAddressConnections,
  IConnection,
  IDelegatedAbonentsConnections,
  IToAbonent,
  ServiceApiService
} from '@app/shared/entities/rd';
import {isEqual} from 'lodash';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import {ServiceType} from '@app/views/services/submodules/shared/abonents-delegation/pipes/service-converter.pipe';
import {CommonModule} from '@angular/common';
import {SharedPipesModule} from '@app/shared';
import {NgxTranslateModule} from '@app/translate/translate.module';
import {InputSwitchModule, InputSwitchOnChangeEvent} from 'primeng/inputswitch';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {
  BaseInfoPanelComponent
} from '@app/shared/components/info-panel/components/base-info-panel/base-info-panel.component';
import {NativeDialogService} from '@app/shared/components/info-panel/services/native-dialog.service';
import {Response} from '@app/shared/components/info-panel/models/response';
import {catchError, debounceTime, takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {of, Subject} from 'rxjs';
import {ComponentLoaderModule} from '@app/shared/ui';
import {TranslateService} from '@ngx-translate/core';
import {InputTextModule} from 'primeng/inputtext';
import {
  ConnectionsSearchPipe
} from '@app/views/abonents/components/pages/abonent-page/abonent-shared/abonent-edit-connection/connections-search.pipe';
import {CheckboxModule} from 'primeng/checkbox';
import {TooltipModule} from 'primeng/tooltip';

@Component({
  selector: 'app-abonent-edit-connection',
  templateUrl: './abonent-edit-connection.component.html',
  styleUrls: ['./abonent-edit-connection.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SharedPipesModule,
    NgxTranslateModule,
    InputSwitchModule,
    ReactiveFormsModule,
    ButtonModule,
    DialogModule,
    ComponentLoaderModule,
    InputTextModule,
    ConnectionsSearchPipe,
    CheckboxModule,
    TooltipModule
  ],
  encapsulation: ViewEncapsulation.None
})
export class AbonentEditConnectionComponent extends BaseInfoPanelComponent implements OnInit, OnDestroy {
  protected readonly ServiceType = ServiceType;
  public abonentDelegationMobileVersion: IAbonentDelegationMobileVersion = null;
  public loading: boolean;
  public searchString: string = null;
  public allControl: FormControl<boolean> = new FormControl<boolean>(false);

  private search$: Subject<string> = new Subject<string>();
  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig<{ abonent: IToAbonent; ownerId: number; request: Function }>,
    private apiService: ServiceApiService,
    private translate: TranslateService,
    private dynamicDialogRef: DynamicDialogRef,
    protected dialogService: NativeDialogService
  ) {
    super(dialogService);
  }

  ngOnInit(): void {
    this.loading = true;
    this.getDelegations();
    this.initSearchEvent();
    this.allControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.setUpControlsValue(value);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public onAccessChange(): void {
    this.disableAllControls();
    this.loading = true;
    const concatConnections = this.abonentDelegationMobileVersion.addressConnections
      .map((address) => address.connections)
      .reduce((acc, currentArray) => acc.concat(currentArray), []).filter((connection) => connection.control.value === true);
    this.apiService.setUpDelegation({
      fromAbonent: this.dynamicDialogConfig.data.ownerId,
      toAbonent: this.dynamicDialogConfig.data.abonent.id,
      connections: concatConnections.map((c) => {
        return {
          id: c.id
        };
      })
    }).pipe(
      catchError((e: HttpErrorResponse) => of(e)),
    ).subscribe((response) => {
      this.enableAllControls();
      this.getDelegations();
      this.loading = false;
      if (response instanceof HttpErrorResponse) {
        this.showResponseStatus(
          response.error,
          response.message,
          Response.error
        );
        return;
      }
      this.showResponseStatus(
        this.translate.instant('abonent.sign_ups.field.success'),
        this.translate.instant('abonent.sign_ups.field.success_message'),
        Response.success,
      );
      this.dynamicDialogConfig.data.request();
      this.dynamicDialogRef.close();
    });
  }

  public onSearch(target: EventTarget): void {
    this.search$.next(target['value']);
  }

  private createFormControlField(response: IAbonentDelegationMobileVersion): IAbonentDelegationMobileVersion {
    response.addressConnections.forEach((c) => c.connections.forEach((co) => {
      co['control'] = new FormControl(this.isConnectionSelected(co.id, response));
    }));
    this.loading = false;
    return response;
  }

  private isConnectionSelected(connectionId: number, response: IAbonentDelegationMobileVersion): boolean {
    return response.delegatedAbonentsConnections.some((delegatedConnection) =>
      delegatedConnection.connections.some((ref) => ref.id === connectionId)
    );
  }

  private disableAllControls(): void {
    this.abonentDelegationMobileVersion.addressConnections
      .map((address) => address.connections)
      .reduce((acc, currentArray) => acc.concat(currentArray), []).forEach((temp) => temp['control'].disable());
  }

  private enableAllControls(): void {
    this.abonentDelegationMobileVersion.addressConnections
      .map((address) => address.connections)
      .reduce((acc, currentArray) => acc.concat(currentArray), []).forEach((temp) => temp['control'].enable());
  }

  private getDelegations(): void {
    this.apiService.fromAbonentToAbonentDelegationMobile(
      this.dynamicDialogConfig.data.ownerId,
      this.dynamicDialogConfig.data.abonent.id
    ).subscribe((response) => {
      this.abonentDelegationMobileVersion = this.createFormControlField(response);
    });
  }

  private initSearchEvent(): void {
    this.search$.pipe(debounceTime(300), takeUntil(this.destroy$)).subscribe((value) => {
      this.searchString = value;
    });
  }

  private setUpControlsValue(value: boolean): void {
    this.abonentDelegationMobileVersion.addressConnections
      .map((address) => address.connections)
      .reduce((acc, currentArray) => acc.concat(currentArray), []).forEach((temp) => temp['control'].setValue(value));
  }
}
