import { NgModule } from '@angular/core';

import { AccountAddressPipe } from './account-address.pipe';
import { AddressPipe } from './address.pipe';
import { ArrayMapPipe } from './array-map.pipe';
import { AwxJobStatusPipe } from './awx-job-status.pipe';
import { CurrencyTypePipe } from './currency-type.pipe';
import { CurrentDate } from './current-date.pipe';
import { FilterPipe } from './filter.pipe';
import { FlatNumberPipe } from './flat-number.pipe';
import { FlatsCountPipe } from './flats-count.pipe';
import { IconPipe } from './icons.pipe';
import { IpRdaConnectionTypePipe } from './ip-rda-connection-type.pipe';
import { MapPipe } from './map.pipe';
import { OrderStatusPipe } from './order-status.pipe';
import { PhonePipe } from './phone.pipe';
import { BoolPipe } from './bool.pipe';
import { SafeUrlPipe } from './safe-url.pipe';
import { ServiceTypeNamePipe } from './service-type-name.pipe';
import { SignUpStatusPipe } from './sign-up-status.pipe';
import { TypesPipe } from './types.pipe';
import {PxToRemPipe} from '@app/shared/pipes/px-to-rem.pipe';

const pipes = [
  SafeUrlPipe,
  PhonePipe,
  IconPipe,
  BoolPipe,
  TypesPipe,
  SignUpStatusPipe,
  OrderStatusPipe,
  IpRdaConnectionTypePipe,
  ArrayMapPipe,
  CurrentDate,
  ServiceTypeNamePipe,
  AddressPipe,
  AccountAddressPipe,
  AwxJobStatusPipe,
  FilterPipe,
  MapPipe,
  FlatsCountPipe,
  FlatNumberPipe,
  CurrencyTypePipe,
  PxToRemPipe
];

@NgModule({
  declarations: pipes,
  exports: pipes
})
export class SharedPipesModule {}
