<div
  class="service-flats"
  [class.service-flats--divided-content]="addresses?.length > 0">
  <ng-container *ngIf="addresses?.length > 0; else emptyAddresses">
    <div class="service-flats__filter_wrapper">
      <app-service-flats-menu
        [filterRowState]="filterRowState"
        [selectedFilterCount]="selectedFilterCount"
        [tariffActiveState]="tariffActiveState"
        [selectedFlatList]="selectedFlatList"
        [manageActiveState]="manageActiveState"
        [deleteActiveState]="deleteActiveState"
        [deleteAbonentsActiveState]="deleteAbonentsActiveState"
        [xlgWDownBreakpoint]="xlgWDownBreakpoint$ | async"
        (toggleFilterRow)="onToggleFilterRow()"
        (resetFilters)="onResetFilters()"
        (addPhone)="onAddPhone()"
        (delegate)="onDelegate()"
        (addVirtualFlat)="onAddVirtualFlat()"
        (addConnections)="onAddConnections()"
        (setTarrif)="onTariff()"
        (manage)="onManage()"
        (deleteFlat)="onDeleteFlat()"
        (deleteSharedAbonents)="
          onDeleteSharedAbonents()
        "></app-service-flats-menu>
    </div>

    <app-service-title-row>
      <div
        class="services"
        services>
        <div class="service-flats__title-keeper">
          <h4 class="service-flats__title">
            {{ 'software.intercom.flats.field.intercom_tube' | translate }}
          </h4>
          <a
            class="service-flats__title-icon td-tariff"
            matTooltipClass="tooltip"
            matTooltip="{{ 'software.intercom.flats.tooltip.intercom_tube' | translate }}"
            target="_blank"
            [href]="hardwareIntercomeLink">
            <mat-icon svgIcon="info"></mat-icon>
          </a>
          <span
            class="service-flats__title-tariff"
            *ngIf="abonentsBillingEnabled$ | async">
            {{ 'software.intercom.flats.tip.intercom_tube' | translate }}
          </span>
        </div>
        <div class="service-flats__title-keeper">
          <h4 class="service-flats__title">
            {{ 'software.intercom.flats.field.rosdomofon' | translate }}
          </h4>
          <a
            class="service-flats__title-icon rd-tariff"
            matTooltipClass="tooltip"
            matTooltip="{{ 'software.intercom.flats.tooltip.rosdomofon' | translate }}"
            target="_blank"
            [href]="softwareIntercomeLink">
            <mat-icon svgIcon="info"></mat-icon>
          </a>
          <span
            class="service-flats__title-tariff"
            *ngIf="abonentsBillingEnabled$ | async">
            {{ 'software.intercom.flats.tip.rosdomofon' | translate }}
          </span>
        </div>
      </div>
    </app-service-title-row>
    <div *ngIf="filterRowState">
      <app-service-flats-filters
        [services]="services"
        [flatsFilters]="flatsFilters"></app-service-flats-filters>
    </div>
    <div class="service-flats-list">
      <ngx-skeleton-loader
        *ngIf="loading && addresses?.length > 0; else contentBlock"
        count="10"
        [theme]="{
          'border-radius': '8px',
          height: '45px'
        }"></ngx-skeleton-loader>

      <ng-template #contentBlock>
        <div class="service-flats-list-wrapper">
          <ng-container
            *ngIf="addresses && addresses.length > 0; else emptyFlats">
            <ng-container *ngFor="let address of addresses">
              <ng-container
                *ngIf="
                  flats &&
                  (flats[address.entrance.id]?.flatsCount ||
                    flats[address.entrance.id]?.virtualFlatsCount)
                ">
                <app-flat-cards-table
                  class="service-flats-list-wrapper"
                  #flatCardsTable
                  [address]="address"
                  [entranceFlats]="flats[address.entrance.id]"
                  [servicesTemplate]="servicesTemplate"
                  [serviceType]="ServicesTypes.SOFTWARE_INTERCOM"
                  [services]="services"
                  [abonentsBillingEnabled]="abonentsBillingEnabled$ | async"
                  [hardwareId]="getHardwareIntercomId()"
                  (onHardwareTariffGet)="hardwareTariff = $event"
                  (flatChecked)="flatChecked($event)"
                  (flatUnchecked)="flatUnchecked($event)"
                  (addPhone)="onAddPhone($event.address, $event.flat)"
                  (handleFlatsToManage)="
                    handleFlatsToManage($event)
                  "></app-flat-cards-table>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </ng-container>
</div>

<ng-template
  #servicesTemplate
  let-flat="flat"
  let-address="address"
  let-account="account">
  <app-flat-service
    [flat]="flat"
    [address]="address"
    [account]="account"
    [serviceType]="ServicesTypes.HARDWARE_INTERCOM"
    [abonentsBillingEnabled]="abonentsBillingEnabled$ | async"
    [xlgWDownBreakpoint]="xlgWDownBreakpoint$ | async"
    [mdWUpBreakpoint]="mdWUpBreakpoint$ | async"
    (addService)="onAddPhysicalTube($event.address, $event.flat)"
    (blockService)="onBlockPhysicalTube($event.flat)"></app-flat-service>
  <app-flat-service
    [flat]="flat"
    [address]="address"
    [account]="account"
    [serviceType]="ServicesTypes.SOFTWARE_INTERCOM"
    [abonentsBillingEnabled]="abonentsBillingEnabled$ | async"
    [xlgWDownBreakpoint]="xlgWDownBreakpoint$ | async"
    [mdWUpBreakpoint]="mdWUpBreakpoint$ | async"
    (addService)="onAddSoftwareIntercom($event.address, $event.flat)"
    (blockService)="onBlockService($event.flat)"></app-flat-service>
</ng-template>

<!-- empty flats -->
<ng-template #emptyFlats>
  <app-info-block>
    <ng-container info-block-text>
      {{ 'software.intercom.flats.message.empty' | translate }}
    </ng-container>
  </app-info-block>
</ng-template>
<!-- empty address -->
<ng-template #emptyAddresses>
  <app-info-block>
    <ng-container info-block-text>
      {{ 'software.intercom.flats.message.connect_addresses_to_add_subscribers' | translate }}
    </ng-container>
  </app-info-block>
</ng-template>
