import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { ServiceEditContentComponent } from './service-edit-content.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        NgxTranslateModule
    ],
    declarations: [
        ServiceEditContentComponent
    ],
    exports: [
        ServiceEditContentComponent
    ],
})
export class ServiceEditContentModule { }
