import { Injectable } from '@angular/core';

import { Links } from '@app/shared/helpers/wiki-links.enum';
import {Dictionary} from '@app/shared/helpers';
@Injectable({
  providedIn: 'root'
})
export class UrlService {
  public links: Dictionary<string> = {
    software_intercoms: Links.SoftwareIntercom,
    software_intercom: Links.SoftwareIntercom,
    video_surveillance: Links.VideoSurveillance,
    video_manager: Links.Videodispatcher,
    gates: Links.Gates,
    gate: Links.Gates,
    mailings: Links.Mailing,
    new_mailing: Links.Mailing,
    payments: Links.Payments,
    sign_ups: Links.SignUpS,
    subscribers: Links.SignUpS,
    camera_create: Links.AddCamera,
    camera_update: Links.AddCamera,
    wizard: Links.Wizard,
    abonents: Links.Abonents,
    activity: Links.Activity,
    settings: Links.Settings,
    software_intercom_service_create: Links.AddSoftwareIntercome,
    software_intercom_create: Links.AddSoftwareIntercome,
    gate_create: Links.AddSoftwareIntercome,
    hardware_intercom_create: Links.AddHardwareIntercome,
    video_surveillance_create: Links.AddVideo,
    adapter_create: Links.AddAdapter,
    company: Links.Company,
    support_link: Links.Company,
    apartment_video_surveillance: Links.ApartmentVideoSurveillance,
  };
}
