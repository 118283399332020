import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { Pure } from '@app/shared/helpers';
import { ActiveHistoryChartData } from './../active-history-chart.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-active-history-chart-line',
  templateUrl: './active-history-chart-line.component.html',
  styleUrls: ['./active-history-chart-line.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveHistoryChartLineComponent {
  @Input() set lineData(lineData: ActiveHistoryChartData[]) {
    this._lineData = lineData;
  }
  @Input() blocksCount: number;
  @Input() loading: boolean;

  private _lineData: ActiveHistoryChartData[];

  constructor(
    private translate: TranslateService
  ) {}

  get lineData(): ActiveHistoryChartData[] {
    return this._lineData;
  }

  @Pure
  makeRange(blocksCount: number) {
    if (blocksCount === 0) {
      return [];
    }

    return [...Array(blocksCount).keys()];
  }

  isActive(index: number): boolean {
    return this.getBlockItemByIndex(index)?.state ?? false;
  }

  isInactive(index: number): boolean {
    return !this.getBlockItemByIndex(index)?.state;
  }

  getTooltipText(index: number): string {
    const blockItem: ActiveHistoryChartData = this.getBlockItemByIndex(index);

    if (blockItem) {
      return this.translate.instant('shared.active.history.chart.line.status') +
        ': ' +
        blockItem.state ? this.translate.instant('enum.state.online') : this.translate.instant('enum.state.offline') +
        ';\n' + this.translate.instant('shared.active.history.chart.line.time') + ': ' + new Date(blockItem.timestamp).toLocaleString();
    } else {
      return this.translate.instant('shared.active.history.chart.line.status') +
        ': ' +
        this.translate.instant('enum.state.offline');
    }
  }

  private getBlockItemByIndex(index: number): ActiveHistoryChartData {
    return this.lineData?.[this.blocksCount - 1 - index] ?? null;
  }
}
