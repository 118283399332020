import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';

export class PasswordValidators {
  static validatePasswordForm(control: UntypedFormGroup): ValidationErrors | null {
    const { oldPassword, newPassword, newPasswordRepeat } = control.getRawValue();

    return newPassword === newPasswordRepeat || !newPassword || !newPasswordRepeat || !oldPassword ? null : { passwordsNotEqual: true };
  }

  static getFormConditionsError(newPassword: string, oldPassword: string): string[] {
    const matcher = [];

    if (newPassword !== oldPassword) {
      matcher.push('error.mismatch');
    }

    if (newPassword.length < 8) {
      matcher.push('error.minLength');
    }

    if (!(/[A-ZА-Я-Ё]/.test(newPassword))) {
      matcher.push('error.capitalLetter');
    }

    if (!(/\d/.test(newPassword))) {
      matcher.push('error.number');
    }

    return matcher;
  }

  static strongPassword(control: AbstractControl): ValidationErrors | null {
    const containsCapitalLetter = /[A-ZА-Я-Ё]/.test(control?.value);
    const containsLowerLetter = /[a-zа-я-ё]/.test(control?.value);
    const containsNumber = (/\d/.test(control?.value));
    const shorterThanEight = (control.value as string)?.length < 8;

    if (!containsCapitalLetter || !containsLowerLetter || !containsNumber || shorterThanEight) {
      return { passwordIsNotStrong: true };
    }

    return null;
  }

  static passwordRepeatMismatch(form: UntypedFormGroup): ValidationErrors | null {
      const password = form.get('newPassword');
      const confirmation = form.get('newPasswordRepeat');

      if (!password || !confirmation || password.value === confirmation.value) {
        return null;
      }

      return { passwordRepeatMismatch: {value: true} };
  }
}
