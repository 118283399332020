import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BatchResponse } from '@app/views/intercom/models';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { batchFormGroup } from './batches-form.model';

@Component({
  selector: 'app-batches-form-group',
  templateUrl: './batches-form-group.component.html',
  styleUrls: ['./batches-form-group.component.scss']
})
export class BatchesFormGroupComponent implements OnChanges {
  @Input() batchId: number;
  @Input() batches: BatchResponse[];
  form: UntypedFormGroup;
  filteredBatches: Observable<BatchResponse[]>;
  @ViewChild('filterInput') private filterInput: ElementRef<HTMLInputElement>;
  @Output() private handleBatchSelected: EventEmitter<{ batchId: number }>;

  constructor() {
    this.initEmitters();
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    const batchesChanges: SimpleChange = changes['batches'];
    if (batchesChanges.currentValue && batchesChanges.currentValue.length > 0) {
      this.initFilter();
    }
  }

  onSelectBatch(event) {
    this.handleBatchSelected.emit({ batchId: event.value });
  }

  onOpenedChange(opened) {
    if (opened) {
      this.filterInput.nativeElement.select();
    } else {
      this.form.get('filter').setValue('');
    }
  }

  private initEmitters() {
    this.handleBatchSelected = new EventEmitter<{ batchId: number }>();
  }

  private initForm() {
    const formDefinition = batchFormGroup();
    this.form = formDefinition.form;
  }

  private initFilter() {
    this.form.get('batchId').setValue(this.batchId);
    this.filteredBatches = this.form.get('filter').valueChanges
      .pipe(
        startWith<string | BatchResponse>(''),
        map(value => typeof value === 'string' ? value : value.number.toString()),
        map(number => number ? this._filter(number) : (this.batches || []).slice())
      );
  }

  private _filter(number: string): BatchResponse[] {
    return this.batches.filter(batch => batch.number.toString().indexOf(number.toString()) === 0);
  }
}
