import { Company } from '@app/shared/entities/rd/company/models';
import { Actions, ActionTypes } from '../actions/companies.actions';

export interface State {
  companies: Company[];
  error: string | null;
  loading: boolean;
  totalCount: number;
}

export const initialState: State = {
  companies: null,
  error: null,
  loading: false,
  totalCount: 0
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GetAllCompanies: {
      return {
        ...state,
        companies: null,
        error: null,
        loading: true,
      };
    }

    case ActionTypes.GetAllCompaniesSuccess: {
      return {
        ...state,
        companies: action.payload,
        error: null,
        loading: false
      };
    }

    case ActionTypes.GetAllCompaniesFailure: {
      return {
        ...state,
        companies: null,
        error: action.payload,
        loading: false,
      };
    }

    case ActionTypes.GetAllCompaniesClear: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
