import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

export enum AbonentDelegationEntity {
  ServiceType,
  ServiceAddress
}

export enum ServiceType {
  SoftwareIntercom = 'software_intercom',
  VideoSurveillance = 'video_surveillance',
  Gate = 'gate',
  HardwareIntercom = 'hardware_intercom',
  HouseChat = 'house_chat',
}

@Pipe({
  name: 'serviceConverter',
  standalone: true
})
export class ServiceConverterPipe implements PipeTransform {

  constructor(protected translate: TranslateService) {}

  transform(value: string, entity: AbonentDelegationEntity): string {
    if (entity === AbonentDelegationEntity.ServiceType) {
      return this.translate.instant(`service-navbar.tab.action.addition.${ServiceType[value]}`);
    }
    return value;
  }

}
