import { HttpErrorResponse } from '@angular/common/http';
import { RdaResponse } from '@app/shared/entities/rd/rda';
import { Actions, ActionTypes } from '../../actions/batches.actions';

export interface State {
    adapters: RdaResponse[];
    error: string | HttpErrorResponse;
    loading: boolean;
}

export const initialState: State = {
    adapters: null,
    error: null,
    loading: false
};

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case ActionTypes.GetBatchAdapters: {
            return {
                ...state,
                adapters: null,
                error: null,
                loading: true
            };
        }

        case ActionTypes.GetBatchAdaptersSuccess: {
            return {
                ...state,
                adapters: action.payload,
                error: null,
                loading: false
            };
        }

        case ActionTypes.GetBatchAdaptersFailure: {
            return {
                ...state,
                adapters: [],
                error: action.payload
            };
        }

        case ActionTypes.GetBatchAdaptersClear: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}
