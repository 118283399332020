import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { AccountSelectionModule } from '@app/shared/components';
import { SharedPipesModule } from '@app/shared/pipes';
import { ComponentLoaderModule, DialogWrapperModule, InfoBlockModule } from '@app/shared/ui';
import { ServiceBlockPhysicalTubeContentComponent } from './components';
import { ServiceBlockPhysicalTubePopupComponent } from './service-block-physical-tube-popup.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        InfoBlockModule,
        SharedPipesModule,
        DialogWrapperModule,
        AccountSelectionModule,
        ComponentLoaderModule,
        NgxTranslateModule
    ],
    declarations: [
        ServiceBlockPhysicalTubePopupComponent,
        ServiceBlockPhysicalTubeContentComponent
    ],
    exports: [
        ServiceBlockPhysicalTubePopupComponent
    ]
})
export class ServiceBlockPhysicalTubePopupModule { }
