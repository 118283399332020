import { ChatContent } from './chat-content.model';

export interface Chat {
    content: ChatContent[];
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: {
        offset: number,
        pageNumber: number,
        pageSize: number,
        paged: true,
        sort: {
            sorted: boolean,
            unsorted: boolean
        },
        unpaged: boolean
    };
    size: 0;
    sort: {
        sorted: boolean,
        unsorted: boolean
    };
    totalElements: number;
    totalPages: number;
}
