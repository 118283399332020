import { Component, OnDestroy, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { LoaderService } from '@app/shared/entities/common/loader';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-splash-loader',
  templateUrl: './splash-loader.component.html',
  styleUrls: ['./splash-loader.component.scss']
})
export class SplashLoaderComponent implements OnInit, OnDestroy {
  @Input() fullScreen: boolean;
  show: boolean;
  private onDestroy$: Subject<void>;

  constructor(
    private loaderService: LoaderService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.show = false;
    this.onDestroy$ = new Subject<void>();

    this.loaderService.getPageLoaderState()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((state: boolean) => {
        this.show = state;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
