<app-dialog-wrapper
  [componentName]="data.componentName"
  [title]="data.title"
>
  <app-info-table>
    <ng-container *ngIf="data.body.showPersonalInfo">
      <app-info-table-record>
        <p title>
          {{ 'abonent.sign_ups.info.popup.field.phone' | translate }}
        </p>
        <p value>
          {{ data.body.signUp.phone?.toString() | phone }}
        </p>
      </app-info-table-record>

      <app-info-table-record>
        <p title>
          {{ 'abonent.sign_ups.info.popup.field.company' | translate }}
        </p>
        <p value>
          {{ data.body.signUp.companyName }}
        </p>
      </app-info-table-record>
    </ng-container>

    <app-info-table-record>
      <p title>
        {{ 'abonent.sign_ups.info.popup.field.address' | translate }}
      </p>
      <p value>
        {{ data.body.signUp.address | address }}
      </p>
    </app-info-table-record>

    <ng-container *ngIf="data.body.showPersonalInfo">
      <app-info-table-record>
        <p title>
          {{ 'abonent.sign_ups.info.popup.field.flat_number' | translate }}
        </p>
        <p value>
          {{ data.body.signUp.address.flat }}
        </p>
      </app-info-table-record>

      <app-info-table-record>
        <p title>
          {{ 'abonent.sign_ups.info.popup.field.address_status' | translate }}
        </p>
        <mat-chip
          value
          class="value"
          [disabled]="true"
          [selectable]="false"
          color="{{ data.body.signUp.isFreeAddress ? 'success' : 'warn' }}"
        >
          {{
            data.body.signUp.isFreeAddress
              ? ('abonent.sign_ups.info.popup.enum.status.free' | translate)
              : ('abonent.sign_ups.info.popup.enum.status.busy' | translate)
          }}
        </mat-chip>
      </app-info-table-record>
    </ng-container>

    <app-info-table-record>
      <p title>
        {{ 'abonent.sign_ups.info.popup.field.is_virtual' | translate }}
      </p>
      <p value>
        {{ !data.body.signUp.isVirtual | bool }}
      </p>
    </app-info-table-record>

    <app-info-table-record>
      <p title>
        {{ 'abonent.sign_ups.info.popup.field.status' | translate }}
      </p>
      <p value>
        {{ data.body.signUp | signUpStatus }}
      </p>
    </app-info-table-record>

    <ng-container *ngIf="data.body.showPersonalInfo">
      <app-info-table-record>
        <p title>
          {{ 'abonent.sign_ups.info.popup.field.ad_campaign' | translate }}
        </p>
        <p value>
          {{ data.body.signUp.adCampaign }}
        </p>
      </app-info-table-record>

      <app-info-table-record>
        <p title>
          {{ 'abonent.sign_ups.info.popup.field.ad_source' | translate }}
        </p>
        <p value>
          {{ data.body.signUp.adSource }}
        </p>
      </app-info-table-record>
    </ng-container>

    <app-info-table-record>
      <p title>
        {{ 'abonent.sign_ups.info.popup.field.created_at' | translate }}
      </p>
      <p value>
        {{ data.body.signUp.createdAt | date: 'dd.MM.yyyy HH:mm:ss' }}
      </p>
    </app-info-table-record>

    <app-info-table-record>
      <p title>
        {{ 'abonent.sign_ups.info.popup.field.updated_at' | translate }}
      </p>
      <p value>
        {{ data.body.signUp.updatedAt | date: 'dd.MM.yyyy HH:mm:ss' }}
      </p>
    </app-info-table-record>
  </app-info-table>
</app-dialog-wrapper>
