<div class="buttons-content">
  <button
    mat-raised-button
    color="primary-inverse"
    class="download"
    [disabled]="isLoading"
    (click)="onDownload.emit()"
  >
    <mat-icon
      *ngIf="!isLoadingDownload"
      svgIcon="export"
      class="icon"
    />
    <mat-spinner
      *ngIf="isLoadingDownload"
      [diameter]="16"
    />
    {{ 'payments.upload.button.download' | translate }}
  </button>
  <button
    mat-raised-button
    color="primary"
    class="upload"
    [disabled]="isLoading"
    (click)="onUpload.emit()"
  >
    <mat-icon
      *ngIf="!isLoadingUpload"
      svgIcon="upload"
      class="icon"
    />
    <mat-spinner
      *ngIf="isLoadingUpload"
      [diameter]="16"
    />
    {{ 'payments.upload.button.upload' | translate }}
  </button>
</div>
