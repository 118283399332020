import {Injectable, OnDestroy} from '@angular/core';
import {DialogWrapperStateHelper} from '@app/shared/ui';

@Injectable()
export class FlatAbonentsPopupService extends DialogWrapperStateHelper<'loading' | 'close' | 'loaded'> implements OnDestroy {
  constructor() {
    super();
  }
  ngOnDestroy() {
    this.destroy();
  }
}
