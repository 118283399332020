<div class="abonents-delegation-popup">
  <app-info-block>
    <img
      class="abonents-delegation-popup-info__image"
      info-block-image
      [src]="resourcesHelper.getResource(resourcePath.INFO_LG) | safeUrl"
      [srcset]="resourcesHelper.getResource(resourcePath.INFO_LG, true) | safeUrl"
    >

    <ng-container info-block-text>
      <span [innerHTML]="'abonents.delegation.popup.content.confirm.text' | translate: {
        phone_source: (owner.phone.toString() | phone),
        phone_target: (phone.toString() | phone)
      }"></span>
    </ng-container>
  </app-info-block>

  <div class="abonents-delegation-popup-actions">
    <button
      mat-raised-button
      color="primary"
      class="abonents-delegation-popup-actions__button mat-button-lg"
      (click)="onDelegate()"
      [disabled]="loading"
    >
      {{ 'abonents.delegation.popup.content.confirm.button.submit' | translate }}
    </button>
  </div>
</div>
