export enum UserRoleType {
  ROLE_OWNER = 'ROLE_OWNER',
  ROLE_SUPPORT = 'ROLE_SUPPORT',
  ROLE_ENGINEER = 'ROLE_ENGINEER',
  ROLE_ENGINEER_ENTRANCES = 'ROLE_ENGINEER_ENTRANCES',
  ROLE_ENGINEER_ABONENTS = 'ROLE_ENGINEER_ABONENTS',
  ROLE_ENGINEER_SIGNUPS = 'ROLE_ENGINEER_SIGNUPS',
  ROLE_MANUFACTURER = 'ROLE_MANUFACTURER',
  ROLE_MANAGER_MAILINGS = 'ROLE_MANAGER_MAILINGS',
  ROLE_ENGINEER_CAMERAS = 'ROLE_ENGINEER_CAMERAS',
  ROLE_ENGINEER_VIDEO_SURVEILLANCE = 'ROLE_ENGINEER_VIDEO_SURVEILLANCE',
  ROLE_ENGINEER_GATES = 'ROLE_ENGINEER_GATES',
  ROLE_MONITORING_ENTRANCES = 'ROLE_MONITORING_ENTRANCES',
  ROLE_MANAGER_BILLS = 'ROLE_MANAGER_BILLS',
  ROLE_DEMO = 'ROLE_DEMO',
  ROLE_DEMO_DHCP = 'ROLE_DEMO_DHCP',
  BLOCKED_USER = 'BLOCKED_USER',
  ROLE_ADMINISTRATOR = 'ROLE_ADMINISTRATOR',
  ROLE_VIDEODISPATCHER = 'ROLE_VIDEODISPATCHER',
  ROLE_ENGENEER_PERSONAL_SURVEILLANCE = 'ROLE_ENGENEER_PERSONAL_SURVEILLANCE',
}
