import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone, OnChanges,
  OnDestroy,
  PLATFORM_ID,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Tooltip } from 'primeng/tooltip';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


/**
 * TooltipForTruncatedTextDirective extends the Tooltip class and provides functionality for displaying tooltips with full text for the truncated content.
 * Before using need to set styles in handed element for example .some-label { max-width: 340px; text-overflow: ellipsis; overflow: hidden; }
 *                                                                :)
 */


@Directive({
  selector: '[appTooltipForTT]',
})
export class TooltipForTruncatedTextDirective extends Tooltip implements OnChanges, AfterViewInit, OnDestroy {
  @Input('appTooltipForTT') content: string | TemplateRef<HTMLElement> | undefined;
  private on = false;
  private destroy = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) public plId: any,
    public override el: ElementRef,
    public override zone: NgZone,
    public override config: PrimeNGConfig,
    private renderer2: Renderer2,
    private ch: ChangeDetectorRef,
    private viewContainer: ViewContainerRef,
  ) {
    super(el, zone, config, renderer2, ch);
    this.zone.runOutsideAngular(() => {
      fromEvent(window, 'resize')
        .pipe(takeUntil(this.destroy))
        .subscribe((evt) => this.onOff(this.el?.nativeElement?.scrollWidth > this.el?.nativeElement?.offsetWidth));
      fromEvent(this.el.nativeElement, 'mouseover')
        .pipe(takeUntil(this.destroy))
        .subscribe({ next: (event) => this.onOff(this.el?.nativeElement?.scrollWidth > this.el?.nativeElement?.offsetWidth) });
      fromEvent(this.el.nativeElement, 'mouseout')
        .pipe(takeUntil(this.destroy))
        .subscribe({ next: () => this.hide() });
    });
  }

  public override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroy.next();
    this.destroy.complete();
  }

  public override ngOnChanges(simpleChange: SimpleChanges): void {
    super.ngOnChanges(simpleChange);
    if (simpleChange['content']) {
      this.onOff(this.el?.nativeElement?.scrollWidth > this.el?.nativeElement?.offsetWidth);
    }
  }

  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  public override show(): void {
    if (!this.on) {
      return;
    }
    super.show();
  }

  public onOff(on: boolean): void {
    this.on = on;
    if (!on) {
      this.hide();
      return;
    }
    if (this.content) {
      this.setOption({ tooltipLabel: `${this.content}` });

      if (this.active) {
        if (this.content) {
          this.updateTextAndAlignOrShow(this.container?.offsetParent);
        } else {
          this.hide();
        }
      }
    }
  }

  public updateTextAndAlignOrShow(offsetParent: boolean): void {
    if (offsetParent) {
      this.updateText();
      this.align();
      return;
    }
    this.show();
  }

  public override updateText(): void {
    const content = this.getOption('tooltipLabel');
    if (content instanceof TemplateRef) {
      const embeddedViewRef = this.viewContainer.createEmbeddedView(content);
      embeddedViewRef.detectChanges();
      embeddedViewRef.rootNodes.forEach((node) => this.tooltipText.appendChild(node));
    } else if (this.getOption('escape')) {
      if (this.tooltipText) {
        this.tooltipText.innerHTML = '';
        // this.tooltipText.appendChild(document.createTextNode(content));
        this.tooltipText.innerHTML = content;
      }
    } else {
      this.tooltipText.innerHTML = content;
    }
  }
}
