import { NgModule } from '@angular/core';
import { CronDateInputModule } from '@app/shared/components';
import { SharedPipesModule } from '@app/shared/pipes';
import { SharedModule } from '@app/shared/shared.module';
import { RdeaStepperModule } from '@app/shared/templates';
import {
  ComponentLoaderModule,
  DialogWrapperModule,
  InfoBlockModule
} from '@app/shared/ui';
import { ServiceWizardPopupContentModule } from '@app/views/services/components';
import {
  PaymentsWizardServiceComponent,
  PaymentsWizardRegistrationComponent,
  PaymentsWizardRegistrationCheckpointComponent,
  PaymentsWizardIntegrationComponent,
} from './containers';
import { PaymentsWizardComponent } from './payments-wizard.component';
import { PaymentsToolsModule } from '@app/shared/components/payments/payments-tools.module';
import { NgxTranslateModule } from '@app/translate/translate.module';
import { NgOptimizedImage } from '@angular/common';

@NgModule({
  imports: [
    SharedModule,
    RdeaStepperModule,
    DialogWrapperModule,
    SharedPipesModule,
    InfoBlockModule,
    CronDateInputModule,
    ComponentLoaderModule,
    ServiceWizardPopupContentModule,
    PaymentsToolsModule,
    NgxTranslateModule,
    NgOptimizedImage
  ],
  declarations: [
    PaymentsWizardServiceComponent,
    PaymentsWizardRegistrationComponent,
    PaymentsWizardRegistrationCheckpointComponent,
    PaymentsWizardIntegrationComponent,
    PaymentsWizardComponent
  ],
  exports: [PaymentsWizardComponent]
})
export class PaymentsWizardModule {}
