import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MaterialModule} from '@app/material.module';
import {
  DeleteEntranceContentModule,
  DeleteIntercomContentModule,
  EntranceEditContainerModule,
  IntercomEditContentModule,
  RdaTranslationsEditorModule
} from '@app/shared/components';
import {RdeaStepperModule} from '@app/shared/templates';
import {ComponentLoaderModule, DialogWrapperModule, InfoBlockModule} from '@app/shared/ui';
import {ServiceIntercomPanelsModule} from '@app/views/services/components';
import {SoftwareIntercomCameraPopupContentModule} from '../software-intercom-camera-popup';
import {
  SoftwareIntercomWizardContentCameraComponent,
  SoftwareIntercomWizardContentChecklistComponent,
  SoftwareIntercomWizardContentComponent,
  SoftwareIntercomWizardContentEntranceComponent,
  SoftwareIntercomWizardContentIntercomComponent,
  SoftwareIntercomWizardContentIntercomPanelsComponent,
  SoftwareIntercomWizardContentTranslationsComponent
} from './software-intercom-wizard-content';
import {SoftwareIntercomWizardPopupComponent} from './software-intercom-wizard-popup.component';
import {SoftwareIntercomWizardContentTariffComponent} from '@app/views/services/submodules/software-intercoms/components/software-intercom-page/popups/software-intercom-wizard-popup/software-intercom-wizard-content/software-intercom-wizard-content-tariff';
import {SoftwareIntercomTariffPopupContentModule} from '@app/views/services/submodules/software-intercoms/components/software-intercom-page/popups/software-intercom-tariff-popup';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        DialogWrapperModule,
        RdeaStepperModule,
        InfoBlockModule,
        EntranceEditContainerModule,
        DeleteEntranceContentModule,
        IntercomEditContentModule,
        DeleteIntercomContentModule,
        RdaTranslationsEditorModule,
        ServiceIntercomPanelsModule,
        ComponentLoaderModule,
        SoftwareIntercomCameraPopupContentModule,
        SoftwareIntercomTariffPopupContentModule,
        NgxTranslateModule
    ],
  providers: [],
  declarations: [
    SoftwareIntercomWizardPopupComponent,
    SoftwareIntercomWizardContentComponent,
    SoftwareIntercomWizardContentEntranceComponent,
    SoftwareIntercomWizardContentIntercomComponent,
    SoftwareIntercomWizardContentTranslationsComponent,
    SoftwareIntercomWizardContentCameraComponent,
    SoftwareIntercomWizardContentChecklistComponent,
    SoftwareIntercomWizardContentIntercomPanelsComponent,
    SoftwareIntercomWizardContentTariffComponent
  ],
  exports: [
    SoftwareIntercomWizardPopupComponent
  ]
})
export class SoftwareIntercomWizardPopupModule {
}
