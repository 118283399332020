import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Flat } from '@app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-flats-card',
  templateUrl: './flats-card.component.html',
  styleUrls: ['./flats-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatsCardComponent {
  readonly displayedColumns = ['number', 'softwareIntercomOwner', 'hardwareIntercomOwner', 'virtual'];

  @Input() flats: Flat[];
  @Input() rdaExists: boolean;
  @Input() loading: boolean;

  xlWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.XL_W_DOWN);

  constructor(
    private resolution: ResolutionService
  ) { }
}
