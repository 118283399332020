<div class="component">
  <app-navbar
    *ngIf="showMenu"
    [links]="steps"
    [active]="mode"
    [centered]="true"
    (select)="onChangePage($event.idx)"
  ></app-navbar>

  <app-intercom-edit-content
    *ngIf="mode === pageMode.INTERCOM"
    [rda]="intercom"
    [loading]="loading"
    [pbxOnRda]="pbxOnRda"
    [intercomTypes]="intercomTypes"
    [filter]="onlyIpIntercoms ? intercomEditFilter.IP_TYPE : ''"
    (updateIntercom)="onUpdateIntercom($event.request)"
    (changeIntercom)="onChangeIntercom($event.rda)"
    (changeIntercomType)="onChangeIntercomType($event.intercomType)"
  ></app-intercom-edit-content>

  <app-rda-translations-editor
    *ngIf="mode === pageMode.TRANSLATIONS"
    [rda]="intercom"
    [loading]="loading"
    [range]="[address?.entrance?.flatStart, address?.entrance?.flatEnd]"
    [additionalRanges]="address?.entrance?.additionalFlatRanges"
    [translationTunings]="translationTunings"
    (saveTranslation)="onSaveTranslation($event.translation)"
    (addTranslationTuning)="onAddTranslationTuning($event.request)"
    (deleteTranslationTuning)="onDeleteTranslationTuning($event.translationTuningId)"
  ></app-rda-translations-editor>

  <app-service-intercom-panels
    *ngIf="mode === pageMode.INTERCOM_PANELS"
    [mode]="intercomPanelsMode"
    [intercom]="intercom"
    [locations]="camerasLocations"
    [intercomPanels]="intercom?.intercoms"
    [loading]="loading"
    [editHidden]="!intercom?.intercomType?.protocol?.ipType && !intercom?.intercomType?.protocol?.intercomIndexesRequired"
    [addHidden]="!intercom?.intercomType?.protocol?.intercomIndexesRequired"
    [deleteHidden]="intercom?.intercoms?.length < 2"
    (changePageMode)="onChangeIntercomPanelsMode($event.mode)"
    (addIntercomPanel)="onAddIntercomPanel($event.request)"
    (updateIntercomPanel)="onEditIntercomPanel($event.request, $event.intercomPanelId, $event.rdaId)"
    (deleteIntercomPanel)="onDeleteIntercomPanel($event.intercomPanelId, $event.rdaId)"
  ></app-service-intercom-panels>

  <app-pbx-on-rda-edit-content
    *ngIf="mode === pageMode.PBX_ON_RDA"
    [loading]="loading"
    [pbxOnRda]="pbxOnRda"
    (connectPbxOnRda)="onConnectPbxOnRda($event.pbxOnRda)"
    (changePbxOnRda)="onChangePbxOnRda($event.oldPbxOnRda, $event.newPbxOnRda)"
    (disconnectPbxOnRda)="onDisconnectPbxOnRda($event.pbxOnRda)"
  ></app-pbx-on-rda-edit-content>
</div>
