import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RemovePopupComponent } from '@app/shared/components';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { Account } from '@app/shared/entities/rd/account/models';
import { Constants } from '@app/shared/helpers';
import { EditPopupComponent } from '@app/shared/templates';
import { AccountServiceData } from '@app/views/abonents/models';
import { EditAccountFormComponent } from './edit-account-form/edit-account-form.component';
import {
  ServiceUnblockAccountPopupComponent
} from '@app/views/abonents/components/containers/abonent-accounts/unblock-non-payment-account/service-unblock-account-popup/service-unblock-account-popup.component';
import { DialogWrapperData } from '@app/shared/ui';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-abonent-accounts',
  templateUrl: './abonent-accounts.component.html',
  styleUrls: ['./abonent-accounts.component.scss'],
})
export class AbonentAccountsComponent {
  readonly displayedAccountsColumns: string[] = ['blocked', 'number', 'actions'];

  @Input() abonentId: number;
  @Input() accounts: Account[];
  @Input() services: AccountServiceData[];
  @Output() selectAccount: EventEmitter<{ accountId: number }> = new EventEmitter();
  @Output() editAccount: EventEmitter<{ accountId: number, accountNumber: string }> = new EventEmitter();
  @Output() deleteAccount: EventEmitter<{ accountId: number }> = new EventEmitter();
  @Output() blockAccount: EventEmitter<{ accountId: number }> = new EventEmitter();
  @Output() unblockAccount: EventEmitter<{ accountId: number }> = new EventEmitter();
  expandedIdx: number;
  expandedElement: any;
  currentAccount: Account;

  constructor(
    private dialog: MatDialog,
    private resolutionService: ResolutionService,
    private translate: TranslateService
  ) { }

  onSelect(account: Account, idx: number) {
    if (this.currentAccount?.id === account.id) {
      this.currentAccount = null;
      this.expandedIdx = null;
    } else {
      this.currentAccount = account;
      this.expandedIdx = idx;
      this.selectAccount.emit({ accountId: this.currentAccount.id });
    }
  }

  onEdit(account: Account) {
    this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data: {
        title: this.translate.instant('abonent.accounts.edit.title'),
        providedData: { 
          accountNumber: account.number,
          isMobile: this.resolutionService.isMobile
        },
        component: EditAccountFormComponent,
        componentName: 'EditAbonentAccount',
        submit: (accountNumber: string) => {
          if (accountNumber) {
            this.editAccount.emit({ accountId: account.id, accountNumber });
          }
        }
      }
    });
  }

  onDelete(accountId: number) {
    this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolutionService.isMobile ? '100%' : '700px',
      data: {
        title: this.translate.instant('abonent.accounts.delete.title'),
        providedData: {
          text: this.translate.instant('abonent.accounts.delete.text'),
          btnText: this.translate.instant('abonent.accounts.delete.button.submit'),
          id: accountId
        },
        component: RemovePopupComponent,
        componentName: 'DeleteAbonentAccount',
        submit: (isDelete: boolean) => {
          if (isDelete) {
            this.deleteAccount.emit({ accountId });
          }
        }
      }
    });
  }

  onBlock(accountId: number, isBlocked: boolean, blockReason: 'NONPAYMENT' | 'MANUALLY_BLOCKED' | null) {
    if (isBlocked) {
      // todo после создания процедуры на back-end удалить blockReason === null
      if (blockReason === 'NONPAYMENT' || blockReason === null) {
        this.showUnblockAccountPopup(accountId);
      } else {
        this.unblockAccount.emit({ accountId });
      }
    } else {
      this.blockAccount.emit({ accountId });
    }
  }

  private showUnblockAccountPopup(accountId: number): void {
    let dialogRef: MatDialogRef<
      ServiceUnblockAccountPopupComponent,
      DialogWrapperData<number, void>
      > = null;

    const dialogWizard: DialogWrapperData<number, void> = {
      title: this.translate.instant('abonent.accounts.unblock.title'),
      componentName: 'ServiceUnblockAccountPopupComponent',
      body: accountId,
      submit: () => {
        this.unblockAccount.emit({ accountId });
        dialogRef.close();
      },
    };

    dialogRef = this.dialog.open(ServiceUnblockAccountPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data: dialogWizard
    });
  }
}
