import { KeyActivityResult } from './key-activity-result.enum';

export interface KeyActivityEventInfo {
  activityUid: string;
  adapterUid: string;
  releId: number;
  dateCreated: string;
  dateActivated: string;
  result: KeyActivityResult;
  dateCreatedMobile: string;
  dateReceivedMobile: string;
  resultMobile: KeyActivityResult;
}
