import { ReducerName } from '@app/store';
import { DashboardModuleRootState, DashboardModuleState } from '@app/views/dashboard/store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const dashboardFeatureSelector =
    createFeatureSelector<DashboardModuleRootState, DashboardModuleState>(ReducerName.DASHBOARD);

const getState = createSelector(
    dashboardFeatureSelector,
    (state: DashboardModuleState) => state.dashboard
);

const getRdasCounts = createSelector(
    getState,
    state => state.rdasCounts
);

const getCamerasCounts = createSelector(
    getState,
    state => state.camerasCounts
);

const getSoftwareIntercomsCounts = createSelector(
    getState,
    state => state.softwareIntercomsCounts
);

const getVideoSurveillanceCounts = createSelector(
    getState,
    state => state.videoSurveillanceCounts
);

const getGatesCounts = createSelector(
    getState,
    state => state.gatesCounts
);

const getRdosImages = createSelector(
    getState,
    state => state.rdosImages
);

const getLoading = createSelector(
    getState,
    state => state.loading
);

export const dashboardSelector = {
    getState,
    getRdasCounts,
    getCamerasCounts,
    getSoftwareIntercomsCounts,
    getVideoSurveillanceCounts,
    getGatesCounts,
    getRdosImages,
    getLoading
};
