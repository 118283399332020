import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CameraInfoPopupComponent, ConfirmComponentConnectionHelper } from '@app/shared/components';
import { Camera, RdaResponse } from '@app/shared/entities/rd';
import { Constants } from '@app/shared/helpers';
import { Address, ServicesTypes } from '@app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData, DialogWrapperSize } from '@app/shared/ui';
import { ServiceFacade } from '@app/views/services/store';
import { environment } from 'environments/environment';
import { ServiceCameraDeletePopupComponent, ServiceCameraPopupBody, ServiceCameraPopupComponent } from '../../popups';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-service-cameras',
  templateUrl: './service-cameras.component.html',
  styleUrls: ['./service-cameras.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceCamerasComponent {
  readonly displayedColumns: string[] = ['active', 'uri', 'id', 'bitrate', 'latency', 'preview', 'private', 'location', 'actions'];
  readonly rdvaPort = environment.RDVAPort;
  readonly rdvaHlsPort = environment.RDVAHlsPort;

  @Input() cameras!: Camera[];
  @Input() addresses!: Address[];
  @Input() serviceType!: ServicesTypes;
  @Input() disabled: boolean;
  @Input() rdas: RdaResponse[];
  @Input() connectedRdas: RdaResponse[];

  constructor(
    protected dialog: MatDialog,
    protected resolution: ResolutionService,
    protected serviceFacade: ServiceFacade,
    protected confirmComponentConnectionHelper: ConfirmComponentConnectionHelper,
    protected translate: TranslateService
  ) { }

  onInfo(camera: Camera) {
    const data: DialogWrapperData<Camera, null> = {
      title: this.translate.instant('service.cameras.info.title'),
      componentName: `${this.serviceType}CameraInfoPage`,
      body: camera
    };

    this.dialog.open(CameraInfoPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.SM,
      data
    });
  }

  onAdd() {
    const data: DialogWrapperData<Partial<ServiceCameraPopupBody>, { camera: Camera }> = {
      title: this.translate.instant('service.cameras.add.title'),
      componentName: `Add${this.serviceType}Camera`,
      body: {},
      submit: (event: { camera: Camera }) =>
        this.confirmComponentConnectionHelper.showBottomSheet(() =>
          this.serviceFacade.addServiceCamera(event.camera)
        )
    };

    this.dialog.open(ServiceCameraPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.SM,
      data
    });
  }

  onEdit(camera: Camera) {

    this.serviceFacade.activeServiceOnvif(camera);

    const data: DialogWrapperData<Partial<ServiceCameraPopupBody>, { camera: Camera }> = {
      title: this.translate.instant('service.cameras.edit.title'),
      componentName: `Edit${this.serviceType}Camera`,
      body: { camera },
      submit: (event: { camera: Camera }) => {
        this.serviceFacade.updateServiceCamera(event.camera);
        this.serviceFacade.activeServiceOnvif(event.camera);
      },
      submitOnvif: (event: { camera: Camera }) => this.serviceFacade.tuneServiceOnvif(event.camera)
    };

    this.dialog.open(ServiceCameraPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.SM,
      data
    });
  }

  onDelete(cameraId: number) {
    const data: DialogWrapperData<{ cameraId: number }, null> = {
      title: this.translate.instant('service.cameras.delete.title'),
      componentName: `Delete${this.serviceType}Camera`,
      body: { cameraId },
      submit: () =>
        this.serviceFacade.deleteServiceCamera(cameraId)
    };

    this.dialog.open(ServiceCameraDeletePopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.SM,
      data
    });
  }

  onRefreshCameras() {
    this.serviceFacade.getServiceCameras();
  }

  onReset(rdvaUri: string, cameraId: number) {
    this.serviceFacade.resetServiceCamera(rdvaUri, cameraId);
  }
}
