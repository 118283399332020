import { Injectable, OnDestroy } from '@angular/core';
import { DialogWrapperStateHelper } from '@app/shared/ui';
import { DeleteServiceRda, DeleteServiceRdaFailure, DeleteServiceRdaSuccess, ServicesActionTypes } from '@app/views/services/store';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ServiceDeleteIntercomPopupService extends DialogWrapperStateHelper<'loading' | 'close' | 'loaded'> implements OnDestroy {
    constructor(
        private actions$: Actions
    ) {
        super();
        this.enableStateListener();
    }

    ngOnDestroy() {
        this.destroy();
    }

    private enableStateListener() {
        this.actions$
            .pipe(
                ofType<DeleteServiceRda>(ServicesActionTypes.DeleteServiceRda), takeUntil(this.onDestroy$),
            )
            .subscribe(() => this.state = 'loading');

        this.actions$
            .pipe(
                ofType<DeleteServiceRdaSuccess>(ServicesActionTypes.DeleteServiceRdaSuccess), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'close');

        this.actions$
            .pipe(
                ofType<DeleteServiceRdaFailure>(ServicesActionTypes.DeleteServiceRdaFailure), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loaded');
    }
}
