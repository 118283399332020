import { Pipe, PipeTransform } from '@angular/core';

export const HTML_FONT_SIZE = 16;
export const LS_SIZE_COEFF = 'BSizeCoeff';

@Pipe({
  name: 'pxToRem',
})
export class PxToRemPipe implements PipeTransform {
  public transform(value: string | number): string {
    return pxToRem(value);
  }
}

export function pxToRem(value: string | number): string {
  const remValue = getRem(value);
  return remValue ? `${remValue}rem` : '0';
}

export function getRem(value: string | number): number {
  const numberValue = typeof value === 'string' ? +value : value;
  if (typeof value === 'string' && Number.isNaN(numberValue)) {
    if (!value) {
      return null;
    }
    if (value.substr(-2) === 'px') {
      const pxValue = value.slice(0, -2);
      return +pxValue / HTML_FONT_SIZE;
    }
    if (value.substr(-3) === 'rem') {
      return +value.slice(0, -3);
    }
    return null;
  }
  return numberValue ? numberValue / HTML_FONT_SIZE : 0;
}

export function remToPx(value: number): number {
  return value * HTML_FONT_SIZE;
}
