<div class="payments-upload-index">
  <ng-container>
    <div
      *ngIf="isUploaded"
      class="result"
    >
      <div class="alert">
        <div class="icon"></div>
        <div class="content">
          <div class="title">
            {{ 'payments.upload.message.confirm.title' | translate }}
          </div>
          <div class="text">
            {{ 'payments.upload.message.confirm.text' | translate }}
          </div>
        </div>
      </div>

      <mat-table
        *ngIf="items?.length > 0"
        [dataSource]="items"
        class="table"
      >
        <mat-header-row
          [style.display]="resolution.isTablet ? 'none' : 'flex'"
          *matHeaderRowDef="tableColumns"
        />

        <mat-row
          *matRowDef="let row; columns: tableColumns;"
        />

        <ng-container matColumnDef="id">
          <mat-header-cell *matHeaderCellDef>
            {{ 'payments.upload.field.id' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.id }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="phone">
          <mat-header-cell *matHeaderCellDef>
            {{ 'payments.upload.field.phone' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.phone }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="number">
          <mat-header-cell *matHeaderCellDef>
            {{ 'payments.upload.field.number' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            <span
              [class]="element.isPaid ? 'green' : 'red'"
            ></span>
            <label>
              {{ element.number }}
            </label>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="address">
          <mat-header-cell *matHeaderCellDef>
            {{ 'payments.upload.field.address' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.address }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="datePeriod">
          <mat-header-cell *matHeaderCellDef>
            {{ 'payments.upload.field.date_period' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.datePeriod }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <mat-header-cell *matHeaderCellDef>
            {{ 'payments.upload.field.created_at' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.createdAt | date: 'dd.MM.yyyy' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="updatedAt">
          <mat-header-cell *matHeaderCellDef>
            {{ 'payments.upload.field.updated_at' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{
              element.isPaid
                ? (element.updatedAt | date:'dd.MM.yyyy')
                : ''
            }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
          <mat-header-cell *matHeaderCellDef>
            {{ 'payments.upload.field.status' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
              <span
                [class]="element.isPaid ? 'green' : 'red'"
              ></span>
              <label>
                {{
                  element.isPaid
                    ? ('payments.upload.enum.status.active' | translate)
                    : ('payments.upload.enum.status.blocked' | translate)
                }}
              </label>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="error">
          <mat-header-cell *matHeaderCellDef>
            {{ 'payments.upload.field.error' | translate }}
          </mat-header-cell>
          <mat-cell *matCellDef="let element">
            {{ element.error }}
          </mat-cell>
        </ng-container>

      </mat-table>
      <div class="buttons">
        <button
          mat-raised-button
          color="primary-inverse"
          class="download"
          [disabled]="isLoading"
          (click)="onCancel()"
        >
          {{ 'payments.upload.button.cancel' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          class="upload"
          [disabled]="isLoading"
          (click)="onUploadConfirm()"
        >
          <mat-spinner
            *ngIf="isLoadingUploadConfirm"
            [diameter]="16"
          />
          {{ 'payments.upload.button.upload_confirm' | translate }}
        </button>
      </div>
    </div>
    <div
      *ngIf="!isUploaded"
      class="empty"
    >
      <div class="icon"></div>
      <div class="text">
        {{ 'payments.upload.message.empty' | translate }}
      </div>
      <div class="buttons">
        <app-payments-upload-buttons
          [isLoading]="isLoading"
          [isLoadingDownload]="isLoadingDownload"
          [isLoadingUpload]="isLoadingUpload"
          (onDownload)="onDownload()"
          (onUpload)="onUpload()"
        />
      </div>
    </div>
    <input
      #fileUpload
      type="file"
      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      (change)="onUploadProcess($event)"
    >
  </ng-container>
</div>
