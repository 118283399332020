<div
  class="payments-wizard-registration-checkpoint tpl-checklist-item"
  [class.tpl-checklist-item--selected]="current === target"
  [class.tpl-checklist-item--success]="current > target"
>
  <div class="tpl-checklist-item-number">
    <p
      class="tpl-checklist-item-number__label"
      *ngIf="current <= target; else completeStepNumber"
    >
      {{ number }}
    </p>
  </div>

  <div class="tpl-checklist-item-content">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #completeStepNumber>
  <mat-icon class="tpl-complete-step">
    done
  </mat-icon>
</ng-template>
