import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PopupBasicComponent } from '@app/shared/templates';
import { Subject } from 'rxjs';
import { createAddModelForm } from './add-model.model';

@Component({
  selector: 'app-add-model-form',
  templateUrl: './add-model-form.component.html',
  styleUrls: ['./add-model-form.component.scss']
})
export class AddModelFormComponent extends PopupBasicComponent implements OnInit {
  form: UntypedFormGroup;

  constructor(
    @Inject('data') public data: { modelType: string },
    @Inject('subject') private subject: Subject<any>
  ) {
    super();
  }

  ngOnInit() {
    this.form = createAddModelForm();
  }

  onSubmit() {
    if (this.form.valid) {
      const value = this.form.getRawValue();
      this.subject.next(value.name);
    }
  }
}
