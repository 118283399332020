import {Injectable, OnDestroy} from '@angular/core';
import {forkJoin, Observable, of, Subject, zip} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';

import {ServiceEntrancesFormatter} from '@app/views/services/components';
import {
  Camera,
  CameraApiService,
  EntranceService,
  FlatApiService,
  IntercomKeyRelay,
  IntercomPanelApiService,
  IpIntercomKeysRequest,
  KeysApiService,
  KeysResponse,
  ProtocolTypes,
  RdaApiService,
  RdaKeysRequest,
  RdaResponse,
  RdaUpdateRequest,
  RdaUtilsService,
  ServiceApiService,
  ServiceCamerasV2Response,
  ServiceCreateConnectionRequest,
  ServiceInfoResponse,
  ServiceResponse
} from '@app/shared/entities/rd';
import {Address, EntrancePageResponse, EntranceRegistration, EntranceUpdateRequest} from '@app/shared/models';
import {ServiceConnectionWithType} from '@app/views/services/models';
import {ServiceBlockRequest} from '../models';
import {HttpErrorResponse} from '@angular/common/http';
import {AddressFormatter} from '@app/shared/services';

@Injectable()
export class ServicePageActionsService {
  constructor(
    private rdaApiService: RdaApiService,
    private keysApiService: KeysApiService,
    private entranceService: EntranceService,
    private cameraApiService: CameraApiService,
    private serviceApiService: ServiceApiService,
    private intercomPanelApiService: IntercomPanelApiService,
    private rdaUtilsService: RdaUtilsService,
    private flatApiService: FlatApiService,
    private addressFormatter: AddressFormatter
  ) {
  }

  getService(serviceId: number): Observable<Partial<ServiceResponse>> {
    const requests: Observable<any>[] = [
      this.serviceApiService.getInfo(serviceId),
      this.serviceApiService.getEntrances(serviceId),
      this.serviceApiService.getRdas(serviceId),
      this.serviceApiService.getCamerasV2(serviceId),
      this.serviceApiService.getKeys(serviceId),
      this.cameraApiService.getCamerasList(null, null, null, null, serviceId)
    ];

    const mergeCameras = (v2Cameras: ServiceCamerasV2Response[], cameras: Camera[]): Camera[] => {
      return v2Cameras.map(camerasResponse => {
        const v2Camera = Object.assign(camerasResponse?.camera, camerasResponse?.intercomId);
        const camera = cameras.find(c => c?.id === v2Camera?.id);

        if (camera === undefined) {
          return v2Camera;
        }

        v2Camera.bitrate = camera?.bitrate;
        v2Camera.segmentLength = camera?.segmentLength;
        v2Camera.segmentsCount = camera?.segmentsCount;
        v2Camera.onvifPort = camera?.onvifPort;

        return v2Camera;
      });
    };

    return forkJoin(requests).pipe(
      map((responses: [ServiceInfoResponse, Address[], RdaResponse[], ServiceCamerasV2Response[], KeysResponse[], Camera[]]) => {
        let id = null;
        let name = null;
        let type = null;
        let customName = null;
        let companyId = null;
        let entrances = null;
        let rdas = null;
        let cameras = null;
        let keys = null;
        let dependantServices = null;
        let tariff = null;

        try {
          if (responses[0]) {
            id = responses[0]?.id;
            name = responses[0]?.name;
            type = responses[0]?.type;
            customName = responses[0]?.customName ? responses[0]?.customName : null;
            companyId = responses[0]?.company?.id;
            dependantServices = responses[0]?.dependantServices;
            tariff = responses[0]?.tariff;
          }
          if (responses[1]) {
            entrances = ServiceEntrancesFormatter.formatEntrances(responses[1], this.addressFormatter);
          }
          if (responses[2]) {
            rdas = responses[2];
          }
          if (responses[4]) {
            keys = responses[4];
          }
          cameras = mergeCameras(responses[3], responses[5]);
        } catch (e) {
          console.warn(e);
        }

        const service: Partial<ServiceResponse> = {
          id: id,
          name: name,
          type: type,
          customName: customName,
          companyId: companyId,
          entrances: entrances,
          rdas: rdas,
          cameras: cameras,
          keys: keys,
          dependantServices: dependantServices,
          tariff: tariff
        };
        return service;
      })
    );
  }

  async addCamera(serviceId: number, camera: Camera, intercomPanelId?: number): Promise<Camera> {
    try {
      const createdCamera: Camera = await this.cameraApiService.addCamera(camera, false).toPromise();
      await this.serviceApiService.connectCamera(serviceId, createdCamera.id, intercomPanelId, camera.deviceId).toPromise();
      return createdCamera;
    } catch (error) {
      throw error;
    }
  }

  async addKey(serviceId: number, rdaUid: string, device: RdaKeysRequest | IpIntercomKeysRequest): Promise<KeysResponse> {
    const createdDevice: KeysResponse = await this.keysApiService.createKey(rdaUid, device, false).toPromise();

    try {
      await this.serviceApiService.connectKey(serviceId, createdDevice.id).toPromise();
      return createdDevice;
    } catch (error) {
      throw error;
    }
  }

  async addEntrance(serviceId: number, entrance: EntranceRegistration, prefix: string): Promise<EntrancePageResponse> {
    const createdEntrance: EntrancePageResponse = await this.entranceService.createAddress(entrance).toPromise();

    try {
      await this.serviceApiService.connectEntrance(serviceId, createdEntrance.id, prefix).toPromise();
      return createdEntrance;
    } catch (error) {
      throw error;
    }
  }

  async updateEntranceWithPrefix(serviceId: number, entranceId: number, request: EntranceUpdateRequest): Promise<void> {
    try {
      await this.entranceService.updateEntrance(entranceId, request).toPromise();
      await this.serviceApiService.connectEntrance(serviceId, entranceId, request.prefix).toPromise();
    } catch (error) {
      throw error;
    }
  }

  async addRda(serviceId: number, rdaUpdateRequest: RdaUpdateRequest): Promise<void> {
    const rdaId: number = rdaUpdateRequest.id;
    const rdaUid: string = rdaUpdateRequest.uid;

    delete rdaUpdateRequest.id;
    delete rdaUpdateRequest.uid;

    try {
      await this.rdaApiService.updateAdapter(rdaUid, rdaUpdateRequest).toPromise();
      await this.serviceApiService.connectRda(serviceId, rdaId).toPromise();
      if (rdaUpdateRequest.protocolNumber !== ProtocolTypes.MetacomWithManyPanels && rdaUpdateRequest.protocolNumber !== ProtocolTypes.Metacom) {
        const keys: KeysResponse[] = await this.keysApiService.getKeys(rdaUid).toPromise();

        const connectRequests = [];
        let hasThirdRelay = false;

        for (let idx = 0; idx < keys.length; ++idx) {
          if (keys[idx].releId === IntercomKeyRelay.THIRD) {
            hasThirdRelay = true;
            if (!keys[idx].service) {
              connectRequests.push(this.serviceApiService.connectKey(serviceId, keys[idx].id));
            }
          }
        }

        if (!hasThirdRelay) {
          const newKey = await this.keysApiService.createKey(rdaUid, {
            authUrl: '',
            locationId: this.keysApiService.defaultKeyLocationId,
            openDoorUrl: '',
            releId: IntercomKeyRelay.THIRD,
            type: this.keysApiService.defaultKeyType
          }, true).toPromise();
          connectRequests.push(this.serviceApiService.connectKey(serviceId, newKey.id));
        }

        await forkJoin(connectRequests).toPromise();
      }
    } catch (error) {
      throw error;
    }
  }

  async replaceRda(serviceId: number, rdaUpdateRequest: RdaUpdateRequest, oldRdaId: number) {
    try {
      await this.serviceApiService.disconnectRda(serviceId, oldRdaId).toPromise();
      await this.addRda(serviceId, rdaUpdateRequest);
    } catch (error) {
      throw error;
    }
  }

  async connectEntrancesIntercoms(serviceId: number, index: string, intercomsIds: number[]) {
    try {
      for (const intercomId of intercomsIds) {
        await this.serviceApiService.connectIntercom(serviceId, {index, rdaId: intercomId}).toPromise();
      }
    } catch (error) {
      throw error;
    }
  }

  async disconnectEntrancesIntercoms(serviceId: number, intercomsIds: number[]) {
    try {
      for (const intercomId of intercomsIds) {
        await this.serviceApiService.disconnectRda(serviceId, intercomId).toPromise();
      }
    } catch (error) {
      throw error;
    }
  }

  async updateEntrancesIntercoms(index: string, intercomsPanelIds: number[]): Promise<void> {
    try {
      for (const intercomPanelId of intercomsPanelIds) {
        await this.intercomPanelApiService.update(intercomPanelId, {index}).toPromise();
      }
    } catch (error) {
      throw error;
    }
  }

  async getRdasForKeys(intercomUids: string[]): Promise<RdaResponse[]> {
    const requests = intercomUids.map((intercomUid: string) =>
      this.rdaApiService.getAdaptersList(0, 1, null, null, null, intercomUid)
        .pipe(
          map(response => response.content[0])
        )
    );

    try {
      if (requests.length > 0) {
        return await forkJoin(requests).toPromise();
      }

      return new Promise<RdaResponse[]>((resolve) => resolve([]));
    } catch (error) {
      throw error;
    }
  }

  async disconnectIntercom(serviceId: number, intercom: RdaResponse) {
    try {
      await this.serviceApiService.disconnectRda(serviceId, intercom.id).toPromise();
    } catch (error) {
      throw error;
    }
  }

  async blockService(request: ServiceBlockRequest): Promise<ServiceConnectionWithType> {
    if (!request.connection?.id) {
      const createConnectionRequest: ServiceCreateConnectionRequest = {
        flatId: request.flatId,
        accountId: request.accountId
      };

      request.connection = {
        ...await this.serviceApiService.createConnection(request.service.id, createConnectionRequest).toPromise(),
        type: request.service.type
      };
    }

    await this.serviceApiService.blockServiceConnection(request.connection.id).toPromise();

    return request.connection;
  }

  async deleteFlat(rdas: RdaResponse[], hardwareServiceConnectionId: number, flatId: number): Promise<void> {
    try {
      const configStr = rdas?.length ? rdas[0].configStr : '';
      const config = this.rdaUtilsService.convertConfigStrToParams(configStr);
      const isControlModeLite = config['control-mode'] === 'lite';
      const intercomTypeExist = !!rdas?.length && !!rdas[0].intercomType;
      const isBeward = intercomTypeExist && rdas[0].intercomType.protocol.number === ProtocolTypes.BEWARD;

      if (isControlModeLite && isBeward && hardwareServiceConnectionId) {
        await this.serviceApiService.unblockServiceConnection(hardwareServiceConnectionId).toPromise();
        await this.flatApiService.remove(flatId).toPromise();

      } else {
        await this.flatApiService.remove(flatId).toPromise();
      }
    } catch (error) {
      throw error;
    }
  }
}
