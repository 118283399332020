import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

interface IStatusState {
  isBlocked: boolean;
  blockReason: 'NONPAYMENT' | 'MANUALLY_BLOCKED' | null;
}

@Pipe({
  name: 'accountStatus'
})
export class AccountStatusPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) {}

  transform(value: IStatusState): unknown {
    if (!value.isBlocked === false) {
      return this.translate.instant('enum.account_status.active');
    } else {
     return value.blockReason === 'NONPAYMENT'
       ? this.translate.instant('enum.account_status.blocked_payment')
       : this.translate.instant('enum.account_status.blocked')
       ;
    }
  }
}
