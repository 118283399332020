<app-dialog-wrapper
  [componentName]="data.componentName"
  [title]="data.title"
>

  <app-delete-service-content
    [serviceName]="data.body.serviceName"
    [serviceType]="data.body.serviceType"
    [loading]="dialogWrapperService.pendingState$ | async"
    (deleteService)="onDelete()"
  ></app-delete-service-content>
</app-dialog-wrapper>