<app-select-search
  [title]="title"
  [required]="required"
  [(ngModel)]="intercomUid"
  [valuesList]="intercoms | map:['select']"
  [selectedValue]="(selectedIntercom$ | async)?.select"
  [disabled]="disabled"
  [showEmptyOption]="showEmptyOption"
  [nonePadding]="withoutDescription"
  [noneMargin]="withoutDescription"
  [loading]="loading$ | async"
  (searchChanged)="onGetIntercoms({ value: $event.value })"
  (valueSelected)="onSelectIntercom($event.value)"
></app-select-search>