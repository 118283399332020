import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-schedule-for-payment',
  templateUrl: './schedule-for-payment.component.html',
  styleUrls: ['./schedule-for-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleForPaymentComponent {
  @Input() invoicingDate: string;
  @Input() reminingDate: string;
  @Input() blockingDate: string;
  @Input() isPrePayment: boolean;
}
