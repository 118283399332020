import { Address } from './address';
import { Abonent } from '@app/shared/models';

export interface Flat {
  id: number;
  rdaUid: string;
  dialed: number;
  address: Address;
  cameraId: number;
  accountId: number;
  virtual?: boolean;
  translated: number;
  isVirtual: boolean;
  softwareIntercomOwner: Abonent;
  hardwareIntercomOwner: Abonent;
  owner?: Abonent;
}
