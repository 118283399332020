import {NgModule} from '@angular/core';
import {DateAdapter} from '@angular/material/core';

import {SharedPipesModule} from '@app/shared';
import {CronDateInputModule, FlatCardModule} from '@app/shared/components';
import {SharedModule} from '@app/shared/shared.module';
import {
  ComponentLoaderModule,
  InfoBlockModule,
  NavbarModule
} from '@app/shared/ui';
import {
  PaymentsCardComponent,
  PaymentsConnectionComponent,
  PaymentsErrorComponent,
  PaymentsListComponent,
  PaymentsPageComponent,
  PaymentsSettingsComponent,
  PaymentsUploadComponent,
  PaymentsUploadIndexComponent,
  PaymentsUploadButtonsComponent,
  PaymentsUploadHistoryComponent,
  PaymentsWizardModule
} from './components';
import {PaymentStatusFilterPipe} from './components/containers/payments-list/status-filter.pipe';
import {PaymentsConfirmComponent} from './components/containers/payments-confirm/payments-confirm.component';
import {
  PaymentsConfirmBottomSheetComponent
} from './components/containers/payments-confirm/payments-confirm-bottom-sheet/payments-confirm-bottom-sheet.component';
import {PaymentsToolsModule} from '@app/shared/components/payments/payments-tools.module';
import {CustomDateAdapter} from './custom.date.adapter';
import {NgxTranslateModule} from '@app/translate/translate.module';
import {CheckboxModule} from 'primeng/checkbox';
import {NgOptimizedImage} from '@angular/common';

@NgModule({
  imports: [
    NavbarModule,
    SharedModule,
    FlatCardModule,
    InfoBlockModule,
    SharedPipesModule,
    ComponentLoaderModule,
    CronDateInputModule,
    PaymentsWizardModule,
    PaymentsToolsModule,
    NgxTranslateModule,
    CheckboxModule,
    NgOptimizedImage
  ],
  declarations: [
    PaymentsCardComponent,
    PaymentsConnectionComponent,
    PaymentsErrorComponent,
    PaymentsListComponent,
    PaymentsPageComponent,
    PaymentsSettingsComponent,
    PaymentsUploadComponent,
    PaymentsUploadIndexComponent,
    PaymentStatusFilterPipe,
    PaymentsConfirmComponent,
    PaymentsConfirmBottomSheetComponent,
    PaymentsUploadIndexComponent,
    PaymentsUploadButtonsComponent,
    PaymentsUploadHistoryComponent
  ],
  exports: [
    PaymentStatusFilterPipe
  ],
  providers: [
    {provide: DateAdapter, useClass: CustomDateAdapter}
  ]
})

export class PaymentsModule {
}
