import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

export const translationTuningFormGroup = () => {
    const controls = {
        fromNum: new UntypedFormControl(null, Validators.required),
        toNum: new UntypedFormControl(null, Validators.required),
        translation: new UntypedFormControl(null, Validators.required),
    };

    return new UntypedFormGroup(controls);
};
