import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';

import {FileResponse} from '@app/shared/api';
import {
  CoreActionTypes,
  CoreChangePassword,
  CoreChangePasswordFailure,
  CoreChangePasswordSuccess,
  CoreClearState,
  CoreGetActiveSignUpsCount,
  CoreGetActiveSignUpsCountFailure,
  CoreGetActiveSignUpsCountSuccess,
  CoreGetCompanyPaymentInfo,
  CoreGetCompanyPaymentInfoFailure,
  CoreGetCompanyPaymentInfoSuccess,
  CoreGetLicenseeReport,
  CoreGetLicenseeReportFailure,
  CoreGetLicenseeReportSuccess,
  CoreGetUnresolvedIssuesCount,
  CoreGetUnresolvedIssuesCountFailure,
  CoreGetUnresolvedIssuesCountSuccess,
  CoreGetUsageStatisticsReport,
  CoreGetUsageStatisticsReportFailure,
  CoreGetUsageStatisticsReportSuccess
} from '@app/core/store/core.actions';
import {SnackbarService} from '@app/shared/components/snackbar';
import {LocalStorageCoreKey} from '@app/shared/entities/common';
import {
  AbonentService,
  PaymentInfoResponse,
  ReportsApiService,
  ReportsUtils,
  UserService
} from '@app/shared/entities/rd';
import {Dictionary, parseError} from '@app/shared/helpers';
import {Abonent, PagedResponse, SignUpStatus} from '@app/shared/models';
import {RequestsService} from '@app/shared/services';
import {DialogWrapperService} from '@app/shared/ui';
import {CoreFacade} from './core.facade';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class CoreEffects {
  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private coreFacade: CoreFacade,
    private userService: UserService,
    private snackbar: SnackbarService,
    private abonentService: AbonentService,
    private signUpsService: RequestsService,
    private reportsApiService: ReportsApiService,
    private dialogWrapperService: DialogWrapperService,
    private translate: TranslateService
  ) {
  }

  getActiveSignUpsCount$ = createEffect(() => this.actions$.pipe(
    ofType<CoreGetActiveSignUpsCount>(CoreActionTypes.CoreGetActiveSignUpsCount),
    switchMap(() =>
      this.signUpsService.getSignUpsCount()
        .pipe(
          map((response: Dictionary<SignUpStatus>[]) => {
            const count: number = Object
              .keys(response)
              .map(key => response[key] > 0 ? 1 : 0)
              .reduce((a, b) => a + b, 0);

            this.coreFacade.addValueToLocalStorage(LocalStorageCoreKey.ACTIVE_SIGNUPS, count);
            return new CoreGetActiveSignUpsCountSuccess(count);
          }),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.showMessage(
              this.translate.instant('core.message.active_sign_ups_count.failed', {
                text: parseError(error)
              })
            );
            return of(new CoreGetActiveSignUpsCountFailure());
          })
        )
    ),
  ));

  getUnresolvedIssuesCount$ = createEffect(() => this.actions$.pipe(
    ofType<CoreGetUnresolvedIssuesCount>(CoreActionTypes.CoreGetUnresolvedIssuesCount),
    switchMap(() =>
      this.abonentService.getPage(0, 1, false)
        .pipe(
          map((response: PagedResponse<Abonent>) => {
            this.coreFacade.addValueToLocalStorage(LocalStorageCoreKey.UNRESOLVED_ISSUES, response.totalElements);
            return new CoreGetUnresolvedIssuesCountSuccess(response.totalElements);
          }),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.showMessage(
              this.translate.instant('core.message.unresolved_issues_count.failed', {
                text: parseError(error)
              })
            );
            return of(new CoreGetUnresolvedIssuesCountFailure());
          })
        )
    ),
  ));

  getCompanyPaymentInfo$ = createEffect(() => this.actions$.pipe(
    ofType<CoreGetCompanyPaymentInfo>(CoreActionTypes.CoreGetCompanyPaymentInfo),
    switchMap((action: CoreGetCompanyPaymentInfo) =>
      from(this.reportsApiService.getPaymentInfo(action.date))
        .pipe(
          map((response: PaymentInfoResponse) => {
            this.coreFacade.addValueToLocalStorage(LocalStorageCoreKey.PAYMENT_INFO, response.paymentSum);
            return new CoreGetCompanyPaymentInfoSuccess(response.paymentSum);
          }),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.showMessage(
              this.translate.instant('core.message.company_payment_info.failed', {
                text: parseError(error)
              })
            );
            return of(new CoreGetCompanyPaymentInfoFailure());
          })
        )
    ),
  ));


  getLicenseeReport$ = createEffect(() => this.actions$.pipe(
    ofType<CoreGetLicenseeReport>(CoreActionTypes.CoreGetLicenseeReport),
    switchMap(action => {
      this.dialogWrapperService.pendingState = true;

      return this.reportsApiService.getLicenseeReport(action.request)
        .pipe(
          map((response: FileResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.handleGetReportAction(response);
            return new CoreGetLicenseeReportSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.snackbar.showMessage(
              this.translate.instant('core.message.licensee_report.failed', {
                text: parseError(error)
              })
            );
            return of(new CoreGetLicenseeReportFailure());
          })
        );
    }),
  ));

  getUsageStatisticsReport$ = createEffect(() => this.actions$.pipe(
    ofType<CoreGetUsageStatisticsReport>(CoreActionTypes.CoreGetUsageStatisticsReport),
    switchMap(action => {
      this.dialogWrapperService.pendingState = true;

      return this.reportsApiService.getUsageStatisticsReport(action.request)
        .pipe(
          map((response: FileResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.handleGetReportAction(response);
            return new CoreGetUsageStatisticsReportSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.snackbar.showMessage(
              this.translate.instant('core.message.usage_statistics_report.failed', {
                text: parseError(error)
              })
            );
            return of(new CoreGetUsageStatisticsReportFailure());
          })
        );
    }),
  ));


  changePassword$ = createEffect(() => this.actions$.pipe(
    ofType<CoreChangePassword>(CoreActionTypes.CoreChangePassword),
    switchMap(action => {
      this.dialogWrapperService.pendingState = true;

      return this.userService.changePassword(action.request)
        .pipe(
          map(() => {
            this.dialogWrapperService.pendingState = false;
            this.snackbar.showMessage(
              this.translate.instant('core.message.change_password.success'),
              'success'
            );
            return new CoreChangePasswordSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.snackbar.showMessage(
              this.translate.instant('core.message.change_password.failed', {
                text: parseError(error)
              })
            );
            return of(new CoreChangePasswordFailure());
          })
        );
    }),
  ));

  clearState$ = createEffect(() => this.actions$.pipe(
    ofType<CoreClearState>(CoreActionTypes.CoreClearState),
    tap(() => new CoreClearState())
  ), {dispatch: false});

  private handleGetReportAction(fileResponse: FileResponse) {
    ReportsUtils.downLoadFile(fileResponse.arrayBuffer, decodeURI(fileResponse.fileName));
    this.snackbar.showMessage(
      this.translate.instant('core.message.report.success'),
      'success'
    );
    this.dialog.closeAll();
  }
}
