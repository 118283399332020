import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ShieldModelService } from '@app/views/intercom/services/shield-model.service';
import {
  ActionTypes,
  GetShieldModels,
  GetShieldModelsSuccess,
  GetShieldModelsFailure,
  CreateShieldModel,
  CreateShieldModelSuccess, CreateShieldModelFailure, GetShieldModelsClear, CreateShieldModelClear
} from '../actions/shield-models.actions';

@Injectable()
export class ShieldsModelsEffects {
  @Effect()
  GetShieldModels$ = this.actions$.pipe(
    ofType<GetShieldModels>(ActionTypes.GetShieldModels),
    switchMap(() =>
      this.shieldService.getModels().pipe(
        map(models => new GetShieldModelsSuccess(models)),
        catchError(error => of(new GetShieldModelsFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  GetShieldModelsClear$ = this.actions$.pipe(
    ofType<GetShieldModelsClear>(ActionTypes.GetShieldModelsClear),
    tap(() => new GetShieldModelsClear())
  );

  @Effect()
  CreateShieldModel$ = this.actions$.pipe(
    ofType<CreateShieldModel>(ActionTypes.CreateShieldModel),
    switchMap(action =>
      this.shieldService.createModel(action.name).pipe(
        map(() => new CreateShieldModelSuccess()),
        catchError(error => of(new CreateShieldModelFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  CreateShieldModelClear$ = this.actions$.pipe(
    ofType<CreateShieldModelClear>(ActionTypes.CreateShieldModel),
    tap(() => new CreateShieldModelClear())
  );

  constructor(private actions$: Actions, private shieldService: ShieldModelService) { }
}
