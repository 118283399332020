import { CompanyReportRequest, LicenseeReportRequest, PenetrationReportRequest, ServiceCreateRequest, SignUpsReportRequest } from '@app/shared/entities/rd';
import { ServicesTypes } from '@app/shared/models';
import { DashboardCamerasCounts, DashboardGatesCounts, DashboardRdasCounts, DashboardSoftwareIntercomsCounts, DashboardVideoSurveillanceCounts, RdosImage } from '@app/views/dashboard/models';
import { Action } from '@ngrx/store';

export enum DashboardActionTypes {
    DashboardGetRdasCounts = '[Dashboard] DashboardGetRdasCounts',
    DashboardGetRdasCountsSuccess = '[Dashboard] DashboardGetRdasCounts Success',
    DashboardGetRdasCountsFailure = '[Dashboard] DashboardGetRdasCounts Failure',

    DashboardGetCamerasCounts = '[Dashboard] DashboardGetCamerasCounts',
    DashboardGetCamerasCountsSuccess = '[Dashboard] DashboardGetCamerasCounts Success',
    DashboardGetCamerasCountsFailure = '[Dashboard] DashboardGetCamerasCounts Failure',

    DashboardGetSoftwareIntercomsCounts = '[Dashboard] DashboardGetSoftwareIntercomsCounts',
    DashboardGetSoftwareIntercomsCountsSuccess = '[Dashboard] DashboardGetSoftwareIntercomsCounts Success',
    DashboardGetSoftwareIntercomsCountsFailure = '[Dashboard] DashboardGetSoftwareIntercomsCounts Failure',

    DashboardGetVideoSurveillanceCounts = '[Dashboard] DashboardGetVideoSurveillanceCounts',
    DashboardGetVideoSurveillanceCountsSuccess = '[Dashboard] DashboardGetVideoSurveillanceCounts Success',
    DashboardGetVideoSurveillanceCountsFailure = '[Dashboard] DashboardGetVideoSurveillanceCounts Failure',

    DashboardGetGatesCounts = '[Dashboard] DashboardGetGatesCounts',
    DashboardGetGatesCountsSuccess = '[Dashboard] DashboardGetGatesCounts Success',
    DashboardGetGatesCountsFailure = '[Dashboard] DashboardGetGatesCounts Failure',

    DashboardGetRdosImages = '[Dashboard] DashboardGetRdosImages',
    DashboardGetRdosImagesSuccess = '[Dashboard] DashboardGetRdosImages Success',
    DashboardGetRdosImagesFailure = '[Dashboard] DashboardGetRdosImages Failure',

    DashboardGetBlockedUserInvoiceReport = '[Dashboard] DashboardGetBlockedUserInvoiceReport',
    DashboardGetBlockedUserInvoiceReportSuccess = '[Dashboard] DashboardGetBlockedUserInvoiceReport Success',
    DashboardGetBlockedUserInvoiceReportFailure = '[Dashboard]DashboardGetBlockedUserInvoiceReport Failure',

    DashboardGetPenetrationReport = '[Dashboard] DashboardGetPenetrationReport',
    DashboardGetPenetrationReportSuccess = '[Dashboard] DashboardGetPenetrationReport Success',
    DashboardGetPenetrationReportFailure = '[Dashboard]DashboardGetPenetrationReport Failure',

    DashboardGetLicenseeReport = '[Dashboard] DashboardGetLicenseeReport',
    DashboardGetLicenseeReportSuccess = '[Dashboard] DashboardGetLicenseeReport Success',
    DashboardGetLicenseeReportFailure = '[Dashboard]DashboardGetLicenseeReport Failure',

    DashboardGetUsageStatisticsReport = '[Dashboard] DashboardGetUsageStatisticsReport',
    DashboardGetUsageStatisticsReportSuccess = '[Dashboard] DashboardGetUsageStatisticsReport Success',
    DashboardGetUsageStatisticsReportFailure = '[Dashboard]DashboardGetUsageStatisticsReport Failure',

    DashboardGetCompanyReport = '[Dashboard] DashboardGetCompanyReport',
    DashboardGetCompanyReportSuccess = '[Dashboard] DashboardGetCompanyReport Success',
    DashboardGetCompanyReportFailure = '[Dashboard]DashboardGetCompanyReport Failure',

    DashboardGetCompanyPeriodicReport = '[Dashboard] DashboardGetCompanyPeriodicReport',
    DashboardGetCompanyPeriodicReportSuccess = '[Dashboard] DashboardGetCompanyPeriodicReport Success',
    DashboardGetCompanyPeriodicReportFailure = '[Dashboard]DashboardGetCompanyPeriodicReport Failure',

    DashboardGetSignUpsReport = '[Dashboard] DashboardGetSignUpsReport',
    DashboardGetSignUpsReportSuccess = '[Dashboard] DashboardGetSignUpsReport Success',
    DashboardGetSignUpsReportFailure = '[Dashboard]DashboardGetSignUpsReport Failure',

    DashboardCreateService = '[Dashboard] DashboardCreateService',
    DashboardCreateServiceSuccess = '[Dashboard] DashboardCreateService Success',
    DashboardCreateServiceFailure = '[Dashboard]DashboardCreateService Failure',

    ClearDashboardStore = '[Dashboard] ClearDashboardStore Failure'
}

export class DashboardGetRdasCounts implements Action {
    readonly type = DashboardActionTypes.DashboardGetRdasCounts;

    constructor() { }
}

export class DashboardGetRdasCountsSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardGetRdasCountsSuccess;

    constructor(public response: DashboardRdasCounts) { }
}

export class DashboardGetRdasCountsFailure implements Action {
    readonly type = DashboardActionTypes.DashboardGetRdasCountsFailure;

    constructor() { }
}

export class DashboardGetCamerasCounts implements Action {
    readonly type = DashboardActionTypes.DashboardGetCamerasCounts;

    constructor() { }
}

export class DashboardGetCamerasCountsSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardGetCamerasCountsSuccess;

    constructor(public response: DashboardCamerasCounts) { }
}

export class DashboardGetCamerasCountsFailure implements Action {
    readonly type = DashboardActionTypes.DashboardGetCamerasCountsFailure;

    constructor() { }
}

export class DashboardGetSoftwareIntercomsCounts implements Action {
    readonly type = DashboardActionTypes.DashboardGetSoftwareIntercomsCounts;

    constructor() { }
}

export class DashboardGetSoftwareIntercomsCountsSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardGetSoftwareIntercomsCountsSuccess;

    constructor(public response: DashboardSoftwareIntercomsCounts) { }
}

export class DashboardGetSoftwareIntercomsCountsFailure implements Action {
    readonly type = DashboardActionTypes.DashboardGetSoftwareIntercomsCountsFailure;

    constructor() { }
}

export class DashboardGetVideoSurveillanceCounts implements Action {
    readonly type = DashboardActionTypes.DashboardGetVideoSurveillanceCounts;

    constructor() { }
}

export class DashboardGetVideoSurveillanceCountsSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardGetVideoSurveillanceCountsSuccess;

    constructor(public response: DashboardVideoSurveillanceCounts) { }
}

export class DashboardGetVideoSurveillanceCountsFailure implements Action {
    readonly type = DashboardActionTypes.DashboardGetVideoSurveillanceCountsFailure;

    constructor() { }
}

export class DashboardGetGatesCounts implements Action {
    readonly type = DashboardActionTypes.DashboardGetGatesCounts;

    constructor() { }
}

export class DashboardGetGatesCountsSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardGetGatesCountsSuccess;

    constructor(public response: DashboardGatesCounts) { }
}

export class DashboardGetGatesCountsFailure implements Action {
    readonly type = DashboardActionTypes.DashboardGetGatesCountsFailure;

    constructor() { }
}

export class DashboardGetRdosImages implements Action {
    readonly type = DashboardActionTypes.DashboardGetRdosImages;

    constructor() { }
}

export class DashboardGetRdosImagesSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardGetRdosImagesSuccess;

    constructor(public response: RdosImage[]) { }
}

export class DashboardGetRdosImagesFailure implements Action {
    readonly type = DashboardActionTypes.DashboardGetRdosImagesFailure;

    constructor() { }
}

export class DashboardGetBlockedUserInvoiceReport implements Action {
    readonly type = DashboardActionTypes.DashboardGetBlockedUserInvoiceReport;

    constructor(public inn: string) { }
}

export class DashboardGetBlockedUserInvoiceReportSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardGetBlockedUserInvoiceReportSuccess;

    constructor() { }
}

export class DashboardGetBlockedUserInvoiceReportFailure implements Action {
    readonly type = DashboardActionTypes.DashboardGetBlockedUserInvoiceReportFailure;

    constructor() { }
}

export class DashboardGetPenetrationReport implements Action {
    readonly type = DashboardActionTypes.DashboardGetPenetrationReport;

    constructor(public request: PenetrationReportRequest) { }
}

export class DashboardGetPenetrationReportSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardGetPenetrationReportSuccess;

    constructor() { }
}

export class DashboardGetPenetrationReportFailure implements Action {
    readonly type = DashboardActionTypes.DashboardGetPenetrationReportFailure;

    constructor() { }
}

export class DashboardGetLicenseeReport implements Action {
    readonly type = DashboardActionTypes.DashboardGetLicenseeReport;

    constructor(public request: LicenseeReportRequest) { }
}

export class DashboardGetLicenseeReportSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardGetLicenseeReportSuccess;

    constructor() { }
}

export class DashboardGetLicenseeReportFailure implements Action {
    readonly type = DashboardActionTypes.DashboardGetLicenseeReportFailure;

    constructor() { }
}

export class DashboardGetUsageStatisticsReport implements Action {
    readonly type = DashboardActionTypes.DashboardGetUsageStatisticsReport;

    constructor(public request: LicenseeReportRequest) { }
}

export class DashboardGetUsageStatisticsReportSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardGetUsageStatisticsReportSuccess;

    constructor() { }
}

export class DashboardGetUsageStatisticsReportFailure implements Action {
    readonly type = DashboardActionTypes.DashboardGetUsageStatisticsReportFailure;

    constructor() { }
}

export class DashboardGetCompanyReport implements Action {
    readonly type = DashboardActionTypes.DashboardGetCompanyReport;

    constructor(public request: CompanyReportRequest) { }
}

export class DashboardGetCompanyReportSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardGetCompanyReportSuccess;

    constructor() { }
}

export class DashboardGetCompanyReportFailure implements Action {
    readonly type = DashboardActionTypes.DashboardGetCompanyReportFailure;

    constructor() { }
}

export class DashboardGetCompanyPeriodicReport implements Action {
    readonly type = DashboardActionTypes.DashboardGetCompanyPeriodicReport;

    constructor(public request: CompanyReportRequest) { }
}

export class DashboardGetCompanyPeriodicReportSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardGetCompanyPeriodicReportSuccess;

    constructor() { }
}

export class DashboardGetCompanyPeriodicReportFailure implements Action {
    readonly type = DashboardActionTypes.DashboardGetCompanyPeriodicReportFailure;

    constructor() { }
}

export class DashboardGetSignUpsReport implements Action {
    readonly type = DashboardActionTypes.DashboardGetSignUpsReport;

    constructor(public request: SignUpsReportRequest) { }
}

export class DashboardGetSignUpsReportSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardGetSignUpsReportSuccess;

    constructor() { }
}

export class DashboardGetSignUpsReportFailure implements Action {
    readonly type = DashboardActionTypes.DashboardGetSignUpsReportFailure;

    constructor() { }
}

export class DashboardCreateService implements Action {
    readonly type = DashboardActionTypes.DashboardCreateService;

    constructor(public serviceType: ServicesTypes, public request: ServiceCreateRequest) { }
}

export class DashboardCreateServiceSuccess implements Action {
    readonly type = DashboardActionTypes.DashboardCreateServiceSuccess;

    constructor() { }
}

export class DashboardCreateServiceFailure implements Action {
    readonly type = DashboardActionTypes.DashboardCreateServiceFailure;

    constructor() { }
}

export class ClearDashboardStore implements Action {
    readonly type = DashboardActionTypes.ClearDashboardStore;

    constructor() { }
}

export type DashboardActions =
    | DashboardGetRdasCounts
    | DashboardGetRdasCountsSuccess
    | DashboardGetRdasCountsFailure

    | DashboardGetCamerasCounts
    | DashboardGetCamerasCountsSuccess
    | DashboardGetCamerasCountsFailure

    | DashboardGetSoftwareIntercomsCounts
    | DashboardGetSoftwareIntercomsCountsSuccess
    | DashboardGetSoftwareIntercomsCountsFailure

    | DashboardGetVideoSurveillanceCounts
    | DashboardGetVideoSurveillanceCountsSuccess
    | DashboardGetVideoSurveillanceCountsFailure

    | DashboardGetGatesCounts
    | DashboardGetGatesCountsSuccess
    | DashboardGetGatesCountsFailure

    | DashboardGetRdosImages
    | DashboardGetRdosImagesSuccess
    | DashboardGetRdosImagesFailure

    | DashboardGetBlockedUserInvoiceReport
    | DashboardGetBlockedUserInvoiceReportSuccess
    | DashboardGetBlockedUserInvoiceReportFailure

    | DashboardGetPenetrationReport
    | DashboardGetPenetrationReportSuccess
    | DashboardGetPenetrationReportFailure

    | DashboardGetLicenseeReport
    | DashboardGetLicenseeReportSuccess
    | DashboardGetLicenseeReportFailure

    | DashboardGetUsageStatisticsReport
    | DashboardGetUsageStatisticsReportSuccess
    | DashboardGetUsageStatisticsReportFailure

    | DashboardGetCompanyReport
    | DashboardGetCompanyReportSuccess
    | DashboardGetCompanyReportFailure

    | DashboardGetCompanyPeriodicReport
    | DashboardGetCompanyPeriodicReportSuccess
    | DashboardGetCompanyPeriodicReportFailure

    | DashboardGetSignUpsReport
    | DashboardGetSignUpsReportSuccess
    | DashboardGetSignUpsReportFailure

    | DashboardCreateService
    | DashboardCreateServiceSuccess
    | DashboardCreateServiceFailure

    | ClearDashboardStore;
