import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResolutionBreakpoint } from './resolution-breakpoints.enum';

@Injectable({
    providedIn: 'root'
})
export class ResolutionService implements OnDestroy {
    private onDestroy$: Subject<void> = new Subject();
    private activeBreakpoints: Record<string, BehaviorSubject<boolean>> = {};

    constructor(
        private breakpointObserver: BreakpointObserver
    ) { }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    initResolutionChangeDetection(): void {
        const breakpoints: string[] = Object.values(ResolutionBreakpoint);

        this.breakpointObserver
            .observe(breakpoints)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((observeResponse: BreakpointState) =>
                this.parseBreakpointsResponse(observeResponse.breakpoints)
            );
    }

    parseBreakpointsResponse(breakpoints: { [key: string]: boolean }): void {
        Object
            .keys(breakpoints)
            .forEach((key: string) => {
                if (this.activeBreakpoints[key]) {
                    this.activeBreakpoints[key].next(breakpoints[key]);
                } else {
                    this.activeBreakpoints[key] = new BehaviorSubject(breakpoints[key]);
                }
            });
    }

    getBreakpoint(breakpoint: ResolutionBreakpoint): Observable<boolean> {
        return this.activeBreakpoints[breakpoint].asObservable();
    }

    getBreakpointState(breakpoint: ResolutionBreakpoint) {
        return this.breakpointObserver.isMatched(breakpoint);
    }
}
