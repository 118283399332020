import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResourcePath } from '@app/shared/entities';
import { ResourcesHelper } from '@app/shared/entities/common';
import { DialogWrapperData } from '@app/shared/ui';
import { ServiceFacade } from '@app/views/services/store';
import { Observable } from 'rxjs';
import { ServiceConnectionDeletePopupBody } from './service-connection-delete-popup-body.model';
import { ServiceConnectionDeletePopupService } from './service-connection-delete-popup.service';

@Component({
  selector: 'app-service-connection-delete-popup',
  templateUrl: './service-connection-delete-popup.component.html',
  styleUrls: ['./service-connection-delete-popup.component.scss'],
  providers: [ServiceConnectionDeletePopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceConnectionDeletePopupComponent implements OnInit {
  readonly resourcePath: typeof ResourcePath = ResourcePath;
  popupState$: Observable<'loading' | 'close' | 'loaded'> = this.popupService.state$;

  constructor(
    public resourcesHelper: ResourcesHelper,
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<ServiceConnectionDeletePopupBody, void>,
    private serviceFacade: ServiceFacade,
    private dialogRef: MatDialogRef<ServiceConnectionDeletePopupComponent>,
    private popupService: ServiceConnectionDeletePopupService
  ) { }

  ngOnInit() {
    this.popupService.addStateChangeListener((state: 'loading' | 'close' | 'loaded') =>
      state === 'close' && this.dialogRef.close()
    );
  }

  onDelete() {
    this.serviceFacade.deleteConnection(this.data.body.connection);
  }
}
