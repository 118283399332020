import { Injectable, OnDestroy } from '@angular/core';
import { DialogWrapperStateHelper } from '@app/shared/ui';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { AddServiceEntrance, AddServiceEntranceFailure, AddServiceEntranceSuccess, ServicesActionTypes, UpdateServiceEntrance, UpdateServiceEntranceFailure, UpdateServiceEntranceSuccess, UpdateServiceEntranceWithPrefix, UpdateServiceEntranceWithPrefixFailure, UpdateServiceEntranceWithPrefixSuccess } from '../../../../store';

@Injectable()
export class ServiceEntrancePopupService extends DialogWrapperStateHelper<'loading' | 'close' | 'loaded'> implements OnDestroy {
    constructor(
        private actions$: Actions
    ) {
        super();
        this.enableStateListeners();
    }

    ngOnDestroy() {
        this.destroy();
    }

    private enableStateListeners() {
        this.actions$
            .pipe(
                ofType<
                    | AddServiceEntrance
                    | UpdateServiceEntrance
                    | UpdateServiceEntranceWithPrefix
                >(...[
                    ServicesActionTypes.AddServiceEntrance,
                    ServicesActionTypes.UpdateServiceEntrance,
                    ServicesActionTypes.UpdateServiceEntranceWithPrefix,
                    ServicesActionTypes.AddAdditionalFlatRange,
                    ServicesActionTypes.UpdateAdditionalFlatRange,
                    ServicesActionTypes.DeleteAdditionalFlatRange,
                ]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loading');

        this.actions$
            .pipe(
                ofType<AddServiceEntranceSuccess>(ServicesActionTypes.AddServiceEntranceSuccess),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'close');

        this.actions$
            .pipe(
                ofType<
                    | AddServiceEntranceFailure
                    | UpdateServiceEntranceSuccess
                    | UpdateServiceEntranceFailure
                    | UpdateServiceEntranceWithPrefixSuccess
                    | UpdateServiceEntranceWithPrefixFailure
                >(...[
                    ServicesActionTypes.AddServiceEntranceFailure,
                    ServicesActionTypes.UpdateServiceEntranceSuccess,
                    ServicesActionTypes.UpdateServiceEntranceFailure,
                    ServicesActionTypes.UpdateServiceEntranceWithPrefixSuccess,
                    ServicesActionTypes.UpdateServiceEntranceWithPrefixFailure,
                    ServicesActionTypes.AddAdditionalFlatRangeSuccess,
                    ServicesActionTypes.AddAdditionalFlatRangeFailure,
                    ServicesActionTypes.UpdateAdditionalFlatRangeSuccess,
                    ServicesActionTypes.UpdateAdditionalFlatRangeFailure,
                    ServicesActionTypes.DeleteAdditionalFlatRangeSuccess,
                    ServicesActionTypes.DeleteAdditionalFlatRangeFailure,
                ]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loaded');
    }
}
