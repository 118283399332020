import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CameraEditContentModule } from '@app/shared/components';
import { DialogWrapperModule } from '@app/shared/ui';
import { ServiceCameraPopupComponent } from './service-camera-popup.component';

@NgModule({
    imports: [
        CommonModule,
        CameraEditContentModule,
        DialogWrapperModule
    ],
    declarations: [
        ServiceCameraPopupComponent,
    ],
    exports: [
        ServiceCameraPopupComponent
    ]
})
export class ServiceCameraPopupModule { }
