<app-dialog-wrapper
  [title]="data.title"
  [progress]="(popupService.state$ | async) === 'loading'"
  [componentName]="data.componentName"
>
  <app-intercom-key-delete-container
    (submit)="onSubmit()"
    [loading]="(popupService.state$ | async) === 'loading'"
    [intercomKeyType]="data.body.intercomKeyType"
    [location]="data.body.location"
  ></app-intercom-key-delete-container>
</app-dialog-wrapper>