import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {
  PERSONAL_AGREEMENT_PAGE_LINK_RU,
  PERSONAL_AGREEMENT_PAGE_LINK_KZ,
  PRIVACY_POLICY_PAGE_LINK_RU,
  PRIVACY_POLICY_PAGE_LINK_KZ
} from '@app/shared/helpers';
import {isValidUrl} from '@app/shared/helpers/isValidLink';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-company-docs',
  templateUrl: './company-docs.component.html',
  styleUrls: ['./company-docs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyDocsComponent {
  public readonly personalAgreementPageLink: string = environment.kz ? PERSONAL_AGREEMENT_PAGE_LINK_KZ : PERSONAL_AGREEMENT_PAGE_LINK_RU;
  public readonly privacyPolicy: string = environment.kz ? PRIVACY_POLICY_PAGE_LINK_KZ : PRIVACY_POLICY_PAGE_LINK_RU;

  private _licenseAgreement: string;

  @Input()
  public get licenseAgreement(): string {
    return this._licenseAgreement;
  }

  public set licenseAgreement(v: string) {
    this._licenseAgreement = isValidUrl(v) ? v : '';
  }
}
