import { CoreActions, CoreActionTypes } from '@app/core/store/core.actions';
import { coreInitialState, CoreState } from '@app/core/store/core.state';
import { ActionReducerMap } from '@ngrx/store';

function reducer(
    state = coreInitialState,
    action: CoreActions
): CoreState {
    switch (action.type) {
        case CoreActionTypes.CoreGetActiveSignUpsCount: {
            return {
                ...state,
                activeSignUps: state.activeSignUps
            };
        }

        case CoreActionTypes.CoreGetActiveSignUpsCountSuccess: {
            return {
                ...state,
                activeSignUps: action.response
            };
        }

        case CoreActionTypes.CoreGetUnresolvedIssuesCount: {
            return {
                ...state,
                unresolvedIssues: state.unresolvedIssues
            };
        }

        case CoreActionTypes.CoreGetUnresolvedIssuesCountSuccess: {
            return {
                ...state,
                unresolvedIssues: action.response
            };
        }

        case CoreActionTypes.CoreGetCompanyPaymentInfo: {
            return {
                ...state,
                payment: state.payment,
                paymentLoading: true
            };
        }

        case CoreActionTypes.CoreGetCompanyPaymentInfoSuccess: {
            return {
                ...state,
                payment: action.response,
                paymentLoading: false
            };
        }
        case CoreActionTypes.CoreGetCompanyPaymentInfoFailure: {
            return;
        }
        case CoreActionTypes.CoreClearState: {
            return {
                ...state = coreInitialState
            };
        }

        default: {
            return state;
        }
    }
}

export const coreReducers: ActionReducerMap<{ core: CoreState }> = {
    core: reducer
};
