import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { PageData } from '@app/shared/models';
import { cloneDeep } from 'lodash';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-paginated-page-wrapper',
  templateUrl: './paginated-page-wrapper.component.html',
  styleUrls: ['./paginated-page-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatedPageWrapperComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject();
  private _paginatorData: PageData;
  readonly filterInput: UntypedFormControl = new UntypedFormControl();
  
  @ViewChild(MatPaginator, { static: true }) readonly paginator: MatPaginator;
  @Input() filterInputPlaceholder: string;
  @Input() set paginatorData(data: PageData) {
    if (data) {
      this._paginatorData = cloneDeep(data);
    }
  }
  get paginatorData() { return this._paginatorData }  

  @Output() private filterInputChanged: EventEmitter<string> = new EventEmitter();
  @Output() private pageChanged: EventEmitter<void> = new EventEmitter();

  ngOnInit() {
    this.paginator.page
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.pageChanged.emit());

    this.filterInput.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((value: string) => this.filterInputChanged.emit(value));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}

