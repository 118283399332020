<div class="software-intercom-wizard-content">
  <app-rdea-stepper
    *ngIf="showMenu"
    [steps]="steps"
    [stepIdx]="wizardStep"
    (changeStep)="onChangeStep($event.stepIdx)"
  ></app-rdea-stepper>

  <ng-container [ngSwitch]="wizardStep">
    <app-software-intercom-wizard-content-entrance
      *ngSwitchCase="step.ADDRESSES"
      [serviceId]="serviceId"
      [loading]="loading"
      [addresses]="addresses"
      class="software-intercom-wizard-content-data"
    ></app-software-intercom-wizard-content-entrance>

    <app-software-intercom-wizard-content-intercom
      *ngSwitchCase="step.INTERCOMS"
      [intercom]="intercoms[0]"
      [serviceId]="serviceId"
      [loading]="loading"
      [intercomTypes]="intercomTypes"
      [filter]="mode === intercoms.length > 1 && allIntercomsIsIp() ? intercomEditFilter.IP_TYPE : ''"
      class="software-intercom-wizard-content-data"
    ></app-software-intercom-wizard-content-intercom>

    <app-software-intercom-wizard-content-translations
      *ngSwitchCase="step.TRANSLATIONS"
      [intercom]="intercoms[0]"
      [serviceId]="serviceId"
      [addresses]="addresses"
      [loading]="loading"
      [translationTuningsDict]="translationTunings"
      [intercomTypes]="intercomTypes"
      class="software-intercom-wizard-content-data"
    ></app-software-intercom-wizard-content-translations>

    <app-software-intercom-wizard-content-intercom-panels
      *ngSwitchCase="step.INTERCOM_PANEL"
      [intercom]="intercoms[0]"
      [locations]="camerasLocations"
      [intercomPanels]="intercoms[0].intercoms"
      [loading]="loading"
      [editHidden]="!intercoms[0].intercomType?.protocol?.ipType && !intercoms[0].intercomType?.protocol?.intercomIndexesRequired"
      [addHidden]="!intercoms[0].intercomType?.protocol?.intercomIndexesRequired"
      [deleteHidden]="!intercoms[0].intercomType?.protocol?.intercomIndexesRequired"
    ></app-software-intercom-wizard-content-intercom-panels>

    <app-software-intercom-wizard-content-camera
      *ngSwitchCase="step.CAMERA"
      [intercoms]="intercoms"
      [loading]="loading"
      [cameras]="cameras"
      [addresses]="addresses"
      [camerasLocations]="camerasLocations"
      [onvif]="onvif"
      [avaliableIntercomPanelsLocations]="avaliableIntercomPanelsLocations"
      [intercomPanels]="intercomPanels"
      class="software-intercom-wizard-content-data"
    ></app-software-intercom-wizard-content-camera>
    <ng-container *ngSwitchCase="step.TARIFF">
      <app-software-intercom-wizard-content-tariff
        *ngIf="abonentsBillingEnabled$ | async"
        class="software-intercom-wizard-content-data"
        [serviceId]="serviceId"
      ></app-software-intercom-wizard-content-tariff>
    </ng-container>

    <app-software-intercom-wizard-content-checklist
      *ngSwitchCase="step.CHECKLIST"
      [intercom]="intercoms[0]"
      (changeStep)="onChangeStep($event.stepIdx)"
    ></app-software-intercom-wizard-content-checklist>

    <div
      class="software-intercom-wizard-content-data software-intercom-wizard-content-loader-block"
      *ngSwitchDefault
    >
      <app-component-loader
        sizePx="80"
        class="software-intercom-wizard-content-loader-block__loader"
        [overTheComponent]="false"
      ></app-component-loader>
    </div>
  </ng-container>

  <div *ngIf="showStepButtons" class="software-intercom-wizard-content-actions-block">
    <button
      mat-raised-button
      color="primary-inverse"
      [disabled]="!isPreviousStepEnabled()"
      (click)="onPreviousStep()"
    >
      {{ 'software.intercom.wizard.content.button.back' | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      [disabled]="!isNextStepEnabled()"
      (click)="onNextStep()"
    >
      {{ 'software.intercom.wizard.content.button.next' | translate }}
    </button>
  </div>

</div>
