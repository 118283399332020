export interface UploadPaymentTemplateResponse {
  id: number;
  phone: string;
  number: string;
  address: string;
  status: string;
  error: string;
  isBlocked: boolean;
  isPaid: boolean;
  datePeriod: string;
  createdAt: number;
  updatedAt: number;
}
