import { PlyrTimeHelper } from './plyr-time-helper';

export class PlyrConvertersHelper {
    /**
     * Calculate absolute time using play position in percent
     * @param percent current play position in percent
     * @returns absolute time
     */
    static calculateAbsoluteTimeFromPercent(percent: number) {
        return PlyrTimeHelper.leftBoundTimestampWithOffset + (PlyrTimeHelper.rightBoundTimestampWithOffset - PlyrTimeHelper.leftBoundTimestampWithOffset) * percent / 100;
    }

    /**
     * @param {number} pageX mouse position on the X axis
     * @param {number} progressBarRect progress bar rectangle
     * @returns current play position in percent
    */
    static calculatePercentFromProgressBarRect(pageX: number, progressBarRect: DOMRect): number {
        return this.roundPercentBorders((100 / progressBarRect.width) * (pageX - progressBarRect.left));
    }

    /**
     * @returns current play position in percent
     */
    static getCurrentPlayPercent(absoluteTime: number): number {
        if (!absoluteTime) {
            return 0;
        }

        return this.roundPercentBorders(
            100 * Math.abs(absoluteTime - PlyrTimeHelper.leftBoundTimestampWithOffset) / (PlyrTimeHelper.rightBoundTimestampWithOffset - PlyrTimeHelper.leftBoundTimestampWithOffset)
        );
    }

    /**
     * Check percent bounds between 0 and 100 percents
     * @param percent current play position in percent
     * @returns current play position in percent with rounded bounds
     */
    static roundPercentBorders(percent: number): number {
        if (percent < Number.EPSILON) {
            percent = 0;
        } else if (100 - percent < Number.EPSILON) {
            percent = 100;
        }

        return percent;
    }

    /**
     * Calculate relative percent using absolute video player time and ranges timestamps
     * @param absoluteTime absolute video player time
     * @returns absolute time in percent
     */
    static calculateRelativePercent(absoluteTime: number) {
        return (absoluteTime - PlyrTimeHelper.minTimestampWithOffset) / (PlyrTimeHelper.maxTimestampWithOffset - PlyrTimeHelper.minTimestampWithOffset) * 100;
    }

    static calculateAbsolutePercent(absoluteTime: number) {
        return (absoluteTime - PlyrTimeHelper.leftBoundTimestampWithOffset) / (PlyrTimeHelper.rightBoundTimestampWithOffset - PlyrTimeHelper.leftBoundTimestampWithOffset) * 100;
    }
}
