import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmComponentConnectionHelper, IntercomEditFilter, IntercomsListContentModel } from '@app/shared/components';
import { IntercomType, RdaResponse, RdaUpdateRequest } from '@app/shared/entities/rd';
import { Address } from '@app/shared/models';
import { ServiceFacade } from '@app/views/services/store';
import { GateMode } from '@app/views/services/submodules/gates/models';
import { GatesPageFacade } from '@app/views/services/submodules/gates/store';
import { GateWizardPopupService } from '../../gate-wizard-popup.service';

@Component({
  selector: 'app-gate-wizard-content-intercoms',
  templateUrl: './gate-wizard-content-intercoms.component.html',
  styleUrls: ['./gate-wizard-content-intercoms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GateWizardContentIntercomsComponent implements OnInit {
  readonly mode: typeof GateMode = GateMode;

  @Input() loading: boolean;
  @Input() serviceId: number;
  @Input() addresses: Address[];
  @Input() currentMode: GateMode;
  @Input() filter: IntercomEditFilter;

  @Input() entrancesIntercoms: RdaResponse[];
  @Input() set intercoms(intercoms: RdaResponse[]) {
    this._intercoms = intercoms;

    if (!intercoms?.length) {
      return;
    }

    this.intercom = intercoms?.[0];
  }
  @Input() set selectedIntercom(selectedIntercom: RdaResponse) {
    this._selectedIntercom = selectedIntercom;
  }
  @Input() selectedIntercomType: IntercomType;

  @Input() index: string;
  @Input() intercomTypes: IntercomType[];
  @Input() avaliableIntercoms: IntercomsListContentModel[];
  intercom: RdaResponse;
  intercomUid: string;

  @Output() private changeControlsVisible: EventEmitter<{ showMenu?: boolean, showStepButtons?: boolean }> = new EventEmitter();
  private _intercoms: RdaResponse[];
  private _selectedIntercom: RdaResponse;

  constructor(
    public popupStateService: GateWizardPopupService,
    private serviceFacade: ServiceFacade,
    private confirmComponentConnectionHelper: ConfirmComponentConnectionHelper,
    private gatesPageFacade: GatesPageFacade
  ) { }

  ngOnInit() {
    this.popupStateService.addStateChangeListener(state => state === 'close' && this.onBack());
  }

  get intercoms(): RdaResponse[] {
    return this._intercoms;
  }

  get selectedIntercom(): RdaResponse {
    return this._selectedIntercom;
  }

  onUpdate(request: RdaUpdateRequest) {
    if (!this.intercom) {
      this.confirmComponentConnectionHelper.showBottomSheet(() => {
        this.serviceFacade.addServiceRda(request);
      });

      return;
    }

    if (this.intercom.uid && this.intercom.uid !== request.uid) {
      this.serviceFacade.changeServiceRda(request, this.intercom.id);
      return;
    }

    this.serviceFacade.updateServiceRda(request.uid, request);
  }

  onDelete() {
    this.serviceFacade.deleteServiceRda(this.intercom);
  }

  onAddIntercom() {
    this.changeControlsVisible.emit({ showMenu: false, showStepButtons: false });
  }

  onEditIntercom(intercomIdx: number) {
    this.intercom = this.intercoms[intercomIdx];
    this.changeControlsVisible.emit({ showMenu: false, showStepButtons: false });
  }

  onDeleteIntercom(intercomIdx: number) {
    this.intercom = this.intercoms[intercomIdx];
    this.changeControlsVisible.emit({ showMenu: false, showStepButtons: false });
  }

  onChangeControlsVisible(showStepButtons: boolean) {
    this.changeControlsVisible.emit({ showStepButtons: false });
  }

  onBack() {
    this.intercom = null;
    this.changeControlsVisible.emit({ showMenu: true, showStepButtons: true });
  }

  onSubmitIntercom(request: RdaUpdateRequest) {
    if (!this.intercom) {
      this.confirmComponentConnectionHelper.showBottomSheet(() => {
        this.serviceFacade.addServiceRda(request);
      });

      return;
    }

    if (this.intercom.uid && this.intercom.uid !== request.uid) {
      this.serviceFacade.changeServiceRda(request, this.intercom.id);
      return;
    }

    this.serviceFacade.updateServiceRda(request.uid, request);
  }

  onSubmitIntercomsWithIndex(index: string) {
    if (this.intercoms?.length > 0) {
      this.gatesPageFacade.updateEntranceIntercoms(index);
      return;
    }

    this.gatesPageFacade.connectEntranceIntercoms(index, this.entrancesIntercoms.map(intercom => intercom.id));
  }

  onSubmitIntercoms(intercomsIds: number[]) {
    this.gatesPageFacade.connectEntranceIntercoms(null, intercomsIds);
  }

  onSkipIntercomSelection() {
    const supportIndices: boolean = this.entrancesIntercoms.reduce(
      (prev, cur) => prev && cur?.intercomType?.protocol?.intercomIndexesRequired, true
    ) && this.selectedIntercomType.protocol.intercomIndexesRequired;

    if (supportIndices) {
      return;
    }

    this.gatesPageFacade.connectEntranceIntercoms(null, this.entrancesIntercoms.map(intercom => intercom.id));
  }

  onIntercomSelected(intercom: RdaResponse) {
    this.gatesPageFacade.setIntercom(intercom);
    this.gatesPageFacade.setMode(GateMode.DEFAULT);
  }
}
