import { Camera } from '@app/shared/entities/rd/camera/models';
import { Company } from '@app/shared/entities/rd/company/models';
import { RdaResponse } from '@app/shared/entities/rd/rda';

export interface ServiceCreateResponse {
    cameras: Camera[];
    company: Company;
    customName: string;
    id: number;
    name: string;
    rdas: RdaResponse;
    status: string;
}
