import { Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-reset-password-content',
  templateUrl: './reset-password-content.component.html',
  styleUrls: ['./reset-password-content.component.scss']
})
export class ResetPasswordContentComponent {
  public email: UntypedFormControl;

  @Output() resetPassword: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
    this.email = new UntypedFormControl('', [Validators.required, Validators.email]);
  }

  onResetPassword() {
    this.resetPassword.emit(this.email.value);
  }
}
