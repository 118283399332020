import { createSelector } from '@ngrx/store';
import { AbonentsModuleState } from '../reducers/reducers';
import { abonentsModuleSelector } from './abonents-module.state';

export const getAbonentsState = createSelector(
  abonentsModuleSelector,
  (state: AbonentsModuleState) => state.abonentsPage
);

export const getAbonentsSuccess = createSelector(
  getAbonentsState,
  state => ({ abonents: state.abonents, totalCount: state.totalCount })
);

export const getAbonentsFailure = createSelector(
  getAbonentsState,
  state => state.error
);

export const getAbonentsLoading = createSelector(
  getAbonentsState,
  state => state.loading
);
