import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {SnackbarService} from '@app/shared/components';
import {EntranceService, EntrancesStatus} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {EntrancePageResponse, PagedResponse, SelectSearch} from '@app/shared/models';
import {ComponentStore, tapResponse} from '@ngrx/component-store';
import {Observable, Subject} from 'rxjs';
import {switchMap, takeUntil} from 'rxjs/operators';
import {addressSearchSelectInitialState, AddressSearchSelectState} from './address-search-select.state';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AddressSearchSelectStore extends ComponentStore<AddressSearchSelectState> implements OnDestroy {
  readonly changeModeSelectItem: { value: EntrancePageResponse, select: SelectSearch } = {
    value: null, select: {
      text: this.translate.instant('shared.address.input.search-select.message.not_found'),
      value: -1
    }
  };

  readonly addresses$: Observable<{
    value: EntrancePageResponse;
    select: SelectSearch
  }[]> = this.select(state => state.addresses);
  readonly lodaing$: Observable<boolean> = this.select(state => state.loading);
  readonly selectSearch$: Observable<SelectSearch> = this.select(state => state.addresses?.[state.selectedAddressIdx]?.select);
  readonly selectedAddress$: Observable<EntrancePageResponse> = this.select(state => state.addresses?.[state.selectedAddressIdx]?.value);

  readonly getAddresses = this.effect((options$: Observable<{
      state?: EntrancesStatus,
      address?: string,
      intercomIndexesRequired?: boolean
    }>) =>
      options$.pipe(
        switchMap((options) => {
          this.setLoading(true);

          return this.entranceService.getEntrancesList(0, 10, options).pipe(
            tapResponse(
              (response: PagedResponse<EntrancePageResponse>) => {
                const addresses: {
                  value: EntrancePageResponse;
                  select: SelectSearch
                }[] = response.content.map((entrance: EntrancePageResponse) => ({
                  value: entrance,
                  select: {value: entrance.id, text: entrance.addressString}
                }));

                if (!options.intercomIndexesRequired) {
                  addresses.push(this.changeModeSelectItem);
                }

                this.setState((state: AddressSearchSelectState) => ({
                  ...state,
                  addresses,
                  loading: false
                }));
              },
              (error: HttpErrorResponse) => {
                this.setLoading(false);
                this.snackbar.showMessage(
                  this.translate.instant('shared.address.input.search-select.message.get_addresses.failed', {
                    text: parseError(error)
                  })
                );
              }
            )
          );
        })
      )
  );

  readonly selectAddressUsingEntranceId = this.updater((state: AddressSearchSelectState, entranceId: number) => {
    const selectedAddressIdx: number = state.addresses.findIndex(
      (address: { value: EntrancePageResponse; select: SelectSearch }) => address.select.value === entranceId
    );

    return {
      ...state,
      selectedAddressIdx
    };
  });

  private readonly onDestroy$: Subject<void> = new Subject();

  constructor(
    private entranceService: EntranceService,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {
    super(addressSearchSelectInitialState);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  setLoading(loading: boolean) {
    this.patchState({loading});
  }

  getSelectedAddress(cb: (address: EntrancePageResponse) => void) {
    this.selectedAddress$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((address: EntrancePageResponse) => address && cb(address));
  }
}
