import { UserRoleType } from './user-role-type.model';

export interface UserData {
  authorities: UserRoleType[];
  length: number;
  client_id: string[];
  company: number;
  exp: number;
  jti: string;
  scope: string[];
  user_name: string;
}
