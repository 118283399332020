import { ActionReducerMap } from '@ngrx/store';
import { EntrancesActions, EntrancesActionTypes } from './entrances.actions';
import { entrancesInitialState, EntrancesState } from './entrances.state';

function reducer(state = entrancesInitialState, action: EntrancesActions): EntrancesState {
    switch (action.type) {
        case EntrancesActionTypes.EntrancesLoadPage: {
            return {
                ...state,
                entrancesLoading: true,
                entrances: null
            };
        }

        case EntrancesActionTypes.EntrancesLoadPageSuccess: {
            return {
                ...state,
                entrancesLoading: false,
                entrances: action.response,
                pageData: action.pageData
            };
        }

        case EntrancesActionTypes.EntrancesLoadPageFailure: {
            return {
                ...state,
                entrancesLoading: false
            };
        }

        case EntrancesActionTypes.EntrancesLoadCounts: {
            return {
                ...state,
                counts: null,
                countsLoading: true,
                entrancesLoading: true,
                entrances: null,
            };
        }

        case EntrancesActionTypes.EntrancesLoadCountsSuccess: {
            return {
                ...state,
                counts: action.response,
                countsLoading: false,
            };
        }

        case EntrancesActionTypes.EntrancesLoadCountsFailure: {
            return {
                ...state,
                countsLoading: false,
            };
        }

        case EntrancesActionTypes.EntrancesLoadEntrance: {
            return {
                ...state,
                loadingEntrance: true,
                loadingCameras: true,
                loadingKeys: true,
                loadingFlats: true,
                entrance: null
            };
        }

        case EntrancesActionTypes.EntrancesLoadEntranceSuccess: {
            return {
                ...state,
                loadingEntrance: false,
                entrance: action.response
            };
        }

        case EntrancesActionTypes.EntrancesLoadEntranceFailure: {
            return {
                ...state,
                loadingEntrance: false,
            };
        }

        case EntrancesActionTypes.EntrancesLoadRdaSuccess: {
            return {
                ...state,
                rda: action.rda
            };
        }

        case EntrancesActionTypes.EntrancesLoadCompanySuccess: {
            return {
                ...state,
                company: action.response
            };
        }

        case EntrancesActionTypes.EntrancesLoadKeys: {
            return {
                ...state,
                loadingKeys: true,
                keys: null
            };
        }

        case EntrancesActionTypes.EntrancesLoadKeysSuccess: {
            return {
                ...state,
                loadingKeys: false,
                keys: action.response.map(key => {
                    key.adapterInfo = {
                        uid: state.rda.uid,
                        ipType: state.rda.intercomType?.protocol?.ipType,
                        protocolNumber: state.rda.intercomType?.protocol?.number,
                        mode: state.rda.mode
                    };

                    return key;
                })
            };
        }

        case EntrancesActionTypes.EntrancesLoadKeysFailure: {
            return {
                ...state,
                loadingKeys: false,
            };
        }

        case EntrancesActionTypes.EntrancesLoadFlats: {
            return {
                ...state,
                loadingFlats: true,
                flats: null
            };
        }

        case EntrancesActionTypes.EntrancesLoadFlatsSuccess: {
            return {
                ...state,
                loadingFlats: false,
                flats: action.response
            };
        }

        case EntrancesActionTypes.EntrancesLoadFlatsFailure: {
            return {
                ...state,
                loadingFlats: false,
            };
        }

        case EntrancesActionTypes.EntrancesLoadGitlabVersionsSuccess: {
            return {
                ...state,
                gitlabVersions: action.response
            };
        }

        case EntrancesActionTypes.EntrancesLoadPbxOnRdaSuccess: {
            return {
                ...state,
                pbxOnRda: action.response
            };
        }

        case EntrancesActionTypes.EntrancesLoadCameras: {
            return {
                ...state,
                loadingCameras: true,
                cameras: null
            };
        }

        case EntrancesActionTypes.EntrancesLoadCamerasSuccess: {
            return {
                ...state,
                loadingCameras: false,
                cameras: action.response
            };
        }

        case EntrancesActionTypes.EntrancesLoadCamerasFailure: {
            return {
                ...state,
                loadingCameras: false,
            };
        }

        case EntrancesActionTypes.EntrancesLoadCamerasWithErrorsSuccess: {
            return {
                ...state,
                camerasWithErrors: action.response
            };
        }

        case EntrancesActionTypes.EntrancesLoadLogs: {
            return {
                ...state,
                loadingLogs: true,
                logs: null
            };
        }

        case EntrancesActionTypes.EntrancesLoadLogsSuccess: {
            return {
                ...state,
                loadingLogs: false,
                logs: action.response
            };
        }

        case EntrancesActionTypes.EntrancesLoadLogsFailure: {
            return {
                ...state,
                loadingLogs: false,
            };
        }

        case EntrancesActionTypes.EntrancesSetIncorrectId: {
            return {
                ...state,
                incorrectId: action.incorrectId
            };
        }

        case EntrancesActionTypes.EntrancesClearEntranceStore: {
            return {
                ...state,
                loadingEntrance: null,
                rda: null,
                company: null,
                entrance: null,
                loadingKeys: null,
                keys: null,
                loadingFlats: null,
                flats: null,
                pbxOnRda: null,
                gitlabVersions: null,
                loadingCameras: null,
                cameras: null,
                camerasWithErrors: null,
                loadingLogs: null,
                logs: null
            };
        }

        default: {
            return state;
        }
    }
}

export const entrancesReducers: ActionReducerMap<{ entrances: EntrancesState }> = {
    entrances: reducer
};
