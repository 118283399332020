<p>
  {{ "delete.delegation.popup.text" | translate }}
</p>

<div class="action-block">
  <button
    mat-raised-button
    color="error"
    (click)="onSubmit()">
    {{ "delete.delegation.popup.button.submit" | translate }}
  </button>
</div>
