import {HttpErrorResponse} from '@angular/common/http';
import {Injectable, OnDestroy} from '@angular/core';
import {SnackbarService} from '@app/shared/components';
import {IntercomTypeUtils, RdaApiService, RdaResponse} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {PagedResponse} from '@app/shared/models';
import {ComponentStore, tapResponse} from '@ngrx/component-store';
import {Observable, Subject, combineLatest} from 'rxjs';
import {switchMap, takeUntil, filter, take, takeWhile} from 'rxjs/operators';
import {RdaSelectSearchParams, RdaSelectSearchResponse} from '../models';
import {intercomSelectSearchInitialState, RdaSelectSearchState} from './rda-select-search.state';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class RdaSearchSelectStore extends ComponentStore<RdaSelectSearchState> implements OnDestroy {
  rdas$: Observable<RdaSelectSearchResponse[]> = this.select((state: RdaSelectSearchState) => state.intercoms);
  loading$: Observable<boolean> = this.select((state: RdaSelectSearchState) => state.loading);
  selectedIntercom$: Observable<RdaSelectSearchResponse> = this.select((state: RdaSelectSearchState) => state.selectedIntercom);

  readonly selectIntercomFromStore = this.updater((state: RdaSelectSearchState, rdaIdx: number) => {
    return {
      ...state,
      selectedIntercom: state.intercoms[rdaIdx]
    };
  });

  readonly getIntercoms = this.effect((options$: Observable<{
      value: string,
      params?: RdaSelectSearchParams,
      select?: boolean
    }>) =>
      options$.pipe(
        switchMap((options) => {
          this.setLoading(true);

          return this.rdaApiService.getAdaptersList(0, 10, options.params?.inactive, options.params?.ipType, options.params?.mode, options.value).pipe(
            tapResponse(
              (response: PagedResponse<RdaResponse>) => {
                this.setLoading(false);

                if (options.select) {
                  const selectedIntercom: RdaResponse = response.content.find((intercom: RdaResponse) => intercom.uid === options.value);

                  if (selectedIntercom) {
                    const preparedIntercom = this.prepareIntercom(selectedIntercom);
                    this.setSelectedIntercom(preparedIntercom);
                  }
                }

                this.setIntercoms(response.content);
              },
              (error: HttpErrorResponse) => {
                this.setLoading(false);
                this.snackbar.showMessage(
                  this.translate.instant('shared.rda.search.select.message.get_intercoms.failed', {
                    text: parseError(error)
                  })
                );
              }
            )
          );
        })
      )
  );

  readonly setIntercoms = this.updater((state: RdaSelectSearchState, intercoms: RdaResponse[]) => {
    let preparedIntercoms: RdaSelectSearchResponse[] = this.prepareIntercoms(intercoms);

    if (!preparedIntercoms?.length && state.intercoms?.length) {
      preparedIntercoms = state.intercoms;
    }

    if (state.selectedIntercom) {
      if (preparedIntercoms.findIndex(intercom => intercom.select.value === state.selectedIntercom.select.value) === -1) {
        preparedIntercoms.unshift(state.selectedIntercom);
      }
    }

    if (state.initialIntercoms) {
      state.initialIntercoms.forEach(initialIntercom => {
        const idx: number = preparedIntercoms.findIndex(intercom => intercom.select.value === initialIntercom.select.value);

        if (idx !== -1) {
          preparedIntercoms.splice(idx, 1);
        }

        preparedIntercoms.unshift(initialIntercom);
      });
    }

    return {
      ...state,
      intercoms: preparedIntercoms
    };
  });

  readonly setLoading = this.updater((state: RdaSelectSearchState, loading: boolean) =>
    ({...state, loading})
  );

  readonly setSelectedIntercom = this.updater((state: RdaSelectSearchState, selectedIntercom: RdaSelectSearchResponse) => {
    if (selectedIntercom) {
      if (!state.intercoms?.length) {
        state.intercoms = [selectedIntercom];
      } else if (state.intercoms?.findIndex(intercom => intercom.select.value === selectedIntercom.select.value) === -1) {
        state.intercoms.unshift(selectedIntercom);
      }
    }

    return {...state, selectedIntercom};
  });

  readonly setInitialIntercoms = this.updater((state: RdaSelectSearchState, intercoms: RdaResponse[]) => {
    const preparedIntercoms: RdaSelectSearchResponse[] = this.prepareIntercoms(intercoms);

    return {
      ...state,
      initialIntercoms: preparedIntercoms
    };
  });

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private rdaApiService: RdaApiService,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {
    super(JSON.parse(JSON.stringify(intercomSelectSearchInitialState)));
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  addStorePreparedListener(cb: (intercoms: RdaSelectSearchResponse[], selectedIntercom: RdaSelectSearchResponse) => void) {
    combineLatest([
      this.rdas$,
      this.selectedIntercom$
    ])
      .pipe(
        filter(([intercoms, selectedIntercom]: [RdaSelectSearchResponse[], RdaSelectSearchResponse]) =>
          !!intercoms && selectedIntercom !== undefined
        ),
        take(1)
      )
      .subscribe(([intercoms, selectedIntercom]: [RdaSelectSearchResponse[], RdaSelectSearchResponse]) => {
        cb(intercoms, selectedIntercom);
      });
  }

  addIntercomsListener(
    handleResponse: (rdas: RdaSelectSearchResponse[]) => void
  ) {
    this.rdas$.pipe(
      takeUntil(this.onDestroy$),
    ).subscribe(rdas =>
      handleResponse(rdas)
    );
  }

  private prepareIntercoms(intercoms: RdaResponse[]): RdaSelectSearchResponse[] {
    const intercomsSelectSearchResponse: RdaSelectSearchResponse[] = [];

    for (const rda of intercoms) {
      const preparedIntercom = this.prepareIntercom(rda);

      if (!preparedIntercom) {
        continue;
      }

      intercomsSelectSearchResponse.push(preparedIntercom);
    }

    return intercomsSelectSearchResponse;
  }

  private prepareIntercom(rda: RdaResponse): RdaSelectSearchResponse {
    if (!rda) {
      return null;
    }

    const badge: string = IntercomTypeUtils.getIpIntercomConnectionTypeText(rda.mode, this.translate);

    return {
      value: rda,
      select: {text: rda.uid, value: rda.uid, badge}
    };
  }
}
