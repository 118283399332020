import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

import {AbonentsFromEntrancesResponse} from '@app/shared/components';
import {GitlabComponentsVersions} from '@app/shared/entities/integrations';
import {
  ActiveHistoryResponse,
  AdapterInfoForKeyResponse,
  Camera,
  Company,
  ConnectionCreateRequest,
  IntercomPanelConnectRequest,
  IntercomPanelResponse,
  IntercomPanelUpdateRequest,
  IntercomType,
  IpIntercomKeysRequest,
  KeysResponse,
  PbxOnRdaResponse,
  RdaKeysRequest,
  RdaResponse,
  RdaUpdateRequest,
  ServiceBlockPhysicalTubeRequest,
  ServiceResponse
} from '@app/shared/entities/rd';
import {Dictionary} from '@app/shared/helpers';
import {
  Address,
  EntranceRegistration,
  EntranceUpdateRequest,
  FlatRange,
  LocationResponse,
  LogsComponentType,
  LogsResponse,
  ServicesTypes,
  TranslationTuningRequest,
  TranslationTuningResponse
} from '@app/shared/models';
import {State} from '@app/store/reducers';
import {
  ServiceActivitySource,
  ServiceBlockRequest,
  ServiceConnectionWithType,
  UpdateServiceRequest
} from '@app/views/services/models';
import {ConnectServiceCamera, TuneServiceOnvif, UpdateServiceCameraSuccess} from '@app/views/services/store';
import {ServiceFlatsFilters} from '../components';
import {ServiceEntrancesFlats} from './../components/containers/service-flats';
import {ServicePageMode} from './../models/service-page-mode.enum';
import {
  AddAdditionalFlatRange,
  AddServiceCamera,
  AddServiceConnectionsFromEntrances,
  AddServiceEntrance,
  AddServiceIntercomPanel,
  AddServiceKey,
  AddServicePhysicalTube,
  AddServiceRda,
  AddTranslationTuning,
  BlockServiceConnection,
  BlockServicePhysicalTube,
  ChangeServicePbxOnRda,
  ChangeServiceRda,
  ClearService,
  ConnectServicePbxOnRda,
  CreateServiceConnections,
  DelegateServiceAbonentAccess,
  DeleteAdditionalFlatRange,
  DeleteServiceAbonentAccess,
  DeleteServiceCamera,
  DeleteServiceConnection,
  DeleteServiceEntrance,
  DeleteServiceFlat,
  DeleteServiceIntercomPanel,
  DeleteServiceKey,
  DeleteServicePhysicalTube,
  DeleteServiceRda,
  DeleteTranslationTuning,
  DisconnectServicePbxOnRda,
  FilterServiceFlats,
  FilterServiceFlatsSuccess,
  GetCamerasLocations,
  GetComponentsVersions,
  GetIntercomTypes,
  GetKeysLocations,
  GetService,
  GetServiceCameraActivity,
  GetServiceCameraActivityBefore,
  GetServiceCameraLogs,
  GetServiceCameras,
  GetServiceCamerasSources,
  GetServiceCompany,
  GetServiceConnections,
  GetServiceConnectionsInit,
  GetServiceKeys,
  GetServiceKeysSources,
  GetServicePbxOnRda,
  GetServicePrivateCameraLogs,
  GetServiceRdaActivity,
  GetServiceRdaActivityBefore,
  GetServiceRdaLogs,
  GetServiceRdas,
  GetServiceRdasSources,
  GetTranslationTunings,
  ResetServiceCamera,
  ServiceConnectionsPrepared,
  ServiceSendEmergencyAlert,
  SetServiceBlocksCount,
  SetServiceComponentType,
  SetServiceFillEmptyFlats,
  SetServiceLogsSource,
  SetServiceLogsStream,
  SetServiceLogsTimeRange,
  UnblockServiceConnection,
  UnblockServicePhysicalTube,
  UpdateAdditionalFlatRange,
  UpdateService,
  UpdateServiceCamera,
  UpdateServiceEntrance,
  UpdateServiceEntranceWithPrefix,
  UpdateServiceIntercomPanel,
  UpdateServiceKey,
  UpdateServiceRda,
  ActiveServiceOnvif
} from './actions';
import {AddDependantService, GetDependantService, SetPageMode} from './actions/services-general.actions';
import {
  DeleteServiceCameraSource,
  DeleteServiceKeySource,
  DeleteServiceRdaSource
} from './actions/services-logs.actions';
import {servicesSelectors} from './services.selectors';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServiceFacade {
  pageMode$: Observable<ServicePageMode> = this.store.select(servicesSelectors.selectPageMode);

  keysLocations$: Observable<LocationResponse[]> = this.store.select(servicesSelectors.selectKeysLocations);
  camerasLocations$: Observable<LocationResponse[]> = this.store.select(servicesSelectors.selectCamerasLocations);
  intercomTypes$: Observable<IntercomType[]> = this.store.select(servicesSelectors.selectIntercomTypes);
  componentsVersions$: Observable<GitlabComponentsVersions> = this.store.select(servicesSelectors.selectComponentsVersions);
  company$: Observable<Company> = this.store.select(servicesSelectors.selectCompany);

  isActiveOnvif$: Observable<boolean> = this.store.select(servicesSelectors.selectActiveOnvif);
  serviceId$: Observable<number> = this.store.select(servicesSelectors.selectServiceId);
  fillEmptyFlats$: Observable<boolean> = this.store.select(servicesSelectors.selectFillEmptyFlats);
  dependantServices$: Observable<Pick<ServiceResponse, 'id' | 'type'>[]> = this.store.select(servicesSelectors.selectDependantServices);
  dependantServicesExtended$: Observable<ServiceResponse[]> = this.store.select(servicesSelectors.selectDependantServicesExtended);

  serviceName$: Observable<string> = this.store.select(servicesSelectors.selectServiceName);
  serviceTariff$: Observable<number> = this.store.select(servicesSelectors.selectServiceTariff);
  serviceCustomName$: Observable<string> = this.store.select(servicesSelectors.selectServiceCustomName);
  serviceType$: Observable<ServicesTypes> = this.store.select(servicesSelectors.selectServiceType);
  entrances$: Observable<Address[]> = this.store.select(servicesSelectors.selectEntrances);
  cameras$: Observable<Camera[]> = this.store.select(servicesSelectors.selectCameras);
  keys$: Observable<KeysResponse[]> = this.store.select(servicesSelectors.selectKeys);
  flats$: Observable<ServiceEntrancesFlats> = this.store.select(servicesSelectors.selectFlats);
  filteredFlats$: Observable<ServiceEntrancesFlats> = this.store.select(servicesSelectors.selectFilteredFlats);
  flatsFilters$: Observable<ServiceFlatsFilters> = this.store.select(servicesSelectors.selectFlatsFilters);
  abonentsLoading$: Observable<boolean> = this.store.select(servicesSelectors.selectAbonentsLoading);
  abonentsBillingEnabled$: Observable<boolean> = this.store.select(servicesSelectors.selectAbonentsBillingEnabled);
  rdas$: Observable<RdaResponse[]> = this.store.select(servicesSelectors.selectRdas);
  pbxOnRda$: Observable<Dictionary<PbxOnRdaResponse>> = this.store.select(servicesSelectors.selectPbxOnRda);
  translationTunings$: Observable<Dictionary<TranslationTuningResponse[]>> = this.store.select(servicesSelectors.selectTranslationTunings);

  blocksCount$: Observable<number> = this.store.select(servicesSelectors.selectBlocksCount);
  sources$: Observable<ServiceActivitySource[]> = this.store.select(servicesSelectors.selectSources);
  activeHistoryIntercoms$: Observable<Dictionary<ActiveHistoryResponse[]>> = this.store.select(servicesSelectors.selectActiveHistoryIntercoms);
  activeHistoryCameras$: Observable<Dictionary<ActiveHistoryResponse[]>> = this.store.select(servicesSelectors.selectActiveHistoryCameras);
  logsData$: Observable<LogsResponse[]> = this.store.select(servicesSelectors.selectLogsData);
  timeRange$: Observable<number> = this.store.select(servicesSelectors.selectTimeRange);
  extendedMode$: Observable<boolean> = this.store.select(servicesSelectors.selectLogsMode);
  totalLogsCount$: Observable<number> = this.store.select(servicesSelectors.selectTotalLogsCount);
  currentLogsCount$: Observable<number> = this.store.select(servicesSelectors.selectCurrentLogsCount);
  selectedLogsSource$: Observable<ServiceActivitySource> = this.store.select(servicesSelectors.selectSelectedLogsSource);
  logsComponentType$: Observable<LogsComponentType> = this.store.select(servicesSelectors.selectLogsComponentType);
  logsLoading$: Observable<boolean> = this.store.select(servicesSelectors.selectLogsLoading);
  intercomPanels$: Observable<IntercomPanelResponse[]> = this.rdas$.pipe(
    map((intercoms: RdaResponse[]) =>
      intercoms?.reduce((prev, cur) => [...prev, ...cur.intercoms], []) ?? []
    )
  );
  intercomPanelsLocations$: Observable<LocationResponse[]> = this.intercomPanels$.pipe(
    map((intercomPanels: IntercomPanelResponse[]) =>
      intercomPanels
        .map(intercomPanel => intercomPanel.location)
        .filter(intercomPanelLocation => intercomPanelLocation !== null)
    )
  );
  avaliableIntercomPanelsLocations$: Observable<LocationResponse[]> = combineLatest([
    this.camerasLocations$,
    this.intercomPanelsLocations$
  ])
    .pipe(
      map(([camerasLocations, intercomPanelsLocations]: [LocationResponse[], LocationResponse[]]) => {
        if (!camerasLocations?.length || !intercomPanelsLocations?.length) {
          return [];
        }

        const camerasIntercomPanelsLocations = [];

        for (const cameraLocation of camerasLocations) {
          const locationExists: boolean = intercomPanelsLocations.findIndex(intercomPanelLocation =>
            intercomPanelLocation?.id === cameraLocation.id
          ) !== -1;

          if (locationExists) {
            camerasIntercomPanelsLocations.push({
              id: cameraLocation.id,
              name: this.translate.instant('services.locations.message.push', {
                name: cameraLocation.name
              }),
              group: 'cameras'
            });
          }
        }

        return camerasIntercomPanelsLocations;
      })
    );

  constructor(
    private store: Store<State>,
    private translate: TranslateService
  ) {
  }

  // General actions
  getService(serviceId: number) {
    this.store.dispatch(new GetService(serviceId));
  }

  getDependService(serviceId: number) {
    this.store.dispatch(new GetDependantService(serviceId));
  }

  updateService(request: UpdateServiceRequest) {
    this.store.dispatch(new UpdateService(request));
  }

  getKeysLocations() {
    this.store.dispatch(new GetKeysLocations());
  }

  getCamerasLocations() {
    this.store.dispatch(new GetCamerasLocations());
  }

  getServiceCompany(companyId: number) {
    this.store.dispatch(new GetServiceCompany(companyId));
  }

  getIntercomTypes() {
    this.store.dispatch(new GetIntercomTypes());
  }

  getComponentVersions() {
    this.store.dispatch(new GetComponentsVersions());
  }

  sendEmergencyAlert(rdaUid: string) {
    this.store.dispatch(new ServiceSendEmergencyAlert(rdaUid));
  }

  addDependantService(dependantServices: Pick<ServiceResponse, 'id' | 'type'>[]) {
    this.store.dispatch(new AddDependantService(dependantServices));
  }

  setPageMode(pageMode: ServicePageMode) {
    this.store.dispatch(new SetPageMode(pageMode));
  }

  clearService() {
    this.store.dispatch(new ClearService());
  }

  // Entrances actions
  addServiceEntrance(entrance: EntranceRegistration, prefix?: string) {
    this.store.dispatch(new AddServiceEntrance(entrance, prefix));
  }

  updateServiceEntranceWithPrefix(entranceId: number, entrance: EntranceUpdateRequest) {
    this.store.dispatch(new UpdateServiceEntranceWithPrefix(entranceId, entrance));
  }

  updateServiceEntrance(entranceId: number, entrance: EntranceUpdateRequest) {
    this.store.dispatch(new UpdateServiceEntrance(entranceId, entrance));
  }

  deleteServiceEntrance(entranceId: number) {
    this.store.dispatch(new DeleteServiceEntrance(entranceId));
  }

  addAdditionalFlatRange(entranceId: number, range: FlatRange) {
    this.store.dispatch(new AddAdditionalFlatRange(entranceId, range));
  }

  updateAdditionalFlatRange(entranceId: number, rangeId: number, range: FlatRange) {
    this.store.dispatch(new UpdateAdditionalFlatRange(entranceId, rangeId, range));
  }

  deleteAdditionalFlatRange(entranceId: number, rangeId: number) {
    this.store.dispatch(new DeleteAdditionalFlatRange(entranceId, rangeId));
  }

  // Intercom actions
  getServiceRdas() {
    this.store.dispatch(new GetServiceRdas());
  }

  addServiceRda(request: RdaUpdateRequest) {
    this.store.dispatch(new AddServiceRda(request));
  }

  changeServiceRda(request: RdaUpdateRequest, oldRdaId: number) {
    this.store.dispatch(new ChangeServiceRda(request, oldRdaId));
  }

  updateServiceRda(rdaUid: string, request: RdaUpdateRequest) {
    this.store.dispatch(new UpdateServiceRda(rdaUid, request));
  }

  deleteServiceRda(intercom: RdaResponse) {
    this.store.dispatch(new DeleteServiceRda(intercom));
  }

  getPbxOnRda(rdaUid: string) {
    this.store.dispatch(new GetServicePbxOnRda(rdaUid));
  }

  connectPbxOnRda(pbxOnRda: PbxOnRdaResponse, rdaUid: string) {
    this.store.dispatch(new ConnectServicePbxOnRda(pbxOnRda, rdaUid));
  }

  changePbxOnRda(oldPbxOnRda: PbxOnRdaResponse, newPbxOnRda: PbxOnRdaResponse, rdaUid: string) {
    this.store.dispatch(new ChangeServicePbxOnRda(oldPbxOnRda, newPbxOnRda, rdaUid));
  }

  disconnectPbxOnRda(pbxOnRda: PbxOnRdaResponse, rdaUid: string) {
    this.store.dispatch(new DisconnectServicePbxOnRda(pbxOnRda, rdaUid));
  }

  getTranslationTunings(rdaUid: string) {
    this.store.dispatch(new GetTranslationTunings(rdaUid));
  }

  addTranslationTuning(rdaUid: string, request: TranslationTuningRequest) {
    this.store.dispatch(new AddTranslationTuning(rdaUid, request));
  }

  deleteTranslationTuning(rdaUid: string, translationTuningId: number) {
    this.store.dispatch(new DeleteTranslationTuning(rdaUid, translationTuningId));
  }

  // Intercom panels actions
  connectIntercomPanel(request: IntercomPanelConnectRequest) {
    this.store.dispatch(new AddServiceIntercomPanel(request));
  }

  updateIntercomPanel(intercomPanelId: number, rdaId: number, request: Partial<IntercomPanelUpdateRequest>) {
    this.store.dispatch(new UpdateServiceIntercomPanel(intercomPanelId, rdaId, request));
  }

  disconnectIntercomPanel(intercomPanelId: number, rdaId: number) {
    this.store.dispatch(new DeleteServiceIntercomPanel(intercomPanelId, rdaId));
  }

  // Intercom keys actions
  getServiceKeys() {
    this.store.dispatch(new GetServiceKeys());
  }

  addServiceKey(intercomInfo: AdapterInfoForKeyResponse, request: RdaKeysRequest) {
    this.store.dispatch(new AddServiceKey(intercomInfo, request));
  }

  updateServiceKey(keyId: number, request: RdaKeysRequest | IpIntercomKeysRequest, locationName: string) {
    this.store.dispatch(new UpdateServiceKey(keyId, request, locationName));
  }

  deleteServiceKey(keyId: number, rdaUid: string) {
    this.store.dispatch(new DeleteServiceKey(keyId, rdaUid));
  }

  // Cameras actions
  getServiceCameras() {
    this.store.dispatch(new GetServiceCameras());
  }

  addServiceCamera(camera: Camera, inercomPanelId?: number) {
    this.store.dispatch(new AddServiceCamera(camera, inercomPanelId));
  }

  updateServiceCamera(camera: Camera) {
    this.store.dispatch(new UpdateServiceCamera(camera));
  }

  updateServiceCameraSuccess(camera: Camera) {
    this.store.dispatch(new UpdateServiceCameraSuccess(camera));
  }

  deleteServiceCamera(cameraId: number) {
    this.store.dispatch(new DeleteServiceCamera(cameraId));
  }

  resetServiceCamera(rdvaUri: string, cameraId: number) {
    this.store.dispatch(new ResetServiceCamera(rdvaUri, cameraId));
  }

  connectServiceCamera(camera: Camera) {
    this.store.dispatch(new ConnectServiceCamera(camera));
  }

  // Connections actions
  async getConnectionsInit(options?: { fillEmptyFlats?: boolean }) {
    await this.store.dispatch(new GetServiceConnectionsInit(options));
  }

  setFillEmptyFlats(fillEmptyFlat: boolean) {
    this.store.dispatch(new SetServiceFillEmptyFlats(fillEmptyFlat));
  }

  getConnections(
    dependantServices: Pick<ServiceResponse, 'id' | 'type'>[],
    entrancesIds: number[]
  ) {
    this.store.dispatch(new GetServiceConnections(dependantServices, entrancesIds));
  }


  connectionsPrepared(flats: ServiceEntrancesFlats) {
    this.store.dispatch(new ServiceConnectionsPrepared(flats));
  }

  createConnections(request: Partial<ConnectionCreateRequest>) {
    this.store.dispatch(new CreateServiceConnections(request));
  }

  blockConnection(request: ServiceBlockRequest) {
    this.store.dispatch(new BlockServiceConnection(request));
  }

  unblockConnection(connection: ServiceConnectionWithType) {
    this.store.dispatch(new UnblockServiceConnection(connection));
  }

  deleteConnection(connection: ServiceConnectionWithType) {
    this.store.dispatch(new DeleteServiceConnection(connection));
  }

  deleteFlat(entranceId: number, flatNumber: number, flatId: number, serviceConnectionId?: number) {
    this.store.dispatch(new DeleteServiceFlat(entranceId, flatNumber, flatId, serviceConnectionId));
  }

  deleteAbonentAccess(entranceId: number, delegationId: number) {
    this.store.dispatch(new DeleteServiceAbonentAccess(entranceId, delegationId));
  }

  addPhysicalTube(entranceId: number, flatNumber: number, flatId: number) {
    this.store.dispatch(new AddServicePhysicalTube(entranceId, flatNumber, flatId));
  }

  deletePhysicalTube(entranceId: number, flatNumber: number, flatId: number, connectionId: number) {
    this.store.dispatch(new DeleteServicePhysicalTube(entranceId, flatNumber, flatId, connectionId));
  }

  blockPhysicalTube(request: ServiceBlockPhysicalTubeRequest) {
    this.store.dispatch(new BlockServicePhysicalTube(request));
  }

  async unblockPhysicalTube(connection: ServiceConnectionWithType) {
    await this.store.dispatch(new UnblockServicePhysicalTube(connection));
  }

  delegateAbonentAccess(
    entranceId: number,
    flatNumber: number,
    fromAbonentId: number,
    toAbonentPhone: number,
    companyId?: number,
  ) {
    this.store.dispatch(new DelegateServiceAbonentAccess(
      entranceId, flatNumber, fromAbonentId, toAbonentPhone, companyId
    ));
  }

  filterFlats(filter: ServiceFlatsFilters) {
    this.store.dispatch(new FilterServiceFlats(filter));
  }

  filterFlatsSuccess(filteredFlats: ServiceEntrancesFlats) {
    this.store.dispatch(new FilterServiceFlatsSuccess(filteredFlats));
  }

  addServiceConnectionsFromEntrances(connections: AbonentsFromEntrancesResponse[], companyId?: number) {
    this.store.dispatch(new AddServiceConnectionsFromEntrances(connections, companyId));
  }

  // Activity actions
  getCamerasSources(cameras: Camera[]) {
    this.store.dispatch(new GetServiceCamerasSources(cameras));
  }

  getRdasSources(rdas: RdaResponse[]) {
    this.store.dispatch(new GetServiceRdasSources(rdas));
  }

  deleteCameraSource(cameraId: number) {
    this.store.dispatch(new DeleteServiceCameraSource(cameraId));
  }

  deleteRdaSource(rdaUid: string) {
    this.store.dispatch(new DeleteServiceRdaSource(rdaUid));
  }

  getKeysSources(request: { intercomUid: string, key: KeysResponse }[]) {
    this.store.dispatch(new GetServiceKeysSources(request));
  }

  deleteKeySource(rdaUid: string) {
    this.store.dispatch(new DeleteServiceKeySource(rdaUid));
  }

  getRdaLogs(rdaUid: string, timeRange: number, limit: number, page: number) {
    this.store.dispatch(new GetServiceRdaLogs(rdaUid, timeRange, limit, page));
  }

  getCameraLogs(rdvaUri: string, cameraId: number) {
    this.store.dispatch(new GetServiceCameraLogs(rdvaUri, cameraId));
  }

  getPrivateCameraLogs(rdaUid: string, cameraId: number, timeRange: number, limit: number, page: number) {
    this.store.dispatch(new GetServicePrivateCameraLogs(rdaUid, cameraId, timeRange, limit, page));
  }

  getRdaActivityBefore() {
    this.store.dispatch(new GetServiceRdaActivityBefore());
  }

  getRdaActivity(rdaId: number, startDate: Date, endDate: Date, stepInMilliseconds: number) {
    this.store.dispatch(new GetServiceRdaActivity(rdaId, startDate, endDate, stepInMilliseconds));
  }

  getCameraActivityBefore() {
    this.store.dispatch(new GetServiceCameraActivityBefore());
  }

  getCameraActivity(cameraId: number, startDate: Date, endDate: Date, stepInMilliseconds: number) {
    this.store.dispatch(new GetServiceCameraActivity(cameraId, startDate, endDate, stepInMilliseconds));
  }

  setLogsSource(source: ServiceActivitySource) {
    this.store.dispatch(new SetServiceLogsSource(source));
  }

  setLogsStream(extendedMode: boolean) {
    this.store.dispatch(new SetServiceLogsStream(extendedMode));
  }

  setTimeRange(timeRange: number) {
    this.store.dispatch(new SetServiceLogsTimeRange(timeRange));
  }

  setLogsComponentType(componentType: LogsComponentType) {
    this.store.dispatch(new SetServiceComponentType(componentType));
  }

  setBlocksCount(blocksCount: number) {
    this.store.dispatch(new SetServiceBlocksCount(blocksCount));
  }

  activeServiceOnvif(camera: Camera) {
    this.store.dispatch(new ActiveServiceOnvif(camera));
  }

  tuneServiceOnvif(camera: Camera) {
    this.store.dispatch(new TuneServiceOnvif(camera));
  }
}
