export interface AppLinks {
    linkiOS: LinksiOS;
    linkAndroid: LinksAndroid;
    linkHuawei?: LinksHuawei;
}

export enum LinksiOS {
    RDRussia = 'https://apps.apple.com/app/id1422470505',
    RDKazakhstanBelarus = 'https://apps.apple.com/app/id1464881731',
    Redcom = 'https://apps.apple.com/app/id1616679733',
    Sibset = 'https://apps.apple.com/app/id1411809065',
    Orion = 'https://apps.apple.com/app/id1453982959',
    vivo = 'https://apps.apple.com/br/app/quem-chegou/id6467722936',
    kz = 'https://apps.apple.com/us/app/мой-домофон/id1464881731',
}


export enum LinksAndroid {
    RDRussia = 'https://play.google.com/store/apps/details?id=com.rosdomofon.rdua',
    RDKazakhstanBelarus = 'https://play.google.com/store/apps/details?id=kz.rosdomofon.rdua',
    Redcom = 'https://play.google.com/store/apps/details?id=ru.redcom.intercom',
    Sibset = 'https://play.google.com/store/apps/details?id=com.rosdomofon.sibset_domofon',
    Orion = 'https://play.google.com/store/apps/details?id=com.rosdomofon.orion',
    vivo = 'https://play.google.com/store/apps/details?id=com.condominium.quemchegou',
    kz = 'https://play.google.com/store/apps/details?id=kz.rosdomofon.rdua',
}

export enum LinksHuawei {
    RDRussia = 'https://appgallery.huawei.com/#/app/C103925489',
    RDKazakhstanBelarus = 'https://appgallery.huawei.com/#/app/C103925503',
    Redcom = 'https://appgallery.huawei.com/#/app/C105896945',
    Sibset = 'https://appgallery.huawei.com/#/app/C103925533',
    Orion = 'https://appgallery.huawei.com/#/app/C103925541',
}
