import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResourcePath } from '@app/shared/entities';
import { ResourcesHelper } from '@app/shared/entities/common';
import { IntercomType, RdaResponse } from '@app/shared/entities/rd';
import { UNSUPPORTED_INTERCOM_TYPE_LINK } from '@app/shared/helpers';

@Component({
  selector: 'app-gate-wizard-content-intercoms-selection',
  templateUrl: './gate-wizard-content-intercoms-selection.component.html',
  styleUrls: ['./gate-wizard-content-intercoms-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GateWizardContentIntercomsSelectionComponent implements OnInit {
  readonly UNSUPPORTED_INTERCOM_TYPE_LINK = UNSUPPORTED_INTERCOM_TYPE_LINK;
  readonly resourcePath: typeof ResourcePath = ResourcePath;

  @Input() loading: boolean;
  @Input() intercomType: IntercomType;
  @Input() intercomTypes: IntercomType[];

  @Output() private intercomSelectionSkipped: EventEmitter<void> = new EventEmitter();
  @Output() private intercomSelected: EventEmitter<{ intercom: RdaResponse }> = new EventEmitter();
  @Output() private changeControlsVisible: EventEmitter<{ showControls?: boolean }> = new EventEmitter();

  constructor(
    public resourcesHelper: ResourcesHelper
  ) { }

  ngOnInit() {
    this.changeControlsVisible.emit({ showControls: false });
  }

  onSkipIntercomSelection() {
    this.intercomSelectionSkipped.emit();
    this.changeControlsVisible.emit({ showControls: true });
  }

  onIntercomSelected(intercom: RdaResponse) {
    this.intercomSelected.emit({ intercom });
    this.changeControlsVisible.emit({ showControls: true });
  }
}
