<div class="popup">
  <p>
    {{ 'shared.popups.confirm.connect.content.message.agree' | translate}}&nbsp;
    <a [href]="tarrifsPageLink | safeUrl" target="_blank">
      {{ 'shared.popups.confirm.connect.content.link.agree' | translate }}
    </a>
  </p>

    <div class="popup-checkbox">
      <mat-checkbox [(ngModel)]="confirmed">
        {{ 'shared.popups.confirm.connect.content.checkbox.dont_show_anymore' | translate }}
      </mat-checkbox>
    </div>

  <div class="popup-actions">
    <button
    color="primary"
      mat-raised-button
      (click)="onConfirm()"
      class="popup-actions__button"
    >
      {{ 'shared.popups.confirm.connect.content.button.confirm' | translate }}
    </button>
  </div>
</div>
