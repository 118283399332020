import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AbonentsFromEntrancesModuleRootState, AbonentsFromEntrancesModuleState, AbonentsFromEntrancesState } from './abonents-from-entrances.state';

export const abonentsFromEntrancesSelector
    = createFeatureSelector<AbonentsFromEntrancesModuleRootState, AbonentsFromEntrancesModuleState>('abonents-from-entrances');

const selectState = createSelector(
    abonentsFromEntrancesSelector,
    (state: AbonentsFromEntrancesModuleState) => state.abonentsFromEntrances
);

const selectStatus = createSelector(
    selectState,
    (state: AbonentsFromEntrancesState) => state.status
);

const selectConnections = createSelector(
    selectState,
    (state: AbonentsFromEntrancesState) => state.connections
);

const selectSelectedAll = createSelector(
    selectState,
    (state: AbonentsFromEntrancesState) => state.selectedAll
);

const selectTotalConnections = createSelector(
    selectState,
    (state: AbonentsFromEntrancesState) => state.totalConnections
);

const selectDoneConnections = createSelector(
    selectState,
    (state: AbonentsFromEntrancesState) => state.doneConnections
);

export const abonentsFromEntrancesConnectorSelectors = {
    selectTotalConnections,
    selectDoneConnections,
    selectSelectedAll,
    selectConnections,
    selectStatus,
};
