import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IntercomPanelResponse } from '@app/shared/entities/rd';

@Component({
  selector: 'app-intercom-panels-list-content',
  templateUrl: './intercom-panels-list-content.component.html',
  styleUrls: ['./intercom-panels-list-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntercomPanelsListContentComponent {
  @Input() intercomPanels: IntercomPanelResponse[];
  @Input() editDisabled = true;
  @Input() deleteDisabled = true;

  @Output() private deleteIntercomPanel: EventEmitter<{ intercomPanel: IntercomPanelResponse }> = new EventEmitter();
  @Output() private editIntercomPanel: EventEmitter<{ intercomPanel: IntercomPanelResponse }> = new EventEmitter();

  onEdit(intercomPanelIdx: number) {
    this.editIntercomPanel.emit({ intercomPanel: this.intercomPanels[intercomPanelIdx] });
  }

  onDelete(intercomPanelIdx: number) {
    this.deleteIntercomPanel.emit({ intercomPanel: this.intercomPanels[intercomPanelIdx] });
  }
}
