import { Injectable } from '@angular/core';
import { TokenService } from '@app/security/token.service';
import { LocalStorageGeneralKey, LocalStorageHelper } from '@app/shared/entities/common';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CompanyUtilsService {
    constructor(
        private tokenService: TokenService
    ) { }

    canGetPaymentInfo(): boolean {
        const rosDomofonCompany = this.rosDomofonCompany();
        const licenseAgreement = LocalStorageHelper.getItem(LocalStorageGeneralKey.LICENSE_AGREEMENT);

        if (licenseAgreement || rosDomofonCompany) {
            return true;
        }

        return false;
    }

    rosDomofonCompany(): boolean {
        const companyId: number = this.tokenService.getCompanyId();

        if (companyId === environment.ROSDOMOFON_COMPANY_ID) {
            return true;
        }

        return false;
    }
}
