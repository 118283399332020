import { Camera } from '@app/shared/entities/rd';
import { Action } from '@ngrx/store';
import { ServicesActionTypes } from './services-action-types.actions';

export class GetServiceCameras implements Action {
    readonly type = ServicesActionTypes.GetServiceCameras;

    constructor() { }
}

export class GetServiceCamerasSuccess implements Action {
    readonly type = ServicesActionTypes.GetServiceCamerasSuccess;

    constructor(public cameras: Camera[]) { }
}

export class GetServiceCamerasFailure implements Action {
    readonly type = ServicesActionTypes.GetServiceCamerasFailure;

    constructor() { }
}

export class AddServiceCamera implements Action {
    readonly type = ServicesActionTypes.AddServiceCamera;

    constructor(public camera: Camera, public intercomPanelId?: number) { }
}

export class AddServiceCameraSuccess implements Action {
    readonly type = ServicesActionTypes.AddServiceCameraSuccess;

    constructor(public camera: Camera) { }
}

export class AddServiceCameraFailure implements Action {
    readonly type = ServicesActionTypes.AddServiceCameraFailure;

    constructor(public camera: Camera) { }
}

export class UpdateServiceCamera implements Action {
    readonly type = ServicesActionTypes.UpdateServiceCamera;

    constructor(public camera: Camera) { }
}

export class UpdateServiceCameraSuccess implements Action {
    readonly type = ServicesActionTypes.UpdateServiceCameraSuccess;

    constructor(public camera: Camera) { }
}

export class UpdateServiceCameraFailure implements Action {
    readonly type = ServicesActionTypes.UpdateServiceCameraFailure;

    constructor() { }
}

export class DeleteServiceCamera implements Action {
    readonly type = ServicesActionTypes.DeleteServiceCamera;

    constructor(public cameraId: number) { }
}

export class DeleteServiceCameraSuccess implements Action {
    readonly type = ServicesActionTypes.DeleteServiceCameraSuccess;

    constructor(public cameraId: number) { }
}

export class DeleteServiceCameraFailure implements Action {
    readonly type = ServicesActionTypes.DeleteServiceCameraFailure;

    constructor() { }
}

export class ResetServiceCamera implements Action {
    readonly type = ServicesActionTypes.ResetServiceCamera;

    constructor(public rdvaUri: string, public cameraId: number) { }
}

export class ResetServiceCameraSuccess implements Action {
    readonly type = ServicesActionTypes.ResetServiceCameraSuccess;

    constructor() { }
}

export class ResetServiceCameraFailure implements Action {
    readonly type = ServicesActionTypes.ResetServiceCameraFailure;

    constructor() { }
}

export class ConnectServiceCamera implements Action {
  readonly type = ServicesActionTypes.ConnectServiceCamera;

  constructor(public camera: Camera) { }
}

export class ConnectServiceCameraSuccess implements Action {
  readonly type = ServicesActionTypes.ConnectServiceCameraSuccess;

  constructor(public success: void) { }
}

export class ConnectServiceCameraFailure implements Action {
  readonly type = ServicesActionTypes.ConnectServiceCameraFailure;

  constructor(public message: string) { }
}

export class ActiveServiceOnvif implements Action {
  readonly type = ServicesActionTypes.ActiveServiceOnvif;

  constructor(public camera: Camera) { }
}

export class ActiveServiceOnvifSuccess implements Action {
  readonly type = ServicesActionTypes.ActiveServiceOnvifSuccess;

  constructor(public active: boolean) { }
}

export class ActiveServiceOnvifFailure implements Action {
  readonly type = ServicesActionTypes.ActiveServiceOnvifFailure;

  constructor(public active: boolean) { }
}

export class TuneServiceOnvif implements Action {
  readonly type = ServicesActionTypes.TuneServiceOnvif;

  constructor(public camera: Camera) { }
}

export class TuneServiceOnvifSuccess implements Action {
  readonly type = ServicesActionTypes.TuneServiceOnvifSuccess;

  constructor(public success: boolean) { }
}

export class TuneServiceOnvifFailure implements Action {
  readonly type = ServicesActionTypes.TuneServiceOnvifFailure;

  constructor(public success: boolean) { }
}

export type ServicesCamerasActions =
    | GetServiceCameras
    | GetServiceCamerasSuccess
    | GetServiceCamerasFailure

    | AddServiceCamera
    | AddServiceCameraSuccess
    | AddServiceCameraFailure

    | UpdateServiceCamera
    | UpdateServiceCameraSuccess
    | UpdateServiceCameraFailure

    | DeleteServiceCamera
    | DeleteServiceCameraSuccess
    | DeleteServiceCameraFailure

    | ResetServiceCamera
    | ResetServiceCameraSuccess
    | ResetServiceCameraFailure

    | ConnectServiceCamera
    | ConnectServiceCameraSuccess
    | ConnectServiceCameraFailure

    | ActiveServiceOnvif
    | ActiveServiceOnvifSuccess
    | ActiveServiceOnvifFailure

    | TuneServiceOnvif
    | TuneServiceOnvifSuccess
    | TuneServiceOnvifFailure;
