export const environment = {
  name: 'Личный кабинет - Умный домофон',
  production: false,
  vivotec: false,
  kz: false,
  lang: 'ru',
  languages: ['ru'],
  locale: 'ru-RU',
  authApiUrl: 'https://dev.rdba.rosdomofon.com',
  authserverApiUrl: 'https://dev.rdba.rosdomofon.com/authserver-service/api/',
  abonentsApiUrl: 'https://dev.rdba.rosdomofon.com/abonents-service/api/',
  intercomApiUrl: 'https://dev.rdba.rosdomofon.com/rdas-service/api/',
  companiesApiUrl: 'https://dev.rdba.rosdomofon.com/companies-service/api/',
  companiesReportsApiUrl: 'https://dev.rdba.rosdomofon.com/companies-service/api/',
  camerasApiUrl: 'https://dev.rdba.rosdomofon.com/cameras-service/api/',
  monitoringApiUrl: 'https://dev.rdba.rosdomofon.com/monitoring-service/api/',
  paymentsApiUrl: 'https://dev.rdba.rosdomofon.com/payments-service/api/',
  projectsVersionsUrl: 'https://dev.rdba.rosdomofon.com/rdas-service/api/',

  dadataApiUrl: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/',
  dadataToken: '5dcb1d828b5e1cfaebd5ce387e5ba8a0b68cdb90',
  kzPostApiUrl: 'https://api.post.kz/api/',

  graylogApiUrl: 'https://graylog.rosdomofon.com/api/',
  graylogToken: 'l1ubm7edc5jcvcekia6nt1q96dmd8gc5gmvlq3tg29hps7f05a3',
  graylogStream: '5df74432a986590013246398',



  RDPNClientUrl: 'https://dev.opendoor.rosdomofon.com/',
  RDVAPort: '2205',
  RDVAHlsPort: '2206',

  yandexApiKey: 'c81e74fc-b4d3-427d-a762-88f906ff6829',

  amplitudeApi: 'bb40e1ed96557fe34f83bec01b130087',
  ROSDOMOFON_COMPANY_ID: 1,

  onvifServerUrl: 'https://dev-onvif.rosdomofon.com/onvif-service/api/',
};
