import { Component, Inject } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PopupBasicComponent } from '@app/shared/templates';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { Subject } from 'rxjs';
import { orderCloseFormGroup } from './order-close-form.model';

@Component({
  selector: 'app-order-close-form',
  templateUrl: './order-close-form.component.html',
  styleUrls: ['./order-close-form.component.scss']
})
export class OrderCloseFormComponent extends PopupBasicComponent {
  form: UntypedFormGroup;

  constructor(
    public resolutionService: ResolutionService,
    @Inject('subject') private subject: Subject<any>
  ) {
    super();
    this.initForm();
  }

  onSubmit() {
    const { warrantyStartDate }: { warrantyStartDate: Date } = this.form.getRawValue();
    this.subject.next(warrantyStartDate.getTime());
  }

  private initForm() {
    this.form = orderCloseFormGroup().form;
  }
}
