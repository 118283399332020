import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { AbonentConnectionToolModule } from '@app/shared/components';
import { SharedPipesModule } from '@app/shared/pipes';
import { ComponentLoaderModule, DialogWrapperModule, InfoBlockModule } from '@app/shared/ui';
import { AbonentConnectionPopupComponent } from './abonent-connection-popup.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        InfoBlockModule,
        SharedPipesModule,
        DialogWrapperModule,
        ComponentLoaderModule,
        AbonentConnectionToolModule,
    ],
    declarations: [
        AbonentConnectionPopupComponent
    ],
    exports: [
        AbonentConnectionPopupComponent
    ]
})
export class AbonentConnectionPopupModule { }
