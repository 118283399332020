import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GitlabComponentsVersions } from '@app/shared/entities/integrations';
import { PbxOnRdaResponse, RdaResponse } from '@app/shared/entities/rd';

@Component({
  selector: 'app-intercom-info-content',
  templateUrl: './intercom-info-content.component.html',
  styleUrls: ['./intercom-info-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntercomInfoContentComponent {
  @Input() intercom: RdaResponse;
  @Input() gitlabComponentsVersions?: GitlabComponentsVersions;
  @Input() isIpIntercom?: boolean;
  @Input() livenessUrl?: string;
  @Input() pbxOnRda?: PbxOnRdaResponse;
  @Input() rdosLatest?: boolean;
  @Input() rdaaLatest?: boolean;
}
