import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { SnackbarService } from '@app/shared/components/snackbar';
import {
  AdapterInfoForKeyResponse,
  IntercomKeyRecord,
  intercomKeyRecords,
  IntercomKeyRelay,
  IntercomKeyType,
  IntercomType,
  IntercomTypeGeneratorUrls,
  KeysResponse,
  ProtocolTypes,
  RdaKeysRequest,
  RdaResponse
} from '@app/shared/entities/rd';
import { IntercomMetacomKeyRelayType } from '@app/shared/entities/rd/keys/models/intercom-metacom-key-relay.type';
import { Constants } from '@app/shared/helpers';
import { LocationResponse, SelectSearch } from '@app/shared/models';
import { IpRdaConnectionTypePipe } from '@app/shared/pipes';
import { DialogWrapperData } from '@app/shared/ui';
import { IntercomKeysEditContainerResponse } from './intercom-keys-edit-container-response.model';
import { IntercomKeysEditContainerHelper } from './intercom-keys-edit-container.helper';
import { IntercomKeysUrlsGeneratorBottomSheetComponent } from './intercom-keys-urls-generator-bottom-sheet';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'environments/environment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-intercom-keys-edit-container',
  templateUrl: './intercom-keys-edit-container.component.html',
  styleUrls: ['./intercom-keys-edit-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [IntercomKeysEditContainerHelper, IpRdaConnectionTypePipe]
})
export class IntercomKeysEditContainerComponent implements OnInit {
  public readonly environment = environment;
  readonly typesList: number[] = Object.values(intercomKeyRecords).map(key => key.type);
  readonly intercomKeyRecords: Record<IntercomKeyType, IntercomKeyRecord> = intercomKeyRecords;
  intercomKeyRelays: number[];
  @Input() keysInUse: KeysResponse[];

  @Input() serviceRdas: RdaResponse[];

  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.changeFormEnabled(!this.loading && !this.disabled);
  }

  @Input() set rdas(rdas: RdaResponse[]) {
    this._rdas = rdas;

    if (this.rdas?.length && !this.initialRdaSelect) {
      this.selectedRdaResponse = this.rdas[0];
      this.setRelayList(this.selectedRdaResponse?.intercomType?.protocol?.number);
      this.initialRdaSelect = {
        text: this.selectedRdaResponse.uid,
        value: this.selectedRdaResponse.uid,
        badge: this.selectedRdaResponse?.intercomType?.protocol.ipType ? this.ipRdaConnectionTypePipe.transform(this.selectedRdaResponse.mode) : null
      };

      this.initKeyValuesFromRda(this.selectedRdaResponse);
    }
  }

  @Input() set key(key: KeysResponse) {
    this._key = key;

    if (key && !this.initialRdaSelect) {
      this.initialRdaSelect = {
        text: key.adapterInfo.uid,
        value: key.adapterInfo.uid,
        badge: key.adapterInfo.ipType ? this.ipRdaConnectionTypePipe.transform(key.adapterInfo.mode) : null
      };

      this.initKeyValuesFromKey(this.key);
    }
  }

  @Input() locations: LocationResponse[];
  ipType: boolean;
  basIp: boolean;
  sputnikIp: boolean;
  protocolNumber: number;
  form: UntypedFormGroup = this.initForm();
  rdaSelect: SelectSearch;
  initialRdaSelect: SelectSearch;
  selectedRdaResponse: RdaResponse;
  emptyRdaIntercomType: boolean;

  @Output() private keyChange: EventEmitter<IntercomKeysEditContainerResponse> = new EventEmitter();
  private _connectedRelays: Record<number, boolean> = {};
  private _disabled = true;
  private _rdas: RdaResponse[];
  private _key: KeysResponse;
  private _loading: boolean;
  private locationsMapped = new BehaviorSubject<SelectSearch[]>([]);
  locationsMapped$ = this.locationsMapped.asObservable();
  selectedLocation: SelectSearch;

  constructor(
    private snackbar: SnackbarService,
    private bottomSheet: MatBottomSheet,
    private changeDetectorRef: ChangeDetectorRef,
    private ipRdaConnectionTypePipe: IpRdaConnectionTypePipe,
    private intercomKeysEditContainerHelper: IntercomKeysEditContainerHelper,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.setLocationsMapped();
  }

  get loading(): boolean {
    return this._loading;
  }

  set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.changeFormEnabled(!this.loading && !this.disabled);
  }

  get disabled() {
    return this._disabled;
  }

  set connectedRelays(connectedRelays: Record<number, boolean>) {
    this._connectedRelays = connectedRelays;

    if (this.key) {
      this.connectedRelays[this.key.releId ?? this.key.relay] = false;
    }
    let relayListLength = 4;

    if (this.protocolNumber === ProtocolTypes.MetacomWithManyPanels || this.protocolNumber === ProtocolTypes.Metacom) {
      relayListLength = 32;
    }

    this.disabled = Object
      .keys(this.connectedRelays)
      .filter(relay => this.connectedRelays[relay] === true)?.length === relayListLength;

    this.changeFormEnabled(!this.loading && !this.disabled);
    this.changeDetectorRef.markForCheck();
  }

  get connectedRelays(): Record<number, boolean> {
    return this._connectedRelays;
  }

  get key(): KeysResponse {
    return this._key;
  }

  get rdas(): RdaResponse[] {
    return this._rdas;
  }

  setLocationsMapped(): void {
    this.locationsMapped.next(this.locations
      .map(location => {
        return { text: location.name, value: location.id };
      })
    );
  }

  onSubmit() {
    if (!this.rdaSelect) {
      this.snackbar.showMessage(this.translate.instant('shared.intercom.keys.edit.container.message.select_adapter_for_which_you_want_to_create_key'), 'info');
      return;
    }

    if (!this.form.valid) {
      this.snackbar.showMessage(this.translate.instant('shared.intercom.keys.edit.container.message.indicate_all_necessary_data'), 'info');
      return;
    }

    let intercomInfo: AdapterInfoForKeyResponse;

    if (this.key) {
      intercomInfo = this.key.adapterInfo;
    } else if (this.selectedRdaResponse) {
      intercomInfo = {
        uid: this.selectedRdaResponse.uid,
        ipType: this.selectedRdaResponse.intercomType?.protocol?.ipType,
        protocolNumber: this.selectedRdaResponse.intercomType?.protocol?.number,
        mode: this.selectedRdaResponse.mode
      };
    } else {
      this.snackbar.showMessage(this.translate.instant('shared.intercom.keys.edit.container.message.no_information_about_adapter'));
      return;
    }

    const request: RdaKeysRequest = this.form.getRawValue();

    this.keyChange.emit({
      keyId: this.key?.id ?? null,
      intercomInfo,
      request,
      locationName: this.locations.find(item => item.id === request.locationId)?.name ?? null
    });
  }

  onGenerateUrls() {
    const data: DialogWrapperData<{
      protocolType: ProtocolTypes,
      intercom: IntercomType,
      params: Array<keyof IntercomTypeGeneratorUrls>
    },
      Partial<IntercomTypeGeneratorUrls>> = {
      title: this.translate.instant('shared.intercom.keys.edit.container.generate_url.title'),
      componentName: 'GenerateIpIntercomUrls',
      body: {
        protocolType: this.protocolNumber,
        intercom: this.selectedRdaResponse?.intercomType,
        params: ['authUrl', 'openDoorUrl']
      },
      submit: (event: Partial<IntercomTypeGeneratorUrls>) => {
        if (event.authUrl || event.authUrl === null) {
          this.form.get('authUrl').setValue(event.authUrl);
        }

        if (event.bearerToken || event.bearerToken === null) {
          this.form.get('authUrl').setValue(event.bearerToken);
        }

        if (event.openDoorUrl || event.openDoorUrl == null) {
          this.form.get('openDoorUrl').setValue(event.openDoorUrl);
        }

        matBottomSheetRef.dismiss();
      }
    };

    const matBottomSheetRef = this.bottomSheet.open(
      IntercomKeysUrlsGeneratorBottomSheetComponent,
      {
        panelClass: Constants.CUSTOM_DIALOG_CLASS,
        data
      }
    );
  }

  onLocationSelected(selectItem: SelectSearch) {
    this.form.get('locationId')?.setValue(selectItem.value);
  }

  onRdaSelected(selectedRdaResponse: RdaResponse, rdaSelectSearch: SelectSearch) {
    if (!selectedRdaResponse) {
      this.selectedRdaResponse = this.rdas.find(rda => rda.intercomType.protocol.number === this.protocolNumber);
    } else {
      this.selectedRdaResponse = selectedRdaResponse;
    }
    this.rdaSelect = rdaSelectSearch;

    if (this.selectedRdaResponse) {
      this.initKeyValuesFromRda(this.selectedRdaResponse);
    }

    if (this.rdaSelect) {
      this.disabled = true;
      this.changeFormEnabled(!this.disabled);

      this.findConnectedRelaysForIntercom(
        this.rdaSelect.value as string,
        (connectedRelays: Record<number, boolean>) => {
          this.connectedRelays = connectedRelays;

          if (!this.key) {
            this.setFreeRelay();
          }
        }
      );
      this.setRelayList(this.protocolNumber);
    }
  }

  getRelays(): (string | IntercomKeyRelay)[] {
    return this.intercomKeysEditContainerHelper.relays;
  }

  private initKeyValuesFromKey(key: KeysResponse) {
    if (!this.key.adapterInfo) {
      return;
    }

    this.rdaSelect = {
      text: this.key.adapterInfo.uid,
      value: this.key.adapterInfo.uid,
      badge: this.key.adapterInfo.ipType ? this.ipRdaConnectionTypePipe.transform(this.key.adapterInfo.mode) : null
    };

    this.ipType = this.key.adapterInfo.ipType;
    this.protocolNumber = this.key.adapterInfo.protocolNumber;
    this.basIp = this.protocolNumber === ProtocolTypes.BAS_IP;
    this.sputnikIp = this.protocolNumber === ProtocolTypes.Sputnik;

    // If key connected to IP intercom
    if (this.ipType) {
      this.form.addControl('openDoorUrl', new UntypedFormControl({
        value: key.openDoorUrl,
        disabled: !this.loading && !this.disabled
      }, Validators.required));

      if (this.basIp || this.sputnikIp) {
        this.form.addControl('authUrl', new UntypedFormControl({
          value: key.authUrl,
          disabled: !this.loading && !this.disabled
        }, Validators.required));
      }
    }

    this.setRelayList(key.adapterInfo.protocolNumber);

    this.form.get('releId').setValue(key.releId ?? key.relay);
    this.form.get('type').setValue(key.type);
    this.form.get('locationId').setValue(key.location?.id);

    this.selectedLocation = {
      text: key.location?.name,
      value: key.location?.id
    };
  }

  private initKeyValuesFromRda(rda: RdaResponse) {
    this.rdaSelect = {
      text: rda.uid,
      value: rda.uid,
      badge: rda?.intercomType?.protocol.ipType ? this.ipRdaConnectionTypePipe.transform(rda.mode) : null
    };

    if (rda.intercomType) {
      this.protocolNumber = rda.intercomType.protocol.number;
      this.emptyRdaIntercomType = false;
      this.ipType = rda.intercomType.protocol.ipType;
      this.basIp = rda.intercomType.protocol.number === ProtocolTypes.BAS_IP;
      this.sputnikIp = rda.intercomType.protocol.number === ProtocolTypes.Sputnik;
    } else {
      this.protocolNumber = null;
      this.ipType = !!rda.mode;
      this.basIp = false;
      this.emptyRdaIntercomType = true;
    }

    // If key connected to IP intercom
    if (this.ipType) {
      if (!this.form.get('openDoorUrl')) {
        this.form.addControl('openDoorUrl', new UntypedFormControl({
          value: null,
          disabled: !this.loading && !this.disabled
        }, Validators.required));
      }

      if (this.sputnikIp || this.basIp && !this.form.get('authUrl')) {
        this.form.addControl('authUrl', new UntypedFormControl({
          value: null,
          disabled: !this.loading && !this.disabled
        }, Validators.required));
      } else if (!this.basIp && !!this.form.get('authUrl')) {
        this.form.removeControl('authUrl');
      }
    } else {
      if (this.form.get('openDoorUrl')) {
        this.form.removeControl('openDoorUrl');
      }

      if (!this.basIp && this.form.get('authUrl')) {
        this.form.removeControl('authUrl');
      }
    }
  }

  private findConnectedRelaysForIntercom(rdaUid: string, cb: (connectedRelays: Record<number, boolean>) => void) {
    this.intercomKeysEditContainerHelper.getRelaysListForAdapter(
      rdaUid,
      this.protocolNumber,
      (success: boolean, connectedRelays: Record<number, boolean>, error: string) => {
        if (success) {
          cb(connectedRelays);
        } else {
          this.snackbar.showMessage(this.translate.instant('shared.intercom.keys.edit.container.message.error_when_receiving_adapter_keys', {
            text: error
          }));
        }
      }
    );
  }

  /**
   * Set 3-rd relay if it's free and set first free relay if isn't
   */
  private setFreeRelay() {
    const relay: number = this.intercomKeysEditContainerHelper.getFreeRelay(this.connectedRelays, this.protocolNumber);

    if (relay !== null && Number.isInteger(relay)) {
      this.form.get('releId').setValue(relay);
    } else {
      this.snackbar.showMessage(this.translate.instant('shared.intercom.keys.edit.container.message.no_free_relay_on_adapter'));
    }
  }

  private changeFormEnabled(enabled: boolean) {
    if (!this.form) {
      return;
    }

    enabled ? this.form.enable() : this.form.disable();
  }

  private setRelayList(protocolNumber?: number) {
    if (protocolNumber === ProtocolTypes.MetacomWithManyPanels || protocolNumber === ProtocolTypes.Metacom) {
      this.getIntercomRelays(IntercomMetacomKeyRelayType);
    } else {
      this.getIntercomRelays(IntercomKeyRelay);
    }
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      locationId: new UntypedFormControl({value: null, disabled: !this.loading && !this.disabled}),
      releId: new UntypedFormControl({value: null, disabled: !this.loading && !this.disabled}, Validators.required),
      type: new UntypedFormControl({
        value: IntercomKeyType.DOOR,
        disabled: !this.loading && !this.disabled
      }, Validators.required)
    });
  }

  private getIntercomRelays(object: Object) {
    this.intercomKeyRelays = Object.values(object).filter(relay =>
      !isNaN(Number(relay)) && !(this.keysInUse && this.keysInUse.find(key => Number(key.relay) === Number(relay)))
    ) as number[];
  }
}
