import {RdeaDate} from '@app/shared/entities/common';
import {RdvaPlaylistItem} from '@app/shared/entities/integrations';
import {PlyrTemplateHelper, PlyrTimeHelper} from '../../plyr-globals';
import {PlyrControlBasicHelper} from '../base';
import {PlyrPlaylistsControlsEventType} from './plyr-playlists-controls-event-type.enum';
import {TranslateService} from '@ngx-translate/core';

/**
 * Class for playlist menu and playlists buttons
 */
export class PlyrPlaylistsControlsHelper extends PlyrControlBasicHelper<PlyrPlaylistsControlsEventType> {
  constructor(
    private translate: TranslateService
  ) {
    super();
    this.enablePlaylistsClickListeners();
  }

  /**
   * Put playlists to playlists menu
   * @param playlists playlists for redraw menu selector
   */
  preparePlaylistMenu(playlists: RdvaPlaylistItem[]) {
    const playlistsSelectorList: HTMLDivElement =
      document.getElementById(PlyrTemplateHelper.PLAYLISTS_SELECTOR_LIST_ID) as HTMLDivElement;

    if (!playlistsSelectorList) {
      return;
    }

    if (!playlists?.length) {
      playlistsSelectorList.innerHTML = '<span>' + this.translate.instant('shared.video_player.plyr.controls.playlists.no_data') + '<span>';
      return;
    }

    playlistsSelectorList.innerHTML = '';

    playlists.forEach((playlist: RdvaPlaylistItem) =>
      playlistsSelectorList.innerHTML += PlyrTemplateHelper.getPlaylistMenuButton(playlist.playlist, playlist.date)
    );

    document.getElementById(PlyrTemplateHelper.PLAYLISTS_MENU_BUTTON_ID).innerText =
      new RdeaDate(playlists[playlists.length - 1].date).toDayMonthString();
  }

  /**
   * Redraw playlists menu button depending on expaneded variable
   * @param expanded new expanded state for playlists menu button
   */
  drawPlaylistsMenuButton(expanded: boolean) {
    document.getElementById(PlyrTemplateHelper.PLAYLISTS_SELECTOR_ID)
      .style.display = expanded ? 'inline' : 'none';

    document.getElementById(PlyrTemplateHelper.PLAYLISTS_MENU_BUTTON_ID)
      .setAttribute('aria-expanded', expanded.toString());
  }

  /**
   * Enable listeners for click on playlists menu button and playlist button
   */
  private enablePlaylistsClickListeners() {
    const playlistsMenuButton: HTMLButtonElement =
      document.getElementById(PlyrTemplateHelper.PLAYLISTS_MENU_BUTTON_ID) as HTMLButtonElement;

    playlistsMenuButton.addEventListener('click', (e: MouseEvent) => {
      const expanded: boolean = playlistsMenuButton.getAttribute('aria-expanded') === 'true';
      this.drawPlaylistsMenuButton(!expanded);
    });

    const playListsSelectorList: HTMLButtonElement =
      document.getElementById(PlyrTemplateHelper.PLAYLISTS_SELECTOR_LIST_ID) as HTMLButtonElement;

    playListsSelectorList.addEventListener('click', (e: MouseEvent) => {
      this.drawPlaylistsMenuButton(false);
      const clickedPlaylistButtonValue: string = (e.target as HTMLButtonElement).getAttribute('attr-date');
      playlistsMenuButton.innerText = new RdeaDate(Number(clickedPlaylistButtonValue)).toDayMonthString();
      PlyrTimeHelper.sourceDate = Number(clickedPlaylistButtonValue);

      this.clickListener$.next({type: PlyrPlaylistsControlsEventType.SELECT_ITEM, e});
    });
  }
}
