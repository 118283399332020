<div class="additional-ranges-container">
  <h3>
    {{ 'shared.additional.ranges.form.title' | translate }}
  </h3>

  <div class="additional-ranges-container-create-range-block">
    <h4 *ngIf="!(mdWDownBreakpoint$ | async)">
      {{ 'shared.additional.ranges.form.field.range_apartments' | translate }}
    </h4>

    <form
      class="additional-ranges-container-create-range-block-form"
      [formGroup]="form"
      (ngSubmit)="onCreate()"
      autocomplete="off"
    >
      <mat-form-field
        appearance="outline"
        [class.none-padding]="!(mdWDownBreakpoint$ | async)"
        [class.none-margin]="!(mdWDownBreakpoint$ | async)"
      >
        <mat-label>
          {{ 'shared.additional.ranges.form.field.range_apartments_start' | translate }}
        </mat-label>
        <input
          type="number"
          min="0"
          matInput
          formControlName="flatStart"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          required
        />
      </mat-form-field>

      <span *ngIf="!(mdWDownBreakpoint$ | async)">&#8212;</span>

      <mat-form-field
        appearance="outline"
        [class.none-padding]="!(mdWDownBreakpoint$ | async)"
        [class.none-margin]="!(mdWDownBreakpoint$ | async)"
      >
        <mat-label>
          {{ 'shared.additional.ranges.form.field.range_apartments_end' | translate }}
        </mat-label>
        <input
          type="number"
          min="0"
          matInput
          formControlName="flatEnd"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          required
        />
      </mat-form-field>

      <button
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="loading"
        class="additional-ranges-container-create-range-block-form-button"
        *ngIf="mdWDownBreakpoint$ | async; else addAdditionalRangeDesktop"
      >
        {{ 'shared.additional.ranges.form.button.create' | translate }}
      </button>

      <ng-template #addAdditionalRangeDesktop>
        <button
          type="submit"
          mat-fab
          [disabled]="loading"
          color="primary-inverse"
          class="additional-ranges-container-create-range-block-form-button"
        >
          <mat-icon>add</mat-icon>
        </button>
      </ng-template>
    </form>
  </div>

  <h3 *ngIf="additionalRanges?.length > 0">
    {{ 'shared.additional.ranges.form.field.additional_ranges' | translate }}
  </h3>

  <div class="additional-ranges-container-ranges-list-block">
    <div
      class="additional-ranges-container-ranges-list-block-item"
      *ngFor="let range of additionalRanges; let lastItem = last"
    >
      <mat-form-field
        appearance="outline"
        [class.none-padding]="!(mdWDownBreakpoint$ | async)"
        [class.none-margin]="!(mdWDownBreakpoint$ | async)"
      >
        <mat-label>
          {{ 'shared.additional.ranges.form.field.additional_ranges_start' | translate }}
        </mat-label>
        <input
          type="number"
          matInput
          [(ngModel)]="range.flatStart"
          [disabled]="loading"
        />
      </mat-form-field>

      <span *ngIf="!(mdWDownBreakpoint$ | async)">&#8212;</span>

      <mat-form-field
        appearance="outline"
        [class.none-padding]="!(mdWDownBreakpoint$ | async)"
        [class.none-margin]="!(mdWDownBreakpoint$ | async)"
      >
        <mat-label>
          {{ 'shared.additional.ranges.form.field.additional_ranges_end' | translate }}
        </mat-label>
        <input
          type="number"
          matInput
          [disabled]="loading"
          [(ngModel)]="range.flatEnd"
        />
      </mat-form-field>

      <div class="additional-ranges-container-ranges-list-block-item-actions-block">
        <button
          type="button"
          mat-raised-button
          color="primary-inverse"
          [disabled]="loading"
          class="additional-ranges-container-ranges-list-block-item-actions-block-button"
          *ngIf="mdWDownBreakpoint$ | async; else desktopUpdateButton"
          (click)="onUpdate(range)"
        >
          {{ 'shared.additional.ranges.form.button.update' | translate }}
        </button>

        <ng-template #desktopUpdateButton>
          <button
            type="button"
            mat-fab
            [disabled]="loading"
            color="primary-inverse"
            class="additional-ranges-container-ranges-list-block-item-actions-block-button"
            (click)="onUpdate(range)"
          >
            <mat-icon>cached</mat-icon>
          </button>
        </ng-template>

        <button
          type="button"
          mat-raised-button
          color="error"
          [disabled]="loading"
          class="additional-ranges-container-ranges-list-block-item-actions-block-button"
          *ngIf="mdWDownBreakpoint$ | async; else desktopRemoveButton"
          (click)="onRemove(range.id)"
        >
          {{ 'shared.additional.ranges.form.button.delete' | translate }}
        </button>

        <ng-template #desktopRemoveButton>
          <button
            type="button"
            mat-fab
            color="error"
            [disabled]="loading"
            class="additional-ranges-container-ranges-list-block-item-actions-block-button"
            (click)="onRemove(range.id)"
          >
            <mat-icon>remove</mat-icon>
          </button>
        </ng-template>
      </div>

      <hr *ngIf="!lastItem && (mdWDownBreakpoint$ | async)">
    </div>
  </div>
</div>
