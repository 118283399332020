import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { FlatCardsTableModule } from '@app/shared/components';
import { SharedDirectivesModule } from '@app/shared/directives';
import { SharedPipesModule } from '@app/shared/pipes';
import { InfoBlockModule } from '@app/shared/ui';
import {
  ServiceFlatsFiltersModule,
  ServiceFlatsModule
} from '@app/views/services/components';
import {
  ServiceAbonentsFromEntrancesPopupModule,
  ServiceConnectionPopupModule,
  ServiceDeletePhysicalTubePopupModule,
  ServiceSubmitConnectionPopupModule
} from '@app/views/services/components/popups';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { GateFlatsComponent } from './gate-flats.component';
import { ServiceTitleRowModule } from '@app/views/services/components/containers/service-title-row/service-title-row.module';
import { ServiceFlatsMenuModule } from '@app/views/services/components/containers/service-flats-menu/service-flats-menu.module';
import { FlatTariffModule } from '@app/shared/components/connections/flat-tariff';
import { FlatServiceModule } from '@app/shared/components/connections/flat-service/flat-service.module';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        ReactiveFormsModule,
        InfoBlockModule,
        FlatCardsTableModule,
        ServiceFlatsModule,
        SharedPipesModule,
        SharedDirectivesModule,
        ServiceFlatsFiltersModule,
        ServiceConnectionPopupModule,
        ServiceAbonentsFromEntrancesPopupModule,
        NgxSkeletonLoaderModule,
        ServiceDeletePhysicalTubePopupModule,
        ServiceSubmitConnectionPopupModule,
        ServiceTitleRowModule,
        ServiceFlatsMenuModule,
        FlatTariffModule,
        FlatServiceModule,
        NgxTranslateModule
    ],
  declarations: [GateFlatsComponent],
  exports: [GateFlatsComponent]
})
export class GateFlatsModule {}
