import { createSelector } from '@ngrx/store';
import { SharedModuleStates } from '../../reducers/reducers';
import { sharedModuleSelector } from '../shared-module.state';

const disconnectAdapterSelector = createSelector(
    sharedModuleSelector,
    (state: SharedModuleStates) => state.disconnectAdapter
);

export const disconnectAdapterState = createSelector(
    disconnectAdapterSelector,
    state => state
);

export const disconnectAdapterStateSuccess = createSelector(
    disconnectAdapterSelector,
    state => state.success
);

export const disconnectAdapterStateLoading = createSelector(
    disconnectAdapterSelector,
    state => state.loading
);

export const disconnectAdapterStateFailure = createSelector(
    disconnectAdapterSelector,
    state => state.error
);
