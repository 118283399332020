import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoreFacade} from '@app/core/store';
import {SnackbarService} from '@app/shared/components/snackbar';
import {
  LocalStorageCompanyKey,
  LocalStorageCoreKey,
  LocalStorageDashboardKey,
  LocalStorageGeneralKey,
  LocalStorageHelper
} from '@app/shared/entities/common';
import {AuthApiService, CompanyApiService, LoginResponse} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {
  AuthActionTypes,
  Login,
  LoginFailure,
  LoginFinished,
  LoginSuccess, LoginZammadInfo,
  Logout
} from '@app/views/auth/store/auth.actions';
import {DashboardFacade} from '@app/views/dashboard/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, exhaustMap, map, switchMap, tap} from 'rxjs/operators';
import {AuthFacade} from './auth.facade';
import {LocalStorageLoginKey} from '@app/shared/entities/common/local-storage/models/local-storage-login-key';
import {SupportApiService} from '@app/views/support-service/services/support-api.service';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AuthEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private route: ActivatedRoute,
    private snackbar: SnackbarService,
    private authApiService: AuthApiService,
    private coreFacade: CoreFacade,
    private authFacade: AuthFacade,
    private dashboardFacade: DashboardFacade,
    private companyApiService: CompanyApiService,
    private supportApiService: SupportApiService,
    private translate: TranslateService
  ) { }

  @Effect()
  Login$ = this.actions$.pipe(
    ofType<Login>(AuthActionTypes.Login),
    exhaustMap(action =>
      this.authApiService.login(action.request).pipe(
        map((response: LoginResponse) => {
          LocalStorageHelper.setItem(LocalStorageGeneralKey.AUTH_TOKEN, response.access_token);
          LocalStorageHelper.setItem(LocalStorageGeneralKey.USER_NAME, action.request.username);

          return new LoginSuccess(response);
        }),
        catchError((error: HttpErrorResponse) => {
          this.snackbar.showMessage(parseError(error));
          return of(new LoginFailure());
        })
      )
    ),
  );

  @Effect()
  ZammadInfo$ = this.actions$.pipe(
    ofType<LoginZammadInfo>(AuthActionTypes.LoginZammadInfo),
    exhaustMap(action =>
      this.supportApiService.getOrganizationsByName(action.companyName)
        .pipe(
          map(response => {
            const organization = response.find((org) => org.name.toLowerCase() === action.companyName.toLowerCase());
            if (organization && organization.id) {
              LocalStorageHelper.setItem(LocalStorageCompanyKey.ZAMMAD_ORGANIZATION_ID, organization.id);
            }
            LocalStorageHelper.setItem(
              LocalStorageCompanyKey.IS_ZAMMAD_ORGANIZATION_EXISTING,
              organization !== undefined
            );

            return new LoginFinished();
          }),
          catchError(() => of(new LoginFinished()))
        )
    ));

  @Effect()
  LoginSuccess$ = this.actions$.pipe(
    ofType<LoginSuccess>(AuthActionTypes.LoginSuccess),
    exhaustMap(action =>
      this.companyApiService.getCompanyById(action.response.company).pipe(
        map((company => {
          LocalStorageHelper.setItem(LocalStorageGeneralKey.COUNTRY, company.country);
          LocalStorageHelper.setItem(LocalStorageGeneralKey.LICENSE_AGREEMENT, company.licenseAgreement);
          LocalStorageHelper.setItem(LocalStorageCompanyKey.NAME, company.name);
          LocalStorageHelper.setItem(LocalStorageCompanyKey.SHORT_NAME, company.shortName);
          LocalStorageHelper.setItem(LocalStorageCompanyKey.ID, company.id);
          LocalStorageHelper.setItem(LocalStorageCompanyKey.INN, company.inn);
          LocalStorageHelper.setItem(LocalStorageCompanyKey.IS_BLOCKED, company.isBlocked);
          LocalStorageHelper.setItem(LocalStorageCompanyKey.MANAGER_EMAIL, company.managerEmail);
          LocalStorageHelper.setItem(LocalStorageCompanyKey.MANAGER_NAME, company.managerName);
          LocalStorageHelper.setItem(LocalStorageCompanyKey.SUPPORT_PHONE, company.supportPhone);

          return new LoginZammadInfo(company.name);
        })
      )
    ).pipe(
        catchError((error: HttpErrorResponse) => {
          this.snackbar.showMessage(
            this.translate.instant('auth.store.message.login_success.failed', {
              text: parseError(error)
            })
          );
          return of(new LoginFailure());
        })
      )
  ));

  @Effect({ dispatch: false })
  LoginFinished$ = this.actions$.pipe(
    ofType<LoginFinished>(AuthActionTypes.LoginFinished),
    tap(() => {
      const hasQueryParams = !!this.route.snapshot.queryParams['redirectURL'];
      const hasLastUrl = !!LocalStorageHelper.getItem(LocalStorageLoginKey.USER_LAST_URL);
      let redirectURL = '/';

      if (hasQueryParams) {
        redirectURL = this.route.snapshot.queryParams['redirectURL'];
      }
      if (hasLastUrl && !hasQueryParams) {
        redirectURL = LocalStorageHelper.getItem(LocalStorageLoginKey.USER_LAST_URL);
      }

      this.router.navigate([redirectURL]);
    })
  );

  @Effect({ dispatch: false })
  Logout$ = this.actions$.pipe(
    ofType<Logout>(AuthActionTypes.Logout),
    tap(action => {
      if (!LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN)) {
        return;
      }

      const queryParams: { redirectURL?: string } = {};

      if (action.redirectURL) {
        queryParams.redirectURL = decodeURIComponent(action.redirectURL).replace('/login?redirectURL=', '');
      }

      this.coreFacade.clearStore();
      this.dashboardFacade.clearStore();
      this.authFacade.loginAuthenticated(false);
      LocalStorageHelper.setItem(LocalStorageLoginKey.USER_LAST_URL, location.pathname + location.search);

      Object.keys(LocalStorageGeneralKey).forEach(value => {
        if (value !== LocalStorageGeneralKey.HIDE_CONNECT_CONFIRMATION_POPUP) {
          LocalStorageHelper.deleteItem(LocalStorageGeneralKey[value]);
        }
      });

      Object.values(LocalStorageCompanyKey).forEach(value => LocalStorageHelper.deleteItem(value));
      Object.values(LocalStorageCoreKey).forEach(value => LocalStorageHelper.deleteItem(value));
      Object.values(LocalStorageDashboardKey).forEach(value => LocalStorageHelper.deleteItem(value));

      sessionStorage.removeItem('hidePasswordExpired');

      this.router.navigate(['/login'], { queryParams: queryParams });
    })
  );
}
