import { AbonentConnectionToolPageMode } from '@app/shared/components';
import {
  CreateAbonentAndGetAccountsResponse,
  ServiceInfoResponse,
  ServiceResponse
} from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';

export interface AbonentConnectionToolState {
  servicesLoading: boolean;
  abonentDataDisabled: boolean;
  services: ServiceInfoResponse[];
  connectedServices: Dictionary<boolean>;
  selectedServices: Pick<ServiceResponse, 'id' | 'type'>[];
  servicesForDelete: Pick<ServiceResponse, 'id' | 'type'>[];
  pageMode: AbonentConnectionToolPageMode;
  abonentAndAccountsResponse: CreateAbonentAndGetAccountsResponse;
}

export const abonentConnectionToolInitialState: AbonentConnectionToolState = {
  servicesLoading: undefined,
  abonentDataDisabled: undefined,
  services: [],
  connectedServices: {},
  selectedServices: [],
  servicesForDelete: [],
  pageMode: undefined,
  abonentAndAccountsResponse: undefined
};
