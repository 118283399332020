import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

const selectUpdateShieldState = createSelector(
  intercomModuleSelector,
  (state: IntercomModuleState) => state.updateShield
);

export const getUpdateShield = createSelector(
  selectUpdateShieldState,
  state => state
);

export const getUpdateShieldSuccess = createSelector(
  selectUpdateShieldState,
  state => state.success
);

export const getUpdateShieldFailure = createSelector(
  selectUpdateShieldState,
  state => state.error
);

export const getUpdateShieldLoading = createSelector(
  selectUpdateShieldState,
  state => state.loading
);
