import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

const selectRdaModelsState = createSelector(
  intercomModuleSelector,
  (state: IntercomModuleState) => state.getRdaModels
);

export const getRdaModels = createSelector(
  selectRdaModelsState,
  state => state
);

export const getRdaModelsSuccess = createSelector(
  selectRdaModelsState,
  state => state.models
);

export const getRdaModelsFailure = createSelector(
  selectRdaModelsState,
  state => state.error
);


export const getRdaModelsLoading = createSelector(
  selectRdaModelsState,
  state => state.loading
);
