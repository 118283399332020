<div class="form-container">
  <p>
    {{ 'intercom.pages.order.page.card.popups.assign.text' | translate }}
  </p>

  <div class="action-block">
    <button
      mat-raised-button
      color="primary"
      (click)="onSubmit()"
    >
      {{ 'intercom.pages.order.page.card.popups.assign.button.submit' | translate }}
    </button>
  </div>
</div>
