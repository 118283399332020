import { Pipe, PipeTransform } from '@angular/core';
import { ServicesTypes } from '@app/shared/models';
import { ServiceHelperService } from '../entities/rd';

@Pipe({ name: 'serviceTypeName' })
export class ServiceTypeNamePipe implements PipeTransform {
    constructor(
        private serviceHelperService: ServiceHelperService
    ) { }

    transform(serviceType: ServicesTypes): string {
        return this.serviceHelperService.getServiceNameTypeText(serviceType);
    }
}
