import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AddressInputMode, AddressSelectResponse} from '@app/shared/components/address/address-input';
import {SnackbarService} from '@app/shared/components/snackbar';
import {LocalStorageGeneralKey, LocalStorageHelper, ResolutionService} from '@app/shared/entities/common';
import {YandexApiService} from '@app/shared/entities/integrations';
import {CompanyApiService} from '@app/shared/entities/rd';
import {addressValidator} from '@app/shared/helpers';
import {Address, AddressInfo, Country, EntranceRegistration, EntranceResponse, FlatRange} from '@app/shared/models';
import {AddressFormatter} from '@app/shared/services';
import {EntranceEditContainerHelper} from './entrance-edit-container.helper';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'environments/environment';
import {
  IBrazilAddress
} from '@app/shared/components/entrances/entrance-edit-container/google-map-form/google-map-form.component';

@Component({
  selector: 'app-entrance-edit-container',
  templateUrl: './entrance-edit-container.component.html',
  styleUrls: ['./entrance-edit-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntranceEditContainerComponent implements OnInit {
  readonly addressInputMode: typeof AddressInputMode = AddressInputMode;
  public readonly environment = environment;

  @Input() set address(address: Address) {
    this._address = address;
    this.initAddress();
  }

  @Input() set showPrefix(showPrefix: boolean) {
    this._showPrefix = showPrefix;
    this.initShowPrefix();
  }

  @Input() set loading(loading: boolean) {
    this._loading = loading;

    if (this.loading) {
      this.form.disable();
    } else {
      this.form.enable();

      if (this.address) {
        this.form.get('address').disable();
        this.form.get('entrance').disable();
      }
    }
  }

  @Input() options: { intercomIndexesRequired?: boolean } = {};
  @Input() label: string;
  @Input() initialMode: AddressInputMode;
  form: UntypedFormGroup = this.initForm();
  country: Country;
  rawAddress: string;
  mapClickable: boolean;
  mode: AddressInputMode;
  addressInfo: AddressInfo;
  manualInputSuggestion: boolean;
  entranceRange: [number, number];

  @Output() private createEntrance: EventEmitter<{ entrance: EntranceRegistration, prefix?: string }> = new EventEmitter();
  @Output() private addRange: EventEmitter<{ range: FlatRange, entranceId: number }> = new EventEmitter();
  @Output() private updateRange: EventEmitter<{ rangeId: number, range: FlatRange, entranceId: number }> = new EventEmitter();
  @Output() private deleteRange: EventEmitter<{ rangeId: number, entranceId: number }> = new EventEmitter();
  private _loading: boolean;
  private _address: Address;
  private _showPrefix: boolean;

  constructor(
    public resolution: ResolutionService,
    private yandexApiService: YandexApiService,
    private snackbar: SnackbarService,
    private companyApiService: CompanyApiService,
    private translate: TranslateService,
    private addressFormatter: AddressFormatter
  ) {
  }

  ngOnInit() {
    this.country = LocalStorageHelper.getItem(LocalStorageGeneralKey.COUNTRY);
    this.mapClickable = this.companyApiService.foreignCompany(this.country);
    this.manualInputSuggestion = this.mapClickable;

    if (this.initialMode) {
      this.mode = this.initialMode;
      return;
    }

    this.mode = EntranceEditContainerHelper.setModeByCountry(this.country);
  }

  get loading(): boolean {
    return this._loading;
  }

  get address(): Address {
    return this._address;
  }

  get showPrefix(): boolean {
    return this._showPrefix;
  }

  onSubmit() {
    if(!EntranceEditContainerHelper.isRangeLimitValid(this.form.get('range').value)){
      this.snackbar.showMessage(this.translate.instant('shared.additional.ranges.form.message.range_limit_exceeded', {
        limit: EntranceEditContainerHelper.RANGE_LIMIT
      }), 'info');
      return false;
    }

    if (!EntranceEditContainerHelper.isRangeBoundsValid(this.form.get('range').value)) {
      this.snackbar.showMessage(this.translate.instant('shared.entrance.edit.container.message.indicate_correct_apartment_range'), 'info');
      return;
    }

    if (!this.form.valid) {
      this.snackbar.showMessage(this.translate.instant('shared.entrance.edit.container.message.indicate_all_necessary_data'), 'info');
      return;
    }

    const {range, prefix, address, entrance} = this.form.getRawValue();
    const entranceRegistration: EntranceRegistration = new EntranceRegistration({range, address, entrance});
    this.createEntrance.emit({entrance: entranceRegistration, prefix: prefix});
  }

  onSelectAddress(event: AddressSelectResponse) {
    if (event.addressStr) {
      this.rawAddress = event.addressStr;
    }

    if (event.entrance) {
      this.addressInfo = AddressFormatter.convertAddressToAddressInfo(event.entrance.address);
      this.form.get('address')
        .setValue(Object.assign(this.addressInfo, {rawAddress: event.entrance.addressString}));

      if (this.showPrefix) {
        this.form.get('prefix').setValue(event.entrance.address.entrance.prefix ?? null);
      }

      this.form.get('range').setValue([
        event.entrance.address.entrance.flatStart,
        event.entrance.address.entrance.flatEnd
      ]);
    }

    this.form.get('entrance').setValue(event.entranceNumber ?? null);
  }

  onChangedCustomAddress(event: { value: string, type: string }) {
    this.addressInfo[event.type] = event.value;
    this.addressInfo = {
      country: this.country,
      city: this.addressInfo.city,
      street: this.addressInfo.street,
      house: this.addressInfo.house,
      building: this.addressInfo.building ?? null,
      housing: this.addressInfo.housing ?? null,
      block: this.addressInfo.block ?? null,
      fiasCode: null,
      kladrCode: null,
      universalCode: null
    };

    this.form.get('address').setValue(
      Object.assign(this.addressInfo, {
        rawAddress: this.addressFormatter.formatAddressInfo(this.addressInfo)
      })
    );
  }

  onSelectAddressInMap(event: { addressInfo: AddressInfo, rawAddress: string, entrance: string }) {
    if (this.mode === AddressInputMode.CREATED) {
      return;
    }

    this.addressInfo = {
      country: this.country,
      city: event.addressInfo.city,
      street: event.addressInfo.street,
      house: event.addressInfo.house,
      building: event.addressInfo.building ?? null,
      housing: event.addressInfo.housing ?? null,
      block: event.addressInfo.block ?? null,
      fiasCode: null,
      kladrCode: null,
      universalCode: null
    };

    this.form.get('address').setValue(Object.assign(this.addressInfo, {rawAddress: event.rawAddress}));
    this.yandexApiService.setAddressFromMap(event.rawAddress);

    if (event.entrance) {
      this.form.get('entrance').setValue(event.entrance);
    }
  }

  onModeChange(mode: AddressInputMode) {
    if (this.mode === mode) {
      this.mode = EntranceEditContainerHelper.setModeByCountry(this.country);
      this.form.get('address').enable();
    } else {
      this.mode = mode;
    }

    switch (this.mode) {
      case AddressInputMode.CUSTOM:
        this.addressInfo = {
          country: null,
          city: null,
          street: null,
          house: null,
          building: null,
          housing: null,
          block: null,
          fiasCode: null,
          kladrCode: null,
          universalCode: null
        };
        break;
      default:
        this.addressInfo = null;
    }

    this.form.get('address').setValue({
      country: null,
      city: null,
      street: null,
      house: null,
      building: null,
      housing: null,
      block: null,
      fiasCode: null,
      kladrCode: null,
      universalCode: null,
      rawAddress: null
    });

    this.form.get('entrance').setValue(null);
    this.form.get('range').setValue([null, null]);

    if (this.showPrefix) {
      this.form.get('prefix').setValue(null);
    }

    this.rawAddress = null;
  }

  onAddRange(range: FlatRange) {
    this.addRange.emit({range, entranceId: this.address.entrance.id});
  }

  onUpdateRange(rangeId: number, range: FlatRange) {
    this.updateRange.emit({rangeId, range, entranceId: this.address.entrance.id});
  }

  onDeleteRange(rangeId: number) {
    this.deleteRange.emit({rangeId, entranceId: this.address.entrance.id});
  }

  public onAddressChange(brazilAddress: IBrazilAddress): void {
    this.form.patchValue(brazilAddress);
  }

  private initAddress() {
    if (!this.address) {
      return;
    }

    const entrance: EntranceResponse = this.address.entrance;

    if (entrance.flatEnd && entrance.flatStart) {
      this.entranceRange = [entrance.flatStart, entrance.flatEnd];
    }

    this.rawAddress = this.addressFormatter.formatAddress(this.address);
    this.addressInfo = AddressFormatter.convertAddressToAddressInfo(this.address);
    this.form.get('address').disable();
    this.form.get('entrance').setValue(this.address.entrance.number);
    this.form.get('entrance').disable();

    if (this.entranceRange) {
      this.form.get('range').setValue(this.entranceRange);
    }

    if (this.form.get('prefix')) {
      this.form.get('prefix').setValue(this.address.entrance.prefix);
    }

    this.country = this.addressInfo.country;

    this.mode = EntranceEditContainerHelper.setModeByCountry(this.country);
  }

  private initShowPrefix() {
    if (!this.showPrefix) {
      return;
    }

    this.form.addControl('prefix', new UntypedFormControl(null));

    if (this.address?.entrance?.prefix) {
      this.form.get('prefix').setValue(this.address.entrance.prefix);
    }
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      address: new UntypedFormGroup({
        country: new UntypedFormControl(null),
        city: new UntypedFormControl(null, Validators.required),
        street: new UntypedFormControl(null, Validators.required),
        house: new UntypedFormControl(null, Validators.required),
        building: new UntypedFormControl(null),
        housing: new UntypedFormControl(null),
        block: new UntypedFormControl(null),
        fiasCode: new UntypedFormControl(null),
        kladrCode: new UntypedFormControl(null),
        universalCode: new UntypedFormControl(null),
        rawAddress: new UntypedFormControl(null, [Validators.required, addressValidator])
      }),
      entrance: new UntypedFormControl(null, Validators.required),
      range: new UntypedFormArray([
        new UntypedFormControl(null, Validators.min(0)),
        new UntypedFormControl(null, Validators.min(0))
      ])
    });
  }
}
