import { RdaNewResponse } from '@app/shared/entities/rd/rda';
import { Actions, ActionTypes } from '../../actions/intercom.actions';

export interface State {
  newAdapters: RdaNewResponse[];
  error: string | null;
  loading: boolean;
  totalCount: number;
}

export const initialState: State = {
  newAdapters: [],
  error: null,
  loading: false,
  totalCount: 0
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GetNewIntercoms: {
      return {
        ...state,
        newAdapters: [],
        error: null,
        loading: true,
      };
    }

    case ActionTypes.GetNewIntercomsSuccess: {
      return {
        ...state,
        newAdapters: action.payload,
        error: null,
        loading: false,
        totalCount: action.totalCount
      };
    }

    case ActionTypes.GetNewIntercomsFailure: {
      return {
        ...state,
        newAdapters: [],
        error: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
