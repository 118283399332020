import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

const selectCreateRdaModelState = createSelector(
  intercomModuleSelector,
  (state: IntercomModuleState) => state.createRdaModel
);

export const getCreateRdaModel = createSelector(
  selectCreateRdaModelState,
  state => state
);

export const getCreateRdaModelSuccess = createSelector(
  selectCreateRdaModelState,
  state => state.success
);

export const getCreateRdaModelFailure = createSelector(
  selectCreateRdaModelState,
  state => state.error
);

export const getCreateRdaModelLoading = createSelector(
  selectCreateRdaModelState,
  state => state.loading
);
