import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ActionTypes } from '../../actions/shields.actions';
import { ShieldTests } from '@app/views/intercom/models/shield/shield-tests.model';

export interface State {
  tests: ShieldTests[];
  error: string | HttpErrorResponse;
  loading: boolean;
}

export const initialState: State = {
  tests: null,
  error: null,
  loading: false
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GetShieldTests: {
      return {
        ...state,
        tests: null,
        error: null,
        loading: true,
      };
    }
    case ActionTypes.GetShieldTestsSuccess: {
      return {
        ...state,
        tests: action.payload,
        error: null,
        loading: false
      };
    }
    case ActionTypes.GetShieldTestsFailure: {
      return {
        ...state,
        tests: null,
        error: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
