import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { ActiveHistoryChartModule } from '@app/shared/containers';
import { InfoBlockModule } from '@app/shared/ui';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ServiceActiveHistoryChartComponent } from './service-active-history-chart';
import { ServiceActivityComponent } from './service-activity.component';
import { ServiceLogsComponent } from './service-logs/service-logs.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ActiveHistoryChartModule,
        NgxSkeletonLoaderModule,
        InfoBlockModule,
        NgxTranslateModule
    ],
    declarations: [
        ServiceActiveHistoryChartComponent,
        ServiceLogsComponent,
        ServiceActivityComponent
    ],
    exports: [ServiceActivityComponent]
})
export class ServiceActivityModule { }
