import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {FileResponse} from '@app/shared/api';
import {SnackbarService} from '@app/shared/components/snackbar';
import {LocalStorageDashboardKey, LocalStorageHelper} from '@app/shared/entities/common';
import {
  Camera,
  CameraApiService,
  InvoiceApiService,
  RdaApiService,
  RdaResponse,
  RdosImageApiService,
  ReportsApiService,
  ReportsUtils,
  ServiceCreateResponse,
  ServiceInfoResponse
} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {ServicesTypes} from '@app/shared/models';
import {DialogWrapperService} from '@app/shared/ui/dialog-wrapper';
import {
  DashboardCamerasCounts,
  DashboardGatesCounts,
  DashboardRdasCounts,
  DashboardSoftwareIntercomsCounts,
  DashboardVideoSurveillanceCounts,
  RdosImage
} from '@app/views/dashboard/models';
import {DashboardHelperService} from '@app/views/dashboard/services';
import {
  ClearDashboardStore,
  DashboardActionTypes,
  DashboardCreateService,
  DashboardCreateServiceFailure,
  DashboardCreateServiceSuccess,
  DashboardGetBlockedUserInvoiceReport,
  DashboardGetBlockedUserInvoiceReportFailure,
  DashboardGetBlockedUserInvoiceReportSuccess,
  DashboardGetCamerasCounts,
  DashboardGetCamerasCountsFailure,
  DashboardGetCamerasCountsSuccess,
  DashboardGetCompanyPeriodicReport,
  DashboardGetCompanyPeriodicReportFailure,
  DashboardGetCompanyPeriodicReportSuccess,
  DashboardGetCompanyReport,
  DashboardGetCompanyReportFailure,
  DashboardGetCompanyReportSuccess,
  DashboardGetGatesCounts,
  DashboardGetGatesCountsFailure,
  DashboardGetGatesCountsSuccess,
  DashboardGetLicenseeReport,
  DashboardGetLicenseeReportFailure,
  DashboardGetLicenseeReportSuccess,
  DashboardGetPenetrationReport,
  DashboardGetPenetrationReportFailure,
  DashboardGetPenetrationReportSuccess,
  DashboardGetRdasCounts,
  DashboardGetRdasCountsFailure,
  DashboardGetRdasCountsSuccess,
  DashboardGetRdosImages,
  DashboardGetRdosImagesFailure,
  DashboardGetRdosImagesSuccess,
  DashboardGetSignUpsReport,
  DashboardGetSignUpsReportFailure,
  DashboardGetSignUpsReportSuccess,
  DashboardGetSoftwareIntercomsCounts,
  DashboardGetSoftwareIntercomsCountsFailure,
  DashboardGetSoftwareIntercomsCountsSuccess,
  DashboardGetUsageStatisticsReport,
  DashboardGetUsageStatisticsReportFailure,
  DashboardGetUsageStatisticsReportSuccess,
  DashboardGetVideoSurveillanceCounts,
  DashboardGetVideoSurveillanceCountsFailure,
  DashboardGetVideoSurveillanceCountsSuccess
} from '@app/views/dashboard/store/dashboard.actions';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private router: Router,
    private snackbar: SnackbarService,
    private rdaApiService: RdaApiService,
    private cameraApiService: CameraApiService,
    private reportsApiService: ReportsApiService,
    private invoiceApiService: InvoiceApiService,
    private rdosImageApiService: RdosImageApiService,
    private dialogWrapperService: DialogWrapperService,
    private dashboardHelperService: DashboardHelperService,
    private translate: TranslateService
  ) {
  }

  @Effect()
  DashboardGetRdasCounts$ = this.actions$.pipe(
    ofType<DashboardGetRdasCounts>(DashboardActionTypes.DashboardGetRdasCounts),
    switchMap(() =>
      this.rdaApiService.getAdaptersListV1()
        .pipe(
          map((response: RdaResponse[]) => {
            const rdasCounts: DashboardRdasCounts = this.dashboardHelperService.calculateRdasCounts(response);
            LocalStorageHelper.setItem(LocalStorageDashboardKey.DASHBOARD_RDAS_COUNTS, rdasCounts);
            return new DashboardGetRdasCountsSuccess(rdasCounts);
          }),
          catchError((error: HttpErrorResponse) => {
            return of(new DashboardGetRdasCountsFailure());
          })
        )
    ),
  );

  @Effect()
  DashboardGetCamerasCounts$ = this.actions$.pipe(
    ofType<DashboardGetCamerasCounts>(DashboardActionTypes.DashboardGetCamerasCounts),
    switchMap(() =>
      this.cameraApiService.getCamerasList()
        .pipe(
          map((response: Camera[]) => {
            const camerasCounts: DashboardCamerasCounts = this.dashboardHelperService.calculateCamerasCounts(response);
            LocalStorageHelper.setItem(LocalStorageDashboardKey.DASHBOARD_CAMERAS_COUNTS, camerasCounts);
            return new DashboardGetCamerasCountsSuccess(camerasCounts);
          }),
          catchError((error: HttpErrorResponse) => {
            return of(new DashboardGetCamerasCountsFailure());
          })
        )
    ),
  );

  @Effect()
  DashboardGetSoftwareIntercomsCounts$ = this.actions$.pipe(
    ofType<DashboardGetSoftwareIntercomsCounts>(DashboardActionTypes.DashboardGetSoftwareIntercomsCounts),
    switchMap(() =>
      this.dashboardHelperService.getSoftwareIntercomsCounts()
        .pipe(
          map((response: ServiceInfoResponse[]) => {
            const softwareIntercomsCounts: DashboardSoftwareIntercomsCounts = this.dashboardHelperService.calculateSoftwareIntercomsCounts(response);
            LocalStorageHelper.setItem(LocalStorageDashboardKey.DASHBOARD_SOFTWARE_INTERCOMS_COUNTS, softwareIntercomsCounts);
            return new DashboardGetSoftwareIntercomsCountsSuccess(softwareIntercomsCounts);
          }),
          catchError((error: HttpErrorResponse) => {
            return of(new DashboardGetSoftwareIntercomsCountsFailure());
          })
        )
    ),
  );

  @Effect()
  DashboardGetVideoSurveillanceCounts$ = this.actions$.pipe(
    ofType<DashboardGetVideoSurveillanceCounts>(DashboardActionTypes.DashboardGetVideoSurveillanceCounts),
    switchMap(() =>
      this.dashboardHelperService.getVideoSurveillanceCounts()
        .pipe(
          map((response: ServiceInfoResponse[]) => {
            const videoSurveillanceCounts: DashboardVideoSurveillanceCounts = this.dashboardHelperService.calculateVideoSurveillanceCounts(response);
            LocalStorageHelper.setItem(LocalStorageDashboardKey.DASHBOARD_VIDEO_SURVEILLANCE_COUNTS, videoSurveillanceCounts);
            return new DashboardGetVideoSurveillanceCountsSuccess(videoSurveillanceCounts);
          }),
          catchError(error => {
            return of(new DashboardGetVideoSurveillanceCountsFailure());
          })
        )
    ),
  );

  @Effect()
  DashboardGetGatesCounts$ = this.actions$.pipe(
    ofType<DashboardGetGatesCounts>(DashboardActionTypes.DashboardGetGatesCounts),
    switchMap(() =>
      this.dashboardHelperService.getGatesCounts()
        .pipe(
          map((response: ServiceInfoResponse[]) => {
            const gatesCounts: DashboardGatesCounts = this.dashboardHelperService.calculateGatesCounts(response);
            LocalStorageHelper.setItem(LocalStorageDashboardKey.DASHBOARD_GATES_COUNTS, gatesCounts);
            return new DashboardGetGatesCountsSuccess(gatesCounts);
          }),
          catchError((error: HttpErrorResponse) => {
            return of(new DashboardGetGatesCountsFailure());
          })
        )
    ),
  );

  @Effect()
  DashboardGetRdosImages$ = this.actions$.pipe(
    ofType<DashboardGetRdosImages>(DashboardActionTypes.DashboardGetRdosImages),
    switchMap(() =>
      this.rdosImageApiService.getRdosImagesList()
        .pipe(
          map((response: RdosImage[]) =>
            new DashboardGetRdosImagesSuccess(response)
          ),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.showMessage(
              this.translate.instant('dashboard.message.get_rdos_images.failed', {
                text: parseError(error)
              })
            );
            return of(new DashboardGetRdosImagesFailure());
          })
        )
    ),
  );

  @Effect()
  DashboardGetBlockedUserInvoiceReport$ = this.actions$.pipe(
    ofType<DashboardGetBlockedUserInvoiceReport>(DashboardActionTypes.DashboardGetBlockedUserInvoiceReport),
    switchMap(action => {
      return this.invoiceApiService.getInvoiceInPdf(action.inn)
        .pipe(
          map((response: FileResponse) => {
            this.handleGetReportAction(response);
            return new DashboardGetBlockedUserInvoiceReportSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.showMessage(
              this.translate.instant('dashboard.message.get_blocked_user_invoice_report.failed', {
                text: parseError(error)
              })
            );
            return of(new DashboardGetBlockedUserInvoiceReportFailure());
          })
        );
    }),
  );

  @Effect()
  DashboardGetPenetrationReport$ = this.actions$.pipe(
    ofType<DashboardGetPenetrationReport>(DashboardActionTypes.DashboardGetPenetrationReport),
    switchMap(action => {
      this.dialogWrapperService.pendingState = true;

      return this.reportsApiService.getPenetrationReport(action.request)
        .pipe(
          map((response: FileResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.handleGetReportAction(response);
            return new DashboardGetPenetrationReportSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.snackbar.showMessage(
              this.translate.instant('dashboard.message.get_penetration_report.failed', {
                text: parseError(error)
              })
            );
            return of(new DashboardGetPenetrationReportFailure());
          })
        );
    }),
  );

  @Effect()
  DashboardGetLicenseeReport$ = this.actions$.pipe(
    ofType<DashboardGetLicenseeReport>(DashboardActionTypes.DashboardGetLicenseeReport),
    switchMap(action => {
      this.dialogWrapperService.pendingState = true;

      return this.reportsApiService.getLicenseeReport(action.request)
        .pipe(
          map((response: FileResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.handleGetReportAction(response);
            return new DashboardGetLicenseeReportSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.snackbar.showMessage(
              this.translate.instant('dashboard.message.get_licensee_report.failed', {
                text: parseError(error)
              })
            );
            return of(new DashboardGetLicenseeReportFailure());
          })
        );
    }),
  );

  @Effect()
  DashboardGetUsageStatisticsReport$ = this.actions$.pipe(
    ofType<DashboardGetUsageStatisticsReport>(DashboardActionTypes.DashboardGetUsageStatisticsReport),
    switchMap(action => {
      this.dialogWrapperService.pendingState = true;

      return this.reportsApiService.getUsageStatisticsReport(action.request)
        .pipe(
          map((response: FileResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.handleGetReportAction(response);
            return new DashboardGetUsageStatisticsReportSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.snackbar.showMessage(
              this.translate.instant('dashboard.message.get_usage_statistics_report.failed', {
                text: parseError(error)
              })
            );
            return of(new DashboardGetUsageStatisticsReportFailure());
          })
        );
    }),
  );

  @Effect()
  DashboardGetCompanyReport$ = this.actions$.pipe(
    ofType<DashboardGetCompanyReport>(DashboardActionTypes.DashboardGetCompanyReport),
    switchMap(action => {
      this.dialogWrapperService.pendingState = true;

      return this.reportsApiService.getCompanyReport(action.request)
        .pipe(
          map((response: FileResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.handleGetReportAction(response);
            return new DashboardGetCompanyReportSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.snackbar.showMessage(
              this.translate.instant('dashboard.message.get_company_report.failed', {
                text: parseError(error)
              })
            );
            return of(new DashboardGetCompanyReportFailure());
          })
        );
    }),
  );

  @Effect()
  DashboardGetCompanyPeriodicReport$ = this.actions$.pipe(
    ofType<DashboardGetCompanyPeriodicReport>(DashboardActionTypes.DashboardGetCompanyPeriodicReport),
    switchMap(action => {
      this.dialogWrapperService.pendingState = true;

      return this.reportsApiService.getCompanyReport(action.request)
        .pipe(
          map((response: FileResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.handleGetReportAction(response);
            return new DashboardGetCompanyPeriodicReportSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.snackbar.showMessage(
              this.translate.instant('dashboard.message.get_company_periodic_report.failed', {
                text: parseError(error)
              })
            );
            return of(new DashboardGetCompanyPeriodicReportFailure());
          })
        );
    }),
  );

  @Effect()
  DashboardGetSignUpsReport$ = this.actions$.pipe(
    ofType<DashboardGetSignUpsReport>(DashboardActionTypes.DashboardGetSignUpsReport),
    switchMap(action => {
      this.dialogWrapperService.pendingState = true;

      return this.reportsApiService.getSignUpsReport(action.request)
        .pipe(
          map((response: FileResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.handleGetReportAction(response);
            return new DashboardGetSignUpsReportSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.snackbar.showMessage(
              this.translate.instant('dashboard.message.get_sign_ups_report.failed', {
                text: parseError(error)
              })
            );
            return of(new DashboardGetSignUpsReportFailure());
          })
        );
    }),
  );

  @Effect()
  DashboardCreateService$ = this.actions$.pipe(
    ofType<DashboardCreateService>(DashboardActionTypes.DashboardCreateService),
    switchMap(action => {
      this.dialogWrapperService.pendingState = true;

      return this.dashboardHelperService.createService(action.serviceType, action.request)
        .pipe(
          map((response: ServiceCreateResponse) => {
            this.snackbar.showMessage(
              this.translate.instant('dashboard.message.create_service.success'),
              'success'
            );
            this.dialogWrapperService.pendingState = false;
            this.handleCreateServiceAction(action.serviceType, response.id, response.customName ?? response.name);
            return new DashboardCreateServiceSuccess();
          }),
          catchError((error: HttpErrorResponse) => {
            this.dialogWrapperService.pendingState = false;
            this.snackbar.showMessage(
              this.translate.instant('dashboard.message.create_service.failed', {
                text: parseError(error)
              })
            );
            return of(new DashboardCreateServiceFailure());
          })
        );
    }),
  );

  @Effect({dispatch: false})
  ClearDashboardStore$ = this.actions$.pipe(
    ofType<ClearDashboardStore>(DashboardActionTypes.ClearDashboardStore),
    tap(() => new ClearDashboardStore()),
  );

  private handleCreateServiceAction(serviceType: ServicesTypes, serviceId: number, serviceName: string) {
    let route: string;

    switch (serviceType) {
      case ServicesTypes.SOFTWARE_INTERCOM:
        route = 'services/software_intercoms';
        break;
      case ServicesTypes.VIDEO_SURVEILLANCE:
        route = 'services/video-surveillance';
        break;
      case ServicesTypes.GATE:
        route = 'services/gates';
        break;
    }

    const queryParams: Object = {'address': serviceName};
    this.router
      .navigate([route, serviceId], {queryParams})
      .then(() => this.dialog.closeAll());
  }

  private handleGetReportAction(fileResponse: FileResponse) {
    ReportsUtils.downLoadFile(fileResponse.arrayBuffer, decodeURI(fileResponse.fileName));
    this.snackbar.showMessage(
      this.translate.instant('dashboard.message.get_report.success'),
      'success'
    );
    this.dialog.closeAll();
  }
}
