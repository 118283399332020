import { Injectable } from '@angular/core';
import { Abonent } from '@app/shared/models';
import { Account } from '../../account';
import { AbonentService } from './abonent.service';

@Injectable({
    providedIn: 'root'
})
export class AbonentUtilsService {
    constructor(
        private abonentService: AbonentService
    ) { }

    async createAbonentAndGetAccounts(phone: number): Promise<{ abonent: Abonent, accounts: Account[] }> {
        try {
            const abonent: Abonent = await this.abonentService.createAbonent(phone).toPromise();
            const accounts: Account[] = await this.abonentService.getAbonentAccounts(abonent.id).toPromise();
            const response: { abonent: Abonent, accounts: Account[] } = { abonent, accounts };

            response.accounts = response.accounts.map(account => {
                account.owner = abonent;
                return account;
            });

            return response;
        } catch (error) {
            throw error;
        }
    }
}
