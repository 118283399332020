import { Injectable } from '@angular/core';
import { RenderAction } from '@app/shared/components/render/render.component';
import { CompanyRolesPageComponent } from '@app/views/company/pages/company-roles-page/company-roles-page.component';
import { CompanyAboutPageComponent } from '@app/views';
import { CoreFacade, UserRoleType } from '@app/core';
import { CompanyPageStore } from '@app/views/company/store/company-page.store';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import {
  LocalStorageCoreKey,
  LocalStorageHelper,
  RdeaDate
} from '@app/shared/entities';
import { PermissionsService, PermissionsTreeKey, TokenService } from '@app/security';
import { CompanyPageType } from '@app/views/company/models/company-page';
import {TranslateService} from '@ngx-translate/core';

export interface ITabsConfig {
  content: RenderAction<CompanyRolesPageComponent | CompanyAboutPageComponent>;
  header: string | null;
  text: string;
}

@Injectable()
export class CompaniesBuilderService {
  public dynamicComponents: ITabsConfig[];

  constructor(
    private coreFacade: CoreFacade,
    private store: CompanyPageStore,
    private resolution: ResolutionService,
    private permissions: PermissionsService,
    private tokenService: TokenService
  ) {
    this.dynamicComponents = [
      {
        content: this.getAboutCompanyPageComponent(),
        header: CompanyPageType.Profile,
        text: 'company.about.page.navbar.profile'
      }
    ];
    if (this.tokenService.getAuthorities().includes(UserRoleType.ROLE_ADMINISTRATOR)) {
      this.dynamicComponents.push({
        content: this.getCompanyRolesPageComponent(),
        header: CompanyPageType.UserAndRoles,
        text: 'company.about.page.navbar.user_and_roles'
      });
    }
  }

  // About company page aria
  private getAboutCompanyPageComponent(): RenderAction<CompanyAboutPageComponent> {
    return {
      component: CompanyAboutPageComponent,
      updater: (instance: CompanyAboutPageComponent) => {
        instance.payment$ = this.coreFacade.paymentValueState$;
        instance.paymentLoading$ = this.coreFacade.paymentLoadingState$;
        instance.company$ = this.store.company$;
        instance.loading$ = this.store.loading$;
        instance.smWDownBreakpoint$ = this.resolution.getBreakpoint(
          ResolutionBreakpoint.SM_W_DOWN
        );
        this.getPaymentInfo();
        return new Promise((resolve) => {
          resolve(true);
        });
      }
    };
  }

  private getPaymentInfo(): void {
    this.store.loadCompanyData();
    if (LocalStorageHelper.getItem(LocalStorageCoreKey.PAYMENT_INFO)) {
      return;
    }
    if (
      !this.permissions.someRoleContains([UserRoleType.BLOCKED_USER]) &&
      this.permissions.canAccessByKey(PermissionsTreeKey.BILLS)
    ) {
      this.coreFacade.getPaymentInfo(new RdeaDate());
    }
  }

  // Company's Roles aria
  private getCompanyRolesPageComponent(): RenderAction<CompanyRolesPageComponent> {
    return {
      component: CompanyRolesPageComponent,
      updater: (instance: CompanyRolesPageComponent) => {
        return new Promise((resolve) => {
          resolve(true);
        });
      }
    };
  }
}
