import {Action} from '@ngrx/store';
import {ServicesActionTypes} from '@app/views/services';
import {Tariff} from '@app/shared/entities/rd/tariff';

export class SetTariff implements Action {
  readonly type = ServicesActionTypes.SetTariff;

  constructor(public tariff: Tariff) {
  }
}
