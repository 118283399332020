import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResourcePath } from '@app/shared/entities/common';
import { RdaUtilsService } from '@app/shared/entities/rd';
import { Constants } from '@app/shared/helpers';
import { EntrancePageResponse } from '@app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData } from '@app/shared/ui';
import { EntranceSupportRequestPopupComponent } from '../entrance-card';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-entrance-warnings',
  templateUrl: './entrance-warnings.component.html',
  styleUrls: ['./entrance-warnings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntranceWarningsComponent implements OnInit {
  readonly resourcePath: typeof ResourcePath = ResourcePath;

  unavaliableParams: string[] = [];

  private readonly rdaParams: Object = {
    'versionInfoOs': '',
    'versionInfoRdaa': '',
    'versionInfoStm': '',
    'versionInfoStmDriver': '',
    'versionInfoShield': ''
  };
  @Input() private entrance: EntrancePageResponse;

  constructor(
    private dialog: MatDialog,
    private rdaService: RdaUtilsService,
    private resolution: ResolutionService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.prepareUnavaliableParams();

    this.rdaParams['versionInfoOs'] = this.translate.instant('entrance.page.warnings.version_info.os');
    this.rdaParams['versionInfoRdaa'] = this.translate.instant('entrance.page.warnings.version_info.rdaa');
    this.rdaParams['versionInfoStm'] = this.translate.instant('entrance.page.warnings.version_info.stm');
    this.rdaParams['versionInfoStmDriver'] = this.translate.instant('entrance.page.warnings.version_info.stm_driver');
    this.rdaParams['versionInfoShield'] = this.translate.instant('entrance.page.warnings.version_info.shield');
  }

  onCreateSupportRequest() {
    const data: DialogWrapperData<EntrancePageResponse, null> = {
      title: this.translate.instant('entrance.page.warnings.support.title'),
      body: this.entrance,
      componentName: 'GetSoftwareIntercomSupportRequest'
    };

    this.dialog.open(EntranceSupportRequestPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ? '100%' : '500px',
      data
    });
  }

  private prepareUnavaliableParams() {
    if (!this.entrance?.rda) {
      return;
    }

    for (const param in this.rdaParams) {
      if (!this.entrance.rda[param]) {
        if (param === 'versionInfoStmDriver' && !this.rdaService.stmDriverAvaliable(this.entrance.rda.versionInfoRdaa)) {
          continue;
        }
        this.unavaliableParams.push(this.rdaParams[param]);
      }
    }
  }
}
