import { Action } from '@ngrx/store';

import { AbonentsFromEntrancesResponse } from '@app/shared/components';
import { ConnectionCreateRequest, ServiceBlockPhysicalTubeRequest, ServiceEntranceFlatResponse, ServiceResponse, ServiceSignUpsResponse } from '@app/shared/entities/rd';
import { ServiceBlockRequest, ServiceConnectionWithType } from '@app/views/services/models';
import { ServiceEntrancesFlats, ServiceFlatsFilters } from '../../components';
import { ServicesActionTypes } from './services-action-types.actions';

export class GetServiceConnectionsInit implements Action {
    readonly type = ServicesActionTypes.GetServiceConnectionsInit;

    constructor(public options?: { fillEmptyFlats?: boolean }) { }
}

export class GetServiceConnections implements Action {
    readonly type = ServicesActionTypes.GetServiceConnections;

    constructor(
        public dependantServices: Pick<ServiceResponse, 'id' | 'type'>[],
        public entrancesIds: number[]
    ) { }
}

export class GetServiceConnectionsSuccess implements Action {
    readonly type = ServicesActionTypes.GetServiceConnectionsSuccess;

    constructor(
        public connections: ServiceConnectionWithType[],
        public entrancesFlats: ServiceEntranceFlatResponse[],
        public signUps: ServiceSignUpsResponse[]
    ) { }
}

export class ServiceConnectionsPrepared implements Action {
    readonly type = ServicesActionTypes.ServiceConnectionsPrepared;

    constructor(public entrancesFlats: ServiceEntrancesFlats) { }
}

export class GetServiceConnectionsFailure implements Action {
    readonly type = ServicesActionTypes.GetServiceConnectionsFailure;

    constructor() { }
}

export class CreateServiceConnections implements Action {
    readonly type = ServicesActionTypes.CreateServiceConnections;

    constructor(public request: Partial<ConnectionCreateRequest>) { }
}

export class CreateServiceConnectionsSuccess implements Action {
    readonly type = ServicesActionTypes.CreateServiceConnectionsSuccess;

    constructor(
        public flats: ServiceEntrancesFlats,
        public filteredFlats: ServiceEntrancesFlats
    ) { }
}

export class CreateServiceConnectionsFailure implements Action {
    readonly type = ServicesActionTypes.CreateServiceConnectionsFailure;

    constructor() { }
}

export class BlockServiceConnection implements Action {
    readonly type = ServicesActionTypes.BlockServiceConnection;

    constructor(public request: ServiceBlockRequest) { }
}

export class BlockServiceConnectionSuccess implements Action {
    readonly type = ServicesActionTypes.BlockServiceConnectionSuccess;

    constructor(
        public flats: ServiceEntrancesFlats,
        public filteredFlats: ServiceEntrancesFlats
    ) { }
}

export class BlockServiceConnectionFailure implements Action {
    readonly type = ServicesActionTypes.BlockServiceConnectionFailure;

    constructor() { }
}

export class UnblockServiceConnection implements Action {
    readonly type = ServicesActionTypes.UnblockServiceConnection;

    constructor(public connection: ServiceConnectionWithType) { }
}

export class UnblockServiceConnectionSuccess implements Action {
    readonly type = ServicesActionTypes.UnblockServiceConnectionSuccess;

    constructor(
        public flats: ServiceEntrancesFlats,
        public filteredFlats: ServiceEntrancesFlats
    ) { }
}

export class UnblockServiceConnectionFailure implements Action {
    readonly type = ServicesActionTypes.UnblockServiceConnectionFailure;

    constructor() { }
}

export class DeleteServiceConnection implements Action {
    readonly type = ServicesActionTypes.DeleteServiceConnection;

    constructor(
        public connection: ServiceConnectionWithType
    ) { }
}

export class DeleteServiceConnectionSuccess implements Action {
    readonly type = ServicesActionTypes.DeleteServiceConnectionSuccess;

    constructor(
        public flats: ServiceEntrancesFlats,
        public filteredFlats: ServiceEntrancesFlats
    ) { }
}

export class DeleteServiceConnectionFailure implements Action {
    readonly type = ServicesActionTypes.DeleteServiceConnectionFailure;

    constructor() { }
}

export class DeleteServiceFlat implements Action {
    readonly type = ServicesActionTypes.DeleteServiceFlat;

    constructor(
      public entranceId: number,
      public flatNumber: number,
      public flatId: number,
      public hardwareServiceConnectionId?: number
    ) { }
}

export class DeleteServiceFlatSuccess implements Action {
    readonly type = ServicesActionTypes.DeleteServiceFlatSuccess;

    constructor(
        public flats: ServiceEntrancesFlats,
        public filteredFlats: ServiceEntrancesFlats
    ) { }
}

export class DeleteServiceFlatFailure implements Action {
    readonly type = ServicesActionTypes.DeleteServiceFlatFailure;

    constructor() { }
}

export class AddServicePhysicalTube implements Action {
    readonly type = ServicesActionTypes.AddServicePhysicalTube;

    constructor(public entranceId: number, public flatNumber: number, public flatId: number) { }
}

export class AddServicePhysicalTubeSuccess implements Action {
    readonly type = ServicesActionTypes.AddServicePhysicalTubeSuccess;

    constructor(
        public flats: ServiceEntrancesFlats,
        public filteredFlats: ServiceEntrancesFlats
    ) { }
}

export class AddServicePhysicalTubeFailure implements Action {
    readonly type = ServicesActionTypes.AddServicePhysicalTubeFailure;

    constructor() { }
}

export class DeleteServicePhysicalTube implements Action {
    readonly type = ServicesActionTypes.DeleteServicePhysicalTube;

    constructor(
        public entranceId: number,
        public flatNumber: number,
        public flatId: number,
        public connectionId: number
    ) { }
}

export class DeleteServicePhysicalTubeSuccess implements Action {
    readonly type = ServicesActionTypes.DeleteServicePhysicalTubeSuccess;

    constructor(
        public flats: ServiceEntrancesFlats,
        public filteredFlats: ServiceEntrancesFlats
    ) { }
}

export class DeleteServicePhysicalTubeFailure implements Action {
    readonly type = ServicesActionTypes.DeleteServicePhysicalTubeFailure;

    constructor() { }
}

export class DelegateServiceAbonentAccess implements Action {
    readonly type = ServicesActionTypes.DelegateServiceAbonentAccess;

    constructor(
        public entranceId: number,
        public flatNumber: number,
        public fromAbonentId: number,
        public toAbonentPhone: number,
        public companyId?: number,
    ) { }
}

export class DelegateServiceAbonentAccessSuccess implements Action {
    readonly type = ServicesActionTypes.DelegateServiceAbonentAccessSuccess;

    constructor(
        public flats: ServiceEntrancesFlats,
        public filteredFlats: ServiceEntrancesFlats
    ) { }
}

export class DelegateServiceAbonentAccessFailure implements Action {
    readonly type = ServicesActionTypes.DelegateServiceAbonentAccessFailure;

    constructor() { }
}

export class DeleteServiceAbonentAccess implements Action {
    readonly type = ServicesActionTypes.DeleteServiceAbonentAccess;

    constructor(
        public entranceId: number,
        public delegationId: number
    ) { }
}

export class DeleteServiceAbonentAccessSuccess implements Action {
    readonly type = ServicesActionTypes.DeleteServiceAbonentAccessSuccess;

    constructor(
        public flats: ServiceEntrancesFlats,
        public filteredFlats: ServiceEntrancesFlats
    ) { }
}

export class DeleteServiceAbonentAccessFailure implements Action {
    readonly type = ServicesActionTypes.DeleteServiceAbonentAccessFailure;

    constructor() { }
}

export class BlockServicePhysicalTube implements Action {
    readonly type = ServicesActionTypes.BlockServicePhysicalTube;

    constructor(public request: ServiceBlockPhysicalTubeRequest) { }
}

export class BlockServicePhysicalTubeSuccess implements Action {
    readonly type = ServicesActionTypes.BlockServicePhysicalTubeSuccess;

    constructor(
        public flats: ServiceEntrancesFlats,
        public filteredFlats: ServiceEntrancesFlats
    ) { }
}

export class BlockServicePhysicalTubeFailure implements Action {
    readonly type = ServicesActionTypes.BlockServicePhysicalTubeFailure;

    constructor() { }
}

export class UnblockServicePhysicalTube implements Action {
    readonly type = ServicesActionTypes.UnblockServicePhysicalTube;

    constructor(public connection: ServiceConnectionWithType) { }
}

export class UnblockServicePhysicalTubeSuccess implements Action {
    readonly type = ServicesActionTypes.UnblockServicePhysicalTubeSuccess;

    constructor(
        public flats: ServiceEntrancesFlats,
        public filteredFlats: ServiceEntrancesFlats
    ) { }
}

export class UnblockServicePhysicalTubeFailure implements Action {
    readonly type = ServicesActionTypes.UnblockServicePhysicalTubeFailure;

    constructor() { }
}

export class AddServiceConnectionsFromEntrances implements Action {
    readonly type = ServicesActionTypes.AddServiceConnectionsFromEntrances;

    constructor(
        public connections: AbonentsFromEntrancesResponse[],
        public companyId?: number
    ) { }
}

export class AddServiceConnectionsFromEntrancesSuccess implements Action {
    readonly type = ServicesActionTypes.AddServiceConnectionsFromEntrancesSuccess;

    constructor() { }
}

export class AddServiceConnectionsFromEntrancesFailure implements Action {
    readonly type = ServicesActionTypes.AddServiceConnectionsFromEntrancesFailure;

    constructor() { }
}

export class FilterServiceFlats implements Action {
    readonly type = ServicesActionTypes.FilterServiceFlats;

    constructor(public filter: ServiceFlatsFilters) { }
}

export class FilterServiceFlatsSuccess implements Action {
    readonly type = ServicesActionTypes.FilterServiceFlatsSuccess;

    constructor(public filteredFlats: ServiceEntrancesFlats) { }
}

export class SetServiceFillEmptyFlats implements Action {
    readonly type = ServicesActionTypes.SetServiceFillEmptyFlats;

    constructor(public fillEmptyFlats: boolean) { }
}

export type ServicesConnectionsActions =
    | GetServiceConnectionsInit
    | GetServiceConnections
    | GetServiceConnectionsSuccess
    | GetServiceConnectionsFailure
    | ServiceConnectionsPrepared

    | CreateServiceConnections
    | CreateServiceConnectionsSuccess
    | CreateServiceConnectionsFailure

    | BlockServiceConnection
    | BlockServiceConnectionSuccess
    | BlockServiceConnectionFailure

    | UnblockServiceConnection
    | UnblockServiceConnectionSuccess
    | UnblockServiceConnectionFailure

    | BlockServicePhysicalTube
    | BlockServicePhysicalTubeSuccess
    | BlockServicePhysicalTubeFailure

    | UnblockServicePhysicalTube
    | UnblockServicePhysicalTubeSuccess
    | UnblockServicePhysicalTubeFailure

    | AddServicePhysicalTube
    | AddServicePhysicalTubeSuccess
    | AddServicePhysicalTubeFailure

    | DeleteServicePhysicalTube
    | DeleteServicePhysicalTubeSuccess
    | DeleteServicePhysicalTubeFailure

    | DeleteServiceConnection
    | DeleteServiceConnectionSuccess
    | DeleteServiceConnectionFailure

    | DeleteServiceFlat
    | DeleteServiceFlatSuccess
    | DeleteServiceFlatFailure

    | DelegateServiceAbonentAccess
    | DelegateServiceAbonentAccessSuccess
    | DelegateServiceAbonentAccessFailure

    | AddServiceConnectionsFromEntrances
    | AddServiceConnectionsFromEntrancesSuccess
    | AddServiceConnectionsFromEntrancesFailure

    | DeleteServiceAbonentAccess
    | DeleteServiceAbonentAccessSuccess
    | DeleteServiceAbonentAccessFailure

    | DeleteServicePhysicalTube
    | DeleteServicePhysicalTubeSuccess
    | DeleteServicePhysicalTubeFailure

    | FilterServiceFlats
    | FilterServiceFlatsSuccess
    | SetServiceFillEmptyFlats;
