import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CoreFacade } from '@app/core';

import { ChangePasswordPopupComponent } from '@app/shared/components';
import {
  LocalStorageCompanyKey,
  LocalStorageGeneralKey,
  LocalStorageHelper
} from '@app/shared/entities';
import { ChangePasswordRequest } from '@app/shared/entities/rd';
import { Constants } from '@app/shared/helpers';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData } from '@app/shared/ui';
import { AuthFacade } from '@app/views/auth';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-company-profile',
  templateUrl: './company-profile.component.html',
  styleUrls: ['./company-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyProfileComponent {
  @Input() isBlocked: boolean = LocalStorageHelper.getItem(
    LocalStorageCompanyKey.IS_BLOCKED
  );
  @Input() licenseeShort: string;
  @Input() brandName: string = LocalStorageHelper.getItem(
    LocalStorageCompanyKey.NAME
  );
  readonly username = LocalStorageHelper.getItem(
    LocalStorageGeneralKey.USER_NAME
  );
  constructor(
    private dialog: MatDialog,
    private coreFacade: CoreFacade,
    private resolution: ResolutionService,
    private authFacade: AuthFacade,
    private translate: TranslateService
  ) {}

  public onOpenSettingsPopup(): void {
    const data: DialogWrapperData<null, ChangePasswordRequest> = {
      title: this.translate.instant('company.about.page.profile.password.title'),
      componentName: 'ChangePassword',
      submit: (request: ChangePasswordRequest) =>
        this.coreFacade.changePassword(request)
    };

    this.dialog.open(ChangePasswordPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? '100%'
        : '350px',
      data
    });
  }

  public onLogout(): void {
    this.authFacade.logout();
  }

  public get name(): string {
    if (this.licenseeShort) {
      return this.licenseeShort;
    }
    return this.brandName ? this.brandName : this.translate.instant('company.about.page.profile.brand_name');
  }
}
