import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ServicesTypes } from '@app/shared/models';

@Component({
  selector: 'app-delete-service-content',
  templateUrl: './delete-service-content.component.html',
  styleUrls: ['./delete-service-content.component.scss']
})
export class DeleteServiceContentComponent {
  readonly SOFTWARE_INTERCOM: ServicesTypes = ServicesTypes.SOFTWARE_INTERCOM;
  readonly GATE: ServicesTypes = ServicesTypes.GATE;
  @Input() loading: boolean;
  @Input() serviceName: string;
  @Input() serviceType: ServicesTypes;

  @Output() private deleteService: EventEmitter<void> = new EventEmitter();

  constructor() { }

  onDelete() {
    this.deleteService.emit();
  }
}
