import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {SnackbarService} from '@app/shared/components';
import {
  Camera,
  IntercomPanelResponse,
  RdaResponse,
  ServiceApiService,
  ServiceCameraUpdateRequest,
  ServiceCreateResponse,
  ServiceInfoResponse,
  ServiceResponse,
  SoftwareIntercomService
} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {Address, PagedResponse, ServicesTypes} from '@app/shared/models';
import {DialogWrapperService} from '@app/shared/ui';
import {
  GetServiceConnectionsInit,
  GetServiceRdasSuccess,
  GetServiceSuccess,
  ServiceFacade,
  ServicesActionTypes,
  UpdateServiceCamera,
  UpdateServiceIntercomPanelSuccess
} from '@app/views/services/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of, Subscription} from 'rxjs';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {SoftwareIntercomsPageService} from './../services';
import {
  ConnectSoftwareIntercomPanelToCamera,
  ConnectSoftwareIntercomPanelToCameraFailure,
  ConnectSoftwareIntercomPanelToCameraSuccess,
  CreateSoftwareIntercom,
  CreateSoftwareIntercomFailure,
  CreateSoftwareIntercomSuccess,
  DeleteSoftwareIntercom,
  DeleteSoftwareIntercomFailure,
  DeleteSoftwareIntercomSuccess,
  DisconnectSoftwareIntercomPanelFromCamera,
  DisconnectSoftwareIntercomPanelFromCameraFailure,
  DisconnectSoftwareIntercomPanelFromCameraSuccess,
  GetSoftwareIntercomsPage,
  GetSoftwareIntercomsPageFailure,
  GetSoftwareIntercomsPageSuccess,
  SoftwareIntercomsPageActionTypes
} from './software-intercoms-page.actions';
import {SoftwareIntercomsPageFacade} from './software-intercoms-page.facade';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class SoftwareIntercomsPageEffects {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private actions$: Actions,
    private snackbar: SnackbarService,
    private serviceFacade: ServiceFacade,
    private serviceApiService: ServiceApiService,
    private dialogWrapperService: DialogWrapperService,
    private softwareIntercomService: SoftwareIntercomService,
    private softwareIntercomsPageFacade: SoftwareIntercomsPageFacade,
    private softwareIntercomsPageService: SoftwareIntercomsPageService,
    private translate: TranslateService
  ) {
  }

  GetSoftwareIntercomsPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSoftwareIntercomsPage>(SoftwareIntercomsPageActionTypes.GetSoftwareIntercomsPage),
      switchMap((action: GetSoftwareIntercomsPage) =>
        this.softwareIntercomService.getPage(action.pageNumber, action.size, action.name)
          .pipe(
            map((response: PagedResponse<ServiceInfoResponse>) => {
              return new GetSoftwareIntercomsPageSuccess(
                this.softwareIntercomsPageService.preparePageResponse(response.content),
                response, 
              )
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(this.translate.instant('software.intercoms.message.error_when_receiving_list_of_services', {
                text: parseError(error)
              }));
              return of(new GetSoftwareIntercomsPageFailure(error));
            })
          )
      )
    )
  );

  CreateSoftwareIntercom$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateSoftwareIntercom>(SoftwareIntercomsPageActionTypes.CreateSoftwareIntercom),
      switchMap((action: CreateSoftwareIntercom) => {
        this.dialogWrapperService.pendingState = true;

        return this.softwareIntercomService.create(action.request)
          .pipe(
            map((response: ServiceCreateResponse) => {
              this.dialogWrapperService.pendingState = false;
              this.snackbar.showMessage(this.translate.instant('software.intercoms.message.create.success', {
                name: response.customName ?? response.name
              }), 'success');

              this.router
                .navigate([`services/software-intercoms`, response.id], {
                  queryParams: {
                    'query': action.options.filterValue ?? null,
                    'page': action.options.pageIndex,
                    'address': response.customName ?? response.name,
                  }
                })
                .then(() => this.dialog.closeAll());

              return new CreateSoftwareIntercomSuccess();
            }),
            catchError((error: HttpErrorResponse) => {
              this.dialogWrapperService.pendingState = false;

              this.snackbar.showMessage(this.translate.instant('software.intercoms.message.create.failed', {
                text: parseError(error)
              }));

              return of(new CreateSoftwareIntercomFailure());
            })
          );
      })
    )
  );

  DeleteSoftwareIntercom$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteSoftwareIntercom>(SoftwareIntercomsPageActionTypes.DeleteSoftwareIntercom),
      switchMap((action: DeleteSoftwareIntercom) => {
        this.dialogWrapperService.pendingState = true;

        return this.softwareIntercomService.deleteService(action.serviceId)
          .pipe(
            map(() => {
              this.snackbar.showMessage(this.translate.instant('software.intercoms.message.delete.success', {
                name: action.serviceId
              }), 'success');

              this.dialogWrapperService.pendingState = false;
              this.dialog.closeAll();
              this.softwareIntercomsPageFacade.getPage(action.options.pageIndex, action.options.pageSize, action.options.filterValue);
              return new DeleteSoftwareIntercomSuccess();
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(this.translate.instant('software.intercoms.message.delete.failed', {
                text: parseError(error)
              }));

              this.dialogWrapperService.pendingState = false;
              return of(new DeleteSoftwareIntercomFailure());
            })
          );
      })
    )
  );

  ConnectSoftwareIntercomPanelToCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ConnectSoftwareIntercomPanelToCamera>(SoftwareIntercomsPageActionTypes.ConnectSoftwareIntercomPanelToCamera),
      withLatestFrom(
        this.serviceFacade.serviceId$,
        this.serviceFacade.intercomPanels$,
        this.serviceFacade.cameras$
      ),
      switchMap(([action, serviceId, intercomPanels, cameras]: [ConnectSoftwareIntercomPanelToCamera, number, IntercomPanelResponse[], Camera[]]) =>
        this.serviceApiService.updateCameraConnection(serviceId, action.cameraId, action.request)
          .pipe(
            // Prepare camera after connection to intercom panel
            map(() => {
              const selectedIntercomPanel: IntercomPanelResponse = intercomPanels.find((intercomPanel: IntercomPanelResponse) =>
                intercomPanel.id === action.request.intercomId
              );

              const connectedCamera: Camera = cameras.find((camera: Camera) => camera.id === action.cameraId);

              if (selectedIntercomPanel) {
                connectedCamera.location = JSON.parse(JSON.stringify(selectedIntercomPanel.location));
                connectedCamera.intercomId = selectedIntercomPanel.id;

                this.serviceFacade.updateServiceCameraSuccess(connectedCamera);
              }

              return new ConnectSoftwareIntercomPanelToCameraSuccess(connectedCamera);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(this.translate.instant('software.intercoms.message.connect.failed', {
                text: parseError(error)
              }));

              return of(new ConnectSoftwareIntercomPanelToCameraFailure());
            })
          )
      )
    )
  );

  DisconnectSoftwareIntercomPanelFromCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DisconnectSoftwareIntercomPanelFromCamera>(SoftwareIntercomsPageActionTypes.DisconnectSoftwareIntercomPanelFromCamera),
      withLatestFrom(
        this.serviceFacade.serviceId$,
        this.serviceFacade.cameras$
      ),
      switchMap(([action, serviceId, cameras]: [DisconnectSoftwareIntercomPanelFromCamera, number, Camera[]]) =>
        this.serviceApiService.updateCameraConnection(serviceId, action.cameraId, {intercomId: null})
          .pipe(
            // Prepare camera after disconnection from intercom panel
            map(() => {
              const disconnectedCamera: Camera = cameras.find((camera: Camera) => camera.id === action.cameraId);

              if (disconnectedCamera) {
                disconnectedCamera.intercomId = null;
                this.serviceFacade.updateServiceCameraSuccess(disconnectedCamera);
              }

              return new DisconnectSoftwareIntercomPanelFromCameraSuccess();
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(this.translate.instant('software.intercoms.message.disconnect.failed', {
                text: parseError(error)
              }));

              return of(new DisconnectSoftwareIntercomPanelFromCameraFailure());
            })
          )
      )
    )
  );

  // Software Intercom Services Effects

  GetServiceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceSuccess>(ServicesActionTypes.GetServiceSuccess),
      tap((action: GetServiceSuccess) => {
        if (action.response.type !== ServicesTypes.SOFTWARE_INTERCOM) {
          return;
        }

        if (action.response.entrances.length > 0) {
          this.serviceFacade.getConnectionsInit();
        }

        action.response.rdas.forEach((rda: RdaResponse) => {
          this.serviceFacade.getPbxOnRda(rda.uid);
          this.serviceFacade.getTranslationTunings(rda.uid);
        });
      })
    ), {dispatch: false}
  );

  UpdateServiceCamera$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateServiceCamera>(ServicesActionTypes.UpdateServiceCamera),
      withLatestFrom(
        this.serviceFacade.serviceType$,
        this.serviceFacade.intercomPanels$,
        this.serviceFacade.cameras$
      ),
      tap(([action, serviceType, intercomPanels, cameras]:
             [UpdateServiceCamera, ServicesTypes, IntercomPanelResponse[], Camera[]]
      ) => {
        if (serviceType !== ServicesTypes.SOFTWARE_INTERCOM) {
          return;
        }

        // Get camera state before change
        const prevCamera: Camera = cameras.find(camera => camera.id === action.camera.id);

        if (prevCamera.location?.id === action.camera.location?.id) {
          return;
        }

        const oldIntercomPanelId: number = intercomPanels.find(intercomPanel =>
          intercomPanel.location?.id === prevCamera.location?.id
        )?.id;

        const newIntercomPanelId: number = intercomPanels.find(intercomPanel =>
          intercomPanel.location?.id === action.camera.location?.id
        )?.id;

        const request: ServiceCameraUpdateRequest = {intercomId: null};

        // If has intercom panels in this location but or if intercom panel changed
        if (newIntercomPanelId && newIntercomPanelId !== oldIntercomPanelId) {
          request.intercomId = newIntercomPanelId;
          this.softwareIntercomsPageFacade.connectIntercomPanelToCamera(action.camera.id, request);
          return;
        }

        // If hasn't intercom panels in this location but in previous location has
        if (!newIntercomPanelId && oldIntercomPanelId) {
          this.softwareIntercomsPageFacade.disconnectIntercomPanelFromCamera(prevCamera.id);
        }
      })
    ), {dispatch: false}
  );

  GetServiceConnectionsInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<GetServiceConnectionsInit>(ServicesActionTypes.GetServiceConnectionsInit),
      withLatestFrom(
        this.serviceFacade.serviceType$,
        this.serviceFacade.dependantServices$,
        this.serviceFacade.entrances$
      ),
      tap(([action, serviceType, dependantServices, addresses]:
             [GetServiceConnectionsInit, ServicesTypes, Pick<ServiceResponse, 'id' | 'type'>[], Address[]]
      ) => {
        if (serviceType !== ServicesTypes.SOFTWARE_INTERCOM) {
          return;
        }

        const preparedDependantServices: Pick<ServiceResponse, 'id' | 'type'>[] =
          dependantServices.reduce((prev, cur) => {
            if (cur.type === ServicesTypes.HARDWARE_INTERCOM) {
              prev.push(cur);
            }

            return prev;
          }, []);

        this.serviceFacade.getConnections(
          preparedDependantServices,
          addresses.map(address => address.entrance.id)
        );
      })
    );
  }, {dispatch: false});

  GetServiceRdasSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<GetServiceRdasSuccess>(ServicesActionTypes.GetServiceRdasSuccess),
      withLatestFrom(
        this.serviceFacade.serviceType$,
        this.serviceFacade.intercomPanels$,
        this.serviceFacade.cameras$
      ),
      tap(([action, serviceType, intercomPanels, cameras]:
             [GetServiceRdasSuccess, ServicesTypes, IntercomPanelResponse[], Camera[]]
      ) => {
        if (serviceType !== ServicesTypes.SOFTWARE_INTERCOM) {
          return;
        }

        cameras.forEach((camera: Camera) => {
          if (camera.location) {
            const camerasIntercomPanel: IntercomPanelResponse = intercomPanels.find((intercomPanel: IntercomPanelResponse) =>
              intercomPanel.location && intercomPanel.location.id === camera.location.id
            );

            if (!camerasIntercomPanel && camera.intercomId) {
              this.softwareIntercomsPageFacade.disconnectIntercomPanelFromCamera(camera.id);
              return;
            }

            if (camerasIntercomPanel && camerasIntercomPanel.id !== camera.intercomId) {
              const request: ServiceCameraUpdateRequest = {intercomId: camerasIntercomPanel.id};
              this.softwareIntercomsPageFacade.connectIntercomPanelToCamera(camera.id, request);
            }
          }
        });
      })
    );
  }, {dispatch: false});

  UpdateServiceIntercomPanelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateServiceIntercomPanelSuccess>(ServicesActionTypes.UpdateServiceIntercomPanelSuccess),
      withLatestFrom(
        this.serviceFacade.serviceType$,
        this.serviceFacade.cameras$,
        this.serviceFacade.intercomPanels$
      ),
      tap(([action, serviceType, cameras, intercomPanels]:
             [UpdateServiceIntercomPanelSuccess, ServicesTypes, Camera[], IntercomPanelResponse[]]
      ) => {
        if (serviceType !== ServicesTypes.SOFTWARE_INTERCOM) {
          return;
        }

        if (action.intercomPanelBeforeChange?.id === action.intercomPanelAfterChange.location?.id) {
          return;
        }

        const cameraWithOldLocation = cameras.find(camera =>
          camera.location && camera.location?.id === action.intercomPanelBeforeChange.location?.id
        );

        if (cameraWithOldLocation) {
          this.softwareIntercomsPageFacade.disconnectIntercomPanelFromCamera(cameraWithOldLocation.id);
        }

        const newIntercomPanel = intercomPanels.find((intercomPanel: IntercomPanelResponse) => intercomPanel.location?.id === action.newLocation?.id);
        const cameraWithNewLocation = cameras.find(camera =>
          camera.location && camera.location?.id === action.intercomPanelAfterChange.location?.id
        );

        if (cameraWithNewLocation && newIntercomPanel) {
          const request: ServiceCameraUpdateRequest = {intercomId: newIntercomPanel.id};
          this.softwareIntercomsPageFacade.connectIntercomPanelToCamera(cameraWithNewLocation.id, request);
        }
      })
    ), {dispatch: false}
  );
}
