import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ResourcePath, ResourcesHelper } from '@app/shared/entities';
import { filter, take, tap } from 'rxjs/operators';

import { PaymentsService } from '../../../services';
import { PaymentsPageStore } from '../../../store';

@Component({
  selector: 'app-payments-error',
  templateUrl: './payments-error.component.html',
  styleUrls: ['./payments-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentsErrorComponent {
  public readonly resourcePath: typeof ResourcePath = ResourcePath;

  constructor(
    public resourcesHelper: ResourcesHelper,
    private store: PaymentsPageStore,
    private paymentsService: PaymentsService
  ) {}

  public onReload(): void {
    this.paymentsService.companyShortName$
      .pipe(
        filter((shortName: string) => shortName !== null),
        take(1),
        tap((companyShortName: string) =>
          this.store.checkPaymentConnection(companyShortName)
        )
      )
      .subscribe();
  }
}
