import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

const updateOrderState = createSelector(
    intercomModuleSelector,
    (state: IntercomModuleState) => state.updateOrder
);

export const updateOrder = createSelector(
    updateOrderState,
    state => state
);
export const updateOrderLoading = createSelector(
    updateOrderState,
    state => state.loading
);
export const updateOrderSuccess = createSelector(
    updateOrderState,
    state => state.success
);

export const updateOrderFailed = createSelector(
    updateOrderState,
    state => state.error
);
