import { Pipe, PipeTransform } from '@angular/core';
import { PaymentsInterface } from '@app/shared/entities/rd/payments/interfaces';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'PaymentStatusFilter',
  pure: false
})
export class PaymentStatusFilterPipe implements PipeTransform {
  constructor(
    private translate: TranslateService
  ) {}

  transform(items: PaymentsInterface[], args: string): PaymentsInterface[] {
    switch (args) {
      case this.translate.instant('payments.list.enum.status.all'):
        return items;
      case this.translate.instant('payments.list.enum.status.paid'):
        return items.filter(payment => payment.paid);
      case this.translate.instant('payments.list.enum.status.wait'):
        return items.filter(payment => !payment.paid && !payment.isBlocked);
      case this.translate.instant('payments.list.enum.status.blocked'):
        return items.filter(payment => payment.isBlocked);
      default:
        return [];
    }
  }
}
