import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { AddressFormatter } from '@app/shared/services';
import { EntrancePageResponse, EntranceRegistration, EntrancesStatusesCountsResponseType, EntranceUpdateRequest, Flat, FlatRange, PagedResponse } from '@app/shared/models';
import { ConnectRdaToAddressRequest } from '@app/views/intercom/models';
import { environment } from 'environments/environment';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EntrancesStatus } from '../models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class EntranceService extends ApiService {
  private addressFormatter: AddressFormatter;

  constructor(
    httpClient: HttpClient,
    humanErrorTranslationService: HumanErrorTranslationService,
    addressFormatter: AddressFormatter
  ) {
    super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1']);

    this.addressFormatter = addressFormatter;
  }

  getEntrancesList(
    page: number, size: number,
    { state, address, intercomIndexesRequired }: { state?: EntrancesStatus, address?: string, intercomIndexesRequired?: boolean } = {}
  ): Observable<PagedResponse<EntrancePageResponse>> {
    let params = new HttpParams({ fromObject: { page, size } });

    if (address) { params = params.set('address', address); }
    if (state) { params = params.set('state', state); }
    if (intercomIndexesRequired) { params = params.set('intercomIndexesRequired', intercomIndexesRequired.toString()); }

    return this.get('/entrances', 1, { params }).pipe(
      map((data: PagedResponse<EntrancePageResponse>) => {
        data.content.map((entrance: EntrancePageResponse): void => {
          entrance.addressString = this.addressFormatter.formatAddress(entrance.address);
        });
        return data;
      })
    );
  }

  getEntrancesFlats(entranceId: number, flatNumber?: number): Observable<Flat[]> {
    const params = {} as any;
    if (flatNumber) { params.flatNumber = flatNumber; }

    return this.get(`/entrances/${entranceId}/flats`, 1, { params });
  }

  createAddress(data: EntranceRegistration): Observable<EntrancePageResponse> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    const payload: ConnectRdaToAddressRequest = {
      country: data.address.country,
      city: data.address.city,
      street: {
        name: data.address.street,
        codeFias: data.address.fiasCode,
        codeKladr: data.address.kladrCode,
        universalCode: data.address.universalCode
      },
      house: {
        number: data.address.house,
        building: data.address.building,
        housing: data.address.housing,
        block: data.address.block
      },
      entrance: {
        number: data.entrance,
        flatStart: data.range ? data.range[0] : undefined,
        flatEnd: data.range ? data.range[1] : undefined,
      }
    };

    return this.post('/addresses', 1, payload, { headers });
  }

  createBrazilAddress(payload: ConnectRdaToAddressRequest): Observable<EntrancePageResponse> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post('/addresses', 1, payload, { headers });
  }

  getEntrance(id: number): Observable<EntrancePageResponse> {
    return this.get(`/entrances/${id}`, 1).pipe(
      map((data: EntrancePageResponse) => {
        for (let i = 0; i < data.cameras.length; ++i) {
          data.cameras[i].id = data.cameras[i]['uid'];
          delete data.cameras[i]['uid'];
        }
        return data;
      })
    );
  }

  updateEntrance(entranceId: number, request: EntranceUpdateRequest): Observable<EntrancePageResponse> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.patch(`/entrances/${entranceId}`, 1, request, { headers });
  }

  getEntrancesCounts(
    statuses: EntrancesStatus[] = Object.values(EntrancesStatus)
  ): Observable<EntrancesStatusesCountsResponseType> {
    const entrances$ = statuses.map((status: EntrancesStatus) =>
      this.get(`/entrances?page=0&size=2&state=${status}`, 1)
    );

    return forkJoin(entrances$).pipe(
      map((responses: PagedResponse<EntrancePageResponse>[]) => {
        const response: EntrancesStatusesCountsResponseType = {};

        statuses.forEach((status: EntrancesStatus, idx: number) =>
          response[status] = responses[idx].totalElements ?? null
        );

        return response;
      })
    );
  }

  createFlatRange(entranceId: number, flatRange: FlatRange): Observable<FlatRange> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.post(`/entrances/${entranceId}/additional_flat_ranges`, 1, flatRange, { headers });
  }

  deleteFlatRange(entranceId: number, flatRangeId: number): Observable<void> {
    return this.delete(`/entrances/${entranceId}/additional_flat_ranges/${flatRangeId}`, 1);
  }

  updateFlatRange(entranceId: number, flatRangeId: number, flatRange: FlatRange): Observable<FlatRange> {
    const headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');
    return this.patch(`/entrances/${entranceId}/additional_flat_ranges/${flatRangeId}`, 1, flatRange, { headers });
  }
}
