import { createSelector } from '@ngrx/store';
import { SharedModuleStates } from '../../reducers/reducers';
import { sharedModuleSelector } from '../shared-module.state';

export const getAvaliableIntercomsV1 = createSelector(
    sharedModuleSelector,
    (state: SharedModuleStates) => state.availableIntercomsV1
);
export const getAvaliableIntercomsV1Success = createSelector(
    getAvaliableIntercomsV1,
    state => state.intercoms
);
export const getAvaliableIntercomsV1Loading = createSelector(
    getAvaliableIntercomsV1,
    state => state.loading
);
export const getAvaliableIntercomsV1Error = createSelector(
    getAvaliableIntercomsV1,
    state => state.error
);
