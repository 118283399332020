import { AddressCriteriaResponse } from './address-criteria-response.model';

export interface MailingResponse {
  id: number;
  message: string;
  deliveryMethod: 'push' | 'sms' | 'push_or_sms';
  user: string;
  addressCriteria: AddressCriteriaResponse[];
  recipientsCount: number;
  createdAt: number;
}
