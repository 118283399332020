import {
  AfterViewChecked,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {VideoManagerN} from '@app/views/services/submodules/video-manager/models/view-manager';
import {Subject} from 'rxjs';
import {
  VideoManagerPageDataService
} from '@app/views/services/submodules/video-manager/services/video-manager-page-data.service';
import {ActivatedRoute} from '@angular/router';
import {VideoType} from '@app/shared/containers/camera/camera-thumbnail/models/videoType';
import {filter, takeUntil} from 'rxjs/operators';
import {LocalStorageGeneralKey, LocalStorageHelper} from '@app/shared/entities';
import {CameraUtilsService} from '@app/shared/entities/rd';
import {HTML_FONT_SIZE, LS_SIZE_COEFF} from '@app/shared/pipes/px-to-rem.pipe';
import {cloneDeep} from 'lodash';
import ViewVariants = VideoManagerN.ViewVariants;

@Component({
  selector: 'app-video-manager-show-screen-page',
  templateUrl: './video-manager-show-screen-page.component.html',
  styleUrls: ['./video-manager-show-screen-page.component.scss', '../../styles/video-manager-shared.styles.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoManagerShowScreenPageComponent implements OnInit, OnDestroy, AfterViewChecked {
  protected readonly ViewVariants = ViewVariants;

  public get authToken(): string {
    return LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN);
  }

  constructor(
    public videoManagerPageDataService: VideoManagerPageDataService,
    private cameraUtilsService: CameraUtilsService,
    private activatedRoute: ActivatedRoute,
  ) {
  }
  protected readonly videoMode = VideoType;
  protected readonly GetMaxBlockByViewScheme = VideoManagerN.GetMaxBlockByViewScheme;
  protected readonly GetViewScheme = VideoManagerN.GetViewScheme;

  @Input() public screen: VideoManagerN.Screen;
  @ViewChild('contentWrapper') public contentWrapper: ElementRef<HTMLDivElement>;

  public isLoaded = false;
  public width: number;
  public height: number;
  public maximazed = {};

  public sizeCoeff = HTML_FONT_SIZE;
  public html: HTMLElement;

  private destroy = new Subject<void>();

  public getDepthInHours(camera: VideoManagerN.ScreenItem): number {
    return this.cameraUtilsService.getDepthInHoursFromConfig(camera?.camera?.configuration);
  }

  public changeSize(plus: boolean): void {
    if (plus && this.sizeCoeff < 24) {
      this.sizeCoeff++;
    } else if (!plus && this.sizeCoeff > 10) {
      this.sizeCoeff--;
    }
    this.html.style.fontSize = `${this.sizeCoeff}px`;
  }

  public applySize(size: number): void {
    this.html.style.fontSize = `${this.sizeCoeff}px`;
    localStorage.setItem(LS_SIZE_COEFF, `${this.sizeCoeff}`);
  }

  public ngOnInit(): void {
    this.html = document.getElementsByTagName('html')[0];
    this.sizeCoeff = localStorage.getItem(LS_SIZE_COEFF) ? Number(localStorage.getItem(LS_SIZE_COEFF)) : HTML_FONT_SIZE;
    this.html.style.fontSize = `${this.sizeCoeff}px`;

    this.videoManagerPageDataService.loaded
      .pipe(takeUntil(this.destroy), filter((data) => !!data))
      .subscribe((data) => {
        this.isLoaded = false;
        if (!this.screen) {
          const id = Number.parseInt(this.activatedRoute.snapshot.params['id']?.match(/([\d]+)/)[0], 10);
          this.screen = this.videoManagerPageDataService.getScreenById(id);
        }
        this.createMaximizeAndEndLoading();
      });
  }

  public ngAfterViewChecked(): void {
    if (this.contentWrapper?.nativeElement?.offsetWidth && this.contentWrapper?.nativeElement?.offsetHeight) {
      this.width = this.contentWrapper.nativeElement.offsetWidth;
      this.height = this.contentWrapper.nativeElement.offsetHeight;
    }
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.applySize(HTML_FONT_SIZE);
  }

  public getWidth(parentContainer: HTMLDivElement, index: number): string {
    if (!this.screen || !this.width) {
      return '100%';
    }
    const columns = VideoManagerN.GetBlockColumnsByViewScheme(this.screen.layout);
    if (columns !== 0) {
      return `${this.width / columns}px`;
    }
    if (index === 0) {
      return `${this.width * 0.8}px`;
    }
    return `${this.width * 0.2}px`;
  }

  public getHeight(parentContainer: HTMLDivElement, index: number): string {
    if (!this.screen || !this.height) {
      return '100%';
    }
    const row = VideoManagerN.GetBlockRowByViewScheme(this.screen.layout);
    if (row !== 0) {
      return `${this.height / row}px`;
    }
    if (index === 0) {
      return `${this.height}px`;
    }
    return `${this.height / 4}px`;
  }

  public onFullScreen(camera: VideoManagerN.ScreenItem): void {
    this.maximazed[camera.cameraId] = !this.maximazed[camera.cameraId];
  }

  public trackByFn(index: number, item: VideoManagerN.ScreenItem): number | string {
    return item.index;
  }

  public selectMainVideoView(camera: VideoManagerN.ScreenItem, cameraIndex: number): void {
    if (this.screen.layout !== ViewVariants.largeOneAndFourOnRightSide) {
      return;
    }
    if (cameraIndex === 0) {
      return;
    }
    const mainCamera = cloneDeep(this.screen.cameras[0]);
    this.screen.cameras = cloneDeep(this.screen.cameras).map((c, index) => {
      if (c.index === mainCamera.index) {
        c = cloneDeep(camera);
      } else {
        if (c.index === camera.index) {
          c = cloneDeep(mainCamera);
        }
      }
      return c;
    });
  }

  private createMaximizeAndEndLoading(): void {
    if (!!this.screen) {
      this.screen.cameras.forEach((c) => this.maximazed[c?.cameraId] = false);
    }
    this.isLoaded = true;
  }
}
