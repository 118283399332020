<div class="popup">
  <div class="popup__content">
    <div class="popup__content-company">
      <app-companies-form-group (handleCompanySelected)="onHandleCompanySelected($event)" [companyList]="companyList"></app-companies-form-group>
    </div>
    <div class="popup__content-batches">
      <app-batches-form-group (handleBatchSelected)="onHandleBatchSelected($event)" [batches]="batchesList"></app-batches-form-group>
    </div>
    <div class="popup__content-navbar">
      <app-navbar [links]="links" [active]="activeLink" (select)="onChangeMode($event.idx)"></app-navbar>
    </div>
    <div class="popup__content-upload">
      <app-document-upload *ngIf="activeLink === 'upload'" (emitReadData)="onEmitReadData($event)"></app-document-upload>
      <textarea *ngIf="activeLink === 'cp'" [(ngModel)]="enteredValue"></textarea>
    </div>
  </div>
  <div class="popup__actions">
      <button
        color="primary"
        mat-raised-button
        class="popup-actions__button"
        (click)="onSubmit()"
      >
        {{ 'intercom.new.adapters.page.group.data.modification.button.submit' | translate }}
      </button>
  </div>
</div>
