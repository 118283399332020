import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { DialogWrapperModule, InfoBlockModule } from '@app/shared/ui';
import { ChangeVirtualStateContentComponent } from './change-virtual-state-content';
import { ChangeVirtualStatePopupComponent } from './change-virtual-state-popup.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        InfoBlockModule,
        DialogWrapperModule,
        NgxTranslateModule

    ],
    declarations: [
        ChangeVirtualStatePopupComponent,
        ChangeVirtualStateContentComponent
    ],
    exports: [
        ChangeVirtualStatePopupComponent
    ]
})
export class ChangeVirtualStatePopupModule { }
