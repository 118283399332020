<app-dialog-wrapper
  [title]="data.title"
  [componentName]="data.componentName"
  [progress]="(state$ | async) === 'loading'"
>
  <app-gate-wizard-content
    [steps]="steps$ | async"
    [wizardStep]="wizardStep$ | async"
    [showMenu]="showMenu$ | async"
    [showStepButtons]="showStepButtons$ | async"
    [addressStepState]="addressStepState$ | async"
    [camerasStepState]="camerasStepState$ | async"
    [tariffStepState]="tariffStepState$ | async"
    [intercomsStepState]="intercomsStepState$ | async"
    [checklistStepState]="checklistStepState$ | async"
    [intercomTypeStepState]="intercomTypeStepState$ | async"
    [initialWizardStep]="initialWizardStep$ | async"
    [index]="index$ | async"
    [mode]="mode$ | async"

    [entrancesIntercoms]="entrancesIntercoms$ | async"
    [addresses]="addresses$ | async"
    [serviceId]="serviceId$ | async"
    [intercoms]="intercoms$ | async"
    [avaliableIntercoms]="avaliableIntercoms$ | async"
    [cameras]="cameras$ | async"
    [selectedIntercom]="intercom$ | async"
    [selectedIntercomType]="intercomType$ | async"
    [camerasLocations]="camerasLocations$ | async"
    [intercomTypes]="intercomTypes$ | async"
    [loading]="(state$ | async) === 'loading'"
  ></app-gate-wizard-content>
</app-dialog-wrapper>
