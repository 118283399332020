import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SnackbarService } from '@app/shared/components/snackbar';
import { RdeaDate, ResolutionService } from '@app/shared/entities/common';
import { SignUpsReportRequest } from '@app/shared/entities/rd';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-sign-ups-report-content',
  templateUrl: './sign-ups-report-content.component.html',
  styleUrls: ['./sign-ups-report-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpsReportContentComponent {
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  maxDate: Date = new Date();
  form: UntypedFormGroup = this.initForm();

  @Output() private submitContent: EventEmitter<{ request: SignUpsReportRequest }> = new EventEmitter();
  private _loading: boolean;

  constructor(
    public resolution: ResolutionService,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {
    this.maxDate = new Date(this.maxDate.setTime(this.maxDate.getTime() - RdeaDate.dayInMilliseconds));
  }

  get loading(): boolean {
    return this._loading;
  }

  onChange(name): void {
    const sourceEl = this.form.controls[name];

    const isFrom = sourceEl === this.form.controls.fromDate;

    const targetEl = isFrom ? this.form.controls.toDate : this.form.controls.fromDate;

    const sourceDate = sourceEl.value;
    let targetDate = new Date(sourceDate.valueOf());

    isFrom ? targetDate.setMonth(targetDate.getMonth() + 1) : targetDate.setMonth(targetDate.getMonth() - 1);

    if (targetDate > this.maxDate) {
      targetDate = this.maxDate;
    }

    if (isFrom) {
      if (!targetEl.value || targetEl.value > targetDate) {
        targetEl.setValue(targetDate);
      }
      if (sourceEl.value > targetEl.value) {
        targetEl.setValue(sourceEl.value);
      }
    } else {
      if (!targetEl.value || targetEl.value < targetDate) {
        targetEl.setValue(targetDate);
      }
      if (sourceEl.value < targetEl.value) {
        targetEl.setValue(sourceEl.value);
      }
    }
  }

  onSubmit() {
    const { fromDate, toDate }: { fromDate: Date, toDate: Date } = this.form.getRawValue();

    if (!this.isDateIntervalCorrect(fromDate, toDate)) {
      return;
    }

    this.submitContent.emit({
      request: {
        fromDate: fromDate ? new RdeaDate(fromDate).getDateTimeString() : null,
        toDate: toDate ? new RdeaDate(toDate).getDateTimeString() : null
      }
    });
  }

  private isDateIntervalCorrect(fromDate: Date, toDate: Date): boolean {
    if (!fromDate || !toDate) {
      return true;
    }

    if (fromDate.getTime() > toDate.getTime()) {
      this.snackbar.showMessage(this.translate.instant('abonents.signups.reports.error.date_range'), 'info');
      return false;
    }

    return true;
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      fromDate: new UntypedFormControl(null),
      toDate: new UntypedFormControl(null)
    });
  }
}
