import { Injectable } from '@angular/core';
import { Camera } from '../models';

@Injectable({ providedIn: 'root' })
export class CameraUtilsService {
    private readonly historyDepthRegex: { [Symbol.match](string: string): RegExpMatchArray } = /history-depth\:([\d]+)/;

    getDepthInHoursFromConfig(configuration: string): number {
        if (!configuration) {
            return 24;
        }
        const matches: RegExpMatchArray = configuration?.match(this.historyDepthRegex);
        return matches?.length > 1 ? Number.parseInt(matches[1], 10) : 24;
    }

    isLatencyError(segmentsCount: number, segmentLength: number): number {
        const latency: [number, number] = this.calcLatency(segmentsCount, segmentLength);

        if (latency === null) {
            return 0;
        } else if (latency[0] >= 3 && latency[0] < 10) {
            return 1;
        } else if (latency[0] >= 10) {
            return 2;
        }
        return 0;
    }

    isBitrateError(bitrate: number): number {
        if (!bitrate) {
            return 0;
        }
        return bitrate > 1100 ? 1 : 0;
    }

    isCameraCorrect(camera: Camera): boolean {
        if (!camera.active) {
            return true;
        }
        if (this.isBitrateError(camera.bitrate) !== 0) {
            return false;
        } else if (this.isLatencyError(camera.segmentsCount, camera.segmentLength) !== 0) {
            return false;
        }
        return true;
    }

    getLatencyRange(segmentsCount: number, segmentLength: number): string {
        const latencyRange: [number, number] = this.calcLatency(segmentsCount, segmentLength);
        if (!latencyRange) {
            return null;
        }
        return `${latencyRange[0]} - ${latencyRange[1]}`;
    }

    calcLatency(segmentsCount: number, segmentLength: number): [number, number] {
        if (!segmentsCount || !segmentLength) {
            return null;
        }

        const latencyNumStr: string = (segmentsCount * segmentLength).toFixed(2);
        const latencyMaxStr: string = ((segmentsCount + 1) * segmentLength).toFixed(2);

        return [Number.parseFloat(latencyNumStr), Number.parseFloat(latencyMaxStr)];
    }

    uriValid(uri: string): boolean {
        if (!uri) {
            return false;
        }

        if (uri.startsWith('rtsp://')) {
            uri = uri.replace('rtsp://', '');
        }

        if (!uri) {
            return false;
        }

        return true;
    }
}
