<app-component-loader
        *ngIf="loading"
        [sizePx]="50"
        [background]="true"
        [overTheComponent]="true"
></app-component-loader>

<div class="user-profile">
  <div
    class="user-profile-back"
    (click)="onBack()">
    <img
      class="user-profile-back__img"
      ngSrc="assets/icons/ic-back.svg"
      alt="error"
      height="16"
      width="8" />
    <span class="user-profile-back__text">
      {{ 'company.roles.user.profile.link.back' | translate }}
    </span>
  </div>

  <div class="user-profile-info">
    <div class="user-profile-info-email">
      <span class="user-profile-info-email__text">
        {{ userResponse?.email }}
      </span>
    </div>
    <div class="user-profile-info-actions">
      <app-company-user-status
        [blocked]="userResponse?.blocked"
      ></app-company-user-status>
      <p-button
        *ngIf="!userResponse?.blocked"
        (click)="onBlock()"
        label="{{ 'company.roles.user.profile.button.block' | translate }}"
        styleClass="p-button-danger p-button-text"
      ></p-button>
      <p-button
        *ngIf="userResponse?.blocked"
        (click)="onUserUnlock()"
        label="{{ 'company.roles.user.profile.button.unlock' | translate }}"
        styleClass="p-button-danger p-button-text"
      ></p-button>
    </div>
  </div>
  <hr />

  <div class="user-profile-header">
    <span class="user-profile-header__text">
      {{ 'company.roles.user.profile.header.roles' | translate }}
    </span>
  </div>
  <div class="user-profile-roles">
    <app-company-roles-management
      *ngIf="userResponse && allAvailableRoles"
      [allAvailableRoles]="allAvailableRoles"
      [mode]="RolesMode.edit"
      [userResponse]="userResponse"
      (access)="onAccess($event)"
    ></app-company-roles-management>
  </div>
  <hr />

  <div class="user-profile-save">
    <p-button
      label="{{ 'company.roles.user.profile.button.save' | translate }}"
      *ngIf="!userResponse?.blocked"
      (click)="onSave()"
    ></p-button>
  </div>
</div>
