<form [formGroup]="form">
  <mat-form-field appearance="outline" class="none-padding">
    <mat-select
      placeholder="{{ 'intercom.new.adapters.page.batches.form.group.placeholder.batch_id' | translate }}}"
      formControlName="batchId"
      (selectionChange)="onSelectBatch($event)"
      (openedChange)="onOpenedChange($event)"
    >
      <input
        #filterInput
        matInput
        placeholder="{{ 'intercom.new.adapters.page.batches.form.group.placeholder.search' | translate }}"
        type="text"
        formControlName="filter"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
      />
      <mat-option [value]="null">--</mat-option>
      <mat-option *ngFor="let batch of (filteredBatches | async)" [value]="batch.id">
        {{ batch.number }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
