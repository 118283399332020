import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CameraEditContentModule } from '@app/shared/components';
import { SoftwareIntercomCameraPopupContentComponent } from './software-intercom-camera-popup-content.component';

@NgModule({
    imports: [
        CommonModule,
        CameraEditContentModule
    ],
    declarations: [
        SoftwareIntercomCameraPopupContentComponent
    ],
    exports: [
        SoftwareIntercomCameraPopupContentComponent
    ]
})
export class SoftwareIntercomCameraPopupContentModule { }
