import {LocalStorageCompanyKey, LocalStorageGeneralKey, LocalStorageHelper} from '@app/shared/entities/common';

export class MenuHelper {

  static getCompanyName(): string | null {
    let companyName = LocalStorageHelper.getItem(LocalStorageCompanyKey.NAME);

    if (!companyName) {
      companyName = LocalStorageHelper.getItem(LocalStorageGeneralKey.USER_NAME);
    }

    return companyName;
  }

  static getCompanyId(): number {
    return LocalStorageHelper.getItem(LocalStorageCompanyKey.ID);
  }

  static getLicenseAgreement(): string {
    return LocalStorageHelper.getItem(LocalStorageGeneralKey.LICENSE_AGREEMENT);
  }
}
