import { Injectable } from '@angular/core';
import { FlatApiService, FlatConnectionResponse, ServiceInfoResponse } from '@app/shared/entities/rd';

@Injectable()
export class AbonentConnectionToolService {
    constructor(
        private flatApiService: FlatApiService
    ) { }

    async getConnectedServices(
        flatId: number,
        services: ServiceInfoResponse[],
    ): Promise<ServiceInfoResponse[]> {
        const connectedServices: ServiceInfoResponse[] = [];

        try {
            const connections: FlatConnectionResponse[] =
                await this.flatApiService.getConnections(flatId).toPromise();

            services.forEach((service: ServiceInfoResponse) => {
                const connectionIdx: number = connections.findIndex((connection: FlatConnectionResponse) =>
                    connection.service.id === service.id
                );

                if (connectionIdx !== - 1) {
                    connectedServices.push(service);
                }
            });
        } catch (error) {
            throw error;
        }

        return connectedServices;
    }
}
