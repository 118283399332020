import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BreadcrumbService } from '@app/core/services/breadcrumb.service';
import { TopBarService } from '@app/core/services/top-bar.service';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import {
  AbonentEventName,
  AbonentStat
} from '@app/shared/entities/rd/monitoring/models';
import { Constants } from '@app/shared/helpers';
import { Abonent, SignUpResponse } from '@app/shared/models';
import { NavbarLink } from '@app/shared/ui';
import { State } from '@app/store/reducers';
import {
  AccountServiceData,
  Chat,
  VoipTokenInfo
} from '@app/views/abonents/models';
import {
  GetAbonentPageAccounts,
  GetAbonentPageInfo,
  GetAbonentPageMessages,
  GetAbonentPageOwnerDelegations,
  GetAbonentPageSharedDelegations,
  GetAbonentPageSignUps,
  GetAbonentPageVoipTokens
} from '@app/views/abonents/store/actions';
import {
  ChangeDateDiff,
  ChangeVoipToken
} from '@app/views/abonents/store/actions/abonent-page.actions';
import {
  abonentPageAccountServicesState,
  abonentPageInfoState,
  abonentPageOwnerState,
  abonentPageSharedState,
  abonentPageStateChat,
  abonentPageVoipTokensState
} from '@app/views/abonents/store/states';
import {
  abonentPageSelectedDateDiff,
  abonentPageSelectedVoipToken,
  abonentPageStatState
} from '@app/views/abonents/store/states/abonent-page.state';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-abonent-page',
  templateUrl: './abonent-page.component.html',
  styleUrls: ['./abonent-page.component.scss']
})
export class AbonentPageComponent implements OnInit, OnDestroy {
  readonly links: NavbarLink[] = [
    {
      name: 'about',
      text: this.translate.instant('abonent.page.navbar.about')
    },
    {
      name: 'owner',
      text: this.translate.instant('abonent.page.navbar.owner')
    },
    {
      name: 'shared',
      text: this.translate.instant('abonent.page.navbar.shared')
    },
    {
      name: 'access',
      text: this.translate.instant('abonent.page.navbar.access')
    }
  ];

  @ViewChild('chatBlock') chatBlock: ElementRef;
  @ViewChild('background') background: ElementRef;
  loading: boolean;
  abonentId: number;
  activeLink = 'owner';
  owner$: Observable<{
    delegations?: any;
    signUps?: SignUpResponse[];
    accounts?: any;
  }> = this.store.select(abonentPageOwnerState);
  shared$: Observable<{
    delegations?: any;
    signUps?: SignUpResponse[];
    accounts?: any;
  }> = this.store.select(abonentPageSharedState);
  eventName: AbonentEventName = AbonentEventName.ALL;
  chat$: Observable<Chat> = this.store.select(abonentPageStateChat);
  abonent$: Observable<Abonent> = this.store.select(abonentPageInfoState);
  accountServices$: Observable<AccountServiceData[]> = this.store.select(
    abonentPageAccountServicesState
  );
  selectedVoipToken$: Observable<VoipTokenInfo> = this.store.select(
    abonentPageSelectedVoipToken
  );
  voipTokens$: Observable<VoipTokenInfo[]> = this.store.select(
    abonentPageVoipTokensState
  );
  selectedDateDiff$: Observable<number> = this.store.select(
    abonentPageSelectedDateDiff
  );
  stat$: Observable<AbonentStat> = this.store.select(abonentPageStatState);

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    public resolution: ResolutionService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<State>,
    private breadcrumbService: BreadcrumbService,
    private topBar: TopBarService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.initRouteData();
    this.initStore();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onChangePage(pageIdx: number) {
    if (pageIdx < 0 || pageIdx > this.links.length - 1) {
      return;
    }

    this.activeLink = this.links[pageIdx].name;

    switch (this.activeLink) {
      case 'about':
        this.store.dispatch(new GetAbonentPageVoipTokens(this.abonentId));
        break;
      case 'owner':
        this.store.dispatch(new GetAbonentPageOwnerDelegations(this.abonentId));
        this.store.dispatch(new GetAbonentPageSignUps(this.abonentId));
        this.store.dispatch(new GetAbonentPageAccounts(this.abonentId));
        break;
      case 'shared':
        this.store.dispatch(
          new GetAbonentPageSharedDelegations(this.abonentId)
        );
        this.store.dispatch(new GetAbonentPageSignUps(this.abonentId));
        this.store.dispatch(new GetAbonentPageAccounts(this.abonentId));
        break;
    }
  }

  onOpenChat() {
    const chatBlockDiv: HTMLDivElement = this.chatBlock.nativeElement;
    if (!chatBlockDiv.classList.contains('chat-block-opened')) {
      chatBlockDiv.classList.add('chat-block-opened');
    }
    const backgroundDiv: HTMLDivElement = this.background.nativeElement;
    if (!backgroundDiv.classList.contains('dark-background')) {
      backgroundDiv.classList.add('dark-background');
    }
  }

  onCloseChat() {
    const chatBlockDiv: HTMLDivElement = this.chatBlock.nativeElement;
    if (chatBlockDiv.classList.contains('chat-block-opened')) {
      chatBlockDiv.classList.remove('chat-block-opened');
    }
    const backgroundDiv: HTMLDivElement = this.background.nativeElement;
    if (backgroundDiv.classList.contains('dark-background')) {
      backgroundDiv.classList.remove('dark-background');
    }
  }

  onVoipTokenChanged(voipToken: VoipTokenInfo) {
    if (!voipToken) {
      return;
    }

    this.store.dispatch(new ChangeVoipToken(voipToken, this.abonentId));
  }

  onDateDiffTypeChanged(dateDiff: number) {
    if (!dateDiff) {
      return;
    }

    this.store.dispatch(new ChangeDateDiff(dateDiff, this.abonentId));
  }

  onEventTypeSelected(eventName: AbonentEventName) {
    if (!eventName) {
      return;
    }

    this.eventName = eventName;
  }

  private initRouteData() {
    this.abonentId = Number.parseInt(
      this.route.snapshot.params['id'].match(/([\d]+)/)[0],
      10
    );

    if (this.route.snapshot.queryParams.phone) {
      this.updateTitles(this.route.snapshot.queryParams.phone);
    }

    this.router.events.pipe(takeUntil(this.onDestroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.abonentId = Number.parseInt(
          this.route.snapshot.params['id'].match(/([\d]+)/)[0],
          10
        );
        this.store.dispatch(new GetAbonentPageInfo(this.abonentId));
        this.store.dispatch(
          new GetAbonentPageMessages(this.abonentId, 'support', 15, 0)
        );
        this.store.dispatch(
          new ChangeDateDiff(Constants.dateDiffs[0].value, this.abonentId)
        );

        this.eventName = AbonentEventName.ALL;

        this.onChangePage(1);

        if (this.route.snapshot.queryParams.phone) {
          this.updateTitles(this.route.snapshot.queryParams.phone);
        }
      }
    });
  }

  private initStore() {
    this.store.dispatch(
      new GetAbonentPageMessages(this.abonentId, 'support', 15, 0)
    );
    this.store.dispatch(new GetAbonentPageInfo(this.abonentId));
    this.store.dispatch(new GetAbonentPageOwnerDelegations(this.abonentId));
    this.store.dispatch(new GetAbonentPageSignUps(this.abonentId));
    this.store.dispatch(new GetAbonentPageAccounts(this.abonentId));
  }

  private updateTitles(phone: string) {
    this.breadcrumbService.title =
      this.translate.instant('abonent.page.title') + ' +' + phone;
    this.topBar.title =
      this.translate.instant('abonent.page.title') + ' +' + phone;
  }
}
