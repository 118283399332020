import { Pipe, PipeTransform } from '@angular/core';
import { IConnection, IToAbonent } from '@app/shared/entities/rd';
import { isEqual } from 'lodash';

@Pipe({
  name: 'stateUrlImg'
})
export class StateUrlImgPipe implements PipeTransform {
  transform(
    value: IConnection,
    ref: { connections: IConnection[]; abonent: IToAbonent }
  ): string {
    return `assets/icons/${
      ref.connections.find((connection) => {
        return (
          isEqual(value.id, connection.id) &&
          isEqual(ref.abonent.id, connection.toAbonent.id)
        );
      })
        ? 'ic-not-block.svg'
        : 'ic-new-lock.svg'
    }`;
  }
}
