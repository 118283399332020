import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  BaseInfoPanelComponent
} from '@app/shared/components/info-panel/components/base-info-panel/base-info-panel.component';
import { NativeDialogService } from '@app/shared/components/info-panel/services/native-dialog.service';
import { Response } from '@app/shared/components/info-panel/models/response';
import { TranslateService } from '@ngx-translate/core';

export const MB_CONVERTER = 1024 * 1024;

@Component({
  selector: 'app-document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss']
})
export class DocumentUploadComponent extends BaseInfoPanelComponent implements OnDestroy, AfterViewInit {
  @ViewChild('fileDropRef') fileDropInput: ElementRef<HTMLInputElement>;

  private unSubscribe$: Subject<boolean> = new Subject();
  public files: File[] = [];

  @Input() showAttached: boolean;
  @Input() maxFileSizeMb = 100;
  @Output() emitReadData: EventEmitter<File[] | FileList> = new EventEmitter<File[]>();

  constructor(
    private zone: NgZone,
    private translate: TranslateService,
    dialogService: NativeDialogService
  ) {
    super(dialogService);
  }

  ngAfterViewInit(): void {
    this.initEvent();
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next(true);
    this.unSubscribe$.unsubscribe();
  }

  public handleDrop(event: Event) {
    this.handleFiles((event.target as HTMLInputElement).files);
    this.clearInput();
  }

  public onDelete(fileConfig: File): void {
    this.files = this.files.filter((file) => !this.compareFile(file, fileConfig));
    this.emitReadData.emit(this.files);
  }

  private initEvent(): void {
    const dropArea = document.querySelector('.upload');
    this.zone.runOutsideAngular(() => {
      ['dragenter', 'dragover'].forEach(eventName => {
        fromEvent(dropArea, eventName).pipe(takeUntil(this.unSubscribe$))
          .subscribe((event) => {
            dropArea.classList.add('hover');
          });
      });
      ['dragleave', 'drop'].forEach(eventName => {
        fromEvent(dropArea, eventName).pipe(takeUntil(this.unSubscribe$))
          .subscribe((event) => {
            dropArea.classList.remove('hover');
          });
      });
    });
  }

  private handleFiles = (handler: FileList) => {
    const file = handler[0];

    if (file.size > this.maxFileSizeMb * MB_CONVERTER) {
      this.showResponseStatus(
        this.translate.instant('shared.upload.document.file_add_error'),
        `${this.translate.instant('shared.upload.document.file_add_error')} ${this.maxFileSizeMb} Mb`,
        Response.info,
      );
      return;
    }

    if (!this.files.find(existingFile => this.compareFile(existingFile, file))) {
      if (this.showAttached) {
        this.files = [...this.files, file];
        this.emitReadData.emit(this.files);
      } else {
        // TODO will delete 'if' when added support to adapter upload RDFT-1630
        this.emitReadData.emit(handler);
      }
    } else {
      this.showResponseStatus(
        this.translate.instant('shared.upload.document.file_add_error'),
        this.translate.instant('shared.upload.document.same_file_error'),
        Response.info,
      );
    }
  }

  private clearInput = () => this.fileDropInput.nativeElement.value = null;

  private compareFile(currentFile: File, selectedFile: File): boolean {
    return currentFile.name + currentFile.size === selectedFile.name + selectedFile.size;
  }
}
