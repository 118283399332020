import { Injectable } from '@angular/core';
import {
  LocalStorageCompanyKey,
  LocalStorageHelper
} from '@app/shared/entities';
import { Company, CompanyApiService } from '@app/shared/entities/rd';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class PaymentsService {
  public companyShortName$: BehaviorSubject<string> = new BehaviorSubject(null);

  constructor(private companyApiService: CompanyApiService) {}

  public loadCompany(): void {
    const shortName: string = LocalStorageHelper.getItem(
      LocalStorageCompanyKey.SHORT_NAME
    );

    if (shortName !== null) {
      this.companyShortName$.next(shortName);
      return;
    }

    this.companyApiService
      .getCompanyById(LocalStorageHelper.getItem(LocalStorageCompanyKey.ID))
      .pipe(
        tap((company: Company) => {
          LocalStorageHelper.setItem(
            LocalStorageCompanyKey.SHORT_NAME,
            company.shortName
          );
          this.companyShortName$.next(company.shortName);
        })
      )
      .subscribe();
  }
}
