<form
    class="popup"
    [formGroup]="form"
    autocomplete="off"
    (submit)="onSubmit()"
  >
    <mat-form-field appearance="outline" class="relative-size">
      <mat-label>{{ 'software.intercoms.create.field.name' | translate }}</mat-label>
      <input
        matInput
        placeholder="{{ 'software.intercoms.create.placeholder.name' | translate }}"
        [formControlName]="'serviceName'"
        autocomplete="off"
      >
      <mat-hint
        [innerHTML]="'software.intercoms.create.hint.name' | translate:{ type: serviceType | serviceTypeName }"
      ></mat-hint>
    </mat-form-field>

    <div class="popup-actions">
      <button
        type="submit"
        color="primary"
        mat-raised-button
        [disabled]="loading"
        class="popup-actions__button"
      >
        {{ 'software.intercoms.create.button.submit' | translate }}
      </button>
    </div>
  </form>
