<app-info-block>
  <span info-block-text>
    {{ 'shared.keys.ip.intercom.handset_lock.text' | translate }}
  </span>
  <span info-block-content>
    {{ 'shared.keys.ip.intercom.handset_lock.content.before' | translate }}
    <ol>
      <li>{{ 'shared.keys.ip.intercom.handset_lock.content.option_1' | translate }}</li>
      <li>{{ 'shared.keys.ip.intercom.handset_lock.content.option_2' | translate }}</li>
      <li>{{ 'shared.keys.ip.intercom.handset_lock.content.option_3' | translate }}</li>
      <li>{{ 'shared.keys.ip.intercom.handset_lock.content.option_4' | translate }}</li>
    </ol>
    {{ 'shared.keys.ip.intercom.handset_lock.content.after' | translate }}
  </span>
</app-info-block>

<form
  class="popup"
  [formGroup]="form"
  autocomplete="off"
  (ngSubmit)="submitParams.emit(form.getRawValue())"
>
  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'shared.keys.ip.intercom.handset_lock.field.ip' | translate }}
    </mat-label>
    <input
      matInput
      required
      type="text"
      autocorrect="off"
      spellcheck="false"
      autocapitalize="off"
      formControlName="host"
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'shared.keys.ip.intercom.handset_lock.field.port' | translate }}
    </mat-label>
    <input
      matInput
      type="number"
      autocorrect="off"
      spellcheck="false"
      autocapitalize="off"
      formControlName="port"
    />
  </mat-form-field>

  <div class="ip-intercom-hanset-lock-login">
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'shared.keys.ip.intercom.handset_lock.field.login' | translate }}
      </mat-label>
      <input
        matInput
        required
        type="text"
        autocorrect="off"
        spellcheck="false"
        autocapitalize="off"
        formControlName="login"
        autocomplete="new-login"
      />
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'shared.keys.ip.intercom.handset_lock.field.password' | translate }}
    </mat-label>
    <input
      matInput
      required
      autocorrect="off"
      spellcheck="false"
      autocapitalize="off"
      formControlName="password"
      autocomplete="new-password"
      [type]="hidePassword ? 'password' : 'text'"
    />

    <mat-icon
      matSuffix
      (click)="hidePassword = !hidePassword"
      class="popup-hide-password__icon"
      [svgIcon]="hidePassword ? 'invisible' : 'visible'"
    ></mat-icon>
  </mat-form-field>

  <div class="popup-actions">
    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="!form.valid || isActualState"
      class="popup-actions__button"
    >
      {{ 'shared.keys.ip.intercom.handset_lock.button.submit' | translate }}
    </button>
  </div>
</form>
