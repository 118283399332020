import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ServiceConnection } from '@app/shared/entities/rd';
import { AbonentsFromEntrancesConnection } from '../abonents-from-entrances-content/models/abonents-from-entrances-connection.model';

@Component({
  selector: 'app-abonents-list',
  templateUrl: './abonents-list.component.html',
  styleUrls: ['./abonents-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbonentsListComponent {
  @Input() loading: boolean;
  @Input() selectedAll: boolean;
  @Input() connections: AbonentsFromEntrancesConnection[];

  @Output() private connectionSelected: EventEmitter<{ selectedValue: ServiceConnection | boolean }> = new EventEmitter();

  onSelectConnection(selectedValue: ServiceConnection | boolean) {
    this.connectionSelected.emit({ selectedValue });
  }

  filterFunc(item: AbonentsFromEntrancesConnection): boolean {
    return item.selected;
  }
}
