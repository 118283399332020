<div class="service-logs-block">
  <div class="service-logs-block-header">
    <h3>
      {{ 'service.logs.title' | translate }}
    </h3>

    <mat-slide-toggle
      [(ngModel)]="extendedMode"
      (change)="onChangeMode($event)"
    >
      {{ 'service.logs.extend' | translate }}
  </mat-slide-toggle>
  </div>

  <div class="service-logs-block-content">
    <ng-container *ngIf="sources?.length > 0">
      <mat-chip-list *ngIf="sources?.length > 1" class="service-logs-block-content-sources">
        <mat-chip
          *ngFor="let source of sources"
          (click)="source.id !== selectedSource?.id && onSelectSource(source)"
          [color]="source.id === selectedSource?.id ? 'primary' : 'primary-inverse'"
        >
          {{ source.label }}
        </mat-chip>
      </mat-chip-list>

      <div class="service-logs-block-content-logs">
        <table class="service-logs-block-content-logs-table">
          <tbody class="service-logs-block-content-logs-table-body">
            <ng-container *ngIf="logs?.length > 0">
              <tr
                *ngFor="let log of logs; let last = last"
                class="service-logs-block-content-logs-table-body-message"
              >
                <div class="service-logs-block-content-logs-table-body-message-title">
                  <p class="service-logs-block-content-logs-table-body-message-title__time">
                    {{ log.createdAt | date: 'dd.MM.yyyy HH:mm:ss' }}
                  </p>

                  <p class="service-logs-block-content-logs-table-body-message-title__msg">
                    {{ extendedMode ? log.message : log.message['text'] }}
                  </p>
                </div>

                <ng-container *ngIf="!extendedMode">
                  <p class="service-logs-block-content-logs-table-body-message__description">
                    {{ log.message['description'] }}
                  </p>
                </ng-container>

                <hr class="service-logs-block-content-logs-table-body-message__divider" *ngIf="!last">
              </tr>

            </ng-container>

            <ngx-skeleton-loader
              *ngIf="loading"
              [class.service-logs-block-content-logs-table-body-skeleton]="loading"
              count="5"
              [theme]="{ 'border-radius': '8px', height: '100px' }"
            ></ngx-skeleton-loader>

            <app-info-block *ngIf="logs?.length === 0 && !loading">
              <ng-container info-block-text>
                {{ 'service.logs.message.empty' | translate }}
              </ng-container>
            </app-info-block>
          </tbody>
        </table>
      </div>
    </ng-container>
  </div>
</div>
