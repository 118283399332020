import { RdaResponse, RdaUtilsService } from '@app/shared/entities/rd/rda';
import { Camera, CameraUtilsService } from '@app/shared/entities/rd';
import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidationErrors } from '@angular/forms';

@Injectable()
export class CameraEditContentService {
    constructor(
        private cameraUtilsService: CameraUtilsService,
        private rdaUtilsService: RdaUtilsService
    ) { }

    privateCamera(camera: Camera): boolean {
        return camera?.rdaUid && camera?.private;
    }

    notIpType(rda: RdaResponse): boolean {
        return rda && (!rda.mode || rda.intercomType && !this.rdaUtilsService.ipType(rda.intercomType));
    }

    uriValid(control: UntypedFormGroup): ValidationErrors | null {
        const uri: string = control.get('uri').value;
        const uriValid: boolean = this.cameraUtilsService.uriValid(uri);

        if (!uriValid) {
            return { incorrectUri: true };
        }

        return null;
    }
}
