import { Component, EventEmitter, Input, LOCALE_ID, OnDestroy, OnInit, Output } from '@angular/core';
import { DialogWrapperData } from '@app/shared/ui';
import { from, Observable, of, Subject } from 'rxjs';
import {IPaymentsWithPagination, PaymentsInterface} from '@app/shared/entities/rd/payments/interfaces';
import { catchError, mergeMap, takeUntil } from 'rxjs/operators';
import { PAYMENTS_STATUS } from '@app/shared/models/PaymentsStatus';
import { AccountApiService, CompanyApiService } from '@app/shared/entities/rd';
import { SnackbarService } from '@app/shared/components';
import { HttpErrorResponse } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-unblock-non-payment-account',
  templateUrl: './unblock-non-payment-account.component.html',
  styleUrls: ['./unblock-non-payment-account.component.scss'],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'ru-RU'
    }
  ]
})
export class UnblockNonPaymentAccountComponent implements OnDestroy, OnInit {
  @Input() wrapperData: DialogWrapperData<number, void>;

  public everythingIsPaid: boolean;
  public isFailedPayment: boolean;
  public wait: boolean;
  private _paymentData: PaymentsInterface[];
  private _unSubscribe$: Subject<boolean> = new Subject<boolean>();

  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private accountApiService: AccountApiService,
    private companyApiService: CompanyApiService,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {
    registerLocaleData(localeDe, environment.locale, localeDeExtra);
    this.everythingIsPaid = false;
    this.isFailedPayment = false;
    this.wait = true;
  }

  ngOnInit(): void {
    this.onViewUpdate();
  }

  ngOnDestroy(): void {
    this._unSubscribe$.next(true);
    this._unSubscribe$.complete();
  }

  get paymentData(): PaymentsInterface[] {
    return this._paymentData;
  }

  set paymentData(value: PaymentsInterface[]) {
    this._paymentData = value;
  }

  public onPay(): void {
    this.wait = true;
    from([...this.paymentData]).pipe(
      mergeMap(payment => this.markPaymentPaid(payment)),
      catchError((err: HttpErrorResponse) => {
        this.isFailedPayment = true;
        this.snackbar.showMessage(
          this.translate.instant('abonent.accounts.unblock.not.payment.account.message.pay.failed', {
            text: err.message
          })
        );
        return of(err);
      }),
      takeUntil(this._unSubscribe$)
    ).subscribe(() => {
      if (this.isFailedPayment) {
        this.onViewUpdate();
      } else {
        this.everythingIsPaid = true;
      }
      this.wait = false;
    });
  }

  public onClose(): void {
    this.close.emit();
  }

  public onSubmit(): void {
    this.wrapperData.submit();
  }

  private markPaymentPaid(param: PaymentsInterface): Observable<PaymentsInterface[]> {
    return this.companyApiService.markPaymentPaid(param.confirmationKey);
  }

  private checkingPaidInvoices(paymentData: Observable<IPaymentsWithPagination>): void {
    paymentData.pipe(takeUntil(this._unSubscribe$)).subscribe((payments) => {
      const allNonPayedPayments: PaymentsInterface[] = this.getAllNonPayedPayments(payments);
      this.paymentData = allNonPayedPayments;
      this.everythingIsPaid = allNonPayedPayments.length === 0;
      this.wait = false;
    });
  }

  private getAllNonPayedPayments(payments: IPaymentsWithPagination): PaymentsInterface[] {
    return payments.content.filter(
      (payment) => [
        PAYMENTS_STATUS.created,
        PAYMENTS_STATUS.pending,
        PAYMENTS_STATUS.canceled
      ].some((status) => status === payment.status));
  }

  private onViewUpdate(): void {
    this.checkingPaidInvoices(this.accountApiService.getPaymentByAccount(this.wrapperData.body, 100));
  }
}
