<div class="rda-translations-editor">
  <app-rda-translations-instruction
  ></app-rda-translations-instruction>

  <app-rda-translation-flats
    [range]="range"
    [loading]="loading"
    [additionalRanges]="additionalRanges"
    [intercomUid]="rda?.uid"
  ></app-rda-translation-flats>

  <app-rda-translations-form
    [loading]="loading"
    [translation]="rda?.translation"
    [translationTunings]="translationTunings"
    (addTranslationTuning)="onAddTranslationTuning($event)"
    (saveTranslation)="onSaveTranslation($event)"
    (deleteTranslationTuning)="onDeleteTranslationTuning($event)"
  >
  </app-rda-translations-form>
</div>
