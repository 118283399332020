<form class="popup" [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="popup-rda-keys">
    <div class="popup-rda-keys-alert" *ngIf="emptyRdaIntercomType">
      <p class="popup-rda-keys-alert__text">
        {{ 'shared.intercom.keys.edit.container.message.type_of_intercom_not_chosen' | translate }}
      </p>
    </div>

    <app-rda-search-select
      [initialIntercoms]="rdas"
      [initialIntercomSearchSelect]="initialRdaSelect"
      [disabled]="loading || !!key"
      [preload]="!key"
      [params]="{ ipType: null, inactive: null }"
      (rdaSelected)="onRdaSelected($event.value, $event.select)"
    ></app-rda-search-select>

    <ng-container *ngIf="rdaSelect">
      <h3 class="popup-rda-keys__title">
        <ng-container *ngIf="!ipType; else ipIntercomTitle">
          {{ 'shared.intercom.keys.edit.container.relay' | translate }}
        </ng-container>
        <ng-template #ipIntercomTitle>
          {{ 'shared.intercom.keys.edit.container.links' | translate }}
        </ng-template>
        {{ 'shared.intercom.keys.edit.container.and_type_key' | translate | lowercase }}
      </h3>

      <ng-container *ngIf="!ipType; else ipIntercomFields">
        <mat-form-field appearance="outline" class="relative-size">
          <mat-label>
            {{ 'shared.intercom.keys.edit.container.field.relay' | translate }}
          </mat-label>
          <mat-select
            required
            placeholder="{{ 'shared.intercom.keys.edit.container.placeholder.relay' | translate }}"
            formControlName="releId"
          >
            <ng-container *ngFor="let relay of intercomKeyRelays">
              <mat-option
                [value]="relay"
                *ngIf="!connectedRelays[relay]"
              >{{ relay }}</mat-option>
            </ng-container>
          </mat-select>
          <mat-hint>
            {{ 'shared.intercom.keys.edit.container.hint.relay' | translate }}
          </mat-hint>
        </mat-form-field>
      </ng-container>
      <ng-template #ipIntercomFields>
        <mat-form-field appearance="outline" *ngIf="basIp || sputnikIp">
          <mat-label>
            {{
              sputnikIp
                ? ('shared.intercom.keys.edit.container.field.bearer_token' | translate )
                : ('shared.intercom.keys.edit.container.field.authorization_url' | translate )
            }}
          </mat-label>
          <input
            type="url"
            matInput
            formControlName="authUrl"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>
            {{ 'shared.intercom.keys.edit.container.field.open_door_url' | translate }}
          </mat-label>
          <input
            type="url"
            matInput
            formControlName="openDoorUrl"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
        </mat-form-field>

        <div class="popup-rda-keys-generator">
          <p
            class="popup-rda-keys-generator__text"
            [innerHTML]="'shared.intercom.keys.edit.container.message.there_are_problems_with_filling_out_links' | translate"
          ></p>
          <button
            type="button"
            mat-raised-button
            color="primary-inverse"
            [disabled]="disabled || loading"
            (click)="onGenerateUrls()"
            class="popup-rda-keys-generator__gen-button"
          >
            {{ 'shared.intercom.keys.edit.container.button.generate_links' | translate }}
          </button>
        </div>
      </ng-template>

      <mat-form-field appearance="outline" class="relative-size">
        <mat-label>
          {{ 'shared.intercom.keys.edit.container.field.key_type' | translate }}
        </mat-label>
        <mat-select
          required
          placeholder="{{ 'shared.intercom.keys.edit.container.placeholder.key_type' | translate }}"
          formControlName="type"
          panelClass="mat-select-custom"
        >
          <mat-select-trigger>
            <div class="popup-rda-keys-type-selector-preview">
              <img
                class="popup-rda-keys-type-selector-preview__icon"
                [src]="intercomKeyRecords[form.get('type').value]?.icon"
              />
              <p class="popup-rda-keys-type-selector-preview__text">
                {{ intercomKeyRecords[form.get('type').value]?.name | translate }}
              </p>
            </div>
          </mat-select-trigger>

          <mat-option
            [value]="type"
            *ngFor="let type of typesList"
          >
            <div class="popup-rda-keys-type-selector">
              <img
                class="popup-rda-keys-type-selector__icon"
                [src]="intercomKeyRecords[type].icon"
              />
              <p class="popup-rda-keys-type-selector__text">
                {{ intercomKeyRecords[type].name | translate }}
              </p>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <app-select-search
        [title]="'shared.intercom.keys.edit.container.field.location' | translate"
        [valuesList]="locationsMapped$ | async"
        [selectedValue]="selectedLocation"
        [isFilterActive]="true"
        [isSortedActive]="true"
        [showEmptyOption]="true"
        (valueSelected)="onLocationSelected($event)"
      ></app-select-search>
    </ng-container>
  </div>

  <div class="popup-actions">
    <button
      type="submit"
      color="primary"
      mat-raised-button
      class="popup-actions__button"
      [disabled]="disabled || loading"
    >
      {{ 'shared.intercom.keys.edit.container.button.submit' | translate }}
    </button>
  </div>
</form>
