import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { IntercomType, IpRdaRequest, RdaResponse } from '@app/shared/entities/rd';
import { DialogWrapperData } from '@app/shared/ui';
import { Observable } from 'rxjs';
import { IpIntercomGenerateBottomSheetService } from './ip-intercom-generate-bottom-sheet.service';

@Component({
  selector: 'app-ip-intercom-generate-bottom-sheet',
  templateUrl: './ip-intercom-generate-bottom-sheet.component.html',
  styleUrls: ['./ip-intercom-generate-bottom-sheet.component.scss'],
  providers: [IpIntercomGenerateBottomSheetService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IpIntercomGenerateBottomSheetComponent {
  loading$: Observable<boolean> = this.ipIntercomGenerateBottomSheetService.loading$;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogWrapperData<{ intercomType: IntercomType }, { response: RdaResponse }>,
    public ipIntercomGenerateBottomSheetService: IpIntercomGenerateBottomSheetService
  ) { }

  onSubmit(request: IpRdaRequest) {
    this.ipIntercomGenerateBottomSheetService.generateIpIntercom(
      request,
      (response: RdaResponse) => this.data.submit({ response })
    );
  }
}
