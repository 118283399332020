import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { AbonentEventName, AbonentEvents } from '@app/shared/entities/rd/monitoring/models';
import { Constants } from '@app/shared/helpers';
import { Abonent } from '@app/shared/models';
import { VoipTokenInfo } from '@app/views/abonents/models';
import { AbonentStat } from '@app/shared/entities/rd';
import {TranslateService} from '@ngx-translate/core';
import {DateService} from '@app/shared/services/date.service';
import {
  ServiceActivityService
} from '@app/views/services/components/containers/service-activity/service-activity.service';

@Component({
  selector: 'app-abonent-info',
  templateUrl: './abonent-info.component.html',
  styleUrls: ['./abonent-info.component.scss'],
  providers: [DateService],
})
export class AbonentInfoComponent implements OnInit {
  readonly abonentEventNameAll = AbonentEventName.ALL;
  readonly abonentEventNameKeys: string[] = Object.keys(AbonentEventName).filter(key => typeof 'string');
  readonly eventTypeLabels: Record<AbonentEventName, string> = {
    'ALL': this.translate.instant('abonent.page.info.enum.event_type.all'),
    'CALL': this.translate.instant('abonent.page.info.enum.event_type.call'),
    'KEY_ACTIVATE': this.translate.instant('abonent.page.info.enum.event_type.key_activate'),
    'SIGN_IN': this.translate.instant('abonent.page.info.enum.event_type.sign_in'),
    'VIDEO': this.translate.instant('abonent.page.info.enum.event_type.video')
  };

  public dateDiffs: Array<{ value: number, name: string }>;

  @Input() abonent!: Abonent;
  @Input() voipTokens!: VoipTokenInfo[];
  @Input() set selectedVoipToken(selectedVoipToken: VoipTokenInfo) {
    this._selectedVoipToken = selectedVoipToken;
  }
  @Input() set stat(stat: AbonentStat) {
    this._stat = stat;

    if (stat?.abonentEvents) {
      this.filterEvents(this.eventName);
    }
  }
  @Input() selectedDateDiff!: number;
  @Input() eventName: AbonentEventName = AbonentEventName.ALL;
  filteredEvents: AbonentEvents[];

  @Output() private dateDiffTypeChanged: EventEmitter<{ dateDiff: number }> = new EventEmitter();
  @Output() private voipTokenChanged: EventEmitter<{ voipToken: VoipTokenInfo }> = new EventEmitter();
  @Output() private eventTypeSelected: EventEmitter<{ eventName: AbonentEventName }> = new EventEmitter();

  private _stat: AbonentStat;
  private _selectedVoipToken: VoipTokenInfo;

  constructor(
    public resolution: ResolutionService,
    private dateService: DateService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.dateDiffs = this.dateService.dateDiffs.slice(3);
  }

  get stat(): AbonentStat {
    return this._stat;
  }

  get selectedVoipToken(): VoipTokenInfo {
    return this._selectedVoipToken;
  }

  onVoipTokenChanged(voipToken: VoipTokenInfo) {
    this.voipTokenChanged.emit({ voipToken });
  }

  onEventTypeSelected(eventName: AbonentEventName) {
    if (this.stat?.abonentEvents) {
      this.filterEvents(eventName);
    } else {
      this.filteredEvents = [];
    }

    this.eventTypeSelected.emit({ eventName });
  }

  onDateDiffSelected() {
    this.dateDiffTypeChanged.emit({ dateDiff: this.selectedDateDiff });
  }

  private filterEvents(eventName: AbonentEventName) {
    if (eventName === AbonentEventName.ALL) {
      this.filteredEvents = this.stat.abonentEvents;
    } else {
      this.filteredEvents = this.stat.abonentEvents.filter(event =>
        event.abonentEventName === eventName
      );
    }
  }
}
