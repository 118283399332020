import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbonentsFromEntrancesResponse } from '@app/shared/components';
import { ServiceConnection } from '@app/shared/entities/rd';
import { DialogWrapperData, DialogWrapperService } from '@app/shared/ui/dialog-wrapper';
import { ServiceAbonentsFromEntrancesPopupData } from './service-abonents-from-entrances-popup-data.model';

@Component({
  selector: 'app-service-abonents-from-entrances-popup',
  templateUrl: './service-abonents-from-entrances-popup.component.html',
  styleUrls: ['./service-abonents-from-entrances-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceAbonentsFromEntrancesPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogWrapperData<ServiceAbonentsFromEntrancesPopupData, { selectedConnections: ServiceConnection[] }>,
    public dialogWrapperService: DialogWrapperService
  ) { }

  onConnect(selectedConnections: AbonentsFromEntrancesResponse[]) {
    this.data.submit({ selectedConnections });
  }
}
