export interface CameraUpdateRequest {
    configuration: string;
    isPrivate: boolean;
    locationId: number;
    password: string;
    rdaUid: string;
    rdvaId: number;
    uri: string;
    user: string;
}
