import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResourcePath } from '@app/shared/entities';
import { ResourcesHelper } from '@app/shared/entities/common';
import { Abonent } from '@app/shared/models';

@Component({
  selector: 'app-abonents-delegation-popup-content-confirm',
  templateUrl: './abonents-delegation-popup-content-confirm.component.html',
  styleUrls: ['./abonents-delegation-popup-content-confirm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbonentsDelegationPopupContentConfirmComponent implements OnInit {
  readonly resourcePath: typeof ResourcePath = ResourcePath;

  @Input() loading: boolean;
  @Input() owner: Abonent;
  @Input() phone: number;

  @Output() private delegateAccess: EventEmitter<{ ownerId: number }> = new EventEmitter();

  constructor(
    public resourcesHelper: ResourcesHelper
  ) { }

  ngOnInit() {
  }

  onDelegate() {
    this.delegateAccess.emit({ ownerId: this.owner.id });
  }
}
