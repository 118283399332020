import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { CityResponse, EntranceResponse, HouseResponse, StreetResponse } from '@app/shared/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HumanErrorTranslationService } from './human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class AddressService extends ApiService {
  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1']);
  }

  getCitiesList(): Observable<CityResponse[]> {
    return this.get(`/addresses/cities`, 1);
  }

  getStreetsList(cityId: number): Observable<StreetResponse[]> {
    return this.get(`/addresses/cities/${cityId}/streets`, 1);
  }

  getHousesList(cityId: number, streetId: number): Observable<HouseResponse[]> {
    return this.get(`/addresses/cities/${cityId}/streets/${streetId}/houses`, 1);
  }

  getEntrancesList(cityId: number, streetId: number, houseId: number): Observable<EntranceResponse[]> {
    return this.get(`/addresses/cities/${cityId}/streets/${streetId}/houses/${houseId}/entrances`, 1);
  }
}
