import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AbonentsFromEntrancesContentModule, EntranceEditContainerModule } from '@app/shared/components';
import { DialogWrapperModule } from '@app/shared/ui';
import { ServiceEntrancePopupComponent } from './service-entrance-popup.component';
import {SoftwareIntercomTariffPopupContentModule} from '@app/views/services/submodules/software-intercoms/components/software-intercom-page/popups/software-intercom-tariff-popup';

@NgModule({
    imports: [
        CommonModule,
        DialogWrapperModule,
        EntranceEditContainerModule,
        AbonentsFromEntrancesContentModule,
        SoftwareIntercomTariffPopupContentModule
    ],
    declarations: [
        ServiceEntrancePopupComponent
    ],
    exports: [
        ServiceEntrancePopupComponent
    ]
})
export class ServiceEntrancePopupModule { }
