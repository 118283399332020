<div class="solution">
  <div class="solution__text">
    <h3>{{title}}</h3>
    <p *ngFor="let solution of solutionDescription">
      {{solution}}
    </p>
  </div>
  <div class="solution__action">
    <button
      mat-raised-button
      (click)="onSetAutomatically($event)"
    >
      {{ 'shared.payments.possible.cron.solutions.button.set_automatically' | translate }}
    </button>
  </div>
</div>
