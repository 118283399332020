import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { IntercomPanelDeleteContentModule, IntercomPanelEditContentModule, IntercomPanelsListContentModule } from '@app/shared/components';
import { ServiceIntercomPanelsComponent } from './service-intercom-panels.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        IntercomPanelsListContentModule,
        IntercomPanelEditContentModule,
        IntercomPanelDeleteContentModule,
        IntercomPanelsListContentModule,
        NgxTranslateModule
    ],
    declarations: [
        ServiceIntercomPanelsComponent,
    ],
    exports: [
        ServiceIntercomPanelsComponent
    ]
})
export class ServiceIntercomPanelsModule { }
