import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CameraInfoPopupComponent } from '@app/shared/components';
import { ResolutionService } from '@app/shared/entities/common';
import { Camera } from '@app/shared/entities/rd/camera/models';
import { Constants } from '@app/shared/helpers';
import { GetCamerasPage } from '@app/shared/store/actions/cameras.actions';
import {
  camerasPageStateLoading,
  camerasPageStateSuccess
} from '@app/shared/store/states/cameras/get-cameras-page.state';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper/dialog-wrapper-data.model';
import { State } from '@app/store/reducers';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { VideoType } from '@app/shared/containers/camera/camera-thumbnail/models/videoType';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'rdea-cameras-page',
  templateUrl: './cameras-page.component.html',
  styleUrls: ['./cameras-page.component.scss']
})
export class CamerasPageComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  pageSize: number;
  filterInput: UntypedFormControl;
  videoMode: VideoType;
  cameras$: Observable<{ dataSource: Camera[], totalCount: number }>;

  public loading = true;

  private onDestroy$: Subject<void>;

  constructor(
    private resolutionService: ResolutionService,
    private store: Store<State>,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.videoMode = VideoType.basic;
  }

  ngOnInit() {
    this.calculatePageSize();
    this.initLoading();
    this.initFilterArea();
    this.initStore();
    this.initPaginator();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  loadCameras(page: number = 0) {
    this.loading = true;
    this.store.dispatch(new GetCamerasPage(page, this.pageSize, 'createdAt,desc', this.filterInput.value));
  }

  isBitrateError(bitrate: number): number {
    if (!bitrate) {
      return 0;
    }
    return bitrate > 1100 ? 1 : 0;
  }

  isLatencyError(segmentsCount: number, segmentLength: number): number {
    const latency: [number, number] = this.calcLatency(segmentsCount, segmentLength);

    if (latency === null) {
      return 0;
    } else if (latency[0] >= 3 && latency[0] < 10) {
      return 1;
    } else if (latency[0] >= 10) {
      return 2;
    }
    return 0;
  }

  getLatencyRange(segmentsCount: number, segmentLength: number): string {
    const latencyRange: [number, number] = this.calcLatency(segmentsCount, segmentLength);
    if (!latencyRange) {
      return null;
    }
    return `${latencyRange[0]} - ${latencyRange[1]}`;
  }

  onInfo(camera: Camera) {
    const data: DialogWrapperData<Camera, null> = {
      title: this.translate.instant('cameras.page.info.title'),
      componentName: 'InfoCameraPage',
      body: camera
    };

    this.dialog.open(CameraInfoPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  sortData(event: { active: string, direction: string }): void {
    if (event.direction === '') {
      this.loadCameras();
    } else {
      this.loadCameras(0);
    }
  }

  isMobile(): boolean {
    return this.resolutionService.isMobile;
  }

  private calculatePageSize() {
    const camerasFieldWidth = window.innerWidth - 240;
    const camerasWidthCount = Math.floor(camerasFieldWidth / 260);

    const camerasFieldHeight = window.innerHeight - 100.313 - 60.16;
    const camerasHeightCount = Math.floor(camerasFieldHeight / 260) + 1;

    const calculatedPageSize = camerasWidthCount * camerasHeightCount;
    this.pageSize = calculatedPageSize > Constants.PAGE_SIZE ? calculatedPageSize : Constants.PAGE_SIZE;
  }

  private calcLatency(segmentsCount: number, segmentLength: number): [number, number] {
    if (!segmentsCount || !segmentLength) {
      return null;
    }

    const latencyNumStr: string = (segmentsCount * segmentLength).toFixed(2);
    const latencyMaxStr: string = ((segmentsCount + 1) * segmentLength).toFixed(2);

    return [Number.parseFloat(latencyNumStr), Number.parseFloat(latencyMaxStr)];
  }

  private initLoading() {
    this.store.select(camerasPageStateLoading)
      .subscribe((state: boolean) => this.loading = state);
  }

  private initFilterArea() {
    this.filterInput = new UntypedFormControl();
    this.filterInput.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.paginator.pageIndex = 0;
        this.loadCameras(this.paginator.pageIndex);
      });
  }

  private initPaginator() {
    this.paginator.page.subscribe((e: PageEvent) => this.loadCameras(e.pageIndex));
  }

  private initStore() {
    this.onDestroy$ = new Subject();
    this.cameras$ = this.store.pipe(select(camerasPageStateSuccess), map(resp => ({ dataSource: resp.cameras, totalCount: resp.totalCount })));
    this.loadCameras(0);
  }
}
