<div class="entrance-warnings-block" *ngIf="onShowPanel()">
  <div class="info-block">
    <h1>
      {{ 'services.warnings.rda.title' | translate }}
    </h1>
    <div class="descr-block">
      <p>
        {{ 'services.warnings.rda.message.failed_get_following_information_about_adapter' | translate }}:
      </p>
      <div class="params-block">
        <p *ngFor="let param of unavaliableParams" class="bold-text">{{param}}</p>
      </div>
      <p class="italic-text">
        {{ 'services.warnings.rda.message.try_update_all_available_components_adapter' | translate }}
        <span class="bold-text">
          ({{ 'services.warnings.rda.message.pay_attention_to_rdaa_update_first_place' | translate }})
        </span>
      </p>
    </div>
    <div class="ref-block">
    </div>
  </div>
  <div class="img-block">
    <img src="../../../../../assets/images/entrance-error.png">
  </div>
</div>
