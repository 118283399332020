import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter',
    pure: false
})
export class FilterPipe implements PipeTransform {
    transform(items: any[], filterFunc: (item: any, param: any) => boolean, funcParam: any): any {
        if (!items || !filterFunc) {
            return items;
        }

        return items.filter((e) => filterFunc(e, funcParam));
    }
}
