import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { SnackbarService } from '@app/shared/components/snackbar';
import { CompanySignUpRequest } from '@app/shared/entities/rd/company/models';
import { CompanyApiService } from '@app/shared/entities/rd/company/services';
import { parseError } from '@app/shared/helpers';
import { LoaderService } from '@app/shared/entities/common/loader';
import { from, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-company-sign-up-request-page',
  templateUrl: './company-sign-up-request-page.component.html',
  styleUrls: ['./company-sign-up-request-page.component.scss']
})
export class CompanySignUpRequestPageComponent implements OnDestroy {
  private onDestroy$: Subject<void> = new Subject();

  constructor(
    private loader: LoaderService,
    private snackbar: SnackbarService,
    private companyApiService: CompanyApiService,
    private translate: TranslateService
  ) { }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSubmit(event: { request: CompanySignUpRequest }) {
    this.loader.loaderState = { state: true };

    from(this.companyApiService.createCompanySignUp(event.request))
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (response) => {
          this.loader.loaderState = { state: false };
          this.snackbar.showMessage(
            this.translate.instant('company.signup.request.page.message.submit.success'), 'success'
          );
        },
        (error: HttpErrorResponse) => {
          this.loader.loaderState = { state: false };
          this.snackbar.showMessage(parseError(error));
        }
      );
  }
}
