import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { PagedResponse } from '@app/shared/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Shield } from '../models';
import { ShieldTests } from '../models/shield/shield-tests.model';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class ShieldService extends ApiService {
  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1']);
  }

  getShieldsByBatchNumber(companyId: Number, batchId, isInstalled: Boolean): Observable<Shield[]> {
    const params = {} as any;
    if (batchId !== null) { params.batchId = batchId; }
    if (isInstalled !== null) { params.isInstalled = isInstalled; }
    if (companyId !== null) { params.companyId = companyId; }

    return this.get('/shields', 1, { params });
  }

  getAllShields(page: number, size: number, search?: string): Observable<PagedResponse<Shield>> {
    const params = { page: page, size: size } as any;
    if (search) { params.search = search; }

    return this.get(`/shields`, 1, { params });
  }

  updateShield(payload: Shield): Observable<void> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.patch(`/shields/${payload.id}`, 1, payload, { headers });
  }

  getShieldTests(shieldId: number): Observable<ShieldTests[]> {
    return this.get(`/shields/${shieldId}/tests`, 1);
  }
}
