import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EditPopupComponent } from '@app/shared/templates';
import { RdaResponse, RdaUtilsService } from '@app/shared/entities/rd/rda';
import { Constants } from '@app/shared/helpers';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-service-rda-warning',
  templateUrl: './service-rda-warning.component.html',
  styleUrls: ['./service-rda-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRdaWarningComponent implements OnInit {
  readonly rdaParams: Object = {
    'versionInfoOs': '',
    'versionInfoRdaa': '',
    'versionInfoStm': '',
    'versionInfoStmDriver': '',
    'versionInfoShield': ''
  };

  unavaliableParams: string[] = [];

  @Input() private componentName!: string;
  @Input() private set rdas(rdas: RdaResponse[]) {
    this._rdas = rdas;
    this.checkRdaParams();
  }
  @Input() private serviceName!: string;
  private _rdas: RdaResponse[];

  constructor(
    private dialog: MatDialog,
    private rdaService: RdaUtilsService,
    private resolution: ResolutionService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.translate.get([
      'service.rda.warning.rdos',
      'service.rda.warning.rdaa',
      'service.rda.warning.stm',
      'service.rda.warning.stm_driver',
      'service.rda.warning.shield'
    ]).subscribe((res: string) => {
      this.rdaParams['versionInfoOs'] = res['service.rda.warning.rdos'];
      this.rdaParams['versionInfoRdaa'] = res['service.rda.warning.rdaa'];
      this.rdaParams['versionInfoStm'] = res['service.rda.warning.stm'];
      this.rdaParams['versionInfoStmDriver'] = res['service.rda.warning.stm_driver'];
      this.rdaParams['versionInfoShield'] = res['service.rda.warning.shield'];
    });
  }

  onShowPanel(): boolean {
    if (!this.rdas?.length || !this.rdas[0]?.intercomType?.id) {
      return false;
    }

    return this.rdas[0].active &&
      this.rdaService.checkAdapterErrors(this.rdas[0]) &&
      this.rdaService.ipType(this.rdas[0].intercomType);
  }

  private get rdas(): RdaResponse[] {
    return this._rdas;
  }

  private checkRdaParams() {
    if (!this.rdas?.length) {
      return;
    }

    for (const param in this.rdaParams) {
      if (!this.rdas[0][param]) {
        if (
          param === 'versionInfoStmDriver' &&
          !this.rdaService.stmDriverAvaliable(this.rdas[0].versionInfoRdaa)
        ) {
          continue;
        }
        this.unavaliableParams.push(this.rdaParams[param]);
      }
    }
  }
}
