<div class="company-register-page">
    <div class="company-register-page-wrapper">
        <img class="company-register-page-wrapper__logo"
            src="../../../assets/logo/logo-main.png"
            [routerLink]="'/login'"
        >
        <app-company-register-form class="company-register-page-wrapper-content"
            (submitted)="onSubmit($event)"
        ></app-company-register-form>
    </div>
</div>