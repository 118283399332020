<div class="payments-wizard-registration">
  <h1 class="tpl-instruction__title">
    {{ 'payments.popups.wizard.registration.title' | translate }}
  </h1>

  <a class="link tpl-instruction__link" (click)="onSkipStep()">
    {{ 'payments.popups.wizard.registration.link.skip_step' | translate }}
  </a>

  <iframe
    *ngIf="isShowYoutubeLink"
    [src]="youtubeLink | safeUrl"
    height="290px"
    width="100%"
    title="{{ 'payments.popups.wizard.registration.iframe.title' | translate }}"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
  ></iframe>

  <ng-container *ngIf="selectedCheckpoint <= checklistStep.CONCLUSION_AGREEMENT && !step.complete">
    <ng-container *ngIf="service === serviceType.YOOKASSA">
      <ng-container *ngTemplateOutlet="instructionYookassa"></ng-container>
    </ng-container>
    <ng-container *ngIf="service === serviceType.ROBOKASSA">
      <ng-container *ngTemplateOutlet="instructionRobokassa"></ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="selectedCheckpoint > checklistStep.CONCLUSION_AGREEMENT || step.complete">
    <ng-container *ngTemplateOutlet="sendAgreementSuccess"></ng-container>
  </ng-container>
</div>

<ng-template #instructionYookassa>
  <div class="tpl-checklist">
    <h3 class="tpl-checklist__title">
      {{ 'payments.popups.wizard.registration.message.check_all_points' | translate }}
    </h3>

    <app-payments-wizard-registration-checkpoint
      [number]="1"
      [current]="selectedCheckpoint"
      [target]="checklistStep.COMPANY_REGISTRATION"
    >
      <h4>
        {{ 'payments.popups.wizard.registration.message.register' | translate }}
      </h4>

      <ng-container *ngIf="selectedCheckpoint === checklistStep.COMPANY_REGISTRATION">
        <p>
          {{ 'payments.popups.wizard.registration.message.check_out_instructions_for_connecting_payments' | translate }}
        </p>

        <div class="tpl-checklist-item-content-actions">
          <button
            mat-raised-button
            color="primary"
            (click)="onNextCheckpoint()"
          >
            {{ 'payments.popups.wizard.registration.button.next_checkpoint' | translate }}
          </button>
        </div>
      </ng-container>
    </app-payments-wizard-registration-checkpoint>

    <app-payments-wizard-registration-checkpoint
      [number]="2"
      [current]="selectedCheckpoint"
      [target]="checklistStep.CONFIG_CASHBOX"
    >
      <h4>
        {{ 'payments.popups.wizard.registration.message.cashier_setting_up' | translate }}
      </h4>

      <ng-container *ngIf="selectedCheckpoint === checklistStep.CONFIG_CASHBOX">
        <p>
          {{ 'payments.popups.wizard.registration.message.to_configure_payment_acceptance_indicate_following_options' | translate }}:
        </p>

        <ul class="tpl-checklist-item-content-list">
          <li [innerHTML]="'payments.popups.wizard.registration.message.reception_of_payments_without_site' | translate"></li>
          <li [innerHTML]="'payments.popups.wizard.registration.message.through_mobile_application' | translate "></li>
          <li [innerHTML]="'payments.popups.wizard.registration.message.payment_for_smart_intercom_services' | translate"></li>
          <li class="tpl-checklist-item-content-list__link">
            {{ 'payments.popups.wizard.registration.message.link_to_application' | translate }}&nbsp;
            <strong>{{ mobileApplicationLink }}</strong>
          </li>
        </ul>

        <img
          class="tpl-checklist-item-content__image"
          src="assets/images/yookassa/example.png"
          alt="Yookassa"
        >

        <div class="tpl-checklist-item-content-actions">
          <button
            mat-raised-button
            color="primary"
            (click)="onNextCheckpoint()"
          >
            {{ 'payments.popups.wizard.registration.button.next_checkpoint' | translate }}
          </button>
        </div>
      </ng-container>
    </app-payments-wizard-registration-checkpoint>

    <app-payments-wizard-registration-checkpoint
      [number]="3"
      [current]="selectedCheckpoint"
      [target]="checklistStep.CONCLUSION_AGREEMENT"
    >
      <h4>
        {{ 'payments.popups.wizard.registration.message.conclusion_of_contract' | translate }}
      </h4>

      <ng-container *ngIf="selectedCheckpoint === checklistStep.CONCLUSION_AGREEMENT">
        <p>
          {{ 'payments.popups.wizard.registration.message.conclude_an_agreement_for_this' | translate }}:
        </p>

        <ul class="tpl-checklist-item-content-list">
          <li>
            {{ 'payments.popups.wizard.registration.message.fill_out_details' | translate }}
          </li>
          <li>
            {{ 'payments.popups.wizard.registration.message.data_on_leader' | translate }}
          </li>
          <li>
            {{ 'payments.popups.wizard.registration.message.additional_information' | translate }}
          </li>
        </ul>

        <p>
          {{ 'payments.popups.wizard.registration.message.submit_contract_for_verification' | translate }}
        </p>

        <div class="tpl-checklist-item-content-actions">
          <button
            mat-raised-button
            color="primary"
            (click)="onNextCheckpoint()"
          >
            {{ 'payments.popups.wizard.registration.button.next_checkpoint' | translate }}
          </button>
        </div>
      </ng-container>
    </app-payments-wizard-registration-checkpoint>

  </div>
</ng-template>

<ng-template #instructionRobokassa>
  <div class="tpl-checklist">
    <h3 class="tpl-checklist__title">
      {{ 'payments.popups.wizard.registration.message.check_all_points' | translate }}
    </h3>

    <app-payments-wizard-registration-checkpoint
      [number]="1"
      [current]="selectedCheckpoint"
      [target]="checklistStep.ENTITY"
    >
      <ng-container *ngIf="selectedCheckpoint === checklistStep.ENTITY; else entityElse">
        <h4>
          {{ 'payments.popups.wizard.registration.message.robokassa.entity.title' | translate }}
        </h4>

        <div class="tpl-checklist-item-content-actions">
          <button
            mat-raised-button
            color="primary"
            (click)="onEntityType(entityType.INDIVIDUAL)"
          >
            {{ 'payments.popups.wizard.registration.button.entity.individual' | translate }}
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="onEntityType(entityType.LEGAL)"
          >
            {{ 'payments.popups.wizard.registration.button.entity.legal' | translate }}
          </button>
        </div>
      </ng-container>
      <ng-template #entityElse>
        <h4>
          {{
            isEntityLegal
              ? ('payments.popups.wizard.registration.enum.entity.legal' | translate)
              : ('payments.popups.wizard.registration.enum.entity.individual' | translate )
          }}
        </h4>
      </ng-template>
    </app-payments-wizard-registration-checkpoint>

    <app-payments-wizard-registration-checkpoint
      [number]="2"
      [current]="selectedCheckpoint"
      [target]="checklistStep.COMPANY_REGISTRATION"
    >
      <h4>
        {{ 'payments.popups.wizard.registration.message.robokassa.register.title' | translate }}
      </h4>

      <ng-container *ngIf="selectedCheckpoint === checklistStep.COMPANY_REGISTRATION">
        <p>
          {{
            isEntityLegal
              ? ('payments.popups.wizard.registration.message.robokassa.register.message.legal' | translate)
              : ('payments.popups.wizard.registration.message.robokassa.register.message.individual' | translate)
          }}
        </p>

        <div class="tpl-checklist-item-content-actions">
          <button
            mat-raised-button
            color="primary"
            (click)="onNextCheckpoint()"
          >
            {{ 'payments.popups.wizard.registration.button.next_checkpoint' | translate }}
          </button>
        </div>
      </ng-container>

    </app-payments-wizard-registration-checkpoint>

    <app-payments-wizard-registration-checkpoint
      [number]="3"
      [current]="selectedCheckpoint"
      [target]="checklistStep.CONFIG_CASHBOX"
    >
      <h4>
        {{ 'payments.popups.wizard.registration.message.robokassa.config.title' | translate }}
      </h4>

      <ng-container *ngIf="selectedCheckpoint === checklistStep.CONFIG_CASHBOX">
        <p>
          {{ 'payments.popups.wizard.registration.message.robokassa.config.message' | translate }}
        </p>

        <div class="tpl-checklist-item-content-actions">
          <button
            mat-raised-button
            color="primary"
            (click)="onNextCheckpoint()"
          >
            {{ 'payments.popups.wizard.registration.button.next_checkpoint' | translate }}
          </button>
        </div>
      </ng-container>

    </app-payments-wizard-registration-checkpoint>

    <app-payments-wizard-registration-checkpoint
      [number]="4"
      [current]="selectedCheckpoint"
      [target]="checklistStep.CONCLUSION_AGREEMENT"
    >
      <h4>
        {{ 'payments.popups.wizard.registration.message.robokassa.conclusion.title' | translate }}
      </h4>

      <ng-container *ngIf="selectedCheckpoint === checklistStep.CONCLUSION_AGREEMENT">
        <p>
          {{ 'payments.popups.wizard.registration.message.robokassa.conclusion.message' | translate }}
        </p>

        <div class="tpl-checklist-item-content-actions">
          <button
            mat-raised-button
            color="primary"
            (click)="onNextCheckpoint()"
          >
            {{ 'payments.popups.wizard.registration.button.next_checkpoint' | translate }}
          </button>
        </div>
      </ng-container>

    </app-payments-wizard-registration-checkpoint>

  </div>
</ng-template>

<ng-template #sendAgreementSuccess>
  <app-info-block>
    <img
      width="50px"
      info-block-image
      [src]="resourcesHelper.getResource(resourcePath.CHECK_LG) | safeUrl"
      [srcset]="resourcesHelper.getResource(resourcePath.CHECK_LG, true) | safeUrl"
    >
    <ng-container info-block-text>
      {{ 'payments.popups.wizard.registration.message.did_you_transfer_agreement_for_the_audit' | translate }}
      <br />
      {{ 'payments.popups.wizard.registration.message.when_check_is_completed_you_can_proceed_to_integration' | translate }}
    </ng-container>
  </app-info-block>
</ng-template>

<ng-template #completeStepNumber>
  <mat-icon class="tpl-complete-step">
    done
  </mat-icon>
</ng-template>
