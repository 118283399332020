import { HttpErrorResponse } from '@angular/common/http';
import { Camera } from '@app/shared/entities/rd/camera/models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  GetCamerasPage = '[Shared] GetCamerasPage',
  GetCamerasPageSuccess = '[Shared] GetCamerasPage Success',
  GetCamerasPageFailure = '[Shared] GetCamerasPage Failure',

  AddCamera = '[Shared] AddCamera',
  AddCameraSuccess = '[Shared] AddCamera Success',
  AddCameraFailure = '[Shared] AddCamera Failure',

  UpdateCamera = '[Shared] UpdateCamera',
  UpdateCameraSuccess = '[Shared] UpdateCamera Success',
  UpdateCameraFailure = '[Shared] UpdateCamera Failure',
  UpdateCameraClear = '[Shared] UpdateCamera Clear',

  DeleteCamera = '[Shared] DeleteCamera',
  DeleteCameraSuccess = '[Shared] DeleteCameraSuccess Success',
  DeleteCameraFailure = '[Shared] DeleteCameraFailure Failure',
  DeleteCameraClear = '[Shared] DeleteCamera Clear',

  SetCameraAsDefault = '[Shared] SetCameraAsDefault',
  SetCameraAsDefaultSuccess = '[Shared] SetCameraAsDefault Success',
  SetCameraAsDefaultFailure = '[Shared] SetCameraAsDefault Failure',

  DisconnectDefaultCamera = '[Shared] DisconnectDefaultCamera',
  DisconnectDefaultCameraSuccess = '[Shared] DisconnectDefaultCamera Success',
  DisconnectDefaultCameraFailure = '[Shared] DisconnectDefaultCamera Failure',
}

export class GetCamerasPage implements Action {
  readonly type = ActionTypes.GetCamerasPage;

  constructor(public page: number = 0, public size: number = 10, public sort: string = 'createdAt,desc', public search?: string, public filterBy?: number[]) { }
}

export class GetCamerasPageSuccess implements Action {
  readonly type = ActionTypes.GetCamerasPageSuccess;

  constructor(public payload: Camera[], public totalCount: number) { }
}

export class GetCamerasPageFailure implements Action {
  readonly type = ActionTypes.GetCamerasPageFailure;

  constructor(public payload: string) { }
}

export class AddCamera implements Action {
  readonly type = ActionTypes.AddCamera;

  constructor(public camera: Camera) { }
}

export class AddCameraSuccess implements Action {
  readonly type = ActionTypes.AddCameraSuccess;

  constructor(public camera: Camera) { }
}

export class AddCameraFailure implements Action {
  readonly type = ActionTypes.AddCameraFailure;

  constructor(public error: HttpErrorResponse) { }
}

export class UpdateCamera implements Action {
  readonly type = ActionTypes.UpdateCamera;

  constructor(public payload: Camera) { }
}
export class UpdateCameraSuccess implements Action {
  readonly type = ActionTypes.UpdateCameraSuccess;

  constructor() { }
}
export class UpdateCameraFailure implements Action {
  readonly type = ActionTypes.UpdateCameraFailure;

  constructor(public error: HttpErrorResponse) { }
}
export class UpdateCameraClear implements Action {
  readonly type = ActionTypes.UpdateCameraClear;

  constructor() { }
}

export class DeleteCamera implements Action {
  readonly type = ActionTypes.DeleteCamera;

  constructor(public id: number) { }
}

export class DeleteCameraSuccess implements Action {
  readonly type = ActionTypes.DeleteCameraSuccess;

  constructor() { }
}

export class DeleteCameraFailure implements Action {
  readonly type = ActionTypes.DeleteCameraFailure;

  constructor(public payload: string) { }
}

export class DeleteCameraClear implements Action {
  readonly type = ActionTypes.DeleteCameraClear;

  constructor() { }
}

export class SetCameraAsDefault implements Action {
  readonly type = ActionTypes.SetCameraAsDefault;

  constructor(public serviceId: number, public cameraId: number) { }
}

export class SetCameraAsDefaultSuccess implements Action {
  readonly type = ActionTypes.SetCameraAsDefaultSuccess;

  constructor(public serviceId: number, public cameraId: number) { }
}

export class SetCameraAsDefaultFailure implements Action {
  readonly type = ActionTypes.SetCameraAsDefaultFailure;

  constructor(public error: HttpErrorResponse) { }
}

export class DisconnectDefaultCamera implements Action {
  readonly type = ActionTypes.DisconnectDefaultCamera;

  constructor(public cameraId: number, public serviceId: number) { }
}

export class DisconnectDefaultCameraSuccess implements Action {
  readonly type = ActionTypes.DisconnectDefaultCameraSuccess;

  constructor(public cameraId: number, public serviceId: number) { }
}

export class DisconnectDefaultCameraFailure implements Action {
  readonly type = ActionTypes.DisconnectDefaultCameraFailure;

  constructor(public error: HttpErrorResponse) { }
}

export type Actions =
  | GetCamerasPage
  | GetCamerasPageSuccess
  | GetCamerasPageFailure
  | AddCamera
  | AddCameraSuccess
  | AddCameraFailure
  | UpdateCamera
  | UpdateCameraSuccess
  | UpdateCameraFailure
  | UpdateCameraClear
  | DeleteCamera
  | DeleteCameraSuccess
  | DeleteCameraFailure
  | DeleteCameraClear
  | SetCameraAsDefault
  | SetCameraAsDefaultSuccess
  | SetCameraAsDefaultFailure
  | DisconnectDefaultCamera
  | DisconnectDefaultCameraSuccess
  | DisconnectDefaultCameraFailure;
