<app-dialog-wrapper
  [componentName]="data.componentName"
  [progress]="(popupState$ | async) === 'loading'"
  [title]="data.title"
  [size]="DialogWrapperSize.SM"
  [hasBackButton]="cameraListVisible || readyCameraFormState"
  (back)="getToPrevious()"
>
  <ng-container *ngIf="!readyCameraFormState && !isLoading">
    <app-info-block class="find-cameras__info" *ngIf="!cameraListVisible">
      <article class="find-cameras__info-text" info-block-text>
        {{ 'services.mutual.find.cameras.popup.message.a_search_for_cameras_connected_to_adapter' | translate }}
      </article>
      <article class="find-cameras__info-text" info-block-content>
        {{ 'services.mutual.find.cameras.popup.message.if_dhcp_mode_is_not_turned_on_adapter' | translate }}
        <br>{{ 'services.mutual.find.cameras.popup.message.activation_can_take_several_minutes' | translate }}
        <br>{{ 'services.mutual.find.cameras.popup.message.best_of_all_it_passes_under_tea_or_coffee' | translate }}
      </article>
    </app-info-block>
    <app-info-block class="find-cameras__info" *ngIf="cameraListVisible">
      <article class="find-cameras__info-text" info-block-text>
        {{ 'services.mutual.find.cameras.popup.message.if_camera_has_connect_button' | translate }}
      </article>
      <article class="find-cameras__info-text" info-block-content>
        {{ 'services.mutual.find.cameras.popup.message.camera_is_connected_automatically' | translate }}
        <br><br>
        <em>
          {{ 'services.mutual.find.cameras.popup.message.if_camera_does_not_have_factory_settings' | translate }}
        </em>
        <br><br>
        {{ 'services.mutual.find.cameras.popup.message.set_button_at_camera_means' | translate }}:
        <ol class="find-cameras__info-list">
          <li class="find-cameras__info-list-item">
            {{ 'services.mutual.find.cameras.popup.message.camera_does_not_support_automatic_connection' | translate }}
          </li>
          <li class="find-cameras__info-list-item">
            {{ 'services.mutual.find.cameras.popup.message.camera_is_not_included_in_the_list_of_supported_equipment' | translate }}
          </li>
        </ol>
        <br><br>
        {{ 'services.mutual.find.cameras.popup.message.contact_technical_support_with_detailed_information_about_camera' | translate }}
      </article>
    </app-info-block>
  </ng-container>

  <div class="find-cameras" *ngIf="!cameraListVisible && !readyCameraFormState && !isLoading">
    <div class="find-cameras__adapter-keeper">
      <app-rda-search-select
        [initialIntercoms]="data.body.rdas"
        [initialIntercomSearchSelect]="initialSelectedRda"
        [params]="{ ipType: false, inactive: null }"
        [disabled]="false"
        (rdaSelected)="rdaSelected($event)"
      ></app-rda-search-select>

      <span class="find-cameras__adapter-subtext">
        {{ 'services.mutual.find.cameras.popup.message.select_adapter_through_which_the_chamber_traffic_transmitted' | translate }}
      </span>
    </div>
    <button
      mat-raised-button
      color="primary"
      class="find-cameras__apply-adapter"
      [disabled]="!selectedRda"
      (click)="enableDHCPForSelectedAdapter()"
    >
      {{ 'services.mutual.find.cameras.popup.button.search' | translate }}
    </button>
  </div>
  <ng-container *ngIf="cameraListVisible && !isLoading">
    <div class="find-cameras__list-keeper" *ngIf="freeLeasesList && freeLeasesList.length > 0">
      <h4 class="find-cameras__list-title">
        {{ 'services.mutual.find.cameras.popup.message.free_cameras' | translate }}
      </h4>
      <div class="find-cameras__list">
        <div class="find-cameras__item" *ngFor="let lease of freeLeasesList">
          <div class="find-cameras__camera-info">
            <span>
              {{
                lease.model
                  ? lease.model.name
                  : ('services.mutual.find.cameras.popup.mac_address' | translate) + ': ' + lease.macAddress
              }}
            </span>
            <mat-icon *ngIf="lease.ready" svgIcon="checked"></mat-icon>
          </div>
          <div class="find-camera__camera-action">
            <button
              *ngIf="lease.ready"
              mat-raised-button
              color="primary"
              (click)="connectReadyCamera(lease)"
            >
              {{ 'services.mutual.find.cameras.popup.button.connect' | translate }}
            </button>
              <button
                *ngIf="!lease.ready"
                mat-raised-button
                color="primary"
                (click)="tuneAndConnectCamera(lease)"
              >
                {{ 'services.mutual.find.cameras.popup.button.tune' | translate }}
              </button>
          </div>
        </div>
      </div>
    </div>
    <div class="find-cameras__list-keeper" *ngIf="busyLeasesList && busyLeasesList.length > 0">
      <h4 class="find-cameras__list-title">
        {{ 'services.mutual.find.cameras.popup.message.busy_cameras' | translate }}
      </h4>
      <div class="find-cameras__list">
        <div class="find-cameras__item" *ngFor="let lease of busyLeasesList">
          <div class="find-cameras__camera-info">
            <span>ID {{lease.id}}</span>
            <mat-icon svgIcon="checked"></mat-icon>
          </div>
          <div class="find-cameras__camera-service" *ngIf="lease.service">
            {{ 'services.mutual.find.cameras.popup.message.camera_is_already_connected_to_service' | translate }}:&nbsp;
            {{ lease.service.customName ? lease.service.customName : lease.service.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="find-cameras__list-keeper find-cameras__list-keeper_empty" *ngIf="freeLeasesList?.length === 0 && busyLeasesList?.length === 0">
      <h4 class="find-cameras__list-title find-cameras__list-title_empty">
        {{ 'services.mutual.find.cameras.popup.message.cameras_not_found' | translate }}
      </h4>
    </div>
  </ng-container>
  <div class="find-cameras__loading" *ngIf="isLoading">
    <mat-spinner [diameter]="loadingSpinnerDiameter"></mat-spinner>
    <pre class="find-cameras__loading-log" [innerHTML]="latestLog?.message"></pre>
  </div>
  <div class="find-cameras__form" *ngIf="readyCameraFormState">
    <h4>
      {{ 'services.mutual.find.cameras.popup.message.indicate_camera_parameters' | translate }}
    </h4>
    <mat-form-field appearance="outline" class="none-padding">
      <mat-label>
        {{ 'services.mutual.find.cameras.popup.field.address' | translate }}
      </mat-label>
      <mat-select [required]="true" (selectionChange)="onSelectAddress($event.value)">
        <mat-option *ngFor="let address of addresses" [value]="address.entrance.id">
          {{ address | address }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" class="none-padding">
      <mat-label>
        {{ 'services.mutual.find.cameras.popup.field.location' | translate }}
      </mat-label>
      <mat-select
        disableOptionCentering
        panelClass="mat-select-custom"
        (selectionChange)="onSelectLocation($event.value)"
      >
        <mat-option *ngFor="let location of locations" [value]="location.id">
          {{ location.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <button
      mat-raised-button
      color="primary"
      class="find-cameras__form-submit"
      (click)="submitCameraData()"
      [disabled]="!selectedEntranceId"
    >
      {{ 'services.mutual.find.cameras.popup.button.connect' | translate }}
    </button>
  </div>
</app-dialog-wrapper>

