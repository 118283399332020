<mat-card class="card-container contacts">
  <div class="header">
    <div class="title-block">
      {{ 'company.about.page.contacts.title' | translate }}
    </div>
    <button
      *appSomeRoleContains="[userRoleType.ROLE_ENGINEER]"
      mat-button
      (click)="onToggleEdit()"
    >
      <mat-icon color="primary">edit</mat-icon>
    </button>
  </div>
  <mat-card-content>
    <div *ngIf="mails" class="mail-list">
      <ng-container *ngFor="let mail of mailInputs">
        <span>{{ mail }}</span>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
