import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpStatusCode, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageGeneralKey, LocalStorageHelper } from '@app/shared/entities/common';
import { Logout } from '@app/views/auth/store/auth.actions';
import { AuthModuleState } from '@app/views/auth/store/auth.state';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const allowAnonymous = /oauth\/token$/i;

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private store: Store<AuthModuleState>
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (allowAnonymous.test(req.url)) {
      return next.handle(req);
    }

    const headers = {};
    const token: string = LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN);

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const request = req.clone({ setHeaders: headers });

    return next.handle(request).pipe(
      catchError(error => {
        switch (error.status) {
          case HttpStatusCode.Unauthorized:
            this.store.dispatch(new Logout(this.router.url));
            return EMPTY;
          default:
            return throwError(error);
        }
      })
    );
  }
}

export let httpTokenProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: HttpTokenInterceptor,
  multi: true
};
