import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { IpIntercomKeysRequest, KeysResponse, RdaKeysRequest } from '@app/shared/entities/rd/keys/models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class KeysApiService extends ApiService {
  public readonly defaultKeyLocationId: number = 1;
  public readonly defaultKeyType: number = 1;

    constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
        super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1']);
    }

    public getKeys(rdaUid: string): Observable<KeysResponse[]> {
        return this.get(`/rdas/${rdaUid}/keys`, 1);
    }

    public createKey(rdaUid: string, request: RdaKeysRequest | IpIntercomKeysRequest, connectToAbonents: boolean = true): Observable<KeysResponse> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        let params = new HttpParams();
        if (connectToAbonents) {
            params = params.set('connect_to_abonents', 'true');
        }

        return this.post(`/rdas/${rdaUid}/keys`, 1, request, { headers, params });
    }

    public updateKey(keyId: number, device: RdaKeysRequest | IpIntercomKeysRequest): Observable<void> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.patch(`/keys/${keyId}`, 1, device, { headers });
    }

    public deleteKey(keyId: number): Observable<void> {
        return this.delete(`/keys/${keyId}`, 1);
    }
}
