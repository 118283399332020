import { Address, AddressInfo, CityResponse, EntranceResponse, HouseResponse, StreetResponse } from '@app/shared/models';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddressFormatter {
  constructor(
    private translate: TranslateService
  ) {}

  static isAddressEmpty(addrObj: Object) {
    for (const key in addrObj) {
      if (addrObj[key] instanceof Object) {
        if (this.isAddressEmpty(addrObj[key]) === false) {
          return false;
        }
      } else {
        if (addrObj[key] instanceof Array && addrObj[key].length !== 0) {
          return false;
        } else if (addrObj[key] !== null) {
          return false;
        }
      }
    }
    return true;
  }

  static convertAddressToAddressInfo(address: Address): AddressInfo {
    if (AddressFormatter.isAddressEmpty(address)) {
      return null;
    }
    return {
      country: address.country,
      city: address.city,
      street: address.street.name,
      house: address.house.number,
      building: address.house.building ?? null,
      housing: address.house.housing ?? null,
      block: address.house.block ?? null,
      fiasCode: address.street.codeFias ?? null,
      kladrCode: address.street.codeKladr ?? null,
      universalCode: address.street.universalCode ?? null
    };
  }

  formatAddress(address: Address): string {
    const tmp: string[] = [];

    if (!AddressFormatter.isAddressEmpty(address)) {
      tmp.push(
        this.part('city', address.city) +
        this.part('street', address.street.name, ', ')
      );

      if (address.house && address.house.number) {
        tmp.push(this.part('house.number', address.house.number, ' '));
      }
      if (address.house && address.house.building) {
        tmp.push(this.part('house.building', address.house.building, ' '));
      }
      if (address.house && address.house.housing) {
        tmp.push(this.part('house.housing', address.house.housing, ' '));
      }
      if (address.house && address.house.block) {
        tmp.push(this.part('house.block', address.house.block, ' '));
      }
      if (address.entrance && address.entrance.number) {
        tmp.push(this.part('entrance.number', address.entrance.number, ', '));
      }
    }
    return tmp.join('');
  }

  formatAddressInfo(address: AddressInfo): string {
    const tmp: string[] = [];

    if (!this.isAddressInfoEmpty(address)) {
      if (address.city) {
        tmp.push(this.part('city', address.city));
      }
      if (address.street) {
        tmp.push(this.part('street', address.street, ', '));
      }
      if (address.house) {
        tmp.push(this.part('house.number', address.house, ', '));
      }
      if (address.building) {
        tmp.push(this.part('house.building', address.building, ' '));
      }
      if (address.housing) {
        tmp.push(this.part('house.housing', address.housing, ' '));
      }
      if (address.block) {
        tmp.push(this.part('house.block', address.block, ' '));
      }
    }
    return tmp.join('');
  }

  formatCityResponse(city: CityResponse): string {
    return (!city) ? '' : this.part('city', city.name);
  }

  formatStreetResponse(street: StreetResponse): string {
    if (!street) {
      return '';
    }
    return (!street) ? '' : this.part('street', street.name, ', ');
  }

  formatHouseResponse(house: HouseResponse): string {
    const tmp: string[] = [];

    if (!house) {
      return '';
    }

    if (house.number) {
      tmp.push(this.part('house.number', house.number, ', '));
    }
    if (house.building) {
      tmp.push(this.part('house.building', house.building, ' '));
    }
    if (house.housing) {
      tmp.push(this.part('house.housing', house.housing, ' '));
    }
    if (house.block) {
      tmp.push(this.part('house.block', house.block, ' '));
    }
    return tmp.join('');
  }

  formatEntranceResponse(entrance: EntranceResponse): string {
    return (!entrance) ? '' : this.part('entrance.number', entrance.number, ', ');
  }

  isAddressInfoEmpty(addressInfo: AddressInfo): boolean {
    return !addressInfo;
  }

  private part(key: string, value: string, prefix: string = null): string {
    const tmp: string[] = [];

    if (prefix !== null) {
      tmp.push(prefix);
    }

    tmp.push(this.translate.instant('shared.services.address.formatter.' + key));
    tmp.push(value);

    return tmp.join(' ');
  }
}
