export enum GraylogCameraPatterns {
    STARTING_CONNECTION = 'Starting connection attempt to ([\\d.]+) port ([\\d]+)',
    SUCCESSFULLY_CONNECTED = 'Successfully connected to ([\\d.]+) port ([\\d]+)',
    FAILED_CONNECTION_TIMEOUT = 'failed: Connection timed out',
    FAILED_CONNECTION_REFUSED = 'failed: Connection refused',
    FAILED_NO_ROUTE_TO_HOST = 'failed: No route to host',
    FAILED_NETWORK_IS_UNREACHEABLE = 'failed: Network is unreachable',
    FAILED_UNAUTHORIZED = 'failed: 401 Unauthorized',
    FAILED_AUTHORIZATION_REQUIRED = 'failed: 401 Authorization Required',
    FAILED = 'failed'
}
