import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { LogsResponse } from '@app/shared/models';
import { Constants } from '@app/shared/helpers';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-service-rda-logs',
  templateUrl: './service-rda-logs.component.html',
  styleUrls: ['./service-rda-logs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceRdaLogsComponent implements OnInit, OnDestroy {
  readonly dateDiffs = Constants.dateDiffs.slice(3);
  readonly secondsToUpdate: number = 10;

  @Input() logs: LogsResponse[];
  @Input() loading: boolean;
  isUpdate = false;
  currentSeconds = 0;
  selectedDateDiff: number = Constants.dateDiffs[3].value;

  @Output() private loadEmit: EventEmitter<{ timeRange: number }> = new EventEmitter();
  @Output() private timeRangeChanged: EventEmitter<{ timeRange: number }> = new EventEmitter();
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    public resolution: ResolutionService
  ) { }

  ngOnInit() {
    this.onLoad();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onLoad() {
    this.loadEmit.next({ timeRange: this.selectedDateDiff });
  }

  onStartUpdate() {
    this.isUpdate = true;

    interval(1000)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(n => {
        this.currentSeconds = n % this.secondsToUpdate;
        if (n % this.secondsToUpdate === 0) {
          this.loadEmit.next({ timeRange: this.selectedDateDiff });
        }
      });
  }

  onStopUpdate() {
    this.isUpdate = false;
    this.currentSeconds = 0;
    this.onDestroy$.next();
  }

  onTimeRangeChanged() {
    this.timeRangeChanged.emit({ timeRange: this.selectedDateDiff });
  }
}
