import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { PopupBasicComponent } from '@app/shared/templates';
import { Subject } from 'rxjs';
import { editAccountFormGroup } from './edit-account-form.model';
import { takeUntil } from 'rxjs/operators';

export interface IFormModalValue {
  number: string;
}

@Component({
  selector: 'app-edit-account-form',
  templateUrl: './edit-account-form.component.html',
  styleUrls: ['./edit-account-form.component.scss']
})
export class EditAccountFormComponent extends PopupBasicComponent implements OnInit {
  form: UntypedFormGroup;
  private unSubscribe$: Subject<void> = new Subject<void>();


  constructor(
    @Inject('data') protected data: { accountNumber: string, isMobile: boolean },
    @Inject('subject') private subject: Subject<any>
  ) {
    super();
    this.form = editAccountFormGroup().form;
  }

  ngOnInit() {
    if (this.data.accountNumber) {
      this.form.get('number').setValue(this.data.accountNumber);
    }

    this.form.valueChanges.pipe(takeUntil(this.unSubscribe$)).subscribe((value: IFormModalValue) => {
      if (value.number.includes(' ')) {
        this.form.get('number').setValue(value.number.replace(' ', ''));
      }
    });
  }

  onSubmit() {
    if (this.form.valid) {
      const number: string = this.form.get('number').value;
      this.subject.next(number);
    }
  }
}
