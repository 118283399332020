<div class="dashboard">
  <div class="chat">
    <mat-card>
      <mat-card-header class="header">
        <div class="title-block">
          <mat-card-title>
            <ng-container *ngIf="resolution.isTablet; else blockName">
              <button mat-raised-button (click)="onCloseChat()">
                {{ 'abonent.chat.button.close' | translate }}
              </button>
            </ng-container>
            <ng-template #blockName>
              {{ 'abonent.chat.title' | translate }}
            </ng-template>
          </mat-card-title>
        </div>

        <button
          mat-icon-button
          color="primary-inverse"
          matTooltip="{{ 'abonent.chat.tooltip.refresh' | translate }}"
          (click)="onLoadFirstPage()"
        >
          <mat-icon>refresh</mat-icon>
        </button>

        <button mat-raised-button color="primary"
          [disabled]="resolved"
          matTooltip="{{ 'abonent.chat.tooltip.resolved' | translate }}"
          (click)="onResolve()"
        >
          {{ 'abonent.chat.button.resolved' | translate }}
        </button>

      </mat-card-header>
    </mat-card>

    <mat-card-content>
      <div class="ui-panel-content-wrapper ng-trigger ng-trigger-panelContent chats-content">
        <div class="ui-panel-content ui-widget-content">
          <div class="ui-tabview ui-widget ui-widget-content ui-corner-all ui-tabview-top">
            <div
              id="scrollBlock"
              class="chat-container"
              infiniteScroll
              [infiniteScrollDistance]="1"
              [infiniteScrollThrottle]="300"
              [fromRoot]="true"
              [alwaysCallback]="true"
              [infiniteScrollContainer]="selector"
              (scrolledUp)="onScroll()"
            >
              <ul id="chatsContent" class="chat-messages">
                <li
                  *ngFor="let message of chat; let idx = index"
                  class="message-block"
                  [class.message-own]="message.incoming"
                  [class.message-from]="!message.incoming"
                >
                  <span class="message-text">
                    <p class="message-area">{{ message.message }}</p>
                    <p class="date-field-small">{{ message.messageDate | currentDate: 'dd/MM/yyyy, HH:mm:ss' }}</p>
                  </span>
                </li>
              </ul>
            </div>
            <app-component-loader *ngIf="loading$ | async"></app-component-loader>
          </div>
        </div>
      </div>
    </mat-card-content>

    <mat-card-actions>
      <div class="new-message">
        <mat-form-field [floatLabel]="'never'">
          <textarea
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            placeholder="{{ 'abonent.chat.placeholder.message' | translate }}"
            cdkAutosizeMaxRows="3"
            id="messageText"
            type="text"
            [(ngModel)]="message"
            autocomplete="off"
            spellcheck="false"
            autocorrect="off"
            autocapitalize="off"
          ></textarea>
        </mat-form-field>

        <button class="send-btn"
          mat-icon-button
          (click)="onSendMessage()"
          [disabled]="(sendMessageLoading$ | async)" title="Reply"
        >
          <div *ngIf="sendMessageLoading$ | async; else sendIcon">
            <mat-spinner [diameter]="40"></mat-spinner>
          </div>
          <ng-template #sendIcon>
            <mat-icon class="send-ic" aria-label="Delete">send</mat-icon>
          </ng-template>
        </button>
      </div>
    </mat-card-actions>
  </div>
</div>
