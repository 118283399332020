import { Pipe, PipeTransform } from '@angular/core';

type IconsTypes = 'camera' | 'flat' | 'key';

@Pipe({ name: 'icon' })
export class IconPipe implements PipeTransform {
  transform(value: IconsTypes) {
    switch (value) {
      case 'camera':
        return 'videocam';
      case 'flat':
        return 'meeting_room';
      case 'key':
        return 'vpn_key';
      default:
        return '';
    }
  }
}
