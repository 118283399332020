import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AddressService } from '../../services/address.service';
import { ActionTypes, GetCitiesList, GetCitiesListFailure, GetCitiesListSuccess, GetEntrancesList, GetEntrancesListFailure, GetEntrancesListSuccess, GetHousesList, GetHousesListFailure, GetHousesListSuccess, GetStreetsList, GetStreetsListFailure, GetStreetsListSuccess, ClearAddress } from '../actions/address.actions';

@Injectable()
export class AddressEffects {
  @Effect()
  GetCitiesList$ = this.actions$.pipe(
    ofType<GetCitiesList>(ActionTypes.GetCitiesList),
    switchMap(() =>
      from(this.addressService.getCitiesList()).pipe(
        map(data => new GetCitiesListSuccess(data)),
        catchError(error => of(new GetCitiesListFailure(error)))
      )
    )
  );

  @Effect()
  GetStreetsList$ = this.actions$.pipe(
    ofType<GetStreetsList>(ActionTypes.GetStreetsList),
    switchMap(action =>
      from(this.addressService.getStreetsList(action.cityId)).pipe(
        map(data => new GetStreetsListSuccess(data)),
        catchError(error => of(new GetStreetsListFailure(error)))
      )
    )
  );

  @Effect()
  GetHousesList$ = this.actions$.pipe(
    ofType<GetHousesList>(ActionTypes.GetHousesList),
    switchMap(action =>
      from(this.addressService.getHousesList(action.cityId, action.streetId)).pipe(
        map(data => new GetHousesListSuccess(data)),
        catchError(error => of(new GetHousesListFailure(error)))
      )
    )
  );

  @Effect()
  GetEntrancesList$ = this.actions$.pipe(
    ofType<GetEntrancesList>(ActionTypes.GetEntrancesList),
    switchMap(action =>
      from(this.addressService.getEntrancesList(action.cityId, action.streetId, action.houseId)).pipe(
        map(data => new GetEntrancesListSuccess(data)),
        catchError(error => of(new GetEntrancesListFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  ClearAddress$ = this.actions$.pipe(
    ofType<ClearAddress>(ActionTypes.ClearAddress),
    tap(() => new ClearAddress())
  );

  constructor(
    private actions$: Actions,
    private addressService: AddressService
  ) { }
}
