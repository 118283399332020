import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { debounceTime, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import {Observable, Subject} from 'rxjs';

import { SnackbarService } from '@app/shared/components';
import {AbonentService, CompanyApiService, ReportsUtils} from '@app/shared/entities/rd';
import { PaymentStatusType } from '@app/shared/entities/rd/payments/enums/payment-status-type.enum';
import {
  PaymentsInterface
} from '@app/shared/entities/rd/payments/interfaces';
import { parseError } from '@app/shared/helpers';
import { PhonePipe } from '@app/shared/pipes';
import { Abonent } from '@app/shared/models';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {FileResponse} from '@app/shared/api';

@Component({
  selector: 'app-payments-list',
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.scss'],
  providers: [PhonePipe]
})
export class PaymentsListComponent implements OnInit, OnDestroy {
  private unSubscribe$: Subject<boolean>;
  public readonly paymentStatusType = PaymentStatusType;
  readonly displayedPaymentColumns = [
    'description',
    'phone',
    'mail',
    'date',
    'amount',
    'is-paid',
    'is-blocked'
  ];
  public paymentSum = {
    pending: {
      value: 0,
      currency: 'RUB'
    },
    blocked: {
      value: 0,
      currency: 'RUB'
    },
    success: {
      value: 0,
      currency: 'RUB'
    }
  };
  public paymentList: PaymentsInterface[] = [];
  public sortButtonConfig: { name: string; }[][];
  public activeButton: string;
  public openedState: boolean;
  public loading: boolean;

  public filterForm: UntypedFormGroup = this.fb.group({
    startDate: new UntypedFormControl(
      new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 31)
    ),
    endDate: new UntypedFormControl(new Date()),
    searchString: new UntypedFormControl('')
  });

  constructor(
    private readonly companyService: CompanyApiService,
    private readonly fb: UntypedFormBuilder,
    private readonly phonePipe: PhonePipe,
    private readonly snackbar: SnackbarService,
    private readonly abonentService: AbonentService,
    private readonly router: Router,
    private readonly translate: TranslateService
  ) {
    this.unSubscribe$ = new Subject<boolean>();
    this.sortButtonConfig = [
      [
        { name: this.translate.instant('payments.list.enum.status.all') },
        { name: this.translate.instant('payments.list.enum.status.paid') },
        { name: this.translate.instant('payments.list.enum.status.wait') },
        { name: this.translate.instant('payments.list.enum.status.blocked') }
      ],
      [
        { name: this.translate.instant('payments.list.button.expand_all') }
      ]
    ];
    this.activeButton = this.sortButtonConfig[0][0].name;
    this.openedState = false;
    this.loading = false;
  }

  public ngOnInit(): void {
    this.initialCall();

    this.filterForm.valueChanges
      .pipe(
        debounceTime(500),
        filter(values => values.endDate && values.startDate),
        map(values => {
          values.endDate = new Date(values.endDate.setHours(23, 59, 59, 999));
          return values;
        }),
        tap(() => this.loading = true),
        takeUntil(this.unSubscribe$),
        switchMap(values =>
          this.companyService.getPaymentsPeriod(1, {
            startDate: Math.floor(values.startDate.getTime() / 1000),
            endDate: Math.floor(values.endDate.getTime() / 1000),
            searchString: values.searchString
          }))
      ).subscribe((res: PaymentsInterface[]) => {
        this.handleResponse(res);
      });
  }

  public ngOnDestroy(): void {
    this.unSubscribe$.next(true);
    this.unSubscribe$.unsubscribe();
  }

  private initialCall() {
    this.companyService.getPaymentsPeriod(1, {
      startDate: Math.floor(this.filterForm.value.startDate.getTime() / 1000),
      endDate: Math.floor(this.filterForm.value.endDate.getTime() / 1000),
      searchString: this.filterForm.value.searchString
    }).pipe(
      takeUntil(this.unSubscribe$),
      tap(() => this.loading = true),
    ).subscribe((res: PaymentsInterface[]) => this.handleResponse(res));
  }

  private handleResponse(res: PaymentsInterface[]) {
    this.loading = false;
    this.paymentList = res.filter(payment =>
      this.phonePipe.transform(payment.paymentData.receipt.customer.phone)
    );
    this.calculatePaymentSums();
  }

  private calculatePaymentSums() {
    this.paymentSum.blocked.value = 0;
    this.paymentSum.pending.value = 0;
    this.paymentSum.success.value = 0;
    if (this.paymentList && this.paymentList.length > 0) {
      this.paymentList.map((payment: PaymentsInterface) => {
        if (!payment.paid && !payment.isBlocked) {
          this.paymentSum.pending.value += Number(
            payment.paymentData.amount.value
          );
        } else if (payment.paid && !payment.isBlocked) {
          this.paymentSum.success.value += Number(
            payment.paymentData.amount.value
          );
        } else if (payment.isBlocked) {
          this.paymentSum.blocked.value += Number(
            payment.paymentData.amount.value
          );
        }
      });
    }
  }

  public async checkPaymentAsPaid(payment: PaymentsInterface) {
    try {
      await this.companyService
        .markPaymentPaid(payment.confirmationKey)
        .toPromise();
      payment.status = PaymentStatusType.CONFIRMED_MANUALLY;
      payment.paid = true;
      payment.isBlocked = false;
      this.calculatePaymentSums();
    } catch (e) {
      this.snackbar.showMessage(
        this.translate.instant('payments.list.message.check_as_paid.failed', {
          text: parseError(e.message)
        }),
        'error'
      );
    }
  }

  public onSort(name: string): void {
    this.activeButton = name;
  }

  public onCollapseAll(buttonConfig): void {
    this.openedState = !this.openedState;
    this.changeButtonName(buttonConfig);
  }

  public onSubscriberEvent(phone: string): void {
    this.loading = true;
    this.abonentService.getAbonentAccountByPhone(phone).pipe(
      takeUntil(this.unSubscribe$)
    ).subscribe(response => {
      this.loading = false;
      this.moveToAbonentCard(response);
    });
  }

  public onDownloadReport(): void {
    this.loading = true;
    this.companyService.getAccountsDownload({
      startDate: Math.floor(this.filterForm.value.startDate.getTime() / 1000),
      endDate: Math.floor(this.filterForm.value.endDate.getTime() / 1000),
      searchString: this.filterForm.value.searchString
    }).pipe(
      takeUntil(this.unSubscribe$),
    ).subscribe((res: FileResponse) => {
      this.loading = false;
      this.handleDownloadReportAction(res);
    });
  }

  private handleDownloadReportAction(fileResponse: FileResponse) {
    ReportsUtils.downLoadFile(fileResponse.arrayBuffer, decodeURI(fileResponse.fileName));
  }

  private changeButtonName(buttonConfig): void {
    buttonConfig.name === this.translate.instant('payments.list.button.expand_all') ?
      buttonConfig.name = this.translate.instant('payments.list.button.collapse_all') :
      buttonConfig.name = this.translate.instant('payments.list.button.expand_all');
  }

  private moveToAbonentCard(response: Abonent): void {
    this.router.navigate([`/abonents/subscriber/${response.id}`]).then();
  }
}
