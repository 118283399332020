import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { FileResponse } from '@app/shared/api';
import { ErrorCodes } from '@app/shared/helpers';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ErrorResponse } from './error-response.model';
import { RequestOptions } from './request-options.model';
import { HumanErrorTranslationService } from '../services/human-error-translation.service';

export abstract class ApiService {
  static MAX_PAGE_SIZE = 2000;
  static readonly CONTENT_TYPE_JSON_HEADER: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(
    private http: HttpClient,
    private humanErrorTranslationService: HumanErrorTranslationService,
    private endpointRoot: string,
    private apiVersions: string[]
  ) { }

  protected getFile(path: string, apiVersion: number, options?: RequestOptions): Observable<FileResponse> {
    const apiVersionStr = this.prepareUsingApiVersion(apiVersion);
    const requestUrl = `${this.endpointRoot}${apiVersionStr}${path}`;

    return this.http
      .get(requestUrl, { ...options, responseType: 'arraybuffer', observe: 'response' })
      .pipe(
        map(resp => this.prepareGetFileResp(resp)),
        catchError(this.handleError)
      );
  }

  protected getText(path: string, apiVersion: number, options?: RequestOptions): Observable<string> {
    const apiVersionStr = this.prepareUsingApiVersion(apiVersion);
    const requestUrl = `${this.endpointRoot}${apiVersionStr}${path}`;

    return this.http
      .get(requestUrl, { ...options, responseType: 'text', observe: 'response' })
      .pipe(map(data => data.body), catchError(this.handleError));
  }

  protected get<R>(path: string, apiVersion: number, options?: Partial<RequestOptions>): Observable<R> {
    const apiVersionStr = this.prepareUsingApiVersion(apiVersion);
    const requestUrl = `${this.endpointRoot}${apiVersionStr}${path}`;

    return this.http.get<R>(requestUrl, { ...options }).pipe(catchError(this.handleError));
  }

  protected delete<R>(path: string, apiVersion: number, options?: Partial<RequestOptions>): Observable<R>;
  protected delete<P, R>(path: string, apiVersion: number, payload: Partial<P>, options?: Partial<RequestOptions>): Observable<R>;
  protected delete<P, R>(path: string, apiVersion: number, payload: string | Partial<P>, options?: Partial<RequestOptions>): Observable<R> {
    const apiVersionStr = this.prepareUsingApiVersion(apiVersion);
    const requestUrl = `${this.endpointRoot}${apiVersionStr}${path}`;
    const requestBody = typeof payload === 'string' ? payload : JSON.stringify(payload);

    return this.http.request<R>('delete', requestUrl, { body: requestBody, ...options }).pipe(catchError(this.handleError));
  }

  protected post<R>(path: string, apiVersion: number, payload?: string, options?: Partial<RequestOptions>): Observable<R>;
  protected post<P, R>(path: string, apiVersion: number, payload?: Partial<P>, options?: Partial<RequestOptions>): Observable<R>;
  protected post<P, R>(path: string, apiVersion: number, payload?: string | Partial<P>, options?: Partial<RequestOptions>): Observable<R> {
    const apiVersionStr = this.prepareUsingApiVersion(apiVersion);
    const requestUrl = `${this.endpointRoot}${apiVersionStr}${path}`;
    const requestBody = typeof payload === 'string' ? payload : JSON.stringify(payload);

    return this.http.post<R>(requestUrl, requestBody, { ...options }).pipe(catchError(this.handleError));
  }

  protected sendFiles<R>(path: string, apiVersion: number, payload?: FormData, options?: Partial<RequestOptions>): Observable<R> {
    const apiVersionStr = this.prepareUsingApiVersion(apiVersion);
    const requestUrl = `${this.endpointRoot}${apiVersionStr}${path}`;
    const requestBody = payload;

    return this.http.post<R>(requestUrl, requestBody, { ...options }).pipe(catchError(this.handleError));
  }

  protected patch<P, R>(path: string, apiVersion: number, payload: Partial<P>, options?: Partial<RequestOptions>): Observable<R> {
    const apiVersionStr = this.prepareUsingApiVersion(apiVersion);
    const requestUrl = `${this.endpointRoot}${apiVersionStr}${path}`;
    const requestBody = JSON.stringify(payload);

    return this.http.patch<R>(requestUrl, requestBody, { ...options }).pipe(catchError(this.handleError));
  }

  protected put<P, R>(path: string, apiVersion: number, payload?: Partial<P>, options?: Partial<RequestOptions>): Observable<R>;
  protected put<R>(path: string, apiVersion: number, payload?: string, options?: Partial<RequestOptions>): Observable<R>
  protected put<P, R>(path: string, apiVersion: number, payload?: string | Partial<P>, options?: Partial<RequestOptions>): Observable<R> {
    const apiVersionStr = this.prepareUsingApiVersion(apiVersion);
    const requestUrl = `${this.endpointRoot}${apiVersionStr}${path}`;
    const requestBody = typeof payload === 'string' ? payload : JSON.stringify(payload);

    return this.http.put<R>(requestUrl, requestBody, { ...options }).pipe(catchError(this.handleError));
  }

  private prepareGetFileResp(resp: HttpResponse<ArrayBuffer>) {
    const contentDisposition: string = resp.headers.get('content-disposition');
    if (!contentDisposition) {
      throw new Error('content-disposition header is not found');
    }
    const fileName = contentDisposition.replace('attachment; filename=', '');
    if (!fileName) {
      throw new Error('Filename in content-disposition header is not found');
    }
    return { arrayBuffer: resp.body, fileName };
  }

  private prepareUsingApiVersion(apiVersion: number): string {
    return this.apiVersions.length > 0 ? this.apiVersions[apiVersion - 1] : '';
  }

  private isCanBeLocalized(errorResponse: ErrorResponse): boolean {
    return errorResponse?.errorCode && ErrorCodes[errorResponse?.errorCode];
  }

  private handleError = (httpError: HttpErrorResponse): Observable<never> => {
    const error: ErrorEvent | ErrorResponse = httpError.error;

    if (error instanceof ErrorEvent) {
      return throwError(httpError);
    }

    if (error?.error) {
      if (this.isCanBeLocalized(error)) {
        error.errorLocalized = this.humanErrorTranslationService.translateFromPath(ErrorCodes[error.errorCode], error.errorMessageParams);
      } else {
        error.errorLocalized = this.humanErrorTranslationService.translate(error.error);
      }
      console.error(`[${httpError.status}] ${httpError.statusText}: (${error.errorCode}) ${error}`);
    }

    return throwError(httpError);
  }
}
