import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Position } from '@app/shared/components/info-panel/models/position';
import { Response } from '@app/shared/components/info-panel/models/response';
import { ToastModule } from 'primeng/toast';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
  providers: [MessageService],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ToastModule
  ],
  encapsulation: ViewEncapsulation.None
})
export class InfoPanelComponent implements AfterViewInit, OnDestroy {
  private _showTransitionOptions = '0ms';
  private _hideTransitionOptions = '0ms';
  private _wait: Subject<void> = new Subject<void>();
  private _unSubscribe: Subject<void> = new Subject<void>();

  @Input() position: Position.tr | Position.tl | Position.br | Position.bl | Position.tc | Position.bc | Position.c = Position.br;
  @Input() response: Response.info | Response.error | Response.success | Response.warn;
  @Input() summary: string;
  @Input() life = 10000;
  @Input() detail: string;
  @Input() key: string;
  @Input() set showTransitionOptions(ms: string) {
    this._showTransitionOptions = `${ms}ms`;
  }
  @Input() set hideTransitionOptions(ms: string) {
    this._hideTransitionOptions = `${ms}ms`;
  }

  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  constructor(private messageService: MessageService) {
    this._wait
      .pipe(
        debounceTime(100),
        takeUntil(this._unSubscribe)
      ).subscribe(() => { this.showSingleMassage(); });
  }

  ngAfterViewInit(): void {
    this._wait.next();
  }

  ngOnDestroy(): void {
    this._unSubscribe.next();
    this._unSubscribe.complete();
  }

  get showTransitionOptions(): string { return this._showTransitionOptions; }
  get hideTransitionOptions(): string { return this._hideTransitionOptions; }

  public onClose(): void {
    this.close.emit();
  }

  public showSingleMassage(): void {
    this.messageService.add(
      {
        severity: this.response,
        summary: this.summary,
        detail: this.detail,
        life: this.life
      }
    );
  }
}
