import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountSelectionType } from '@app/shared/components';
import { ResourcePath, ResourcesHelper } from '@app/shared/entities';
import { Account, CreateAbonentAndGetAccountsResponse } from '@app/shared/entities/rd';
import { Abonent } from '@app/shared/models';
import { Observable } from 'rxjs';
import { ServiceBlockPhysicalTubePopupMode } from './models';
import { ServiceBlockPhysicalTubeContentStore } from './store/service-block-physical-tube-content.store';

@Component({
  selector: 'app-service-block-physical-tube-content',
  templateUrl: './service-block-physical-tube-content.component.html',
  styleUrls: ['./service-block-physical-tube-content.component.scss'],
  providers: [ServiceBlockPhysicalTubeContentStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceBlockPhysicalTubeContentComponent {
  readonly resourcePath: typeof ResourcePath = ResourcePath;
  readonly pageModes: typeof ServiceBlockPhysicalTubePopupMode = ServiceBlockPhysicalTubePopupMode;

  @Input() loading: boolean;
  @Input() phone: number;
  @Input() text: string;
  abonentAndAccountsLoading$: Observable<boolean> = this.store.abonentAndAccountsLoading$;
  pageMode$: Observable<ServiceBlockPhysicalTubePopupMode> = this.store.pageMode$;
  abonentAndAccountsResponse$: Observable<CreateAbonentAndGetAccountsResponse> = this.store.abonentAndAccountsResponse$;

  @Output() private blockPhysicalTube: EventEmitter<{ account: Account }> = new EventEmitter();

  constructor(
    public resourcesHelper: ResourcesHelper,
    private store: ServiceBlockPhysicalTubeContentStore
  ) { }

  onBlock() {
    if (this.phone) {
      this.checkAbonent();
      return;
    }

    this.blockPhysicalTube.emit();
  }

  onAccountSelected(abonent: Abonent, account: Account, type: AccountSelectionType) {
    if (type === AccountSelectionType.NEW_ACCOUNT) {
      account = null;
    }

    this.blockPhysicalTube.emit({ account });
  }

  private checkAbonent() {
    this.store.addAbonentAccountsListener((response: CreateAbonentAndGetAccountsResponse) => {
      if (response?.accounts?.length > 1) {
        this.store.updatePageMode(ServiceBlockPhysicalTubePopupMode.ACCOUNTS_SELECTION);
        return;
      }

      let abonent: Abonent;
      let account: Account;

      abonent = response.abonent;

      if (response?.accounts?.length === 1) {
        account = response.accounts[0];
      }

      this.blockPhysicalTube.emit({ account });
    });

    this.store.createAbonentAndGetAccounts({ phone: this.phone });
  }
}
