import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Camera, CameraUtilsService } from '@app/shared/entities/rd';
import { Links } from '@app/shared/helpers/wiki-links.enum';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-cameras-table-container',
  templateUrl: './cameras-table-container.component.html',
  styleUrls: ['./cameras-table-container.component.scss']
})
export class CamerasTableContainerComponent implements OnInit {

  constructor(
    private resolution: ResolutionService,
    private cameraUtilsService: CameraUtilsService,
    private translate: TranslateService
  ) { }
  readonly rdvaPort = environment.RDVAPort;
  readonly rdvaHlsPort = environment.RDVAHlsPort;
  public link: string = Links.Camera;
  @Input() cameras: Camera[];
  @Input() showDefaultCamera: boolean;
  @Input() showAddress: boolean;
  @Input() showBitrateAndLatency: boolean;
  @Input() disabled: boolean;
  @Input() loading: boolean;
  @Input() readonly: boolean;
  @Input() emptyCamerasCaption: string;
  displayedColumns: string[];
  smWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.SM_W_DOWN);

  @Output() private addCamera: EventEmitter<void> = new EventEmitter();
  @Output() private editCamera: EventEmitter<{ camera: Camera }> = new EventEmitter();
  @Output() private cameraInfo: EventEmitter<{ camera: Camera }> = new EventEmitter();
  @Output() private deleteCamera: EventEmitter<{ id: number, idx: number }> = new EventEmitter();
  @Output() private resetCamera: EventEmitter<{ rdvaUri: string, cameraId: number }> = new EventEmitter();
  @Output() private refreshCameras: EventEmitter<void> = new EventEmitter();

  public readonly environment = environment;

  ngOnInit() {
    this.initTable();

    this.translate.get('software.intercom.cameras.message.connect_first_camera').subscribe(res => {
      this.emptyCamerasCaption = res;
    });
  }

  onAdd() {
    this.addCamera.emit();
  }

  onEdit(camera: Camera) {
    this.editCamera.emit({ camera });
  }

  onDelete(id: number, idx: number) {
    this.deleteCamera.emit({ id, idx });
  }

  onReset(rdvaUri: string, cameraId: number) {
    this.resetCamera.emit({ rdvaUri, cameraId });
  }

  onRefreshCameras() {
    this.refreshCameras.emit();
  }

  onInfo(camera: Camera) {
    this.cameraInfo.emit({ camera });
  }

  cameraStatusColor(camera: Camera) {
    const cameraCorrect: boolean = this.cameraUtilsService.isCameraCorrect(camera);

    if (cameraCorrect && camera.active) {
      return 'success';
    } else if (!cameraCorrect && camera.active) {
      return 'warn';
    }

    return 'error';
  }

  private initTable() {
    this.displayedColumns = ['active', 'uri', 'id'];

    if (this.showBitrateAndLatency) {
      this.displayedColumns = [...this.displayedColumns, 'bitrate', 'latency'];
    }

    this.displayedColumns = [...this.displayedColumns, 'preview', 'private', 'location'];

    if (this.showDefaultCamera) {
      this.displayedColumns.push('default');
    }

    this.displayedColumns.push('actions');
  }
}
