import { Account } from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';
import { Flat } from '@app/shared/models';
import { ServiceConnectionWithType } from './../../../../models';
import { ServiceFlatSharedAbonent } from './service-flat-shared-abonent.model';
import { ServiceFlatSignUps } from './service-flat-sign-ups.model';

export interface ServiceFlat {
    flatNumber: number;
    account: Partial<Account>;
    flat: Partial<Flat>;
    sharedAbonents: ServiceFlatSharedAbonent[];
    signUps: Partial<ServiceFlatSignUps>;
    services: Dictionary<ServiceConnectionWithType>;
}
