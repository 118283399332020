import {ChangeDetectionStrategy, Component, Input} from '@angular/core';

@Component({
  selector: 'app-snow',
  templateUrl: './snow.component.html',
  styleUrls: ['./snow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnowComponent {
  @Input() snowflakesNumber = 150;
}
