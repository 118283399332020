import { IntercomType } from '@app/shared/entities/rd';
import { Actions, ActionTypes } from '../../actions/intercom.actions';

export interface State {
  types: IntercomType[];
  error: string | null;
  loading: boolean;
}

export const initialState: State = {
  types: [],
  error: null,
  loading: false,
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GetIntercomTypes: {
      return {
        ...state,
        types: [],
        error: null,
        loading: true,
      };
    }

    case ActionTypes.GetIntercomTypesSuccess: {
      return {
        ...state,
        types: action.payload,
        error: null,
        loading: false,
      };
    }

    case ActionTypes.GetIntercomTypesFailure: {
      return {
        ...state,
        types: [],
        error: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
