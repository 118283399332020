import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavbarLink } from '@app/shared/ui';
import { CompanyPageType } from '@app/views/company/models/company-page';
import {
  CompaniesBuilderService,
  ITabsConfig
} from '@app/views/company/services/companies-builder.service';
import { CompanyPageStore } from '@app/views/company/store/company-page.store';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-company-general-page',
  templateUrl: './company-general-page.component.html',
  styleUrls: ['./company-general-page.component.scss'],
  providers: [CompaniesBuilderService, CompanyPageStore]
})
export class CompanyGeneralPageComponent implements OnInit, OnDestroy {
  public activeDynamicComponent: ITabsConfig = null;
  public updateActiveComponent: boolean;

  private componentsDestroyer$: Subject<number> = new Subject<number>();
  private unSubscribe$: Subject<boolean> = new Subject<boolean>();

  public activeLink: string = CompanyPageType.Profile;
  public readonly navbarLinks: NavbarLink[] = [];

  constructor(
    private companiesBuilderService: CompaniesBuilderService,
    private activeRout: ActivatedRoute,
    private router: Router,
    private translate: TranslateService
  ) {
    this.activeDynamicComponent = companiesBuilderService.dynamicComponents[0];
    this.navbarLinks = companiesBuilderService.dynamicComponents.map(component => {
      return {
        name: component.header,
        text: component.text
      };
    });
  }

  ngOnInit(): void {
    this.componentsDestroyer$
      .pipe(debounceTime(100), takeUntil(this.unSubscribe$))
      .subscribe((tabIdx: number) => {
        this.activeDynamicComponent =
          this.companiesBuilderService.dynamicComponents[tabIdx];
        this.updateActiveComponent = false;
      });

    this.activeRout.queryParams.subscribe(res => {
      if (res.userAndRoles) {
        const tabIdx = this.companiesBuilderService.dynamicComponents.length - 1;
        this.onChangeTab(tabIdx);
        this.resetQuery(res?.page);
      }
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  }

  public onChangeTab(tabIdx: number): void {
    this.updateActiveComponent = true;
    this.activeLink = this.navbarLinks[tabIdx].name;
    this.componentsDestroyer$.next(tabIdx);
  }

  private resetQuery(page: string): void {
    this.router.navigate([], {
      queryParams: {
        page: page
      }
    });
  }
}
