import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { LogsResponse } from '@app/shared/models';
import { ServiceActivitySource } from '@app/views/services/models';

@Component({
  selector: 'app-service-logs',
  templateUrl: './service-logs.component.html',
  styleUrls: ['./service-logs.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceLogsComponent implements OnInit, OnDestroy {
  @Input() logs: LogsResponse[];
  @Input() sources: ServiceActivitySource[];
  @Input() selectedSource: ServiceActivitySource;
  @Input() extendedMode: boolean;
  @Input() loading: boolean;
  @Input() totalCount: number;

  @Output() private changeLogsMode: EventEmitter<{ extendedMode: boolean }> = new EventEmitter();
  @Output() private sourceSelected: EventEmitter<{ source: ServiceActivitySource }> = new EventEmitter();
  @Output() private scrolledToBottom: EventEmitter<void> = new EventEmitter();
  private window: Window;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
  }

  ngOnInit() {
    this.window.addEventListener('scroll', this.scroll, true);
  }

  ngOnDestroy() {
    this.window.removeEventListener('scroll', this.scroll, true);
  }

  onSelectSource(source: ServiceActivitySource) {
    this.sourceSelected.emit({ source });
  }

  onChangeMode(event: MatSlideToggleChange) {
    this.changeLogsMode.emit({ extendedMode: event.checked });
  }

  scroll = (event): void => {
    if (event.target.scrollTop + this.window.innerHeight === event.target.scrollHeight) {
      this.scrolledToBottom.emit();
    }
  }
}
