import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UpdateServiceRequest } from '@app/views/services/models';

@Component({
  selector: 'app-service-edit-content',
  templateUrl: './service-edit-content.component.html',
  styleUrls: ['./service-edit-content.component.scss']
})
export class ServiceEditContentComponent implements OnInit {
  @Input() serviceName: string;
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  form: UntypedFormGroup = this.initForm();

  @Output() private editService: EventEmitter<{ request: UpdateServiceRequest }> = new EventEmitter();
  private _loading: boolean;

  ngOnInit() {
    if (this.serviceName) {
      this.form.get('customName').setValue(this.serviceName);
    }
  }

  get loading(): boolean {
    return this._loading;
  }

  onEdit() {
    const request: UpdateServiceRequest = this.form.getRawValue();
    if (request.customName === '') {
      request.customName = null;
    }

    this.editService.emit({ request });
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      customName: new UntypedFormControl(null)
    });
  }
}
