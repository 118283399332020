export interface CompanyRegisterRequest {
    shortName: string;
    name: string;
    licenseeShort: string;
    ogrn: string;
    email: string;
    inn: string;
    kpp: string;
    managementName: string;
    managementPost: string;
    companyType: string;
    country: string;
    address: string;
    supportPhone: string;
    paymentLink: string;
    paymentDetails: {
        account: string,
        bank: string,
        bic: string,
        corrAcc: string
    };
}
