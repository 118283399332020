<mat-card class="bottom-sheet-wrapper">
  <mat-card-header class="bottom-sheet-wrapper-header">
    <mat-card-title class="bottom-sheet-wrapper-header__title">{{ title }}</mat-card-title>
  </mat-card-header>
  <mat-card-content class="bottom-sheet-wrapper-content">
    <mat-progress-bar [mode]="mode" *ngIf="progress"></mat-progress-bar>
    <ng-content></ng-content>
  </mat-card-content>
  <app-component-loader *ngIf="loading" [background]="true"></app-component-loader>
</mat-card>
