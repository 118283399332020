import {Injectable} from '@angular/core';
import {IRoleResponse} from '@app/views/company/models/company-page';
import {LocalStorageGeneralKey, LocalStorageHelper} from '@app/shared/entities';
import {Dictionary} from '@app/shared/helpers';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class RolesComponentStoreService {
  public allAvailableRoles: IRoleResponse[] = JSON.parse(LocalStorageHelper.getItem(LocalStorageGeneralKey.AVAILABLE_ROLES));
  // company.roles.header.email
  public rolesDictionary: Dictionary<string> = {
    // ROLE_MANUFACTURER: 'Производитель',
    ROLE_OWNER: this.translate.instant('company.roles.dictionary.owner'),
    ROLE_ENGINEER: this.translate.instant('company.roles.dictionary.engineer'),
    // ROLE_DEMO: 'Инженер с доступом к демо-функциям',
    ROLE_ENGINEER_ENTRANCES: this.translate.instant('company.roles.dictionary.engineer_entrances'),
    ROLE_ENGINEER_ABONENTS: this.translate.instant('company.roles.dictionary.engineer_abonents'),
    ROLE_ENGINEER_SIGNUPS: this.translate.instant('company.roles.dictionary.engineer_signups'),
    ROLE_ENGINEER_VIDEO_SURVEILLANCE: this.translate.instant('company.roles.dictionary.engineer_video_surveillance'),
    ROLE_ENGINEER_CAMERAS: this.translate.instant('company.roles.dictionary.engineer_cameras'),
    ROLE_MANAGER_MAILINGS: this.translate.instant('company.roles.dictionary.manager_mailings'),
    ROLE_MONITORING_ENTRANCES: this.translate.instant('company.roles.dictionary.monitoring_entrances'),
    ROLE_MANAGER_BILLS: this.translate.instant('company.roles.dictionary.manager_builds'),
    ROLE_ADMINISTRATOR: this.translate.instant('company.roles.dictionary.administrator'),
    ROLE_SUPPORT: this.translate.instant('company.roles.dictionary.support'),
    ROLE_VIDEODISPATCHER: this.translate.instant('company.roles.dictionary.videodispatcher'),
    ROLE_ENGENEER_PERSONAL_SURVEILLANCE: this.translate.instant('company.roles.dictionary.personal_surveillance'),
  };
  public rolesDescDictionary: Dictionary<string> = {
    // ROLE_MANUFACTURER: 'Производитель',
    ROLE_OWNER: this.translate.instant('company.roles.description.owner'),
    ROLE_ENGINEER: this.translate.instant('company.roles.description.engineer'),
    // ROLE_DEMO: 'Инженер с доступом к демо-функциям',
    ROLE_ENGINEER_ENTRANCES: this.translate.instant('company.roles.description.engineer_entrances'),
    ROLE_ENGINEER_ABONENTS: this.translate.instant('company.roles.description.engineer_abonents'),
    ROLE_ENGINEER_SIGNUPS: this.translate.instant('company.roles.description.engineer_signups'),
    ROLE_ENGINEER_VIDEO_SURVEILLANCE: this.translate.instant('company.roles.description.engineer_video_surveillance'),
    ROLE_ENGINEER_CAMERAS: this.translate.instant('company.roles.description.engineer_cameras'),
    ROLE_MANAGER_MAILINGS: this.translate.instant('company.roles.description.manager_mailings'),
    ROLE_MONITORING_ENTRANCES: this.translate.instant('company.roles.description.monitoring_entrances'),
    ROLE_MANAGER_BILLS: this.translate.instant('company.roles.description.manager_builds'),
    ROLE_ADMINISTRATOR: this.translate.instant('company.roles.description.administrator'),
    ROLE_SUPPORT: this.translate.instant('company.roles.description.support'),
    ROLE_VIDEODISPATCHER: this.translate.instant('company.roles.description.videodispatcher'),
    ROLE_ENGENEER_PERSONAL_SURVEILLANCE: this.translate.instant('company.roles.description.personal_surveillance'),
  };

  constructor(private translate: TranslateService) {
  }
}
