<mat-card class="card-container settings">
  <div class="header">
    <div class="title-block">
      {{ 'company.about.page.settings.title' | translate }}
    </div>

    <button
      *appSomeRoleContains="[userRoleType.ROLE_ENGINEER]"
      mat-button
      (click)="onToggleEdit()">
      <mat-icon color="primary">edit</mat-icon>
    </button>
  </div>
  <mat-card-content>
    <div class="settings-content">
      <div class="settings-text">
        <div class="settings-text-item">
          <span class="text">
            {{ 'company.about.page.settings.field.banner' | translate }}
          </span>
          <img
            *ngIf="company.banner; else noLogo"
            class="logo"
            [src]="image" />
          <span class="text">
            {{ 'company.about.page.settings.field.brand_name' | translate }}
          </span>
          <span
            *ngIf="company.brandName; else noValue"
            class="brand-name">
            {{ company.brandName }}
          </span>
          <div class="settings-image settings-image--mobile">
            <img
              [src]="image"
              class="logo-mobile" />
          </div>
          <span class="text">
            {{ 'company.about.page.settings.field.payment_link' | translate }}
          </span>
          <a
            *ngIf="company.paymentLink; else noValue"
            class="link"
            [href]="company.paymentLink"
            target="_blank">
            {{ company.paymentLink }}
          </a>
          <span class="text">
            {{ 'company.about.page.settings.field.app_link' | translate }}
          </span>
          <a
            *ngIf="company.personalAccountLink; else noValue"
            [href]="company.personalAccountLink"
            class="link"
            target="_blank">
            {{ company.personalAccountLink }}
          </a>
        </div>
      </div>
      <div class="settings-image">
        <img
          [src]="image"
          class="logo-mobile" />
      </div>
      <div class="support">
        <span class="sub-heading">
          {{ 'company.about.page.settings.header.support' | translate }}
        </span>
        <div class="support-content">
          <mat-checkbox
            disabled="true"
            class="box"
            [checked]="company.supportChatEnabled"
          >
            {{ 'company.about.page.settings.field.support_chat_enabled' | translate }}
          </mat-checkbox>
          <a
            class="phone"
            [href]="phone">
            {{ company.supportPhone | phone }}
          </a>
        </div>
      </div>

      <!--  Блок закоментирован до выяснения подробностей
        <div class="request">
        <span class="sub-heading">Заявки на подключение</span>
        <div class="request-content">
          <div class="request-text">
            <span>Ссылка на оферту для пользователей</span>
            <a
              *ngIf="company.autoSignOffer; else noValue"
              [href]="company.autoSignOffer">
              {{ company.autoSignOffer }}
            </a>
          </div>
          <mat-checkbox
            disabled="true"
            [checked]="company.autoSignRequireContract"
            class="request-checkbox">
            Требовать логин(ЛС) при составлении заявки
          </mat-checkbox>
        </div>
      </div> -->
    </div>
  </mat-card-content>
</mat-card>

<ng-template #noValue>
  <span>-</span>
</ng-template>

<ng-template #noLogo>
  <div class="no-logo"></div>
</ng-template>
