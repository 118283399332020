import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResourcePath, ResourcesHelper, ResourceType } from '@app/shared/entities/common';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'button[rdea-squared-button]',
  templateUrl: './squared-button.component.html',
  styleUrls: ['./squared-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SquaredButtonComponent {
  readonly resourceType: typeof ResourceType = ResourceType;

  @Input() resourcePath!: ResourcePath;

  constructor(
    public resourcesHelper: ResourcesHelper
  ) { }
}
