import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, ViewChild, ViewEncapsulation
} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ServicesTypes} from '@app/shared/models';
import {MatFormField} from '@angular/material/form-field';

@Component({
  selector: 'app-software-intercom-tariff-popup-content',
  templateUrl: './software-intercom-tariff-popup-content.component.html',
  styleUrls: ['./software-intercom-tariff-popup-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.Emulated
})
export class SoftwareIntercomTariffPopupContentComponent implements OnInit, OnChanges {
  @Input() defaultValue: number | string = ' ';
  @Input() defaultHardwareValue: number | string = ' ';
  @Input() showCheckbox: boolean;
  @Input() serviceType: ServicesTypes;
  @Output() contentSaved: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('softwareTariff') softwareTariffInput: MatFormField;
  @ViewChild('hardwareTariff') hardwareTariffInput: MatFormField;

  public readonly ServicesTypes = ServicesTypes;

  constructor(
    protected readonly fb: UntypedFormBuilder,
    protected readonly cdr: ChangeDetectorRef
  ) { }

  public tariffGroup: UntypedFormGroup;

  ngOnInit() {
    this.initializeComponent();
  }

  ngOnChanges() {
    this.initializeComponent();
  }

  private initializeComponent(): void {
    const controls = {
      applyTariffForConnections: new UntypedFormControl(false, null)
    };

    controls[`tariff${this.serviceType}`] = new UntypedFormControl(this.defaultValue, null);

    if (this.serviceType !== ServicesTypes.VIDEO_SURVEILLANCE && this.serviceType !== ServicesTypes.GATE) {
      controls[`tariff${ServicesTypes.HARDWARE_INTERCOM}`] = new UntypedFormControl(this.defaultHardwareValue, null);
    }

    this.tariffGroup = this.fb.group({...controls});
    if (this.defaultValue && this.softwareTariffInput) {
      this.softwareTariffInput.floatLabel = 'always';
    }
    if (this.defaultHardwareValue && this.hardwareTariffInput) {
      this.hardwareTariffInput.floatLabel = 'always';
    }
  }

  public onTariffChange(event: Event, serviceType: ServicesTypes): void {
    this.tariffGroup.controls[`tariff${serviceType}`].setValue((event.target as HTMLInputElement).value);
  }

  public onSubmit(): void {
    this.contentSaved.emit(this.tariffGroup.value);
  }

  get isButtonDisabled() {
    return ((this.defaultValue || this.defaultValue === 0) && (this.defaultValue === +this.tariffGroup.value['tariff' + this.serviceType]))
        && ((this.defaultHardwareValue || this.defaultHardwareValue === 0) && (this.defaultHardwareValue === +this.tariffGroup.value.tariffHardwareIntercom))
        && !(this.tariffGroup.controls['applyTariffForConnections'].value);
  }
}
