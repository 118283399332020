<div class="component popup">
  <app-info-block>
    <ng-container info-block-text>
      <ng-container [ngSwitch]="indiciesMode">
        <ng-container *ngSwitchCase="false">
          {{ 'services.popups.general.indicies.mode.content.message.you_have_chosen_gate_tuning_with_your_rda_or_ip_intercom' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="true">
          {{ 'services.popups.general.indicies.mode.content.message.you_have_chosen_setting_gate_with_reuse_adapters' | translate }}
        </ng-container>

        <ng-container *ngSwitchDefault>
          {{ 'services.popups.general.indicies.mode.content.message.do_you_plan_use_your_rda_or_ip_intercom_on_gate' | translate }}
        </ng-container>
      </ng-container>

    </ng-container>

    <ng-container info-block-description *ngIf="indiciesMode !== null && indiciesMode !== undefined">
      <br>

      <ng-container *ngIf="!disabled">
        {{ 'services.popups.general.indicies.mode.content.message.settings_mode_can_be_changed_until_addresses' | translate }}
      </ng-container>

      <ng-container *ngIf="disabled">
        {{ 'services.popups.general.indicies.mode.content.message.addles_and_adapters_already_connected_service' | translate }}
      </ng-container>
    </ng-container>
  </app-info-block>

  <div class="component-actions-block popup-actions-block">
    <ng-container *ngIf="indiciesMode === undefined || indiciesMode === null; else changeIndiciesMode">
      <button
        mat-raised-button
        color="primary"
        class="mat-button-lg popup-actions-block__button"
        (click)="onSelectIndiciesMode(false)"
      >
        {{ 'services.popups.general.indicies.mode.content.button.yes' | translate }}
      </button>

      <button
        mat-raised-button
        color="primary-inverse"
        class="mat-button-lg popup-actions-block__button"
        (click)="onSelectIndiciesMode(true)"
      >
        {{ 'services.popups.general.indicies.mode.content.button.no' | translate }}
      </button>
    </ng-container>

    <ng-template #changeIndiciesMode>
      <button
        mat-raised-button
        color="primary-inverse"
        [disabled]="disabled"
        class="mat-button-lg popup-actions-block__button"
        (click)="onChangeIndiciesMode()"
      >
        {{ 'services.popups.general.indicies.mode.content.button.change' | translate }}
      </button>
    </ng-template>
  </div>
</div>
