import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@app/material.module';
import { FlatFilterBottomSheetComponent } from './flat-filter-bottom-sheet.component';
import { ServiceFlatsFiltersModule } from '../../../containers/service-flats-filters';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [CommonModule, MaterialModule, ServiceFlatsFiltersModule, NgxTranslateModule],
  declarations: [FlatFilterBottomSheetComponent],
  exports: [FlatFilterBottomSheetComponent]
})
export class FlatFilterBottomSheetModule {}
