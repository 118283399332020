import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IntercomUpdateToolStatus } from '@app/shared/components';

@Component({
  selector: 'app-service-intercom-update-button',
  templateUrl: './service-intercom-update-button.component.html',
  styleUrls: ['./service-intercom-update-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceIntercomUpdateButtonComponent {
  readonly intercomUpdateToolStatus = IntercomUpdateToolStatus;

  @Input() status!: IntercomUpdateToolStatus;
  @Output() upgradeClicked: EventEmitter<void> = new EventEmitter();

  onUpgrade() {
    this.upgradeClicked.emit();
  }

  selectColor() {
    switch (this.status) {
      case IntercomUpdateToolStatus.SUCCESS:
        return 'success';
      case IntercomUpdateToolStatus.FAILED:
        return 'error';
      case IntercomUpdateToolStatus.PROGRESS:
        return 'primary-inverse';
      default:
        return 'warn';
    }
  }

  showSpinner() {
    if (
      this.status === IntercomUpdateToolStatus.STARTED ||
      this.status === IntercomUpdateToolStatus.PROGRESS
    ) {
      return true;
    }

    return false;
  }
}
