import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageGeneralKey, LocalStorageHelper } from '@app/shared/entities/common/local-storage';
import { AuthFacade } from '@app/views/auth/store';
import { PermissionsHelper, PermissionsService } from '../permissions';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private permissions: PermissionsService,
    private authFacade: AuthFacade
  ) { }

  canActivate(
    routeSnapshot: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    // Navigate depending on the routeLink
    const routeLink = PermissionsHelper.getLinkFromRouteData(routeSnapshot);

    if (!routeLink) {
      this.router.navigate(['/']);
      return false;
    }

    // Navigate depending on the JWT token
    const token: string = LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN);

    if (!token) {
      this.authFacade.logout();
      this.router.navigate(['/login'], { queryParams: { redirectURL: state.url } });
      return false;
    }

    // Navigate depending on the role permissions
    const { access, defaultRoute } = this.permissions.canAccessByRoute(routeLink);

    if (access) {
      return true;
    }

    if (defaultRoute) {
      this.router.navigate([defaultRoute]);
      return true;
    }

    this.router.navigate(['/']);
    return false;
  }

  isAuth(): boolean {
    return LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN) ? true : false;
  }
}
