import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { IConfig } from 'ngx-mask/lib/config';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { enterAllNumbers, maxDuration, minDuration } from './duration-input.validators';
import { DurationString, DurationRange } from './duration-input.models';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-duration-input',
  templateUrl: './duration-input.component.html',
  styleUrls: ['./duration-input.component.scss']
})
export class DurationInputComponent implements AfterViewInit, OnDestroy {
  durationMaskPatterns!: IConfig['patterns'];

  // Duration inputs must be in format: __:__
  @Input() readonly maxDuration: DurationString;
  @Input() readonly minDuration: DurationString;
  @Input() durationControl: FormControl<DurationString>;
  private durationRange!: DurationRange;
  private onDestroy$ = new Subject();

  ngAfterViewInit() {
    this.durationRange = new DurationRange(this.minDuration, this.maxDuration);

    this.setValidators();
    this.initMaskPatterns();
    this.addControlListener();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next('');
    this.onDestroy$.complete();
  }

  private setValidators(): void {
    this.durationControl.setValidators([
      Validators.required, 
      minDuration(this.minDuration),
      maxDuration(this.maxDuration), 
      enterAllNumbers
    ]);

    this.durationControl.updateValueAndValidity();
  }

  private initMaskPatterns(): void {
    const firstDigitPattern = new RegExp(`[${this.durationRange.firstDigitMinimum}-${this.durationRange.firstDigitMaximum}]`);
    
    this.durationMaskPatterns = {
      'm': { pattern: firstDigitPattern}, 
      '0': { pattern: /[0-9]/ }, 
      's': { pattern: /[0-5]/},
      'l': { pattern: /[0-7]/}
    }
  }

  private addControlListener(): void {
    this.durationControl.valueChanges.pipe(distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe(value => {
        const minuteFirstDigit = value.split('')[0];
        const minuteSecondDigit = value.split('')[1];

        if (!minuteFirstDigit) {
          return;
        }

        this.durationMaskPatterns['0'].pattern = this.getPatternForSecondDigit(minuteFirstDigit);

        if (minuteSecondDigit) {
          this.durationMaskPatterns['s'].pattern = this.getPatternForThirdDigit(minuteFirstDigit, minuteSecondDigit);
          this.durationMaskPatterns['l'].pattern = this.getPatternForFourthDigit(minuteFirstDigit, minuteSecondDigit);
        } 
    })
  }

  private getPatternForSecondDigit(firstMinute: string): RegExp {
    if (firstMinute !== this.durationRange.firstDigitMaximum) {
      return /[0-9]/;
    }

    return new RegExp(`[${this.durationRange.secondDigitMinimum}-${this.durationRange.secondDigitMaximum}]`);
  }

  private getPatternForThirdDigit(firstMinute: string, secondMinute: string): RegExp {
    if (firstMinute === this.durationRange.firstDigitMaximum && secondMinute === this.durationRange.secondDigitMaximum) {
      return /0/;
    }

    return /[0-5]/;
  }

  private getPatternForFourthDigit(firstMinute: string, secondMinute: string): RegExp {
    if (firstMinute === this.durationRange.firstDigitMaximum && secondMinute === this.durationRange.secondDigitMaximum) {
      return /0/;
    }

    return /[0-9]/;
  }
}