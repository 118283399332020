import { CallAbonentInfo } from './call-abonent-info.model';
import { CallResult } from './call-result.enum';

export interface CallEventInfo {
  callId: string;
  adapterUid: string;
  dialed: number;
  translated: number;
  dateCreated: string;
  dateConnected: string;
  dateRemoved: string;
  dateCall: string;
  respondedAbonent: CallAbonentInfo;
  alertedAbonents: CallAbonentInfo[];
  alertedAbonentsStr: string;
  result: CallResult;
  monitoringError: boolean;
  errorCode: string;
}
