import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { ServiceRdaWarningComponent } from './service-rda-warning.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        NgxTranslateModule
    ],
    declarations: [ServiceRdaWarningComponent],
    exports: [ServiceRdaWarningComponent]
})
export class ServiceRdaWarningModule { }
