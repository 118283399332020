import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

export const editAccountFormGroup = () => {
  const controls = {
    number: new UntypedFormControl(null, Validators.required),
  };
  return {
    form: new UntypedFormGroup(controls),
    controls: controls
  };
};
