import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared';
import { DialogWrapperModule, InfoTableModule, TextBadgeModule } from '@app/shared/ui';
import { IntercomInfoPopupComponent } from './intercom-info-popup.component';
import { IntercomInfoContentComponent } from './intercom-info-content';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        InfoTableModule,
        DialogWrapperModule,
        SharedPipesModule,
        TextBadgeModule,
        NgxTranslateModule,
    ],
    declarations: [
        IntercomInfoPopupComponent,
        IntercomInfoContentComponent
    ],
    exports: [
        IntercomInfoPopupComponent
    ]
})
export class IntercomInfoPopupModule { }
