<app-button-with-spinner-loader
  spinnerSize="25"
  [color]="selectColor()"
  [attr.ng-reflect-color]="selectColor()"
  [showSpinner]="showSpinner()"
  (clickEvent)="onUpgrade()"
>
  <ng-container [ngSwitch]="status">
    <ng-container *ngSwitchCase="intercomUpdateToolStatus.STARTED">
      {{ 'service.intercom.update.button.started' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="intercomUpdateToolStatus.SUCCESS">
      {{ 'service.intercom.update.button.success' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="intercomUpdateToolStatus.FAILED">
      {{ 'service.intercom.update.button.success' | translate }}
    </ng-container>

    <ng-container *ngSwitchCase="intercomUpdateToolStatus.PROGRESS">
      {{ 'service.intercom.update.button.progress' | translate }}
    </ng-container>

    <ng-container *ngSwitchDefault>
      {{ 'service.intercom.update.button.default' | translate }}
    </ng-container>
  </ng-container>
</app-button-with-spinner-loader>
