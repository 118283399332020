import { VideoPlayerMode } from './../../models';
import { PlyrTemplateHelper } from './plyr-template-helper';

export class PlyrVideoWrapperHelper {
    /**
     * Redraw video wrapper depending on mode state
     */
    static redrawVideoWrapper(mode: VideoPlayerMode) {
        const videoWrapper: HTMLDivElement =
            document.getElementsByClassName(PlyrTemplateHelper.VIDEO_WRAPPER_CLASS)[0] as HTMLDivElement;

        if (mode === VideoPlayerMode.LIVE) {
            videoWrapper.classList.remove(PlyrTemplateHelper.VIDEO_WRAPPER_ARCHIVE_STYLE);
            videoWrapper.classList.add(PlyrTemplateHelper.VIDEO_WRAPPER_LIVE_STYLE);
        } else if (mode === VideoPlayerMode.ARCHIVE) {
            videoWrapper.classList.remove(PlyrTemplateHelper.VIDEO_WRAPPER_LIVE_STYLE);
            videoWrapper.classList.add(PlyrTemplateHelper.VIDEO_WRAPPER_ARCHIVE_STYLE);
        }
    }
}
