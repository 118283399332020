import { Injectable } from '@angular/core';
import { MailingResponse } from '@app/views/abonents/models';
import { MailingService } from '@app/views/abonents/services';
import { PagedResponse } from '@app/shared/models';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap } from 'rxjs/operators';
import { ActionTypes, GetMailings, GetMailingsClear, GetMailingsFailure, GetMailingsSuccess, SendMailing, SendMailingClear, SendMailingFailure, SendMailingSuccess } from '../actions/mailing.actions';

@Injectable()
export class MailingEffects {
  @Effect()
  GetMailings$ = this.actions$.pipe(
    ofType<GetMailings>(ActionTypes.GetMailings),
    switchMap(action =>
      this.mailingService.getMailings(action.page, action.size).pipe(
        map((mailings: PagedResponse<MailingResponse>) => new GetMailingsSuccess(mailings.content, mailings.totalElements)),
        catchError(error => of(new GetMailingsFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  GetMailingsClear$ = this.actions$.pipe(
    ofType<GetMailingsClear>(ActionTypes.GetMailingsClear),
    tap(() => new GetMailingsClear())
  );

  @Effect()
  SendMailing$ = this.actions$.pipe(
    ofType<SendMailing>(ActionTypes.SendMailing),
    concatMap(action =>
      this.mailingService.sendMailing(action.mailing).pipe(
        map(mailing => new SendMailingSuccess(mailing)),
        catchError(error => of(new SendMailingFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  SendMailingClear$ = this.actions$.pipe(
    ofType<SendMailingClear>(ActionTypes.SendMailingClear),
    tap(() => new SendMailingClear())
  );

  constructor(
    private actions$: Actions,
    private mailingService: MailingService
  ) { }
}
