import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { GetRdaModels, GetRdaModelsSuccess, GetRdaModelsFailure, ActionTypes, CreateRdaModel, CreateRdaModelSuccess, CreateRdaModelFailure, GetRdaModelsClear, CreateRdaModelClear } from '../actions/rda-models.actions';
import { RdaModelService } from '@app/views/intercom/services';

@Injectable()
export class RdaModelsEffects {
  @Effect()
  GetRdaModels$ = this.actions$.pipe(
    ofType<GetRdaModels>(ActionTypes.GetRdaModels),
    switchMap(() =>
      this.rdaModelService.getModels().pipe(
        map(models => new GetRdaModelsSuccess(models)),
        catchError(error => of(new GetRdaModelsFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  GetRdaModelsClear$ = this.actions$.pipe(
    ofType<GetRdaModelsClear>(ActionTypes.GetRdaModelsClear),
    tap(() => new GetRdaModelsClear())
  );

  @Effect()
  CreateRdaModel$ = this.actions$.pipe(
    ofType<CreateRdaModel>(ActionTypes.CreateRdaModel),
    switchMap(action =>
      this.rdaModelService.createModel(action.name).pipe(
        map(() => new CreateRdaModelSuccess()),
        catchError(error => of(new CreateRdaModelFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  CreateRdaModelClear$ = this.actions$.pipe(
    ofType<CreateRdaModelClear>(ActionTypes.CreateRdaModelClear),
    tap(() => new CreateRdaModelClear())
  );

  constructor(private actions$: Actions, private rdaModelService: RdaModelService) { }
}
