import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { DialogWrapperModule } from '@app/shared/ui';
import { PenetrationReportContentComponent } from './penetration-report-content';
import { PenetrationReportPopupComponent } from './penetration-report-popup.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        DialogWrapperModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        NgxMatDatetimePickerModule,
        NgxTranslateModule
    ],
    declarations: [
        PenetrationReportContentComponent,
        PenetrationReportPopupComponent
    ],
    exports: [PenetrationReportPopupComponent]
})
export class PenetrationReportPopupModule { }
