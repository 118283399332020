<div class="new-user">
  <div
    class="new-user-info"
    [formGroup]="userForm">
    <span>
      {{ 'company.roles.create.header.user' | translate }}
    </span>
    <input
      placeholder="{{ 'company.roles.create.placeholder.email' | translate }}"
      formControlName="email"
      class="new-user-info__email"
      pInputText
      [control]="getFormControl('email')"
      appLowerCase/>
  </div>
  <div class="new-user-roles">
    <span>
      {{ 'company.roles.create.header.roles' | translate }}
    </span>
    <app-company-roles-management
      [allAvailableRoles]="allAvailableRoles"
      [mode]="RolesMode.create"
      [userResponse]="null"
      (access)="onAccess($event)"
    ></app-company-roles-management>
  </div>
  <div class="new-user-actions">
    <p-button
      [disabled]="userForm.invalid"
      [pTooltip]="
      userForm.get('email').valid  && userForm.get('roleNames').invalid ?
      ROLE_WARN_MESSAGE :
      null"
      (click)="onSubmit()"
    >
      {{ 'company.roles.create.button.submit' | translate }}
    </p-button>
  </div>
</div>
