import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { IpIntercomGenerateUrlsContainerModule } from '@app/shared/components/keys';
import { IpIntercomGenerateContentModule } from '@app/shared/components/rda/ip-intercom-generate-content';
import { SelectSearchModule } from '@app/shared/containers/select-search';
import { SharedDirectivesModule } from '@app/shared/directives';
import { IpRdaConnectionTypePipe, SharedPipesModule } from '@app/shared/pipes';
import { BottomSheetWrapperModule } from '@app/shared/templates';
import { InfoBlockModule } from '@app/shared/ui';
import { IpIntercomHandsetLockParamsContainerModule } from '../../keys/ip-intercom-handset-lock-params-container';
import { RdaSelectSearchModule } from './../rda-search-select/rda-search-select.module';
import { IncorrectIntercomTypeBottomSheetComponent } from './incorrect-intercom-type-bottom-sheet';
import { IntercomEditContentComponent } from './intercom-edit-content.component';
import { IpIntercomGenerateBottomSheetComponent } from './ip-intercom-generate-bottom-sheet';
import { IpIntercomHandsetLockBottomSheetComponent } from './ip-intercom-handset-lock-bottom-sheet';
import { IpIntercomUrlsBottomSheetComponent } from './ip-intercom-urls-bottom-sheet';
import {
  IpSputnikGenerateUrlsContainerModule
} from '@app/shared/components';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedPipesModule,
    BottomSheetWrapperModule,
    IpIntercomGenerateContentModule,
    IpIntercomGenerateUrlsContainerModule,
    IpIntercomHandsetLockParamsContainerModule,
    RdaSelectSearchModule,
    MaterialModule,
    SharedDirectivesModule,
    SelectSearchModule,
    InfoBlockModule,
    IpSputnikGenerateUrlsContainerModule,
    TranslateModule
  ],
  providers: [IpRdaConnectionTypePipe],
  declarations: [
    IntercomEditContentComponent,
    IpIntercomGenerateBottomSheetComponent,
    IpIntercomUrlsBottomSheetComponent,
    IpIntercomHandsetLockBottomSheetComponent,
    IncorrectIntercomTypeBottomSheetComponent,
  ],
  exports: [IntercomEditContentComponent],
})
export class IntercomEditContentModule {}
