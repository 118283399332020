type Predicate = (prop: any) => boolean;

export function removeEmpty(obj: any, ...predicates: Predicate[]) {
  const propNames = Object.getOwnPropertyNames(obj);

  for (const propName of propNames) {
    const value = obj[propName];

    if (value === null || value === undefined) {
      delete obj[propName];
      continue;
    }

    if (predicates && predicates.some(p => p(value))) {
      delete obj[propName];
    }
  }

  return obj;
}
