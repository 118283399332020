import { LogoutEventInfo } from './event-info/logout-event/logout-event-info.model';
import { SignInEventInfo } from './event-info/sign-in-event/sign-in-event-info.model';
import { KeyActivityEventInfo } from './event-info/key-activity-event/key-activity-event-info.model';
import { ShareAccessEventInfo } from './event-info/share-access-event/share-access-event-info.model';
import { VideoEventInfo } from './event-info/video-event/video-event-info.model';
import { CallEventInfo } from './event-info/call-event/call-event-info.model';

export interface EventInfo {
  callEventInfo: CallEventInfo;
  videoEventInfo: VideoEventInfo;
  shareAccessEventInfo: ShareAccessEventInfo;
  keyActivityEventInfo: KeyActivityEventInfo;
  signInEventInfo: SignInEventInfo;
  logOutEventInfo: LogoutEventInfo;
}
