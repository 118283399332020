import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RdaWarningsContentModule } from '@app/shared/components';
import { DialogWrapperModule } from '@app/shared/ui';
import { ServiceSupportRequestPopupComponent } from './service-support-request-popup.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    imports: [
        CommonModule,
        DialogWrapperModule,
        RdaWarningsContentModule,
        MatDialogModule
    ],
    declarations: [
        ServiceSupportRequestPopupComponent,
    ],
    exports: [
        ServiceSupportRequestPopupComponent
    ]
})
export class ServiceSupportRequestPopupModule { }
