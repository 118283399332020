import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { NavbarLink } from '@app/shared/ui';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent {
  @Input() links!: NavbarLink[];
  @Input() active: string;
  @Input() neutral = true;
  @Input() centered = false;
  @Output() private select: EventEmitter<{ idx: number }> = new EventEmitter();

  constructor(
    private translate: TranslateService
  ) {}

  onChangeActive(idx: number) {
    this.select.emit({ idx });
  }

  hideDivider(lastItem: boolean, idx: number): boolean {
    const curItemActive = this.links[idx].name === this.active;
    const nextItemActive = idx + 1 < this.links.length && this.links[idx + 1].name === this.active;

    if (lastItem || curItemActive || nextItemActive) {
      return true;
    }

    return false;
  }

  getText(text: string): string {
    const translated = this.translate.instant(text);
    return translated === text ? text : translated;
  }
}
