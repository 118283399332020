import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogWrapperData } from '@app/shared/ui';
import { ServiceCameraDeletePopupService } from './service-camera-delete-popup.service';

@Component({
  selector: 'app-service-camera-delete-popup',
  templateUrl: './service-camera-delete-popup.component.html',
  styleUrls: ['./service-camera-delete-popup.component.scss'],
  providers: [ServiceCameraDeletePopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceCameraDeletePopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<{ cameraId: number }, null>,
    public popupService: ServiceCameraDeletePopupService,
    private dialogRef: MatDialogRef<ServiceCameraDeletePopupComponent>,
  ) { }

  ngOnInit() {
    this.popupService.addStateChangeListener(state => state === 'close' && this.dialogRef.close());
  }

  onDelete() {
    this.data.submit(null);
  }
}
