<div class="payments-connection">
  <mat-card class="card-container">
    <mat-card-content class="payments-connection-content">
      <app-info-block>
        <img
          width="50px"
          info-block-image
          [src]="resourcesHelper.getResource(resourcePath.INFO_LG) | safeUrl"
          [srcset]="resourcesHelper.getResource(resourcePath.INFO_LG, true) | safeUrl"
        >
        <ng-container info-block-text>
          {{ 'payments.connection.message.connect' | translate }}
        </ng-container>

        <ng-container info-block-actions>
          <button
            mat-raised-button
            color="primary"
            class="mat-button-lg payments-connection-content__button"
            (click)="onOpenWizard()"
          >
            {{ 'payments.connection.button.open_wizard' | translate }}
          </button>
        </ng-container>
      </app-info-block>
    </mat-card-content>
  </mat-card>
</div>
