import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import {
  ActionTypes,
  GetShieldsByBatchNumber,
  GetShieldsByBatchNumberFailure,
  GetShieldsByBatchNumberSuccess,
  UpdateShield,
  UpdateShieldSuccess,
  UpdateShieldFailure,
  GetAllShields,
  GetAllShieldsSuccess,
  GetAllShieldsFailure,
  GetShieldsByBatchNumberClear,
  GetShieldTests,
  GetShieldTestsSuccess,
  GetShieldTestsFailure
} from '../actions/shields.actions';
import { ShieldService } from '@app/views/intercom/services/shield.service';

@Injectable()
export class ShieldsEffects {
  @Effect()
  GetShieldsByBatchNumber$ = this.actions$.pipe(
    ofType<GetShieldsByBatchNumber>(ActionTypes.GetShieldsByBatchNumber),
    switchMap(action =>
      this.shieldService.getShieldsByBatchNumber(action.companyId, action.batchNumber, action.isInstalled).pipe(
        map(shields => new GetShieldsByBatchNumberSuccess(shields)),
        catchError(error => of(new GetShieldsByBatchNumberFailure(error)))
      )
    )
  );

  @Effect({ dispatch: false })
  GetShieldsByBatchNumberClear$ = this.actions$.pipe(
    ofType<GetShieldsByBatchNumberClear>(ActionTypes.GetShieldsByBatchNumberClear),
    tap(() => new GetShieldsByBatchNumberClear())
  );

  @Effect()
  GetShieldTests$ = this.actions$.pipe(
    ofType<GetShieldTests>(ActionTypes.GetShieldTests),
    switchMap(action =>
      this.shieldService.getShieldTests(action.shieldId).pipe(
        map(shields => new GetShieldTestsSuccess(shields)),
        catchError(error => of(new GetShieldTestsFailure(error)))
      )
    )
  );

  @Effect()
  GetAllShields$ = this.actions$.pipe(
    ofType<GetAllShields>(ActionTypes.GetAllShields),
    switchMap(action =>
      this.shieldService.getAllShields(action.page, action.size, action.search).pipe(
        map(data => new GetAllShieldsSuccess(data.content, data.totalElements)),
        catchError(error => of(new GetAllShieldsFailure(error)))
      )
    )
  );

  @Effect()
  UpdateShield$ = this.actions$.pipe(
    ofType<UpdateShield>(ActionTypes.UpdateShield),
    switchMap(action =>
      this.shieldService.updateShield(action.payload).pipe(
        map(() => new UpdateShieldSuccess()),
        catchError(error => of(new UpdateShieldFailure(error)))
      )
    )
  );

  constructor(private actions$: Actions, private shieldService: ShieldService) { }
}
