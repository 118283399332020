export interface BatchResponse {
    id: number;
    number: number;
    count: Number;
    companyName: string;
    rdaModelName: string;
    shieldModelName: string;
    userName: string;
    createdAt: string;
}
