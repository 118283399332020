import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-service-indicies-mode-content',
  templateUrl: './service-indicies-mode-content.component.html',
  styleUrls: ['./service-indicies-mode-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceIndiciesModeContentComponent {
  @Input() disabled: boolean;
  @Input() indiciesMode: boolean;

  @Output() private selectIndiciesMode: EventEmitter<{ indiciesMode: boolean }> = new EventEmitter();

  onSelectIndiciesMode(indiciesMode: boolean) {
    this.selectIndiciesMode.emit({ indiciesMode });
  }

  onChangeIndiciesMode() {
    this.selectIndiciesMode.emit({ indiciesMode: null });
  }
}
