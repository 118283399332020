import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';

import { RdeaDate } from '@app/shared/entities/common';
import { VideoshotGetRequest } from '@app/shared/entities/integrations';
import { DialogWrapperData, DialogWrapperService } from '@app/shared/ui/dialog-wrapper';
import { VideoPlayerVideoshotRequest, VideoshotPopupState, VideoshotTimeData } from '../models';
import { VideoPlayerFacade, VideoPlayerState, SaveVideoshotTimeData } from '../store';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-video-player-videoshot-popup.component',
  templateUrl: './video-player-videoshot-popup.component.html',
  styleUrls: ['./video-player-videoshot-popup.component.scss'],
  providers: [VideoPlayerFacade],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerVideoshotPopupComponent implements OnInit, OnDestroy {
  state$: Observable<VideoshotPopupState> = this.videoPlayerFacade.videoshotState$;
  private videoshotSavedTimeData$: Observable<VideoshotTimeData> = this.videoPlayerFacade.videoshotTimeData$;
  private savedTimeData: VideoshotTimeData;
  private durationInSec: number;
  private dateFormatString: string;
  private videoshotRequest: VideoshotGetRequest;
  private onDestroy$ = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<VideoPlayerVideoshotRequest, null>,
    public dialogWrapperService: DialogWrapperService,
    private videoPlayerFacade: VideoPlayerFacade, 
    private store: Store<VideoPlayerState>,
  ) { }

  ngOnInit() {
    this.videoshotSavedTimeData$.pipe(takeUntil(this.onDestroy$)).subscribe(value => this.savedTimeData = value);
  }

  onCreateVideoshotTask(dateFrom: Date, durationInSeconds: number) {
    this.dateFormatString = this.prepareDateFormatString(dateFrom, durationInSeconds);
    this.durationInSec = durationInSeconds;
    
    const request: VideoshotGetRequest = {
      rdvaUri: this.data.body.rdvaUri,
      cameraId: this.data.body.cameraId,
      dateFormatString: this.dateFormatString,
      duration: this.durationInSec, 
      requestCancellation: this.onDestroy$
    };
    
    this.videoPlayerFacade.createVideoshotTask(request);
    this.store.dispatch(new SaveVideoshotTimeData({date: this.dateFormatString, durationInSeconds: durationInSeconds}));
  }

  onDownloadVideoshot() {
    const date = this.dateFormatString ?? this.savedTimeData.date;
    const duration = this.durationInSec ?? this.savedTimeData.durationInSeconds;

    this.videoshotRequest = {
      rdvaUri: this.data.body.rdvaUri,
      cameraId: this.data.body.cameraId,
      dateFormatString: date,
      duration: duration, 
      requestCancellation: this.onDestroy$
    };

    this.videoPlayerFacade.getVideoshot(this.videoshotRequest);
  }

  onInitVideoshotBuilding() {
    this.videoPlayerFacade.initVideoshotState();
    this.dateFormatString = null;
    this.durationInSec = null;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next('');
    this.onDestroy$.complete();

    this.dialogWrapperService.pendingState = false;
  }

  /**
   * Calculate and return middle date as string
   * @param dateFrom  start date covered
   * @param duration videoshot duration in seconds
   * @returns date string in {year}-{month}-{day}-{hour}-{minute}-{second} format
   */
  private prepareDateFormatString(dateFrom: Date, duration: number): string {
    const middleDate = new Date(dateFrom.getTime() + (duration * 1000) / 2);
    const middleDateWithTimezone = new RdeaDate(middleDate.getTime() + middleDate.getTimezoneOffset() * 60 * 1000);
    return middleDateWithTimezone.getDateTimeString({ dateDivider: '-', timeDivider: '-', partsDivider: '-' });
  }
}
