<div class="active-history-chart-row">
  <ngx-skeleton-loader
    count="1"
    [theme]="{ 'border-radius': '0px', height: '14px' }"
    *ngIf="loading; else contentBlock"
    class="active-history-chart-row-loader"
  ></ngx-skeleton-loader>

  <ng-template #contentBlock>
    <ng-container *ngIf="lineData !== undefined">
      <ng-container *ngFor="let index of makeRange(blocksCount)">
        <div
          class="active-history-chart-row-item"
          [matTooltip]="getTooltipText(index)"
          [class.active-history-chart-row-item--active]="isActive(index)"
          [class.active-history-chart-row-item--inactive]="isInactive(index)"
        ></div>
      </ng-container>
    </ng-container>
  </ng-template>
</div>