import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { FlatCardModule } from '@app/shared/components/connections/flat-card';
import { SharedPipesModule } from '@app/shared/pipes';
import { FlatPhoneModule } from '../flat-phone';
import { FlatStatusModule } from '../flat-status';
import { FlatCardsTableComponent } from './flat-cards-table.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        SharedPipesModule,
        FlatCardModule,
        FlatStatusModule,
        FlatPhoneModule,
        NgxTranslateModule
    ],
  declarations: [FlatCardsTableComponent],
  exports: [FlatCardsTableComponent]
})
export class FlatCardsTableModule {}
