import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CameraInfoPopupComponent } from '@app/shared/components/camera/camera-info-popup';
import { SnackbarService } from '@app/shared/components/snackbar';
import { LocalStorageGeneralKey, LocalStorageHelper, ResolutionService } from '@app/shared/entities/common';
import { Camera, CameraApiService } from '@app/shared/entities/rd';
import { Constants } from '@app/shared/helpers';
import { DialogWrapperData } from '@app/shared/ui';
import { CameraVideoPopupComponent } from './camera-video-popup/camera-video-popup.component';
import { CameraVideoPopupBody } from './camera-video-popup/camera-video-popup.model';
import { IConnectionInfo } from '@app/shared/components/camera/camera-already-exists/camera-already-exists.component';
import { VideoType } from '@app/shared/containers/camera/camera-thumbnail/models/videoType';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-camera-thumbnail',
  templateUrl: './camera-thumbnail.component.html',
  styleUrls: ['./camera-thumbnail.component.scss']
})
export class CameraThumbnailComponent implements OnInit {
  @Input() noActionOnClick: boolean;
  @Input() showHover = true;
  @Input() showInfo: boolean;
  @Input() camera: Camera;
  @Input() mode: VideoType;
  @Input() connectionInfo: IConnectionInfo;

  @Output() public onClick = new EventEmitter<Event>();

  private authToken: string;

  constructor(
    public cameraApiService: CameraApiService,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private resolution: ResolutionService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.authToken = LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN);
  }

  onVideo() {
    const data: DialogWrapperData<CameraVideoPopupBody, { message: string, type: 'info' | 'error' | 'success' }> = {
      title: this.translate.instant('shared.camera.thumbnail.video.title'),
      componentName: 'InfoCameraThumbnail',
      submit: (event: { message: string, type: 'info' | 'error' | 'success' }) =>
        this.snackbar.showMessage(event.message, event.type)
      ,
      body: {
        rdvaUri: this.camera.rdva?.uri ?? this.camera.rdvaUri,
        cameraId: this.camera.id,
        token: this.authToken,
        configuration: this.camera.configuration,
        active: this.camera.active,
        poster: this.cameraApiService.getImageThumbnail(this.camera.rdva?.uri ?? this.camera.rdvaUri, this.camera.id),
      }
    };

    this.dialog.open(CameraVideoPopupComponent, {
      panelClass: Constants.PLAYER_DIALOG_CLASS,
      backdropClass: Constants.PLAYER_DIALOG_BACKDROP_CLASS,
      disableClose: this.resolution.isMobile,
      data
    });
  }

  onInfo() {
    const data: DialogWrapperData<Camera, null> = {
      title: this.translate.instant('shared.camera.thumbnail.video.title'),
      componentName: 'InfoCameraThumbnail',
      body: this.camera
    };

    this.dialog.open(CameraInfoPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }
}
