import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SnackbarService } from '@app/shared/components/snackbar';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { Constants, parseError } from '@app/shared/helpers';
import { EditPopupComponentData } from '@app/shared/models';
import { LoaderService } from '@app/shared/entities/common/loader';
import { EditPopupComponent } from '@app/shared/templates';
import { State } from '@app/store/reducers';
import { BatchRequest, BatchResponse } from '@app/views/intercom/models';
import { GetBatchesPage, PutNewBatch } from '@app/views/intercom/store/actions';
import { PutNewBatchClear } from '@app/views/intercom/store/actions/batches.actions';
import { getAllBatchesStateLoading, getBatchesPageSuccess, putNewBatchError, putNewBatchLoading, putNewBatchSuccess } from '@app/views/intercom/store/states';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { AddBatchFormComponent } from './add-batch-form/add-batch-form.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-batches-page',
  templateUrl: './batches-page.component.html',
  styleUrls: ['./batches-page.component.scss']
})
export class BatchesPageComponent implements OnInit, OnDestroy {
  readonly displayedColumns: string[] = ['createdAt', 'number', 'count', 'companyName', 'rdaModelName', 'shieldModelName', 'userName'];
  readonly barcodeLength: number = 11;
  readonly pageSize: number = 10;
  batches$: Observable<{ dataSource: MatTableDataSource<BatchResponse>, totalCount: number }>;
  loading$: Observable<boolean>;
  dataSource: MatTableDataSource<BatchResponse>;
  filterInput = new UntypedFormControl();
  @ViewChild(MatPaginator, { static: true }) private paginator: MatPaginator;
  private onDestroy$: Subject<void>;
  private dialogRef: MatDialogRef<EditPopupComponent>;

  constructor(
    public resolution: ResolutionService,
    private store: Store<State>,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private loaderService: LoaderService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.initLoading();
    this.initStore();
    this.initFilter();
    this.initPaginator();
    this.loadBatches(0);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.store.dispatch(new PutNewBatchClear());
  }

  onCreate() {
    const data: EditPopupComponentData = {
      title: this.translateService.instant('intercom.batches.page.create.title'),
      providedData: {},
      component: AddBatchFormComponent,
      componentName: 'AddBatch',
      submit: (request: BatchRequest) => {
        if (request) {
          this.store.dispatch(new PutNewBatch(request));
        }
      }
    };

    this.dialogRef = this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '350px',
      data
    });
  }

  private initLoading() {
    combineLatest([
      this.store.select(getAllBatchesStateLoading),
      this.store.select(putNewBatchLoading)
    ])
      .subscribe((values: boolean[]) => this.loaderService.loaderState = { state: values.some((x: boolean) => x) });
  }

  private initStore() {
    this.onDestroy$ = new Subject();
    this.initStoreListeners();
    this.initStoreSelectors();
  }

  private initStoreListeners() {
    this.store.pipe(select(putNewBatchSuccess), takeUntil(this.onDestroy$))
      .subscribe(resp => {
        if (resp) {
          if (this.dialogRef) { this.dialogRef.close(); }
          this.loadBatches(0);
        }
      });

    this.store.pipe(select(putNewBatchError), takeUntil(this.onDestroy$))
      .subscribe(error => error && this.snackbar.showMessage(
        this.translateService.instant('intercom.batches.page.message.create.failed', {
          text: parseError(error)
        })
      ));
  }

  private initStoreSelectors() {
    this.batches$ = this.store.pipe(
      select(getBatchesPageSuccess),
      map(data => ({ dataSource: new MatTableDataSource(data.batches), totalCount: data.totalCount }))
    );
  }

  private initFilter() {
    this.filterInput.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.paginator.pageIndex = 0;
        this.loadBatches(0);
      });
  }

  private initPaginator() {
    this.paginator.page.subscribe((e: PageEvent) => this.loadBatches(e.pageIndex));
  }

  private loadBatches(page: number) {
    this.store.dispatch(new GetBatchesPage(page, this.paginator.pageSize, this.filterInput.value));
  }
}
