<mat-card class="card-container tarifs">
  <div class="header">
    <div class="title-block">
      {{ 'company.about.page.tariffs.title' | translate }}
    </div>
    <div
      class="payment-wrapper"
      *appRdeaCanAccess="permissionsTreeKey.BILLS">
      <app-component-loader
        *ngIf="loading"
        [sizePx]="30"></app-component-loader>
      <div *ngIf="payment" class="payment">
        {{ 'company.about.page.tariffs.field.current_payment' | translate }}:
        {{
          payment
            | currency : ('currency' | translate) : ""
            | currencyType : company?.country?.currency
        }}
      </div>
    </div>
  </div>
  <mat-card-content>
    <div class="tarif-block">
      <span class="sub-heading">
        {{ 'company.about.page.tariffs.header.intercom' | translate }}
        <ng-container *ngIf="smWDownBreakpoint">
          <div class="payment-wrapper" *appRdeaCanAccess="permissionsTreeKey.BILLS">
            <app-component-loader *ngIf="loading" [sizePx]="30" />

            <div class="payment" *ngIf="payment">
              {{ 'company.about.page.tariffs.field.current_payment' | translate }}:
              {{
                payment
                  | currency : ('currency' | translate) : ""
                  | currencyType : company?.country?.currency
              }}
            </div>
          </div>
        </ng-container>
      </span>
      <div class="tarif-content">
        <div class="tarif-value">
          <span class="text">
            {{ 'company.about.page.tariffs.field.hist_depth' | translate }}
          </span>
          <span class="time">
            {{ 'company.about.page.tariffs.field.hist_depth_hour' | translate:{
              value: company.intercomHistDepth
            } }}
          </span>
        </div>
        <div class="tarif-value">
          <span class="text">
            {{ 'company.about.page.tariffs.field.cost_one_camera' | translate }}
          </span>
          <span class="price">
            {{
              company.intercomCameraSum
                | currencyType : company?.country?.currency
            }}
          </span>
        </div>
        <div class="tarif-value">
          <span class="text">
            {{ 'company.about.page.tariffs.field.base_price' | translate }}
          </span>
          <span class="price">
            {{ company.baseSum | currencyType : company?.country?.currency }}
          </span>
        </div>
        <div class="tarif-value">
          <span class="text">
            {{ 'company.about.page.tariffs.field.price_subscriber_limit' | translate }}
          </span>
          <span class="price">
            {{
              company.subscribersOverLimitSum
                | currencyType : company?.country?.currency
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="tarif-block">
      <span class="sub-heading">
        {{ 'company.about.page.tariffs.header.video_surveillance' | translate }}
      </span>
      <div class="tarif-content">
        <div class="tarif-value">
          <span class="text">
            {{ 'company.about.page.tariffs.field.hist_depth' | translate }}
          </span>
          <span class="time">
            {{ 'company.about.page.tariffs.field.hist_depth_hour' | translate:{
              value: company.surveillanceHistDepth
            } }}
          </span>
        </div>
        <div class="tarif-value">
          <span class="text">
            {{ 'company.about.page.tariffs.field.cost_one_camera' | translate }}
          </span>
          <span class="price">
            {{
              company.surveillanceSum
                | currencyType : company?.country?.currency
            }}
          </span>
        </div>
      </div>
    </div>

    <div class="tarif-block">
      <span class="sub-heading">
        {{ 'company.about.page.tariffs.header.gate' | translate }}
      </span>

      <div class="tarif-content">
        <div class="tarif-value">
          <span class="text">
             {{ 'company.about.page.tariffs.field.hist_depth' | translate }}
          </span>
          <span class="time">
            {{ 'company.about.page.tariffs.field.hist_depth_hour' | translate:{
              value: company.gateHistDepth
            } }}
          </span>
        </div>
        <div class="tarif-value">
          <span class="text">
            {{ 'company.about.page.tariffs.field.cost_one_camera' | translate }}
          </span>
          <span class="price">
            {{
              company.gateCameraSum | currencyType : company?.country?.currency
            }}
          </span>
        </div>
        <div class="tarif-value">
          <span class="text">
            {{ 'company.about.page.tariffs.field.base_price' | translate }}
          </span>
          <span class="price">
            {{
              company.gateBaseSum | currencyType : company?.country?.currency
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="tarif-block">
      <span class="sub-heading">
        {{ 'company.about.page.tariffs.header.banner' | translate }}
      </span>

      <div class="tarif-content">
        <div class="tarif-value">
          <span class="text">
            {{ 'company.about.page.tariffs.field.cost_banner' | translate }}
          </span>
          <span class="price">
            {{ company.bannerSum | currencyType : company?.country?.currency }}
          </span>
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
