<form [formGroup]="form" (submit)="onSubmit()" autocomplete="off" class="form-block">
  <h3 class="form-block__title">
    {{
      startReportDateEnabled
        ? ('shared.reports.company.popup.message.specify_period_for_which_you_want_to_receive_report' | translate)
        : ('shared.reports.company.popup.message.specify_date_for_which_you_would_like_to_receive_report' | translate)
    }}
  </h3>
  <p class="form-block__paragraph">
    {{ 'shared.reports.company.popup.message.by_default_report_for_previous_date_will_be_loaded' | translate }}
  </p>

  <mat-form-field *ngIf="startReportDateEnabled" appearance="outline">
    <mat-label>
      {{ 'shared.reports.company.popup.content.field.start' | translate }}
    </mat-label>
    <input matInput
      [max]="maxDate"
      autocorrect="off"
      autocomplete="off"
      spellcheck="false"
      autocapitalize="off"
      formControlName="startReportDate"
      [matDatepicker]="startReportDatePicker"
      placeholder="2/02/2020"
    >
    <mat-datepicker-toggle matSuffix [for]="startReportDatePicker">
      <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #startReportDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>
      {{
        startReportDateEnabled
          ? ('shared.reports.company.popup.content.field.end' | translate)
          : ('shared.reports.company.popup.content.field.date' | translate)
      }}
    </mat-label>
    <input matInput
      [max]="maxDate"
      autocorrect="off"
      autocomplete="off"
      spellcheck="false"
      autocapitalize="off"
      formControlName="finishReportDate"
      [matDatepicker]="finishReportDatePicker"
      placeholder="2/02/2020"
    >
    <mat-datepicker-toggle matSuffix [for]="finishReportDatePicker">
      <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #finishReportDatePicker></mat-datepicker>
  </mat-form-field>

  <div class="form-block-actions">
    <button
      type="submit"
      color="primary"
      mat-raised-button
      class="form-block-actions__submit-button"
      [disabled]="loading"
    >
      {{ 'shared.reports.company.popup.content.button.submit' | translate }}
    </button>
  </div>
</form>
