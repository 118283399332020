import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

export const modelFormGroup = () => {
    const model = {
        modelId: new UntypedFormControl(null, Validators.required),
        filter: new UntypedFormControl(null)
    };
    return {
        form: new UntypedFormGroup(model),
        model: model
    };
};
