<div class="cron-date-input">
  <div class="cron-date-input-header" *ngIf="label">
    <mat-label>{{ label }}</mat-label>
  </div>

  <ng-container *ngIf="editMode; else readMode">
    <div class="cron-date-input-fields">
      <span>
        {{ 'shared.cron.date.input.every' | translate }}
      </span>
      <mat-icon svgIcon="info" [title]="infoTitle"></mat-icon>
      <mat-form-field
        appearance="outline"
        class="none-padding none-margin cron-date-input-fields-days"
      >
        <mat-label>{{ dayLabel }}</mat-label>

        <input
          matInput
          required
          type="number"
          min="1"
          max="28"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          (keydown)="onStopPropagation($event)"
          [formControl]="dayControl"
          [matAutocomplete]="daysAutocomplete"
        >
      </mat-form-field>

      <span>
        <nobr>
          {{ 'shared.cron.date.input.number_in' | translate }}
        </nobr>
      </span>

      <mat-autocomplete #daysAutocomplete="matAutocomplete">
        <mat-option *ngFor="let day of filteredDays$ | async" [value]="day">
          {{ day }}
        </mat-option>
      </mat-autocomplete>

      <mat-form-field
        appearance="outline"
        class="none-padding none-margin cron-date-input-fields-time"
      >
        <mat-label>{{ timeLabel }}</mat-label>

        <input
          matInput
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          type="time"
          required
          (keydown)="onStopPropagation($event)"
          [formControl]="timeControl"
        >
      </mat-form-field>
      <span>{{ 'shared.cron.date.input.or' | translate }} {{ 'shared.cron.date.input.last_day_of_month' | translate }}</span>
      <div>
        <mat-slide-toggle [formControl]="lastDayCheckedControl" required></mat-slide-toggle>
      </div>
    </div>
  </ng-container>

  <div class="cron-date-input-example">
    <mat-hint *ngIf="exampleDate">
      {{ 'shared.cron.date.input.nearest_launch' | translate }}
      {{ exampleDate | date: 'dd.MM.yyyy HH:mm:ss' }}
      ({{ exampleDate.getTimezoneName() }})
    </mat-hint>
  </div>
</div>

<ng-template #readMode>
  <div>
    {{ 'shared.cron.date.input.every' | translate }} 
    {{(dayControl.valueChanges | async) !== 'L' 
      ? ('shared.cron.date.input.last_day_of_month' | translate ) + ' ' + ( timeControl.valueChanges | async )
      : (dayControl.valueChanges | async) + ' ' + ('shared.cron.date.input.number_in' | translate ) + ' ' + ( timeControl.valueChanges | async )}} 
  </div>
</ng-template>
