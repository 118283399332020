import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Camera, IntercomPanelResponse, IntercomType, PbxOnRdaResponse, RdaResponse, ServiceResponse } from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';
import { Address, LocationResponse, RdeaStepperStep, TranslationTuningResponse } from '@app/shared/models';
import { DialogWrapperData } from '@app/shared/ui';
import { ServiceFacade } from '@app/views/services/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  SoftwareIntercomWizardCameraStepData,
  SoftwareIntercomWizardChecklistStepData,
  SoftwareIntercomWizardIntercomPanelStepData,
  SoftwareIntercomWizardIntercomStepData,
  SoftwareIntercomWizardPopupStep,
  SoftwareIntercomWizardPopupStepper,
  SoftwareIntercomWizardTariffStepData,
  SoftwareIntercomWizardTranslationsStepData,
  SoftwareIntercomWizardUpdateStep
} from './models';
import { SoftwareIntercomWizardPopupService } from './software-intercom-wizard-popup.service';
import { SoftwareIntercomWizardPopupStore } from './store';

@Component({
  selector: 'app-software-intercom-wizard-popup',
  templateUrl: './software-intercom-wizard-popup.component.html',
  styleUrls: ['./software-intercom-wizard-popup.component.scss'],
  providers: [SoftwareIntercomWizardPopupService, SoftwareIntercomWizardPopupStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomWizardPopupComponent implements OnInit {
  readonly wizardStep$: Observable<SoftwareIntercomWizardPopupStep> = this.popupStore.wizardStep$;
  readonly steps$: Observable<RdeaStepperStep[]> = this.popupStore.steps$;
  readonly showStepButtons$: Observable<boolean> = this.popupStore.showStepButtons$;
  readonly showMenu$: Observable<boolean> = this.popupStore.showMenu$;

  readonly addressStepState$: Observable<SoftwareIntercomWizardUpdateStep> = this.serviceFacade.entrances$.pipe(
    map((entrances: Address[]) => {
      const entrancesExists: boolean = entrances?.length > 0;
      const entrancesLoaded: boolean = entrances?.length !== undefined;

      return {
        complete: entrancesExists,
        initialized: entrancesLoaded
      };
    })
  );

  readonly intercomsStepState$: Observable<SoftwareIntercomWizardUpdateStep> =
    combineLatest([this.serviceFacade.rdas$, this.addressStepState$]).pipe(
      map(([intercoms, addressStepState]: SoftwareIntercomWizardIntercomStepData) => {
        const intercomsExists: boolean = intercoms?.length > 0;
        const intercomsLoaded: boolean = intercoms?.length !== undefined;
        const previousComplete: boolean = addressStepState.complete;

        return {
          complete: intercomsExists,
          initialized: intercomsLoaded,
          disabled: !previousComplete
        };
      })
    );

  readonly intercomPanelStepState$: Observable<SoftwareIntercomWizardUpdateStep> =
    combineLatest([this.serviceFacade.rdas$, this.intercomsStepState$]).pipe(
      map(([intercoms, intercomsStepState]: SoftwareIntercomWizardIntercomPanelStepData) => {
        const firstIntercomHasOnePanelWithIndex: boolean = intercoms?.[0]?.intercoms?.findIndex(intercomPanel => !!intercomPanel.index) !== -1;
        const intercomsLoaded: boolean = intercoms?.length !== undefined;
        const previousComplete: boolean = intercomsStepState.complete;
        const firstIntercomSupportIndices: boolean = (intercoms?.[0]?.intercomType?.protocol?.intercomIndexesRequired) ?? false;

        return {
          complete: firstIntercomHasOnePanelWithIndex,
          initialized: intercomsLoaded,
          disabled: !previousComplete || !firstIntercomSupportIndices,
          hidden: !firstIntercomSupportIndices
        };
      })
    );

  readonly translationStepState$: Observable<SoftwareIntercomWizardUpdateStep> =
    combineLatest([this.serviceFacade.rdas$, this.intercomsStepState$, this.serviceFacade.translationTunings$]).pipe(
      map(([intercoms, intercomsStepState]: SoftwareIntercomWizardTranslationsStepData) => {
        const intercomsLoaded: boolean = intercoms?.length !== undefined;
        const intercomComplete: boolean = intercomsStepState.complete;
        const firstIntercomIsIp: boolean = intercoms?.[0]?.intercomType?.protocol?.ipType ?? false;

        return {
          complete: true,
          initialized: intercomsLoaded,
          disabled: !intercomComplete,
          hidden: intercomsLoaded && intercoms.length === 0 ? true : firstIntercomIsIp
        };
      })
    );

  readonly camerasStepState$: Observable<SoftwareIntercomWizardUpdateStep> =
    combineLatest([this.serviceFacade.cameras$, this.addressStepState$]).pipe(
      map(([cameras, addressStepState]: SoftwareIntercomWizardCameraStepData) => {
        const camerasExists: boolean = cameras?.length > 0;
        const camerasLoaded: boolean = cameras?.length !== undefined;
        const addressComplete: boolean = addressStepState.complete;

        return {
          complete: camerasExists,
          initialized: camerasLoaded,
          disabled: !addressComplete
        };
      })
    );

  readonly tariffStepState$: Observable<SoftwareIntercomWizardUpdateStep> =
    combineLatest([this.serviceFacade.serviceTariff$, this.serviceFacade.rdas$, this.intercomsStepState$]).pipe(
      map(([tariff, intercoms, intercomsStepState]: SoftwareIntercomWizardTariffStepData) => {
        const intercomsComplete: boolean = !intercomsStepState.complete;
        const intercomsLoaded: boolean = intercoms?.length !== undefined;
        const tariffExist: boolean = !!tariff;

        return {
          complete: tariffExist,
          initialized: intercomsLoaded,
          disabled: intercomsComplete
        };
      })
    );

  readonly checklistStepState$: Observable<SoftwareIntercomWizardUpdateStep> =
    combineLatest([this.addressStepState$, this.intercomsStepState$, this.intercomPanelStepState$, this.translationStepState$, this.camerasStepState$]).pipe(
      map(([addressStepState, intercomsStepState, intercomPanelStepState, translationStepState, camerasStepState]: SoftwareIntercomWizardChecklistStepData) => {
        const addressComplete: boolean = addressStepState.complete;
        const intercomComplete: boolean = intercomsStepState.complete;
        const camerasComplete: boolean = camerasStepState.complete;

        const intercomPanelHidden: boolean = intercomPanelStepState.hidden;
        const intercomPanelComplete: boolean = intercomPanelStepState.complete;
        const translationHidden: boolean = translationStepState.hidden;
        const translationComplete: boolean = translationStepState.complete;

        return {
          disabled: !(
            addressComplete && intercomComplete &&
            (intercomPanelHidden ? true : intercomPanelComplete) &&
            (translationHidden ? true : translationComplete) &&
            camerasComplete
          )
        };
      })
    );

  readonly initialWizardStep$: Observable<SoftwareIntercomWizardPopupStep> = this.popupStore.steps$.pipe(
    map((steps: SoftwareIntercomWizardPopupStepper[]) =>
      steps.reduce((prev, cur) => prev && cur.initialized, true) ?
        steps.findIndex((step: SoftwareIntercomWizardPopupStepper) => !step.complete && !step.hidden && !step.disabled) :
        null
    )
  );

  intercoms$: Observable<RdaResponse[]> = this.serviceFacade.rdas$;
  serviceId$: Observable<number> = this.serviceFacade.serviceId$;
  cameras$: Observable<Camera[]> = this.serviceFacade.cameras$;
  onvif$: Observable<boolean> = this.serviceFacade.isActiveOnvif$;
  pbxOnRda$: Observable<Dictionary<PbxOnRdaResponse>> = this.serviceFacade.pbxOnRda$;
  addresses$: Observable<Address[]> = this.serviceFacade.entrances$;
  intercomTypes$: Observable<IntercomType[]> = combineLatest([
    this.serviceFacade.intercomTypes$,
    this.serviceFacade.rdas$
  ]).pipe(
    map(([intercomTypes, intercoms]: [IntercomType[], RdaResponse[]]) => {
      const allIntercomsIsIp: boolean = intercoms?.reduce((prev, cur) => prev && cur.intercomType?.protocol?.ipType, true);
      return intercoms.length > 1 && allIntercomsIsIp ? intercomTypes.filter(intercomType => intercomType.protocol.ipType) : intercomTypes;
    })
  );
  translationTunings$: Observable<Dictionary<TranslationTuningResponse[]>> = this.serviceFacade.translationTunings$;
  avaliableIntercomPanelsLocations$: Observable<LocationResponse[]> = this.serviceFacade.avaliableIntercomPanelsLocations$;
  camerasLocations$: Observable<LocationResponse[]> = this.serviceFacade.camerasLocations$;
  intercomPanels$: Observable<IntercomPanelResponse[]> = this.serviceFacade.intercomPanels$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<any, any>,
    public popupService: SoftwareIntercomWizardPopupService,
    private serviceFacade: ServiceFacade,
    private popupStore: SoftwareIntercomWizardPopupStore
  ) { }

  ngOnInit() {
    this.popupService.addStateChangeListener((state: 'loading' | 'loaded' | 'done') => {
      if (state === 'done') {
        this.popupStore.changeMenuVisible(true);
        this.popupStore.changeStepButtonsVisible(true);
      }
    });
  }
}
