import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, FileResponse } from '@app/shared/api';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class InvoiceApiService extends ApiService {
    constructor(
        httpClient: HttpClient,
        humanErrorTranslationService: HumanErrorTranslationService
    ) {
        super(httpClient, humanErrorTranslationService, environment.companiesApiUrl, ['v1']);
    }

    getInvoiceInPdf(inn: string): Observable<FileResponse> {
        return this.getFile(`/invoices/${inn}/pdf`, 1);
    }
}
