import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import { IntercomInfoContainerBody, IntercomInfoPopupComponent, IntercomUpdateToolHelper, IntercomUpdateToolStatus, SnackbarService } from '@app/shared/components';
import { GitlabComponentsVersions } from '@app/shared/entities/integrations';
import { IntercomPanelResponse, IntercomType, PbxOnRdaResponse, RdaResponse, RdaUpdateRequest, RdaUtilsService } from '@app/shared/entities/rd';
import { compareVersions, Constants, Dictionary } from '@app/shared/helpers';
import { Links } from '@app/shared/helpers/wiki-links.enum';
import { Address, ServicesTypes, TranslationTuningResponse } from '@app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData, DialogWrapperSize } from '@app/shared/ui';
import { ServiceFacade } from '@app/views/services/store';
import { ServiceDeleteIntercomPopupComponent, ServiceIntercomPopupBody, ServiceIntercomPopupComponent } from '../../../popups';
import { ServiceIntercomUpdatePopupComponent } from '../service-intercom-update-popup';
import {TranslateService} from '@ngx-translate/core';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';

@Component({
  selector: 'app-service-intercom',
  templateUrl: './service-intercom.component.html',
  styleUrls: ['./service-intercom.component.scss'],
  providers: [IntercomUpdateToolHelper],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceIntercomComponent implements OnDestroy {

  @Input() set intercom(intercom: RdaResponse) {
    this._intercom = intercom;
    this.rdaPro = this._intercom.description?.includes('mac') ? this._intercom.description : undefined;
    this.initUpdateTool();
    this.initRdaVariables();
    this.initIpIntercomVariables();
    this.initDisplayedColumns();

    if (this.intercom && this.componentsVersions) {
      this.initLatestVersions();
    }
  }
  @Input() set componentsVersions(componentsVersions: GitlabComponentsVersions) {
    this._componentsVersions = componentsVersions;

    if (this._componentsVersions && this.intercom) {
      this.initLatestVersions();
    }
  }
  @Input() set intercomPanels(intercomPanels: IntercomPanelResponse[]) {
    this._intercomPanels = intercomPanels;

    if (!intercomPanels) {
      return;
    }

    this.prepareIntercomsPanelsNames(intercomPanels);
  }
  @Input() entrances: Address[];
  @Input() intercoms: RdaResponse[];
  @Input() serviceType!: ServicesTypes;
  @Input() pbxOnRda!: Dictionary<PbxOnRdaResponse>;
  private _rdaPro: string;
  intercomPanelsNames: string[] = [];
  livenessUrl: string;
  rdosLatest: boolean;
  rdaaLatest: boolean;
  stmDriverLatest: boolean;
  displayedColumns: string[];
  invalidIntercomType: boolean;
  someComponentHasUpdate: boolean;
  updateStatus$: Observable<IntercomUpdateToolStatus>;
  intercomsNames: string[];
  xlWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.XL_W_DOWN);

  @Input() translationsDisabled: boolean;
  @Input() private intercomTypes!: IntercomType[];
  @Input() private translationTunings: Dictionary<TranslationTuningResponse[]>;
  private _popupComponentRef: MatDialogRef<ServiceIntercomPopupComponent, unknown> = null;
  private _intercom: RdaResponse;
  private _intercomPanels: IntercomPanelResponse[];
  private _componentsVersions: GitlabComponentsVersions;

  constructor(
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private rdaUtils: RdaUtilsService,
    private rdaService: RdaUtilsService,
    private resolution: ResolutionService,
    private serviceFacade: ServiceFacade,
    private intercomUpdateToolHelper: IntercomUpdateToolHelper,
    private translate: TranslateService
  ) {
    this._rdaPro = '';
  }

  ngOnDestroy(): void {
    this._popupComponentRef?.close();
  }

  get isIShield(): boolean {
    return this._intercom.description?.includes('iShield');
  }

  get rdaPro(): string {
    return this._rdaPro;
  }

  set rdaPro(description: string) {
    this._rdaPro = description?.match(/(:\w\w){3,}/gmi)[0].replace(/(:(\w\w))/gi, '$2');
  }

  get intercom(): RdaResponse {
    return this._intercom;
  }

  get componentsVersions(): GitlabComponentsVersions {
    return this._componentsVersions;
  }

  get intercomPanels(): IntercomPanelResponse[] {
    return this._intercomPanels;
  }

  onEdit(rda: RdaResponse) {
    const data: DialogWrapperData<Partial<ServiceIntercomPopupBody>, { request: RdaUpdateRequest }> = {
      title: this.translate.instant('service.intercom.edit.title'),
      componentName: `Update${this.serviceType}Rda`,
      body: {
        rda,
        onlyIpIntercoms: this.intercom.intercomType?.protocol?.ipType && this.intercoms?.length > 1
      },
      submit: (event: { request: RdaUpdateRequest }) => {
        console.log('____SUB');
        if (this.intercom.uid && this.intercom.uid !== event.request.uid) {
          this.serviceFacade.changeServiceRda(event.request, this.intercom.id);
        } else {
          this.serviceFacade.updateServiceRda(event.request.uid, event.request);
        }
      }
    };

    this._popupComponentRef = this.dialog.open(ServiceIntercomPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.MD,
      data
    });
  }

  onDelete() {
    const data: DialogWrapperData<{ rdaUid: string }, boolean> = {
      title: this.translate.instant('service.intercom.delete.title'),
      componentName: `Delete${this.serviceType}Rda`,
      body: { rdaUid: this.intercom.uid },
      submit: () => {
        this.serviceFacade.deleteServiceRda(this.intercom);
        this.serviceFacade.getServiceKeys();
      }
    };

    this.dialog.open(ServiceDeleteIntercomPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  onUpgrade(intercomUid: string) {
    const data: DialogWrapperData<{ intercomUid: string }, null> = {
      title: this.translate.instant('service.intercom.upgrade.title'),
      componentName: 'ServiceIntercomUpdate',
      body: { intercomUid }
    };

    this.dialog.open(ServiceIntercomUpdatePopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ? '100%' : '500px',
      data
    });
  }

  onInfo() {
    const data: DialogWrapperData<IntercomInfoContainerBody, null> = {
      title: this.translate.instant('service.intercom.info.title'),
      componentName: 'ServiceIntercomInfo',
      body: {
        intercom: this.intercom,
        isIpIntercom: this.isIpIntercom(),
        pbxOnRda: this.pbxOnRda[this.intercom.uid],
        livenessUrl: this.livenessUrl,
        gitlabComponentsVersions: this.componentsVersions,
        rdosLatest: this.rdosLatest,
        rdaaLatest: this.rdaaLatest
      }
    };

    this.dialog.open(IntercomInfoPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ? '100%' : '500px',
      data
    });
  }

  isIpIntercom() {
    return this.rdaUtils.ipType(this.intercom.intercomType);
  }

  private prepareIntercomsPanelsNames(intercomsPanels: IntercomPanelResponse[]) {
    this.intercomPanelsNames = intercomsPanels
      .map(intercomPanel => intercomPanel?.location?.name)
      .filter(intercomPanelName => intercomPanelName !== undefined);
  }

  private initRdaVariables() {
    if (this.isIpIntercom() || !this.intercom) {
      return;
    }

    const supportedIntercom: boolean = this.rdaService.supportedIntercom(this.intercom, this.intercom?.intercomType);
    const correctedVersions: boolean = this.rdaService.correctedVersions(this.intercom, this.intercom?.intercomType);

    if (supportedIntercom === false && correctedVersions === false) {
      this.invalidIntercomType = true;
    }
  }

  private initIpIntercomVariables() {
    if (!this.isIpIntercom() || !this.intercom) {
      return;
    }

    const { liveness } = this.rdaService.convertConfigStrToParams(this.intercom?.configStr);
    this.livenessUrl = liveness || '';
  }

  private initDisplayedColumns() {
    if (!this.intercom) {
      return;
    }

    if (this.isIpIntercom()) {
      this.displayedColumns = ['lastHeartBeatTime', 'mode', 'pbxOnRda', 'livenessUrl'];
    } else {
      this.displayedColumns = ['ip', 'lastHeartBeatTime', 'translation', 'versionInfoOs', 'versionInfoRdaa', 'versionInfoStmDriver', 'versionInfoStm', 'versionInfoShield'];
    }

    this.displayedColumns.push('actions');
  }

  private initLatestVersions() {
    this.rdosLatest = this.checkLatestVersion(
      this.intercom?.versionInfoOs,
      this.componentsVersions?.rdosLatest
    );

    this.rdaaLatest = this.checkLatestVersion(
      this.intercom?.versionInfoRdaa,
      this.componentsVersions?.rdaaLatest
    );

    this.someComponentHasUpdate = !this.rdosLatest || !this.rdaaLatest;
  }

  private initUpdateTool() {
    this.updateStatus$ = this.intercomUpdateToolHelper.initUpdateTool(
      this.isIShield ? this.rdaPro : this.intercom.uid,
      (updateRdaaJobSuccess: boolean, updateRdosJobSuccess: boolean) => {
        this.upgradeHandler(updateRdaaJobSuccess, updateRdosJobSuccess);
      }
    );
  }

  private upgradeHandler(updateRdaaJobSuccess: boolean, updateRdosJobSuccess: boolean) {
    if (updateRdaaJobSuccess || updateRdosJobSuccess) {
      this.serviceFacade.getServiceRdas();
    }

    if (!updateRdaaJobSuccess && !updateRdosJobSuccess) {
      this.snackbar.showMessage(this.translate.instant('service.intercom.message.failed_updating_adapter'), 'error');
      return;
    }

    if (updateRdaaJobSuccess && updateRdosJobSuccess) {
      this.snackbar.showMessage(this.translate.instant('service.intercom.message.success_updating_adapter'), 'success');
      return;
    }

    const tmp = [];
    tmp.push(this.translate.instant(updateRdaaJobSuccess ? 'service.intercom.message.success_updating_rdaa' : 'service.intercom.message.success_updating_rdaa'));
    tmp.push(this.translate.instant(updateRdosJobSuccess ? 'service.intercom.message.success_updating_rdos' : 'service.intercom.message.success_updating_rdos'));

    this.snackbar.showMessage(tmp.join('; '), 'info');
  }

  private checkLatestVersion(rdaVersion: string, latestVersion: string): boolean {
    const comparedVersions: number = compareVersions(rdaVersion, latestVersion);

    if (comparedVersions === null) {
      return null;
    }

    return comparedVersions >= 0;
  }

  public get link(): string {
    return Links.Offline;
  }
}
