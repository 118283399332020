import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectSearchModule } from '@app/shared/containers/select-search';
import { SharedPipesModule } from '@app/shared/pipes';
import { AddressSearchSelectComponent } from './address-search-select.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SelectSearchModule,
        SharedPipesModule
    ],
    declarations: [
        AddressSearchSelectComponent
    ],
    exports: [
        AddressSearchSelectComponent
    ]
})
export class AddressSearchSelectModule { }
