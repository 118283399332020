import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';
import { IntercomTypeUtils, RdaResponse } from '@app/shared/entities/rd';
import { SelectSearch } from '@app/shared/models';
import { Observable } from 'rxjs';
import { RdaSelectSearchParams, RdaSelectSearchResponse } from './models';
import { RdaSearchSelectStore } from './store';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-rda-search-select',
  templateUrl: './rda-search-select.component.html',
  styleUrls: ['./rda-search-select.component.scss'],
  providers: [RdaSearchSelectStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RdaSearchSelectComponent implements OnInit {
  @Input() set initialIntercoms(initialIntercoms: RdaResponse[]) {
    this._initialIntercoms = initialIntercoms;

    if (initialIntercoms) {
      this.store.setIntercoms(initialIntercoms);
    }
  }
  @Input() set initialIntercomSearchSelect(initialIntercomSearchSelect: SelectSearch) {
    this._initialIntercomSearchSelect = initialIntercomSearchSelect;

    this.initUsingIntercomSearchSelect(initialIntercomSearchSelect ?? null);
  }
  @Input() preload = false;
  @Input() required = true;
  @Input() showEmptyOption = false;
  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
    this.title = disabled
      ? this.translate.instant('shared.rda.search.select.adapter')
      : this.translate.instant('shared.rda.search.select.find_and_select_desired_adapter')
    ;
  }
  @Input() params: RdaSelectSearchParams = { ipType: null, inactive: null, mode: null };
  @Input() withoutDescription: boolean;

  title: string;
  intercomUid: string;
  loading$: Observable<boolean> = this.store.loading$;
  selectedIntercom$: Observable<RdaSelectSearchResponse> = this.store.selectedIntercom$;
  intercoms: RdaSelectSearchResponse[];

  @Output() private rdaSelected: EventEmitter<RdaSelectSearchResponse> = new EventEmitter();
  private _disabled: boolean;
  private _initialIntercoms: RdaResponse[];
  private _initialIntercomSearchSelect: SelectSearch;

  constructor(
    private store: RdaSearchSelectStore,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.store.addStorePreparedListener((intercoms: RdaSelectSearchResponse[], selectedIntercom: RdaSelectSearchResponse) => {
      if (!selectedIntercom) {
        this.onGetIntercoms();
        return;
      }

      // If don't need full intercom data
      if (!this.preload) {
        this.intercomUid = selectedIntercom.select.value as string;
        this.rdaSelected.emit(selectedIntercom);
        return;
      }

      // If full intercom data already loaded
      if (selectedIntercom.value) {
        this.intercomUid = selectedIntercom.value.uid;
        this.rdaSelected.emit(selectedIntercom);
        return;
      }

      // Check intercoms array
      const idx = intercoms.findIndex(intercom => intercom.select.value === selectedIntercom.select.value);

      selectedIntercom = intercoms[idx];

      if (selectedIntercom.value) {
        this.store.setSelectedIntercom(selectedIntercom);
        this.intercomUid = selectedIntercom.value.uid;
        this.rdaSelected.emit(intercoms[idx]);
      } else {
        this.onGetIntercoms({ value: selectedIntercom.select.value as string, select: true });
      }
    });

    this.store.addIntercomsListener((intercoms: RdaSelectSearchResponse[]) => this.intercoms = intercoms);
  }

  get disabled(): boolean {
    return this._disabled;
  }

  get initialIntercomSearchSelect(): SelectSearch {
    return this._initialIntercomSearchSelect;
  }

  get initialIntercoms(): RdaResponse[] {
    return this._initialIntercoms;
  }

  onGetIntercoms(
    { value = '', params = { ipType: this.params.ipType, inactive: this.params.inactive, mode: this.params.mode }, select = false }:
      { value?: string, params?: RdaSelectSearchParams, select?: boolean } = {}
  ) {
    this.store.getIntercoms({ value, params, select });
  }

  onSelectIntercom(value: string) {
    const idx: number = this.intercoms.findIndex(intercom => intercom.select.text === value);

    if (idx < 0) {
      return;
    }

    this.store.selectIntercomFromStore(idx);
    if (!this.intercoms.length) {
      this.onGetIntercoms();
    }
    this.rdaSelected.emit(this.intercoms[idx]);
  }

  private initUsingIntercomSearchSelect(selectedIntercom: SelectSearch) {
    this.store.setSelectedIntercom(selectedIntercom ? { value: null, select: selectedIntercom } : null);
  }

  initUsingIntercom(intercom: RdaResponse) {
    const selectedIntercom = {
      value: intercom,
      select: {
        text: intercom.uid,
        value: intercom.uid,
        badge: intercom.mode ? IntercomTypeUtils.getIpIntercomConnectionTypeText(intercom.mode, this.translate) : null
      }
    };

    this.intercomUid = intercom.uid;
    this.store.setSelectedIntercom(selectedIntercom);
    this.rdaSelected.emit(selectedIntercom);
  }
}
