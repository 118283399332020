<app-select-search
  [title]="title"
  [required]="false"
  [(ngModel)]="pbxOnRdaUid"
  [valuesList]="pbxOnRdas | map:['select']"
  [selectedValue]="selectedPbxOnRda$ | async"
  [disabled]="disabled"
  [showEmptyOption]="true"
  [nonePadding]="withoutDescription"
  [noneMargin]="withoutDescription"
  [loading]="loading$ | async"
  (searchChanged)="onGetPbxOnRdaList($event.value)"
  (valueSelected)="onSelectPbxOnRda($event.value)"
></app-select-search>