import * as Plyr from 'plyr';
import {Subject} from 'rxjs';
import {VideoPlayerMode} from '../models';
import {PlyrTemplateHelper, PlyrVideoWrapperHelper} from './plyr-globals';
import {TranslateService} from '@ngx-translate/core';
import { ControlElementDisplayValue } from '../models/video-player-controls-display-value.enum';

export class RdeaPlyr extends Plyr {
  mode: VideoPlayerMode;
  hasReleId = false;
  loadingState$: Subject<boolean> = new Subject();
  translate: TranslateService;

  /**
   * Overrided Plyr constructor with video HTML selector and default Plyr options
   * @param initialMode
   * @param {HTMLMediaElement} media video HTML selector
   * @param translate
   * @param param1 object with custom Plyr config (default values if it's empty)
   */
  constructor(
    initialMode: VideoPlayerMode,
    media: HTMLVideoElement,
    translate: TranslateService,
    options: Plyr.Options = {
      autoplay : true,
      controls : PlyrTemplateHelper.getControls(translate),
      invertTime : false,
      clickToPlay : false,
      fullscreen : {enabled: false},
    },
    noInitElements = false,
  ) {
    super(media, {...options});
    this.translate = translate;
    if (noInitElements) {
      return;
    }
    
    this.initMode(initialMode);
    this.enableLoadingListener();
  }

  /**
   * Set new mode state and redraw Plyr controls
   * @param mode video player mode for redraw player interface
   * @param playlistElementsCount playlist elements count for redraw player interface
   */
  initMode(mode: VideoPlayerMode, playlistElementsCount?: number | undefined) {
    this.mode = mode;
    PlyrVideoWrapperHelper.redrawVideoWrapper(this.mode);
    this.redrawControls(playlistElementsCount);
  }

  /**
   * Redraw playlists menu button depending on expaneded variable
   * @param expanded new expanded state for playlists menu button
   */
  redrawPlaylistsMenuButton(expanded: boolean) {
    document.getElementById(PlyrTemplateHelper.PLAYLISTS_SELECTOR_ID)
      .style.display = expanded ? 'inline' : 'none';

    document.getElementById(PlyrTemplateHelper.PLAYLISTS_MENU_BUTTON_ID)
      .setAttribute('aria-expanded', expanded.toString());
  }

  /**
   * Redraw events menu button depending on expaneded variable
   * @param expanded new expanded state for events menu button
   */
  redrawEventsMenuButton(expanded: boolean) {
    document.getElementById(PlyrTemplateHelper.EVENTS_SELECTOR_ID)
      .style.display = expanded ? 'inline' : 'none';

    document.getElementById(PlyrTemplateHelper.EVENTS_MENU_BUTTON_ID)
      .setAttribute('aria-expanded', expanded.toString());
  }

  /**
   * Enable Plyr events for change loading state when player is playing, seeking or starting data loading
   */
  private enableLoadingListener() {
    this.on('playing', () => this.loadingState$.next(false));
    this.on('seeking', () => this.loadingState$.next(true));
    this.on('seeked', () => this.loadingState$.next(false));
    this.on('loadstart', () => this.loadingState$.next(true));
  }

  /**
   * Redraw controls depending on mode state
   */
  private redrawControls(playlistElementsCount?: number | undefined) {
    let archiveButtonText: string;
    let displayMode: string;
    let controlsClassNames: string;

    switch (this.mode) {
      case VideoPlayerMode.ARCHIVE:
        archiveButtonText = 'LIVE';
        displayMode = ControlElementDisplayValue.BLOCK;
        controlsClassNames = `${PlyrTemplateHelper.CONTROLS_STYLE} ${PlyrTemplateHelper.CONTROLS_ARCHIVE_STYLE}`;
        break;
      default:
        archiveButtonText = this.translate.instant('shared.video_player.rdea.plyr.button.archive');
        displayMode = ControlElementDisplayValue.NONE;
        controlsClassNames = `${PlyrTemplateHelper.CONTROLS_STYLE} ${PlyrTemplateHelper.CONTROLS_LIVE_STYLE}`;
    }

    document.getElementById(PlyrTemplateHelper.EVENTS_OPEN_DOOR_ID).style.display = 
      !this.hasReleId ? ControlElementDisplayValue.NONE : ControlElementDisplayValue.BLOCK;
    document.getElementById(PlyrTemplateHelper.CHANGE_MOD_ID).innerText = archiveButtonText;
    document.getElementById(PlyrTemplateHelper.CONTROLS_ID).className = controlsClassNames;

    [
      PlyrTemplateHelper.GET_VIDEOSHOT_ID,
      PlyrTemplateHelper.TIMELINE_BLOCK_ID,
      PlyrTemplateHelper.PLAYLIST_MENU_ID,
      PlyrTemplateHelper.EVENTS_MENU_ID,
      PlyrTemplateHelper.PROGRESS_TIME_ID,
      PlyrTemplateHelper.PROGRESS_BAR_PICKER_ID,
    ].forEach(elementId => {
      if (elementId === PlyrTemplateHelper.GET_VIDEOSHOT_ID && !playlistElementsCount) {
        document.getElementById(elementId).style.display = ControlElementDisplayValue.NONE;
        return;
      }

      document.getElementById(elementId).style.display = displayMode;
    });
  }
}
