<div class="popup pbx-on-rda-edit-content">
  <h3 class="pbx-on-rda-edit-content__title">
    {{ 'shared.pbx_on_rda.edit.content.title' | translate }}
  </h3>

  <app-pbx-on-rda-search-select
    #pbxOnRdaSearchSelect
    [pbxOnRdaList]="initialPbxOnRda ? [initialPbxOnRda] : []"
    [disabled]="loading"
    [initPbxOnRdaSelect]="pbxOnRda"
    [withoutDescription]="true"
    (pbxOnRdaSelected)="onSelectPbxOnRda($event.value, $event.select)"
  ></app-pbx-on-rda-search-select>

  <div class="popup-actions">
    <button
      color="primary"
      mat-raised-button
      class="popup-actions__button"
      [disabled]="loading"
      (click)="onSubmit()"
    >
      {{ 'shared.pbx_on_rda.edit.content.button.submit' | translate }}
    </button>
  </div>
</div>
