import { PermissionsTreeMonitoringEntrances } from './permissions-tree/permissions-tree-monitroing-entrances';
import { UserRoleType } from '@app/core/models';
import {
  PermissionsTreeBase,
  PermissionsTreeBlockedUser,
  PermissionsTreeDemo,
  PermissionsTreeEngineer,
  PermissionsTreeEngineerAbonents,
  PermissionsTreeEngineerCameras,
  PermissionsTreeEngineerEntrances,
  PermissionsTreeEngineerGates,
  PermissionsTreeEngineerSignUps,
  PermissionsTreeEngineerVideoSurveillance,
  PermissionsTreeManagerBills,
  PermissionsTreeManagerMailings,
  PermissionsTreeManufacturer, PermissionsTreePersonalSurveillance,
  PermissionsTreeVideodispatcher
} from '@app/security';
import { PermissionsTreeSupport } from './permissions-tree/permissions-tree-support';
import { PermissionsTreeOwner } from '@app/security/permissions/permissions-tree/permissions-tree-owner';
import { PermissionsTreeAdmin } from '@app/security/permissions/permissions-tree/permissions-tree-admin';

export class PermissionsTreeBuilder {
    static rolesConstructors: Partial<Record<UserRoleType, any>> = {
        [UserRoleType.ROLE_SUPPORT]: PermissionsTreeSupport,
        [UserRoleType.ROLE_OWNER]: PermissionsTreeOwner,
        [UserRoleType.ROLE_ADMINISTRATOR]: PermissionsTreeAdmin,
        [UserRoleType.ROLE_ENGINEER]: PermissionsTreeEngineer,
        [UserRoleType.ROLE_ENGINEER_ENTRANCES]: PermissionsTreeEngineerEntrances,
        [UserRoleType.ROLE_ENGINEER_ABONENTS]: PermissionsTreeEngineerAbonents,
        [UserRoleType.ROLE_ENGINEER_SIGNUPS]: PermissionsTreeEngineerSignUps,
        [UserRoleType.ROLE_MANUFACTURER]: PermissionsTreeManufacturer,
        [UserRoleType.ROLE_MANAGER_MAILINGS]: PermissionsTreeManagerMailings,
        [UserRoleType.ROLE_ENGINEER_CAMERAS]: PermissionsTreeEngineerCameras,
        [UserRoleType.ROLE_ENGINEER_VIDEO_SURVEILLANCE]: PermissionsTreeEngineerVideoSurveillance,
        [UserRoleType.ROLE_ENGINEER_GATES]: PermissionsTreeEngineerGates,
        [UserRoleType.ROLE_MONITORING_ENTRANCES]: PermissionsTreeMonitoringEntrances,
        [UserRoleType.BLOCKED_USER]: PermissionsTreeBlockedUser,
        [UserRoleType.ROLE_VIDEODISPATCHER]: PermissionsTreeVideodispatcher,
        [UserRoleType.ROLE_ENGENEER_PERSONAL_SURVEILLANCE]: PermissionsTreePersonalSurveillance,
    };

    static rolesMixinsConstructors: Partial<Record<UserRoleType, any>> = {
        [UserRoleType.ROLE_DEMO]: PermissionsTreeDemo,
        [UserRoleType.ROLE_DEMO_DHCP]: PermissionsTreeDemo,
        [UserRoleType.ROLE_MANAGER_BILLS]: PermissionsTreeManagerBills
    };

    static buildRolesPermissions(roleTypes: UserRoleType[]) {
        if (!roleTypes) {
            return null;
        }

        const userRolesPermissions: { [key: string]: PermissionsTreeBase } = {};

        roleTypes.forEach((roleType: UserRoleType) =>
            PermissionsTreeBuilder.rolesConstructors[roleType] &&
            (userRolesPermissions[roleType] = new PermissionsTreeBuilder.rolesConstructors[roleType]())
        );

        return userRolesPermissions;
    }

    static buildRolesMixinsPermissions(roleMixinsTypes: UserRoleType[]) {
        if (!roleMixinsTypes) {
            return null;
        }

        const userRolesMixinsPermissions: { [key: string]: PermissionsTreeBase } = {};

        roleMixinsTypes.forEach((roleType: UserRoleType) =>
            PermissionsTreeBuilder.rolesMixinsConstructors[roleType] &&
            (userRolesMixinsPermissions[roleType] = new PermissionsTreeBuilder.rolesMixinsConstructors[roleType]())
        );

        return userRolesMixinsPermissions;
    }
}
