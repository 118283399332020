import { SignUpResponse, WaitAbonentSignUpsCount } from '@app/shared/models';
import { NavbarLink } from '@app/shared/ui';
import { SignUpsPageMode } from '@app/views/abonents/models';
import { SignUpsPageTableOptions } from '../models';

export interface SignUpsPageState {
    page: number;
    size: number;
    loading: boolean;

    filterInputValue: string;
    signUps: SignUpResponse[];
    totalCount: number;
    activeLink: SignUpsPageMode;
    savedActiveLink: SignUpsPageMode;
    waitAbonentSignUpsCount: WaitAbonentSignUpsCount;
    showWarning: boolean;
    links: NavbarLink[];
    tableOptions: SignUpsPageTableOptions;
}

export const signUpsPageInitialState = {
    page: 0,
    size: 10,
    loading: false,

    filterInputValue: null,
    signUps: null,
    totalCount: 0,
    activeLink: SignUpsPageMode.UNPROCESSED,
    savedActiveLink: null,
    waitAbonentSignUpsCount: {
        olderThanWeek: 0,
        olderThanMonth: 0
    },
    showWarning: false,
    links: [
        { name: SignUpsPageMode.UNPROCESSED, text: 'sign_ups.page.enum.state.unprocessed' },
        { name: SignUpsPageMode.PROCESSED, text: 'sign_ups.page.enum.state.processed' },
        { name: SignUpsPageMode.WAIT_ABONENT_CONFIRMATION, text: 'sign_ups.page.enum.state.wait_abonent_confirmation' },
        { name: SignUpsPageMode.COMPLETE, text: 'sign_ups.page.enum.state.complete' },
        { name: SignUpsPageMode.BY_REGION, text: 'sign_ups.page.enum.state.by_region' },
        { name: SignUpsPageMode.BUILDING, text: 'sign_ups.page.enum.state.building' }
    ],
    tableOptions: {
        byRegion: null,
        withoutCompany: null,
        displayedColumns: null,
        statuses: null
    }
};
