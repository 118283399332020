import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PenetrationReportRequest } from '@app/shared/entities/rd';
import { DialogWrapperData, DialogWrapperService } from '@app/shared/ui/dialog-wrapper';

@Component({
  selector: 'app-penetration-report-popup',
  templateUrl: './penetration-report-popup.component.html',
  styleUrls: ['./penetration-report-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PenetrationReportPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogWrapperData<{ companyId: number }, PenetrationReportRequest>,
    public dialogWrapperService: DialogWrapperService
  ) { }

  onSubmit(request: PenetrationReportRequest) {
    this.data.submit(request);
  }
}
