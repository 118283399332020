import { Component } from '@angular/core';
import { MailingResponse } from '@app/views/abonents/models';
import { MailingService } from '@app/views/abonents/services';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';

@Component({
  selector: 'app-mailing-page',
  templateUrl: './mailing-page.component.html',
  styleUrls: ['./mailing-page.component.scss']
})
export class MailingPageComponent {
  mailing: MailingResponse;
  selectedMailingIdx: number;

  constructor(
    private mailingService: MailingService,
    private resolutionService: ResolutionService
  ) { }

  onExit() {
    this.mailing = null;
    this.selectedMailingIdx = null;
  }

  onSelectMailing(event: { mailing: MailingResponse, idx: number }) {
    this.selectedMailingIdx = event.idx;
    this.mailing = event.mailing;
  }

  onRepeatMailing(event: { mailing: MailingResponse }) {
    this.mailing = this.mailingService.initEmptyMailingResponse();
    this.mailing.addressCriteria = event.mailing.addressCriteria;
    this.mailing.message = event.mailing.message;
    this.selectedMailingIdx = -1;
  }

  onSubmit() {
    this.mailing = null;
    this.selectedMailingIdx = null;
  }

  isMobile(): boolean {
    return this.resolutionService.isMobile;
  }
}
