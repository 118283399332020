import { Component, Input } from '@angular/core';
import { ResolutionService } from '@app/shared/entities/common';
import { RDOS_IMAGES_STORAGE } from '@app/shared/helpers';
import { RdosImage } from '@app/views/dashboard/models';

@Component({
  selector: 'app-dashboard-rdos-images',
  templateUrl: './dashboard-rdos-images.component.html',
  styleUrls: ['./dashboard-rdos-images.component.scss']
})
export class DashboardRdosImagesComponent {
  readonly displayedColumns: string[] = ['version', 'releaseMessage', 'createdAt', 'actions'];

  @Input() rdosImages!: RdosImage[];

  constructor(
    public resolution: ResolutionService
  ) { }

  onOpenLink(rdosImage: RdosImage) {
    window.open(rdosImage.link ?? RDOS_IMAGES_STORAGE);
  }

  onGetLastVersion() {
    window.open(this.rdosImages[0].link ?? RDOS_IMAGES_STORAGE);
  }
}
