import { GitlabComponentsVersions } from '@app/shared/entities/integrations';
import { Company, IntercomType, ServiceResponse } from '@app/shared/entities/rd';
import { UpdateServiceRequest } from '@app/views/services/models';
import { Action } from '@ngrx/store';
import { ServicePageMode } from './../../models';
import { ServicesActionTypes } from './services-action-types.actions';

export class GetService implements Action {
    readonly type = ServicesActionTypes.GetService;

    constructor(public serviceId: number) { }
}

export class GetDependantService implements Action {
    readonly type = ServicesActionTypes.GetDependantService;

    constructor(public serviceId: number) { }
}

export class GetDependantServiceSuccess implements Action {
    readonly type = ServicesActionTypes.GetDependantServiceSuccess;

    constructor(public response: ServiceResponse) { }
}

export class GetServiceSuccess implements Action {
    readonly type = ServicesActionTypes.GetServiceSuccess;

    constructor(public response: ServiceResponse) { }
}

export class GetServiceFailure implements Action {
    readonly type = ServicesActionTypes.GetServiceFailure;

    constructor() { }
}

export class UpdateService implements Action {
    readonly type = ServicesActionTypes.UpdateService;

    constructor(public request: UpdateServiceRequest) { }
}

export class UpdateServiceSuccess implements Action {
    readonly type = ServicesActionTypes.UpdateServiceSuccess;

    constructor(public response: UpdateServiceRequest) { }
}

export class UpdateServiceFailure implements Action {
    readonly type = ServicesActionTypes.UpdateServiceFailure;

    constructor() { }
}

export class GetServiceCompany implements Action {
    readonly type = ServicesActionTypes.GetServiceCompany;

    constructor(public companyId: number) { }
}

export class GetServiceCompanySuccess implements Action {
    readonly type = ServicesActionTypes.GetServiceCompanySuccess;

    constructor(public response: Company) { }
}

export class GetServiceCompanyFailure implements Action {
    readonly type = ServicesActionTypes.GetServiceCompanyFailure;

    constructor() { }
}

export class GetIntercomTypes implements Action {
    readonly type = ServicesActionTypes.GetIntercomTypes;

    constructor() { }
}

export class GetIntercomTypesSuccess implements Action {
    readonly type = ServicesActionTypes.GetIntercomTypesSuccess;

    constructor(public response: IntercomType[]) { }
}

export class GetIntercomTypesFailure implements Action {
    readonly type = ServicesActionTypes.GetIntercomTypesFailure;

    constructor() { }
}

export class GetComponentsVersions implements Action {
    readonly type = ServicesActionTypes.GetComponentsVersions;

    constructor() { }
}

export class GetComponentsVersionsSuccess implements Action {
    readonly type = ServicesActionTypes.GetComponentsVersionsSuccess;

    constructor(public response: GitlabComponentsVersions) { }
}

export class GetComponentsVersionsFailure implements Action {
    readonly type = ServicesActionTypes.GetComponentsVersionsFailure;

    constructor() { }
}

export class ServiceSendEmergencyAlert implements Action {
    readonly type = ServicesActionTypes.ServiceSendEmergencyAlert;

    constructor(public rdaUid: string) { }
}

export class ServiceSendEmergencyAlertSuccess implements Action {
    readonly type = ServicesActionTypes.ServiceSendEmergencyAlertSuccess;

    constructor() { }
}

export class ServiceSendEmergencyAlertFailure implements Action {
    readonly type = ServicesActionTypes.ServiceSendEmergencyAlertFailure;

    constructor() { }
}

export class AddDependantService implements Action {
    readonly type = ServicesActionTypes.AddDependantService;

    constructor(public dependantServices: Pick<ServiceResponse, 'id' | 'type'>[]) { }
}

export class SetPageMode implements Action {
    readonly type = ServicesActionTypes.SetPageMode;

    constructor(public pageMode: ServicePageMode) { }
}

export class ClearService implements Action {
    readonly type = ServicesActionTypes.ClearService;

    constructor() { }
}

export type ServicesGeneralActions =
    | GetService
    | GetServiceSuccess
    | GetServiceFailure

    | GetDependantService
    | GetDependantServiceSuccess

    | UpdateService
    | UpdateServiceSuccess
    | UpdateServiceFailure

    | GetServiceCompany
    | GetServiceCompanySuccess
    | GetServiceCompanyFailure

    | GetIntercomTypes
    | GetIntercomTypesSuccess
    | GetIntercomTypesFailure

    | GetComponentsVersions
    | GetComponentsVersionsFailure
    | GetComponentsVersionsSuccess

    | ServiceSendEmergencyAlert
    | ServiceSendEmergencyAlertSuccess
    | ServiceSendEmergencyAlertFailure

    | AddDependantService
    | SetPageMode
    | ClearService;
