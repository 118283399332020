import { Injectable } from '@angular/core';
import { LocalStorageDashboardKey, LocalStorageHelper } from '@app/shared/entities/common';
import { CompanyReportRequest, LicenseeReportRequest, PenetrationReportRequest, ServiceCreateRequest, SignUpsReportRequest } from '@app/shared/entities/rd';
import { ServicesTypes } from '@app/shared/models';
import { State } from '@app/store/reducers';
import { DashboardCamerasCounts, DashboardGatesCounts, DashboardRdasCounts, DashboardSoftwareIntercomsCounts, DashboardVideoSurveillanceCounts, RdosImage } from '@app/views/dashboard/models';
import { DashboardCreateService, DashboardGetBlockedUserInvoiceReport, DashboardGetCamerasCounts, DashboardGetCompanyReport, DashboardGetGatesCounts, DashboardGetLicenseeReport, DashboardGetPenetrationReport, DashboardGetRdasCounts, DashboardGetRdosImages, DashboardGetSignUpsReport, DashboardGetSoftwareIntercomsCounts, DashboardGetUsageStatisticsReport, DashboardGetVideoSurveillanceCounts } from '@app/views/dashboard/store/dashboard.actions';
import { dashboardSelector } from '@app/views/dashboard/store/dashboard.selectors';
import { DashboardState } from '@app/views/dashboard/store/dashboard.state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ClearDashboardStore, DashboardGetCamerasCountsSuccess, DashboardGetGatesCountsSuccess, DashboardGetRdasCountsSuccess, DashboardGetSoftwareIntercomsCountsSuccess, DashboardGetVideoSurveillanceCountsSuccess } from './dashboard.actions';

@Injectable()
export class DashboardFacade {
    getState$: Observable<DashboardState> = this.store.select(dashboardSelector.getState);
    getRdasCounts$: Observable<DashboardRdasCounts> = this.store.select(dashboardSelector.getRdasCounts);
    getCamerasCounts$: Observable<DashboardCamerasCounts> = this.store.select(dashboardSelector.getCamerasCounts);
    getSoftwareIntercomsCounts$: Observable<DashboardSoftwareIntercomsCounts> = this.store.select(dashboardSelector.getSoftwareIntercomsCounts);
    getVideoSurveillanceCounts$: Observable<DashboardVideoSurveillanceCounts> = this.store.select(dashboardSelector.getVideoSurveillanceCounts);
    getGatesCounts$: Observable<DashboardGatesCounts> = this.store.select(dashboardSelector.getGatesCounts);
    getRdosImages$: Observable<RdosImage[]> = this.store.select(dashboardSelector.getRdosImages);
    loading$: Observable<boolean> = this.store.select(dashboardSelector.getLoading);

    constructor(
        private store: Store<State>
    ) { }

    getRdasCounts() {
        this.store.dispatch(new DashboardGetRdasCounts());
    }

    getCamerasCounts() {
        this.store.dispatch(new DashboardGetCamerasCounts());
    }

    getSoftwareIntercomsCounts() {
        this.store.dispatch(new DashboardGetSoftwareIntercomsCounts());
    }

    getVideoSurveillanceCounts() {
        this.store.dispatch(new DashboardGetVideoSurveillanceCounts());
    }

    getGatesCounts() {
        this.store.dispatch(new DashboardGetGatesCounts());
    }

    getRdosImages() {
        this.store.dispatch(new DashboardGetRdosImages());
    }

    getBlockedUserInvoiceReport(inn: string) {
        this.store.dispatch(new DashboardGetBlockedUserInvoiceReport(inn));
    }

    getPenetrationReport(request: PenetrationReportRequest) {
        this.store.dispatch(new DashboardGetPenetrationReport(request));
    }

    getLicenseeReport(request: LicenseeReportRequest) {
        this.store.dispatch(new DashboardGetLicenseeReport(request));
    }

    getUsageStatisticsReport(request: LicenseeReportRequest) {
        this.store.dispatch(new DashboardGetUsageStatisticsReport(request));
    }

    getCompanyReport(request: CompanyReportRequest) {
        this.store.dispatch(new DashboardGetCompanyReport(request));
    }

    getCompanyPeriodicReport(request: CompanyReportRequest) {
        this.store.dispatch(new DashboardGetCompanyReport(request));
    }

    getSignUpsReport(request: SignUpsReportRequest) {
        this.store.dispatch(new DashboardGetSignUpsReport(request));
    }

    createService(serviceType: ServicesTypes, request: ServiceCreateRequest) {
        this.store.dispatch(new DashboardCreateService(serviceType, request));
    }

    clearStore() {
        this.store.dispatch(new ClearDashboardStore());
    }

    loadValuesFromLocalStorage() {
        Object.keys(LocalStorageDashboardKey).forEach(key => {
            const value = LocalStorageHelper.getItem(LocalStorageDashboardKey[key]);

            switch (LocalStorageDashboardKey[key]) {
                case LocalStorageDashboardKey.DASHBOARD_RDAS_COUNTS:
                    this.store.dispatch(new DashboardGetRdasCountsSuccess(value));
                    break;
                case LocalStorageDashboardKey.DASHBOARD_CAMERAS_COUNTS:
                    this.store.dispatch(new DashboardGetCamerasCountsSuccess(value));
                    break;
                case LocalStorageDashboardKey.DASHBOARD_SOFTWARE_INTERCOMS_COUNTS:
                    this.store.dispatch(new DashboardGetSoftwareIntercomsCountsSuccess(value));
                    break;
                case LocalStorageDashboardKey.DASHBOARD_VIDEO_SURVEILLANCE_COUNTS:
                    this.store.dispatch(new DashboardGetVideoSurveillanceCountsSuccess(value));
                    break;
                case LocalStorageDashboardKey.DASHBOARD_GATES_COUNTS:
                    this.store.dispatch(new DashboardGetGatesCountsSuccess(value));
                    break;
            }
        });
    }
}
