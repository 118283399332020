<div class="intercom-update-tool">
  <div
    class="intercom-update-tool-notice"
    [class.intercom-update-tool-notice--success]="(status$ | async) === intercomUpdateToolStatus.SUCCESS"
    [class.intercom-update-tool-notice--failed]="(status$ | async) === intercomUpdateToolStatus.FAILED"
  >
    <h4 class="intercom-update-tool-notice__text">
      <ng-contianer [ngSwitch]="(updateRdaaJob$ | async)?.status !== AwxJobStatus.FAILED ? (status$ | async) : intercomUpdateToolStatus.FAILED">
        <ng-container *ngSwitchCase="intercomUpdateToolStatus.SUCCESS">
          {{ 'shared.rda.intercom.update.tool.message.status.success' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="intercomUpdateToolStatus.FAILED">
          {{ 'shared.rda.intercom.update.tool.message.status.failed' | translate }}
          <ng-container *ngIf="(errorsCount$ | async) > 1; else retryMessage">
            <br>
            {{ 'shared.rda.intercom.update.tool.message.copy_details_by_clicking_button_below_and_contact' | translate }}
            <a [href]="supportLink | safeUrl">
              {{ 'shared.rda.intercom.update.tool.link.technical_support' | translate }}
            </a>
          </ng-container>
          <ng-template #retryMessage>
            {{ 'shared.rda.intercom.update.tool.message.status.retry' | translate }}
          </ng-template>
        </ng-container>

        <ng-container *ngSwitchCase="intercomUpdateToolStatus.STARTED">
          {{ 'shared.rda.intercom.update.tool.message.status.started' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="intercomUpdateToolStatus.PROGRESS">
          {{ 'shared.rda.intercom.update.tool.message.status.progress' | translate }}
        </ng-container>

        <ng-container *ngSwitchDefault>
          {{ 'shared.rda.intercom.update.tool.message.status.default' | translate }}
        </ng-container>
      </ng-contianer>
    </h4>
  </div>

  <div class="intercom-update-tool-actions">
    <button
      mat-raised-button
      color="primary"
      class="intercom-update-tool-actions__action"
      *ngIf="(status$ | async) !== intercomUpdateToolStatus.SUCCESS"
      [disabled]="(updateRdaaJob$ | async)?.status !== AwxJobStatus.FAILED && (status$ | async) !== intercomUpdateToolStatus.NOT_STARTED && (status$ | async) !== intercomUpdateToolStatus.SUCCESS && (status$ | async) !== intercomUpdateToolStatus.FAILED"
      (click)="onStartUpdate()"
    >
      <ng-container [ngSwitch]="(updateRdaaJob$ | async)?.status !== AwxJobStatus.FAILED ? (status$ | async) : intercomUpdateToolStatus.FAILED">
        <ng-container *ngSwitchCase="intercomUpdateToolStatus.NOT_STARTED">
          {{ 'shared.rda.intercom.update.tool.message.action.not_started' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="intercomUpdateToolStatus.FAILED">
          {{ 'shared.rda.intercom.update.tool.message.action.faield' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="intercomUpdateToolStatus.STARTED">
          {{ 'shared.rda.intercom.update.tool.message.action.started' | translate }}
        </ng-container>

        <ng-container *ngSwitchCase="intercomUpdateToolStatus.PROGRESS">
          {{ 'shared.rda.intercom.update.tool.message.action.progress' | translate }}
        </ng-container>
      </ng-container>
  </button>
  </div>

  <ng-container *ngIf="(status$ | async) !== intercomUpdateToolStatus.NOT_STARTED">
    <mat-progress-bar
      mode="indeterminate"
      class="intercom-update-tool__loader"
      *ngIf="!(updateRdaaJob$ | async)?.finished ? !(updateRdosJob$ | async)?.finished : false"
    ></mat-progress-bar>

    <div class="intercom-update-tool-content">
      <div class="intercom-update-tool-content-logs">
        <h4 class="intercom-update-tool-content-logs__title">
          {{ 'shared.rda.intercom.update.tool.header.update_details' | translate }}
        </h4>

        <div class="intercom-update-tool-content-logs-actions">
          <button
            mat-raised-button
            color="primary-inverse"
            (click)="onChangeExpandLogsState()"
          >
            {{
              logsExpanded
                ? ('shared.rda.intercom.update.tool.button.hide' | translate)
                : ('shared.rda.intercom.update.tool.button.show' | translate)
            }}
          </button>

          <button
            mat-raised-button
            color="primary"
            [disabled]="copyDisabled"
            (click)="onCopyLogs()"
          >
            {{ 'shared.rda.intercom.update.tool.button.copy' | translate }}
          </button>
        </div>

        <div class="intercom-update-tool-content-logs-info" *ngIf="logsExpanded">
          <div class="intercom-update-tool-content-logs-info-jobs">
            <div class="intercom-update-tool-content-logs-info-jobs-item">
              <p class="intercom-update-tool-content-logs-info-jobs-item__title">
                {{ 'shared.rda.intercom.update.tool.message.status.rdos_update' | translate }}
              </p>
              <p class="intercom-update-tool-content-logs-info-jobs-item__value">
                {{ (updateRdaaJob$ | async)?.status !== AwxJobStatus.FAILED ? ((updateRdosJob$ | async)?.status | awx_job_status) : ((updateRdaaJob$ | async)?.status | awx_job_status) }}
              </p>
            </div>

            <div class="intercom-update-tool-content-logs-info-jobs-item">
              <p class="intercom-update-tool-content-logs-info-jobs-item__title">
                {{ 'shared.rda.intercom.update.tool.message.status.rdaa_update' | translate }}
              </p>
              <p class="intercom-update-tool-content-logs-info-jobs-item__value">
                {{ (updateRdaaJob$ | async)?.status | awx_job_status }}
              </p>
            </div>
          </div>

          <table class="intercom-update-tool-content-logs-info-table">
            <tbody class="intercom-update-tool-content-logs-info-table-body">
              <ng-container
                *ngIf="(status$ | async) !== intercomUpdateToolStatus.SUCCESS && (status$ | async) !== intercomUpdateToolStatus.FAILED || logs?.length > 0; else noData"
              >
                <tr
                  *ngFor="let log of logs; let last = last"
                  class="intercom-update-tool-content-logs-info-table-body-message"
                >
                  <div class="intercom-update-tool-content-logs-info-table-body-message-title">
                    <p class="intercom-update-tool-content-logs-tinfo-able-body-message-title__time">
                      {{ log.date | date: 'dd.MM.yyyy HH:mm:ss' }}
                    </p>

                    <p class="intercom-update-tool-content-logs-info-table-body-message-title__msg">
                      {{ log.message }}
                    </p>
                  </div>

                  <hr class="intercom-update-tool-content-logs-info-table-body-message__divider" *ngIf="!last">
                </tr>

                <div
                  *ngIf="!logs?.length"
                  [class.intercom-update-tool-content-logs-table-body-skeleton]="!logs?.length"
                >
                  <ngx-skeleton-loader
                    count="3"
                    [theme]="{ 'border-radius': '8px', height: '100px' }"
                  ></ngx-skeleton-loader>
                </div>
              </ng-container>

              <ng-template #noData>
                <h3 class="intercom-update-tool-content-logs-info-table-body__empty-text">
                  {{ 'shared.rda.intercom.update.tool.message.no_data' | translate }}
                </h3>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </ng-container>
</div>
