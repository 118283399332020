import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { FlatConnectionResponse, FlatCreateRequest, FlatCreateResponse, FlatUpdateRequest } from '../models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({
    providedIn: 'root'
})
export class FlatApiService extends ApiService {
    private contentTypeHeaders: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
        super(httpClient, humanErrorTranslationService, environment.abonentsApiUrl, ['v1']);
    }

    create(request: FlatCreateRequest): Observable<FlatCreateResponse> {
        return this.post(`/flats`, 1, request, { headers: this.contentTypeHeaders });
    }

    update(flatId: number, request: Partial<FlatUpdateRequest>): Observable<unknown> {
        return this.patch(`/flats/${flatId}`, 1, request, { headers: this.contentTypeHeaders });
    }

    remove(flatId: number): Observable<void> {
        return this.delete(`/flats/${flatId}`, 1);
    }

    getConnections(flatId: number): Observable<FlatConnectionResponse[]> {
        return this.get(`/flats/${flatId}/connections`, 1);
    }
}
