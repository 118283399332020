import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { IntercomPanelResponse } from '@app/shared/entities/rd';
import { LocationResponse } from '@app/shared/models';

@Component({
  selector: 'app-intercom-panel-edit-content',
  templateUrl: './intercom-panel-edit-content.component.html',
  styleUrls: ['./intercom-panel-edit-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntercomPanelEditContentComponent implements OnInit {
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  @Input() indexHidden: boolean;
  @Input() locations: LocationResponse[];
  @Input() intercomPanel: IntercomPanelResponse;
  form: UntypedFormGroup = new UntypedFormGroup({
    index: new UntypedFormControl(null),
    locationId: new UntypedFormControl(null)
  });

  @Output() private submitIntercomPanel: EventEmitter<{ location: LocationResponse, index: number }> = new EventEmitter();
  private _loading: boolean;

  ngOnInit() {
    if (!this.intercomPanel) {
      return;
    }

    this.form.setValue({
      index: this.intercomPanel.index,
      locationId: this.intercomPanel.location?.id ?? null
    });
  }

  get loading(): boolean {
    return this._loading;
  }

  onSubmit() {
    this.submitIntercomPanel.emit({
      location: this.locations.find(location => location.id === this.form.get('locationId').value),
      index: this.form.get('index').value
    });
  }
}
