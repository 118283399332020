<div class="abonents-delegation-popup">
  <app-info-block>
    <img
      class="abonents-delegation-popup-info__image"
      info-block-image
      [src]="resourcesHelper.getResource(resourcePath.INFO_LG) | safeUrl"
      [srcset]="resourcesHelper.getResource(resourcePath.INFO_LG, true) | safeUrl"
    >
    <ng-container info-block-text>
      {{ 'abonents.delegation.popup.content.close.info' | translate }}
    </ng-container>
  </app-info-block>

  <div class="abonents-delegation-popup-actions">
    <button
      mat-raised-button
      color="error"
      class="abonents-delegation-popup-actions__button mat-button-lg"
      (click)="onClose()"
      [disabled]="loading"
    >
      {{ 'abonents.delegation.popup.content.close.button.submit' | translate }}
    </button>
  </div>
</div>
