import { PaymentConfigurationResponse } from '@app/shared/entities/rd';

export interface PaymentsPageState {
  configuration: PaymentConfigurationResponse | null | undefined;
  integrationCheck: boolean;
  updateConfigurationPending: boolean;
  errorHandled: boolean;
  configurationChanged: boolean;
}

export const paymentsPageInitialState: PaymentsPageState = {
  configuration: null,
  integrationCheck: true,
  updateConfigurationPending: null,
  errorHandled: null,
  configurationChanged: false
};
