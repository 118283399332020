import { HttpErrorResponse } from '@angular/common/http';
import { Camera } from '@app/shared/entities/rd/camera/models';
import { Actions, ActionTypes } from '@app/shared/store/actions/cameras.actions';

export interface State {
  camera: Camera | null;
  error: HttpErrorResponse;
  loading: boolean;
}

export const initialState: State = {
  camera: null,
  error: null,
  loading: false,
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.UpdateCamera: {
      return {
        ...state,
        camera: action.payload,
        error: null,
        loading: true,
      };
    }

    case ActionTypes.UpdateCameraSuccess: {
      return {
        ...state,
        camera: null,
        error: null,
        loading: false,
      };
    }

    case ActionTypes.UpdateCameraFailure: {
      return {
        ...state,
        camera: null,
        error: action.error,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
}
