<app-component-loader
  *ngIf="(loading$ | async) || (progress$ | async)"
  [sizePx]="50"
  [background]="true"></app-component-loader>

<div
  class="d-access"
  *ngIf="!(loading$ | async)">
  <div class="d-access-navbar">
    <p-tabMenu
      [model]="items"
      [activeItem]="activeItem"></p-tabMenu>
  </div>
  <div class="d-access-header">
    <span class="d-access-header__text">
      {{ "service-navbar.tab.action.addition.header_text" | translate }}
    </span>
    <i class="pi pi-info-circle" style="color:#0da7e2; font-size: 18px" [pTooltip]="'service-navbar.tab.action.addition.help_text' | translate"></i>
  </div>
  <div class="d-access-state">
    <p-chip
      [ngClass]="{
        'd-unrestricted': isUnrestricted,
        'd-limited': isLimited,
        'd-forbidden': isForbidden
      }"
      [label]="label"></p-chip>
  </div>
  <div class="d-access-controls">
    <div class="d-access-controls-control">
      <p-checkbox
        [binary]="true"
        [(ngModel)]="unlimitedControl"
        (ngModelChange)="onModeChange(DelegationConfig.Unlimited)"></p-checkbox>
      <span>
        {{ "service-navbar.tab.action.addition.unrestricted_text" | translate }}
      </span>
    </div>
    <div class="d-access-controls-control">
      <p-checkbox
        [binary]="true"
        [(ngModel)]="limitedControl"
        (ngModelChange)="onModeChange(DelegationConfig.Limited)"></p-checkbox>
      <span>
          {{ "service-navbar.tab.action.addition.contractor_limit_text" | translate }}
      </span>
      <input
        pInputText
        type="number"
        [readonly]="unlimitedControl"
        [formControl]="personControl" />
    </div>
  </div>
  <div
    *ngIf="delegationsOverLimitReport.length && !isUnrestricted"
    class="d-access-warning">
    <span class="d-access-warning__message">
      {{ "service-navbar.tab.action.addition.warn_message" | translate }}
    </span>
    <span
      class="d-access-warning__action"
      (click)="panel.toggle($event)">
      {{ "service-navbar.tab.action.addition.users_info" | translate }}
    </span>
  </div>
  <div class="d-access-actions">
    <button
      pButton
      [label]="'service-navbar.tab.action.addition.apply' | translate"
      (click)="onSetUpConfiguration()"></button>
  </div>
</div>

<p-overlayPanel #panel>
  <ng-template pTemplate="content">
    <p-table
      [value]="delegationsOverLimitReport"
      selectionMode="single"
      [paginator]="true"
      [rows]="6"
      responsiveLayout="scroll">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="phone">
            {{ "service-navbar.tab.action.addition.phone" | translate }}
          </th>
          <th pSortableColumn="phone">
            {{ "service-navbar.tab.action.addition.limit_text" | translate }}
          </th>
          <th pSortableColumn="phone">
            {{ "service-navbar.tab.action.addition.set_up_access" | translate }}
          </th>
          <th pSortableColumn="link"></th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-product>
        <tr>
          <td>{{ product.abonentInfo.phone }}</td>
          <td style="text-align: center">{{ product.delegationsLimit }}</td>
          <td style="text-align: center">{{ product.delegationsCount }}</td>
          <td
            style="text-align: center"
            (click)="onAbonentView(product.abonentInfo.id)">
            <i
              class="pi pi-arrow-right"
              style="color: #0da7e2; font-size: 20px; cursor: pointer"></i>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>
