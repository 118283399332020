import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';

import {
  AbonentService,
  Account,
  AccountApiService,
  ConnectionCreateRequest,
  ConnectionCreateResponse,
  CreateDelegationRequest,
  CreateDelegationResponse,
  DelegationsApiService,
  EntranceService,
  FlatApiService,
  FlatConnectionResponse,
  FlatCreateRequest,
  FlatCreateResponse,
  FlatUpdateRequest,
  ServiceApiService,
  ServiceConnection,
  ServiceConnectionRequest,
  ServiceCreateConnectionRequest,
  ServiceInfoResponse,
  ServiceResponse
} from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';
import { Abonent, Flat, ServicesTypes } from '@app/shared/models';
import { CreateFlatForAbonentRequest } from '@app/views/abonents/models';
import { ServiceConnectionWithType } from '@app/views/services/models';

@Injectable()
export class ConnectionService {
  constructor(
    private abonentService: AbonentService,
    private entranceService: EntranceService,
    private accountsService: AccountApiService,
    private serviceApiService: ServiceApiService,
    private delegationsApiService: DelegationsApiService,
    private flatApiService: FlatApiService
  ) {}

  async createConnection(
    request: ServiceConnectionRequest,
    connectionDoneCb: () => void
  ): Promise<void | Observable<never>> {
    try {
      await from(this.connectToServices(request)).toPromise();
      connectionDoneCb();
    } catch (error) {
      throw error;
    }
  }

  private async createFlat(
    request: Partial<ConnectionCreateRequest>
  ): Promise<FlatCreateResponse> {
    const flatCreateRequest: FlatCreateRequest = {
      entranceId: request.entranceId,
      flatNumber: request.flatNumber,
      virtual: request.virtual
    };

    if (!request.withoutPhoneNumber) {
      flatCreateRequest.abonentId = request.abonent?.id;
    }

    return this.flatApiService.create(flatCreateRequest).toPromise();
  }

  private async updateFlat(
    request: Partial<ConnectionCreateRequest>
  ): Promise<void> {
    const flatUpdateRequest: Partial<FlatUpdateRequest> = {};

    if (request.virtual !== undefined) {
      flatUpdateRequest.virtual = request.virtual;
    }

    if (request.abonent?.id !== undefined) {
      flatUpdateRequest.abonentId = request.abonent.id;
    }

    await this.flatApiService
      .update(request.flatId, flatUpdateRequest)
      .toPromise();
  }

  public async manageNoPhoneFlat(
    request: Partial<ConnectionCreateRequest>
  ): Promise<ConnectionCreateResponse> {
    const response: ConnectionCreateResponse = {};
    const connections: Dictionary<ServiceConnectionWithType> = {};
    if (!request.flatId) {
      response.flat = await this.createFlat(request);
      request.flatId = response.flat.id;
    } else if (
      request.flatId &&
      (request.virtual !== undefined || request.abonent?.id !== undefined)
    ) {
      await this.updateFlat(request);
    }
    // In flat without phone number we can only have and can only connect Hardware Intercome service
    const connectedServices = await this.flatApiService
      .getConnections(request.flatId)
      .toPromise();
    const hardwareIntercomeToConnect = request.servicesForConnect.find(
      (service) => service.type === ServicesTypes.HARDWARE_INTERCOM
    );
    
    if (!hardwareIntercomeToConnect && connectedServices.length === 1) {
      await this.serviceApiService
        .deleteServiceConnection(connectedServices[0].id)
        .toPromise();

      const updatedConnectedServices = await this.flatApiService
        .getConnections(request.flatId)
        .toPromise();
      
      if (updatedConnectedServices.length === 0) {
        await this.flatApiService.remove(request.flatId).toPromise();
      }

    } else if (
      hardwareIntercomeToConnect.type && 
      connectedServices.length === 0
    ) {
      const connectionRequest: ServiceCreateConnectionRequest = {
        flatId: request.flatId,
        accountId: request.account?.id
      };
      const connectionResponse = await this.serviceApiService
        .createConnection(hardwareIntercomeToConnect.id, connectionRequest)
        .toPromise();
      connections[connectionResponse.id] = {
        ...connectionResponse,
        type: hardwareIntercomeToConnect.type
      };
    }
    response.connections = Object.values(connections);
    response.abonent = request.abonent;
    response.account = request.account;

    return response;
  }

  public async manageConnections(
    request: Partial<ConnectionCreateRequest>
  ): Promise<ConnectionCreateResponse> {
    const response: ConnectionCreateResponse = {};
    const connections: Dictionary<ServiceConnectionWithType> = {};

    // If we need account for connections
    const needCreateAccountForConnections: boolean =
      !request.account?.id &&
      (!!request.servicesForConnect?.length ||
        !!request?.existingConnectionsIds?.length);

    if (needCreateAccountForConnections && !request.withoutPhoneNumber) {
      const account = await this.accountsService
        .createAbonentAndAccount(request.phone, request.companyId)
        .toPromise();
      request.account = account;
      request.abonent = account.owner;
    }
    if (
      request.flatId &&
      (request.virtual !== undefined || request.abonent?.id !== undefined)
    ) {
      await this.updateFlat(request);
    }

    // delete connections
    const connectedServices = await this.flatApiService
      .getConnections(request.flatId)
      .toPromise();
    const connectedServicesIds = connectedServices.map(
      (connectedService) => connectedService.service.id
    );
    if (request?.servicesForDelete.length) {
      await this.deleteConnections(
        connectedServices,
        request.servicesForDelete
      );
    }
    // Create new connections
    for (const service of request.servicesForConnect ?? []) {
      if (!connectedServicesIds.includes(service.id)) {
        const connectionRequest: ServiceCreateConnectionRequest = {
          flatId: request.flatId,
          accountId: request.account?.id
        };
        const connectionResponse = await this.serviceApiService
          .createConnection(service.id, connectionRequest)
          .toPromise();
        connections[connectionResponse.id] = {
          ...connectionResponse,
          type: service.type
        };
      }
    }

    // Update existing connections
    for (const connectionId of request.existingConnectionsIds ?? []) {
      await this.serviceApiService
        .updateConnection(connectionId, request.account?.id)
        .toPromise();
    }

    response.abonent = request.abonent;
    response.account = request.account;
    response.connections = Object.values(connections);

    return response;
  }

  async createConnectionV2(
    request: Partial<ConnectionCreateRequest>
  ): Promise<ConnectionCreateResponse> {
    const response: ConnectionCreateResponse = {};
    const connections: Dictionary<ServiceConnectionWithType> = {};

    // If we need account for connections
    const needCreateAccountForConnections: boolean =
      !request.account?.id &&
      (!!request.servicesForConnect?.length ||
        !!request?.existingConnectionsIds?.length);

    if (needCreateAccountForConnections && !request.withoutPhoneNumber) {
      const account = await this.accountsService
        .createAbonentAndAccount(request.phone, request.companyId)
        .toPromise();
      request.account = account;
      request.abonent = account.owner;
    }

    // If we need abonent for connections
    if (!request.abonent && !request.withoutPhoneNumber) {
      request.abonent = await this.abonentService
        .createAbonent(request.phone)
        .toPromise();
    }

    // Create flat if flatId empty
    if (!request.flatId) {
      response.flat = await this.createFlat(request);
      request.flatId = response.flat.id;

      // Or update actial flat
    } else if (
      request.flatId &&
      (request.virtual !== undefined || request.abonent?.id !== undefined)
    ) {
      await this.updateFlat(request);
    }
    // delete connections
    const connectedServices = await this.flatApiService
      .getConnections(request.flatId)
      .toPromise();
    if (request?.servicesForDelete.length) {
      await this.deleteConnections(
        connectedServices,
        request.servicesForDelete
      );
    }
    // Create new connections
    for (const service of request.servicesForConnect ?? []) {
      const connectionRequest: ServiceCreateConnectionRequest = {
        flatId: request.flatId,
        accountId: request.account?.id
      };

      const connectionResponse = await this.serviceApiService
        .createConnection(service.id, connectionRequest)
        .toPromise();
      connections[connectionResponse.id] = {
        ...connectionResponse,
        type: service.type
      };
    }

    // Update existing connections
    for (const connectionId of request.existingConnectionsIds ?? []) {
      await this.serviceApiService
        .updateConnection(connectionId, request.account?.id)
        .toPromise();
    }

    response.abonent = request.abonent;
    response.account = request.account;
    response.connections = Object.values(connections);

    return response;
  }

  async createAbonentAndGetAccounts(
    phone: number,
    cb: (
      abonentId: number,
      accounts: Account[],
      error?: HttpErrorResponse
    ) => void
  ) {
    try {
      const abonentId: number = (
        await this.abonentService.createAbonent(phone).toPromise()
      ).id;
      const accounts: Account[] = await this.abonentService
        .getAbonentAccounts(abonentId)
        .toPromise();
      const response: { abonentId: number; accounts: Account[] } = {
        abonentId,
        accounts
      };

      if (response.abonentId && response.accounts) {
        cb(response.abonentId, response.accounts);
      }
    } catch (error) {
      cb(null, null, error);
    }
  }

  async deleteConnections(
    oldServicesList: FlatConnectionResponse[],
    servicesForDelete: Pick<ServiceResponse, 'id' | 'type'>[]
  ) {
    const servicesForDeleteIds = servicesForDelete.map((service) => service.id);
    await Promise.all(
      oldServicesList.map(async (connection) => {
        if (servicesForDeleteIds.includes(connection.service.id)) {
          await this.serviceApiService
            .deleteServiceConnection(connection.id)
            .toPromise();
        }
      })
    );
  }

  async checkDelegation(
    entranceId: number,
    flat: number,
    services: ServiceInfoResponse[],
    phone: number
  ): Promise<{
    owner: Abonent;
    connectedServices: ServiceInfoResponse[];
    checkDelegationError: HttpErrorResponse;
  }> {
    let owner: Abonent;
    const connectedServices: ServiceInfoResponse[] = [];
    let checkDelegationError: HttpErrorResponse;

    try {
      for (const service of services) {
        const connections: ServiceConnection[] = await this.serviceApiService
          .getConnections(service.id, entranceId)
          .toPromise();

        for (const connection of connections) {
          if (
            connection.flat.address.flat === flat &&
            connection.flat.address.entrance.id === entranceId
          ) {
            owner = connection.account.owner;
            break;
          }
        }

        if (owner) {
          if (owner.phone === phone) {
            connectedServices.push(service);
            owner = null;
          } else {
            break;
          }
        }
      }
    } catch (error) {
      checkDelegationError = error;
    }

    return { owner, connectedServices, checkDelegationError };
  }

  async getEntrancesServices(
    entranceId: number
  ): Promise<ServiceInfoResponse[]> {
    let entrancesServices: ServiceInfoResponse[];

    try {
      entrancesServices = await this.serviceApiService
        .getEntranceServices(entranceId)
        .toPromise();
    } catch (error) {
      throw error;
    }

    return entrancesServices;
  }

  async createDelegation(
    request: CreateDelegationRequest
  ): Promise<CreateDelegationResponse> {
    try {
      const abonent: Abonent = await this.abonentService
        .createAbonent(request.toAbonentPhone)
        .toPromise();
      const accounts: Account[] = await this.abonentService
        .getAbonentAccounts(abonent.id)
        .toPromise();
      const toAbonentResp: { abonentId: number; accounts: Account[] } = {
        abonentId: abonent.id,
        accounts
      };

      if (toAbonentResp.accounts.length === 0) {
        await this.accountsService
          .createAbonentAndAccount(request.toAbonentPhone, request.companyId)
          .toPromise();
      }

      return await this.delegationsApiService
        .createDelegation(request.fromAbonentId, toAbonentResp.abonentId)
        .toPromise();
    } catch (error) {
      throw error;
    }
  }

  private async connectToServices(
    request: ServiceConnectionRequest
  ): Promise<void> {
    if (request.accountId == null) {
      request.accountId = (
        await this.accountsService
          .createAbonentAndAccount(request.phone, request.companyId)
          .toPromise()
      ).id;
    }

    let flatId: number;

    if (request.flatId) {
      flatId = request.flatId;
    } else {
      const flats: Flat[] = await this.entranceService
        .getEntrancesFlats(request.entranceId, request.flatNumber)
        .toPromise();

      if (!flats.length) {
        const flatRequest: CreateFlatForAbonentRequest = {
          entranceId: request.entranceId,
          flatNumber: request.flatNumber,
          virtual: request.virtual
        };
        flatId = (
          await this.abonentService
            .createFlat(request.abonentId, flatRequest)
            .toPromise()
        ).id;
      } else {
        flatId = flats[0].id;
      }
    }

    for (const serviceId of request.servicesIds) {
      await this.accountsService
        .connectServiceToAccount(request.accountId, serviceId, flatId)
        .toPromise();
    }
  }
}
