import { Abonent } from './../../../../models/abonent';
import { Account } from './../../account/models/account';
import { ServiceResponse } from '../../services';

export interface ConnectionCreateRequest {
  phone: number;
  abonent: Abonent;
  account: Account;
  flatId: number;
  companyId: number;
  virtual: boolean;
  entranceId: number;
  flatNumber: number;
  servicesForConnect: Pick<ServiceResponse, 'id' | 'type'>[];
  servicesForDelete: Pick<ServiceResponse, 'id' | 'type'>[];
  existingConnectionsIds: number[];
  baseServiceId?: number;
  withoutPhoneNumber?: boolean;
  responseMessage?: string;
}
