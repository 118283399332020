import { Injectable } from '@angular/core';
import { AmplitudeComponentTrackType, AmplitudeEvents, AmplitudePageEventRequest, AmplitudeUpdateClientRequest } from '@app/shared/entities/integrations/amplitude';
import * as Amplitude from 'amplitude-js';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class AmplitudeService {
    private client: Amplitude.AmplitudeClient;

    initClient(callback?: (isSuccess: boolean) => void) {
        Amplitude.getInstance()
            .init(environment.amplitudeApi, null, this.defaultConfig, (client: Amplitude.AmplitudeClient) => {
                this.client = client;
                callback(client ? true : false);
            });
    }

    updateClient(request: AmplitudeUpdateClientRequest) {
        const data: any = {};
        if (request.companyId) { data.companyId = request.companyId; }
        if (request.companyName) { data.companyName = request.companyName; }
        this.client.setUserProperties(data);
        this.client.setUserId(request.userName);
    }

    trackComponent(trackedComponentName: string, type: AmplitudeComponentTrackType, event: AmplitudeEvents, request: AmplitudePageEventRequest): number {
        return this.client.logEventWithTimestamp(`${trackedComponentName}_${type.toString()}_${event.toString()}`, request, (new Date()).getTime());
    }

    private get defaultConfig(): Amplitude.Config {
        return { includeReferrer: true, includeUtm: true, includeGclid: true };
    }
}
