<h3 class="rda-translations-form__title">
  {{ 'shared.rda.translations.editor.form.title' | translate }}
</h3>

<ng-container [ngSwitch]="mode">
  <div class="rda-translations-form-simple" *ngSwitchCase="'simple'">
    <mat-form-field appearance="outline" class="none-padding none-margin">
      <mat-label>
        {{ 'shared.rda.translations.editor.form.field.offset' | translate }}
      </mat-label>
      <input
        type="number"
        matInput
        [(ngModel)]="translation"
        name="translation"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
      />
    </mat-form-field>

    <button
      mat-raised-button
      color="primary"
      [disabled]="loading"
      (click)="onSaveTranslation()"
    >
      {{ 'shared.rda.translations.editor.form.button.save' | translate }}
    </button>
  </div>

  <ng-container *ngSwitchDefault>
    <form
      [formGroup]="form"
      class="rda-translations-form-advanced"
      (ngSubmit)="onAddAdvancedTranslation()"
    >
      <div class="rda-translations-form-advanced-range">
        <mat-form-field appearance="outline" class="none-padding">
          <mat-label>
            {{ 'shared.rda.translations.editor.form.field.from_num' | translate }}
          </mat-label>
          <input
            type="number"
            matInput
            formControlName="fromNum"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="none-padding">
          <mat-label>
            {{ 'shared.rda.translations.editor.form.field.to_num' | translate }}
          </mat-label>
          <input
            type="number"
            matInput
            formControlName="toNum"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" class="none-padding">
        <mat-label>
          {{ 'shared.rda.translations.editor.form.field.offset' | translate }}
        </mat-label>
        <input
          type="number"
          matInput
          formControlName="translation"
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
        />
      </mat-form-field>

      <button
        type="submit"
        mat-raised-button
        color="primary"
        [disabled]="loading"
        *ngIf="resolution.isMobile; else addTranslationTuningDesktop"
        class="rda-translations-form-advanced__action"
      >
        {{ 'shared.rda.translations.editor.form.button.submit' | translate }}
      </button>

      <ng-template #addTranslationTuningDesktop>
        <button type="submit" mat-fab color="primary-inverse">
          <mat-icon>add</mat-icon>
        </button>
      </ng-template>
    </form>

    <hr *ngIf="translationTunings?.length > 0">

    <div
      class="rda-translations-form-advanced"
      *ngFor="let translationTuning of translationTunings"
    >
      <div class="rda-translations-form-advanced-range">
        <mat-form-field appearance="outline" class="none-padding">
          <mat-label>
            {{ 'shared.rda.translations.editor.form.field.from_num' | translate }}
          </mat-label>
          <input
            type="number"
            matInput
            disabled
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            [value]="translationTuning.fromNum"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="none-padding">
          <mat-label>
            {{ 'shared.rda.translations.editor.form.field.to_num' | translate }}
          </mat-label>
          <input
            type="number"
            matInput
            disabled
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            [value]="translationTuning.toNum"
          />
        </mat-form-field>
      </div>

      <mat-form-field appearance="outline" class="none-padding">
        <mat-label>
          {{ 'shared.rda.translations.editor.form.field.offset' | translate }}
        </mat-label>
        <input
          type="number"
          matInput
          disabled
          autocomplete="off"
          autocorrect="off"
          autocapitalize="off"
          spellcheck="false"
          [value]="translationTuning.translation"
        />
      </mat-form-field>

      <button
        mat-raised-button
        color="error"
        [disabled]="loading"
        *ngIf="resolution.isMobile; else removeTranslationTuningDesktop"
        class="rda-translations-form-advanced__action"
        (click)="onDeleteTranslationTuning(translationTuning['id'])"
      >
        {{ 'shared.rda.translations.editor.form.button.delete' | translate }}
      </button>

      <ng-template #removeTranslationTuningDesktop>
        <button
          type="submit"
          mat-fab
          color="error"
          [disabled]="loading"
          (click)="onDeleteTranslationTuning(translationTuning['id'])"
        >
          <mat-icon>remove</mat-icon>
        </button>
      </ng-template>
    </div>
  </ng-container>
</ng-container>

<a
  class="rda-translations-form__link"
  (click)="onChangeMode()"
>
  {{ 'shared.rda.translations.editor.form.link.change_mode' | translate:{
    mode: mode === 'simple'
      ? ('shared.rda.translations.editor.form.enum.mode.extend' | translate)
      : ('shared.rda.translations.editor.form.enum.mode.simple' | translate)
  } }}
</a>

<a class="rda-translations-form__link" target="_blank" (click)="onSupportNavigate()">
  {{ 'shared.rda.translations.editor.form.link.support_link' | translate }}
</a>
