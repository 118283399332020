import { Injectable, OnDestroy } from '@angular/core';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class ServiceActivityService implements OnDestroy {
    private readonly secondsToUpdate = 10;
    private onDestroy$: Subject<void> = new Subject();

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    startTimer(
        handleTick: (remainingSeconds: number, secondsToUpdate: number) => void
    ) {
        interval(1000)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((remainingSeconds: number) => {
                handleTick(remainingSeconds, this.secondsToUpdate);
            });
    }

    stopTimer() {
        this.onDestroy$.next();
    }
}
