import { HttpErrorResponse } from '@angular/common/http';
import { RdaResponse, RdaUpdateRequest, ServiceIntercomConnectRequest } from '@app/shared/entities/rd';
import { Action } from '@ngrx/store';
import { ServicesActionTypes } from './services-action-types.actions';

export class GetServiceRdas implements Action {
    readonly type = ServicesActionTypes.GetServiceRdas;

    constructor() { }
}

export class GetServiceRdasSuccess implements Action {
    readonly type = ServicesActionTypes.GetServiceRdasSuccess;

    constructor(public rdas: RdaResponse[]) { }
}

export class GetServiceRdasFailure implements Action {
    readonly type = ServicesActionTypes.GetServiceRdasFailure;

    constructor(public error: HttpErrorResponse) { }
}

export class AddServiceRda implements Action {
    readonly type = ServicesActionTypes.AddServiceRda;

    constructor(public request: RdaUpdateRequest) { }
}

export class AddServiceRdaSuccess implements Action {
    readonly type = ServicesActionTypes.AddServiceRdaSuccess;

    constructor(public response: RdaUpdateRequest) { }
}

export class AddServiceRdaFailure implements Action {
    readonly type = ServicesActionTypes.AddServiceRdaFailure;

    constructor() { }
}

export class UpdateServiceRda implements Action {
    readonly type = ServicesActionTypes.UpdateServiceRda;

    constructor(public rdaUid: string, public request: RdaUpdateRequest) { }
}

export class UpdateServiceRdaSuccess implements Action {
    readonly type = ServicesActionTypes.UpdateServiceRdaSuccess;

    constructor(public response: RdaUpdateRequest) { }
}

export class UpdateServiceRdaFailure implements Action {
    readonly type = ServicesActionTypes.UpdateServiceRdaFailure;

    constructor() { }
}

export class DeleteServiceRda implements Action {
    readonly type = ServicesActionTypes.DeleteServiceRda;

    constructor(public intercom: RdaResponse) { }
}

export class DeleteServiceRdaSuccess implements Action {
    readonly type = ServicesActionTypes.DeleteServiceRdaSuccess;

    constructor(public intercom: RdaResponse) { }
}

export class DeleteServiceRdaFailure implements Action {
    readonly type = ServicesActionTypes.DeleteServiceRdaFailure;

    constructor() { }
}

export class ChangeServiceRda implements Action {
    readonly type = ServicesActionTypes.ChangeServiceRda;

    constructor(public request: RdaUpdateRequest, public oldRdaId: number) { }
}

export class ChangeServiceRdaSuccess implements Action {
    readonly type = ServicesActionTypes.ChangeServiceRdaSuccess;

    constructor(public request: RdaUpdateRequest) { }
}

export class ChangeServiceRdaFailure implements Action {
    readonly type = ServicesActionTypes.ChangeServiceRdaFailure;

    constructor() { }
}

export type ServicesIntercomsActions =
    | GetServiceRdas
    | GetServiceRdasSuccess
    | GetServiceRdasFailure

    | AddServiceRda
    | AddServiceRdaSuccess
    | AddServiceRdaFailure

    | UpdateServiceRda
    | UpdateServiceRdaSuccess
    | UpdateServiceRdaFailure

    | DeleteServiceRda
    | DeleteServiceRdaSuccess
    | DeleteServiceRdaFailure

    | ChangeServiceRda
    | ChangeServiceRdaSuccess
    | ChangeServiceRdaFailure;
