import { Injectable, OnDestroy } from '@angular/core';
import { DialogWrapperStateHelper } from '@app/shared/ui';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { ServicesActionTypes } from '../../../../store';

@Injectable()
export class ServiceConnectionBlockPopupService extends DialogWrapperStateHelper<'loading' | 'close' | 'loaded'> implements OnDestroy {
    constructor(
        private actions$: Actions
    ) {
        super();
        this.enableStateListeners();
    }

    ngOnDestroy() {
        this.destroy();
    }

    private enableStateListeners() {
        this.actions$
            .pipe(
                ofType(...[
                    ServicesActionTypes.BlockServiceConnection,
                    ServicesActionTypes.UnblockServiceConnection,
                    ServicesActionTypes.BlockServicePhysicalTube,
                    ServicesActionTypes.UnblockServicePhysicalTube
                ]), takeUntil(this.onDestroy$),
            )
            .subscribe(() => this.state = 'loading');

        this.actions$
            .pipe(
                ofType(...[
                    ServicesActionTypes.BlockServiceConnectionSuccess,
                    ServicesActionTypes.UnblockServiceConnectionSuccess,
                    ServicesActionTypes.BlockServicePhysicalTubeSuccess,
                    ServicesActionTypes.UnblockServicePhysicalTubeSuccess
                ]), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'close');

        this.actions$
            .pipe(
                ofType(...[
                    ServicesActionTypes.BlockServiceConnectionFailure,
                    ServicesActionTypes.UnblockServiceConnectionFailure,
                    ServicesActionTypes.BlockServicePhysicalTubeFailure,
                    ServicesActionTypes.UnblockServicePhysicalTubeFailure
                ]), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loaded');
    }
}
