import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { KzPostResponse } from '../models/kz-post-response';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({
    providedIn: 'root'
})
export class KzPostApiService extends ApiService {
    constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService, handler: HttpBackend) {
        httpClient = new HttpClient(handler);
        super(httpClient, humanErrorTranslationService, environment.kzPostApiUrl, []);
    }

    public byAddress(address: string, from: number = 0): Observable<KzPostResponse> {
        return this.get(`byAddress/${encodeURIComponent(address)}?from=${from}`, 0);
    }
}
