import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  CronDateInputService,
  DEFAULT_POST_PAYMENT,
  DEFAULT_PRE_PAYMENT,
  IPaymentFormModel,
  SnackbarService
} from '@app/shared/components';
import {
  PaymentConfigurationResponse,
  UpdatePaymentConfigurationRequest
} from '@app/shared/entities/rd';
import { ABOUT_PAYMENT_CONNECTION, Constants } from '@app/shared/helpers';
import { PaymentsPageStore } from '@app/views/payments/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  PaymentsConfirmBottomSheetComponent
} from '@app/views/payments/components/containers/payments-confirm/payments-confirm-bottom-sheet/payments-confirm-bottom-sheet.component';
import { MatDialogRef } from '@angular/material/dialog/dialog-ref';
import {TranslateService} from '@ngx-translate/core';
import { Links } from '@app/shared/helpers/wiki-links.enum';

export interface IPaymentsConfirmDialogWizard {
  title: string;
  componentName: string;
  risksAccepts: () => void;
}

@Component({
  selector: 'app-payments-settings',
  templateUrl: './payments-settings.component.html',
  styleUrls: ['./payments-settings.component.scss'],
  providers: [CronDateInputService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentsSettingsComponent implements OnInit, OnDestroy {
  private onDestroy$: Subject<void> = new Subject();

  public readonly paymentConnectionLink: string = ABOUT_PAYMENT_CONNECTION;
  public readonly cronErrors$: Subject<string[]> = new Subject();
  @Input() public configuration!: PaymentConfigurationResponse;

  public updateConfigurationPending$: Observable<boolean> =
    this.store.updateConfigurationPending$;

  public readonly disabled$: Observable<boolean> =
    this.store.configurationChanged$.pipe(
      map((configurationChanged: boolean) => configurationChanged === false)
    );

  public readonly form: UntypedFormGroup = new UntypedFormGroup({
    invoicingDate: new UntypedFormControl('', {
      validators: [Validators.required]
    }),
    reminingDate: new UntypedFormControl('', {
      validators: [Validators.required]
    }),
    blockingDate: new UntypedFormControl('', {
      validators: [Validators.required]
    }),
    isRecurringEnabled: new UntypedFormControl(false, []),
  });

  public isRisksAccepts: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  get autoPaymentsLink(): string { return Links.AutoPayments; }

  constructor(
    private store: PaymentsPageStore,
    private snackbar: SnackbarService,
    private cronDateInput: CronDateInputService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((values: IPaymentFormModel) => {
        const errorMessages: string[] = this.cronDateInput.prepareCronErrors(values, this.configuration.paymentPeriodShift);
        this.cronErrors$.next(errorMessages);
        this.store.setConfigurationChanged(
          !this.cronDatesDoesNotChanged(values) && errorMessages.length === 0
        );
      });

    this.updateConfigurationPending$
      .pipe(
        takeUntil(this.onDestroy$),
        tap((pending: boolean) =>
          pending ? this.form.disable() : this.form.enable()
        )
      )
      .subscribe();

    if (!this.isValidDateRange().length) {
      this.initFormValue();
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onEdit(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.onRisksAccepts();
  }

  public getFormValue(controlName: string) {
    return this.form.get(controlName)?.value;
  }

  public onSubmit(cronErrorsExists: boolean): void {
    if (this.cronDateInput.checkErrorAfterSubmit(
      cronErrorsExists, {form: this.form, fieldsToChecking: ['invoicingDate', 'reminingDate', 'blockingDate']}
    )) {
      return;
    } else {
      const {
        invoicingDate,
        reminingDate,
        blockingDate,
        isRecurringEnabled
      }: { invoicingDate: string; reminingDate: string; blockingDate: string; isRecurringEnabled: boolean; } =
        this.form.getRawValue();

      const payload: UpdatePaymentConfigurationRequest = {
        isRecurringEnabled: isRecurringEnabled,
        schedule: {
          billCronExpr: invoicingDate,
          blockCronExpr: blockingDate,
          reminderCronExpr: reminingDate,
        }
      };

      this.store.updatePaymentSettings(payload);
    }
  }

  public isDisable(pending: boolean, disabled: boolean): boolean {
    return pending || disabled;
  }

  public onSetAutomatically(): void {
    this.configuration.paymentPeriodShift ? this.form.patchValue(DEFAULT_PRE_PAYMENT) : this.form.patchValue(DEFAULT_POST_PAYMENT);
  }

  private cronDatesDoesNotChanged(values: IPaymentFormModel): boolean {
    return (
      values.invoicingDate === this.configuration.schedule?.billCronExpr &&
      values.reminingDate === this.configuration.schedule?.reminderCronExpr &&
      values.blockingDate === this.configuration.schedule?.blockCronExpr &&
      values.isRecurringEnabled === this.configuration.isRecurringEnabled
    );
  }

  private isValidDateRange(): string[] {
    return this.cronDateInput.prepareCronErrors(this.form.value, this.configuration.paymentPeriodShift);
  }

  private initFormValue(): void {
    this.form.patchValue({
      invoicingDate: this.configuration.schedule?.billCronExpr,
      reminingDate: this.configuration.schedule?.reminderCronExpr,
      blockingDate: this.configuration.schedule?.blockCronExpr,
      isRecurringEnabled: this.configuration.isRecurringEnabled
    });
  }

  private onRisksAccepts(): void {
    let dialogRef: MatDialogRef<
      PaymentsConfirmBottomSheetComponent,
      IPaymentsConfirmDialogWizard
      > = null;

    const paymentsConfirmDialogWizard: IPaymentsConfirmDialogWizard = {
      title: this.translate.instant('payments.settings.risks_accepts.title'),
      componentName: 'PaymentsConfirmDialog',
      risksAccepts: () => {
        this.isRisksAccepts.next(true);
        dialogRef.close();
      }
    };

    dialogRef = this.dialog.open(PaymentsConfirmBottomSheetComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data: paymentsConfirmDialogWizard
    });
  }
}
