<div class="popup delete-camera-content">
  <app-info-block>
    <ng-container info-block-text>
      <span [innerHTML]="'shared.delete.camera.content.text' | translate: { id: cameraId }"></span>
    </ng-container>
  </app-info-block>

  <div class="popup-actions">
    <button
      mat-raised-button
      color="error"
      [disabled]="loading"
      class="popup-actions__button"
      (click)="onDelete()"
    >
      {{ 'shared.delete.camera.content.button.submit' | translate }}
    </button>
  </div>
</div>
