import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

export const orderCreateFormGroup = () => {
    const batch = {
        rdaCount: new UntypedFormControl(null, [Validators.required, Validators.min(1)]),
        customerCompanyId: new UntypedFormControl(null, [Validators.required])
    };
    return {
        form: new UntypedFormGroup(batch),
        batch: batch
    };
};
