<app-dialog-wrapper
  [componentName]="data.componentName"
  [title]="data.title"
>
  <div class="popup info-table">
    <div class="table-column" [class.table-column-neutral]="idx % 2 === 0"
      *ngFor="let orderStatus of data.body.orderStatusHistory; let idx = index">
      <p class="title">{{orderStatus.status | orderStatus}}</p>
      <p class="value">{{orderStatus.createdAt | date: 'dd.MM.yyyy HH:mm:ss'}}</p>
    </div>
  </div>
</app-dialog-wrapper>