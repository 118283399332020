import { createSelector } from '@ngrx/store';
import { SharedModuleStates } from '../../reducers/reducers';
import { sharedModuleSelector } from '../shared-module.state';

export const getUpdateCameraState = createSelector(
  sharedModuleSelector,
  (state: SharedModuleStates) => state.updateCamera
);

export const getUpdateCameraSuccess = createSelector(
  getUpdateCameraState,
  state => state.success
);

export const getUpdateCameraFailure = createSelector(
  getUpdateCameraState,
  state => state.error
);

export const getUpdateCameraLoading = createSelector(
  getUpdateCameraState,
  state => state.loading
);
