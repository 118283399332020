import { Dictionary } from '@app/shared/helpers';
import { ServiceEntranceRangeInfo } from './service-entrance-range-info.model';
import { ServiceFlat } from './service-flat-connection.model';
import {ServiceFlatsFilters} from '@app/views/services/components';

export interface ServiceEntranceFlats {
    addressString: string;
    flatRangesInfo: ServiceEntranceRangeInfo[];
    virtualFlatsCount: number;
    flatsCount: number;
    tariff: number;
    virtualFlats: Dictionary<Partial<ServiceFlat>>;
    flats?: Dictionary<Partial<ServiceFlat>>;
    filters?: ServiceFlatsFilters;
}
