import { Component, Input } from '@angular/core';
import { Dictionary } from '@app/shared/helpers';
import { ITicketResponse } from '@app/views/support-service/services/support-api.service';
import { NgStyle } from '@angular/common';

export const REQUEST_STATUS: Dictionary<string> = {
  New: '#E88F4F',
  'In progress': '#E88F4F',
  'Waiting for support': '#F5CA59',
  'Waiting for client': '#E88F4F',
  Planned: '#E88F4F',
  Development: '#F5CA59',
  Manufacture: '#F5CA59',
  Solved: '#5FCF8C',
  Closed: '#5FCF8C'
};

@Component({
  selector: 'app-request-status',
  templateUrl: './request-status.component.html',
  styleUrls: ['./request-status.component.scss'],
  standalone: true,
  imports: [NgStyle]
})
export class RequestStatusComponent {
  @Input() public tickets: ITicketResponse = null;
  public REQUEST_STATUS: Dictionary<string> = REQUEST_STATUS;
}
