import { Clipboard } from '@angular/cdk/clipboard';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RdeaDate } from '@app/shared/entities';
import { RdaResponse } from '@app/shared/entities/rd';
import { SUPPORT_LINK } from '@app/shared/helpers';
import { Router } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-rda-warnings-content',
  templateUrl: './rda-warnings-content.component.html',
  styleUrls: ['./rda-warnings-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RdaWarningsContentComponent implements OnInit {
  readonly SUPPORT_LINK: string = SUPPORT_LINK;

  @Input() serviceName: string;
  @Input() addressString: string;
  @Input() entranceId: number;
  @Input() rda!: RdaResponse;

  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  public supportRequestLines: string[] = [];

  constructor(
    private clipboard: Clipboard,
    public router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    console.log(this.addressString);
    if (this.serviceName) {
      this.supportRequestLines.push(this.translate.instant('shared.rda.warnings.content.field.service', { value: this.serviceName }));
    }

    if (this.addressString) {
      this.supportRequestLines.push(this.translate.instant('shared.rda.warnings.content.field.address', { value: this.addressString }));
    }

    if (this.entranceId) {
      this.supportRequestLines.push(this.translate.instant('shared.rda.warnings.content.field.entrance_id', { value: this.entranceId }));
    }

    this.supportRequestLines = [
      ...this.supportRequestLines,
      this.translate.instant('shared.rda.warnings.content.field.rda.uid', {
        value: this.rda.uid
      }),
      this.translate.instant('shared.rda.warnings.content.field.rda.status', {
        value: this.rda.active
          ? this.translate.instant('shared.rda.warnings.content.enum.status.online')
          : this.translate.instant('shared.rda.warnings.content.enum.status.offline')
      }),
      this.translate.instant('shared.rda.warnings.content.field.rda.ip', {
        value: this.rda.ipAddress
      }),
      this.translate.instant('shared.rda.warnings.content.field.rda.last_heart_beat_time', {
        value: new RdeaDate(this.rda.lastHeartBeatTime).toLocaleString()
      }),
      this.translate.instant('shared.rda.warnings.content.field.rda.version_rdos', {
        value: this.rda.versionInfoOs
      }),
      this.translate.instant('shared.rda.warnings.content.field.rda.version_rdaa', {
        value: this.rda.versionInfoRdaa
      }),
      this.translate.instant('shared.rda.warnings.content.field.rda.version_stm_driver', {
        value: this.rda.versionInfoStmDriver
      }),
      this.translate.instant('shared.rda.warnings.content.field.rda.version_stm', {
        value: this.rda.versionInfoStm
      }),
      this.translate.instant('shared.rda.warnings.content.field.rda.version_shield', {
        value: this.rda.versionInfoShield
      })
    ];

    this.clipboard.copy(this.supportRequestLines.join('\n'));
  }

  public supportRequest(): void {
    this.router.navigate([
      '/support'
    ], {
      queryParams: {
        address: this.serviceName,
        description: this.supportRequestLines
      }
    }).then(() => {
      this.close.emit();
    });
  }
}
