<div class="page-container dashboard-page">
  <div class="dashboard-page-buttons"
    *appSomeRoleContains="[
    userRoleType.ROLE_ENGINEER,
    userRoleType.ROLE_ENGINEER_ENTRANCES,
    userRoleType.ROLE_ENGINEER_VIDEO_SURVEILLANCE,
    userRoleType.ROLE_ENGINEER_GATES,
    userRoleType.ROLE_ADMINISTRATOR,
    userRoleType.ROLE_OWNER
    ]"
  >
    <div class="dashboard-page-buttons-container"
      *appSomeRoleContains="[
      userRoleType.ROLE_ENGINEER,
      userRoleType.ROLE_ENGINEER_ENTRANCES,
      userRoleType.ROLE_ADMINISTRATOR,
      userRoleType.ROLE_OWNER
      ]"
    >
      <button
        rdea-squared-button
        [resourcePath]="resourcePath.CREATE_SOFTWARE_INTERCOM_BTN"
        (click)="onCreateService(serviceType.SOFTWARE_INTERCOM)"
      >
        {{ 'dashboard.page.button.intercom' | translate }}
      </button>
    </div>

    <div class="dashboard-page-buttons-container"
      *appSomeRoleContains="[
      userRoleType.ROLE_ENGINEER,
      userRoleType.ROLE_ENGINEER_VIDEO_SURVEILLANCE,
      userRoleType.ROLE_ADMINISTRATOR,
      userRoleType.ROLE_OWNER
      ]"
    >
      <button
        rdea-squared-button
        [resourcePath]="resourcePath.CREATE_VIDEO_SURVEILLANCE_BTN"
        (click)="onCreateService(serviceType.VIDEO_SURVEILLANCE)"
      >
        {{ 'dashboard.page.button.video_surveillance' | translate }}
      </button>
    </div>

    <div class="dashboard-page-buttons-container"
      *appSomeRoleContains="[
      userRoleType.ROLE_ENGINEER,
      userRoleType.ROLE_ENGINEER_GATES,
      userRoleType.ROLE_ADMINISTRATOR,
      userRoleType.ROLE_OWNER
      ]"
    >
      <button
        rdea-squared-button
        [resourcePath]="resourcePath.CREATE_GATE_BTN"
        (click)="onCreateService(serviceType.GATE)"
      >
        {{ 'dashboard.page.button.gate' | translate }}
      </button>
    </div>
  </div>

  <div
    class="dashboard-page-info dashboard-page-info--grid"
    *appSomeRoleContains="[
    userRoleType.ROLE_ENGINEER,
    userRoleType.ROLE_ENGINEER_ENTRANCES,
    userRoleType.ROLE_ENGINEER_CAMERAS,
    userRoleType.ROLE_ENGINEER_GATES,
    userRoleType.ROLE_ADMINISTRATOR,
    userRoleType.ROLE_OWNER
    ]"
  >
    <div *appSomeRoleContains="[userRoleType.ROLE_ENGINEER, userRoleType.ROLE_ENGINEER_ENTRANCES, userRoleType.ROLE_ADMINISTRATOR, userRoleType.ROLE_OWNER]">
      <app-counts-card
        title="{{ 'dashboard.page.cards.intercom.title' | translate }}"
        firstLabel="{{ 'dashboard.page.cards.intercom.first' | translate }}"
        [percent]="(softwareIntercomsCounts$ | async)?.online"
        [firstValue]="(softwareIntercomsCounts$ | async)?.inOperation"
      ></app-counts-card>
    </div>

    <div *appSomeRoleContains="[userRoleType.ROLE_ENGINEER, userRoleType.ROLE_ENGINEER_VIDEO_SURVEILLANCE, userRoleType.ROLE_ADMINISTRATOR, userRoleType.ROLE_OWNER]">
      <app-counts-card
        title="{{ 'dashboard.page.cards.video_surveillance.title' | translate }}"
        firstLabel="{{ 'dashboard.page.cards.video_surveillance.first' | translate }}"
        [percent]="(videoSurveillanceCounts$ | async)?.online"
        [firstValue]="(videoSurveillanceCounts$ | async)?.inOperation"
      ></app-counts-card>
    </div>

    <div *appSomeRoleContains="[userRoleType.ROLE_ENGINEER, userRoleType.ROLE_ENGINEER_GATES, userRoleType.ROLE_ADMINISTRATOR, userRoleType.ROLE_OWNER]">
      <app-counts-card
        title="{{ 'dashboard.page.cards.gate.title' | translate }}"
        firstLabel="{{ 'dashboard.page.cards.gate.first' | translate }}"
        [percent]="(gatesCounts$ | async)?.online"
        [firstValue]="(gatesCounts$ | async)?.inOperation"
      ></app-counts-card>
    </div>
  </div>

  <div class="dashboard-page-info">
    <div *appSomeRoleContains="[userRoleType.BLOCKED_USER]">
      <app-dashboard-blocked-user
        [managerEmail]="managerEmail"
        [inn]="inn"
      ></app-dashboard-blocked-user>
    </div>
  </div>

  <div
    class="dashboard-page-info dashboard-page-info--grid"
    *appSomeRoleContains="[userRoleType.ROLE_ENGINEER, userRoleType.ROLE_ENGINEER_CAMERAS, userRoleType.ROLE_ADMINISTRATOR, userRoleType.ROLE_OWNER]"
  >
    <div *appSomeRoleContains="[userRoleType.ROLE_ENGINEER, userRoleType.ROLE_ADMINISTRATOR]">
      <app-counts-card
        title="{{ 'dashboard.page.cards.rdas.title' | translate }}"
        firstLabel="{{ 'dashboard.page.cards.rdas.first' | translate }}"
        secondLabel="{{ 'dashboard.page.cards.rdas.second' | translate }}"
        [showSecondLabel]="true"
        [percent]="(rdasCounts$ | async)?.online"
        [firstValue]="(rdasCounts$ | async)?.inOperation"
        [secondValue]="(rdasCounts$ | async)?.inStock"
      ></app-counts-card>
    </div>

    <div *appSomeRoleContains="[userRoleType.ROLE_ENGINEER, userRoleType.ROLE_ENGINEER_CAMERAS, userRoleType.ROLE_ADMINISTRATOR, userRoleType.ROLE_OWNER]">
      <app-counts-card
        title="{{ 'dashboard.page.cards.cameras.title' | translate }}"
        firstLabel="{{ 'dashboard.page.cards.cameras.first' | translate }}"
        [percent]="(camerasCounts$ | async)?.online"
        [firstValue]="(camerasCounts$ | async)?.inOperation"
      ></app-counts-card>
    </div>
  </div>

  <ng-container *ngIf="!environment.vivotec">
    <div
      class="dashboard-page-info dashboard-page-info--grid"
      *appSomeRoleContains="[userRoleType.ROLE_ENGINEER]"
    >
      <app-dashboard-reports></app-dashboard-reports>
    </div>
  </ng-container>

  <div *appSomeRoleContains="[userRoleType.ROLE_MANUFACTURER]">
    <app-dashboard-rdos-images [rdosImages]="rdosImages$ | async"></app-dashboard-rdos-images>
  </div>
</div>
