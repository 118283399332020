import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { IntercomType, IntercomTypeGeneratorUrls, ProtocolTypes } from '@app/shared/entities/rd';
import { DialogWrapperData } from '@app/shared/ui';

@Component({
  selector: 'app-intercom-keys-urls-generator-bottom-sheet',
  templateUrl: './intercom-keys-urls-generator-bottom-sheet.component.html',
  styleUrls: ['./intercom-keys-urls-generator-bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntercomKeysUrlsGeneratorBottomSheetComponent {
  public readonly ProtocolTypes = ProtocolTypes;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogWrapperData<
      {
        protocolType: ProtocolTypes,
        params: Array<keyof IntercomTypeGeneratorUrls>,
        intercom: IntercomType
      },
      Partial<IntercomTypeGeneratorUrls>
    >
  ) { }

  onGenerated(event: Partial<IntercomTypeGeneratorUrls>) {
    this.data.submit(event);
  }
}
