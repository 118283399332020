import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { IConvertedSignUpResponse } from '@app/views/abonents/components/pages/sign-ups-page/data-sourse-converter.pipe';
import { AutoConnectionStatusPipe } from '@app/views/abonents/components/pages/sign-ups-page/auto-connection-modal/auto-connection-status.pipe';
import { ServiceType } from '@app/views/services/submodules/shared/abonents-delegation/pipes/service-converter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { SharedPipesModule } from '@app/shared';
import { ReadableTimeFormat } from '@app/views/support-service/components/requests/requests.component';
import { ButtonModule } from 'primeng/button';
import {
  AbonentService,
  Account,
  AccountApiService,
  FlatApiService,
  FlatCreateResponse,
  IAbonentConnection,
  ServiceApiService,
  ServiceConnection,
  ServiceInfoResponse
} from '@app/shared/entities/rd';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ComponentLoaderModule } from '@app/shared/ui';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { isEqual } from 'lodash';
import { catchError, concatMap, switchMap, tap, toArray } from 'rxjs/operators';
import { BaseInfoPanelComponent } from '@app/shared/components/info-panel/components/base-info-panel/base-info-panel.component';
import { NativeDialogService } from '@app/shared/components/info-panel/services/native-dialog.service';
import { Response } from '@app/shared/components/info-panel/models/response';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin, from, of } from 'rxjs';
import { RequestsService } from '@app/shared/services';
import { Abonent, ServicesTypes, SignUpStatus } from '@app/shared/models';
import {
  AccountSelectionModule,
  AccountSelectionType
} from '@app/shared/components';

@Component({
  selector: 'app-auto-connection-modal',
  templateUrl: './auto-connection-modal.component.html',
  styleUrls: ['./auto-connection-modal.component.scss'],
  imports: [
    CommonModule,
    AutoConnectionStatusPipe,
    TranslateModule,
    SharedPipesModule,
    ButtonModule,
    InputSwitchModule,
    ComponentLoaderModule,
    ReactiveFormsModule,
    AccountSelectionModule
  ],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  providers: [NativeDialogService]
})
export class AutoConnectionModalComponent
  extends BaseInfoPanelComponent
  implements OnInit
{
  protected readonly ServiceType = ServiceType;
  public serviceInfoResponse: ServiceInfoResponse[] = [];
  public connectionForDeleting: IAbonentConnection | undefined = null;
  public loading = true;
  public accountStep = false;
  public requesting = false;
  public isDisconnectedMode: boolean;
  public abonent: Abonent = null;
  public accounts: Account[] = null;
  public hasPhysicalTube = new FormControl(!this.dynamicDialogConfig.data.signUp.isVirtual);

  constructor(
    public dynamicDialogConfig: DynamicDialogConfig<{
      signUp: IConvertedSignUpResponse;
    }>,
    public apiService: ServiceApiService,
    private flatApiService: FlatApiService,
    protected dialogService: NativeDialogService,
    private requestsService: RequestsService,
    private dynamicDialogRef: DynamicDialogRef,
    private abonentService: AbonentService,
    private accountApiService: AccountApiService
  ) {
    super(dialogService);
  }

  get updatedAt(): string {
    return ReadableTimeFormat.getAdaptiveDate(
      new Date(this.dynamicDialogConfig.data.signUp.updatedAt).toString(),
      {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      }
    );
  }

  ngOnInit(): void {
    this.isDisconnectedMode =
      this.dynamicDialogConfig.data.signUp.signUpType === 'DISCONNECTION';
    if (this.isDisconnectedMode) {
      forkJoin({
        entrances: this.apiService.getEntranceServices(
          this.dynamicDialogConfig.data.signUp.entranceId
        ),
        connections: this.apiService.abonentConnection(
          this.dynamicDialogConfig.data.signUp.abonentId
        ),
        request: of(this.dynamicDialogConfig.data.signUp.services)
      }).subscribe((response) => {
        if (
          response.request?.length !== 0 &&
          response.connections?.length === 0
        ) {
          this.showResponseStatus(
            'Внимание',
            'Эта заявка уже была обработана',
            Response.warn
          );
          this.updateSignUp(this.dynamicDialogConfig.data.signUp.signUpId);
          this.dynamicDialogRef.close(true);
          return;
        }
        this.connectionForDeleting = response.connections?.find((connection) =>
          isEqual(
            connection.flat.address.flat,
            this.dynamicDialogConfig.data.signUp.flatNumber
          )
        );
        if (this.connectionForDeleting === undefined) {
          this.showResponseStatus(
            'Внимание',
            'Эта заявка уже была обработана',
            Response.warn
          );
          this.updateSignUp(this.dynamicDialogConfig.data.signUp.signUpId);
          this.dynamicDialogRef.close(true);
          return;
        }
        const servicesForDeleting =
          this.connectionForDeleting?.connections?.filter((connection) =>
            this.dynamicDialogConfig.data.signUp?.services?.find((s) =>
              isEqual(s.id, connection.service.id)
            )
          );

        this.serviceInfoResponse = response.entrances
          .filter((e) =>
            servicesForDeleting?.find((s) => isEqual(s.service.id, e.id))
          )
          .map((res) => {
            return {
              ...res,
              control: new FormControl(!this.isAbonentChoice(res))
            };
          });
        this.loading = false;
      });
    } else {
      this.abonentService
        .createAbonent(this.dynamicDialogConfig.data.signUp.phone)
        .pipe(
          switchMap((abonent) => {
            this.abonent = abonent;
            return this.abonentService.getAbonentAccounts(abonent.id);
          })
        )
        .subscribe((response) => {
          this.accounts = response;
        });
      this.apiService
        .getEntranceServices(this.dynamicDialogConfig.data.signUp.entranceId)
        .subscribe((response) => {
          this.serviceInfoResponse = response.map((res) => {
            return {
              ...res,
              control: new FormControl(this.isAbonentChoice(res))
            };
          });
          this.loading = false;
        });
    }
  }

  public isAbonentChoice(service: ServiceInfoResponse): boolean {
    return !!this.dynamicDialogConfig.data.signUp.services?.find((s) =>
      isEqual(s.id, service.id)
    );
  }

  public onAction(signUpType: string): void {
    const signUp = this.dynamicDialogConfig.data.signUp;
    if (signUpType === 'CONNECTION') {
      if (!this.accounts?.length) {
        this.onAccountSelected(
          this.abonent,
          null,
          AccountSelectionType.NEW_ACCOUNT
        );
        return;
      }
      this.accountStep = true;
    }
    if (signUpType === 'DISCONNECTION') {
      this.requesting = true;
      const servicesForDeleting = this.serviceInfoResponse.filter(
        (s) => s.control.value === false
      );
      from(servicesForDeleting)
        .pipe(
          concatMap((s) => {
            return this.apiService
              .deleteServiceConnection(
                this.connectionForDeleting.connections.find((c) =>
                  isEqual(c.service.id, s.id)
                )?.id
              )
              .pipe(catchError((error: HttpErrorResponse) => of(error)));
          }),
          toArray(),
          tap((results) => {
            this.requesting = false;
            const errors = results.filter(
              (res) => res instanceof HttpErrorResponse
            );
            if (errors.length > 0) {
              const errorMessage = errors
                .map((err) => JSON.stringify(err))
                .join(', ');
              this.showResponseStatus(
                'Ошибка',
                `Произошла ошибка: ${errorMessage}`,
                Response.error
              );
            } else {
              this.showResponseStatus(
                'Успешно',
                'Все заявки были обработаны успешно',
                Response.success
              );
              this.updateSignUp(signUp.signUpId);
            }
          })
        )
        .subscribe();
    }
  }

  public onAccountSelected(
    abonent: Abonent,
    account: Account,
    type: AccountSelectionType
  ): void {
    this.requesting = true;
    this.accountStep = false;
    const signUp = this.dynamicDialogConfig.data.signUp;
    if (type === AccountSelectionType.SELCTED_ACCOUNT) {
      const servicesForConnection = this.serviceInfoResponse.filter(
        (s) => s.control.value === true
      );
      this.flatApiService
        .create({
          entranceId: signUp.address?.entrance?.id,
          flatNumber: signUp.flatNumber,
          virtual: !this.hasPhysicalTube.value,
          abonentId: signUp.abonentId
        })
        .subscribe((flat: FlatCreateResponse) => {
          from(servicesForConnection)
            .pipe(
              concatMap((s) => {
                return this.apiService
                  .createConnection(s.id, {
                    flatId: flat.id,
                    accountId: account.id
                  })
                  .pipe(catchError((error: HttpErrorResponse) => of(error)));
              }),
              toArray(),
              tap((results) => {
                this.requesting = false;
                const errors = results.filter(
                  (res) => res instanceof HttpErrorResponse
                );
                if (errors.length > 0) {
                  const errorMessage = errors
                    .map((err) => JSON.stringify(err))
                    .join(', ');
                  this.showResponseStatus(
                    'Ошибка',
                    `Произошла ошибка: ${errorMessage}`,
                    Response.error
                  );
                } else {
                  this.showResponseStatus(
                    'Успешно',
                    'Все заявки были обработаны успешно',
                    Response.success
                  );
                  this.updateSignUp(
                    this.dynamicDialogConfig.data.signUp.signUpId
                  );
                }
              })
            )
            .subscribe();
        });
    }
    if (type === AccountSelectionType.NEW_ACCOUNT) {
      const requestIds = this.serviceInfoResponse
        .filter((s) => s.control.value)
        .map((s) => s.id);
      this.accountApiService
        .createAbonentAndAccount(abonent.phone)
        .subscribe((response: Account) => {
          this.flatApiService
            .create({
              entranceId: signUp.address?.entrance?.id,
              flatNumber: signUp.flatNumber,
              virtual: !this.hasPhysicalTube.value,
              abonentId: signUp.abonentId
            })
            .pipe(
              switchMap((flat) => {
                return from(requestIds).pipe(
                  concatMap((id) =>
                    this.apiService
                      .createConnection(id, {
                        flatId: flat.id,
                        accountId: response.id
                      })
                      .pipe(catchError((error: HttpErrorResponse) => of(error)))
                  ),
                  toArray()
                );
              })
            )
            .subscribe({
              next: (
                connections: (HttpErrorResponse | ServiceConnection)[]
              ) => {
                this.requesting = false;
                const hasError = connections.some(
                  (connection) => connection instanceof HttpErrorResponse
                );
                if (hasError) {
                  const errorMessage = connections
                    .filter(
                      (connection) => connection instanceof HttpErrorResponse
                    )
                    .map(
                      (connection) => (connection as HttpErrorResponse).message
                    )
                    .join(', ');
                  this.showResponseStatus(
                    'Ошибка',
                    `Попробуйте ещё раз! ${errorMessage}`,
                    Response.error
                  );
                  return;
                }
                this.showResponseStatus(
                  'Успешно',
                  'Заявка была обработана',
                  Response.success
                );
                this.updateSignUp(signUp.signUpId);
              },
              error: (error) => {
                this.requesting = false;
                this.showResponseStatus(
                  'Ошибка',
                  `Произошла ошибка: ${error.message}`,
                  Response.error
                );
              }
            });
        });
    }
  }

  public isSoftwareIntercom(serviceType: string): boolean {
    return serviceType === ServicesTypes.SOFTWARE_INTERCOM
  }

  private updateSignUp(signUpId: number): void {
    this.requestsService
      .updateSignUpV2(signUpId, { status: SignUpStatus.CONNECTED })
      .pipe(catchError((error: HttpErrorResponse) => of(error)))
      .subscribe((response) => {
        if (response instanceof HttpErrorResponse) {
          this.showResponseStatus(
            'Ошибка',
            `Не удалось закрыть заявку: ${response.message}`,
            Response.error
          );
          return;
        }
        this.dynamicDialogRef.close(signUpId);
      });
  }
}
