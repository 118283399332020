import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CamerasTableContainerModule } from '@app/shared/containers';
import { ServiceCamerasModule } from '@app/views/services/components';
import { FindCamerasPopupModule } from '@app/views/services/submodules/mutual/find-cameras-popup/find-cameras-popup.module';
import { SoftwareIntercomCamerasComponent } from './software-intercom-cameras.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        ServiceCamerasModule,
        CamerasTableContainerModule,
        FindCamerasPopupModule,
        NgxTranslateModule
    ],
    declarations: [
        SoftwareIntercomCamerasComponent
    ],
    exports: [
        SoftwareIntercomCamerasComponent
    ]
})
export class SoftwareIntercomCamerasModule { }
