import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogWrapperData } from '@app/shared/ui';
import { IUserRequest } from '@app/views/company/models/company-page';

@Component({
  selector: 'app-company-create-user-popup',
  templateUrl: './company-create-user-popup.component.html',
  styleUrls: ['./company-create-user-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyCreateUserPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogWrapperData<IUserRequest, IUserRequest>,
    private dialogRef: MatDialogRef<CompanyCreateUserPopupComponent>
  ) {}

  public onSubmit(userRequest: IUserRequest): void {
    const request: IUserRequest = {
      ...userRequest,
      name: userRequest.email
    };

    this.data.submit(request);
    this.dialogRef.close();
  }
}
