import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { SnackbarService } from '@app/shared/components';
import { ConnectionCreateRequest, ConnectionCreateResponse, ConnectionService, ServiceResponse } from '@app/shared/entities/rd';
import { parseError } from '@app/shared/helpers';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { from, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { abonentConnectionPopupInitialState, AbonentConnectionPopupState } from './abonent-connection-popup.state';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AbonentConnectionPopupStore extends ComponentStore<AbonentConnectionPopupState> implements OnDestroy {
    readonly status$: Observable<'loading' | 'loaded' | 'close'> = this.select((state: AbonentConnectionPopupState) => state.status);

    readonly createConnections = this.effect((options$: Observable<
        { request: Partial<ConnectionCreateRequest> }
    >) =>
        options$.pipe(
            switchMap((options) => {
                this.updateStatusState('loading');

                return from(this.connectionService.createConnectionV2(options.request))
                    .pipe(
                        tapResponse(
                            (response: ConnectionCreateResponse) => {
                                this.snackbar.showMessage(
                                  this.translate.instant('abonent.connection.popup.message.create.success'),
                                  'success'
                                );
                                this.updateStatusState('close');
                            },
                            (error: HttpErrorResponse) => {
                                this.updateStatusState('loaded');
                                this.snackbar.showMessage(
                                  this.translate.instant('abonent.connection.popup.message.create.failed', {
                                    text: parseError(error)
                                  })
                                );
                            }
                        )
                    );
            })
        )
    );

    readonly updateStatusState = this.updater((state: AbonentConnectionPopupState, status: 'loading' | 'loaded' | 'close') => {
        return { ...state, status };
    });

    private onDestroy$: Subject<void> = new Subject();

    constructor(
        private connectionService: ConnectionService,
        private snackbar: SnackbarService,
        private translate: TranslateService
    ) {
        super(JSON.parse(JSON.stringify(abonentConnectionPopupInitialState)));
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    addStatusChangeListener(cb: (status: 'loading' | 'loaded' | 'close') => void) {
        this.status$
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(status => cb(status));
    }
}
