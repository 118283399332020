import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SelectSearchModule } from '@app/shared/containers/select-search';
import { SipAdapterUtilsService } from '@app/shared/entities/rd';
import { SharedPipesModule } from '@app/shared/pipes';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PbxOnRdaSearchSelectComponent } from './pbx-on-rda-search-select.component';
import { PbxOnRdaSearchSelectService } from './pbx-on-rda-search-select.service';
import { PbxOnRdaSearchSelectEffects, PbxOnRdaSearchSelectFacade, pbxOnRdaSearchSelectReducer } from './store';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SelectSearchModule,
        SharedPipesModule,
        EffectsModule.forFeature([
            PbxOnRdaSearchSelectEffects
        ]),
        StoreModule.forFeature('pbx-on-rda-search-select', pbxOnRdaSearchSelectReducer)
    ],
    declarations: [
        PbxOnRdaSearchSelectComponent
    ],
    providers: [
        SipAdapterUtilsService,
        PbxOnRdaSearchSelectService,
        PbxOnRdaSearchSelectFacade
    ],
    exports: [
        PbxOnRdaSearchSelectComponent
    ]
})
export class PbxOnRdaSearchSelectModule { }
