export interface PermissionsTreeRules {
    dashboard: { enabled: boolean };
    pbx: { enabled: boolean };
    entrances: {
        enabled: boolean;
        childrens: {
            id: { enabled: boolean };
        }
    };
    'apartment-video-surveillance': {
        enabled: boolean;
        childrens: {
            id: { enabled: boolean };
        }
    };
    intercom: {
        childrens: {
            adapters: { enabled: boolean };
            cameras: { enabled: boolean };
            'new-adapters': { enabled: boolean };
            batches: { enabled: boolean };
            shields: {
                enabled: boolean,
                childrens: {
                    shield: { enabled: boolean };
                }
            },
            models: { enabled: boolean };
            orders: { enabled: boolean };
        }
    };
    abonents: {
        childrens: {
            subscribers: {
                enabled: boolean,
                childrens: {
                    subscriber: { enabled: boolean };
                }
            };
            sign_ups: { enabled: boolean };
        }
    };
    mailing: {
        childrens: {
            all: { enabled: boolean };
            new: { enabled: boolean };
            id: { enabled: boolean };
        }
    };
    services: {
        enabled: boolean;
        childrens: {
            'video-surveillance': {
                enabled: boolean;
                childrens: {
                    id: { enabled: boolean };
                }
            },
            'video-manager': {
                enabled: boolean;
                childrens: {
                    add: { enabled: boolean };
                    edit: { enabled: boolean };
                    show: { enabled: boolean };
                }
            },
            gates: {
                enabled: boolean;
                childrens: {
                    id: { enabled: boolean };
                }
            },
            'software-intercoms': {
                enabled: boolean;
                childrens: {
                    id: { enabled: boolean };
                }
            }
        }
    };
    payments: { enabled: boolean };
    login: { enabled: boolean };
    support: { enabled: boolean };
}
