import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignUpResponse } from '@app/shared/models';
import { DialogWrapperData } from '@app/shared/ui';
import { DialogService } from 'primeng/dynamicdialog';
import {
  AutoConnectionModalComponent
} from '@app/views/abonents/components/pages/sign-ups-page/auto-connection-modal/auto-connection-modal.component';

@Component({
  selector: 'app-sign-ups-info-popup',
  templateUrl: './sign-ups-info-popup.component.html',
  styleUrls: ['./sign-ups-info-popup.component.scss'],
  providers: [DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignUpsInfoPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<{ signUp: SignUpResponse, showPersonalInfo: string }, null>,
    private primeDialogService: DialogService,
  ) { }

  public onAutoConnection(): void {
    this.primeDialogService.open(
      AutoConnectionModalComponent,
      {
        header: 'Заявка',
        width: '560px',
        data: {
          signUp: this.data.body.signUp
        }
      }
    );
  }
}
