<div class="popup delete-entrance-content">
  <app-info-block>
    <ng-container info-block-text>
      {{ 'shared.entrances.delete.entrance.popup.info' | translate }}
      <br>
      <strong>id: {{ entranceId }}</strong>
      ?
    </ng-container>
  </app-info-block>

  <div class="popup-actions">
    <button
      mat-raised-button
      color="error"
      [disabled]="loading"
      class="popup-actions__button"
      (click)="onDelete()"
    >
      {{ 'shared.entrances.delete.entrance.popup.button.delete' | translate }}
    </button>
  </div>
</div>
