import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Params, Router} from '@angular/router';
import {BreadcrumbService} from '@app/core';
import {SnackbarService} from '@app/shared/components/snackbar';
import {LoaderService} from '@app/shared/entities/common';
import {
  EmergencyAlertResponse,
  GitlabComponentsVersions,
  GitlabService,
  RDNPCService
} from '@app/shared/entities/integrations';
import {
  Company,
  CompanyApiService,
  IntercomType,
  IntercomTypeApiService,
  KeysResponse,
  ServiceApiService,
  ServiceResponse
} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {ServicesTypes} from '@app/shared/models';
import {ServicePageActionsService, ServicesHelper} from '@app/views/services/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
  GetComponentsVersions,
  GetComponentsVersionsFailure,
  GetComponentsVersionsSuccess,
  GetDependantService,
  GetDependantServiceSuccess,
  GetIntercomTypes,
  GetIntercomTypesFailure,
  GetIntercomTypesSuccess,
  GetService,
  GetServiceCompany,
  GetServiceCompanyFailure,
  GetServiceCompanySuccess,
  GetServiceFailure,
  GetServiceSuccess,
  ServicesActionTypes,
  ServiceSendEmergencyAlert,
  ServiceSendEmergencyAlertFailure,
  ServiceSendEmergencyAlertSuccess,
  UpdateService,
  UpdateServiceFailure,
  UpdateServiceSuccess
} from '../actions';
import {ServiceFacade} from '../services.facade';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServicesGeneralEffects {
  constructor(
    private actions$: Actions,
    private loader: LoaderService,
    private snackbar: SnackbarService,
    private router: Router,
    private rdnpcService: RDNPCService,
    private companyApiService: CompanyApiService,
    private serviceFacade: ServiceFacade,
    private gitlabService: GitlabService,
    private servicesHelper: ServicesHelper,
    private breadcrumb: BreadcrumbService,
    private serviceApiService: ServiceApiService,
    private intercomTypeApiService: IntercomTypeApiService,
    private servicePageService: ServicePageActionsService,
    private translate: TranslateService
  ) {
  }

  GetService$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetService>(ServicesActionTypes.GetService),
      switchMap((action: GetService) => {
        this.loader.loaderState = {state: true};

        return this.servicePageService.getService(action.serviceId)
          .pipe(
            map((response: ServiceResponse) => {
              this.loader.loaderState = {state: false};
              return new GetServiceSuccess(response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(
                this.translate.instant('services.message.get.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetServiceFailure());
            })
          );
      })
    )
  );

  // Remove after implementation RDBT-531
  // Replace tariff receipt from GetService
  GetDependantService$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetDependantService>(ServicesActionTypes.GetDependantService),
      switchMap((action: GetDependantService) => {
        this.loader.loaderState = {state: true};

        return this.servicePageService.getService(action.serviceId)
          .pipe(
            map((response: ServiceResponse) => {
              this.loader.loaderState = {state: false};
              return new GetDependantServiceSuccess(response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(
                this.translate.instant('services.message.get.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetServiceFailure());
            })
          );
      })
    )
  );

  GetServiceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceSuccess>(ServicesActionTypes.GetServiceSuccess),
      tap((action: GetServiceSuccess) => {
        this.breadcrumb.title = action.response.customName ?? action.response.name;

        this.checkRoute(action.response);

        this.serviceFacade.getRdasSources(action.response.rdas);
        this.serviceFacade.getCamerasSources(action.response.cameras);
        this.serviceFacade.getKeysSources(
          action.response.keys
            .filter((key: KeysResponse) =>
              action.response.rdas.findIndex(rda => rda.uid === key.adapterId) === -1)
            .map((key: KeysResponse) =>
              ({intercomUid: key.adapterId, key: key})
            )
        );

        if (action.response.companyId) {
          this.serviceFacade.getServiceCompany(action.response.companyId);
        }
      })
    ), {dispatch: false}
  );

  UpdateService$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateService>(ServicesActionTypes.UpdateService),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [UpdateService, number]) =>
        this.serviceApiService.update(serviceId, action.request)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.message.update.success'),
                'success'
              );
              return new UpdateServiceSuccess(action.request);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.message.update.failed', {
                  text: parseError(error)
                })
              );
              return of(new UpdateServiceFailure());
            })
          ))
    )
  );

  UpdateServiceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateServiceSuccess>(ServicesActionTypes.UpdateServiceSuccess),
      withLatestFrom(
        this.serviceFacade.serviceName$,
        this.serviceFacade.serviceCustomName$
      ),
      tap(([action, serviceName, serviceCustomName]: [UpdateServiceSuccess, string, string]) =>
        this.breadcrumb.title = serviceCustomName ?? serviceName
      )
    ), {dispatch: false}
  );

  GetServiceCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceCompany>(ServicesActionTypes.GetServiceCompany),
      switchMap((action: GetServiceCompany) => {
        return this.companyApiService.getCompanyById(action.companyId)
          .pipe(
            map((response: Company) =>
              new GetServiceCompanySuccess(response)
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.message.get_company.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetServiceCompanyFailure());
            })
          );
      })
    )
  );

  GetIntercomTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetIntercomTypes>(ServicesActionTypes.GetIntercomTypes),
      switchMap(() =>
        this.intercomTypeApiService.getAdaptersTypes()
          .pipe(
            map((response: IntercomType[]) =>
              new GetIntercomTypesSuccess(response)
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.message.get_intercom_types.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetIntercomTypesFailure());
            })
          )
      )
    )
  );

  GetComponentsVersions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetComponentsVersions>(ServicesActionTypes.GetComponentsVersions),
      switchMap(() =>
        this.gitlabService.getLatestVersions()
          .pipe(
            map((response: GitlabComponentsVersions) =>
              new GetComponentsVersionsSuccess(response)
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.message.get_component_versions.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetComponentsVersionsFailure());
            })
          )
      )
    )
  );

  ServiceSendEmergencyAlert$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ServiceSendEmergencyAlert>(ServicesActionTypes.ServiceSendEmergencyAlert),
      switchMap((action: ServiceSendEmergencyAlert) =>
        this.rdnpcService.sendEmergencyAlert(action.rdaUid)
          .pipe(
            map((response: EmergencyAlertResponse) => {
              if (response.result.indexOf('failed') !== -1) {
                this.snackbar.showMessage(
                  this.translate.instant('services.message.send_emergency_alert.no_access')
                );
              } else {
                this.snackbar.showMessage(
                  this.translate.instant('services.message.send_emergency_alert.success'),
                  'success'
                );
              }

              return new ServiceSendEmergencyAlertSuccess();
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.message.send_emergency_alert.failed', {
                  text: parseError(error)
                })
              );
              return of(new ServiceSendEmergencyAlertFailure());
            })
          )
      )
    )
  );

  private checkRoute(service: ServiceResponse) {
    const correctRoute: string = this.servicesHelper.getCorrectServiceRoute(service.type, ServicesTypes.VIDEO_SURVEILLANCE);

    if (correctRoute) {
      const currentQueryParams: Params = this.servicesHelper.getQueryParams();

      this.router
        .navigate([correctRoute, service.id], {queryParams: currentQueryParams})
        .then(() => {
        });
    }
  }
}
