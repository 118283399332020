import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ResourcePath, ResourcesHelper } from '@app/shared/entities';

@Component({
  selector: 'app-abonents-delegation-popup-content-close',
  templateUrl: './abonents-delegation-popup-content-close.component.html',
  styleUrls: ['./abonents-delegation-popup-content-close.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbonentsDelegationPopupContentCloseComponent {
  readonly resourcePath: typeof ResourcePath = ResourcePath;

  @Input() loading: boolean;

  @Output() private closeSignUp: EventEmitter<void> = new EventEmitter();

  constructor(
    public resourcesHelper: ResourcesHelper
  ) { }

  onClose() {
    this.closeSignUp.emit();
  }
}
