<ng-container *ngIf="tableData && !(loading$ | async) then content else loader"></ng-container>

<ng-template #loader>
  <div class="loader-section rrr" style='display: flex;
    justify-content: center;
    height: 420px;
    align-items: flex-end;'>
    <p-progressSpinner></p-progressSpinner>
  </div>
</ng-template>

<ng-template #content>
  <div *ngIf="hasTableDataAndLoading; else hasNotData" class="content-wrapper">
    <div class="content-wrapper-table">
      <div *ngIf="primeTableConfig.showDoublePaginator" class="content-wrapper-table-pagination">
        <ng-content select="[header-content]"></ng-content>
        <p-skeleton class="skeleton-paginator" *ngIf="loading"></p-skeleton>
        <app-custom-paginator
          *ngIf="!loading"
          [paginatorConfig]="paginatorConfig"
          (pageChange)="onPageChange($event.page)"
          (pageSizeChange)="onPageSizeChange($event)"
        ></app-custom-paginator>
      </div>
      <p-table
        #table
        [loading]="loading"
        [value]="tableData"
        [(selection)]="selectedTableData"
        (selectionChange)="onSelectionChange($event)"
        (onLazyLoad)='initLazyListener($event)'
        [lazy]="true"
        [scrollHeight]="scrollTableHeight"
        [resizableColumns]="resizableColumns"
        [scrollable]="scrollable"
        [styleClass]="styleClass"
        [dataKey]="primeTableConfig.key"
        [customSort]="customSort">
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 4rem" *ngIf="primeTableConfig.showCheckboxColumn">
              <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
            </th>
            <th *ngIf="primeTableConfig.showStatusColumn">
              <!--
                  Status columns
              -->
            </th>
            <ng-container *ngIf="primeTableConfig.showSwitchColumn">
              <th></th>
            </ng-container>
            <th
              *ngFor="let config of primeTableConfig.header"
              class="content-wrapper-table__header"
              [class]="config.className"
              [pSortableColumn]="config.sort && !loading ? config.field : null"
              [pTooltip]="config.tooltip"
              tooltipPosition="top"
            >
              <!--Sorting section-->
              <span class="content-wrapper-table__header-text">{{config.name}}</span>
              <p-sortIcon *ngIf="config.sort" [field]="config.field"></p-sortIcon>
              <!-- ************* -->
            </th>
            <!--Action header section-->
            <ng-container *ngIf="primeTableConfig.showActionColumn">
              <th></th>
            </ng-container>
            <ng-container *ngIf="primeTableConfig.showCustomActionColumn">
              <th></th>
            </ng-container>
            <ng-container *ngIf="primeTableConfig?.showRenderActionColumn">
              <th></th>
            </ng-container>
            <!-- ************* -->
          </tr>
        </ng-template>
        <ng-template pTemplate="loadingbody">
          <tr *ngFor="let row of mockRows">
            <td *ngFor="let column of mockHeaders">
              <p-skeleton class="skeleton-table-cell"></p-skeleton>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-data let-ri="rowIndex">
          <tr class="content-wrapper-table__body" *ngIf="loading === false">
            <!-- Checkbox section -->
            <td *ngIf="primeTableConfig.showCheckboxColumn">
              <p-tableCheckbox [value]="data"></p-tableCheckbox>
            </td>
            <!-- ************************** -->

            <!--Status (Switch) section-->
            <ng-container *ngIf="primeTableConfig.showSwitchColumn">
              <td>
                <p-inputSwitch
                  [disabled]="!primeTableConfig.showActionColumn"
                  class="content-wrapper-table__body__switch"
                  [(ngModel)]="data.status"
                  (ngModelChange)="onStatusChange(data)">
                </p-inputSwitch>
              </td>
            </ng-container>
            <!-- ************************** -->
            <td *ngFor="let config of primeTableConfig.body" [class]="config.className">
              <!--Link and navigation section-->
              <a
                @appearDisappear
                class="content-wrapper-table__body__link"
                *ngIf="config.isLink"
                (click)="onNavigate(data)"
                pTooltip="{{data[config.field]?.length > truncateLength ? data[config.field] : ''}}"
                tooltipPosition="bottom"
              >{{data[config.field]}}</a>
              <!-- ************************** -->

              <!-- List View -->
              <div  *ngIf="config.isListView">
                <ul style="margin: 0;padding-left: 15px;">
                  <li *ngFor="let li of data[config.field]">
                    <span style="color: #757685;">
                      {{('service-navbar.tab.action.addition.' + ServiceType[li]) | translate}}
                    </span>
                  </li>
                </ul>
              </div>
              <!-- ************************** -->

              <!--Base data section-->
              <div
                *ngIf="!config.isListView && !config.isLink && !config.isStatus && !config.isSwitch && !config?.useRender"
                [class.right-align]="config.isRight"
                [style]="'display:flex!important;gap: 10px;align-items:center;'">
                <div *ngIf="config.additionField">
                  <ng-container *ngIf="config.additionField.type === BodyAdditionField.base">
                    {{data[config.additionField.field]}}
                  </ng-container>
                  <ng-container *ngIf="config.additionField.type === BodyAdditionField.status">
                    <div
                      class="content-wrapper-table__body-status"
                      [ngClass]="
                        {
                          'content-wrapper-table__body-status-success': data[config.additionField.field] === true,
                          'content-wrapper-table__body-status-warn': data[config.additionField.field] === false
                        }">
                    </div>
                  </ng-container>
                  <ng-container *ngIf="config.additionField.type === BodyAdditionField.requestType">
                    <ng-container *ngIf="data[config.additionField.field] === 'DISCONNECTION'">
                      <img src="assets/icons/ic-disconection.svg" style="margin-top: 4px">
                    </ng-container>
                    <ng-container *ngIf="data[config.additionField.field] === 'CONNECTION'">
                      <img src="assets/icons/ic-connections.svg" style="margin-top: 4px">
                    </ng-container>
                  </ng-container>
                </div>
                <span
                  @appearDisappear
                  class="content-wrapper-table__body__text"
                  [style]="config.style"
                >
                  <img *ngIf="config.icon && !data[config.field]" [src]="config.icon" alt="'error'"> <!--трубка в заявках-->
                  <span *ngIf="!config.icon && config.field !== 'createdAt' && data[config.field]" >
                    {{data[config.field]}}
                  </span>
                  <span *ngIf="config.field === 'createdAt'">
                    {{data[config.field] | date: 'dd MMMM yyyy г.' }}
                  </span>
                  <span *ngIf="config.showArrow">
                    <mat-icon
                      [style]="'height: 25px; width: 18px; cursor: pointer'"
                      svgIcon="right-arrow"
                      (click)="arrowClick.emit(data)"
                    ></mat-icon>
                  </span>
                </span>
              </div>
              <!-- *************** -->

              <!-- Status column -->
              <div *ngIf="primeTableConfig.showStatusColumn && config">
                ....
              </div>
              <!-- *************** -->

              <!-- render component in cell -->
              <div *ngIf="config?.useRender">
                <app-render [component]="config?.renderOptions?.component" [updater]="config?.renderOptions?.updater"
                            [data]="data[config.field]" [rendererData]="data"></app-render>
              </div>
              <!-- *************** -->

              <!--Switch section-->
              <p-inputSwitch
                *ngIf="config.isSwitch"
                class="content-wrapper-table__body__switch"
                [disabled]="!primeTableConfig.showActionColumn || primeTableConfig.disableSwitch"
                [(ngModel)]="data.status"
                (ngModelChange)="onStatusChange(data)"
              ></p-inputSwitch>
              <!-- *************** -->
            </td>

            <!--Base Action section (edit, delete...)-->
            <th @appearDisappear *ngIf="primeTableConfig.showActionColumn">
              <div class="content-wrapper-table__body-action">
                <button
                  pButton
                  class="p-button-text"
                  icon="pi pi-pencil"
                  [disabled]="primeTableConfig.disableEdit"
                  (click)="onEdit(data)"
                  pTooltip="{{(primeTableConfig.disableEdit
                                    ? 'advertiserConversions.editDisabled'
                                    : 'advertiserConversions.edit')}}"
                  tooltipPosition="left"
                ></button>
                <button
                  pButton
                  class="p-button-text"
                  icon="pi pi-trash"
                  [disabled]="primeTableConfig.disableDelete"
                  *ngIf="!primeTableConfig.hideDelete"
                  (click)="onDelete(data)"
                ></button>
              </div>
            </th>
            <!-- *************** -->
            <!-- Custom action -->
            <th
              @appearDisappear
              *ngIf="primeTableConfig.showCustomActionColumn"
              class="content-wrapper-table__body-custom-actions">
              <p-overlayPanel #op>
                <ng-template pTemplate="content">
                  <ng-container *ngFor="let item of menuItems">
                    <div
                      *ngIf="item.name(data) as name"
                      (click)="onMenuItemSelect(item, op, data)"
                      [style]="item.style"
                      class="content-wrapper-table__body-custom"
                    ><span>{{name}}</span>
                    </div>
                  </ng-container>
                </ng-template>
              </p-overlayPanel>
              <button mat-icon-button aria-label="" (click)="op.toggle($event)">
                <mat-icon svgIcon="submenu"></mat-icon>
              </button>
            </th>
            <!-- *************** -->

            <!-- action from render component -->
            <th @appearDisappear *ngIf="primeTableConfig?.showRenderActionColumn">
              <app-render [component]="primeTableConfig?.renderOptions?.component"
                          [updater]="primeTableConfig?.renderOptions?.updater"
                          [data]="data" [rendererData]="data"></app-render>
            </th>
            <!-- *************** -->
          </tr>
        </ng-template>
      </p-table>
      <div class="content-wrapper-table-pagination">
        <app-custom-paginator
          [paginatorConfig]="paginatorConfig"
          (pageChange)="onPageChange($event.page)"
          (pageSizeChange)="onPageSizeChange($event)"
        ></app-custom-paginator>
      </div>
    </div>
  </div>
  <ng-template #hasNotData>
    <ng-content select="[empty-content]"></ng-content>
  </ng-template>
  <ng-template #inlineActionColumn>
    <ng-content select="[inline-action-column]"></ng-content>
  </ng-template>
</ng-template>
