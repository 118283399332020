import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy
} from '@angular/core';
import {Tariff} from '@app/shared/entities/rd/tariff';
import {ServiceApiService, ServiceResponse} from '@app/shared/entities/rd';
import {ServiceFacade} from '@app/views/services';
import {Observable, Subscription} from 'rxjs';
import {ServicesTypes} from '@app/shared/models';
import {SnackbarService} from '@app/shared/components';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-software-intercom-wizard-content-tariff',
  templateUrl: './software-intercom-wizard-content-tariff.component.html',
  styleUrls: ['./software-intercom-wizard-content-tariff.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SnackbarService]
})
export class SoftwareIntercomWizardContentTariffComponent implements OnDestroy {
  @Input() serviceId!: number;
  public tariff$: Observable<number> = this.serviceFacade.serviceTariff$;
  public serviceType$: Observable<ServicesTypes> = this.serviceFacade.serviceType$;
  public hardwareTariff: number;
  private subscriptions: Subscription[] = [];
  private dependantServices: Pick<ServiceResponse, 'id' | 'type'>[];
  constructor(
    protected readonly serviceApiService: ServiceApiService,
    protected readonly serviceFacade: ServiceFacade,
    protected readonly serviceApi: ServiceApiService,
    protected readonly snackbar: SnackbarService,
    protected readonly translate: TranslateService
  ) {
    this.subscriptions.push(
      ...[
        this.serviceFacade.dependantServices$.subscribe(dependant => {
          this.dependantServices = dependant;
        }),
        this.serviceApi.getInfo(this.getHardwareIntercomId()).subscribe(hardwareInfo => {
          this.hardwareTariff = hardwareInfo?.tariff;
        })
      ]
    );
  }

  ngOnDestroy() {
    this.subscriptions.map((subscription: Subscription) => {
      subscription.unsubscribe();
    });
  }

  public async onTariffChanged(data: Tariff): Promise<void> {
    if (data[`tariff${ServicesTypes.HARDWARE_INTERCOM}`]) {
      this.hardwareTariff = data[`tariff${ServicesTypes.HARDWARE_INTERCOM}`];
    }
    await this.serviceApiService.updateServiceTariff(this.serviceId, {tariff: data[`tariff${ServicesTypes.SOFTWARE_INTERCOM}`], applyTariffForConnections: data.applyTariffForConnections}).toPromise();
    await this.serviceApiService.updateServiceTariff(this.getHardwareIntercomId(), {tariff: data[`tariff${ServicesTypes.HARDWARE_INTERCOM}`], applyTariffForConnections: data.applyTariffForConnections}).toPromise();
    await this.serviceFacade.getConnectionsInit();
    await this.serviceFacade.getService(this.serviceId);

    this.snackbar.showMessage(
      this.translate.instant('services.software.intercom.wizard.tariff.message.tariff_changed.success'),
      'success'
    );
  }

  public getHardwareIntercomId(): number | undefined {
    let hardwareId;
    for (const service of this.dependantServices) {
      if (service.type === ServicesTypes.HARDWARE_INTERCOM) {
        hardwareId = service.id;
      }
    }
    return hardwareId;
  }
}
