import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { LocationResponse } from '../models';
import { HumanErrorTranslationService } from './human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class LocationsService extends ApiService {

  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1']);
  }

  public getLocations(group: 'keys' | 'cameras' = null): Observable<LocationResponse[]> {
    let params = new HttpParams();
    params.append('localizationName', environment.vivotec ? 'pt-br' : 'ru');

    if (group) {
      params = params.append('group', group);
    }

    return this.get(`/locations`, 1, { params });
  }
}
