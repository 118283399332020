import { AwxJobLogResponse, CreateAwxUpdateJobRequest, CreateAwxUpdateJobResponse } from '@app/shared/entities/rd/awx-jobs/models';
import { Action } from '@ngrx/store';
import { IntercomUpdateToolStatus } from '@app/shared/components';

export enum IntercomUpdateToolActionTypes {
  InitUpdateJobs = '[IntercomUpdateTool] InitUpdateJobs',
  ClearUpdateJobs = '[IntercomUpdateTool] ClearUpdateJobs',
  FinishUpdateJobs = '[IntercomUpdateTool] FinishUpdateJobs',
  SetIntercomStatus = '[IntercomUpdateTool] SetIntercomStatus',

  GetUpdateRdaaJob = '[IntercomUpdateTool] GetUpdateRdaaJob',
  GetUpdateRdaaJobSuccess = '[IntercomUpdateTool] GetUpdateRdaaJob Success',

  GetUpdateRdosJob = '[IntercomUpdateTool] GetUpdateRdosJob',
  GetUpdateRdosJobSuccess = '[IntercomUpdateTool] GetUpdateRdosJob Success',

  CreateUpdateRdaaJob = '[IntercomUpdateTool] CreateUpdateRdaaJob',
  CreateUpdateRdaaJobSuccess = '[IntercomUpdateTool] CreateUpdateRdaaJob Success',

  CreateUpdateRdosJob = '[IntercomUpdateTool] CreateUpdateRdosJob',
  CreateUpdateRdosJobSuccess = '[IntercomUpdateTool] CreateUpdateRdosJob Success',

  GetUpdateRdaaJobLogs = '[IntercomUpdateTool] GetUpdateRdaaJobLogs',
  GetUpdateRdaaJobLogsSuccess = '[IntercomUpdateTool] GetUpdateRdaaJobLogs Success',

  GetUpdateRdosJobLogs = '[IntercomUpdateTool] GetUpdateRdosJobLogs',
  GetUpdateRdosJobLogsSuccess = '[IntercomUpdateTool] GetUpdateRdosJobLogs Success',

  DeleteUpdateJobStateIfSuccess = '[IntercomUpdateTool] DeleteUpdateJobStateIfSuccess',
}

export class InitUpdateJobs implements Action {
  readonly type = IntercomUpdateToolActionTypes.InitUpdateJobs;

  constructor(public intercomUid: string) { }
}

export class ClearUpdateJobs implements Action {
  readonly type = IntercomUpdateToolActionTypes.ClearUpdateJobs;

  constructor(public intercomUid: string) { }
}

export class FinishUpdateJobs implements Action {
  readonly type = IntercomUpdateToolActionTypes.FinishUpdateJobs;

  constructor(
    public intercomUid: string,
    public rdaaUpdateState: boolean,
    public rdosUpdateState: boolean
  ) { }
}

export class SetIntercomStatus implements Action {
  readonly type = IntercomUpdateToolActionTypes.SetIntercomStatus;

  constructor(
    public intercomUid: string,
    public status: IntercomUpdateToolStatus
  ) { }
}

export class GetUpdateRdaaJob implements Action {
  readonly type = IntercomUpdateToolActionTypes.GetUpdateRdaaJob;

  constructor(public intercomUid: string, public jobId: number) { }
}

export class GetUpdateRdaaJobSuccess implements Action {
  readonly type = IntercomUpdateToolActionTypes.GetUpdateRdaaJobSuccess;

  constructor(public intercomUid: string, public response: CreateAwxUpdateJobResponse) { }
}

export class GetUpdateRdosJob implements Action {
  readonly type = IntercomUpdateToolActionTypes.GetUpdateRdosJob;

  constructor(public intercomUid: string, public jobId: number) { }
}

export class GetUpdateRdosJobSuccess implements Action {
  readonly type = IntercomUpdateToolActionTypes.GetUpdateRdosJobSuccess;

  constructor(public intercomUid: string, public response: CreateAwxUpdateJobResponse) { }
}

export class CreateUpdateRdaaJob implements Action {
  readonly type = IntercomUpdateToolActionTypes.CreateUpdateRdaaJob;

  constructor(public intercomUid: string, public request: CreateAwxUpdateJobRequest) { }
}

export class CreateUpdateRdaaJobSuccess implements Action {
  readonly type = IntercomUpdateToolActionTypes.CreateUpdateRdaaJobSuccess;

  constructor(public intercomUid: string, public response: CreateAwxUpdateJobResponse) { }
}

export class CreateUpdateRdosJob implements Action {
  readonly type = IntercomUpdateToolActionTypes.CreateUpdateRdosJob;

  constructor(public intercomUid: string, public request: CreateAwxUpdateJobRequest) { }
}

export class CreateUpdateRdosJobSuccess implements Action {
  readonly type = IntercomUpdateToolActionTypes.CreateUpdateRdosJobSuccess;

  constructor(public intercomUid: string, public response: CreateAwxUpdateJobResponse) { }
}

export class GetUpdateRdaaJobLogs implements Action {
  readonly type = IntercomUpdateToolActionTypes.GetUpdateRdaaJobLogs;

  constructor(
    public intercomUid: string,
    public jobId: number
  ) { }
}

export class GetUpdateRdaaJobLogsSuccess implements Action {
  readonly type = IntercomUpdateToolActionTypes.GetUpdateRdaaJobLogsSuccess;

  constructor(
    public intercomUid: string,
    public jobId: number,
    public response: AwxJobLogResponse[],
    public totalCount: number
  ) { }
}

export class GetUpdateRdosJobLogs implements Action {
  readonly type = IntercomUpdateToolActionTypes.GetUpdateRdosJobLogs;

  constructor(
    public intercomUid: string,
    public jobId: number
  ) { }
}

export class GetUpdateRdosJobLogsSuccess implements Action {
  readonly type = IntercomUpdateToolActionTypes.GetUpdateRdosJobLogsSuccess;

  constructor(
    public intercomUid: string,
    public jobId: number,
    public response: AwxJobLogResponse[],
    public totalCount: number
  ) { }
}

export class DeleteUpdateJobStateIfSuccess implements Action {
  readonly type = IntercomUpdateToolActionTypes.DeleteUpdateJobStateIfSuccess;

  constructor(public intercomUid: string) { }
}

export type IntercomUpdateToolActions =
  | InitUpdateJobs
  | ClearUpdateJobs
  | FinishUpdateJobs
  | SetIntercomStatus

  | GetUpdateRdaaJob
  | GetUpdateRdaaJobSuccess

  | GetUpdateRdosJob
  | GetUpdateRdosJobSuccess

  | CreateUpdateRdaaJob
  | CreateUpdateRdaaJobSuccess

  | CreateUpdateRdosJob
  | CreateUpdateRdosJobSuccess

  | GetUpdateRdaaJobLogs
  | GetUpdateRdaaJobLogsSuccess

  | GetUpdateRdosJobLogs
  | GetUpdateRdosJobLogsSuccess

  | DeleteUpdateJobStateIfSuccess;
