import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VideoSurveillanceModuleState, VideoSurveillancePageState, VideoSurveillanceRootState } from './video-surveillance-list.state';

export const videoSurveillanceModuleSelector =
    createFeatureSelector<VideoSurveillanceRootState, VideoSurveillanceModuleState>('video-surveillance');

const getState = createSelector(
    videoSurveillanceModuleSelector,
    (state: VideoSurveillanceModuleState) => state.videoSurveillance
);

const getVideoSurveillanceList = createSelector(
    getState,
    (state: VideoSurveillancePageState) => state.videoSurveillanceList
);


const getLoading = createSelector(
    getState,
    state => state.loading
);

const getPageData = createSelector(
    getState, 
    (state: VideoSurveillancePageState) => state.pageData
)

export const videoSurveillanceListSelector = {
    getState,
    getVideoSurveillanceList,
    getLoading, 
    getPageData
};
