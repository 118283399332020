import { Abonent } from '@app/shared/models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  GetAbonentsPage = '[Abonents] GetAbonentsPage',
  GetAbonentsPageSuccess = '[Abonents] GetAbonentsPage Success',
  GetAbonentsPageFailure = '[Abonents] GetAbonentsPage Failure'
}

export class GetAbonentsPage implements Action {
  readonly type = ActionTypes.GetAbonentsPage;

  constructor(
    public page: number,
    public size: number,
    public resolved: boolean,
    public phone?: string,
    public sort?: string
  ) {}
}

export class GetAbonentsPageSuccess implements Action {
  readonly type = ActionTypes.GetAbonentsPageSuccess;

  constructor(public payload: Abonent[], public totalCount: number) {}
}

export class GetAbonentsPageFailure implements Action {
  readonly type = ActionTypes.GetAbonentsPageFailure;

  constructor() {}
}

export type Actions =
  | GetAbonentsPage
  | GetAbonentsPageSuccess
  | GetAbonentsPageFailure;
