import { PaymentConfigurationSchedule } from './payment-configuration-schedule';

interface CreatePaymentConfigurationRequestYookassa {
  apiKey: string;
  shopId: string;
}

interface CreatePaymentConfigurationRequestRobokassa {
  uri: string;
  login: string;
  pass1: string;
  pass2: string;
  culture: string;
}

interface CreatePaymentConfigurationRequest {
  serviceType: string;
  paymentPeriodShift: boolean;
  schedule: PaymentConfigurationSchedule;
  configYookassa: CreatePaymentConfigurationRequestYookassa;
  configRobokassa: CreatePaymentConfigurationRequestRobokassa;
}

export {
  CreatePaymentConfigurationRequest,
  CreatePaymentConfigurationRequestYookassa,
  CreatePaymentConfigurationRequestRobokassa
};
