import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

const getOrdersPageState = createSelector(
    intercomModuleSelector,
    (state: IntercomModuleState) => state.getOrdersPage
);

export const getOrdersPage = createSelector(
    getOrdersPageState,
    state => state
);
export const getOrdersPageLoading = createSelector(
    getOrdersPageState,
    state => state.loading
);
export const getOrdersPageSuccess = createSelector(
    getOrdersPageState,
    state => ({ data: state.orders, totalCount: state.totalCount })
);

export const getOrdersPageFailed = createSelector(
    getOrdersPageState,
    state => state.error
);
