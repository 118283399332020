import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { RdeaStepperComponent } from './rdea-stepper.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        NgxTranslateModule
    ],
    declarations: [
        RdeaStepperComponent
    ],
    exports: [
        RdeaStepperComponent
    ]
})
export class RdeaStepperModule { }
