import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ActionTypes } from '../../actions/intercom.actions';

export interface State {
  success: boolean;
  error: HttpErrorResponse;
  loading: boolean;
}

export const initialState: State = {
  success: null,
  error: null,
  loading: false,
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.UpdateIntercom: {
      return {
        ...state,
        success: null,
        error: null,
        loading: true,
      };
    }
    case ActionTypes.UpdateIntercomSuccess: {
      return {
        ...state,
        success: true,
        error: null,
        loading: false
      };
    }
    case ActionTypes.UpdateIntercomFailure: {
      return {
        ...state,
        success: false,
        error: action.error,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}
