<form
  class="popup"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  autocomplete="off"
>
  <mat-form-field appearance="outline" *ngIf="!indexHidden">
    <mat-label>
      {{ 'shared.intercom.panels.edit.content.field.index' | translate }}
    </mat-label>
    <input
      type="text"
      matInput
      placeholder="{{ 'shared.intercom.panels.edit.content.placeholder.index' | translate }}"
      formControlName="index"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      class="none-padding"
    />
  </mat-form-field>

  <mat-form-field appearance="outline" class="relative-size">
    <mat-label>
      {{ 'shared.intercom.panels.edit.content.field.location_id' | translate }}
    </mat-label>
    <mat-select
      disableOptionCentering
      panelClass="mat-select-custom"
      formControlName="locationId"
    >
      <mat-option [value]="null">--</mat-option>
      <mat-option *ngFor="let location of locations" [value]="location.id">
        {{ location.name }}
      </mat-option>
    </mat-select>

    <mat-hint>
      {{ 'shared.intercom.panels.edit.content.hint.location_id' | translate }}
    </mat-hint>
  </mat-form-field>


  <div class="popup-actions action-block">
    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="loading"
      class="popup-actions__button"
    >
      {{ 'shared.intercom.panels.edit.content.button.submit' | translate }}
    </button>
  </div>
</form>
