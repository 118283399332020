import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/shared/components/snackbar';
import {LoaderService} from '@app/shared/entities/common';
import {RdaApiService, RdaResponse, ServiceApiService, ServiceResponse} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {ServicesTypes} from '@app/shared/models';
import {ServicePageActionsService} from '@app/views/services/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
  AddServiceRda,
  AddServiceRdaFailure,
  AddServiceRdaSuccess,
  ChangeServiceRda,
  ChangeServiceRdaFailure,
  ChangeServiceRdaSuccess,
  DeleteServiceRda,
  DeleteServiceRdaFailure,
  DeleteServiceRdaSuccess,
  GetServiceRdas,
  GetServiceRdasFailure,
  GetServiceRdasSuccess,
  ServicesActionTypes,
  UpdateServiceRda,
  UpdateServiceRdaFailure,
  UpdateServiceRdaSuccess
} from '../actions';
import {ServiceFacade} from '../services.facade';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServicesIntercomsEffects {
  constructor(
    private actions$: Actions,
    private loader: LoaderService,
    private snackbar: SnackbarService,
    private rdaApiService: RdaApiService,
    private serviceApiService: ServiceApiService,
    private servicePageService: ServicePageActionsService,
    private serviceFacade: ServiceFacade,
    private translate: TranslateService
  ) {
  }

  GetServiceRdas$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceRdas>(ServicesActionTypes.GetServiceRdas),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [GetServiceRdas, number]) => {
        this.loader.loaderState = {state: true};

        return this.serviceApiService.getRdas(serviceId)
          .pipe(
            map((response: RdaResponse[]) => {
              this.loader.loaderState = {state: false};
              return new GetServiceRdasSuccess(response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(
                this.translate.instant('services.intercoms.message.get_service_rdas.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetServiceRdasFailure(error));
            })
          );
      })
    )
  );

  GetServiceRdasSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceRdasSuccess>(ServicesActionTypes.GetServiceRdasSuccess),
      withLatestFrom(this.serviceFacade.serviceType$),
      tap(([action, serviceType]: [GetServiceRdasSuccess, ServicesTypes]) => {
        if (serviceType === ServicesTypes.SOFTWARE_INTERCOM) {
          action.rdas.forEach((rda: RdaResponse) => {
            this.serviceFacade.getPbxOnRda(rda.uid);
            this.serviceFacade.getTranslationTunings(rda.uid);
          });
        }

        this.serviceFacade.getRdasSources(action.rdas);
        this.serviceFacade.getServiceKeys();
      })
    ), {dispatch: false}
  );

  AddServiceRda$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddServiceRda>(ServicesActionTypes.AddServiceRda),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [AddServiceRda, number]) =>
        from(this.servicePageService.addRda(serviceId, action.request))
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercoms.message.add_service_rda.success'),
                'success'
              );
              this.serviceFacade.getServiceRdas();
              return new AddServiceRdaSuccess(action.request);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercoms.message.add_service_rda.failed', {
                  text: parseError(error)
                })
              );
              return of(new AddServiceRdaFailure());
            })
          )
      )
    )
  );

  UpdateServiceRda$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateServiceRda>(ServicesActionTypes.UpdateServiceRda),
      switchMap((action: UpdateServiceRda) =>
        this.rdaApiService.updateAdapter(action.rdaUid, action.request)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercoms.message.update_service_rda.success'),
                'success'
              );
              return new UpdateServiceRdaSuccess(action.request);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercoms.message.update_service_rda.failed', {
                  text: parseError(error)
                })
              );
              return of(new UpdateServiceRdaFailure());
            })
          )
      )
    )
  );

  DeleteServiceRda$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceRda>(ServicesActionTypes.DeleteServiceRda),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [DeleteServiceRda, number]) =>
        from(this.servicePageService.disconnectIntercom(serviceId, action.intercom))
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercoms.message.delete_service_rda.success'),
                'success'
              );
              return new DeleteServiceRdaSuccess(action.intercom);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercoms.message.delete_service_rda.failed', {
                  text: parseError(error)
                })
              );
              return of(new DeleteServiceRdaFailure());
            })
          )
      )
    )
  );

  DeleteServiceRdaSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceRdaSuccess>(ServicesActionTypes.DeleteServiceRdaSuccess),
      tap((action: DeleteServiceRdaSuccess) => {
        this.serviceFacade.deleteRdaSource(action.intercom.uid);
      })
    ), {dispatch: false}
  );

  ChangeServiceRda$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ChangeServiceRda>(ServicesActionTypes.ChangeServiceRda),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [ChangeServiceRda, number]) =>
        from(this.servicePageService.replaceRda(serviceId, action.request, action.oldRdaId))
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercoms.message.change_service_rda.success'),
                'success'
              );
              this.serviceFacade.getServiceRdas();
              return new ChangeServiceRdaSuccess(action.request);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercoms.message.change_service_rda.failed', {
                  text: parseError(error)
                })
              );
              return of(new ChangeServiceRdaFailure());
            })
          )
      )
    )
  );
}
