import { HttpErrorResponse } from '@angular/common/http';
import { IntercomType } from '@app/shared/entities/rd';
import { RdaNewResponse, RdaResponse, RdaUpdateRequest } from '@app/shared/entities/rd/rda';
import { Address } from '@app/shared/models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  GetNewIntercoms = '[Shared] GetNewIntercoms',
  GetNewIntercomsSuccess = '[Shared] GetNewIntercoms Success',
  GetNewIntercomsFailure = '[Shared] GetNewIntercoms Failure',
  GetNewIntercomsClear = '[Shared] GetNewIntercoms Clear',

  GetAvaliableIntercoms = '[Shared] GetAvaliableIntercoms',
  GetAvaliableIntercomsSuccess = '[Shared] GetAvaliableIntercoms Success',
  GetAvaliableIntercomsFailure = '[Shared] GetAvaliableIntercoms Failure',

  GetAvaliableIntercomsV1 = '[Shared] GetAvaliableIntercomsV1',
  GetAvaliableIntercomsV1Success = '[Shared] GetAvaliableIntercomsV1 Success',
  GetAvaliableIntercomsV1Failure = '[Shared] GetAvaliableIntercomsV1 Failure',

  GetIntercomTypes = '[Shared] GetGetIntercomTypes',
  GetIntercomTypesSuccess = '[Shared] GetGetIntercomTypes Success',
  GetIntercomTypesFailure = '[Shared] GetGetIntercomTypes Failure',

  UpdateIntercom = '[Shared] UpdateIntercom',
  UpdateIntercomSuccess = '[Shared] UpdateIntercom Success',
  UpdateIntercomFailure = '[Shared] UpdateIntercom Failure',

  DisconnectAdapter = '[Shared] DisconnectAdapter',
  DisconnectAdapterSuccess = '[Shared] DisconnectAdapter Success',
  DisconnectAdapterFailure = '[Shared] DisconnectAdapter Failure',
  DisconnectAdapterClear = '[Shared] DisconnectAdapter Clear'
}

export class GetAvaliableIntercoms implements Action {
  readonly type = ActionTypes.GetAvaliableIntercoms;

  constructor(public page: number = 0, public size: number = 10, public inactive = true, public ipType: boolean, public mode: string, public address: string, public sort?: string) { }
}

export class GetAvaliableIntercomsSuccess implements Action {
  readonly type = ActionTypes.GetAvaliableIntercomsSuccess;

  constructor(public payload: RdaResponse[], public totalCount: number) { }
}

export class GetAvaliableIntercomsFailure implements Action {
  readonly type = ActionTypes.GetAvaliableIntercomsFailure;

  constructor(public payload: string) { }
}

export class GetAvaliableIntercomsV1 implements Action {
  readonly type = ActionTypes.GetAvaliableIntercomsV1;

  constructor(public inactive?: boolean) { }
}

export class GetAvaliableIntercomsV1Success implements Action {
  readonly type = ActionTypes.GetAvaliableIntercomsV1Success;

  constructor(public payload: RdaResponse[]) { }
}

export class GetAvaliableIntercomsV1Failure implements Action {
  readonly type = ActionTypes.GetAvaliableIntercomsV1Failure;

  constructor(public payload: HttpErrorResponse) { }
}

export class GetNewIntercoms implements Action {
  readonly type = ActionTypes.GetNewIntercoms;

  constructor(public page: number = 0, public size: number = 10, public uid?: string) { }
}

export class GetNewIntercomsSuccess implements Action {
  readonly type = ActionTypes.GetNewIntercomsSuccess;

  constructor(public payload: RdaNewResponse[], public totalCount: number) { }
}

export class GetNewIntercomsFailure implements Action {
  readonly type = ActionTypes.GetNewIntercomsFailure;

  constructor(public payload: string) { }
}

export class GetNewIntercomsClear implements Action {
  readonly type = ActionTypes.GetNewIntercomsClear;
}

export class GetIntercomTypes implements Action {
  readonly type = ActionTypes.GetIntercomTypes;

  constructor() { }
}

export class GetIntercomTypesSuccess implements Action {
  readonly type = ActionTypes.GetIntercomTypesSuccess;

  constructor(public payload: IntercomType[]) { }
}

export class GetIntercomTypesFailure implements Action {
  readonly type = ActionTypes.GetIntercomTypesFailure;

  constructor(public payload: string) { }
}

export class UpdateIntercom implements Action {
  readonly type = ActionTypes.UpdateIntercom;

  constructor(public request: RdaUpdateRequest) { }
}

export class UpdateIntercomSuccess implements Action {
  readonly type = ActionTypes.UpdateIntercomSuccess;

  constructor(public response: RdaUpdateRequest) { }
}

export class UpdateIntercomFailure implements Action {
  readonly type = ActionTypes.UpdateIntercomFailure;

  constructor(public error: HttpErrorResponse) { }
}

export class DisconnectAdapter implements Action {
  readonly type = ActionTypes.DisconnectAdapter;

  constructor(public adapter: RdaResponse) { }
}

export class DisconnectAdapterSuccess implements Action {
  readonly type = ActionTypes.DisconnectAdapterSuccess;

  constructor() { }
}

export class DisconnectAdapterFailure implements Action {
  readonly type = ActionTypes.DisconnectAdapterFailure;

  constructor(public payload: HttpErrorResponse) { }
}

export class DisconnectAdapterClear implements Action {
  readonly type = ActionTypes.DisconnectAdapterClear;

  constructor() { }
}

export type Actions =
  | GetNewIntercoms
  | GetNewIntercomsSuccess
  | GetNewIntercomsFailure
  | GetNewIntercomsClear
  | GetAvaliableIntercoms
  | GetAvaliableIntercomsSuccess
  | GetAvaliableIntercomsFailure
  | GetAvaliableIntercomsV1
  | GetAvaliableIntercomsV1Success
  | GetAvaliableIntercomsV1Failure
  | GetIntercomTypes
  | GetIntercomTypesSuccess
  | GetIntercomTypesFailure
  | UpdateIntercom
  | UpdateIntercomSuccess
  | UpdateIntercomFailure
  | DisconnectAdapter
  | DisconnectAdapterSuccess
  | DisconnectAdapterFailure
  | DisconnectAdapterClear;
