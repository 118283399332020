<div class="form-container">
  <p [innerHTML]="'delete.address.popup.text' | translate:{ value: data.adapter.uid }" ></p>
  <div class="action-block">
    <button
      mat-raised-button
      color="error"
      (click)="onDelete()"
    >
      {{ 'delete.address.popup.button.submit' | translate }}
    </button>
  </div>
</div>
