<mat-card class="card-container">
  <mat-card-content>
    <mat-list class="mat-list-messages" infiniteScroll [infiniteScrollDistance]="2" [infiniteScrollThrottle]="50"
      [fromRoot]="true" [alwaysCallback]="true" [infiniteScrollContainer]="'.mat-list-messages'" (scrolled)="onLoad()">
      <mat-list-item [ngClass]="{ 'mat-list-item-selected': selectedMailingIdx === -1 }"
        (click)="isMobile() ? onRoute(null) : onCreate()">
        <mat-icon mat-list-icon>add</mat-icon>
        <h3 mat-line>
          {{ 'mailing.page.history.button.create' | translate }}
        </h3>
        <mat-divider></mat-divider>
      </mat-list-item>

      <mat-list-item [ngClass]="{ 'mat-list-item-selected': selectedMailingIdx === idx }"
        *ngFor="let mailing of mailings$ | async; let idx = index"
        (click)="isMobile() ? onRoute(mailing) : onSelect(mailing, idx)">
        <mat-icon mat-list-icon>drafts</mat-icon>
        <h3 mat-line>{{ mailing.user }}</h3>
        <p class="mat-list-item-text" mat-line> {{ mailing.message }} </p>
        <p class="mat-list-item-date" mat-line>{{ mailing.createdAt | date: 'dd.MM.yyyy HH:mm:ss' }}</p>
        <mat-divider></mat-divider>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
  <app-component-loader *ngIf="loading$ | async"></app-component-loader>
</mat-card>
