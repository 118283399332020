import { createSelector } from '@ngrx/store';
import { SharedModuleStates } from '../../reducers/reducers';
import { sharedModuleSelector } from '../shared-module.state';

export const setCameraAsDefaultState = createSelector(
    sharedModuleSelector,
    (state: SharedModuleStates) => state.setCameraAsDefault
);

export const setCameraAsDefaultStateSuccess = createSelector(
    setCameraAsDefaultState,
    state => state.success
);

export const setCameraAsDefaultStateFailure = createSelector(
    setCameraAsDefaultState,
    state => state.error
);

export const setCameraAsDefaultStateLoading = createSelector(
    setCameraAsDefaultState,
    state => state.loading
);
