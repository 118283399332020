import { Component, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { PopupBasicComponent } from '@app/shared/templates';

@Component({
  selector: 'app-order-assign-popup',
  templateUrl: './order-assign-popup.component.html',
  styleUrls: ['./order-assign-popup.component.scss']
})
export class OrderAssignPopupComponent extends PopupBasicComponent {
  constructor(@Inject('subject') private subject: Subject<any>) {
    super();
  }

  onSubmit() {
    this.subject.next(true);
  }
}
