import { Injectable } from '@angular/core';
import { parseError } from '@app/shared/helpers';
import { SipAdapterResponse } from '../models';
import { SipAdapterApiService } from './sip-adapter-api.service';

@Injectable({
    providedIn: 'root'
})
export class SipAdapterUtilsService {
    constructor(
        private sipAdapterApi: SipAdapterApiService
    ) { }

    prepareSipAdapterSocket(sipAdapter: SipAdapterResponse) {
        return sipAdapter.ip ? sipAdapter.ip + (sipAdapter.port ? `:${sipAdapter.port}` : '') : null;
    }

    async getList(cb: (sipAdapters: SipAdapterResponse[], error: string) => void) {
        try {
            const response = await this.sipAdapterApi.getList().toPromise();
            cb(response, null);
        } catch (error) {
            cb(null, parseError(error));
        }
    }
}
