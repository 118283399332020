<mat-card class="card-container docs">
  <div class="header">
    <div class="title-block">
      {{ 'company.about.page.docs.title' | translate }}
    </div>
  </div>
  <mat-card-content class="docs-content">
    <div class="docs-links">
      <a [href]="personalAgreementPageLink" target="_blank">
        {{ 'company.about.page.docs.links.personal_agreement' | translate }}
      </a>
      <a [href]="privacyPolicy" target="_blank">
        {{ 'company.about.page.docs.links.privacy_policy' | translate }}
      </a>
      <a *ngIf="licenseAgreement" [href]="licenseAgreement" target="_blank">
        {{ 'company.about.page.docs.links.license_agreement' | translate }}
      </a>
    </div>
  </mat-card-content>
</mat-card>
