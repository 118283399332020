export enum GraylogRdaPatterns {
    NETWORK_UPDATE = 'NETWORK: UPDATE received ([\\d]+) item\\(s\\)',
    INTERCOM_DIAL = 'INTERCOM: DIAL ([\\d]+)',
    NETWORK_CALL_SETUP = 'NETWORK: CALL SETUP',
    INTERCOM_PICKUP = 'INTERCOM: PICKUP',
    INTERCOM_DROP = 'INTERCOM: DROP',
    NETWORK_RELEASE_RECEIVED = 'NETWORK: RELEASE received ([\\w]+) ([\\d]+)',
    NETWORK_CONNECT_RECEIVED = 'NETWORK: CONNECT received ([\\w]+) ([\\d]+.[\\d]+.[\\d]+.[\\d]+) ([\\d]+) ([\\w;]+)',
    NETWORK_ACTIVATE_RECEIVED = 'NETWORK: ACTIVATE received ([\\w-]+) ([\\w]+)',
    NETWORK_VIDEOSOURCES_RECEIVED = 'NETWORK: VIDEOSOURCES received',
    NETWORK_CONFIGURATION_RECEIVED = 'NETWORK: CONFIGURATION received : ([\\w\\W]+)',
    NETWORK_ACTIVATED_SENT = 'NETWORK: ACTIVATED sent : ([\\w-]+) ([\\w_]+)',
    NETWORK_CONNECTION_CLOSED = 'NETWORK: connection closed',
    NETWORK_CONNECTING = 'NETWORK: connecting ([\\w\\d.]+):([\\d]+)',
    NETWORK_CONNECTED = 'NETWORK: connected ([\\d.]+):([\\d]+) local IP : ([\\d.]+)'
}
