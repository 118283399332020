import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PbxOnRdaSearchSelectModuleRootState, PbxOnRdaSearchSelectModuleState, PbxOnRdaSearchSelectState } from './pbx-on-rda-search-select.state';

export const pbxOnRdaSearchSelectSelector = createFeatureSelector<
    PbxOnRdaSearchSelectModuleRootState, PbxOnRdaSearchSelectModuleState
>('pbx-on-rda-search-select');

const selectState = createSelector(
    pbxOnRdaSearchSelectSelector,
    (state: PbxOnRdaSearchSelectModuleState) => state.pbxOnRdaSearchSelect
);

const selectPbxOnRdaList = createSelector(
    selectState,
    (state: PbxOnRdaSearchSelectState) => state.pbxOnRdaList
);

const selectSelectedPbxRda = createSelector(
    selectState,
    (state: PbxOnRdaSearchSelectState) => state.selectedPbxOnRda
);

const selectLoading = createSelector(
    selectState,
    (state: PbxOnRdaSearchSelectState) => state.loading
);

export const pbxOnRdaSearchSelectSelectors = {
    selectPbxOnRdaList,
    selectSelectedPbxRda,
    selectLoading
};
