<app-dialog-wrapper
  [title]="data.title"
  [progress]="(popupService.state$ | async) === 'loading'"
  [componentName]="data.componentName"
>
  <app-delete-intercom-content
    [rdaUid]="data.body.rdaUid"
    [loading]="(popupService.state$ | async) === 'loading'"
    (deleteRda)="onDelete()"
  ></app-delete-intercom-content>
</app-dialog-wrapper>