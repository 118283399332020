import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { EntrancePageResponse, SelectSearch } from '@app/shared/models';
import { Observable } from 'rxjs';
import { AddressSearchSelectParams } from './address-select-search-params.model';
import { AddressSearchSelectStore } from './store/address-search-select.store';

@Component({
  selector: 'app-address-search-select',
  templateUrl: './address-search-select.component.html',
  styleUrls: ['./address-search-select.component.scss'],
  providers: [AddressSearchSelectStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddressSearchSelectComponent implements OnInit {
  @Input() title: string;
  @Input() params: AddressSearchSelectParams;
  @Input() options: { intercomIndexesRequired?: boolean } = {};
  @Input() withoutDescription: boolean;

  entranceId: number;
  loading$: Observable<boolean> = this.store.lodaing$;
  addresses$: Observable<{ value: EntrancePageResponse, select: SelectSearch }[]> = this.store.addresses$;
  selectSearch$: Observable<SelectSearch> = this.store.selectSearch$;

  @Output() private changeMode: EventEmitter<void> = new EventEmitter();
  @Output() private addressSelected: EventEmitter<{ address: EntrancePageResponse }> = new EventEmitter();

  constructor(
    private store: AddressSearchSelectStore
  ) { }

  ngOnInit() {
    this.store.getSelectedAddress((address: EntrancePageResponse) => {
      this.entranceId = address.id;
      this.addressSelected.emit({ address });
    });

    this.onGetAddresses();
  }

  onGetAddresses(address?: string) {
    this.store.getAddresses({
      address,
      intercomIndexesRequired: this.options?.intercomIndexesRequired
    });
  }

  onSelectAddress(entranceId: number) {
    if (entranceId === -1) {
      this.changeMode.emit();
      return;
    }

    this.store.selectAddressUsingEntranceId(entranceId);
  }
}
