import { LoggerService } from '@app/shared/entities/common';
import * as Plyr from 'plyr';
import { VideoPlayerCustomClickEventType } from '../../models';
import { PlyrTimeHelper } from '../plyr-globals';
import { RdeaPlyr } from '../rdea-plyr';
import { PlyrControlBasicHelper } from './base';
import { PlyrChangeModeButtonControlHelper } from './change-mode-button-control';
import { PlyrEventControlsHelper, PlyrEventsControlsEventType } from './events-controls';
import { PlyrPlaylistsControlsEventType, PlyrPlaylistsControlsHelper } from './playlists-controls';
import { PlyrProgressBarControlEventType, PlyrProgressControlHelper } from './progress-bar-control';
import { PlyrSeekingControlsEventType } from './seeking-controls/plyr-seeking-controls-event-type.enum';
import { PlyrSeekingControlsHelper } from './seeking-controls/plyr-seeking-controls-helper';
import { PlyrVideoshotButtonControlHelper } from './videoshot-button-control';
import { PlyrOpenDoorHelper } from './open-door-control';
import {TranslateService} from '@ngx-translate/core';

/**
 * Class for controls helpers init
 */
export class PlyrControlsHelper extends PlyrControlBasicHelper<VideoPlayerCustomClickEventType> {
    constructor(
        plyr: RdeaPlyr,
        private loggerService: LoggerService,
        private translate: TranslateService
    ) {
        super();

        this.prepareInternalControls();
        this.enablePlayerStateListeners(plyr);
        this.enableInternalControlsListeners(plyr);

        if (this.loggerService.enabled) {
            this.enableHTML5EvensListeners(plyr);
            this.enableStandartMediaEventsListeners(plyr);
        }
    }

    /**
     * Reset progress bar bounds, ranges, timestamps and events
     */
    resetControls() {
        PlyrTimeHelper.prepareProgressBarBounds(null, null);
        this.progress().prepareAndDrawGaps(null);
        this.progress().prepareAndDrawEvents(null);
        this.events().prepareEvents(null);
    }

    /**
     * Get PlyrProgressControlHelper instance
     * @returns PlyrProgressControlHelper instance
     */
    progress(): PlyrProgressControlHelper {
        return this.internalControls.progress as PlyrProgressControlHelper;
    }

    /**
     * Get PlyrEventControlsHelper instance
     * @returns PlyrEventControlsHelper instance
     */
    events(): PlyrEventControlsHelper {
        return this.internalControls.event as PlyrEventControlsHelper;
    }

    /**
     * Get PlyrPlaylistsControlsHelper instance
     * @returns PlyrPlaylistsControlsHelper instance
     */
    playlists(): PlyrPlaylistsControlsHelper {
        return this.internalControls.playlists as PlyrPlaylistsControlsHelper;
    }

    /**
     * Get PlyrVideoshotButtonControlHelper instance
     * @returns PlyrVideoshotButtonControlHelper instance
     */
    videoshot(): PlyrVideoshotButtonControlHelper {
        return this.internalControls.videoshot as PlyrVideoshotButtonControlHelper;
    }

    /**
     * Get PlyrChangeModeButtonControlHelper instance
     * @returns PlyrChangeModeButtonControlHelper instance
     */
    changeMode(): PlyrChangeModeButtonControlHelper {
        return this.internalControls.changeMode as PlyrChangeModeButtonControlHelper;
    }

    openDoor(): PlyrOpenDoorHelper {
      return this.internalControls.openDoor as PlyrOpenDoorHelper;
    }

    /**
     * Get PlyrSeekingControlsHelper instance
     * @returns PlyrSeekingControlsHelper instance
     */
    seeking(): PlyrSeekingControlsHelper {
        return this.internalControls.seeking as PlyrSeekingControlsHelper;
    }

    /**
     * Prepare controls which contains into Plyr Control
     */
    private prepareInternalControls() {
        this.internalControls = {
            progress: new PlyrProgressControlHelper(this.loggerService),
            event: new PlyrEventControlsHelper(this.translate),
            playlists: new PlyrPlaylistsControlsHelper(this.translate),
            videoshot: new PlyrVideoshotButtonControlHelper(),
            changeMode: new PlyrChangeModeButtonControlHelper(),
            openDoor: new PlyrOpenDoorHelper(),
            seeking: new PlyrSeekingControlsHelper(this.translate)
        };
    }

    /**
     * Enable listeners for internal controls
     * @param {RdeaPlyr} Plyr instance
     */
    private enableInternalControlsListeners(plyr: RdeaPlyr) {
        this.seeking().addClickListener((type: PlyrSeekingControlsEventType, e: MouseEvent) => {
            if (type === PlyrSeekingControlsEventType.CLICK_FORWARD) {
                plyr.currentTime += 10;
            } else if (type === PlyrSeekingControlsEventType.CLICK_BACKWARD) {
                plyr.currentTime -= 10;
            }

            this.progress().updateCurrentTime(plyr.currentTime);
        });

        this.playlists().addClickListener((type: PlyrPlaylistsControlsEventType, e: MouseEvent) => {
            if (type === PlyrPlaylistsControlsEventType.SELECT_ITEM) {
                this.progress().clearCanvas();

                this.clickListener$.next({
                    type: VideoPlayerCustomClickEventType.SELECT_PLAYLIST,
                    e: (e.target as HTMLButtonElement).value
                });
            }
        });

        this.progress().addClickListener((type: PlyrProgressBarControlEventType, currentTime: number) => {
            if (type === PlyrProgressBarControlEventType.PROGRESS_BAR_CLICK) {
                plyr.currentTime = currentTime;
            }
        });

        this.events().addClickListener((type: PlyrEventsControlsEventType, e: MouseEvent | boolean | number) => {
            switch (type) {
                case PlyrEventsControlsEventType.CLICK_NEXT:
                    this.progress().selectNextEvent();
                    break;
                case PlyrEventsControlsEventType.CLICK_PREVIOUS:
                    this.progress().selectPreviousEvent();
                    break;
                case PlyrEventsControlsEventType.CLICK_ITEM:
                    plyr.redrawEventsMenuButton(false);
                    this.progress().selectEvent(e as number);
                    break;
                case PlyrEventsControlsEventType.CLICK_MENU:
                    plyr.redrawEventsMenuButton(!e as boolean);
                    break;
            }
        });

        this.videoshot().addClickListener(() => {
            this.clickListener$.next({ type: VideoPlayerCustomClickEventType.GET_VIDEOSHOT });
        });

        this.changeMode().addClickListener(() => {
            this.clickListener$.next({ type: VideoPlayerCustomClickEventType.CHANGE_MODE });
        });

        this.openDoor().addClickListener( () => {
          this.clickListener$.next({ type: VideoPlayerCustomClickEventType.OPEN_DOOR });
        });
    }

    /**
     * Enable listeners for player events (includes timeupdate and canplay events)
     * @param {RdeaPlyr} Plyr instance
     */
    private enablePlayerStateListeners(plyr: RdeaPlyr) {
        plyr.on('timeupdate', (e: Plyr.PlyrEvent) => {
            // Update time after every video player tick
            this.progress().updateCurrentTime(plyr.currentTime);
        });

        plyr.on('canplay', (e: Plyr.PlyrEvent) => {
            if (plyr.paused) {
                plyr.play();
            }
        });

        plyr.on('playing', (e: Plyr.PlyrEvent) => {
            plyr.toggleControls(true);
        });
    }

    /**
     * Enable listeners for player HTML5 events
     * @param {RdeaPlyr} Plyr instance
     */
    private enableHTML5EvensListeners(plyr: RdeaPlyr) {
        plyr.on('timeupdate', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR - timeupdate`, e);
        });

        plyr.on('canplay', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR HTML5 -  canplay`, e);
        });

        plyr.on('playing', (e: Plyr.PlyrEvent) => {
            this.loggerService.log('PLYR - playing', e);
        });

        plyr.on('seeking', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR - seeking`, e);
        });

        plyr.on('loadstart', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR HTML5 -  loadstart`, e);
        });

        plyr.on('loadeddata', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR HTML5 -  loadeddata`, e);
        });

        plyr.on('loadedmetadata', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR HTML5 -  loadedmetadata`, e);
        });

        plyr.on('qualitychange', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR HTML5 -  qualitychange`, e);
        });

        plyr.on('canplaythrough', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR HTML5 -  canplaythrough`, e);
        });

        plyr.on('stalled', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR HTML5 -  stalled`, e);
        });

        plyr.on('emptied', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR HTML5 -  emptied`, e);
        });

        plyr.on('waiting', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR HTML5 -  waiting`, e);
        });

        plyr.on('cuechange', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR HTML5 -  cuechange`, e);
        });

        plyr.on('error', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR HTML5 -  error`, e);
        });
    }

    /**
     * Enable listeners for player Plyr events
     * @param {RdeaPlyr} Plyr instance
     */
    private enableStandartMediaEventsListeners(plyr: RdeaPlyr) {
        plyr.on('progress', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - progress`, e);
        });

        plyr.on('play', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - play`, e);
        });

        plyr.on('pause', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - pause`, e);
        });

        plyr.on('ready', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - ready`, e);
        });

        plyr.on('volumechange', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - volumechange`, e);
        });

        plyr.on('seeked', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - seeked`, e);
        });

        plyr.on('ratechange', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - ratechange`, e);
        });

        plyr.on('ended', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - ended`, e);
        });

        plyr.on('enterfullscreen', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - enterfullscreen`, e);
        });

        plyr.on('exitfullscreen', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - exitfullscreen`, e);
        });

        plyr.on('captionsenabled', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - captionsenabled`, e);
        });

        plyr.on('captionsdisabled', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - captionsdisabled`, e);
        });

        plyr.on('languagechange', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - languagechange`, e);
        });

        plyr.on('controlshidden', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - controlshidden`, e);
        });

        plyr.on('controlsshown', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - controlsshown`, e);
        });

        plyr.on('seeked', (e: Plyr.PlyrEvent) => {
            this.loggerService.log(`PLYR STANDART MEDIA - seeked`, e);
        });
    }
}
