import { createSelector } from '@ngrx/store';
import { SharedModuleStates } from '../reducers/reducers';
import { sharedModuleSelector } from './shared-module.state';

const selectAddressState = createSelector(
  sharedModuleSelector,
  (state: SharedModuleStates) => state.address
);

export const getAddressState = createSelector(
  selectAddressState,
  state => state
);

export const getAddressLoading = createSelector(
  selectAddressState,
  state => state.loading
);

export const getAddressError = createSelector(
  selectAddressState,
  state => state.error
);

export const getCitiesStateSuccess = createSelector(
  selectAddressState,
  state => state.cities
);

export const getStreetsStateSuccess = createSelector(
  selectAddressState,
  state => state.streets
);

export const getHousesStateSuccess = createSelector(
  selectAddressState,
  state => state.houses
);

export const getEntrancesStateSuccess = createSelector(
  selectAddressState,
  state => state.entrances
);
