import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { IntercomPanelsListContentComponent } from './intercom-panels-list-content.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        TranslateModule,
    ],
    declarations: [
        IntercomPanelsListContentComponent
    ],
    exports: [
        IntercomPanelsListContentComponent
    ]
})
export class IntercomPanelsListContentModule { }
