import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogWrapperData } from '@app/shared/ui';
import { BannerAlertPopupBody } from './banner-alert-popup-body.model';

@Component({
  selector: 'app-banner-alert-popup',
  templateUrl: './banner-alert-popup.component.html',
  styleUrls: ['./banner-alert-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BannerAlertPopupComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<BannerAlertPopupBody, void>,
    private dialogRef: MatDialogRef<BannerAlertPopupComponent>,
  ) { }


  onSubmit() {
    this.data.submit();
    this.dialogRef.close();
  }
}
