import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Address } from '@app/shared/models';

@Component({
  selector: 'app-entrances-list-content',
  templateUrl: './entrances-list-content.component.html',
  styleUrls: ['./entrances-list-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntrancesListContentComponent {
  @Input() addresses: Address[];

  @Output() private addAddress: EventEmitter<void> = new EventEmitter();
  @Output() private editAddress: EventEmitter<{ addressIdx: number }> = new EventEmitter();
  @Output() private deleteAddress: EventEmitter<{ addressId: number }> = new EventEmitter();

  onAdd() {
    this.addAddress.next();
  }

  onEdit(addressIdx: number) {
    this.editAddress.next({ addressIdx });
  }

  onDelete(addressId: number) {
    this.deleteAddress.next({ addressId });
  }
}
