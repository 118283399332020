import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RdeaDate } from '@app/shared/entities/common';
import { CompanyReportRequest } from '@app/shared/entities/rd';

@Component({
  selector: 'app-company-report-content',
  templateUrl: './company-report-content.component.html',
  styleUrls: ['./company-report-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyReportContentComponent implements OnInit {
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  @Input() startReportDateEnabled: boolean;
  form: UntypedFormGroup = this.initForm();
  maxDate: Date = new Date();

  @Output() private submitContent: EventEmitter<{ request: CompanyReportRequest }> = new EventEmitter();
  private _loading: boolean;

  constructor() {
    this.maxDate = new Date(this.maxDate.setTime(this.maxDate.getTime() - RdeaDate.dayInMilliseconds));
  }

  ngOnInit() {
    if (this.startReportDateEnabled) {
      this.form.addControl('startReportDate', new UntypedFormControl(null));
    }
  }

  get loading(): boolean {
    return this._loading;
  }

  onSubmit() {
    const { finishReportDate, startReportDate }:
      { finishReportDate: Date, startReportDate: Date } = this.form.getRawValue();

    this.submitContent.emit({
      request: {
        finishReportDate: finishReportDate ? new RdeaDate(finishReportDate).toDateTimeString({ time: false }) : null,
        startReportDate: startReportDate ? new RdeaDate(startReportDate).toDateTimeString({ time: false }) : null
      }
    });
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      finishReportDate: new UntypedFormControl(null)
    });
  }
}
