import { PhoneModel, PhoneValidatorModel, PrefixModel, PrefixType } from '@app/shared/entities/rd';
import { environment } from 'environments/environment';

const rdPhoneList = [
    { prefix: '+7', countryShortName: 'RU' || 'KZ' || null || undefined, mask: '(__)___-__-__' },
    { prefix: '+380', countryShortName: 'UA', mask: '(___)___-__-__' },
    { prefix: '+375', countryShortName: 'BY', mask: '(___)___-__-__' }
];

const brazilPhoneList: PrefixModel[] = [
    { prefix: '+55', countryShortName: 'BR', mask: '(__)_____-____' },
];

export class PhonesUtils {
    static phoneNumberLength = 10;

    static prefixesList: PrefixModel[] = environment.vivotec ? brazilPhoneList : rdPhoneList as PrefixModel[];

    /**
     *
     * @param phone phone format with same separators
     * @param withSign Add + sign before phone number if true
     */
    static toString(phone: { number: string, prefix: string }, withSign?: boolean): string {
        let phoneStr: string = (phone.prefix + phone.number).replace(/-|\+|\(|\)/g, '');

        if (withSign) {
            phoneStr = '+' + phoneStr;
        }

        return phoneStr;
    }

    /**
     *
     * @param phone phone format with same separators
     * @param withSign Add + sign before phone number if true
     */
    static toNumber(phone: { number: string, prefix: string }): number {
        const phoneStr: string = (phone.prefix + phone.number).replace(/-|\+|\(|\)/g, '');

        return Number.parseInt(phoneStr, 10);
    }

    static parsePhone(phone: number): PhoneModel {
        if (!phone) {
            return null;
        }

        const phoneString: string = phone.toString();
        const phoneLength: number = phoneString.length - this.phoneNumberLength;
        const phoneModel: PhoneModel = {
            number: phoneString.substring(phoneLength),
            prefix: this.prefixTypeFromPrefixString(Number.parseInt(phoneString.substring(0, phoneLength), 10))
        };
        return phoneModel;
    }

    static phoneNumber(phone: string): string | null {
        if (!phone) {
            return null;
        }
        const phoneLength = phone.length - this.phoneNumberLength;
        return phone.substring(phoneLength);
    }
    static foreignPhone(prefix: PrefixType): boolean {
        return prefix !== '+7';
    }

    static brazilPhone(prefix: PrefixType): boolean {
      return prefix === '+55';
    }

    static initPhoneValidator(prefix: PrefixType): PhoneValidatorModel {
        let phoneValidator: PhoneValidatorModel;

        if (!prefix) {
            return null;
        }
        if (this.foreignPhone(prefix)) {
          if (this.brazilPhone(prefix)) {
            phoneValidator = {
              phoneNumberLength: 14,
              prepareIndices: [3, 9, null],
              regTemplate: new RegExp(/^\([\d]{2}\)[\d]{5}\-[\d]{4}$/gm)
            };
          } else {
            phoneValidator = {
              phoneNumberLength: 13,
              prepareIndices: [3, 7, 10],
              regTemplate: new RegExp(/^\([\d]{2}\)[\d]{3}\-[\d]{2}\-[\d]{2}$/gm)
            };

          }
        } else {
            phoneValidator = {
                phoneNumberLength: 14,
                prepareIndices: [4, 8, 11],
                regTemplate: new RegExp(/^\([\d]{3}\)[\d]{3}\-[\d]{2}\-[\d]{2}$/gm)
            };
        }

        return phoneValidator;
    }

    static validatePhone(text: string, regTemplate: RegExp): boolean {
        const reg: RegExp = new RegExp(regTemplate);
        return reg.test(text);
    }

    private static prefixTypeFromPrefixString(prefix: number) {
        let prefixType: PrefixType;

        switch (prefix) {
            case 375: return prefixType = '+375'; break;
            case 380: return prefixType = '+380'; break;
            default: prefixType = '+7'; break;
        }

        return prefixType;
    }
}
