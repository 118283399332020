import { House, Street } from '@app/views/intercom/models';
import { Country, EntranceResponse } from '@app/shared/models';

export interface Address {
  country: Country;
  city: string;
  street: Street;
  house: House;
  entrance: EntranceResponse;
  flat?: number;
}
