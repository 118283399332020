import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  LOCALE_ID,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { appearDisappear, appearDisappearFast, justFade } from '@app/shared/animations/animations';
import { SharedModule } from '@app/shared/shared.module';
import {
  CustomPaginatorComponent, IDefaultOption, IPageChange, IPaginatorConfig
} from '@app/shared/components/prime-base-table/custom-paginator/custom-paginator.component';
import { TableFilterPipe } from '@app/shared/components/prime-base-table/table-filter.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { Router } from '@angular/router';
import { RolesAccessPipe } from '@app/shared/components/prime-base-table/roles-access.pipe';
import { TranslateService } from '@ngx-translate/core';
import { NgxTranslateModule } from '@app/translate/translate.module';
import {
  RequestStatusComponent
} from '@app/views/support-service/components/requests/request-status/request-status.component';
import { SkeletonModule } from 'primeng/skeleton';
import { ViewportRuler } from '@angular/cdk/overlay';
import {OverlayPanel, OverlayPanelModule} from 'primeng/overlaypanel';
import {cloneDeep} from 'lodash';
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';

registerLocaleData(localeRu);

export interface IPrimeTableConfig {
  key?: string;
  showActionColumn?: boolean;
  showCustomActionColumn?: boolean;
  dropdownPaginationMod?: boolean;
  showSearchAction?: boolean;
  paginatorMode?: boolean;
  lazyLoadingMode?: boolean;
  rolesAccessMode?: boolean;
  header: {
    name: string;
    field: string;
    className?: string;
    sort: boolean;
  }[];
  body: {
    isLink?: boolean;
    isBaseLink?: boolean;
    isTube?: boolean;
    isServiceStatus?: boolean;
    isStatus?: boolean;
    isSupportStatus?: boolean;
    isNativeRole?: boolean;
    field: string;
    className?: string;
  }[];
}
export interface IMenuItems<T> {
  name: ((value: T) => string) | string;
  action: (value: T) => void;
  style?: string;
}

const BASE_SCROLL_HEIGHT = '100%';

@Component({
  selector: 'app-prime-base-table',
  templateUrl: './prime-base-table.component.html',
  styleUrls: ['./prime-base-table.component.scss'],
  imports: [CommonModule, ProgressSpinnerModule, TableModule, SharedModule, CustomPaginatorComponent, TableFilterPipe, TooltipModule, InputTextModule, ButtonModule, RolesAccessPipe, NgxTranslateModule, RequestStatusComponent, SkeletonModule, OverlayPanelModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [appearDisappear, justFade, appearDisappearFast],
  standalone: true,
  providers: [
    { provide: LOCALE_ID, useValue: 'ru' }
  ]
})
export class PrimeBaseTableComponent<TableData> {
  public paginatorConfig: IPaginatorConfig<IDefaultOption> = null;

  private _tableData: TableData[] = [];
  private _selectedIds: number[];

  @Input() set tableData(data: TableData[]) {
    console.log(data);
    if (data) {
      this._tableData = cloneDeep(data);
      this.totalRecords = data.length;
      this.setPaginatorConfig(this._tableData);
    }
  }
  @Input() menuItems: IMenuItems<TableData>[] = [];
  @Input() primeTableConfig: IPrimeTableConfig;
  @Input() resizableColumns = true;
  @Input() scrollable = true;
  @Input() styleClass: string;
  @Input() scrollTableHeight: string = BASE_SCROLL_HEIGHT;
  @Input() linkNameField: string;
  @Input() loading: boolean;
  @Input() showSkeleton: boolean;
  @Input() pageSize = 10;
  @Input() hidBorder = true;
  @Input() showEmptyImg = true;

  @Output() navigate: EventEmitter<{ data: TableData; field?: string; }> = new EventEmitter<{
    data: TableData; field?: string;
  }>();
  @Output() edit: EventEmitter<TableData> = new EventEmitter<TableData>();
  @Output() delete: EventEmitter<TableData> = new EventEmitter<TableData>();
  @Output() selectionChange: EventEmitter<TableData[]> = new EventEmitter<
    TableData[]
  >();

  public totalRecords: number;

  constructor(
    private router: Router,
    private translate: TranslateService,
    private viewportRuler: ViewportRuler,
    private cd: ChangeDetectorRef,
  ) {}

  get hasTableData(): boolean {
    return !!this.tableData.length;
  }

  get mockRows(): number[] {
    return new Array(10);
  }

  get tableData(): TableData[] {
    return this._tableData;
  }

  get selectedTableData(): number[] {
    return this._selectedIds;
  }

  set selectedTableData(value: number[]) {
    this._selectedIds = value;
  }

  get currentPageReportTemplate(): string {
    return '{first} - {last} ' + this.translate.instant('shared.prime_base_table.of') + ' {totalRecords}';
  }

  public onSelectionChange(entities: TableData[]): void {
    this.selectionChange.emit(entities);
  }

  public onNavigate(entity: TableData): void {
    this.navigate.emit({ data: entity });
  }

  public onEdit(entity: TableData): void {
    this.edit.emit(entity);
  }

  public onDelete(entity: TableData): void {
    this.delete.emit(entity);
  }

  public onPageChange(pageChange: IPageChange): void {
    this.paginatorConfig.page = pageChange.page + 1;
    this.updateQueryParams(pageChange.page + 1);
  }

  public onPageSizeChange(size: number): void {
    this.paginatorConfig.pageSize = size;
  }

  private setPaginatorConfig(tableData: TableData[]): void {
    const { width } = this.viewportRuler.getViewportSize();
    this.paginatorConfig = {
      dropdownPaginationMod: this.primeTableConfig?.dropdownPaginationMod,
      options: [
        { label: 10, value: 10 },
        { label: 15, value: 15 },
        { label: 25, value: 25 },
        { label: 100, value: 100 },
      ],
      optionLabel: 'label',
      optionValue: 'value',
      first: 0,
      rows: 10,
      totalRecords: tableData.length,
      currentPageReportTemplate: this.currentPageReportTemplate,
      pageLinkSize: width < 615 ? 2 : 3,
      pageSize: 10,
      page: 1
    };
    if (this.pageSize && this.pageSize !== 10 && this.paginatorConfig?.options.find((o) => o.value === this.pageSize) === undefined) {
      this.paginatorConfig.options.push({
        label: this.pageSize,
        value: this.pageSize
      });
      this.paginatorConfig.options.sort((a, b) => a.value - b.value);
      this.paginatorConfig.pageSize = this.pageSize;
      this.paginatorConfig.rows = this.pageSize;
    }
  }

  private updateQueryParams(page: number): void {
    /*
        this.router.navigate([], {
      queryParams: {
        page: page
      },
      queryParamsHandling: 'merge'
    });
    */
  }

  public onMenuItemSelect(item: IMenuItems<TableData>, op: OverlayPanel, data: TableData): void {
    item.action(data);
    op.toggle(false);
  }

  onSort(sort: {
    field: string;
    order: 1 | -1
  }) {
    this.tableData = sortByField(this._tableData, sort.field, sort.order);

    function sortByField(arr: TableData[], field: string, order: 1 | -1) {
      return arr.sort((a, b) => {
        if (field === 'created_at') {
          field = 'origin_created_at';
        }
        const valueA = a[field];
        const valueB = b[field];

        // Проверка на null
        if (valueA === null) { return 1; }
        if (valueB === null) { return -1; }

        if (typeof valueA === 'string' && typeof valueB === 'string') {
          // Сортировка строк
          if (order === 1) {
            return valueA.localeCompare(valueB);
          } else if (order === -1) {
            return valueB.localeCompare(valueA);
          }
        } else if (typeof valueA === 'number' && typeof valueB === 'number') {
          // Сортировка чисел
          if (order === 1) {
            return valueA - valueB;
          } else if (order === -1) {
            return valueB - valueA;
          }
        } else if (valueA instanceof Date && valueB instanceof Date) {
          // Сортировка дат
          if (order === 1) {
            return (valueA as any) - (valueB as any);
          } else if (order === -1) {
            return (valueB as any) - (valueA as any);
          }
        } else {
          throw new Error('Unsupported data type or mismatched types for sorting');
        }

        throw new Error('Order must be 1 (ascending) or -1 (descending)');
      });
    }
  }
}
