<app-dialog-wrapper
  [title]="data.title"
  [componentName]="data.componentName"
  [progress]="(popupService.state$ | async) === 'loading'"
>
  <app-service-intercom-popup-content
    [loading]="(popupService.state$ | async) === 'loading'"
    [translationsStepState]="translationsStepState$ | async"
    [intercomPanelsStepState]="intercomPanelsStepState$ | async"
    [pbxOnRdaStepState]="pbxOnRdaStepState$ | async"
    [mode]="mode$ | async"
    [intercomPanelsMode]="intercomPanelsMode$ | async"
    [showMenu]="showMenu$ | async"
    [onlyIpIntercoms]="data.body.onlyIpIntercoms"

    [steps]="steps$ | async"
    [intercom]="intercom$ | async"
    [intercoms]="intercoms$ | async"
    [pbxOnRda]="pbxOnRda$ | async"
    [address]="address$ | async"
    [intercomTypes]="intercomTypes$ | async"
    [camerasLocations]="camerasLocations$ | async"
    [translationTunings]="translationTunings$ | async"
  ></app-service-intercom-popup-content>
</app-dialog-wrapper>
