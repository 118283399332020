import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { IPrimeTableConfig } from '@app/shared/components/prime-base-table/prime-base-table.component';
import { Router } from '@angular/router';
import { UserService } from '@app/shared/entities/rd';
import { BehaviorSubject, of, Subject, zip } from 'rxjs';
import {catchError, debounceTime, takeUntil} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  ActionType,
  CompanyBlockUserPopupComponent
} from '@app/views/company/pages/company-roles-page/company-block-user-confirm/company-block-user-popup/company-block-user-popup.component';
import {
  IRoleResponse,
  IUserRequest,
  IUserResponse
} from '@app/views/company/models/company-page';
import { CompanyCreateUserPopupComponent } from '@app/views/company/pages/company-roles-page/company-create-user/company-create-user-popup/company-create-user-popup.component';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from '@app/shared/components';
import { RolesComponentStoreService } from '@app/views/company/pages/company-roles-page/roles-component-store.service';
import { LocalStorageGeneralKey, LocalStorageHelper } from '@app/shared/entities';
import { TranslateService } from '@ngx-translate/core';
import { parseError } from '@app/shared/helpers';

const OPTIONAL_LABEl = 'translate';

@Component({
  selector: 'app-company-roles-page',
  templateUrl: './company-roles-page.component.html',
  styleUrls: ['./company-roles-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CompanyRolesPageComponent implements OnInit, OnDestroy {
  public optionLabel: string = OPTIONAL_LABEl;
  public userResponse: IUserResponse[] = [];
  public filteredRoles: IRoleResponse[] = [];
  public tableConfig: IPrimeTableConfig = {
    key: 'email',
    showActionColumn: true,
    rolesAccessMode: true,
    header: [
      { name: this.translate.instant('company.roles.header.email'), field: 'email', className: 'pt-w-100', sort: true },
      { name: this.translate.instant('company.roles.header.role'), field: 'roleNames', className: 'pt-w-100', sort: true },
      { name: this.translate.instant('company.roles.header.status'), field: 'blocked', className: 'pt-w-40', sort: true }
    ],
    body: [
      { className: 'pt-w-80', field: 'email' },
      { className: 'pt-w-80', isNativeRole: true , field: 'roleNames' },
      { className: 'pt-w-40', isStatus: true, field: 'blocked' }
    ]
  };
  public searchingText: string = null;

  public loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );
  public search$: Subject<string> = new Subject<string>();
  private unSubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private userService: UserService,
    private matDialog: MatDialog,
    private snackbarService: SnackbarService,
    private changeDetectorRef: ChangeDetectorRef,
    public rolesComponentStoreService: RolesComponentStoreService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.request();

    this.search$.pipe(
      debounceTime(400), takeUntil(this.unSubscribe$)
    ).subscribe((text) => {
      this.searchingText = text;
      this.changeDetectorRef.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  public onOpenUserProfile(user: IUserResponse): void {
    this.router.navigate(['partners/company-user-profile'], {
      queryParams: { ...user },
      queryParamsHandling: 'merge'
    });
  }

  public onBlockUser(user: IUserResponse): void {
    this.matDialog.open(CompanyBlockUserPopupComponent, {
      data: {
        title: this.translate.instant('company.roles.block.title'),
        body: { email: user.email, type: ActionType.block },
        submit: (blockUser: boolean) => {
          if (blockUser) {
            this.userService
              .blockUser(user.id)
              .pipe(
                catchError((error: HttpErrorResponse) => of(error)),
                takeUntil(this.unSubscribe$)
              )
              .subscribe((response) => {
                if (response instanceof HttpErrorResponse) {
                  this.snackbarService.showMessage(
                    this.translate.instant('company.roles.block.message.submit.failed', {
                      text: response.message
                    }),
                    'error'
                  );
                } else {
                  this.snackbarService.showMessage(
                    this.translate.instant('company.roles.block.message.submit.success'),
                    'success'
                  );
                  this.request();
                }
              });
          }
        }
      }
    });
  }

  public onUserCreate(): void {
    this.matDialog.open(CompanyCreateUserPopupComponent, {
      data: {
        title: this.translate.instant('company.roles.create.title'),
        submit: (userRequest: IUserRequest) => {
          this.loading$.next(true);

          this.userService
            .createUser(userRequest)
            .pipe(
              catchError((error: HttpErrorResponse) => of(error)),
              takeUntil(this.unSubscribe$)
            )
            .subscribe((response) => {
              if (response instanceof HttpErrorResponse) {
                this.snackbarService.showMessage(
                  this.translate.instant('company.roles.create.message.submit.failed', {
                    text: parseError(response)
                  }),
                  'error'
                );
              } else {
                this.snackbarService.showMessage(
                  this.translate.instant('company.roles.create.message.submit.success'),
                  'success'
                );
                this.request();
              }
              this.loading$.next(false);
            });
        }
      }
    });
  }

  public onRoleChange(roleResponse: IRoleResponse[]): void {
    this.filteredRoles = roleResponse;
  }

  public onSearchByEmail(e: Event): void {
    this.search$.next((e.target as HTMLInputElement).value);
  }

  private request(): void {
    zip(this.userService.getRoleList(), this.userService.getCompanyUsers())
      .pipe(
        catchError((error: HttpErrorResponse) => of(error)),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((response) => {
        if (response instanceof HttpErrorResponse) {
          this.snackbarService.showMessage(
            this.translate.instant('company.roles.message.request.failed', {
              text: response.message
            }),
            'error'
          );
        } else {
          const roles = response[0].map(role => {
            return {
              ...role,
              translate: this.rolesComponentStoreService.rolesDictionary[role.name]
            };
          }).filter(r => r.translate);
          LocalStorageHelper.setItem(LocalStorageGeneralKey.AVAILABLE_ROLES, JSON.stringify(roles));
          this.rolesComponentStoreService.allAvailableRoles = roles;
          this.userResponse = response[1];
        }
        this.loading$.next(false);
      });
  }
}
