<app-dialog-wrapper
  [title]="data.title"
  [componentName]="data.componentName"
  [progress]="loading$ | async"
  *ngIf="{
    steps: steps$ | async,
    wizardStep: wizardStep
  } as state"
>
  <div class="payment-wizard">
    <app-rdea-stepper
      [steps]="state.steps"
      [stepIdx]="state.wizardStep"
      (changeStep)="onChangeStep($event.stepIdx)"
      class="payment-wizard__stepper"
    ></app-rdea-stepper>

    <div class="payment-wizard-body">
      <ng-container [ngSwitch]="state.wizardStep">
        <ng-container *ngSwitchCase="stepType.SERVICE">
          <app-payments-wizard-service></app-payments-wizard-service>
        </ng-container>
        <ng-container *ngSwitchCase="stepType.REGISTRATION">
          <app-payments-wizard-registration
            [step]="state.steps[wizardStep]"
            (skipStep)="onNextStep()"
          ></app-payments-wizard-registration>
        </ng-container>

        <ng-container *ngSwitchCase="stepType.INTEGRATION">
          <app-payments-wizard-integration
            (success)="onSuccess()"
          ></app-payments-wizard-integration>
        </ng-container>
      </ng-container>
    </div>

    <div class="payment-wizard-actions">
      <button
        mat-raised-button
        *ngIf="state.wizardStep !== 0"
        color="primary-inverse"
        [disabled]="!isPreviousStepEnabled()"
        (click)="onPreviousStep()"
      >
        {{ 'payments.popups.wizard.button.prev' | translate }}
      </button>

      <button
        mat-raised-button
        *ngIf="state.wizardStep !== state.steps.length - 1"
        color="primary"
        [disabled]="!isNextStepEnabled()"
        (click)="onNextStep()"
      >
        {{ 'payments.popups.wizard.button.next' | translate }}
      </button>
    </div>
  </div>
</app-dialog-wrapper>
