<form
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
  class="form-block account-selection"
>
  <app-info-block>
    <ng-container info-block-text>
      {{ 'shared.connections.account.selection.info.1' | translate }}<br>
      {{ abonent.phone.toString() | phone }}
      <ng-container *ngIf="canBeSkipped">
        <br>
        <br>
        {{ 'shared.connections.account.selection.info.2' | translate }}
      </ng-container>
    </ng-container>
  </app-info-block>

  <p-dropdown *ngIf="isNewControl" [options]="accounts" formControlName="accountId" appendTo="body">
    <ng-template let-account pTemplate="item">
      <span>
         {{ account.id + (account.number ? ' (' + account.number + ')' : '') }}
      </span>
    </ng-template>
    <ng-template pTemplate="selectedItem">
        <span>
          {{ selectedAccount.id ? selectedAccount.id + (selectedAccount.number ? ' (' + selectedAccount.number + ')' : '') : selectedAccount }}
        </span>
    </ng-template>
  </p-dropdown>
  <mat-form-field *ngIf="!isNewControl" appearance="outline" class="none-padding">
    <mat-label>
      {{ 'shared.connections.account.selection.field.account' | translate }}
    </mat-label>
    <mat-select formControlName="accountId">
      <mat-option *ngFor="let account of accounts" [value]="account.id">
        {{ account.id + (account.number ? ' (' + account.number + ')' : '') }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <div class="account-selection-private-block">
    <mat-slide-toggle
      (change)="onChange($event)"
      formControlName="newAccount"
    ></mat-slide-toggle>
    <p>
      {{ 'shared.connections.account.selection.message.create_new_account' | translate }}
    </p>
  </div>

  <div class="form-block-actions">
    <button
      *ngIf="canBeSkipped"
      type="button"
      mat-raised-button
      (click)="onSkip()"
      color="primary-inverse"
      class="form-block-actions__submit-button"
    >
      {{ 'shared.connections.account.selection.button.skip' | translate }}
    </button>

    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="loading"
      class="form-block-actions__submit-button"
    >
      {{ 'shared.connections.account.selection.button.continue' | translate }}
    </button>
  </div>
</form>
