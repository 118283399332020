import { Injectable } from '@angular/core';
import { ServiceInfoResponse } from '@app/shared/entities/rd';
import { ServicePageServiceModel } from '@app/views/services/base';
import { SoftwareIntercomsPageModel } from '../models';

@Injectable()
export class SoftwareIntercomsPageService implements ServicePageServiceModel<SoftwareIntercomsPageModel> {
    preparePageResponse(serviceInfoList: ServiceInfoResponse[]): SoftwareIntercomsPageModel[] {
        return serviceInfoList.map((serviceInfo: ServiceInfoResponse) => ({
            id: serviceInfo.id,
            name: serviceInfo.customName ?? serviceInfo.name,
            companyId: serviceInfo.company?.id,
            rdasStates: {
                active: serviceInfo.rdas.filter(rda => rda.active).length,
                inactive: serviceInfo.rdas.filter(rda => !rda.active).length
            },
            camerasStates: {
                active: serviceInfo.cameras.filter(camera => camera.active).length,
                inactive: serviceInfo.cameras.filter(camera => !camera.active).length
            }
        }));
    }
}
