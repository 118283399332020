import { ServiceFlat } from '@app/views/services/components';
import { Address, ServicesTypes } from '@app/shared/models';
import { Dictionary } from '@app/shared/helpers';

export interface ServiceManagePopupModel {
    addresses: Address[];
    flats: Dictionary<ServiceFlat[]>;
    flatList: ServiceFlat[];
    serviceType: ServicesTypes;
}

export interface SelectedFlatsWithId {
    id: number,
    selectedFlat: ServiceFlat;
}