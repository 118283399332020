<div class="form-container">
  <h3>
    {{ 'intercom.orders.card.form.rdas.title' | translate }}
  </h3>

  <mat-form-field class="batch-select" appearance="outline">
    <mat-label>
      {{ 'intercom.orders.card.form.rdas.field.party' | translate }}
    </mat-label>
    <mat-select (selectionChange)="onSelectBatch($event)" [(ngModel)]="selectedBatchId" name="food">
      <mat-option *ngFor="let batch of batches$ | async" [value]="batch.id">
        {{batch.number}}
        {{
          selectedBatchAdaptersCount[batch.id]
            ? '(' + ('intercom.orders.card.form.rdas.selected' | translate | lowercase) + ' ' + selectedBatchAdaptersCount[batch.id] + ')'
            : ''
        }}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <button
    class="select-all"
    *ngIf="selectedBatchId"
    mat-raised-button
    color="primary-inverse"
    (click)="onSelectAllAdapters()"
  >
    {{
      selectedBatches[selectedBatchId]
        ? ('intercom.orders.card.form.rdas.button.deselect_all' | translate)
        : ('intercom.orders.card.form.rdas.button.select_all' | translate)
    }}
  </button>

  <mat-selection-list class="bordered-list" class="adapters-list" *ngIf="adapters?.length > 0" (selectionChange)="onSelectAdapter($event)">
    <div mat-subheader>
      {{ 'intercom.orders.card.form.rdas.selected' | translate }} {{selectedAdaptersCount}} {{ 'intercom.orders.card.form.rdas.of' | translate }} {{rdaCount}}
    </div>
    <mat-list-option [value]="adapter" [selected]="selectedAdapters[adapter.id]" *ngFor="let adapter of adapters"
      [disabled]="isDisabled(adapter.id)">
      {{adapter.uid}}
    </mat-list-option>
  </mat-selection-list>

  <div class="action-block">
    <button
      type="submit"
      mat-raised-button color="primary"
      (click)="onSubmit()"
    >
      {{ 'intercom.orders.card.form.rdas.button.submit' | translate }}
    </button>
  </div>
</div>
