<div class="filters-item btn-group">
  <span class="filters-item-name">{{ serviceName }}</span>
  <button
    class="filter-btn"
    [class.active]="flatsFilters.activeServices.includes(serviceType)"
    (click)="onFilterByActiveServiceType()"
  >
    {{ 'service.filter.item.button.active' | translate }}
  </button>
  <button
    class="filter-btn"
    [class.active]="flatsFilters.stoppedServices.includes(serviceType)"
    (click)="onFilterByStoppedServiceType()"
  >
    {{ 'service.filter.item.button.stopped' | translate }}
  </button>
  <button
    class="filter-btn"
    [class.active]="flatsFilters.blockedServices.includes(serviceType)"
    (click)="onFilterByBlockedServiceType()"
  >
    {{ 'service.filter.item.button.blocked' | translate }}
  </button>
</div>
