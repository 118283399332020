import { Component, Inject } from '@angular/core';
import { PopupBasicComponent } from '@app/shared/templates';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-delete-delegation-popup',
  templateUrl: './delete-delegation-popup.component.html',
  styleUrls: ['./delete-delegation-popup.component.scss']
})
export class DeleteDelegationPopupComponent extends PopupBasicComponent {
  constructor(@Inject('subject') private subject: Subject<any>) {
    super();
  }

  onSubmit() {
    this.subject.next(true);
  }
}
