import { LocationResponse } from '@app/shared/models';
import { Action } from '@ngrx/store';
import { ServicesActionTypes } from './services-action-types.actions';

export class GetKeysLocations implements Action {
    readonly type = ServicesActionTypes.GetKeysLocations;

    constructor() { }
}

export class GetKeysLocationsSuccess implements Action {
    readonly type = ServicesActionTypes.GetKeysLocationsSuccess;

    constructor(public response: LocationResponse[]) { }
}

export class GetKeysLocationsFailure implements Action {
    readonly type = ServicesActionTypes.GetKeysLocationsFailure;

    constructor() { }
}

export class GetCamerasLocations implements Action {
    readonly type = ServicesActionTypes.GetCamerasLocations;

    constructor() { }
}

export class GetCamerasLocationsSuccess implements Action {
    readonly type = ServicesActionTypes.GetCamerasLocationsSuccess;

    constructor(public response: LocationResponse[]) { }
}

export class GetCamerasLocationsFailure implements Action {
    readonly type = ServicesActionTypes.GetCamerasLocationsFailure;

    constructor() { }
}

export type ServicesLocationsActions =
    | GetCamerasLocations
    | GetCamerasLocationsSuccess
    | GetCamerasLocationsFailure

    | GetKeysLocations
    | GetKeysLocationsSuccess
    | GetKeysLocationsFailure;
