import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddressInputMode } from '@app/shared/components';
import { Constants } from '@app/shared/helpers';
import { Address, EntranceRegistration, EntranceUpdateRequest, ServicesTypes } from '@app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData, DialogWrapperSize } from '@app/shared/ui';
import { Observable } from 'rxjs';
import { ServiceFacade } from '../../../store';
import { ServiceDeleteEntrancePopupComponent, ServiceEntrancePopupComponent, ServiceEntrancePopupData, ServiceEntrancePopupResponse } from '../../popups';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-service-entrances',
  templateUrl: './service-entrances.component.html',
  styleUrls: ['./service-entrances.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceEntrancesComponent {
  @Input() serviceType!: ServicesTypes;
  @Input() entrances!: Address[];
  @Input() displayedColumns!: string[];
  @Input() maxCount: number;
  @Input() showPrefix: boolean;
  xlWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.XL_W_DOWN);

  constructor(
    protected resolution: ResolutionService,
    protected dialog: MatDialog,
    protected serviceFacade: ServiceFacade,
    private translate: TranslateService
  ) { }

  onAdd() {
    const data: DialogWrapperData<ServiceEntrancePopupData, ServiceEntrancePopupResponse> = {
      title: this.translate.instant('service.entrances.add.title'),
      componentName: `Add${this.serviceType}Entrance`,
      body: {
        showPrefix: this.showPrefix,
        initialMode: this.prepareInitialMode(),
        label: this.translate.instant('service.entrances.add.label'),
      },
      submit: (event: { entrance: EntranceRegistration, prefix: string }) =>
        this.serviceFacade.addServiceEntrance(event.entrance, event.prefix)
    };

    this.dialog.open(ServiceEntrancePopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.MD,
      data
    });
  }

  onEdit(address: Address) {
    const data: DialogWrapperData<ServiceEntrancePopupData, ServiceEntrancePopupResponse> = {
      title: this.translate.instant('service.entrances.edit.title'),
      componentName: `Add${this.serviceType}Entrance`,
      body: {
        address: address,
        showPrefix: this.showPrefix,
        label: this.translate.instant('service.entrances.edit.label'),
        initialMode: this.prepareInitialMode(address)
      },
      submit: (event: { entrance: EntranceRegistration, prefix: string }) => {
        const entranceUpdateRequest: EntranceUpdateRequest = {
          flatStart: event.entrance.range[0],
          flatEnd: event.entrance.range[1]
        };

        if (this.showPrefix && address.entrance.prefix !== event.prefix) {
          entranceUpdateRequest.prefix = event.prefix;
          this.serviceFacade.updateServiceEntranceWithPrefix(address.entrance.id, entranceUpdateRequest);
        } else {
          this.serviceFacade.updateServiceEntrance(address.entrance.id, entranceUpdateRequest);
        }
      }
    };

    this.dialog.open(ServiceEntrancePopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.MD,
      data
    });
  }

  onDelete(entranceId: number) {
    const data: DialogWrapperData<{ entranceId: number }, null> = {
      title: this.translate.instant('service.entrances.delete.title'),
      componentName: `Delete${this.serviceType}Entrance`,
      body: { entranceId },
      submit: () =>
        this.serviceFacade.deleteServiceEntrance(entranceId)
    };

    this.dialog.open(ServiceDeleteEntrancePopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.SM,
      data
    });
  }

  prepareInitialMode(address?: Address): AddressInputMode {
    if (
      !address &&
      (
        this.serviceType === ServicesTypes.VIDEO_SURVEILLANCE ||
        this.serviceType === ServicesTypes.GATE
      )
    ) {
      return AddressInputMode.CREATED;
    }

    return null;
  }
}
