import {Pipe, PipeTransform} from '@angular/core';
import {PersonalSurveillance} from '@app/shared/entities/rd/services/models/personal-surveillance.model';

export enum TableAVSEnum {
  id = 'id',
  name = 'name',
  phone = 'phone',
  camerasCount = 'camerasCount',
  status = 'status',
  camerasStatuses = 'camerasStatuses',
  actions = 'actions',
}

export interface TableAVSConverted {
  [TableAVSEnum.id]: number;
  [TableAVSEnum.name]: string;
  [TableAVSEnum.camerasCount]: number;
  [TableAVSEnum.status]: boolean;
  [TableAVSEnum.camerasStatuses]: TableAVSCameraStatuses[];
  raw?: PersonalSurveillance.ServiceResponse;
}

export interface TableAVSCameraStatuses {
  id: number;
  active: boolean;
}

@Pipe({name: 'apartmentVideoGridConverter'})
export class ApartmentVideoGridConverterPipe implements PipeTransform {

  transform(signUpResponse: PersonalSurveillance.ServiceResponse[]): TableAVSConverted[] {
    return signUpResponse.map((value) => {
      return {
        [TableAVSEnum.id]: value.id,
        [TableAVSEnum.phone]: value?.abonent?.phone,
        [TableAVSEnum.name]: value?.customName || value.name,
        [TableAVSEnum.camerasCount]: value.cameras.length,
        [TableAVSEnum.status]: value?.isBlocked,
        [TableAVSEnum.camerasStatuses]: value.cameras.map((c) => ({id: c.id, active: c.active})),
        raw: value
      };
    });
  }
}
