import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IntercomPanelConnectRequest, IntercomPanelResponse, IntercomPanelUpdateRequest, RdaResponse } from '@app/shared/entities/rd';
import { LocationResponse } from '@app/shared/models';
import { ServiceIntercomPanelsMode } from './service-intercom-panels-mode.model';

@Component({
  selector: 'app-service-intercom-panels',
  templateUrl: './service-intercom-panels.component.html',
  styleUrls: ['./service-intercom-panels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceIntercomPanelsComponent {
  readonly modes: typeof ServiceIntercomPanelsMode = ServiceIntercomPanelsMode;

  @Input() editHidden = true;
  @Input() deleteHidden = true;
  @Input() addHidden = true;
  @Input() intercom: RdaResponse;
  @Input() loading: boolean;
  @Input() intercomPanels: IntercomPanelResponse[];
  @Input() locations: LocationResponse[];
  @Input() mode: ServiceIntercomPanelsMode = ServiceIntercomPanelsMode.LIST;
  intercomPanel: IntercomPanelResponse;

  @Output() private addIntercomPanel: EventEmitter<{ request: IntercomPanelConnectRequest }> = new EventEmitter();
  @Output() private updateIntercomPanel: EventEmitter<{ request: Partial<IntercomPanelUpdateRequest>, intercomPanelId: number, rdaId: number }> = new EventEmitter();
  @Output() private deleteIntercomPanel: EventEmitter<{ intercomPanelId: number, rdaId: number }> = new EventEmitter();
  @Output() private changePageMode: EventEmitter<{ mode: ServiceIntercomPanelsMode }> = new EventEmitter();

  onShowAdd() {
    this.changePageMode.emit({ mode: ServiceIntercomPanelsMode.ADD });
  }

  onShowEdit(intercomPanel: IntercomPanelResponse) {
    this.intercomPanel = intercomPanel;
    this.changePageMode.emit({ mode: ServiceIntercomPanelsMode.EDIT });
  }

  onShowDelete(intercomPanel: IntercomPanelResponse) {
    this.intercomPanel = intercomPanel;
    this.changePageMode.emit({ mode: ServiceIntercomPanelsMode.DELETE });
  }

  onBack() {
    this.changePageMode.emit({ mode: ServiceIntercomPanelsMode.LIST });
  }

  onAdd(location: LocationResponse, index: string) {
    const request: IntercomPanelConnectRequest = { rdaId: this.intercom.id };

    if (location) {
      request.locationId = location.id;
    }

    if (index !== null && index !== undefined) {
      request.index = index;
    }

    this.addIntercomPanel.emit({ request });
  }

  onEdit(location: LocationResponse, index: string) {
    const request: Partial<IntercomPanelUpdateRequest> = {};

    if (index !== this.intercomPanel.index) {
      request.index = index;
    }

    if (location) {
      request.locationId = location.id;
    } else if (this.intercomPanel.location) {
      request.locationId = null;
    }

    this.updateIntercomPanel.emit({ request, intercomPanelId: this.intercomPanel.id, rdaId: this.intercom.id });
  }

  onDelete() {
    this.deleteIntercomPanel.emit({ intercomPanelId: this.intercomPanel.id, rdaId: this.intercom.id });
  }
}
