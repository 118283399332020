import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogWrapperData, DialogWrapperService } from '@app/shared/ui';
import { ServiceDeleteKeyPopupData } from './service-delete-key-popup-data.model';
import { ServiceDeleteKeyPopupService } from './service-delete-key-popup.service';

@Component({
  selector: 'app-service-delete-key-popup',
  templateUrl: './service-delete-key-popup.component.html',
  styleUrls: ['./service-delete-key-popup.component.scss'],
  providers: [ServiceDeleteKeyPopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceDeleteKeyPopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<ServiceDeleteKeyPopupData, null>,
    public dialogWrapperService: DialogWrapperService,
    public popupService: ServiceDeleteKeyPopupService,
    private dialogRef: MatDialogRef<ServiceDeleteKeyPopupComponent>
  ) { }

  ngOnInit() {
    this.popupService.addStateChangeListener(state => state === 'close' && this.dialogRef.close());
  }

  onSubmit() {
    this.data.submit(null);
  }
}
