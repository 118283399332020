import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { ConfirmComponentConnectionHelper, IntercomEditFilter } from '@app/shared/components';
import { IntercomPanelConnectRequest, IntercomPanelUpdateRequest, IntercomType, PbxOnRdaResponse, RdaResponse, RdaUpdateRequest } from '@app/shared/entities/rd';
import { Address, LocationResponse, TranslationTuningRequest, TranslationTuningResponse } from '@app/shared/models';
import { NavbarLink } from '@app/shared/ui';
import { ServiceIntercomPopupMode, ServiceIntercomPopupStep, ServiceIntercomPopupUpdateStep } from '@app/views/services/components';
import { ServiceFacade } from '@app/views/services/store';
import { ServiceIntercomPanelsMode } from './../../../../containers';
import { ServiceIntercomPopupStore } from './../store';

@Component({
  selector: 'app-service-intercom-popup-content',
  templateUrl: './service-intercom-popup-content.component.html',
  styleUrls: ['./service-intercom-popup-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceIntercomPopupContentComponent {
  readonly pageMode: typeof ServiceIntercomPopupMode = ServiceIntercomPopupMode;
  readonly intercomEditFilter: typeof IntercomEditFilter = IntercomEditFilter;

  @Input() set translationsStepState(stepState: ServiceIntercomPopupUpdateStep) {
    this.popupStore.updateStepState({ step: ServiceIntercomPopupStep.TRANSLATIONS, stepState });
  }

  @Input() set intercomPanelsStepState(stepState: ServiceIntercomPopupUpdateStep) {
    this.popupStore.updateStepState({ step: ServiceIntercomPopupStep.INTERCOM_PANELS, stepState });
  }

  @Input() set pbxOnRdaStepState(stepState: ServiceIntercomPopupUpdateStep) {
    this.popupStore.updateStepState({ step: ServiceIntercomPopupStep.PBX_ON_RDA, stepState });
  }

  @Input() showMenu: boolean;
  @Input() mode: ServiceIntercomPopupMode;
  @Input() steps: NavbarLink[];
  @Input() loading: boolean;
  @Input() address: Address;
  @Input() onlyIpIntercoms: boolean;
  @Input() intercom: RdaResponse;
  @Input() intercoms: RdaResponse[];
  @Input() pbxOnRda: PbxOnRdaResponse;
  @Input() camerasLocations: LocationResponse[];
  @Input() intercomTypes: IntercomType[];
  @Input() translationTunings: TranslationTuningResponse[];
  @Input() intercomPanelsMode: ServiceIntercomPanelsMode;

  constructor(
    private popupStore: ServiceIntercomPopupStore,
    private serviceFacade: ServiceFacade,
    private confirmComponentConnectionHelper: ConfirmComponentConnectionHelper
  ) { }

  onChangePage(pageIdx: number) {
    this.popupStore.selectMode(pageIdx);
  }

  onUpdateIntercom(request: RdaUpdateRequest) {
    this.popupStore.updateSelectedIntercomUid(request.uid);

    if (!this.intercom) {
      this.confirmComponentConnectionHelper.showBottomSheet(() =>
        this.serviceFacade.addServiceRda(request)
      );

      return;
    }

    if (this.intercom && this.intercom.uid !== request.uid) {
      request.translation = this.intercom.translation;
      this.serviceFacade.changeServiceRda(request, this.intercom.id);
      return;
    }

    this.serviceFacade.updateServiceRda(request.uid, request);
  }

  onChangeIntercom(rda: RdaResponse) {
    if (this.intercom && this.intercom.id !== rda.id) {
      this.popupStore.updateSelectedIntercomUid(rda.uid);
      this.popupStore.updateFormChanged(true);
    }
  }

  onChangeIntercomType(intercomType: IntercomType) {
    if (this.intercom?.intercomType && this.intercom?.intercomType?.id !== intercomType?.id) {
      this.popupStore.updateFormChanged(true);
    }
  }

  onChangeIntercomPanelsMode(mode: ServiceIntercomPanelsMode) {
    this.popupStore.changeIntercomPanelsMode(mode);
    this.popupStore.changeMenuVisible(mode === ServiceIntercomPanelsMode.LIST);
  }

  onAddIntercomPanel(request: IntercomPanelConnectRequest) {
    this.serviceFacade.connectIntercomPanel(request);
  }

  onEditIntercomPanel(request: Partial<IntercomPanelUpdateRequest>, intercomPanelId: number, rdaId: number) {
    this.serviceFacade.updateIntercomPanel(intercomPanelId, rdaId, request);
  }

  onDeleteIntercomPanel(intercomPanelId: number, rdaId: number) {
    this.serviceFacade.disconnectIntercomPanel(intercomPanelId, rdaId);
  }

  onSaveTranslation(translation: number) {
    const request: RdaUpdateRequest = { id: this.intercom.id, translation };

    this.serviceFacade.updateServiceRda(this.intercom.uid, request);
  }

  onAddTranslationTuning(request: TranslationTuningRequest) {
    this.serviceFacade.addTranslationTuning(this.intercom.uid, request);
  }

  onDeleteTranslationTuning(translationTuningId: number) {
    this.serviceFacade.deleteTranslationTuning(this.intercom.uid, translationTuningId);
  }

  onConnectPbxOnRda(pbxOnRda: PbxOnRdaResponse) {
    this.serviceFacade.connectPbxOnRda(pbxOnRda, this.intercom.uid);
  }

  onChangePbxOnRda(oldPbxOnRda: PbxOnRdaResponse, newPbxOnRda: PbxOnRdaResponse) {
    this.serviceFacade.changePbxOnRda(oldPbxOnRda, newPbxOnRda, this.intercom.uid);
  }

  onDisconnectPbxOnRda(pbxOnRda: PbxOnRdaResponse) {
    this.serviceFacade.disconnectPbxOnRda(pbxOnRda, this.intercom.uid);
  }
}
