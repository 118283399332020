import { Injectable } from '@angular/core';
import { RdaResponse, ServiceApiService, ServiceInfoResponse } from '@app/shared/entities/rd';
import { ServicesTypes } from '@app/shared/models';
import { ServicePageActionsService } from '@app/views/services';
import { ServicePageServiceModel } from '@app/views/services/base';
import { GatesPageModel } from '../models';

@Injectable()
export class GatesPageService implements ServicePageServiceModel<GatesPageModel> {
    constructor(
        private serviceApiService: ServiceApiService,
        private servicePageService: ServicePageActionsService
    ) { }

    preparePageResponse(serviceInfoList: ServiceInfoResponse[]): GatesPageModel[] {
        return serviceInfoList.map((serviceInfo: ServiceInfoResponse) => ({
            id: serviceInfo.id,
            name: serviceInfo.customName ?? serviceInfo.name,
            companyId: serviceInfo.company?.id,
            rdasStates: {
                active: serviceInfo.rdas.filter(rda => rda.active).length,
                inactive: serviceInfo.rdas.filter(rda => !rda.active).length
            },
            camerasStates: {
                active: serviceInfo.cameras.filter(camera => camera.active).length,
                inactive: serviceInfo.cameras.filter(camera => !camera.active).length
            }
        }));
    }

    async getEntrancesIntercoms(entrancesIds: number[]): Promise<RdaResponse[]> {
        let intercoms: RdaResponse[] = [];

        try {
            for (const entranceId of entrancesIds) {
                const services: ServiceInfoResponse[] = await this.serviceApiService.getEntranceServices(entranceId).toPromise();

                for (const service of services) {
                    if (service.type !== ServicesTypes.SOFTWARE_INTERCOM) {
                        continue;
                    }

                    const serviceIntercoms: RdaResponse[] = await this.serviceApiService.getRdas(service.id).toPromise();

                    if (!serviceIntercoms?.length) {
                        continue;
                    }

                    intercoms = intercoms.concat(serviceIntercoms.map(intercom => {
                        intercom.entranceId = entranceId;
                        return intercom;
                    }));
                }
            }

            return intercoms;
        } catch (error) {
            throw error;
        }
    }

    async addEntranceIntercoms(entranceId: number): Promise<RdaResponse[]> {
        return await this.getEntrancesIntercoms([entranceId]);
    }

    async removeEntranceIntercoms(serviceId: number, intercomsIds: number[]): Promise<void> {
        await this.servicePageService.disconnectEntrancesIntercoms(serviceId, intercomsIds);
    }
}
