<app-bottom-sheet-wrapper
  [title]="data.title"
  [progress]="loading$ | async"
  [componentName]="data.componentName"
>
  <app-ip-intercom-generate-content
    [intercomType]="data.body.intercomType"
    [loading]="loading$ | async"
    (createIpIntercom)="onSubmit($event.request)"
  ></app-ip-intercom-generate-content>
</app-bottom-sheet-wrapper>
