<form
  class="popup"
  [formGroup]="form"
  autocomplete="off"
  (ngSubmit)="onSubmit()"
>
  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'shared.keys.ip.intercom.generate.url.field.ip' | translate }}
    </mat-label>
    <input
      matInput
      required
      type="text"
      autocorrect="off"
      spellcheck="false"
      autocapitalize="off"
      formControlName="ip"
      autocomplete="new-ip-address"
    />
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'shared.keys.ip.intercom.generate.url.field.port' | translate }}
    </mat-label>
    <input
      required
      matInput
      type="number"
      autocorrect="off"
      spellcheck="false"
      autocapitalize="off"
      formControlName="port"
      autocomplete="new-port"
    />
  </mat-form-field>

  <div class="ip-intercom-generate-urls-container-login-doornum">
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'shared.keys.ip.intercom.generate.url.field.login' | translate }}
      </mat-label>
      <input
        matInput
        required
        readonly
        #loginInput
        type="text"
        autocorrect="off"
        spellcheck="false"
        autocapitalize="off"
        formControlName="login"
        autocomplete="new-url-login"
        (focus)="this.removeAttribute(loginInput)"
      />
    </mat-form-field>

    <mat-form-field *ngIf="form.get('doorNum')" appearance="outline">
      <mat-label>
        {{ 'shared.keys.ip.intercom.generate.url.field.door_num' | translate }}
      </mat-label>
      <input
        matInput
        required
        min="1"
        max="3"
        type="number"
        autocorrect="off"
        spellcheck="false"
        autocapitalize="off"
        formControlName="doorNum"
        autocomplete="door-num"
      />
    </mat-form-field>
  </div>

  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'shared.keys.ip.intercom.generate.url.field.password' | translate }}
    </mat-label>
    <input
      matInput
      required
      readonly
      #passwordInput
      autocorrect="off"
      spellcheck="false"
      autocapitalize="off"
      formControlName="password"
      autocomplete="new-url-password"
      [type]="hidePassword ? 'password' : 'text'"
      (focus)="this.removeAttribute(passwordInput)"
    />

    <mat-icon
      matSuffix
      (click)="hidePassword = !hidePassword"
      class="popup-hide-password__icon"
      [svgIcon]="hidePassword ? 'invisible' : 'visible'"
    ></mat-icon>
  </mat-form-field>

  <div class="popup-actions">
    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="!form.valid || loading"
      class="popup-actions__button"
    >
      {{ 'shared.keys.ip.intercom.generate.url.button.submit' | translate }}
    </button>
  </div>
</form>
