import { NgModule } from '@angular/core';
import { SharedPipesModule } from '@app/shared';
import { SelectSearchModule } from '@app/shared/containers/select-search';
import { SharedModule } from '@app/shared/shared.module';
import { CreatePbxOnRdaFormComponent, DeletePbxOnRdaContentComponent, PbxOnRdaInfoContentComponent, PbxOnRdaListPageComponent } from '@app/views/pbx-on-rda/components';
import { PbxOnRdaListPageFacade, PbxOnRdaPageEffects, pbxOnRdaPageReducers } from '@app/views/pbx-on-rda/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        SharedModule,
        SharedPipesModule,
        SelectSearchModule,
        StoreModule.forFeature('pbx-on-rda', pbxOnRdaPageReducers),
        EffectsModule.forFeature([PbxOnRdaPageEffects]),
        NgxTranslateModule,
    ],
    declarations: [
        PbxOnRdaListPageComponent,
        CreatePbxOnRdaFormComponent,
        DeletePbxOnRdaContentComponent,
        PbxOnRdaInfoContentComponent,
    ],
    providers: [
        PbxOnRdaListPageFacade
    ]
})
export class PbxOnRdaModule { }
