import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-delete-intercom-content',
  templateUrl: './delete-intercom-content.component.html',
  styleUrls: ['./delete-intercom-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteIntercomContentComponent {
  @Input() rdaUid!: string;
  @Input() loading: boolean;
  @Output() private deleteRda: EventEmitter<void> = new EventEmitter();

  onDelete() {
    this.deleteRda.emit();
  }
}
