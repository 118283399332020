import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { Company } from '@app/shared/entities/rd/company/models';
import { RdaNewResponse } from '@app/shared/entities/rd/rda';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { companyFormGroup } from './companies-form.model';

@Component({
  selector: 'app-companies-form-group',
  templateUrl: './companies-form-group.component.html',
  styleUrls: ['./companies-form-group.component.scss']
})
export class CompaniesFormGroupComponent implements OnChanges {
  @Input() rda: RdaNewResponse;
  @Input() companyList: Company[];
  filteredCompanies: Observable<Company[]>;
  form: UntypedFormGroup;
  @ViewChild('filterInput') private filterInput: ElementRef<HTMLInputElement>;
  @Output() private handleCompanySelected: EventEmitter<{ companyId: number }>;

  constructor() {
    this.initEmitters();
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    const companyListChanges: SimpleChange = changes['companyList'];
    if (companyListChanges.currentValue && companyListChanges.currentValue.length > 0) {
      this.initFilter();
    }
  }

  onSelectCompany(event: MatSelectChange) {
    this.handleCompanySelected.emit({ companyId: event.value });
  }

  onOpenedChange(opened) {
    if (opened) {
      this.filterInput.nativeElement.select();
    } else {
      this.form.get('filter').setValue('');
    }
  }

  private initEmitters() {
    this.handleCompanySelected = new EventEmitter<{ companyId: number }>();
  }

  private initForm() {
    const formDefinition = companyFormGroup();
    this.form = formDefinition.form;
  }

  private initFilter() {
    let companyId: number;
    if (this.rda && this.rda.companyId) {
      companyId = this.rda.companyId;
    }
    this.form.get('companyId').setValue(companyId);

    this.filteredCompanies = this.form.get('filter').valueChanges
      .pipe(
        startWith<string | Company>(''),
        map(value => typeof value === 'string' ? value : value.name),
        map(name => name ? this._filter(name) : (this.companyList || []).slice())
      );
  }

  private _filter(name: string): Company[] {
    const filterValue = name.toLowerCase();
    return this.companyList.filter(company => company.name.toLowerCase().indexOf(filterValue) > -1);
  }
}
