import {Injectable} from '@angular/core';
import {EntrancesStatus} from '@app/shared/entities/rd';
import {EntrancePageResponse, EntrancesStatusesCountsResponseType} from '@app/shared/models';
import {AddressFormatter} from '@app/shared/services';
import {NavbarLink} from '@app/shared/ui';
import {EntrancesPageModel, entrancesStatusesRecords} from '../models';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class EntrancesPageService {

  constructor(
    private translate: TranslateService,
    private addressFormatter: AddressFormatter
  ) {}

  prepareEntrancesPageResponse(
    entrancePageResponseList: EntrancePageResponse[]
  ): EntrancesPageModel[] {
    return entrancePageResponseList.map((entrance: EntrancePageResponse) => {
      const camerasStatuses = {
        online: 0,
        offline: 0
      };

      if (entrance.cameras) {
        entrance.cameras.forEach((camera) => {
          camerasStatuses[camera.active ? 'online' : 'offline']++;
        });
      }

      return ({
        id: entrance.id,
        address: this.addressFormatter.formatAddress(entrance.address),
        intercomStatus: entrance.rda?.active ?? null,
        camerasStatuses: camerasStatuses.online > 0 || camerasStatuses.offline > 0 ? camerasStatuses : null
      });
    });
  }

  prepareCountsResponse(response: EntrancesStatusesCountsResponseType): NavbarLink[] {
    return Object
      .entries(response)
      .map((value: [EntrancesStatus, number]) => ({
        name: value[0],
        text: `${this.translate.instant(entrancesStatusesRecords[value[0]].text)} (${(value[1] ?? 0)})`
      }));
  }
}
