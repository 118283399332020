<app-dialog-wrapper
  [componentName]="data.componentName"
  [title]="data.title"
  [progress]="(popupState$ | async) === 'loading'"
>
  <div class="popup service-submit-connection-popup">
    <app-info-block>
      <img
        class="service-submit-connection-popup-info__image"
        info-block-image
        [src]="resourcesHelper.getResource(resourcePath.INFO_LG) | safeUrl"
        [srcset]="resourcesHelper.getResource(resourcePath.INFO_LG, true) | safeUrl"
      />
      <ng-container info-block-text>
        {{ 'services.popups.submit.info' | translate }}
      </ng-container>
    </app-info-block>

    <div class="popup-actions">
      <button
        mat-raised-button
        color="primary"
        class="popup-actions__button"
        (click)="onConnect()"
        [disabled]="(popupState$ | async) === 'loading'"
      >
        {{ 'services.popups.submit.button.connect' | translate }}
      </button>
    </div>
  </div>
</app-dialog-wrapper>
