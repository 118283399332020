import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { InfoBlockModule } from '@app/shared/ui';
import { IntercomKeyDeleteContainerComponent } from './intercom-key-delete-container.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        InfoBlockModule,
        NgxTranslateModule
    ],
    declarations: [IntercomKeyDeleteContainerComponent],
    exports: [IntercomKeyDeleteContainerComponent]
})
export class IntercomKeyDeleteContainerModule { }
