<div class="google-container">
  <ng-container *ngIf="mode === MODE.CREATE">
    <google-map
      #googleMap
      [zoom]="zoom"
      [options]="options"
      (mapInitialized)="initMapEvents()"
      (mapClick)="onMapClick($event)">
      <map-marker [position]="marker"></map-marker>
    </google-map>
    <!-- Use custom zoom buttons -->
    <button pButton class="google-container__zoom-in" (click)="zoomIn($event)">+</button>
    <button pButton class="google-container__zoom-out" (click)="zoomOut($event)">-</button>
  </ng-container>

  <div class="google-container-search">
    <label>
      Address
    </label>
    <input
      #search
      class="google-container-search__address"
      pInputText
      type="text"
      placeholder="{{ 'shared.entrances.edit.google.map.form.search' | translate }}"
      [value]="addressFormatter.formatAddress(address)"
      [readonly]="mode === MODE.EDIT"
      (keydown.enter)="$event.preventDefault()"
    />
  </div>
  <div class="google-container-addition">
    <div class="google-container-addition-container">
      <label>
        {{ 'shared.entrances.edit.google.map.form.field.additional' | translate }}
      </label>
      <input
        placeholder="{{ 'shared.entrances.edit.google.map.form.placeholder.additional' | translate }}"
        class="google-container-addition-container__complemento"
        [formControl]="complemento"
        [readonly]="mode === MODE.EDIT"
        pInputText/>
    </div>
    <div class="google-container-addition-container">
      <label>
        {{ 'shared.entrances.edit.google.map.form.field.entrance' | translate }}
      </label>
      <input
        placeholder="{{ 'shared.entrances.edit.google.map.form.placeholder.entrance' | translate }}"
        pInputText
        class="google-container-addition-container__entrance"
        [formControl]="entrance"
        [readonly]="mode === MODE.EDIT"/>
    </div>
  </div>
</div>
