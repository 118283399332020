import { ServiceConnection } from '@app/shared/entities/rd';
import { EntranceResponse } from '@app/shared/models';
import { Action } from '@ngrx/store';
import { AbonentsFromEntrancesStatus } from '../models';

export enum AbonentsFromEntrancesActionTypes {
    AbonentsFromEntrancesGetConnections = '[AbonentsFromEntrances] AbonentsFromEntrancesGetConnections',
    AbonentsFromEntrancesGetConnectionsSuccess = '[AbonentsFromEntrances] AbonentsFromEntrancesGetConnectionsSuccess',
    AbonentsFromEntrancesGetConnectionsFailure = '[AbonentsFromEntrances] AbonentsFromEntrancesGetConnectionsFailure',

    AbonentsFromEntrancesSetChecked = '[AbonentsFromEntrances] AbonentsFromEntrancesSetChecked',
    AbonentsFromEntrancesSetAllChecked = '[AbonentsFromEntrances] AbonentsFromEntrancesSetAllChecked',

    AbonentsFromEntrancesSetStatus = '[AbonentsFromEntrances] AbonentsFromEntrancesSetStatus',
    AbonentsFromEntrancesRemoveConnection = '[AbonentsFromEntrances] RemoveAbonentFromEntrance',

    AbonentsFromEntrancesStartConnectionProcess = '[AbonentsFromEntrances] AbonentsFromEntrancesStartConnectionProcess',
    AbonentsFromEntrancesUpdateConnectionProcess = '[AbonentsFromEntrances] AbonentsFromEntrancesUpdateConnectionProcess',
    AbonentsFromEntrancesFinishConnectionProcess = '[AbonentsFromEntrances] AbonentsFromEntrancesFinishConnectionProcess',

    AbonentsFromEntrancesClearState = '[AbonentsFromEntrances] AbonentsFromEntrancesClearState',
}

export class AbonentsFromEntrancesGetConnections implements Action {
    readonly type = AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesGetConnections;

    constructor(public entrances: Pick<EntranceResponse, 'id'>[], public ignoredServices: number[]) { }
}

export class AbonentsFromEntrancesGetConnectionsSuccess implements Action {
    readonly type = AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesGetConnectionsSuccess;

    constructor(public connections: ServiceConnection[]) { }
}

export class AbonentsFromEntrancesGetConnectionsFailure implements Action {
    readonly type = AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesGetConnectionsFailure;

    constructor() { }
}

export class AbonentsFromEntrancesSetChecked implements Action {
    readonly type = AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesSetChecked;

    constructor(public connectionId: number) { }
}

export class AbonentsFromEntrancesSetAllChecked implements Action {
    readonly type = AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesSetAllChecked;

    constructor() { }
}

export class AbonentsFromEntrancesRemoveConnection implements Action {
    readonly type = AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesRemoveConnection;

    constructor(public connections: Pick<ServiceConnection, 'id'>[]) { }
}

export class AbonentsFromEntrancesStartConnectionProcess implements Action {
    readonly type = AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesStartConnectionProcess;

    constructor(public totalConnections: number) { }
}

export class AbonentsFromEntrancesUpdateConnectionProcess implements Action {
    readonly type = AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesUpdateConnectionProcess;

    constructor(public doneConnections: number, public connectionId: number) { }
}

export class AbonentsFromEntrancesFinishConnectionProcess implements Action {
    readonly type = AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesFinishConnectionProcess;

    constructor() { }
}

export class AbonentsFromEntrancesSetStatus implements Action {
    readonly type = AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesSetStatus;

    constructor(public status: AbonentsFromEntrancesStatus) { }
}

export class AbonentsFromEntrancesClearState implements Action {
    readonly type = AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesClearState;

    constructor() { }
}

export type AbonentsFromEntrancesActions =
    | AbonentsFromEntrancesGetConnections
    | AbonentsFromEntrancesGetConnectionsSuccess
    | AbonentsFromEntrancesGetConnectionsFailure

    | AbonentsFromEntrancesSetChecked
    | AbonentsFromEntrancesSetAllChecked

    | AbonentsFromEntrancesRemoveConnection
    | AbonentsFromEntrancesSetStatus

    | AbonentsFromEntrancesStartConnectionProcess
    | AbonentsFromEntrancesUpdateConnectionProcess
    | AbonentsFromEntrancesFinishConnectionProcess

    | AbonentsFromEntrancesClearState;
