import { ApplicationRef, Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DialogWrapperService implements OnDestroy {
    private _pendingState$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private applicationRef: ApplicationRef
    ) { }

    ngOnDestroy() {
        this._pendingState$.next(false);
        this._pendingState$.complete();
    }

    set pendingState(state: boolean) {
        this._pendingState$.next(state);
        this.applicationRef.tick();
    }

    get pendingState$(): Observable<boolean> {
        return this._pendingState$.asObservable();
    }
}
