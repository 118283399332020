<div class="viewer" *ngIf="showAttached && files?.length">
  <p-orderList [value]="files" header="{{'shared.upload.document.selected_file' | translate}}" [listStyle]="{ 'max-height': '15rem' }">
    <ng-template let-fileConfig pTemplate="item">
      <app-file-card
        [fileConfig]="fileConfig"
        [actions]="{
          delete: true,
          download: false
        }"
        (delete)="onDelete($event)">
      </app-file-card>
    </ng-template>
  </p-orderList>
</div>
<div class="upload">
  <div class="upload__container">
    <input #fileDropRef class="drop-aria" type="file" multiple (change)="handleDrop($event)">
    <div class="upload__container-img">
      <img src="assets/icons/ic-upload-file.svg" alt="error">
    </div>
    <div class="upload__container-text">
      <span>
        {{ 'shared.upload.document.drop_file_there' | translate }}
      </span>
      <span>
        {{ 'shared.upload.document.or_select_file' | translate }}
      </span>
    </div>
  </div>
</div>
