<mat-card class="card-container">
  <mat-card-header>
    <mat-card-title>
      {{ 'abonent.page.info.title' | translate }}
    </mat-card-title>
  </mat-card-header>

  <hr>

  <mat-card-content>
    <app-info-table maxWidth="initial" [titleWidthInPx]="resolution.isMobile ? null : 200">
      <app-info-table-record>
        <p title>
          {{ 'abonent.page.info.header.application_status' | translate }}
        <p>

        <div value>
          <mat-chip
            [disabled]="true"
            [selectable]="false"
            color="{{ abonent?.resolved ? 'success' : 'error' }}"
          >
            {{
              abonent?.resolved
                ? ('abonent.page.info.enum.resolved.processed' | translate)
                : ('abonent.page.info.enum.resolved.not_processed' | translate)
            }}
          </mat-chip>
        </div>
      </app-info-table-record>

      <app-info-table-record>
        <p title>
          {{ 'abonent.page.info.field.id' | translate }}
        </p>
        <p value>
          {{ abonent?.id }}
        </p>
      </app-info-table-record>

      <app-info-table-record>
        <p title>
          {{ 'abonent.page.info.field.uid' | translate }}
        </p>
        <p value>
          {{ abonent?.uid }}
        </p>
      </app-info-table-record>

      <app-info-table-record>
        <p title>
          {{ 'abonent.page.info.field.phone' | translate }}
        </p>
        <p value>
          {{ abonent?.phone ? ('+' + abonent?.phone) : '' }}
        </p>
      </app-info-table-record>
    </app-info-table>
  </mat-card-content>
</mat-card>

<mat-card class="card-container">
  <mat-card-header>
    <mat-card-title>
      {{ 'abonent.page.info.header.voip_tokens' | translate }}
    </mat-card-title>
  </mat-card-header>

  <hr>

  <mat-card-content>
    <mat-form-field appearance="outline">
      <mat-label>
        {{ 'abonent.page.info.field.voip_token' | translate }}
      </mat-label>
      <mat-select (selectionChange)="onVoipTokenChanged($event.value)" [value]="selectedVoipToken">
        <mat-option *ngFor="let voipTokenInfo of voipTokens; let i = index" [value]="voipTokenInfo">
          {{ voipTokenInfo.application }} {{ voipTokenInfo.createdAt | date: 'dd.MM.yyyy' }} {{ voipTokenInfo.device }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <app-info-table
      *ngIf="selectedVoipToken"
      maxWidth="initial"
      [titleWidthInPx]="resolution.isMobile ? null : 200"
    >
      <app-info-table-record>
        <p title>
          {{ 'abonent.page.info.field.voip_token_id' | translate }}
        </p>
        <p value>
          {{ selectedVoipToken.id }}
        </p>
      </app-info-table-record>

      <app-info-table-record>
        <p title>
          {{ 'abonent.page.info.field.voip_token_id' | translate }}
        </p>
        <p value>
          {{ selectedVoipToken.token }}
        </p>
      </app-info-table-record>

      <app-info-table-record>
        <p title>
          {{ 'abonent.page.info.field.created_at' | translate }}
        </p>
        <p value>
          {{ selectedVoipToken.createdAt | date: 'dd.MM.yyyy HH:mm:ss' }}
        </p>
      </app-info-table-record>

      <app-info-table-record>
        <p title>
          {{ 'abonent.page.info.field.device' | translate }}
        </p>
        <p value>
          {{ selectedVoipToken.device }}
        </p>
      </app-info-table-record>

      <app-info-table-record>
        <p title>
          {{ 'abonent.page.info.field.os_version' | translate }}
        </p>
        <p value>
          {{ selectedVoipToken.osVersion }}
        </p>
      </app-info-table-record>

      <app-info-table-record>
        <p title>
          {{ 'abonent.page.info.field.application' | translate }}
        </p>
        <p value>
          {{ selectedVoipToken.application }}
        </p>
      </app-info-table-record>

      <app-info-table-record>
        <p title>
          {{ 'abonent.page.info.field.app_version' | translate }}
        </p>
        <p value>
          {{ selectedVoipToken.appVersion }}
        </p>
      </app-info-table-record>
    </app-info-table>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="selectedVoipToken" class="card-container">
  <mat-card-header>
    <mat-card-title>
      {{ 'abonent.page.info.header.events' | translate }}
    </mat-card-title>
  </mat-card-header>

  <hr>

  <mat-card-content>
    <div class="events-filter-block">
      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'abonent.page.info.field.event_type' | translate }}
        </mat-label>
        <mat-select (selectionChange)="onEventTypeSelected($event.value)" [value]="eventName">
          <mat-option *ngFor="let key of abonentEventNameKeys" [value]="key">
            {{ eventTypeLabels[key] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>
          {{ 'abonent.page.info.field.interval' | translate }}
        </mat-label>
        <mat-select (selectionChange)="onDateDiffSelected()" [(ngModel)]="selectedDateDiff">
          <mat-option *ngFor="let dateDiff of dateDiffs" [value]="dateDiff.value">
            {{ dateDiff.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <app-info-table
      maxWidth="initial"
      [titleWidthInPx]="resolution.isMobile ? null : 300"
      *ngFor="let event of filteredEvents; let last = last"
    >
      <ng-container
        #info="var"
        [appVar]="event.eventInfo.callEventInfo"
        *ngIf="event.eventInfo.callEventInfo"
      >
        <app-info-table-record *ngIf="eventName === abonentEventNameAll">
          <p title>
            {{ 'abonent.page.info.field.event' | translate }}
          </p>
          <p value>
            <strong>{{ 'abonent.page.info.field.call' | translate }}</strong>
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.call_id' | translate }}
          </p>
          <p value>
            {{ info.appVar.callId }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.adapter_uid' | translate }}
          </p>
          <p value>
            {{ info.appVar.adapterUid }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.dialed' | translate }}
          </p>
          <p value>
            {{ info.appVar.dialed }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.translated' | translate }}
          </p>
          <p value>
            {{ info.appVar.translated }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.date_call' | translate }}
          </p>
          <p value>
            {{ info.appVar.dateCall | date: 'dd.MM.yyyy HH:mm:ss' }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.date_created' | translate }}
          </p>
          <p value>
            {{ info.appVar.dateCreated | date: 'dd.MM.yyyy HH:mm:ss' }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.date_connected' | translate }}
          </p>
          <p value>
            {{ info.appVar.dateConnected | date: 'dd.MM.yyyy HH:mm:ss' }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.date_removed' | translate }}
          </p>
          <p value>
            {{ info.appVar.dateRemoved | date: 'dd.MM.yyyy HH:mm:ss' }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>{{ 'abonent.page.info.field.call_duration' | translate }}</p>
          <p value>
            <ng-container *ngIf="info.appVar.dateConnected && info.appVar.dateRemoved">
              {{ (info.appVar.dateRemoved - info.appVar.dateConnected) | date: 'mm:ss' }}
            </ng-container>
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.result' | translate }}
          </p>
          <p value>{{ info.appVar.result }}</p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.monitoring_error' | translate }}
          </p>
          <p value>
            {{
              info.appVar.monitoringError
                ? ('enum.bool.yes' | translate)
                : ('enum.bool.no' | translate)
            }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.error_code' | translate }}
          </p>
          <p value>
            {{ info.appVar.errorCode }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.received_notification' | translate }}
          </p>
          <p value>
            <ng-container *ngIf="info.appVar.alertedAbonents?.length > 0">
              <ng-container *ngFor="let abonent of info.appVar.alertedAbonents; let idx = index; let last = last">
                <ng-container *ngIf="abonent?.abonentInfo?.phone || abonent?.applicationInfo?.pushToken">
                  <p>
                    {{ 'abonent.page.info.field.number' | translate }}: +{{ abonent?.abonentInfo?.phone }}
                  </p>
                  <p class="voip-token-field">
                    {{ 'abonent.page.info.field.token' | translate }}: {{ abonent?.applicationInfo?.pushToken }}
                  </p>
                  <hr *ngIf="!last">
                </ng-container>
              </ng-container>
            </ng-container>
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.accepted_call' | translate }}
          </p>
          <p value>
            <span>
              {{ 'abonent.page.info.field.number' | translate }} +{{ info.appVar.respondedAbonent.abonentInfo.phone }}
            </span>
            <br>
            <span>
              {{ 'abonent.page.info.field.token' | translate }} {{ info.appVar.respondedAbonent.applicationInfo.pushToken }}
            </span>
          </p>
        </app-info-table-record>
      </ng-container>

      <ng-container
        #info="var"
        *ngIf="event.eventInfo.keyActivityEventInfo"
        [appVar]="event.eventInfo.keyActivityEventInfo"
      >
        <app-info-table-record *ngIf="eventName === abonentEventNameAll">
          <p title>
            {{ 'abonent.page.info.field.event' | translate }}
          </p>
          <p value>
            <strong>{{ 'abonent.page.info.field.opening_door' | translate }}</strong>
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.activity_uid' | translate }}
          </p>
          <p value>
            {{ info.appVar.activityUid }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.adapter_uid' | translate }}
          </p>
          <p value>
            {{ info.appVar.adapterUid }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.rele_id' | translate }}
          </p>
          <p value>{{ info.appVar.releId }}</p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.creating_event' | translate }}
          </p>
          <p value>
            {{ info.appVar.dateCreated | date: 'dd.MM.yyyy HH:mm:ss' }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.activation_key' | translate }}
          </p>
          <p value>
            {{ info.appVar.dateActivated | date: 'dd.MM.yyyy HH:mm:ss' }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.result' | translate }}
          </p>
          <p value>
            {{ info.appVar.result }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.date_created_mobile' | translate }}
          </p>
          <p value>
            {{ info.appVar.dateCreatedMobile | date: 'dd.MM.yyyy HH:mm:ss' }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.date_received_mobile' | translate }}
          </p>
          <p value>
            {{ info.appVar.dateReceivedMobile | date: 'dd.MM.yyyy HH:mm:ss' }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.result_mobile' | translate }}
          </p>
          <p value>
            {{ info.appVar.resultMobile }}
          </p>
        </app-info-table-record>
      </ng-container>

      <ng-container
        #info="var"
        *ngIf="event.eventInfo.shareAccessEventInfo"
        [appVar]="event.eventInfo.shareAccessEventInfo"
      >
        <app-info-table-record *ngIf="eventName === abonentEventNameAll">
          <p title>
            {{ 'abonent.page.info.field.event' | translate }}
          </p>
          <p value>
            <strong> {{ 'abonent.page.info.field.access_delegation' | translate }}</strong>
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.phone' | translate }}
          </p>
          <p value>
            {{ info.appVar.phone }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.result' | translate }}
          </p>
          <p value>
            {{ info.appVar.result }}
          </p>
        </app-info-table-record>
      </ng-container>

      <ng-container
        #info="var"
        *ngIf="event.eventInfo.signInEventInfo"
        [appVar]="event.eventInfo.signInEventInfo"
      >
        <app-info-table-record *ngIf="eventName === abonentEventNameAll">
          <p title>
            {{ 'abonent.page.info.field.event' | translate }}
          </p>
          <p value>
            <strong>{{ 'abonent.page.info.field.authorization' | translate }}</strong>
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.date_last_active' | translate }}
          </p>
          <p value>
            {{ info.appVar.dateLastActive | date: 'dd.MM.yyyy HH:mm:ss' }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.date_sign_in' | translate }}
          </p>
          <p value>
            {{ info.appVar.dateSignIn | date: 'dd.MM.yyyy HH:mm:ss' }}
          </p>
        </app-info-table-record>
      </ng-container>

      <ng-container
        #info="var"
        *ngIf="event.eventInfo.videoEventInfo"
        [appVar]="event.eventInfo.videoEventInfo"
      >
        <app-info-table-record *ngIf="eventName === abonentEventNameAll">
          <p title>
            {{ 'abonent.page.info.field.event' | translate }}
          </p>
          <p value>
            <strong>
              {{ 'abonent.page.info.field.playing_video' | translate }}
            </strong>
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.activity_uid' | translate }}
          </p>
          <p value>
            {{ info.appVar.activityUid }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.camera_id' | translate }}
          </p>
          <p value>
            {{ info.appVar.cameraId }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.selected_video_for_playing_phone' | translate }}
          </p>
          <p value>
            {{ info.appVar.dateStarted | date: 'dd.MM.yyyy HH:mm:ss' }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.playing_began_phone' | translate }}
          </p>
          <p value>
            {{ info.appVar.dateReceived | date: 'dd.MM.yyyy HH:mm:ss' }}
          </p>
        </app-info-table-record>

        <app-info-table-record>
          <p title>
            {{ 'abonent.page.info.field.result' | translate }}
          </p>
          <p value>
            {{ info.appVar.result }}
          </p>
        </app-info-table-record>
      </ng-container>

      <hr *ngIf="!last" class="event-divider">
    </app-info-table>
    <ng-template #emptyBlock>
      <app-empty-data *ngIf="filteredEvents?.length < 1 && eventName">
      </app-empty-data>
    </ng-template>
  </mat-card-content>
</mat-card>
