import { ActionReducerMap } from '@ngrx/store';
import { VideoSurveillanceActions, VideoSurveillanceListActionTypes } from './video-surveillance-list.actions';
import { videoSurveillanceListInitialState, VideoSurveillancePageState } from './video-surveillance-list.state';

function reducer(
    state = videoSurveillanceListInitialState,
    action: VideoSurveillanceActions
): VideoSurveillancePageState {
    switch (action.type) {
        case VideoSurveillanceListActionTypes.GetVideoSurveillanceList: {
            return {
                ...state,
                videoSurveillanceList: null,
                loading: true
            };
        }

        case VideoSurveillanceListActionTypes.GetVideoSurveillanceListSuccess: {
            return {
                ...state,
                videoSurveillanceList: action.response,
                pageData: action.pageData,
                loading: false
            };
        }

        case VideoSurveillanceListActionTypes.GetVideoSurveillanceListFailure: {
            return {
                ...state,
                videoSurveillanceList: null,
                loading: false
            };
        }

        default: {
            return state;
        }
    }
}

export const videoSurveillancePageReducers: ActionReducerMap<{ videoSurveillance: VideoSurveillancePageState }> = {
    videoSurveillance: reducer
};
