import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ButtonModule} from 'primeng/button';
import {InputTextModule} from 'primeng/inputtext';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators
} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import {DropdownModule} from 'primeng/dropdown';
import {InputSwitchModule} from 'primeng/inputswitch';
import {catchError, filter, mergeMap, takeUntil} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';
import {fromOpacity} from '@app/shared/animations/animations';
import {SkeletonModule} from 'primeng/skeleton';
import {InputMask, InputMaskModule} from 'primeng/inputmask';
import {AbonentService, Account, ServiceApiService, ServiceConnection} from '@app/shared/entities/rd';
import {Abonent} from '@app/shared/models';
import {isEmpty} from 'lodash';
import {ResolutionBreakpoint, ResolutionService} from '@app/shared/services';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {SnackbarService} from '@app/shared/components';
import {parseError} from '@app/shared/helpers';

export namespace ApartmentVideoAddService {
  export interface Output {
    formData: {
      subscriber: string;
      serviceName: string;
      selectedAccount?: number;
      createNewAccount?: boolean;
      newAccountName?: string;
    };
    createAccountResponse?: Account;
    createPServiceResponse?: ServiceConnection;
  }

  export interface Form {
    subscriber: AbstractControl<string>;
    serviceName: AbstractControl<string>;
    selectedAccount?: AbstractControl<number>;
    createNewAccount?: AbstractControl<boolean>;
    newAccountName?: AbstractControl<string>;
  }
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    ReactiveFormsModule,
    TranslateModule,
    MatFormFieldModule,
    DropdownModule,
    InputSwitchModule,
    SkeletonModule,
    InputMaskModule,
    ProgressSpinnerModule
  ],
  selector: 'app-apartment-video-add-service',
  templateUrl: './apartment-video-add-service.component.html',
  styleUrls: ['./apartment-video-add-service.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [fromOpacity]
})
export class ApartmentVideoAddServiceComponent implements OnInit, OnDestroy {
  public loaded = false;
  public showLoading = false;
  public addServiceForm: FormGroup<ApartmentVideoAddService.Form>;
  @ViewChild('subscriberField') public subscriberField: InputMask;

  public accountlist: Account[] = [];

  public get isRename(): boolean {
    return this.dynamicDialogConfig?.data?.rename || false;
  }

  public get serviceName(): string {
    return this.dynamicDialogConfig?.data?.serviceName || '';
  }

  public get isFormValid(): boolean {
    return this.addServiceForm?.valid;
  }

  public showAdditionalFields = false;

  private createdAccount = null;
  private abonent: Abonent;
  private destroy = new Subject<void>();

  constructor(
    private dynamicDialogRef: DynamicDialogRef,
    private dynamicDialogConfig: DynamicDialogConfig,
    private resolutionService: ResolutionService,
    private abonentService: AbonentService,
    private serviceApiService: ServiceApiService,
    private snackbar: SnackbarService,
  ) {
    this.dynamicDialogConfig.styleClass = 'apartment-video-add-service__modal';
    if (this.resolutionService.getBreakpointState(ResolutionBreakpoint.SM_W_DOWN)) {
      this.dynamicDialogConfig.height = '100%';
      this.dynamicDialogConfig.width = '100%';
      this.dynamicDialogConfig.styleClass = `${this.dynamicDialogConfig.styleClass} apartment-video-add-service__modal--full-screen`;
    }
  }

  public ngOnInit(): void {
    this.addServiceForm = new FormGroup<ApartmentVideoAddService.Form>({
      subscriber: new FormControl<string>({
        value: '',
        disabled: this.isRename
      }, [Validators.required, this.simplePhoneValidator()]),
      serviceName: new FormControl<string>(this.serviceName, [Validators.required, Validators.minLength(3)]),
      selectedAccount: new FormControl<any>(null),
      createNewAccount: new FormControl<boolean>(false),
      newAccountName: new FormControl<string>({value: null, disabled: true}),
    });
    if (!this.isRename) {
      this.addServiceForm.get('subscriber').valueChanges.pipe(takeUntil(this.destroy)).subscribe((value) => {
        const val = this.addServiceForm.get('subscriber').value;
        if (!val || this.addServiceForm.get('subscriber').invalid || !this.subscriberField.isCompleted()) {
          this.addServiceForm.get('selectedAccount').patchValue(null);
          this.addServiceForm.get('newAccountName').patchValue(null);
          this.addServiceForm.get('selectedAccount').clearValidators();
          this.addServiceForm.get('newAccountName').clearValidators();
          this.showAdditionalFields = false;
          this.accountlist = [];
          this.createdAccount = null;
          this.abonent = null;
          return;
        }
      });
      this.addServiceForm.get('createNewAccount').valueChanges.pipe(takeUntil(this.destroy)).subscribe((value) => {
        if (this.addServiceForm.get('createNewAccount').value) {
          this.addServiceForm.get('newAccountName').enable();
          this.addServiceForm.get('selectedAccount').clearValidators();
          this.addServiceForm.get('selectedAccount').disable();
        } else {
          this.addServiceForm.get('newAccountName').clearValidators();
          this.addServiceForm.get('newAccountName').disable();
          this.addServiceForm.get('selectedAccount').setValidators([Validators.required]);
          this.addServiceForm.get('selectedAccount').enable();
        }
        this.addServiceForm.markAllAsTouched();
      });
    }
    this.loaded = true;
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public onSaveAction(): void {
    if (this.isFormValid) {
      if (this.isRename) {
        this.dynamicDialogRef.close({formData: this.addServiceForm.getRawValue()});
      } else {
        if (this.createdAccount && this.formatPhone(this.addServiceForm.get('subscriber').value) === this.createdAccount) {
          this.createNewService();
        } else {
          this.createNewAccount();
        }
      }
    }
  }

  public createNewAccount(): void {
    this.showLoading = true;
    const formData = this.addServiceForm.getRawValue();
    formData.subscriber = this.formatPhone(formData.subscriber);
    this.abonentService.createAbonent(formData.subscriber)
      .pipe(
        takeUntil(this.destroy),
        mergeMap((response) => {
          this.abonent = response;
          this.createdAccount = formData.subscriber;
          return this.abonentService.getAbonentAccounts(this.abonent.id);
        })
      )
      .subscribe((response) => {
        this.accountlist = response;

        if (isEmpty(this.accountlist)) {
          this.addServiceForm.get('selectedAccount').clearValidators();
          this.addServiceForm.get('selectedAccount').setValue(null);
          this.addServiceForm.get('createNewAccount').setValue(true);
          this.addServiceForm.get('createNewAccount').disable();
        } else {
          this.addServiceForm.get('createNewAccount').enable();
          this.addServiceForm.get('createNewAccount').setValue(false);
        }

        this.showAdditionalFields = true;
        this.showLoading = false;
      });
  }

  public createNewService(): void {
    const formData = this.addServiceForm.getRawValue();
    formData.subscriber = this.formatPhone(formData.subscriber);

    let subsr: Observable<Account>;
    let createdAccount: Account = null;
    if (!formData?.createNewAccount) {
      createdAccount = this.accountlist.find((f) => f.id === +formData.selectedAccount);
      subsr = of(createdAccount);
    } else {
      createdAccount = this.accountlist.find((f) => f.number === formData.newAccountName);
      subsr = !createdAccount ?
        this.abonentService.createAccount2({phone: +formData.subscriber, number: formData.newAccountName, companyId: null} as any) :
        of(createdAccount);
    }

    subsr.pipe(
      mergeMap((response) => {
        createdAccount = response;
        return this.serviceApiService.postPersonalSurveillanceList({serviceName: formData.serviceName});
      }),
      mergeMap((response) => {
        return this.serviceApiService.createConnection(response.id, {accountId: createdAccount.id, flatId: null})
          .pipe(
            catchError((err) => {
              this.snackbar.showMessage(parseError(err));
              this.showLoading = false;
              return of(null);
            })
          );
      }),
      filter((response) => !!response),
    ).subscribe((response) => {
      this.dynamicDialogRef.close(<ApartmentVideoAddService.Output>{
        formData,
        createAccountResponse: createdAccount,
        createPServiceResponse: response,
      });
      this.showLoading = false;
    });
  }

  private simplePhoneValidator(): ValidatorFn {
    return (control: AbstractControl<string>): ValidationErrors | null => {
      const value = this.formatPhone(control.value);
      return !!value && value.length === 11 ? null : {error: true};
    };
  }

  private formatPhone(value: string): string {
    return !value ? '' : value.replace(/\D/g, '');
  }
}
