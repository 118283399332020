import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ActionTypes } from '../../actions/orders.actions';
import { OrderResponse } from '@app/views/intercom/models';

export interface State {
    order: OrderResponse;
    error: string | HttpErrorResponse | null;
    loading: boolean;
}

export const initialState: State = {
    order: null,
    error: null,
    loading: false
};

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case ActionTypes.CreateOrder: {
            return {
                ...state,
                order: null,
                error: null,
                loading: true
            };
        }

        case ActionTypes.CreateOrderSuccess: {
            return {
                ...state,
                order: action.payload,
                error: null,
                loading: false
            };
        }

        case ActionTypes.CreateOrderFailure: {
            return {
                ...state,
                order: null,
                error: action.payload,
                loading: false
            };
        }

        case ActionTypes.CreateOrderClear: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}

