import { Pipe, PipeTransform } from '@angular/core';
import { PaymentsInterface } from '@app/shared/entities/rd/payments/interfaces';
import { PAYMENTS_STATUS } from '@app/shared/models/PaymentsStatus';

@Pipe({
  name: 'BlockedPayments'
})
export class BlockedPaymentsPipe implements PipeTransform {
  transform(payments: PaymentsInterface[]): PaymentsInterface[] {
    return payments?.filter(payment => [
      PAYMENTS_STATUS.created,
      PAYMENTS_STATUS.pending,
      PAYMENTS_STATUS.canceled
    ].some(status => payment.status === status));
  }
}
