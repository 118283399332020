import {
  LocalStorageDashboardKey,
  LocalStorageGeneralKey,
  LocalStorageKey,
  LocalStorageLoginKey,
  LocalStoragePaymentsKey
} from '@app/shared/entities/common/local-storage/models';

export class LocalStorageHelper {
  static setItem(type: LocalStorageKey, value: any) {
    if (type === null || type === undefined || value === null || type === undefined) {
      return;
    }

    switch (type) {
      case LocalStorageGeneralKey.COUNTRY:
        localStorage[type] = value?.shortName ? JSON.stringify(value) : null;
        break;
      case LocalStorageDashboardKey.DASHBOARD_RDAS_COUNTS:
      case LocalStorageDashboardKey.DASHBOARD_CAMERAS_COUNTS:
      case LocalStorageDashboardKey.DASHBOARD_SOFTWARE_INTERCOMS_COUNTS:
      case LocalStorageDashboardKey.DASHBOARD_VIDEO_SURVEILLANCE_COUNTS:
      case LocalStorageDashboardKey.DASHBOARD_GATES_COUNTS:
      case LocalStoragePaymentsKey.INTEGRATION_FORM:
      case LocalStoragePaymentsKey.STEPPER:
      case LocalStorageLoginKey.USER_LAST_URL:
      case LocalStorageLoginKey.USER_SAVED_LOGIN:
        localStorage[type] = JSON.stringify(value);
        break;
      default:
        localStorage[type] = value;
        break;
    }
  }

  static getItem(type: LocalStorageKey): any {
    if (!type || !localStorage[type]) {
      return null;
    }

    switch (type) {
      case LocalStorageGeneralKey.COUNTRY:
      case LocalStorageDashboardKey.DASHBOARD_RDAS_COUNTS:
      case LocalStorageDashboardKey.DASHBOARD_CAMERAS_COUNTS:
      case LocalStorageDashboardKey.DASHBOARD_SOFTWARE_INTERCOMS_COUNTS:
      case LocalStorageDashboardKey.DASHBOARD_VIDEO_SURVEILLANCE_COUNTS:
      case LocalStorageDashboardKey.DASHBOARD_GATES_COUNTS:
      case LocalStoragePaymentsKey.INTEGRATION_FORM:
      case LocalStoragePaymentsKey.STEPPER:
      case LocalStorageLoginKey.USER_LAST_URL:
      case LocalStorageLoginKey.USER_SAVED_LOGIN:
        return JSON.parse(localStorage[type]);
      default:
        return localStorage[type];
    }
  }

  static deleteItem(type: LocalStorageKey) {
    localStorage.removeItem(type);
  }
}
