import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { TokenService } from '@app/security';
import { RdaUpdateRequest } from '@app/shared/entities/rd/rda';
import { PopupBasicComponent } from '@app/shared/templates';
import { Subject } from 'rxjs';
import { addIntercomForm } from './add-intercom-form.model';

@Component({
  selector: 'app-add-intercom-form',
  templateUrl: './add-intercom-form.component.html',
  styleUrls: ['./add-intercom-form.component.scss']
})
export class AddIntercomFormComponent extends PopupBasicComponent implements OnInit {
  form: UntypedFormGroup;
  companyId: number;

  constructor(
    @Inject('subject') private subject: Subject<any>,
    private tokenService: TokenService
  ) {
    super();
    this.initForm();
  }

  ngOnInit() {
    this.companyId = this.tokenService.getCompanyId();
  }

  initForm() {
    this.form = addIntercomForm();
  }

  onSubmit() {
    if (this.form.valid) {
      const value = this.form.getRawValue();
      const intercomRegistration: RdaUpdateRequest = { id: null, uid: value.uid, companyId: this.companyId };
      this.subject.next({ intercomRegistration });
    }
  }
}
