import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared';
import { IpIntercomGenerateUrlsContainerModule } from '@app/shared/components/keys';
import { IpIntercomGenerateContentComponent } from './ip-intercom-generate-content.component';
import {
    IpSputnikGenerateUrlsContainerModule
} from '@app/shared/components';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        SharedPipesModule,
        MaterialModule,
        IpIntercomGenerateUrlsContainerModule,
        IpSputnikGenerateUrlsContainerModule,
        NgxTranslateModule
    ],
    declarations: [
        IpIntercomGenerateContentComponent
    ],
    exports: [
        IpIntercomGenerateContentComponent
    ]
})
export class IpIntercomGenerateContentModule { }
