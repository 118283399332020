import {Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import { CommonModule } from '@angular/common';
import {Paginator, PaginatorModule} from 'primeng/paginator';

export interface IDefaultOption {
  label: number;
  value: number;
}

export interface IPaginatorConfig<TData> {
  options?: IDefaultOption[] | TData[];
  optionLabel?: string;
  optionValue?: string;
  first?: number;
  rows?: number;
  totalRecords?: number;
  pageLinkSize?: number;
  currentPageReportTemplate?: string;
  dropdownPaginationMod?: boolean;
  pageSize?: number;
  page?: number;
}

export interface IPageChange {
  first: number;
  page: number;
  pageCount: number;
  rows: number;
}

@Component({
  selector: 'app-custom-paginator',
  templateUrl: './custom-paginator.component.html',
  styleUrls: ['./custom-paginator.component.scss'],
  imports: [CommonModule, PaginatorModule],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class CustomPaginatorComponent<TDropdownModal> {
  @Input() paginatorConfig: IPaginatorConfig<TDropdownModal>;

  @Output() pageChange: EventEmitter<IPageChange> = new EventEmitter<IPageChange>();
  @Output() pageSizeChange: EventEmitter<number> = new EventEmitter<number>();

  @ViewChild(Paginator) public paginator: Paginator;

  public onPageSizeChange(size: number): void {
    this.pageSizeChange.emit(size);
  }

  public onPageChange(pageChange: IPageChange): void {
    this.pageChange.emit(pageChange);
  }

}
