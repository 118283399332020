import {IpRdaConnectionTypes} from '@app/shared/entities/rd';
import {TranslateService} from '@ngx-translate/core';

export class IntercomTypeUtils {
  static getIpIntercomConnectionTypeText(type: string, translate: TranslateService): string {
    if (!type) {
      return translate.instant('enum.intercom_type.direct');
    }

    switch (type.toLowerCase()) {
      case IpRdaConnectionTypes.SIPTRUNK:
        return translate.instant('enum.intercom_type.siptrunk');
      case IpRdaConnectionTypes.DIRECT:
        return translate.instant('enum.intercom_type.direct');
    }
  }
}
