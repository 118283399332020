import { Injectable } from '@angular/core';
import { NavbarLink } from '@app/shared/ui';
import { ServiceIntercomPanelsMode, ServiceIntercomPopupMode } from '@app/views/services/components';
import { ComponentStore } from '@ngrx/component-store';
import { Observable } from 'rxjs';
import { ServiceIntercomPopupStep, ServiceIntercomPopupUpdateStep } from '../models';
import { initialServiceIntercomPopupSteps, ServiceIntercomPopupState } from './service-intercom-popup.state';

@Injectable()
export class ServiceIntercomPopupStore extends ComponentStore<ServiceIntercomPopupState> {
    readonly steps$: Observable<NavbarLink[]> = this.select((state: ServiceIntercomPopupState) => state.steps);
    readonly mode$: Observable<ServiceIntercomPopupMode> = this.select((state: ServiceIntercomPopupState) => state.mode);
    readonly formChanged$: Observable<boolean> = this.select((state: ServiceIntercomPopupState) => state.formChanged);
    readonly selectedIntercomUid$: Observable<string> = this.select((state: ServiceIntercomPopupState) => state.selectedIntercomUid);
    readonly intercomPanelsMode$: Observable<ServiceIntercomPanelsMode> = this.select(state => state.intercomPanelsMode);
    readonly showMenu$: Observable<boolean> = this.select(state => state.showMenu);

    readonly updateStepState = this.updater((
        state: ServiceIntercomPopupState,
        { step, stepState }: { step: ServiceIntercomPopupStep, stepState?: ServiceIntercomPopupUpdateStep }
    ) => {
        if (stepState.disabled !== undefined) {
            state.steps[step].disabled = stepState.disabled;
        }

        if (stepState.hidden !== undefined) {
            state.steps[step].hidden = stepState.hidden;
        }

        state.steps[step].tooltip = stepState.tooltip;

        return {
            ...state,
            steps: state.steps.slice()
        };
    });

    readonly updateFormChanged = this.updater((state: ServiceIntercomPopupState, formChanged: boolean) =>
        ({ ...state, formChanged })
    );

    readonly updateNewIntercom = this.updater((state: ServiceIntercomPopupState, newIntercom: boolean) =>
        ({ ...state, newIntercom })
    );

    readonly updateSelectedIntercomUid = this.updater((state: ServiceIntercomPopupState, selectedIntercomUid: string) =>
        ({ ...state, selectedIntercomUid })
    );

    readonly changeIntercomPanelsMode = this.updater((state: ServiceIntercomPopupState, intercomPanelsMode: ServiceIntercomPanelsMode) =>
        ({ ...state, intercomPanelsMode, showMenu: intercomPanelsMode === ServiceIntercomPanelsMode.LIST })
    );

    readonly changeMenuVisible = this.updater((state: ServiceIntercomPopupState, show: boolean) =>
        ({ ...state, showMenu: !!show })
    );

    readonly selectMode = this.updater((state: ServiceIntercomPopupState, mode: ServiceIntercomPopupMode) =>
        ({ ...state, mode })
    );

    constructor() {
        super({
            steps: JSON.parse(JSON.stringify(initialServiceIntercomPopupSteps)),
            mode: ServiceIntercomPopupMode.INTERCOM,
            showMenu: true,
            formChanged: false,
            newIntercom: null,
            selectedIntercomUid: null,
            intercomPanelsMode: ServiceIntercomPanelsMode.LIST
        });
    }
}
