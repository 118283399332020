import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '@app/shared/components';
import { ResourcePath, ResourcesHelper } from '@app/shared/entities';
import { Constants } from '@app/shared/helpers';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData, DialogWrapperSize } from '@app/shared/ui';
import { filter, take, tap } from 'rxjs/operators';

import { PaymentsService } from '../../../services';
import { PaymentsPageStore } from '../../../store';
import { PaymentsWizardComponent } from '../../popups';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-payments-connection',
  templateUrl: './payments-connection.component.html',
  styleUrls: ['./payments-connection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentsConnectionComponent {
  public readonly resourcePath: typeof ResourcePath = ResourcePath;

  constructor(
    public resourcesHelper: ResourcesHelper,
    private dialog: MatDialog,
    private resolution: ResolutionService,
    private store: PaymentsPageStore,
    private snackbar: SnackbarService,
    private paymentsService: PaymentsService,
    private translate: TranslateService
  ) {}

  public onOpenWizard(): void {
    const data: DialogWrapperData<void, void> = {
      title: this.translate.instant('payments.connection.open_wizard.title'),
      componentName: 'PaymentsWizard',
      submit: () => {
        this.snackbar.showMessage(
          this.translate.instant('payments.connection.open_wizard.message.success'),
          'success'
        );

        this.paymentsService.companyShortName$
          .pipe(
            filter((shortName: string) => shortName !== null),
            take(1),
            tap((companyShortName: string) =>
              this.store.checkPaymentConnection(companyShortName)
            )
          )
          .subscribe();

        dialog.close();
      }
    };

    const dialog: MatDialogRef<PaymentsWizardComponent> = this.dialog.open(
      PaymentsWizardComponent,
      {
        autoFocus: false,
        panelClass: Constants.FULLSCREEN_DIALOG_CLASS,
        width: this.resolution.getBreakpointState(
          ResolutionBreakpoint.MD_W_DOWN
        )
          ? DialogWrapperSize.MAX
          : DialogWrapperSize.MD,
        data
      }
    );
  }
}
