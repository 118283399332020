import { Injectable, OnDestroy } from '@angular/core';
import { DialogWrapperStateHelper } from '@app/shared/ui';
import { ServicesActionTypes } from '@app/views/services/store';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { DeleteServiceEntrance, DeleteServiceEntranceFailure, DeleteServiceEntranceSuccess } from './../../../../store/';

@Injectable()
export class ServiceDeleteEntrancePopupService extends DialogWrapperStateHelper<'loading' | 'close' | 'loaded'> implements OnDestroy {
    constructor(
        private actions$: Actions
    ) {
        super();
        this.enableStateListeners();
    }

    ngOnDestroy(): void {
        this.destroy();
    }

    private enableStateListeners() {
        this.actions$
            .pipe(
                ofType<DeleteServiceEntrance>(ServicesActionTypes.DeleteServiceEntrance), takeUntil(this.onDestroy$),
            )
            .subscribe(() => this.state = 'loading');

        this.actions$
            .pipe(
                ofType<DeleteServiceEntranceSuccess>(ServicesActionTypes.DeleteServiceEntranceSuccess), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'close');

        this.actions$
            .pipe(
                ofType<DeleteServiceEntranceFailure>(ServicesActionTypes.DeleteServiceEntranceFailure), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loaded');
    }
}
