import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@app/shared/components';
import { LoaderService } from '@app/shared/entities/common/loader';
import { DadataCompanySuggestionData, DadataSuggestionResponse } from '@app/shared/entities/integrations/dadata';
import { CompanySignUpRequest } from '@app/shared/entities/rd/company/models';
import { PERSONAL_AGREEMENT_PAGE_LINK_KZ, PERSONAL_AGREEMENT_PAGE_LINK_RU, PRIVACY_POLICY_PAGE_LINK_KZ, PRIVACY_POLICY_PAGE_LINK_RU } from '@app/shared/helpers';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-company-sign-up-request-form',
  templateUrl: './company-sign-up-request-form.component.html',
  styleUrls: ['./company-sign-up-request-form.component.scss']
})
export class CompanySignUpRequestFormComponent implements OnInit {
  readonly privacyPolicyPageLink: string = environment.kz ? PRIVACY_POLICY_PAGE_LINK_KZ : PRIVACY_POLICY_PAGE_LINK_RU;
  readonly personalAgreementPageLink: string = environment.kz ? PERSONAL_AGREEMENT_PAGE_LINK_KZ : PERSONAL_AGREEMENT_PAGE_LINK_RU;

  form: UntypedFormGroup;
  loading: boolean;
  @Output() private submitted: EventEmitter<{ request: CompanySignUpRequest }> = new EventEmitter();
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private loader: LoaderService,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      companyName: new UntypedFormControl('', Validators.required),
      ogrn: new UntypedFormControl({ value: '', disabled: true }, Validators.required),
      email: new UntypedFormControl('', Validators.required),
      accept: new UntypedFormControl('', Validators.required),
      acceptAgreements: new UntypedFormControl('', Validators.required),
    });

    this.loader.getComponentLoaderState()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(state => this.loading = state);
  }

  onValueSelected(suggestion: DadataSuggestionResponse) {
    const suggestionData: DadataCompanySuggestionData = suggestion.data as DadataCompanySuggestionData;
    const companyName = suggestionData.name.short_with_opf ?? suggestionData.name.full_with_opf;

    this.form.get('companyName').setValue(companyName);
    this.form.get('ogrn').setValue(suggestionData.ogrn);
  }

  onSubmit() {
    if (!this.form.get('accept').value) {
      this.snackbar.showMessage(
        this.translate.instant('company.signup.request.form.message.accept'), 'info'
      );
      return;
    }

    if (!this.form.get('acceptAgreements').value) {
      this.snackbar.showMessage(
        this.translate.instant('company.signup.request.form.message.accept_agreements'), 'info'
      );
      return;
    }

    const request: CompanySignUpRequest = this.form.getRawValue();
    this.submitted.emit({ request });
  }

  getFormControl(controlName: string): UntypedFormControl {
    return this.form?.get(controlName) as UntypedFormControl;
  }
}
