<app-dialog-wrapper
  [title]="data.title"
  [componentName]="data.componentName"
>
  <form
    class="popup"
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    autocomplete="off"
  >
    <app-select-search
      [required]="true"
      [title]="'pbx-on-rda.create.field.uid' | translate"
      formControlName="rdaUid"
      [valuesList]="rdasList"
      [selectedValue]="selectedRda"
      [loading]="rdasLoading"
      (searchChanged)="onSearchRdas($event.value)"
      (valueSelected)="onSelectRda($event.value)"
    ></app-select-search>

    <app-select-search
      [required]="true"
      [title]="'pbx-on-rda.create.field.uid_sip' | translate"
      formControlName="sipAdapterUid"
      [valuesList]="filteredSipAdaptersList"
      [selectedValue]="selectedSipAdapter"
      [loading]="sipAdapterLoading"
      (searchChanged)="onFilterSipAdapters($event.value)"
      (valueSelected)="onSelectSipAdapter($event.value)"
    ></app-select-search>

    <div class="popup-actions">
      <button mat-raised-button
        class="popup-actions__button"
        type="submit"
        color="primary"
      >
        {{
          data.body
            ? ('pbx-on-rda.create.button.update' | translate)
            : ('pbx-on-rda.create.button.create' | translate)
        }}
      </button>
    </div>
  </form>
</app-dialog-wrapper>
