import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageGeneralKey } from '@app/shared/entities/common/local-storage';
import { Constants } from '@app/shared/helpers';
import { Attributes, IntersectionObserverHooks } from 'ng-lazyload-image';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class LazyLoadImageHooks extends IntersectionObserverHooks {
    private httpClient: HttpClient;

    constructor(httpClient: HttpClient, handler: HttpBackend) {
        super();
        this.httpClient = new HttpClient(handler);
    }

    setup(attributes: Attributes) {
        attributes.defaultImagePath = Constants.CAMERA_LOADING_IMG_PATH;
        attributes.errorImagePath = Constants.CAMERA_ERROR_IMG_PATH;
        super.setup(attributes);
    }

    loadImage(attributes: Attributes): Observable<string> {
        const token: string = localStorage.getItem(LocalStorageGeneralKey.AUTH_TOKEN);
        const headers: { Authorization: string } = { Authorization: `bearer ${token}`, };

        return this.httpClient.get(attributes.imagePath, { responseType: 'blob', headers })
            .pipe(map(blob => URL.createObjectURL(blob)));
    }

    setLoadedImage(imagePath: string, attributes: Attributes) {
        (attributes.element as HTMLImageElement).src = imagePath;
    }

    setErrorImage(error: Error, attributes: Attributes) {
        (attributes.element as HTMLImageElement).src = attributes.errorImagePath;
    }
}
