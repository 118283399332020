import { HttpErrorResponse } from '@angular/common/http';
import { PbxOnRdaQuery, PbxOnRdaRequest, PbxOnRdaResponse } from '@app/shared/entities/rd/pbx-on-rda/models';
import { Action } from '@ngrx/store';

export enum PbxOnRdaPageActionTypes {
    GetPbxOnRdaPage = '[Pbx] GetPbxOnRdaPage',
    GetPbxOnRdaPageSuccess = '[Pbx] GetPbxOnRdaPage Success',
    GetPbxOnRdaPageFailure = '[Pbx] GetPbxOnRdaPage Failure',

    CreatePbxOnRdaPage = '[Pbx] CreatePbxOnRdaPage',
    CreatePbxOnRdaPageSuccess = '[Pbx] CreatePbxOnRdaPage Success',
    CreatePbxOnRdaPageFailure = '[Pbx] CreatePbxOnRdaPage Failure',

    UpdatePbxOnRdaPage = '[Pbx] UpdatePbxOnRdaPage',
    UpdatePbxOnRdaPageSuccess = '[Pbx] UpdatePbxOnRdaPage Success',
    UpdatePbxOnRdaPageFailure = '[Pbx] UpdatePbxOnRdaPage Failure',

    DeletePbxOnRdaPage = '[Pbx] DeletePbxOnRdaPage',
    DeletePbxOnRdaPageSuccess = '[Pbx] DeletePbxOnRdaPage Success',
    DeletePbxOnRdaPageFailure = '[Pbx] DeletePbxOnRdaPage Failure'
}

export class GetPbxOnRdaPage implements Action {
    readonly type = PbxOnRdaPageActionTypes.GetPbxOnRdaPage;

    constructor(public page: number, public size: number, public query?: PbxOnRdaQuery) { }
}

export class GetPbxOnRdaPageSuccess implements Action {
    readonly type = PbxOnRdaPageActionTypes.GetPbxOnRdaPageSuccess;

    constructor(public content: PbxOnRdaResponse[], public totalElements: number) { }
}

export class GetPbxOnRdaPageFailure implements Action {
    readonly type = PbxOnRdaPageActionTypes.GetPbxOnRdaPageFailure;

    constructor(public error: HttpErrorResponse) { }
}

export class CreatePbxOnRdaPage implements Action {
    readonly type = PbxOnRdaPageActionTypes.CreatePbxOnRdaPage;

    constructor(public request: PbxOnRdaRequest) { }
}

export class CreatePbxOnRdaPageSuccess implements Action {
    readonly type = PbxOnRdaPageActionTypes.CreatePbxOnRdaPageSuccess;

    constructor(public response: PbxOnRdaResponse) { }
}

export class CreatePbxOnRdaPageFailure implements Action {
    readonly type = PbxOnRdaPageActionTypes.CreatePbxOnRdaPageFailure;

    constructor(public error: HttpErrorResponse) { }
}

export class UpdatePbxOnRdaPage implements Action {
    readonly type = PbxOnRdaPageActionTypes.UpdatePbxOnRdaPage;

    constructor(public pbxOnRdaUid: string, public request: PbxOnRdaRequest) { }
}

export class UpdatePbxOnRdaPageSuccess implements Action {
    readonly type = PbxOnRdaPageActionTypes.UpdatePbxOnRdaPageSuccess;

    constructor(public pbxOnRdaUid: string, public request: PbxOnRdaRequest) { }
}

export class UpdatePbxOnRdaPageFailure implements Action {
    readonly type = PbxOnRdaPageActionTypes.UpdatePbxOnRdaPageFailure;

    constructor(public error: HttpErrorResponse) { }
}

export class DeletePbxOnRdaPage implements Action {
    readonly type = PbxOnRdaPageActionTypes.DeletePbxOnRdaPage;

    constructor(public pbxOnRdaUid: string) { }
}

export class DeletePbxOnRdaPageSuccess implements Action {
    readonly type = PbxOnRdaPageActionTypes.DeletePbxOnRdaPageSuccess;

    constructor(public pbxOnRdaUid: string) { }
}

export class DeletePbxOnRdaPageFailure implements Action {
    readonly type = PbxOnRdaPageActionTypes.DeletePbxOnRdaPageFailure;

    constructor(public error: HttpErrorResponse) { }
}

export type PbxOnRdaPageActions =
    | GetPbxOnRdaPage
    | GetPbxOnRdaPageSuccess
    | GetPbxOnRdaPageFailure

    | CreatePbxOnRdaPage
    | CreatePbxOnRdaPageSuccess
    | CreatePbxOnRdaPageFailure

    | UpdatePbxOnRdaPage
    | UpdatePbxOnRdaPageSuccess
    | UpdatePbxOnRdaPageFailure

    | DeletePbxOnRdaPage
    | DeletePbxOnRdaPageSuccess
    | DeletePbxOnRdaPageFailure;
