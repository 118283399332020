import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {MatDialog} from '@angular/material/dialog';

import {
  ConnectionService,
  FlatApiService,
  ServiceApiService,
  ServiceBlockPhysicalTubeRequest,
  ServiceHelperService,
  ServiceResponse,
  ServicesList
} from '@app/shared/entities/rd';
import {Constants} from '@app/shared/helpers';
import {Address, ServicesTypes} from '@app/shared/models';
import {ResolutionBreakpoint, ResolutionService} from '@app/shared/services';
import {DialogWrapperData, DialogWrapperSize} from '@app/shared/ui';
import {
  ServiceBlockPhysicalTubePopupComponent,
  ServiceBlockPhysicalTubePopupResponse,
  ServiceConnectionBlockPopupBody,
  ServiceConnectionBlockPopupComponent,
  ServiceFlat,
  ServiceFlatsComponent
} from '@app/views/services/components';
import {AbonentConnectionToolStore, SnackbarService} from '@app/shared/components';
import {ServiceFacade, ServicesHelper} from '@app/views/services';
import {PhonePipe} from '@app/shared/pipes';
import {Links} from '@app/shared/helpers/wiki-links.enum';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {
  FlatFilterBottomSheetComponent
} from '@app/views/services/components/popups/flat-filter/flat-filter-bottom-sheet/flat-filter-bottom-sheet.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-software-intercom-flats',
  templateUrl: './software-intercom-flats.component.html',
  styleUrls: [
    '../../../../../../components/containers/service-flats/service-flats.component.scss'
  ],
  providers: [ConnectionService, PhonePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomFlatsComponent extends ServiceFlatsComponent {
  public ServicesTypes = ServicesTypes;
  public abonentsBillingEnabled$: Observable<boolean> = this.serviceFacade.abonentsBillingEnabled$;
  public services = [
    ServicesTypes.HARDWARE_INTERCOM,
    ServicesTypes.SOFTWARE_INTERCOM
  ];
  constructor(
    protected readonly dialog: MatDialog,
    protected readonly resolution: ResolutionService,
    protected readonly serviceFacade: ServiceFacade,
    protected readonly serviceHelperService: ServiceHelperService,
    protected readonly abonentConnectionToolStore: AbonentConnectionToolStore,
    protected readonly flatApiService: FlatApiService,
    protected readonly serviceApiService: ServiceApiService,
    protected readonly serviceHelper: ServicesHelper,
    protected readonly store: Store,
    protected readonly snackbar: SnackbarService,
    protected readonly phonePipe: PhonePipe,
    protected readonly bottomSheet: MatBottomSheet,
    protected readonly translate: TranslateService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super(
      dialog,
      resolution,
      serviceFacade,
      serviceHelperService,
      serviceHelper,
      flatApiService,
      phonePipe,
      bottomSheet,
      translate
    );
  }

  onAddVirtualFlat() {
    this.addConnection({
      addresses: this.addresses,
      serviceType: ServicesTypes.SOFTWARE_INTERCOM,
      virtualFlat: true,
      hasPhysicalTubeDefault: false,
      componentName: 'AddVirtualFlat',
      title: this.translate.instant('software.intercom.flats.add.virtual.title')
    });
  }

  onAddPhone(address?: Address, flat?: Partial<ServiceFlat>) {
    let hasPhysicalTubeDefault: boolean;
    let hasPhysicalTubeHidden: boolean;
    const existingConnectionsIds: number[] = [];

    // PHYSICAL_TUBE connected and can access to button
    if (flat?.flat?.virtual === false) {
      hasPhysicalTubeDefault = true;
    }

    // Search already connected hardware intercom for updating connection when account doesn't connected
    if (
      !flat?.account?.id &&
      flat?.services?.[ServicesTypes.HARDWARE_INTERCOM]
    ) {
      existingConnectionsIds.push(
        flat.services[ServicesTypes.HARDWARE_INTERCOM].id
      );
    }

    this.addConnection({
      flat,
      addresses: address ? [address] : this.addresses,
      componentName: `Add${this.serviceType}Phone`,
      noPhone: false,
      existingConnectionsIds,
      title: this.translate.instant('software.intercom.flats.add.phone.title'),
      hasPhysicalTubeDefault,
      hasPhysicalTubeHidden,
      responseMessage: this.translate.instant('software.intercom.flats.add.phone.message.response')
    });
  }

  onAddPhysicalTube(address: Address, flat: Partial<ServiceFlat>) {
    const existingConnectionsIds: number[] = [];
    let hasPhysicalTubeDefault: boolean = true;
    let hasPhysicalTubeHidden: boolean = true;

    // Search already connected hardware intercom for updating connection when account doesn't connected
    if (
      !flat?.account?.id &&
      flat?.services?.[ServicesTypes.HARDWARE_INTERCOM]
    ) {
      existingConnectionsIds.push(
        flat.services[ServicesTypes.HARDWARE_INTERCOM].id
      );
    }

    this.addConnection({
      flat,
      addresses: [address],
      serviceType: ServicesTypes.HARDWARE_INTERCOM,
      defaultService: {
        id: this.getHardwareIntercomId(),
        type: ServicesTypes.HARDWARE_INTERCOM
      },
      noPhone: true,
      existingConnectionsIds,
      hasPhysicalTubeDefault,
      hasPhysicalTubeHidden
    });
  }

  onAddSoftwareIntercom(address: Address, flat: Partial<ServiceFlat>) {
    const existingConnectionsIds: number[] = [];
    let hasPhysicalTubeDefault: boolean;
    let hasPhysicalTubeHidden: boolean;

    // PHYSICAL_TUBE connected and can access to button
    if (flat?.flat?.virtual === false) {
      hasPhysicalTubeDefault = true;
    }

    // Search already connected hardware intercom for updating connection when account doesn't connected
    if (
      !flat?.account?.id &&
      flat?.services?.[ServicesTypes.HARDWARE_INTERCOM]
    ) {
      existingConnectionsIds.push(
        flat.services[ServicesTypes.HARDWARE_INTERCOM].id
      );
    }

    this.addConnection({
      flat,
      addresses: [address],
      serviceType: ServicesTypes.SOFTWARE_INTERCOM,
      defaultService: { id: this.serviceId, type: this.serviceType },
      existingConnectionsIds,
      hasPhysicalTubeDefault,
      hasPhysicalTubeHidden
    });
  }

  public openFilterModal(): void {
    const data = {
      services: this.services,
      flatsFilters: this.flatsFilters
    };
    this.bottomSheet.open(FlatFilterBottomSheetComponent, { data });
  }

  async onBlockPhysicalTube(flat: ServiceFlat) {
    // event.source.checked =
    //   !flat.services?.[ServicesTypes.HARDWARE_INTERCOM]?.blocked ?? true;

    const dependantService: ServicesList = this.dependantServices.find(
      (service: ServicesList) =>
        service.type === ServicesTypes.HARDWARE_INTERCOM
    );
    const blockedService: Pick<ServiceResponse, 'id' | 'type'> =
      dependantService
        ? {
            id: dependantService.id,
            type: dependantService.type
          }
        : null;

    if (flat.services?.[ServicesTypes.HARDWARE_INTERCOM]?.blocked) {
      this.unblockPhysicalTube(flat);
      return;
    }

    await this.blockPhysicalTube(flat, blockedService);
  }

  private async blockPhysicalTube(
    flat: Partial<ServiceFlat>,
    blockedService: Pick<ServiceResponse, 'id' | 'type'>
  ) {
    this.abonentConnectionToolStore.getFlatServices({
      entranceId: flat.flat.address.entrance.id,
      flatId: flat.flat.id,
      hasPhysicalTube: blockedService.type === ServicesTypes.HARDWARE_INTERCOM
    });
    let hasHardwareIntercom = false;
    let connections = [];
    connections = await this.flatApiService
      .getConnections(flat.flat.id)
      .toPromise();

    connections.map((connection) => {
      if (
        !hasHardwareIntercom &&
        connection.service.type === ServicesTypes.HARDWARE_INTERCOM
      ) {
        hasHardwareIntercom = true;
      }
    });
    const data: DialogWrapperData<
      { phone: number },
      ServiceBlockPhysicalTubePopupResponse
    > = {
      title: this.translate.instant('software.intercom.flats.block.title'),
      componentName: `BlockPhysicalYubeConnection`,
      body: { phone: flat?.account?.owner?.phone },
      submit: async (event: ServiceBlockPhysicalTubePopupResponse) => {
        const serviceId = this.serviceHelper.getServiceIdFromParams();
        const request: ServiceBlockPhysicalTubeRequest = {
          abonent: flat?.account?.owner,
          account: event?.account,
          flat: flat.flat,
          entranceId: flat.flat.address.entrance.id,
          dependantService: blockedService,
          connection: flat?.services?.[ServicesTypes.HARDWARE_INTERCOM],
          serviceId: this.serviceId,
          companyId: this.companyId
        };

        await this.serviceFacade.blockPhysicalTube(request);
        this.serviceFacade.getService(serviceId);
      }
    };

    this.dialog.open(ServiceBlockPhysicalTubePopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? DialogWrapperSize.MAX
        : DialogWrapperSize.SM,
      data
    });
  }

  private unblockPhysicalTube(flat: Partial<ServiceFlat>) {
    const data: DialogWrapperData<ServiceConnectionBlockPopupBody, void> = {
      title: this.translate.instant('software.intercom.flats.unblock.title'),
      componentName: `Unblock${ServicesTypes.HARDWARE_INTERCOM}Connection`,
      body: {
        text: this.translate.instant('software.intercom.flats.unblock.text'),
        blocked: true
      },
      submit: async () => {
        const serviceId = this.serviceHelper.getServiceIdFromParams();
        await this.serviceFacade.unblockPhysicalTube(
          flat.services[ServicesTypes.HARDWARE_INTERCOM]
        );
        this.serviceFacade.getService(serviceId);
        this.changeDetectorRef.markForCheck();
      }
    };

    this.dialog.open(ServiceConnectionBlockPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? DialogWrapperSize.MAX
        : DialogWrapperSize.SM,
      data
    });
  }

  public get hardwareIntercomeLink(): string {
    return Links.AddHardwareIntercome;
  }

  public get softwareIntercomeLink(): string {
    return Links.SoftwareIntercom;
  }
}
