<mat-card class="counts-card">
  <mat-card-header>
    <mat-card-title>{{ title }}</mat-card-title>
  </mat-card-header>
  <mat-card-content class="counts-card-content">
    <circle-progress
      class="counts-card-content__circle"
      [percent]="percent"
      [subtitle]="'enum.state.online' | translate"
    ></circle-progress>

    <div class="counts-card-content-info">
      <div>
        <p class="counts-card-content-info__count-field">
          {{ firstValue !== null ? firstValue : '-' }}
        </p>

        <p class="counts-card-content-info__description">
          {{ firstLabel }}
        </p>
      </div>

      <ng-container *ngIf="showSecondLabel">
        <hr>

        <div>
          <p class="counts-card-content-info__count-field">
            {{ secondValue !== null ? secondValue : '-' }}
          </p>

          <p class="counts-card-content-info__description">
            {{ secondLabel }}
          </p>
        </div>
      </ng-container>
    </div>

  </mat-card-content>
</mat-card>
