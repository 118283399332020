import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared';
import { CameraThumbnailModule } from '@app/shared/containers/camera/camera-thumbnail';
import { InfoBlockModule } from '@app/shared/ui';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CamerasTableContainerComponent } from './cameras-table-container.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        SharedPipesModule,
        CameraThumbnailModule,
        NgxSkeletonLoaderModule,
        InfoBlockModule,
        NgxTranslateModule,
    ],
    declarations: [CamerasTableContainerComponent],
    exports: [CamerasTableContainerComponent]
})
export class CamerasTableContainerModule { }
