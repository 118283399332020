import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { ViewportRuler } from '@angular/cdk/overlay';
import { takeUntil } from 'rxjs/operators';

export enum AppSizeStates {
  PHONE = 1,
  TABLET_SMALL,
  TABLET,
  DESKTOP ,
}

export interface AppSizeState {
  [AppSizeStates.PHONE]: number;
  [AppSizeStates.TABLET_SMALL]: number;
  [AppSizeStates.TABLET]: number;
  [AppSizeStates.DESKTOP]: number;
}

export const DefaultAppSizeStates: AppSizeState = {
  [AppSizeStates.PHONE]: 440,
  [AppSizeStates.TABLET_SMALL]: 768,
  [AppSizeStates.TABLET]: 1024,
  [AppSizeStates.DESKTOP]: 1280,
};


@Injectable()
export class DeviceEventService {
  public appSizeState: AppSizeStates = AppSizeStates.PHONE;
  public appSizeState$ = new BehaviorSubject<AppSizeStates>(AppSizeStates.PHONE);
  public appGLoading$ = new BehaviorSubject<boolean>(false);

  public width: number;
  public height: number;

  private destroy =  new Subject<void>();
  private readonly viewportChange: Subscription;
  constructor(
    public readonly viewportRuler: ViewportRuler,
    private readonly ngZone: NgZone
  ) {
    this.setSize();
    this.viewportChange = this.viewportRuler.change()
      .pipe(takeUntil(this.destroy))
      .subscribe((data) => {
        this.ngZone.run(() => this.setSize());
      });
  }

  public unsubscribe(): void {
    this.appSizeState$.complete();
    this.appSizeState$.unsubscribe();
    this.appGLoading$.unsubscribe();
    this.destroy.complete();
    this.destroy.unsubscribe();
  }

  private setSize() {
    const { width, height } = this.viewportRuler.getViewportSize();
    this.width = width;
    this.height = height;

    if (this.width >= DefaultAppSizeStates[AppSizeStates.DESKTOP]) {
      this.appSizeState = AppSizeStates.DESKTOP;
    } else if (this.width < DefaultAppSizeStates[AppSizeStates.DESKTOP] && this.width >= DefaultAppSizeStates[AppSizeStates.TABLET]) {
      this.appSizeState = AppSizeStates.TABLET;
    } else if (this.width < DefaultAppSizeStates[AppSizeStates.TABLET] && this.width >= DefaultAppSizeStates[AppSizeStates.TABLET_SMALL]) {
      this.appSizeState = AppSizeStates.TABLET_SMALL;
    } else {
      this.appSizeState = AppSizeStates.PHONE;
    }
    this.appSizeState$.next(this.appSizeState);
  }
}
