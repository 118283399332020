import {Component, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CameraInfoContainerModule} from '@app/shared/containers';
import {PersonalSurveillance} from '@app/shared/entities/rd/services/models/personal-surveillance.model';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import {ResolutionBreakpoint, ResolutionService} from '@app/shared/services';

@Component({
  selector: 'app-apartment-video-camera-info',
  standalone: true,
  imports: [CommonModule, CameraInfoContainerModule],
  templateUrl: './apartment-video-camera-info.component.html',
  styleUrls: ['./apartment-video-camera-info.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ApartmentVideoCameraInfoComponent {
  public get cameraInfo(): PersonalSurveillance.CameraFull {
    return this.dynamicDialogConfig?.data?.cameraInfo;
  }

  constructor(
    private dynamicDialogConfig: DynamicDialogConfig,
    private resolutionService: ResolutionService,
  ) {
    this.dynamicDialogConfig.styleClass = 'apartment-video-camera-info__modal';
    if (this.resolutionService.getBreakpointState(ResolutionBreakpoint.SM_W_DOWN)) {
      this.dynamicDialogConfig.height = '100%';
      this.dynamicDialogConfig.width = '100%';
      this.dynamicDialogConfig.styleClass = `${this.dynamicDialogConfig.styleClass} apartment-video-camera-info__modal--full-screen`;
    }
  }
}
