import { Injectable, OnDestroy } from '@angular/core';
import { ActionTypes, SendAbonentPageMessage, SendAbonentPageMessageSuccess } from '@app/views/abonents/store/actions/abonent-page.actions';
import { Actions, ofType } from '@ngrx/effects';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class AbonentChatSerivice implements OnDestroy {
    private onDestroy$: Subject<void> = new Subject();
    private messageSended: Subject<boolean> = new Subject();

    constructor(
        private actions$: Actions
    ) {
        this.enableStateListeners();
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    addSendMessageListener(cb: () => void) {
        this.messageSended
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((messageSended: boolean) => messageSended && cb());
    }

    private enableStateListeners() {
        this.actions$
            .pipe(
                ofType<SendAbonentPageMessage>(ActionTypes.SendAbonentPageMessage), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.messageSended.next(false));

        this.actions$
            .pipe(
                ofType<SendAbonentPageMessageSuccess>(ActionTypes.SendAbonentPageMessageSuccess), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.messageSended.next(true));
    }
}
