<div class="apartment-video-surveillance-page__wrapper">
  <p-card class="apartment-video-surveillance-page__card">
    <div class="apartment-video-surveillance-page__content__wrapper">
      <div class="apartment-video-surveillance-page__content__table__wrapper">
        <app-pagination-data-view [data]="(tableData | async) | apartmentVideoGridConverter"
                                  [loading]="waitTableData$" [config]="paginationDataViewConfig"
                                  (output)="viewerOutput($event)">
          <div header-actions class="content__header__action__wrapper">
            <p-button icon="pi pi-plus" [label]="'apartments_video_surveillance.page.add' | translate"
                      styleClass="p-button-outlined" (onClick)="onAddAction()"></p-button>
          </div>
        </app-pagination-data-view>
      </div>
    </div>
  </p-card>
</div>
