import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { GitlabComponentsVersions } from '@app/shared/entities/integrations';
import {
  ActiveHistoryResponse,
  Camera,
  Company,
  KeysResponse,
  ProtocolTypes,
  RdaResponse,
  ServiceResponse
} from '@app/shared/entities/rd';
import { Constants, Dictionary } from '@app/shared/helpers';
import {
  Address,
  LocationResponse,
  LogsComponentType,
  LogsResponse,
  ServicesTypes
} from '@app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData, DialogWrapperSize } from '@app/shared/ui';
import {
  ServiceEntrancesFlats,
  ServiceFlatsFilters
} from '@app/views/services/components';
import {
  ServiceActivitySource,
  ServicePageMode
} from '@app/views/services/models';
import {
  ServicePageListeners,
  ServicesHelper
} from '@app/views/services/services';
import { ServiceFacade } from '@app/views/services/store';
import { SoftwareIntercomWizardPopupComponent } from './popups';
import { UserRoleType } from '@app/core';
import { TokenService } from '@app/security';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-software-intercom-page',
  templateUrl: './software-intercom-page.component.html',
  styleUrls: ['./software-intercom-page.component.scss'],
  providers: [ServicesHelper, ServicePageListeners],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomPageComponent implements OnInit, OnDestroy {
  readonly pageMode = ServicePageMode;
  readonly serviceType: ServicesTypes = ServicesTypes.SOFTWARE_INTERCOM;

  pageMode$: Observable<ServicePageMode> = this.serviceFacade.pageMode$;

  // Service general data
  serviceId$: Observable<number> = this.serviceFacade.serviceId$;
  serviceName$: Observable<string> = this.serviceFacade.serviceName$;
  serviceTariff$: Observable<number> = this.serviceFacade.serviceTariff$;
  serviceCustomName$: Observable<string> = this.serviceFacade.serviceCustomName$;
  dependantServices$: Observable<Pick<ServiceResponse, 'id' | 'type'>[]> = this.serviceFacade.dependantServices$;
  entrances$: Observable<Address[]> = this.serviceFacade.entrances$;
  rdas$: Observable<RdaResponse[]> = this.serviceFacade.rdas$;
  cameras$: Observable<Camera[]> = this.prepareCameras();
  keys$: Observable<KeysResponse[]> = this.serviceFacade.keys$;

  // Service additional data
  keysLocations$: Observable<LocationResponse[]> = this.serviceFacade.keysLocations$;
  componentsVersions$: Observable<GitlabComponentsVersions> = this.serviceFacade.componentsVersions$;
  company$: Observable<Company> = this.serviceFacade.company$;
  abonentsLoading$: Observable<boolean> = this.serviceFacade.abonentsLoading$;

  // Flats data
  flats$: Observable<ServiceEntrancesFlats> = this.serviceFacade.filteredFlats$;
  flatsFilters$: Observable<ServiceFlatsFilters> = this.serviceFacade.flatsFilters$;

  // Service activity
  logs$: Observable<LogsResponse[]> = this.serviceFacade.logsData$;
  logsLoading$: Observable<boolean> = this.serviceFacade.logsLoading$;
  blocksCount$: Observable<number> = this.serviceFacade.blocksCount$;
  sources$: Observable<ServiceActivitySource[]> = this.serviceFacade.sources$;
  timeRange$: Observable<number> = this.serviceFacade.timeRange$;
  extendedMode$: Observable<boolean> = this.serviceFacade.extendedMode$;
  totalLogsCount$: Observable<number> = this.serviceFacade.totalLogsCount$;
  currentLogsCount$: Observable<number> = this.serviceFacade.currentLogsCount$;
  logsComponentType$: Observable<LogsComponentType> = this.serviceFacade.logsComponentType$;
  selectedLogsSource$: Observable<ServiceActivitySource> = this.serviceFacade.selectedLogsSource$;
  activeHistoryCameras$: Observable<Dictionary<ActiveHistoryResponse[]>> = this.serviceFacade.activeHistoryCameras$;
  activeHistoryIntercoms$: Observable<Dictionary<ActiveHistoryResponse[]>> = this.serviceFacade.activeHistoryIntercoms$;

  public maxKeyCount = 4;

  constructor(
    private dialog: MatDialog,
    private serviceFacade: ServiceFacade,
    private serviceHelper: ServicesHelper,
    private pageListeners: ServicePageListeners,
    private changeDetectorRef: ChangeDetectorRef,
    private resolution: ResolutionService,
    private tokenService: TokenService,
    private translate: TranslateService
  ) { }

  async ngOnInit() {
    await this.initializeComponent();
  }

  ngOnDestroy() {
    this.serviceFacade.clearService();
  }

  get haveUserSupportAccess(): boolean {
    return this.tokenService.getAuthorities().includes(UserRoleType.ROLE_SUPPORT);
  }

  private async initializeComponent() {
    const serviceId: number = this.serviceHelper.getServiceIdFromParams();
    await Promise.all([
      this.serviceFacade.getService(serviceId),
      this.serviceFacade.getKeysLocations(),
      this.serviceFacade.getCamerasLocations(),
      this.serviceFacade.getIntercomTypes(),
      this.serviceFacade.getComponentVersions()
    ]);
    this.rdas$.subscribe((rdas) => {
      let metacomRda = null;
      if (rdas?.length) {
        metacomRda = rdas?.find(
          (rda) =>
            rda?.intercomType?.protocol?.number ===
            ProtocolTypes?.MetacomWithManyPanels ||
            rda?.intercomType?.protocol?.number === ProtocolTypes?.Metacom
        );
      }
      if (!!metacomRda) {
        this.maxKeyCount = 32;
      }
    });
    this.enableEmptyServiceListener();

    this.dependantServices$
      .pipe(
        filter(dependantServices => !!dependantServices)
      )
      .subscribe((dependantServices) => {
        dependantServices
          .filter(dependantService => dependantService.type === ServicesTypes.HARDWARE_INTERCOM)
          .forEach(dependantService => {
            this.serviceFacade.getDependService(dependantService.id);
          });
      })
  }

  onChangePageMode(pageMode: ServicePageMode) {
    console.log(pageMode);
    this.serviceFacade.setPageMode(pageMode);
  }

  onOpenWizard() {
    const data: DialogWrapperData<null, null> = {
      title: this.translate.instant('software.intercom.wizard.title'),
      componentName: 'ConfigSoftwareIntercomService'
    };

    this.dialog.open(SoftwareIntercomWizardPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      autoFocus: false,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? DialogWrapperSize.MAX
        : DialogWrapperSize.MD,
      data
    });
  }

  onManageClose() {
    const serviceId: number = this.serviceHelper.getServiceIdFromParams();
    this.serviceFacade.getService(serviceId);
  }

  private prepareCameras() {
    return combineLatest([
      this.serviceFacade.cameras$,
      this.serviceFacade.intercomPanelsLocations$
    ]).pipe(
      map(
        ([cameras, intercomPanelsLocations]: [
          Camera[],
          LocationResponse[]
        ]) => {
          if (!cameras) {
            return null;
          }

          return cameras.map((camera: Camera) => {
            const intercomPanelLocationId: number =
              intercomPanelsLocations.findIndex(
                (intercomPanelLocation) =>
                  intercomPanelLocation.id === camera.location?.id
              );

            if (intercomPanelLocationId !== -1) {
              camera.location.name = `${intercomPanelsLocations[intercomPanelLocationId].name} `
                + this.translate.instant('software.intercom.calling_panel_has_been_installed');
            }

            return camera;
          });
        }
      )
    );
  }

  private enableEmptyServiceListener() {
    this.pageListeners.addServiceEmptyListener(() => {
      this.serviceFacade.setPageMode(ServicePageMode.SETTINGS);
      this.changeDetectorRef.markForCheck();
      this.onOpenWizard();
    });
  }
}
