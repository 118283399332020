import { HttpBackend, HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { PagedResponse } from '@app/shared/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { AddRdasToOrderRequest, AddScansToOrderRequest, CreateOrderRequest, OrderResponse, UpdateOrderRequest } from '../models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class OrdersService extends ApiService {
    constructor(httpClient: HttpClient, handler: HttpBackend, humanErrorTranslationService: HumanErrorTranslationService) {
        super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1']);
    }

    public getOrdersPage(page: number, size: number, withoutManufacturer?: boolean, search?: string): Observable<PagedResponse<OrderResponse>> {
        const params = { page: page, size: size } as any;
        if (search) { params.search = search; }
        if (withoutManufacturer) { params.withoutManufacturer = withoutManufacturer; }

        return this.get(`/orders`, 1, { params });
    }

    public createOrder(request: CreateOrderRequest): Observable<OrderResponse> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');


        return this.post('/orders', 1, request, { headers });
    }

    public updateOrder(orderId: number, updateOrderRequest: UpdateOrderRequest): Observable<void> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.patch(`/orders/${orderId}`, 1, updateOrderRequest, { headers });
    }

    public deleteOrder(orderId: number): Observable<void> {
        return this.delete(`/orders/${orderId}`, 1);
    }

    public addRdas(orderId: number, addRdasToOrderRequest: AddRdasToOrderRequest): Observable<void> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.post(`/orders/${orderId}/rdas?rdaIds=${addRdasToOrderRequest.rdaIds.join(',')}`, 1, null, { headers });
    }

    public addScans(orderId: number, addScansToOrderRequest: AddScansToOrderRequest): Observable<any> {
        const formData = new FormData();
        for (let i = 0; i < addScansToOrderRequest.orderScans.length; ++i) {
            formData.append(`files`, addScansToOrderRequest.orderScans[i]);
        }
        return this.sendFiles(`/orders/${orderId}/scans`, 1, formData);
    }
}
