import { YandexCountryCoordinate } from '../models';

export class YandexHelper {
    static readonly capitalsCoords: YandexCountryCoordinate = {
        RU: [55.45, 37.37],
        KZ: [51.18, 71.44],
        UA: [50.45, 30.52],
        BY: [53.9, 27.56]
    };
}
