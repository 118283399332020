<div class="filters">
  <div class="filters-flats">
    <span>
      {{ 'service.flats.filters.field.flat' | translate }}
    </span>
    <input
      class="filters-input"
      matInput
      type="number"
      placeholder="{{ 'service.flats.filters.placeholder.from' | translate }}"
      [value]="flatsFilters.rangeStart?.toString()"
      (input)="onSetRangeStartFilter($event.target.value)" />
    <input
      class="filters-input"
      matInput
      type="number"
      placeholder="{{ 'service.flats.filters.placeholder.to' | translate }}"
      [value]="flatsFilters.rangeEnd?.toString()"
      (input)="onSetRangeEndFilter($event.target.value)" />
  </div>
  <div class="filters-phone">
    <span>
      {{ 'service.flats.filters.field.phone' | translate }}
    </span>
    <input
      class="filters-input"
      [disabled]="this.flatsFilters.emptyFlat"
      [value]="flatsFilters.phone"
      (input)="onSetAbonentPhoneFilter($event.target.value)"
      matInput
      type="text"
      placeholder="{{ 'service.flats.filters.placeholder.phone' | translate }}"
    />
    <button
      class="filters-button"
      [class.active]="flatsFilters.emptyFlat"
      (click)="onSetEmptyFlatFilter()"
    >
      {{ 'service.flats.filters.button.empty' | translate }}
    </button>
  </div>
  <div class="services">
    <ng-container *ngFor="let service of services">
      <app-service-filter-item
        [serviceType]="service"
        [flatsFilters]="flatsFilters"
        (filterByActiveServiceType)="onFilterByActiveServiceType($event)"
        (filterByStoppedServiceType)="onFilterByStoppedServiceType($event)"
        (filterByBlockedServiceType)="
          onFilterByBlockedServiceType($event)
        "></app-service-filter-item>
    </ng-container>
  </div>

  <div class="filters-status">
    <span>
      {{ 'service.flats.filters.field.status' | translate }}
    </span>
    <button
      class="filters-button"
      [class.active]="flatsFilters.blockedAbonent"
      (click)="onSetBlockedStatusFilter()">
      {{ 'service.flats.filters.button.blocked' | translate }}
    </button>
    <button
      class="filters-button"
      [class.active]="flatsFilters.activeAbonent"
      (click)="onSetActiveStatusFilter()">
      {{ 'service.flats.filters.button.active' | translate }}
    </button>
  </div>
</div>
