import {Injectable} from '@angular/core';
import {AddressInfo} from '@app/shared/models';
import {Observable, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class YandexApiService {
  private _addressFromMap: Subject<string> = new Subject<string>();
  private translate: TranslateService;

  constructor(
    translate: TranslateService
  ) {
    this.translate = translate;
  }

  getAddressInfo(address: { kind: string, name: string }[]): { addressInfo: AddressInfo, entrance: string } {
    const addressInfo: AddressInfo = {
      country: null,
      city: null,
      street: null,
      house: null,
      building: null,
      housing: null,
      block: null,
      fiasCode: null,
      kladrCode: null,
      universalCode: null
    };

    const cityT = this.translate.instant('shared.entities.integrations.yandex.city');
    const streetT = this.translate.instant('shared.entities.integrations.yandex.street');
    const entranceT = this.translate.instant('shared.entities.integrations.yandex.entrance');

    const city = address.find(element => element.kind === 'locality');
    if (city) {
      city.name = city.name.toLocaleLowerCase()
        .replace(' ' + cityT, '')
        .replace(cityT + ' ', '')
      ;
      city.name = city.name.charAt(0).toUpperCase() + city.name.slice(1);
      addressInfo.city = city.name;
    }

    const house = address.find(element => element.kind === 'house');
    if (house) {
      const streetAndHousing = house.name
        .split(this.translate.instant('shared.entities.integrations.yandex.short.housing'));
      const streetAndBuilding = house.name
        .split(this.translate.instant('shared.entities.integrations.yandex.short.building'));

      if (streetAndHousing.length > 1) {
        addressInfo.house = streetAndHousing[0];
        addressInfo.housing = streetAndHousing[1];
      } else if (streetAndBuilding.length > 1) {
        addressInfo.house = streetAndBuilding[0];
        addressInfo.building = streetAndBuilding[1];
      } else {
        addressInfo.house = house.name;
      }
    } else {
      addressInfo.house = null;
      addressInfo.building = null;
      addressInfo.housing = null;
      addressInfo.block = null;
    }

    const districts = address.filter(element => ['district', 'street'].includes(element.kind));
    if (districts.length > 0) {
      let street = districts[districts.length - 1].name;
      street = street.toLocaleLowerCase()
        .replace(' ' + streetT, '')
        .replace(streetT + ' ', '')
      ;
      street = street.charAt(0).toUpperCase() + street.slice(1);
      addressInfo.street = street;
    }

    const entrance = address.find(element => element.kind === 'entrance');
    if (entrance) {
      entrance.name = entrance.name.toLocaleLowerCase()
        .replace(' ' + entranceT, '')
        .replace(entranceT + ' ', '')
      ;
      entrance.name = entrance.name.charAt(0).toUpperCase() + entrance.name.slice(1);
    }

    if (!addressInfo.city && addressInfo.street && addressInfo.house) {
      const area = address.find(element => element.kind === 'area');
      if (area) {
        area.name = area.name.toLocaleLowerCase()
          .replace(' ' + cityT, '')
          .replace(cityT + ' ', '')
        ;
        area.name = area.name.charAt(0).toUpperCase() + area.name.slice(1);
        addressInfo.city = area.name;
      }
    }

    return { addressInfo, entrance: entrance?.name };
  }

  setAddressFromMap(value: string) {
    this._addressFromMap.next(value);
  }

  getAddressFromMap(): Observable<string> {
    return this._addressFromMap.asObservable();
  }
}


