import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CameraInfoContainerModule } from '@app/shared/containers/camera/camera-info-container';
import { DialogWrapperModule } from '@app/shared/ui';
import { CameraInfoPopupComponent } from './camera-info-popup.component';

@NgModule({
    imports: [
        CommonModule,
        DialogWrapperModule,
        CameraInfoContainerModule
    ],
    declarations: [CameraInfoPopupComponent],
    exports: [CameraInfoPopupComponent]
})
export class CameraInfoPopupModule { }
