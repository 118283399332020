import { ServiceInfoResponse } from '@app/shared/entities/rd';
import { ServiceConnectionWithType } from './service-connection-with-type.type';

export interface ServiceBlockRequest {
    entranceId: number;
    flatNumber: number;
    flatId: number;
    accountId: number;
    service: Pick<ServiceInfoResponse, 'id' | 'type'>;
    connection: ServiceConnectionWithType;
}
