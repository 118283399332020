import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyReportRequest } from '@app/shared/entities/rd';
import { DialogWrapperService } from '@app/shared/ui';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper';

@Component({
  selector: 'app-company-report-popup',
  templateUrl: './company-report-popup.component.html',
  styleUrls: ['./company-report-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyReportPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogWrapperData<{ startReportDateEnabled?: boolean }, CompanyReportRequest>,
    public dialogWrapperService: DialogWrapperService
  ) { }

  onSubmit(request: CompanyReportRequest) {
    this.data.submit(request);
  }
}
