import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { ChangeVirtualStatePopupModule } from '@app/shared/components';
import { SharedDirectivesModule } from '@app/shared/directives';
import { ServiceHelperService } from '@app/shared/entities/rd';
import { SharedPipesModule } from '@app/shared/pipes';
import { InfoBlockModule } from '@app/shared/ui';
import {
  ServiceAbonentsFromEntrancesPopupModule,
  ServiceConnectionBlockPopupModule,
  ServiceConnectionPopupModule
} from '@app/views/services/components/popups';
import {
  ServiceAbonentsDisconnectPopupModule,
  ServiceConnectionDeletePopupModule,
  ServiceDelegationPopupModule,
  ServiceFlatDeletePopupModule
} from '../../popups/connections';
import { ServiceFlatsFiltersModule } from '../service-flats-filters';
import { ServiceFlatsComponent } from './service-flats.component';
import { ServiceFlatsHelperService } from './services-flats-helper.service';
import { ServiceTariffPopupModule } from '@app/views/services/components/popups/connections/service-tariff-popup';
import { FlatAbonentsPopupModule } from '@app/views/services/components/popups/abonents/flat-abonents-popup/flat-abonents-popup.module';
import { FlatFilterPopupModule } from '../../popups/flat-filter/flat-filter-popup.component/flat-filter-popup.module';
import { FlatFilterBottomSheetModule } from '../../popups/flat-filter/flat-filter-bottom-sheet/flat-filter-bottom-sheet.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule,
    InfoBlockModule,
    SharedPipesModule,
    SharedDirectivesModule,
    ServiceFlatsFiltersModule,
    ServiceConnectionPopupModule,
    ChangeVirtualStatePopupModule,
    ServiceConnectionBlockPopupModule,
    ServiceConnectionDeletePopupModule,
    ServiceAbonentsFromEntrancesPopupModule,
    ServiceFlatDeletePopupModule,
    ServiceAbonentsDisconnectPopupModule,
    ServiceDelegationPopupModule,
    ServiceTariffPopupModule,
    FlatAbonentsPopupModule,
    FlatFilterPopupModule,
    FlatFilterBottomSheetModule
  ],
  declarations: [ServiceFlatsComponent],
  providers: [
    { provide: ServiceHelperService, useClass: ServiceFlatsHelperService }
  ],
  exports: [ServiceFlatsComponent]
})
export class ServiceFlatsModule {}
