import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { IntercomEditFilter } from '@app/shared/components';
import { IntercomType, RdaResponse } from '@app/shared/entities/rd';
import { GateWizardUpdateStep } from '@app/views';
import { ServiceWizardPopupContentComponent } from '@app/views/services/components';
import { GateWizardPopupStep } from '../models';
import { GateMode } from './../../../../../models';
import { GateWizardPopupStore } from './../store';
import {Observable, Subscription} from 'rxjs';
import {ServiceFacade} from '@app/views/services';

@Component({
  selector: 'app-gate-wizard-content',
  templateUrl: './gate-wizard-content.component.html',
  styleUrls: ['./gate-wizard-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GateWizardContentComponent extends ServiceWizardPopupContentComponent implements OnDestroy {
  readonly step: typeof GateWizardPopupStep = GateWizardPopupStep;
  readonly gateMode: typeof GateMode = GateMode;
  readonly intercomEditFilter: typeof IntercomEditFilter = IntercomEditFilter;
  public abonentsBillingEnabled$: Observable<boolean> = this.serviceFacade.abonentsBillingEnabled$;

  @Input() index: string;
  @Input() mode: GateMode;
  @Input() selectedIntercom: RdaResponse;
  @Input() entrancesIntercoms: RdaResponse[];
  @Input() selectedIntercomType: IntercomType;
  private abonentsBillingEnabledSubscription: Subscription;

  @Input() set addressStepState(stepState: GateWizardUpdateStep) {
    this.popupStore.updateStepState({ step: GateWizardPopupStep.ADDRESSES, stepState });
  }
  @Input() set intercomTypeStepState(stepState: GateWizardUpdateStep) {
    // WARN: change detector don't detect changes after updateStepState running
    setTimeout(() =>
      this.popupStore.updateStepState({ step: GateWizardPopupStep.INTERCOM_TYPE, stepState }), 0
    );
  }
  @Input() set intercomsStepState(stepState: GateWizardUpdateStep) {
    this.popupStore.updateStepState({ step: GateWizardPopupStep.INTERCOMS, stepState });
  }
  @Input() set camerasStepState(stepState: GateWizardUpdateStep) {
    this.popupStore.updateStepState({ step: GateWizardPopupStep.CAMERA, stepState });
  }
  @Input() set tariffStepState(stepState: GateWizardUpdateStep) {
    this.abonentsBillingEnabledSubscription = this.abonentsBillingEnabled$.subscribe(abonentsBillingEnabled => {
      this.popupStore.updateStepState({ step: GateWizardPopupStep.TARIFF, stepState: {...stepState, hidden: !abonentsBillingEnabled} });
    });
  }
  @Input() set checklistStepState(stepState: GateWizardUpdateStep) {
    this.popupStore.updateStepState({ step: GateWizardPopupStep.CHECKLIST, stepState });
  }
  @Input() set initialWizardStep(initialWizardStep: GateWizardPopupStep) {
    if (this.wizardStep === undefined || this.wizardStep === null) {
      // WARN: change detector don't detect changes after updateStepState running
      setTimeout(() => this.popupStore.selectStep(initialWizardStep), 0);
    }
  }

  @Input() avaliableIntercoms: RdaResponse[];

  constructor(
    private popupStore: GateWizardPopupStore,
    private serviceFacade: ServiceFacade
  ) {
    super();
  }

  ngOnDestroy() {
    this.abonentsBillingEnabledSubscription.unsubscribe();
  }

  allIntercomsIsIp(): boolean {
    return this.intercoms?.reduce((prev, cur) => prev && cur?.intercomType?.protocol?.ipType, true);
  }

  onNextStep() {
    const wizardStep: GateWizardPopupStep = this.getNextStep(GateWizardPopupStep.CHECKLIST);

    if (wizardStep === null) {
      return;
    }

    setTimeout(() => this.popupStore.selectStep(wizardStep), 0);
  }

  onPreviousStep() {
    const wizardStep: GateWizardPopupStep = this.getPreviousStep();

    if (wizardStep === null) {
      return;
    }

    setTimeout(() => this.popupStore.selectStep(wizardStep), 0);
  }

  onChangeStep(stepIdx: number) {
    setTimeout(() => {
      this.popupStore.selectStep(stepIdx);
      this.popupStore.changeStepButtonsVisible(true);
      this.popupStore.changeMenuVisible(true);
    }, 0);
  }

  onChangeControlsVisible(showMenu: boolean, showStepButtons: boolean) {
    if (showStepButtons !== null && showStepButtons !== undefined) {
      this.popupStore.changeStepButtonsVisible(showStepButtons);
    }

    if (showMenu !== null && showMenu !== undefined) {
      this.popupStore.changeMenuVisible(showMenu);
    }
  }
}
