import { Account } from '@app/shared/entities/rd/account/models';
import { GetDelegationResponse } from '@app/shared/entities/rd/delegations/models';
import { AbonentStat } from '@app/shared/entities/rd/monitoring/models';
import { Constants } from '@app/shared/helpers';
import { Abonent, SignUpResponse, SignUpStatus } from '@app/shared/models';
import { AccountServiceData, Chat, VoipTokenInfo } from '@app/views/abonents/models';
import { Actions, ActionTypes } from '../actions/abonent-page.actions';

export interface AbonentPageState {
    abonent: Abonent;
    stat: AbonentStat;
    voipTokens: VoipTokenInfo[];
    selectedVoipToken: VoipTokenInfo;
    selectedDateDiff: number;

    delegations?: GetDelegationResponse[];

    signUps?: SignUpResponse[];
    lastUpdatedStatus: SignUpStatus;

    accounts?: Account[];
    accountServices: AccountServiceData[];

    chat: Chat;
    chatLoading: boolean;
    sendMessageLoading: boolean;
}

export const abonentPageInitialState: AbonentPageState = {
    abonent: null,
    stat: null,
    voipTokens: [],
    selectedVoipToken: null,
    selectedDateDiff: Constants.dateDiffs[0].value,

    delegations: null,

    signUps: [],
    lastUpdatedStatus: null,

    accounts: [],
    accountServices: [],

    chat: null,
    chatLoading: false,
    sendMessageLoading: false
};

export function abonentPageReducer(state = abonentPageInitialState, action: Actions): AbonentPageState {
    switch (action.type) {
        case ActionTypes.GetAbonentPageInfo: {
            return {
                ...state,
                abonent: null
            };
        }

        case ActionTypes.GetAbonentPageInfoSuccess: {
            return {
                ...state,
                abonent: action.response
            };
        }

        case ActionTypes.GetAbonentPageInfoFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.GetAbonentPageVoipTokens: {
            return {
                ...state,
                voipTokens: [],
                selectedVoipToken: null,
                stat: null
            };
        }

        case ActionTypes.GetAbonentPageVoipTokensSuccess: {
            return {
                ...state,
                voipTokens: action.response
            };
        }

        case ActionTypes.GetAbonentPageVoipTokensFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.ChangeVoipToken: {
            return {
                ...state,
                selectedVoipToken: action.voipToken
            };
        }

        case ActionTypes.ChangeDateDiff: {
            return {
                ...state,
                selectedDateDiff: action.dateDiff
            };
        }

        case ActionTypes.GetAbonentPageStat: {
            return {
                ...state,
                stat: null
            };
        }

        case ActionTypes.GetAbonentPageStatSuccess: {
            return {
                ...state,
                stat: action.response
            };
        }

        case ActionTypes.GetAbonentPageStatFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.UpdateAbonentPageInfo: {
            return {
                ...state
            };
        }

        case ActionTypes.UpdateAbonentPageInfoSuccess: {
            state.abonent.resolved = action.request.resolved;

            return {
                ...state
            };
        }

        case ActionTypes.UpdateAbonentPageInfoFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.GetAbonentPageMessages: {
            return {
                ...state,
                chat: null,
                chatLoading: true,
            };
        }

        case ActionTypes.GetAbonentPageMessagesSuccess: {
            action.chat.content = action.chat.content.reverse();

            return {
                ...state,
                chat: action.chat,
                chatLoading: false
            };
        }

        case ActionTypes.GetAbonentPageMessagesFailure: {
            return {
                ...state,
                chatLoading: false
            };
        }

        case ActionTypes.LoadAbonentPageMessages: {
            return {
                ...state,
                chatLoading: true,
            };
        }

        case ActionTypes.LoadAbonentPageMessagesSuccess: {
            state.chat.content = action.chat.content.reverse().concat(state.chat.content);
            state.chat.number++;

            return {
                ...state,
                chatLoading: false
            };
        }

        case ActionTypes.LoadAbonentPageMessagesFailure: {
            return {
                ...state,
                chatLoading: false
            };
        }

        case ActionTypes.SendAbonentPageMessage: {
            return {
                ...state,
                sendMessageLoading: true
            };
        }

        case ActionTypes.SendAbonentPageMessageSuccess: {
            state.chat.content.push(action.response);

            return {
                ...state,
                sendMessageLoading: false
            };
        }

        case ActionTypes.SendAbonentPageMessageFailure: {
            return {
                ...state,
                sendMessageLoading: false
            };
        }

        case ActionTypes.GetAbonentPageOwnerDelegations: {
            return {
                ...state,
                delegations: []
            };
        }

        case ActionTypes.GetAbonentPageOwnerDelegationsSuccess: {
            state.delegations = action.response;

            return {
                ...state
            };
        }

        case ActionTypes.GetAbonentPageOwnerDelegationsFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.GetAbonentPageSharedDelegations: {
            return {
                ...state,
                delegations: [],
                signUps: [],
                accounts: [],
                accountServices: []
            };
        }

        case ActionTypes.GetAbonentPageSharedDelegationsSuccess: {
            state.delegations = action.response;

            return {
                ...state
            };
        }

        case ActionTypes.GetAbonentPageSharedDelegationsFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.DeleteAbonentPageDelegation: {
            return {
                ...state
            };
        }

        case ActionTypes.DeleteAbonentPageDelegationSuccess: {
            const delegationIdx = state.delegations.findIndex(delegation => delegation.id === action.delegationId);
            state.delegations.splice(delegationIdx, 1);

            return {
                ...state
            };
        }

        case ActionTypes.DeleteAbonentPageDelegationFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.GetAbonentPageSignUps: {
            return {
                ...state,
                signUps: []
            };
        }

        case ActionTypes.GetAbonentPageSignUpsSuccess: {
            state.signUps = action.response;
            return {
                ...state
            };
        }

        case ActionTypes.GetAbonentPageSignUpsFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.GetAbonentPageAccounts: {
            return {
                ...state,
                accounts: []
            };
        }

        case ActionTypes.GetAbonentPageAccountsSuccess: {
            state.accounts = action.response;

            return {
                ...state
            };
        }

        case ActionTypes.GetAbonentPageAccountsFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.UpdateAbonentPageAccount: {
            return {
                ...state
            };
        }

        case ActionTypes.UpdateAbonentPageAccountSuccess: {
            const accountIdx = state.accounts.findIndex(account => account.id === action.accountId);
            state.accounts[accountIdx].number = action.accountNumber;

            return {
                ...state
            };
        }

        case ActionTypes.UpdateAbonentPageAccountFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.DeleteAbonentPageAccount: {
            return {
                ...state
            };
        }

        case ActionTypes.DeleteAbonentPageAccountSuccess: {
            const accountIdx = state.accounts.findIndex(account => account.id === action.accountId);
            state.accounts.splice(accountIdx, 1);

            return {
                ...state
            };
        }

        case ActionTypes.DeleteAbonentPageAccountFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.BlockAbonentPageAccount: {
            return {
                ...state
            };
        }

        case ActionTypes.BlockAbonentPageAccountSuccess: {
            const accountIdx = state.accounts.findIndex(account => account.id === action.accountId);
            state.accounts[accountIdx].blocked = true;

            return {
                ...state
            };
        }

        case ActionTypes.BlockAbonentPageAccountFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.UnblockAbonentPageAccount: {
            return {
                ...state
            };
        }

        case ActionTypes.UnblockAbonentPageAccountSuccess: {
            const accountIdx = state.accounts.findIndex(account => account.id === action.accountId);
            state.accounts[accountIdx].blocked = false;

            return {
                ...state
            };
        }

        case ActionTypes.UnblockAbonentPageAccountFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.GetAbonentPageAccountServices: {
            return {
                ...state,
                accountServices: []
            };
        }

        case ActionTypes.GetAbonentPageAccountServicesSuccess: {
            state.accountServices = action.response;

            return {
                ...state
            };
        }

        case ActionTypes.GetAbonentPageAccountServicesFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.DisconnectAbonentPageService: {
            return {
                ...state
            };
        }

        case ActionTypes.DisconnectAbonentPageServiceSuccess: {
            const serviceIdx = state.accountServices.findIndex(service =>
                service.id === action.serviceId && (!action.flatId || action.flatId === service.flat.id)
            );
            state.accountServices.splice(serviceIdx, 1);

            return {
                ...state
            };
        }

        case ActionTypes.DisconnectAbonentPageServiceFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.BlockAbonentPageService: {
            return {
                ...state
            };
        }

        case ActionTypes.BlockAbonentPageServiceSuccess: {
            const serviceIdx = state.accountServices.findIndex(service =>
                service.id === action.serviceId && (!action.flatId || action.flatId === service.flat.id)
            );
            state.accountServices[serviceIdx].isBlocked = true;

            return {
                ...state
            };
        }

        case ActionTypes.BlockAbonentPageServiceFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.UnblockAbonentPageService: {
            return {
                ...state
            };
        }

        case ActionTypes.UnblockAbonentPageServiceSuccess: {
            const serviceIdx = state.accountServices.findIndex(service =>
                service.id === action.serviceId && (!action.flatId || action.flatId === service.flat.id)
            );
            state.accountServices[serviceIdx].isBlocked = false;

            return {
                ...state
            };
        }

        case ActionTypes.UnblockAbonentPageServiceFailure: {
            return {
                ...state
            };
        }

        case ActionTypes.UpdateAbonentPageSignUp: {
            return {
                ...state,
                lastUpdatedStatus: null
            };
        }

        case ActionTypes.UpdateAbonentPageSignUpSuccess: {
            const signUpIdx = state.signUps.findIndex(signUp => signUp.signUpId === action.id);
            state.signUps[signUpIdx].isVirtual = action.request.isVirtual;
            state.signUps[signUpIdx].status = action.request.status;

            return {
                ...state,
                lastUpdatedStatus: action.request.status
            };
        }

        case ActionTypes.UpdateAbonentPageSignUpFailure: {
            return {
                ...state,
                lastUpdatedStatus: null
            };
        }

        default: {
            return state;
        }
    }
}
