import { EntrancePageResponse, SelectSearch } from '@app/shared/models';

export interface AddressSearchSelectState {
    loading: boolean;
    addresses: { value: EntrancePageResponse; select: SelectSearch }[];
    selectedAddressIdx: number;
}

export const addressSearchSelectInitialState: AddressSearchSelectState = {
    loading: undefined,
    addresses: undefined,
    selectedAddressIdx: undefined
};
