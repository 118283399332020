import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { BannerAlertPopupComponent } from '@app/views/company/pages/company-about-page/company-settings-form/banner-alert-popup/banner-alert-popup.component';
import { CompanyGeneralPageComponent } from '@app/views/company/pages/company-general-page/company-general-page.component';
import { CompanyRolesPageComponent } from '@app/views/company/pages/company-roles-page/company-roles-page.component';
import { CompanyUserProfileComponent } from '@app/views/company/pages/company-roles-page/company-user-profile/company-user-profile.component';
import { CompanyBlockUserPopupComponent } from '@app/views/company/pages/company-roles-page/company-block-user-confirm/company-block-user-popup/company-block-user-popup.component';
import { CompanyBlockUserConfirmComponent } from '@app/views/company/pages/company-roles-page/company-block-user-confirm/company-block-user-confirm.component';
import { CompanyCreateUserComponent } from '@app/views/company/pages/company-roles-page/company-create-user/company-create-user.component';
import { UserResponsePipePipe } from '@app/views/company/pages/company-roles-page/user-response.pipe';
import { CompanyCreateUserPopupComponent } from '@app/views/company/pages/company-roles-page/company-create-user/company-create-user-popup/company-create-user-popup.component';
import { CompanyRolesManagementComponent } from '@app/views/company/pages/company-roles-page/company-roles-management/company-roles-management.component';
import { ButtonModule } from 'primeng/button';
import { SharedModule } from '@app/shared/shared.module';
import { ComponentLoaderModule, NavbarModule } from '@app/shared/ui';
import { MultiSelectModule } from 'primeng/multiselect';
import { PrimeBaseTableComponent } from '@app/shared/components/prime-base-table/prime-base-table.component';
import { CheckboxModule } from 'primeng/checkbox';
import { RenderComponent } from '@app/shared/components/render/render.component';
import { SharedPipesModule } from '@app/shared';
import { SharedDirectivesModule } from '@app/shared/directives';
import { PhoneInputModule } from '@app/shared/components';
import { CompanyRoutingModule } from '@app/views/company/company-routing.module';
import { InputTextModule } from 'primeng/inputtext';
import { TabMenuModule } from 'primeng/tabmenu';
import {NgxTranslateModule} from '@app/translate/translate.module';
import {DeviceEventService} from '@app/shared/services/device-event-service.service';

@NgModule({
  declarations: [
    BannerAlertPopupComponent,
    CompanyGeneralPageComponent,
    CompanyRolesPageComponent,
    CompanyUserProfileComponent,
    CompanyBlockUserPopupComponent,
    CompanyBlockUserConfirmComponent,
    CompanyCreateUserComponent,
    UserResponsePipePipe,
    CompanyCreateUserPopupComponent,
    CompanyRolesManagementComponent
  ],
    imports: [
        CommonModule,
        ButtonModule,
        SharedModule,
        ComponentLoaderModule,
        MultiSelectModule,
        PrimeBaseTableComponent,
        CheckboxModule,
        RenderComponent,
        NavbarModule,
        SharedModule,
        SharedPipesModule,
        SharedDirectivesModule,
        PhoneInputModule,
        CompanyRoutingModule,
        NgOptimizedImage,
        InputTextModule,
        TabMenuModule,
        NgxTranslateModule,
    ],
    providers: [ DeviceEventService ],
})
export class CompanyRolesModule {}
