import { LoaderService } from '@app/shared/entities/common/loader';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PreviosQueryType, RouteDetectionService } from '@app/shared/entities/common';
import { EntrancesStatus } from '@app/shared/entities/rd';
import { Constants } from '@app/shared/helpers/constants';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { NavbarLink, PaginatedPageWrapperComponent } from '@app/shared/ui';
import { Observable, Subject } from 'rxjs';
import { EntrancesPageModel } from '../../models';
import { EntrancesFacade } from './../../store';
import { PageData } from '@app/shared/models';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-entrances-page',
  templateUrl: './entrances-page.component.html',
  styleUrls: ['./entrances-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntrancesPageComponent implements OnInit, OnDestroy {
  readonly entranceStatuses: EntrancesStatus[] = Object.values(EntrancesStatus);
  readonly displayedColumns: string[] = ['address', 'rdaState', 'camerasStates', 'actions'];

  @ViewChild(PaginatedPageWrapperComponent, { static: true }) paginatedPageWrapper: PaginatedPageWrapperComponent;
  entrances$: Observable<EntrancesPageModel[]> = this.entrancesFacade.entrances$;
  pageData$: Observable<PageData> = this.entrancesFacade.pageData$;
  counts$: Observable<NavbarLink[]> = this.entrancesFacade.counts$;
  entrancesLoading$: Observable<boolean> = this.entrancesFacade.entrancesLoading$;
  countsLoading$: Observable<boolean> = this.entrancesFacade.countsLoading$;
  xlWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.XL_W_DOWN);

  private onDestroy$: Subject<void> = new Subject();
  private currentState: EntrancesStatus = EntrancesStatus.ONLINE;
  private savedState: EntrancesStatus;

  constructor(
    private resolution: ResolutionService,
    private routeDetection: RouteDetectionService,
    private entrancesFacade: EntrancesFacade,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.loaderService.loaderState = { state: false };
    this.entrancesFacade.loadCounts();
    this.prepareQueryParams();
    this.loadEntrances();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onChangeTab(idx: number) {
    this.currentState = this.entranceStatuses[idx] as EntrancesStatus;
    this.paginatedPageWrapper.paginator.pageIndex = 0;
    this.paginatedPageWrapper.filterInput.setValue(null, { emitEvent: false });
    this.loadEntrances();
  }

  onFilterInputChanged(value: string) {
    if (value) {
      this.savedState = this.currentState;
      this.currentState = null;
    } else if (this.savedState) {
      this.currentState = this.savedState;
      this.savedState = null;
    }

    this.paginatedPageWrapper.paginator.pageIndex = 0;
    this.loadEntrances();
  }

  onPageChanged() {
    this.loadEntrances();
  }

  private prepareQueryParams() {
    const queryParams = this.routeDetection.getQueryParams();

    if (queryParams.type === PreviosQueryType.ENTRANCE) {
      this.paginatedPageWrapper.paginator.pageIndex = queryParams.page ?? 0;

      if (queryParams.status) {
        this.savedState = (queryParams.status as EntrancesStatus) ?? this.entranceStatuses[0] ?? this.currentState;
        this.currentState = null;
      }

      if (queryParams.query) {
        this.paginatedPageWrapper.filterInput.setValue(queryParams.query, { emitEvent: false });
      } else {
        this.currentState = queryParams.status as EntrancesStatus;
      }
    }
  }

  private loadEntrances() {
    this.entrancesFacade.getPage(
      this.paginatedPageWrapper.paginator.pageIndex,
      this.paginatedPageWrapper.paginator.pageSize,
      this.currentState,
      this.paginatedPageWrapper.filterInput.value
    );
  }
}
