<div class="form-block">
  <div>
    <p>{{data.text}} <strong>id: {{data.id}}</strong>?</p>
    <p class="weight-bold" *ngIf="data.description">{{data.description}}</p>
  </div>
  <div class="action-block">
    <button mat-raised-button color="error" type="submit" class="mr-5" (click)="onSubmit()">
      {{data.btnText}}
    </button>
  </div>
</div>