import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ResourcePath, ResourcesHelper } from '@app/shared/entities/common';

@Component({
  selector: 'app-gate-wizard-content-intercoms-with-indicies',
  templateUrl: './gate-wizard-content-intercoms-with-indicies.component.html',
  styleUrls: ['./gate-wizard-content-intercoms-with-indicies.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GateWizardContentIntercomsWithIndiciesComponent {
  readonly resourcePath: typeof ResourcePath = ResourcePath;

  @Input() set loading(loading: boolean) {
    this._loading = loading;
    loading ? this.form.disable() : this.form.enable();
  }
  @Input() set index(index: string) {
    this._index = index;

    if (index) {
      this.form.setValue({ index });
    }
  }
  form: UntypedFormGroup = new UntypedFormGroup({
    index: new UntypedFormControl(null, [Validators.required])
  });

  @Output() private indexSelected: EventEmitter<{ index: string }> = new EventEmitter();
  private _index: string;
  private _loading: boolean;

  constructor(
    public resourcesHelper: ResourcesHelper
  ) { }

  get index(): string {
    return this._index;
  }

  get loading(): boolean {
    return this._loading;
  }

  onSubmit() {
    const { index } = this.form.getRawValue();
    this.indexSelected.emit({ index });
  }
}
