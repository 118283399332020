import { parseError } from '@app/shared/helpers';
import { ActionReducerMap } from '@ngrx/store';
import { PbxOnRdaPageActions, PbxOnRdaPageActionTypes } from './pbx-on-rda.actions';
import { pbxOnRdaPageInitialState, PbxOnRdaPageState } from './pbx-on-rda.state';

function reducer(state = pbxOnRdaPageInitialState, action: PbxOnRdaPageActions): PbxOnRdaPageState {
    switch (action.type) {
        case PbxOnRdaPageActionTypes.GetPbxOnRdaPage: {
            return {
                ...state,
                content: [],
                totalElements: 0,
                actions: PbxOnRdaPageActionTypes.GetPbxOnRdaPage,
                loading: true,
                error: null
            };
        }

        case PbxOnRdaPageActionTypes.GetPbxOnRdaPageSuccess: {
            return {
                ...state,
                content: action.content,
                totalElements: action.totalElements,
                loading: false,
                error: null
            };
        }

        case PbxOnRdaPageActionTypes.GetPbxOnRdaPageFailure: {
            return {
                ...state,
                loading: false,
                error: parseError(action.error)
            };
        }

        case PbxOnRdaPageActionTypes.CreatePbxOnRdaPage: {
            return {
                ...state,
                actions: PbxOnRdaPageActionTypes.CreatePbxOnRdaPage,
                loading: true,
                error: null
            };
        }

        case PbxOnRdaPageActionTypes.CreatePbxOnRdaPageSuccess: {
            state.content.push(action.response);
            state.totalElements++;

            return {
                ...state,
                loading: false,
                error: null
            };
        }

        case PbxOnRdaPageActionTypes.CreatePbxOnRdaPageFailure: {
            return {
                ...state,
                loading: false,
                error: parseError(action.error)
            };
        }

        case PbxOnRdaPageActionTypes.UpdatePbxOnRdaPage: {
            return {
                ...state,
                actions: PbxOnRdaPageActionTypes.UpdatePbxOnRdaPage,
                loading: true,
                error: null
            };
        }

        case PbxOnRdaPageActionTypes.UpdatePbxOnRdaPageSuccess: {
            return {
                ...state,
                loading: false,
                error: null
            };
        }

        case PbxOnRdaPageActionTypes.UpdatePbxOnRdaPageFailure: {
            return {
                ...state,
                loading: false,
                error: parseError(action.error)
            };
        }

        case PbxOnRdaPageActionTypes.DeletePbxOnRdaPage: {
            return {
                ...state,
                actions: PbxOnRdaPageActionTypes.DeletePbxOnRdaPage,
                loading: true,
                error: null
            };
        }

        case PbxOnRdaPageActionTypes.DeletePbxOnRdaPageSuccess: {
            const pbxIdx = state.content.findIndex(pbx => pbx.pbxOnRdaUid === action.pbxOnRdaUid);
            state.content.splice(pbxIdx, 1);

            return {
                ...state,
                loading: false,
                error: null
            };
        }

        case PbxOnRdaPageActionTypes.DeletePbxOnRdaPageFailure: {
            return {
                ...state,
                loading: false,
                error: parseError(action.error)
            };
        }

        default: {
            return state;
        }
    }
}

export const pbxOnRdaPageReducers: ActionReducerMap<{ pbxOnRdaPage: PbxOnRdaPageState }> = {
    pbxOnRdaPage: reducer
};
