import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {SnackbarService} from '@app/shared/components';
import {
  GateService,
  IntercomType,
  RdaResponse,
  ServiceCreateResponse,
  ServiceInfoResponse,
  ServiceResponse,
  ServicesList,
  ServiceUtilsService
} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {Address, PagedResponse, ServicesTypes} from '@app/shared/models';
import {DialogWrapperService} from '@app/shared/ui';
import {ServicePageActionsService} from '@app/views/services/services';
import {
  GetServiceConnectionsInit,
  GetServiceSuccess,
  ServiceFacade,
  ServicesActionTypes
} from '@app/views/services/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {GateMode} from '../models';
import {
  AddServiceEntranceSuccess,
  DeleteServiceEntranceSuccess,
  DeleteServiceRdaSuccess,
  GetServiceRdasSuccess
} from './../../../store';
import {GatesPageService} from './../services';
import {
  AddGateEntranceIntercoms,
  AddGateEntranceIntercomsFailure,
  AddGateEntranceIntercomsSuccess,
  ConnectGateEntranceIntercoms,
  ConnectGateEntranceIntercomsFailure,
  ConnectGateEntranceIntercomsSuccess,
  CreateGate,
  CreateGateFailure,
  CreateGateSuccess,
  DeleteGate,
  DeleteGateFailure,
  DeleteGateSuccess,
  GatesActionTypes,
  GetGateEntrancesIntercoms,
  GetGateEntrancesIntercomsFailure,
  GetGateEntrancesIntercomsSuccess,
  GetGatesPage,
  GetGatesPageFailure,
  GetGatesPageSuccess,
  RemoveGateEntranceIntercoms,
  RemoveGateEntranceIntercomsFailure,
  RemoveGateEntranceIntercomsSuccess,
  SetGateIntercomType,
  UpdateGateEntranceIntercoms,
  UpdateGateEntranceIntercomsFailure,
  UpdateGateEntranceIntercomsSuccess
} from './gates-page.actions';
import {GatesPageFacade} from './gates-page.facade';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class GatesPageEffects {
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private actions$: Actions,
    private snackbar: SnackbarService,
    private gateService: GateService,
    private serviceFacade: ServiceFacade,
    private gatesPageFacade: GatesPageFacade,
    private gatePageService: GatesPageService,
    private dialogWrapperService: DialogWrapperService,
    private servicePageService: ServicePageActionsService,
    private translate: TranslateService
  ) {}

  // Gates Effects

  GetGatesPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetGatesPage>(GatesActionTypes.GetGatesPage),
      switchMap((action: GetGatesPage) =>
        this.gateService.getPage(action.page, action.size, action.name)
          .pipe(
            map((response: PagedResponse<ServiceInfoResponse>) =>
              new GetGatesPageSuccess(
                this.gatePageService.preparePageResponse(response.content),
                response
              )
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.gates.page.message.get_gates_page.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetGatesPageFailure());
            })
          )
      )
    )
  );

  CreateGate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateGate>(GatesActionTypes.CreateGate),
      switchMap((action: CreateGate) => {
        this.dialogWrapperService.pendingState = true;

        return this.gateService.create(action.request)
          .pipe(
            map((response: ServiceCreateResponse) => {
              this.dialogWrapperService.pendingState = false;
              this.snackbar.showMessage(
                this.translate.instant('services.gates.page.message.create_gate.success', {
                  title: response.customName ?? response.name
                }),
                'success'
              );

              this.router
                .navigate([`services/gates`, response.id], {
                  queryParams: {
                    'query': action.options.filterValue ?? null,
                    'page': action.options.pageIndex,
                    'address': response.customName ?? response.name,
                  }
                })
                .then(() => this.dialog.closeAll());

              return new CreateGateSuccess();
            }),
            catchError((error: HttpErrorResponse) => {
              this.dialogWrapperService.pendingState = false;
              this.snackbar.showMessage(
                this.translate.instant('services.gates.page.message.create_gate.failed', {
                  text: parseError(error)
                })
              );
              return of(new CreateGateFailure());
            })
          );
      })
    )
  );

  DeleteGate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteGate>(GatesActionTypes.DeleteGate),
      switchMap((action: DeleteGate) => {
        this.dialogWrapperService.pendingState = true;

        return this.gateService.deleteService(action.serviceId)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.gates.page.message.delete_gate.success'),
                'success'
              );
              this.dialogWrapperService.pendingState = false;
              this.dialog.closeAll();
              this.gatesPageFacade.getPage(action.options.pageIndex, action.options.pageSize, action.options.filterValue);
              return new DeleteGateSuccess();
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.gates.page.message.delete_gate.failed', {
                  text: parseError(error)
                })
              );
              this.dialogWrapperService.pendingState = false;
              return of(new DeleteGateFailure());
            })
          );
      })
    )
  );

  // Gate Effects

  GetGateEntrancesIntercoms$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetGateEntrancesIntercoms>(GatesActionTypes.GetGateEntrancesIntercoms),
      switchMap((action: GetGateEntrancesIntercoms) =>
        from(this.gatePageService.getEntrancesIntercoms(action.entrancesIds))
          .pipe(
            map((entrancesIntercoms: RdaResponse[]) =>
              new GetGateEntrancesIntercomsSuccess(entrancesIntercoms)
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.gates.page.message.get_gate_entrances_intercoms.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetGateEntrancesIntercomsFailure());
            })
          )
      )
    )
  );

  AddGateEntranceIntercoms$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddGateEntranceIntercoms>(GatesActionTypes.AddGateEntranceIntercoms),
      withLatestFrom(this.gatesPageFacade.mode$),
      switchMap(([action, mode]: [AddGateEntranceIntercoms, GateMode]) =>
        from(this.gatePageService.addEntranceIntercoms(action.entrancesId))
          .pipe(
            map((intercoms: RdaResponse[]) =>
              new AddGateEntranceIntercomsSuccess(
                intercoms.map(intercom => {
                  intercom.entranceId = action.entrancesId;
                  return intercom;
                }),
                {connectIntercoms: mode === GateMode.REUSE || mode === GateMode.INDICES}
              )
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.gates.page.message.add_gate_entrance_intercoms.failed', {
                  text: parseError(error)
                })
              );
              return of(new AddGateEntranceIntercomsFailure());
            })
          )
      )
    )
  );

  AddGateEntranceIntercomsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddGateEntranceIntercomsSuccess>(GatesActionTypes.AddGateEntranceIntercomsSuccess),
      withLatestFrom(this.gatesPageFacade.index$),
      tap(([action, index]: [AddGateEntranceIntercomsSuccess, string]) => {
        if (action.options?.connectIntercoms) {
          this.gatesPageFacade.connectEntranceIntercoms(index, action.intercoms.map(intercom => intercom.id));
        }
      })
    ), {dispatch: false}
  );

  RemoveGateEntranceIntercoms$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveGateEntranceIntercoms>(GatesActionTypes.RemoveGateEntranceIntercoms),
      withLatestFrom(
        this.serviceFacade.serviceId$,
        this.serviceFacade.rdas$
      ),
      switchMap(([action, serviceId, intercoms]: [RemoveGateEntranceIntercoms, number, RdaResponse[]]) => {
        const intercomsIds = action.intercomsIds.filter(intercomId => intercoms.findIndex(intercom => intercom.id === intercomId) !== -1);

        return from(this.gatePageService.removeEntranceIntercoms(serviceId, intercomsIds))
          .pipe(
            map(() =>
              new RemoveGateEntranceIntercomsSuccess(action.intercomsIds)
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.gates.page.message.remove_gate_entrance_intercoms.failed', {
                  text: parseError(error)
                })
              );
              return of(new RemoveGateEntranceIntercomsFailure());
            })
          );
      })
    )
  );

  RemoveGateEntranceIntercomsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<RemoveGateEntranceIntercomsSuccess>(GatesActionTypes.RemoveGateEntranceIntercomsSuccess),
      tap(() => this.serviceFacade.getServiceRdas())
    ), {dispatch: false}
  );

  ConnectGateEntranceIntercoms$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ConnectGateEntranceIntercoms>(GatesActionTypes.ConnectGateEntranceIntercoms),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [ConnectGateEntranceIntercoms, number]) =>
        from(this.servicePageService.connectEntrancesIntercoms(serviceId, action.index, action.intercomsIds))
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.gates.page.message.connect_gate_entrance_intercoms.success'),
                'success'
              );
              return new ConnectGateEntranceIntercomsSuccess();
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.gates.page.message.connect_gate_entrance_intercoms.failed', {
                  text: parseError(error)
                })
              );
              return of(new ConnectGateEntranceIntercomsFailure());
            })
          )
      )
    )
  );

  ConnectGateEntranceIntercomsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ConnectGateEntranceIntercomsSuccess>(GatesActionTypes.ConnectGateEntranceIntercomsSuccess),
      tap(() => this.serviceFacade.getServiceRdas())
    ), {dispatch: false}
  );

  UpdateGateEntranceIntercoms$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateGateEntranceIntercoms>(GatesActionTypes.UpdateGateEntranceIntercoms),
      withLatestFrom(
        this.serviceFacade.rdas$,
        this.gatesPageFacade.index$
      ),
      switchMap(([action, intercoms, index]: [UpdateGateEntranceIntercoms, RdaResponse[], string]) =>
        from(this.servicePageService.updateEntrancesIntercoms(
          action.index,
          intercoms
            .map((intercom: RdaResponse) => intercom.intercoms.find(intercomPanel => intercomPanel.index === index)?.id)
            .filter((intercomId: number) => intercomId !== undefined)
        ))
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.gates.page.message.update_gate_entrance_intercoms.success'),
                'success'
              );
              return new UpdateGateEntranceIntercomsSuccess(action.index);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.gates.page.message.update_gate_entrance_intercoms.failed', {
                  text: parseError(error)
                })
              );
              return of(new UpdateGateEntranceIntercomsFailure());
            })
          )
      )
    )
  );

  UpdateGateEntranceIntercomsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateGateEntranceIntercomsSuccess>(GatesActionTypes.UpdateGateEntranceIntercomsSuccess),
      tap((action: UpdateGateEntranceIntercomsSuccess) => {
        this.serviceFacade.getServiceRdas();
        this.gatesPageFacade.setIndex(action.index);
      })
    ), {dispatch: false}
  );

  SetGateIntercomType$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetGateIntercomType>(GatesActionTypes.SetGateIntercomType),
      withLatestFrom(
        this.gatesPageFacade.entrancesIntercoms$,
        this.serviceFacade.rdas$
      ),
      tap(([action, entrancesIntercoms, intercoms]: [SetGateIntercomType, RdaResponse[], RdaResponse[]]) => {
        if (intercoms.length > 0) {
          this.prepareRdasResponse(intercoms);
          return;
        }

        this.prepareEntrancesIntercomsResponse(entrancesIntercoms, action.intercomType);
      })
    ), {dispatch: false}
  );

  // General Services Effects

  GetServiceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceSuccess>(ServicesActionTypes.GetServiceSuccess),
      withLatestFrom(this.serviceFacade.serviceType$),
      tap(([action, serviceType]: [GetServiceSuccess, ServicesTypes]) => {
        if (serviceType !== ServicesTypes.GATE) {
          return;
        }

        if (action.response.entrances.length > 0) {
          this.serviceFacade.getConnectionsInit();
        }

        if (action.response.entrances.length > 0) {
          this.gatesPageFacade.getEntrancesIntercoms(
            action.response.entrances.map(address => address.entrance.id)
          );
        }

        if (action.response.rdas?.length > 0) {
          this.gatesPageFacade.setIntercomType(action.response.rdas[0].intercomType);
          this.gatesPageFacade.setIntercom(action.response.rdas[0]);
          this.gatesPageFacade.setIndex(action.response.rdas[0].intercoms?.[0]?.index);
          this.prepareRdasResponse(action.response.rdas);
        }
      })
    ), {dispatch: false}
  );

  GetServiceConnectionsInit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<GetServiceConnectionsInit>(ServicesActionTypes.GetServiceConnectionsInit),
      withLatestFrom(
        this.serviceFacade.serviceType$,
        this.serviceFacade.dependantServices$,
        this.serviceFacade.entrances$
      ),
      tap(([action, serviceType, dependantServices, addresses]:
             [GetServiceConnectionsInit, ServicesTypes, Pick<ServiceResponse, 'id' | 'type'>[], Address[]]
      ) => {
        if (serviceType !== ServicesTypes.GATE) {
          return;
        }

        const preparedDependantServices: Pick<ServiceResponse, 'id' | 'type'>[] =
          dependantServices.reduce((prev, cur) => {
            if (cur.type === ServicesTypes.HARDWARE_INTERCOM) {
              prev.push(cur);
            }

            return prev;
          }, []);

        this.serviceFacade.getConnections(
          preparedDependantServices,
          addresses.map(address => address.entrance.id)
        );
      })
    );
  }, {dispatch: false});

  GetServiceRdasSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceRdasSuccess>(ServicesActionTypes.GetServiceRdasSuccess),
      withLatestFrom(this.serviceFacade.serviceType$),
      tap(([action, serviceType]: [GetServiceRdasSuccess, ServicesTypes]) => {
        if (serviceType !== ServicesTypes.GATE) {
          return;
        }

        if (action.rdas.length > 0) {
          this.gatesPageFacade.setIntercomType(action.rdas?.[0].intercomType);
          this.gatesPageFacade.setIntercom(action.rdas?.[0]);
          this.gatesPageFacade.setIndex(action.rdas?.[0].intercoms?.[0]?.index);
          this.prepareRdasResponse(action.rdas);
        }
      })
    ), {dispatch: false}
  );

  AddServiceEntranceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddServiceEntranceSuccess>(ServicesActionTypes.AddServiceEntranceSuccess),
      withLatestFrom(this.serviceFacade.serviceType$),
      tap(([action, serviceType]: [AddServiceEntranceSuccess, ServicesTypes]) => {
        if (serviceType !== ServicesTypes.GATE) {
          return;
        }

        this.gatesPageFacade.addEntranceIntercoms(action.entrance.id);
      })
    ), {dispatch: false}
  );

  DeleteServiceEntranceSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceEntranceSuccess>(ServicesActionTypes.DeleteServiceEntranceSuccess),
      withLatestFrom(
        this.serviceFacade.serviceType$,
        this.gatesPageFacade.entrancesIntercoms$
      ),
      tap(([action, serviceType, entrancesIntercoms]: [DeleteServiceEntranceSuccess, ServicesTypes, RdaResponse[]]) => {
        if (serviceType !== ServicesTypes.GATE) {
          return;
        }

        const rdasForRemove: RdaResponse[] = entrancesIntercoms.filter(rda => rda.entranceId === action.entranceId);
        this.gatesPageFacade.removeEntranceIntercoms(rdasForRemove.map(rda => rda.id));
      })
    ), {dispatch: false}
  );

  DeleteServiceRdaSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceRdaSuccess>(ServicesActionTypes.DeleteServiceRdaSuccess),
      withLatestFrom(
        this.serviceFacade.serviceType$,
        this.gatesPageFacade.intercom$,
        this.serviceFacade.rdas$,
        this.gatesPageFacade.entrancesIntercoms$,
        this.gatesPageFacade.intercomType$
      ),
      tap(([action, serviceType, selectedIntercom, intercoms, entrancesIntercoms, intercomType]:
             [DeleteServiceRdaSuccess, ServicesTypes, RdaResponse, RdaResponse[], RdaResponse[], IntercomType]
      ) => {
        if (serviceType !== ServicesTypes.GATE) {
          return;
        }

        if (action.intercom.uid === selectedIntercom?.uid) {
          this.gatesPageFacade.setIntercom(undefined);
        }

        if (!intercoms.length) {
          this.gatesPageFacade.setIndex(null);
          intercomType = null;
          this.gatesPageFacade.setIntercomType(intercomType);
          this.prepareEntrancesIntercomsResponse(entrancesIntercoms, intercomType);
        }
      })
    ), {dispatch: false}
  );

  private prepareRdasResponse(intercoms: RdaResponse[]) {
    const supportsIndices: boolean = this.intercomsSupportIndices(intercoms);

    if (supportsIndices) {
      this.gatesPageFacade.setMode(GateMode.INDICES);
    } else if (intercoms.length === 1) {
      this.gatesPageFacade.setMode(GateMode.DEFAULT);
    } else {
      this.gatesPageFacade.setMode(GateMode.REUSE);
    }
  }

  private prepareEntrancesIntercomsResponse(entrancesIntercoms: RdaResponse[], intercomType: IntercomType) {
    const supportsIndices: boolean = this.intercomsSupportIndices(entrancesIntercoms) &&
      intercomType?.protocol?.intercomIndexesRequired;

    if (supportsIndices) {
      this.gatesPageFacade.setMode(GateMode.INDICES);
    } else if (intercomType?.protocol?.ipType) {
      this.gatesPageFacade.setMode(GateMode.DEFAULT);
    } else {
      this.gatesPageFacade.setMode(GateMode.SELECTION);
    }
  }

  private intercomsSupportIndices(intercoms: RdaResponse[]): boolean {
    return intercoms?.reduce(
      (prev, cur) => prev && cur?.intercomType?.protocol?.intercomIndexesRequired,
      true
    );
  }
}
