<app-dialog-wrapper
  [title]="data.title"
  [progress]="(status$ | async) === 'loading'"
  [componentName]="data.componentName"
>
  <div class="popup">
    <ng-container [ngSwitch]="mode$ | async">
      <app-abonents-delegation-popup-content-confirm
          *ngSwitchCase="'confirm'"
          [owner]="owner$ | async"
          [phone]="data.body.signUp.phone"
          [loading]="(status$ | async) === 'loading'"
          (delegateAccess)="onDelegate($event.ownerId)"
      ></app-abonents-delegation-popup-content-confirm>

      <app-abonents-delegation-popup-content-close
        *ngSwitchCase="'close'"
        [loading]="(status$ | async) === 'loading'"
        [phone]="data.body.signUp.phone"
        (closeSignUp)="onClose()"
      ></app-abonents-delegation-popup-content-close>

      <app-info-block *ngSwitchCase="'check'">
        <app-component-loader
          info-block-image
          sizePx="50"
          [overTheComponent]="false"
        ></app-component-loader>

        <ng-container info-block-text>
          {{ 'abonents.delegation.popup.info' | translate }}
        </ng-container>
      </app-info-block>

    </ng-container>
  </div>
</app-dialog-wrapper>
