import * as root from '@app/store/reducers';

export interface CoreModuleState {
    core: CoreState;
}

export interface CoreModuleRootState extends root.State {
    'core': CoreModuleState;
}

export interface CoreState {
    activeSignUps: number;
    unresolvedIssues: number;
    payment: number;
    paymentLoading: boolean
}

export const coreInitialState: CoreState = {
    activeSignUps: null,
    unresolvedIssues: null,
    payment: null,
    paymentLoading: false
};
