import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonLinkComponent } from './button-link.component';


const components = [
    ButtonLinkComponent,
];

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: components,
    exports: components
})
export class ButtonLinkModule { }
