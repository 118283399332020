import { ActionReducerMap } from '@ngrx/store';
import { AbonentsFromEntrancesActions, AbonentsFromEntrancesActionTypes } from './abonents-from-entrances.actions';
import { abonentsFromEntrancesInitialState, AbonentsFromEntrancesModuleState, AbonentsFromEntrancesState } from './abonents-from-entrances.state';

function reducer(
    state = abonentsFromEntrancesInitialState,
    action: AbonentsFromEntrancesActions
): AbonentsFromEntrancesState {
    switch (action.type) {
        case AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesGetConnections: {
            return {
                ...state,
                status: 'loading'
            };
        }

        case AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesGetConnectionsSuccess: {
            return {
                ...state,
                status: action.connections.length > 0 ? 'accounts' : 'success',
                selectedAll: true,
                connections: action.connections.filter(connection => connection.account.owner.phone && connection.account.owner.phone !== 7).map(connection => ({
                    ...connection,
                    selected: true,
                    abonent: connection.account.owner
                })),
            };
        }

        case AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesGetConnectionsFailure: {
            return {
                ...state,
                status: 'error'
            };
        }

        case AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesSetChecked: {
            const connectionIdx: number = state.connections.findIndex(connection => connection.id === action.connectionId);

            state.connections[connectionIdx].selected = !state.connections[connectionIdx].selected;
            const filteredConnectionsIds: number[] = state.connections.map(connection => connection.id);
            const filteredSelectedConnectionsIds: number[] = state.connections.reduce((filtered, connection) => {
                if (connection.selected) {
                    filtered.push(connection.id);
                }

                return filtered;
            }, []);

            if (!state.connections[connectionIdx].selected) {
                state.selectedAll = false;
            } else if (filteredConnectionsIds.length === filteredSelectedConnectionsIds.length) {
                state.selectedAll = true;
            }

            return {
                ...state
            };
        }

        case AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesSetAllChecked: {
            state.selectedAll = !state.selectedAll;

            for (const connection of state.connections) {
                connection.selected = !!state.selectedAll;
            }

            return {
                ...state
            };
        }

        case AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesRemoveConnection: {
            for (const connectionWithId of action.connections) {
                const connectionIdx: number = state.connections.findIndex(connection => connection.id === connectionWithId.id);
                state.connections.splice(connectionIdx, 1);

                state.connections = state.connections.slice();
            }

            if (state.connections.length === 0) {
                state.status = 'success';
            }

            return {
                ...state
            };
        }

        case AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesSetStatus: {
            return {
                ...state,
                status: action.status,
            };
        }

        case AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesStartConnectionProcess: {
            return {
                ...state,
                doneConnections: 0,
                status: 'connection',
                totalConnections: action.totalConnections
            };
        }

        case AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesUpdateConnectionProcess: {
            const connectionIdx: number = state.connections.findIndex(connection => connection.id === action.connectionId);
            state.connections.splice(connectionIdx, 1);

            return {
                ...state,
                doneConnections: state.doneConnections + action.doneConnections
            };
        }

        case AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesFinishConnectionProcess: {
            return {
                ...state,
                doneConnections: null,
                totalConnections: null,
                status: 'success'
            };
        }

        case AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesClearState: {
            return {
                connections: undefined,
                status: undefined,
                selectedAll: undefined,
                totalConnections: undefined,
                doneConnections: undefined,
                error: undefined
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
}

export const abonentsFromEntrancesReducer: ActionReducerMap<AbonentsFromEntrancesModuleState> = {
    abonentsFromEntrances: reducer
};
