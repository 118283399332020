import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CameraInfoPopupComponent } from '@app/shared/components';
import { Camera } from '@app/shared/entities/rd';
import { Constants } from '@app/shared/helpers';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData } from '@app/shared/ui';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-cameras-card',
  templateUrl: './cameras-card.component.html',
  styleUrls: ['./cameras-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CamerasCardComponent {
  @Input() cameras: Camera[];
  @Input() loading: boolean;

  constructor(
    private resolution: ResolutionService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) { }

  onInfo(camera: Camera) {
    const data: DialogWrapperData<Camera, null> = {
      title: this.translate.instant('entrances.cameras.card.info.title'),
      componentName: `EntranceCameraInfoPage`,
      body: camera
    };

    this.dialog.open(CameraInfoPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ? '100%' : 'auto',
      data
    });
  }
}
