import { ChangeDetectorRef, Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { AmplitudeComponentTrackType, AmplitudeEvents, AmplitudeService } from '@app/shared/entities/integrations/amplitude';
import { BottomSheetData } from '@app/shared/models';
import { LoaderService } from '@app/shared/entities/common/loader';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PopupBasicComponent } from '../popup-basic/popup-basic.component';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent implements OnInit, OnDestroy {
  outlet: PopupBasicComponent;
  dataInjector: Injector;
  loading: boolean;
  private onDestroy$: Subject<void>;
  private subject: Subject<any>;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: BottomSheetData,
    private bottomSheetRef: MatBottomSheetRef<BottomSheetComponent>,
    private amplitudeService: AmplitudeService,
    private changeDetectorRef: ChangeDetectorRef,
    private loader: LoaderService,
    private injector: Injector
  ) {
    this.initComponentOutlet();
    this.trackOpenedPopup();
  }

  ngOnInit() {
    this.onDestroy$ = new Subject<void>();

    this.loader.getComponentLoaderState()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(state => {
        this.loading = state;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.subject.next();
    this.subject.complete();

    this.onDestroy$.next();
    this.onDestroy$.complete();

    this.trackClosedPopup();
  }

  private initComponentOutlet() {
    this.subject = new Subject();
    this.subject.asObservable().subscribe(event => {
      this.data.submit(event);
      this.bottomSheetRef.dismiss();
    });

    this.outlet = this.data.component;
    this.dataInjector = Injector.create({
      providers: [
        { provide: 'data', useValue: this.data.providedData },
        { provide: 'subject', useValue: this.subject }
      ],
      parent: this.injector
    });
  }

  private trackOpenedPopup() {
    this.amplitudeService.trackComponent(
      this.data.componentName,
      AmplitudeComponentTrackType.BOTTOM_SHEET,
      AmplitudeEvents.OPEN,
      null
    );
  }

  private trackClosedPopup() {
    this.amplitudeService.trackComponent(
      this.data.componentName,
      AmplitudeComponentTrackType.BOTTOM_SHEET,
      AmplitudeEvents.CLOSE,
      null
    );
  }
}
