import { HttpErrorResponse } from '@angular/common/http';
import { Camera } from '@app/shared/entities/rd/camera/models';
import { Actions, ActionTypes } from '../../actions/cameras.actions';

export interface State {
  cameras: Camera[];
  error: string | HttpErrorResponse;
  totalCount: number;
  loading: boolean;
}

export const initialState: State = {
  cameras: [],
  error: null,
  totalCount: null,
  loading: false
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GetCamerasPage: {
      return {
        ...state,
        cameras: [],
        error: null,
        totalCount: null,
        loading: true
      };
    }

    case ActionTypes.GetCamerasPageSuccess: {
      return {
        ...state,
        cameras: action.payload,
        totalCount: action.totalCount,
        loading: false
      };
    }

    case ActionTypes.GetCamerasPageFailure: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}
