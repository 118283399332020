import { VoipTokenInfo } from '@app/views/abonents/models';
import { AbonentEventName } from './abonent-event-name.model';
import { EventInfo } from './event-info.model';

export interface AbonentEvents {
  eventInfo: EventInfo;
  applicationInfo: VoipTokenInfo;
  abonentEventName: AbonentEventName;
  date: string;
}
