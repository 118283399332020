import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  VideoManagerAddCameraModalComponent,
  VideoManagerAddEditPageComponent,
  VideoManagerPageComponent,
  VideoManagerPlayerComponent,
  VideoManagerShowScreenPageComponent
} from '@app/views/services/submodules/video-manager/components';
import {CardModule} from 'primeng/card';
import {
  VideoManagerPageActionsService
} from '@app/views/services/submodules/video-manager/services/video-manager-page-actions.service';
import {ButtonModule} from 'primeng/button';
import {NgxTranslateModule} from '@app/translate/translate.module';
import {TooltipModule} from 'primeng/tooltip';
import {RouterModule} from '@angular/router';
import {InputTextModule} from 'primeng/inputtext';
import {SelectButtonModule} from 'primeng/selectbutton';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CameraThumbnailModule} from '@app/shared/containers';
import {
  CustomPaginatorComponent
} from '@app/shared/components/prime-base-table/custom-paginator/custom-paginator.component';
import {SkeletonModule} from 'primeng/skeleton';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {CheckboxModule} from 'primeng/checkbox';
import {
  VideoManagerPageDataService
} from '@app/views/services/submodules/video-manager/services/video-manager-page-data.service';
import {SharedPipesModule} from '@app/shared';
import {RadioButtonModule} from 'primeng/radiobutton';
import {ConfirmationService} from 'primeng/api';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {VideoPlayerModule} from '@app/shared/components';
import {ComponentLoaderModule} from '@app/shared/ui';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {SharedDirectivesModule} from '@app/shared/directives';
import {SliderModule} from 'primeng/slider';

@NgModule({
  declarations: [
    VideoManagerPageComponent,
    VideoManagerAddEditPageComponent,
    VideoManagerAddCameraModalComponent,
    VideoManagerShowScreenPageComponent,
    VideoManagerPlayerComponent,
  ],
    imports: [
        CommonModule,
        RouterModule,
        CardModule,
        ButtonModule,
        NgxTranslateModule,
        TooltipModule,
        InputTextModule,
        SelectButtonModule,
        ReactiveFormsModule,
        CameraThumbnailModule,
        CustomPaginatorComponent,
        SkeletonModule,
        LazyLoadImageModule,
        CheckboxModule,
        FormsModule,
        SharedPipesModule,
        RadioButtonModule,
        ConfirmDialogModule,
        DragDropModule,
        VideoPlayerModule,
        ComponentLoaderModule,
        MatButtonModule,
        MatDialogModule,
        MatIconModule,
        TieredMenuModule,
        SharedDirectivesModule,
        SliderModule,
    ],
  providers: [VideoManagerPageActionsService, VideoManagerPageDataService, DialogService, DynamicDialogRef, ConfirmationService]
})
export class VideoManagerModule {
}
