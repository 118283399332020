import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ChangePasswordRequest } from '@app/shared/entities/rd';
import { DialogWrapperData, DialogWrapperService } from '@app/shared/ui';

@Component({
  selector: 'app-change-password-popup',
  templateUrl: './change-password-popup.component.html',
  styleUrls: ['./change-password-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<null, ChangePasswordRequest>,
    public dialogWrapperService: DialogWrapperService
  ) { }

  onChangePassword(oldPassword: string, newPassword: string) {
    this.data.submit({ oldPassword, newPassword });
  }
}
