<ng-container [ngSwitch]="mode">
    <ng-container *ngSwitchCase="addressInputMode.CREATED">
        <app-address-search-select
            title="{{ 'shared.address.input.search-select.title' | translate }}"
            [withoutDescription]="true"
            [options]="options"
            (addressSelected)="onSelectEntrance($event.address)"
            (changeMode)="onChangeMode()"
        ></app-address-search-select>
    </ng-container>

    <app-dadata-address
        *ngSwitchCase="addressInputMode.DADATA"
        [label]="label"
        [country]="country"
        [initialAddress]="initialAddress"
        (submittedAddress)="onSelectAddress($event)"
    ></app-dadata-address>

    <app-kz-post-address
        *ngSwitchCase="addressInputMode.KZ_POST"
        [label]="label"
        [country]="country"
        [initialAddress]="initialAddress"
        (submittedAddress)="onSelectAddress($event)"
        (getAddressError)="onError($event.error)"
    ></app-kz-post-address>

    <app-yandex-address
        *ngSwitchDefault
        [country]="country"
        [label]="label"
        [initialAddress]="initialAddress"
        (selectAddress)="onSelectAddress($event)"
        (getAddressError)="onError($event.error)"
    ></app-yandex-address>
</ng-container>
