<form [formGroup]="form">
  <mat-form-field appearance="outline" class="none-padding">
    <mat-select
      placeholder="{{ 'intercom.companies.form.group.placeholder.company' | translate }}"
      formControlName="companyId"
      (selectionChange)="onSelectCompany($event)"
      (openedChange)="onOpenedChange($event)"
    >
      <input
        #filterInput
        matInput
        placeholder="{{ 'intercom.companies.form.group.placeholder.company' | translate }}"
        type="text"
        formControlName="filter"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
      >
      <mat-option *ngFor="let company of (filteredCompanies | async)" [value]="company.id">
        {{ company.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
