import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { ActiveHistoryResponse, Camera, RdaResponse } from '@app/shared/entities/rd';
import { Constants, Dictionary } from '@app/shared/helpers';
import { LogsComponentType, LogsResponse, ServicesTypes } from '@app/shared/models';
import { ServiceActivitySource } from '@app/views/services/models';
import { ServiceFacade } from '../../../store';
import { ServiceActivityService } from './service-activity.service';
import {TranslateService} from '@ngx-translate/core';
import { DateService } from '@app/shared/services/date.service';

@Component({
  selector: 'app-service-activity',
  templateUrl: './service-activity.component.html',
  styleUrls: ['./service-activity.component.scss'],
  providers: [ServiceActivityService, DateService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceActivityComponent implements OnInit, OnChanges {
  readonly componentTypes = LogsComponentType;
  // readonly timeRanges = Constants.dateDiffs.slice(3);
  public timeRanges: Array<{ value: number, name: string }>;

  @Input() set timeRange(timeRange: number) {
    this._timeRange = timeRange;

    if (this.timeRange) {
      return;
    }

    this.serviceFacade.setTimeRange(this.dateService.dateDiffs[4].value);
  }

  @Input() set sources(sources: ServiceActivitySource[]) {
    this._sources = sources;

    if (!this.sources?.length) {
      return;
    }

    this.rdasSources = this.filterSources(LogsComponentType.RDA);
    this.camerasSources = this.filterSources(LogsComponentType.CAMERA);
  }

  @Input() logs: LogsResponse[];
  @Input() logsLoading: boolean;
  @Input() extendedMode: boolean;
  @Input() blocksCount: number;
  @Input() componentType: LogsComponentType;
  @Input() selectedLogsSource: ServiceActivitySource;
  @Input() activeHistoryIntercoms: Dictionary<ActiveHistoryResponse[]>;
  @Input() activeHistoryCameras: Dictionary<ActiveHistoryResponse[]>;

  logsPage = 0;
  rdasSources: ServiceActivitySource[];
  camerasSources: ServiceActivitySource[];
  endDate: Date;
  startDate: Date;
  isUpdate = false;
  currentSeconds = 0;
  stepMilliseconds: number;

  @Input() private totalLogsCount: number;
  @Input() private currentLogsCount: number;
  @Input() private serviceType: ServicesTypes;
  private initialized: boolean;
  private readonly limit = 150;
  private _timeRange: number;
  private _sources: ServiceActivitySource[];

  private selectedLogsLoaded: boolean;
  private componentTypeLoaded: boolean;
  private sourcesLoaded: boolean;
  private sourcesInitialized: boolean;

  constructor(
    private serviceFacade: ServiceFacade,
    private activityService: ServiceActivityService,
    private changeDetectorRef: ChangeDetectorRef,
    private translate: TranslateService,
    private dateService: DateService
  ) { }

  ngOnInit(): void {
    this.timeRanges = this.dateService.dateDiffs.slice(3, 10);
  }

  ngOnChanges(changes: SimpleChanges) {
    // All variables for first charts and logs load initialized
    this.initChartsAndLogs();

    // Sources with depending variables initialized
    if ((changes['selectedLogsSource']?.firstChange)) {
      this.selectedLogsLoaded = true;
    }

    if ((changes['componentType']?.firstChange)) {
      this.componentTypeLoaded = true;
    }

    if (this.sources?.length > 0) {
      this.sourcesLoaded = true;
    }

    if (
      !this.sourcesInitialized &&
      this.selectedLogsLoaded &&
      this.componentTypeLoaded &&
      this.sourcesLoaded &&
      !this.selectedLogsSource &&
      !this.componentType
    ) {
      this.prepareSources();
      this.sourcesInitialized = true;
    }
  }

  get timeRange(): number {
    return this._timeRange;
  }

  get sources(): ServiceActivitySource[] {
    return this._sources;
  }

  onRefresh() {
    this.loadCharts();
    this.loadLogs();
  }

  onSelectSource(selectedSource: ServiceActivitySource) {
    this.serviceFacade.setLogsSource(selectedSource);

    if (this.initialized) {
      this.loadLogs({ selectedSource });
    }
  }

  onLoadLogsNextPage() {
    if (this.currentLogsCount >= this.totalLogsCount || this.logsLoading) {
      return;
    }

    this.loadLogs({ logsPage: this.logsPage + 1 });
  }

  onChangeExtendedMode(extendedMode: boolean) {
    this.serviceFacade.setLogsStream(extendedMode);
  }

  onChangeComponentType(componentType: LogsComponentType) {
    this.serviceFacade.setLogsComponentType(componentType);

    switch (componentType) {
      case LogsComponentType.RDA:
        this.serviceFacade.setLogsSource(this.rdasSources[0]);
        this.loadCharts({ componentType: LogsComponentType.RDA });
        this.loadLogs({ selectedSource: this.rdasSources[0] });
        break;
      case LogsComponentType.CAMERA:
        this.serviceFacade.setLogsSource(this.camerasSources[0]);
        this.loadCharts({ componentType: LogsComponentType.CAMERA });
        this.loadLogs({ selectedSource: this.camerasSources[0] });
        break;
    }
  }

  onChangeTimeRange(timeRange: number) {
    this.serviceFacade.setTimeRange(timeRange);
    this.loadCharts({ timeRange });
    this.loadLogs({ timeRange });
  }

  onChangeChartStep(blocksCount: number) {
    if (blocksCount === this.blocksCount) {
      return;
    }

    this.serviceFacade.setBlocksCount(blocksCount);
    this.initChartsAndLogs({ blocksCount });

    if (this.initialized) {
      this.loadCharts({ blocksCount });
    }
  }

  onStartUpdate() {
    this.isUpdate = true;

    this.activityService.startTimer((remainingSeconds: number, secondsToUpdate: number) => {
      this.currentSeconds = remainingSeconds % secondsToUpdate;
      this.changeDetectorRef.markForCheck();

      if (remainingSeconds % secondsToUpdate === 0) {
        this.loadCharts();
        this.loadLogs();
      }
    });
  }

  onStopUpdate() {
    this.activityService.stopTimer();
    this.currentSeconds = 0;
    this.isUpdate = false;
  }

  getSourceWithErrorIdx(sources: ServiceActivitySource[]): number {
    if (!sources?.length) {
      return null;
    }

    return sources.findIndex(source => !source.status);
  }

  getChipColor(sources: ServiceActivitySource[], componentType: LogsComponentType) {
    const errorIdx: number = this.getSourceWithErrorIdx(sources);
    const selectedComponentType: boolean = componentType === this.componentType;

    if (selectedComponentType) {
      return 'primary';
    }

    if (errorIdx !== -1) {
      return 'error';
    }

    return 'primary-inverse';
  }

  canBeUpdated() {
    switch (this.componentType) {
      case LogsComponentType.RDA:
        return this.rdasSources?.length > 0;
      case LogsComponentType.CAMERA:
        return this.camerasSources?.length > 0;
      default:
        return false;
    }
  }

  showComponentTypesChips(): boolean {
    switch (this.serviceType) {
      case ServicesTypes.SOFTWARE_INTERCOM:
      case ServicesTypes.GATE:
        return this.rdasSources?.length > 0 && this.camerasSources?.length > 0;
      default:
        return false;
    }
  }

  getIntercomLabel(): string {
    switch (this.serviceType) {
      case ServicesTypes.SOFTWARE_INTERCOM:
        return this.translate.instant('service.activity.enum.type.software_intercom');
      case ServicesTypes.GATE:
        return this.translate.instant('service.activity.enum.type.gate');
    }
  }

  showEmptySourcesMessage() {
    let messageSubstring: string;

    switch (this.serviceType) {
      case ServicesTypes.SOFTWARE_INTERCOM:
        messageSubstring = this.translate.instant('service.activity.message.empty_sources.software_intercom');
        break;
      case ServicesTypes.GATE:
        messageSubstring = this.translate.instant('service.activity.message.empty_sources.gate');
        break;
      default:
        messageSubstring = '';
    }

    return this.translate.instant('service.activity.message.empty_sources.text', {
      text: messageSubstring
    });
  }

  showIntercomsChart(): boolean {
    let needToShow = false;

    switch (this.serviceType) {
      case ServicesTypes.SOFTWARE_INTERCOM:
      case ServicesTypes.GATE:
        needToShow = true;
        break;
    }

    if (needToShow && this.componentType !== LogsComponentType.RDA) {
      needToShow = false;
    }

    return needToShow;
  }

  showCamerasChart(): boolean {
    let needToShow = false;

    switch (this.serviceType) {
      case ServicesTypes.SOFTWARE_INTERCOM:
      case ServicesTypes.VIDEO_SURVEILLANCE:
      case ServicesTypes.GATE:
        needToShow = true;
        break;
    }

    if (needToShow && this.componentType !== LogsComponentType.CAMERA) {
      needToShow = false;
    }

    return needToShow;
  }

  filterSources(componentType: LogsComponentType) {
    return this.sources.filter((source: ServiceActivitySource) => source.type === componentType);
  }

  private initChartsAndLogs({ blocksCount = this.blocksCount }: { blocksCount?: number } = {}) {
    const canLoad: boolean =
      !!this.componentType &&
      !!this.timeRange &&
      !!this.selectedLogsSource &&
      !!blocksCount &&
      this.sources?.length > 0;

    if (!this.initialized && canLoad) {
      this.initialized = canLoad;

      this.loadCharts({ blocksCount });
      this.loadLogs();
    }
  }

  private prepareSources() {
    // Find first source with error
    let sourceWithErrorIdx: number = this.getSourceWithErrorIdx(this.rdasSources);

    if (sourceWithErrorIdx !== null && sourceWithErrorIdx !== -1) {
      this.serviceFacade.setLogsSource(this.rdasSources[sourceWithErrorIdx]);
      this.serviceFacade.setLogsComponentType(LogsComponentType.RDA);
      return;
    }

    sourceWithErrorIdx = this.getSourceWithErrorIdx(this.camerasSources);

    if (sourceWithErrorIdx !== null && sourceWithErrorIdx !== -1) {
      this.serviceFacade.setLogsSource(this.camerasSources[sourceWithErrorIdx]);
      this.serviceFacade.setLogsComponentType(LogsComponentType.CAMERA);
      return;
    }

    // Find first avaliable source
    if (this.rdasSources.length > 0) {
      this.serviceFacade.setLogsSource(this.rdasSources[0]);
      this.serviceFacade.setLogsComponentType(LogsComponentType.RDA);
      return;
    }

    if (this.camerasSources.length > 0) {
      this.serviceFacade.setLogsSource(this.camerasSources[0]);
      this.serviceFacade.setLogsComponentType(LogsComponentType.CAMERA);
      return;
    }
  }

  private loadLogs(
    {
      selectedSource = this.selectedLogsSource,
      timeRange = this.timeRange,
      logsPage = 0
    }: { selectedSource?: ServiceActivitySource, timeRange?: number, logsPage?: number } = {}
  ) {
    this.logsPage = logsPage;

    switch (selectedSource.type) {
      case LogsComponentType.RDA:
        const rda: RdaResponse = selectedSource.component as RdaResponse;
        this.serviceFacade.getRdaLogs(rda.uid, timeRange, this.limit, logsPage);
        break;
      case LogsComponentType.CAMERA:
        const camera: Camera = selectedSource.component as Camera;

        if (camera.rdaUid) {
          this.serviceFacade.getPrivateCameraLogs(camera.rdaUid, camera.id, timeRange, this.limit, logsPage);
        } else {
          this.serviceFacade.getCameraLogs(camera.rdvaUri, camera.id);
        }
        break;
    }
  }

  private loadCharts(
    {
      componentType = this.componentType,
      blocksCount = this.blocksCount,
      timeRange = this.timeRange
    }: { blocksCount?: number, componentType?: LogsComponentType, timeRange?: number } = {}
  ) {
    this.endDate = new Date();
    this.startDate = new Date(this.endDate.getTime() - timeRange * 60 * 1000);
    this.stepMilliseconds = Math.ceil(timeRange * 60 / blocksCount) * 1000;

    switch (componentType) {
      case LogsComponentType.RDA:
        this.serviceFacade.getRdaActivityBefore();

        this.rdasSources.forEach((rdaSource: ServiceActivitySource) =>
          this.serviceFacade.getRdaActivity(rdaSource.component?.id, this.startDate, this.endDate, this.stepMilliseconds)
        );
        break;
      case LogsComponentType.CAMERA:
        this.serviceFacade.getCameraActivityBefore();

        this.camerasSources.forEach((cameraSource: ServiceActivitySource) =>
          this.serviceFacade.getCameraActivity(cameraSource.component?.id, this.startDate, this.endDate, this.stepMilliseconds)
        );
        break;
    }
  }
}
