<div class="payments-upload">
  <mat-card class="card-container">
    <mat-card-header>
      <mat-card-title>
        {{ 'payments.upload.title' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="controls">
        <app-navbar
          [links]="links"
          [active]="activeLink"
          (select)="onChangeTab($event.idx)"
        />
        <div
          *ngIf="childIndex?.isUploaded"
          class="buttons"
        >
          <app-payments-upload-buttons
            [isLoading]="childIndex?.isLoading"
            [isLoadingDownload]="childIndex?.isLoadingDownload"
            [isLoadingUpload]="childIndex?.isLoadingUpload"
            (onDownload)="childIndex?.onDownload()"
            (onUpload)="childIndex?.onUpload()"
          />
        </div>
      </div>

      <ng-container [ngSwitch]="activeLink">
        <ng-container *ngSwitchCase="pageType.INDEX">
          <app-payments-upload-index
            #childIndex
          />
        </ng-container>
        <ng-container *ngSwitchCase="pageType.HISTORY">
          <app-payments-upload-history />
        </ng-container>
      </ng-container>

    </mat-card-content>
  </mat-card>
</div>
