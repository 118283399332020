import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/shared/components/snackbar';
import {RdvaApiService, RdvaPlaylistItem, VideoshotGetRequest} from '@app/shared/entities/integrations';
import {parseError} from '@app/shared/helpers';
import {DialogWrapperService} from '@app/shared/ui/dialog-wrapper';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {FileSaverService} from 'ngx-filesaver';
import {from, Observable, of, throwError} from 'rxjs';
import {catchError, exhaustMap, delay, map, retryWhen, switchMap, mergeMap, takeUntil} from 'rxjs/operators';
import {VIDEOSHOT_FILENAME_FORMAT} from '../video-player.constants';
import {
  VideoPlayerActionTypes,
  VideoPlayerCreateVideoshotTask,
  VideoPlayerCreateVideoshotTaskFailure,
  VideoPlayerCreateVideoshotTaskSuccess,
  VideoPlayerGetPlaylists,
  VideoPlayerGetPlaylistsFailure,
  VideoPlayerGetPlaylistsSuccess,
  VideoPlayerGetVideoshot,
  VideoPlayerGetVideoshotFailure,
  VideoPlayerGetVideoshotSuccess
} from './video-player.actions';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class VideoPlayerEffects {
  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private rdvaApiService: RdvaApiService,
    private fileSaver: FileSaverService,
    private dialogWrapperService: DialogWrapperService,
    private translate: TranslateService
  ) {
  }

  public getPlaylists(rdvaUri: string, cameraId: number, timeZone: number): Observable<{response: RdvaPlaylistItem[], error: HttpErrorResponse}> {
    return this.rdvaApiService.getPlaylists(rdvaUri, cameraId, timeZone).pipe(
      map((response: RdvaPlaylistItem[]) => {
        if (response?.length === 0) {
          this.snackbar.showMessage(
            this.translate.instant('shared.video_player.message.get_playlists.empty'),
            'info'
          );
        }
        return {response, error: null};
      }),
      catchError((error: HttpErrorResponse) => {
        this.snackbar.showMessage(
          this.translate.instant('shared.video_player.message.get_playlists.failed', {
            text: parseError(error)
          })
        );
        return of({response: null, error});
      })
    );
  }

  public addVideoshotBatch(request: VideoshotGetRequest): Observable<{response: any, error: HttpErrorResponse}> {
    const delayTime = this.calculateDelay(request.duration);

    return this.rdvaApiService.addVideoshotBatch(request).pipe(
      delay(delayTime),
      map(() => {
        this.dialogWrapperService.pendingState = false;
        return {response: true, error: null};
      }),
      catchError((error: HttpErrorResponse) => {
        this.dialogWrapperService.pendingState = false;
        this.snackbar.showMessage(
          this.translate.instant('shared.video_player.message.create_video_shot_task.failed', {
            text: parseError(error)
          })
        );
        return of({response: null, error});
      }), 
      takeUntil(request.requestCancellation)
    );
  }

  public getVideoshot(request: VideoshotGetRequest): Observable<{response: ArrayBuffer, error: HttpErrorResponse }> {
    return this.rdvaApiService.getVideoshot(request).pipe(
      map((response: ArrayBuffer) => {
        const fileName = VIDEOSHOT_FILENAME_FORMAT
          .replace('{0}', request.cameraId.toString())
          .replace('{1}', request.dateFormatString)
          .replace('{2}', request.duration.toString());

        this.fileSaver.save(<any>(response), fileName);

        this.dialogWrapperService.pendingState = false;
        this.snackbar.showMessage(
          this.translate.instant('shared.video_player.message.get_video_shot.success'),
          'success', 
          true
        );
        return {response, error: null};
      }),
      retryWhen(errors => {
        return errors.pipe(
          mergeMap((error, errorCount) => {
            const notFoundErrorLimits = 5;

            if (error.status === 404 && errorCount < notFoundErrorLimits) {
              return of(error).pipe(delay(3000));
            }

            return throwError(error);
          })
        )
      }),
      catchError((error: HttpErrorResponse) => {
        this.dialogWrapperService.pendingState = false;
        this.snackbar.showMessage(
          this.translate.instant('shared.video_player.message.get_video_shot.failed', {
            text: parseError(error)
          })
        );
        return of({error, response: null});
      }),
      takeUntil(request.requestCancellation)
    );
  }

  @Effect()
  public VideoPlayerGetPlaylists$ = this.actions$.pipe(
    ofType<VideoPlayerGetPlaylists>(VideoPlayerActionTypes.VideoPlayerGetPlaylists),
    exhaustMap((action: VideoPlayerGetPlaylists) =>
      this.getPlaylists(action.rdvaUri, action.cameraId, action.timeZone)
        .pipe(map((response) =>
          response.response === null && !!response.error ?
            new VideoPlayerGetPlaylistsFailure() :
            new VideoPlayerGetPlaylistsSuccess(response.response)))
    )
  );

  @Effect()
  public VideoPlayerCreateVideoshotTask$ = this.actions$.pipe(
    ofType<VideoPlayerCreateVideoshotTask>(VideoPlayerActionTypes.VideoPlayerCreateVideoshotTask),
    switchMap((action: VideoPlayerCreateVideoshotTask) => {
      this.dialogWrapperService.pendingState = true;
      return from(this.addVideoshotBatch(action.request))
        .pipe(map((response) =>
          response.response === null && !!response.error  ?
            new VideoPlayerCreateVideoshotTaskFailure() :
            new VideoPlayerCreateVideoshotTaskSuccess()));
    }),
  );

  @Effect()
  public VideoPlayerGetVideoshot$ = this.actions$.pipe(
    ofType<VideoPlayerGetVideoshot>(VideoPlayerActionTypes.VideoPlayerGetVideoshot),
    switchMap((action: VideoPlayerGetVideoshot) => {
      this.dialogWrapperService.pendingState = true;
      return this.getVideoshot(action.request).pipe(
        map((response) =>
          response.response === null && !!response.error ?
            new VideoPlayerGetVideoshotFailure(response.error) :
            new VideoPlayerGetVideoshotSuccess()),
      );
    }),
  );

  private calculateDelay(duration: number): number {
    const delayIfLess10Minutes = 1000;
    const delayIfLess20Minutes = 2000;
    const delayIfMore20Minutes = 3000;

    if (duration < 60 * 10) {
      return delayIfLess10Minutes;
    }

    if (duration < 60 * 20) {
      return delayIfLess20Minutes;
    }

    return delayIfMore20Minutes;
  }
}