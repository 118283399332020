<div class="video-manager-page__wrapper">
  <ng-container *ngIf="videoManagerPageDataService.loaded | async">
    <div *ngFor="let grid of grids; let i = index" class="video-manager-page__card__wrapper">
      <p-card class="video-manager-page__card">
        <div class="video-manager-page__card__content__wrapper">
          <div class="video-manager-page__card__content__previews__wrapper" [ngClass]="GetViewScheme(grid?.layout)">
            <ng-container *ngFor="let camera of grid?.cameras; let cameraIndex = index">
              <ng-container *ngIf="cameraIndex + 1 <= GetMaxBlockByViewScheme(grid?.layout)">
                <div *ngIf="!!camera?.camera" class="video-manager-page__card__content__previews__item__wrapper">
                  <app-camera-thumbnail style="width: 100%; height: 100%;" [showHover]="false" [noActionOnClick]="true"
                                        [camera]="camera?.camera" [mode]="videoMode.basic"></app-camera-thumbnail>
                </div>
                <div *ngIf="!camera?.camera" class="video-manager-page__card__content__previews__item__wrapper"></div>
              </ng-container>
            </ng-container>
          </div>
          <div class="video-manager-page__card__content__footer__wrapper">
            <div class="video-manager-page__card__content__footer--title" [appTooltipForTT]="grid?.name">{{grid?.name}}</div>
            <div class="video-manager-page__card__content__footer__actions__wrapper">
              <p-button icon="pi pi-times" styleClass="p-button-rounded p-button-text p-button-danger"
                        [pTooltip]="'video.manager.tooltip.remove_screen' | translate"
                        (onClick)="removeScreen(grid)"></p-button>
              <p-button icon="pi pi-pencil" styleClass="p-button-rounded p-button-text"
                        [routerLink]="['/services/video-manager/edit', grid?.id]"
                        [pTooltip]="'video.manager.tooltip.edit' | translate"></p-button>
              <p-button icon="pi pi-desktop" styleClass="p-button-rounded p-button-text"
                        [routerLink]="['/services/video-manager/show', grid?.id]"
                        [pTooltip]="'video.manager.tooltip.open' | translate"></p-button>
            </div>
          </div>
        </div>
      </p-card>
    </div>
    <div class="video-manager-page__card__wrapper">
      <p-card class="video-manager-page__card">
        <a [routerLink]="['/services/video-manager/add']" class="video-manager-page__card__content__empty__wrapper">
          <i class="pi pi-plus-circle video-manager-page__card__content__empty--icon" aria-hidden="false"></i>
          <div class="video-manager-page__card__content__empty--title">{{'video.manager.tooltip.add' | translate}}</div>
        </a>
      </p-card>
    </div>
  </ng-container>
  <ng-container *ngIf="!(videoManagerPageDataService.loaded | async)">
    <div class="video-manager-page__card__wrapper">
      <p-skeleton width="100%" height="100%" [pTooltip]="'video.manager.common.cameras_is_loading' | translate"></p-skeleton>
    </div>
  </ng-container>
</div>

<p-confirmDialog #cd [style]="{'max-width': '650px'}" key="confDialog">
  <ng-template pTemplate="footer">
    <div class="video-manager-page__confirmation__footer__wrapper">
      <div class="video-manager-page__confirmation__footer__right__wrapper">
        <p-button icon="pi pi-times" styleClass="p-button-outlined" [label]="'video.manager.common.no' | translate"
                  (click)="cd.reject()"></p-button>
        <p-button icon="pi pi-check" [label]="'video.manager.common.yes' | translate"
                  (click)="cd.accept()"></p-button>
      </div>
    </div>
  </ng-template>
</p-confirmDialog>

