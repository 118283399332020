<button
  mat-raised-button
  [color]="color"
  (click)="clickEvent.emit()"
>
  <div
    class="button-with-spinner-loader"
    [class.button-with-spinner-loader--with-loader]="showSpinner"
  >
    <span class="button-with-spinner-loader__text">
      <ng-content></ng-content>
    </span>

    <app-component-loader
      *ngIf="showSpinner"
      [sizePx]="spinnerSize"
      class="button-with-spinner-loader__loader"
    ></app-component-loader>
  </div>
</button>