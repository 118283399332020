<div
  class="payments-settings"
  *ngIf="{
    pending: updateConfigurationPending$ | async,
    paymentPeriodShift: configuration?.paymentPeriodShift,
    cronErrors: cronErrors$ | async,
    disabled: disabled$ | async
  } as state"
>
  <mat-card class="card-container">
    <mat-card-header>
      <div class="payments-settings-header">
        <mat-card-title>
          {{ 'payments.settings.title' | translate }}
        </mat-card-title>
        <span>{{ 'payments.settings.mode' | translate }}:<strong> {{
          state.paymentPeriodShift
            ? ('payments.settings.enum.mode.prepay' | translate)
            : ('payments.settings.enum.mode.postpay' | translate)
          }}</strong></span>
      </div>
    </mat-card-header>
    <hr>
    <mat-card-content>
      <form
        class="payments-settings-form"
        [formGroup]="form"
        (ngSubmit)="onSubmit(!!state.cronErrors?.length)"
        autocomplete="off"
      >
        <div class="payments-settings-form-content">
          <div class="payments-settings-form-content-tasks">
            <app-cron-date-input
              label="{{ 'payments.settings.field.invoicing_date' | translate }}"
              formControlName="invoicingDate"
              required
            ></app-cron-date-input>
            <hr>
            <div class="payments-settings-form-content-tasks-warning" *ngIf="isRisksAccepts | async">
              <span>
                {{ 'payments.settings.message.warning_manual_mode' | translate }}
              </span>
              <a href="{{paymentConnectionLink}}">
                {{ 'payments.settings.link.payment_connection_link' | translate }}
              </a>
            </div>
            <div class="payments-settings-form-content-tasks-additional">
              <div class="payments-settings-form-content-tasks-additional-fields">
                <app-cron-date-input
                  label="{{ 'payments.settings.field.remining_date' | translate }}"
                  formControlName="reminingDate"
                  [editMode]="isRisksAccepts | async"
                  required
                ></app-cron-date-input>

                <app-cron-date-input
                  label="{{ 'payments.settings.field.blocking_date' | translate }}"
                  formControlName="blockingDate"
                  [editMode]="isRisksAccepts | async"
                  required
                ></app-cron-date-input>
              </div>
              <div class="payments-settings-form-content-tasks-additional-edit">
                <a
                  *ngIf="!(isRisksAccepts | async)"
                  (click)="onEdit($event)"
                >
                  <mat-icon aria-label="Edit">edit</mat-icon>
                  <span>{{ 'payments.settings.button.edit' | translate }}</span>
                </a>
              </div>
            </div>
            <div class="payments-settings-form-content-tasks-recurrent">
              <hr *ngIf="(isRisksAccepts | async)" />
              <h2>
                {{ 'payments.settings.recurring.title' | translate }}
              </h2>
              <div class="payments-settings-form-content-tasks-recurrent-control">
                <ng-container *ngIf="!(isRisksAccepts | async)">
                <img
                  [ngSrc]="'assets/icons/' + (getFormValue('isRecurringEnabled') ? 'ic-checked-circle' : 'ic-close-circle') + '.svg'"
                  alt="circle"
                  height="16"
                  width="16"
                >
                </ng-container>
                <ng-container *ngIf="(isRisksAccepts | async)">
                  <p-checkbox
                    formControlName="isRecurringEnabled"
                    [binary]="true"
                    [readonly]="!(isRisksAccepts | async)"
                    [disabled]="!(isRisksAccepts | async)"
                  ></p-checkbox>
                </ng-container>
                <span>
                   {{ 'payments.settings.recurring.checkbox' | translate }}
                </span>
              </div>
              <div *ngIf="(isRisksAccepts | async) && getFormValue('isRecurringEnabled')" class="solution">
                <div class="solution__text">
                  <h3 [innerHTML]="'payments.settings.recurring.message.title' | translate"></h3>
                  <p>
                    <span [innerHTML]="'payments.settings.recurring.message.text' | translate"></span>
                    <a
                      [href]="autoPaymentsLink" target="_blank"
                    >{{ 'payments.settings.recurring.message.link' | translate }}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ng-container *ngIf="state.cronErrors?.length then help; else dateResult"></ng-container>
          <ng-template #help>
            <app-possible-cron-solutions
              *ngIf="state.cronErrors?.length"
              [solutionDescription]="state.cronErrors"
              (setAutomatically)="onSetAutomatically()">
            </app-possible-cron-solutions>
          </ng-template>
          <ng-template #dateResult>
            <app-schedule-for-payment
              [isPrePayment]="state.paymentPeriodShift"
              [invoicingDate]="getFormValue('invoicingDate')"
              [reminingDate]="getFormValue('reminingDate')"
              [blockingDate]="getFormValue('blockingDate')">
            </app-schedule-for-payment>
          </ng-template>
        </div>
        <div class="payments-settings-form-actions">
          <button mat-raised-button
            class="mat-button-lg payments-settings-form-actions__action"
            type="submit"
            color="primary"
            [disabled]="isDisable(state.pending, state.disabled) || form.invalid"
          >
            <ng-container *ngIf="state.pending">
              <app-component-loader [whiteCircle]="true"></app-component-loader>
            </ng-container>

            <ng-container *ngIf="!state.pending">
              {{ 'payments.settings.button.save' | translate }}
            </ng-container>
          </button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
