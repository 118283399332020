<div class="info-block">
  <h3 class="info-block__text">
    {{
      isUIDGenerateMode
        ? ('shared.keys.ip.ip.sputnik.generate.urls.info.0' | translate)
        : ('shared.keys.ip.ip.sputnik.generate.urls.info.1' | translate)
    }}
  </h3>
  <div class="info-block-content">
    <a class="link" [href]="link">
      {{
        isUIDGenerateMode
          ? ('shared.keys.ip.ip.sputnik.generate.urls.link.0' | translate)
          : ('shared.keys.ip.ip.sputnik.generate.urls.link.1' | translate)
      }}
    </a>
  </div>
</div>

<form
  class="popup"
  [formGroup]="formGroup"
  (ngSubmit)="onSubmit()"
>
  <mat-form-field appearance="outline" *ngIf="isUIDGenerateMode">
    <mat-label>
      {{ 'shared.keys.ip.ip.sputnik.generate.urls.field.bearer_token' | translate }}
    </mat-label>
    <input
      matInput
      required
      type="text"
      spellcheck="false"
      autocapitalize="off"
      autocomplete="new-ip-address"
      formControlName="bearerToken"
      placeholder="{{ 'shared.keys.ip.ip.sputnik.generate.urls.placeholder.bearer_token' | translate }}"
    >
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'shared.keys.ip.ip.sputnik.generate.urls.field.ip' | translate }}
    </mat-label>
    <input
      matInput
      required
      type="text"
      spellcheck="false"
      autocapitalize="off"
      formControlName="ip"
      autocomplete="new-ip-address"
    />
  </mat-form-field>

  <div class="popup-actions">
    <button
      type="submit"
      mat-raised-button
      color="primary"
      [disabled]="!formGroup.valid"
      class="popup-actions__button"
    >
      {{ 'shared.keys.ip.ip.sputnik.generate.urls.button.submit' | translate }}
    </button>
  </div>
</form>
