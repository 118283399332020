import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

export const batchFormGroup = () => {
  const batch = {
    batchId: new UntypedFormControl(null),
    filter: new UntypedFormControl(null)
  };
  return {
    form: new UntypedFormGroup(batch),
    batch: batch
  };
};
