import {AwxJobStatus} from '@app/shared/entities/rd';
import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'awx_job_status'
})
export class AwxJobStatusPipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) {}

  transform(status: AwxJobStatus) {
    switch (status) {
      case AwxJobStatus.PENDING:
        return this.translate.instant('enum.awx_job_status.pending');
      case AwxJobStatus.RUNNING:
        return this.translate.instant('enum.awx_job_status.running');
      case AwxJobStatus.FAILED:
        return this.translate.instant('enum.awx_job_status.failed');
      case AwxJobStatus.SUCCESSFUL:
        return this.translate.instant('enum.awx_job_status.successful');
      default:
        return this.translate.instant('enum.awx_job_status.default');
    }
  }
}
