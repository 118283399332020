<mat-card class="card-container contacts">
    <div class="header">
      <div class="title-block">
        {{ 'company.about.page.contacts.edit.title' | translate }}
      </div>
    </div>
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="mail-list" formArrayName="mailsList">
          <ng-container
            *ngFor="let mail of form.get('mailsList')['controls']; index as i">
            <div class="mail-input">
              <mat-icon svgIcon="offline" class="mail-input__icon" (click)="deleteInput(i)" />
              <mat-form-field
                flats-filters-search
                class="none-padding mail-input__input"
                appearance="outline"
              >
                <input
                  type="email"
                  matInput
                  autocomplete="off"
                  autocorrect="off"
                  autocapitalize="off"
                  spellcheck="false"
                  formControlName="{{ i }}" />
              </mat-form-field>
            </div>
          </ng-container>

          <button type="button" class="btn-add" (click)="addInput()">
            <mat-icon svgIcon="add-sm" class="btn-add__icon" />
            {{ 'company.about.page.contacts.edit.button.add' | translate }}
          </button>

          <div class="btn-group">
            <button mat-raised-button color="primary-inverse" (click)="toggleContactsEdit()">
              {{ 'company.about.page.contacts.edit.button.cancel' | translate }}
            </button>
            <button type="submit" mat-raised-button color="primary">
              {{ 'company.about.page.contacts.edit.button.submit' | translate }}
            </button>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
