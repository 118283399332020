import { GitlabComponentsVersions } from '@app/shared/entities/integrations';
import { Camera, Company, EntrancesStatus, KeysResponse, PbxOnRdaResponse, RdaResponse } from '@app/shared/entities/rd';
import { EntrancePageResponse, Flat, LogsResponse, PageData } from '@app/shared/models';
import { NavbarLink } from '@app/shared/ui';
import { Action } from '@ngrx/store';
import { EntrancesPageModel } from '../models';

export enum EntrancesActionTypes {
    EntrancesLoadPage = '[Entrances] EntrancesLoadPage',
    EntrancesLoadPageSuccess = '[Entrances] EntrancesLoadPage Success',
    EntrancesLoadPageFailure = '[Entrances] EntrancesLoadPage Failure',

    EntrancesLoadCounts = '[Entrances] EntrancesLoadCounts',
    EntrancesLoadCountsSuccess = '[Entrances] EntrancesLoadCounts Success',
    EntrancesLoadCountsFailure = '[Entrances] EntrancesLoadCounts Failure',

    EntrancesLoadEntrance = '[Entrances] EntrancesLoadEntrance',
    EntrancesLoadEntranceSuccess = '[Entrances] EntrancesLoadEntrance Success',
    EntrancesLoadEntranceFailure = '[Entrances] EntrancesLoadEntrance Failure',

    EntrancesLoadRdaSuccess = '[Entrances] EntrancesLoadRdaSuccess Success',

    EntrancesLoadCompany = '[Entrances] EntrancesLoadCompany',
    EntrancesLoadCompanySuccess = '[Entrances] EntrancesLoadCompany Success',
    EntrancesLoadCompanyFailure = '[Entrances] EntrancesLoadCompany Failure',

    EntrancesLoadKeys = '[Entrances] EntrancesLoadKeys',
    EntrancesLoadKeysSuccess = '[Entrances] EntrancesLoadKeys Success',
    EntrancesLoadKeysFailure = '[Entrances] EntrancesLoadKeys Failure',

    EntrancesLoadFlats = '[Entrances] EntrancesLoadFlats',
    EntrancesLoadFlatsSuccess = '[Entrances] EntrancesLoadFlats Success',
    EntrancesLoadFlatsFailure = '[Entrances] EntrancesLoadFlats Failure',

    EntrancesLoadGitlabVersions = '[Entrances] EntrancesLoadGitlabVersions',
    EntrancesLoadGitlabVersionsSuccess = '[Entrances] EntrancesLoadGitlabVersions Success',
    EntrancesLoadGitlabVersionsFailure = '[Entrances] EntrancesLoadGitlabVersions Failure',

    EntrancesLoadPbxOnRda = '[Entrances] EntrancesLoadPbxOnRda',
    EntrancesLoadPbxOnRdaSuccess = '[Entrances] EntrancesLoadPbxOnRda Success',
    EntrancesLoadPbxOnRdaFailure = '[Entrances] EntrancesLoadPbxOnRda Failure',

    EntrancesLoadServiceId = '[Entrances] EntrancesLoadServiceId',
    EntrancesLoadServiceIdSuccess = '[Entrances] EntrancesLoadServiceId Success',
    EntrancesLoadServiceIdFailure = '[Entrances] EntrancesLoadServiceId Failure',

    EntrancesLoadCameras = '[Entrances] EntrancesLoadCameras',
    EntrancesLoadCamerasSuccess = '[Entrances] EntrancesLoadCameras Success',
    EntrancesLoadCamerasFailure = '[Entrances] EntrancesLoadCameras Failure',

    EntrancesLoadCamerasWithErrorsSuccess = '[Entrances] EntrancesLoadCamerasWithErrors Success',

    EntrancesLoadLogs = '[Entrances] EntrancesLoadLogs',
    EntrancesLoadLogsSuccess = '[Entrances] EntrancesLoadLogs Success',
    EntrancesLoadLogsFailure = '[Entrances] EntrancesLoadLogs Failure',

    EntrancesSetIncorrectId = '[Entrances] EntrancesSetIncorrectId Failure',
    EntrancesClearEntranceStore = '[Entrances] EntrancesClearEntranceStore',
}

export class EntrancesLoadPage implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadPage;

    constructor(
        public page: number,
        public size: number,
        public status: EntrancesStatus,
        public address?: string
    ) { }
}

export class EntrancesLoadPageSuccess implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadPageSuccess;

    constructor(public response: EntrancesPageModel[], public pageData: PageData) { }
}

export class EntrancesLoadPageFailure implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadPageFailure;

    constructor() { }
}

export class EntrancesLoadCounts implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadCounts;

    constructor() { }
}

export class EntrancesLoadCountsSuccess implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadCountsSuccess;

    constructor(public response: NavbarLink[]) { }
}

export class EntrancesLoadCountsFailure implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadCountsFailure;

    constructor() { }
}

export class EntrancesLoadEntrance implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadEntrance;

    constructor(public entranceId: number) { }
}

export class EntrancesLoadEntranceSuccess implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadEntranceSuccess;

    constructor(public response: EntrancePageResponse) { }
}

export class EntrancesLoadEntranceFailure implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadEntranceFailure;

    constructor() { }
}

export class EntrancesLoadRdaSuccess implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadRdaSuccess;

    constructor(public rda: RdaResponse) { }
}

export class EntrancesLoadCompany implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadCompany;

    constructor(public companyId: number) { }
}

export class EntrancesLoadCompanySuccess implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadCompanySuccess;

    constructor(public response: Company) { }
}

export class EntrancesLoadCompanyFailure implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadCompanyFailure;

    constructor() { }
}

export class EntrancesLoadKeys implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadKeys;

    constructor(public rdaUid: string) { }
}

export class EntrancesLoadKeysSuccess implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadKeysSuccess;

    constructor(public response: KeysResponse[]) { }
}

export class EntrancesLoadKeysFailure implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadKeysFailure;

    constructor() { }
}

export class EntrancesLoadFlats implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadFlats;

    constructor(public entranceId: number) { }
}

export class EntrancesLoadFlatsSuccess implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadFlatsSuccess;

    constructor(public response: Flat[]) { }
}

export class EntrancesLoadFlatsFailure implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadFlatsFailure;

    constructor() { }
}

export class EntrancesLoadGitlabVersions implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadGitlabVersions;

    constructor() { }
}

export class EntrancesLoadGitlabVersionsSuccess implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadGitlabVersionsSuccess;

    constructor(public response: GitlabComponentsVersions) { }
}

export class EntrancesLoadGitlabVersionsFailure implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadGitlabVersionsFailure;

    constructor() { }
}

export class EntrancesLoadPbxOnRda implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadPbxOnRda;

    constructor(public rdaUid: string) { }
}

export class EntrancesLoadPbxOnRdaSuccess implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadPbxOnRdaSuccess;

    constructor(public response: PbxOnRdaResponse) { }
}

export class EntrancesLoadPbxOnRdaFailure implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadPbxOnRdaFailure;

    constructor() { }
}

export class EntrancesLoadServiceId implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadServiceId;

    constructor(public entranceId: number) { }
}

export class EntrancesLoadServiceIdSuccess implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadServiceIdSuccess;

    constructor() { }
}

export class EntrancesLoadServiceIdFailure implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadServiceIdFailure;

    constructor() { }
}

export class EntrancesLoadCameras implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadCameras;

    constructor(public entranceId: number, public serviceId: number) { }
}

export class EntrancesLoadCamerasSuccess implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadCamerasSuccess;

    constructor(public response: Camera[]) { }
}

export class EntrancesLoadCamerasFailure implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadCamerasFailure;

    constructor() { }
}

export class EntrancesLoadCamerasWithErrorsSuccess implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadCamerasWithErrorsSuccess;

    constructor(public response: Camera[]) { }
}

export class EntrancesLoadLogs implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadLogs;

    constructor(public rdaUid: string, public timeRange: number) { }
}

export class EntrancesLoadLogsSuccess implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadLogsSuccess;

    constructor(public response: LogsResponse[]) { }
}

export class EntrancesLoadLogsFailure implements Action {
    readonly type = EntrancesActionTypes.EntrancesLoadLogsFailure;

    constructor() { }
}

export class EntrancesSetIncorrectId implements Action {
    readonly type = EntrancesActionTypes.EntrancesSetIncorrectId;

    constructor(public incorrectId: boolean) { }
}

export class EntrancesClearEntranceStore implements Action {
    readonly type = EntrancesActionTypes.EntrancesClearEntranceStore;

    constructor() { }
}

export type EntrancesActions =
    | EntrancesLoadPage
    | EntrancesLoadPageSuccess
    | EntrancesLoadPageFailure

    | EntrancesLoadCounts
    | EntrancesLoadCountsSuccess
    | EntrancesLoadCountsFailure

    | EntrancesLoadEntrance
    | EntrancesLoadEntranceSuccess
    | EntrancesLoadEntranceFailure

    | EntrancesLoadRdaSuccess

    | EntrancesLoadCompany
    | EntrancesLoadCompanySuccess
    | EntrancesLoadCompanyFailure

    | EntrancesLoadKeys
    | EntrancesLoadKeysSuccess
    | EntrancesLoadKeysFailure

    | EntrancesLoadFlats
    | EntrancesLoadFlatsSuccess
    | EntrancesLoadFlatsFailure

    | EntrancesLoadGitlabVersions
    | EntrancesLoadGitlabVersionsSuccess
    | EntrancesLoadGitlabVersionsFailure

    | EntrancesLoadPbxOnRda
    | EntrancesLoadPbxOnRdaSuccess
    | EntrancesLoadPbxOnRdaFailure

    | EntrancesLoadServiceId
    | EntrancesLoadServiceIdSuccess
    | EntrancesLoadServiceIdFailure

    | EntrancesLoadCameras
    | EntrancesLoadCamerasSuccess
    | EntrancesLoadCamerasFailure

    | EntrancesLoadCamerasWithErrorsSuccess

    | EntrancesLoadLogs
    | EntrancesLoadLogsSuccess
    | EntrancesLoadLogsFailure

    | EntrancesSetIncorrectId
    | EntrancesClearEntranceStore;
