import * as root from '@app/store/reducers';
import { VideoSurveillancePageModel } from './../models/video-surveillance-page.model';
import { PageData } from '@app/shared/models';

export interface VideoSurveillanceModuleState {
    videoSurveillance: VideoSurveillancePageState;
}

export interface VideoSurveillanceRootState extends root.State {
    'video-surveillance': VideoSurveillanceModuleState;
}

export interface VideoSurveillancePageState {
    videoSurveillanceList: VideoSurveillancePageModel[];
    pageData: PageData;
    loading: boolean;
}

export const videoSurveillanceListInitialState: VideoSurveillancePageState = {
    videoSurveillanceList: undefined,
    loading: undefined,
    pageData: undefined
};
