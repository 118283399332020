import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared';
import { IntercomKeysContainerModule, RdaWarningsContentModule } from '@app/shared/components';
import { CamerasTableContainerModule } from '@app/shared/containers';
import { SharedDirectivesModule } from '@app/shared/directives';
import { DialogWrapperModule, InfoBlockModule, NavbarModule, PaginatedPageWrapperModule, TextBadgeModule } from '@app/shared/ui';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { ServiceRdaLogsModule } from '../services/components';
import { CamerasCardComponent, EntranceCardComponent, EntrancePageComponent, EntrancePageHelper, EntrancesPageComponent, EntranceSupportRequestPopupComponent, EntranceWarningsComponent, FlatsCardComponent, RdaCardComponent, RdaKeysCardComponent } from './components';
import { EntrancesPageService } from './services';
import { EntrancesFacade, entrancesReducers } from './store';
import { EntrancesEffects } from './store/entrances.effects';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        FormsModule,
        RouterModule,
        NavbarModule,
        CommonModule,
        MaterialModule,
        InfoBlockModule,
        ClipboardModule,
        TextBadgeModule,
        SharedPipesModule,
        ReactiveFormsModule,
        DialogWrapperModule,
        ServiceRdaLogsModule,
        SharedDirectivesModule,
        NgxSkeletonLoaderModule,
        IntercomKeysContainerModule,
        PaginatedPageWrapperModule,
        CamerasTableContainerModule,
        RdaWarningsContentModule,
        FormsModule,
        EffectsModule.forFeature([
            EntrancesEffects
        ]),
        StoreModule.forFeature('entrances', entrancesReducers),
        NgxTranslateModule,
    ],
    providers: [
        EntrancesFacade,
        EntrancesPageService,
        EntrancePageHelper
    ],
    declarations: [
        RdaCardComponent,
        FlatsCardComponent,
        CamerasCardComponent,
        RdaKeysCardComponent,
        EntrancePageComponent,
        EntranceCardComponent,
        EntrancesPageComponent,
        EntranceWarningsComponent,
        EntranceSupportRequestPopupComponent
    ],
    exports: [
        EntrancesPageComponent,
        EntrancePageComponent
    ]
})
export class EntrancesModule { }
