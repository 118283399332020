import { HttpErrorResponse } from '@angular/common/http';
import { BatchRequest, BatchResponse } from '@app/views/intercom/models';
import { RdaResponse } from '@app/shared/entities/rd/rda';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  GetBatchesPage = '[Intercom] GetBatchesPage',
  GetBatchesPageSuccess = '[Intercom] GetBatchesPage Success',
  GetBatchesPageFailure = '[Intercom] GetBatchesPage Failure',

  GetAllBatches = '[Intercom] GetAllBatches',
  GetAllBatchesSuccess = '[Intercom] GetAllBatches Success',
  GetAllBatchesFailure = '[Intercom] GetAllBatches Failure',

  PutNewBatch = '[Intercom] PutNewBatch',
  PutNewBatchSuccess = '[Intercom] PutNewBatch Success',
  PutNewBatchFailure = '[Intercom] PutNewBatch Failure',
  PutNewBatchClear = '[Intercom] PutNewBatch Clear',

  GetBatchAdapters = '[Intercom] GetBatchAdapters',
  GetBatchAdaptersSuccess = '[Intercom] GetBatchAdapters Success',
  GetBatchAdaptersFailure = '[Intercom] GetBatchAdapters Failure',
  GetBatchAdaptersClear = '[Intercom] GetBatchAdapters Clear'
}

export class GetBatchesPage implements Action {
  readonly type = ActionTypes.GetBatchesPage;

  constructor(public page: number = 0, public size: number = 10, public search: string) { }
}

export class GetBatchesPageSuccess implements Action {
  readonly type = ActionTypes.GetBatchesPageSuccess;

  constructor(public payload: BatchResponse[], public totalCount: number) { }
}

export class GetBatchesPageFailure implements Action {
  readonly type = ActionTypes.GetBatchesPageFailure;

  constructor(public payload: string) { }
}

export class GetAllBatches implements Action {
  readonly type = ActionTypes.GetAllBatches;

  constructor(public search?: string) { }
}

export class GetAllBatchesSuccess implements Action {
  readonly type = ActionTypes.GetAllBatchesSuccess;

  constructor(public payload: BatchResponse[]) { }
}

export class GetAllBatchesFailure implements Action {
  readonly type = ActionTypes.GetAllBatchesFailure;

  constructor(public payload: HttpErrorResponse) { }
}

export class PutNewBatch implements Action {
  readonly type = ActionTypes.PutNewBatch;

  constructor(public request: BatchRequest) { }
}

export class PutNewBatchSuccess implements Action {
  readonly type = ActionTypes.PutNewBatchSuccess;

  constructor() { }
}

export class PutNewBatchFailure implements Action {
  readonly type = ActionTypes.PutNewBatchFailure;

  constructor(public payload: HttpErrorResponse) { }
}

export class PutNewBatchClear implements Action {
  readonly type = ActionTypes.PutNewBatchClear;

  constructor() { }
}

export class GetBatchAdapters implements Action {
  readonly type = ActionTypes.GetBatchAdapters;

  constructor(public batchId: number, public search?: string) { }
}

export class GetBatchAdaptersSuccess implements Action {
  readonly type = ActionTypes.GetBatchAdaptersSuccess;

  constructor(public payload: RdaResponse[]) { }
}

export class GetBatchAdaptersFailure implements Action {
  readonly type = ActionTypes.GetBatchAdaptersFailure;

  constructor(public payload: HttpErrorResponse) { }
}

export class GetBatchAdaptersClear implements Action {
  readonly type = ActionTypes.GetBatchAdaptersClear;

  constructor() { }
}

export type Actions =
  | GetBatchesPage
  | GetBatchesPageSuccess
  | GetBatchesPageFailure
  | GetAllBatches
  | GetAllBatchesSuccess
  | GetAllBatchesFailure
  | PutNewBatch
  | PutNewBatchSuccess
  | PutNewBatchFailure
  | PutNewBatchClear
  | GetBatchAdapters
  | GetBatchAdaptersSuccess
  | GetBatchAdaptersFailure
  | GetBatchAdaptersClear;
