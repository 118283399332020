import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { ComponentLoaderModule } from '@app/shared/ui';
import { SelectSearchComponent } from './select-search.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentLoaderModule,
        MaterialModule,
        NgxTranslateModule,
    ],
    declarations: [SelectSearchComponent],
    exports: [SelectSearchComponent]
})
export class SelectSearchModule { }
