import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {NgClass, NgIf, NgSwitch, NgSwitchCase} from '@angular/common';
import {CalendarModule} from 'primeng/calendar';
import {Checkbox, CheckboxModule} from 'primeng/checkbox';
import {
  FilterButtonComponent
} from '@app/views/support-service/components/requests/filters-block/filter-button/filter-button.component';
import {DropdownModule} from 'primeng/dropdown';
import {IZammadUserResponse} from '@app/views/support-service/services/support-api.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MultiSelectModule} from 'primeng/multiselect';
import {AppSizeStates, DeviceEventService} from '@app/shared/services/device-event-service.service';

export enum FiltersBlockType {
  SUPPORT = 'support',
}

export const SUPPORT_FORM = {
  isShowOnlyOwnerTasks: new FormControl(null, []),
  dateOfCreating: new FormControl(null, []),
  user: new FormControl(null, []),
};

@Component({
  selector: 'app-filters-block',
  templateUrl: './filters-block.component.html',
  styleUrls: ['./filters-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    ReactiveFormsModule,
    NgSwitch,
    NgSwitchCase,
    CalendarModule,
    CheckboxModule,
    FilterButtonComponent,
    DropdownModule,
    MultiSelectModule,
    NgIf,
    NgClass
  ],
  providers: [DeviceEventService],
  standalone: true
})
export class FiltersBlockComponent<T> implements OnInit {
  @Input() filtersBlockType: FiltersBlockType = null;
  @Input() employees: IZammadUserResponse[] = [];
  @Input() set disable(state: boolean) {
    state ? this.form?.disable({ emitEvent: false }) : this.form?.enable({ emitEvent: false });
  }

  public form: FormGroup;
  public FiltersBlockType = FiltersBlockType;
  private unSubscribe$: Subject<void> = new Subject<void>();
  protected readonly AppSizeStates = AppSizeStates;

  @Output() changeForm: EventEmitter<T> = new EventEmitter<T>();

  constructor(
    private formBuilder: FormBuilder,
    protected deviceEventService: DeviceEventService,
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.initFormListener();
  }

  public onClear(): void {
    this.form.reset();
  }

  public onShowOnlyOwnerTasksClick(): void {
    if (!this.form.disabled) {
      this.form.get('isShowOnlyOwnerTasks').setValue(!this.form.get('isShowOnlyOwnerTasks').value);
    }
  }

  private initForm(): void {
    switch (this.filtersBlockType) {
      case FiltersBlockType.SUPPORT:
        this.form = this.formBuilder.group(SUPPORT_FORM);
        break;
    }
  }

  private initFormListener(): void {
    this.form.valueChanges.pipe(
      takeUntil(this.unSubscribe$)
    ).subscribe((value: T) => {
      this.changeForm.emit(value);
    });
  }
}
