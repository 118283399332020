import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DialogWrapperModule } from '@app/shared/ui';
import { SoftwareIntercomTariffPopupContentModule } from './software-intercom-tariff-popup-content';
import { SoftwareIntercomTariffPopupComponent } from './software-intercom-tariff-popup.component';

@NgModule({
    imports: [
        CommonModule,
        DialogWrapperModule,
        SoftwareIntercomTariffPopupContentModule
    ],
    declarations: [
        SoftwareIntercomTariffPopupComponent
    ],
    exports: [
        SoftwareIntercomTariffPopupComponent
    ]
})
export class SoftwareIntercomTariffPopupModule { }
