import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActiveHistoryChartData } from '@app/shared/containers/active-history-chart';
import { ActiveHistoryResponse } from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';
import { ServiceActivitySource } from '@app/views/services/models';

@Component({
  selector: 'app-service-active-history-chart',
  templateUrl: './service-active-history-chart.component.html',
  styleUrls: ['./service-active-history-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceActiveHistoryChartComponent {
  @Input() sources!: ServiceActivitySource[];
  @Input() blocksCount: number;
  @Input() set activeHistory(data: Dictionary<ActiveHistoryResponse[]>) {
    if (!data) {
      return;
    }

    this._data = new Map();

    Object.keys(data).map(dataKey => {
      if (data[dataKey]) {
        this._data[dataKey] = data[dataKey].map(item => ({
          state: Number.parseFloat(item.value) > 0 ? true : false,
          value: item.value,
          timestamp: item.timestamp
        })).reverse();
      } else {
        this._data[dataKey] = null;
      }
    });
  }
  @Input() title: string;

  @Output() reloadChart: EventEmitter<{ blocksCount: number }> = new EventEmitter();
  private _data: Map<string, ActiveHistoryChartData[]> = new Map();

  onReloadChart(blocksCount: number) {
    this.reloadChart.emit({ blocksCount });
  }

  get data(): Map<string, ActiveHistoryChartData[]> {
    return this._data;
  }
}
