import { FlatCardModel, FlatCardPhoneModel } from '@app/shared/components';
import { Dictionary } from '@app/shared/helpers';
import { ServiceFlatsItemServicesModel } from './service-flats-item-services.model';

export interface ServiceFlatsItemModel extends FlatCardModel {
    entranceId: number;
    ownerPhone: FlatCardPhoneModel;
    sharedPhones: FlatCardPhoneModel[];
    services: Dictionary<ServiceFlatsItemServicesModel>;
}
