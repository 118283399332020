<div class="custom-container">
  <button
    class="custom-container__button"
    [style]="style"
    pButton
    type="button"
    (click)="onClick()">
    <i *ngIf="icon" class="pi {{icon}}"></i>
    <span *ngIf="text">{{text}}</span>
  </button>
  <span *ngIf="badgeContent" class="badge">{{badgeContent}}</span>
</div>
