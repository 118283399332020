import { ResourcesHelper } from '@app/shared/entities/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ResourcePath } from '@app/shared/entities';
import { RdaResponse } from '@app/shared/entities/rd';

@Component({
  selector: 'app-gate-wizard-content-intercoms-reuse',
  templateUrl: './gate-wizard-content-intercoms-reuse.component.html',
  styleUrls: ['./gate-wizard-content-intercoms-reuse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GateWizardContentIntercomsReuseComponent {
  readonly resourcePath: typeof ResourcePath = ResourcePath;

  @Input() intercoms: RdaResponse[];

  constructor(
    public resourcesHelper: ResourcesHelper
  ) { }
}
