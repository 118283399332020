import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ResourcePath, ResourcesHelper } from '@app/shared/entities/common';
import { DialogWrapperData } from '@app/shared/ui';
import { Observable } from 'rxjs';
import { ServiceSubmitConnectionPopupService } from './service-submit-connection-popup.service';

@Component({
  selector: 'app-service-submit-connection-popup',
  templateUrl: './service-submit-connection-popup.component.html',
  styleUrls: ['./service-submit-connection-popup.component.scss'],
  providers: [ServiceSubmitConnectionPopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceSubmitConnectionPopupComponent implements OnInit {
  readonly resourcePath: typeof ResourcePath = ResourcePath;
  readonly popupState$: Observable<'loading' | 'close' | 'loaded'> = this.popupService.state$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<void, void>,
    public resourcesHelper: ResourcesHelper,
    private popupService: ServiceSubmitConnectionPopupService,
    private dialogRef: MatDialogRef<ServiceSubmitConnectionPopupComponent>
  ) { }

  ngOnInit() {
    this.popupService.addStateChangeListener((state: 'loading' | 'close' | 'loaded') =>
      state === 'close' && this.dialogRef.close()
    );
  }

  onConnect() {
    this.data.submit();
  }
}
