import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ActionTypes } from '../../actions/shield-models.actions';

export interface State {
  success: boolean;
  loading: boolean;
  error: HttpErrorResponse;
}

export const initialState: State = {
  success: null,
  loading: false,
  error: null,
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.CreateShieldModel: {
      return {
        ...state,
        success: null,
        error: null,
        loading: true,
      };
    }

    case ActionTypes.CreateShieldModelSuccess: {
      return {
        ...state,
        success: true,
        error: null,
        loading: false
      };
    }

    case ActionTypes.CreateShieldModelFailure: {
      return {
        ...state,
        success: false,
        error: action.error,
        loading: false,
      };
    }

    case ActionTypes.CreateShieldModelClear: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
