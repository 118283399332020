import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserRoleType } from '@app/core/models';
import { SnackbarService } from '@app/shared/components';
import { Company, RdaResponse, ServiceApiService, ServiceResponse } from '@app/shared/entities/rd';
import { Constants } from '@app/shared/helpers';
import { ServicesTypes } from '@app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData, DialogWrapperSize } from '@app/shared/ui';
import { UpdateServiceRequest } from '@app/views/services/models';
import { ServiceFacade } from '@app/views/services/store';
import {Observable, of, Subscription} from 'rxjs';
import { ServiceEditPopupComponent, ServiceSupportRequestPopupComponent } from '../../popups';
import {
  ServiceTariffPopupComponent,
  ServiceTariffPopupModel
} from '@app/views/services/components/popups/connections/service-tariff-popup';
import { FindCamerasPopupComponent } from '@app/views/services/submodules/mutual';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'environments/environment';
import {FormControl} from '@angular/forms';
import {catchError, takeUntil} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {
  BaseInfoPanelComponent
} from '@app/shared/components/info-panel/components/base-info-panel/base-info-panel.component';
import {NativeDialogService} from '@app/shared/components/info-panel/services/native-dialog.service';
import {Response} from '@app/shared/components/info-panel/models/response';

@Component({
  selector: 'app-service-config',
  templateUrl: './service-config.component.html',
  styleUrls: ['./service-config.component.scss'],
  providers: [NativeDialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceConfigComponent extends BaseInfoPanelComponent implements OnDestroy, OnInit {
  readonly userRoleType = UserRoleType;
  readonly environment;

  @Input() company: Company;
  @Input() name: string;
  @Input() rdas: RdaResponse[];
  @Input() customName: string;
  @Input() supportDisabled: boolean;
  @Input() connectDisabled: boolean;
  @Input() emergencyDisabled: boolean;
  @Input() renameDisabled: boolean;
  @Input() supportHidden: boolean;
  @Input() connectHidden: boolean;
  @Input() emergencyHidden: boolean;
  @Input() renameHidden: boolean;
  autoconnectionLoading = true;
  autoConnection: FormControl<boolean> = new FormControl<boolean>(null);
  mdWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.MD_W_DOWN);
  tariff$: Observable<number> = this.serviceFacade.serviceTariff$;

  // Remove after implementation RDBT-531
  // Replace tariff receipt from GetService
  dependantServicesExtended$ = this.serviceFacade.dependantServicesExtended$;

  public ServicesTypes = ServicesTypes;
  private subscriptionList: Subscription[] = [];

  @Input() serviceType: ServicesTypes;
  @Output() private openWizard: EventEmitter<void> = new EventEmitter();
  private serviceId: number;
  private serviceTariff: number;
  private dependantServices: Pick<ServiceResponse, 'id' | 'type'>[];

  constructor(
    private resolution: ResolutionService,
    private serviceFacade: ServiceFacade,
    private dialog: MatDialog,
    private snackbar: SnackbarService,
    private serviceApi: ServiceApiService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,
    dialogService: NativeDialogService
  ) {
    super(dialogService);
    this.subscriptionList.push(...[
      this.serviceFacade.serviceId$.subscribe(serviceId => {
        this.serviceId = serviceId;
      }),
      this.serviceFacade.serviceTariff$.subscribe(serviceTariff => {
        this.serviceTariff = serviceTariff;
      }),
      this.serviceFacade.dependantServices$.subscribe(dependant => {
        this.dependantServices = dependant;
      })
    ]);
    this.environment = environment;
  }

  ngOnInit(): void {
    this.serviceApi.getInfo(this.serviceId).subscribe((response) => {
      this.autoConnection.setValue(response.autoconnectionEnabled, { emitEvent: false });
      this.autoconnectionLoading = false;
      this.cd.detectChanges();
    });
    this.subscriptionList.push(this.autoConnection.valueChanges.subscribe((res: boolean) => {
      this.serviceApi.update(this.serviceId, {
        autoconnectionEnabled: res
      }).pipe(
        catchError((error: HttpErrorResponse) => of(error))
      ).subscribe((response) => {
        if (response instanceof HttpErrorResponse) {
          this.showResponseStatus(response.error, response.message, Response.error);
          return;
        }
        this.showResponseStatus(
         '',
          this.translate.instant('services.message.update.success'),
          Response.success
        );
      });
    }));
  }

  showDivider(): boolean {
    return this.company && (!this.supportHidden || !this.connectHidden || !this.emergencyHidden || !this.renameHidden);
  }

  onOpenServiceWizard() {
    this.openWizard.emit();
  }

  onSendEmergencyAlert() {
    if (!this.rdas?.[0]) {
      this.snackbar.showMessage(this.translate.instant('service.config.message.no_available_adapter_for_sending_emergency_alert'));
      return;
    }

    this.serviceFacade.sendEmergencyAlert(this.rdas[0].uid);
  }

  onSupport() {
    const data: DialogWrapperData<{ serviceName: string, rda: RdaResponse }, null> = {
      title: this.translate.instant('service.config.support.title'),
      componentName: `${this.serviceType}SupportRequest`,
      body: { serviceName: this.customName ?? this.name, rda: this.rdas?.[0] },
    };

    this.dialog.open(ServiceSupportRequestPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.SM,
      data
    });
  }

  onRename() {
    const data: DialogWrapperData<{ serviceName: string }, UpdateServiceRequest> = {
      title: this.translate.instant('service.config.rename.title'),
      componentName: `Edit${this.serviceType}Info`,
      body: { serviceName: this.customName },
      submit: (request: UpdateServiceRequest) =>
        this.serviceFacade.updateService(request)
    };

    this.dialog.open(ServiceEditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.SM,
      data
    });
  }

  getTitleTariff(serviceType: ServicesTypes): string {
    switch (serviceType) {
      case ServicesTypes.HARDWARE_INTERCOM:
        return this.translate.instant('service.config.field.tariff_for_tube_intercom');
      default:
        return this.translate.instant('service.config.field.tariff');
    }
  }

  public onFindCameras(): void {
    const data: DialogWrapperData<{rdas: RdaResponse[]}, {}> = {
      title: this.translate.instant('service.config.find_cameras.title'),
      componentName: `FindCameras`,
      body: {
        rdas: this.rdas.filter(rda => !rda.intercomType?.protocol?.ipType)
      }
    };

    this.dialog.open(FindCamerasPopupComponent, {
      data
    });
  }

  async onTariffEdit() {
    const tariff = {
      tariffHardwareIntercom: await this.getHardwareIntercomTariff()
    };
    tariff[`tariff${this.serviceType}`] = this.serviceTariff;
    const data: DialogWrapperData<ServiceTariffPopupModel, void> = {
      title: this.translate.instant('service.config.tariff_edit.title'),
      componentName: 'ServiceTariffPopup',
      body: {
        serviceId: this.serviceId,
        hardwareId: this.getHardwareIntercomId(),
        tariff,
        showCheckboxForAllAbonents: true,
        serviceType: this.serviceType
      },
      submit: () => {
        this.serviceFacade.getService(this.serviceId);
        this.serviceFacade.getConnectionsInit();
      }
    };

    this.dialog.open(ServiceTariffPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN) ?
        DialogWrapperSize.MAX : DialogWrapperSize.SM,
      data
    });
  }

  public getHardwareIntercomId(): number | undefined {
    let hardwareId;
    for (const service of this.dependantServices) {
      if (service.type === ServicesTypes.HARDWARE_INTERCOM) {
        hardwareId = service.id;
      }
    }
    return hardwareId;
  }

  public async getHardwareIntercomTariff(): Promise<number | undefined> {
    const hardwareId = this.getHardwareIntercomId();
    if (hardwareId) {
      const hardwareInfo = await this.serviceApi.getInfo(this.getHardwareIntercomId()).toPromise();
      return hardwareInfo?.tariff;
    }
  }

  ngOnDestroy(): void {
    this.subscriptionList.map(subscription => {
      subscription.unsubscribe();
    });
  }
}
