import { Pipe, PipeTransform } from '@angular/core';
import {IRoleResponse, IUserResponse} from '@app/views/company/models/company-page';

@Pipe({
  name: 'userResponsePipe'
})
export class UserResponsePipePipe implements PipeTransform {
  transform(value: IUserResponse[], filter: IRoleResponse[], text: string): IUserResponse[] {
    if (!filter?.length && !text) { return value; }
    if (!filter?.length && text) {
      return value.filter(result => result.email.includes(text));
    }
    if (filter?.length && !text) {
      return value.filter(obj1 => {
        return filter.some(obj2 => {
          return JSON.stringify(obj1.roleNames).includes(obj2.name);
        });
        });
    }
    if (filter?.length && text) {
      return value.filter(obj1 => {
        return filter.some(obj2 => {
          return JSON.stringify(obj1.roleNames).includes(obj2.name);
        });
      }).filter(result => result.email.includes(text));
    }
    return value;
  }
}
