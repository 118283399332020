import { MediaMatcher } from '@angular/cdk/layout';
import { Constants } from '../../../helpers/constants';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ResolutionService {
    _mobileQuery: MediaQueryList;
    _tabletQuery: MediaQueryList;
    private _mobileQueryListener: () => void;
    private _tabletQueryListener: () => void;

    constructor(private media: MediaMatcher) { }

    get isMobile(): boolean {
        return this._mobileQuery.matches;
    }

    get isTablet(): boolean {
        return this._tabletQuery.matches;
    }

    initMobileQuery(cb: Function) {
        this._mobileQuery = this.media.matchMedia(Constants.MAX_MOBILE_WIDTH);
        this._mobileQueryListener = () => cb(true);
        // tslint:disable-next-line:deprecation
        this._mobileQuery.addListener(this._mobileQueryListener);
    }

    initTabletQuery(cb: Function) {
        this._tabletQuery = this.media.matchMedia(Constants.MAX_TABLET_WIDTH);
        this._tabletQueryListener = () => cb(true);
        // tslint:disable-next-line:deprecation
        this._tabletQuery.addListener(this._tabletQueryListener);
    }
}
