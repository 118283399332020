import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { ButtonLinkModule } from '../button-link';
import { ComponentLoaderModule } from '../component-loader/component-loader.module';
import { DialogWrapperComponent } from './dialog-wrapper.component';
import {NgxTranslateModule} from '@app/translate/translate.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
    imports: [
        ScrollingModule,
        CommonModule,
        MaterialModule,
        ComponentLoaderModule,
        ButtonLinkModule,
        NgxTranslateModule
    ],
    declarations: [
        DialogWrapperComponent
    ],
    exports: [
        DialogWrapperComponent
    ]
})
export class DialogWrapperModule { }
