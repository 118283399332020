export interface VideoPlayerVideoshotRequest {
    minDate: Date;
    maxDate: Date;
    depthInHours: number;
    rdvaUri: string;
    cameraId: number;
    token: string;
    isMobile: boolean
}

export interface VideoshotTimeData {
    date: string, 
    durationInSeconds: number
}
