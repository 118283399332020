<app-dialog-wrapper
  [title]="data.title"
  [componentName]="data.componentName"
>
  <div class="info-table">
    <div class="table-column">
      <p class="title">
        {{ 'pbx-on-rda.info.content.field.id' | translate }}
      </p>
      <p class="value">
        {{ data.body.id }}
      </p>
    </div>

    <div class="table-column table-column-neutral">
      <p class="title">
        {{ 'pbx-on-rda.info.content.field.uid' | translate }}
      </p>
      <p class="value">
        {{ data.body.pbxOnRdaUid }}
      </p>
    </div>

    <div class="table-column">
      <p class="title">
        {{ 'pbx-on-rda.info.content.field.vpn' | translate }}
      </p>
      <p class="value">
        {{ data.body.vpnIpAddress }}
      </p>
    </div>

    <div class="table-column table-column-neutral">
      <p class="title">
        {{ 'pbx-on-rda.info.content.field.uid_sip' | translate }}
      </p>
      <p class="value">
        {{ data.body.sipAdapter.uid }}
      </p>
    </div>

    <div class="table-column">
      <p class="title">
        {{ 'pbx-on-rda.info.content.field.dns' | translate }}
      </p>
      <p class="value">
        {{ data.body.sipAdapter.dnsName }}
      </p>
    </div>

    <div class="table-column table-column-neutral">
      <p class="title">
        {{ 'pbx-on-rda.info.content.field.ip' | translate }}
      </p>
      <p class="value">
        {{ data.body.sipAdapter.ip }}:{{ data.body.sipAdapter.port }}
      </p>
    </div>

    <div class="table-column">
      <p class="title">
        {{ 'pbx-on-rda.info.content.field.connected_rdas' | translate }}
      </p>
      <p class="value">
        {{ joinRdas(data.body.connectedRdas) }}
      </p>
    </div>
  </div>
</app-dialog-wrapper>
