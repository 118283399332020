import { HttpErrorResponse } from '@angular/common/http';
import { Action } from '@ngrx/store';
import { CityResponse, StreetResponse, HouseResponse, EntranceResponse } from '@app/shared/models';

export enum ActionTypes {
  GetCitiesList = '[Shared] GetCitiesList',
  GetCitiesListSuccess = '[Shared] GetCitiesListSuccess Success',
  GetCitiesListFailure = '[Shared] GetCitiesListFailure Failure',

  GetStreetsList = '[Shared] GetStreetsList',
  GetStreetsListSuccess = '[Shared] GetStreetsListSuccess Success',
  GetStreetsListFailure = '[Shared] GetStreetsListFailure Failure',

  GetHousesList = '[Shared] GetHousesList',
  GetHousesListSuccess = '[Shared] GetHousesListSuccess Success',
  GetHousesListFailure = '[Shared] GetHousesListFailure Failure',

  GetEntrancesList = '[Shared] GetGetEntrancesList',
  GetEntrancesListSuccess = '[Shared] GetGetEntrancesListSuccess Success',
  GetEntrancesListFailure = '[Shared] GetGetEntrancesListFailure Failure',

  ClearAddress = '[Shared] ClearAddress'
}

export class GetCitiesList implements Action {
  readonly type = ActionTypes.GetCitiesList;

  constructor() { }
}

export class GetCitiesListSuccess implements Action {
  readonly type = ActionTypes.GetCitiesListSuccess;

  constructor(public payload: CityResponse[]) { }
}

export class GetCitiesListFailure implements Action {
  readonly type = ActionTypes.GetCitiesListFailure;

  constructor(public payload: HttpErrorResponse) { }
}

export class GetStreetsList implements Action {
  readonly type = ActionTypes.GetStreetsList;

  constructor(public cityId: number) { }
}

export class GetStreetsListSuccess implements Action {
  readonly type = ActionTypes.GetStreetsListSuccess;

  constructor(public payload: StreetResponse[]) { }
}

export class GetStreetsListFailure implements Action {
  readonly type = ActionTypes.GetStreetsListFailure;

  constructor(public payload: HttpErrorResponse) { }
}

export class GetHousesList implements Action {
  readonly type = ActionTypes.GetHousesList;

  constructor(public cityId: number, public streetId: number) { }
}

export class GetHousesListSuccess implements Action {
  readonly type = ActionTypes.GetHousesListSuccess;

  constructor(public payload: HouseResponse[]) { }
}

export class GetHousesListFailure implements Action {
  readonly type = ActionTypes.GetHousesListFailure;

  constructor(public payload: HttpErrorResponse) { }
}

export class GetEntrancesList implements Action {
  readonly type = ActionTypes.GetEntrancesList;

  constructor(public cityId: number, public streetId: number, public houseId: number) { }
}

export class GetEntrancesListSuccess implements Action {
  readonly type = ActionTypes.GetEntrancesListSuccess;

  constructor(public payload: EntranceResponse[]) { }
}

export class GetEntrancesListFailure implements Action {
  readonly type = ActionTypes.GetEntrancesListFailure;

  constructor(public payload: HttpErrorResponse) { }
}

export class ClearAddress implements Action {
  readonly type = ActionTypes.ClearAddress;

  constructor() { }
}

export type Actions =
  | GetCitiesList
  | GetCitiesListSuccess
  | GetCitiesListFailure
  | GetStreetsList
  | GetStreetsListSuccess
  | GetStreetsListFailure
  | GetHousesList
  | GetHousesListSuccess
  | GetHousesListFailure
  | GetEntrancesList
  | GetEntrancesListSuccess
  | GetEntrancesListFailure
  | ClearAddress;
