<ng-container *ngIf="(cameraRdaLoader$ | async) then loader else content">

</ng-container>

<ng-template #loader>
  <div class="video-stream-popup-empty">
    <app-component-loader
      sizePx="50"
      [overTheComponent]="false"
    ></app-component-loader>
  </div>
</ng-template>
<ng-template #content>
  <video [id]="VIDEO_CONTAINER_ID" #videoContainer></video>
  <button
    mat-fab
    mat-dialog-close
    color="transparent"
    class="video-stream-popup__close-button"
    *ngIf="mdWDownBreakpoint$ | async"
  >
    <mat-icon>close</mat-icon>
  </button>
  <app-component-loader
    *ngIf="loading$| async"
    sizePx="50"
    [overTheComponent]="false"
    class="video-stream-popup-loader"
  ></app-component-loader>
</ng-template>
