import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CoreFacade} from '@app/core/store';
import {SnackbarService} from '@app/shared/components/snackbar';
import {LoaderService, RdeaDate} from '@app/shared/entities/common';
import {KeysApiService, KeysResponse, RdaResponse, ServiceApiService, ServiceResponse} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {ServicePageActionsService} from '@app/views/services/services';
import {ServiceFacade} from '@app/views/services/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
  AddServiceKey,
  AddServiceKeyFailure,
  AddServiceKeySuccess,
  DeleteServiceKey,
  DeleteServiceKeyFailure,
  DeleteServiceKeySuccess,
  GetServiceKeys,
  GetServiceKeysFailure,
  GetServiceKeysSuccess,
  ServicesActionTypes,
  UpdateServiceKey,
  UpdateServiceKeyFailure,
  UpdateServiceKeySuccess
} from '../actions';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServicesIntercomKeysEffects {
  constructor(
    private actions$: Actions,
    private coreFacade: CoreFacade,
    private snackbar: SnackbarService,
    private loader: LoaderService,
    private keysApiService: KeysApiService,
    private serviceApiService: ServiceApiService,
    private servicePageService: ServicePageActionsService,
    private serviceFacade: ServiceFacade,
    private translate: TranslateService
  ) {
  }

  GetServiceKeys$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceKeys>(ServicesActionTypes.GetServiceKeys),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [GetServiceKeys, number]) => {
        this.loader.loaderState = {state: true};

        return this.serviceApiService.getKeys(serviceId)
          .pipe(
            map((response: KeysResponse[]) => {
              this.loader.loaderState = {state: false};
              return new GetServiceKeysSuccess(response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.loader.loaderState = {state: false};
              this.snackbar.showMessage(
                this.translate.instant('services.intercom.keys.message.get_service_keys.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetServiceKeysFailure());
            })
          );
      })
    )
  );

  GetServiceKeysSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetServiceKeysSuccess>(ServicesActionTypes.GetServiceKeysSuccess),
      withLatestFrom(this.serviceFacade.rdas$),
      tap(([action, rdas]: [GetServiceKeysSuccess, RdaResponse[]]) =>
        this.serviceFacade.getKeysSources(
          action.keys
            .filter((key: KeysResponse) =>
              rdas.findIndex(rda => rda.uid === key.adapterId) === -1)
            .map((key: KeysResponse) =>
              ({intercomUid: key.adapterId, key: key})
            )
        )
      )
    ), {dispatch: false}
  );

  AddServiceKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddServiceKey>(ServicesActionTypes.AddServiceKey),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [AddServiceKey, number]) =>
        from(this.servicePageService.addKey(serviceId, action.intercomInfo.uid, action.request))
          .pipe(
            map((key: KeysResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercom.keys.message.add_service_key.success'),
                'success'
              );
              return new AddServiceKeySuccess(key, action.intercomInfo);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercom.keys.message.add_service_key.failed', {
                  text: parseError(error)
                })
              );
              return of(new AddServiceKeyFailure());
            })
          )
      )
    )
  );

  AddServiceKeySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddServiceKeySuccess>(ServicesActionTypes.AddServiceKeySuccess),
      tap(() => this.coreFacade.getPaymentInfo(new RdeaDate()))
    ), {dispatch: false}
  );

  UpdateServiceKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateServiceKey>(ServicesActionTypes.UpdateServiceKey),
      switchMap((action: UpdateServiceKey) =>
        this.keysApiService.updateKey(action.keyId, action.request)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercom.keys.message.update_service_key.success'),
                'success'
              );
              return new UpdateServiceKeySuccess(action.keyId, action.request, action.locationName);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercom.keys.message.update_service_key.failed', {
                  text: parseError(error)
                })
              );
              return of(new UpdateServiceKeyFailure());
            })
          )
      )
    )
  );

  DeleteServiceKey$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceKey>(ServicesActionTypes.DeleteServiceKey),
      switchMap((action: DeleteServiceKey) =>
        this.keysApiService.deleteKey(action.keyId)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercom.keys.message.delete_service_key.success'),
                'success'
              );
              return new DeleteServiceKeySuccess(action.keyId, action.rdaUid);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercom.keys.message.delete_service_key.failed', {
                  text: parseError(error)
                })
              );
              return of(new DeleteServiceKeyFailure());
            })
          )
      )
    )
  );

  DeleteServiceKeySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceKeySuccess>(ServicesActionTypes.DeleteServiceKeySuccess),
      tap((action: DeleteServiceKeySuccess) => this.serviceFacade.deleteKeySource(action.rdaUid))
    ), {dispatch: false}
  );
}
