import { Pipe, PipeTransform } from '@angular/core';

import { ServiceFlat } from '@app/views/services/components';
import { Dictionary } from '../helpers';

@Pipe({
    name: 'flatsCount',
    pure: false
})
export class FlatsCountPipe implements PipeTransform {
    transform(value: Dictionary<ServiceFlat | boolean>): number {
        return Object.values(value).filter(flat => flat).length;
    }
}
