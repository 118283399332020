import { ActivatedRouteSnapshot } from '@angular/router';

export class PermissionsHelper {
    static getLinkFromRouteData(routeSnapshot: ActivatedRouteSnapshot) {
        if (!routeSnapshot) {
            return null;
        }

        let routeData: ActivatedRouteSnapshot = routeSnapshot;

        while (routeData.children.length > 0) {
            routeData = routeData.children[0];
        }

        return routeData.data['link'];
    }
}
