<div class="tpl-service-menu">
  <a
    *ngIf="flat?.signUps?.count"
    [routerLink]="['/abonents/sign_ups']"
    [queryParams]="{
      query: (address | address) + ', ' + ('shared.flat.status.flat_short' | translate) + ' ' + flat.flatNumber,
      signUpType: flat.signUps?.content['signUpType'],
      status: flat.signUps?.content['status'],
      queryMode: 'current'
    }"
    mat-icon-button
    class="tpl-service-menu__button">
    <mat-icon svgIcon="sign-ups"></mat-icon>
  </a>
  <ng-container *ngIf="!!flat.account || !!flat.flat; else emptyFlat">
    <span
      *ngIf="flat.account?.blocked; else activeStatus"
      class="service-flats__status service-flats__status_warn">
      {{ mdWUpBreakpoint ? ('shared.flat.status.blocked' | translate) : null }}
    </span>
    <ng-template #activeStatus>
      <span class="service-flats__status service-flats__status_active">
        {{ mdWUpBreakpoint ? ('shared.flat.status.active' | translate) : null }}
      </span>
    </ng-template>
  </ng-container>
  <ng-template #emptyFlat>
    <span class="service-flats__status service-flats__status_empty">
      {{ mdWUpBreakpoint ? ('shared.flat.status.empty' | translate) : null }}
    </span>
  </ng-template>
</div>
