import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { AbonentsListComponent } from './abonents-list.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        SharedPipesModule,
        MaterialModule,
        NgxTranslateModule,
    ],
    declarations: [
        AbonentsListComponent
    ],
    exports: [
        AbonentsListComponent
    ]
})
export class AbonentsListModule { }
