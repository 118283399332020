import { Pipe, PipeTransform } from '@angular/core';
import { IntercomTypeUtils } from '@app/shared/entities/rd';
import {TranslateService} from '@ngx-translate/core';

@Pipe({ name: 'ipRdaConnectionType' })
export class IpRdaConnectionTypePipe implements PipeTransform {
  constructor(
    private translate: TranslateService
  ) {}

  transform(type: string): string {
    return IntercomTypeUtils.getIpIntercomConnectionTypeText(type, this.translate);
  }
}
