import { createSelector } from '@ngrx/store';
import { SharedModuleStates } from '../../reducers/reducers';
import { sharedModuleSelector } from '../shared-module.state';

export const updateIntercomState = createSelector(
  sharedModuleSelector,
  (state: SharedModuleStates) => state.updateIntercom
);

export const updateIntercomStateSuccess = createSelector(
  updateIntercomState,
  state => state.success
);

export const updateIntercomStateFailure = createSelector(
  updateIntercomState,
  state => state.error
);

export const updateIntercomStateLoading = createSelector(
  updateIntercomState,
  state => state.loading
);
