import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IntercomKeysEditContainerData, IntercomKeysEditContainerResponse } from '@app/shared/components';
import { AdapterInfoForKeyResponse, KeysResponse, RdaKeysRequest, RdaResponse } from '@app/shared/entities/rd';
import { LocationResponse } from '@app/shared/models';
import { DialogWrapperData } from '@app/shared/ui';
import { ServiceFacade } from '@app/views/services/store';
import { Observable } from 'rxjs';
import { ServiceIntercomKeyPopupService } from './service-intercom-key-popup.service';

@Component({
  selector: 'app-service-intercom-key-edit-popup',
  templateUrl: './service-intercom-key-edit-popup.component.html',
  styleUrls: ['./service-intercom-key-edit-popup.component.scss'],
  providers: [ServiceIntercomKeyPopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceIntercomKeyEditPopupComponent implements OnInit {
  intercoms$: Observable<RdaResponse[]> = this.serviceFacade.rdas$;
  keysLocations$: Observable<LocationResponse[]> = this.serviceFacade.keysLocations$;
  popupState$: Observable<'loading' | 'close' | 'loaded'> = this.popupService.state$;
  public keys: KeysResponse[];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogWrapperData<IntercomKeysEditContainerData, IntercomKeysEditContainerResponse>,
    public popupService: ServiceIntercomKeyPopupService,
    private serviceFacade: ServiceFacade,
    private dialogRef: MatDialogRef<ServiceIntercomKeyEditPopupComponent>
  ) { }

  ngOnInit() {
    this.popupService.addStateChangeListener(state =>
      state === 'close' && this.dialogRef.close()
    );
    this.serviceFacade.keys$
      .subscribe((keys) => {
        this.keys = keys?.filter(key => !key.service);
    });
  }

  onSubmit(keyId: number, intercomInfo: AdapterInfoForKeyResponse, request: RdaKeysRequest, locationName: string) {
    this.data.submit({ keyId, intercomInfo, request, locationName });
  }
}
