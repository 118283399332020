import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Input, ViewChild } from '@angular/core';

@Component({
  selector: 'app-info-block',
  templateUrl: './info-block.component.html',
  styleUrls: ['./info-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoBlockComponent {
  @ViewChild('image') image: ElementRef<HTMLImageElement>;
  @ViewChild('text') text: ElementRef<HTMLTitleElement>;
  @ViewChild('body') body: ElementRef<HTMLDivElement>;
  @ViewChild('content') content: ElementRef<HTMLDivElement>;
  @ViewChild('description') description: ElementRef<HTMLParagraphElement>;

  @Input()
  @HostBinding('attr.contentWidth')
  contentWidth = 50;

  @Input() contentHidden = true;
}
