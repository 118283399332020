import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SnackbarComponent } from './snackbar.component';
import { MaterialModule } from '@app/material.module';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [SnackbarComponent],
  exports: [SnackbarComponent],
})
export class SnackbarModule { }
