<div class="active-history-chart">
  <div class="active-history-chart-content">
    <div class="active-history-chart-content-labels">
      <div class="active-history-chart-content-labels-item active-history-chart-content-labels-item--title">
        <h3 class="active-history-chart-content-labels-item__title">{{ title }}</h3>
      </div>

      <div
        *ngFor="let source of sources; let index = index"
        class="active-history-chart-content-labels-item"
        [class.active-history-chart-content-labels-item--accent]="index % 2 === 0"
      >
        <p
          class="active-history-chart-content-labels-item__text"
          [class.active-history-chart-content-labels-item__text--with-error]="errorExists(data[source.id])"
        >
          {{ source.label }}
        </p>
      </div>
    </div>

    <div #table class="active-history-chart-content-table">
      <div class="active-history-chart-content-table-timeline">
        <div
          class="active-history-chart-content-table-timeline-item"
          *ngFor="let timestamp of timestamps; let index = index; let first = first; let last = last"
        >
        <p class="active-history-chart-content-table-timeline-item__date">
          {{ first || last || dateChanged(timestamps[index], timestamps[index - 1]) ? (timestamp | date: 'dd.MM') : '' }}
          <br>
          {{ timestamp | date: 'HH:mm' }}
        </p>
        </div>
      </div>

      <ng-container *ngFor="let source of sources">
        <app-active-history-chart-line
          class="active-history-chart-content-table-row"
          [lineData]="data[source.id]"
          [blocksCount]="blocksCount"
          [loading]="source.loading"
        ></app-active-history-chart-line>
      </ng-container>
    </div>
  </div>
</div>