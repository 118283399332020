import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UserRoleType } from '@app/core/models';
import { PermissionsService } from '@app/security/permissions';

@Directive({ selector: '[appSomeRoleContains]' })
export class SomeRoleContainsDirective implements OnInit {
  @Input() appSomeRoleContains: UserRoleType[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissions: PermissionsService
  ) { }

  ngOnInit() {
    const isAllowed = this.permissions.someRoleContains(this.appSomeRoleContains);
    isAllowed ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
  }
}
