import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

const addScansToOrderState = createSelector(
    intercomModuleSelector,
    (state: IntercomModuleState) => state.addScansToOrder
);

export const addScansToOrder = createSelector(
    addScansToOrderState,
    state => state
);
export const addScansToOrderLoading = createSelector(
    addScansToOrderState,
    state => state.loading
);
export const addScansToOrderSuccess = createSelector(
    addScansToOrderState,
    state => state.success
);

export const addScansToOrderFailed = createSelector(
    addScansToOrderState,
    state => state.error
);
