import {ChangeDetectionStrategy, Component, Input, OnDestroy} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponentConnectionHelper } from '@app/shared/components';
import { GitlabComponentsVersions } from '@app/shared/entities/integrations';
import { PbxOnRdaResponse, RdaResponse, RdaUpdateRequest } from '@app/shared/entities/rd';
import { Constants, Dictionary } from '@app/shared/helpers';
import { Address, ServicesTypes } from '@app/shared/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { DialogWrapperData, DialogWrapperSize } from '@app/shared/ui';
import { ServiceFacade } from '@app/views/services/store';
import { Observable } from 'rxjs';
import { ServiceIntercomPopupBody, ServiceIntercomPopupComponent } from '../../popups';
import {TranslateService} from '@ngx-translate/core';
import {MatDialogRef} from '@angular/material/dialog/dialog-ref';

@Component({
  selector: 'app-service-intercoms',
  templateUrl: './service-intercoms.component.html',
  styleUrls: ['./service-intercoms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceIntercomsComponent implements OnDestroy {
  @Input() intercoms: RdaResponse[];
  @Input() entrances: Address[];
  @Input() addDisabledCondition: Function;
  @Input() addDisabled: boolean;
  @Input() serviceType!: ServicesTypes;
  @Input() translationsDisabled: boolean;
  @Input() componentsVersions: GitlabComponentsVersions;
  xlWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.XL_W_DOWN);

  private popupRef: MatDialogRef<ServiceIntercomPopupComponent, unknown> = null;

  pbxOnRda$: Observable<Dictionary<PbxOnRdaResponse>> = this.serviceFacade.pbxOnRda$;

  constructor(
    private dialog: MatDialog,
    private resolution: ResolutionService,
    private serviceFacade: ServiceFacade,
    private confirmComponentConnectionHelper: ConfirmComponentConnectionHelper,
    private translate: TranslateService
  ) { }

  ngOnDestroy(): void {
    this.popupRef?.close();
  }

  onAdd() {
    const data: DialogWrapperData<Partial<ServiceIntercomPopupBody>, { request: RdaUpdateRequest }> = {
      title: this.translate.instant('service.intercoms.add.title'),
      componentName: `Add${this.serviceType}Rda`,
      body: {
        onlyIpIntercoms: this.intercoms?.length > 0 && this.intercoms.reduce((prev, cur) => prev && cur?.intercomType?.protocol?.ipType, true)
      },
      submit: (event: { request: RdaUpdateRequest }) => {
        if (this.serviceType !== ServicesTypes.GATE) {
          this.confirmComponentConnectionHelper.showBottomSheet(() => {
            this.serviceFacade.addServiceRda(event.request);
          });
        } else {
          this.serviceFacade.addServiceRda(event.request);
        }
      }
    };

    this.popupRef = this.dialog.open(ServiceIntercomPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.getBreakpointState(ResolutionBreakpoint.MD_W_DOWN)
        ? DialogWrapperSize.MAX : DialogWrapperSize.MD,
      data
    });
  }
}

