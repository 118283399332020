import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';

import { ServiceTitleRowComponent } from './service-title-row.component';
import {NgxTranslateModule} from '@app/translate/translate.module';



@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        NgxTranslateModule
    ],
    declarations: [
        ServiceTitleRowComponent
    ],
    exports: [
        ServiceTitleRowComponent
    ]
})
export class ServiceTitleRowModule { }
