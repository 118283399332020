<form
  class="popup component"
  autocomplete="off"
  [formGroup]="form"
  (ngSubmit)="onSubmit()"
>
  <app-info-block class="component-info">
    <img
      class="component-info__image"
      info-block-image
      [src]="resourcesHelper.getResource(resourcePath.INFO_LG) | safeUrl"
      [srcset]="resourcesHelper.getResource(resourcePath.INFO_LG, true) | safeUrl"
    >

    <ng-container info-block-text>
      {{ 'services.gates.page.popups.wizard.content.intercoms.indicies.message.all_connected_addresses_support_work_with_several_calling_panels' | translate }}
    </ng-container>

    <ng-container info-block-content>
      <div class="component-info-content">
        <h4 class="component-info-content__title"></h4>

        <p>
          {{ 'services.gates.page.popups.wizard.content.intercoms.indicies.message.you_can_reuse_rda_installed_in_entrances' | translate }}
        </p>
      </div>
    </ng-container>

    <ng-container info-block-description>
      <strong>
        {{ 'services.gates.page.popups.wizard.content.intercoms.indicies.message.to_do_this_indicate_the_index' | translate }}
      </strong>
    </ng-container>
  </app-info-block>

  <mat-form-field appearance="outline" class="relative-size none-padding">
    <mat-label>
      {{ 'services.gates.page.popups.wizard.content.intercoms.indicies.field.index' | translate }}
    </mat-label>
    <input
      matInput
      type="number"
      formControlName="index"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      required
    >
  </mat-form-field>

  <div class="popup-actions">
    <button
      mat-raised-button
      [disabled]="loading"
      type="submit"
      color="primary"
      class="popup-actions__button mat-button-lg"
    >
      {{ 'services.gates.page.popups.wizard.content.intercoms.indicies.button.submit' | translate }}
    </button>
  </div>
</form>
