import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { Account } from '@app/shared/entities/rd/account/models';
import { Abonent, PagedResponse } from '@app/shared/models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';
import {
  CreateFlatForAbonentRequest,
  CreateFlatForAbonentResponse,
  UpdateAbonentRequest,
  VoipTokenInfo
} from '@app/views/abonents/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AbonentService extends ApiService {
  private contentTypeHeaders: HttpHeaders = new HttpHeaders().set(
    'Content-Type',
    'application/json'
  );

  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.abonentsApiUrl, ['v1', 'v2', 'v3']);
  }

  createAbonent(phone: number | string): Observable<Abonent> {
    return this.post(
      '/abonents',
      1,
      { phone: phone },
      { headers: this.contentTypeHeaders }
    );
  }

  updateAbonent(abonentId: number, request: UpdateAbonentRequest) {
    return this.patch(`/abonents/${abonentId}`, 1, request, {
      headers: this.contentTypeHeaders
    });
  }

  createAccount(abonentId: number, companyId?: number): Observable<Account> {
    const request: any = {};

    if (companyId !== null) {
      request.companyId = companyId;
    }

    return this.post(`/abonents/${abonentId}/accounts`, 1, request, {
      headers: this.contentTypeHeaders
    });
  }

  public createAccount2(payload: {companyId: number, phone: number}): Observable<Account> {
    return this.post(`/accounts`, 1, payload, {headers: this.contentTypeHeaders});
  }

  createFlat(
    abonentId: number,
    request: CreateFlatForAbonentRequest
  ): Observable<CreateFlatForAbonentResponse> {
    return this.post(`/abonents/${abonentId}/flats`, 1, request, {
      headers: this.contentTypeHeaders
    });
  }

  getPage(
    page: number = 0,
    size: number = 10,
    resolved: boolean = null,
    phone?: string,
    messageDate?: string
  ): Observable<PagedResponse<Abonent>> {
    const params = { page: page, size: size } as any;

    if (resolved !== null) {
      params.resolved = resolved;
    }

    if (phone) {
      params.phone = phone;
    }

    if (messageDate) {
      params.sort = `supportRequestDate,${messageDate}`;
    }

    return this.get('/abonents', 2, { params: params });
  }

  // Todo In the future we must move this logic to back end
  getAbonentAccountByPhone(phone: string): Observable<Abonent> {
    const params = { page: 0, size: 1, phone } as any;

    return this.get<PagedResponse<Abonent>>('/abonents', 2, {
      params: params
    }).pipe(map((response) => response.content[0]));
  }

  public getAllAbonentAccountByPhone(phone: string): Observable<PagedResponse<Abonent>> {
    const params = { page: 0, size: 1, phone } as any;

    return this.get<PagedResponse<Abonent>>('/abonents', 2, {params: params});
  }

  getAbonentAccounts(abonentId: number): Observable<Account[]> {
    return this.get(`/abonents/${abonentId}/accounts`, 1);
  }

  getAbonentInfo(abonentId: number): Observable<Abonent> {
    return this.get(`/abonents/${abonentId}`, 1);
  }

  getVoipTokensInfoList(abonentId: number): Observable<VoipTokenInfo[]> {
    return this.get(`/abonents/${abonentId}/voip_tokens`, 1);
  }
}
