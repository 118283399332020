<app-dialog-wrapper
  [title]="data.title"
  [progress]="(state$ | async) === 'loading'"
  [componentName]="data.componentName"
>
  <app-service-block-physical-tube-content
    [phone]="data.body.phone"
    [text]="data.body.text"
    [loading]="(state$ | async) === 'loading'"
    (blockPhysicalTube)="onSubmit($event)"
  ></app-service-block-physical-tube-content>
</app-dialog-wrapper>
