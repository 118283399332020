import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IGroupModificationWizard } from '@app/views/intercom/components';
import { RdaApiService } from '@app/shared/entities/rd';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-group-data-modification-bottom-sheet',
  templateUrl: './group-data-modification-bottom-sheet.component.html',
  styleUrls: ['./group-data-modification-bottom-sheet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupDataModificationBottomSheetComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public groupModificationWizard: IGroupModificationWizard<Observable<boolean>>,
    private rdaApiService: RdaApiService) {
  }

  public onSubmit(data): void {
    this.groupModificationWizard.onSubmit(this.rdaApiService.updateAdapters(data.uid, {
      companyId: data.companyId,
      batchId: data.batchId
    }));
  }
}
