import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'currentDate'
})
export class CurrentDate extends DatePipe implements PipeTransform {
    transform(value: Date | string | number): string | null;
    transform(value: null | undefined): null;
    transform(value: Date | string | number | null | undefined): string | null;
    transform(value: Date | string | number | null | undefined): string | null;
    transform(value: number, format: string = 'mediumDate'): string {
        return super.transform(new Date(value), format);
    }
}
