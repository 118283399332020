import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { CronDateInputComponent } from './cron-date-input.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, MaterialModule, NgxTranslateModule],
  declarations: [CronDateInputComponent],
  exports: [CronDateInputComponent]
})
export class CronDateInputModule {}
