import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Account } from '@app/shared/entities/rd';
import { DialogWrapperData } from '@app/shared/ui';
import { Observable } from 'rxjs';
import { ServiceBlockPhysicalTubeBody, ServiceBlockPhysicalTubePopupResponse } from './models';
import { ServiceBlockPhysicalTubePopupService } from './service-block-physical-tube-popup.service';

@Component({
  selector: 'app-service-block-physical-tube-popup',
  templateUrl: './service-block-physical-tube-popup.component.html',
  styleUrls: ['./service-block-physical-tube-popup.component.scss'],
  providers: [ServiceBlockPhysicalTubePopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceBlockPhysicalTubePopupComponent implements OnInit {
  state$: Observable<'loading' | 'close' | 'loaded'> = this.popupService.state$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<ServiceBlockPhysicalTubeBody, ServiceBlockPhysicalTubePopupResponse>,
    private dialogRef: MatDialogRef<ServiceBlockPhysicalTubePopupComponent>,
    private popupService: ServiceBlockPhysicalTubePopupService
  ) { }

  ngOnInit() {
    this.popupService.addStateChangeListener((state: 'loading' | 'close' | 'loaded') =>
      state === 'close' && this.dialogRef.close()
    );
  }

  onSubmit(event: { account?: Account }) {
    this.data.submit(event);
  }
}
