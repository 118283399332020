<form 
  class="edit-account-form"
  [class.width-for-mobile]="data.isMobile" 
  [formGroup]="form" 
  (ngSubmit)="onSubmit()" 
  autocomplete="off"
>
  <mat-form-field class="edit-account-form__account-number" appearance="outline">
    <mat-label>
      {{ 'edit.account.form.field.account' | translate }}
    </mat-label>
    <input
      type="text" matInput
      placeholder="{{ 'edit.account.form.placeholder.account' | translate }}"
      formControlName="number"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      required
    />
    <mat-error>
      {{ 'edit.account.form.error.account' | translate }}
    </mat-error>
  </mat-form-field>

  <div class="action-block">
    <button type="submit" mat-raised-button color="primary">
      {{ 'edit.account.form.button.submit' | translate }}
    </button>
  </div>
</form>
