import { PermissionsTreeKeysType, PermissionsTreeRules } from './models';

export class PermissionsTreeBase {
    defaultRoute: string;
    keys: PermissionsTreeKeysType;
    routes: PermissionsTreeRules;

    constructor(
        defaultRoute: string,
        keys: PermissionsTreeKeysType,
        routes: PermissionsTreeRules
    ) {
        this.configurateKeys(keys);
        this.configuratePermissions(routes);
        this.configurateDefaultRoute(defaultRoute);
    }

    private configurateDefaultRoute(defaultRoute: string) {
        this.defaultRoute = defaultRoute;
    }

    private configurateKeys(keys: PermissionsTreeKeysType) {
        if (keys) {
            this.keys = keys;
            return;
        }

        this.keys = keys;
    }

    private configuratePermissions(routes: PermissionsTreeRules) {
        this.routes = routes;
    }
}
