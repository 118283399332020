<div
  class="component"
  [class.component--without-menu]="!showMenu"
  [class.component--without-step-buttons]="!showStepButtons"
>
  <app-rdea-stepper
    *ngIf="showMenu"
    [steps]="steps"
    [stepIdx]="wizardStep"
    (changeStep)="onChangeStep($event.stepIdx)"
    class="component__stepper"
  ></app-rdea-stepper>

  <div class="component-body">
    <ng-container [ngSwitch]="wizardStep">
      <app-gate-wizard-content-entrances
        *ngSwitchCase="step.ADDRESSES"
        [serviceId]="serviceId"
        [loading]="loading"
        [addresses]="addresses"
        class="component-body-item"
        (changePageMode)="onChangeControlsVisible($event.default, $event.default)"
      ></app-gate-wizard-content-entrances>

      <app-gate-wizard-content-intercom-type
        *ngSwitchCase="step.INTERCOM_TYPE"
        [intercomType]="selectedIntercomType"
        [intercomTypes]="intercomTypes"
        [disabled]="intercoms?.length > 0"
        [loading]="loading"
        class="component-body-item"
      ></app-gate-wizard-content-intercom-type>

      <app-gate-wizard-content-intercoms
        *ngSwitchCase="step.INTERCOMS"
        [intercoms]="intercoms"
        [serviceId]="serviceId"
        [loading]="loading"
        [index]="index"
        [currentMode]="mode"
        [filter]="mode === gateMode.DEFAULT && intercoms.length > 1 && allIntercomsIsIp() ? intercomEditFilter.IP_TYPE : ''"

        [addresses]="addresses"
        [avaliableIntercoms]="avaliableIntercoms"
        [selectedIntercom]="selectedIntercom"
        [intercomTypes]="intercomTypes"
        [selectedIntercomType]="selectedIntercomType"
        [entrancesIntercoms]="entrancesIntercoms"
        class="component-body-item"
        (changeControlsVisible)="onChangeControlsVisible($event.showMenu, $event.showStepButtons)"
      ></app-gate-wizard-content-intercoms>

      <app-gate-wizard-content-camera
        *ngSwitchCase="step.CAMERA"
        [intercoms]="intercoms"
        [loading]="loading"
        [cameras]="cameras"
        [addresses]="addresses"
        [locations]="camerasLocations"
        class="component-body-item"
      ></app-gate-wizard-content-camera>

      <ng-container *ngIf="abonentsBillingEnabled$ | async">
        <app-gate-wizard-content-tariff
          *ngSwitchCase="step.TARIFF"
          class="software-intercom-wizard-content-data"
          [serviceId]="serviceId"
        ></app-gate-wizard-content-tariff>
      </ng-container>

      <app-gate-wizard-content-checklist
        *ngSwitchCase="step.CHECKLIST"
        [intercoms]="intercoms"
        class="component-body-item"
        (changeStep)="onChangeStep($event.stepIdx)"
      ></app-gate-wizard-content-checklist>
    </ng-container>
  </div>

  <div *ngIf="showStepButtons && steps" class="component-actions-block">
    <button
      mat-raised-button
      color="primary-inverse"
      [disabled]="wizardStep < 1"
      (click)="onPreviousStep()"
    >
      {{ 'services.gates.page.popups.wizard.content.button.prev' | translate }}
    </button>

    <button
      mat-raised-button
      color="primary"
      [disabled]="wizardStep === steps.length - 1"
      (click)="onNextStep()"
    >
      {{ 'services.gates.page.popups.wizard.content.button.next' | translate }}
    </button>
  </div>
</div>
