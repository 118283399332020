import { AddressInfo } from '@app/shared/models';

export interface RdaUpdateRequest {
  id?: number;
  uid?: string;
  intercomTypeId?: number;
  translation?: number;
  address?: AddressInfo;
  entrance?: string;
  range?: [number, number];
  cameraId?: number;
  companyId?: number;
  speakerVolume?: number;
  micSensitivity?: number;
  batchNumber?: Number;
  batchId?: Number;
  shieldId?: Number;
  rdaModel?: string;
  configStr?: string;
  mode?: string;
  protocolNumber?: number;
  dhcpEnabled?: boolean;
}
