import { createSelector } from '@ngrx/store';
import { SharedModuleStates } from '../../reducers/reducers';
import { sharedModuleSelector } from '../shared-module.state';

export const selectIntercomTypesState = createSelector(
  sharedModuleSelector,
  (state: SharedModuleStates) => state.intercomTypes
);

export const getIntercomTypes = createSelector(
  selectIntercomTypesState,
  state => state
);

export const getIntercomTypesSuccess = createSelector(
  selectIntercomTypesState,
  state => state.types
);

export const getIntercomTypesLoading = createSelector(
  selectIntercomTypesState,
  state => state.loading
);

export const getIntercomTypesError = createSelector(
  selectIntercomTypesState,
  state => state.error
);
