import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { AbonentsListModule } from '@app/shared/components/connections/abonents-list';
import { AccountSelectionModule } from '@app/shared/components/connections/account-selection';
import { SharedDirectivesModule } from '@app/shared/directives';
import { ConnectionService } from '@app/shared/entities/rd';
import { SharedPipesModule } from '@app/shared/pipes';
import { InfoBlockModule } from '@app/shared/ui';
import { ComponentLoaderModule } from '@app/shared/ui/component-loader';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AbonentsFromEntrancesContentComponent } from './abonents-from-entrances-content.component';
import { AbonentsFromEntrancesHelper } from './abonents-from-entrances-content.helper';
import { AbonentsFromEntrancesEffects, AbonentsFromEntrancesFacade, abonentsFromEntrancesReducer } from './store';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        SharedPipesModule,
        SharedDirectivesModule,
        ComponentLoaderModule,
        InfoBlockModule,
        AbonentsListModule,
        AccountSelectionModule,
        EffectsModule.forFeature([AbonentsFromEntrancesEffects]),
        StoreModule.forFeature(
            'abonents-from-entrances', abonentsFromEntrancesReducer
        ),
        NgxTranslateModule
    ],
    providers: [
        ConnectionService,
        AbonentsFromEntrancesHelper,
        AbonentsFromEntrancesFacade,
    ],
    declarations: [
        AbonentsFromEntrancesContentComponent
    ],
    exports: [
        AbonentsFromEntrancesContentComponent
    ]
})
export class AbonentsFromEntrancesContentModule { }
