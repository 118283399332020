import { CreateDelegationResponse } from '@app/shared/entities/rd';
import { ServicesTypes } from '@app/shared/models';
import { ServiceEntrancesFlats, ServiceFlat } from '../../components';
import { ServiceBlockPhysicalTubeHandlerData, ServiceConnectionWithType, ServiceCreateConnectionsHandlerData } from '../../models';

export class ServicesConnectionHandler {
    static handleCreateServiceConnections(
        data: Partial<ServiceCreateConnectionsHandlerData>
    ): { flats: ServiceEntrancesFlats, filteredFlats: ServiceEntrancesFlats } {
        const {
            flats,
            filteredFlats,
            connections,
            flat,
            abonent,
            account,
            entranceId,
            flatNumber,
            virtual,
        } = data;

        let serviceFlat: Partial<ServiceFlat>;
        let isVirtualFlat: boolean;

        isVirtualFlat = this.checkFlatIsVirtual(flats, entranceId, flatNumber);

        if (!isVirtualFlat) {
            serviceFlat = flats[entranceId].flats[flatNumber];
        } else {
            serviceFlat = flats[entranceId].virtualFlats[flatNumber];
        }

        if (!serviceFlat) {
            serviceFlat = {};
        }

        if (!serviceFlat.flatNumber) {
            serviceFlat.flatNumber = flatNumber;
        }

        if (!serviceFlat.flat) {
            serviceFlat.flat = {};
            serviceFlat.flat = flat;
        }

        if (serviceFlat.flat && virtual !== undefined) {
            serviceFlat.flat.virtual = virtual;
        }

        if (abonent || account) {
            if (!serviceFlat.account?.id && account && connections?.length) {
                serviceFlat.account = account;
            }

            if (!serviceFlat.account?.id && abonent) {
                serviceFlat.account = { owner: abonent };
            }

            if (serviceFlat.flat && !serviceFlat.flat?.owner && abonent) {
                serviceFlat.flat.owner = abonent;
            }
        }

        connections.forEach((connection: ServiceConnectionWithType) => {
            if (!serviceFlat.flat?.id) {
                serviceFlat.flat = connection.flat;
            }

            if (connection) {
                if (!serviceFlat.account?.id) {
                    serviceFlat.account = connection.account;
                }

                if (!serviceFlat.flat) {
                    serviceFlat.flat = connection.flat;
                }
            }


            if (!serviceFlat.services) {
                serviceFlat.services = {};
            }

            serviceFlat.services[connection.type] = connection;

            if (!isVirtualFlat) {
                if (!flats[entranceId].flats[flatNumber]) {
                    flats[entranceId].flatsCount++;
                    filteredFlats[entranceId].flatsCount++;
                }
            } else {
                if (!flats[entranceId].virtualFlats[flatNumber]) {
                    flats[entranceId].virtualFlatsCount++;
                    filteredFlats[entranceId].virtualFlatsCount++;
                }
            }
        });

        if (!isVirtualFlat) {
            flats[entranceId].flats[flatNumber] = serviceFlat;
            filteredFlats[entranceId].flats[flatNumber] = serviceFlat;
        } else {
            flats[entranceId].virtualFlats[flatNumber] = serviceFlat;
            filteredFlats[entranceId].virtualFlats[flatNumber] = serviceFlat;
        }

        return { flats, filteredFlats };
    }

    static handleDeleteServiceConnection(
        flats: ServiceEntrancesFlats,
        filteredFlats: ServiceEntrancesFlats,
        connection: ServiceConnectionWithType
    ): { flats: ServiceEntrancesFlats, filteredFlats: ServiceEntrancesFlats } {
        const entranceId: number = connection.flat.address.entrance.id;
        const flatNumber: number = connection.flat.address.flat;
        const serviceType: ServicesTypes = connection.type;

        const isVirtualFlat = this.checkFlatIsVirtual(flats, entranceId, flatNumber);

        const flat: Partial<ServiceFlat> =
            isVirtualFlat ? flats[entranceId].virtualFlats[flatNumber] : flats[entranceId].flats[flatNumber];

        delete flat.services[serviceType];

        if (!Object.keys(flat.services).length) {
            flat.account = { owner: flat.account.owner };
        }

        if (flat?.flat?.id && connection.type === ServicesTypes.HARDWARE_INTERCOM) {
            flat.flat.virtual = true;
        }

        if (!isVirtualFlat) {
            flats[entranceId].flats[flatNumber] = flat;
            filteredFlats[entranceId].flats[flatNumber] = flat;
        } else {
            flats[entranceId].virtualFlats[flatNumber] = flat;
            filteredFlats[entranceId].virtualFlats[flatNumber] = flat;
        }

        return { flats, filteredFlats };
    }

    static handleBlockServicePhysicalTube(
        data: ServiceBlockPhysicalTubeHandlerData
    ): { flats: ServiceEntrancesFlats, filteredFlats: ServiceEntrancesFlats } {
        const {
            flats,
            filteredFlats,
            entranceId,
            flat,
            connection
        } = data;

        const isVirtualFlat: boolean = this.checkFlatIsVirtual(flats, entranceId, flat.address.flat);

        let serviceFlat: Partial<ServiceFlat>;

        if (!isVirtualFlat) {
            serviceFlat = flats[entranceId].flats[flat.address.flat];
        } else {
            serviceFlat = flats[entranceId].virtualFlats[flat.address.flat];
        }

        if (!serviceFlat) {
            serviceFlat = {};
        }

        if (!serviceFlat.flatNumber && connection.flat.address) {
            serviceFlat.flatNumber = flat.address.flat;
        }

        if (!serviceFlat.flat?.id && connection.flat) {
            serviceFlat.flat = connection.flat;
        }

        if (!serviceFlat.account?.id && connection.account) {
            serviceFlat.account = connection.account;
        }

        if (!serviceFlat.services) {
            serviceFlat.services = {};
        }

        serviceFlat.services[connection.type] = connection;
        serviceFlat.services[connection.type].blocked = true;

        if (!isVirtualFlat) {
            flats[entranceId].flats[flat.address.flat] = serviceFlat;
            filteredFlats[entranceId].flats[flat.address.flat] = serviceFlat;
        } else {
            flats[entranceId].virtualFlats[flat.address.flat] = serviceFlat;
            filteredFlats[entranceId].virtualFlats[flat.address.flat] = serviceFlat;
        }

        return { flats, filteredFlats };
    }

    static handleUnblockServicePhysicalTubeSuccess(
        flats: ServiceEntrancesFlats,
        filteredFlats: ServiceEntrancesFlats,
        connection: ServiceConnectionWithType
    ): { flats: ServiceEntrancesFlats, filteredFlats: ServiceEntrancesFlats } {
        const entranceId: number = connection.flat.address.entrance.id;
        const flatNumber: number = connection.flat.address.flat;
        const isVirtualFlat: boolean = this.checkFlatIsVirtual(flats, entranceId, flatNumber);

        const flat: Partial<ServiceFlat> =
            isVirtualFlat ? flats[entranceId].virtualFlats[flatNumber] : flats[entranceId].flats[flatNumber];

        flat.services[ServicesTypes.HARDWARE_INTERCOM].blocked = false;

        if (!Object.keys(flat.services).length && flat.account?.owner) {
            flat.account = { owner: flat.account.owner };
        }

        if (!isVirtualFlat) {
            flats[entranceId].flats[flatNumber] = flat;
            filteredFlats[entranceId].flats[flatNumber] = flat;
        } else {
            flats[entranceId].virtualFlats[flatNumber] = flat;
            filteredFlats[entranceId].virtualFlats[flatNumber] = flat;
        }

        return { flats, filteredFlats };
    }

    static handleAddServicePhysicalTube(
        flats: ServiceEntrancesFlats,
        filteredFlats: ServiceEntrancesFlats,
        entranceId: number,
        flatNumber: number
    ): { flats: ServiceEntrancesFlats, filteredFlats: ServiceEntrancesFlats } {
        const isVirtualFlat: boolean = this.checkFlatIsVirtual(flats, entranceId, flatNumber);

        if (!isVirtualFlat) {
            flats[entranceId].flats[flatNumber].flat.virtual = false;
            filteredFlats[entranceId].flats[flatNumber].flat.virtual = false;
        } else {
            flats[entranceId].virtualFlats[flatNumber].flat.virtual = false;
            filteredFlats[entranceId].virtualFlats[flatNumber].flat.virtual = false;
        }

        return { flats, filteredFlats };
    }

    static handleDeleteServicePhysicalTube(
        flats: ServiceEntrancesFlats,
        filteredFlats: ServiceEntrancesFlats,
        entranceId: number,
        flatNumber: number
    ): { flats: ServiceEntrancesFlats, filteredFlats: ServiceEntrancesFlats } {
        const isVirtualFlat: boolean = this.checkFlatIsVirtual(flats, entranceId, flatNumber);
        const flat: Partial<ServiceFlat> = isVirtualFlat ? flats[entranceId].virtualFlats[flatNumber] : flats[entranceId].flats[flatNumber];

        if (flat.services?.[ServicesTypes.HARDWARE_INTERCOM]) {
            delete flat.services[ServicesTypes.HARDWARE_INTERCOM];
        }

        if (flat?.flat?.virtual !== undefined) {
            flat.flat.virtual = true;
        }

        if (!isVirtualFlat) {
            flats[entranceId].flats[flatNumber] = flat;
            filteredFlats[entranceId].flats[flatNumber] = flat;
        } else {
            flats[entranceId].virtualFlats[flatNumber] = flat;
            filteredFlats[entranceId].virtualFlats[flatNumber] = flat;
        }

        return { flats, filteredFlats };
    }

    static handleBlockServiceConnection(
        flats: ServiceEntrancesFlats,
        filteredFlats: ServiceEntrancesFlats,
        connection: ServiceConnectionWithType
    ): { flats: ServiceEntrancesFlats, filteredFlats: ServiceEntrancesFlats } {
        const entranceId: number = connection.flat.address.entrance.id;
        const flatNumber: number = connection.flat.address.flat;
        const isVirtualFlat = this.checkFlatIsVirtual(flats, entranceId, flatNumber);

        if (!isVirtualFlat) {
            flats[entranceId].flats[flatNumber].services[connection.type].blocked = true;
            filteredFlats[entranceId].flats[flatNumber].services[connection.type].blocked = true;
        } else {
            flats[entranceId].virtualFlats[flatNumber].services[connection.type].blocked = true;
            filteredFlats[entranceId].virtualFlats[flatNumber].services[connection.type].blocked = true;
        }

        return { flats, filteredFlats };
    }

    static handleUnblockServiceConnection(
        flats: ServiceEntrancesFlats,
        filteredFlats: ServiceEntrancesFlats,
        connection: ServiceConnectionWithType
    ): { flats: ServiceEntrancesFlats, filteredFlats: ServiceEntrancesFlats } {
        const entranceId: number = connection.flat.address.entrance.id;
        const flatNumber: number = connection.flat.address.flat;
        const serviceType: ServicesTypes = connection.type;
        const isVirtualFlat = this.checkFlatIsVirtual(flats, entranceId, flatNumber);

        if (!isVirtualFlat) {
            flats[entranceId].flats[flatNumber].services[serviceType].blocked = false;
            filteredFlats[entranceId].flats[flatNumber].services[serviceType].blocked = false;

            if (connection) {
                flats[entranceId].flats[flatNumber].services[serviceType] = { ...connection };
                filteredFlats[entranceId].flats[flatNumber].services[serviceType] = { ...connection };
            }
        } else {
            flats[entranceId].virtualFlats[flatNumber].services[serviceType].blocked = false;
            filteredFlats[entranceId].virtualFlats[flatNumber].services[serviceType].blocked = false;

            if (connection) {
                flats[entranceId].virtualFlats[flatNumber].services[serviceType] = { ...connection };
                filteredFlats[entranceId].virtualFlats[flatNumber].services[serviceType] = { ...connection };
            }
        }

        return { flats, filteredFlats };
    }

    static handleDeleteServiceFlat(
        flats: ServiceEntrancesFlats,
        filteredFlats: ServiceEntrancesFlats,
        fillEmptyFlats: boolean,
        entranceId: number,
        flatNumber: number
    ): { flats: ServiceEntrancesFlats, filteredFlats: ServiceEntrancesFlats } {
        if (fillEmptyFlats) {
            if (flats[entranceId].flats[flatNumber]) {
                flats[entranceId].flats[flatNumber] = { flatNumber };
                filteredFlats[entranceId].flats[flatNumber] = { flatNumber };
            } else {
                delete flats[entranceId].virtualFlats[flatNumber];
                delete filteredFlats[entranceId].virtualFlats[flatNumber];
                flats[entranceId].virtualFlatsCount = Object.keys(flats[entranceId].virtualFlats).length;
                filteredFlats[entranceId].virtualFlatsCount = Object.keys(filteredFlats[entranceId].virtualFlats).length;
            }
        } else {
            if (flats[entranceId].flats[flatNumber]) {
                delete flats[entranceId].flats[flatNumber];
                delete filteredFlats[entranceId].flats[flatNumber];
                flats[entranceId].flatsCount = Object.keys(flats[entranceId].flats).length;
                filteredFlats[entranceId].flatsCount = Object.keys(filteredFlats[entranceId].flats).length;
            } else {
                delete flats[entranceId].virtualFlats[flatNumber];
                delete filteredFlats[entranceId].virtualFlats[flatNumber];
                flats[entranceId].virtualFlatsCount = Object.keys(flats[entranceId].virtualFlats).length;
                filteredFlats[entranceId].virtualFlatsCount = Object.keys(filteredFlats[entranceId].virtualFlats).length;
            }
        }

        return { flats, filteredFlats };
    }

    static handleDelegateServiceAbonentAccess(
        flats: ServiceEntrancesFlats,
        filteredFlats: ServiceEntrancesFlats,
        entranceId: number,
        response: CreateDelegationResponse
    ): { flats: ServiceEntrancesFlats, filteredFlats: ServiceEntrancesFlats } {
        Object.keys(flats[entranceId].flats).forEach(flatNumber => {
            if (flats[entranceId].flats[flatNumber]?.account?.owner?.id === response.fromAbonent.id) {
                if (!flats[entranceId].flats[flatNumber].sharedAbonents) {
                    flats[entranceId].flats[flatNumber].sharedAbonents = [];
                }

                flats[entranceId].flats[flatNumber].sharedAbonents.push({
                    delegationId: response.id,
                    abonentId: response.toAbonent.id,
                    number: response.toAbonent.phone
                });

                if (filteredFlats[entranceId].flats[flatNumber]) {
                    filteredFlats[entranceId].flats[flatNumber].sharedAbonents =
                        flats[entranceId].flats[flatNumber].sharedAbonents;
                }
            }
        });

        Object.keys(flats[entranceId].virtualFlats).forEach(flatNumber => {
            if (flats[entranceId].virtualFlats[flatNumber]?.account?.owner?.id === response.fromAbonent.id) {
                if (!flats[entranceId].virtualFlats[flatNumber].sharedAbonents) {
                    flats[entranceId].virtualFlats[flatNumber].sharedAbonents = [];
                }

                flats[entranceId].virtualFlats[flatNumber].sharedAbonents.push({
                    delegationId: response.id,
                    abonentId: response.toAbonent.id,
                    number: response.toAbonent.phone
                });

                if (filteredFlats[entranceId].virtualFlats[flatNumber]) {
                    filteredFlats[entranceId].virtualFlats[flatNumber].sharedAbonents =
                        flats[entranceId].virtualFlats[flatNumber].sharedAbonents;
                }
            }
        });

        return { flats, filteredFlats };
    }

    static handleDeleteServiceAbonentAccess(
        flats: ServiceEntrancesFlats,
        filteredFlats: ServiceEntrancesFlats,
        entranceId: number,
        delegationId: number
    ): { flats: ServiceEntrancesFlats, filteredFlats: ServiceEntrancesFlats } {
        Object.keys(flats[entranceId].flats).forEach(flatNumber => {
            if (!flats[entranceId].flats[flatNumber].sharedAbonents) {
                return;
            }

            const delegationIdx: number = flats[entranceId].flats[flatNumber].sharedAbonents.findIndex(abonent =>
                abonent.delegationId === delegationId
            );

            if (delegationIdx !== -1) {
                flats[entranceId].flats[flatNumber].sharedAbonents.splice(delegationIdx, 1);

                if (filteredFlats[entranceId].flats[flatNumber]) {
                    filteredFlats[entranceId].flats[flatNumber].sharedAbonents.splice(delegationIdx, 1);
                }
            }
        });

        Object.keys(flats[entranceId].virtualFlats).forEach(flatNumber => {
            if (!flats[entranceId].virtualFlats[flatNumber].sharedAbonents) {
                return;
            }

            const delegationIdx: number = flats[entranceId].virtualFlats[flatNumber].sharedAbonents.findIndex(abonent =>
                abonent.delegationId === delegationId
            );

            if (delegationIdx !== -1) {
                flats[entranceId].virtualFlats[flatNumber].sharedAbonents.splice(delegationIdx, 1);

                if (filteredFlats[entranceId].virtualFlats[flatNumber]) {
                    filteredFlats[entranceId].virtualFlats[flatNumber].sharedAbonents.splice(delegationIdx, 1);
                }
            }
        });

        return { flats, filteredFlats };
    }

    private static checkFlatIsVirtual(flats: ServiceEntrancesFlats, entranceId: number, flatNumber: number): boolean {
        return flats?.[entranceId]?.flatRangesInfo?.findIndex(rangeInfo =>
            flatNumber >= rangeInfo.range[0] && flatNumber <= rangeInfo.range[1]
        ) === -1;
    }
}
