import { IntercomType, IpRdaConnectionTypes } from '@app/shared/entities/rd';
import { Address } from '@app/shared/models';
import { BatchResponse } from '@app/views/intercom/models';
import { IntercomPanelResponse } from './intercom-panel-response.model';

export class RdaResponse {
  id: number;
  address: Address;
  uid: string;
  companyId: number;
  intercomTypeId: number;
  translation?: number;
  intercomType?: IntercomType;
  active: boolean;
  entranceId: number;
  cameraId: number;
  lastHeartBeatTime: number;
  versionInfoShield: string;
  versionInfoStm: string;
  versionInfoStmDriver: string;
  versionInfoOs: string;
  versionInfoRdaa: string;
  ipAddress: string;
  speakerVolume: number;
  micSensitivity: number;
  batch: BatchResponse;
  configStr: string;
  mode?: IpRdaConnectionTypes;
  intercoms?: IntercomPanelResponse[];
  dhcpEnabled?: boolean;
  description?: string;

  constructor(values?: Partial<RdaResponse>) {
    Object.assign(this, values);
  }
}
