import { LoaderService } from '@app/shared/entities/common/loader';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ResourcePath, ResourcesHelper } from '@app/shared/entities';
import { GitlabComponentsVersions } from '@app/shared/entities/integrations';
import { Camera, Company, KeysResponse, PbxOnRdaResponse, RdaResponse, RdaUtilsService } from '@app/shared/entities/rd';
import { EntrancePageResponse, Flat, LogsResponse } from '@app/shared/models';
import { Observable } from 'rxjs';
import { EntrancesFacade } from '../../store';
import { EntrancePageHelper } from './entrance-page.helper';

@Component({
  selector: 'app-entrance-page',
  templateUrl: './entrance-page.component.html',
  styleUrls: ['./entrance-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EntrancePageComponent implements OnInit, OnDestroy {
  readonly resourcePath: typeof ResourcePath = ResourcePath;

  rda$: Observable<RdaResponse> = this.entrancesFacade.rda$;
  logs$: Observable<LogsResponse[]> = this.entrancesFacade.logs$;
  keys$: Observable<KeysResponse[]> = this.entrancesFacade.keys$;
  flats$: Observable<Flat[]> = this.entrancesFacade.flats$;
  company$: Observable<Company> = this.entrancesFacade.company$;
  cameras$: Observable<Camera[]> = this.entrancesFacade.cameras$;
  entrance$: Observable<EntrancePageResponse> = this.entrancesFacade.entrance$;
  pbxOnRda$: Observable<PbxOnRdaResponse> = this.entrancesFacade.pbxOnRda$;
  camerasWithErrors$: Observable<Camera[]> = this.entrancesFacade.camerasWithErrors$;
  gitlabComponentsVersions$: Observable<GitlabComponentsVersions> = this.entrancesFacade.gitlabVersions$;

  loadingEntrance$: Observable<boolean> = this.entrancesFacade.entranceLoading$;
  keysLoading$: Observable<boolean> = this.entrancesFacade.keysLoading$;
  flatsLoading$: Observable<boolean> = this.entrancesFacade.flatsLoading$;
  camerasLoading$: Observable<boolean> = this.entrancesFacade.camerasLoading$;
  logsLoading$: Observable<boolean> = this.entrancesFacade.logsLoading$;

  incorrectId$: Observable<boolean> = this.entrancesFacade.incorrectId$;

  constructor(
    public resourcesHelper: ResourcesHelper,
    private route: ActivatedRoute,
    private rdaUtilsService: RdaUtilsService,
    private entrancesFacade: EntrancesFacade,
    private entrancePageHelper: EntrancePageHelper,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.loaderService.loaderState = { state: false };

    const entranceId = this.entrancePageHelper.getEntranceIdFromActivatedRoute(this.route.snapshot);

    if (!entranceId) {
      this.entrancesFacade.setIncorrectId(true);
      return;
    }

    const addressStr = this.route.snapshot.queryParams.address;

    if (addressStr) {
      this.entrancePageHelper.updateTitle(entranceId, addressStr);
    }

    this.entrancesFacade.loadEntrance(entranceId);
  }

  ngOnDestroy() {
    this.entrancesFacade.clearEntranceStore();
  }

  showEntranceWarning(entrance: EntrancePageResponse): boolean {
    if (!entrance?.rda?.active || !entrance.rda?.intercomType?.id) {
      return false;
    }

    return this.rdaUtilsService.checkAdapterErrors(entrance.rda) && this.rdaUtilsService.ipType(entrance.rda.intercomType) === false;
  }

  showServiceInfo(rda: RdaResponse) {
    return this.rdaUtilsService.ipType(rda?.intercomType) === false &&
      this.rdaUtilsService.ipType(rda?.intercomType) !== undefined;
  }

  onLoadLogs(rdaUid: string, timeRange: number) {
    this.entrancesFacade.loadLogs(rdaUid, timeRange);
  }
}
