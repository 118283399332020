import { Injectable, OnDestroy } from '@angular/core';
import { DialogWrapperStateHelper } from '@app/shared/ui';
import { ServicesActionTypes } from '@app/views/services/store';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class SoftwareIntercomWizardPopupService extends DialogWrapperStateHelper<'loading' | 'loaded' | 'done'> implements OnDestroy {
    constructor(
        private actions$: Actions
    ) {
        super();
        this.enableStateListeners();
    }

    ngOnDestroy() {
        this.destroy();
    }

    private enableStateListeners() {
        this.actions$
            .pipe(
                ofType(...[
                    ServicesActionTypes.AddServiceEntrance,
                    ServicesActionTypes.UpdateServiceEntrance,
                    ServicesActionTypes.DeleteServiceEntrance,
                    ServicesActionTypes.AddAdditionalFlatRange,
                    ServicesActionTypes.UpdateAdditionalFlatRange,
                    ServicesActionTypes.DeleteAdditionalFlatRange,

                    ServicesActionTypes.AddServiceRda,
                    ServicesActionTypes.ChangeServiceRda,
                    ServicesActionTypes.UpdateServiceRda,

                    ServicesActionTypes.AddTranslationTuning,
                    ServicesActionTypes.DeleteTranslationTuning,

                    ServicesActionTypes.AddServiceCamera,
                    ServicesActionTypes.UpdateServiceCamera,

                    ServicesActionTypes.AddServiceIntercomPanel,
                    ServicesActionTypes.DeleteServiceIntercomPanel,
                    ServicesActionTypes.UpdateServiceIntercomPanel
                ]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loading');

        this.actions$
            .pipe(
                ofType(...[
                    ServicesActionTypes.AddServiceIntercomPanelSuccess,
                    ServicesActionTypes.DeleteServiceIntercomPanelSuccess
                ]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'done');

        this.actions$
            .pipe(
                ofType(...[
                    ServicesActionTypes.AddServiceEntranceSuccess,
                    ServicesActionTypes.AddServiceEntranceFailure,
                    ServicesActionTypes.UpdateServiceEntranceSuccess,
                    ServicesActionTypes.UpdateServiceEntranceFailure,
                    ServicesActionTypes.DeleteServiceEntranceSuccess,
                    ServicesActionTypes.DeleteServiceEntranceFailure,
                    ServicesActionTypes.AddAdditionalFlatRangeSuccess,
                    ServicesActionTypes.AddAdditionalFlatRangeFailure,
                    ServicesActionTypes.UpdateAdditionalFlatRangeSuccess,
                    ServicesActionTypes.UpdateAdditionalFlatRangeFailure,
                    ServicesActionTypes.DeleteAdditionalFlatRangeSuccess,
                    ServicesActionTypes.DeleteAdditionalFlatRangeFailure,

                    ServicesActionTypes.AddServiceRdaSuccess,
                    ServicesActionTypes.AddServiceRdaFailure,
                    ServicesActionTypes.ChangeServiceRdaSuccess,
                    ServicesActionTypes.ChangeServiceRdaFailure,
                    ServicesActionTypes.UpdateServiceRdaSuccess,
                    ServicesActionTypes.UpdateServiceRdaFailure,

                    ServicesActionTypes.AddTranslationTuningSuccess,
                    ServicesActionTypes.AddTranslationTuningFailure,
                    ServicesActionTypes.DeleteTranslationTuningSuccess,
                    ServicesActionTypes.DeleteTranslationTuningFailure,

                    ServicesActionTypes.AddServiceCameraSuccess,
                    ServicesActionTypes.AddServiceCameraFailure,
                    ServicesActionTypes.UpdateServiceCameraSuccess,
                    ServicesActionTypes.UpdateServiceCameraFailure,

                    ServicesActionTypes.AddServiceIntercomPanelFailure,
                    ServicesActionTypes.UpdateServiceIntercomPanelSuccess,
                    ServicesActionTypes.UpdateServiceIntercomPanelFailure,
                    ServicesActionTypes.DeleteServiceIntercomPanelFailure,
                    ServicesActionTypes.UpdateServiceIntercomPanelFailure
                ]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loaded');
    }
}
