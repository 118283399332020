import { Address, Abonent } from '@app/shared/models';

export interface FlatCreateResponse {
    id: number;
    accountId: number;
    adapters: {
        cameraIds: number[],
        intercomIndex: string;
        rdaUid: string;
    }[];
    address: Address;
    blocked: boolean;
    cameraId: number;
    hardwareIntercomId: number;
    owner: Abonent;
    rdaUid: string;
    softwareIntercomId: number;
    translated: number;
    virtual: boolean;
}
