import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Dictionary } from '@app/shared/helpers';
import { LogsComponentType } from '@app/shared/models';
import { ResolutionService } from '@app/shared/entities/common/mobile-query/mobile-query.service';
import { ServiceActivitySource } from '@app/views/services/models';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ActiveHistoryChartData } from './active-history-chart.model';

@Component({
  selector: 'app-active-history-chart',
  templateUrl: './active-history-chart.component.html',
  styleUrls: ['./active-history-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActiveHistoryChartComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly componentTypes = LogsComponentType;

  @ViewChild('table') table: ElementRef;
  @Input() title: string;
  @Input() sources: ServiceActivitySource[];
  @Input() set data(data: Dictionary<ActiveHistoryChartData[]>) {
    this._data = data;

    this.timestamps = [];

    if (Object.keys(this.data).length > 0) {
      const timestamps = this.getTimestampsFromLongestChart(data);

      this.blocksCount = timestamps.length > 0 ? timestamps.length : 1;
      this.minTimestamp = timestamps[timestamps?.length - 1];
      this.maxTimestamp = timestamps[0];

      this.changeWidthSubject.next();
    }
  }
  timestamps: number[] = [];
  currentWidth: number;
  blocksCount: number;

  @Input() private minBlockWidth = 30;
  @Input() private defaultBlocksCount = 20;
  @Output() private widthChanged: EventEmitter<{ blocksCount: number }> = new EventEmitter();
  private _data: Dictionary<ActiveHistoryChartData[]>;
  private scrolledObserver: MutationObserver;
  private changeWidthWithDelaySubject: Subject<number> = new Subject();
  private changeWidthSubject: Subject<number> = new Subject();
  private changeWidthWithDelaySubscription: Subscription;
  private changeWidthSubscription: Subscription;
  private maxTimestamp: number;
  private minTimestamp: number;

  constructor(
    private resolution: ResolutionService
  ) { }

  ngOnInit() {
    this.changeWidthWithDelaySubscription = this.changeWidthWithDelaySubject
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        const tableWidth: number = (this.table.nativeElement as HTMLDivElement).clientWidth;
        this.redrawTable(tableWidth);
      });

    this.changeWidthSubscription = this.changeWidthSubject
      .subscribe(() => {
        if (this.minTimestamp && this.maxTimestamp && this.table) {
          const tableWidth: number = (this.table.nativeElement as HTMLDivElement).clientWidth;

          let dateBlockCount: number;
          if (this.resolution.isMobile) {
            dateBlockCount = Math.ceil(this.defaultBlocksCount * this.minBlockWidth / 100);
          } else {
            dateBlockCount = Math.ceil(tableWidth / 100);
          }

          this.prepareTimestamps(this.minTimestamp, this.maxTimestamp, dateBlockCount);
        }
      });
  }

  ngAfterViewInit() {
    this.redrawTable(this.table.nativeElement.clientWidth);
    this.moveScrollToRight();
    this.initDataBlockMutationObserver();
  }

  ngOnDestroy() {
    if (this.changeWidthWithDelaySubscription) {
      this.changeWidthWithDelaySubscription.unsubscribe();
    }

    if (this.changeWidthSubscription) {
      this.changeWidthSubscription.unsubscribe();
    }

    if (this.scrolledObserver) {
      this.scrolledObserver.disconnect();
    }
  }

  get data(): Dictionary<ActiveHistoryChartData[]> {
    return this._data;
  }

  errorExists(chartData: ActiveHistoryChartData[]) {
    return !chartData ? false : chartData.length === 0 || chartData.findIndex(chartItem => !chartItem.state) > -1;
  }

  dateChanged(curTimestamp: number, prevTimestamp: number): boolean {
    return new Date(curTimestamp).getDate() !== new Date(prevTimestamp).getDate();
  }

  private setCurrentWidth(newWidth: number) {
    this.currentWidth = newWidth;
    this.widthChanged.emit({ blocksCount: Math.floor(this.currentWidth / this.minBlockWidth) - 1 });
  }

  private initDataBlockMutationObserver() {
    this.scrolledObserver = new MutationObserver(() => this.moveScrollToRight());
    const config: MutationObserverInit = { childList: true };

    this.scrolledObserver.observe(this.table.nativeElement, config);
  }

  private moveScrollToRight() {
    if (this.table?.nativeElement && this.table.nativeElement.scrollLeft !== this.table.nativeElement.scrollWidth) {
      this.table.nativeElement.scrollLeft = this.table.nativeElement.scrollWidth;
    }
  }

  private prepareTimestamps(startTimestamp: number, endTimestamp: number, size: number) {
    const result: number[] = [];
    const timeRangeDiff: number = Math.round((endTimestamp - startTimestamp) / size);

    let stopTimeStamp: number = endTimestamp;

    if (timeRangeDiff > 2000000) {
      stopTimeStamp += 1 * 60 * 1000;
    } else {
      stopTimeStamp += 5 * 60 * 1000;
    }

    if (timeRangeDiff === 0) {
      result.push(new Date(startTimestamp).getTime());
      return result;
    }

    for (let timestamp = startTimestamp; timestamp <= stopTimeStamp; timestamp += timeRangeDiff) {
      const currentDate = new Date(timestamp);

      if (timeRangeDiff > 2000000) {
        currentDate.setMinutes(Math.round(currentDate.getMinutes() / 5) * 5);
      }

      result.push(currentDate.getTime());
    }

    this.timestamps = result;
  }

  private redrawTable(tableWidth: number) {
    if (!this.currentWidth || Math.abs(tableWidth - this.currentWidth) > 199) {
      const newWidth = this.resolution.isMobile ? this.defaultBlocksCount * this.minBlockWidth : tableWidth;
      this.setCurrentWidth(newWidth);
    }
  }

  private getTimestampsFromLongestChart(data: Dictionary<ActiveHistoryChartData[]>): number[] {
    const objectArray: number[][] =
      Object
        .values(data)
        .map((row: ActiveHistoryChartData[]) =>
          row ? row.map(rowItem => rowItem.timestamp) : []
        );

    const rowsTimestampsLengths: number[] = objectArray.map(row => row.length);
    const longestRowsTimestampsIndex: number = rowsTimestampsLengths.indexOf(Math.max(...rowsTimestampsLengths));
    const timestamps: number[] = objectArray[longestRowsTimestampsIndex];

    return timestamps;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.changeWidthWithDelaySubject.next(event.target.innerWidth);
    this.changeWidthSubject.next(event.target.innerWidth);
  }
}
