import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GitlabComponentsVersions } from '@app/shared/entities/integrations';
import { Company, IpRdaConnectionTypes, PbxOnRdaResponse, RdaResponse, RdaUtilsService } from '@app/shared/entities/rd';
import { compareVersions } from '@app/shared/helpers';

@Component({
  selector: 'app-rda-card',
  templateUrl: './rda-card.component.html',
  styleUrls: ['./rda-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RdaCardComponent {
  readonly SIPTRUNK = IpRdaConnectionTypes.SIPTRUNK;

  @Input() set rda(rda: RdaResponse) {
    this._rda = rda;
    if (this._rda) {
      this.initRdaVariables();
      this.initIpIntercomVariables();

      if (this.gitlabComponentsVersions) {
        this.initLatestVersions();
      }
    }
  }
  @Input() pbxOnRda: PbxOnRdaResponse;
  @Input() set gitlabComponentsVersions(gitlabComponentsVersions: GitlabComponentsVersions) {
    this._gitlabComponentsVersions = gitlabComponentsVersions;

    if (this._gitlabComponentsVersions && this.rda) {
      this.initLatestVersions();
    }
  }
  @Input() company!: Company;
  @Input() loading: boolean;
  ipIntercom: boolean;
  updateAvaliable: boolean;
  someComponentHasUpdate: boolean;
  rdosLatest: boolean;
  rdaaLatest: boolean;
  supportedIntercom: boolean;
  stmDriverUpgradeSupported: boolean;
  stmDriverAvaliable: boolean;
  correctedVersions: boolean;
  livenessUrl: string;

  private _rda: RdaResponse;
  private _gitlabComponentsVersions: GitlabComponentsVersions;

  constructor(
    private rdaUtilsService: RdaUtilsService
  ) { }

  get rda(): RdaResponse {
    return this._rda;
  }

  get gitlabComponentsVersions(): GitlabComponentsVersions {
    return this._gitlabComponentsVersions;
  }

  private checkLatestVersion(rdaVersion: string, latestVersion: string): boolean {
    const comparedVersions: number = compareVersions(rdaVersion, latestVersion);

    if (comparedVersions === null) {
      return null;
    }

    return comparedVersions >= 0;
  }

  private initRdaVariables() {
    this.ipIntercom = this.rdaUtilsService.ipType(this.rda.intercomType);

    if (this.ipIntercom || !this.rda) {
      return;
    }

    this.updateAvaliable = this.rda?.active;
    this.supportedIntercom = this.rdaUtilsService.supportedIntercom(this.rda, this.rda?.intercomType);
    this.correctedVersions = this.rdaUtilsService.correctedVersions(this.rda, this.rda?.intercomType);
    this.stmDriverUpgradeSupported = this.rdaUtilsService.stmDriverUpgradeSupported(this.rda.versionInfoOs);
    this.stmDriverAvaliable = this.rdaUtilsService.stmDriverAvaliable(this.rda.versionInfoRdaa);
  }

  private initIpIntercomVariables() {
    if (!this.rdaUtilsService.ipType(this.rda.intercomType) || !this.rda) {
      return;
    }

    const { liveness } = this.rdaUtilsService.convertConfigStrToParams(this.rda?.configStr);
    this.livenessUrl = liveness || '';
  }

  private initLatestVersions() {
    this.rdosLatest = this.checkLatestVersion(this.rda?.versionInfoOs, this.gitlabComponentsVersions?.rdosLatest);
    this.rdaaLatest = this.checkLatestVersion(this.rda?.versionInfoRdaa, this.gitlabComponentsVersions?.rdaaLatest);
    this.someComponentHasUpdate = !this.rdosLatest || !this.rdaaLatest;
  }
}
