<ng-container *ngIf="isEmpty(additionalActions) then noAdditionalActions else haveAdditionalActions"></ng-container>

<ng-template #noAdditionalActions>
  <div *ngIf="title" class="title-bar__wrapper">
    <p-button *ngIf="!!snapshotDataParentRoute" styleClass="p-button-link"
              [label]="snapshotDataParentRoute?.label | translate" [icon]="snapshotDataParentRoute?.icon || 'pi pi-chevron-left'"
              [routerLink]="[snapshotDataParentRoute?.link]"></p-button>
    <h1 class="title-bar--title"
        [ngClass]="{'title-bar--title--centered': !!snapshotDataParentRoute, 'title-bar--title--with-padding': !isEmpty(additionalActions)}">{{ title }}</h1>
    <div *ngIf="link?.length > 0" class="title-bar__links__wrapper">
      <app-button-link [link]="link" class="title-bar--link">{{ 'core.title_bar.how_it_work' | translate }}</app-button-link>
    </div>
  </div>
</ng-template>

<ng-template #haveAdditionalActions>
  <div *ngIf="title || link?.length > 0 || !isEmpty(additionalActions)" class="title-bar__wrapper title-bar__with-actions">
    <p-button *ngIf="!!snapshotDataParentRoute" styleClass="p-button-text"
              [label]="snapshotDataParentRoute?.label | translate" [icon]="snapshotDataParentRoute?.icon || 'pi pi-chevron-left'"
              [routerLink]="[snapshotDataParentRoute?.link]"></p-button>

    <h1 *ngIf="title" class="title-bar--title"
        [ngClass]="{'title-bar--title--centered': !!snapshotDataParentRoute, 'title-bar--title--with-padding': !isEmpty(additionalActions)}">{{ title }}</h1>
    <div *ngIf="link?.length > 0" class="title-bar__links__wrapper">
      <app-button-link [link]="link" class="title-bar--link">{{ 'core.title_bar.how_it_work' | translate }}</app-button-link>
    </div>
    <div *ngIf="!isEmpty(additionalActions)" class="title-bar__actions__wrapper">
      <p-button *ngFor="let additionalAction of additionalActions"
                [disabled]="additionalAction?.disabled" [label]="additionalAction?.label" [icon]="additionalAction?.icon"
                (onClick)="additionalAction?.action()"></p-button>
    </div>
  </div>
</ng-template>
