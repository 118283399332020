import {Injectable} from '@angular/core';
import {AccountApiService, ServiceApiService} from '@app/shared/entities/rd';
import {ApartmentVideoSurveillancePage} from '@app/views/services/submodules/apartment-video-surveillance/components';
import {Observable, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {isEmpty} from 'lodash';
import {SnackbarService} from '@app/shared/components';
import {parseError} from '@app/shared/helpers';

@Injectable()
export class ApartmentVideoSurveillanceActionsService {

  constructor(
    private serviceApiService: ServiceApiService,
    private accountApiService: AccountApiService,
    private snackbar: SnackbarService,
  ) {
  }

  public removeService(serviceId: number, goutput: ApartmentVideoSurveillancePage.ExtDeleteOutput): Observable<boolean> {
    return (
      goutput?.confirm ?
        this.serviceApiService.getConnections(serviceId).pipe(map((r) => ({
          ...goutput,
          connectionId: !isEmpty(r) ? r[0].id : null
        }))) :
        of(null)
    ).pipe(
      mergeMap((output: ApartmentVideoSurveillancePage.ExtDeleteOutput) => {
          if (output?.connectionId) {
            return this.serviceApiService.deleteServiceConnection(output?.connectionId)
              .pipe(
                map((r) => ({...output, errorWhenDisconnect: false})),
                catchError((err) => {
                  this.snackbar.showMessage(parseError(err));
                  return of({...output, errorWhenDisconnect: true});
                })
              );
          }
          return of(output);
        }
      ),
      mergeMap((output: ApartmentVideoSurveillancePage.ExtDeleteOutput) => {
          if (output?.confirm) {
            return this.serviceApiService.deleteService(serviceId)
              .pipe(
                map((r) => true),
                catchError((err) => {
                  this.snackbar.showMessage(parseError(err));
                  return of(false);
                })
              );
          }
          return of(false);
        }
      )
    );
  }

  public blockingAVService(serviceId: number): Observable<boolean> {
    return this.accountApiService.getServiceConnections(serviceId).pipe(
      mergeMap((response) => {
        return this.accountApiService.blockAccountService2(response[0]?.id).pipe(
          map((r) => true),
          catchError((err) => {
            this.snackbar.showMessage(parseError(err));
            return of(false);
          })
        );
      })
    );
  }

  public unblockingAVService(serviceId: number): Observable<boolean> {
    return this.accountApiService.getServiceConnections(serviceId).pipe(
      mergeMap((response) => {
        return this.accountApiService.unblockAccountService2(response[0]?.id).pipe(
          map((r) => true),
          catchError((err) => {
            this.snackbar.showMessage(parseError(err));
            return of(false);
          })
        );
      })
    );
  }
}
