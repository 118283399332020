import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { RdeaStepperStep } from '@app/shared/models';

@Component({
  selector: 'app-rdea-stepper',
  templateUrl: './rdea-stepper.component.html',
  styleUrls: ['./rdea-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RdeaStepperComponent {
  @Input() stepIdx: number;
  @Input() steps: RdeaStepperStep[];

  @Output() private changeStep: EventEmitter<{ stepIdx: number }> = new EventEmitter();

  onSelectStep(stepIdx: number) {
    if (this.steps[stepIdx].disabled) {
      return;
    }

    this.changeStep.emit({ stepIdx });
  }
}
