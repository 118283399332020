import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { DialogWrapperModule } from '@app/shared/ui';

import { FlatFilterPopupComponent } from './flat-filter-popup.component';
import { FlatFilterPopupService } from './flat-filter-popup.service';

@NgModule({
  imports: [CommonModule, DialogWrapperModule, MaterialModule],
  declarations: [FlatFilterPopupComponent],
  exports: [FlatFilterPopupComponent],
  providers: [FlatFilterPopupService]
})
export class FlatFilterPopupModule {}
