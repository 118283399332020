import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

const selectShieldsByBatchNumberState = createSelector(
  intercomModuleSelector,
  (state: IntercomModuleState) => state.getShieldsByBatchNumber
);

export const getShieldsByBatchNumber = createSelector(
  selectShieldsByBatchNumberState,
  state => state
);

export const getShieldsByBatchNumberSuccess = createSelector(
  selectShieldsByBatchNumberState,
  state => state.shields
);

export const getShieldsByBatchNumberFailure = createSelector(
  selectShieldsByBatchNumberState,
  state => state.error
);

export const getShieldsByBatchNumberLoading = createSelector(
  selectShieldsByBatchNumberState,
  state => state.loading
);
