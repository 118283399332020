export enum AbonentPageAction {
    GET_ABONENT_INFO = 'get-abonent-info',
    GET_ABONENT_STAT = 'get-abonent-stat',
    GET_ABONENT_VOIP_TOKENS = 'get-abonent-voip-tokens',

    GET_MESSAGES = 'get-messages',
    SEND_MESSAGE = 'send-message',
    UPDATE_ABONENT = 'update-abonent',

    GET_OWNER_DELEGATIONS = 'get-owner-delegations',
    DELETE_OWNER_DELEGATION = 'delete-owner-delegation',
    GET_SHARED_DELEGATIONS = 'get-shared-delegations',

    GET_SIGN_UPS = 'get-sign-ups',
    UPDATE_SIGN_UP = 'update-sign-up',

    GET_ACCOUNTS = 'get-accounts',
    BLOCK_ACCOUNT = 'block-account',
    UNBLOCK_ACCOUNT = 'unblock-account',
    UPDATE_ACCOUNT = 'update-account',
    DELETE_ACCOUNT = 'delete-account',

    GET_ACCOUNT_SERVICES = 'get-account-services',
    BLOCK_ACCOUNT_SERVICE = 'block-account-service',
    UNBLOCK_ACCOUNT_SERVICE = 'unblock-account-service',
    DISCONNECT_ACCOUNT_SERVICE = 'disconnect-account-service'
}
