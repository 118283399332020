<ng-container [ngSwitch]="currentMode">
  <app-gate-wizard-content-intercoms-default
    *ngSwitchCase="mode.DEFAULT"
    [loading]="loading"
    [filter]="filter"
    [intercom]="selectedIntercom"
    [intercomType]="selectedIntercomType"
    [intercomTypes]="intercomTypes"
    (updateIntercom)="onSubmitIntercom($event.request)"
  ></app-gate-wizard-content-intercoms-default>

  <app-gate-wizard-content-intercoms-selection
    *ngSwitchCase="mode.SELECTION"
    [loading]="loading"
    [intercomTypes]="intercomTypes"
    [intercomType]="selectedIntercomType"
    (intercomSelectionSkipped)="onSkipIntercomSelection()"
    (intercomSelected)="onIntercomSelected($event.intercom)"
    (changeControlsVisible)="onChangeControlsVisible($event.showControls)"
  ></app-gate-wizard-content-intercoms-selection>

  <app-gate-wizard-content-intercoms-with-indicies
    *ngSwitchCase="mode.INDICES"
    [loading]="loading"
    [index]="index"
    (indexSelected)="onSubmitIntercomsWithIndex($event.index)"
  ></app-gate-wizard-content-intercoms-with-indicies>

  <app-gate-wizard-content-intercoms-reuse
    *ngSwitchCase="mode.REUSE"
    [loading]="loading"
    [intercoms]="intercoms"
    (indexSelected)="onSubmitIntercomsWithIndex($event.index)"
  ></app-gate-wizard-content-intercoms-reuse>

  <div class="popup" *ngSwitchDefault>
    <app-info-block>
      <app-component-loader
        info-block-image
        sizePx="50"
        [overTheComponent]="false"
      ></app-component-loader>

      <ng-container info-block-text>
        {{ 'services.gates.page.popups.wizard.content.intercoms.message.check_adapters' | translate }}
      </ng-container>
    </app-info-block>
  </div>
</ng-container>
