import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionsService } from '@app/security/permissions';

@Directive({ selector: '[appRdeaCanAccess]' })
export class CanAccessDirective implements OnInit {
  @Input() appRdeaCanAccess: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private permissions: PermissionsService
  ) { }

  ngOnInit() {
    const isAllowed: boolean = this.permissions.canAccessByKey(this.appRdeaCanAccess);
    isAllowed ? this.viewContainer.createEmbeddedView(this.templateRef) : this.viewContainer.clear();
  }
}
