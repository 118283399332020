export interface DadataAddressSuggestionData {
    city_fias_id: string;
    city_kladr_id: string;
    city_type: string;
    city: string;
    settlement_fias_id: string;
    settlement_kladr_id: string;
    settlement_type: string;
    settlement: string;
    country: string;
    country_iso_code: string;
    geo_lat: string;
    geo_lon: string;
    house_fias_id: string;
    house_kladr_id: string;
    house_type: string;
    house: string;
    block: string;
    block_type: string;
    postal_code: string;
    street_fias_id: string;
    street_kladr_id: string;
    street_type: string;
    street: string;
    fias_id: string;
    fias_level: string;
    kladr_id: string;
    okato: string;
    oktmo: string;
  }

