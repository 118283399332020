<form [formGroup]="form" (submit)="onSubmit()" autocomplete="off">
  <p>
    {{ 'intercom.pages.order.page.card.form.close.message.confirm' | translate }}
  </p>

  <div class="form-inputs">
    <mat-form-field class="mr-5" appearance="outline">
      <mat-label>
        {{ 'intercom.pages.order.page.card.form.close.field.date' | translate }}
      </mat-label>
      <input
        matInput
        [ngxMatDatetimePicker]="picker1"
        placeholder="{{ 'intercom.pages.order.page.card.form.close.placeholder.date' | translate }}"
        formControlName="warrantyStartDate"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        required
      />
      <mat-datepicker-toggle matSuffix [for]="picker1">
        <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
      </mat-datepicker-toggle>
      <ngx-mat-datetime-picker #picker1 [showSpinners]="false" [showSeconds]="false"
        [touchUi]="resolutionService.isMobile">
      </ngx-mat-datetime-picker>
    </mat-form-field>
  </div>

  <div class="action-block">
    <button
      type="submit"
      mat-raised-button
      color="primary"
    >
      {{ 'intercom.pages.order.page.card.form.close.button.submit' | translate }}
    </button>
  </div>
</form>
