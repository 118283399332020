import * as root from '@app/store/reducers';
import { SoftwareIntercomsPageModel } from '../models';
import { PageData } from '@app/shared/models';

export interface SoftwareIntercomsModuleState {
    softwareIntercoms: SoftwareIntercomsPageState;
}

export interface SoftwareIntercomsRootState extends root.State {
    'software-intercoms': SoftwareIntercomsModuleState;
}

export interface SoftwareIntercomsPageState {
    softwareIntercoms: SoftwareIntercomsPageModel[];
    loading: boolean;
    pageData: PageData;
}

export const softwareIntercomsPageInitialState: SoftwareIntercomsPageState = {
    softwareIntercoms: undefined,
    loading: undefined, 
    pageData: undefined
};
