<mat-card class="card-container service-intercoms">
  <mat-card-header class="header">
    <div class="title-block">
      <mat-card-title>
        {{ 'service.intercoms.title' | translate }}
      </mat-card-title>
    </div>

    <button
      [disabled]="(addDisabledCondition ? addDisabledCondition(intercoms) : false) || addDisabled"
      mat-raised-button
      (click)="onAdd()"
      color="primary-inverse"
      matTooltip="{{ 'service.intercoms.tooltip.add' | translate }}"
      [matTooltipPosition]="'before'"
    >
      <mat-icon>add</mat-icon>
    </button>
  </mat-card-header>

  <hr>

  <mat-card-content class="service-intercoms-content">
    <ng-container *ngIf="intercoms?.length > 0; else addAdapter">
      <ng-container *ngFor="let intercom of intercoms; let last = last">
        <app-service-intercom
          [intercom]="intercom"
          [intercoms]="intercoms"
          [intercomPanels]="intercom.intercoms"
          [serviceType]="serviceType"
          [entrances]="entrances"
          [translationsDisabled]="translationsDisabled"
          [componentsVersions]="componentsVersions"
          [pbxOnRda]="pbxOnRda$ | async"
        ></app-service-intercom>

        <hr *ngIf="!last && !(xlWDownBreakpoint$ | async)">
      </ng-container>
    </ng-container>

    <ng-template #addAdapter>
      <app-info-block contentWidth="50">
        <ng-container info-block-text>
          {{ 'service.intercoms.message.add' | translate }}
        </ng-container>
      </app-info-block>
    </ng-template>
  </mat-card-content>
</mat-card>
