import { HttpErrorResponse } from '@angular/common/http';
import { RdaModel } from '@app/shared/entities/rd/rda';
import { Actions, ActionTypes } from '../../actions/rda-models.actions';

export interface State {
  models: RdaModel[];
  loading: boolean;
  error: string | HttpErrorResponse;
}

export const initialState: State = {
  models: null,
  loading: false,
  error: null
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GetRdaModels: {
      return {
        ...state,
        models: null,
        error: null,
        loading: true,
      };
    }

    case ActionTypes.GetRdaModelsSuccess: {
      return {
        ...state,
        models: action.payload,
        error: null,
        loading: false
      };
    }

    case ActionTypes.GetRdaModelsFailure: {
      return {
        ...state,
        models: null,
        error: action.payload,
        loading: false,
      };
    }

    case ActionTypes.GetRdaModelsClear: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
