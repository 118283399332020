import { KzPostAddressType } from './kz-post-address-type';

export interface KzPostAddressPart {
    actual: string;
    id: string;
    nameKaz: string;
    nameRus: string;
    parentId: string;
    type: KzPostAddressType;
}
