import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TextBadgeComponent } from './text-badge.component';

@NgModule({
    imports: [CommonModule],
    declarations: [TextBadgeComponent],
    exports: [TextBadgeComponent]
})
export class TextBadgeModule { }
