<div class="payment-card"
     [class.payment-card--blocked]="status !== paymentsStatusType.CONFIRMED_MANUALLY && blocked"
     [class.payment-card--warning]="status !== paymentsStatusType.CONFIRMED_MANUALLY && !paid && !blocked"
>
  <div
    class="payment-card-block"
    (click)="toggleChildren($event)"
  >

    <div class="payment-card-block-info">
      <div class="payment-card-block-info-abonents">
        <div
          #owner
          class="payment-card-block-info-abonents-owner"
          [class.payment-card-block-info-abonents-owner--hidden]="phoneBlock.childNodes.length == 0"
          (click)="moveToSubscriberCard(owner, $event)"
        >
          <mat-icon
            svgIcon="user"
            class="payment-card-block-info-abonents-owner__phone-user__icon"
          ></mat-icon>
          <p
            #phoneBlock
            class="payment-card-block-info-abonents-owner__phone"
          >
            <ng-content select="[payment-phone]"></ng-content>
          </p>
          <mat-icon
            class="payment-card-block-info-abonents-owner__phone-row__icon"
            svgIcon="right-arrow"
          ></mat-icon>
        </div>
      </div>
      <div class="payment-card-block-actions-tariff_mobile">
        <ng-content select="[payment-tariff-mobile]"></ng-content>
      </div>
    </div>

    <div class="payment-card-block-actions">
      <div class="payment-card-block-actions-tariff">
        <ng-content select="[payment-tariff]"></ng-content>
      </div>

      <div class="payment-card-block-actions-date">
        <ng-content select="[payment-date]"></ng-content>
      </div>

      <div
        class="payment-card-block-actions-dropdown"
        [class.payment-card-block-actions-dropdown-active]="openedState"
      >
        <button
          mat-button
          color="primary-inverse"
          class="mat-mini-fab"
          (click)="toggleChildren($event)"
        >
          <mat-icon svgIcon="chevron-down"></mat-icon>
        </button>
      </div>
    </div>

  </div>

  <div class="payment-card-block-children" *ngIf="openedState" @openClose>
    <ng-content select="[payment-children]"></ng-content>
  </div>
</div>
