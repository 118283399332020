import { Injectable, OnDestroy } from '@angular/core';
import { ActivationEnd, Params, Router, RouterEvent } from '@angular/router';
import { SnapshotData } from '@app/shared/models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'environments/environment';
import {ResourcePath, ResourceType} from '@app/shared/entities';

export interface INavbarLogoConfig {
  path: ResourcePath;
  srcset: boolean;
  type: ResourceType;
}

@Injectable()
export class AppHelper implements OnDestroy {
    private onDestroy$: Subject<void> = new Subject<void>();

    constructor(private router: Router) { }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

   public addRouterActivationEndEventListener(cb: (data: SnapshotData, queryParams: Params) => void): void {
        this.router.events
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((event: RouterEvent) => {
                if (!(event instanceof ActivationEnd)) {
                    return;
                }

                if (!Object.keys(event.snapshot.data).length) {
                    return;
                }

                cb(event.snapshot.data as SnapshotData, event.snapshot.queryParams);
            });
    }

    public initNavbarAppLogo(): INavbarLogoConfig {
      if (environment.vivotec) {
        return {
          path: ResourcePath.LOGO_VIVO_NAVBAR,
          srcset: false,
          type: ResourceType.SVG
        };
      }

      if (environment.kz) {
        return {
          path: ResourcePath.LOGO_KZ_NAVBAR,
          srcset: false,
          type: ResourceType.SVG
        };
      }

      return {
        path: ResourcePath.LOGO_NAVBAR,
        srcset: true,
        type: ResourceType.PNG
      };
    }
}
