import { Account } from './../../account/models/account';
import { ServiceConnectionWithType } from '@app/views/services/models';
import { ServiceResponse } from '../..';
import { FlatCreateResponse } from '../../flat';
import { Abonent } from '@app/shared/models';

export interface ConnectionCreateResponse {
    abonent?: Abonent;
    account?: Account;
    flat?: FlatCreateResponse;
    connections?: ServiceConnectionWithType[];
    dependantServices?: Pick<ServiceResponse, 'id' | 'type'>[];
}
