import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BreadcrumbService } from '@app/core/services';
import { TopBarService } from '@app/core/services/top-bar.service';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { LoaderService } from '@app/shared/entities/common/loader';
import { State } from '@app/store/reducers';
import { ShieldTests } from '@app/views/intercom/models';
import { GetShieldTests } from '@app/views/intercom/store/actions';
import { getShieldTestsLoading, getShieldTestsSuccess } from '@app/views/intercom/store/states';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-shield-tests',
  templateUrl: './shield-tests.component.html',
  styleUrls: ['./shield-tests.component.scss']
})
export class ShieldTestsComponent implements OnInit {
  readonly displayedColumns: string[] = ['error', 'description', 'createdAt'];
  shieldNumber: number;
  shieldId: number;
  shieldTests$: Observable<ShieldTests[]>;

  constructor(
    public resolution: ResolutionService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private store: Store<State>,
    private topBar: TopBarService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.prepareParams();
    this.initLoading();
    this.initStore();
  }

  private prepareParams() {
    this.shieldId = Number.parseInt(this.route.snapshot.params['id'].match(/([\d]+)/)[0], 10);
    this.shieldNumber = this.route.snapshot.queryParams.number;
    if (this.shieldNumber) {
      this.updateTitles();
    }
  }

  private initLoading() {
    this.store.select(getShieldTestsLoading)
      .subscribe((state: boolean) => this.loaderService.loaderState = { state });
  }

  private initStore() {
    this.initStoreSelectors();
    this.getStoreData();
  }

  private initStoreSelectors() {
    this.shieldTests$ = this.store.select(getShieldTestsSuccess);
  }

  private getStoreData() {
    this.store.dispatch(new GetShieldTests(this.shieldId));
  }

  private updateTitles() {
    this.breadcrumbService.title = `Шилд №${this.shieldNumber}`;
    this.topBar.title = `Шилд №${this.shieldNumber}`;
  }
}
