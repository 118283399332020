import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

/**
 * Basic class for all plyr controls (custom or not)
 */
export class PlyrControlBasicHelper<E> {
    protected clickListener$: Subject<{ type: E, e?: MouseEvent | number | string | boolean }> = new Subject();
    protected internalControls: Record<string, PlyrControlBasicHelper<any>> = {};
    private onDestroy$: Subject<void> = new Subject();

    /**
     * Add listener for clicks from control
     * @param handleEvent clicks handler
     */
    async addClickListener(handleEvent: (type: E, e: MouseEvent | number | string | boolean) => void) {
        await this.clickListener$
            .pipe(
                takeUntil(this.onDestroy$),
                map((event: { type: E, e: MouseEvent | number | string | boolean }) => handleEvent(event.type, event.e))
            )
            .toPromise();
    }

    /**
     * Destroy click listener and all internal controls
     */
    destroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();

        Object.values(this.internalControls)
            .forEach((control: PlyrControlBasicHelper<any>) => control.destroy());
    }
}
