import { Component, Inject } from '@angular/core';
import { AddScansToOrderRequest } from '@app/views/intercom/models';
import { PopupBasicComponent } from '@app/shared/templates';
import { UploaderOptions, UploadFile, UploadOutput } from 'ngx-uploader';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-order-scans-form',
  templateUrl: './order-scans-form.component.html',
  styleUrls: ['./order-scans-form.component.scss']
})
export class OrderScansFormComponent extends PopupBasicComponent {
  options: UploaderOptions;
  dragOver: boolean;
  private files: UploadFile[];

  constructor(@Inject('subject') private subject: Subject<any>) {
    super();
    this.initUploader();
  }

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'addedToQueue':
        this.addedToQueue(output.file);
        break;
      case 'uploading':
        this.uploading(output.file);
        break;
      case 'removed':
        this.removed(output.file);
        break;
      case 'dragOver':
        this.dragOver = true;
        break;
      case 'dragOut':
      case 'drop':
        this.dragOver = false;
        break;
      case 'done':
        break;
    }
  }

  private addedToQueue(file: UploadFile) {
    if (typeof file !== 'undefined') {
      this.files.push(file);
    }
  }

  private uploading(uploadFile: UploadFile) {
    if (typeof uploadFile !== 'undefined') {
      const index = this.files.findIndex(file => typeof file !== 'undefined' && uploadFile.id === file.id);
      this.files[index] = uploadFile;
    }
  }

  private removed(uploadFile: UploadFile) {
    this.files = this.files.filter((file: UploadFile) => file !== uploadFile);
  }

  onUpload() {
    const addScansToOrderRequest: AddScansToOrderRequest = { orderScans: this.files.map(file => file.nativeFile) };
    this.subject.next(addScansToOrderRequest);
  }

  private initUploader() {
    this.options = { concurrency: 1, maxUploads: 3, maxFileSize: 1000000 };
    this.files = [];
  }
}
