import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

const selectCreateShieldModelState = createSelector(
  intercomModuleSelector,
  (state: IntercomModuleState) => state.createShieldModel
);
export const getCreateShieldModel = createSelector(
  selectCreateShieldModelState,
  state => state
);
export const getCreateShieldModelSuccess = createSelector(
  selectCreateShieldModelState,
  state => state.success
);
export const getCreateShieldModelLoading = createSelector(
  selectCreateShieldModelState,
  state => state.loading
);
export const getCreateShieldModelFailure = createSelector(
  selectCreateShieldModelState,
  state => state.error
);
