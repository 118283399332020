import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/shared/components/snackbar';
import {RdaApiService} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {TranslationTuningResponse} from '@app/shared/models';
import {TranslationTuningService} from '@app/views/intercom/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, concatMap, map} from 'rxjs/operators';
import {
  AddTranslationTuning,
  AddTranslationTuningFailure,
  AddTranslationTuningSuccess,
  DeleteTranslationTuning,
  DeleteTranslationTuningFailure,
  DeleteTranslationTuningSuccess,
  GetTranslationTunings,
  GetTranslationTuningsFailure,
  GetTranslationTuningsSuccess,
  ServicesActionTypes
} from '../actions';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServicesTranslationTuningsEffects {
  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private rdaApiService: RdaApiService,
    private translationTuningService: TranslationTuningService,
    private translate: TranslateService
  ) {
  }

  GetTranslationTunings$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTranslationTunings>(ServicesActionTypes.GetTranslationTunings),
      concatMap((action: GetTranslationTunings) => {
        return this.rdaApiService.getTranslationTunings(action.rdaUid)
          .pipe(
            map((response: TranslationTuningResponse[]) =>
              new GetTranslationTuningsSuccess(action.rdaUid, response)
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.translation.tunings.message.get_translation_tunings.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetTranslationTuningsFailure());
            })
          );
      })
    )
  );

  AddTranslationTuning$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddTranslationTuning>(ServicesActionTypes.AddTranslationTuning),
      concatMap((action: AddTranslationTuning) =>
        this.rdaApiService.createTranslationTuning(action.rdaUid, action.request)
          .pipe(
            map((response: TranslationTuningResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.translation.tunings.message.add_translation_tuning.success'),
                'success'
              );
              return new AddTranslationTuningSuccess(action.rdaUid, response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.translation.tunings.message.add_translation_tuning.failed', {
                  text: parseError(error)
                })
              );
              return of(new AddTranslationTuningFailure());
            })
          )
      )
    )
  );

  DeleteTranslationTuning$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteTranslationTuning>(ServicesActionTypes.DeleteTranslationTuning),
      concatMap((action: DeleteTranslationTuning) =>
        this.translationTuningService.deleteTranslationTuning(action.translationTuningId)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.translation.tunings.message.delete_translation_tuning.success'),
                'success'
              );
              return new DeleteTranslationTuningSuccess(action.rdaUid, action.translationTuningId);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.translation.tunings.message.delete_translation_tuning.failed', {
                  text: parseError(error)
                })
              );
              return of(new DeleteTranslationTuningFailure());
            })
          )
      )
    )
  );
}
