import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Camera } from '@app/shared/entities/rd/camera/models';
import { DialogWrapperData } from '@app/shared/ui';

@Component({
  selector: 'app-camera-info-popup',
  templateUrl: './camera-info-popup.component.html',
  styleUrls: ['./camera-info-popup.component.scss']
})
export class CameraInfoPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<Camera, null>
  ) { }
}
