import { Injectable, OnDestroy } from '@angular/core';
import { DialogWrapperStateHelper } from '@app/shared/ui';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { ServicesActionTypes } from '../../../../store';

@Injectable()
export class ServiceIntercomPopupService extends DialogWrapperStateHelper<'loading' | 'done' | 'loaded' | 'close'> implements OnDestroy {
    constructor(
        private actions$: Actions
    ) {
        super();
        this.enableStateListeners();
    }

    ngOnDestroy() {
        this.destroy();
    }

    private enableStateListeners() {
        this.actions$
            .pipe(
                ofType(...[
                    ServicesActionTypes.AddTranslationTuning,
                    ServicesActionTypes.DeleteTranslationTuning,
                    ServicesActionTypes.AddServiceRda,
                    ServicesActionTypes.ChangeServiceRda,
                    ServicesActionTypes.UpdateServiceRda,
                    ServicesActionTypes.ConnectServicePbxOnRda,
                    ServicesActionTypes.ChangeServicePbxOnRda,
                    ServicesActionTypes.DisconnectServicePbxOnRda,
                    ServicesActionTypes.AddServiceIntercomPanel,
                    ServicesActionTypes.UpdateServiceIntercomPanel,
                    ServicesActionTypes.DeleteServiceIntercomPanel
                ]), takeUntil(this.onDestroy$),
            )
            .subscribe(() => this.state = 'loading');

        this.actions$
            .pipe(
                ofType(...[
                    ServicesActionTypes.AddServiceRdaSuccess,
                    ServicesActionTypes.UpdateServiceRdaSuccess,
                    ServicesActionTypes.ChangeServiceRdaSuccess,
                    ServicesActionTypes.UpdateServiceIntercomPanelSuccess,
                ]), takeUntil(this.onDestroy$),
            )
            .subscribe(() => this.state = 'done');

        this.actions$
            .pipe(
                ofType(...[
                    ServicesActionTypes.AddServiceIntercomPanelSuccess,
                    ServicesActionTypes.DeleteServiceIntercomPanelSuccess
                ]), takeUntil(this.onDestroy$),
            )
            .subscribe(() => this.state = 'close');

        this.actions$
            .pipe(
                ofType(...[
                    ServicesActionTypes.AddTranslationTuningFailure,
                    ServicesActionTypes.AddTranslationTuningSuccess,
                    ServicesActionTypes.DeleteTranslationTuningSuccess,
                    ServicesActionTypes.DeleteTranslationTuningFailure,
                    ServicesActionTypes.AddServiceRdaFailure,
                    ServicesActionTypes.ChangeServiceRdaFailure,
                    ServicesActionTypes.UpdateServiceRdaFailure,
                    ServicesActionTypes.ConnectServicePbxOnRdaSuccess,
                    ServicesActionTypes.ConnectServicePbxOnRdaFailure,
                    ServicesActionTypes.ChangeServicePbxOnRdaSuccess,
                    ServicesActionTypes.ChangeServicePbxOnRdaFailure,
                    ServicesActionTypes.DisconnectServicePbxOnRdaSuccess,
                    ServicesActionTypes.DisconnectServicePbxOnRdaFailure,
                    ServicesActionTypes.AddServiceIntercomPanelFailure,
                    ServicesActionTypes.DeleteServiceIntercomPanelFailure,
                    ServicesActionTypes.UpdateServiceIntercomPanelFailure
                ]), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loaded');
    }
}
