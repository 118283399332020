import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ServicesTypes} from '@app/shared/models';
import {ServiceTariffPopupComponent} from '@app/views/services/components/popups/connections/service-tariff-popup';

@Component({
  selector: 'app-software-intercom-tariff-popup',
  templateUrl: './software-intercom-tariff-popup.component.html',
  styleUrls: ['./software-intercom-tariff-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomTariffPopupComponent extends ServiceTariffPopupComponent {
  public readonly ServicesTypes = ServicesTypes;
}
