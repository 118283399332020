import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { DeleteIntercomContentModule, IntercomEditContentModule, RdaTranslationsEditorModule } from '@app/shared/components';
import { DialogWrapperModule, NavbarModule } from '@app/shared/ui';
import { ServiceDeleteIntercomPopupComponent } from './service-delete-intercom-popup.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        IntercomEditContentModule,
        RdaTranslationsEditorModule,
        DeleteIntercomContentModule,
        DialogWrapperModule,
        NavbarModule,
    ],
    declarations: [
        ServiceDeleteIntercomPopupComponent
    ],
    exports: [
        ServiceDeleteIntercomPopupComponent
    ]
})
export class ServiceDeleteIntercomPopupModule { }
