<div class="popup">
  <h3>{{ 'shared.rda.intercom.edit.content.title' | translate }}</h3>

  <mat-form-field
    appearance="outline"
    class="relative-size intercom-type-selector"
  >
    <mat-label>{{ 'shared.rda.intercom.edit.content.field.type' | translate }}</mat-label>

    <mat-select
      required
      disableOptionCentering
      [(ngModel)]="selectedIntercomTypeId"
      panelClass="mat-select-custom"
      [disabled]="loading"
      (selectionChange)="onSelectIntercomType()"
    >
      <mat-option class="intercom-type-selector__option">--</mat-option>
      <mat-option
        [value]="item.id"
        *ngFor="let item of intercomTypes"
        [disabled]="intercomTypeWithError(item)"
        class="intercom-type-selector__option"
        [class.mat-option-warning]="intercomTypeWithError(item)"
      >
        {{ item.name + (intercomTypeWithError(item) ? ' (' + intercomTypeErrorText(item) + ')' : '') }}
      </mat-option>
    </mat-select>

    <mat-hint>
      {{ 'shared.rda.intercom.edit.content.hint.type' | translate }}
      <a [href]="UNSUPPORTED_INTERCOM_TYPE_LINK | safeUrl" target="_blank">
        {{ 'shared.rda.intercom.edit.content.link.look_at_here' | translate }}
      </a>
    </mat-hint>
  </mat-form-field>
  <app-info-block *ngIf="isGate">
    <img
      class="component-selection-info__image"
      info-block-image
      [src]="resourcesHelper.getResource(resourcePath.INFO_LG) | safeUrl"
      [srcset]="resourcesHelper.getResource(resourcePath.INFO_LG, true) | safeUrl"
    >

    <ng-container info-block-text>
      {{ 'shared.rda.intercom.edit.content.message.info.you_plan_install_adapter_1' | translate }}
      <br>
      {{ 'shared.rda.intercom.edit.content.message.info.you_plan_install_adapter_2' | translate }}
    </ng-container>

    <ng-container info-block-content>
      <div class="component-selection-info-content">
        <h4 class="component-selection-info-content__title">
          {{ 'shared.rda.intercom.edit.content.message.selection.title' | translate }}
        </h4>
        <p>
          {{ 'shared.rda.intercom.edit.content.message.selection.text_1' | translate }}
        </p>
        <p>
          {{ 'shared.rda.intercom.edit.content.message.selection.text_2' | translate }}
        </p>
      </div>
    </ng-container>
  </app-info-block>

  <div *ngIf="incorrectIntercomType" class="changed-intercom-alert">
    <p class="changed-intercom-alert__text">
      {{ 'shared.rda.intercom.edit.content.error.incorrect_intercom_type' | translate }}
    </p>
  </div>

  <app-rda-search-select
    #rdaSelectSearch
    [initialIntercoms]="rda ? [rda] : []"
    [disabled]="loading"
    [initialIntercomSearchSelect]="initialSelectedRda"
    [withoutDescription]="true"
    [params]="{ ipType: ipIntercom || filter === intercomEditFilter.IP_TYPE || null, inactive: isSoftwareIntercoms }"
    (rdaSelected)="onSelectRda($event.value, $event.select)"
  ></app-rda-search-select>

  <div
    class="adapter-status"
    *ngIf="rda?.uid && intercomType"
  >
    <div
      class="adapter-status__indicator"
      [class.adapter-status__indicator--online-background]="rda.active"
      [class.adapter-status__indicator--offline-background]="!rda.active"
    >
    </div>
    <p
      class="adapter-status__text"
      [class.adapter-status__text--online]="rda.active"
      [class.adapter-status__text--offline]="!rda.active"
    >
      {{ 'shared.rda.intercom.edit.content.field.adapter' | translate }}
      {{ rda.active ? translate.instant('shared.rda.intercom.edit.content.enum.status.online') : translate.instant('shared.rda.intercom.edit.content.enum.status.offline') }}
    </p>
  </div>

  <form
    autocomplete="off"
    [formGroup]="rdaForm"
    *ngIf="!ipIntercom; else ipIntercomForm"
    (ngSubmit)="onSubmitRda()"
  >
    <ng-container *ngIf="rda?.uid && intercomType">
      <div class="volume-block">
        <h3>{{ 'shared.rda.intercom.edit.content.field.volume' | translate }}</h3>

        <div class="volume-block-body">
          <span class="volume-block-body__barrier-value">-60</span>
          <mat-slider
            min="-60"
            max="60"
            step="1"
            thumbLabel=true
            class="volume-block-body__slider"
            formControlName="speakerVolume"
          ></mat-slider>
          <span class="volume-block-body__barrier-value">60</span>
        </div>

        <mat-hint class="volume-block__hint">
          {{ 'shared.rda.intercom.edit.content.hint.volume' | translate }}
        </mat-hint>
      </div>

      <div class="volume-block">
        <h3>{{ 'shared.rda.intercom.edit.content.field.gain' | translate }}</h3>

        <div class="volume-block-body">
          <span class="volume-block-body__barrier-value">-60</span>
          <mat-slider
            min="-60"
            max="60"
            step="1"
            thumbLabel=true
            class="volume-block-body__slider"
            formControlName="micSensitivity"
          ></mat-slider>
          <span class="volume-block-body__barrier-value">60</span>
        </div>

        <mat-hint class="volume-block__hint">
          {{ 'shared.rda.intercom.edit.content.hint.gain' | translate }}
        </mat-hint>
      </div>
    </ng-container>


    <div class="popup-actions popup-actions--rda-form">
      <button
        type="submit"
        color="primary"
        mat-raised-button
        class="popup-actions__button"
        [disabled]="!intercomType || !selectedRda || loading"
      >
        {{ 'shared.rda.intercom.edit.content.button.submit' | translate }}
      </button>
    </div>
  </form>

  <ng-template #ipIntercomForm>
    <form
      autocomplete="off"
      [formGroup]="ipRdaForm"
      (ngSubmit)="onSubmitIpRda()"
    >
      <div class="generate-block popup-actions">
        <p class="generate-block__text" [innerHTML]="'shared.rda.intercom.edit.content.hint.generate_uid' | translate"></p>
        <button
          type="button"
          mat-raised-button
          [disabled]="loading"
          class="generate-block__button popup-actions__button"
          color="primary-inverse"
          (click)="onOpenIpRdaGeneratior()"
        >
          {{ 'shared.rda.intercom.edit.content.button.generate_uid' | translate }}
        </button>
      </div>

      <div *ngIf="rda?.uid">
        <mat-form-field appearance="outline" class="relative-size" *ngIf="isSputnikIntercomType && ipRdaForm.contains('bearerToken')">
          <mat-label>{{ 'shared.rda.intercom.edit.content.field.token' | translate }}</mat-label>
          <input
            type="url"
            matInput
            required
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            formControlName="bearerToken"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="relative-size" *ngIf="isHickIntercomType || isRubitekIntercomType || isSipCdaIntercomType">
          <mat-label>{{ 'shared.rda.intercom.edit.content.field.login' | translate }}</mat-label>
          <input
            type="url"
            matInput
            required
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            formControlName="login"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="relative-size" *ngIf="isHickIntercomType || isRubitekIntercomType || isSipCdaIntercomType">
          <mat-label>{{ 'shared.rda.intercom.edit.content.field.password' | translate }}</mat-label>
          <input
            type="url"
            matInput
            required
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
            formControlName="password"
          />
        </mat-form-field>

        <mat-form-field appearance="outline" class="relative-size">
          <mat-label>{{ 'shared.rda.intercom.edit.content.field.url' | translate }}</mat-label>
          <input
            type="url"
            matInput
            formControlName="livenessUrl"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          />
        </mat-form-field>

        <div class="generate-block popup-actions">
          <p class="generate-block__text" [innerHTML]="'shared.rda.intercom.edit.content.hint.generate_link' | translate"></p>
          <button
            type="button"
            mat-raised-button
            [disabled]="loading"
            color="primary-inverse"
            class="generate-block__button popup-actions__button"
            (click)="onOpenLivenessUrlGenerator()"
          >
            {{ 'shared.rda.intercom.edit.content.button.generate_link' | translate }}
          </button>
        </div>
      </div>

      <div *ngIf="isBewardIntercomType && rda?.uid && !isSpecialIntercomType" class="lock-block popup-actions">
        <h3>{{ 'shared.rda.intercom.edit.content.field.block' | translate }}</h3>
        <button
          type="button"
          mat-raised-button
          [disabled]="loading"
          class="lock-block__button popup-actions__button"
          color="primary-inverse"
          (click)="onOpenHandsetLock()"
        >
          {{ 'shared.rda.intercom.edit.content.button.block' | translate }}
        </button>
      </div>

      <div class="popup-actions">
        <button
          type="submit"
          color="primary"
          mat-raised-button
          class="popup-actions__button"
          [disabled]="!intercomType || !selectedRda || loading || isSputnikIntercomType ? ipRdaForm.invalid : false"
        >
          {{ 'shared.rda.intercom.edit.content.button.submit' | translate }}
        </button>
      </div>
    </form>
  </ng-template>
</div>
