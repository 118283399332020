<div class="popup change-virtual-state-content">
  <app-info-block>
    <ng-container info-block-text>
      {{ 'shared.connections.change.virtual.state.popup.info' | translate }}
    </ng-container>
  </app-info-block>

  <div class="popup-actions">
    <button
      mat-raised-button
      color="primary"
      class="popup-actions__button"
      [disabled]="loading"
      (click)="onSubmit()"
    >
      {{ 'shared.connections.change.virtual.state.popup.button.submit' | translate }}
    </button>
  </div>
</div>
