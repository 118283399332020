<div class="table-page-container">
  <mat-card>
    <mat-card-content>
      <div class="input-field">
          <mat-form-field [class.mr-5]="!resolution.isMobile" appearance="outline" class="none-padding">
            <input
              matInput
              placeholder="{{ 'intercom.new.adapters.page.placeholder.search' | translate }}"
              [formControl]="filterInput"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
            >
            <mat-icon matSuffix>search</mat-icon>
          </mat-form-field>
        <button mat-raised-button color="primary-inverse" (click)="onGroupDataModification()">
          <mat-icon>add</mat-icon>
          <ng-container>
            {{ 'intercom.new.adapters.page.button.group_data_modification' | translate }}
          </ng-container>
        </button>
      </div>

      <hr>

      <mat-table [dataSource]="(adapters$ | async)?.dataSource"
        *ngIf="(adapters$ | async)?.totalCount > 0; else emptyData">

        <ng-container matColumnDef="createdAt">
          <mat-header-cell data-label="createdAt" *matHeaderCellDef>
            {{ 'intercom.new.adapters.page.field.created_at' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="createdAt"
            label-name="{{ 'intercom.new.adapters.page.field.created_at' | translate }}"
            *matCellDef="let element"
          >
            {{ element.createdAt | date: 'dd.MM.yyyy HH:mm:ss' }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="validation">
          <mat-header-cell data-label="validation" *matHeaderCellDef></mat-header-cell>
          <mat-cell
            data-label="validation"
            label-name="{{ 'intercom.new.adapters.page.field.validation' | translate }}"
            *matCellDef="let element"
          >
            <ng-container *ngIf="showErrorStatus(element)">
              <p class="validation-warn" *ngIf="resolution.isTablet; else validationIcon">
                {{ 'intercom.new.adapters.page.message.error' | translate }}
              </p>
              <ng-template #validationIcon>
                <mat-icon class="validation-warn">error</mat-icon>
              </ng-template>
            </ng-container>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="active">
          <mat-header-cell data-label="active" *matHeaderCellDef>
            {{ 'intercom.new.adapters.page.field.status' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="active"
            label-name="{{ 'intercom.new.adapters.page.field.status' | translate }}"
            *matCellDef="let element"
          >
            <mat-chip-list [class.mat-chip-list--place-content]="resolution.isTablet">
              <mat-chip
                [disabled]="true"
                [selectable]="false"
                color="{{ element.active ? 'success' : 'error' }}"
              >
                {{ element.active ? ('enum.state.online' | translate) : ('enum.state.offline' | translate) }}
              </mat-chip>
            </mat-chip-list>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="rdaUid">
          <mat-header-cell *matHeaderCellDef data-label="rdaUid">
            {{ 'intercom.new.adapters.page.field.rda_uid' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="rdaUid"
            label-name="{{ 'intercom.new.adapters.page.field.rda_uid' | translate }}"
            *matCellDef="let element"
          >
            {{element.uid}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="shieldUid">
          <mat-header-cell data-label="shieldUid" *matHeaderCellDef>
            {{ 'intercom.new.adapters.page.field.shield_uid' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="shieldUid"
            label-name="{{ 'intercom.new.adapters.page.field.shield_uid' | translate }}"
            *matCellDef="let element"
          >
            {{element.shieldUid}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="shieldNumber">
          <mat-header-cell data-label="shieldNumber" *matHeaderCellDef>
            {{ 'intercom.new.adapters.page.field.shield_number' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="shieldNumber"
            label-name="{{ 'intercom.new.adapters.page.field.shield_number' | translate }}"
            *matCellDef="let element"
          >
            {{element.shieldNumber}}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="batchNumber">
          <mat-header-cell data-label="batchNumber" *matHeaderCellDef>
            {{ 'intercom.new.adapters.page.field.batch_number' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="batchNumber"
            label-name="{{ 'intercom.new.adapters.page.field.batch_number' | translate }}"
            *matCellDef="let element"
          >
            <app-batches-form-group [batches]="batches" [batchId]="element.batch.id"
              (handleBatchSelected)="onSelectBatch($event, element)">
            </app-batches-form-group>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="companyName">
          <mat-header-cell data-label="companyName" *matHeaderCellDef>
            {{ 'intercom.new.adapters.page.field.company_name' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="companyName"
            label-name="{{ 'intercom.new.adapters.page.field.company_name' | translate }}"
            *matCellDef="let element"
          >
            <app-companies-form-group (handleCompanySelected)="onSelectCompany($event, element)" [rda]="element"
              [companyList]="companies$ | async">
            </app-companies-form-group>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell data-label="actions" *matHeaderCellDef></mat-header-cell>
          <mat-cell data-label="actions" *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Actions">
              <mat-icon svgIcon="submenu"></mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <div class="triggered-menu-content">
                <button
                  class="triggered-menu-content__button"
                  mat-button color="primary"
                  (click)="onPrintRdaUid(element)"
                >
                  {{ 'intercom.new.adapters.page.button.print_rda_uid' | translate }}
                </button>

                <button
                  class="triggered-menu-content__button"
                  mat-button color="primary"
                  (click)="onInfo(element)"
                >
                  {{ 'intercom.new.adapters.page.button.info' | translate }}
                </button>
              </div>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row [style.display]="resolution.isTablet ? 'none' : 'flex'" *matHeaderRowDef="displayedColumns">
        </mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <ng-template #emptyData>
        <app-empty-data></app-empty-data>
      </ng-template>

      <mat-paginator [length]="(adapters$ | async)?.totalCount" [pageSize]="pageSize" [hidePageSize]="true"
        [showFirstLastButtons]="true">
      </mat-paginator>

      <button mat-mini-fab color="primary" class="timer-btn"
        (click)="isAutoupdate ? stopAutoupdate() : startAutoupdate()">
        <ng-container *ngIf="isAutoupdate; else updateIcon">{{(10 - curSeconds % 10)}}</ng-container>
        <ng-template #updateIcon>
          <mat-icon>refresh</mat-icon>
        </ng-template>
      </button>
    </mat-card-content>
  </mat-card>
</div>
