import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/shared/components/snackbar';
import {ServiceConnection} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {AbonentsFromEntrancesHelper} from '../abonents-from-entrances-content.helper';
import {
  AbonentsFromEntrancesActionTypes,
  AbonentsFromEntrancesGetConnections,
  AbonentsFromEntrancesGetConnectionsFailure,
  AbonentsFromEntrancesGetConnectionsSuccess
} from './abonents-from-entrances.actions';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AbonentsFromEntrancesEffects {
  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private abonentsFromEntrancesConnectorHelper: AbonentsFromEntrancesHelper,
    private translate: TranslateService
  ) {
  }

  @Effect()
  AbonentsFromEntrancesGetConnections$ = this.actions$.pipe(
    ofType<AbonentsFromEntrancesGetConnections>(AbonentsFromEntrancesActionTypes.AbonentsFromEntrancesGetConnections),
    switchMap((action: AbonentsFromEntrancesGetConnections) =>
      from(this.abonentsFromEntrancesConnectorHelper.getEntrancesAccounts(action.entrances, action.ignoredServices))
        .pipe(
          map((connections: ServiceConnection[]) =>
            new AbonentsFromEntrancesGetConnectionsSuccess(connections)
          ),
          catchError((error: HttpErrorResponse) => {
            this.snackbar.showMessage(
              this.translate.instant('shared.connections.abonents.from.entrances.error.get_connections.failed', {
                text: parseError(error)
              })
            );
            return of(new AbonentsFromEntrancesGetConnectionsFailure());
          })
        )
    ),
  );
}
