import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { TokenService } from '@app/security';
import { LocalStorageCompanyKey, LocalStorageGeneralKey, LocalStorageHelper } from '@app/shared/entities/common';
import { AmplitudeComponentTrackType, AmplitudeEvents, AmplitudeService, AmplitudeUpdateClientRequest } from '@app/shared/entities/integrations';
import { SnapshotData } from '@app/shared/models';

@Injectable()
export class AmplitudeHelper {
    trackedComponentName: string;

    constructor(
        private tokenService: TokenService,
        private amplitude: AmplitudeService,
    ) { }

    initAmplitude() {
        this.amplitude.initClient((success: boolean) =>
            success && this.updateAmplitudeClient()
        );
    }

    updateAmplitudeClient() {
        const userName = LocalStorageHelper.getItem(LocalStorageGeneralKey.USER_NAME);
        const companyName = LocalStorageHelper.getItem(LocalStorageCompanyKey.NAME);
        const companyId = this.tokenService.getCompanyId();
        const updateClientRequest: AmplitudeUpdateClientRequest = { userName: userName };

        if (companyName) {
            updateClientRequest.companyName = companyName;
        }
        if (companyId) {
            updateClientRequest.companyId = companyId;
        }

        this.amplitude.updateClient(updateClientRequest);
    }

    clearAmplitudeClient() {
        this.amplitude.updateClient({ userName: null });
    }

    createAmplitudeEvent(data: SnapshotData, queryParams: Params) {
        if (!this.trackedComponentName) {
            this.createAmplitudeLoadEvent(data, queryParams);
            return;

        }

        if (this.createAmplitudeCloseEvent()) {
            this.createAmplitudeLoadEvent(data, queryParams);
        }
    }

    private createAmplitudeLoadEvent(data: SnapshotData, queryParams: Params): number {
        const request: any = { componentName: data.page };

        if (queryParams && Object.keys(queryParams).length > 0) {
            request.params = queryParams;
        }

        this.trackedComponentName = request.componentName;

        return this.amplitude.trackComponent(
            this.trackedComponentName,
            AmplitudeComponentTrackType.PAGE,
            request.params?.['redirectURL'] ? AmplitudeEvents.REDIRECT : AmplitudeEvents.OPEN,
            null
        );
    }

    private createAmplitudeCloseEvent(): number {
        return this.amplitude.trackComponent(
            this.trackedComponentName,
            AmplitudeComponentTrackType.PAGE,
            AmplitudeEvents.CLOSE,
            null
        );
    }
}
