import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { PaymentStatusType } from '@app/shared/entities/rd/payments/enums/payment-status-type.enum';
import { animate, style, transition, trigger } from '@angular/animations';

export const openClose = trigger('openClose', [
  transition(':enter', [
    style({ height: 0, opacity: 0, overflow: 'hidden' }),
    animate('.3s ease-in-out', style({ height: '*', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ height: '*', opacity: 1, overflow: 'hidden' }),
    animate('.3s ease-in-out', style({ height: 0, opacity: 0 }))
  ]),
]);

const SUBSCRIBER_CLASS = 'payment-card-block-info-abonents-owner__phone';

@Component({
  selector: 'app-payment-card',
  templateUrl: './payments-card.component.html',
  styleUrls: ['./payments-card.component.scss'],
  animations: [openClose],
})
export class PaymentsCardComponent {
  @Input() status!: string;
  @Input() blocked!: boolean;
  @Input() paid!: boolean;
  @Input() openedState = false;
  public readonly paymentsStatusType = PaymentStatusType;

  xlWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.XL_W_DOWN);
  mdWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.MD_W_DOWN);

  @Output() subscriberEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private resolution: ResolutionService
  ) { }

  public toggleChildren(event): void {
    event.stopPropagation();
    
    if (event.target.classList.contains(SUBSCRIBER_CLASS)) { 
      return; 
    }
    
    this.openedState = !this.openedState;
  }

  public moveToSubscriberCard(owner: HTMLDivElement, event): void {
    event.stopPropagation();

    const phone = owner.querySelector(`.${SUBSCRIBER_CLASS}`).textContent;
    this.subscriberEvent.emit(phone);
  }
}
