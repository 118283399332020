import { Account, FlatCreateResponse } from '@app/shared/entities/rd';
import { Abonent } from '@app/shared/models';
import { ServiceEntrancesFlats } from '../components';
import { ServiceConnectionWithType } from './service-connection-with-type.type';

export interface ServiceCreateConnectionsHandlerData {
    flats: ServiceEntrancesFlats;
    filteredFlats: ServiceEntrancesFlats;
    connections: ServiceConnectionWithType[];
    flat: FlatCreateResponse;
    abonent: Abonent;
    account: Account;
    entranceId: number;
    flatNumber: number;
    virtual: boolean;
}
