import { ReducerName } from '@app/store';
import { AuthActions, AuthActionTypes } from '@app/views/auth/store/auth.actions';
import { authInitialState, AuthState } from '@app/views/auth/store/auth.state';
import { ActionReducerMap } from '@ngrx/store';

function reducer(state = authInitialState, action: AuthActions): AuthState {
  switch (action.type) {
    case AuthActionTypes.Login: {
      return {
        ...state,
        loading: true,
      };
    }

    case AuthActionTypes.LoginFinished: {
      return {
        ...state,
        authenticated: true,
        loading: false
      };
    }

    case AuthActionTypes.LoginAuthenticated: {
      return {
        ...state,
        authenticated: action.authenticated
      };
    }

    case AuthActionTypes.LoginFailure: {
      return {
        ...state,
        loading: false,
      };
    }

    case AuthActionTypes.Logout: {
      return {
        ...state,
        authenticated: false,
        loading: false
      };
    }

    default: {
      return state;
    }
  }
}

export const authReducers: ActionReducerMap<{ [ReducerName.AUTH]: AuthState }> = {
  [ReducerName.AUTH]: reducer
};
