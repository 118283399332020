<div class="services">
  <h3 class="services__title">
    <ng-content select="[abonent-connection-tool-services-title]"></ng-content>
  </h3>
  <div
    class="services-list"
    *ngIf="avaliableServices?.length > 0">
    <p
      class="services-address"
      *ngFor="let address of addresses">
      {{ address | address }}
    </p>
    <ng-container *ngFor="let service of avaliableServices">
      <ng-container
        *ngIf="service.id !== defaultService?.id && !hideService(service)">
        <div class="checkbox-wrapper">
          <mat-checkbox
            class="rd-checkbox"
            [checked]="hasCurrentService(service.id)"
            [value]="
              connectedServices[service.id]
                ? connectedServices[service.id]
                : false
            "
            [indeterminate]="!!flatList && hasAnyService(service.type)"
            (change)="onSelectService($event.checked, service)">
            <div class="services-list-text-block">
              <p class="services-list-text-block__title">
                {{ service.type | serviceTypeName }}
              </p>
            </div>
          </mat-checkbox>
          <p class="services-list-text-block__subtext">
            {{ service.customName || service.name }}
          </p>
          <div
            *ngIf="showTube(service)"
            class="services-list-toggle">
            <mat-slide-toggle
              matTooltip="{{ 'shared.abonents.connection.tool.services.tooltip.intercom_tube' | translate }}"
              [matTooltipDisabled]="flatList.length === 1"
              [checked]="
                (!!flatList && hasAllFlatsTube() && !hasNoFlatsTube()) ||
                tubeState
              "
              (change)="onChangePhysicalTube($event)"
              [disabled]="flatList.length > 1">
              {{ 'shared.abonents.connection.tool.services.field.intercom_tube' | translate }}
            </mat-slide-toggle>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="allAvailableServices === 0">
    {{ 'shared.abonents.connection.tool.services.message.not_data' | translate }}
  </div>
</div>
