<app-navbar
  [links]="navbarLinks"
  [active]="activeLink"
  [neutral]="false"
  (select)="onChangeTab($event.idx)"
></app-navbar>

<app-render
  *ngIf="!updateActiveComponent"
  [component]="activeDynamicComponent.content.component"
  [updater]="activeDynamicComponent.content.updater">
</app-render>
