import { ActionReducerMap } from '@ngrx/store';
import { SoftwareIntercomsActions, SoftwareIntercomsPageActionTypes } from './software-intercoms-page.actions';
import { softwareIntercomsPageInitialState, SoftwareIntercomsPageState } from './software-intercoms-page.state';

function reducer(
    state = softwareIntercomsPageInitialState,
    action: SoftwareIntercomsActions
): SoftwareIntercomsPageState {
    switch (action.type) {
        case SoftwareIntercomsPageActionTypes.GetSoftwareIntercomsPage: {
            return {
                ...state,
                softwareIntercoms: null,
                loading: true
            };
        }

        case SoftwareIntercomsPageActionTypes.GetSoftwareIntercomsPageSuccess: {
            return {
                ...state,
                softwareIntercoms: action.softwareIntercoms,
                pageData: action.pageData,
                loading: false
            };
        }

        case SoftwareIntercomsPageActionTypes.GetSoftwareIntercomsPageFailure: {
            return {
                ...state,
                softwareIntercoms: null,
                loading: false
            };
        }

        default: {
            return state;
        }
    }
}

export const softwareIntercomsReducers: ActionReducerMap<{ softwareIntercoms: SoftwareIntercomsPageState }> = {
    softwareIntercoms: reducer
};
