<div class="schedule">
  <div class="schedule__text">
    <div class="schedule__text-header">
      <h3>
        {{ 'shared.payments.schedule.payment.title' | translate }}
      </h3>
    </div>
    <div class="schedule__text-time">
      <div>
        <h4>{{invoicingDate | paymentInstruction}}</h4>
        <p>
          {{ 'shared.payments.schedule.payment.payments_are_set' | translate }} {{
            isPrePayment
              ? ('shared.payments.schedule.payment.next_month' | translate)
              : ('shared.payments.schedule.payment.for_current_month' | translate)
          }}
        </p>
      </div>
      <div>
        <h4>{{reminingDate | paymentInstruction}}</h4>
        <p>
          {{ 'shared.payments.schedule.payment.users_will_be_notified' | translate }}
        </p>
      </div>
      <div>
        <h4>{{blockingDate | paymentInstruction}}</h4>
        <p>
          {{ 'shared.payments.schedule.payment.not_payers_will_be_blocked' | translate }}
        </p>
      </div>
    </div>
  </div>
</div>
