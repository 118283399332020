import {ChangeDetectorRef, Component, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {IPrimeTableConfig} from '@app/shared/components/prime-base-table/prime-base-table.component';
import {
  IObjectManagerAttributes,
  ITicketResponse,
  IUserDaoResponse, IZammadUserResponse,
  SupportApiService
} from '@app/views/support-service/services/support-api.service';
import {HttpErrorResponse} from '@angular/common/http';
import {catchError, debounceTime, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, forkJoin, Observable, of, Subject} from 'rxjs';
import {
  BaseInfoPanelComponent
} from '@app/shared/components/info-panel/components/base-info-panel/base-info-panel.component';
import {NativeDialogService} from '@app/shared/components/info-panel/services/native-dialog.service';
import {Response} from '@app/shared/components/info-panel/models/response';
import {isEqual} from 'lodash';
import {environment} from 'environments/environment';
import {Router} from '@angular/router';
import {LocalStorageCompanyKey, LocalStorageHelper} from '@app/shared/entities';
import {ViewportRuler} from '@angular/cdk/overlay';
import {
  ACTIVE_STATUS,
  CLOSED_STATUS,
  WAITING_STATUS
} from '@app/views/support-service/components/requests/tickets-filter.pipe';
import {
  FiltersBlockComponent,
  FiltersBlockType, SUPPORT_FORM
} from '@app/views/support-service/components/requests/filters-block/filters-block.component';
import {justFade, openCloseH} from '@app/shared/animations/animations';
import {AppSizeStates, DeviceEventService} from '@app/shared/services/device-event-service.service';

export enum NAVBAR_LINKS {
  ACTIVE,
  WAITING,
  CLOSED
}

export interface ISupportFormValue {
  isShowOnlyOwnerTasks: boolean;
  dateOfCreating: Date | Date[];
  user: number[];
}

export interface ISelectOptions {
  value: NAVBAR_LINKS;
  name: string;
}

export enum ObjectManagerAttributes {
  Status = 119,
  Rate = 133
}

export const REQUEST_TABLE_CONFIG: IPrimeTableConfig = {
  key: 'id',
  showActionColumn: false,
  header: [
    {
      name: 'Заявка',
      field: 'number',
      className: 'pt-w-40',
      sort: true
    },
    {
      name: 'Заголовок',
      field: 'title',
      className: 'pt-w-80',
      sort: true
    },
    {
      name: 'Статус',
      field: 'readableState',
      className: 'pt-w-80',
      sort: true
    },
    {
      name: 'Автор',
      field: 'creator',
      className: 'pt-w-80',
      sort: true
    },
    {
      name: 'Время создания',
      field: 'created_at',
      className: 'pt-w-80',
      sort: true
    }
  ],
  body: [
    { className: 'pt-w-40', field: 'number' },
    { className: 'pt-w-80', field: 'title', isLink: true },
    { className: 'pt-w-80', field: 'readableState', isSupportStatus: true },
    { className: 'pt-w-80', field: 'creator' },
    { className: 'pt-w-80', field: 'created_at' }
  ]
};

export abstract class ReadableTimeFormat {
  public static pcConfig = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  };

  public static mobileConfig = {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  };

  public static getAdaptiveDate(
    time: string,
    options?: Partial<Intl.DateTimeFormatOptions>
  ): string {
    const date = new Date(time);
    return date.toLocaleString(
      environment.vivotec ? 'pt-PT' : 'ru-RU',
      options
    );
  }
}

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss'],
  providers: [NativeDialogService],
  animations: [justFade, openCloseH],
  encapsulation: ViewEncapsulation.None
})
export class RequestsComponent extends BaseInfoPanelComponent implements OnInit {
  public tableConfig: IPrimeTableConfig = REQUEST_TABLE_CONFIG;
  public tickets: ITicketResponse[] = [];
  public user: IUserDaoResponse;
  public usersFromCurrentCompany: IZammadUserResponse[] = [];
  public loading = true;
  public mobileLoading = false;
  public options: ISelectOptions[] = [
    {
      value: NAVBAR_LINKS.ACTIVE,
      name: 'Активные'
    },
    {
      value: NAVBAR_LINKS.WAITING,
      name: 'Ожидают решения'
    },
    {
      value: NAVBAR_LINKS.CLOSED,
      name: 'Завершенные'
    }
  ];
  public optionValue: ISelectOptions = this.options[0];
  public isFiltersOpen: boolean;
  public supportFormValue: ISupportFormValue = null;
  public showSidebar: boolean;
  public chunkSize = 5;
  public showSkeleton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  private currentQuery: string = null;
  private unSubscribe$: Subject<void> = new Subject<void>();
  protected search$: Subject<string> = new Subject<string>();
  protected readonly AppSizeStates = AppSizeStates;
  protected readonly FiltersBlockType = FiltersBlockType;

  constructor(
    private supportApiService: SupportApiService,
    private viewportRuler: ViewportRuler,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    protected deviceEventService: DeviceEventService,
    dialogService: NativeDialogService
  ) {
    super(dialogService);
  }

  get isFormEmpty(): boolean {
    return this.supportFormValue && !Object.values(this.supportFormValue).filter((value) => value)?.length;
  }

  ngOnInit(): void {
    this.getAllUsers();
    const statuses = Object.values(ACTIVE_STATUS);
    this.currentQuery = `(${[...statuses.map((s, i) => `rd_state:"${s}"${statuses.length - 1 > i ? ' OR ' : ''}`)].join('')})`;
    this.getUserInfo();
    this.request(this.currentQuery);

    this.search$
      .pipe(
        tap(() => {
          this.mobileLoading = true;
          this.showSkeleton$.next(true);
        }),
        debounceTime(500),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((text) => {
        let subString = '';
        if (text) {
          subString = `AND (title: ${text}* OR number: ${text}*)`;
        }
        this.currentQuery = `${this.currentQuery} ${subString}`;
        this.request(this.currentQuery ? this.currentQuery : text);
        this.currentQuery = this.currentQuery.slice(0, this.currentQuery.indexOf('AND') - 1);
      });
  }

  public onNavigate(navigate: { data: ITicketResponse }): void {
    this.router.navigate([`support/request/${navigate.data.id}`]);
  }

  public onOptionsChange(option: ISelectOptions, search: HTMLInputElement): void {
    this.mobileLoading = true;
    this.optionValue = option;
    const states = [];
    if (option.value === NAVBAR_LINKS.ACTIVE) {
      states.push(...Object.values(ACTIVE_STATUS));
    }
    if (option.value === NAVBAR_LINKS.WAITING) {
      states.push(...Object.values(WAITING_STATUS));
    }
    if (option.value === NAVBAR_LINKS.CLOSED) {
      states.push(...Object.values(CLOSED_STATUS));
    }
    this.currentQuery = states.map((s, i) => `rd_state:"${s}"${states.length - 1 > i ? ' OR ' : ''}`).join('');
    this.request(`(${this.currentQuery}) ${search.value ? `AND (title: ${search.value}* OR number: ${search.value}*)` : ''}`);
  }

  public onAction(): void {
    if (this.deviceEventService.appSizeState === AppSizeStates.PHONE) {
      this.showSidebar = !this.showSidebar;
      return;
    }
    this.isFiltersOpen = !this.isFiltersOpen;
  }

  public onChangeForm(supportFormValue: ISupportFormValue): void {
    this.showSkeleton$.next(true);
    this.supportFormValue = supportFormValue;
  }

  public onFiltersReset(): void {
    this.onChangeForm(null);
    SUPPORT_FORM.user.setValue(null);
    SUPPORT_FORM.dateOfCreating.setValue(null);
    SUPPORT_FORM.isShowOnlyOwnerTasks.setValue(null);
  }

  public onScrollPosition(isTopPosition: boolean): void {
    if (isTopPosition) {
      this.chunkSize += this.chunkSize;
      this.changeDetectorRef.detectChanges();
    }
  }

  public getBadgeValue(supportFormValue: ISupportFormValue): string {
    let value = 0;
    if (supportFormValue?.user?.length) {
      value += 1;
    }
    if (supportFormValue?.isShowOnlyOwnerTasks) {
      value += 1;
    }
    if (supportFormValue?.dateOfCreating || ((supportFormValue?.dateOfCreating as Date[])?.length && supportFormValue?.dateOfCreating[0])) {
      value += 1;
    }
    return value === 0 ? '' : value.toString();
  }

  public request(query?: string): void {
    this.showSkeleton$.next(true);
    forkJoin({
      tickets: this.tasksSearch(query),
      states: this.ticketStates()
    })
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe((response) => {
        if (response instanceof HttpErrorResponse) {
          this.showResponseStatus(
            response.error,
            response.message,
            Response.error
          );
          return;
        }
        if (
          (response.tickets as ITicketResponse[]) &&
          (response.tickets as ITicketResponse[])?.length
        ) {
          this.setUpTicketStates(response.states as IObjectManagerAttributes);
          this.tickets =
            (response.tickets as ITicketResponse[]) &&
            (response.tickets as ITicketResponse[])
              .sort((a, b) => (new Date(b.created_at) as any) - (new Date(a.created_at) as any))
              .map((ticket) => {
                const res = this.usersFromCurrentCompany.find((user) => isEqual(user.id, ticket.customer_id))?.login;
                const creatorEmail: string = res ? res : null;
                return {
                  ...ticket,
                  state: (
                    response.states as IObjectManagerAttributes
                  ).data_option.options.find((state) =>
                    isEqual(ticket.rd_state, state.value)
                  ).value,
                  readableState: (
                    response.states as IObjectManagerAttributes
                  ).data_option.options.find((state) =>
                    isEqual(ticket.rd_state, state.value)
                  ).name,
                  creator: creatorEmail,
                  origin_created_at: ticket.created_at,
                  created_at: ReadableTimeFormat.getAdaptiveDate(
                    ticket.created_at,
                    this.getAdaptiveDateConfig()
                  )
                };
              })
              .filter((ticket) => {
                if (
                  LocalStorageHelper.getItem(
                    LocalStorageCompanyKey.SHORT_NAME
                  ) !== 'Rosdomofon'
                ) {
                  return isEqual(
                    +ticket.organization_id,
                    +LocalStorageHelper.getItem(
                      LocalStorageCompanyKey.ZAMMAD_ORGANIZATION_ID
                    )
                  );
                }
                return true;
              });
        } else {
          this.tickets = [];
        }
        this.loading = false;
        this.mobileLoading = false;
        this.showSkeleton$.next(false);
        this.changeDetectorRef.detectChanges();
      });
  }

  private ticketStates(): Observable<
    HttpErrorResponse | IObjectManagerAttributes
  > {
    return this.supportApiService
      .objectManagerAttributes(ObjectManagerAttributes.Status)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => of(errorResponse))
      );
  }

  private tasksSearch(
    value: string
  ): Observable<HttpErrorResponse | ITicketResponse[]> {
    return this.supportApiService
      .getTicketsWithSearch(value)
      .pipe(
        catchError((errorResponse: HttpErrorResponse) => of(errorResponse))
      );
  }

  private setUpTicketStates(states: IObjectManagerAttributes): void {
    LocalStorageHelper.setItem(
      LocalStorageCompanyKey.TICKET_STATES,
      JSON.stringify(states.data_option.options)
    );
  }

  private getAdaptiveDateConfig(): Partial<Intl.DateTimeFormatOptions> {
    return (
      this.viewportRuler.getViewportSize().width > 450
        ? ReadableTimeFormat.pcConfig
        : ReadableTimeFormat.mobileConfig
    ) as Partial<Intl.DateTimeFormatOptions>;
  }

  private getUserInfo(): void {
    this.supportApiService
      .getUserInfoByToken().pipe(takeUntil(this.unSubscribe$)).subscribe((user) => {
      console.log(user);
      this.user = user;
    });
  }

  private getAllUsers(): void {
    this.supportApiService.userSearch().pipe(takeUntil(this.unSubscribe$)
    ).subscribe((res) => {
      this.usersFromCurrentCompany = res;
    });
  }
}
