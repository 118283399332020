import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/shared/components';
import {GitlabComponentsVersions, GitlabService, GraylogRdaApiService} from '@app/shared/entities/integrations';
import {
  Camera,
  CameraUtilsService,
  Company,
  CompanyApiService,
  EntranceService,
  IpRdaConnectionTypes,
  KeysApiService,
  KeysResponse,
  PbxOnRdaApiService,
  PbxOnRdaResponse,
  RdaUtilsService,
  ServiceApiService,
  ServiceInfoResponse
} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers/parse-error';
import {
  EntrancePageResponse,
  EntrancesStatusesCountsResponseType,
  Flat,
  PagedResponse,
  ServicesTypes
} from '@app/shared/models';
import {LogsResponse} from '@app/shared/models/logs';
import {AddressFormatter} from '@app/shared/services';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {EntrancePageHelper} from '../components/entrance-page';
import {EntrancesPageService} from '../services';
import {
  EntrancesActionTypes,
  EntrancesLoadCameras,
  EntrancesLoadCamerasFailure,
  EntrancesLoadCamerasSuccess,
  EntrancesLoadCompany,
  EntrancesLoadCompanyFailure,
  EntrancesLoadCompanySuccess,
  EntrancesLoadCounts,
  EntrancesLoadCountsFailure,
  EntrancesLoadCountsSuccess,
  EntrancesLoadEntrance,
  EntrancesLoadEntranceFailure,
  EntrancesLoadEntranceSuccess,
  EntrancesLoadFlats,
  EntrancesLoadFlatsFailure,
  EntrancesLoadFlatsSuccess,
  EntrancesLoadGitlabVersions,
  EntrancesLoadGitlabVersionsFailure,
  EntrancesLoadGitlabVersionsSuccess,
  EntrancesLoadKeys,
  EntrancesLoadKeysFailure,
  EntrancesLoadKeysSuccess,
  EntrancesLoadLogs,
  EntrancesLoadLogsFailure,
  EntrancesLoadLogsSuccess,
  EntrancesLoadPage,
  EntrancesLoadPageFailure,
  EntrancesLoadPageSuccess,
  EntrancesLoadPbxOnRda,
  EntrancesLoadPbxOnRdaFailure,
  EntrancesLoadPbxOnRdaSuccess,
  EntrancesLoadServiceId,
  EntrancesLoadServiceIdFailure,
  EntrancesLoadServiceIdSuccess
} from './entrances.actions';
import {EntrancesFacade} from './entrances.facade';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class EntrancesEffects {
  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private entrancesFacade: EntrancesFacade,
    private entranceService: EntranceService,
    private rdaService: RdaUtilsService,
    private keysApiService: KeysApiService,
    private entrancesPageService: EntrancesPageService,
    private companyApiService: CompanyApiService,
    private cameraUtilsService: CameraUtilsService,
    private graylogRdaApiService: GraylogRdaApiService,
    private serviceApiService: ServiceApiService,
    private gitlabService: GitlabService,
    private pbxOnRdaApiService: PbxOnRdaApiService,
    private entrancePageHelper: EntrancePageHelper,
    private addressFormatter: AddressFormatter,
    private translate: TranslateService
  ) {
  }

  EntrancesLoadPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EntrancesLoadPage>(EntrancesActionTypes.EntrancesLoadPage),
      switchMap((action: EntrancesLoadPage) =>
        this.entranceService.getEntrancesList(action.page, action.size, {state: action.status, address: action.address})
          .pipe(
            map((response: PagedResponse<EntrancePageResponse>) =>
              new EntrancesLoadPageSuccess(
                this.entrancesPageService.prepareEntrancesPageResponse(response.content),
                response
              )
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('entrances.message.get_entrances_list.failed', {
                  text: parseError(error)
                })
              );
              return of(new EntrancesLoadPageFailure());
            })
          )
      )
    )
  );

  EntrancesLoadCounts$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EntrancesLoadCounts>(EntrancesActionTypes.EntrancesLoadCounts),
      switchMap(() =>
        this.entranceService.getEntrancesCounts()
          .pipe(
            map((response: EntrancesStatusesCountsResponseType) =>
              new EntrancesLoadCountsSuccess(
                this.entrancesPageService.prepareCountsResponse(response)
              )
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('entrances.message.get_entrances_counts.failed', {
                  text: parseError(error)
                })
              );
              return of(new EntrancesLoadCountsFailure());
            })
          )
      )
    )
  );

  EntrancesLoadEntrance$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EntrancesLoadEntrance>(EntrancesActionTypes.EntrancesLoadEntrance),
      switchMap((action: EntrancesLoadEntrance) =>
        this.entranceService.getEntrance(action.entranceId)
          .pipe(
            map((response: EntrancePageResponse) => {
              this.entrancePageHelper.updateTitle(
                response.id,
                this.addressFormatter.formatAddress(response.address)
              );

              this.entrancesFacade.loadEntranceFlats(response.id);
              this.entrancesFacade.loadServiceComponents(response.id);

              if (response.rda) {
                this.entrancesFacade.loadRdaSuccess(response.rda);
                this.entrancesFacade.loadEntranceKeys(response.rda.uid);
                this.entrancesFacade.loadGitlabVersions();

                if (this.rdaService.ipType(response.rda.intercomType) && response.rda.mode === IpRdaConnectionTypes.SIPTRUNK) {
                  this.entrancesFacade.loadPbxOnRda(response.rda.uid);
                }

                if (response.rda.companyId) {
                  this.entrancesFacade.loadCompany(response.rda.companyId);
                }
              } else {
                this.entrancesFacade.loadEntranceKeysSuccess([]);
              }

              return new EntrancesLoadEntranceSuccess(response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('entrances.message.get_entrance.failed', {
                  text: parseError(error)
                })
              );
              return of(new EntrancesLoadEntranceFailure());
            })
          )
      )
    )
  );

  EntrancesLoadCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EntrancesLoadCompany>(EntrancesActionTypes.EntrancesLoadCompany),
      switchMap((action: EntrancesLoadCompany) =>
        this.companyApiService.getCompanyById(action.companyId)
          .pipe(
            map((response: Company) =>
              new EntrancesLoadCompanySuccess(response)
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('entrances.message.get_company.failed', {
                  text: parseError(error)
                })
              );
              return of(new EntrancesLoadCompanyFailure());
            })
          )
      )
    )
  );

  EntrancesLoadKeys$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EntrancesLoadKeys>(EntrancesActionTypes.EntrancesLoadKeys),
      switchMap((action: EntrancesLoadKeys) =>
        this.keysApiService.getKeys(action.rdaUid)
          .pipe(
            map((response: KeysResponse[]) =>
              new EntrancesLoadKeysSuccess(response)
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('entrances.message.get_keys.failed', {
                  text: parseError(error)
                })
              );
              return of(new EntrancesLoadKeysFailure());
            })
          )
      )
    )
  );

  EntrancesLoadFlats$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EntrancesLoadFlats>(EntrancesActionTypes.EntrancesLoadFlats),
      switchMap((action: EntrancesLoadFlats) =>
        this.entranceService.getEntrancesFlats(action.entranceId)
          .pipe(
            map((response: Flat[]) =>
              new EntrancesLoadFlatsSuccess(response)
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('entrances.message.get_flats.failed', {
                  text: parseError(error)
                })
              );
              return of(new EntrancesLoadFlatsFailure());
            })
          )
      )
    )
  );

  EntrancesLoadGitlabVersions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EntrancesLoadGitlabVersions>(EntrancesActionTypes.EntrancesLoadGitlabVersions),
      switchMap(() =>
        this.gitlabService.getLatestVersions()
          .pipe(
            map((response: GitlabComponentsVersions) =>
              new EntrancesLoadGitlabVersionsSuccess(response)
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('entrances.message.get_latest_versions.failed', {
                  text: parseError(error)
                })
              );
              return of(new EntrancesLoadGitlabVersionsFailure());
            })
          )
      )
    )
  );

  EntrancesLoadPbxOnRda$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EntrancesLoadPbxOnRda>(EntrancesActionTypes.EntrancesLoadPbxOnRda),
      switchMap((action: EntrancesLoadPbxOnRda) =>
        this.pbxOnRdaApiService.getPage(0, 1, {rdaUid: action.rdaUid})
          .pipe(
            map((response: PagedResponse<PbxOnRdaResponse>) =>
              new EntrancesLoadPbxOnRdaSuccess(response.content[0])
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('entrances.message.get_pbx_on_rda.failed', {
                  text: parseError(error)
                })
              );
              return of(new EntrancesLoadPbxOnRdaFailure());
            })
          )
      )
    )
  );

  EntrancesLoadServiceId$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EntrancesLoadServiceId>(EntrancesActionTypes.EntrancesLoadServiceId),
      switchMap((action: EntrancesLoadServiceId) =>
        this.serviceApiService.getEntranceServices(action.entranceId)
          .pipe(
            map((response: ServiceInfoResponse[]) => {
              const serviceId: number = response.find(service =>
                service.type === ServicesTypes.SOFTWARE_INTERCOM ||
                service.type === ServicesTypes.VIDEO_SURVEILLANCE
              )?.id;

              if (serviceId) {
                this.entrancesFacade.loadCameras(action.entranceId, serviceId);
              } else {
                this.entrancesFacade.loadCamerasSuccess([]);
                this.entrancesFacade.loadCamerasWithErrorsSuccess([]);
              }

              return new EntrancesLoadServiceIdSuccess();
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('entrances.message.get_entrance_services.failed', {
                  text: parseError(error)
                })
              );
              return of(new EntrancesLoadServiceIdFailure());
            })
          )
      )
    )
  );

  EntrancesLoadCameras$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EntrancesLoadCameras>(EntrancesActionTypes.EntrancesLoadCameras),
      switchMap((action: EntrancesLoadCameras) =>
        from(this.entrancePageHelper.getCameras(action.entranceId, action.serviceId))
          .pipe(
            map((response: Camera[]) => {
              this.entrancesFacade.loadCamerasWithErrorsSuccess(
                response.filter(camera => !this.cameraUtilsService.isCameraCorrect(camera))
              );
              return new EntrancesLoadCamerasSuccess(response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('entrances.message.get_cameras.failed', {
                  text: parseError(error)
                })
              );
              return of(new EntrancesLoadCamerasFailure());
            })
          )
      )
    )
  );

  EntrancesLoadLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType<EntrancesLoadLogs>(EntrancesActionTypes.EntrancesLoadLogs),
      switchMap((action: EntrancesLoadLogs) =>
        this.graylogRdaApiService.getExtendedLogs(action.rdaUid, null, action.timeRange)
          .pipe(
            map((response: LogsResponse[]) => {
              return new EntrancesLoadLogsSuccess(response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('entrances.message.get_logs.failed', {
                  text: parseError(error)
                })
              );
              return of(new EntrancesLoadLogsFailure());
            })
          )
      )
    )
  );
}
