<mat-toolbar>
  <button
    mat-icon-button
    *ngIf="mdWDownBreakpoint$ | async"
    (click)="backAction ? location.back() : onClick()"
  >
    <mat-icon>{{ backAction ? 'keyboard_backspace' : 'menu' }}</mat-icon>
  </button>
  <h1>{{ (mdWDownBreakpoint$ | async) ? titleTranslated(title) : ('core.top_bar.title' | translate) }}</h1>
  <button
    mat-icon-button
    (click)="openLink()"
    *ngIf="(mdWDownBreakpoint$ | async) && hasLink"
  >
    <mat-icon svgIcon="question"></mat-icon>
  </button>
</mat-toolbar>
