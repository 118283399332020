import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ServiceCreateRequest } from '@app/shared/entities/rd';
import { ServicesTypes } from '@app/shared/models';

@Component({
  selector: 'app-create-service-content',
  templateUrl: './create-service-content.component.html',
  styleUrls: ['./create-service-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateServiceContentComponent {
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  @Input() serviceType: ServicesTypes;
  form: UntypedFormGroup = this.initForm();

  @Output() private createService: EventEmitter<{ request: ServiceCreateRequest }> = new EventEmitter();
  private _loading: boolean;

  get loading(): boolean {
    return this._loading;
  }

  onSubmit() {
    this.createService.emit({ request: this.form.getRawValue() });
  }

  private initForm() {
    return new UntypedFormGroup({ serviceName: new UntypedFormControl(null) });
  }
}
