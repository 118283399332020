<form [formGroup]="form" (submit)="onSubmit()" autocomplete="off" class="form-block">
  <h3 class="form-block__title">
    {{ 'shared.reports.penetration.report.content.title' | translate }}
  </h3>
  <p class="form-block__paragraph">
    {{ 'shared.reports.penetration.report.content.subtitle' | translate }}
  </p>

  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'shared.reports.penetration.report.content.field.first_date' | translate }}
    </mat-label>
    <input matInput
      [max]="maxDate"
      autocorrect="off"
      autocomplete="off"
      spellcheck="false"
      autocapitalize="off"
      formControlName="firstDate"
      placeholder="{{ 'shared.reports.penetration.report.content.placeholder.first_date' | translate }}"
      [matDatepicker]="firstDatePicker"
    >
    <mat-datepicker-toggle matSuffix [for]="firstDatePicker">
      <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #firstDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'shared.reports.penetration.report.content.field.second_date' | translate }}
    </mat-label>
    <input matInput
      [max]="maxDate"
      autocorrect="off"
      autocomplete="off"
      spellcheck="false"
      autocapitalize="off"
      formControlName="secondDate"
      placeholder="{{ 'shared.reports.penetration.report.content.placeholder.second_date' | translate }}"
      [matDatepicker]="secondDatePicker"
    >
    <mat-datepicker-toggle matSuffix [for]="secondDatePicker">
      <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #secondDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'shared.reports.penetration.report.content.field.third_date' | translate }}
    </mat-label>
    <input matInput
      [max]="maxDate"
      autocorrect="off"
      autocomplete="off"
      spellcheck="false"
      autocapitalize="off"
      formControlName="thirdDate"
      placeholder="{{ 'shared.reports.penetration.report.content.placeholder.third_date' | translate }}"
      [matDatepicker]="thirdDatePicker"
    >
    <mat-datepicker-toggle matSuffix [for]="thirdDatePicker">
      <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #thirdDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline" *ngIf="companyId === ROSDOMOFON_COMPANY_ID">
    <mat-label>
      {{ 'shared.reports.penetration.report.content.field.cutting' | translate }}
    </mat-label>
    <input matInput
      [max]="maxDate"
      autocorrect="off"
      autocomplete="off"
      spellcheck="false"
      autocapitalize="off"
      placeholder="{{ 'shared.reports.penetration.report.content.placeholder.cutting' | translate }}"
      formControlName="servicesLimitDate"
      [matDatepicker]="servicesLimitDatePicker"
    >
    <mat-datepicker-toggle matSuffix [for]="servicesLimitDatePicker">
      <mat-icon matDatepickerToggleIcon>calendar_today</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker #servicesLimitDatePicker></mat-datepicker>
  </mat-form-field>

  <div class="form-block-actions">
    <button
      type="submit"
      color="primary"
      mat-raised-button
      class="form-block-actions__submit-button"
      [disabled]="loading"
    >
      {{ 'shared.reports.penetration.report.content.button.submit' | translate }}
    </button>
  </div>
</form>
