<div class="apartment-video-service-activity__wrapper">
  <app-service-activity [serviceType]="serviceType"
                        [logs]="logs$ | async"
                        [logsLoading]="logsLoading$ | async"
                        [blocksCount]="blocksCount$ | async"
                        [sources]="sources$ | async"
                        [timeRange]="timeRange$ | async"
                        [extendedMode]="extendedMode$ | async"
                        [componentType]="logsComponentType$ | async"
                        [totalLogsCount]="totalLogsCount$ | async"
                        [currentLogsCount]="currentLogsCount$ | async"
                        [selectedLogsSource]="selectedLogsSource$ | async"
                        [activeHistoryCameras]="activeHistoryCameras$ | async"></app-service-activity>
</div>
