import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {GridRenderAction, RenderComponent} from '@app/shared/components/render/render.component';
import {DataViewModule} from 'primeng/dataview';
import {CommonModule} from '@angular/common';
import {cloneDeep, isEmpty} from 'lodash';
import {TooltipModule} from 'primeng/tooltip';
import {BehaviorSubject, of, Subject, Subscription} from 'rxjs';
import {ResolutionBreakpoint, ResolutionService} from '@app/shared/services';
import {Paginator, PaginatorModule} from 'primeng/paginator';
import {IPageChange} from '@app/shared/components/prime-base-table/custom-paginator/custom-paginator.component';
import {InputTextModule} from 'primeng/inputtext';
import {AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {delay, takeUntil} from 'rxjs/operators';
import {ButtonModule} from 'primeng/button';

export namespace PaginationDataView {
  export type SortOrder = 'asc' | 'desc' | 'none';
  export const ClassPrefix = 'pagination-data-view';

  export interface Config<TData> {
    id: string;
    key: string;
    emptyMessage?: string;
    showSearch?: boolean;
    sizes: SizesColumnConfig[];

    header: HeaderColumnConfig[]; // config for usual rendering HEADER row
    headerTemplate?: TemplateRef<any>; // rendering HEADER using template and context
    headerRenderOptions?: GridRenderAction<any, any, TData>; // rendering HEADER using dynamic render

    grid: GridColumnConfig[]; // config for usual rendering GRID OR LIST using cell config

    gridRowTemplate?: TemplateRef<any>; // for rendering GRID row using template from parent component
    listRowTemplate?: TemplateRef<any>; // for rendering LIST row using template from parent component

    gridRowRenderOptions?: GridRenderAction<any, any, TData>; // for rendering GRID row using dynamic render
    listRowRenderOptions?: GridRenderAction<any, any, TData>; // for rendering LIST row using dynamic render

    paginator?: PaginatorConfig;
  }

  export interface PaginatorConfig {
    show: true;
    dropdownValues?: number[];
    first?: number;
    rows?: number;
    totalRecords?: number;
    pageLinkSize?: number;
    currentPageReportTemplate?: string;
    pageSize?: number;
    page?: number;
  }

  export interface BaseColumnConfig {
    field: string;
    isAction?: boolean;
  }

  export interface SizesColumnConfig extends BaseColumnConfig {
    width: number;
    sizeType?: 'px' | 'rem' | '%';
  }

  export interface HeaderColumnConfig extends BaseColumnConfig {
    name: string;
    className?: string;
    sort?: boolean;
    tooltip?: string;
    useRender?: boolean;
    renderOptions?: GridRenderAction<any, any, any>;
  }

  export interface GridColumnConfig extends BaseColumnConfig {
    className?: string;
    tooltip?: string;
    useRender?: boolean;
    renderOptions?: GridRenderAction<any, any, any>;
  }

  export interface State<TData> {
    sortBy: { field: string; order: SortOrder; };
    page: number;
    init?: boolean;
    search?: string;
    pageSize: number;
    selected?: TData[];
  }
}

@Component({
  standalone: true,
  selector: 'app-pagination-data-view',
  templateUrl: './pagination-data-view.component.html',
  styleUrls: ['./pagination-data-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    DataViewModule,
    RenderComponent,
    TooltipModule,
    PaginatorModule,
    InputTextModule,
    ReactiveFormsModule,
    TranslateModule,
    ButtonModule,
  ],
  changeDetection: ChangeDetectionStrategy.Default
})
export class PaginationDataViewComponent<TData> implements OnInit, OnDestroy  {
  protected readonly isEmpty = isEmpty;

  @Input() public layout: 'list' | 'grid';
  @Input() public data: TData[] = [];

  @Input() public set config(conf: PaginationDataView.Config<TData>) {
    const state = localStorage.getItem(`pagination-data-view-${conf.key}`);
    this._state = state ? JSON.parse(state) : null;
    if (!this._state) {
      this._state = {
        sortBy: {field: '', order: 'none'},
        search: '',
        page: conf?.paginator?.first || 0,
        pageSize: conf?.paginator?.pageSize || 0
      };
    }
    this.sizesMapper = new Map<string, { size: string; name: string; }>();
    conf.sizes.forEach(s => this.sizesMapper.set(
      s.field, {
        size: `${s.width}${s.sizeType || 'px'}`,
        name: conf.header.find((h) => h.field === s.field)?.name
      }
    ));
    this._config = cloneDeep(conf);
  }

  @Input() public loading = new BehaviorSubject<boolean>(false);
  @Output() public output = new EventEmitter<PaginationDataView.State<TData>>();

  @ViewChild('pDataView') public pDataView: DataView;
  @ViewChild(Paginator) public paginator: Paginator;

  public headerForm = new FormGroup<{ search: AbstractControl<string> }>({
    search: new FormControl<string>('', Validators.minLength(3)),
  });
  public sizesMapper: Map<string, { size: string; name: string; }>;
  public init = false;
  public trackByFn = (index: number, item: TData) => item[this.config.key];

  public get config(): PaginationDataView.Config<TData> {
    return this._config;
  }

  public get classPrefix(): string {
    return PaginationDataView.ClassPrefix;
  }

  public get layoutType(): string {
    return this.layout ? this.layout : !this.resolutionService.getBreakpointState(ResolutionBreakpoint.XL_W_DOWN) ? 'grid' : 'list';
  }

  public get searchText(): string {
    return this.headerForm?.get('search')?.value || '';
  }

  private _config: PaginationDataView.Config<TData>;
  private _state: PaginationDataView.State<TData>;
  private changeSubscription: Subscription;
  private destroy = new Subject<void>();

  constructor(
    private readonly resolutionService: ResolutionService,
  ) {
  }

  public ngOnInit(): void {
    this.headerForm.get('search').setValue(this._state?.search);
    this.headerForm.get('search').valueChanges
      .pipe(takeUntil(this.destroy))
      .subscribe((value) => this.emitChangeWithDelay());
    this.init = true;
    this.saveStateAndFire(true);
  }

  public ngOnDestroy(): void {
    this.destroySubscription();
    this.destroy.next();
    this.destroy.complete();
  }

  public emitChangeWithDelay(isEnter: boolean = false): void {
    this.destroySubscription();
    if (isEnter) {
      this.onSearchAction();
      return;
    }
    this.changeSubscription = of(this.searchText).pipe(delay(500))
      .subscribe((value) => this.onSearchAction());
  }

  public onSort(hConfig: PaginationDataView.HeaderColumnConfig): void {
    if (!hConfig.sort) {
      return;
    }
    const isCurrentFieldSorted = this._state.sortBy?.field === hConfig.field;
    const order = !isCurrentFieldSorted ? 'asc' :
      this._state.sortBy?.order === 'asc' ?
        'desc' :
        this._state.sortBy?.order === 'desc' ?
          'none' :
          'asc';
    this._state.sortBy = {field: hConfig.field, order};
    this.saveStateAndFire(false);
  }

  public getHeaderNgClasses(hConfig: PaginationDataView.HeaderColumnConfig): Record<string, boolean> {
    return {
      [hConfig.className || '']: !!hConfig.className,
      [`${this.classPrefix}__header__cell--sortable-column`]: !!hConfig?.sort,
      [`${this.classPrefix}__header__cell--sort-type-${this.isCurrentFieldSorted(hConfig) ? this._state?.sortBy?.order : 'none'}`]: !!hConfig?.sort,
    };
  }

  public getSortIconClass(hConfig: PaginationDataView.HeaderColumnConfig): Record<string, boolean> {
    let className = '';
    if (this.isCurrentFieldSorted(hConfig)) {
      switch (this._state?.sortBy?.order) {
        case 'asc':
          className = 'pi pi-sort-amount-up';
          break;
        case 'desc':
          className = 'pi pi-sort-amount-down';
          break;
        default:
          break;
      }
    }
    return {[className]: true};
  }

  private onSearchAction(): void {
    this._state.search = this.searchText;
    this.saveStateAndFire(false);
  }

  public onPageChange($event: IPageChange): void {
    this._state.page = $event.page;
    this._state.pageSize = $event.rows;
    this.saveStateAndFire(false);
  }

  private destroySubscription(): void {
    if (this.changeSubscription) {
      this.changeSubscription.unsubscribe();
      this.changeSubscription = null;
    }
  }

  private isCurrentFieldSorted(hConfig: PaginationDataView.HeaderColumnConfig): boolean {
    return this._state.sortBy?.field === hConfig.field;
  }

  private saveStateAndFire(init: boolean): void {
    localStorage.setItem(`pagination-data-view-${this.config.id}`, JSON.stringify(this._state));
    this.output.emit(cloneDeep({init, ...this._state}));
  }
}
