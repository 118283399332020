<mat-card class="company-register-form">
  <mat-card-header>
    <mat-card-title>
      {{ 'company.register.page.form.title' | translate }}
    </mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <mat-horizontal-stepper
      labelPosition="bottom"
      [linear]="true">
      <mat-step
        class="company-register-form-step"
        [stepControl]="firstFormGroup">
        <form
          class="company-register-form-step-content"
          [formGroup]="firstFormGroup">
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'company.register.page.form.field.company_name' | translate }}
            </mat-label>
            <input
              matInput
              id="companyName"
              type="text"
              formControlName="companyName"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              required />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'company.register.page.form.field.orgn' | translate }}
            </mat-label>
            <input
              matInput
              id="ogrn"
              type="text"
              formControlName="ogrn"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              required />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'company.register.page.form.field.inn' | translate }}
            </mat-label>
            <input
              matInput
              id="individualNumber"
              type="text"
              formControlName="individualNumber"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              required />
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'company.register.page.form.field.legal_address' | translate }}
            </mat-label>
            <input
              matInput
              id="legalAddress"
              type="text"
              formControlName="legalAddress"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              required />
          </mat-form-field>

          <div class="company-register-form-step-content-buttons--single">
            <button
              mat-raised-button
              type="button"
              [class.company-register-form-step-content-buttons__button--large]="
                true
              "
              [class.company-register-form-step-content-buttons__button]="true"
              color="primary"
              matStepperNext
            >
              {{ 'company.register.page.form.button.next' | translate }}
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step
        class="company-register-form-step"
        [stepControl]="secondFormGroup">
        <form
          class="company-register-form-step-content"
          [formGroup]="secondFormGroup">
          <mat-form-field appearance="outline">
            <mat-label>
              {{ 'company.register.page.form.field.email' | translate }}
            </mat-label>
            <input
              matInput
              id="email"
              type="email"
              formControlName="email"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              required />
          </mat-form-field>
          <app-phone-input formGroupName="phone">
            <ng-container phone-input-label>
              {{ 'company.register.page.form.label.email' | translate }}
            </ng-container>
            <ng-container phone-input-hint>
              {{ 'company.register.page.form.hint.email' | translate }}
            </ng-container>
          </app-phone-input>

          <div class="company-register-form-step-content-buttons--double">
            <button
              mat-raised-button
              type="button"
              [class.company-register-form-step-content-buttons__button--large]="
                true
              "
              [class.company-register-form-step-content-buttons__button]="true"
              color="primary-inverse"
              matStepperPrevious
            >
              {{ 'company.register.page.form.button.prev' | translate }}
            </button>

            <button
              mat-raised-button
              type="button"
              [class.company-register-form-step-content-buttons__button--large]="
                true
              "
              [class.company-register-form-step-content-buttons__button]="true"
              color="primary"
              matStepperNext
            >
              {{ 'company.register.page.form.button.next' | translate }}
            </button>
          </div>
        </form>
      </mat-step>

      <mat-step
        class="company-register-form-step"
        [stepControl]="thirdFormGroup">
        <form
          class="company-register-form-step-content"
          [formGroup]="thirdFormGroup"
          (ngSubmit)="onSubmit()">
          <div class="company-register-form-step-content-checkbox-block">
            <mat-checkbox
              class="company-register-form-step-content-checkbox-block__checkbox"
              id="accept"
              formControlName="accept"
            ></mat-checkbox>
            <p class="company-register-form-step-content-checkbox-block__text">
              {{ 'company.register.page.form.message.agreement_1' | translate }}
              <a
                [href]="licenseAgreementUrl"
                target="_blank"
              >
                {{ 'company.register.page.form.link.agreement' | translate }}
              </a>
              {{ 'company.register.page.form.message.agreement_2' | translate: {
                name: companyNameWithOpf
              } }}
            </p>
          </div>

          <div class="company-register-form-step-content-buttons--double">
            <button
              mat-raised-button
              type="button"
              [class.company-register-form-step-content-buttons__button--small]="
                true
              "
              [class.company-register-form-step-content-buttons__button]="true"
              color="primary-inverse"
              matStepperPrevious
            >
              {{ 'company.register.page.form.button.prev' | translate }}
            </button>

            <button
              mat-raised-button
              type="submit"
              [class.company-register-form-step-content-buttons__button--large]="
                true
              "
              [class.company-register-form-step-content-buttons__button]="true"
              color="success"
            >
              {{ 'company.register.page.form.button.submit' | translate }}
            </button>
          </div>
        </form>
      </mat-step>
    </mat-horizontal-stepper>
  </mat-card-content>
</mat-card>
