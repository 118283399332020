import { Pipe, PipeTransform } from '@angular/core';
import {Address, SignUpResponse} from '@app/shared/models';
import {AddressFormatter} from '@app/shared/services';
import {IConnection, IServicesConnection} from '@app/shared/entities/rd';

export interface IConvertedSignUpResponse {
  signUpId: number;
  signUpType?: string;
  abonentId: number;
  phone: string | number;
  houseId: number;
  isVirtual: boolean;
  flatId: number;
  isFreeAddress: boolean;
  isOwner: boolean;
  isDelegated: boolean;
  entranceId: number;
  address: Address;
  fullAddress: string;
  flatNumber: number;
  createdAt: number | Date | string;
  updatedAt: number;
  companyName: string;
  companyId: number;
  addressStr: string;
  adCampaign: string;
  adSource: string;
  status: string;
  services: IServicesConnection[];
  disconnectionReason?: string;
}

@Pipe({
  name: 'dataSourceConverter'
})
export class DataSourceConverterPipe implements PipeTransform {

  constructor(private addressFormatter: AddressFormatter) {}

  transform(signUpResponse: SignUpResponse[]): IConvertedSignUpResponse[] {
    return signUpResponse.map((value) => {
      return {
        ...value,
        services: value['services'] ? value['services'].map((service) => service.type) : [],
        phone: `+${value.phone}`,
        fullAddress: this.addressFormatter.formatAddress(value.address),
        flatNumber: value.address.flat,

      };
    }) as any;
  }

}
