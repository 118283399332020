import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LocalStorageDashboardKey, LocalStorageHelper} from '@app/shared/entities';
import {ButtonModule} from 'primeng/button';
import {Router} from '@angular/router';

@Component({
  selector: 'app-dashboard-warning',
  templateUrl: './dashboard-warning.component.html',
  styleUrls: ['./dashboard-warning.component.scss'],
  imports: [CommonModule, ButtonModule],
  standalone: true,
})
export class DashboardWarningComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {LocalStorageHelper.setItem(LocalStorageDashboardKey.DASHBOARD_DISCONNECTION_REQUESTS, true);}

  public navigate(): void {
    this.router.navigate(['/abonents/sign_ups']).then(() => {});
  }
}
