import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { LoginRequest, LoginResponse } from '@app/shared/entities/rd';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthApiService extends ApiService {
    constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
        super(httpClient, humanErrorTranslationService, environment.authApiUrl, []);
    }

    login(request: LoginRequest): Observable<LoginResponse> {
        const headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
        const params = new HttpParams()
            .set('grant_type', 'password')
            .set('client_id', 'machine')
            .set('username', request.username)
            .set('password', request.password);

        return this.post<LoginResponse>('/oauth/token', 0, params.toString(), { headers });
    }
}
