
export interface UpdatePaymentConfigurationRequest {
  isRecurringEnabled: boolean;
  schedule: UpdatePaymentConfigurationScheduleRequest;
}

export interface UpdatePaymentConfigurationScheduleRequest {
  billCronExpr: string;
  blockCronExpr: string;
  reminderCronExpr: string;
}
