<div class="apartment-video-add-service__wrapper">
  <div *ngIf="showLoading" class="apartment-video-add-service__content__loader__wrapper">
    <p-progressSpinner></p-progressSpinner>
  </div>
  <div class="apartment-video-add-service__content apartment-video-add-service__content__wrapper">
    <div *ngIf="!loaded" class="content__form__wrapper">
      <p-skeleton width="100%" height="2rem"></p-skeleton>
      <p-skeleton width="100%" height="2rem"></p-skeleton>
      <p-skeleton width="5rem" height="2rem"></p-skeleton>
      <p-skeleton width="100%" height="2rem"></p-skeleton>
      <p-skeleton width="100%" height="2rem"></p-skeleton>
    </div>
    <div *ngIf="loaded" [formGroup]="addServiceForm" class="content__form__wrapper">
      <div *ngIf="!isRename" class="content__form__field__wrapper">
        <p-inputMask #subscriberField mask="+7 999 999 99 99" id="subscriber" formControlName="subscriber" class="content__form__field--item"
                     [placeholder]="'apartments_video_surveillance.popup.subscriber' | translate" [autoClear]="false"></p-inputMask>
      </div>
      <div class="content__form__field__wrapper">
        <input pInputText id="serviceName" formControlName="serviceName" class="content__form__field--item"
               [placeholder]="'apartments_video_surveillance.popup.name_service_placeholder' | translate"/>
      </div>
      <div @fromOpacity [hidden]="!showAdditionalFields || isRename" class="content__form__field__wrapper">
        <p-dropdown styleClass="content__form__field--item" formControlName="selectedAccount"
                    [options]="accountlist" optionLabel="number" optionValue="id" appendTo="body"
                    [placeholder]="'apartments_video_surveillance.popup.select_account' | translate"></p-dropdown>
      </div>
      <div @fromOpacity [hidden]="!showAdditionalFields || isRename" class="content__form__field__wrapper">
        <div class="content__form__field__row__wrapper">
          <p-inputSwitch formControlName="createNewAccount"></p-inputSwitch>
          <label class="content__form__field--label">{{'apartments_video_surveillance.popup.create_new_account' | translate}}</label>
        </div>
      </div>
      <div @fromOpacity [hidden]="!showAdditionalFields || isRename" class="content__form__field__wrapper">
        <input class="content__form__field--item" type="text" pInputText id="newAccountName"
               formControlName="newAccountName" [placeholder]="'apartments_video_surveillance.popup.new_account' | translate" />
      </div>
    </div>
  </div>
  <div class="apartment-video-add-service__footer__wrapper">
    <p-button [disabled]="!isFormValid"
              [label]="(isRename ? 'apartments_video_surveillance.popup.save' : 'apartments_video_surveillance.popup.add') | translate"
              [styleClass]="isFormValid ? '' : 'p-button-secondary'" class="apartment-video-add-service__footer--fw-action"
              (onClick)="onSaveAction()"></p-button>
  </div>
</div>
