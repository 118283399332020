import { LoggerService, RdeaDate } from '@app/shared/entities/common';
import { VideoPlayerFragmentShortDate, VideoPlayerGapsColorTypes } from '../../../models';
import { TZ_OFFSET_IN_MILLISECONDS } from '../../../video-player.constants';
import { PlyrConvertersHelper, PlyrTemplateHelper, PlyrTimeHelper } from '../../plyr-globals';
import { PlyrPickerControlEventType, PlyrPickerControlsHelper } from '../picker-control';
import { PlyrControlBasicHelper } from './../base';
import { PlyrProgressBarControlCanvasHelper } from './plyr-progress-bar-control-canvas-helper';
import { PlyrProgressBarControlEventType } from './plyr-progress-bar-control-event-type.enum';

/**
 * Class for progress bar control
 */
export class PlyrProgressControlHelper extends PlyrControlBasicHelper<PlyrProgressBarControlEventType> {
    private eventsTimestamps: VideoPlayerFragmentShortDate[];
    private rangesTimestamps: VideoPlayerFragmentShortDate[];
    private progressBarWrapper: HTMLDivElement;
    private canvasHelper: PlyrProgressBarControlCanvasHelper;

    constructor(
        private loggerService: LoggerService
    ) {
        super();

        this.progressBarWrapper = document.getElementById(PlyrTemplateHelper.PROGRESS_BAR_WRAPPER_ID) as HTMLDivElement;

        this.prepareInternalControls();
        this.enableProgressBarEventListeners();
        this.enableInternalControlsListeners();
        this.prepareHelpers();
    }

    /**
     * Set progress time and progress bar using absolute time
     * @param {number} absoluteTime time from fragment tag in milliseconds
    */
    setCurrentTime(absoluteTime: number) {
        const correctedAbsoluteTimeWithOffset: number =
            this.findFirstAbsoluteTimeFromFragments(absoluteTime - TZ_OFFSET_IN_MILLISECONDS).correctAbsoluteTimeWithOffset;
        this.updatePlyrTimeControls(correctedAbsoluteTimeWithOffset);
    }

    /**
     * Update progress time using relative time from player
     * @param {number} relativeTime current time from player in milliseconds
    */
    updateCurrentTime(relativeTime: number) {
        if (PlyrTimeHelper.absoluteTime === undefined) {
            return;
        }

        // If relative time not selected before
        if (PlyrTimeHelper.relativeTime === undefined || PlyrTimeHelper.relativeTime === null) {
            PlyrTimeHelper.relativeTime = relativeTime;
        }

        const absoluteTime = PlyrTimeHelper.absoluteTime + (relativeTime - PlyrTimeHelper.relativeTime) * 1000;
        PlyrTimeHelper.relativeTime = relativeTime;

        this.updatePlyrTimeControls(absoluteTime);
    }

    /**
     * Draw gaps from rages timestamps
     * @param {VideoPlayerFragmentShortDate[]} rangesTimestamps ranges timestamps from Hls fragments
     */
    prepareAndDrawGaps(rangesTimestamps: VideoPlayerFragmentShortDate[]) {
        this.rangesTimestamps = rangesTimestamps;

        if (!this.rangesTimestamps?.length) {
            return;
        }

        // If video starts not from left bound
        const startTimestampWithOffset = this.rangesTimestamps[0].startTimestamp - TZ_OFFSET_IN_MILLISECONDS;
        const boundsDiff = PlyrTimeHelper.rightBoundTimestampWithOffset - PlyrTimeHelper.leftBoundTimestampWithOffset;

        if (startTimestampWithOffset > PlyrTimeHelper.leftBoundTimestampWithOffset) {
            this.canvasHelper.drawGap(
                0,
                (startTimestampWithOffset - PlyrTimeHelper.leftBoundTimestampWithOffset) * 100 / boundsDiff,
                VideoPlayerGapsColorTypes.GAPS
            );
        }

        for (let i = 0; i < this.rangesTimestamps.length - 1; ++i) {
            if (this.rangesTimestamps[i + 1].startTimestamp - this.rangesTimestamps[i].endTimestamp < 10000) {
                continue;
            }

            const leftBoundTimestampWithOffset: number =
                Math.abs(this.rangesTimestamps[i].endTimestamp - TZ_OFFSET_IN_MILLISECONDS - PlyrTimeHelper.leftBoundTimestampWithOffset);

            this.canvasHelper.drawGap(
                leftBoundTimestampWithOffset * 100 / boundsDiff,
                Math.abs(this.rangesTimestamps[i + 1].startTimestamp - this.rangesTimestamps[i].endTimestamp) * 100 / boundsDiff,
                VideoPlayerGapsColorTypes.GAPS
            );
        }

        // If video ended not at right bound
        const endTimestampWithOffset = this.rangesTimestamps[this.rangesTimestamps.length - 1].endTimestamp - TZ_OFFSET_IN_MILLISECONDS;

        if (endTimestampWithOffset < PlyrTimeHelper.rightBoundTimestampWithOffset) {
            this.canvasHelper.drawGap(
                Math.abs(endTimestampWithOffset - PlyrTimeHelper.leftBoundTimestampWithOffset) * 100 / boundsDiff,
                100 - Math.abs(endTimestampWithOffset - PlyrTimeHelper.leftBoundTimestampWithOffset) * 100 / boundsDiff,
                VideoPlayerGapsColorTypes.GAPS
            );
        }
    }

    /**
     * Select event for the selected absolute time
     * @param absoluteTime event start position in absolute time
     */
    selectEvent(absoluteTime: number) {
        this.applySelectedTime({ absoluteTimeWithOffset: absoluteTime });
    }

    /**
     * Fill timeline using white color
     */
    clearCanvas() {
        this.canvasHelper.clear();
    }

    /**
     * Get next event in events list (move by circle)
     */
    selectNextEvent() {
        const getCorrectTimestamp = (curTimestamps: VideoPlayerFragmentShortDate, nextTimestamps: VideoPlayerFragmentShortDate) =>
            PlyrTimeHelper.absoluteTime >= curTimestamps.startTimestamp - TZ_OFFSET_IN_MILLISECONDS &&
                PlyrTimeHelper.absoluteTime < nextTimestamps.startTimestamp - TZ_OFFSET_IN_MILLISECONDS ? nextTimestamps : null;

        const leftTranslation = (curTimestamps: VideoPlayerFragmentShortDate) =>
            PlyrTimeHelper.absoluteTime < curTimestamps.startTimestamp - TZ_OFFSET_IN_MILLISECONDS;

        const getTimestampUsingBounds = (start: number, end: number) => {
            if (end === -1) {
                return this.eventsTimestamps[0];
            } else if (start === this.eventsTimestamps.length - 1 && end === this.eventsTimestamps.length - 1) {
                return this.eventsTimestamps[0];
            } else if (start === this.eventsTimestamps.length - 1) {
                return this.eventsTimestamps[this.eventsTimestamps.length - 1];
            }

            return null;
        };

        const fragmentStartTimestamp: number = this.findEvent(
            getCorrectTimestamp,
            leftTranslation,
            getTimestampUsingBounds
        );

        this.applySelectedTime({ absoluteTimeWithOffset: fragmentStartTimestamp - TZ_OFFSET_IN_MILLISECONDS });
    }

    /**
     * Get previous event in events list (move by circle)
     */
    selectPreviousEvent() {
        const getCorrectTimestamp = (curTimestamps: VideoPlayerFragmentShortDate, nextTimestamps: VideoPlayerFragmentShortDate) =>
            PlyrTimeHelper.absoluteTime < nextTimestamps.endTimestamp - TZ_OFFSET_IN_MILLISECONDS &&
                PlyrTimeHelper.absoluteTime > curTimestamps.endTimestamp - TZ_OFFSET_IN_MILLISECONDS ? curTimestamps : null;

        const leftTranslation = (curTimestamps: VideoPlayerFragmentShortDate) =>
            PlyrTimeHelper.absoluteTime <= curTimestamps.endTimestamp - TZ_OFFSET_IN_MILLISECONDS;

        const getTimestampUsingBounds = (start: number, end: number) => {
            if (start === 0 && end === -1) {
                return this.eventsTimestamps[this.eventsTimestamps.length - 1];
            } else if (start === 0 && end === 0) {
                return this.eventsTimestamps[0];
            }

            return null;
        };

        const fragmentStartTimestamp: number = this.findEvent(
            getCorrectTimestamp,
            leftTranslation,
            getTimestampUsingBounds
        );

        this.applySelectedTime({ absoluteTimeWithOffset: fragmentStartTimestamp - TZ_OFFSET_IN_MILLISECONDS });
    }

    /**
     * Draw events from events timestamps
     * @param {VideoPlayerFragmentShortDate[]} eventsTimestamps events timestamps from Hls fragments
     */
    prepareAndDrawEvents(eventsTimestamps: VideoPlayerFragmentShortDate[]) {
        this.eventsTimestamps = eventsTimestamps;

        if (!this.eventsTimestamps?.length) {
            return;
        }

        const boundsDiff: number = PlyrTimeHelper.rightBoundTimestampWithOffset - PlyrTimeHelper.leftBoundTimestampWithOffset;

        for (let i = 0; i < this.eventsTimestamps.length; ++i) {
            this.canvasHelper.drawGap(
                (this.eventsTimestamps[i].startTimestamp - TZ_OFFSET_IN_MILLISECONDS - PlyrTimeHelper.leftBoundTimestampWithOffset) * 100 / boundsDiff,
                (this.eventsTimestamps[i].endTimestamp - this.eventsTimestamps[i].startTimestamp) * 100 / boundsDiff,
                VideoPlayerGapsColorTypes.EVENTS
            );
        }
    }

    /**
     * Get PlyrPickerControlsHelper instance
     * @returns PlyrPickerControlsHelper instance
     */
    private picker(): PlyrPickerControlsHelper {
        return this.internalControls.picker as PlyrPickerControlsHelper;
    }

    /**
     * Update time indicators (progress bar, progress time, picker and tooltip)
     * @param absoluteTime selected absolute time
     */
    private updatePlyrTimeControls(absoluteTimeWithOffset: number) {
        PlyrTimeHelper.absoluteTime = absoluteTimeWithOffset;
        const percent: number = PlyrConvertersHelper.getCurrentPlayPercent(PlyrTimeHelper.absoluteTime);

        // Update time in picker only if picker is not being holded
        if (!this.picker().pickerDown) {
            this.setProgressBarElement(percent);
            this.picker().setPicker(percent);
        }

        this.setProgressTimeElement(PlyrTimeHelper.absoluteTime);

        // Don't update data in tooltip when picker is not being overed
        if (this.picker().pickerOvered) {
            const clientRect: DOMRect = this.progressBarWrapper.getBoundingClientRect();

            this.picker().setTooltip({
                percent: PlyrConvertersHelper.getCurrentPlayPercent(PlyrTimeHelper.absoluteTime),
                width: clientRect.width
            });
        }
    }

    /**
     * Find event using custom conditions
     * @param getCorrectFragmentShortData function for calculate correct timestamp on earch loop
     * @param leftTranslation function for select search direction
     * @param getFragmentShortData function for calculate correct timestamp using start and stop bounds (use after general loop)
     * @returns correct timestamp with event
     */
    private findEvent(
        getCorrectFragmentShortData: (curTimestamps: VideoPlayerFragmentShortDate, nextTimestamps: VideoPlayerFragmentShortDate) => VideoPlayerFragmentShortDate,
        leftTranslation: (curTimestamps: VideoPlayerFragmentShortDate) => boolean,
        getFragmentShortData: (start: number, end: number) => VideoPlayerFragmentShortDate,
    ): number {
        let start = 0;
        let end = this.eventsTimestamps.length - 1;
        let resultTimestamp: number;

        while (start <= end) {
            const mid = Math.floor((start + end) / 2);
            const curTimestamps: VideoPlayerFragmentShortDate = this.eventsTimestamps[mid];
            const nextTimestamps: VideoPlayerFragmentShortDate = this.eventsTimestamps[mid + 1];

            if (!nextTimestamps) {
                break;
            }

            const correctFragmentShortData: VideoPlayerFragmentShortDate = getCorrectFragmentShortData(curTimestamps, nextTimestamps);

            if (correctFragmentShortData) {
                resultTimestamp = correctFragmentShortData.startTimestamp;
                break;
            } else if (leftTranslation(curTimestamps)) {
                end = mid - 1;
            } else {
                start = mid + 1;
            }
        }

        const fragmentShortData: VideoPlayerFragmentShortDate = getFragmentShortData(start, end);

        if (fragmentShortData) {
            resultTimestamp = fragmentShortData.startTimestamp;
        }

        return resultTimestamp;
    }

    /**
     * Prepare controls which contains into Plyr Control
     */
    private prepareInternalControls() {
        this.internalControls = {
            picker: new PlyrPickerControlsHelper()
        };
    }

    private prepareHelpers() {
        this.canvasHelper = new PlyrProgressBarControlCanvasHelper();
    }

    /**
     * Set progress time HMTL element
     * @param {number} absoluteTime absolute video player time
     */
    private setProgressTimeElement(absoluteTime: number) {
        if (!absoluteTime) {
            return;
        }

        document.getElementById(PlyrTemplateHelper.PROGRESS_TIME_ID).innerText = new RdeaDate(absoluteTime).getTimeString();
    }

    /**
     * Set current play position in progress bar HTML element
     * @param {number} percent current play position in percent
    */
    private setProgressBarElement(percent: number) {
        const progressBar = document.getElementById(PlyrTemplateHelper.PROGRESS_BAR_ID);
        progressBar.setAttribute('aria-valuenow', percent.toString());
        progressBar.setAttribute('value', percent.toString());
    }

    /**
     * Check and return absolute time which fall into the range timestamps using binary search algorithm
     * @param {number} absoluteTime absolute time in milliseconds
     * @returns {number} absolute time which fall into the range timestamps
     */
    private findFirstAbsoluteTimeFromFragments(
        absoluteTimeWithOffset: number
    ): { correctAbsoluteTimeWithOffset: number, fragmentShortData: VideoPlayerFragmentShortDate } {
        let start = 0;
        let end = this.rangesTimestamps.length - 1;

        while (start <= end) {
            const mid = Math.floor((start + end) / 2);
            const startFragment = this.rangesTimestamps[mid].startTimestamp - TZ_OFFSET_IN_MILLISECONDS;
            const endFragment = this.rangesTimestamps[mid].endTimestamp - TZ_OFFSET_IN_MILLISECONDS;

            if (absoluteTimeWithOffset >= startFragment && absoluteTimeWithOffset <= endFragment) {
                return { correctAbsoluteTimeWithOffset: absoluteTimeWithOffset, fragmentShortData: this.rangesTimestamps[mid] };
            } else if (absoluteTimeWithOffset > endFragment) {
                start = mid + 1;
            } else {
                end = mid - 1;
            }
        }

        if (start === this.rangesTimestamps.length) {
            // If time range stopped after last time range
            return {
                correctAbsoluteTimeWithOffset: this.rangesTimestamps[this.rangesTimestamps.length - 1].startTimestamp - TZ_OFFSET_IN_MILLISECONDS,
                fragmentShortData: this.rangesTimestamps[this.rangesTimestamps.length - 1]
            };
        } else if (end === -1) {
            // If time range stopped before first time range
            return {
                correctAbsoluteTimeWithOffset: this.rangesTimestamps[0].startTimestamp - TZ_OFFSET_IN_MILLISECONDS,
                fragmentShortData: this.rangesTimestamps[0]
            };
        } else if (start !== 0 && end !== -1) {
            // If time range stopped between start and end time ranges
            return {
                correctAbsoluteTimeWithOffset: this.rangesTimestamps[end].startTimestamp - TZ_OFFSET_IN_MILLISECONDS,
                fragmentShortData: this.rangesTimestamps[end]
            };
        }

        return null;
    }

    /**
     * Enable progress bar events listeners (mousemove, mouseleave, progress bar click)
     */
    private enableProgressBarEventListeners() {
        this.progressBarWrapper.addEventListener('mousemove', (e: MouseEvent) => {
            this.loggerService.log(`PLYR PROGRESS BAR - mousemove`, e);

            if (this.picker().pickerDown) {
                return;
            }

            const clientRect: DOMRect = this.progressBarWrapper.getBoundingClientRect();
            this.picker().setTooltip({
                percent: PlyrConvertersHelper.calculatePercentFromProgressBarRect(e.pageX, clientRect),
                width: clientRect.width
            });
        });

        this.progressBarWrapper.addEventListener('mouseleave', (e: MouseEvent) => {
            this.loggerService.log(`PLYR PROGRESS BAR - mouseleave`, e);
            this.picker().setTooltip({ percent: null, width: null, hide: true });
        }, true);

        this.progressBarWrapper.addEventListener('click', (e: MouseEvent) => {
            this.loggerService.log(`PLYR PROGRESS BAR - click`, e);

            const clientRect: DOMRect = this.progressBarWrapper.getBoundingClientRect();
            const percent = PlyrConvertersHelper.calculatePercentFromProgressBarRect(e.pageX, clientRect);

            this.applySelectedTime({ percent });
        });
    }

    /**
     * Enable listeners for internal controls
     */
    private enableInternalControlsListeners() {
        this.picker().addClickListener((type: PlyrPickerControlEventType, pickerPosition: number) => {
            if (type === PlyrPickerControlEventType.STOP_SELECTION) {
                const clientRect: DOMRect = this.progressBarWrapper.getBoundingClientRect();
                const percent = PlyrConvertersHelper.calculatePercentFromProgressBarRect(pickerPosition, clientRect);
                this.applySelectedTime({ percent });
                this.picker().setTooltip({ percent: null, width: null, hide: true });
            }
        });
    }

    /**
     * Set selected time in percent or absolute value and send progress bar click event
     * @param {object} param1 percent - absolute time in percent, absoluteTime - current player time, checkBounds - check timestamps bound
     */
    private applySelectedTime(
        { percent, absoluteTimeWithOffset: absoluteTimeWithOffset }: { percent?: number, absoluteTimeWithOffset?: number }
    ) {
        PlyrTimeHelper.relativeTime = null;

        if (percent !== null && percent !== undefined) {
            absoluteTimeWithOffset = PlyrConvertersHelper.calculateAbsoluteTimeFromPercent(percent);
        }

        const { correctAbsoluteTimeWithOffset: correctAbsoluteTime, fragmentShortData } = this.findFirstAbsoluteTimeFromFragments(absoluteTimeWithOffset);
        absoluteTimeWithOffset = correctAbsoluteTime;

        this.updatePlyrTimeControls(absoluteTimeWithOffset);

        const diff = absoluteTimeWithOffset - (fragmentShortData.startTimestamp - TZ_OFFSET_IN_MILLISECONDS);
        const currentTime = fragmentShortData.startPlayerTime + diff / 1000;

        this.clickListener$.next({
            type: PlyrProgressBarControlEventType.PROGRESS_BAR_CLICK,
            e: currentTime
        });
    }
}
