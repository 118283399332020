import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

export const selectGetAllBatchesState = createSelector(
  intercomModuleSelector,
  (state: IntercomModuleState) => state.putNewBatch
);

export const putNewBatch = createSelector(
  selectGetAllBatchesState,
  state => state
);

export const putNewBatchSuccess = createSelector(
  selectGetAllBatchesState,
  state => state.success
);

export const putNewBatchLoading = createSelector(
  selectGetAllBatchesState,
  state => state.loading
);

export const putNewBatchError = createSelector(
  selectGetAllBatchesState,
  state => state.error
);
