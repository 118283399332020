<app-dialog-wrapper
  [componentName]="data.componentName"
  [title]="data.title"
  [progress]="(popupState$ | async) === 'loading'"
>
  <div class="popup service-connection-delete-popup">
    <app-info-block>
      <img
        class="service-connection-delete-popup-info__image"
        info-block-image
        [src]="resourcesHelper.getResource(resourcePath.INFO_LG) | safeUrl"
        [srcset]="resourcesHelper.getResource(resourcePath.INFO_LG, true) | safeUrl"
      >

      <ng-container info-block-text>
        {{ 'services.popups.connections.flat.delete.popup.message.confirm' | translate:{
          name: countFlats > 1
            ? ('services.popups.connections.flat.delete.popup.message.confirm_flats' | translate)
            : ('services.popups.connections.flat.delete.popup.message.confirm_flat' | translate)
        } }}
        <br>
        <br>
        <strong>
          {{ 'services.popups.connections.flat.delete.popup.message.warning' | translate }}
        </strong>
      </ng-container>
    </app-info-block>

    <div class="popup-actions">
      <button
        mat-raised-button
        color="error"
        class="popup-actions__button"
        (click)="onDelete()"
        [disabled]="(popupState$ | async) === 'loading'"
      >
        {{ 'services.popups.connections.flat.delete.popup.button.delete' | translate }}
      </button>
    </div>
  </div>
</app-dialog-wrapper>
