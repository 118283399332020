import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VideoshotPopupState } from '@app/shared/components/video-player/models';
import { ResolutionBreakpoint, ResolutionService } from '@app/shared/services';
import { Observable } from 'rxjs';
import { DurationString } from '../duration-input/duration-input.models';
import * as moment from 'moment';

@Component({
  selector: 'app-video-player-videoshot-container',
  templateUrl: './video-player-videoshot-container.component.html',
  styleUrls: ['./video-player-videoshot-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoPlayerVideoshotContainerComponent {
  mdWDownBreakpoint$: Observable<boolean> = this.resolution.getBreakpoint(ResolutionBreakpoint.MD_W_DOWN);
  readonly deafultDurationValue: DurationString = '15:00';
  readonly minTimeDuration: DurationString = '00:01';
  readonly maxTimeDuration: DurationString = '30:00';
  
  form: FormGroup = new FormGroup({
    dateFrom: new FormControl<moment.Moment>(null, Validators.required),
    duration: new FormControl<DurationString>(this.deafultDurationValue)
  });
  
  get maxMinutes() { return this.maxTimeDuration.split(':')[0] };
  get maxSeconds() { return this.maxTimeDuration.split(':')[1] };
  
  get durationControl() { return this.form.get('duration') };
  get dateFromControl() { return this.form.get('dateFrom')};
  
  private readonly defaultDurationInSeconds: number = 299;
  private _loading: boolean;
  
  @Input() 
  set loading(loading: boolean) {
    this._loading = loading;
    this.form[loading ? 'disable' : 'enable']();
  }
  get loading(): boolean { return this._loading }

  get minDateMomentFormat() { return moment(this.minDate) }
  get maxDateMomentFormat() { return moment(this.maxDate) }

  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() depthInHours!: number;
  @Input() state: VideoshotPopupState;
  
  @Output() private createVideoshotTask: EventEmitter<{ dateFrom: Date, duration: number }> = new EventEmitter();
  @Output() private downloadVideoshot: EventEmitter<void> = new EventEmitter();
  @Output() private initVideoshotBuilding: EventEmitter<void> = new EventEmitter();

  constructor(
    private resolution: ResolutionService
  ) { }

  onCreateVideoshotTask() {
    const { dateFrom, duration } = this.form.getRawValue();
    this.createVideoshotTask.emit({ dateFrom: dateFrom.toDate(), duration: this.convertDuration(duration) });
  }

  onDownloadVideoshot() {
    this.downloadVideoshot.emit();
  }

  onInitVideoshotBuilding() {
    this.initVideoshotBuilding.emit();
  }

  /**
   * convert duration string mm:ss to seconds
   * @param duration convert duration string mm:ss to seconds
   * @returns duration in seconds
   */
  private convertDuration(duration: string): number {
    if (duration === null) {
      return this.defaultDurationInSeconds;
    }

    const splittedValues: string[] = duration.split('');
    const minutes = Number.parseInt(splittedValues[0] + splittedValues[1], 10);
    const seconds = Number.parseInt(splittedValues[2] + splittedValues[3], 10);

    return (minutes * 60) + seconds;
  }
}
