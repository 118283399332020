import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ConfirmComponentConnectionModule } from '@app/shared/components';
import { CamerasTableContainerModule } from '@app/shared/containers';
import { ServiceCameraPopupModule } from '../../popups';
import { ServiceCameraDeletePopupModule } from '../../popups';
import { ServiceCamerasComponent } from './service-cameras.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        ServiceCameraPopupModule,
        ServiceCameraDeletePopupModule,
        CamerasTableContainerModule,
        ConfirmComponentConnectionModule,
        NgxTranslateModule,
    ],
    declarations: [
        ServiceCamerasComponent
    ],
    exports: [
        ServiceCamerasComponent
    ]
})
export class ServiceCamerasModule { }
