import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { DialogWrapperModule, InfoBlockModule } from '@app/shared/ui';
import { ServiceAbonentsDisconnectPopupContentComponent } from './service-abonents-disconnect-popup-content';
import { ServiceAbonentsDisconnectPopupComponent } from './service-abonents-disconnect-popup.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        InfoBlockModule,
        SharedPipesModule,
        DialogWrapperModule,
        NgxTranslateModule
    ],
    declarations: [
        ServiceAbonentsDisconnectPopupComponent,
        ServiceAbonentsDisconnectPopupContentComponent
    ],
    exports: [
        ServiceAbonentsDisconnectPopupComponent
    ]
})
export class ServiceAbonentsDisconnectPopupModule { }
