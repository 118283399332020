import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';

@Injectable()
export class ServiceWorkerHelper {
  constructor(private swUpdate: SwUpdate) {}

  addUpdateListener() {
    return this.swUpdate.available;
  }

  forceUpdate(): Promise<boolean | void> {
    return new Promise((resolve) => {
      resolve(this.swUpdate.checkForUpdate());
    }).then(() => {
      return this.swUpdate.activateUpdate();
    });
  }
}
