<app-dialog-wrapper
    [componentName]="data.componentName"
    [title]="data.title"
>
    <app-intercom-info-content
        [intercom]="data.body.intercom"
        [gitlabComponentsVersions]="data.body.gitlabComponentsVersions"
        [isIpIntercom]="data.body.isIpIntercom"
        [livenessUrl]="data.body.livenessUrl"
        [pbxOnRda]="data.body.pbxOnRda"
        [rdosLatest]="data.body.rdosLatest"
        [rdaaLatest]="data.body.rdaaLatest"
    ></app-intercom-info-content>
</app-dialog-wrapper>