import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ServicesTypes } from '@app/shared/models';
import { ServiceFacade } from '@app/views/services';
import { ServiceFlatsFilters } from '../service-flats/models';

@Component({
  selector: 'app-service-flats-filters',
  templateUrl: './service-flats-filters.component.html',
  styleUrls: ['./service-flats-filters.component.scss']
})
export class ServiceFlatsFiltersComponent {
  private _flatsFilters: ServiceFlatsFilters = {
    activeAbonent: false,
    activeServices: [],
    blockedAbonent: false,
    emptyFlat: false,
    blockedServices: [],
    stoppedServices: [],
    phone: '',
    rangeStart: null,
    rangeEnd: null,
  };

  @Input() services: ServicesTypes[] = [];

  @Input()
  public set flatsFilters(value) {
    this._flatsFilters = value;
  }

  public get flatsFilters() {
    return this._flatsFilters;
  }
  public serviceTypes = ServicesTypes;

  public filtersHidden = true;
  public readonly numberMask = /^[\d]+$/gm;
  public filterInput: UntypedFormControl = new UntypedFormControl();

  constructor(private serviceFacade: ServiceFacade) {}

  onChangeFiltersVisible(visible: boolean) {
    this.filtersHidden = visible;
  }

  onFilterByActiveServiceType(serviceType: ServicesTypes) {
    const serviceIdx: number = this._flatsFilters.activeServices.findIndex(
      (onlyServiceType: ServicesTypes) => onlyServiceType === serviceType
    );

    if (serviceIdx > -1) {
      this._flatsFilters.activeServices.splice(serviceIdx, 1);
    } else {
      this._flatsFilters.activeServices.push(serviceType);
    }

    const blockedServiceIdx = this._flatsFilters.blockedServices.findIndex(
      (onlyServiceType: ServicesTypes) => onlyServiceType === serviceType
    );

    if (blockedServiceIdx > -1) {
      this._flatsFilters.blockedServices.splice(blockedServiceIdx, 1);
    }

    const stoppedServiceIdx = this._flatsFilters.stoppedServices.findIndex(
      (onlyServiceType: ServicesTypes) => onlyServiceType === serviceType
    );

    if (stoppedServiceIdx > -1) {
      this._flatsFilters.stoppedServices.splice(stoppedServiceIdx, 1);
    }

    this.serviceFacade.filterFlats(this._flatsFilters);
  }

  onFilterByBlockedServiceType(serviceType: ServicesTypes) {
    const serviceIdx: number = this._flatsFilters.blockedServices.findIndex(
      (onlyServiceType: ServicesTypes) => onlyServiceType === serviceType
    );

    if (serviceIdx > -1) {
      this._flatsFilters.blockedServices.splice(serviceIdx, 1);
    } else {
      this._flatsFilters.blockedServices.push(serviceType as ServicesTypes);
    }

    const activeServiceIdx = this._flatsFilters.activeServices.findIndex(
      (onlyServiceType: ServicesTypes) => onlyServiceType === serviceType
    );

    if (activeServiceIdx > -1) {
      this._flatsFilters.activeServices.splice(activeServiceIdx, 1);
    }

    const stoppedServiceIdx = this._flatsFilters.stoppedServices.findIndex(
      (onlyServiceType: ServicesTypes) => onlyServiceType === serviceType
    );

    if (stoppedServiceIdx > -1) {
      this._flatsFilters.stoppedServices.splice(stoppedServiceIdx, 1);
    }

    this.serviceFacade.filterFlats(this._flatsFilters);
  }

  onFilterByStoppedServiceType(serviceType: ServicesTypes) {
    const serviceIdx: number = this._flatsFilters.stoppedServices.findIndex(
      (onlyServiceType: ServicesTypes) => onlyServiceType === serviceType
    );

    if (serviceIdx > -1) {
      this._flatsFilters.stoppedServices.splice(serviceIdx, 1);
    } else {
      this._flatsFilters.stoppedServices.push(serviceType as ServicesTypes);
    }

    const activeServiceIdx = this._flatsFilters.activeServices.findIndex(
      (onlyServiceType: ServicesTypes) => onlyServiceType === serviceType
    );

    if (activeServiceIdx > -1) {
      this._flatsFilters.activeServices.splice(activeServiceIdx, 1);
    }

    const blockedServiceIdx = this._flatsFilters.blockedServices.findIndex(
      (onlyServiceType: ServicesTypes) => onlyServiceType === serviceType
    );

    if (blockedServiceIdx > -1) {
      this._flatsFilters.blockedServices.splice(blockedServiceIdx, 1);
    }

    this.serviceFacade.filterFlats(this._flatsFilters);
  }

  public onSetRangeStartFilter(value: string) {
    this._flatsFilters.rangeStart = Number(value);
    this.serviceFacade.filterFlats(this._flatsFilters);
  }

  public onSetRangeEndFilter(value: string) {
    this._flatsFilters.rangeEnd = Number(value);
    this.serviceFacade.filterFlats(this._flatsFilters);
  }

  public onSetAbonentPhoneFilter(value: string) {
    this._flatsFilters.phone = value;
    this._flatsFilters.emptyFlat = false;
    this.serviceFacade.filterFlats(this._flatsFilters);
  }

  public onSetEmptyFlatFilter() {
    this._flatsFilters.emptyFlat = !this._flatsFilters.emptyFlat;
    this._flatsFilters.phone = '';
    this.serviceFacade.filterFlats(this._flatsFilters);
  }

  public onSetBlockedStatusFilter() {
    this._flatsFilters.blockedAbonent = !this._flatsFilters.blockedAbonent;
    this._flatsFilters.activeAbonent = false;
    this.serviceFacade.filterFlats(this._flatsFilters);
  }

  public onSetActiveStatusFilter() {
    this._flatsFilters.activeAbonent = !this._flatsFilters.activeAbonent;
    this._flatsFilters.blockedAbonent = false;
    this.serviceFacade.filterFlats(this._flatsFilters);
  }

  public get emptyFlat(): boolean {
    return this._flatsFilters.emptyFlat;
  }

  public get phone(): string {
    return this._flatsFilters.phone;
  }
}
