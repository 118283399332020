import { GateWizardContentIntercomsMode } from '../gate-wizard-content';
import { GateWizardPopupStep, GateWizardPopupStepper } from '../models';

export const initialGateWizardSteps: GateWizardPopupStepper[] = [
    {
      text: 'services.gates.gate.wizard.popup.state.step_1',
      complete: false,
      active: false,
      hidden: false,
      disabled: false,
      initialized: false
    },
    {
      text: 'services.gates.gate.wizard.popup.state.step_2',
      complete: false,
      active: false,
      hidden: false,
      disabled: true,
      initialized: false
    },
    {
      text: 'services.gates.gate.wizard.popup.state.step_3',
      complete: false,
      active: false,
      hidden: false,
      disabled: true,
      initialized: false
    },
    {
      text: 'services.gates.gate.wizard.popup.state.step_4',
      complete: false,
      active: false,
      hidden: false,
      disabled: true,
      initialized: false
    },
    {
      text: 'services.gates.gate.wizard.popup.state.step_5',
      complete: false,
      active: false,
      hidden: false,
      disabled: true,
      initialized: false
    },
    {
      text: 'services.gates.gate.wizard.popup.state.step_6',
      complete: false,
      active: false,
      hidden: false,
      disabled: true,
      initialized: true
    }
];

export interface GateWizardPopupState {
    steps: GateWizardPopupStepper[];
    showStepButtons: boolean;
    showMenu: boolean;
    wizardStep: GateWizardPopupStep;
    intercomsStep: GateWizardContentIntercomsMode;
}
