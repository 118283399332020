import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { RdeaDate } from '@app/shared/entities/common';
import { DayOfTheMonthRange } from 'cron-parser';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';

import { CronDateInputService } from './services';

@Component({
  selector: 'app-cron-date-input',
  templateUrl: './cron-date-input.component.html',
  styleUrls: ['./cron-date-input.component.scss'],
  providers: [
    CronDateInputService,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CronDateInputComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CronDateInputComponent
  implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {
  public readonly days: number[] = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28
  ];

  @Input() public readonly label?: string;
  @Input() public readonly timeLabel?: string = 'Время UTC';
  @Input() public readonly dayLabel?: string = 'Число';
  @Input() public readonly placeholder?: string;
  @Input() set disabledState(state: boolean) {
    this.setDisabledState(state);
  }

  @Input() set editMode(mode: boolean) {
    this._editMode = mode;

    if (this.lastDayCheckedControl.value) {
      this.dayControl.disable({ emitEvent: false });
    }
  }
  get editMode(): boolean {
    return this._editMode;
  }

  public readonly dayControl: UntypedFormControl = new UntypedFormControl();
  public readonly timeControl: UntypedFormControl = new UntypedFormControl();
  public readonly lastDayCheckedControl: UntypedFormControl = new UntypedFormControl();
  public readonly disabledForm$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public value: string;
  public savedDay: DayOfTheMonthRange;
  public filteredDays$: Observable<number[]>;
  public exampleDate: RdeaDate;
  public infoTitle: string;

  private readonly defaultValue: string = '0 0 9 26 * ?';
  private readonly onDestroy$: Subject<void> = new Subject();
  private _invalid = false;
  private onChange = (value: any) => {};
  private onTouched = () => {};
  private _editMode: boolean;

  constructor(private cronDateInputService: CronDateInputService) {
    this.infoTitle = 'Вручную можно задать только даты с 1 до 28. Если хотите выставлять счет в последний день месяца, нажмите соответствующую галочку. Время выставляется по UTC+0.';
    this._editMode = true;
  }

  ngOnInit(): void {
    this.filteredDays$ = this.dayControl.valueChanges.pipe(
      takeUntil(this.onDestroy$),
      startWith<string>(''),
      map((value: number | string) => (typeof value === 'number' ? value : '')),
      map((filter: number | string) => this.filter(filter))
    );

    this.lastDayCheckedControl.valueChanges
      .pipe(
        takeUntil(this.onDestroy$),
        tap((lastDayChecked: boolean) => {
          if (lastDayChecked) {
            this.savedDay = this.dayControl.value ?? 1;
            this.dayControl.setValue(null, { emitEvent: false });
            this.dayControl.disable({ emitEvent: false });
            return;
          }

          if (!this.disabledForm$.getValue()) {
            this.dayControl.enable({ emitEvent: false });
          }
          this.dayControl.setValue(this.savedDay, { emitEvent: false });
        })
      )
      .subscribe();

    combineLatest([
      this.dayControl.valueChanges,
      this.timeControl.valueChanges,
      this.lastDayCheckedControl.valueChanges
    ])
      .pipe(
        takeUntil(this.onDestroy$),
        tap(
          ([day, time, lastDayChecked]: [
            DayOfTheMonthRange,
            string,
            boolean
          ]) => {
            if (day > 28) {
              this.dayControl.setValue(28);
              return;
            }
            if (
              !lastDayChecked &&
              this.savedDay !== null &&
              (this.savedDay > 28 || this.savedDay < 1)
            ) {
              this.value = null;
            } else if (
              !lastDayChecked &&
              !this.savedDay &&
              (day > 28 || day < 1)
            ) {
              this.value = null;
            } else {
              this.value = this.cronDateInputService.convertExpression(
                time,
                lastDayChecked
                  ? 'L'
                  : day !== null && day !== 'L'
                  ? day
                  : this.savedDay
              );
            }

            this._invalid = this.value === null;

            if (!lastDayChecked) {
              this.savedDay = null;
            }

            this.exampleDate = this.cronDateInputService.getLocalDate(this.value);
            this.onChange(this.value);
            this.onTouched();
          }
        )
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.disabledForm$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((state) => {
        state ? this.disableForm() : this.enableForm();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public get invalid(): boolean {
    return this._invalid;
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  public writeValue(value: string): void {
    this.value = value || this.defaultValue;

    try {
      const { day, hour, minute } = this.cronDateInputService.parseExpression(
        this.value
      );

      this.lastDayCheckedControl.setValue(day === 'L');

      if (this.lastDayCheckedControl.value) {
        this.savedDay = 1;
      }

      this.dayControl.setValue(day);
      this.timeControl.setValue(RdeaDate.toHHMM(hour, minute));
    } catch (error) {
      console.error(`--- writeValue error: ${error}`);
    }

    this.onChange(this.value);
    this.onTouched();
  }

  public setDisabledState(disabled: boolean): void {
    this.disabledForm$.next(disabled);
  }

  public onBlur(): void {
    this.onTouched();
  }

  public filter(filter: string | number): number[] {
    if (!filter) {
      this.days.slice();
    }

    return this.days.filter(
      (day: number) =>
        day.toString().toLowerCase().indexOf(filter.toString().toLowerCase()) >=
        0
    );
  }

  public onStopPropagation(event: KeyboardEvent): void {
    if (event.code === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private disableForm(): void {
    this.dayControl.disable();
    this.timeControl.disable();
  }

  private enableForm(): void {
    this.dayControl.enable();
    this.timeControl.enable();
  }
}
