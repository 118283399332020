<div class="login-form" [ngClass]="{
  'vivo': environment.vivotec,
  'rosdomofon': !environment.vivotec
}">
  <div class="login-form-wrapper">
    <div class="login-form-wrapper-content">
      <form
        class="login-form-wrapper-content-form"
        [formGroup]="form"
        (ngSubmit)="onSubmit()"
      >
        <div class="login-form-wrapper-content-form-header">
          <img src="{{logoImg}}" alt="error">
        </div>
        <h1 class="login-form-wrapper-content-form__title">
          {{ 'login.page.content.title' | translate }}
        </h1>
        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="{{ 'login.page.content.placeholder.login' | translate }}"
            type="text"
            autocomplete="off"
            formControlName="username"
            required
          ></mat-form-field>

        <mat-form-field appearance="outline">
          <input
            matInput
            placeholder="{{ 'login.page.content.placeholder.password' | translate }}"
            type="password"
            formControlName="password"
            autocomplete="current-password"
            required
          ></mat-form-field>
        <div class="login-form-wrapper-content-form-action">
          <div class="login-form-wrapper-content-form-action-save">
            <mat-checkbox formControlName="saveLogin"></mat-checkbox>
            <span>
              {{ 'login.page.content.field.remember' | translate }}
            </span>
          </div>
          <div class="login-form-wrapper-content-form-action-reset">
            <a (click)="onResetPassword()">
              {{ 'login.page.content.link.forgot' | translate | lowercase }}
            </a>
          </div>
        </div>

        <button
          class="login-form-wrapper-content-form__button"
          mat-raised-button
          type="submit"
          id="loginButton"
          [disabled]="loading"
        >
          <app-component-loader
            *ngIf="loading; else btnText"
            [whiteCircle]="true"
          ></app-component-loader>
          <ng-template #btnText>
            {{ 'login.page.content.button.submit' | translate }}
          </ng-template>
        </button>

        <div class="login-form-wrapper-content-form-footer">
          <div *ngIf="!environment.vivotec">
            <p class="login-form-wrapper-content-form-footer__text">
              {{ 'login.page.content.message.want_become_our_partner' | translate }}
            </p>
            <a class="login-form-wrapper-content-form-footer__link" [routerLink]="'/partners/request'">
              {{ 'login.page.content.link.signup' | translate }}
            </a>
          </div>
          <div *ngIf="!environment.vivotec">
            <img src="assets/logo/logo-ic-sk.svg" alt="error">
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<ng-content select="[snowflakes]"></ng-content>
