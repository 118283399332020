import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { CheckboxComponent } from './checkbox.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        NgxTranslateModule,
    ],
    declarations: [CheckboxComponent],
    exports: [CheckboxComponent]
})
export class CheckboxModule { }
