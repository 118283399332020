import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { IpRdaRequest, RdaNewResponse, RdaResponse, RdaUpdateRequest } from '@app/shared/entities/rd/rda';
import { Address, PagedResponse, TranslationTuningRequest, TranslationTuningResponse } from '@app/shared/models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';
import { ConnectRdaToAddressRequest } from '@app/views/intercom/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RdaApiService extends ApiService {
    constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
        super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1', 'v2']);
    }

    getAdaptersList(page: number = 0, size: number = 10, inactive = true, ipType: boolean, mode: string, address: string, sort = 'createdAt,desc'): Observable<PagedResponse<RdaResponse>> {
        let params = new HttpParams()
            .append('page', page.toString())
            .append('size', size.toString());

        if (inactive !== null && inactive !== undefined) { params = params.append('inactive', String(inactive)); }
        if (ipType !== null && ipType !== undefined) { params = params.append('ip_type', String(ipType)); }
        if (sort !== null && sort !== undefined) { params = params.append('sort', sort); }
        if (address !== null && address !== undefined) { params = params.append('address', address); }
        if (mode !== null && mode !== undefined) { params = params.append('mode', mode); }

        return this.get('/rdas', 2, { params });
    }

    getAdaptersListV1(inactive?: boolean): Observable<RdaResponse[]> {
        const params = {} as any;
        if (inactive) { params.inactive = inactive; }

        return this.get('/rdas', 1, { params });
    }

    getNewIntercoms(page: number = 0, size: number = 10, uid?: string): Observable<PagedResponse<RdaNewResponse>> {
        const params = { page, size } as any;
        if (uid) { params.uid = uid; }

        return this.get('/rdas/all/?sort=createdAt,desc&ip_type=false', 1, { params: params });
    }

    getTranslationTunings(intercomUid: string): Observable<TranslationTuningResponse[]> {
        return this.get<TranslationTuningResponse[]>(`/rdas/${intercomUid}/translation_tunings`, 1);
    }

    createTranslationTuning(intercomUid: string, request: TranslationTuningRequest): Observable<TranslationTuningResponse> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.post<TranslationTuningRequest, TranslationTuningResponse>(`/rdas/${intercomUid}/translation_tunings`, 1, request, { headers });
    }

    updateAdapter(rdaUid: string, request: RdaUpdateRequest): Observable<boolean> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        return this.patch(`/rdas/${rdaUid}`, 1, request, { headers }).pipe(map(() => true));
    }

    updateAdapters(rdaUid: string[], appliedParameters: RdaUpdateRequest): Observable<boolean> {
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this.patch(`/rdas/`, 1, {uids: rdaUid, appliedParameters}, { headers }).pipe(map(() => true));
    }

    getAdapter(rdaUid: string): Observable<RdaResponse> {
      const headers = new HttpHeaders().set('Content-Type', 'application/json');
      return this.get<RdaResponse>(`/rdas/${rdaUid}`, 1, { headers });
    }

    createIpRda(request: IpRdaRequest): Observable<RdaResponse> {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.post<IpRdaRequest, RdaResponse>(`/rdas/ip_rda`, 1, request, { headers });
    }

    addAddress(rdaUid: string, data: Address): Observable<boolean> {
        const path = `/rdas/${rdaUid}/address`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.post<ConnectRdaToAddressRequest, void>(path, 1, data, { headers })
            .pipe(map(() => true));
    }

    deleteAddress(adapter: RdaResponse): Observable<boolean> {
        const path = `/rdas/${adapter.uid}/address`;
        const headers = new HttpHeaders().set('Content-Type', 'application/json');

        return this.delete<void>(path, 1, { headers }).pipe(map(() => true));
    }

    getRdaByUid(rdaUid: string): Observable<{ content: RdaResponse[] }> {
      return this.get<{ content: RdaResponse[] }>('/rdas/all', 1, { params: {uid: rdaUid} });
    }

    openDoor(uid: string, rele: number): Observable<void> {
      const path = `/rdas/${uid}/activate_key`;
      const headers = new HttpHeaders().set('Content-Type', 'application/json');

      return this.post(path, 1, {
        rele: rele
      }, { headers });
    }
}
