import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-payments-upload-buttons',
  templateUrl: './payments-upload-buttons.component.html',
  styleUrls: ['./payments-upload-buttons.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentsUploadButtonsComponent {
  @Input() isLoading: boolean;
  @Input() isLoadingDownload: boolean;
  @Input() isLoadingUpload: boolean;

  @Output() onDownload = new EventEmitter<void>();
  @Output() onUpload = new EventEmitter<void>();
}
