import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {
  IntercomType,
  IntercomTypeGeneratorUrls,
  IntercomTypeLinkGenerator
} from '@app/shared/entities/rd';

@Component({
  selector: 'app-ip-sputnik-generate-urls-container',
  templateUrl: './ip-sputnik-generate-urls-container.component.html',
  styleUrls: ['./ip-sputnik-generate-urls-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IpSputnikGenerateUrlsContainerComponent implements OnInit {
  @Input() isUIDGenerateMode: boolean;
  @Input() intercom: IntercomType;
  @Input() params: string[] = ['bearerToken', 'livenessUrl', 'openDoorUrl'];

  public formGroup: UntypedFormGroup;
  private _link: string;

  @Output() private urlsGenerated: EventEmitter<
    Partial<IntercomTypeGeneratorUrls>
  > = new EventEmitter();

  constructor(private formBuilder: UntypedFormBuilder) {
    this.link =
      'https://wiki.rosdomofon.com/ru/Поддержка/Техникам_и_администраторам/IP_домофоны/Подключение_IP_домофона_к_облаку_РосДомофон/Подключение_Sputnik';
  }

  ngOnInit(): void {
    this.initForm();
  }

  get link(): string {
    return this._link;
  }

  set link(newLink: string) {
    this._link = newLink;
  }

  public onSubmit(): void {
    const urls: Partial<IntercomTypeGeneratorUrls> =
      IntercomTypeLinkGenerator.generateIntercomTypeLinks(
        this.intercom,
        this.formGroup.getRawValue(),
        this.params
      );

    this.urlsGenerated.emit(urls);
  }

  private initForm(): void {
    this.formGroup = this.formBuilder.group({
      ip: new UntypedFormControl(null, [Validators.required])
    });
    if (this.isUIDGenerateMode) {
      this.formGroup.addControl(
        'bearerToken',
        new UntypedFormControl(null, [Validators.required])
      );
    }
  }
}
