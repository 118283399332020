import { Component, Inject } from '@angular/core';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper/dialog-wrapper-data.model';
import { ConfirmComponentConnectionData } from '../confirm-component-connection.model';

@Component({
  selector: 'app-confirm-component-connection-bottom-sheet',
  templateUrl: './confirm-component-connection-bottom-sheet.component.html',
  styleUrls: ['./confirm-component-connection-bottom-sheet.component.scss']
})
export class ConfirmComponentConnectionBottomSheetComponent {
  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: DialogWrapperData<ConfirmComponentConnectionData, boolean>
  ) { }

  onConfirm(confirmed: boolean) {
    this.data.submit(confirmed);
  }
}
