import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NavbarLink } from '@app/shared/ui';
import { TranslateService } from '@ngx-translate/core';
import { PaymentsUploadIndexComponent } from '@app/views/payments';
import { PaymentsUploadPageType } from '@app/views/payments/models/payments-upload-page';

@Component({
  selector: 'app-payments-upload',
  templateUrl: './payments-upload.component.html',
  styleUrls: ['./payments-upload.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaymentsUploadComponent implements OnInit {
  @ViewChild('childIndex', {static: false}) childIndex: PaymentsUploadIndexComponent;

  public readonly pageType: typeof PaymentsUploadPageType = PaymentsUploadPageType;

  public readonly links: NavbarLink[] = [
    { name: this.pageType.INDEX, text: this.translate.instant('payments.upload.tab.index') },
    { name: this.pageType.HISTORY, text: this.translate.instant('payments.upload.tab.history') }
  ];

  public activeLink: string;

  constructor(
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.onChangeTab(0);
  }

  onChangeTab(idx: number) {
    this.activeLink = this.links[idx].name;
    this.changeDetectorRef.detectChanges();
  }
}
