import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { IpIntercomGenerateUrlsContainerComponent } from './ip-intercom-generate-urls-container.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        NgxTranslateModule,
    ],
    declarations: [IpIntercomGenerateUrlsContainerComponent],
    exports: [IpIntercomGenerateUrlsContainerComponent]
})
export class IpIntercomGenerateUrlsContainerModule { }
