import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Camera, IntercomType, RdaResponse } from '@app/shared/entities/rd';
import { Address, LocationResponse, RdeaStepperStep } from '@app/shared/models';

@Component({
  selector: 'app-service-wizard-popup-content',
  templateUrl: './service-wizard-popup-content.component.html',
  styleUrls: ['./service-wizard-popup-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceWizardPopupContentComponent {
  @Input() steps: RdeaStepperStep[];
  @Input() wizardStep: number;
  @Input() showMenu: boolean;
  @Input() showStepButtons: boolean;

  @Input() loading: boolean;
  @Input() serviceId: number;
  @Input() cameras: Camera[];
  @Input() addresses: Address[];
  @Input() intercoms: RdaResponse[];
  @Input() onvif: boolean;
  @Input() intercomTypes: IntercomType[];
  @Input() camerasLocations: LocationResponse[];

  getNextStep(endStep: number): number {
    if (
      this.wizardStep === null || this.wizardStep === undefined ||
      this.wizardStep === endStep
    ) {
      return null;
    }

    let wizardStep: number = this.wizardStep;

    do {
      wizardStep++;

      if (!this.steps[wizardStep]) {
        break;
      }

      if (!this.steps[wizardStep]?.disabled && !this.steps[wizardStep]?.hidden) {
        break;
      }
    } while (wizardStep < endStep + 1);

    if (wizardStep > endStep) {
      return null;
    }

    return wizardStep;
  }

  getPreviousStep(startStep: number = 0): number {
    let wizardStep: number = this.wizardStep;

    if (!wizardStep) {
      return null;
    }

    do {
      wizardStep--;

      if (!this.steps[wizardStep]) {
        break;
      }

      if (!this.steps[wizardStep]?.disabled && !this.steps[wizardStep]?.hidden) {
        break;
      }
    } while (wizardStep > startStep);

    if (wizardStep < startStep) {
      return null;
    }

    return wizardStep;
  }
}
