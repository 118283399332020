import {ServicesTypes} from '@app/shared/models';
import {Injectable} from '@angular/core';
import {ServiceHelperService} from '@app/shared/entities/rd';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServiceFlatsHelperService extends ServiceHelperService {

  constructor(
    translate: TranslateService
  ) {
    super(translate);
  }

  getServiceNameTypeText(serviceType: ServicesTypes): string {
    switch (serviceType) {
      case ServicesTypes.SOFTWARE_INTERCOM:
        return this.translate.instant('enum.type.software_intercom');
      case ServicesTypes.HARDWARE_INTERCOM:
        return this.translate.instant('enum.type.hardware_intercom_manager');
      case ServicesTypes.HOUSE_CHAT:
        return this.translate.instant('enum.type.house_chat');
      case ServicesTypes.VIDEO_SURVEILLANCE:
        return this.translate.instant('enum.type.video_surveillance');
      case ServicesTypes.GATE:
        return this.translate.instant('enum.type.gate');
      default:
        return '';
    }
  }
}
