import { createSelector } from '@ngrx/store';
import { AbonentsModuleState } from '../reducers/reducers';
import { abonentsModuleSelector } from './abonents-module.state';

export const abonentPageState = createSelector(
    abonentsModuleSelector,
    (state: AbonentsModuleState) => state.abonentPage
);

export const abonentPageOwnerState = createSelector(
    abonentPageState,
    state => ({
        delegations: state.delegations,
        signUps: state.signUps,
        accounts: state.accounts
    })
);

export const abonentPageSharedState = createSelector(
    abonentPageState,
    state => ({
        delegations: state.delegations,
        signUps: state.signUps,
        accounts: state.accounts
    })
);

export const abonentPageInfoState = createSelector(
    abonentPageState,
    state => state.abonent
);

export const abonentPageVoipTokensState = createSelector(
    abonentPageState,
    state => state.voipTokens
);

export const abonentPageStatState = createSelector(
    abonentPageState,
    state => state.stat
);

export const abonentPageAccountServicesState = createSelector(
    abonentPageState,
    state => state.accountServices
);

export const abonentPageSelectedVoipToken = createSelector(
    abonentPageState,
    state => state.selectedVoipToken
);

export const abonentPageSelectedDateDiff = createSelector(
    abonentPageState,
    state => state.selectedDateDiff
);

export const abonentPageStateChat = createSelector(
    abonentPageState,
    state => state.chat
);

export const abonentPageStateChatLoading = createSelector(
    abonentPageState,
    state => state.chatLoading
);

export const abonentPageStateSendMessageLoading = createSelector(
    abonentPageState,
    state => state.sendMessageLoading
);
