import { Injectable, OnDestroy } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { DialogWrapperStateHelper } from '@app/shared/ui';
import { takeUntil } from 'rxjs/operators';
import { Camera } from '@app/shared/entities/rd';
import { AddServiceCameraSuccess, ConnectServiceCameraFailure, ConnectServiceCameraSuccess } from '@app/views/services/store/actions/services-cameras.actions';
import { ServicesActionTypes } from '@app/views/services/store/actions/services-action-types.actions';

@Injectable()
export class CameraAlreadyExistsPopupService extends DialogWrapperStateHelper<'Failure' | 'Success' | Camera> implements OnDestroy {

  constructor(private actions$: Actions) {
    super();
    this.initStateListeners();
  }

  ngOnDestroy(): void {
    this.destroy();
  }

  private initStateListeners(): void {
    this.actions$
      .pipe(
        ofType<
          | ConnectServiceCameraSuccess
          >(...[
          ServicesActionTypes.ConnectServiceCameraSuccess
        ]),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => this.state = 'Success');

    this.actions$
      .pipe(
        ofType<
          | ConnectServiceCameraFailure
          >(...[
          ServicesActionTypes.ConnectServiceCameraFailure
        ]),
        takeUntil(this.onDestroy$)
      )
      .subscribe(() => this.state = 'Failure');

    this.actions$
      .pipe(
        ofType<AddServiceCameraSuccess>(ServicesActionTypes.AddServiceCameraFailure), takeUntil(this.onDestroy$)
      )
      .subscribe((response) => this.state = response.camera);

  }
}
