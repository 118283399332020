import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { SharedDirectivesModule } from '@app/shared/directives';
import { AddressSearchSelectModule } from '../address-search-select';
import { AddressInputComponent } from './address-input.component';
import { CustomAddressComponent } from './custom-address';
import { DadataAddressComponent } from './dadata-address';
import { KzPostAddressComponent } from './kz-post-address';
import { YandexAddressComponent } from './yandex-address';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        SharedDirectivesModule,
        AddressSearchSelectModule,
        NgxTranslateModule
    ],
    declarations: [
        CustomAddressComponent,
        DadataAddressComponent,
        KzPostAddressComponent,
        YandexAddressComponent,
        AddressInputComponent
    ],
    exports: [
        AddressInputComponent
    ]
})
export class AddressInputModule { }
