<div class="payments-upload-history">
  <div
    *ngIf="items?.length"
    class="result"
  >
    <mat-table
      *ngIf="items?.length > 0"
      [dataSource]="items"
      class="table"
    >
      <mat-header-row
        [style.display]="resolution.isTablet ? 'none' : 'flex'"
        *matHeaderRowDef="tableColumns"
      />

      <mat-row
        *matRowDef="let row; columns: tableColumns;"
      />

      <ng-container matColumnDef="createdAt">
        <mat-header-cell *matHeaderCellDef>
          {{ 'payments.upload.history.field.created_at' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.createdAt | date: 'dd.MM.yyyy' }}
        </mat-cell>
      </ng-container>


      <ng-container matColumnDef="email">
        <mat-header-cell *matHeaderCellDef>
          {{ 'payments.upload.history.field.email' | translate }}
        </mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element.email }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="file">
        <mat-header-cell *matHeaderCellDef>
          {{ 'payments.upload.history.field.file' | translate }}
        </mat-header-cell>
        <mat-cell
          *matCellDef="let element"
          (click)="onDownload(element.id)"
        >
          {{ element.fileName }}
        </mat-cell>
      </ng-container>


    </mat-table>

  </div>
  <div
    *ngIf="!items?.length"
    class="empty"
  >
    <div
      *ngIf="!isLoading"
      class="icon"
    ></div>

    <div
      *ngIf="isLoading"
      class="spinner-wrapper"
    >
      <mat-spinner [diameter]="32" />
    </div>

    <div
      *ngIf="!isLoading"
      class="text"
    >
      {{ 'payments.upload.history.message.empty' | translate }}
    </div>
  </div>
</div>
