import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';

import { FlatTariffComponent } from './flat-tariff.component';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [FlatTariffComponent],
  exports: [FlatTariffComponent]
})
export class FlatTariffModule {}
