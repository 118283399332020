import { Pipe, PipeTransform } from '@angular/core';
import { CronDateInputService } from '@app/shared/components';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'environments/environment';

@Pipe({
  name: 'paymentInstruction'
})
export class PaymentInstructionPipe implements PipeTransform {
  constructor(
    private cronDateInput: CronDateInputService,
    private translate: TranslateService
  ) {}

  transform(cronDate: string): string {
    const localDate = this.cronDateInput.getLocalDate(cronDate,);

    if (localDate?.getDate() > 28) {
      return this.translate.instant('enum.payment_instruction.last_day_of_month');
    }

    return new Date(localDate).toLocaleDateString(environment.locale, {
      month: 'long',
      day: 'numeric'
    });
  }
}
