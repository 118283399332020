<app-dialog-wrapper
  [title]="data.title"
  [progress]="(state$ | async) === 'loading'"
  [componentName]="data.componentName"
>
<app-abonent-connection-tool
    class="popup"
    [phone]="data.body.phone"
    [flatId]="data.body.flatId"
    [noPhone]="data.body.noPhone"
    [addresses]="data.body.addresses"
    [flatNumber]="data.body.flatNumber"
    [flatList]="data.body.flatList"
    [virtualFlat]="data.body.virtualFlat"
    [loading]="(state$ | async) === 'loading'"
    [defaultService]="data.body.defaultService"
    [hasPhysicalTubeHidden]="data.body.hasPhysicalTubeHidden"
    [hasPhysicalTubeDefault]="data.body.hasPhysicalTubeDefault"
    [servicesSelectionRequired]="data.body.servicesSelectionRequired"
    [abonentAndAccountLoadingForced]="data.body.abonentAndAccountLoadingForced"
    (submitConnections)="onSubmitConnections($event.request)"
  ></app-abonent-connection-tool>
</app-dialog-wrapper>
