import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {UserRoleType} from '@app/core/models';
import {PermissionsService} from '@app/security/permissions';
import {CreateServicePopupComponent} from '@app/shared/components';
import {
  LoaderService,
  LocalStorageCompanyKey,
  LocalStorageDashboardKey,
  LocalStorageHelper,
  ResourcePath
} from '@app/shared/entities/common';
import {ServiceCreateRequest} from '@app/shared/entities/rd';
import {Constants} from '@app/shared/helpers';
import {ServicesTypes} from '@app/shared/models/services-types';
import {DialogWrapperData} from '@app/shared/ui';
import {
  DashboardCamerasCounts,
  DashboardGatesCounts,
  DashboardRdasCounts,
  DashboardSoftwareIntercomsCounts,
  DashboardVideoSurveillanceCounts,
  RdosImage
} from '@app/views/dashboard/models';
import {DashboardFacade} from '@app/views/dashboard/store';
import {Observable} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {environment} from 'environments/environment';
import {RequestsService} from '@app/shared/services';
import {DialogService} from 'primeng/dynamicdialog';
import {
  DashboardWarningComponent
} from '@app/views/dashboard/components/dashboard-page/dashboard-warning/dashboard-warning.component';
import {DynamicDialogRef} from 'primeng/dynamicdialog/dynamicdialog-ref';
import { SignUpStatus } from '@app/shared/models/sign-ups/sign-ups-statuses.enum';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
  providers: [DialogService]
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  readonly userRoleType: typeof UserRoleType = UserRoleType;
  readonly serviceType: typeof ServicesTypes = ServicesTypes;
  readonly resourcePath: typeof ResourcePath = ResourcePath;
  public readonly environment = environment;

  inn: string;
  managerEmail: string;
  rdosImages$: Observable<RdosImage[]>;
  rdasCounts$: Observable<DashboardRdasCounts>;
  gatesCounts$: Observable<DashboardGatesCounts>;
  camerasCounts$: Observable<DashboardCamerasCounts>;
  videoSurveillanceCounts$: Observable<DashboardVideoSurveillanceCounts>;
  softwareIntercomsCounts$: Observable<DashboardSoftwareIntercomsCounts>;
  dynamicDialogRef: DynamicDialogRef = null;

  constructor(
    private dialog: MatDialog,
    private loaderService: LoaderService,
    private permissions: PermissionsService,
    private dashboardFacade: DashboardFacade,
    private translate: TranslateService,
    private requestsService: RequestsService,
    private primeDialogService: DialogService,
  ) { }

  ngOnInit() {
    this.initStore();
    this.loadCounts();
    this.prepareDateForBlockedUser();
    this.checkDisconnectionRequests();
    this.loaderService.loaderState = { state: false };
  }

  ngOnDestroy(): void {
    this.dynamicDialogRef?.destroy();
  }

  onCreateService(serviceType: ServicesTypes) {
    const data: DialogWrapperData<
      { serviceType: ServicesTypes },
      { request: ServiceCreateRequest }
    > = {
      title: this.translate.instant('dashboard.page.create.service.title'),
      componentName: `Create${serviceType}`,
      body: { serviceType },
      submit: (event: { request: ServiceCreateRequest }) =>
        this.dashboardFacade.createService(serviceType, event.request)
    };

    this.dialog.open(CreateServicePopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  private initStore() {
    this.rdosImages$ = this.dashboardFacade.getRdosImages$;
    this.camerasCounts$ = this.dashboardFacade.getCamerasCounts$;
    this.rdasCounts$ = this.dashboardFacade.getRdasCounts$;
    this.gatesCounts$ = this.dashboardFacade.getGatesCounts$;
    this.softwareIntercomsCounts$ =
      this.dashboardFacade.getSoftwareIntercomsCounts$;
    this.videoSurveillanceCounts$ =
      this.dashboardFacade.getVideoSurveillanceCounts$;

    this.dashboardFacade.loadValuesFromLocalStorage();
  }

  private loadCounts() {
    if (this.permissions.someRoleContains([UserRoleType.ROLE_MANUFACTURER, UserRoleType.ROLE_ADMINISTRATOR, UserRoleType.ROLE_OWNER])) {
      this.dashboardFacade.getRdosImages();
    }

    if (this.permissions.someRoleContains([UserRoleType.ROLE_ENGINEER, UserRoleType.ROLE_ADMINISTRATOR, UserRoleType.ROLE_OWNER])) {
      this.dashboardFacade.getRdasCounts();
    }

    if (
      this.permissions.someRoleContains([
        UserRoleType.ROLE_ENGINEER,
        UserRoleType.ROLE_ENGINEER_CAMERAS,
        UserRoleType.ROLE_ADMINISTRATOR,
        UserRoleType.ROLE_OWNER
      ])
    ) {
      this.dashboardFacade.getCamerasCounts();
    }

    if (
      this.permissions.someRoleContains([
        UserRoleType.ROLE_ENGINEER,
        UserRoleType.ROLE_ENGINEER_ENTRANCES,
        UserRoleType.ROLE_ADMINISTRATOR,
        UserRoleType.ROLE_OWNER
      ])
    ) {
      this.dashboardFacade.getSoftwareIntercomsCounts();
    }

    if (
      this.permissions.someRoleContains([
        UserRoleType.ROLE_ENGINEER,
        UserRoleType.ROLE_ENGINEER_VIDEO_SURVEILLANCE,
        UserRoleType.ROLE_ADMINISTRATOR,
        UserRoleType.ROLE_OWNER
      ])
    ) {
      this.dashboardFacade.getVideoSurveillanceCounts();
    }

    if (
      this.permissions.someRoleContains([
        UserRoleType.ROLE_ENGINEER,
        UserRoleType.ROLE_ENGINEER_GATES,
        UserRoleType.ROLE_ADMINISTRATOR,
        UserRoleType.ROLE_OWNER
      ])
    ) {
      this.dashboardFacade.getGatesCounts();
    }
  }

  private prepareDateForBlockedUser() {
    if (this.permissions.someRoleContains([UserRoleType.BLOCKED_USER])) {
      this.inn = LocalStorageHelper.getItem(LocalStorageCompanyKey.INN);
      this.managerEmail = LocalStorageHelper.getItem(
        LocalStorageCompanyKey.MANAGER_EMAIL
      );
    }
  }

  private checkDisconnectionRequests(): void {
    const isAlreadyShow = LocalStorageHelper.getItem(LocalStorageDashboardKey.DASHBOARD_DISCONNECTION_REQUESTS);
    if (isAlreadyShow === false || isAlreadyShow === null) {
      this.requestsService.getSignUpsV2List(
        0, 100, { 
          signUpType: 'DISCONNECTION', 
          status: [SignUpStatus.UNPROCESSED, SignUpStatus.PROCESSED, SignUpStatus.WAIT_ABONENT_CONFIRMATION]
        }
      ).subscribe((response) => {
        if (response.content?.length) {
          this.dynamicDialogRef = this.primeDialogService.open(
            DashboardWarningComponent,
            {
              header: 'Внимание!',
              width: '630px',
              data: {}
            }
          );
        }
      });
    }
  }
}
