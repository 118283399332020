<app-dialog-wrapper
  [title]="data.title"
  [progress]="(status$ | async) === 'loading'"
  [componentName]="data.componentName"
>
<app-abonent-connection-tool
    class="popup"
    [phone]="data.body.phone"
    [flatId]="data.body.flatId"
    [addresses]="data.body.addresses"
    [flatNumber]="data.body.flatNumber"
    [flatList]="data.body.flatList"
    [hasPhysicalTubeDefault]="data.body.hasPhysicalTube"
    [servicesSelectionRequired]="true"
    [loading]="(status$ | async) === 'loading'"
    (submitConnections)="onSubmitConnections($event.request)"
  ></app-abonent-connection-tool>
</app-dialog-wrapper>
