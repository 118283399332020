<div class="service-abonents-disconnect-popup-content">
  <div class="service-abonents-disconnect-popup-content-data">
    <div class="service-abonents-disconnect-popup-content-data-item">
      <div class="service-abonents-disconnect-popup-content-data-item-info">
        <p class="service-abonents-disconnect-popup-content-data-item-info__text">
          {{ owner.phone.toString() | phone }}
        </p>

        <p class="service-abonents-disconnect-popup-content-data-item-info__description">
          {{ 'services.popups.connections.abonents.content.general' | translate }}
        </p>
      </div>
    </div>
  </div>

  <div class="service-abonents-disconnect-popup-content-data" *ngIf="sharedAbonents?.length > 0">
    <div
      class="service-abonents-disconnect-popup-content-data-item"
      *ngFor="let sharedAbonent of sharedAbonents; let idx = index"
    >
      <div class="service-abonents-disconnect-popup-content-data-item-info">
        <p class="service-abonents-disconnect-popup-content-data-item-info__text">
          {{ sharedAbonent.number.toString() | phone }}
        </p>

        <p class="service-abonents-disconnect-popup-content-data-item-info__description">
          {{ 'services.popups.connections.abonents.content.family' | translate }}
        </p>
      </div>

      <div class="service-abonents-disconnect-popup-content-data-item-actions-block">
        <button
          mat-fab
          color="error"
          [disabled]="loading"
          class="service-abonents-disconnect-popup-content-data-item-actions-block__button"
          (click)="onDisconnectSharedAbonent(sharedAbonent.delegationId)"
        >
          <mat-icon>remove</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
