/**
 * cronExpr: string;
 * ss - seconds,
 * mm - minutes,
 * hh - hours,
 * DD - day of month,
 * MM - month,
 * dd - day of week with * replaced to ? for server format,
 * yy - year (optional)
 */
export interface PaymentConfigurationSchedule {
  billCronExpr: string;
  blockCronExpr: string;
  reminderCronExpr: string;
}
