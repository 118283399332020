import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ButtonModule} from 'primeng/button';
import {TooltipModule} from 'primeng/tooltip';
import {TranslateModule} from '@ngx-translate/core';
import {ContextMenuModule} from 'primeng/contextmenu';
import {MenuItem} from 'primeng/api';
import {RippleModule} from 'primeng/ripple';
import {isEmpty} from 'lodash';

@Component({
  selector: 'app-avs-grid-actions',
  standalone: true,
  imports: [CommonModule, ButtonModule, TooltipModule, TranslateModule, ContextMenuModule, RippleModule],
  templateUrl: './avs-grid-actions.component.html',
  styleUrls: ['./avs-grid-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class AvsGridActionsComponent {
  protected readonly isEmpty = isEmpty;
  @Input() public useDots = false;
  @Input() public items: MenuItem[] = [];
  @Output() public output = new EventEmitter<{delete: boolean; navigate: boolean}>();
  public onDeleteAction(): void {
    this.output.emit({delete: true, navigate: false});
  }
  public onNavigateAction(): void {
    this.output.emit({delete: false, navigate: true});
  }
}
