import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { AddressInfo } from '@app/shared/models';

export const addressValidator = (control: AbstractControl) => {
  const group = control.parent as UntypedFormGroup;
  if (!group) {
    return null;
  }

  const city = isInvalid(group, 'city');
  const street = isInvalid(group, 'street');
  const house = isInvalid(group, 'house');

  if (city || street || house) {
    return {
      city,
      street,
      house
    };
  }

  return null;
};

function isInvalid(group: UntypedFormGroup, name: keyof AddressInfo) {
  const control = group.controls[name as string];
  return control.invalid && control.touched;
}
