import { Component, Input } from '@angular/core';
import { ServicesHelper } from '@app/views/services/services';
import { Camera } from '@app/shared/entities/rd/camera/models';
import { CAMERAS_GUIDE_LINK } from '@app/shared/helpers';

@Component({
  selector: 'app-service-cameras-warning',
  templateUrl: './service-cameras-warning.component.html',
  styleUrls: ['./service-cameras-warning.component.scss']
})
export class ServiceCamerasWarningComponent {
  readonly CAMERAS_GUIDE_LINK: string = CAMERAS_GUIDE_LINK;

  camerasIdsWithErrors: string;

  @Input() private set cameras(cameras: Camera[]) {
    if (!cameras?.length) {
      return;
    }

    this.camerasIdsWithErrors =
      this.servicePageHelper
        .filterCamerasWithError(cameras)
        .map(camera => camera.id).join(', ');
  }

  constructor(
    private servicePageHelper: ServicesHelper
  ) { }
}
