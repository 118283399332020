import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  CameraAlreadyExistsComponent
} from '@app/shared/components/camera/camera-already-exists/camera-already-exists.component';
import { CameraThumbnailModule } from '@app/shared/containers';
import { MatIconModule } from '@angular/material/icon';
import { DialogWrapperModule } from '@app/shared/ui/dialog-wrapper/dialog-wrapper.module';
import { ButtonLinkModule } from '@app/shared/ui/button-link/button-link.module';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
  declarations: [
    CameraAlreadyExistsComponent
  ],
  exports: [
    CameraAlreadyExistsComponent
  ],
    imports: [
        CommonModule,
        CameraThumbnailModule,
        ButtonLinkModule,
        DialogWrapperModule,
        MatIconModule,
        MatButtonModule,
        MatTooltipModule,
        NgxTranslateModule
    ]
})
export class CameraAlreadyExistsModule { }
