import { Account } from '@app/shared/entities/rd';
import { ServicesTypes } from '@app/shared/models';

export interface ServicesList {
  id: number;
  blocked: boolean;
  name: string;
  customName: string;
  type: ServicesTypes;
  accounts: Account[];
}
