import {ServiceFlat} from '@app/views/services/components';
import {ServicesTypes} from '@app/shared/models';

export interface ServiceTariffPopupModel {
    flats?: ServiceFlat[];
    serviceId?: number;
    hardwareId?: number;
    tariff?: {
      tariffHardwareIntercom?: number
      tariffSoftwareIntercom?: number
      tariffGate?: number
      tariffVideoSurveillance?: number
    };
    showCheckboxForAllAbonents?: boolean;
    serviceType: ServicesTypes;
}
