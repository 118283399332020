import { HttpErrorResponse } from '@angular/common/http';
import * as root from '@app/store/reducers';
import { AbonentsFromEntrancesConnection, AbonentsFromEntrancesStatus } from '../models';

export interface AbonentsFromEntrancesState {
    connections: AbonentsFromEntrancesConnection[];
    status: AbonentsFromEntrancesStatus;
    totalConnections: number;
    doneConnections: number;
    selectedAll: boolean;
    error: HttpErrorResponse;
}

export const abonentsFromEntrancesInitialState: AbonentsFromEntrancesState = {
    connections: undefined,
    totalConnections: undefined,
    doneConnections: undefined,
    status: null,
    selectedAll: false,
    error: undefined
};

export interface AbonentsFromEntrancesModuleState {
    abonentsFromEntrances: AbonentsFromEntrancesState;
}

export interface AbonentsFromEntrancesModuleRootState extends root.State {
    'abonents-from-entrances': AbonentsFromEntrancesModuleState;
}
