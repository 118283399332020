import { VideoPlayerCustomClickEventType } from '../../../models';
import { PlyrTemplateHelper } from '../../plyr-globals';
import { PlyrControlBasicHelper } from '../base/plyr-control-basic-helper';

/**
 * Class to open door
 */
export class PlyrOpenDoorHelper extends PlyrControlBasicHelper<VideoPlayerCustomClickEventType> {
  constructor() {
    super();
    this.enableEventsOpenDoorClickListener();
  }

  /**
   * Enable listener to open door
   */
  private enableEventsOpenDoorClickListener() {
    const eventsOpenDoor: HTMLButtonElement =
      document.getElementById(PlyrTemplateHelper.EVENTS_OPEN_DOOR_ID) as HTMLButtonElement;

    eventsOpenDoor.addEventListener('click', (e: MouseEvent) => {
      this.clickListener$.next({ type: VideoPlayerCustomClickEventType.OPEN_DOOR });
    });
  }
}
