import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes/shared-pipes.module';
import { BottomSheetWrapperModule } from '@app/shared/templates';
import { DialogWrapperModule } from '@app/shared/ui';
import { ConfirmComponentConnectionBottomSheetComponent } from './confirm-component-connection-bottom-sheet';
import { ConfirmComponentConnectionContentComponent } from './confirm-component-connection-content';
import { ConfirmComponentConnectionDialogComponent } from './confirm-component-connection-dialog';
import { ConfirmComponentConnectionHelper } from './confirm-component-connection.helper';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        DialogWrapperModule,
        MaterialModule,
        FormsModule,
        SharedPipesModule,
        BottomSheetWrapperModule,
        NgxTranslateModule,
    ],
    declarations: [
        ConfirmComponentConnectionBottomSheetComponent,
        ConfirmComponentConnectionContentComponent,
        ConfirmComponentConnectionDialogComponent
    ],
    providers: [ConfirmComponentConnectionHelper]
})
export class ConfirmComponentConnectionModule { }
