import { IntercomKeyType } from './intercom-key-type.enum';

export const intercomKeyRecords: Record<IntercomKeyType, IntercomKeyRecord> = {
    1: { icon: '/assets/images/icon-type-1.png', type: 1, name: 'shared.intercom.key.record.door' },
    2: { icon: '/assets/images/icon-type-2.png', type: 2, name: 'shared.intercom.key.record.barrier' },
    3: { icon: '/assets/images/icon-type-3.png', type: 3, name: 'shared.intercom.key.record.gates' },
    4: { icon: '/assets/images/icon-type-4.png', type: 4, name: 'shared.intercom.key.record.gate' },
};

export interface IntercomKeyRecord {
    icon: string;
    type: number;
    name: string;
}
