<ng-container [formGroup]="controlContainer.control">
  <mat-form-field appearance="outline" class="relative-size">
    <mat-label>
      {{ label || ('shared.address.input.title' | translate) }}
    </mat-label>
    <div style="position: relative">
      <input
        matInput
        type="text"
        [placeholder]="'shared.address.input.message.specify_address' | translate"
        formControlName="rawAddress"
        [matAutocomplete]="auto"
        autocomplete="off"
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
      >
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="onOptionSelected($event.option.value)"
      >
        <mat-option *ngFor="let option of options; let i = index" [value]="i">
          {{ option.addressRus }}
        </mat-option>
      </mat-autocomplete>
    </div>
    <mat-error>{{ getError() }}</mat-error>
  </mat-form-field>

  <input formControlName="country" type="hidden" />
  <input formControlName="city" type="hidden" />
  <input formControlName="street" type="hidden" />
  <input formControlName="house" type="hidden" />
  <input formControlName="building" type="hidden" />
  <input formControlName="housing" type="hidden" />
  <input formControlName="block" type="hidden" />
  <input formControlName="fiasCode" type="hidden" />
  <input formControlName="kladrCode" type="hidden" />
  <input formControlName="universalCode" type="hidden" />
</ng-container>
