import { BatchResponse } from '@app/views/intercom/models';
import { IntercomType } from '@app/shared/entities/rd';

export class RdaNewResponse {
  uid: string;
  ipAddress: string;
  companyId: number;
  companyName: string;
  createdAt: string;
  lastHeartBeatTime: string;
  rdaModel: string;
  shieldNumber: Number;
  shieldModel: string;
  batch: BatchResponse;
  shieldId?: Number;
  shieldUid?: string;
  intercomType: IntercomType;
  intercomTypeName: string;
  intercomTypeId: number;
  active: boolean;
  versionInfoShield: string;
  versionInfoStm: string;
  versionInfoStmDriver: string;
  versionInfoOs: string;
  versionInfoRdaa: string;

  constructor(values?: Partial<RdaNewResponse>) {
    Object.assign(this, values);
  }
}
