<div [ngClass]="{ 'container': fromNavigationState }">
  <mat-card class="card-container">
    <mat-card-title *ngIf="mailing">
      {{
        mailing.id
          ? ('mailing.page.new.title.from' | translate: { date: (mailing.createdAt | date: 'dd.MM.yyyy') })
          : ('mailing.page.new.title.create' | translate)
      }}
    </mat-card-title>

    <mat-card-content [ngClass]="{ 'empty-content': !mailing }">
      <div *ngIf="mailing; else notSelected">

        <ng-container *ngIf="mailing.id; else editBlock">
          <div>
            <p>
              {{ 'mailing.page.new.field.user' | translate }}:
              <span class="bold-text">
                {{ mailing.user }}
              </span>
            </p>
            <p>
              {{ 'mailing.page.new.field.recipients_count' | translate }}:
              <span class="bold-text">
                {{ mailing.recipientsCount }}
              </span>
            </p>
            <p>
              {{ 'mailing.page.new.field.created_at' | translate }}:
              <span class="bold-text">
                {{ mailing.createdAt | date: 'dd.MM.yyyy HH:mm:ss' }}
              </span>
            </p>
          </div>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ 'mailing.page.new.header.added_addresses' | translate }}
              </mat-panel-title>
              <mat-panel-description>
                {{ addedAddresses.length }}
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-chip-list *ngIf="addedAddresses.length > 0; else emptyAddresses">
              <mat-chip
                *ngFor="let addedAddress of addedAddresses; let idx = index"
                [selectable]="true"
                [removable]="false"
              >
                {{ addedAddress.displayedName }}
              </mat-chip>
            </mat-chip-list>
            <ng-template #emptyAddresses>
              <p>
                {{ 'mailing.page.new.message.empty_addresses' | translate }}
              </p>
            </ng-template>
          </mat-expansion-panel>

          <div class="mailing-body-footer">
            <div class="mailing-body">
              <p class="mailing-body-text">{{mailing.message}}</p>
            </div>

            <button
              mat-raised-button
              color="primary"
              (click)="onRepeatMailing()"
            >
              {{ 'mailing.page.new.button.repeat' | translate }}
            </button>
          </div>
        </ng-container>
        <ng-template #editBlock>

          <div class="address-block input-field">
            <div>
              <mat-form-field class="address-input none-padding none-margin" appearance="outline">
                <mat-label>
                  {{ 'mailing.page.new.field.address' | translate }}
                </mat-label>
                <input
                  #inputAutoComplete
                  type="text"
                  matInput
                  placeholder="{{ 'mailing.page.new.placeholder.address' | translate }}"
                  [formControl]="filterInput"
                  [matAutocomplete]="auto"
                  autocomplete="off"
                  spellcheck="false"
                  autocorrect="off"
                  autocapitalize="off"
                />
                <mat-error>
                  {{ 'mailing.page.new.error.address' | translate }}
                </mat-error>
              </mat-form-field>
              <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                (optionSelected)="onSelected($event.option.value)">
                <mat-option *ngFor="let address of filteredAddresses" [value]="address">
                  {{ address.displayedName }}
                </mat-option>
              </mat-autocomplete>
            </div>
            <button
              [disabled]="!addressSelected.cities.selectedId"
              mat-raised-button
              color="primary-inverse"
              (click)="onAddAddress()"
            >
              {{ 'mailing.page.new.button.add' | translate }}
            </button>
          </div>

          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ 'mailing.page.new.header.added_addresses' | translate }}
              </mat-panel-title>
              <mat-panel-description>
                {{ addedAddresses.length }}
              </mat-panel-description>
            </mat-expansion-panel-header>

            <mat-chip-list *ngIf="addedAddresses.length > 0; else emptyAddresses">
              <mat-chip *ngFor="let addedAddress of addedAddresses; let idx = index" [selectable]="false"
                [removable]="true">
                {{addedAddress.displayedName}}
                <mat-icon matChipRemove *ngIf="true" (click)="onRemoveAddress(idx)">cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
            <ng-template #emptyAddresses>
              <p>
                {{ 'mailing.page.new.message.empty_addresses' | translate }}
              </p>
            </ng-template>
          </mat-expansion-panel>

          <form [formGroup]="form" (ngSubmit)="onSubmit()">
            <mat-form-field class="message-area" appearance="outline">
              <mat-label>
                {{ 'mailing.page.new.field.message' | translate }}
              </mat-label>
              <textarea
                matInput cdkTextareaAutosize
                cdkAutosizeMinRows="7"
                cdkAutosizeMaxRows="7"
                placeholder="{{ 'mailing.page.new.placeholder.message' | translate }}"
                type="text"
                formControlName="message"
                autocomplete="off"
                spellcheck="false"
                autocorrect="off"
                autocapitalize="off"
                required
              ></textarea>
              <mat-error>
                {{ 'mailing.page.new.error.message' | translate }}
              </mat-error>
            </mat-form-field>

            <button type="submit" mat-raised-button color="primary">
              {{ 'mailing.page.new.button.submit' | translate }}
            </button>
          </form>
        </ng-template>

      </div>
      <ng-template #notSelected>
        <span class="not-selected-text">
            {{ 'mailing.page.new.message.not_selected' | translate }}
        </span>
      </ng-template>
    </mat-card-content>
  </mat-card>
</div>
