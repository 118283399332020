<div class="page-container dashboard">
  <app-service-navbar
    [pageMode]="pageMode$ | async"
    (pageModeChange)="onChangePageMode($event.pageMode)"
  ></app-service-navbar>

  <ng-container [ngSwitch]="pageMode$ | async">
    <ng-container *ngSwitchCase="pageMode.ABONENTS">
      <app-video-surveillance-flats
        [serviceId]="serviceId$ | async"
        [companyId]="(company$ | async)?.id"
        [serviceType]="serviceType"
        [addresses]="entrances$ | async"
        [loading]="abonentsLoading$ | async"
        [flatsFilters]="flatsFilters$ | async"
        [dependantServices]="dependantServices$ | async"
        [flats]="flats$ | async"
        [tariff]="serviceTariff$ | async"
        [enabled]="(entrances$ | async)?.length > 0"
        (manageClose)="onManageClose()"
      ></app-video-surveillance-flats>
    </ng-container>

    <ng-container *ngSwitchCase="pageMode.SETTINGS">
      <app-service-cameras-warning
        [cameras]="cameras$ | async"
      ></app-service-cameras-warning>

      <app-service-config
        [company]="company$ | async"
        [serviceType]="serviceType"
        [supportHidden]="true"
        [connectHidden]="true"
        [emergencyHidden]="true"
        [customName]="serviceCustomName$ | async"
        [name]="serviceName$ | async"
        [rdas]="rdas$ | async"
      ></app-service-config>

      <app-service-entrances
        [entrances]="entrances$ | async"
        [serviceType]="serviceType"
        [displayedColumns]="['address', 'range', 'additionalRanges', 'actions']"
      ></app-service-entrances>

      <app-service-cameras
        [cameras]="cameras$ | async"
        [serviceType]="serviceType"
        [addresses]="(entrances$ | async)"
        [disabled]="!(entrances$ | async)?.length"
      ></app-service-cameras>
    </ng-container>

    <ng-container *ngSwitchCase="pageMode.DELEGATION">
      <app-delegation></app-delegation>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <app-service-activity
      [serviceType]="serviceType"
      [logs]="logs$ | async"
      [logsLoading]="logsLoading$ | async"
      [blocksCount]="blocksCount$ | async"
      [sources]="sources$ | async"
      [timeRange]="timeRange$ | async"
      [extendedMode]="extendedMode$ | async"
      [componentType]="logsComponentType$ | async"
      [totalLogsCount]="totalLogsCount$ | async"
      [currentLogsCount]="currentLogsCount$ | async"
      [selectedLogsSource]="selectedLogsSource$ | async"
      [activeHistoryCameras]="activeHistoryCameras$ | async"
    ></app-service-activity>
    </ng-container>
  </ng-container>

</div>
