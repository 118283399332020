import { Injectable } from '@angular/core';
import { UserRoleType } from '@app/core/models';
import { TokenService } from '@app/security';
import { LocalStorageGeneralKey, LocalStorageHelper } from '@app/shared/entities/common';
import { Country } from '@app/shared/models';
import { PermissionsTreeBase, PermissionsTreeKey } from './permissions-tree';
import { PermissionsTreeBuilder } from './permissions-tree-builder';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  private country: Country;
  private preparedRoles: UserRoleType[];
  private preparedRolesMixins: UserRoleType[];
  private preparedRolesPermissions: { [key: string]: PermissionsTreeBase };
  private preparedRolesMixinsPermissions: { [key: string]: PermissionsTreeBase };

  constructor(
    private tokenService: TokenService
  ) {
    this.preparePermissions();
  }

  get userRoles(): UserRoleType[] {
    return this.preparedRoles;
  }

  preparePermissions() {
    const { roles, rolesMixins } = this.prepareRolesFromToken();

    this.preparedRoles = roles;
    this.preparedRolesMixins = rolesMixins;
    this.preparedRolesPermissions = PermissionsTreeBuilder.buildRolesPermissions(this.preparedRoles);
    this.preparedRolesMixinsPermissions = PermissionsTreeBuilder.buildRolesMixinsPermissions(this.preparedRolesMixins);
    this.country = LocalStorageHelper.getItem(LocalStorageGeneralKey.COUNTRY);
  }

  canAccessByKey(key: string): boolean {
    if (key === PermissionsTreeKey.ABONENTS_SIGN_UPS && this.signUpsHiddenForCountry()) {
      return false;
    }

    let access: boolean = this.checkRolesPermissions(this.checkUserKeyPermission, key).access;
    const roleMixinAccess: boolean = this.checkRolesMixinsPermissions(this.checkUserKeyPermission, key);

    if (roleMixinAccess !== null && roleMixinAccess !== undefined) {
      access = roleMixinAccess;
    }

    if (access === null) {
      access = false;
    }

    return access;
  }

  canAccessByRoute(routeLink: string): { access: boolean, defaultRoute?: string } {
    if (routeLink.indexOf('sign_ups') > -1 && this.signUpsHiddenForCountry()) {
      return { access: false };
    }

    const permissions: { access: boolean, permissionsTree: PermissionsTreeBase } =
      this.checkRolesPermissions(this.checkUserRoutePermission, routeLink);

    let access: boolean = permissions.access;
    const roleMixinAccess: boolean = this.checkRolesMixinsPermissions(this.checkUserRoutePermission, routeLink);

    if (roleMixinAccess !== null && roleMixinAccess !== undefined) {
      access = roleMixinAccess;
    }

    if (access === true) {
      return { access: true };
    }

    if (permissions.permissionsTree?.defaultRoute) {
      return { access: false, defaultRoute: permissions.permissionsTree?.defaultRoute };
    }

    return { access: false };
  }

  someRoleContains(roles: UserRoleType[]): boolean {
    return roles.some((role: UserRoleType) => this.preparedRoles.includes(role) || this.preparedRolesMixins.includes(role));
  }

  private checkRolesPermissions(condition: Function, key: string): { access: boolean, permissionsTree: PermissionsTreeBase } {
    const result: { access: boolean, permissionsTree: PermissionsTreeBase } = { access: null, permissionsTree: null };

    // Check roles
    for (const role of this.preparedRoles) {
      const permissionsTree = this.preparedRolesPermissions[role];

      result.access = condition.bind(this)(key, permissionsTree);
      result.permissionsTree = permissionsTree;

      if (result.access) {
        break;
      }
    }

    return result;
  }

  private checkRolesMixinsPermissions(condition: Function, key: string): boolean {
    let access: boolean;

    // Check mixins for roles
    for (const role of this.preparedRolesMixins) {
      const permissionsMixinsTree = this.preparedRolesMixinsPermissions[role];

      access = condition.bind(this)(key, permissionsMixinsTree);

      if (access) {
        break;
      }
    }

    return access;
  }

  private checkUserKeyPermission(key: string, userRole: PermissionsTreeBase) {
    return userRole?.keys?.[key];
  }

  private checkUserRoutePermission(routeLink: string, userRole: PermissionsTreeBase) {
    const routeLinkArray: string[] = routeLink.split('/');

    if (routeLinkArray.length === 0) {
      return false;
    }

    let route: any = userRole.routes[routeLinkArray[0]];

    for (let idx = 1; idx < routeLinkArray.length; ++idx) {
      route = route.childrens[routeLinkArray[idx]];
    }

    return route.enabled;
  }

  private prepareRolesFromToken(): { roles: UserRoleType[], rolesMixins: UserRoleType[] } {
    const authorities: UserRoleType[] = this.tokenService.getAuthorities();

    if (!authorities) {
      return { roles: [UserRoleType.ROLE_ENGINEER], rolesMixins: [] };
    }

    if (authorities.includes(UserRoleType.BLOCKED_USER)) {
      return { roles: [UserRoleType.BLOCKED_USER], rolesMixins: [] };
    }

    const allRoles: UserRoleType[] = Object.values(UserRoleType).filter(authority => authorities.includes(authority));
    const roles: UserRoleType[] = [];
    const rolesMixins: UserRoleType[] = [];

    for (const role of allRoles) {
      if (PermissionsTreeBuilder.rolesConstructors[role]) {
        roles.push(role);
      } else if (PermissionsTreeBuilder.rolesMixinsConstructors[role]) {
        rolesMixins.push(role);
      }
    }

    if (roles.length === 0) {
      roles.push(UserRoleType.ROLE_SUPPORT);
    }

    if (rolesMixins.includes(UserRoleType.ROLE_DEMO) && roles.length === 0) {
      roles.push(UserRoleType.ROLE_ENGINEER);
    }

    return { roles, rolesMixins };
  }

  private signUpsHiddenForCountry() {
    return this.country && this.country?.shortName !== 'RU' && this.country?.shortName !== 'KZ';
  }
}
