import { VideoPlayerCustomClickEventType } from '../../../models';
import { PlyrTemplateHelper } from '../../plyr-globals';
import { PlyrControlBasicHelper } from '../base';

/**
 * Class for change mode button
 */
export class PlyrChangeModeButtonControlHelper extends PlyrControlBasicHelper<VideoPlayerCustomClickEventType> {
    constructor() {
        super();
        this.enableClickListener();
    }

    /**
     * Enable listener for change mode button
     */
    private enableClickListener() {
        const changeModeButton = document.getElementById(PlyrTemplateHelper.CHANGE_MOD_ID);

        if (changeModeButton) {
            changeModeButton.addEventListener('click', (e: MouseEvent) =>
                this.clickListener$.next({ type: VideoPlayerCustomClickEventType.CHANGE_MODE, e })
            );
        }
    }
}
