import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RdeaDate, ResolutionService } from '@app/shared/entities/common';
import { LicenseeReportRequest } from '@app/shared/entities/rd';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { LicenseeReportPopupComponent } from '@app/shared/components';

@Component({
  selector: 'app-licensee-report-content',
  templateUrl: './licensee-report-content.component.html',
  styleUrls: ['./licensee-report-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LicenseeReportContentComponent {
  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  form: UntypedFormGroup = this.initForm();
  maxDate: Date = new Date();

  @Output() private submitContent: EventEmitter<{ request: LicenseeReportRequest }> = new EventEmitter();
  private _loading: boolean;

  constructor(
    public resolution: ResolutionService,
    private readonly dialogRef: MatDialogRef<LicenseeReportPopupComponent>,
    private readonly router: Router
  ) {
    this.maxDate = new Date(this.maxDate.setTime(this.maxDate.getTime() - RdeaDate.dayInMilliseconds));
  }

  get loading(): boolean {
    return this._loading;
  }

  onSubmit() {
    const { reportDate }: { reportDate: Date } = this.form.getRawValue();

    this.submitContent.emit({
      request: {
        reportDate: reportDate ? new RdeaDate(reportDate).getDateTimeString() : null
      }
    });
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      reportDate: new UntypedFormControl(null)
    });
  }
}
