import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { GraylogMessage, GraylogResponse } from '@app/shared/entities/integrations/graylog/models';
import { Constants } from '@app/shared/helpers';
import { LogsResponse } from '@app/shared/models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export interface IGraylogQuery {
  source?: string;
  destinationId?: number;
  rdaUid?: string;
}

@Injectable({ providedIn: 'root' })
export abstract class GraylogApiService extends ApiService {
  protected GRAYLOG_MINUTE: number;

  protected headers: HttpHeaders;

  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.monitoringApiUrl, []);
    this.GRAYLOG_MINUTE = 60;
  }

  getExtendedLogs(
    sourceId: string,
    destinationId: number,
    range: number = Constants.dateDiffs[0].value,
    limit: number = 150,
    offset: number = 0,
    sort: string = 'timestamp:desc'
  ) {
    return this.getComponentLogs(sourceId, destinationId, range, limit, offset, sort)
      .pipe(
        map((response: GraylogResponse) => this.prepareExtendedResponse(response))
      );
  }

  getMixedLogs(
    sourceId: string,
    destinationId: number,
    range: number = Constants.dateDiffs[0].value,
    limit: number = 150,
    offset: number = 0,
    sort: string = 'timestamp:desc'
  ): Observable<{ logs: LogsResponse[], extendedLogs: LogsResponse[], totalCount: number }> {
    return this.getComponentLogs(sourceId, destinationId, range, limit, offset, sort)
      .pipe(
        map((response: GraylogResponse) => ({
          logs: this.prepareParsedResponse(response),
          extendedLogs: this.prepareExtendedResponse(response),
          totalCount: response.total_results,
          currentCount: response.messages.length
        }))
      );
  }

  private getComponentLogs(
    sourceId: string,
    destinationId: number,
    range: number = Constants.dateDiffs[0].value,
    limit: number = 150,
    offset: number = 0,
    sort: string = 'timestamp:desc'
  ): Observable<GraylogResponse> {
    const query = this.prepareQuery(sourceId, destinationId);
    return this.getLogs(query, range, limit, offset, sort);
  }

  protected prepareExtendedResponse(response: GraylogResponse): LogsResponse[] {
    return response.messages
      .map(
        (message: GraylogMessage) => ({
          message: message.message.message,
          createdAt: message.message.timestamp
        })
      );
  }

  protected getLogs(query, range: number, limit: number, offset: number, sort: string): Observable<GraylogResponse> {
    const params = this.prepareQueryParams(query, range, limit, offset, sort);
    const options = { headers: this.headers, params};

    return this.get<GraylogResponse>('v1/graylog', 1, options);
  }

  protected prepareQueryParams(query, range: number, limit: number, offset: number, sort: string) {
    return new HttpParams({
      fromObject: {
        ...query,
        range: (range * this.GRAYLOG_MINUTE).toString(),
        limit: limit.toString(),
        offset: offset.toString(),
        sort: sort
      }
    });
  }

  protected abstract prepareParsedResponse(graylogMessages: GraylogResponse): LogsResponse[];

  protected abstract prepareQuery(sourceId: string, destinationId: number): IGraylogQuery;
}
