<div class="payments-wizard-service">
  <h1 class="tpl-instruction__title">
    {{ 'payments.popups.wizard.service.title' | translate }}
  </h1>

  <mat-radio-group
    aria-labelledby="services-radio-group-label"
    class="services-radio-group"
    [(ngModel)]="service"
    (ngModelChange)="updateService()"
  >
    <mat-radio-button class="example-radio-button" [value]="serviceType.YOOKASSA">
      <img ngSrc="/assets/images/payments/yookassa.svg" alt="Yookassa" width="109" height="26" />
    </mat-radio-button>
    <mat-radio-button class="example-radio-button" [value]="serviceType.ROBOKASSA">
      <img ngSrc="/assets/images/payments/robokassa.svg" alt="Robokassa" width="164" height="25" />
    </mat-radio-button>
  </mat-radio-group>
</div>
