import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IntercomKeyDeleteContainerModule } from '@app/shared/components';
import { DialogWrapperModule } from '@app/shared/ui';
import { ServiceDeleteKeyPopupComponent } from './service-delete-key-popup.component';

@NgModule({
    imports: [
        CommonModule,
        DialogWrapperModule,
        IntercomKeyDeleteContainerModule

    ],
    declarations: [ServiceDeleteKeyPopupComponent],
    exports: [ServiceDeleteKeyPopupComponent]
})
export class ServiceDeleteKeyPopupModule { }
