<app-dialog-wrapper
  [componentName]="data.componentName"
  [progress]="(popupService.state$ | async) === 'loading'"
  [title]="data.title"
>
  <app-delete-camera-content
    [loading]="(popupService.state$ | async) === 'loading'"
    [cameraId]="data.body.cameraId"
    (deleteCamera)="onDelete()"
  ></app-delete-camera-content>
</app-dialog-wrapper>