<mat-card class="models-container">
  <mat-card-header class="header">
    <div class="title-block">
      <mat-card-title>
        {{ 'intercom.models.page.title' | translate }}
      </mat-card-title>
    </div>

    <button
      mat-raised-button
      color="primary-inverse"
      (click)="onAddModel('rda')"
    >
      <mat-icon>add</mat-icon>
      <ng-container *ngIf="!resolution.isMobile">
        {{ 'intercom.models.page.button.add' | translate }}
      </ng-container>
    </button>
  </mat-card-header>

  <hr>

  <mat-card-content>
    <mat-table *ngIf="(rdaModels$ | async)?.data?.length > 0; else emptyRdaModels"
      [dataSource]="(rdaModels$ | async)?.data">

      <ng-container matColumnDef="name">
        <mat-cell data-label="name" *matCellDef="let element">
          {{ element.name }}
        </mat-cell>
      </ng-container>

      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <ng-template #emptyRdaModels>
      <h2 class="add-new-text">
        {{ 'intercom.models.page.message.create_first' | translate }}
      </h2>
    </ng-template>

  </mat-card-content>
</mat-card>

<mat-card class="models-container">
  <mat-card-header class="header">
    <div class="title-block">
      <mat-card-title>
        {{ 'intercom.models.page.header.shield' | translate }}
      </mat-card-title>
    </div>
    <button
      mat-raised-button
      color="primary-inverse"
      (click)="onAddModel('shield')"
    >
      <mat-icon>add</mat-icon>
      <ng-container *ngIf="!resolution.isMobile">
        {{ 'intercom.models.page.button.add' | translate }}
      </ng-container>
    </button>
  </mat-card-header>

  <hr>

  <mat-card-content>
    <mat-table *ngIf="(shieldModels$ | async)?.data?.length > 0; else emptyShieldModels"
      [dataSource]="(shieldModels$ | async)?.data">
      <ng-container matColumnDef="name">
        <mat-cell data-label="name" *matCellDef="let element">
          {{ element.name }}
        </mat-cell>
      </ng-container>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <ng-template #emptyShieldModels>
      <h2 class="add-new-text">
        {{ 'intercom.models.page.message.create_shield_first' | translate }}
      </h2>
    </ng-template>

  </mat-card-content>
</mat-card>
