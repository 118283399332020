import { ServicesTypes } from '@app/shared/models/services-types';
import { Flat, Address } from '@app/shared/models';

export interface AccountServiceData {
  id: number;
  flat: Flat;
  name: string;
  address: Address;
  customName: string;
  isBlocked: boolean;
  type: ServicesTypes;
  tariff: number;
  bindedSoftwareIntercomeId?: number;
}
