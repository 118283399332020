import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { UserData, UserRoleType } from '@app/core/models';
import { LoaderService, LocalStorageGeneralKey, LocalStorageHelper, ResolutionService } from '@app/shared/entities/common';
import { Company } from '@app/shared/entities/rd';
import { Constants } from '@app/shared/helpers/constants';
import { EditPopupComponentData } from '@app/shared/models';
import { EditPopupComponent } from '@app/shared/templates';
import { NavbarLink } from '@app/shared/ui';
import { State } from '@app/store/reducers';
import { CreateOrderRequest, OrderResponse } from '@app/views/intercom/models';
import { CreateOrder, CreateOrderClear, GetAllCompanies, GetAllCompaniesClear, GetOrdersPage, GetOrdersPageClear } from '@app/views/intercom/store/actions';
import { addRdasToOrderLoading, addRdasToOrderSuccess, addScansToOrderLoading, addScansToOrderSuccess, createOrderLoading, createOrderSuccess, deleteOrderLoading, deleteOrderSuccess, getAllCompaniesLoading, getAllCompaniesSuccess, getOrdersPageLoading, getOrdersPageSuccess, updateOrderLoading, updateOrderSuccess } from '@app/views/intercom/store/states';
import { JwtHelperService } from '@auth0/angular-jwt';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { OrderCreateFormComponent } from './order-card/forms/order-create-form/order-create-form.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-orders-page',
  templateUrl: './orders-page.component.html',
  styleUrls: ['./orders-page.component.scss']
})
export class OrdersPageComponent implements OnInit, OnDestroy {
  readonly pageSize: number = Constants.PAGE_SIZE;
  readonly links: NavbarLink[] = [
    { name: 'myOrders', text: this.translate.instant('intercom.orders.page.navbar.my') },
    { name: 'freeOrders', text: this.translate.instant('intercom.orders.page.navbar.free') }
  ];
  activeLink: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  orders$: Observable<{ data: OrderResponse[]; totalCount: number; }>;
  companies: Company[];
  filterInput: UntypedFormControl;
  withoutManufacturer: boolean;
  authorities: UserRoleType[];
  private onDestroy$: Subject<void>;
  private dialogRef: MatDialogRef<EditPopupComponent>;

  constructor(
    public resolution: ResolutionService,
    private store: Store<State>,
    private dialog: MatDialog,
    private jwtHelper: JwtHelperService,
    private loaderService: LoaderService,
    private translate: TranslateService
  ) {
    this.withoutManufacturer = false;
    this.activeLink = 'myOrders';
  }

  ngOnInit() {
    this.prepareRules();
    this.initLoading();
    this.initFilter();
    this.initStore();
    this.initPaginator();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.clearStoreListeners();
  }

  onChangeType() {
    this.withoutManufacturer = !this.withoutManufacturer;
    this.loadOrders(0);
  }

  onCreate() {
    if (this.isManufacturer()) {
      this.store.dispatch(new GetAllCompanies());
    } else {
      this.createOrder();
    }
  }

  onChangePage(pageIdx: number) {
    this.activeLink = this.links[pageIdx].name;
    this.withoutManufacturer = pageIdx === 1;
    this.clearStoreListeners();
    this.loadOrders(0);
  }

  isManufacturer(): boolean {
    return this.authorities?.includes(UserRoleType.ROLE_MANUFACTURER);
  }

  private initLoading() {
    combineLatest([
      this.store.select(getOrdersPageLoading),
      this.store.select(addScansToOrderLoading),
      this.store.select(addRdasToOrderLoading),
      this.store.select(createOrderLoading),
      this.store.select(updateOrderLoading),
      this.store.select(deleteOrderLoading),
      this.store.select(getAllCompaniesLoading)
    ])
      .subscribe((values: boolean[]) => this.loaderService.loaderState = { state: values.some((x: boolean) => x) });
  }

  private initStore() {
    this.onDestroy$ = new Subject();

    this.initStoreListeners();
    this.getStoreData();
  }

  private initStoreListeners() {
    this.store.pipe(select(createOrderSuccess), takeUntil(this.onDestroy$))
      .subscribe(resp => {
        if (resp) {
          if (this.dialogRef) { this.dialogRef.close(); }
          this.loadOrders(0);
        }
      });

    this.store.pipe(select(updateOrderSuccess), takeUntil(this.onDestroy$))
      .subscribe(resp => resp && this.loadOrders(0));

    this.store.pipe(select(addRdasToOrderSuccess), takeUntil(this.onDestroy$))
      .subscribe(resp => resp && this.loadOrders(0));

    this.store.pipe(select(addScansToOrderSuccess), takeUntil(this.onDestroy$))
      .subscribe(resp => resp && this.loadOrders(0));

    this.store.pipe(select(deleteOrderSuccess), takeUntil(this.onDestroy$))
      .subscribe(resp => resp && this.loadOrders(0));

    this.store.pipe(select(getAllCompaniesSuccess), takeUntil(this.onDestroy$))
      .subscribe(resp => {
        if (resp) {
          this.companies = resp;
          this.createOrder();
        }
      });
  }

  private initFilter() {
    this.filterInput = new UntypedFormControl();
    this.filterInput.valueChanges.pipe(debounceTime(500), distinctUntilChanged())
      .subscribe(() => {
        this.paginator.pageIndex = 0;
        this.loadOrders(0);
      });
  }

  private initPaginator() {
    this.paginator.page.subscribe((e: PageEvent) => { });
  }

  private prepareRules() {
    const token = LocalStorageHelper.getItem(LocalStorageGeneralKey.AUTH_TOKEN);
    const profileData: UserData = this.jwtHelper.decodeToken(token);
    this.authorities = profileData.authorities;
  }

  private getStoreData() {
    this.orders$ = this.store.select(getOrdersPageSuccess);
    this.loadOrders(0);
  }

  private clearStoreListeners() {
    this.store.dispatch(new GetOrdersPageClear());
    this.store.dispatch(new CreateOrderClear());
    this.store.dispatch(new GetAllCompaniesClear());
  }

  private loadOrders(page: number) {
    this.store.dispatch(new GetOrdersPage(page, this.pageSize, this.withoutManufacturer, this.filterInput.value));
  }

  private createOrder() {
    const data: EditPopupComponentData = {
      title: this.translate.instant('intercom.orders.page.create.title'),
      providedData: { isManufacturer: this.isManufacturer(), companies: this.companies },
      component: OrderCreateFormComponent,
      componentName: 'CreateOrder',
      submit: (request: CreateOrderRequest) => {
        this.store.dispatch(new CreateOrder(request));
      }
    };

    this.dialogRef = this.dialog.open(EditPopupComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '300px',
      data
    });
  }
}
