import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DashboardFacade } from '@app/views/dashboard/store/dashboard.facade';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard-blocked-user',
  templateUrl: './dashboard-blocked-user.component.html',
  styleUrls: ['./dashboard-blocked-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardBlockedUserComponent implements OnInit {
  @Input() managerEmail!: string;
  loading$: Observable<boolean>;

  @Input() private inn!: string;

  constructor(
    private dashboardFacade: DashboardFacade,
  ) { }

  ngOnInit() {
    this.loading$ = this.dashboardFacade.loading$;
  }

  onGetPaymentReport() {
    this.dashboardFacade.getBlockedUserInvoiceReport(this.inn);
  }
}
