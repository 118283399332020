import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Company } from '@app/shared/entities/rd';
import { BatchResponse } from '@app/views/intercom/models';
import { SnackbarService } from '@app/shared/components';
import { NavbarLink } from '@app/shared/ui';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-group-data-modification',
  templateUrl: './group-data-modification.component.html',
  styleUrls: ['./group-data-modification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GroupDataModificationComponent {
  readonly links: NavbarLink[] = [
    {
      name: 'upload',
      text: this.translate.instant('intercom.new.adapters.page.group.data.modification.navbar.upload')
    },
    {
      name: 'cp',
      text: this.translate.instant('intercom.new.adapters.page.group.data.modification.navbar.cp')
    }
  ];
  private _uid: string[] | null;
  private _batchId: number | null;
  private _companyId: number | null;

  public activeLink: 'upload' | 'cp';
  public enteredValue: string;
  public uploadValue: string;

  @Input() companyList: Company[];
  @Input() batchesList: BatchResponse[];

  @Output() private submit: EventEmitter<{ uid: string[], companyId: number, batchId: number }>;

  constructor(
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {
    this.submit = new EventEmitter();
    this.companyList = [];
    this.batchesList = [];
    this._companyId = null;
    this._batchId = null;
    this.activeLink = 'upload';
    this.enteredValue = '';
    this.uploadValue = '';
    this._uid = [];
  }

  set uid(value: string[]) {
    this._uid = value;
  }
  get uid(): string[] {
    return this._uid;
  }

  set batchId(value: number) {
    this._batchId = value;
  }
  get batchId(): number {
    return this._batchId;
  }

  set companyId(value: number) {
    this._companyId = value;
  }
  get companyId(): number {
    return this._companyId;
  }

  public onEmitReadData(handler): void {
    const reader = new FileReader();

    new Promise((resolve, reject) => {
      reader.readAsText(handler[0]);
      reader.onload = () => resolve(reader.result);
      reader.onabort = () => reject(reader.result);
    }).then((result: string) => {
      this.notificationProcessing(this.valueFileParser(result));
      this.uploadValue = result;
    }).catch(() => this.notificationProcessing([], true));
  }

  public onHandleBatchSelected(event: { batchId: number }): void {
    this.batchId = event.batchId;
  }

  public onHandleCompanySelected(event: { companyId: number }): void {
    this.companyId = event.companyId;
  }

  public onSubmit(): void {
    if (this.companyId === null) {
      this.handlerNotification(
        this.translate.instant('intercom.new.adapters.page.group.data.modification.message.need_select_company'),
        'info'
      );
      return;
    }
    this.submit.emit({ uid: this.getAddedUid(), batchId: this.batchId, companyId: this.companyId });
  }

  public onChangeMode(idx: number): void {
    this.activeLink = this.links[idx].name;
  }

  private handlerNotification(msg: string, type: 'error' | 'info' | 'success'): void {
    this.snackbar.showMessage(msg, type);
  }

  private getAddedUid(): string[] {
    switch (this.activeLink) {
      case 'upload' :
        return this.valueFileParser(this.uploadValue);
      case 'cp':
        return this.valueTextParser(this.enteredValue);
      default: return [];
    }
  }

  private notificationProcessing(uids: string[], error?: boolean): void {
    if (error) {
      this.handlerNotification(
        this.translate.instant('intercom.new.adapters.page.group.data.modification.message.unknown'),
        'error'
      );
    }
    if (uids.length) {
      if (uids.length > 1000) {
        this.handlerNotification(
          this.translate.instant('intercom.new.adapters.page.group.data.modification.message.max_elements_count'),
          'info'
        );
        return;
      }
      this.handlerNotification(
        this.translate.instant('intercom.new.adapters.page.group.data.modification.message.success', {
          count: uids.length
        }),
        'success'
      );
    } else {
      this.handlerNotification(
        this.translate.instant('intercom.new.adapters.page.group.data.modification.message.empty'),
        'info'
      );
      return;
    }
  }

  private valueFileParser(resource: string): string[] {
    return resource
      .split('\n')
      .map(uid => uid.slice(0, uid.indexOf(' ')).replace('\t', ''))
      .filter((row) => row);
  }

  private valueTextParser(resource: string): string[] {
    return resource.split('\n').filter((r) => r !== '');
  }
}
