<div
  class="navbar-container"
  [class.navbar-container--neutral]="neutral"
  [class.navbar-container--centered]="centered"
>
  <div class="navbar-container-block">
    <ng-container *ngFor="let link of links; let lastItem = last; let idx = index">
      <ng-container *ngIf="!link.hidden">
        <div [matTooltip]="link.tooltip">
          <button
            mat-raised-button
            class="navbar-container-block__button"
            [color]="link.name === active ? 'primary' : neutral ? 'primary-inverse' : 'primary-white'"
            [disabled]="link.disabled"
            matTooltipPosition="below"
            (click)="onChangeActive(idx)"
          >
            {{ getText(link.text) | translate }}
          </button>
        </div>

        <hr
          class="navbar-container-block__divider"
          [class.navbar-container-block__divider--hidden]="hideDivider(lastItem, idx)"
        />
      </ng-container>
    </ng-container>
  </div>
</div>
