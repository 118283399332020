<form [formGroup]="form" (submit)="onSubmit()">
  <h3>
    {{ 'intercom.orders.card.form.create.message.indicate_number_adapters' | translate }}
    {{!order && isManufacturer ? ', ' + ('intercom.orders.card.form.create.message.and_select_company' | translate) : ''}}
  </h3>
  <mat-form-field appearance="outline" class="none-padding">
    <input
      matInput type="number"
      min="1"
      placeholder="{{ 'intercom.orders.card.form.create.placeholder.number' | translate }}"
      formControlName="rdaCount"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      required
    >
  </mat-form-field>

  <ng-container *ngIf="!order && isManufacturer">
    <app-companies-form-group [companyList]="companies" (handleCompanySelected)="onSelectCompany($event)">
    </app-companies-form-group>
  </ng-container>

  <div class="action-block">
    <button type="submit" mat-raised-button color="primary">
      {{ 'intercom.orders.card.form.create.button.submit' | translate }}
    </button>
  </div>
</form>
