<mat-card class="card-container info">
  <div class="header">
    <div class="title-block">
      {{ 'company.about.page.info.title' | translate }}
    </div>
  </div>
  <mat-card-content class="info-content">
    <span class="info-item-name">
      {{ 'company.about.page.info.field.licensee' | translate }}
    </span>
    <span class="info-item-value">
      {{ licensee }}
    </span>
    <span class="info-item-name">
      {{ 'company.about.page.info.field.licensee_short' | translate }}
    </span>
    <span class="info-item-value">
      {{ licenseeShort }}
    </span>

    <div class="row row-1">
      <span class="info-item-name">
        {{ 'company.about.page.info.field.inn' | translate }}
      </span>
      <span class="info-item-value">
        {{ inn }}
      </span>
      <span class="info-item-name">
        {{ 'company.about.page.info.field.ogrn' | translate }}
      </span>
      <span class="info-item-value">
        {{ ogrn }}
      </span>
      <span class="info-item-name">
        {{ 'company.about.page.info.field.payment_bank' | translate }}
      </span>
      <span class="info-item-value">
        {{ paymentBank }}
      </span>
      <span class="info-item-name">
        {{ 'company.about.page.info.field.payment_bic' | translate }}
      </span>
      <span class="info-item-value">
        {{ paymentBic }}
      </span>
      <span class="info-item-name">
        {{ 'company.about.page.info.field.edm' | translate }}
      </span>
      <span class="info-item-value">
        {{ edm }}
      </span>
    </div>
    <div class="row row-2">
      <span class="info-item-name address">
        {{ 'company.about.page.info.field.address' | translate }}
      </span>
      <span class="info-item-value address">
        {{ address }}
      </span>
      <span class="info-item-name">
        {{ 'company.about.page.info.field.payment_account' | translate }}
      </span>
      <span class="info-item-value">
        {{ paymentAccount }}
      </span>
      <span class="info-item-name">
        {{ 'company.about.page.info.field.payment_correspondent_account' | translate }}
      </span>
      <span class="info-item-value">
        {{ paymentCorrespondentAccount }}
      </span>
      <span class="info-item-name" *ngIf="licenseAgreement.length > 0; else emptyValue">
        {{ 'company.about.page.info.field.license_agreement' | translate }}
      </span>
      <span class="info-item-value" *ngIf="licenseAgreement.length > 0">
        {{ licenseAgreement }}
      </span>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #emptyValue>
  <span class="info-item-name"></span>
</ng-template>
