<app-paginated-page-wrapper
  #paginatedPageWrapper
  filterInputPlaceholder="{{ 'software.intercoms.placeholder.filter' | translate }}"
  [paginatorData]="pageData$ | async"
  (filterInputChanged)="onFilterInputChanged()"
  (pageChanged)="onPageChanged()">
  <ng-container page-template-actions>
    <button
      mat-raised-button
      color="primary-inverse"
      (click)="onCreate()">
      <mat-icon>add</mat-icon>
      <ng-container *ngIf="!(xlWDownBreakpoint$ | async)">
        {{ 'software.intercoms.button.add' | translate }}
      </ng-container>
    </button>
  </ng-container>

  <ng-container page-template-content>
    <mat-table
      [dataSource]="softwareIntercoms$ | async"
      *ngIf="pageData?.totalElements !== 0; else emptyData">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef>
          {{ 'software.intercoms.field.name' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="name"
          label-name="{{ 'software.intercoms.field.name' | translate }}"
          *matCellDef="let element">
          {{ element.customName || element.name }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="rdasState">
        <mat-header-cell data-label="rdasState" *matHeaderCellDef>
          {{ 'software.intercoms.field.rdasState' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="rdasState"
          label-name="{{ 'software.intercoms.field.rdasState' | translate }}"
          *matCellDef="let element">
          <mat-chip-list
            [class.mat-chip-list--place-content]="xlWDownBreakpoint$ | async">
            <ng-container
              *ngIf="
                element.rdasStates.active === 0 &&
                  element.rdasStates.inactive === 0;
                else rdasStates
              ">
              <mat-chip
                [disabled]="true"
                [selectable]="false">
                {{ 'software.intercoms.enum.rdasState.none' | translate }}
              </mat-chip>
            </ng-container>
            <ng-template #rdasStates>
              <mat-chip
                color="success"
                [disabled]="true"
                [selectable]="false"
                *ngIf="element.rdasStates.active > 0">
                {{ 'software.intercoms.enum.rdasState.online' | translate }}: {{ element.rdasStates.active }}
              </mat-chip>

              <mat-chip
                color="error"
                [disabled]="true"
                [selectable]="false"
                *ngIf="element.rdasStates.inactive > 0">
                {{ 'software.intercoms.enum.rdasState.offline' | translate }}: {{ element.rdasStates.inactive }}
              </mat-chip>
            </ng-template>
          </mat-chip-list>
          <ng-template #rdaNotInstalled>
            <mat-chip
              [disabled]="true"
              [selectable]="false">
              {{ 'software.intercoms.enum.rdasState.none' | translate }}
            </mat-chip>
          </ng-template>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="camerasStates">
        <mat-header-cell *matHeaderCellDef>
          {{ 'software.intercoms.field.camerasState' | translate }}
        </mat-header-cell>
        <mat-cell
          data-label="camerasStates"
          label-name="{{ 'software.intercoms.field.camerasState' | translate }}"
          *matCellDef="let element">
          <mat-chip-list
            [class.mat-chip-list--place-content]="xlWDownBreakpoint$ | async">
            <ng-container
              *ngIf="
                element.camerasStates.active === 0 &&
                  element.camerasStates.inactive === 0;
                else camerasStates
              ">
              <mat-chip
                [disabled]="true"
                [selectable]="false">
                {{ 'software.intercoms.enum.camerasState.none' | translate }}
              </mat-chip>
            </ng-container>
            <ng-template #camerasStates>
              <mat-chip
                color="success"
                [disabled]="true"
                [selectable]="false"
                *ngIf="element.camerasStates.active > 0">
                {{ 'software.intercoms.enum.camerasState.online' | translate }}: {{ element.camerasStates.active }}
              </mat-chip>

              <mat-chip
                color="error"
                [disabled]="true"
                [selectable]="false"
                *ngIf="element.camerasStates.inactive > 0">
                {{ 'software.intercoms.enum.camerasState.offline' | translate }}: {{ element.camerasStates.inactive }}
              </mat-chip>
            </ng-template>
          </mat-chip-list>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell
          data-label="actions"
          *matHeaderCellDef></mat-header-cell>
        <mat-cell
          data-label="actions"
          *matCellDef="let element">
          <button
            matTooltip="{{ 'software.intercoms.action.delete' | translate }}"
            [matTooltipPosition]="'before'"
            mat-icon-button
            color="error"
            (click)="onDelete(element.id, element.name)">
            <mat-icon aria-label="Delete">delete</mat-icon>
          </button>

          <a
            mat-icon-button
            [routerLink]="'/services/software-intercoms/' + element.id"
            [queryParams]="{
              query: paginatedPageWrapper.filterInput.value,
              page: paginatedPageWrapper.paginator.pageIndex,
              name: element.name,
              type: previosQueryType.SOFTWARE_INTERCOMS
            }">
            <mat-icon>navigate_next</mat-icon>
          </a>
        </mat-cell>
      </ng-container>

      <mat-header-row
        [style.display]="(xlWDownBreakpoint$ | async) ? 'none' : 'flex'"
        *matHeaderRowDef="displayedColumns">
        {{ 'software.intercoms.action.title' | translate }}
      </mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>

    <ng-template #emptyData>
      <app-info-block>
        <ng-container info-block-text>
          {{ 'software.intercoms.message.empty' | translate }}
        </ng-container>
      </app-info-block>
    </ng-template>

    <ngx-skeleton-loader
      *ngIf="loading$ | async"
      count="10"
      [theme]="{
        'border-radius': '8px',
        'margin-top': (xlWDownBreakpoint$ | async) ? '0px' : '10px',
        height: (xlWDownBreakpoint$ | async) ? '225px' : '81px'
      }"></ngx-skeleton-loader>
  </ng-container>
</app-paginated-page-wrapper>
