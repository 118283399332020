import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { of, Subject } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';
import {
  CompanyPageType,
  IRoleResponse,
  IUserResponse
} from '@app/views/company/models/company-page';
import { UserService } from '@app/shared/entities/rd';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackbarService } from '@app/shared/components';
import { RolesComponentStoreService } from '@app/views/company/pages/company-roles-page/roles-component-store.service';
import { MatDialog } from '@angular/material/dialog';
import {
  ActionType,
  CompanyBlockUserPopupComponent
} from '@app/views/company/pages/company-roles-page/company-block-user-confirm/company-block-user-popup/company-block-user-popup.component';
import { TranslateService } from '@ngx-translate/core';
import { UserManagementMode } from '../company-roles-management/company-roles-management.component';

export interface IRoleConfig {
  id: number;
  name: string;
  translate?: string;
  description?: string;
  control?: FormControl<boolean>;
}

export interface IAccessManagement {
  newRoles: number[];
  removedRoles: number[];
}

@Component({
  selector: 'app-company-company-user-profile',
  templateUrl: './company-user-profile.component.html',
  styleUrls: ['./company-user-profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyUserProfileComponent implements OnInit, OnDestroy {
  public userResponse: IUserResponse;
  public allAvailableRoles: IRoleResponse[] = this.rolesComponentStoreService.allAvailableRoles;
  public roleIds: IAccessManagement = { newRoles: [], removedRoles: [], };
  public loading: boolean;

  private unSubscribe$: Subject<boolean> = new Subject<boolean>();
  protected readonly RolesMode = UserManagementMode;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private snackbarService: SnackbarService,
    private changeDetectorRef: ChangeDetectorRef,
    public rolesComponentStoreService: RolesComponentStoreService,
    private matDialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        map((user) => {
          return {
            ...user,
            blocked: this.stringToBoolean(user.blocked)
          };
        }),
        takeUntil(this.unSubscribe$)
      )
      .subscribe((response: IUserResponse) => {
        this.userResponse = response;
      });
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next(true);
    this.unSubscribe$.complete();
  }

  public onBack(): void {
    this.router.navigate(['/partners/about'], {
      queryParams: { [CompanyPageType.UserAndRoles]: true, },
      queryParamsHandling: 'merge'
    });
  }

  public onSave(): void {
    this.loading = true;
    this.userService.deleteRoles(+this.userResponse.id, this.roleIds.removedRoles).pipe(
        switchMap(() => this.userService.addRoles(+this.userResponse.id, this.roleIds.newRoles).pipe(
            catchError((error: HttpErrorResponse) => of(error)),
        ))
    ).subscribe((response) => {
      if (response instanceof HttpErrorResponse) {
        this.snackbarService.showMessage(
          this.translate.instant('company.roles.user.profile.message.save.success', {
            text: response.message
          }),
          'error'
        );
      } else {
        this.snackbarService.showMessage(
          this.translate.instant('company.roles.user.profile.message.save.success'),
          'success'
        );
      }
      this.loading = false;
      this.updateView();
    });

  }

  public onBlock(): void {
    this.matDialog.open(CompanyBlockUserPopupComponent, {
      data: {
        title: this.translate.instant('company.roles.user.profile.block.title'),
        body: { email: this.userResponse.email, type: ActionType.block },
        submit: (blockUser: boolean) => {
          if (blockUser) {
            this.loading = true;
            this.userService
                .blockUser(+this.userResponse.id)
                .pipe(
                    catchError((error: HttpErrorResponse) => of(error)),
                    takeUntil(this.unSubscribe$)
                )
                .subscribe((response) => {
                  if (response instanceof HttpErrorResponse) {
                    this.snackbarService.showMessage(
                      this.translate.instant('company.roles.user.profile.block.message.failed', {
                        text: response.message
                      }),
                      'error'
                    );
                  } else {
                    this.snackbarService.showMessage(
                      this.translate.instant('company.roles.user.profile.block.message.success'),
                      'success'
                    );
                    this.userResponse.blocked = true;
                    this.updateRouteConfig(this.userResponse.blocked);
                  }
                  this.updateView();
                });
          }
        }
      }
    });
  }

  public onAccess(roles: IRoleConfig[]): void {
    this.roleIds.newRoles = roles.map((r) => r.id);
    this.roleIds.removedRoles = this.allAvailableRoles
        .filter(availableRole => !roles.some(role => role.id === availableRole.id) && availableRole.name !== 'ROLE_OWNER')
        .map((role) => role.id);
  }

  public onUserUnlock(): void {
    this.matDialog.open(
      CompanyBlockUserPopupComponent,
      {
        data: {
          title: this.translate.instant('company.roles.user.profile.unlock.title'),
          body: { email: this.userResponse.email, type: ActionType.unlock },
          submit: (unlock: boolean) => {
            if (unlock) {
              this.loading = true;
              this.userService.unlockUser(this.userResponse.id).pipe(
                catchError((error: HttpErrorResponse) => of(error)),
                takeUntil(this.unSubscribe$)
              ).subscribe((response: IUserResponse | HttpErrorResponse) => {
                if (response instanceof HttpErrorResponse) {
                  this.snackbarService.showMessage(
                    this.translate.instant('company.roles.user.profile.unlock.message.failed', {
                      text: response.message
                    }),
                    'error'
                  );
                } else {
                  this.snackbarService.showMessage(
                    this.translate.instant('company.roles.user.profile.unlock.message.success'),
                    'success'
                  );
                  this.userResponse.blocked = false;
                  this.updateRouteConfig(this.userResponse.blocked);
                  this.updateView();
                }
                this.updateView();
              });
            }
          }
        }
      }
    );
  }

  private stringToBoolean(str): boolean {
    if (str === 'true') {
      return true;
    } else if (str === 'false') {
      return false;
    } else {
      return Boolean(str);
    }
  }

  private updateView(): void {
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  private updateRouteConfig(value: boolean): void {
    this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        blocked: value
      }
    });
  }
}
