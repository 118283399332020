import { AdapterInfoForKeyResponse, IpIntercomKeysRequest, KeysResponse, RdaKeysRequest } from '@app/shared/entities/rd';
import { Action } from '@ngrx/store';
import { ServicesActionTypes } from './services-action-types.actions';

export class GetServiceKeys implements Action {
    readonly type = ServicesActionTypes.GetServiceKeys;

    constructor() { }
}

export class GetServiceKeysSuccess implements Action {
    readonly type = ServicesActionTypes.GetServiceKeysSuccess;

    constructor(public keys: KeysResponse[]) { }
}

export class GetServiceKeysFailure implements Action {
    readonly type = ServicesActionTypes.GetServiceKeysFailure;

    constructor() { }
}

export class AddServiceKey implements Action {
    readonly type = ServicesActionTypes.AddServiceKey;

    constructor(public intercomInfo: AdapterInfoForKeyResponse, public request: RdaKeysRequest) { }
}

export class AddServiceKeySuccess implements Action {
    readonly type = ServicesActionTypes.AddServiceKeySuccess;

    constructor(public device: KeysResponse, public intercomInfo: AdapterInfoForKeyResponse) { }
}

export class AddServiceKeyFailure implements Action {
    readonly type = ServicesActionTypes.AddServiceKeyFailure;

    constructor() { }
}

export class UpdateServiceKey implements Action {
    readonly type = ServicesActionTypes.UpdateServiceKey;

    constructor(
        public keyId: number,
        public request: RdaKeysRequest | IpIntercomKeysRequest,
        public locationName: string
    ) { }
}

export class UpdateServiceKeySuccess implements Action {
    readonly type = ServicesActionTypes.UpdateServiceKeySuccess;

    constructor(
        public keyId: number,
        public response: RdaKeysRequest | IpIntercomKeysRequest,
        public locationName: string
    ) { }
}

export class UpdateServiceKeyFailure implements Action {
    readonly type = ServicesActionTypes.UpdateServiceKeyFailure;

    constructor() { }
}

export class DeleteServiceKey implements Action {
    readonly type = ServicesActionTypes.DeleteServiceKey;

    constructor(public keyId: number, public rdaUid: string) { }
}

export class DeleteServiceKeySuccess implements Action {
    readonly type = ServicesActionTypes.DeleteServiceKeySuccess;

    constructor(public keyId: number, public rdaUid: string) { }
}

export class DeleteServiceKeyFailure implements Action {
    readonly type = ServicesActionTypes.DeleteServiceKeyFailure;

    constructor() { }
}

export type ServicesIntercomKeysActions =
    | GetServiceKeys
    | GetServiceKeysFailure
    | GetServiceKeysSuccess

    | AddServiceKey
    | AddServiceKeySuccess
    | AddServiceKeyFailure

    | UpdateServiceKey
    | UpdateServiceKeySuccess
    | UpdateServiceKeyFailure

    | DeleteServiceKey
    | DeleteServiceKeySuccess
    | DeleteServiceKeyFailure;
