import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared';
import { DialogWrapperModule, InfoTableModule, TextBadgeModule } from '@app/shared/ui';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { IntercomKeysContainerItemComponent } from './intercom-keys-container-item';
import { IntercomKeysContainerComponent } from './intercom-keys-container.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        InfoTableModule,
        TextBadgeModule,
        SharedPipesModule,
        DialogWrapperModule,
        NgxSkeletonLoaderModule,
        NgxTranslateModule
    ],
    declarations: [IntercomKeysContainerComponent, IntercomKeysContainerItemComponent],
    exports: [IntercomKeysContainerComponent]
})
export class IntercomKeysContainerModule { }
