import { Injectable } from '@angular/core';
import { AwxJobLogResponse, CreateAwxUpdateJobRequest, CreateAwxUpdateJobResponse } from '@app/shared/entities/rd/awx-jobs/models';
import { State } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IntercomUpdateToolStatus } from '../intercom-update-tool-status.model';
import { ClearUpdateJobs, CreateUpdateRdaaJob, CreateUpdateRdaaJobSuccess, CreateUpdateRdosJob, CreateUpdateRdosJobSuccess, DeleteUpdateJobStateIfSuccess, FinishUpdateJobs, GetUpdateRdaaJob, GetUpdateRdaaJobLogs, GetUpdateRdaaJobLogsSuccess, GetUpdateRdaaJobSuccess, GetUpdateRdosJob, GetUpdateRdosJobLogs, GetUpdateRdosJobLogsSuccess, GetUpdateRdosJobSuccess, InitUpdateJobs, SetIntercomStatus } from './intercom-update-tool.actions';
import { intercomUpdateToolSelectors } from './intercom-update-tool.selectors';

@Injectable()
export class IntercomUpdateToolFacade {
    constructor(
        private store: Store<State>
    ) { }

    status$: Observable<IntercomUpdateToolStatus>;
    state$: Observable<{ listening: boolean, rdaaUpdateJobId: number, rdosUpdateJobId: number }>;
    errorsCount$: Observable<number>;
    updateRdaaJob$: Observable<CreateAwxUpdateJobResponse>;
    updateRdosJob$: Observable<CreateAwxUpdateJobResponse>;
    updateRdaaJobLogs$: Observable<AwxJobLogResponse[]>;
    updateRdosJobLogs$: Observable<AwxJobLogResponse[]>;

    initSelectors(intercomUid: string) {
        this.status$ = this.store.select(intercomUpdateToolSelectors.selectJobsStatus, { intercomUid });
        this.state$ = this.store.select(intercomUpdateToolSelectors.selectUpdateState, { intercomUid });
        this.errorsCount$ = this.store.select(intercomUpdateToolSelectors.selectErrorsCount, { intercomUid });
        this.updateRdaaJob$ = this.store.select(intercomUpdateToolSelectors.selectUpdateRdaaJob, { intercomUid });
        this.updateRdosJob$ = this.store.select(intercomUpdateToolSelectors.selectUpdateRdosJob, { intercomUid });
        this.updateRdaaJobLogs$ = this.store.select(intercomUpdateToolSelectors.selectUpdateRdaaJobLogs, { intercomUid });
        this.updateRdosJobLogs$ = this.store.select(intercomUpdateToolSelectors.selectUpdateRdosJobLogs, { intercomUid });
    }

    initUpdateJobs(intercomUid: string) {
        this.store.dispatch(new InitUpdateJobs(intercomUid));
    }

    clearUpdateJobs(intercomUid: string) {
        this.store.dispatch(new ClearUpdateJobs(intercomUid));
    }

    finishUpdateJobs(intercomUid: string, rdaaUpdateState: boolean, rdosUpdateState: boolean) {
        this.store.dispatch(new FinishUpdateJobs(intercomUid, rdaaUpdateState, rdosUpdateState));
    }

    setIntercomSatus(intercomUid: string, status: IntercomUpdateToolStatus) {
        this.store.dispatch(new SetIntercomStatus(intercomUid, status));
    }

    getUpdateRdaaJob(intercomUid: string, jobId: number) {
        this.store.dispatch(new GetUpdateRdaaJob(intercomUid, jobId));
    }

    getUpdateRdaaJobSuccess(intercomUid: string, response: CreateAwxUpdateJobResponse) {
        this.store.dispatch(new GetUpdateRdaaJobSuccess(intercomUid, response));
    }

    getUpdateRdosJob(intercomUid: string, jobId: number) {
        this.store.dispatch(new GetUpdateRdosJob(intercomUid, jobId));
    }

    getUpdateRdosJobSuccess(intercomUid: string, response: CreateAwxUpdateJobResponse) {
        this.store.dispatch(new GetUpdateRdosJobSuccess(intercomUid, response));
    }

    createUpdateRdaaJob(intercomUid: string, request: CreateAwxUpdateJobRequest) {
        this.store.dispatch(new CreateUpdateRdaaJob(intercomUid, request));
    }

    createUpdateRdaaJobSuccess(intercomUid: string, response: CreateAwxUpdateJobResponse) {
        this.store.dispatch(new CreateUpdateRdaaJobSuccess(intercomUid, response));
    }

    createUpdateRdosJob(intercomUid: string, request: CreateAwxUpdateJobRequest) {
        this.store.dispatch(new CreateUpdateRdosJob(intercomUid, request));
    }

    createUpdateRdosJobSuccess(intercomUid: string, response: CreateAwxUpdateJobResponse) {
        this.store.dispatch(new CreateUpdateRdosJobSuccess(intercomUid, response));
    }

    getUpdateRdaaJobLogs(intercomUid: string, jobId: number) {
        this.store.dispatch(new GetUpdateRdaaJobLogs(intercomUid, jobId));
    }

    getUpdateRdaaJobLogsSuccess(
        intercomUid: string,
        jobId: number,
        response: AwxJobLogResponse[],
        totalCount: number
    ) {
        this.store.dispatch(new GetUpdateRdaaJobLogsSuccess(intercomUid, jobId, response, totalCount));
    }

    getUpdateRdosJobLogs(intercomUid: string, jobId: number) {
        this.store.dispatch(new GetUpdateRdosJobLogs(intercomUid, jobId));
    }

    getUpdateRdosJobLogsSuccess(
        intercomUid: string,
        jobId: number,
        response: AwxJobLogResponse[],
        totalCount: number
    ) {
        this.store.dispatch(new GetUpdateRdosJobLogsSuccess(intercomUid, jobId, response, totalCount));
    }

    deleteUpdateJobStateIfSuccess(intercomUid: string) {
        this.store.dispatch(new DeleteUpdateJobStateIfSuccess(intercomUid));
    }
}
