import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { PagedResponse, Flat } from '@app/shared/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { HumanErrorTranslationService } from './human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class FlatsService extends ApiService {
  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.abonentsApiUrl, ['v1']);
  }

  getFlats(size?: number, addressParams?: Object): Observable<PagedResponse<Flat>> {
    let flatsQuery = '';
    if (addressParams && Object.keys(addressParams).length > 0) {
      Object.keys(addressParams).map((param, idx) => {
        flatsQuery += idx === 0 ? '?' : '&';
        flatsQuery += `${param}=${encodeURI(addressParams[param])}`;
      });
    }
    return this.get(`/flats${flatsQuery}`, 1);
  }
}


