import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
  debounceTime
} from 'rxjs/operators';

import { AbonentsFromEntrancesHelper } from '@app/shared/components';
import { SnackbarService } from '@app/shared/components/snackbar';
import {
  ConnectionCreateResponse,
  ConnectionService,
  CreateDelegationRequest,
  CreateDelegationResponse,
  DelegationsApiService,
  FlatApiService,
  ProtocolTypes,
  RdaResponse,
  RdaUtilsService,
  ServiceApiService,
  ServiceBlockPhysicalTubeRequest,
  ServiceEntranceFlatResponse,
  ServiceResponse,
  ServiceSignUpsResponse,
  ServiceUtilsService
} from '@app/shared/entities/rd';
import { parseError } from '@app/shared/helpers';
import { Address, ServicesTypes } from '@app/shared/models';
import {
  ServiceEntrancesFlats,
  ServiceFlatsFormatter
} from '@app/views/services/components';
import { ServiceConnectionWithType } from '@app/views/services/models';
import { ServicePageActionsService } from '@app/views/services/services';
import {
  AddServiceConnectionsFromEntrances,
  AddServiceConnectionsFromEntrancesFailure,
  AddServiceConnectionsFromEntrancesSuccess,
  AddServicePhysicalTube,
  AddServicePhysicalTubeFailure,
  AddServicePhysicalTubeSuccess,
  BlockServiceConnection,
  BlockServiceConnectionFailure,
  BlockServiceConnectionSuccess,
  BlockServicePhysicalTube,
  BlockServicePhysicalTubeFailure,
  BlockServicePhysicalTubeSuccess,
  CreateServiceConnections,
  CreateServiceConnectionsFailure,
  CreateServiceConnectionsSuccess,
  DelegateServiceAbonentAccess,
  DelegateServiceAbonentAccessFailure,
  DelegateServiceAbonentAccessSuccess,
  DeleteServiceAbonentAccess,
  DeleteServiceAbonentAccessFailure,
  DeleteServiceAbonentAccessSuccess,
  DeleteServiceConnection,
  DeleteServiceConnectionFailure,
  DeleteServiceConnectionSuccess,
  DeleteServiceFlat,
  DeleteServiceFlatFailure,
  DeleteServiceFlatSuccess,
  DeleteServicePhysicalTube,
  DeleteServicePhysicalTubeFailure,
  DeleteServicePhysicalTubeSuccess,
  FilterServiceFlats,
  GetServiceConnections,
  GetServiceConnectionsFailure,
  GetServiceConnectionsSuccess,
  ServicesActionTypes,
  UnblockServiceConnection,
  UnblockServiceConnectionFailure,
  UnblockServiceConnectionSuccess,
  UnblockServicePhysicalTube,
  UnblockServicePhysicalTubeFailure,
  UnblockServicePhysicalTubeSuccess
} from '../actions';
import { ServicesConnectionHandler } from '../handlers';
import { ServiceFacade } from '../services.facade';
import {AddressFormatter} from '@app/shared/services';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServicesConnectionsEffects {

  private ERROR_USER_DOESNT_HAVE_SERVICES = "Index 0 out of bounds for length 0";

  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private serviceFacade: ServiceFacade,
    private serviceApiService: ServiceApiService,
    private servicePageActionsService: ServicePageActionsService,
    private connectorHelper: AbonentsFromEntrancesHelper,
    private connectionService: ConnectionService,
    private flatApiService: FlatApiService,
    private serviceUtilsService: ServiceUtilsService,
    private delegationsApiService: DelegationsApiService,
    private addressFormatter: AddressFormatter,
    private translate: TranslateService
  ) {}

  GetServiceConnections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType<GetServiceConnections>(ServicesActionTypes.GetServiceConnections),
      withLatestFrom(
        this.serviceFacade.serviceId$,
        this.serviceFacade.serviceType$
      ),
      switchMap(
        ([action, serviceId, serviceType]: [
          GetServiceConnections,
          number,
          ServicesTypes
        ]) => {
          return this.serviceUtilsService
            .loadConnectionsWithData(
              { id: serviceId, type: serviceType },
              action.dependantServices,
              action.entrancesIds
            )
            .pipe(
              map(
                ([connections, entrancesFlatsList, signUps]: [
                  ServiceConnectionWithType[],
                  ServiceEntranceFlatResponse[][],
                  ServiceSignUpsResponse[]
                ]) => {
                  return new GetServiceConnectionsSuccess(
                    connections,
                    entrancesFlatsList.reduce(
                      (prev, cur) => [...prev, ...cur],
                      []
                    ),
                    signUps
                  );
                }
              ),
              catchError((error: HttpErrorResponse) => {
                this.snackbar.showMessage(
                  this.translate.instant('services.connections.message.get_service_connections', {
                    text: parseError(error)
                  })
                );
                return of(new GetServiceConnectionsFailure());
              })
            );
        }
      )
    );
  });

  GetServiceConnectionsSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<GetServiceConnectionsSuccess>(
          ServicesActionTypes.GetServiceConnectionsSuccess
        ),
        withLatestFrom(
          this.serviceFacade.entrances$,
          this.serviceFacade.fillEmptyFlats$
        ),
        tap(
          ([action, addresses, fillEmptyFlats]: [
            GetServiceConnectionsSuccess,
            Address[],
            boolean
          ]) => {
            const flats: ServiceEntrancesFlats =
              ServiceFlatsFormatter.prepareFlatsFromConnections(
                action.connections,
                action.entrancesFlats,
                addresses,
                action.signUps,
                { fillEmptyFlats },
                this.addressFormatter
              );

            this.serviceFacade.connectionsPrepared(flats);
            let filtersSubscription: Subscription;
            filtersSubscription = this.serviceFacade.flatsFilters$.subscribe(
              (filters) => {
                if (filters) {
                  this.serviceFacade.filterFlats(filters);
                  filtersSubscription?.unsubscribe();
                }
              }
            );
          }
        )
      ),
    { dispatch: false }
  );

  AddServiceConnectionsFromEntrances$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddServiceConnectionsFromEntrances>(
        ServicesActionTypes.AddServiceConnectionsFromEntrances
      ),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(
        ([action, serviceId]: [AddServiceConnectionsFromEntrances, number]) =>
          from(
            this.connectorHelper.batchConnection(
              serviceId,
              action.connections,
              action.companyId
            )
          ).pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.add_service_connections_from_entrances.success'),
                'success'
              );
              this.serviceFacade.getConnectionsInit();
              return new AddServiceConnectionsFromEntrancesSuccess();
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.add_service_connections_from_entrances.failed', {
                  text: parseError(error)
                })
              );
              this.serviceFacade.getConnectionsInit();
              return of(new AddServiceConnectionsFromEntrancesFailure());
            })
          )
      )
    )
  );

  CreateServiceConnections$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateServiceConnections>(
        ServicesActionTypes.CreateServiceConnections
      ),
      withLatestFrom(
        this.serviceFacade.serviceType$,
        this.serviceFacade.dependantServices$,
        this.serviceFacade.flats$,
        this.serviceFacade.filteredFlats$
      ),
      switchMap(
        ([action, serviceType, dependantServices, flats, filteredFlats]: [
          CreateServiceConnections,
          ServicesTypes,
          Pick<ServiceResponse, 'id' | 'type'>[],
          ServiceEntrancesFlats,
          ServiceEntrancesFlats
        ]) => {
          return from(
            this.connectionService.createConnectionV2(action.request)
          ).pipe(
            map((response: ConnectionCreateResponse) => {
              this.snackbar.showMessage(
                action.request.responseMessage
                  ? action.request.responseMessage
                  : this.translate.instant('services.connections.message.create_service_connections.success'),
                'success'
              );

              response.connections = response.connections.filter(
                (connection) => connection.type === serviceType
              );

              if (dependantServices?.length) {
                response.connections = response.connections.filter(
                  (connection) =>
                    dependantServices.findIndex(
                      (dependantService) =>
                        dependantService.type === connection.type
                    )
                );
              }

              const handledAction =
                ServicesConnectionHandler.handleCreateServiceConnections({
                  flats,
                  filteredFlats,
                  connections: response.connections,
                  flat: response.flat,
                  abonent: response.abonent,
                  account: response.account,
                  entranceId: action.request.entranceId,
                  flatNumber: action.request.flatNumber,
                  virtual: action.request.virtual
                });

              this.serviceFacade.getConnectionsInit();

              return new CreateServiceConnectionsSuccess(
                handledAction.flats,
                handledAction.filteredFlats
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.create_service_connections.failed', {
                  text: parseError(error)
                })
              );
              return of(new CreateServiceConnectionsFailure());
            })
          );
        }
      )
    )
  );

  BlockServiceConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BlockServiceConnection>(
        ServicesActionTypes.BlockServiceConnection
      ),
      withLatestFrom(
        this.serviceFacade.flats$,
        this.serviceFacade.filteredFlats$
      ),
      switchMap(
        ([action, flats, filteredFlats]: [
          BlockServiceConnection,
          ServiceEntrancesFlats,
          ServiceEntrancesFlats
        ]) => {
          return from(
            this.servicePageActionsService.blockService(action.request)
          ).pipe(
            map((connection: ServiceConnectionWithType) => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.block_service_connection.success'),
                'success'
              );

              const handledAction =
                ServicesConnectionHandler.handleBlockServiceConnection(
                  flats,
                  filteredFlats,
                  connection
                );

              return new BlockServiceConnectionSuccess(
                handledAction.flats,
                handledAction.filteredFlats
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.block_service_connection.failed', {
                  text: parseError(error)
                })
              );
              return of(new BlockServiceConnectionFailure());
            })
          );
        }
      )
    )
  );

  AddServicePhysicalTube$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddServicePhysicalTube>(
        ServicesActionTypes.AddServicePhysicalTube
      ),
      withLatestFrom(
        this.serviceFacade.flats$,
        this.serviceFacade.filteredFlats$
      ),
      switchMap(
        ([action, flats, filteredFlats]: [
          AddServicePhysicalTube,
          ServiceEntrancesFlats,
          ServiceEntrancesFlats
        ]) =>
          this.flatApiService.update(action.flatId, { virtual: false }).pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.add_service_physical_tube.success'),
                'success'
              );

              const handledAction =
                ServicesConnectionHandler.handleAddServicePhysicalTube(
                  flats,
                  filteredFlats,
                  action.entranceId,
                  action.flatNumber
                );

              return new AddServicePhysicalTubeSuccess(
                handledAction.flats,
                handledAction.filteredFlats
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.add_service_physical_tube.failed', {
                  text: parseError(error)
                })
              );
              return of(new AddServicePhysicalTubeFailure());
            })
          )
      )
    )
  );

  DeleteServicePhysicalTube$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServicePhysicalTube>(
        ServicesActionTypes.DeleteServicePhysicalTube
      ),
      withLatestFrom(
        this.serviceFacade.flats$,
        this.serviceFacade.filteredFlats$
      ),
      switchMap(
        ([action, flats, filteredFlats]: [
          DeleteServicePhysicalTube,
          ServiceEntrancesFlats,
          ServiceEntrancesFlats
        ]) =>
          from(
            this.serviceUtilsService.deletePhysicalTube(
              action.flatId,
              action.connectionId
            )
          ).pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.delete_service_physical_tube.success'),
                'success'
              );

              const handledAction =
                ServicesConnectionHandler.handleDeleteServicePhysicalTube(
                  flats,
                  filteredFlats,
                  action.entranceId,
                  action.flatNumber
                );

              return new DeleteServicePhysicalTubeSuccess(
                handledAction.flats,
                handledAction.filteredFlats
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.delete_service_physical_tube.failed', {
                  text: parseError(error)
                })
              );
              return of(new DeleteServicePhysicalTubeFailure());
            })
          )
      )
    )
  );

  BlockServicePhysicalTube$ = createEffect(() =>
    this.actions$.pipe(
      ofType<BlockServicePhysicalTube>(
        ServicesActionTypes.BlockServicePhysicalTube
      ),
      withLatestFrom(
        this.serviceFacade.flats$,
        this.serviceFacade.filteredFlats$
      ),
      switchMap(
        ([action, flats, filteredFlats]: [
          BlockServicePhysicalTube,
          ServiceEntrancesFlats,
          ServiceEntrancesFlats
        ]) => {
          return from(
            this.serviceUtilsService.blockPhysicalTube(action.request)
          ).pipe(
            map((response: ServiceBlockPhysicalTubeRequest) => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.block_service_physical_tube.success'),
                'success'
              );

              if (
                !action.request.dependantService &&
                response.dependantService
              ) {
                this.serviceFacade.addDependantService([
                  response.dependantService
                ]);
              }

              if (response.account && !response.connection.account) {
                response.connection.account = response.account;
              }

              return ServicesConnectionHandler.handleBlockServicePhysicalTube({
                flats,
                filteredFlats,
                connection: response.connection,
                entranceId: action.request.entranceId,
                flat: action.request.flat
              });
            }),
            map(
              (response: {
                flats: ServiceEntrancesFlats;
                filteredFlats: ServiceEntrancesFlats;
              }) =>
                new BlockServicePhysicalTubeSuccess(
                  response.flats,
                  response.filteredFlats
                )
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.block_service_physical_tube.failed', {
                  text: parseError(error)
                })
              );
              return of(new BlockServicePhysicalTubeFailure());
            })
          );
        }
      )
    )
  );

  UnblockServicePhysicalTube$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnblockServicePhysicalTube>(
        ServicesActionTypes.UnblockServicePhysicalTube
      ),
      withLatestFrom(
        this.serviceFacade.flats$,
        this.serviceFacade.filteredFlats$
      ),
      switchMap(
        ([action, flats, filteredFlats]: [
          UnblockServicePhysicalTube,
          ServiceEntrancesFlats,
          ServiceEntrancesFlats
        ]) => {
          return from(
            this.serviceUtilsService.unblockPhysicalTube(action.connection.id)
          ).pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.unblock_service_physical_tube.success'),
                'success'
              );

              const handledAction =
                ServicesConnectionHandler.handleUnblockServicePhysicalTubeSuccess(
                  flats,
                  filteredFlats,
                  action.connection
                );

              return new UnblockServicePhysicalTubeSuccess(
                handledAction.flats,
                handledAction.filteredFlats
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.unblock_service_physical_tube.failed', {
                  text: parseError(error)
                })
              );
              return of(new UnblockServicePhysicalTubeFailure());
            })
          );
        }
      )
    )
  );

  UnblockServiceConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnblockServiceConnection>(
        ServicesActionTypes.UnblockServiceConnection
      ),
      withLatestFrom(
        this.serviceFacade.flats$,
        this.serviceFacade.filteredFlats$
      ),
      switchMap(
        ([action, flats, filteredFlats]: [
          UnblockServiceConnection,
          ServiceEntrancesFlats,
          ServiceEntrancesFlats
        ]) => {
          return this.serviceApiService
            .unblockServiceConnection(action.connection.id)
            .pipe(
              map(() => {
                this.snackbar.showMessage(
                  this.translate.instant('services.connections.message.unblock_service_connection.success'),
                  'success'
                );

                const handledAction =
                  ServicesConnectionHandler.handleUnblockServiceConnection(
                    flats,
                    filteredFlats,
                    action.connection
                  );

                return new UnblockServiceConnectionSuccess(
                  handledAction.flats,
                  handledAction.filteredFlats
                );
              }),
              catchError((error: HttpErrorResponse) => {
                this.snackbar.showMessage(
                  this.translate.instant('services.connections.message.unblock_service_connection.failed', {
                    text: parseError(error)
                  })
                );
                return of(new UnblockServiceConnectionFailure());
              })
            );
        }
      )
    )
  );

  DeleteServiceConnection$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceConnection>(
        ServicesActionTypes.DeleteServiceConnection
      ),
      withLatestFrom(
        this.serviceFacade.flats$,
        this.serviceFacade.filteredFlats$,
        this.serviceFacade.fillEmptyFlats$
      ),
      switchMap(
        ([action, flats, filteredFlats, fillEmptyFlats]: [
          DeleteServiceConnection,
          ServiceEntrancesFlats,
          ServiceEntrancesFlats,
          boolean
        ]) => {
          return this.serviceApiService
            .deleteServiceConnection(action.connection.id)
            .pipe(
              map(() => {
                this.snackbar.showMessage(
                  this.translate.instant('services.connections.message.delete_service_connection.success'),
                  'success'
                );

                const handledAction =
                  ServicesConnectionHandler.handleDeleteServiceConnection(
                    flats,
                    filteredFlats,
                    action.connection
                  );

                return new DeleteServiceConnectionSuccess(
                  handledAction.flats,
                  handledAction.filteredFlats
                );
              }),
              catchError((error: HttpErrorResponse) => {
                this.snackbar.showMessage(
                  this.translate.instant('services.connections.message.delete_service_connection.failed', {
                    text: parseError(error)
                  })
                );
                return of(new DeleteServiceConnectionFailure());
              })
            );
        }
      )
    )
  );

  DeleteServiceFlat$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceFlat>(ServicesActionTypes.DeleteServiceFlat),
      withLatestFrom(
        this.serviceFacade.rdas$,
        this.serviceFacade.flats$,
        this.serviceFacade.filteredFlats$,
        this.serviceFacade.fillEmptyFlats$
      ),
      switchMap(
        ([action, rdas, flats, filteredFlats, fillEmptyFlats]: [
          DeleteServiceFlat,
          RdaResponse[],
          ServiceEntrancesFlats,
          ServiceEntrancesFlats,
          boolean
        ]) => {
          return from(
            this.servicePageActionsService.deleteFlat(
              rdas,
              action.hardwareServiceConnectionId,
              action.flatId
            )
          ).pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.delete_service_flat.success'),
                'success'
              );
              const handledAction =
                ServicesConnectionHandler.handleDeleteServiceFlat(
                  flats,
                  filteredFlats,
                  fillEmptyFlats,
                  action.entranceId,
                  action.flatNumber
                );

              return new DeleteServiceFlatSuccess(
                handledAction.flats,
                handledAction.filteredFlats
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.delete_service_flat.failed', {
                  text: parseError(error)
                })
              );
              return of(new DeleteServiceFlatFailure());
            })
          );
        }
      )
    )
  );

  DeleteServiceAbonentAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceAbonentAccess>(
        ServicesActionTypes.DeleteServiceAbonentAccess
      ),
      withLatestFrom(
        this.serviceFacade.flats$,
        this.serviceFacade.filteredFlats$
      ),
      switchMap(
        ([action, flats, filteredFlats]: [
          DeleteServiceAbonentAccess,
          ServiceEntrancesFlats,
          ServiceEntrancesFlats
        ]) => {
          return from(
            this.delegationsApiService.deleteDelegation(action.delegationId)
          ).pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.delete_service_abonent_access.success'),
                'success'
              );

              const handledAction =
                ServicesConnectionHandler.handleDeleteServiceAbonentAccess(
                  flats,
                  filteredFlats,
                  action.entranceId,
                  action.delegationId
                );

              return new DeleteServiceAbonentAccessSuccess(
                handledAction.flats,
                handledAction.filteredFlats
              );
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.delete_service_abonent_access.failed', {
                  text: parseError(error)
                })
              );
              return of(new DeleteServiceAbonentAccessFailure());
            })
          );
        }
      )
    )
  );

  DelegateServiceAbonentAccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DelegateServiceAbonentAccess>(
        ServicesActionTypes.DelegateServiceAbonentAccess
      ),
      withLatestFrom(
        this.serviceFacade.flats$,
        this.serviceFacade.filteredFlats$
      ),
      switchMap(
        ([action, flats, filteredFlats]: [
          DelegateServiceAbonentAccess,
          ServiceEntrancesFlats,
          ServiceEntrancesFlats
        ]) => {
          const request: CreateDelegationRequest = {
            fromAbonentId: action.fromAbonentId,
            toAbonentPhone: action.toAbonentPhone,
            companyId: action.companyId
          };

          return from(this.connectionService.createDelegation(request)).pipe(
            map((response: CreateDelegationResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.delegate_service_abonent_access.success'),
                'success'
              );

              const handledAction =
                ServicesConnectionHandler.handleDelegateServiceAbonentAccess(
                  flats,
                  filteredFlats,
                  action.entranceId,
                  response
                );

              return new DelegateServiceAbonentAccessSuccess(
                handledAction.flats,
                handledAction.filteredFlats
              );
            }),
            catchError((error: HttpErrorResponse) => {
              let textError = parseError(error);
              if(error.error['error'] === this.ERROR_USER_DOESNT_HAVE_SERVICES){
                textError = this.translate.instant('services.connections.message.delete_service_flat.error.subscriber_without_services');
              }

              this.snackbar.showMessage(
                this.translate.instant('services.connections.message.delegate_service_abonent_access.failed', {
                  text: textError
                })
              );
              return of(new DelegateServiceAbonentAccessFailure());
            })
          );
        }
      )
    )
  );

  FilterServiceFlats$ = createEffect(
    () =>
      this.actions$.pipe(
        debounceTime(500),
        ofType<FilterServiceFlats>(ServicesActionTypes.FilterServiceFlats),
        withLatestFrom(this.serviceFacade.flats$),
        tap(([action, flats]: [FilterServiceFlats, ServiceEntrancesFlats]) =>
          this.serviceFacade.filterFlatsSuccess(
            ServiceFlatsFormatter.filterFlats(flats, action.filter)
          )
        )
      ),
    { dispatch: false }
  );
}
