import { TRANSLATIONS_TUTORIAL_VIDEO_LINK } from '@app/shared/helpers/constants';
import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-rda-translations-instruction',
  templateUrl: './rda-translations-instruction.component.html',
  styleUrls: ['./rda-translations-instruction.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RdaTranslationsInstructionComponent {
  readonly translationsTutorialVideoLink: string = TRANSLATIONS_TUTORIAL_VIDEO_LINK;
}
