<div
  class="flat-card"
  [ngClass]="highlighted">
  <div
    class="flat-card-block"
    [class.flat-card-block--sm]="
      serviceType === ServicesTypes.GATE ||
      serviceType === ServicesTypes.VIDEO_SURVEILLANCE
    ">
    <div class="flat-card-block-info-number">
      <h3 class="flat-card-block-info-number__title">
        <ng-content select="[flat-number]"></ng-content>
      </h3>
    </div>

    <div class="flat-card-block-info-abonents">
      <div
        class="flat-card-block-info-abonents-owner"
        [class.flat-card-block-info-abonents-owner--hidden]="
          phoneBlock.childNodes.length == 0
        ">
        <p
          #phoneBlock
          class="flat-card-block-info-abonents-owner__phone">
          <ng-content select="[flat-phone]"></ng-content>
        </p>
      </div>
    </div>
    <div class="flat-services">
      <ng-content select="[flat-services]"></ng-content>
    </div>
    <div class="flat-status">
      <ng-content select="[flat-status]"></ng-content>
    </div>
  </div>
</div>
