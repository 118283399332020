import { DadataSuggestionResponse } from './dadata-suggestion-response.model';

export interface SuggestionsRequestOptions {
  token: string;
  type: string;
  count?: number;
  geoLocation?: boolean;
  floating?: boolean;
  hint?: string | false;
  noSuggestionsHint?: string | false;
  bounds?: 'city' | 'street' | 'house';
  restrict_value?: boolean;
  constraints?: any;
  onSelect?: (suggestion: DadataSuggestionResponse, changed) => void;
  onSelectNothing?: () => void;
  formatSelected?: (suggestion: DadataSuggestionResponse) => string;
  params: {
    status: SuggestionsStatus[]
  };
}

export enum SuggestionsStatus {
  ACTIVE = 'ACTIVE'
}
