import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { Camera, KeysResponse, RdaResponse } from '@app/shared/entities/rd';
import { IntercomPanelConnectRequest, ServiceCamerasV2Response, ServiceCameraUpdateRequest, ServiceConnection, ServiceCreateConnectionRequest, ServiceCreateRequest, ServiceCreateResponse, ServiceEntranceFlatResponse, ServiceInfoResponse, ServiceIntercomConnectRequest, ServiceSignUpsResponse, ServicesList } from '@app/shared/entities/rd/services/models';
import { Address, PagedResponse } from '@app/shared/models';
import { UpdateServiceRequest } from '@app/views/services/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import {ServiceOnvifActiveResponse} from '@app/shared/entities/rd/services/models/service-onvif-active-response.model';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({
  providedIn: 'root'
})
export abstract class OnvifService extends ApiService {
  protected headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.onvifServerUrl, ['v1', 'v2']);
  }

  prepareParams(camera: Camera) {
    const body: Object = {
      hostname: '',
      port: camera?.onvifPort,
      username: camera?.user,
      password: camera?.password,
      private: camera?.private,
      id: camera?.id,
      uid: camera?.rdaUid
    };

    const uriShards = camera?.uri?.split('/');
    const fullUri = uriShards[2]?.split(':');

    if (camera.user && camera.password) {
      body['hostname'] = fullUri ? fullUri[0] : null;
    } else {
      body['username'] = fullUri ? fullUri[0] : null;
      body['password'] = fullUri ? fullUri[1]?.split('@')[0] : null;
      body['hostname'] = fullUri ? fullUri[1]?.split('@')[1] : null;
    }

    return body;
  }

  activeServiceOnvif(camera: Camera): Observable<ServiceOnvifActiveResponse> {

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.post('/active', 1, this.prepareParams(camera), { headers: headers });
  }

  tuneServiceOnvif(camera: Camera): Observable<ServiceOnvifActiveResponse> {

    const headers = new HttpHeaders().set('Content-Type', 'application/json');

    return this.post('/tune', 1, this.prepareParams(camera), { headers: headers });
  }
}
