import {RdeaDate} from '@app/shared/entities/common';
import {TZ_OFFSET_IN_MILLISECONDS} from '../../video-player.constants';
import {TranslateService} from '@ngx-translate/core';
import {Injectable} from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PlyrTemplateHelper {
  static readonly VIDEO_CONTAINER_ID = 'videoContainer';
  static readonly CHANGE_MOD_ID = 'changeMode';
  static readonly GET_VIDEOSHOT_ID = 'getVideoshot';
  static readonly TIMELINE_BLOCK_ID = 'timelineBlock';
  static readonly CONTROLS_ID = 'plyrControls';
  static readonly PLAYLISTS_MENU_BUTTON_ID = 'playlistsMenuButton';
  static readonly PLAYLIST_MENU_ID = 'playlistsMenu';
  static readonly PLAYLISTS_SELECTOR_ID = 'playlistsSelector';
  static readonly PLAYLISTS_SELECTOR_LIST_ID = 'playlistsSelectorList';
  static readonly EVENTS_MENU_ID = 'eventsMenu';
  static readonly EVENTS_MENU_BUTTON_ID = 'eventsMenuButton';
  static readonly EVENTS_OPEN_DOOR_ID = 'eventsOpenDoor';
  static readonly EVENTS_SELECTOR_ID = 'eventsSelector';
  static readonly EVENTS_SELECTOR_LIST_ID = 'eventsSelectorList';
  static readonly PROGRESS_GAPS_ID = 'progressGaps';
  static readonly PROGRESS_BAR_ID = 'progressBar';
  static readonly PROGRESS_BAR_WRAPPER_ID = 'progressBarWrapper';
  static readonly PROGRESS_BAR_PICKER_ID = 'progressBarPicker';
  static readonly PROGRESS_BAR_TOOLTIP_ID = 'progressBarTooltip';
  static readonly PROGRESS_TIME_ID = 'progressTime';
  static readonly EVENTS_ACTION_NEXT_ID = 'eventsActionNext';
  static readonly EVENTS_ACTION_PREVIOUS_ID = 'eventsActionPrevious';
  static readonly SEEKING_FORWARD_ID = 'seekingForwardId';
  static readonly SEEKING_BACKWARD_ID = 'seekingBackwardId';

  static readonly CAPTIONS_WRAPPER_CLASS = 'plyr__captions';
  static readonly VIDEO_WRAPPER_CLASS = 'plyr__video-wrapper';

  static readonly CONTROLS_STYLE = 'plyr__controls';
  static readonly CONTROLS_LIVE_STYLE = `${PlyrTemplateHelper.CONTROLS_STYLE}--live`;
  static readonly CONTROLS_ARCHIVE_STYLE = `${PlyrTemplateHelper.CONTROLS_STYLE}--archive`;

  static readonly VIDEO_WRAPPER_LIVE_STYLE = `${PlyrTemplateHelper.VIDEO_WRAPPER_CLASS}--live`;
  static readonly VIDEO_WRAPPER_ARCHIVE_STYLE = `${PlyrTemplateHelper.VIDEO_WRAPPER_CLASS}--archive`;

  static readonly EVENTS_BUTTON_CLASS = `${PlyrTemplateHelper.CONTROLS_STYLE}-events-button`;
  static readonly EVENTS_BUTTON_IMG_CLASS = `${PlyrTemplateHelper.EVENTS_BUTTON_CLASS}__img`;
  static readonly EVENTS_BUTTON_IMG_CLASS_LEFT = `${PlyrTemplateHelper.EVENTS_BUTTON_CLASS}__img--left`;
  static readonly EVENTS_BUTTON_IMG_CLASS_RIGHT = `${PlyrTemplateHelper.EVENTS_BUTTON_CLASS}__img--right`;
  static readonly EVENTS_BUTTON_CLASS_LEFT = `${PlyrTemplateHelper.EVENTS_BUTTON_CLASS}--left`;
  static readonly EVENTS_BUTTON_CLASS_RIGHT = `${PlyrTemplateHelper.EVENTS_BUTTON_CLASS}--right`;

  static readonly SEEKING_BUTTON_CLASS = `${PlyrTemplateHelper.CONTROLS_STYLE}-seeking-button`;
  static readonly SEEKING_BUTTON_IMG_CLASS = `${PlyrTemplateHelper.EVENTS_BUTTON_CLASS}__img`;
  static readonly SEEKING_BUTTON_IMG_CLASS_LEFT = `${PlyrTemplateHelper.EVENTS_BUTTON_CLASS}__img--left`;
  static readonly SEEKING_BUTTON_IMG_CLASS_RIGHT = `${PlyrTemplateHelper.EVENTS_BUTTON_CLASS}__img--right`;
  static readonly SEEKING_BUTTON_CLASS_LEFT = `${PlyrTemplateHelper.EVENTS_BUTTON_CLASS}--left`;
  static readonly SEEKING_BUTTON_CLASS_RIGHT = `${PlyrTemplateHelper.EVENTS_BUTTON_CLASS}--right`;

  constructor(
    private translate: TranslateService
  ) {
  }

  /**
   * Get Plyr controls HTML template
   * @returns Plyr controls HTML template
   */
  static readonly getControls = (translate: TranslateService): any => `
    <div id="${PlyrTemplateHelper.CONTROLS_ID}" class="${PlyrTemplateHelper.CONTROLS_STYLE} ${PlyrTemplateHelper.CONTROLS_STYLE}--live">
        <div class="video-player-timeline">
                <button
                    type="button"
                    class="${PlyrTemplateHelper.CONTROLS_STYLE}__item plyr__control"
                    data-plyr="play"
                >
                    <svg class="icon--pressed" role="presentation">
                        <use xlink:href="#plyr-pause"></use>
                    </svg>
                    <svg class="icon--not-pressed" role="presentation">
                        <use xlink:href="#plyr-play"></use>
                    </svg>
                    <span class="label--pressed plyr__tooltip" role="tooltip">
                        ${translate.instant('shared.video_player.plyr.template.button.pause')}
                    </span>
                    <span class="label--not-pressed plyr__tooltip" role="tooltip">
                        ${translate.instant('shared.video_player.plyr.template.button.play')}
                    </span>
                </button>

                <div
                    id="${PlyrTemplateHelper.TIMELINE_BLOCK_ID}"
                    style="display:none"
                    class="${PlyrTemplateHelper.CONTROLS_STYLE}__item plyr__progress__container"
                >
                    <div class="plyr__progress">
                        <div id="${PlyrTemplateHelper.PROGRESS_BAR_ID}" class="plyr__custom-progress-bar">
                            <div id="${PlyrTemplateHelper.PROGRESS_BAR_WRAPPER_ID}" class="plyr__custom-progress-bar-wrapper">
                                <canvas class="plyr__gaps" id="${PlyrTemplateHelper.PROGRESS_GAPS_ID}"></canvas>
                            </div>
                        </div>
                        <div class="plyr__custom-picker" id="${PlyrTemplateHelper.PROGRESS_BAR_PICKER_ID}"></div>
                        <span id="${PlyrTemplateHelper.PROGRESS_BAR_TOOLTIP_ID}" role="tooltip" class="plyr__tooltip"></span>
                    </div>
                </div>

                <div
                    id="${PlyrTemplateHelper.PROGRESS_TIME_ID}"
                    class="${PlyrTemplateHelper.CONTROLS_STYLE}__item ${PlyrTemplateHelper.CONTROLS_STYLE}__progress-time"
                ></div>
            </div>

            <div class="video-player-buttons">
                <button
                    type="button"
                    class="${PlyrTemplateHelper.CONTROLS_STYLE}__item plyr__control"
                    data-plyr="fullscreen"
                >
                    <svg class="icon--pressed" role="presentation">
                        <use xlink:href="#plyr-exit-fullscreen"></use>
                    </svg>
                    <svg class="icon--not-pressed" role="presentation">
                        <use xlink:href="#plyr-enter-fullscreen"></use>
                    </svg>
                </button>

                <button
                    id="${PlyrTemplateHelper.CHANGE_MOD_ID}"
                    type="button"
                    class="${PlyrTemplateHelper.CONTROLS_STYLE}__item plyr__control"
                >
                   ${translate.instant('shared.video_player.plyr.template.button.archive')}
                </button>

                <button
                    id="${PlyrTemplateHelper.EVENTS_OPEN_DOOR_ID}"
                    type="button"
                    class="${PlyrTemplateHelper.CONTROLS_STYLE}__item plyr__control ${PlyrTemplateHelper.CONTROLS_STYLE}__item__events"
                >
                    ${translate.instant('shared.video_player.plyr.template.button.open_door')}
                </button>


                <div
                    id="${PlyrTemplateHelper.EVENTS_MENU_ID}"
                    style="display: none;"
                    class="${PlyrTemplateHelper.CONTROLS_STYLE}__item plyr__menu"
                >
                    <button
                        id="${PlyrTemplateHelper.EVENTS_MENU_BUTTON_ID}"
                        aria-haspopup="true"
                        aria-expanded="false"
                        type="button"
                        class="${PlyrTemplateHelper.CONTROLS_STYLE}__item plyr__control ${PlyrTemplateHelper.CONTROLS_STYLE}__item__events"
                    >
                        <img src="assets/icons/ic-call-answer.svg">
                        ${translate.instant('shared.video_player.plyr.template.button.events')}
                    </button>

                    <div class="plyr__menu__container" style="display: none;" id="${PlyrTemplateHelper.EVENTS_SELECTOR_ID}">
                        <div>
                            <div class="plyr__menu__container-wrapper">
                                <div
                                    role="menu"
                                    class="plyr__menu__container-wrapper-content"
                                    id="${PlyrTemplateHelper.EVENTS_SELECTOR_LIST_ID}"
                                >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    id="${PlyrTemplateHelper.PLAYLIST_MENU_ID}"
                    style="display: none;"
                    class="${PlyrTemplateHelper.CONTROLS_STYLE}__item plyr__menu"
                >
                    <button
                        id="${PlyrTemplateHelper.PLAYLISTS_MENU_BUTTON_ID}"
                        aria-haspopup="true"
                        aria-expanded="false"
                        type="button"
                        class="${PlyrTemplateHelper.CONTROLS_STYLE}__item plyr__control"
                    >
                        ${translate.instant('shared.video_player.plyr.template.button.playlists')}
                    </button>

                    <div class="plyr__menu__container" style="display: none;" id="${PlyrTemplateHelper.PLAYLISTS_SELECTOR_ID}">
                        <div>
                            <div class="plyr__menu__container-wrapper">
                                <div
                                    role="menu"
                                    class="plyr__menu__container-wrapper-content"
                                    id="${PlyrTemplateHelper.PLAYLISTS_SELECTOR_LIST_ID}"
                                >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button
                    id="${PlyrTemplateHelper.GET_VIDEOSHOT_ID}"
                    type="button"
                    style="display:none"
                    class="${PlyrTemplateHelper.CONTROLS_STYLE}__item plyr__control"
                >
                    ${translate.instant('shared.video_player.plyr.template.button.get_video_shot')}
                </button>
            </div>
        </div>
  `;

  /**
   * Get playlist button template for playlists menu
   * @param value value for playlist button
   * @param dateInMilliseconds date for playlist button in milliseconds
   * @returns playlist button HTML template for playlists menu
   */
  static readonly getPlaylistMenuButton = (value: string, dateInMilliseconds: number) => `
        <button
            data-plyr="settings"
            type="button"
            class="plyr__control plyr__control--forward"
            role="menuitem"
            aria-haspopup="true"
            value="${value}"
            attr-date="${dateInMilliseconds}"
        >
            ${new RdeaDate(dateInMilliseconds).toDayMonthString()}
        </button>
    `;

  /**
   * Get HTML template event button for events menu
   * @param value value for event button
   * @param dateInMilliseconds date for event button in milliseconds
   * @returns playlist button HTML template for event menu
   */
  static readonly getEventMenuButton = (date: RdeaDate) => `
         <button
             data-plyr="settings"
             type="button"
             class="plyr__control plyr__control--forward"
             role="menuitem"
             aria-haspopup="true"
             value="${date.toDayMonthString()}"
             attr-date="${date.getTime() - TZ_OFFSET_IN_MILLISECONDS}"
         >
             ${new RdeaDate(date.getTime() - TZ_OFFSET_IN_MILLISECONDS).toDateTimeString({date: false, utc0: true})}
         </button>
     `;

  /**
   * Get forward HTML button content template
   * @returns forward HMTL button content template
   */
  static readonly getSeekForwardButtonContent = (translate: TranslateService) => `
            ${translate.instant('shared.video_player.plyr.template.button.plus_10_second')}
            <img
                class="${PlyrTemplateHelper.SEEKING_BUTTON_IMG_CLASS} ${PlyrTemplateHelper.SEEKING_BUTTON_IMG_CLASS_RIGHT}"
                src="assets/icons/ic-forward.svg"
            >
    `;

  /**
   * Get backward HTML button content template
   * @returns backward HMTL button content template
   */
  static readonly getSeekBackwardButtonContent = (translate: TranslateService) => `
        <img
            class="${PlyrTemplateHelper.SEEKING_BUTTON_IMG_CLASS} ${PlyrTemplateHelper.SEEKING_BUTTON_IMG_CLASS_LEFT}"
            src="assets/icons/ic-forward.svg"
        >
            ${translate.instant('shared.video_player.plyr.template.button.minus_10_second')}
    `;

  /**
   * Get next event HTML button content template
   * @returns next event HMTL button content template
   */
  static readonly getNextEventButtonContent = (translate: TranslateService) => `
        ${translate.instant('shared.video_player.plyr.template.button.next_event')}
        <img
            class="${PlyrTemplateHelper.EVENTS_BUTTON_IMG_CLASS} ${PlyrTemplateHelper.EVENTS_BUTTON_IMG_CLASS_RIGHT}"
            src="assets/icons/ic-video-event-previous.svg"
        >
     `;

  /**
   * Get previous event HTML button content template
   * @returns previous event HMTL button content template
   */
  static readonly getPreviousEventButtonContent = (translate: TranslateService) => `
        <img
            class="${PlyrTemplateHelper.EVENTS_BUTTON_IMG_CLASS} ${PlyrTemplateHelper.EVENTS_BUTTON_IMG_CLASS_LEFT}"
            src="assets/icons/ic-video-event-previous.svg"
        >
        ${translate.instant('shared.video_player.plyr.template.button.prev_event')}
     `;

  /**
   * Get empty event HTML content template
   * @returns  empty event HMTL content template
   */
  static readonly getEmptyEventsContent = (translate: TranslateService) => `
    <span>${translate.instant('shared.video_player.plyr.template.message.no_events')}<span>
  `;
}
