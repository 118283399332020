import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { SquaredButtonComponent } from './squared-button.component';

@NgModule({
    imports: [
        CommonModule,
        SharedPipesModule,
        MaterialModule,
    ],
    declarations: [
        SquaredButtonComponent
    ],
    exports: [
        SquaredButtonComponent
    ]
})
export class SquaredButtonModule { }
