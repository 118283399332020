import { HttpErrorResponse } from '@angular/common/http';
import { PbxOnRdaResponse } from '@app/shared/entities/rd';
import { Action } from '@ngrx/store';
import { ServicesActionTypes } from './services-action-types.actions';

export class GetServicePbxOnRda implements Action {
    readonly type = ServicesActionTypes.GetServicePbxOnRda;

    constructor(public rdaUid: string, public page: number = 0, public size: number = 20) { }
}

export class GetServicePbxOnRdaSuccess implements Action {
    readonly type = ServicesActionTypes.GetServicePbxOnRdaSuccess;

    constructor(public pbxOnRda: PbxOnRdaResponse, public rdaUid: string) { }
}

export class GetServicePbxOnRdaFailure implements Action {
    readonly type = ServicesActionTypes.GetServicePbxOnRdaFailure;

    constructor(public error: HttpErrorResponse) { }
}

export class ConnectServicePbxOnRda implements Action {
    readonly type = ServicesActionTypes.ConnectServicePbxOnRda;

    constructor(public pbxOnRda: PbxOnRdaResponse, public rdaUidForConnect: string) { }
}

export class ConnectServicePbxOnRdaSuccess implements Action {
    readonly type = ServicesActionTypes.ConnectServicePbxOnRdaSuccess;

    constructor(public pbxOnRda: PbxOnRdaResponse, public rdaUidForConnect: string) { }
}

export class ConnectServicePbxOnRdaFailure implements Action {
    readonly type = ServicesActionTypes.ConnectServicePbxOnRdaFailure;

    constructor(public error: HttpErrorResponse) { }
}

export class DisconnectServicePbxOnRda implements Action {
    readonly type = ServicesActionTypes.DisconnectServicePbxOnRda;

    constructor(public pbxOnRda: PbxOnRdaResponse, public rdaUidForConnect: string) { }
}

export class DisconnectServicePbxOnRdaSuccess implements Action {
    readonly type = ServicesActionTypes.DisconnectServicePbxOnRdaSuccess;

    constructor(public pbxOnRda: PbxOnRdaResponse, public rdaUidForConnect: string) { }
}

export class DisconnectServicePbxOnRdaFailure implements Action {
    readonly type = ServicesActionTypes.DisconnectServicePbxOnRdaFailure;

    constructor(public error: HttpErrorResponse) { }
}

export class ChangeServicePbxOnRda implements Action {
    readonly type = ServicesActionTypes.ChangeServicePbxOnRda;

    constructor(
        public oldPbxOnRda: PbxOnRdaResponse,
        public newPbxOnRda: PbxOnRdaResponse,
        public rdaUidForConnect: string
    ) { }
}

export class ChangeServicePbxOnRdaSuccess implements Action {
    readonly type = ServicesActionTypes.ChangeServicePbxOnRdaSuccess;

    constructor(public pbxOnRda: PbxOnRdaResponse, public rdaUidForConnect: string) { }
}

export class ChangeServicePbxOnRdaFailure implements Action {
    readonly type = ServicesActionTypes.ChangeServicePbxOnRdaFailure;

    constructor(public error: HttpErrorResponse) { }
}

export type ServicesPbxOnRdaActions =
    | GetServicePbxOnRda
    | GetServicePbxOnRdaSuccess
    | GetServicePbxOnRdaFailure

    | ConnectServicePbxOnRda
    | ConnectServicePbxOnRdaSuccess
    | ConnectServicePbxOnRdaFailure

    | DisconnectServicePbxOnRda
    | DisconnectServicePbxOnRdaSuccess
    | DisconnectServicePbxOnRdaFailure

    | ChangeServicePbxOnRda
    | ChangeServicePbxOnRdaSuccess
    | ChangeServicePbxOnRdaFailure;
