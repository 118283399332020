import { Address } from '@app/shared/models';

export interface CreateFlatForAbonentResponse {
    accountId: number;
    address: Address;
    blocked: boolean;
    cameraId: number;
    hardwareIntercomId: number;
    id: number;
    rdaUid: string;
    softwareIntercomId: number;
    translated: number;
    virtual: boolean;
}
