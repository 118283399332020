import { TranslationTuningRequest, TranslationTuningResponse } from '@app/shared/models';
import { Action } from '@ngrx/store';
import { ServicesActionTypes } from './services-action-types.actions';

export class GetTranslationTunings implements Action {
    readonly type = ServicesActionTypes.GetTranslationTunings;

    constructor(public rdaUid: string) { }
}

export class GetTranslationTuningsSuccess implements Action {
    readonly type = ServicesActionTypes.GetTranslationTuningsSuccess;

    constructor(public rdaUid: string, public response: TranslationTuningResponse[]) { }
}

export class GetTranslationTuningsFailure implements Action {
    readonly type = ServicesActionTypes.GetTranslationTuningsFailure;

    constructor() { }
}

export class AddTranslationTuning implements Action {
    readonly type = ServicesActionTypes.AddTranslationTuning;

    constructor(public rdaUid: string, public request: TranslationTuningRequest) { }
}

export class AddTranslationTuningSuccess implements Action {
    readonly type = ServicesActionTypes.AddTranslationTuningSuccess;

    constructor(public rdaUid: string, public response: TranslationTuningResponse) { }
}

export class AddTranslationTuningFailure implements Action {
    readonly type = ServicesActionTypes.AddTranslationTuningFailure;

    constructor() { }
}

export class DeleteTranslationTuning implements Action {
    readonly type = ServicesActionTypes.DeleteTranslationTuning;

    constructor(public rdaUid: string, public translationTuningId: number) { }
}

export class DeleteTranslationTuningSuccess implements Action {
    readonly type = ServicesActionTypes.DeleteTranslationTuningSuccess;

    constructor(public rdaUid: string, public translationTuningId: number) { }
}

export class DeleteTranslationTuningFailure implements Action {
    readonly type = ServicesActionTypes.DeleteTranslationTuningFailure;

    constructor() { }
}

export type ServicesTranslationTuningsActions =
    | GetTranslationTunings
    | GetTranslationTuningsSuccess
    | GetTranslationTuningsFailure

    | AddTranslationTuning
    | AddTranslationTuningSuccess
    | AddTranslationTuningFailure

    | DeleteTranslationTuning
    | DeleteTranslationTuningSuccess
    | DeleteTranslationTuningFailure;
