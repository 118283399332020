import { ActionReducerMap } from '@ngrx/store';
import { PbxOnRdaSearchSelectActions, PbxOnRdaSearchSelectActionTypes } from './pbx-on-rda-search-select.actions';
import { pbxOnRdaSearchSelectInitialState, PbxOnRdaSearchSelectModuleState, PbxOnRdaSearchSelectState } from './pbx-on-rda-search-select.state';

function reducer(
    state = pbxOnRdaSearchSelectInitialState,
    action: PbxOnRdaSearchSelectActions
): PbxOnRdaSearchSelectState {
    switch (action.type) {
        case PbxOnRdaSearchSelectActionTypes.PbxOnRdaSearchSelectGetPbxOnRda: {
            return {
                ...state,
                loading: true
            };
        }

        case PbxOnRdaSearchSelectActionTypes.PbxOnRdaSearchSelectGetPbxOnRdaSuccess: {
            if (
                state.selectedPbxOnRda &&
                action.response.findIndex(rda => rda.select.value === state.selectedPbxOnRda.value) === -1
            ) {
                action.response.unshift({ value: null, select: state.selectedPbxOnRda });
            }

            return {
                ...state,
                loading: false,
                pbxOnRdaList: action.response
            };
        }

        case PbxOnRdaSearchSelectActionTypes.PbxOnRdaSearchSelectGetPbxOnRdaFailure: {
            return {
                ...state,
                loading: false
            };
        }

        case PbxOnRdaSearchSelectActionTypes.PbxOnRdaSearchSelectInitUsingSelectedPbxOnRda: {
            return {
                ...state,
                loading: false,
                selectedPbxOnRda: action.selectedPbxOnRda,
                pbxOnRdaList: [
                    { value: null, select: action.selectedPbxOnRda }
                ]
            };
        }

        case PbxOnRdaSearchSelectActionTypes.PbxOnRdaSearchSelectInitUsingPbxOnRdaList: {
            state.pbxOnRdaList = [];

            for (const pbxOnRda of action.pbxOnRdaList) {
                state.pbxOnRdaList.push({
                    value: pbxOnRda,
                    select: { text: pbxOnRda.pbxOnRdaUid, value: pbxOnRda.pbxOnRdaUid }
                });

                if (state.pbxOnRdaList?.length > 0) {
                    state.selectedPbxOnRda = state.pbxOnRdaList[0].select;
                }
            }

            return {
                ...state
            };
        }

        case PbxOnRdaSearchSelectActionTypes.SelectPbxOnRdaFromPbxOnRdaList: {
            return {
                ...state,
                selectedPbxOnRda: state.pbxOnRdaList[action.rdaIdx].select
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
}

export const pbxOnRdaSearchSelectReducer: ActionReducerMap<PbxOnRdaSearchSelectModuleState> = {
    pbxOnRdaSearchSelect: reducer
};
