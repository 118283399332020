<app-dialog-wrapper
  [title]="data.title"
  [componentName]="data.componentName"
  [progress]="(popupService.state$ | async) === 'loading'"
>
  <app-software-intercom-wizard-content
    [steps]="steps$ | async"
    [wizardStep]="wizardStep$ | async"
    [showMenu]="showMenu$ | async"
    [showStepButtons]="showStepButtons$ | async"

    [addressStepState]="addressStepState$ | async"
    [intercomsStepState]="intercomsStepState$ | async"
    [intercomPanelStepState]="intercomPanelStepState$ | async"
    [translationStepState]="translationStepState$ | async"
    [camerasStepState]="camerasStepState$ | async"
    [tariffStepState]="tariffStepState$ | async"
    [checklistStepState]="checklistStepState$ | async"
    [initialWizardStep]="initialWizardStep$ | async"

    [addresses]="addresses$ | async"
    [serviceId]="serviceId$ | async"
    [intercoms]="intercoms$ | async"
    [onvif]="onvif$ | async"
    [cameras]="cameras$ | async"
    [translationTunings]="translationTunings$ | async"
    [camerasLocations]="camerasLocations$ | async"
    [avaliableIntercomPanelsLocations]="avaliableIntercomPanelsLocations$ | async"
    [intercomTypes]="intercomTypes$ | async"
    [intercomPanels]="intercomPanels$ | async"
    [loading]="(popupService.state$ | async) === 'loading'"
  ></app-software-intercom-wizard-content>
</app-dialog-wrapper>
