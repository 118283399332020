import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'map'
})
export class MapPipe implements PipeTransform {
    transform(items: any[], fields: string[]): any {
        if (!items || !fields) {
            return items;
        }

        return items.map(item => {
            let value = JSON.parse(JSON.stringify(item));

            for (const field of fields) {
                value = value?.[field];
            }

            return value;
        });
    }
}
