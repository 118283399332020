import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogWrapperData, DialogWrapperService } from '@app/shared/ui';
import { DeleteServicePopupModel } from './delete-service-popup.model';

@Component({
  selector: 'app-delete-service-popup',
  templateUrl: './delete-service-popup.component.html',
  styleUrls: ['./delete-service-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeleteServicePopupComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<DeleteServicePopupModel, null>,
    public dialogWrapperService: DialogWrapperService
  ) { }

  onDelete() {
    this.data.submit(null);
  }
}
