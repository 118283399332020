<div class="pagination-data-view__wrapper">
  <div *ngIf="config.showSearch" [formGroup]="headerForm" class="pagination-data-view__actions__wrapper">
    <div class="pagination-data-view__actions__search__wrapper">
      <input type="text" pInputText formControlName="search" class="pagination-data-view__actions__search--field"
             [placeholder]="'apartments_video_surveillance.page.search' | translate"
             (keyup.enter)="emitChangeWithDelay(true)"/>
    </div>
    <div class="pagination-data-view__actions__action__wrapper">
      <ng-content select="[header-actions]"></ng-content>
    </div>
  </div>

  <section class="pagination-data-view__grid__wrapper">
    <p-dataView *ngIf="init" #pDataView [value]="data" [layout]="layoutType"
                [trackBy]="trackByFn" [loading]="loading | async" [emptyMessage]="config?.emptyMessage">
      <ng-template pTemplate="header">
        <div *ngIf="config?.header && layoutType === 'grid'" class="pagination-data-view__header__wrapper">
          <ng-container *ngIf="config?.headerTemplate && !config?.headerRenderOptions" [ngTemplateOutlet]="config?.headerTemplate"></ng-container>
          <ng-container *ngIf="!!config?.headerRenderOptions && !config?.headerTemplate ">
            <app-render [component]="config?.headerRenderOptions?.component"
                        [updater]="config?.headerRenderOptions?.updater"></app-render>
          </ng-container>
          <ng-container *ngIf="!config?.headerTemplate && !config?.headerRenderOptions">
            <div *ngFor="let hConfig of config?.header" class="pagination-data-view__header__cell__wrapper"
                 [ngClass]="getHeaderNgClasses(hConfig)"
                 [ngStyle]="{'max-width': sizesMapper.get(hConfig.field).size}"
                 (click)="onSort(hConfig)">
              <ng-container *ngIf="hConfig?.useRender && !!hConfig?.renderOptions">
                <app-render [component]="hConfig?.renderOptions?.component"
                            [updater]="hConfig?.renderOptions?.updater"></app-render>
              </ng-container>
              <ng-container *ngIf="!hConfig?.useRender || (hConfig?.useRender && !hConfig?.renderOptions)">
                <div class="pagination-data-view__header__cell--text"
                     [pTooltip]="!!hConfig?.tooltip ? hConfig?.tooltip : ''">{{ hConfig.name }}
                </div>
              </ng-container>
              <i class="pagination-data-view__header__cell--sort-icon" [ngClass]="getSortIconClass(hConfig)"
                 aria-hidden="true"></i>
            </div>
          </ng-container>
        </div>
      </ng-template>
      <ng-template let-item pTemplate="listItem">
        <div class="pagination-data-view__content__wrapper pagination-data-view__content-list__wrapper">
          <ng-container *ngIf="config?.listRowTemplate && !config?.listRowRenderOptions" [ngTemplateOutlet]="config?.listRowTemplate"
                        [ngTemplateOutletContext]="{item: item}"></ng-container>
          <ng-container *ngIf="!!config?.listRowRenderOptions && !config?.listRowTemplate">
            <app-render [component]="config?.listRowRenderOptions?.component" [updater]="config?.listRowRenderOptions?.updater"
                        [rendererData]="item"></app-render>
          </ng-container>
          <ng-container *ngIf="!config?.listRowTemplate && !config?.listRowRenderOptions">
            <div *ngFor="let gConfig of config?.grid" class="pagination-data-view__content-list__cell__wrapper"
                 [ngClass]="{'pagination-data-view__content-list__cell__action__wrapper': !((gConfig?.isAction && sizesMapper.get(gConfig.field).name) || !gConfig?.isAction)}">
              <div *ngIf="(gConfig?.isAction && sizesMapper.get(gConfig.field).name) || !gConfig?.isAction"
                   class="pagination-data-view__content-list__cell--title">{{ sizesMapper.get(gConfig.field).name }}:
              </div>
              <ng-container *ngIf="gConfig?.useRender && !!gConfig?.renderOptions">
                <app-render [component]="gConfig?.renderOptions?.component" [updater]="gConfig?.renderOptions?.updater"
                            [data]="item[gConfig.field]" [rendererData]="item"></app-render>
              </ng-container>
              <ng-container *ngIf="!gConfig?.useRender || (gConfig?.useRender && !gConfig?.renderOptions)">
                <div class="pagination-data-view__content-list__cell--text"
                     [pTooltip]="!!gConfig?.tooltip ? gConfig?.tooltip : ''">{{ item[gConfig.field] }}
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-template>
      <ng-template let-item pTemplate="gridItem">
        <div class="pagination-data-view__content__wrapper pagination-data-view__content-grid__wrapper">
          <ng-container *ngIf="config?.gridRowTemplate && !config?.gridRowRenderOptions" [ngTemplateOutlet]="config?.gridRowTemplate"
                        [ngTemplateOutletContext]="{item: item}"></ng-container>
          <ng-container *ngIf="!!config?.gridRowRenderOptions && !config?.gridRowTemplate">
            <app-render [component]="config?.gridRowRenderOptions?.component" [updater]="config?.gridRowRenderOptions?.updater"
                        [rendererData]="item"></app-render>
          </ng-container>
          <ng-container *ngIf="!config?.gridRowTemplate && !config?.gridRowRenderOptions">
            <div *ngFor="let gConfig of config?.grid" class="pagination-data-view__content-grid__cell__wrapper"
                 [ngStyle]="{'max-width': sizesMapper.get(gConfig.field).size}">
              <ng-container *ngIf="gConfig?.useRender && !!gConfig?.renderOptions">
                <app-render [component]="gConfig?.renderOptions?.component" [updater]="gConfig?.renderOptions?.updater"
                            [data]="item[gConfig.field]" [rendererData]="item"></app-render>
              </ng-container>
              <ng-container *ngIf="!gConfig?.useRender || (gConfig?.useRender && !gConfig?.renderOptions)">
                <div class="pagination-data-view__content-grid__cell--text"
                     [pTooltip]="!!gConfig?.tooltip ? gConfig?.tooltip : ''">{{ item[gConfig.field] }}
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </p-dataView>
  </section>
  <div class="pagination-data-view__paginator__wrapper">
    <p-paginator *ngIf="config?.paginator?.show && !isEmpty(data)"
                 dropdownAppendTo="body" [showCurrentPageReport]="true" [showPageLinks]="true" [showFirstLastIcon]="true"
                 [rowsPerPageOptions]="config.paginator?.dropdownValues"
                 [first]="config.paginator?.first"
                 [rows]="config.paginator?.pageSize"
                 [totalRecords]="config.paginator?.totalRecords"
                 [pageLinkSize]="config.paginator?.pageLinkSize"
                 [currentPageReportTemplate]="config.paginator?.currentPageReportTemplate"
                 (onPageChange)="onPageChange($event)"></p-paginator>
  </div>
</div>
