import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { MatSelectChange } from '@angular/material/select';
import { AddRdasToOrderRequest, BatchResponse } from '@app/views/intercom/models';
import { GetAllBatches, GetBatchAdapters, GetBatchAdaptersClear } from '@app/views/intercom/store/actions';
import { getAllBatchesStateSuccess } from '@app/views/intercom/store/states';
import { getBatchAdaptersSuccess } from '@app/views/intercom/store/states/batches/get-batch-adapters.state';
import { PopupBasicComponent } from '@app/shared/templates';
import { RdaResponse } from '@app/shared/entities/rd/rda';
import { SnackbarService } from '@app/shared/components/snackbar';
import { State } from '@app/store/reducers';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-order-rdas-form',
  templateUrl: './order-rdas-form.component.html',
  styleUrls: ['./order-rdas-form.component.scss']
})
export class OrderRdasFormComponent extends PopupBasicComponent implements OnInit, OnDestroy {
  batches$: Observable<BatchResponse[]>;
  adapters: RdaResponse[];
  selectedBatchId: number;
  selectedAdapters: Object;
  selectedBatches: Object;
  selectedBatchAdaptersCount: Object;
  selectedAdaptersCount: number;
  rdaCount: number;
  private onDestroy$: Subject<void>;

  constructor(
    private store: Store<State>,
    private snackbar: SnackbarService,
    @Inject('data') private data: { adapters: RdaResponse[], rdaCount: number },
    @Inject('subject') private subject: Subject<any>,
    private translate: TranslateService
  ) {
    super();
    this.initVariables();
  }

  ngOnInit() {
    this.initSelectedAdaptersFromData();
    this.initStore();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.store.dispatch(new GetBatchAdaptersClear());
  }

  onSelectBatch(event: MatSelectChange) {
    this.store.dispatch(new GetBatchAdapters(event.value));
  }

  onSelectAdapter(event: MatSelectionListChange) {
    const selectedAdapter: RdaResponse = event.option.value as RdaResponse;

    const selected: number = this.selectedAdapters[selectedAdapter.id] ? null : this.selectedBatchId;
    this.selectedAdapters[selectedAdapter.id] = selected;
    this.selectedBatchAdaptersCount[this.selectedBatchId] += selected ? 1 : -1;
    this.selectedAdaptersCount += selected ? 1 : -1;

    for (const adapter of this.adapters) {
      if (!this.selectedAdapters[adapter.id]) {
        this.selectedBatches[this.selectedBatchId] = false;
        return;
      }
    }
    this.selectedBatches[this.selectedBatchId] = true;
  }

  onSelectAllAdapters() {
    const selected = this.selectedBatches[this.selectedBatchId] ? false : true;

    if (!selected) {
      this.selectedAdaptersCount -= this.selectedBatchAdaptersCount[this.selectedBatchId];
      this.selectedBatchAdaptersCount[this.selectedBatchId] = 0;
      this.adapters.map(adapter => this.selectedAdapters[adapter.id] = null);
    } else {
      for (const adapter of this.adapters) {
        if (this.selectedAdaptersCount === this.rdaCount) {
          break;
        }
        this.selectedAdaptersCount += 1;
        this.selectedBatchAdaptersCount[this.selectedBatchId] += 1;
        this.selectedAdapters[adapter.id] = this.selectedBatchId;
      }
    }
    this.selectedBatches[this.selectedBatchId] = selected;
  }

  isDisabled(adapterId: number) {
    return this.selectedAdaptersCount === this.rdaCount && !this.selectedAdapters[adapterId] ? true : false;
  }

  onSubmit() {
    const rdaIds: number[] = this.getSelectedAdaptersIds();

    if (rdaIds.length > 0) {
      const request: AddRdasToOrderRequest = { rdaIds };
      this.subject.next(request);
    } else {
      this.snackbar.showMessage(
        this.translate.instant('intercom.orders.card.form.rdas.message.submit.failed'),
        'info'
      );
    }
  }

  private getSelectedAdaptersIds(): number[] {
    return Object
      .keys(this.selectedAdapters).map(key => {
        if (this.selectedAdapters[key]) {
          return Number.parseInt(key, 10);
        }
      })
      .filter(id => id);
  }

  private initVariables() {
    this.selectedAdapters = {};
    this.selectedBatches = {};
    this.selectedBatchAdaptersCount = {};
  }

  private initStore() {
    this.onDestroy$ = new Subject();
    this.initStoreListeners();
    this.initStoreSelectors();
    this.store.dispatch(new GetAllBatches());
  }

  private initStoreListeners() {
    this.store.pipe(select(getBatchAdaptersSuccess), takeUntil(this.onDestroy$))
      .subscribe(resp => {
        if (resp) {
          this.adapters = resp;
          this.checkSelectedBatchAdapters();
        }
      });
  }

  private initStoreSelectors() {
    this.batches$ = this.store.pipe(
      select(getAllBatchesStateSuccess),
      map(batches => {
        if (batches) {
          this.countSelectedRdas(batches);
        }
        return batches;
      }));
  }

  private initSelectedAdaptersFromData() {
    this.data.adapters.map(adapter => this.selectedAdapters[adapter.id] = adapter.batch.id);
    this.rdaCount = this.data.rdaCount;
    this.selectedAdaptersCount = this.getSelectedAdaptersIds().length;
  }

  private countSelectedRdas(batches: BatchResponse[]) {
    for (const batch of batches) {
      const adaptersCount = Object
        .keys(this.selectedAdapters)
        .map(key => this.selectedAdapters[key] === batch.id)
        .filter(val => val === true).length;

      this.selectedBatchAdaptersCount[batch.id] = adaptersCount;
    }
  }

  private checkSelectedBatchAdapters() {
    for (const adapter of this.adapters) {
      if (!this.selectedAdapters[adapter.id]) {
        return;
      }
    }
    this.selectedBatches[this.selectedBatchId] = true;
  }
}
