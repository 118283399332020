import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AccountSelectionType } from '@app/shared/components/connections/account-selection';
import { SnackbarService } from '@app/shared/components/snackbar';
import { ResourcePath, ResourcesHelper } from '@app/shared/entities/common';
import { Account, ServiceConnection } from '@app/shared/entities/rd';
import { Abonent, Address, EntranceResponse } from '@app/shared/models';
import { Observable } from 'rxjs';
import { AbonentsFromEntrancesHelper } from './abonents-from-entrances-content.helper';
import { AbonentsFromEntrancesConnection, AbonentsFromEntrancesResponse, AbonentsFromEntrancesStatus } from './models';
import { AbonentsFromEntrancesFacade } from './store';

@Component({
  selector: 'app-abonents-from-entrances-content',
  templateUrl: './abonents-from-entrances-content.component.html',
  styleUrls: ['./abonents-from-entrances-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AbonentsFromEntrancesContentComponent implements OnInit, OnDestroy {
  readonly resourcePath: typeof ResourcePath = ResourcePath;

  @Input() loading: boolean;
  @Input() ignoredServices: number[];
  abonent: Abonent;
  address: Address;
  accounts: Account[];
  selectedAll$: Observable<boolean> = this.storeFacade.selectedAll$;
  doneConnections$: Observable<number> = this.storeFacade.doneConnections$;
  totalConnections$: Observable<number> = this.storeFacade.totalConnections$;
  status$: Observable<AbonentsFromEntrancesStatus> = this.storeFacade.status$;
  connections$: Observable<AbonentsFromEntrancesConnection[]> = this.storeFacade.selectConnections$;

  @Input() private entrances!: Pick<EntranceResponse, 'id'>[];
  @Output() private connectionsSelected: EventEmitter<{ selectedConnections: AbonentsFromEntrancesResponse[] }> = new EventEmitter();
  private lastIdx = 0;
  private response: AbonentsFromEntrancesResponse[];

  constructor(
    public resourcesHelper: ResourcesHelper,
    private snackbar: SnackbarService,
    private changeDetectorRef: ChangeDetectorRef,
    private storeFacade: AbonentsFromEntrancesFacade,
    private connectorHelper: AbonentsFromEntrancesHelper
  ) { }

  ngOnInit() {
    this.storeFacade.getAbonentsFromEntrancesAccounts(this.entrances, this.ignoredServices);
    this.storeFacade.addConnectionsLoadedListener((connectionsCount: number) =>
      connectionsCount === 0 && (this.connectionsSelected.emit({ selectedConnections: [] }))
    );
  }

  ngOnDestroy() {
    this.storeFacade.clearState();
  }

  onSelectConnection(selectedValue: ServiceConnection | boolean) {
    if (typeof selectedValue === 'boolean') {
      this.storeFacade.changeAllAbonentsFromEntrancesChecked();
      return;
    }

    this.storeFacade.changeAbonentsFromEntrancesChecked(selectedValue.id);
  }

  onSelectConnections(connections: AbonentsFromEntrancesConnection[]) {
    this.lastIdx = 0;
    this.address = null;

    this.response = connections
      .filter(connection => connection.selected)
      .map((connection: AbonentsFromEntrancesConnection) => {
        const responseConnection = Object.assign({}, connection);
        delete responseConnection.selected;
        return Object.assign(responseConnection, { abonent: connection.account.owner });
      });

    this.onCheckAbonentsAccounts();
  }

  onSelectAccount(selectedAccount: Account, type: AccountSelectionType) {
    switch (type) {
      case AccountSelectionType.NEW_ACCOUNT:
        this.response[this.lastIdx - 1].account = null;
        break;
      case AccountSelectionType.SELCTED_ACCOUNT:
        this.response[this.lastIdx - 1].account =
          this.accounts.find((account: Account) => account.id === selectedAccount.id);
        break;
      default:
        this.lastIdx -= 1;
        this.response.splice(this.lastIdx, 1);
    }

    if (this.response.length < this.lastIdx) {
      this.connectionsSelectedEvent();
      return;
    }

    this.onCheckAbonentsAccounts();
  }

  private async onCheckAbonentsAccounts() {
    this.storeFacade.changeStatus('account-checking');

    this.connectorHelper.getFirstAccountsValidForAccountCheck(
      this.response,
      (accounts: Account[], lastIdx: number) => {
        this.lastIdx = lastIdx;
        this.accounts = accounts;
        this.address = this.response[this.lastIdx - 1].flat.address;
        this.abonent = this.response[this.lastIdx - 1].account.owner;
        this.storeFacade.changeStatus('account-select');
        this.changeDetectorRef.detectChanges();
      },
      this.lastIdx
    );

    this.connectionsSelectedEvent();
  }

  private connectionsSelectedEvent() {
    this.storeFacade.changeStatus('accounts');
    this.connectionsSelected.emit({ selectedConnections: this.response });
  }
}
