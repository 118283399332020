import { Component, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { PopupBasicComponent } from '@app/shared/templates';

@Component({
  selector: 'app-confirm-mailing-send-popup',
  templateUrl: './confirm-mailing-send-popup.component.html',
  styleUrls: ['./confirm-mailing-send-popup.component.scss']
})
export class ConfirmMailingSendPopupComponent extends PopupBasicComponent {
  constructor(@Inject('subject') private subject: Subject<any>) {
    super();
  }

  onSubmit() {
    this.subject.next(true);
  }

}
