import { Injectable, OnDestroy } from '@angular/core';
import { IntercomsListContentModel } from '@app/shared/components';
import { ComponentStore } from '@ngrx/component-store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { initialIntercomsListContentState, IntercomsListContentState } from './intercoms-list-content.state';

@Injectable()
export class IntercomsListContentStore extends ComponentStore<IntercomsListContentState> implements OnDestroy {
    private onDestroy$: Subject<void> = new Subject();

    constructor() {
        super(initialIntercomsListContentState);
    }

    readonly intercoms$: Observable<IntercomsListContentModel[]> = this.select(state => state.intercoms);
    readonly selectedAll$: Observable<boolean> = this.select(state => state.selectedAll);

    readonly setIntercoms = this.updater((state, intercoms: IntercomsListContentModel[]) => {
        return {
            ...state,
            intercoms: intercoms.map((intercom: IntercomsListContentModel) => {
                intercom.selected = state.selectedAll;
                return intercom;
            })
        };
    });

    readonly setSelectedAll = this.updater((state, selectedAll: boolean) => {
        return {
            ...state,
            selectedAll,
            intercoms: state.intercoms.map((intercom: IntercomsListContentModel) => {
                intercom.selected = selectedAll;
                return intercom;
            })
        };
    });

    readonly selectIntercom = this.updater((state, intercomId: number) => {
        const intercomIdx: number = state.intercoms.findIndex(state => state.id === intercomId);
        state.intercoms[intercomIdx].selected = !state.intercoms[intercomIdx].selected;

        return {
            ...state,
            intercoms: state.intercoms.slice(),
            selectedAll: state.intercoms.reduce((acc, next) => {
                return acc && next.selected;
            }, true)
        };
    });

    readonly changeSelectedAll = this.updater((state) => {
        state.selectedAll = !state.selectedAll;
        state.intercoms.forEach(intercom => intercom.selected = state.selectedAll);

        return {
            ...state,
            intercoms: state.intercoms.slice()
        };
    });

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }

    addSelectedIntercomsListener(cb: (intercoms: IntercomsListContentModel[]) => void) {
        this.intercoms$
            .pipe(
                takeUntil(this.onDestroy$),
                map((intercoms: IntercomsListContentModel[]) =>
                    intercoms.filter((intercom: IntercomsListContentModel) => !!intercom.selected)
                )
            )
            .subscribe((intercoms: IntercomsListContentModel[]) => cb(intercoms));
    }
}
