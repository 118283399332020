import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { AbonentConnectionToolModule, AccountSelectionModule, PhoneInputModule } from '@app/shared/components';
import { DialogWrapperModule, InfoBlockModule, InfoTableModule } from '@app/shared/ui';
import { ServiceConnectionPopupComponent } from './service-connection-popup.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        DialogWrapperModule,
        ReactiveFormsModule,
        PhoneInputModule,
        AbonentConnectionToolModule,
        AccountSelectionModule,
        InfoBlockModule,
        InfoTableModule,

    ],
    declarations: [
        ServiceConnectionPopupComponent
    ],
    exports: [
        ServiceConnectionPopupComponent
    ]
})
export class ServiceConnectionPopupModule { }
