export interface GetSignUpsV2Query {
  abonentId?: number;
  isFreeAddress?: boolean;
  process?: boolean;
  searchString?: string;
  status?: string[];
  withoutCompany?: boolean;
  byRegion?: boolean;
  signUpType?: 'CONNECTION' | 'DISCONNECTION' | '';
}
