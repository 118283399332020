<div
  class="flat-service"
  *ngIf="flat.services && flat.services[serviceType]; else noService">
  <div
    *ngIf="xlgWDownBreakpoint"
    class="flat-service__title"
  >
    <h4>{{ title }}</h4>
    <mat-icon class="title-icon" svgIcon="info"></mat-icon>
  </div>
  <div class="flat-service__slider">
    <mat-icon
      *ngIf="isSWIntercom && !flat.flat.virtual"
      class="flat-service__tube"
      matTooltip="{{ 'shared.flat.service.tube_in_flat' | translate }}"
      matTooltipClass="tooltip"
      svgIcon="tube-dark"></mat-icon>
    <mat-slide-toggle
      class="flat-service__toggle"
      [labelPosition]="'before'"
      [checked]="toggleStatus"
      [color]="toggleStatus ? 'success' : 'error'"
      (change)="onBlockService($event, flat)"
      [disabled]="isAccountBlocked"></mat-slide-toggle>
    <span class="flat-service__status">
      <ng-container *ngIf="!isAccountBlocked && isServiceActive">
        {{ 'shared.flat.service.enum.state.enabled' | translate }}
      </ng-container>
      <ng-container *ngIf="!isAccountBlocked && !isServiceActive">
        {{ 'shared.flat.service.enum.state.disabled' | translate }}
      </ng-container>
      <ng-container *ngIf="isAccountBlocked">
        {{ 'shared.flat.service.enum.state.blocked' | translate }}
      </ng-container>
    </span>
  </div>
  <div class="flat-service__tariff">
    <app-flat-tariff
      *ngIf="abonentsBillingEnabled"
      [content]="flat.services[serviceType].tariff"
      [serviceId]="flat.services[serviceType].id"></app-flat-tariff>
  </div>
</div>
<ng-template #noService>
  <div class="no-service">
    <div
      *ngIf="xlgWDownBreakpoint"
      class="no-service__title">
      <h4>{{ title }}</h4>
      <mat-icon class="title-icon" svgIcon="info"></mat-icon>
    </div>
    <div class="no-service__slider">
      <button
        mat-button
        class="no-service-button"
        (click)="onAddService(address, flat)"
        [disabled]="isAccountBlocked">
        <mat-icon
          *ngIf="mdWUpBreakpoint"
          svgIcon="add-sm"
          class="add-icon-large"></mat-icon>
        <span class="flat-service__status">
          {{ isHWIntercom ? ('shared.flat.service.button.manage' | translate | lowercase) : ('shared.flat.service.button.connect' | translate | lowercase) }}
        </span>
        <mat-icon
          *ngIf="!mdWUpBreakpoint"
          svgIcon="add-sm"
          class="add-icon-large"></mat-icon>
      </button>
    </div>
  </div>
</ng-template>
