import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RdaResponse } from '@app/shared/entities/rd';
import { ServicePageMode } from '@app/views/services/models';
import { ServiceFacade } from '@app/views/services/store';
import { SoftwareIntercomWizardPopupComponent } from '../../software-intercom-wizard-popup.component';
import { SoftwareIntercomWizardPopupStep } from './../../models';
import { SoftwareIntercomWizardContentChecklistStep } from './software-intercom-wizard-content-checklist-step.model';

@Component({
  selector: 'app-software-intercom-wizard-content-checklist',
  templateUrl: './software-intercom-wizard-content-checklist.component.html',
  styleUrls: ['./software-intercom-wizard-content-checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SoftwareIntercomWizardContentChecklistComponent {
  readonly checklistSteps: typeof SoftwareIntercomWizardContentChecklistStep = SoftwareIntercomWizardContentChecklistStep;

  @Input() intercom: RdaResponse;
  selectedCheckpoint = 1;
  lastCheckpointStep = 1;

  @Output() private changeStep: EventEmitter<{ stepIdx: number }> = new EventEmitter();

  constructor(
    private serviceFacade: ServiceFacade,
    private dialogRef: MatDialogRef<SoftwareIntercomWizardPopupComponent>
  ) { }

  onNextStep() {
    this.selectedCheckpoint++;
  }

  onNextLastCheckpointStep() {
    this.lastCheckpointStep++;
  }

  onChangeStep(step: SoftwareIntercomWizardContentChecklistStep) {
    switch (step) {
      case SoftwareIntercomWizardContentChecklistStep.INTERCOMS:
        this.changeStep.emit({ stepIdx: SoftwareIntercomWizardPopupStep.INTERCOMS });
        break;
      case SoftwareIntercomWizardContentChecklistStep.PHYSICAL_TUBE:
        this.serviceFacade.setPageMode(ServicePageMode.ABONENTS);
        this.dialogRef.close();
        break;
      case SoftwareIntercomWizardContentChecklistStep.TEST_ABONENT:
        this.serviceFacade.setPageMode(ServicePageMode.ABONENTS);
        this.dialogRef.close();
        break;
    }
  }
}
