import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {PersonalSurveillance} from '@app/shared/entities/rd/services/models/personal-surveillance.model';
import {PaginationDataView} from '@app/shared/components/pagination-data-view/pagination-data-view.component';
import {
  TableAVSCameraStatuses,
  TableAVSConverted,
  TableAVSEnum
} from '@app/views/services/submodules/apartment-video-surveillance/pipes';
import {GridRenderAction} from '@app/shared/components/render/render.component';
import {
  AvsGridActionsComponent
} from '@app/views/services/submodules/apartment-video-surveillance/components/sipmple/avs-grid-actions/avs-grid-actions.component';
import {BehaviorSubject, of, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {
  TextWithActionComponent
} from '@app/shared/components/table-with-server-pagination/twsp-cells-components/text-with-action/text-with-action.component';
import {PhonePipe} from '@app/shared/pipes';
import {ServiceApiService} from '@app/shared/entities/rd';
import {Router} from '@angular/router';
import {catchError, map, mergeMap, takeUntil} from 'rxjs/operators';
import {
  ApartmentVideoAddService,
  ApartmentVideoAddServiceComponent,
  ApartmentVideoCameraConnect,
  ApartmentVideoCameraConnectComponent
} from '@app/views/services/submodules/apartment-video-surveillance/components/modals';
import {DialogService} from 'primeng/dynamicdialog';
import {SnackbarService} from '@app/shared/components';
import {
  ListStatusesCellComponent
} from '@app/shared/components/table-with-server-pagination/twsp-cells-components/list-statuses-cell/list-statuses-cell.component';
import {
  ApartmentVideoRemoveService,
  ApartmentVideoRemoveServiceComponent
} from '@app/views/services/submodules/apartment-video-surveillance/components/modals/apartment-video-remove-service/apartment-video-remove-service.component';
import {isEmpty} from 'lodash';
import {
  ApartmentVideoSurveillanceActionsService
} from '@app/views/services/submodules/apartment-video-surveillance/services/apartment-video-surveillance-actions.service';
import {
  ApartmentVideoCameraInfoComponent
} from '@app/views/services/submodules/apartment-video-surveillance/components/modals/apartment-video-camera-info/apartment-video-camera-info.component';
import {LoaderService} from '@app/shared/entities';
import {
  ApartmentVideoChangeTariff,
  ApartmentVideoChangeTariffComponent
} from '@app/views/services/submodules/apartment-video-surveillance/components/modals/apartment-video-change-tariff/apartment-video-change-tariff.component';

export namespace ApartmentVSSettingsTab {
  export enum TableFields {
    subscriber = 'subscriber',
    name = 'name',
    company = 'company',
    supportPhone = 'supportPhone',
    rate = 'rate',
    status = 'status',
    uri = 'uri',
    id = 'id',
    location = 'location',
    actions = 'actions',
  }

  export interface OwnerTableView {
    id: number;
    [TableFields.subscriber]: string;
    [TableFields.name]: string;
    [TableFields.company]: string;
    [TableFields.supportPhone]: string;
    [TableFields.rate]: string;
    row: PersonalSurveillance.AccountResponse;
  }

  export interface CameraTableView {
    [TableFields.status]: TableAVSCameraStatuses[];
    [TableFields.uri]: string;
    [TableFields.id]: number;
    [TableFields.location]: string;
    row: PersonalSurveillance.CameraFull;
  }
}

@Component({
  selector: 'app-apartment-video-service-settings',
  templateUrl: './apartment-video-service-settings.component.html',
  styleUrls: ['./apartment-video-service-settings.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ApartmentVideoServiceSettingsComponent implements OnInit, OnDestroy {
  protected readonly isEmpty = isEmpty;
  public readonly TableFields = ApartmentVSSettingsTab.TableFields;
  public readonly ConfirmType = ApartmentVideoRemoveService.ConfirmType;
  @Input() public serviceInfo: PersonalSurveillance.ServiceByIdResponse;
  @Output() public reloadData = new EventEmitter<boolean>();

  public get isServiceBlocked(): boolean {
    return this.serviceInfo?.isBlocked;
  }

  public waitOwnerData$ = new BehaviorSubject<boolean>(true);
  public ownerDataViewConfig: PaginationDataView.Config<PersonalSurveillance.AccountResponse>;
  public ownerDataView: ApartmentVSSettingsTab.OwnerTableView[] = [];
  public waitCamerasData$ = new BehaviorSubject<boolean>(true);
  public camerasDataViewConfig: PaginationDataView.Config<PersonalSurveillance.CameraFull>;
  public camerasDataView: ApartmentVSSettingsTab.CameraTableView[] = [];

  private phonePipe = new PhonePipe();
  private destroy = new Subject<void>();

  constructor(
    private serviceApiService: ServiceApiService,
    private translateService: TranslateService,
    private router: Router,
    private dialogService: DialogService,
    private snackbar: SnackbarService,
    private apartmentVideoSurveillanceActionsService: ApartmentVideoSurveillanceActionsService,
    private loaderService: LoaderService,
  ) {
  }

  public ngOnInit(): void {
    this.ownerDataView = this.serviceInfo?.accounts.map((acc) => {
      return <ApartmentVSSettingsTab.OwnerTableView>{
        id: acc.id,
        [this.TableFields.subscriber]: acc?.owner?.phone || '',
        [this.TableFields.name]: `${this.serviceInfo?.customName || this.serviceInfo.name}`,
        [this.TableFields.company]: acc?.company?.shortName || '',
        [this.TableFields.supportPhone]: '',
        [this.TableFields.rate]: this.serviceInfo?.tariff ?
          `${this.serviceInfo?.tariff} ${this.translateService.instant('currency')}` :
          this.translateService.instant('apartments_video_surveillance.table.unknown'),
        row: acc
      };
    });
    this.camerasDataView = this.serviceInfo?.cameras?.map((camera) => {
      return <ApartmentVSSettingsTab.CameraTableView>{
        id: camera.id,
        [this.TableFields.status]: [{id: camera?.id, active: camera?.active}],
        [this.TableFields.uri]: camera?.uri || '',
        [this.TableFields.id]: camera?.id || '',
        [this.TableFields.location]: camera?.location?.name,
        row: camera
      };
    });
    this.ownerDataViewConfig = this.getOwnerDataViewConfig();
    this.camerasDataViewConfig = this.getCamerasDataViewConfig();
    this.waitOwnerData$.next(false);
    this.waitCamerasData$.next(false);
  }

  public ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
  }

  public renameService(): void {
    this.dialogService.open(
      ApartmentVideoAddServiceComponent,
      {
        header: this.translateService.instant('apartments_video_surveillance.popup.renaming_service'),
        data: {
          rename: true,
          serviceName: this.serviceInfo?.customName || this.serviceInfo?.name
        },
        width: '499px',
        styleClass: 'apartment-video-add-service__modal',
        closeOnEscape: true,
        dismissableMask: true,
        closable: true,
        resizable: false,
        draggable: true,
      }
    ).onClose
      .pipe(
        takeUntil(this.destroy),
        mergeMap((output: ApartmentVideoAddService.Output) => {
            if (output?.formData?.serviceName) {
              return this.serviceApiService.update(this.serviceInfo.id, {customName: output.formData.serviceName})
                .pipe(map(() => output));
            }
            return of(null);
          }
        )
      )
      .subscribe((response: ApartmentVideoAddService.Output | null) => {
        if (response) {
          this.snackbar.showMessage(
            this.translateService.instant('apartments_video_surveillance.popup.renaming_service_success'),
            'success'
          );
          this.reloadData.emit(true);
        }
      });
  }

  public onDeleteItem(confirmType: ApartmentVideoRemoveService.ConfirmType, name: number | string): void {
    let header: string;
    switch (confirmType) {
      case ApartmentVideoRemoveService.ConfirmType.RemovingAVService: {
        header = this.translateService.instant('apartments_video_surveillance.popup.disable_service');
        break;
      }
      case ApartmentVideoRemoveService.ConfirmType.RemovingCamera: {
        header = this.translateService.instant('apartments_video_surveillance.popup.deleting_camera');
        break;
      }
      case ApartmentVideoRemoveService.ConfirmType.BlockingAVService: {
        header = this.translateService.instant('apartments_video_surveillance.popup.block_service');
        break;
      }
      case ApartmentVideoRemoveService.ConfirmType.UnblockingAVService: {
        header = this.translateService.instant('apartments_video_surveillance.popup.unblock_service');
        break;
      }
    }
    this.dialogService.open(
      ApartmentVideoRemoveServiceComponent,
      {
        header,
        data: {name, confirmType},
        width: '499px',
        closeOnEscape: true,
        dismissableMask: true,
        closable: true,
        resizable: false,
        draggable: true,
      }
    ).onClose
      .pipe(
        takeUntil(this.destroy),
        mergeMap((output: { confirm: boolean }) => {
            if (output?.confirm) {
              this.loaderService.loaderState = {state: true, type: 'page'};
              switch (confirmType) {
                case ApartmentVideoRemoveService.ConfirmType.BlockingAVService: {
                  return this.apartmentVideoSurveillanceActionsService.blockingAVService(this.serviceInfo.id);
                }
                case ApartmentVideoRemoveService.ConfirmType.UnblockingAVService: {
                  return this.apartmentVideoSurveillanceActionsService.unblockingAVService(this.serviceInfo.id);
                }
                case ApartmentVideoRemoveService.ConfirmType.RemovingAVService: {
                  return this.apartmentVideoSurveillanceActionsService.removeService(this.serviceInfo.id, output);
                }
                case ApartmentVideoRemoveService.ConfirmType.RemovingCamera: {
                  return this.serviceApiService.disconnectCamera(this.serviceInfo.id, name as number).pipe(
                    map(() => true),
                    catchError((err) => {
                      this.snackbar.showMessage(err?.error?.error || 'Some error :(', 'error');
                      return of(false);
                    })
                  );
                }
                default: {
                  return of(false);
                }
              }
            }
            return of(output);
          }
        )
      )
      .subscribe((output: boolean) => {
        this.loaderService.loaderState = {state: false, type: 'page'};
        if (output) {
          switch (confirmType) {
            case ApartmentVideoRemoveService.ConfirmType.BlockingAVService: {
              this.snackbar.showMessage(
                this.translateService.instant('apartments_video_surveillance.popup.block_service_success'),
                'success'
              );
              this.reloadData.emit(true);
              break;
            }
            case ApartmentVideoRemoveService.ConfirmType.UnblockingAVService: {
              this.snackbar.showMessage(
                this.translateService.instant('apartments_video_surveillance.popup.unblock_service_success'),
                'success'
              );
              this.reloadData.emit(true);
              break;
            }
            case ApartmentVideoRemoveService.ConfirmType.RemovingAVService: {
              this.snackbar.showMessage(
                this.translateService.instant('apartments_video_surveillance.popup.deleting_service_success'),
                'success'
              );
              this.router.navigate(['/apartment-video-surveillance']);
              break;
            }
            case ApartmentVideoRemoveService.ConfirmType.RemovingCamera: {
              this.snackbar.showMessage(
                this.translateService.instant('apartments_video_surveillance.popup.deleting_camera_success'),
                'success'
              );
              this.reloadData.emit(true);
              break;
            }
            default: {
              this.reloadData.emit(true);
              break;
            }
          }
        }
      });
  }

  public addOrEditCamera(cameraInfo?: PersonalSurveillance.CameraFull): void {
    this.dialogService.open(
      ApartmentVideoCameraConnectComponent,
      {
        header: !!cameraInfo ?
          this.translateService.instant('apartments_video_surveillance.popup.camera_editing') :
          this.translateService.instant('apartments_video_surveillance.popup.camera_connection'),
        data: {serviceId: this.serviceInfo.id, cameraInfo},
        width: '499px',
        closeOnEscape: true,
        dismissableMask: true,
        closable: true,
        resizable: false,
        draggable: true,
      }
    ).onClose
      .pipe(takeUntil(this.destroy))
      .subscribe((output: ApartmentVideoCameraConnect.Output) => {
        if (output) {
          this.reloadData.emit(true);
        }
      });
  }

  private showCameraInfo(cameraInfo?: PersonalSurveillance.CameraFull): void {
    this.dialogService.open(
      ApartmentVideoCameraInfoComponent,
      {
        header: this.translateService.instant('apartments_video_surveillance.popup.about_camera'),
        data: {cameraInfo},
        width: '499px',
        closeOnEscape: true,
        dismissableMask: true,
        closable: true,
        resizable: false,
        draggable: true,
      }
    ).onClose.pipe(takeUntil(this.destroy)).subscribe();
  }

  private editRate(data: PersonalSurveillance.AccountResponse): void {
    this.dialogService.open(
      ApartmentVideoChangeTariffComponent,
      {
        header: this.translateService.instant('apartments_video_surveillance.popup.editing_tariff'),
        data: <ApartmentVideoChangeTariff.MInput>{serviceInfo: this.serviceInfo},
        width: '499px',
        closeOnEscape: true,
        dismissableMask: true,
        closable: true,
        resizable: false,
        draggable: true,
      }
    ).onClose
      .pipe(takeUntil(this.destroy))
      .subscribe((output: ApartmentVideoCameraConnect.Output) => {
        if (output) {
          this.snackbar.showMessage(
            this.translateService.instant('apartments_video_surveillance.popup.edit_tariff_success'),
            'success'
          );
          this.reloadData.emit(true);
        }
      });

  }

  private navigateToOwner(data: PersonalSurveillance.AccountResponse): void {
    this.router.navigate(['abonents/subscriber', data.owner.id]);
  }

  private getOwnerDataViewConfig(): PaginationDataView.Config<ApartmentVSSettingsTab.OwnerTableView> {
    const getTableStr = (path: string) => this.translateService.instant(`apartments_video_surveillance.table.${path}`);
    return {
      id: 'avs-settings-owner-table',
      key: 'id',
      emptyMessage: this.translateService.instant('apartments_video_surveillance.page.no_data_found'),
      sizes: [
        {field: this.TableFields.subscriber, width: 20, sizeType: '%'},
        {field: this.TableFields.name, width: 50, sizeType: '%'},
        {field: this.TableFields.company, width: 150},
        {field: this.TableFields.supportPhone, width: 250},
        {field: this.TableFields.rate, width: 150},
      ],
      header: [
        {name: getTableStr('subscriber'), field: this.TableFields.subscriber},
        {name: getTableStr('name'), field: this.TableFields.name},
        {name: getTableStr('company'), field: this.TableFields.company},
        {name: getTableStr('support_phone'), field: this.TableFields.supportPhone},
        {name: getTableStr('tariff'), field: this.TableFields.rate},
      ],
      grid: [
        {
          field: this.TableFields.subscriber,
          useRender: true,
          renderOptions: <GridRenderAction<TextWithActionComponent, string, ApartmentVSSettingsTab.OwnerTableView>>{
            component: TextWithActionComponent,
            updater: (componentInstance, data, rowData: ApartmentVSSettingsTab.OwnerTableView) => {
              componentInstance.text = this.phonePipe.transform(data?.toString());
              componentInstance.actionsRight = [
                {
                  label: '', icon: 'pi pi-arrow-right', action: () => this.navigateToOwner(rowData.row),
                  styleClass: 'p-button-rounded p-button-info p-button-outlined', className: 'grid-actions grid-actions--gray'
                }
              ];
            }
          }
        },
        {
          field: this.TableFields.name,
          useRender: true,
          renderOptions: <GridRenderAction<TextWithActionComponent, string, ApartmentVSSettingsTab.OwnerTableView>>{
            component: TextWithActionComponent,
            updater: (componentInstance, data, rowData: ApartmentVSSettingsTab.OwnerTableView) => {
              componentInstance.text = data;
              componentInstance.actionsRight = [
                {
                  label: '', icon: 'pi pi-pencil', action: () => this.renameService(),
                  tooltip: this.translateService.instant('apartments_video_surveillance.popup.renaming_service'),
                  styleClass: 'p-button-rounded p-button-info p-button-outlined', className: 'grid-actions'
                }
              ];
            }
          }
        },
        {field: this.TableFields.company},
        {field: this.TableFields.supportPhone},
        {
          field: this.TableFields.rate,
          useRender: true,
          isAction: true,
          renderOptions: <GridRenderAction<TextWithActionComponent, string, ApartmentVSSettingsTab.OwnerTableView>>{
            component: TextWithActionComponent,
            updater: (componentInstance, data, rowData: ApartmentVSSettingsTab.OwnerTableView) => {
              componentInstance.text = data;
              componentInstance.actionsRight = [
                {
                  label: '', icon: 'pi pi-pencil', action: () => this.editRate(rowData.row),
                  tooltip: this.translateService.instant('apartments_video_surveillance.popup.editing_tariff'),
                  tooltipPosition: 'top',
                  styleClass: 'p-button-rounded p-button-info p-button-outlined', className: 'grid-actions'
                }
              ];
            }
          }
        },
      ],
      paginator: null
    };
  }

  private getCamerasDataViewConfig(): PaginationDataView.Config<PersonalSurveillance.CameraFull> {
    const getTableStr = (path: string) => this.translateService.instant(`apartments_video_surveillance.table.${path}`);
    return {
      id: 'avs-settings-cameras-table',
      key: 'id',
      emptyMessage: this.translateService.instant('apartments_video_surveillance.page.no_data_found'),
      sizes: [
        {field: this.TableFields.status, width: 20, sizeType: '%'},
        {field: this.TableFields.uri, width: 50, sizeType: '%'},
        {field: this.TableFields.id, width: 150},
        {field: this.TableFields.actions, width: 30, sizeType: '%'},
      ],
      header: [
        {name: getTableStr('status'), field: this.TableFields.status},
        {name: getTableStr('uri'), field: this.TableFields.uri},
        {name: getTableStr('id'), field: this.TableFields.id},
        {name: '', field: this.TableFields.actions, isAction: true},
      ],
      grid: [
        {
          field: this.TableFields.status,
          useRender: true,
          renderOptions: <GridRenderAction<ListStatusesCellComponent, TableAVSCameraStatuses[], TableAVSConverted>>{
            component: ListStatusesCellComponent,
            updater: (componentInstance, data, rowData) => {
              componentInstance.statuses = data;
              componentInstance.useSingleValue = true;
            }
          }
        },
        {field: this.TableFields.uri},
        {field: this.TableFields.id},
        {
          field: TableAVSEnum.actions,
          useRender: true,
          isAction: true,
          renderOptions: <GridRenderAction<AvsGridActionsComponent, ApartmentVSSettingsTab.CameraTableView, ApartmentVSSettingsTab.CameraTableView>>{
            component: AvsGridActionsComponent,
            updater: (componentInstance, data, rowData: ApartmentVSSettingsTab.CameraTableView) => {
              componentInstance.useDots = true;
              componentInstance.items = [
                {
                  label: this.translateService.instant('apartments_video_surveillance.table.about_camera'),
                  styleClass: 'avs-menu-item--usual',
                  command: () => this.showCameraInfo(rowData.row)
                },
                {
                  label: this.translateService.instant('apartments_video_surveillance.table.edit'),
                  styleClass: 'avs-menu-item--usual',
                  command: () => this.addOrEditCamera(rowData.row)
                },
                {
                  label: this.translateService.instant('apartments_video_surveillance.table.delete'),
                  styleClass: 'avs-menu-item--danger',
                  command: () => this.onDeleteItem(ApartmentVideoRemoveService.ConfirmType.RemovingCamera, rowData.row.id.toString())
                },
              ];
            }
          },
        },
      ],
      paginator: null
    };
  }
}
