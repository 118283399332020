import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { ComponentLoaderModule } from '../component-loader/component-loader.module';
import { ButtonWithSpinnerLoaderComponent } from './button-with-spinner-loader.component';

const components = [
    ButtonWithSpinnerLoaderComponent,
];

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ComponentLoaderModule,
    ],
    declarations: components,
    exports: components
})
export class ButtonWithSpinnerLoaderModule { }
