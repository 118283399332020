<mat-card class="card-container rda-card">
  <mat-card-header class="header">
    <div class="title-block">
      <mat-card-title>
        {{ 'entrance.page.rda_card.title' | translate }}
      </mat-card-title>
    </div>
  </mat-card-header>

  <hr>

  <mat-card-content class="rda-card-content">
    <h2 class="rda-card-content-uid" *ngIf="rda?.uid">
      {{ 'entrance.page.rda_card.field.uid' | translate }}:
      {{ rda.uid }}
    </h2>

    <ng-container *ngIf="rda">
      <div class="rda-card-content-status">
        <h3 class="rda-card-content-status__title">
          {{ 'entrance.page.rda_card.field.status' | translate }}:
        </h3>
        <mat-chip
          [disabled]="true"
          [selectable]="false"
          color="{{ rda.active ? 'success' : 'error' }}"
        >
          {{
            rda.active
              ? ('entrance.page.rda_card.enum.status.online' | translate)
              : ('entrance.page.rda_card.enum.status.offline' | translate)
          }}
        </mat-chip>
      </div>

      <div class="rda-card-content-info-block" *ngIf="rda.intercomType?.name">
        <p>
          {{ 'entrance.page.rda_card.field.intercom_type' | translate }}: {{ rda.intercomType?.name }}
          <a target="_blank"
            *ngIf="rda.intercomType?.instructionUrl"
            [href]="rda.intercomType.instructionUrl"
          >
            ({{ 'entrance.page.rda_card.link.instruction_url' | translate }})
          </a>
        </p>

        <mat-icon *ngIf="correctedVersions === false || supportedIntercom === false" class="error-ic">
          error
        </mat-icon>
      </div>

      <p *ngIf="rda?.ipAddress">
        {{ 'entrance.page.rda_card.field.ip_address' | translate }}:
        {{ rda?.ipAddress }}
      </p>

      <p *ngIf="rda?.lastHeartBeatTime">
        {{ 'entrance.page.rda_card.field.last_heart_beat_time' | translate }}:
        {{ rda?.lastHeartBeatTime | date: 'dd.MM.yyyy HH:mm:ss' }}
      </p>

      <ng-container *ngIf="!ipIntercom; else ipIntercomBlock">
        <p *ngIf="rda?.translation">
          {{ 'entrance.page.rda_card.field.translation' | translate }}:
          {{ rda?.translation }}
        </p>

        <div class="rda-card-content-info-block">
          <p>
            {{ 'entrance.page.rda_card.version_info.os' | translate }}:
            {{ rda?.versionInfoOs }}
            <ng-container *ngIf="rda.active" [ngSwitch]="rdosLatest">
              <app-text-badge color="warn" *ngSwitchCase="false">
                {{ 'entrance.page.rda_card.message.update_is_available' | translate | lowercase }}
                {{ gitlabComponentsVersions?.rdosLatest }}
              </app-text-badge>

              <app-text-badge color="success" *ngSwitchCase="true">
                {{ 'entrance.page.rda_card.message.fresh_version' | translate | lowercase }}
              </app-text-badge>
            </ng-container>
          </p>
          <mat-icon *ngIf="!rda.versionInfoOs" class="error-ic">error</mat-icon>
        </div>

        <div class="rda-card-content-info-block">
          <p>
            {{ 'entrance.page.rda_card.version_info.rdaa' | translate }}:
            {{ rda?.versionInfoRdaa }}
            <ng-container *ngIf="rda.active" [ngSwitch]="rdaaLatest">
              <app-text-badge color="warn" *ngSwitchCase="false">
                {{ 'entrance.page.rda_card.message.update_is_available' | translate | lowercase }}
                {{ gitlabComponentsVersions?.rdaaLatest }}
              </app-text-badge>

              <app-text-badge color="success" *ngSwitchCase="true">
                {{ 'entrance.page.rda_card.message.fresh_version' | translate | lowercase }}
              </app-text-badge>
            </ng-container>
          </p>
          <mat-icon *ngIf="!rda?.versionInfoRdaa" class="error-ic">error</mat-icon>
        </div>

        <div class="rda-card-content-info-block" *ngIf="stmDriverAvaliable">
          <p>
            {{ 'entrance.page.rda_card.version_info.stm_driver' | translate }}:
            {{ rda?.versionInfoStmDriver }}
          </p>
          <ng-container *ngIf="rda">
            <mat-icon *ngIf="!rda?.versionInfoStmDriver" class="error-ic">error</mat-icon>
          </ng-container>
        </div>

        <div class="rda-card-content-info-block">
          <p>
            {{ 'entrance.page.rda_card.version_info.stm' | translate }}:
            {{ rda?.versionInfoStm }}
          </p>
          <ng-container *ngIf="rda">
            <mat-icon *ngIf="!rda?.versionInfoStm" class="error-ic">error</mat-icon>
          </ng-container>
        </div>

        <div class="rda-card-content-info-block">
          <p>
            {{ 'entrance.page.rda_card.version_info.shield' | translate }}:
            {{ rda?.versionInfoShield }}
          </p>
          <ng-container *ngIf="rda">
            <mat-icon *ngIf="!rda?.versionInfoShield" class="error-ic">error</mat-icon>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #ipIntercomBlock>
        <p *ngIf="rda?.mode">
          {{ 'entrance.page.rda_card.field.connection_type' | translate }}:
          {{ rda.mode | ipRdaConnectionType }}
        </p>

        <p *ngIf="pbxOnRda && rda.mode === SIPTRUNK">
          {{ 'entrance.page.rda_card.field.pbx_on_rda' | translate }}:
          {{ pbxOnRda.pbxOnRdaUid }}
        </p>

        <p *ngIf="livenessUrl">
          {{ 'entrance.page.rda_card.field.liveness_url' | translate }}:
          {{ livenessUrl }}
        </p>
      </ng-template>

      <hr>

      <p *ngIf="company?.name">
        {{ 'entrance.page.rda_card.field.company' | translate }}:
        {{ company.name }}
      </p>

      <p *ngIf="company?.supportPhone">
        {{ 'entrance.page.rda_card.field.phone' | translate }}:
        {{ company.supportPhone }}
      </p>
    </ng-container>

    <ngx-skeleton-loader
      *ngIf="loading"
      count="1"
      [theme]="{
        'border-radius': '8px',
        'margin-top': '0px',
        'height': '400px'
      }"
    ></ngx-skeleton-loader>

    <app-info-block *ngIf="!rda && !loading">
      <ng-container info-block-text>
        {{ 'entrance.page.rda_card.message.adapter_not_connected' | translate }}
      </ng-container>
    </app-info-block>
  </mat-card-content>
</mat-card>
