import { PbxOnRdaResponse } from '@app/shared/entities/rd/pbx-on-rda/models';
import * as root from '@app/store/reducers';
import { PbxOnRdaPageActionTypes } from './pbx-on-rda.actions';

export interface PbxOnRdaPageModuleState {
    pbxOnRdaPage: PbxOnRdaPageState;
}

export interface PbxOnRdaPageRootState extends root.State {
    'pbx-on-rda': PbxOnRdaPageModuleState;
}

export interface PbxOnRdaPageState {
    content: PbxOnRdaResponse[];
    totalElements: number;
    actions: PbxOnRdaPageActionTypes;
    loading: boolean;
    error: string;
}

export const pbxOnRdaPageInitialState: PbxOnRdaPageState = {
    content: [],
    totalElements: 0,
    actions: null,
    loading: false,
    error: null
};
