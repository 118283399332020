import { Abonent } from '@app/shared/models';

export interface ChatContent {
    abonent: Abonent;
    channel: string;
    id: number;
    incoming: boolean;
    message: string;
    messageDate: number;
    success?: boolean;
}
