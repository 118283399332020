import { Pipe, PipeTransform } from '@angular/core';
import { IUserResponse } from '@app/views/company/models/company-page';

@Pipe({
  name: 'rolesAccess',
  standalone: true
})
export class RolesAccessPipe implements PipeTransform {

  transform(accessMode: boolean, data: IUserResponse): boolean {
    if (!accessMode) { return true; }
    return !data.roleNames.find(user => user.includes('ROLE_OWNER'));
  }

}
