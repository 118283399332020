<div class="duplicate">
  <div class="duplicate-camera">
    <app-camera-thumbnail
      [camera]="wrapperData.body"
      [mode]="mode"
      [connectionInfo]="connectionInfo"
    >
    </app-camera-thumbnail>
  </div>
  <div class="duplicate-config">
    <div class="duplicate-config-item" *ngFor="let info of cameraInfo">
      <div class="duplicate-config-item-header">
        <span class="duplicate-config-item-header__text">{{info.title}}</span>
      </div>
      <div class="duplicate-config-item-data">
        <span class="duplicate-config-item-data__text" [matTooltip]="info.data">{{info.data}}</span>
      </div>
    </div>
  </div>
  <div class="duplicate-info">
    <ng-container *ngIf="isCameraCorrect()">
      <app-button-link
        [link]="link"
        [color]="color"
        class="link">
        <div class="duplicate-info-warning">
          {{ 'shared.camera.already.exists.message.duplicate.warning' | translate }}
        </div>
        <div class="duplicate-info-solution">
          {{ 'shared.camera.already.exists.message.duplicate.warning' | translate }}
        </div>
      </app-button-link>
    </ng-container>
  </div>
  <div class="duplicate-services">
    <h3 class="duplicate-services__header">
      {{ 'shared.camera.already.exists.header.duplicate' | translate }}
    </h3>
    <div *ngFor="let info of servicesInfo">
      <div class="duplicate-services-address">
        <span style="color: #757685">{{info.address}}</span>
      </div>
      <div class="duplicate-services-items">
        <div *ngFor="let service of info.services">
          <mat-icon
            [svgIcon]="service.serviceSvgIcon"
            class="menu-nav-block-item-link__icon">
          </mat-icon>
          <span>{{service.serviceName}}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="duplicate-action">
    <button
      type="submit"
      mat-raised-button
      class="duplicate-actions__button"
      (click)="onSubmit()"
    >
      {{ 'shared.camera.already.exists.button.submit' | translate }}
    </button>
  </div>
</div>
