<div class="payments-list">
  <mat-card class="card-container">
    <mat-card-content>
      <div class="filter-keeper none-padding" [formGroup]="filterForm">
        <mat-form-field
          flats-filters-search
          appearance="outline"
          class="none-padding none-margin"
        >
          <mat-label>
            {{ 'payments.list.field.start' | translate }}
          </mat-label>
          <input
            matInput
            formControlName="startDate"
            [matDatepicker]="startPicker"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          >
          <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>
        <div class="filter-date-divider"></div>
        <mat-form-field
          flats-filters-search
          appearance="outline"
          class="none-padding none-margin"
        >
          <mat-label>
            {{ 'payments.list.field.end' | translate }}
          </mat-label>
          <input
            matInput
            formControlName="endDate"
            [matDatepicker]="endPicker"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          >
          <mat-datepicker-toggle matSuffix [for]="endPicker"></mat-datepicker-toggle>
          <mat-datepicker #endPicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field
          flats-filters-search
          appearance="outline"
          class="none-padding none-margin filter-title"
        >
          <mat-label>
            {{ 'payments.list.field.flats_search' | translate }}
          </mat-label>
          <input
            matInput
            formControlName="searchString"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            spellcheck="false"
          >
        </mat-form-field>
      </div>
      <div class="payment-sum-keeper">
        <div class="payment-sum">
          <div class="payment-sum-value-keeper">
            <img class="payment-sum-icon" src="assets/images/check-lg.png" alt="Success">
            <h4 class="payment-sum-value">{{paymentSum.success.value | currency : paymentSum.success.currency : 'symbol-narrow'}}</h4>
          </div>
          <span class="payment-sum-status">
            {{ 'payments.list.enum.status.paid' | translate }}
          </span>
        </div>
        <div class="payment-sum">
          <div class="payment-sum-value-keeper">
            <img class="payment-sum-icon" src="assets/images/clock-sm.png" alt="Waiting for Payment">
            <h4 class="payment-sum-value">{{paymentSum.pending.value | currency : paymentSum.pending.currency : 'symbol-narrow'}}</h4>
          </div>
          <span class="payment-sum-status">
            {{ 'payments.list.enum.status.wait' | translate }}
          </span>
        </div>
        <div class="payment-sum">
          <div class="payment-sum-value-keeper">
            <img class="payment-sum-icon" src="assets/images/error-lg.png" alt="Blocked">
            <h4 class="payment-sum-value">{{paymentSum.blocked.value | currency : paymentSum.blocked.currency : 'symbol-narrow'}}</h4>
          </div>
          <span class="payment-sum-status">
            {{ 'payments.list.enum.status.blocked' | translate }}
          </span>
        </div>
      </div>
      <div class="payment-sum-actions">
        <div class="payment-sum-actions-left">
          <ng-container *ngFor="let buttonConfig of sortButtonConfig[0]">
            <button
              mat-raised-button
              [color]="buttonConfig.name === activeButton ? 'primary' : 'primary-inverse'"
              (click)="onSort(buttonConfig.name)">
              {{buttonConfig.name}}
            </button>
          </ng-container>
        </div>
        <div class="payment-sum-actions-right">
          <button
            mat-raised-button
            color="primary-inverse"
            class="payment-sum-actions-right-download"
            (click)="onDownloadReport()"
          >
            <mat-icon>download</mat-icon>
            {{ 'payments.list.button.download_report' | translate }}
          </button>
        </div>
      </div>
    </mat-card-content>
    <div class="payment-sum-keeper_mobile">
      <div class="payment-sum">
        <div class="payment-sum-value-keeper">
          <img class="payment-sum-icon" src="assets/images/check-lg.png" alt="Success">
          <h4 class="payment-sum-value">{{paymentSum.success.value | currency : paymentSum.success.currency : 'symbol-narrow'}}</h4>
        </div>
        <span class="payment-sum-status">
            {{ 'payments.list.enum.status.paid' | translate }}
          </span>
      </div>
      <div class="payment-sum">
        <div class="payment-sum-value-keeper">
          <img class="payment-sum-icon" src="assets/images/clock-sm.png" alt="Waiting for Payment">
          <h4 class="payment-sum-value">{{paymentSum.pending.value | currency : paymentSum.pending.currency : 'symbol-narrow'}}</h4>
        </div>
        <span class="payment-sum-status">
            {{ 'payments.list.enum.status.wait' | translate }}
          </span>
      </div>
      <div class="payment-sum">
        <div class="payment-sum-value-keeper">
          <img class="payment-sum-icon" src="assets/images/error-lg.png" alt="Blocked">
          <h4 class="payment-sum-value">{{paymentSum.blocked.value | currency : paymentSum.blocked.currency : 'symbol-narrow'}}</h4>
        </div>
        <span class="payment-sum-status">
            {{ 'payments.list.enum.status.blocked' | translate }}
          </span>
      </div>
    </div>
  </mat-card>

  <div class="payments-list-content">
    <div class="payments-list-content-buttons">
      <ng-container *ngFor="let buttonConfig of sortButtonConfig[1]">
        <a (click)="onCollapseAll(buttonConfig)">
          {{ buttonConfig.name }}
        </a>
      </ng-container>
    </div>
    <ng-container
      *ngIf="paymentList && paymentList.length > 0 && (paymentList | PaymentStatusFilter: activeButton).length !== 0; else emptyData"
    >
      <app-payment-card
        *ngFor="let payment of paymentList | PaymentStatusFilter: activeButton"
        [status]="payment.status"
        [blocked]="payment.isBlocked"
        [paid]="payment.paid"
        [openedState]="openedState"
        (subscriberEvent)="onSubscriberEvent($event)"
      >
        <ng-container payment-phone>
          {{payment.paymentData.receipt.customer.phone | phone}}
        </ng-container>
        <ng-container
          payment-tariff
          [ngTemplateOutlet]="paymentTariff"
          [ngTemplateOutletContext]="{ payment: payment }"
        ></ng-container>
        <ng-container
          payment-tariff-mobile
          [ngTemplateOutlet]="paymentTariff"
          [ngTemplateOutletContext]="{ payment: payment }"
        ></ng-container>
        <ng-container payment-date>
          {{payment.createdAt | date : 'dd.MM.yyyy'}}
        </ng-container>
        <ng-container payment-children>
          <div *ngIf="payment.accountNumber" class="payment-card-block-child-account-number">
            {{ 'payments.list.field.ls' | translate }}: {{payment.accountNumber}}
          </div>
          <ul class="payment-card-block-child-list" *ngIf="payment.paymentData.receipt.items && payment.paymentData.receipt.items.length > 0">
            <li class="payment-card-block-child" *ngFor="let child of payment.paymentData.receipt.items">
              <span class="payment-card-block-child-title">
                {{child.description}}
              </span>
              <span class="payment-card-block-child-amount">
                <ng-container>
                  <ng-container *ngIf="payment.status === paymentStatusType.CONFIRMED_MANUALLY; else paymentOtherStatuses">
                    <img class="payment-card-block-actions-tariff-icon" src="assets/images/check-outline.png" alt="Success">
                  </ng-container>
                  <ng-template #paymentOtherStatuses>
                    <img *ngIf="payment.paid" class="payment-card-block-actions-tariff-icon" src="assets/images/check-lg.png" alt="Success">
                    <img *ngIf="payment.isBlocked" class="payment-card-block-actions-tariff-icon" src="assets/images/error-lg.png" alt="Blocked">
                    <img *ngIf="!payment.paid && !payment.isBlocked" class="payment-card-block-actions-tariff-icon" src="assets/images/clock-sm.png" alt="Waiting">
                  </ng-template>
                </ng-container>
                {{child.amount.value | currency : child.amount.currency : 'symbol-narrow'}}
              </span>
              <div class="payment-card-block-child-date">
                <span>
                  {{payment.createdAt | date : 'dd.MM.yyyy'}}
                  {{payment.paid ? ('payments.list.enum.status.exhibited' | translate | lowercase) : ''}}
                </span>
                <span *ngIf="payment.paid">
                  {{payment.updatedAt | date : 'dd.MM.yyyy'}}
                  {{ 'payments.list.enum.status.paid' | translate | lowercase }}
                </span>
              </div>
              <span *ngIf="!payment.paid" class="payment-card-block-child-settings">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
                  <mat-icon svgIcon="settings"></mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  <div class="triggered-menu-content">
                    <button class="triggered-menu-content__button" (click)="checkPaymentAsPaid(payment)" mat-button color="primary">
                      {{ 'payments.list.button.check_as_paid' | translate }}
                    </button>
                  </div>
                </mat-menu>
              </span>
            </li>
          </ul>
        </ng-container>
      </app-payment-card>
    </ng-container>
    <ng-template #emptyData>
      <app-empty-data></app-empty-data>
    </ng-template>
    <ng-template #paymentTariff let-payment="payment">
      <ng-container>
        <ng-container *ngIf="payment.status === paymentStatusType.CONFIRMED_MANUALLY; else paymentOtherStatuses">
          <img class="payment-card-block-actions-tariff-icon" src="assets/images/check-outline.png" alt="Success">
        </ng-container>
        <ng-template #paymentOtherStatuses>
          <img *ngIf="payment.paid" class="payment-card-block-actions-tariff-icon" src="assets/images/check-lg.png" alt="Success">
          <img *ngIf="payment.isBlocked" class="payment-card-block-actions-tariff-icon" src="assets/images/error-lg.png" alt="Blocked">
          <img *ngIf="!payment.paid && !payment.isBlocked" class="payment-card-block-actions-tariff-icon" src="assets/images/clock-sm.png" alt="Waiting">
        </ng-template>
      </ng-container>
      {{payment.paymentData.amount.value | currency : payment.paymentData.amount.currency : 'symbol-narrow'}}
    </ng-template>
  </div>
</div>

<app-component-loader *ngIf="loading" [background]="true" [sizePx]="40">

</app-component-loader>
