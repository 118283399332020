import {
  Camera,
  IntercomPanelResponse,
  IntercomType,
  IpIntercomKeysRequest,
  KeysResponse,
  RdaResponse,
  ServiceResponse
} from '@app/shared/entities/rd';
import {Address, FlatRange, LogsComponentType, LogsResponse, TranslationTuningResponse} from '@app/shared/models';
import {ServiceActivitySource} from '../models';
import {ServicesActions, ServicesActionTypes} from './actions';
import {servicesInitialState, ServicesModuleState} from './services.state';

export function servicesReducer(
    state: ServicesModuleState = JSON.parse(JSON.stringify(servicesInitialState)), action: ServicesActions
): ServicesModuleState {
    switch (action.type) {
        // General cases
      case ServicesActionTypes.GetServiceSuccess: {
            state.general.service = {
                id: action.response.id,
                name: action.response.name,
                customName: action.response.customName,
                type: action.response.type,
                tariff: action.response.tariff,
            };

            state.general.dependantServices = action.response.dependantServices.map(service =>
                ({ id: service.id, type: service.type }) as Pick<ServiceResponse, 'id' | 'type'>
            );
            state.settings.entrances = action.response.entrances;
            state.settings.rdas = action.response.rdas;
            state.settings.cameras = action.response.cameras;
            state.settings.keys = action.response.keys;

            return { ...state };
        }

        case ServicesActionTypes.GetDependantServiceSuccess: {
            state.general.dependantServicesExtended = state.general.dependantServices
              .filter(service => service.id === action.response.id)
              .map(service => {
                if(service.id === action.response.id){
                  return action.response;
                };
              })
            return { ...state };
        }

        case ServicesActionTypes.UpdateServiceSuccess: {
            state.general.service.customName = action.response.customName ?? null;

            return { ...state };
        }

        case ServicesActionTypes.GetIntercomTypesSuccess: {
            state.general.intercomTypes = action.response;

            return { ...state };
        }

        case ServicesActionTypes.GetCamerasLocationsSuccess: {
            state.general.camerasLocations = action.response;

            return { ...state };
        }

        case ServicesActionTypes.GetKeysLocationsSuccess: {
            state.general.keysLocations = action.response;

            return { ...state };
        }

        case ServicesActionTypes.GetServiceCompanySuccess: {
            state.general.company = action.response;

            return { ...state };
        }

        case ServicesActionTypes.GetComponentsVersionsSuccess: {
            state.general.componentsVersions = action.response;

            return { ...state };
        }

        case ServicesActionTypes.AddDependantService: {
            if (!state.general.dependantServices) {
                state.general.dependantServices = [];
            }

            if (action.dependantServices?.length) {
                state.general.dependantServices = [
                    ...state.general.dependantServices,
                    ...action.dependantServices
                ];
            }

            return { ...state };
        }

        // Entrances cases
        case ServicesActionTypes.AddServiceEntranceSuccess: {
            state.settings.entrances.push(action.entrance.address);
            state.settings.entrances = state.settings.entrances.slice();

            return { ...state };
        }

        case ServicesActionTypes.UpdateServiceEntranceWithPrefixSuccess:
        case ServicesActionTypes.UpdateServiceEntranceSuccess: {
            const entranceIdx: number = state.settings.entrances.findIndex((address: Address) =>
                address.entrance.id === action.entranceId
            );

            Object.keys(action.response).forEach((responseKey: string) =>
                state.settings.entrances[entranceIdx].entrance[responseKey] = action.response[responseKey]
            );

            state.settings.entrances = state.settings.entrances.slice();

            return { ...state };
        }

        case ServicesActionTypes.DeleteServiceEntranceSuccess: {
            const entranceIdx = state.settings.entrances.findIndex((address: Address) =>
                address.entrance.id === action.entranceId
            );

            state.settings.entrances.splice(entranceIdx, 1);
            state.settings.entrances = state.settings.entrances.slice();

            return { ...state };
        }

        case ServicesActionTypes.AddAdditionalFlatRangeSuccess: {
            const entranceIdx: number = state.settings.entrances.findIndex((entrance: Address) =>
                entrance.entrance.id === action.entranceId
            );

            state.settings.entrances[entranceIdx].entrance.additionalFlatRanges.push(action.range);
            state.settings.entrances = state.settings.entrances.slice();

            return { ...state };
        }

        case ServicesActionTypes.UpdateAdditionalFlatRangeSuccess: {
            const entranceIdx: number = state.settings.entrances.findIndex((entrance: Address) =>
                entrance.entrance.id === action.entranceId
            );

            const rangeIdx: number = state.settings.entrances[entranceIdx].entrance.additionalFlatRanges.findIndex((flatRange: FlatRange) =>
                flatRange.id === action.rangeId
            );

            state.settings.entrances[entranceIdx].entrance.additionalFlatRanges[rangeIdx].flatStart = action.range.flatStart;
            state.settings.entrances[entranceIdx].entrance.additionalFlatRanges[rangeIdx].flatEnd = action.range.flatEnd;
            state.settings.entrances = state.settings.entrances.slice();

            return { ...state };
        }

        case ServicesActionTypes.DeleteAdditionalFlatRangeSuccess: {
            const entranceIdx: number = state.settings.entrances.findIndex((entrance: Address) =>
                entrance.entrance.id === action.entranceId
            );

            const rangeIdx: number = state.settings.entrances[entranceIdx].entrance.additionalFlatRanges.findIndex((flatRange: FlatRange) =>
                flatRange.id === action.rangeId
            );

            state.settings.entrances[entranceIdx].entrance.additionalFlatRanges.splice(rangeIdx, 1);
            state.settings.entrances = state.settings.entrances.slice();

            return { ...state };
        }

        // Intercom cases
        case ServicesActionTypes.UpdateServiceRdaSuccess: {
            const rdaIdx: number = state.settings.rdas.findIndex((rda: RdaResponse) =>
                rda.id === action.response.id
            );

            if (action.response.intercomTypeId) {
                const location: IntercomType = state.general.intercomTypes.find((intercomType: IntercomType) =>
                    intercomType.id === action.response.intercomTypeId
                ) ?? null;

                state.settings.rdas[rdaIdx].intercomType = location;
                delete action.response.intercomTypeId;
            }

            Object.keys(action.response).forEach((responseKey: string) =>
                state.settings.rdas[rdaIdx][responseKey] = action.response[responseKey]
            );

            state.settings.rdas = JSON.parse(JSON.stringify(state.settings.rdas));

            return { ...state };
        }

        case ServicesActionTypes.GetServiceRdasSuccess: {
            state.settings.rdas = action.rdas;

            return { ...state };
        }

        case ServicesActionTypes.DeleteServiceRdaSuccess: {
            const deleteRdaIdx = state.settings.rdas.findIndex(rda => rda.id === action.intercom.id);

            state.settings.keys = state.settings.keys.filter((key: KeysResponse) =>
                key.adapterId !== state.settings.rdas[deleteRdaIdx].uid
            );

            state.settings.keys = state.settings.keys.slice();

            delete state.settings.pbxOnRda[action.intercom.uid];
            state.settings.pbxOnRda = JSON.parse(JSON.stringify(state.settings.pbxOnRda));

            state.settings.rdas.splice(deleteRdaIdx, 1);
            state.settings.rdas = state.settings.rdas.slice();

            if (state.activity.logs.source?.id === action.intercom.id) {
                state.activity.logs.source = null;
            }

            return { ...state };
        }

        // Intercom panels cases
        case ServicesActionTypes.UpdateServiceIntercomPanelSuccess: {
            const intercomIdx: number = state.settings.rdas.findIndex((intercom: RdaResponse) =>
                intercom.id === action.rdaId
            );

            const intercomPanelIdx = state.settings.rdas[intercomIdx].intercoms.findIndex((intercomPanel: IntercomPanelResponse) =>
                intercomPanel.id === action.intercomPanelBeforeChange.id
            );

            state.settings.rdas[intercomIdx].intercoms[intercomPanelIdx] = action.intercomPanelAfterChange;
            state.settings.rdas[intercomIdx].intercoms = state.settings.rdas[intercomIdx].intercoms.slice();
            state.settings.rdas = state.settings.rdas.slice();

            return { ...state };
        }

        case ServicesActionTypes.DeleteServiceIntercomPanelSuccess: {
            const intercomIdx: number = state.settings.rdas.findIndex((intercom: RdaResponse) =>
                intercom.id === action.rdaId
            );

            const intercomPanelIdx = state.settings.rdas[intercomIdx].intercoms.findIndex((intercomPanel: IntercomPanelResponse) =>
                intercomPanel.id === action.intercomPanelId
            );

            state.settings.rdas[intercomIdx].intercoms.splice(intercomPanelIdx, 1);
            state.settings.rdas = state.settings.rdas.slice();

            return { ...state };
        }

        // Intercom keys cases
        case ServicesActionTypes.GetServiceKeysSuccess: {
            state.settings.keys = action.keys;

            return { ...state };
        }

        case ServicesActionTypes.AddServiceKeySuccess: {
            action.device.relay = action.device.releId;
            state.settings.keys.push(action.device);
            state.settings.keys[state.settings.keys.length - 1].adapterInfo = action.intercomInfo;
            state.settings.keys = state.settings.keys.slice();

            return { ...state };
        }

        case ServicesActionTypes.UpdateServiceKeySuccess: {
            const keyIdx: number = state.settings.keys.findIndex((key: KeysResponse) => key.id === action.keyId);

            if (action.response.type !== undefined) {
                state.settings.keys[keyIdx].type = action.response.type;
            }

            if ((action.response as IpIntercomKeysRequest).openDoorUrl !== undefined) {
                state.settings.keys[keyIdx].openDoorUrl = (action.response as IpIntercomKeysRequest).openDoorUrl;
            }

            if ((action.response as IpIntercomKeysRequest).authUrl !== undefined) {
                state.settings.keys[keyIdx].authUrl = (action.response as IpIntercomKeysRequest).authUrl;
            }

            if (action.response.releId !== undefined) {
                state.settings.keys[keyIdx].relay = action.response.releId;
            }

            if (action.response.locationId !== undefined && action.locationName !== undefined) {
                if (!state.settings.keys[keyIdx].location) {
                    state.settings.keys[keyIdx].location = {
                        id: null,
                        name: null
                    };
                }

                state.settings.keys[keyIdx].location.id = action.response.locationId;
                state.settings.keys[keyIdx].location.name = action.locationName;
            }

            state.settings.keys[keyIdx] = Object.assign({}, state.settings.keys[keyIdx]);
            state.settings.keys = state.settings.keys.slice();

            return { ...state };
        }

        case ServicesActionTypes.DeleteServiceKeySuccess: {
            const keyIdx: number = state.settings.keys.findIndex(device => device.id === action.keyId);
            state.settings.keys.splice(keyIdx, 1);
            state.settings.keys = state.settings.keys.slice();

            return { ...state };
        }

        case ServicesActionTypes.GetServicePbxOnRdaSuccess: {
            state.settings.pbxOnRda[action.rdaUid] = action.pbxOnRda;

            return { ...state };
        }

        case ServicesActionTypes.ConnectServicePbxOnRdaSuccess: {
            state.settings.pbxOnRda[action.rdaUidForConnect] = action.pbxOnRda;
            state.settings.pbxOnRda = JSON.parse(JSON.stringify(state.settings.pbxOnRda));

            return { ...state };
        }

        case ServicesActionTypes.DisconnectServicePbxOnRdaSuccess: {
            state.settings.pbxOnRda[action.rdaUidForConnect] = null;
            state.settings.pbxOnRda = JSON.parse(JSON.stringify(state.settings.pbxOnRda));

            return { ...state };
        }

        case ServicesActionTypes.ChangeServicePbxOnRdaSuccess: {
            state.settings.pbxOnRda[action.rdaUidForConnect] = action.pbxOnRda;
            state.settings.pbxOnRda = JSON.parse(JSON.stringify(state.settings.pbxOnRda));

            return { ...state };
        }

        case ServicesActionTypes.GetTranslationTuningsSuccess: {
            state.settings.translationTunings[action.rdaUid] = action.response;

            return { ...state };
        }

        case ServicesActionTypes.AddTranslationTuningSuccess: {
            state.settings.translationTunings[action.rdaUid].push(action.response);

            return { ...state };
        }

        case ServicesActionTypes.DeleteTranslationTuningSuccess: {
            const translationTuningIdx = state.settings.translationTunings[action.rdaUid].findIndex((translationTuning: TranslationTuningResponse) =>
                translationTuning.id === action.translationTuningId
            );

            state.settings.translationTunings[action.rdaUid].splice(translationTuningIdx, 1);

            return { ...state };
        }

        // Cameras cases
        case ServicesActionTypes.GetServiceCamerasSuccess: {
            state.settings.cameras = action.cameras;

            return { ...state };
        }

        case ServicesActionTypes.UpdateServiceCameraSuccess: {
            const cameraIdx: number = state.settings.cameras.findIndex((camera: Camera) =>
                camera.id === action.camera.id
            );

            state.settings.cameras[cameraIdx] = action.camera;
            state.settings.cameras = state.settings.cameras.slice();

            return { ...state };
        }

        case ServicesActionTypes.AddServiceCameraSuccess: {
            state.settings.cameras.push(action.camera);
            state.settings.cameras = state.settings.cameras.slice();

            return { ...state };
        }

        case ServicesActionTypes.DeleteServiceCameraSuccess: {
            const cameraIdx: number = state.settings.cameras.findIndex((camera: Camera) =>
                camera.id === action.cameraId
            );

            state.settings.cameras.splice(cameraIdx, 1);
            state.settings.cameras = state.settings.cameras.slice();

            return { ...state };
        }

        // Connections cases
        case ServicesActionTypes.GetServiceConnectionsInit: {
            if (action.options?.fillEmptyFlats !== undefined) {
                state.abonents.fillEmptyFlats = action.options?.fillEmptyFlats;
            }

            return { ...state };
        }

        case ServicesActionTypes.GetServiceConnectionsSuccess: {
            state.abonents.loading = false;
            return { ...state };
        }

        case ServicesActionTypes.GetServiceConnectionsFailure: {
            state.abonents.loading = false;
            return { ...state };
        }

        case ServicesActionTypes.ServiceConnectionsPrepared: {
            state.abonents.loading = false;
            state.abonents.flats = JSON.parse(JSON.stringify(action.entrancesFlats));
            state.abonents.filteredFlats = JSON.parse(JSON.stringify(action.entrancesFlats));
            return { ...state };
        }

        case ServicesActionTypes.CreateServiceConnectionsSuccess:
        case ServicesActionTypes.DeleteServiceConnectionSuccess:
        case ServicesActionTypes.BlockServicePhysicalTubeSuccess:
        case ServicesActionTypes.UnblockServicePhysicalTubeSuccess:
        case ServicesActionTypes.AddServicePhysicalTubeSuccess:
        case ServicesActionTypes.DeleteServicePhysicalTubeSuccess:
        case ServicesActionTypes.BlockServiceConnectionSuccess:
        case ServicesActionTypes.UnblockServiceConnectionSuccess:
        case ServicesActionTypes.DeleteServiceFlatSuccess:
        case ServicesActionTypes.DelegateServiceAbonentAccessSuccess:
        case ServicesActionTypes.DeleteServiceAbonentAccessSuccess: {
            state.abonents.flats = JSON.parse(JSON.stringify(action.flats));
            state.abonents.filteredFlats = JSON.parse(JSON.stringify(action.filteredFlats));
            return { ...state };
        }

        case ServicesActionTypes.FilterServiceFlats: {
            return { ...state };
        }

        case ServicesActionTypes.FilterServiceFlatsSuccess: {
            state.abonents.filteredFlats = JSON.parse(JSON.stringify(action.filteredFlats));
            state.abonents.loading = false;
            return { ...state };
        }

        case ServicesActionTypes.SetServiceFillEmptyFlats: {
            state.abonents.fillEmptyFlats = action.fillEmptyFlats;
            return { ...state };
        }

        // Activity cases
        case ServicesActionTypes.SetServiceLogsSource: {
            state.activity.logs.source = action.source;

            return { ...state };
        }

        case ServicesActionTypes.SetServiceLogsStream: {
            state.activity.logs.extendedMode = action.extendedMode;

            const data: LogsResponse[] = state.activity.logs.data;
            state.activity.logs.data = state.activity.logs.savedData;
            state.activity.logs.savedData = data;

            return { ...state };
        }

        case ServicesActionTypes.SetServiceLogsTimeRange: {
            state.activity.logs.timeRange = action.timeRange;

            return { ...state };
        }

        case ServicesActionTypes.GetServiceRdasSourcesSuccess: {
            state.activity.logs.rdasSources = action.response;

            if (state.activity.logs?.rdasSources && state.activity.logs?.camerasSources && state.activity.logs?.keysSources) {
                state.activity.logs.sources = [
                    ...state.activity.logs.rdasSources,
                    ...state.activity.logs.keysSources,
                    ...state.activity.logs.camerasSources
                ];
            }

            return { ...state };
        }

        case ServicesActionTypes.DeleteServiceRdaSource: {
            const sourceIdx: number = state.activity.logs?.rdasSources.findIndex((source: ServiceActivitySource) =>
                (source.component as RdaResponse).uid === action.rdaUid
            );

            if (sourceIdx !== -1) {
                state.activity.logs.rdasSources.splice(sourceIdx, 1);

                if (state.activity.logs.rdasSources.length === 0 && state.activity.logs.componentType === LogsComponentType.RDA) {
                    if (state.activity.logs.keysSources.length > 0) {
                        state.activity.logs.componentType = LogsComponentType.RDA;
                        state.activity.logs.source = state.activity.logs.keysSources[0];
                    } else if (state.activity.logs.camerasSources.length > 0) {
                        state.activity.logs.componentType = LogsComponentType.CAMERA;
                        state.activity.logs.source = state.activity.logs.camerasSources[0];
                    } else {
                        state.activity.logs.componentType = null;
                        state.activity.logs.source = null;
                    }
                }

                state.activity.logs.sources = [
                    ...state.activity.logs.rdasSources,
                    ...state.activity.logs.keysSources,
                    ...state.activity.logs.camerasSources
                ];
            }

            return { ...state };
        }

        case ServicesActionTypes.GetServiceCamerasSourcesSuccess: {
            state.activity.logs.camerasSources = action.response;

            if (state.activity.logs?.rdasSources && state.activity.logs?.camerasSources && state.activity.logs?.keysSources) {
                state.activity.logs.sources = [
                    ...state.activity.logs.rdasSources,
                    ...state.activity.logs.keysSources,
                    ...state.activity.logs.camerasSources
                ];
            }

            return { ...state };
        }

        case ServicesActionTypes.DeleteServiceCameraSource: {
            const sourceIdx: number = state.activity.logs.camerasSources?.findIndex((source: ServiceActivitySource) =>
                source.id === action.cameraId
            );

            if (sourceIdx !== -1) {
                state.activity.logs.camerasSources.splice(sourceIdx, 1);

                if (state.activity.logs.camerasSources.length === 0 && state.activity.logs.componentType === LogsComponentType.CAMERA) {
                    if (state.activity.logs.rdasSources.length > 0) {
                        state.activity.logs.componentType = LogsComponentType.RDA;
                        state.activity.logs.source = state.activity.logs.rdasSources[0];
                    } else if (state.activity.logs.keysSources.length > 0) {
                        state.activity.logs.componentType = LogsComponentType.RDA;
                        state.activity.logs.source = state.activity.logs.rdasSources[0];
                    } else {
                        state.activity.logs.componentType = null;
                        state.activity.logs.source = null;
                    }
                }

                state.activity.logs.sources = [
                    ...state.activity.logs.rdasSources,
                    ...state.activity.logs.keysSources,
                    ...state.activity.logs.camerasSources
                ];
            }

            return { ...state };
        }

        case ServicesActionTypes.GetServiceKeysSourcesSuccess: {
            state.activity.logs.keysSources = action.response;

            if (state.activity.logs?.rdasSources && state.activity.logs?.camerasSources && state.activity.logs?.keysSources) {
                state.activity.logs.sources = [
                    ...state.activity.logs.rdasSources,
                    ...state.activity.logs.keysSources,
                    ...state.activity.logs.camerasSources
                ];
            }

            return { ...state };
        }

        case ServicesActionTypes.DeleteServiceKeySource: {
            const sourceIdx: number = state.activity.logs.keysSources?.findIndex((source: ServiceActivitySource) =>
                (source.component as RdaResponse).uid === action.rdaUid
            );

            if (sourceIdx !== -1) {
                state.activity.logs.keysSources.splice(sourceIdx, 1);

                if (state.activity.logs.keysSources.length === 0 && state.activity.logs.componentType === LogsComponentType.RDA) {
                    if (state.activity.logs.rdasSources.length > 0) {
                        state.activity.logs.componentType = LogsComponentType.RDA;
                        state.activity.logs.source = state.activity.logs.rdasSources[0];
                    } else if (state.activity.logs.camerasSources.length > 0) {
                        state.activity.logs.componentType = LogsComponentType.CAMERA;
                        state.activity.logs.source = state.activity.logs.camerasSources[0];
                    } else {
                        state.activity.logs.componentType = null;
                        state.activity.logs.source = null;
                    }
                }

                state.activity.logs.sources = [
                    ...state.activity.logs.rdasSources,
                    ...state.activity.logs.keysSources,
                    ...state.activity.logs.camerasSources
                ];
            }

            return { ...state };
        }

        case ServicesActionTypes.GetServiceRdaActivityBefore: {
            state.activity.activeHistoryIntercoms = {};

            return { ...state };
        }

        case ServicesActionTypes.GetServiceRdaActivity: {
            let sourceIdx: number = state.activity.logs.rdasSources?.findIndex((source: ServiceActivitySource) =>
                source.id === action.rdaId
            );

            if (sourceIdx === -1 && state.activity.logs.keysSources) {
                sourceIdx = state.activity.logs.keysSources?.findIndex(source => source.id === action.rdaId);
                state.activity.logs.keysSources[sourceIdx].loading = true;
                state.activity.logs.sources = [
                    ...state.activity.logs.rdasSources,
                    ...state.activity.logs.keysSources,
                    ...state.activity.logs.camerasSources
                ];
            } else if (state.activity.logs.rdasSources) {
                state.activity.logs.rdasSources[sourceIdx].loading = true;
                state.activity.logs.sources = [
                    ...state.activity.logs.rdasSources,
                    ...state.activity.logs.keysSources,
                    ...state.activity.logs.camerasSources
                ];
            }

            return { ...state };
        }

        case ServicesActionTypes.GetServiceRdaActivitySuccess: {
            let sourceIdx: number = state.activity.logs.rdasSources?.findIndex((source: ServiceActivitySource) =>
                source.id === action.rdaId
            );

            if (sourceIdx === -1 && state.activity.logs.keysSources) {
                sourceIdx = state.activity.logs.keysSources?.findIndex(source => source.id === action.rdaId);
                state.activity.logs.keysSources[sourceIdx].loading = false;
                state.activity.logs.sources = [
                    ...state.activity.logs.rdasSources,
                    ...state.activity.logs.keysSources,
                    ...state.activity.logs.camerasSources
                ];
            } else if (state.activity.logs.rdasSources) {
                state.activity.logs.rdasSources[sourceIdx].loading = false;
                state.activity.logs.sources = [
                    ...state.activity.logs.rdasSources,
                    ...state.activity.logs.keysSources,
                    ...state.activity.logs.camerasSources
                ];
            }

            state.activity.activeHistoryIntercoms[action.rdaId] = action.response;
            state.activity.activeHistoryIntercoms = JSON.parse(JSON.stringify(state.activity.activeHistoryIntercoms));

            return { ...state };
        }

        case ServicesActionTypes.GetServiceRdaActivityFailure: {
            let sourceIdx: number = state.activity.logs.rdasSources?.findIndex((source: ServiceActivitySource) =>
                source.id === action.rdaId
            );

            if (sourceIdx === -1 && state.activity.logs.keysSources) {
                sourceIdx = state.activity.logs.keysSources?.findIndex(source => source.id === action.rdaId);
                state.activity.logs.keysSources[sourceIdx].loading = false;
                state.activity.logs.sources = [
                    ...state.activity.logs.rdasSources,
                    ...state.activity.logs.keysSources,
                    ...state.activity.logs.camerasSources
                ];
            } else if (state.activity.logs.rdasSources) {
                state.activity.logs.rdasSources[sourceIdx].loading = false;
                state.activity.logs.sources = [
                    ...state.activity.logs.rdasSources,
                    ...state.activity.logs.keysSources,
                    ...state.activity.logs.camerasSources
                ];
            }

            state.activity.activeHistoryIntercoms[action.rdaId] = [];
            state.activity.activeHistoryIntercoms = JSON.parse(JSON.stringify(state.activity.activeHistoryIntercoms));

            return { ...state };
        }

        case ServicesActionTypes.GetServiceCameraActivityBefore: {
            state.activity.activeHistoryCameras = {};

            return { ...state };
        }

        case ServicesActionTypes.GetServiceCameraActivity: {
            const sourceIdx: number = state.activity.logs.camerasSources.findIndex((source: ServiceActivitySource) =>
                source.id === action.cameraId
            );

            state.activity.logs.camerasSources[sourceIdx].loading = true;
            state.activity.logs.sources = [
                ...state.activity.logs.rdasSources,
                ...state.activity.logs.keysSources,
                ...state.activity.logs.camerasSources
            ];

            return { ...state };
        }

        case ServicesActionTypes.GetServiceCameraActivitySuccess: {
            const sourceIdx: number = state?.activity?.logs?.camerasSources?.findIndex((source: ServiceActivitySource) =>
                source?.id === action?.cameraId
            );

            state.activity.logs.camerasSources[sourceIdx].loading = false;
            state.activity.logs.sources = [
                ...state.activity.logs.rdasSources,
                ...state.activity.logs.keysSources,
                ...state.activity.logs.camerasSources
            ];

            state.activity.activeHistoryCameras[action.cameraId] = action?.response;
            state.activity.activeHistoryCameras = JSON.parse(JSON.stringify(state?.activity?.activeHistoryCameras));

            return { ...state };
        }

        case ServicesActionTypes.GetServiceCameraActivityFailure: {
            const sourceIdx: number = state.activity.logs.camerasSources.findIndex((source: ServiceActivitySource) =>
                source.id === action.cameraId
            );

            state.activity.logs.camerasSources[sourceIdx].loading = false;
            state.activity.logs.sources = [
                ...state.activity.logs.rdasSources,
                ...state.activity.logs.keysSources,
                ...state.activity.logs.camerasSources
            ];

            state.activity.activeHistoryCameras[action.cameraId] = [];
            state.activity.activeHistoryCameras = JSON.parse(JSON.stringify(state.activity.activeHistoryCameras));

            return { ...state };
        }

        case ServicesActionTypes.GetServicePrivateCameraLogs: {
            if (action.page === 0) {
                state.activity.logs.data = [];
                state.activity.logs.savedData = [];
            }

            state.activity.logs.loading = true;

            return { ...state };
        }

        case ServicesActionTypes.GetServiceCameraLogs: {
            state.activity.logs.data = [];
            state.activity.logs.savedData = [];
            state.activity.logs.loading = true;

            return { ...state };
        }

        case ServicesActionTypes.GetServiceRdaLogs: {
            if (action.page === 0) {
                state.activity.logs.data = [];
                state.activity.logs.savedData = [];
            }

            state.activity.logs.loading = true;

            return { ...state };
        }

        case ServicesActionTypes.GetServiceCameraLogsSuccess:
        case ServicesActionTypes.GetServiceRdaLogsSuccess: {
            state.activity.logs.loading = false;

            if (action.page > 0) {
                state.activity.logs.currentCount += action.currentCount;

                if (state.activity.logs.extendedMode) {
                    state.activity.logs.data = [...state.activity.logs.data, ...action.extendedResponse];
                    state.activity.logs.savedData = [...state.activity.logs.savedData, ...action.response];
                } else {
                    state.activity.logs.data = [...state.activity.logs.data, ...action.response];
                    state.activity.logs.savedData = [...state.activity.logs.savedData, ...action.extendedResponse];
                }
            } else {
                state.activity.logs.currentCount = action.currentCount;

                if (state.activity.logs.extendedMode) {
                    state.activity.logs.data = action.extendedResponse;
                    state.activity.logs.savedData = action.response;
                } else {
                    state.activity.logs.data = action.response;
                    state.activity.logs.savedData = action.extendedResponse;
                }
            }

            state.activity.logs.totalCount = action.totalCount;


            return { ...state };
        }

        case ServicesActionTypes.GetServiceCameraLogsFailure:
        case ServicesActionTypes.GetServiceRdaLogsFailure: {
            state.activity.logs.loading = false;

            return { ...state };
        }

        case ServicesActionTypes.SetServiceComponentType: {
            state.activity.logs.componentType = action.componentType;

            return { ...state };
        }

        case ServicesActionTypes.SetServiceBlocksCount: {
            state.activity.logs.blocksCount = action.blocksCount;

            return { ...state };
        }

        case ServicesActionTypes.SetPageMode: {
            state.general.pageMode = action.pageMode;

            return { ...state };
        }

        case ServicesActionTypes.ClearService: {
            state = JSON.parse(JSON.stringify(servicesInitialState));

            return { ...state };
        }

        case ServicesActionTypes.ActiveServiceOnvifFailure:
        case ServicesActionTypes.ActiveServiceOnvifSuccess: {
            state.onvif.isActive = action.active;

            return { ...state };
        }

        default: {
            return state;
        }
    }
}
