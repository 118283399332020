import { NgxMatDatetimePickerModule } from '@angular-material-components/datetime-picker';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { DialogWrapperModule } from '@app/shared/ui';
import { SignUpsReportContentComponent } from './sign-ups-report-content';
import { SignUpsReportPopupComponent } from './sign-ups-report-popup.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        DialogWrapperModule,
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        NgxMatDatetimePickerModule,
        TranslateModule
    ],
    declarations: [
        SignUpsReportContentComponent,
        SignUpsReportPopupComponent
    ],
    exports: [SignUpsReportPopupComponent]
})
export class SignUpsReportPopupModule { }
