import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/shared/components/snackbar';
import {PbxOnRdaApiService, PbxOnRdaResponse} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {PagedResponse} from '@app/shared/models';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, switchMap} from 'rxjs/operators';
import {PbxOnRdaSearchSelectService} from '../pbx-on-rda-search-select.service';
import {
  PbxOnRdaSearchSelectActionTypes,
  PbxOnRdaSearchSelectGetPbxOnRda,
  PbxOnRdaSearchSelectGetPbxOnRdaFailure,
  PbxOnRdaSearchSelectGetPbxOnRdaSuccess
} from './pbx-on-rda-search-select.actions';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class PbxOnRdaSearchSelectEffects {
  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private pbxOnRdaApiService: PbxOnRdaApiService,
    private pbxOnRdaSearchSelectService: PbxOnRdaSearchSelectService,
    private translate: TranslateService
  ) {
  }

  PbxOnRdaSearchSelectGetRdas$ = createEffect(() =>
    this.actions$.pipe(
      ofType<PbxOnRdaSearchSelectGetPbxOnRda>(PbxOnRdaSearchSelectActionTypes.PbxOnRdaSearchSelectGetPbxOnRda),
      switchMap((action: PbxOnRdaSearchSelectGetPbxOnRda) =>
        this.pbxOnRdaApiService.getPage(0, 10, {rdaUid: action.value})
          .pipe(
            map((pagedResponse: PagedResponse<PbxOnRdaResponse>) =>
              new PbxOnRdaSearchSelectGetPbxOnRdaSuccess(
                this.pbxOnRdaSearchSelectService.preparePbxOnRdasList(pagedResponse.content)
              )
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('shared.pbx_on_rda.search.select.message.get.failed', {
                  text: parseError(error)
                })
              );
              return of(new PbxOnRdaSearchSelectGetPbxOnRdaFailure(error));
            })
          )
      ),
    )
  );
}
