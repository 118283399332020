import { HttpErrorResponse } from '@angular/common/http';
import { RdaModel } from '@app/shared/entities/rd/rda';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  GetRdaModels = '[Intercom] GetRdaModels',
  GetRdaModelsSuccess = '[Intercom] GetRdaModels Success',
  GetRdaModelsFailure = '[Intercom] GetRdaModels Failure',
  GetRdaModelsClear = '[Intercom] GetRdaModels Clear',

  CreateRdaModel = '[Intercom] CreateRdaModel',
  CreateRdaModelSuccess = '[Intercom] CreateRdaModel Success',
  CreateRdaModelFailure = '[Intercom] CreateRdaModel Failure',
  CreateRdaModelClear = '[Intercom] CreateRdaModel Clear',
}

export class GetRdaModels implements Action {
  readonly type = ActionTypes.GetRdaModels;

  constructor() { }
}

export class GetRdaModelsSuccess implements Action {
  readonly type = ActionTypes.GetRdaModelsSuccess;

  constructor(public payload: RdaModel[]) { }
}

export class GetRdaModelsFailure implements Action {
  readonly type = ActionTypes.GetRdaModelsFailure;

  constructor(public payload: string) { }
}

export class GetRdaModelsClear implements Action {
  readonly type = ActionTypes.GetRdaModelsClear;

  constructor() { }
}

export class CreateRdaModel implements Action {
  readonly type = ActionTypes.CreateRdaModel;

  constructor(public name: string) { }
}

export class CreateRdaModelSuccess implements Action {
  readonly type = ActionTypes.CreateRdaModelSuccess;

  constructor() { }
}

export class CreateRdaModelFailure implements Action {
  readonly type = ActionTypes.CreateRdaModelFailure;

  constructor(public error: HttpErrorResponse) { }
}

export class CreateRdaModelClear implements Action {
  readonly type = ActionTypes.CreateRdaModelClear;

  constructor() { }
}

export type Actions =
  | GetRdaModels
  | GetRdaModelsSuccess
  | GetRdaModelsFailure
  | CreateRdaModel
  | CreateRdaModelSuccess
  | CreateRdaModelFailure
  | GetRdaModelsClear
  | CreateRdaModelClear;
