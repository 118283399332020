import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { Account } from '@app/shared/entities/rd/account/models';
import { AccountServiceData, BlockAccountServiceRequest, DisconnectAccountServiceRequest } from '@app/views/abonents/models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {IPaymentsWithPagination, PaymentsInterface} from '@app/shared/entities/rd/payments/interfaces';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class AccountApiService extends ApiService {
  private headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.abonentsApiUrl, ['v1', 'v2']);
  }

  createAbonentAndAccount(phone: number | string, companyId?: number): Observable<Account> {
    const body: any = { phone: phone };
    if (companyId) { body.companyId = companyId; }

    return this.post('/accounts/', 1, body, { headers: this.headers });
  }
  /**
   * Creates an abonent and account with the given payload.
   *
   * @param {Object} payload - Object containing companyId, forTest, number - for account id, and phone - for abonent id.
   * @return {Observable<Account>} Observable that emits the created Account.
   */
  public createAbonentAndAccount2(payload: {companyId?: number; forTest?: boolean; number: string; phone: string}): Observable<Account> {
    return this.post('/accounts/', 1, payload, { headers: this.headers });
  }

  disconnectAccountFromService(accountId: number, serviceId: number, request: DisconnectAccountServiceRequest): Observable<any> {
    return this.delete(`/accounts/${accountId}/services/${serviceId}`, 1, request, { headers: this.headers });
  }

  deleteAccount(id: number): Observable<boolean> {
    return this.delete(`/accounts/${id}`, 1).pipe(map(() => true));
  }

  blockAccount(id: number): Observable<boolean> {
    return this.put(`/accounts/${id}/block`, 2).pipe(map(() => true));
  }

  unblockAccount(id: number): Observable<boolean> {
    return this.delete(`/accounts/${id}/block`, 2).pipe(map(() => true));
  }

  getAccountServices(accountId: number): Observable<AccountServiceData[]> {
    return this.get(`/accounts/${accountId}/services`, 1);
  }

  getPaymentByAccount(accountId: number, size?: number, page?: number): Observable<IPaymentsWithPagination> {
    const params = { page: page, size: size } as any;
    return this.get(`/payments/account?accountIds=${accountId}`, 1, { params });
  }

  connectServiceToAccount(accountId: number, serviceId: number, flat: number) {
    const body = { flatId: flat };
    return this.post(`/accounts/${accountId}/services/${serviceId}`, 1, body, { headers: this.headers });
  }

  blockAccountService(accountId: number, serviceId: number, request: BlockAccountServiceRequest): Observable<void> {
    let body: any;
    if (request.flatId) { body = { flatId: request.flatId }; }

    return this.put(`/accounts/${accountId}/services/${serviceId}/block`, 1, body, { headers: this.headers });
  }

  unblockAccountService(accountId: number, serviceId: number, request: BlockAccountServiceRequest): Observable<void> {
    const body: any = {};
    if (request.flatId) { body['flatId'] = request.flatId; }

    return this.delete(`/accounts/${accountId}/services/${serviceId}/block`, 1, body, { headers: this.headers });
  }

  public blockAccountService2(serviceId: number): Observable<void> {
    return this.put(`/services_connections/${serviceId}/block`, 1, null, { headers: this.headers });
  }

  public unblockAccountService2(serviceId: number): Observable<void> {
    return this.delete(`/services_connections/${serviceId}/block`, 1, null, { headers: this.headers });
  }

  public getServiceConnections(serviceId: number): Observable<any[]> {
    return this.get(`/services/${serviceId}/connections`, 1);
  }

  disconnectAccountService(accountId: number, serviceId: number, request: DisconnectAccountServiceRequest): Observable<void> {
    let body: any;
    if (request.flatId) { body = { flatId: request.flatId }; }

    return this.delete(`/accounts/${accountId}/services/${serviceId}`, 1, body, { headers: this.headers });
  }

  updateAccount(accountId: number, accountNumber: string): Observable<void> {
    const payload = { number: accountNumber };
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.patch(`/accounts/${accountId}`, 1, payload, { headers });
  }
}
