import { RdaResponse } from '@app/shared/entities/rd';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogWrapperData } from '@app/shared/ui';

@Component({
  selector: 'app-service-support-request-popup',
  templateUrl: './service-support-request-popup.component.html',
  styleUrls: ['./service-support-request-popup.component.scss']
})
export class ServiceSupportRequestPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<{ serviceName: string, rda: RdaResponse }, null>,
    public dialogRef: MatDialogRef<ServiceSupportRequestPopupComponent>
  ) { }

  public onClose(): void {
    this.dialogRef.close();
  }
}
