import {ChangeDetectionStrategy, Component, Inject, Input, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EntranceRegistration, FlatRange } from '@app/shared/models';
import { DialogWrapperData } from '@app/shared/ui';
import { ServiceFacade } from '../../../../store';
import { ServiceEntrancePopupData } from './service-entrance-popup-data.model';
import { ServiceEntrancePopupResponse } from './service-entrance-popup-response.model';
import { ServiceEntrancePopupService } from './service-entrance-popup.service';
import {ServiceApiService} from '@app/shared/entities/rd';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-service-entrance-popup',
  templateUrl: './service-entrance-popup.component.html',
  styleUrls: ['./service-entrance-popup.component.scss'],
  providers: [ServiceEntrancePopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceEntrancePopupComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<ServiceEntrancePopupData, ServiceEntrancePopupResponse>,
    public popupService: ServiceEntrancePopupService,
    private dialogRef: MatDialogRef<ServiceEntrancePopupComponent>,
    protected readonly serviceFacade: ServiceFacade,
    protected readonly serviceApiService: ServiceApiService
  ) { }

  ngOnInit() {
    this.popupService.addStateChangeListener(state => state === 'close' && this.dialogRef.close());
  }

  onCreate(entrance: EntranceRegistration, prefix?: string) {
    this.data.submit({ entrance, prefix });
  }

  onAddRange(range: FlatRange, entranceId: number) {
    this.serviceFacade.addAdditionalFlatRange(entranceId, range);
  }

  onUpdateRange(rangeId: number, rangeToUpdate: FlatRange, entranceId: number) {
    this.serviceFacade.updateAdditionalFlatRange(entranceId, rangeId, rangeToUpdate);
  }

  onDeleteRange(rangeId: number, entranceId: number) {
    this.serviceFacade.deleteAdditionalFlatRange(entranceId, rangeId);
  }
}
