import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

const createOrderState = createSelector(
    intercomModuleSelector,
    (state: IntercomModuleState) => state.createOrder
);

export const createOrder = createSelector(
    createOrderState,
    state => state
);
export const createOrderLoading = createSelector(
    createOrderState,
    state => state.loading
);
export const createOrderSuccess = createSelector(
    createOrderState,
    state => state.order
);

export const createOrderFailed = createSelector(
    createOrderState,
    state => state.error
);
