import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { IntercomPanelEditContentComponent } from './intercom-panel-edit-content.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        ReactiveFormsModule,
        NgxTranslateModule
    ],
    declarations: [
        IntercomPanelEditContentComponent
    ],
    exports: [
        IntercomPanelEditContentComponent
    ]
})
export class IntercomPanelEditContentModule { }
