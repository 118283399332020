<form [formGroup]="form" #formDirective="ngForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <app-model-list [model]="'shield'" (handleModelSelected)="onShieldModelSelected($event)"></app-model-list>
  <app-model-list [model]="'rda'" (handleModelSelected)="onRdaModelSelected($event)"></app-model-list>

  <app-companies-form-group (handleCompanySelected)="onSelectCompany($event)" [companyList]="companies$ | async">
  </app-companies-form-group>

  <div class="action-block">
    <button
      type="submit"
      mat-raised-button
      color="primary"
    >
      {{ 'intercom.batches.page.form.button.submit' | translate }}
    </button>
  </div>
</form>
