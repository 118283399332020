import { HttpErrorResponse } from '@angular/common/http';
import { takeUntil } from 'rxjs/operators';
import { KzPostApiService, KzPostResponse } from '@app/shared/entities/integrations/kz-post';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { parseError } from '@app/shared/helpers';

@Injectable()
export class KzPostAddressHelper {
    private onDestroy$: Subject<void> = new Subject();

    constructor(
        private kzPostApiService: KzPostApiService
    ) { }

    getAddress(
        address: string,
        cb: (success: boolean, response: KzPostResponse, error?: string) => void
    ) {
        this.kzPostApiService.byAddress(address)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                (response: KzPostResponse) => cb(true, response),
                (error: HttpErrorResponse) => cb(false, null, parseError(error))
            );
    }
}
