import { CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppComponent } from '@app/core/components/app.component';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { reducers } from '@app/store/reducers';
import { AbonentsModule, AuthModule, CompanyModule, DashboardModule, PbxOnRdaModule, ServicesModule } from '@app/views';
import { AppRoutingModule } from '@app/views/app-routing.module';
import { IntercomModule } from '@app/views/intercom/intercom.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'environments/environment';
import { EntrancesModule } from './views/entrances';
import { PaymentsModule } from '@app/views/payments/payments.module';
import { NgxTranslateModule } from './translate/translate.module';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    // NGRX/store modules
    StoreModule.forRoot(reducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: 'RDEA Store DevTools',
      logOnly: environment.production,
    }),
    StoreDevtoolsModule,
    ServiceWorkerModule.register('./ngsw-worker.js'),

    // Application modules
    CoreModule,
    AuthModule,
    SharedModule,
    IntercomModule,
    AppRoutingModule,
    CompanyModule,
    AbonentsModule,
    ServicesModule,
    DashboardModule,
    EntrancesModule,
    PbxOnRdaModule,
    PaymentsModule,
    NgxTranslateModule,
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: HammerGestureConfig },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
