import { ReducerName } from '@app/store';
import * as root from '@app/store/reducers';

export interface AuthModuleState {
  [ReducerName.AUTH]: AuthState;
}

export interface AuthModuleRootState extends root.State {
  [ReducerName.AUTH]: AuthModuleState;
}

export interface AuthState {
  authenticated: boolean;
  loading: boolean;
}

export const authInitialState: AuthState = {
  authenticated: undefined,
  loading: undefined
};
