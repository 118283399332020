import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DeleteEntranceContentModule } from '@app/shared/components';
import { DialogWrapperModule } from '@app/shared/ui';
import { ServiceDeleteEntrancePopupComponent } from './service-delete-entrance-popup.component';

@NgModule({
    imports: [
        CommonModule,
        DialogWrapperModule,
        DeleteEntranceContentModule
    ],
    declarations: [
        ServiceDeleteEntrancePopupComponent
    ],
    exports: [
        ServiceDeleteEntrancePopupComponent
    ]
})
export class ServiceDeleteEntrancePopupModule { }
