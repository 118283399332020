import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { CountsCardComponent } from './counts-card.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    NgCircleProgressModule,
    NgxTranslateModule
  ],
    declarations: [
        CountsCardComponent
    ],
    exports: [
        CountsCardComponent
    ]
})
export class CountsCardModule { }
