<div
  class="intercom-keys-container-item"
  [class.intercom-keys-container-item--bas-ip]="basIp()"
  [class.intercom-keys-container-item--rda]="!key.adapterInfo.ipType"
  [class.intercom-keys-container-item--ip-intercom]="
    key.adapterInfo.ipType && !basIp()
  ">
  <div class="intercom-keys-container-item-row">
    <div class="intercom-keys-container-item-row-col">
      <div class="intercom-keys-container-item-row-col-item">
        <p
          class="intercom-keys-container-item-row-col-item-value intercom-keys-container-item-row-col-item-value--strong">
          <ng-container [ngSwitch]="key.type">
            <img
              *ngSwitchCase="intercomKeyRecords[intercomKeyType.BARRIER].type"
              class="intercom-keys-container-item-row-col-item-value__image"
              [src]="intercomKeyRecords[intercomKeyType.BARRIER].icon" />
            <img
              *ngSwitchCase="intercomKeyRecords[intercomKeyType.GOAL].type"
              class="intercom-keys-container-item-row-col-item-value__image"
              [src]="intercomKeyRecords[intercomKeyType.GOAL].icon" />
            <img
              *ngSwitchCase="intercomKeyRecords[intercomKeyType.GATE].type"
              class="intercom-keys-container-item-row-col-item-value__image"
              [src]="intercomKeyRecords[intercomKeyType.GATE].icon" />
            <img
              *ngSwitchDefault
              class="intercom-keys-container-item-row-col-item-value__image"
              [src]="intercomKeyRecords[intercomKeyType.DOOR].icon" />
          </ng-container>
        </p>
      </div>
    </div>
  </div>

  <div
    class="intercom-keys-container-item-row"
    *ngIf="!key.adapterInfo.ipType">
    <div class="intercom-keys-container-item-row-col">
      <div class="intercom-keys-container-item-row-col-item">
        <p class="intercom-keys-container-item-row-col-item__title">
          {{ 'shared.intercom.keys.container.item.field.relay' | translate }}
        </p>
        <p class="intercom-keys-container-item-row-col-item-value">
          {{ key.releId || key.relay }}
        </p>
      </div>
    </div>
  </div>

  <div
    class="intercom-keys-wrapper"
    *ngIf="key.adapterInfo.ipType">
    <div
      class="intercom-keys-container-item-row"
      *ngIf="basIp()">
      <div class="intercom-keys-container-item-row-col">
        <div class="intercom-keys-container-item-row-col-item">
          <p class="intercom-keys-container-item-row-col-item__title">
            {{ 'shared.intercom.keys.container.item.field.authorization' | translate }}
          </p>
          <p class="intercom-keys-container-item-row-col-item-value">
            {{ key.authUrl }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="intercom-keys-container-item-row"
      *ngIf="sputnikIp()">
      <div class="intercom-keys-container-item-row-col">
        <div class="intercom-keys-container-item-row-col-item">
          <p class="intercom-keys-container-item-row-col-item__title">
            {{ 'shared.intercom.keys.container.item.field.token' | translate }}
          </p>
          <p class="intercom-keys-container-item-row-col-item-value">
            {{ key.authUrl }}
          </p>
        </div>
      </div>
    </div>

    <div class="intercom-keys-container-item-row">
      <div class="intercom-keys-container-item-row-col">
        <div class="intercom-keys-container-item-row-col-item">
          <p class="intercom-keys-container-item-row-col-item__title">
            {{ 'shared.intercom.keys.container.item.field.opening_door' | translate }}
          </p>
          <p class="intercom-keys-container-item-row-col-item-value">
            {{ key.openDoorUrl }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="intercom-keys-container-item-row">
    <div class="intercom-keys-container-item-row-col">
      <div class="intercom-keys-container-item-row-col-item">
        <p class="intercom-keys-container-item-row-col-item__title">
          {{ 'shared.intercom.keys.container.item.field.location' | translate }}
        </p>
        <p class="intercom-keys-container-item-row-col-item-value">
          {{ key.location ? key.location.name : "" }}
        </p>
      </div>
    </div>
  </div>

  <div
    class="intercom-keys-container-item-row"
    *ngIf="!readonly">
    <div class="intercom-keys-container-item-state">
      <div class="intercom-keys-container-item-state-actions">
        <button
          mat-icon-button
          color="primary"
          matTooltip="{{ 'shared.intercom.keys.container.item.tooltip.edit' | translate }}"
          [matTooltipPosition]="'above'"
          (click)="onEdit()"
          class="intercom-keys-container-item-state-actions__button">
          <mat-icon aria-label="Edit">edit</mat-icon>
        </button>

        <button
          mat-icon-button
          color="error"
          matTooltip="{{ 'shared.intercom.keys.container.item.tooltip.delete' | translate }}"
          [matTooltipPosition]="'above'"
          (click)="onDelete()"
          class="intercom-keys-container-item-state-actions__button">
          <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
