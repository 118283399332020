import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { DialogWrapperModule } from '@app/shared/ui';
import { ChangePasswordContentComponent } from './change-password-content';
import { ChangePasswordPopupComponent } from './change-password-popup.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MaterialModule,
        ReactiveFormsModule,
        DialogWrapperModule,
        NgxTranslateModule
    ],
    declarations: [
        ChangePasswordPopupComponent,
        ChangePasswordContentComponent
    ],
    exports: [
        ChangePasswordPopupComponent
    ]
})
export class ChangePasswordPopupModule { }
