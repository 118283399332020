import { LocalStorageCompanyKey } from './local-storage-company-key.enum';
import { LocalStorageCoreKey } from './local-storage-core-key.enum';
import { LocalStorageDashboardKey } from './local-storage-dashboard-key.enum';
import { LocalStorageGeneralKey } from './local-storage-general-key.enum';
import { LocalStoragePaymentsKey } from './local-storage-payments-key.enum';
import { LocalStorageLoginKey } from '@app/shared/entities/common/local-storage/models/local-storage-login-key';

export type LocalStorageKey =
  | LocalStorageCompanyKey
  | LocalStorageCoreKey
  | LocalStorageDashboardKey
  | LocalStorageGeneralKey
  | LocalStoragePaymentsKey
  | LocalStorageLoginKey;
