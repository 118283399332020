export interface GraylogMessage {
    decoration_stats: any;
    highlight_ranges: Object;
    index: string;
    message: GraylogMessageData;
}

interface GraylogMessageData {
    LoggerName: string;
    Severity: string;
    SourceClassName: string;
    SourceLineNumber: number;
    SourceMethodName: string;
    SourceSimpleClassName: string;
    Thread: string;
    Time: string;
    facility: string;
    full_message: string;
    gl2_remote_ip: string;
    gl2_remote_port: number;
    gl2_source_input: string;
    gl2_source_node: string;
    level: number;
    message: string;
    service: string;
    source: string;
    streams: string[];
    tag: string;
    timestamp: string;
    _id: string;
}
