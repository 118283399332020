<app-software-intercom-camera-popup-content
  [camera]="camera"
  [rdas]="intercoms"
  [addresses]="addresses"
  [camerasLocations]="camerasLocations"
  [avaliableIntercomPanelsLocations]="avaliableIntercomPanelsLocations"
  [intercomPanels]="intercomPanels"
  [onvif]="onvif"
  [loading]="loading"
  (submitCamera)="onSubmit($event.camera, $event.intercomPanelId)"
  (submitOnvif)="onSubmitOnvif($event.camera)"
></app-software-intercom-camera-popup-content>
