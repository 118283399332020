import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SnackbarService } from '@app/shared/components/snackbar';
import { AbonentService } from '@app/shared/entities/rd';
import { parseError } from '@app/shared/helpers/parse-error';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  ActionTypes,
  GetAbonentsPage,
  GetAbonentsPageFailure,
  GetAbonentsPageSuccess
} from '../actions/abonents-page.actions';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AbonentsEffects {
  constructor(
    private actions$: Actions,
    private abonentService: AbonentService,
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {}

  GetAbonentsPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAbonentsPage>(ActionTypes.GetAbonentsPage),
      switchMap((action) =>
        this.abonentService
          .getPage(
            action.page,
            action.size,
            action.resolved,
            action.phone,
            action.sort
          )
          .pipe(
            map(
              (abonents) =>
                new GetAbonentsPageSuccess(
                  abonents.content,
                  abonents.totalElements
                )
            ),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('abonents.page.message.get.failed', {
                  text: parseError(error)
                })
              );
              return of(new GetAbonentsPageFailure());
            })
          )
      )
    )
  );
}
