<app-dialog-wrapper
  [title]="data.title"
  [progress]="(popupState$ | async) === 'loading'"
  [componentName]="data.componentName"
>
  <app-intercom-keys-edit-container
    [key]="data.body.key"
    [loading]="(popupState$ | async) === 'loading'"
    [rdas]="intercoms$ | async"
    [locations]="keysLocations$ | async"
    (keyChange)="onSubmit($event.keyId, $event.intercomInfo, $event.request, $event.locationName)"
  ></app-intercom-keys-edit-container>
</app-dialog-wrapper>
