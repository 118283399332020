<mat-card class="card-container notifications">
  <div class="header">
    <div class="title-block">
      {{ 'company.about.page.notifications.title' | translate }}
    </div>
  </div>
  <mat-card-content>
    <form [formGroup]="form">
      <div class="notifications-content">
        <mat-checkbox formControlName="adaptersStatesNotification">
          {{ 'company.about.page.notifications.adapters' | translate }}
        </mat-checkbox>
        <mat-checkbox formControlName="camerasStatesNotification">
          {{ 'company.about.page.notifications.cameras' | translate }}
        </mat-checkbox>
        <mat-checkbox formControlName="delegationsNotification">
          {{ 'company.about.page.notifications.delegations' | translate }}
        </mat-checkbox>
      </div>
    </form>
  </mat-card-content>
</mat-card>
