import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { IntercomsListContentModule } from '@app/shared/components';
import { ComponentLoaderModule, InfoBlockModule } from '@app/shared/ui';
import { ServiceIntercomsListContentModule } from '@app/views/services/components/popups';
import { GateServiceEntrancesIntercomsComponent } from './gate-service-entrances-intercoms.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MaterialModule,
        IntercomsListContentModule,
        InfoBlockModule,
        ServiceIntercomsListContentModule,
        ComponentLoaderModule,
        NgxTranslateModule
    ],
    declarations: [
        GateServiceEntrancesIntercomsComponent
    ],
    exports: [
        GateServiceEntrancesIntercomsComponent
    ]
})
export class GateServiceEntrancesIntercomsModule { }
