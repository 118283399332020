<div class="info-block">
  <div
    #image
    class="info-block-image"
    [style.width]="contentWidth + 'px'"
  >
    <ng-content select="[info-block-image]"></ng-content>
  </div>

  <h3 #text class="info-block__text">
    <ng-content select="[info-block-text]"></ng-content>
  </h3>

  <ng-content select="[info-block-secondary-text]"></ng-content>

  <div
    class="info-block-body"
    #body
    [class.info-block-body--hidden]="contentHidden"
  >
    <div id="content" #content class="info-block-body-content">
      <ng-content select="[info-block-content]"></ng-content>
    </div>

    <p id="description" #description class="info-block-body__description">
      <ng-content select="[info-block-description]"></ng-content>
    </p>
  </div>

  <a
    class="link info-block__more"
    *ngIf="content.innerHTML || !!description.innerHTML"
    (click)="contentHidden = !contentHidden"
  >
    {{
      contentHidden
        ? ('shared.ui.info_block.more' | translate)
        : ('shared.ui.info_block.hide' | translate)
    }}
  </a>

  <ng-content select="[info-block-actions]"></ng-content>
</div>
