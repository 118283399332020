import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChatContent } from '@app/views/abonents/models';
import { ApiService } from '@app/shared/api';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { Chat } from '../models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({ providedIn: 'root' })
export class ChatService extends ApiService {
  headers = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
    super(httpClient, humanErrorTranslationService, environment.abonentsApiUrl, ['v1']);
  }

  getChat(
    abonentId: number,
    channel: string,
    page: number = 0,
    size: number = 15,
    sort: string = 'messageDate,desc'
  ): Observable<Chat> {
    let params = new HttpParams();
    params = params.append('channel', channel.toString());
    params = params.append('page', page.toString());
    params = params.append('size', size.toString());
    params = params.append('sort', sort.toString());

    return this.get(`/abonents/${abonentId}/messages`, 1, { headers: this.headers, params });
  }

  sendMessage(abonentId: number, message: string): Observable<ChatContent> {
    const request: Object = { channel: 'support', message: message };

    return this.post(`/abonents/${abonentId}/messages`, 1, request, { headers: this.headers });
  }
}
