import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { ServiceFlatsState } from './service-flats.state';

@Injectable()
export class ServiceFlatsStore extends ComponentStore<ServiceFlatsState> {
    constructor() {
        super();
    }
}
