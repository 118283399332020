import { Component, Input } from '@angular/core';
import {environment} from 'environments/environment';

@Component({
  selector: 'app-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.scss']
})
export class ButtonLinkComponent {
  public readonly environment = environment;
  private _color: string;

  @Input() link!: string;
  @Input()
  public set color(value: string) {
    this._color = value;
  }

  public get color(): string {
    return this._color;
  }
}
