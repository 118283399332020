import {ServiceFacade} from '@app/views/services/store';
import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/shared/components';
import {EntranceService} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {FlatRange} from '@app/shared/models';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {
  AddAdditionalFlatRange,
  AddAdditionalFlatRangeFailure,
  AddAdditionalFlatRangeSuccess,
  DeleteAdditionalFlatRange,
  DeleteAdditionalFlatRangeFailure,
  DeleteAdditionalFlatRangeSuccess,
  ServicesActionTypes,
  UpdateAdditionalFlatRange,
  UpdateAdditionalFlatRangeFailure,
  UpdateAdditionalFlatRangeSuccess
} from '../actions';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServicesAdditionalFlatRangesEffects {
  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private serviceFacade: ServiceFacade,
    private entranceService: EntranceService,
    private translate: TranslateService
  ) {}

  AddAdditionalFlatRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddAdditionalFlatRange>(ServicesActionTypes.AddAdditionalFlatRange),
      switchMap((action: AddAdditionalFlatRange) =>
        this.entranceService.createFlatRange(action.entranceId, action.range)
          .pipe(
            map((response: FlatRange) => {
              this.snackbar.showMessage(
                this.translate.instant('services.additional_flat_ranges.message.create.success'),
                'success'
              );
              return new AddAdditionalFlatRangeSuccess(action.entranceId, response);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.additional_flat_ranges.message.create.failed', {
                  text: parseError(error)
                })
              );
              return of(new AddAdditionalFlatRangeFailure());
            })
          ))
    )
  );

  AddAdditionalFlatRangeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddAdditionalFlatRangeSuccess>(ServicesActionTypes.AddAdditionalFlatRangeSuccess),
      tap((action: AddAdditionalFlatRangeSuccess) => {
        this.serviceFacade.getConnectionsInit();
      })
    ), {dispatch: false}
  );

  UpdateAdditionalFlatRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateAdditionalFlatRange>(ServicesActionTypes.UpdateAdditionalFlatRange),
      switchMap((action: UpdateAdditionalFlatRange) =>
        this.entranceService.updateFlatRange(action.entranceId, action.rangeId, action.range)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.additional_flat_ranges.message.update.success'),
                'success'
              );
              return new UpdateAdditionalFlatRangeSuccess(action.entranceId, action.rangeId, action.range);
            }),
            catchError(error => {
              this.snackbar.showMessage(
                this.translate.instant('services.additional_flat_ranges.message.update.failed', {
                  text: parseError(error)
                })
              );
              return of(new UpdateAdditionalFlatRangeFailure());
            })
          ))
    )
  );

  UpdateAdditionalFlatRangeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateAdditionalFlatRangeSuccess>(ServicesActionTypes.UpdateAdditionalFlatRangeSuccess),
      tap((action: UpdateAdditionalFlatRangeSuccess) => {
        this.serviceFacade.getConnectionsInit();
      })
    ), {dispatch: false}
  );

  DeleteAdditionalFlatRange$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteAdditionalFlatRange>(ServicesActionTypes.DeleteAdditionalFlatRange),
      switchMap((action: DeleteAdditionalFlatRange) =>
        this.entranceService.deleteFlatRange(action.entranceId, action.rangeId)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.additional_flat_ranges.message.delete.success'),
                'success'
              );
              return new DeleteAdditionalFlatRangeSuccess(action.entranceId, action.rangeId);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.additional_flat_ranges.message.delete.failed', {
                  text: parseError(error)
                })
              );
              return of(new DeleteAdditionalFlatRangeFailure());
            })
          ))
    )
  );

  DeleteAdditionalFlatRangeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteAdditionalFlatRangeSuccess>(ServicesActionTypes.DeleteAdditionalFlatRangeSuccess),
      tap((action: DeleteAdditionalFlatRangeSuccess) => {
        this.serviceFacade.getConnectionsInit();
      })
    ), {dispatch: false}
  );
}
