import { Injectable, OnDestroy } from '@angular/core';
import { DialogWrapperStateHelper } from '@app/shared/ui';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import { DeleteServiceKey, DeleteServiceKeyFailure, DeleteServiceKeySuccess, ServicesActionTypes } from '../../../../store';

@Injectable()
export class ServiceDeleteKeyPopupService extends DialogWrapperStateHelper<'loading' | 'close' | 'loaded'> implements OnDestroy {
    constructor(
        private actions$: Actions
    ) {
        super();
        this.enableStateListeners();
    }

    ngOnDestroy() {
        this.destroy();
    }

    private enableStateListeners() {
        this.actions$
            .pipe(
                ofType<DeleteServiceKey>(ServicesActionTypes.DeleteServiceKey), takeUntil(this.onDestroy$),
            )
            .subscribe(() => this.state = 'loading');

        this.actions$
            .pipe(
                ofType<DeleteServiceKeySuccess>(ServicesActionTypes.DeleteServiceKeySuccess), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'close');

        this.actions$
            .pipe(
                ofType<DeleteServiceKeyFailure>(ServicesActionTypes.DeleteServiceKeyFailure), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loaded');
    }
}
