import { AwxJobStatus } from '@app/shared/entities/rd';
import { ActionReducerMap } from '@ngrx/store';
import { IntercomUpdateToolStatus } from '../intercom-update-tool-status.model';
import { IntercomUpdateToolActions, IntercomUpdateToolActionTypes } from './intercom-update-tool.actions';
import { intercomUpdateToolInitialState, IntercomUpdateToolIntecomsState } from './intercom-update-tool.state';

function reducer(state = intercomUpdateToolInitialState, action: IntercomUpdateToolActions): IntercomUpdateToolIntecomsState {
    switch (action.type) {
        case IntercomUpdateToolActionTypes.InitUpdateJobs: {
            if (!state.intercomUpdateState[action.intercomUid]) {
                state.intercomUpdateState[action.intercomUid] = {
                    errorsCount: state.intercomUpdateState[action.intercomUid]?.errorsCount ?? 0,
                    listening: undefined,
                    rdaaUpdateJob: undefined,
                    rdosUpdateJob: undefined,
                    rdaaUpdateJobLogs: undefined,
                    rdosUpdateJobLogs: undefined,
                    status: IntercomUpdateToolStatus.NOT_STARTED,
                };
            }

            return {
                ...state,
            };
        }

        case IntercomUpdateToolActionTypes.SetIntercomStatus: {
          state.intercomUpdateState[action.intercomUid].status = action.status;

          return {
            ...state,
          };
        }

        case IntercomUpdateToolActionTypes.ClearUpdateJobs: {
            state.intercomUpdateState[action.intercomUid] = {
                errorsCount: state.intercomUpdateState[action.intercomUid]?.errorsCount ?? 0,
                listening: undefined,
                rdaaUpdateJob: undefined,
                rdosUpdateJob: undefined,
                rdaaUpdateJobLogs: undefined,
                rdosUpdateJobLogs: undefined,
                status: IntercomUpdateToolStatus.NOT_STARTED,
            };

            return {
                ...state,
            };
        }

        case IntercomUpdateToolActionTypes.FinishUpdateJobs: {
            if (
                state.intercomUpdateState[action.intercomUid].status !== IntercomUpdateToolStatus.SUCCESS &&
                state.intercomUpdateState[action.intercomUid].status !== IntercomUpdateToolStatus.FAILED
            ) {
                state.intercomUpdateState[action.intercomUid].errorsCount++;
            }

            state.intercomUpdateState[action.intercomUid].status =
                action.rdaaUpdateState && action.rdosUpdateState ?
                    IntercomUpdateToolStatus.SUCCESS : IntercomUpdateToolStatus.FAILED;

            return {
                ...state,
            };
        }

        case IntercomUpdateToolActionTypes.GetUpdateRdaaJob: {
            state.intercomUpdateState[action.intercomUid].listening = false;

            return {
                ...state
            };
        }

        case IntercomUpdateToolActionTypes.GetUpdateRdaaJobSuccess: {
            state.intercomUpdateState[action.intercomUid].rdaaUpdateJob = action.response;

            if (action.response.status === AwxJobStatus.RUNNING) {
                state.intercomUpdateState[action.intercomUid].status = IntercomUpdateToolStatus.PROGRESS;
            }

            return {
                ...state
            };
        }

        case IntercomUpdateToolActionTypes.GetUpdateRdosJob: {
            state.intercomUpdateState[action.intercomUid].listening = false;

            return {
                ...state
            };
        }

        case IntercomUpdateToolActionTypes.GetUpdateRdosJobSuccess: {
            state.intercomUpdateState[action.intercomUid].rdosUpdateJob = action.response;

            if (action.response.status === AwxJobStatus.RUNNING) {
                state.intercomUpdateState[action.intercomUid].status = IntercomUpdateToolStatus.PROGRESS;
            }

            return {
                ...state
            };
        }

        case IntercomUpdateToolActionTypes.CreateUpdateRdaaJob: {
            state.intercomUpdateState[action.intercomUid].status = IntercomUpdateToolStatus.STARTED;

            return {
                ...state
            };
        }

        case IntercomUpdateToolActionTypes.CreateUpdateRdaaJobSuccess: {
            state.intercomUpdateState[action.intercomUid].listening = true;
            state.intercomUpdateState[action.intercomUid].rdaaUpdateJob = action.response;
            state.intercomUpdateState[action.intercomUid].status = IntercomUpdateToolStatus.PROGRESS;

            return {
                ...state
            };
        }

        case IntercomUpdateToolActionTypes.CreateUpdateRdosJob: {
            state.intercomUpdateState[action.intercomUid].status = IntercomUpdateToolStatus.STARTED;

            return {
                ...state
            };
        }

        case IntercomUpdateToolActionTypes.CreateUpdateRdosJobSuccess: {
            state.intercomUpdateState[action.intercomUid].listening = true;
            state.intercomUpdateState[action.intercomUid].rdosUpdateJob = action.response;
            state.intercomUpdateState[action.intercomUid].status = IntercomUpdateToolStatus.PROGRESS;

            return {
                ...state
            };
        }

        case IntercomUpdateToolActionTypes.GetUpdateRdaaJobLogs: {
            return {
                ...state
            };
        }

        case IntercomUpdateToolActionTypes.GetUpdateRdaaJobLogsSuccess: {
            state.intercomUpdateState[action.intercomUid].rdaaUpdateJobLogs = action.response;

            return {
                ...state
            };
        }

        case IntercomUpdateToolActionTypes.GetUpdateRdosJobLogs: {
            return {
                ...state
            };
        }

        case IntercomUpdateToolActionTypes.GetUpdateRdosJobLogsSuccess: {
            state.intercomUpdateState[action.intercomUid].rdosUpdateJobLogs = action.response;

            return {
                ...state
            };
        }

        case IntercomUpdateToolActionTypes.DeleteUpdateJobStateIfSuccess: {
            if (state.intercomUpdateState[action.intercomUid]?.status === IntercomUpdateToolStatus.SUCCESS) {
                delete state.intercomUpdateState[action.intercomUid];
            }

            return {
                ...state
            };
        }

        default: {
            return {
                ...state
            };
        }
    }
}

export const intercomUpdateToolReducers: ActionReducerMap<{ intercomUpdateTool: IntercomUpdateToolIntecomsState }> = {
    intercomUpdateTool: reducer
};
