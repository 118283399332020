import { HttpErrorResponse } from '@angular/common/http';
import { OrderResponse } from '@app/views/intercom/models';
import { Actions, ActionTypes } from '../../actions/orders.actions';

export interface State {
    orders: OrderResponse[];
    totalCount: number;
    error: string | HttpErrorResponse | null;
    loading: boolean;
}

export const initialState: State = {
    orders: null,
    totalCount: null,
    error: null,
    loading: false
};

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case ActionTypes.GetOrdersPage: {
            return {
                ...state,
                orders: null,
                totalCount: null,
                error: null,
                loading: true
            };
        }

        case ActionTypes.GetOrdersPageSuccess: {
            return {
                ...state,
                orders: action.payload,
                totalCount: action.totalCount,
                error: null,
                loading: false
            };
        }

        case ActionTypes.GetOrdersPageFailure: {
            return {
                ...state,
                orders: null,
                totalCount: null,
                error: action.payload,
                loading: false
            };
        }

        case ActionTypes.GetOrdersPageClear: {
            return initialState;
        }

        case ActionTypes.UpdateOrderSuccess: {
            const changeOrderIdx: number = state.orders.findIndex(order => order.id === action.orderId);
            if (action.request.rdaCount) {
                state.orders[changeOrderIdx].rdaCount = action.request.rdaCount;
            }
            if (action.request.status) {
                state.orders[changeOrderIdx].status = action.request.status;
            }

            return {
                ...state,
                error: null,
                loading: false
            };
        }

        default: {
            return state;
        }
    }
}

