import { NavbarLink } from '@app/shared/ui';
import { ServiceIntercomPanelsMode } from '@app/views/services/components';
import { ServiceIntercomPopupMode } from '../service-intercom-popup-mode';

export const initialServiceIntercomPopupSteps: NavbarLink[] = [
    { name: ServiceIntercomPopupMode.INTERCOM, text: 'service.intercom.popup.stepper.adapters' },
    { name: ServiceIntercomPopupMode.TRANSLATIONS, text: 'service.intercom.popup.stepper.displacements' },
    { name: ServiceIntercomPopupMode.INTERCOM_PANELS, text: 'service.intercom.popup.stepper.intercom_panels' },
    { name: ServiceIntercomPopupMode.PBX_ON_RDA, text: 'service.intercom.popup.stepper.pbx_on_rda', disabled: true, hidden: true }
];

export interface ServiceIntercomPopupState {
    steps: NavbarLink[];
    mode: ServiceIntercomPopupMode;
    newIntercom: boolean;
    selectedIntercomUid: string;
    formChanged: boolean;
    showMenu: boolean;
    intercomPanelsMode: ServiceIntercomPanelsMode;
}
