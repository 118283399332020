import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EntrancesModuleState, EntrancesRootState, EntrancesState } from './entrances.state';

const entrancesFeatureSelector = createFeatureSelector<EntrancesRootState, EntrancesModuleState>('entrances');

const selectState = createSelector(
    entrancesFeatureSelector,
    (state: EntrancesModuleState) => state.entrances
);

const selectEntrances = createSelector(
    selectState,
    (state: EntrancesState) => state.entrances
);

const selectEntrancesLoading = createSelector(
    selectState,
    (state: EntrancesState) => state.entrancesLoading
);

const selectCountsLoading = createSelector(
    selectState,
    (state: EntrancesState) => state.countsLoading
);

const selectPageData = createSelector(
    selectState,
    (state: EntrancesState) => state.pageData
);

const selectCounts = createSelector(
    selectState,
    (state: EntrancesState) => state.counts
);

const selectEntrance = createSelector(
    selectState,
    (state: EntrancesState) => state.entrance
);

const selectEntranceLoading = createSelector(
    selectState,
    (state: EntrancesState) => state.loadingEntrance
);

const selectRda = createSelector(
    selectState,
    (state: EntrancesState) => state.rda
);

const selectCompany = createSelector(
    selectState,
    (state: EntrancesState) => state.company
);

const selectKeys = createSelector(
    selectState,
    (state: EntrancesState) => state.keys
);

const selectKeysLoading = createSelector(
    selectState,
    (state: EntrancesState) => state.loadingKeys
);

const selectFlats = createSelector(
    selectState,
    (state: EntrancesState) => state.flats
);

const selectFlatsLoading = createSelector(
    selectState,
    (state: EntrancesState) => state.loadingFlats
);

const selectGitlabVersions = createSelector(
    selectState,
    (state: EntrancesState) => state.gitlabVersions
);

const selectPbxOnRda = createSelector(
    selectState,
    (state: EntrancesState) => state.pbxOnRda
);

const selectCameras = createSelector(
    selectState,
    (state: EntrancesState) => state.cameras
);

const selectCamerasLoading = createSelector(
    selectState,
    (state: EntrancesState) => state.loadingCameras
);

const selectCamerasWithErrors = createSelector(
    selectState,
    (state: EntrancesState) => state.camerasWithErrors
);

const selectLogs = createSelector(
    selectState,
    (state: EntrancesState) => state.logs
);

const selectLogsLoading = createSelector(
    selectState,
    (state: EntrancesState) => state.loadingLogs
);

const selectIncorrectId = createSelector(
    selectState,
    (state: EntrancesState) => state.incorrectId
);

export const entrancesSelectors = {
    selectEntrances,
    selectEntrancesLoading,
    selectCountsLoading,
    selectPageData,
    selectCounts,

    selectEntrance,
    selectEntranceLoading,
    selectRda,
    selectCompany,
    selectKeys,
    selectKeysLoading,
    selectFlats,
    selectFlatsLoading,
    selectGitlabVersions,
    selectPbxOnRda,
    selectCameras,
    selectCamerasLoading,
    selectCamerasWithErrors,
    selectLogs,
    selectLogsLoading,
    selectIncorrectId
};
