import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService } from '@app/shared/api';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { IntercomPanelUpdateRequest } from '../models';
import { HumanErrorTranslationService } from '@app/shared/services/human-error-translation.service';

@Injectable({
    providedIn: 'root'
})
export class IntercomPanelApiService extends ApiService {
    headers: HttpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(httpClient: HttpClient, humanErrorTranslationService: HumanErrorTranslationService) {
        super(httpClient, humanErrorTranslationService, environment.intercomApiUrl, ['v1', 'v2']);
    }

    update(intercomId: number, request: Partial<IntercomPanelUpdateRequest>): Observable<void> {
        return this.patch(`/intercoms/${intercomId}`, 1, request, { headers: this.headers });
    }
}
