import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { environment } from 'environments/environment';
import { ServiceFlat } from '../service-flats/models';

@Component({
  selector: 'app-service-flats-menu',
  templateUrl: './service-flats-menu.component.html',
  styleUrls: ['./service-flats-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceFlatsMenuComponent {
  readonly environment = environment;

  @Input() filterRowState: boolean;
  @Input() selectedFilterCount: number;
  @Input() tariffActiveState: boolean;
  @Input() selectedFlatList: ServiceFlat[];
  @Input() manageActiveState: boolean;
  @Input() deleteActiveState: boolean;
  @Input() deleteAbonentsActiveState: boolean;
  @Input() xlgWDownBreakpoint: boolean;
  @Input() isVideoSurveillance: boolean;

  @Output() toggleFilterRow: EventEmitter<void> = new EventEmitter();
  @Output() resetFilters: EventEmitter<void> = new EventEmitter();
  @Output() addPhone: EventEmitter<void> = new EventEmitter();
  @Output() delegate: EventEmitter<ServiceFlat> = new EventEmitter();
  @Output() addVirtualFlat: EventEmitter<void> = new EventEmitter();
  @Output() addConnections: EventEmitter<void> = new EventEmitter();
  @Output() setTarrif: EventEmitter<ServiceFlat[]> = new EventEmitter();
  @Output() manage: EventEmitter<void> = new EventEmitter();
  @Output() deleteFlat: EventEmitter<ServiceFlat> = new EventEmitter();
  @Output() deleteSharedAbonents: EventEmitter<ServiceFlat[]> =
    new EventEmitter();

  public onToggleFilterRow(): void {
    this.toggleFilterRow.emit();
  }

  public onResetFilters(): void {
    this.resetFilters.emit();
  }

  public onAddPhone(): void {
    this.addPhone.emit();
  }

  public onDelegate(flat: ServiceFlat): void {
    this.delegate.emit();
  }

  public onAddVirtualFlat(): void {
    this.addVirtualFlat.emit();
  }

  public onAddConnections(): void {
    this.addConnections.emit();
  }

  public onTariff(): void {
    this.setTarrif.emit();
  }

  public onManage(): void {
    this.manage.emit();
  }
  public onDeleteFlat(): void {
    this.deleteFlat.emit();
  }

  public onDeleteSharedAbonents() {
    this.deleteSharedAbonents.emit();
  }
}
