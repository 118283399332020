import { Directive, HostListener, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[appLowerCase]'
})
export class LowerCaseDirective {
  @Input() control: AbstractControl;

  @HostListener('input', ['$event'])
  onInput(): void {
    const value = this.control.value
    this.control.setValue(value.toLowerCase())
  }
}
