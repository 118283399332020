<div class="component-data" *ngIf="intercomPanels?.length > 0">
  <div class="component-data-item" *ngFor="let intercomPanel of intercomPanels; let idx = index">
    <div class="component-data-item-actions-block">
      <button
        *ngIf="!editDisabled"
        mat-fab
        color="primary-inverse"
        class="component-data-item-actions-block__button"
        (click)="onEdit(idx)"
      >
        <mat-icon>settings</mat-icon>
      </button>

      <button
        *ngIf="intercomPanels?.length > 1 && !deleteDisabled"
        mat-fab
        color="error"
        class="component-data-item-actions-block__button"
        (click)="onDelete(idx)"
      >
        <mat-icon>remove</mat-icon>
      </button>
    </div>

    <div class="component-data-item-info">
      <p class="component-data-item-info__text">
        <strong>{{ 'shared.intercom.panels.list.content.call_panel' | translate }} {{ idx + 1 }}</strong>
      </p>

      <p class="component-data-item-info__text" *ngIf="intercomPanel.location">
        {{ intercomPanel.location.name }}
      </p>

      <p class="component-data-item-info__text">
        {{ 'shared.intercom.panels.list.content.index' | translate }}: {{ intercomPanel.index ?? 'shared.intercom.panels.list.content.not_set' | translate }}
      </p>
    </div>
  </div>
</div>
