export enum RdvaCameraLogsStatusName {
    STARTING_CONNECTION = 'STARTING_CONNECTION',
    SUCCESSFULLY_CONNECTED = 'SUCCESSFULLY_CONNECTED',
    FAILED_CONNECTION_TIMEOUT = 'FAILED_CONNECTION_TIMEOUT',
    FAILED_CONNECTION_REFUSED = 'FAILED_CONNECTION_REFUSED',
    FAILED_NO_ROUTE_TO_HOST = 'FAILED_NO_ROUTE_TO_HOST',
    FAILED_NETWORK_IS_UNREACHEABLE = 'FAILED_NETWORK_IS_UNREACHEABLE',
    FAILED_UNAUTHORIZED = 'FAILED_UNAUTHORIZED',
    FAILED_AUTHORIZATION_REQUIRED = 'FAILED_AUTHORIZATION_REQUIRED',
    FAILED = 'FAILED'
}
