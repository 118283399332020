import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';

import { ServiceFilterItemComponent } from './service-filter-item';

import { ServiceFlatsFiltersComponent } from './service-flats-filters.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [CommonModule, MaterialModule, NgxTranslateModule],
  declarations: [ServiceFlatsFiltersComponent, ServiceFilterItemComponent],
  exports: [ServiceFlatsFiltersComponent]
})
export class ServiceFlatsFiltersModule {}
