import { Injectable } from '@angular/core';
import { ServiceConnection } from '@app/shared/entities/rd';
import { EntranceResponse } from '@app/shared/models';
import { State } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AbonentsFromEntrancesConnection, AbonentsFromEntrancesStatus } from '../models';
import { AbonentsFromEntrancesClearState, AbonentsFromEntrancesFinishConnectionProcess, AbonentsFromEntrancesStartConnectionProcess, AbonentsFromEntrancesUpdateConnectionProcess, AbonentsFromEntrancesSetChecked, AbonentsFromEntrancesSetStatus, AbonentsFromEntrancesSetAllChecked, AbonentsFromEntrancesGetConnections, AbonentsFromEntrancesRemoveConnection } from './abonents-from-entrances.actions';
import { abonentsFromEntrancesConnectorSelectors } from './abonents-from-entrances.selectors';

@Injectable()
export class AbonentsFromEntrancesFacade {
    constructor(private store: Store<State>) { }

    status$: Observable<AbonentsFromEntrancesStatus> =
        this.store.select(abonentsFromEntrancesConnectorSelectors.selectStatus);
    selectedAll$: Observable<boolean> =
        this.store.select(abonentsFromEntrancesConnectorSelectors.selectSelectedAll);
    selectConnections$: Observable<AbonentsFromEntrancesConnection[]> =
        this.store.select(abonentsFromEntrancesConnectorSelectors.selectConnections);
    totalConnections$: Observable<number> =
        this.store.select(abonentsFromEntrancesConnectorSelectors.selectTotalConnections);
    doneConnections$: Observable<number> =
        this.store.select(abonentsFromEntrancesConnectorSelectors.selectDoneConnections);

    getAbonentsFromEntrancesAccounts(entrances: Pick<EntranceResponse, 'id'>[], ignoredServices: number[]) {
        this.store.dispatch(new AbonentsFromEntrancesGetConnections(entrances, ignoredServices));
    }

    async addConnectionsLoadedListener(
        cb: (accountsCount: number) => void
    ) {
        await this.selectConnections$
            .pipe(
                map(connection =>
                    connection !== undefined && cb(Object.keys(connection)?.length)
                )
            )
            .toPromise();
    }

    changeAbonentsFromEntrancesChecked(connectionId: number) {
        this.store.dispatch(new AbonentsFromEntrancesSetChecked(connectionId));
    }

    changeAllAbonentsFromEntrancesChecked() {
        this.store.dispatch(new AbonentsFromEntrancesSetAllChecked());
    }

    removeAbonentsFromEntrances(connections: Pick<ServiceConnection, 'id'>[]) {
        this.store.dispatch(new AbonentsFromEntrancesRemoveConnection(connections));
    }

    changeStatus(status: AbonentsFromEntrancesStatus) {
        this.store.dispatch(new AbonentsFromEntrancesSetStatus(status));
    }

    startConnectionProgress(totalConnections: number) {
        this.store.dispatch(new AbonentsFromEntrancesStartConnectionProcess(totalConnections));
    }

    updateConnectionProgress(incrementDoneConnections: number, connectionId: number) {
        this.store.dispatch(new AbonentsFromEntrancesUpdateConnectionProcess(incrementDoneConnections, connectionId));
    }

    finishConnectionProgress() {
        this.store.dispatch(new AbonentsFromEntrancesFinishConnectionProcess());
    }

    clearState() {
        this.store.dispatch(new AbonentsFromEntrancesClearState());
    }
}
