import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { UpdateCompanyRequest } from "@app/views/company/store/company-page.state";
import { CompanyPageStore } from "@app/views/company/store/company-page.store";
import { Subscription } from "rxjs";

@Component({
    selector: 'app-company-notifications',
    templateUrl: './company-notifications.component.html',
    styleUrls: ['./company-notifications.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyNotificationsComponent implements OnInit, OnDestroy {
    @Input() adaptersStatesNotification: string;
    @Input() camerasStatesNotification: string;
    @Input() delegationsNotification: string;
    private sub$: Subscription;
    constructor(private store: CompanyPageStore) { }
    public form: UntypedFormGroup;


    public ngOnInit(): void {
        this.form = new UntypedFormGroup({
            adaptersStatesNotification: new UntypedFormControl(this.adaptersStatesNotification),
            camerasStatesNotification: new UntypedFormControl(this.camerasStatesNotification),
            delegationsNotification: new UntypedFormControl(this.delegationsNotification)
        })

        this.sub$ = this.form.valueChanges.subscribe(values => {
            const payload: UpdateCompanyRequest = {
                company: { ...values }
            };
            this.store.updateWithoutLoader(payload);
        })
    }

    public ngOnDestroy(): void {
        this.sub$.unsubscribe();
    }


}