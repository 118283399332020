import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from '@app/shared/components/snackbar';
import { CompanyRegisterRequest } from '@app/shared/entities/rd/company/models';
import { CompanyApiService } from '@app/shared/entities/rd/company/services';
import { parseError } from '@app/shared/helpers';
import { LoaderService } from '@app/shared/entities/common/loader';
import { from, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-company-register-page',
  templateUrl: './company-register-page.component.html',
  styleUrls: ['./company-register-page.component.scss']
})
export class CompanyRegisterPageComponent implements OnDestroy {
  loading$: Observable<boolean>;
  onDestroy$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private loader: LoaderService,
    private snackbar: SnackbarService,
    private companyApiService: CompanyApiService,
    private translate: TranslateService
  ) { }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSubmit(event: { request: CompanyRegisterRequest, errorMessage: string }) {
    if (!event.errorMessage && event.request) {
      this.loader.loaderState = { state: true };

      from(this.companyApiService.registerCompany(event.request))
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
          () => {
            this.loader.loaderState = { state: false };
            this.snackbar.showMessage(this.translate.instant('company.register.page.message.success'), 'success');
          },
          (error: HttpErrorResponse) => {
            this.loader.loaderState = { state: false };
            this.snackbar.showMessage(parseError(error));
          }
        );
    } else {
      this.snackbar.showMessage(event.errorMessage);
      this.router.navigate(['/login']);
    }
  }
}
