<mat-card class="card-container entrance-card">
  <mat-card-header class="header">
    <div class="title-block">
      <mat-card-title>
        {{ 'entrance.page.card.title' | translate }}
      </mat-card-title>
    </div>
  </mat-card-header>

  <hr>

  <mat-card-content class="entrance-card-content">
    <ngx-skeleton-loader
      *ngIf="loading"
      count="1"
      [theme]="{
        'border-radius': '8px',
        'margin-top': '0px',
        'height': '128px'
      }"
    ></ngx-skeleton-loader>

    <ng-container *ngIf="!loading && entrance">
      <h2 class="entrance-card-content__title">
        {{ 'entrance.page.card.field.address' | translate }}:
        {{ entrance.address | address }}
      </h2>

      <p>
        {{ 'entrance.page.card.field.range_flat' | translate: {
          start: entrance.address.entrance.flatStart,
          end: entrance.address.entrance.flatEnd
        } }}
      </p>

      <p *ngIf="entrance.address.entrance.additionalFlatRanges.length > 0">
        {{ 'entrance.page.card.field.additional_range' | translate }}:
        {{ entrance.address.entrance.additionalFlatRanges | arrayMap: '{0} - {1}' }}
      </p>

      <div class="entrance-card-content-action-block">
        <ng-container *ngIf="entrance.rda && !environment.vivotec">
          <button
            mat-raised-button
            color="primary-inverse"
            class="entrance-card-content-action-block__button"
            (click)="onCreateSupportRequest()"
          >
            {{ 'entrance.page.card.button.create_support' | translate }}
          </button>
        </ng-container>
      </div>

    </ng-container>
  </mat-card-content>
</mat-card>
