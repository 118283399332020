import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoginRequest } from '@app/shared/entities/rd';
import { AuthFacade } from '@app/views/auth/store';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Component({
  selector: 'rdea-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginPageComponent {
  protected readonly environment = environment;
  loading$: Observable<boolean> = this.authFacade.loading$;

  constructor(
    private authFacade: AuthFacade
  ) { }

  onSubmit(request: LoginRequest) {
    this.authFacade.login(request);
  }
}
