<ng-container *ngIf="{
  cronErrors: cronErrors$ | async
} as state">
  <form
    class="integration"
    [formGroup]="form"
    (ngSubmit)="onSubmit(!!(state.cronErrors?.length))"
    autocomplete="off"
  >
    <div class="integration-api">
      <h3>
        {{ 'payments.popups.wizard.title' | translate }}
      </h3>

      <div class="integration-api-content">
        <div class="integration-api-content-inputs">
          <ng-container *ngIf="service === serviceType.YOOKASSA">
            <mat-form-field appearance="outline" class="none-padding none-margin">
              <mat-label>
                {{ 'payments.popups.wizard.field.api_key' | translate }}
              </mat-label>
              <input
                matInput
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
                type="text"
                required
                formControlName="apiKey"
                placeholder="{{ 'payments.popups.wizard.placeholder.api_key' | translate }}"
              >
            </mat-form-field>

            <mat-form-field appearance="outline" class="none-padding none-margin">
              <mat-label>
                {{ 'payments.popups.wizard.field.shop_id' | translate }}
              </mat-label>
              <input
                matInput
                autocomplete="off"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
                type="text"
                required
                formControlName="shopId"
                placeholder="{{ 'payments.popups.wizard.placeholder.shop_id' | translate }}"
              >
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="service === serviceType.ROBOKASSA">
            <div class="flex column gap-1">
              <div class="flex row sm-column gap-1">
                <mat-form-field appearance="outline" class="none-padding none-margin">
                  <mat-label>
                    {{ 'payments.popups.wizard.field.uri' | translate }}
                  </mat-label>
                  <mat-select formControlName="uri">
                    <mat-option [value]="robokassaUriType.RU">robokassa.ru</mat-option>
                    <mat-option [value]="robokassaUriType.KZ">robokassa.kz</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" class="none-padding none-margin">
                  <mat-label>
                    {{ 'payments.popups.wizard.field.login' | translate }}
                  </mat-label>
                  <input
                    matInput
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    type="text"
                    required
                    formControlName="login"
                    placeholder="{{ 'payments.popups.wizard.placeholder.login' | translate }}"
                  >
                </mat-form-field>
              </div>
              <div class="flex row sm-column gap-1">
                <mat-form-field appearance="outline" class="none-padding none-margin">
                  <mat-label>
                    {{ 'payments.popups.wizard.field.pass1' | translate }}
                  </mat-label>
                  <input
                    matInput
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    type="text"
                    required
                    formControlName="pass1"
                    placeholder="{{ 'payments.popups.wizard.placeholder.pass1' | translate }}"
                  >
                </mat-form-field>
                <mat-form-field appearance="outline" class="none-padding none-margin">
                  <mat-label>
                    {{ 'payments.popups.wizard.field.pass2' | translate }}
                  </mat-label>
                  <input
                    matInput
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false"
                    type="text"
                    required
                    formControlName="pass2"
                    placeholder="{{ 'payments.popups.wizard.placeholder.pass2' | translate }}"
                  >
                </mat-form-field>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="integration-api-content-suggestion">
          <ul>
            <li>
              <a
                class="integration-api-content-suggestion__link"
                (click)="inputsSuggestionExpanded = !inputsSuggestionExpanded">
                {{
                  inputsSuggestionExpanded
                    ? ('payments.popups.wizard.message.hide_hint' | translate)
                    : ('payments.popups.wizard.message.do_not_know_where_to_find' | translate)
                }}
              </a>
              <div
                class="integration-api-content-suggestion-data"
                *ngIf="inputsSuggestionExpanded"
              >
                <ng-container *ngIf="service === serviceType.YOOKASSA">
                  <p class="integration-api-content-suggestion-data__label">
                    {{ 'payments.popups.wizard.message.text.0' | translate }}
                  </p>

                  <img
                    class="integration-api-content-suggestion-data__image"
                    src="assets/images/yookassa/api-key.png">

                  <p class="integration-api-content-suggestion-data__label">
                    {{ 'payments.popups.wizard.message.text.1' | translate }}
                  </p>

                  <img
                    class="integration-api-content-suggestion-data__image"
                    src="assets/images/yookassa/shop-id.png">
                </ng-container>

                <ng-container *ngIf="service === serviceType.ROBOKASSA">
                  <p class="integration-api-content-suggestion-data__label">
                    {{ 'payments.popups.wizard.message.robokassa.fields' | translate }}
                  </p>

                  <img
                    class="integration-api-content-suggestion-data__image"
                    src="assets/images/robokassa/fields.png">
                </ng-container>
              </div>
            </li>
            <li>
              <a
                class="integration-api-content-suggestion__link"
                [href]="paymentConnectionLink"
                target="_blank"
              >
                {{ 'payments.popups.wizard.link.payment_connection_link' | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <hr>

    <div class="integration-type">
      <h3>
        {{ 'payments.popups.wizard.header.select_model_payment' | translate }}
      </h3>

      <div class="integration-type-content">
        <mat-radio-group class="integration-type-content__input" formControlName="paymentPeriodShift">
          <mat-radio-button
            class="example-radio-button"
            *ngFor="let paymentType of paymentTypes"
            [value]="paymentType.value"
            (click)="onActivePathChange(paymentType.value)"
          >
            {{paymentType.name}}
          </mat-radio-button>
        </mat-radio-group>
        <div class="integration-type-content__img">
          <img src="{{activePath | async}}" alt="error">
        </div>
      </div>
    </div>

    <hr>

    <div class="integration-dates">
      <div class="integration-dates-content">
        <div class="integration-dates-content-accepted">
          <app-cron-date-input
            label="{{ 'payments.popups.wizard.field.invoicing_date' | translate }}"
            formControlName="invoicingDate"
            required
          ></app-cron-date-input>
        </div>
        <hr>
        <div class="integration-dates-content-warning" *ngIf="isRisksAccepts | async">
          <span>
            {{ 'payments.popups.wizard.message.risks_accepts' | translate }}
          </span>
          <a href="{{paymentConnectionLink}}">
            {{ 'payments.popups.wizard.link.read_documentation_to_become_familiar' | translate }}
          </a>
        </div>
        <div class="integration-dates-content-unaccepted">
          <div class="integration-dates-content-unaccepted-inputs">
            <app-cron-date-input
              label="{{ 'payments.popups.wizard.field.remining_date' | translate }}"
              formControlName="reminingDate"
              [editMode]="isRisksAccepts | async"
              required
            ></app-cron-date-input>

            <app-cron-date-input
              label="{{ 'payments.popups.wizard.field.blocking_date' | translate }}"
              formControlName="blockingDate"
              [editMode]="isRisksAccepts | async"
              required
            ></app-cron-date-input>
          </div>
          <div class="integration-dates-content-unaccepted-edit"  *ngIf="!(isRisksAccepts | async)">
            <button
              (click)="onEdit($event)"
              style="border-radius: 0 !important;"
              mat-icon-button>
              <mat-icon aria-label="Edit">edit</mat-icon>
              <span>
                {{ 'payments.popups.wizard.button.edit' | translate }}
              </span>
            </button>
          </div>
        </div>
        <ng-container *ngIf="state.cronErrors?.length then help; else dateResult"></ng-container>
        <ng-template #help>
          <app-possible-cron-solutions
            *ngIf="state.cronErrors?.length"
            [solutionDescription]="state.cronErrors"
            (setAutomatically)="onSetAutomatically()">
          </app-possible-cron-solutions>
        </ng-template>
        <ng-template #dateResult>
          <app-schedule-for-payment
            [isPrePayment]="getFormValue('paymentPeriodShift')"
            [invoicingDate]="getFormValue('invoicingDate')"
            [reminingDate]="getFormValue('reminingDate')"
            [blockingDate]="getFormValue('blockingDate')">
          </app-schedule-for-payment>
        </ng-template>
      </div>
    </div>

    <hr>

    <div class="integration-actions">
      <button
        mat-raised-button
        class="integration-actions__action"
        type="submit"
        color="primary"
        [disabled]="loading$ | async"
      >
        {{ 'payments.popups.wizard.button.submit' | translate }}
      </button>
    </div>
  </form>
</ng-container>
