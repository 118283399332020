import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PreviosQueryType } from '@app/shared/entities/common';
import {
  ConnectionService,
  FlatApiService,
  ServiceApiService,
  ServiceHelperService
} from '@app/shared/entities/rd';
import { Address, ServicesTypes } from '@app/shared/models';
import { SignUpsPageMode } from '@app/views/abonents/models';
import {
  ServiceFlat,
  ServiceFlatsComponent
} from '@app/views/services/components';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ResolutionService } from '@app/shared/services';
import { ServiceFacade, ServicesHelper } from '@app/views/services';
import { SnackbarService } from '@app/shared/components';
import { PhonePipe } from '@app/shared/pipes';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FlatFilterBottomSheetComponent } from '@app/views/services/components/popups/flat-filter/flat-filter-bottom-sheet/flat-filter-bottom-sheet.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-video-surveillance-flats',
  templateUrl: './video-surveillance-flats.component.html',
  styleUrls: [
    '../../../../../../components/containers/service-flats/service-flats.component.scss'
  ],
  providers: [ConnectionService, PhonePipe],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoSurveillanceFlatsComponent extends ServiceFlatsComponent {
  readonly queryType: typeof PreviosQueryType = PreviosQueryType;
  readonly signUpsPageMode: typeof SignUpsPageMode = SignUpsPageMode;
  public ServicesTypes = ServicesTypes;
  public services = [ServicesTypes.VIDEO_SURVEILLANCE];
  public abonentsBillingEnabled$: Observable<boolean> =
    this.serviceFacade.abonentsBillingEnabled$;

  constructor(
    protected readonly dialog: MatDialog,
    protected readonly resolution: ResolutionService,
    protected readonly serviceFacade: ServiceFacade,
    protected readonly serviceHelperService: ServiceHelperService,
    protected readonly serviceApiService: ServiceApiService,
    protected readonly serviceHelper: ServicesHelper,
    protected readonly flatApiService: FlatApiService,
    protected readonly snackbar: SnackbarService,
    protected readonly phonePipe: PhonePipe,
    protected readonly bottomSheet: MatBottomSheet,
    protected readonly translate: TranslateService
  ) {
    super(
      dialog,
      resolution,
      serviceFacade,
      serviceHelperService,
      serviceHelper,
      flatApiService,
      phonePipe,
      bottomSheet,
      translate
    );
  }

  public onAddVideoSurveillance(address: Address, flat: Partial<ServiceFlat>) {
    let hasPhysicalTubeDefault: boolean;
    let hasPhysicalTubeHidden: boolean;

    if (flat?.flat?.virtual !== undefined) {
      hasPhysicalTubeDefault = !flat?.flat?.virtual;
      hasPhysicalTubeHidden = true;
    }

    this.addConnection({
      flat,
      addresses: [address],
      serviceType: ServicesTypes.VIDEO_SURVEILLANCE,
      defaultService: {
        id: this.serviceId,
        type: ServicesTypes.VIDEO_SURVEILLANCE
      },
      hasPhysicalTubeDefault,
      hasPhysicalTubeHidden
    });
  }

  public onAddPhone(address?: Address, flat?: Partial<ServiceFlat>): void {
    const existingConnectionsIds: number[] = [];

    if (
      !flat?.account?.id &&
      flat?.services?.[ServicesTypes.VIDEO_SURVEILLANCE]
    ) {
      existingConnectionsIds.push(
        flat.services[ServicesTypes.VIDEO_SURVEILLANCE].id
      );
    }

    this.addConnection({
      flat,
      addresses: address ? [address] : this.addresses,
      componentName: `Add${this.serviceType}Phone`,
      noPhone: false,
      existingConnectionsIds,
      title: this.translate.instant('services.video_surveillance.flats.add_phone.title'),
      responseMessage: this.translate.instant('services.video_surveillance.flats.add_phone.response.message')
    });
  }

  public openFilterModal(): void {
    const data = {
      services: this.services,
      flatsFilters: this.flatsFilters
    };
    this.bottomSheet.open(FlatFilterBottomSheetComponent, { data });
  }
}
