import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TopBarService {
  private _title: BehaviorSubject<string>;
  private _isBackBtnEnabled: BehaviorSubject<boolean>;

  constructor() {
    this._title = new BehaviorSubject<string>('');
    this._isBackBtnEnabled = new BehaviorSubject<boolean>(false);
  }

  set isBackBtnEnabled(value: boolean) {
    this._isBackBtnEnabled.next(value);
  }

  set title(value: string) {
    this._title.next(value);
  }

  get title$(): Observable<string> {
    return this._title.asObservable();
  }

  get backBtnEnabled$(): Observable<boolean> {
    return this._isBackBtnEnabled.asObservable();
  }
}
