import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'autoConnectionStatus',
  standalone: true
})
export class AutoConnectionStatusPipe implements PipeTransform {
  transform(value: string): string {
    if (value === 'CONNECTION') {
      return 'На подключение услуг';
    }
    if (value === 'DISCONNECTION') {
      return 'На отключение услуг';
    }
    throw Error('Unknown status');
  }
}
