import { HttpErrorResponse } from '@angular/common/http';
import { Actions, ActionTypes } from '../../actions/orders.actions';

export interface State {
    success: boolean;
    error: string | HttpErrorResponse | null;
    loading: boolean;
}

export const initialState: State = {
    success: null,
    error: null,
    loading: false
};

export function reducer(state = initialState, action: Actions): State {
    switch (action.type) {
        case ActionTypes.AddScansToOrder: {
            return {
                ...state,
                success: null,
                error: null,
                loading: true
            };
        }

        case ActionTypes.AddScansToOrderSuccess: {
            return {
                ...state,
                success: true,
                error: null,
                loading: false
            };
        }

        case ActionTypes.AddScansToOrderFailure: {
            return {
                ...state,
                success: false,
                error: action.payload,
                loading: false
            };
        }

        case ActionTypes.AddScansToOrderClear: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}

