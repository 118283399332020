<div class="avs-grid-actions__wrapper">
  <ng-container *ngIf="useDots && !isEmpty(items)">
    <button #btn pButton pRipple icon="pi pi-ellipsis-v" class="p-button-rounded p-button-text"></button>
    <p-contextMenu appendTo="body" [target]="btn" [model]="items" triggerEvent="click"></p-contextMenu>
  </ng-container>
  <ng-container *ngIf="!useDots">
    <p-button icon="pi pi-trash" styleClass="p-button-rounded p-button-danger p-button-text"
              [pTooltip]="'apartments_video_surveillance.popup.disable_service' | translate" tooltipPosition="left"
              (onClick)="onDeleteAction()"></p-button>
    <p-button icon="pi pi-chevron-right" styleClass="p-button-rounded p-button-text"
              [pTooltip]="'apartments_video_surveillance.popup.details_service' | translate" tooltipPosition="left"
              (onClick)="onNavigateAction()"></p-button>
  </ng-container>
</div>
