import { createSelector } from '@ngrx/store';
import { SharedModuleStates } from '../../reducers/reducers';
import { sharedModuleSelector } from '../shared-module.state';

export const camerasPageState = createSelector(
  sharedModuleSelector,
  (state: SharedModuleStates) => state.getCamerasPage
);

export const camerasPageStateSuccess = createSelector(
  camerasPageState,
  state => ({ cameras: state.cameras, totalCount: state.totalCount })
);

export const camerasPageStateFailure = createSelector(
  camerasPageState,
  state => state.error
);

export const camerasPageStateLoading = createSelector(
  camerasPageState,
  state => state.loading
);
