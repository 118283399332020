<div class="page-container dashboard">
  <app-service-navbar
    [pageMode]="pageMode$ | async"
    (pageModeChange)="onChangePageMode($event.pageMode)"
  ></app-service-navbar>

  <ng-container [ngSwitch]="pageMode$ | async">

    <ng-container *ngSwitchCase="pageMode.ABONENTS">
      <app-software-intercom-flats
        [serviceId]="serviceId$ | async"
        [companyId]="(company$ | async)?.id"
        [serviceType]="serviceType"
        [addresses]="entrances$ | async"
        [loading]="abonentsLoading$ | async"
        [flatsFilters]="flatsFilters$ | async"
        [dependantServices]="dependantServices$ | async"
        [flats]="flats$ | async"
        [enabled]="(entrances$ | async)?.length > 0"
        [tariff]="(serviceTariff$ | async)"
        (manageClose)="onManageClose()"
      ></app-software-intercom-flats>
    </ng-container>

    <ng-container *ngSwitchCase="pageMode.SETTINGS">
      <app-service-rda-warning
        [rdas]="rdas$ | async"
        [componentName]="serviceType"
        [serviceName]="(serviceCustomName$ | async) ?? (serviceName$ | async)"
      ></app-service-rda-warning>

      <app-service-cameras-warning
        [cameras]="cameras$ | async"
      ></app-service-cameras-warning>

      <app-service-config
        [company]="company$ | async"
        [serviceType]="serviceType"
        [rdas]="rdas$ | async"
        [supportHidden]="!(rdas$ | async)?.length || !haveUserSupportAccess"
        [emergencyHidden]="!(rdas$ | async)?.length"
        [customName]="serviceCustomName$ | async"
        [name]="serviceName$ | async"
        (openWizard)="onOpenWizard()"
      ></app-service-config>

      <app-service-entrances
        [maxCount]="1"
        [entrances]="entrances$ | async"
        [serviceType]="serviceType"
        [displayedColumns]="['address', 'range', 'additionalRanges', 'actions']"
      ></app-service-entrances>

      <app-service-intercoms
        [intercoms]="rdas$ | async"
        [addDisabledCondition]="false"
        [serviceType]="serviceType"
        [entrances]="(entrances$ | async)"
        [componentsVersions]="componentsVersions$ | async"
      ></app-service-intercoms>

      <app-software-intercom-cameras
        [cameras]="cameras$ | async"
        [serviceType]="serviceType"
        [rdas]="rdas$ | async"
        [connectedRdas]="rdas$ | async"
        [addresses]="entrances$ | async"
        [disabled]="!(entrances$ | async)?.length"
      ></app-software-intercom-cameras>

      <app-service-intercom-keys
        [rdas]="(rdas$ | async)"
        [keys]="(keys$ | async)"
        [locations]="keysLocations$ | async"
        [serviceType]="serviceType"
        [options]="{
          disabledActions: (rdas$ | async)?.length < 1 || (keys$ | async)?.length === maxKeyCount,
          intercomRequired: true
        }"
      ></app-service-intercom-keys>
    </ng-container>

    <ng-container *ngSwitchCase="pageMode.DELEGATION">
      <app-delegation></app-delegation>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <app-service-activity
        [logs]="logs$ | async"
        [logsLoading]="logsLoading$ | async"
        [serviceType]="serviceType"
        [sources]="sources$ | async"
        [timeRange]="timeRange$ | async"
        [blocksCount]="blocksCount$ | async"
        [extendedMode]="extendedMode$ | async"
        [componentType]="logsComponentType$ | async"
        [totalLogsCount]="totalLogsCount$ | async"
        [currentLogsCount]="currentLogsCount$ | async"
        [selectedLogsSource]="selectedLogsSource$ | async"
        [activeHistoryCameras]="activeHistoryCameras$ | async"
        [activeHistoryIntercoms]="activeHistoryIntercoms$ | async"
      ></app-service-activity>
    </ng-container>
  </ng-container>
</div>
