<div class="popup">
  <div class="popup__content">
    <div class="popup__content-icon">
      <img src="assets/icons/ic-alert.svg" alt="error">
    </div>
    <div class="popup__content-text">
      <p>
        {{ 'payments.confirm.message.warning' | translate }}&nbsp;
        <a [href]="paymentConnectionLink">
          {{ 'payments.confirm.link.payment_connection_link' | translate }}
        </a>
      </p>
      <p [innerHTML]="'payments.confirm.message.confirm' | translate"></p>
    </div>
  </div>
  <div class="popup__actions">
    <button
      color="primary"
      mat-raised-button
      class="popup-actions__button"
      (click)="onRisksAccepts()"
    >
      {{ 'payments.confirm.button.risks_accepts' | translate }}
    </button>
  </div>
</div>
