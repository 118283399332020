import {NgModule} from '@angular/core';
import {FindCamerasPopupComponent} from '@app/views/services/submodules/mutual';
import {RdaSelectSearchModule} from '@app/shared/components';
import {DialogWrapperModule, InfoBlockModule} from '@app/shared/ui';
import {MaterialModule} from '@app/material.module';
import {FindCamerasPopupService} from './find-cameras-popup.service';
import {CommonModule} from '@angular/common';
import {ServiceFacade} from '@app/views/services/store/services.facade';
import {IpRdaConnectionTypePipe} from '@app/shared/pipes/ip-rda-connection-type.pipe';
import {SharedPipesModule} from '@app/shared/pipes';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        RdaSelectSearchModule,
        MaterialModule,
        InfoBlockModule,
        DialogWrapperModule,
        SharedPipesModule,
        NgxTranslateModule
    ],
  exports: [FindCamerasPopupComponent],
  declarations: [FindCamerasPopupComponent],
  providers: [
    FindCamerasPopupService,
    ServiceFacade,
    IpRdaConnectionTypePipe
  ],
})
export class FindCamerasPopupModule {
}
