import { Dictionary } from '@app/shared/helpers';
import { FlatCardPhoneModel } from './flat-card-phone.model';
import { FlatCardServicesModel } from './flat-card-services-model';

export interface FlatCardModel {
    blocked: boolean;
    flatNumber: number;
    ownerPhone: FlatCardPhoneModel;
    sharedPhones: FlatCardPhoneModel[];
    services: Dictionary<FlatCardServicesModel>;
}
