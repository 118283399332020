<div
  class="check-software-intercom-block"
  *ngIf="selectedCheckpoint <= 6 && lastCheckpointStep < 8; else readyBlock"
>
  <h3>
    {{ 'software.intercom.wizard.content.checklist.title' | translate }}
  </h3>

  <div class="check-block"
    [ngClass]="{
      'check-block-selected': selectedCheckpoint === 1,
      'check-block-success': selectedCheckpoint > 1
    }"
  >
    <div class="check-number-block">
      <p class="check-number" *ngIf="selectedCheckpoint <= 1; else completeStep">1</p>
      <ng-template #completeStep>
        <mat-icon>done</mat-icon>
      </ng-template>
    </div>
    <div class="check-body">
      <h4>{{ 'software.intercom.wizard.content.checklist.adapter_status_online' | translate }}</h4>
      <ng-container *ngIf="selectedCheckpoint === 1">
        <p [class.check-body-online]="intercom.active" [class.check-body-offline]="!intercom.active">
          {{ 'software.intercom.wizard.content.checklist.current_status' | translate }}:
          {{ intercom.active ? ('software.intercom.wizard.content.checklist.enum.status.online' | translate ) : ('software.intercom.wizard.content.checklist.enum.status.offline' | translate ) }}</p>
        <div class="action-block">
          <button mat-raised-button color="primary-white" (click)="onChangeStep(checklistSteps.INTERCOMS)">
            {{ 'software.intercom.wizard.content.checklist.button.edit' | translate }}
          </button>
          <button mat-raised-button color="primary" (click)="onNextStep()">
            {{ 'software.intercom.wizard.content.checklist.button.done' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="check-block"
    [ngClass]="{
      'check-block-selected': selectedCheckpoint === 2,
      'check-block-success': selectedCheckpoint > 2
    }"
  >
    <div class="check-number-block">
      <p class="check-number" *ngIf="selectedCheckpoint <= 2; else completeStep">2</p>
      <ng-template #completeStep>
        <mat-icon>done</mat-icon>
      </ng-template>
    </div>
    <div class="check-body">
      <h4>
        {{ 'software.intercom.wizard.content.checklist.connect_test_subscriber' | translate }}
      </h4>
      <ng-container *ngIf="selectedCheckpoint === 2">
        <p>
          {{ 'software.intercom.wizard.content.checklist.connect_apartment_make_sure_is_not_blocked_intercom_level' | translate }}
        </p>
        <div class="action-block">
          <button mat-raised-button color="primary-white" (click)="onChangeStep(checklistSteps.TEST_ABONENT)">
            {{ 'software.intercom.wizard.content.checklist.button.correct' | translate }}
          </button>
          <button mat-raised-button color="primary" (click)="onNextStep()">
            {{ 'software.intercom.wizard.content.checklist.button.done' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="check-block"
    [ngClass]="{
      'check-block-selected': selectedCheckpoint === 3,
      'check-block-success': selectedCheckpoint > 3
    }"
  >
    <div class="check-number-block">
      <p class="check-number" *ngIf="selectedCheckpoint <= 3; else completeStep">3</p>
      <ng-template #completeStep>
        <mat-icon>done</mat-icon>
      </ng-template>
    </div>
    <div class="check-body">
      <h4>
        {{ 'software.intercom.wizard.content.checklist.take_into_account_physical_tube' | translate }}
      </h4>
      <ng-container *ngIf="selectedCheckpoint === 3">
        <p>
          {{ 'software.intercom.wizard.content.checklist.if_there_technical_ability_necessary_connect_physical_tube_to_test_apartment' | translate }}
        </p>
        <div class="action-block">
          <button mat-raised-button color="primary-white" (click)="onChangeStep(checklistSteps.PHYSICAL_TUBE)">
            {{ 'software.intercom.wizard.content.checklist.button.correct' | translate }}
          </button>
          <button mat-raised-button color="primary" (click)="onNextStep()">
            {{ 'software.intercom.wizard.content.checklist.button.done' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="check-block"
    [ngClass]="{
      'check-block-selected': selectedCheckpoint === 4,
      'check-block-success': selectedCheckpoint > 4
    }"
  >
    <div class="check-number-block">
      <p class="check-number" *ngIf="selectedCheckpoint <= 4; else completeStep">4</p>
      <ng-template #completeStep>
        <mat-icon>done</mat-icon>
      </ng-template>
    </div>
    <div class="check-body">
      <h4>
        {{ 'software.intercom.wizard.content.checklist.enter_smart_intercom_with_subscriber' | translate }}
      </h4>
      <ng-container *ngIf="selectedCheckpoint === 4">
        <div class="action-block">
          <button mat-raised-button color="primary" (click)="onNextStep()">
            {{ 'software.intercom.wizard.content.checklist.button.done' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="check-block"
    [ngClass]="{
      'check-block-selected': selectedCheckpoint === 5,
      'check-block-success': selectedCheckpoint > 5
    }"
  >
    <div class="check-number-block">
      <p class="check-number" *ngIf="selectedCheckpoint <= 5; else completeStep">5</p>
      <ng-template #completeStep>
        <mat-icon>done</mat-icon>
      </ng-template>
    </div>
    <div class="check-body">
      <h4>
        {{ 'software.intercom.wizard.content.checklist.open_door_from_application' | translate }}
      </h4>
      <ng-container *ngIf="selectedCheckpoint === 5">
        <p>
          {{ 'software.intercom.wizard.content.checklist.without_calling_press_door_opening_button_in_mobile_application' | translate }}
        </p>
        <div class="action-block">
          <button mat-raised-button color="primary" (click)="onNextStep()">
            {{ 'software.intercom.wizard.content.checklist.button.done' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="check-block"
    [ngClass]="{
      'check-block-selected': selectedCheckpoint === 6 && lastCheckpointStep < 8,
      'check-block-success': lastCheckpointStep > 7
    }"
  >
    <div class="check-number-block">
      <p class="check-number" *ngIf="lastCheckpointStep < 8; else completeStep">6</p>
      <ng-template #completeStep>
        <mat-icon>done</mat-icon>
      </ng-template>
    </div>
    <div class="check-body">
      <h4>
        {{ 'software.intercom.wizard.content.checklist.make_call' | translate }}
      </h4>
      <ng-container *ngIf="selectedCheckpoint === 6 && lastCheckpointStep < 8">
        <p>
          {{ 'software.intercom.wizard.content.checklist.he_must_go_to_mobile_device_and_on_physical_tube' | translate }}:
        </p>

        <div class="check-body-subitems">
          <div class="check-body-subitem">
            <div class="check-body-subitem-body">
              <div
                class="check-body-subitem-number"
                [class.check-body-subitem-number-success]="lastCheckpointStep > 1"
              >
                <p *ngIf="lastCheckpointStep <= 1; else completeStep">a</p>
                <ng-template #completeStep>
                  <mat-icon>done</mat-icon>
                </ng-template>
              </div>
              <div class="check-body-subitem-actions">
                <p>
                  {{ 'software.intercom.wizard.content.checklist.reset_call_intercom' | translate }}
                </p>
                <button *ngIf="lastCheckpointStep === 1" mat-raised-button color="primary"
                  (click)="onNextLastCheckpointStep()">
                  {{ 'software.intercom.wizard.content.checklist.button.done' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="check-body-subitem">
            <div class="check-body-subitem-body">
              <div
                class="check-body-subitem-number"
                [class.check-body-subitem-number-success]="lastCheckpointStep > 2"
              >
                <p *ngIf="lastCheckpointStep <= 2; else completeStep">b</p>
                <ng-template #completeStep>
                  <mat-icon>done</mat-icon>
                </ng-template>
              </div>
              <div class="check-body-subitem-actions">
                <p>
                  {{ 'software.intercom.wizard.content.checklist.separate_call_from_mobile_phone_without_taking' | translate }}
                </p>
                <button
                  *ngIf="lastCheckpointStep === 2"
                  mat-raised-button color="primary"
                  (click)="onNextLastCheckpointStep()"
                >
                  {{ 'software.intercom.wizard.content.checklist.button.done' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="check-body-subitem">
            <div class="check-body-subitem-body">
              <div
                class="check-body-subitem-number"
                [class.check-body-subitem-number-success]="lastCheckpointStep > 3"
              >
                <p *ngIf="lastCheckpointStep <= 3; else completeStep">c</p>
                <ng-template #completeStep>
                  <mat-icon>done</mat-icon>
                </ng-template>
              </div>
              <div class="check-body-subitem-actions">
                <p>
                  {{ 'software.intercom.wizard.content.checklist.open_door_from_mobile_phone_without_taking' | translate }}
                </p>
                <button
                  *ngIf="lastCheckpointStep === 3"
                  color="primary"
                  (click)="onNextLastCheckpointStep()"
                  mat-raised-button
                >
                  {{ 'software.intercom.wizard.content.checklist.button.done' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="check-body-subitem">
            <div class="check-body-subitem-body">
              <div
                class="check-body-subitem-number"
                [class.check-body-subitem-number-success]="lastCheckpointStep > 4"
              >
                <p *ngIf="lastCheckpointStep <= 4; else completeStep">d</p>
                <ng-template #completeStep>
                  <mat-icon>done</mat-icon>
                </ng-template>
              </div>
              <div class="check-body-subitem-actions">
                <p>
                  {{ 'software.intercom.wizard.content.checklist.reply_from_mobile_phone_and_talk' | translate }}
                </p>
                <ul>
                  <li>
                    {{ 'software.intercom.wizard.content.checklist.phone_to_gate' | translate }}
                  </li>
                  <li>
                    {{ 'software.intercom.wizard.content.checklist.gate_to_phone' | translate }}
                  </li>
                  <li>
                    {{ 'software.intercom.wizard.content.checklist.calibrate_volume_of_dynamics_and_sensitivity_of_microphone' | translate }}
                  </li>
                </ul>
                <button
                  *ngIf="lastCheckpointStep === 4"
                  color="primary"
                  (click)="onNextLastCheckpointStep()"
                  mat-raised-button
                >
                  {{ 'software.intercom.wizard.content.checklist.button.done' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="check-body-subitem">
            <div class="check-body-subitem-body">
              <div
                class="check-body-subitem-number"
                [class.check-body-subitem-number-success]="lastCheckpointStep > 5"
              >
                <p *ngIf="lastCheckpointStep <= 5; else completeStep">e</p>
                <ng-template #completeStep>
                  <mat-icon>done</mat-icon>
                </ng-template>
              </div>
              <div class="check-body-subitem-actions">
                <p>
                  {{ 'software.intercom.wizard.content.checklist.reply_from_mobile_phone_and_drop_call' | translate }}
                </p>
                <button
                  *ngIf="lastCheckpointStep === 5"
                  color="primary"
                  (click)="onNextLastCheckpointStep()"
                  mat-raised-button
                >
                  {{ 'software.intercom.wizard.content.checklist.button.done' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="check-body-subitem">
            <div class="check-body-subitem-body">
              <div
                class="check-body-subitem-number"
                [class.check-body-subitem-number-success]="lastCheckpointStep > 6"
              >
                <p *ngIf="lastCheckpointStep <= 6; else completeStep">f</p>
                <ng-template #completeStep>
                  <mat-icon>done</mat-icon>
                </ng-template>
              </div>
              <div class="check-body-subitem-actions">
                <p>
                  {{ 'software.intercom.wizard.content.checklist.reply_from_mobile_phone_and_open_gate' | translate }}
                </p>
                <button
                  *ngIf="lastCheckpointStep === 6"
                  color="primary"
                  (click)="onNextLastCheckpointStep()"
                  mat-raised-button
                >
                  {{ 'software.intercom.wizard.content.checklist.button.done' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="check-body-subitem">
            <div class="check-body-subitem-body">
              <div
                class="check-body-subitem-number"
                [class.check-body-subitem-number-success]="lastCheckpointStep > 7"
              >
                <p *ngIf="lastCheckpointStep <= 7; else completeStep">g</p>
                <ng-template #completeStep>
                  <mat-icon>done</mat-icon>
                </ng-template>
              </div>
              <div class="check-body-subitem-actions">
                <p>
                  {{ 'software.intercom.wizard.content.checklist.reply_to_call_from_physical_tube' | translate }}
                </p>
                <button
                  *ngIf="lastCheckpointStep === 7"
                  color="primary"
                  (click)="onNextLastCheckpointStep()"
                  mat-raised-button
                >
                  {{ 'software.intercom.wizard.content.checklist.button.done' | translate }}
                </button>
              </div>
            </div>
          </div>

        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #readyBlock>
  <div class="check-software-intercom-complete">
    <app-info-block>
      <ng-container info-block-text>
        {{ 'software.intercom.wizard.content.checklist.message.service_successfully_tuned' | translate }}
      </ng-container>
    </app-info-block>

    <div class="action-block">
      <button
        mat-raised-button
        mat-dialog-close
        color="primary"
        class="mat-button-lg"
      >
        {{ 'software.intercom.wizard.content.checklist.button.complete_setting' | translate }}
      </button>
    </div>
  </div>
</ng-template>
