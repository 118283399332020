import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogWrapperData } from '@app/shared/ui';
import { OrderStatusHistoryResponse } from '@app/views/intercom/models';

@Component({
  selector: 'app-order-history-popup',
  templateUrl: './order-history-popup.component.html',
  styleUrls: ['./order-history-popup.component.scss']
})
export class OrderHistoryPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<
      { orderStatusHistory: Array<OrderStatusHistoryResponse> }, null
    >
  ) { }
}
