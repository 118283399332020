import { Shield } from '@app/views/intercom/models';
import { Actions, ActionTypes } from '../../actions/shields.actions';

export interface State {
  shields: Shield[];
  error: string | null;
  loading: boolean;
}

export const initialState: State = {
  shields: [],
  error: null,
  loading: false
};

export function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.GetShieldsByBatchNumber: {
      return {
        ...state,
        shields: [],
        error: null,
        loading: true,
      };
    }
    case ActionTypes.GetShieldsByBatchNumberSuccess: {
      return {
        ...state,
        shields: action.payload,
        error: null,
        loading: false
      };
    }
    case ActionTypes.GetShieldsByBatchNumberFailure: {
      return {
        ...state,
        shields: [],
        error: action.payload,
        loading: false,
      };
    }
    case ActionTypes.GetShieldsByBatchNumberClear: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
