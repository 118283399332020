import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IntercomEditFilter } from '@app/shared/components';
import { IntercomType, RdaResponse, RdaUpdateRequest } from '@app/shared/entities/rd';

@Component({
  selector: 'app-gate-wizard-content-intercoms-default',
  templateUrl: './gate-wizard-content-intercoms-default.component.html',
  styleUrls: ['./gate-wizard-content-intercoms-default.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GateWizardContentIntercomsDefaultComponent {
  @Input() intercom: RdaResponse;
  @Input() loading: boolean;
  @Input() intercomType: IntercomType;
  @Input() intercomTypes: IntercomType[];
  @Input() filter: IntercomEditFilter;

  @Output() private updateIntercom: EventEmitter<{ request: RdaUpdateRequest }> = new EventEmitter();

  onSubmit(request: RdaUpdateRequest) {
    this.updateIntercom.emit({ request });
  }
}
