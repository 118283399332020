import { Pipe, PipeTransform } from '@angular/core';
import { IConnection, IToAbonent } from '@app/shared/entities/rd';
import { isEqual } from 'lodash';

@Pipe({
  name: 'delegatedServices'
})
export class DelegatedServicesPipe implements PipeTransform {
  transform(
    delegations: IConnection[],
    ref: { connections: IConnection[]; abonent: IToAbonent }
  ): number {
    return delegations.filter((delegation) => {
      return (
        isEqual(delegation.toAbonent.phone, ref.abonent.phone) &&
        ref.connections.some((connection) =>
          isEqual(connection.service.id, delegation.service.id)
        )
      );
    }).length;
  }
}
