import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { PbxOnRdaSearchSelectModule } from '../pbx-on-rda-search-select';
import { PbxOnRdaEditContentComponent } from './pbx-on-rda-edit-content.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        PbxOnRdaSearchSelectModule,
        TranslateModule
    ],
    declarations: [
        PbxOnRdaEditContentComponent
    ],
    exports: [
        PbxOnRdaEditContentComponent
    ]
})
export class PbxOnRdaEditContentModule { }
