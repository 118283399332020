import { Company } from '@app/shared/entities/rd/company/models';
import { RdaResponse } from '@app/shared/entities/rd/rda';
import { OrderScanResponse } from './order-scan-response.model';
import { OrderStatusHistoryResponse } from './order-status-history-response.model';

export interface OrderResponse {
    id: number;
    customerCompany: Company;
    manufacturerName: string;
    status: string;
    rdaCount: number;
    warrantyStartDate: Date;
    createdAt: Date;
    rdasList: Array<RdaResponse>;
    orderStatusHistoryResponseList: Array<OrderStatusHistoryResponse>;
    orderScanResponseList: Array<OrderScanResponse>;
}
