import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import { IRoleConfig } from '@app/views/company/pages/company-roles-page/company-user-profile/company-user-profile.component';
import {
  IRoleResponse,
  IUserResponse
} from '@app/views/company/models/company-page';
import { FormControl } from '@angular/forms';
import {RolesComponentStoreService} from '@app/views/company/pages/company-roles-page/roles-component-store.service';

export enum UserManagementMode {
  create,
  edit
}

export const suggestedRoles = ['ROLE_SUPPORT'];

@Component({
  selector: 'app-company-roles-management',
  templateUrl: './company-roles-management.component.html',
  styleUrls: ['./company-roles-management.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyRolesManagementComponent {
  @Input() allAvailableRoles: IRoleResponse[] = [];
  @Input() set userResponse(userResponse: IUserResponse | null) {
    this.setRolesConfig(userResponse);
  }
  // pass over the UserResponse
  @Input() mode: UserManagementMode = null;

  @Output() access: EventEmitter<IRoleConfig[]> = new EventEmitter<
    IRoleConfig[]
  >();

  constructor(private rolesComponentStoreService: RolesComponentStoreService) {}

  public configs: IRoleConfig[] = [];

  public onRoleAction(
    state: boolean,
    config: IRoleConfig,
    isClickByName?: boolean
  ): void {
    config.control.setValue(isClickByName ? !state : state);
    config.control.updateValueAndValidity();

    this.onNewAccess();
  }

  private setRolesConfig(userResponse: IUserResponse): void {
    this.configs = this.allAvailableRoles.length
      ? this.allAvailableRoles.map((role: IRoleConfig) => {
          return {
            ...role,
            translate: this.rolesComponentStoreService.rolesDictionary[role.name],
            description: this.rolesComponentStoreService.rolesDescDictionary[role.name],
            control: new FormControl<any>(
              userResponse?.roleNames?.includes(role.name) || (suggestedRoles.includes(role.name) && this.mode === UserManagementMode.create)
            )
          };
        }).filter(r => r?.translate && r.name !== 'ROLE_OWNER').sort((a, b) => {
          if (a?.name < b?.name) {
            return -1;
          }
          if (a?.name > b?.name) {
            return 1;
          }
          return 0;
        })
      : [];

    this.onNewAccess();
  }

  private onNewAccess(): void {
    this.access.emit(this.configs.filter((c) => c.control.value));
  }
}
