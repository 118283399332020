import { Pipe, PipeTransform } from '@angular/core';
import {ViewportRuler} from '@angular/cdk/overlay';

@Pipe({
  name: 'trimLongField'
})
export class TrimLongFieldPipe implements PipeTransform {
  public truncateLength = this.viewportRuler.getViewportSize().width > 450 ? 60 : 20;

  constructor(private readonly viewportRuler: ViewportRuler) {}

  transform(value: string): string {
    value ||= ' ';
    const stringValue: string = value.toString();

    if (stringValue.length < this.truncateLength) {
      return stringValue;
    }

    return stringValue.slice(0, this.truncateLength) + '...';
  }
}
