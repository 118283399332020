<app-dialog-wrapper
  [title]="data.title"
  [componentName]="data.componentName">
  <div class="popup">
    <div class="unblock-icon">
      <mat-icon
        svgIcon="alert"
        class="alert-icon"></mat-icon>
    </div>
    <p>
      {{ data.body.bannerSum }}&nbsp;
      {{ 'company.about.page.settings.form.banner.alert.popup.info' | translate }}
    </p>

    <div class="popup-actions">
      <button
        mat-raised-button
        class="popup-actions__button"
        (click)="onSubmit()"
        color="primary"
      >
        {{ 'company.about.page.settings.form.banner.alert.popup.button.submit' | translate }}
      </button>
    </div>
  </div>
</app-dialog-wrapper>
