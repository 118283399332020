export interface ServiceConnectionRequest {
    phone: number;
    abonentId: number;
    accountId: number;
    servicesIds: number[];
    entranceId: number;
    flatNumber: number;
    flatId?: number;
    companyId: number;
    virtual?: boolean;
}
