import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Camera, RdaResponse, ServiceResponse } from '@app/shared/entities/rd';
import { IntercomType } from '@app/shared/entities/rd/intercom-type/models';
import { Address, LocationResponse, RdeaStepperStep } from '@app/shared/models';
import { DialogWrapperData } from '@app/shared/ui';
import { ServiceFacade } from '@app/views/services/store';
import { GateMode } from '@app/views/services/submodules/gates/models';
import { GatesPageFacade } from '@app/views/services/submodules/gates/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GateWizardPopupService } from './gate-wizard-popup.service';
import { GateWizardCameraStepData, GateWizardChecklistStepData, GateWizardIntercomsStepData, GateWizardIntercomTypeStepData, GateWizardPopupStep, GateWizardPopupStepper, GateWizardUpdateStep } from './models';
import { GateWizardPopupStore } from './store';
import {SoftwareIntercomWizardTariffStepData} from '@app/views';

@Component({
  selector: 'app-gate-wizard-popup',
  templateUrl: './gate-wizard-popup.component.html',
  styleUrls: ['./gate-wizard-popup.component.scss'],
  providers: [GateWizardPopupService, GateWizardPopupStore],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GateWizardPopupComponent {
  wizardStep$: Observable<GateWizardPopupStep> = this.popupStore.wizardStep$;
  showMenu$: Observable<boolean> = this.popupStore.showMenu$;
  showStepButtons$: Observable<boolean> = this.popupStore.showStepButtons$;
  steps$: Observable<RdeaStepperStep[]> = this.popupStore.steps$;

  intercom$: Observable<RdaResponse> = this.gatesPageFacade.intercom$;
  intercomType$: Observable<IntercomType> = this.gatesPageFacade.intercomType$;
  entrancesIntercoms$: Observable<RdaResponse[]> = this.gatesPageFacade.entrancesIntercoms$;
  index$: Observable<string> = this.gatesPageFacade.index$;
  mode$: Observable<GateMode> = this.gatesPageFacade.mode$;

  state$: Observable<'loading' | 'close' | 'loaded'> = this.stateService.state$;
  addresses$: Observable<Address[]> = this.serviceFacade.entrances$;
  serviceId$: Observable<number> = this.serviceFacade.serviceId$;
  intercoms$: Observable<RdaResponse[]> = this.serviceFacade.rdas$;
  intercomTypes$: Observable<IntercomType[]> = combineLatest([
    this.serviceFacade.intercomTypes$,
    this.serviceFacade.rdas$
  ]).pipe(
    map(([intercomTypes, intercoms]: [IntercomType[], RdaResponse[]]) => {
      const allIntercomsIsIp: boolean = intercoms?.reduce((prev, cur) => prev && cur.intercomType?.protocol?.ipType, true);
      return intercoms.length > 1 && allIntercomsIsIp ? intercomTypes.filter(intercomType => intercomType.protocol.ipType) : intercomTypes;
    })
  );
  cameras$: Observable<Camera[]> = this.serviceFacade.cameras$;
  camerasLocations$: Observable<LocationResponse[]> = this.serviceFacade.camerasLocations$;

  addressStepState$: Observable<GateWizardUpdateStep> = this.serviceFacade.entrances$.pipe(
    map((entrances: Address[]) => {
      const entrancesExists: boolean = entrances?.length > 0;
      const entrancesLoaded: boolean = entrances?.length !== undefined;

      return {
        complete: entrancesExists,
        initialized: entrancesLoaded
      };
    })
  );

  intercomTypeStepState$: Observable<GateWizardUpdateStep> =
    combineLatest([this.gatesPageFacade.intercomType$, this.addressStepState$]).pipe(
      map(([intercomType, addressStepState]: GateWizardIntercomTypeStepData) => {
        const intercomTypeSelected: boolean = !!intercomType;
        const addressStepComplete: boolean = addressStepState.complete;

        return {
          complete: intercomTypeSelected,
          initialized: true,
          disabled: !addressStepComplete
        };
      })
    );

  intercomsStepState$: Observable<GateWizardUpdateStep> =
    combineLatest([this.serviceFacade.rdas$, this.intercomTypeStepState$]).pipe(
      map(([intercoms, intercomTypeStepState]: GateWizardIntercomsStepData) => {
        const intercomsExists: boolean = intercoms?.length > 0;
        const intercomsLoaded: boolean = intercoms?.length !== undefined;
        const previousStepComplete: boolean = intercomTypeStepState.complete;

        return {
          complete: intercomsExists,
          initialized: intercomsLoaded,
          disabled: !previousStepComplete
        };
      })
    );

  tariffStepState$: Observable<GateWizardUpdateStep> =
    combineLatest([this.serviceFacade.serviceTariff$, this.serviceFacade.rdas$, this.intercomsStepState$]).pipe(
      map(([tariff, intercoms, intercomsStepState]: SoftwareIntercomWizardTariffStepData) => {
        const intercomsComplete: boolean = !intercomsStepState.complete;
        const intercomsLoaded: boolean = intercoms?.length !== undefined;
        const tariffExist: boolean = !!tariff;

        return {
          complete: tariffExist,
          initialized: intercomsLoaded,
          disabled: intercomsComplete
        };
      })
    );

  camerasStepState$: Observable<GateWizardUpdateStep> =
    combineLatest([this.serviceFacade.cameras$, this.intercomsStepState$]).pipe(
      map(([cameras, intercomsStepState]: GateWizardCameraStepData) => {
        const camerasExists: boolean = cameras?.length > 0;
        const camerasLoaded: boolean = cameras?.length !== undefined;
        const previousStepComplete: boolean = intercomsStepState.complete;

        return {
          complete: camerasExists,
          initialized: camerasLoaded,
          disabled: !previousStepComplete
        };
      })
    );

  checklistStepState$: Observable<GateWizardUpdateStep> =
    combineLatest([this.addressStepState$, this.intercomTypeStepState$, this.intercomsStepState$, this.camerasStepState$]).pipe(
      map(([addressStepState, intercomTypeStepState, intercomsStepState, camerasStepState]: GateWizardChecklistStepData) => {
        const addressComplete: boolean = addressStepState.complete;
        const intercomTypeComplete: boolean = intercomTypeStepState.complete;
        const intercomComplete: boolean = intercomsStepState.complete;
        const camerasComplete: boolean = camerasStepState.complete;

        return {
          disabled: !(addressComplete && intercomTypeComplete && intercomComplete && camerasComplete)
        };
      })
    );

  initialWizardStep$: Observable<GateWizardPopupStep> =
    this.popupStore.steps$.pipe(
      map((steps: GateWizardPopupStepper[]) => {
        if (steps.reduce((prev, cur) => prev && cur.initialized, true)) {
          return steps.findIndex((step: GateWizardPopupStepper) => !step.complete && !step.hidden && !step.disabled);
        }
        return null;
      })
    );

  avaliableIntercoms$: Observable<RdaResponse[]> =
    combineLatest([this.serviceFacade.rdas$, this.gatesPageFacade.entrancesIntercoms$]).pipe(
      map(([intercoms, entrancesIntercoms]: [RdaResponse[], RdaResponse[]]) =>
        entrancesIntercoms?.filter(entranceIntercom => intercoms?.findIndex(intercom => intercom.id === entranceIntercom.id) === -1)
      )
    );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<any, any>,
    private stateService: GateWizardPopupService,
    private serviceFacade: ServiceFacade,
    private popupStore: GateWizardPopupStore,
    private gatesPageFacade: GatesPageFacade
  ) { }
}
