import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PbxOnRdaResponse } from '@app/shared/entities/rd/pbx-on-rda/models';
import { Pure } from '@app/shared/helpers';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper/dialog-wrapper-data.model';

@Component({
  selector: 'app-pbx-on-rda-info-content',
  templateUrl: './pbx-on-rda-info-content.component.html',
  styleUrls: ['./pbx-on-rda-info-content.component.scss']
})
export class PbxOnRdaInfoContentComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<PbxOnRdaResponse, null>) { }

  @Pure
  joinRdas(connectedRdas: string[]) {
    if (!connectedRdas?.length) {
      return '';
    }

    return connectedRdas.join(', ');
  }
}
