<div class="checklist-block" *ngIf="selectedCheckpoint <= 6 && lastCheckpointStep < 8; else readyBlock">
  <h3>
    {{ 'gates.page.popup.wizard.content.checklist.title' | translate }}
  </h3>

  <div class="check-block"
    [ngClass]="{
      'check-block-selected': selectedCheckpoint === 1,
      'check-block-success': selectedCheckpoint > 1
    }"
  >
    <div class="check-number-block">
      <p class="check-number" *ngIf="selectedCheckpoint <= 1; else completeStep">1</p>

      <ng-template #completeStep>
        <mat-icon>done</mat-icon>
      </ng-template>
    </div>

    <div class="check-body">
      <h4>
        {{ 'gates.page.popup.wizard.content.checklist.header.step.1' | translate }}
        <strong>{{ 'enum.state.offline' | translate }}</strong>
      </h4>

      <ng-container *ngIf="selectedCheckpoint === 1">
        <p *ngFor="let intercom of intercoms"
          class="check-body-status"
          [class.check-body-status--online]="intercom.active"
          [class.check-body-status--offline]="!intercom.active"
        >
          {{ 'gates.page.popup.wizard.content.checklist.field.adapter' | translate }}
          {{ intercom.uid }}: {{ intercom.active ? ('enum.state.online' | translate) : ('enum.state.offline' | translate) }}
        </p>

        <div class="action-block">
          <button
            mat-raised-button
            color="primary-white"
            (click)="onChangeStep(checklistSteps.INTERCOMS)"
          >
            {{ 'gates.page.popup.wizard.content.checklist.button.edit' | translate }}
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="onNextStep()"
          >
            {{ 'gates.page.popup.wizard.content.checklist.button.done' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="check-block"
    [ngClass]="{
      'check-block-selected': selectedCheckpoint === 2,
      'check-block-success': selectedCheckpoint > 2
    }"
  >
    <div class="check-number-block">
      <p class="check-number" *ngIf="selectedCheckpoint <= 2; else completeStep">2</p>

      <ng-template #completeStep>
        <mat-icon>done</mat-icon>
      </ng-template>
    </div>

    <div class="check-body">
      <h4>
        {{ 'gates.page.popup.wizard.content.checklist.header.step.2' | translate }}
      </h4>

      <ng-container *ngIf="selectedCheckpoint === 2">
        <p>
          {{ 'gates.page.popup.wizard.content.checklist.message.step.2' | translate }}
        </p>

        <div class="action-block">
          <button
            mat-raised-button
            color="primary-white"
            (click)="onChangeStep(checklistSteps.TEST_ABONENT)"
          >
            {{ 'gates.page.popup.wizard.content.checklist.button.save' | translate }}
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="onNextStep()"
          >
            {{ 'gates.page.popup.wizard.content.checklist.button.done' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="check-block"
    [ngClass]="{
      'check-block-selected': selectedCheckpoint === 3,
      'check-block-success': selectedCheckpoint > 3
    }"
  >
    <div class="check-number-block">
      <p class="check-number" *ngIf="selectedCheckpoint <= 3; else completeStep">3</p>

      <ng-template #completeStep>
        <mat-icon>done</mat-icon>
      </ng-template>
    </div>

    <div class="check-body">
      <h4>
        {{ 'gates.page.popup.wizard.content.checklist.header.step.3' | translate }}
      </h4>

      <ng-container *ngIf="selectedCheckpoint === 3">
        <p>
          {{ 'gates.page.popup.wizard.content.checklist.message.step.3' | translate }}
        </p>

        <div class="action-block">
          <button
            mat-raised-button
            color="primary-white"
            (click)="onChangeStep(checklistSteps.PHYSICAL_TUBE)"
          >
            {{ 'gates.page.popup.wizard.content.checklist.button.save' | translate }}
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="onNextStep()"
          >
            {{ 'gates.page.popup.wizard.content.checklist.button.done' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="check-block"
    [ngClass]="{
      'check-block-selected': selectedCheckpoint === 4,
      'check-block-success': selectedCheckpoint > 4
    }"
  >
    <div class="check-number-block">
      <p class="check-number" *ngIf="selectedCheckpoint <= 4; else completeStep">4</p>

      <ng-template #completeStep>
        <mat-icon>done</mat-icon>
      </ng-template>
    </div>

    <div class="check-body">
      <h4>
        {{ 'gates.page.popup.wizard.content.checklist.header.step.4' | translate }}
      </h4>

      <ng-container *ngIf="selectedCheckpoint === 4">
        <div class="action-block">
          <button
            mat-raised-button
            color="primary"
            (click)="onNextStep()"
          >
            {{ 'gates.page.popup.wizard.content.checklist.button.done' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="check-block"
    [ngClass]="{
      'check-block-selected': selectedCheckpoint === 5,
      'check-block-success': selectedCheckpoint > 5
    }"
  >
    <div class="check-number-block">
      <p class="check-number" *ngIf="selectedCheckpoint <= 5; else completeStep">5</p>

      <ng-template #completeStep>
        <mat-icon>done</mat-icon>
      </ng-template>
    </div>

    <div class="check-body">
      <h4>{{ 'gates.page.popup.wizard.content.checklist.header.step.5' | translate }}</h4>

      <ng-container *ngIf="selectedCheckpoint === 5">
        <p>
          {{ 'gates.page.popup.wizard.content.checklist.message.step.5' | translate }}
        </p>

        <div class="action-block">
          <button
            mat-raised-button
            color="primary"
            (click)="onNextStep()"
          >
            {{ 'gates.page.popup.wizard.content.checklist.button.done' | translate }}
          </button>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="check-block"
    [ngClass]="{
      'check-block-selected': selectedCheckpoint === 6 && lastCheckpointStep < 8,
      'check-block-success': lastCheckpointStep > 7
    }"
  >
    <div class="check-number-block">
      <p class="check-number" *ngIf="lastCheckpointStep < 8; else completeStep">6</p>

      <ng-template #completeStep>
        <mat-icon>done</mat-icon>
      </ng-template>
    </div>

    <div class="check-body">
      <h4>
        {{ 'gates.page.popup.wizard.content.checklist.header.step.6' | translate }}
      </h4>

      <ng-container *ngIf="selectedCheckpoint === 6 && lastCheckpointStep < 8">
        <p>
          {{ 'gates.page.popup.wizard.content.checklist.message.step.6.text' | translate }}
        </p>
        <div class="check-body-subitems">

          <div class="check-body-subitem">
            <div class="check-body-subitem-body">
              <div class="check-body-subitem-number" [class.check-body-subitem-number-success]="lastCheckpointStep > 1">
                <p *ngIf="lastCheckpointStep <= 1; else completeStep">a</p>

                <ng-template #completeStep>
                  <mat-icon>done</mat-icon>
                </ng-template>
              </div>

              <div class="check-body-subitem-actions">
                <p>
                  {{ 'gates.page.popup.wizard.content.checklist.message.step.6.a' | translate }}
                </p>

                <button
                  *ngIf="lastCheckpointStep === 1"
                  mat-raised-button
                  color="primary"
                  (click)="onNextLastCheckpointStep()"
                >
                  {{ 'gates.page.popup.wizard.content.checklist.button.done' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="check-body-subitem">
            <div class="check-body-subitem-body">
              <div
                class="check-body-subitem-number"
                [class.check-body-subitem-number-success]="lastCheckpointStep > 2"
              >
                <p *ngIf="lastCheckpointStep <= 2; else completeStep">b</p>

                <ng-template #completeStep>
                  <mat-icon>done</mat-icon>
                </ng-template>
              </div>

              <div class="check-body-subitem-actions">
                <p>
                  {{ 'gates.page.popup.wizard.content.checklist.message.step.6.b' | translate }}
                </p>
                <button
                  *ngIf="lastCheckpointStep === 2"
                  mat-raised-button
                  color="primary"
                  (click)="onNextLastCheckpointStep()"
                >
                  {{ 'gates.page.popup.wizard.content.checklist.button.done' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="check-body-subitem">
            <div class="check-body-subitem-body">
              <div
                class="check-body-subitem-number"
                [class.check-body-subitem-number-success]="lastCheckpointStep > 3"
              >
                <p *ngIf="lastCheckpointStep <= 3; else completeStep">c</p>

                <ng-template #completeStep>
                  <mat-icon>done</mat-icon>
                </ng-template>
              </div>

              <div class="check-body-subitem-actions">
                <p>
                  {{ 'gates.page.popup.wizard.content.checklist.message.step.6.c' | translate }}
                </p>
                <button
                  *ngIf="lastCheckpointStep === 3"
                  mat-raised-button
                  color="primary"
                  (click)="onNextLastCheckpointStep()"
                >
                  {{ 'gates.page.popup.wizard.content.checklist.button.done' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="check-body-subitem">
            <div class="check-body-subitem-body">
              <div
                class="check-body-subitem-number"
                [class.check-body-subitem-number-success]="lastCheckpointStep > 4"
              >
                <p *ngIf="lastCheckpointStep <= 4; else completeStep">d</p>

                <ng-template #completeStep>
                  <mat-icon>done</mat-icon>
                </ng-template>
              </div>

              <div class="check-body-subitem-actions">
                <p>
                  {{ 'gates.page.popup.wizard.content.checklist.message.step.6.d.text' | translate }}
                </p>

                <ul>
                  <li>
                    {{ 'gates.page.popup.wizard.content.checklist.message.step.6.d.1' | translate }}
                  </li>
                  <li>
                    {{ 'gates.page.popup.wizard.content.checklist.message.step.6.d.2' | translate }}
                  </li>
                  <li>
                    {{ 'gates.page.popup.wizard.content.checklist.message.step.6.d.3' | translate }}
                  </li>
                </ul>

                <button
                  *ngIf="lastCheckpointStep === 4"
                  mat-raised-button
                  color="primary"
                  (click)="onNextLastCheckpointStep()"
                >
                  {{ 'gates.page.popup.wizard.content.checklist.button.done' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="check-body-subitem">
            <div class="check-body-subitem-body">
              <div
                class="check-body-subitem-number"
                [class.check-body-subitem-number-success]="lastCheckpointStep > 5"
              >
                <p *ngIf="lastCheckpointStep <= 5; else completeStep">e</p>

                <ng-template #completeStep>
                  <mat-icon>done</mat-icon>
                </ng-template>
              </div>

              <div class="check-body-subitem-actions">
                <p>
                  {{ 'gates.page.popup.wizard.content.checklist.message.step.6.e' | translate }}
                </p>
                <button
                  *ngIf="lastCheckpointStep === 5"
                  mat-raised-button
                  color="primary"
                  (click)="onNextLastCheckpointStep()"
                >
                  {{ 'gates.page.popup.wizard.content.checklist.button.done' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="check-body-subitem">
            <div class="check-body-subitem-body">
              <div
                class="check-body-subitem-number"
                [class.check-body-subitem-number-success]="lastCheckpointStep > 6"
              >
                <p *ngIf="lastCheckpointStep <= 6; else completeStep">f</p>

                <ng-template #completeStep>
                  <mat-icon>done</mat-icon>
                </ng-template>
              </div>

              <div class="check-body-subitem-actions">
                <p>
                  {{ 'gates.page.popup.wizard.content.checklist.message.step.6.f' | translate }}
                </p>
                <button
                  *ngIf="lastCheckpointStep === 6"
                  mat-raised-button
                  color="primary"
                  (click)="onNextLastCheckpointStep()"
                >
                  {{ 'gates.page.popup.wizard.content.checklist.button.done' | translate }}
                </button>
              </div>
            </div>
          </div>

          <div class="check-body-subitem">
            <div class="check-body-subitem-body">
              <div
                class="check-body-subitem-number"
                [class.check-body-subitem-number-success]="lastCheckpointStep > 7"
              >
                <p *ngIf="lastCheckpointStep <= 7; else completeStep">g</p>

                <ng-template #completeStep>
                  <mat-icon>done</mat-icon>
                </ng-template>
              </div>

              <div class="check-body-subitem-actions">

                <p>
                  {{ 'gates.page.popup.wizard.content.checklist.message.step.6.g' | translate }}
                </p>
                <button
                  *ngIf="lastCheckpointStep === 7"
                  mat-raised-button
                  color="primary"
                  (click)="onNextLastCheckpointStep()"
                >
                  {{ 'gates.page.popup.wizard.content.checklist.button.done' | translate }}
                </button>
              </div>
            </div>
          </div>

        </div>
      </ng-container>
    </div>
  </div>
</div>
<ng-template #readyBlock>
  <div class="checklist-complete">
    <app-info-block>
      <ng-container info-block-text>
        {{ 'gates.page.popup.wizard.content.checklist.message.success' | translate }}
      </ng-container>
    </app-info-block>

    <div class="action-block">
      <button
        mat-raised-button
        mat-dialog-close
        color="primary"
        class="mat-button-lg"
      >
        {{ 'gates.page.popup.wizard.content.checklist.button.close' | translate }}
      </button>
    </div>
  </div>
</ng-template>
