import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-change-virtual-state-content',
  templateUrl: './change-virtual-state-content.component.html',
  styleUrls: ['./change-virtual-state-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangeVirtualStateContentComponent {
  @Input() loading: boolean;
  @Output() changeState: EventEmitter<void> = new EventEmitter();

  onSubmit() {
    this.changeState.emit();
  }
}
