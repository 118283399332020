<div class="flat-cards-table">
  <ng-container *ngFor="let rangeInfo of entranceFlats.flatRangesInfo">
    <ng-container *ngIf="rangeInfo.totalFlatsCount">
      <h5 class="address-title address-title--mobile">
        {{ address | address }}
      </h5>
      <div class="flat-cards-table__range-keeper">
        <mat-checkbox
          class="flat-cards-table__checkbox"
          [value]="
            isRangeSelected(
              getFlatRangeArray(rangeInfo.range[0], rangeInfo.range[1])
            )
          "
          [checked]="
            isRangeSelected(
              getFlatRangeArray(rangeInfo.range[0], rangeInfo.range[1])
            )
          "
          (change)="
            onRangeSelect(
              $event,
              getFlatRangeArray(rangeInfo.range[0], rangeInfo.range[1])
            )
          "></mat-checkbox>

        <h4 class="flat-cards-table__range-title">
          {{ rangeInfo.range[0] }} - {{ rangeInfo.range[1] }} {{ 'shared.flat.cards.table.flat_short' | translate }}
        </h4>

        <h5 class="flat-cards-table address-title">
          {{ address | address }}
        </h5>
        <span class="flat-cards-table__tag" [class.hide]="!isSelected">
          {{ 'shared.flat.cards.table.selected_flats' | translate: { selected: selectedFlats | flatsCount } }}
        </span>
        <button
          class="flat-cards-table__button"
          (click)="onToggleHideContainer(i)">
          <mat-icon
            svgIcon="chevron-down"
            class="chevron-down"
            [class.closed]="hiddenFlatsTables.includes(i)"></mat-icon>
        </button>
      </div>

      <div
        class="flat-cards-table-items"
        *ngIf="!hiddenFlatsTables.includes(i)">
        <ng-container
          *ngFor="
            let flatNumber of getFlatRangeArray(
              rangeInfo.range[0],
              rangeInfo.range[1]
            )
          ">
          <ng-container
            *ngIf="
              entranceFlats.flats[flatNumber] &&
              hitInFlatRanges(flatNumber, rangeInfo.range)
            "
            [ngTemplateOutlet]="flatCard"
            [ngTemplateOutletContext]="{
              flat: entranceFlats.flats[flatNumber] || {
                flatNumber: flatNumber
              },
              flatNumber: flatNumber,
              numberFieldWidth: numberFieldWidth
            }"></ng-container>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="entranceFlats.virtualFlatsCount">
    <h5 class="address-title address-title--mobile">
      {{ address | address }}
    </h5>
    <div
      class="flat-cards-table__range-keeper flat-cards-table__range-keeper_range-out">
      <mat-checkbox
        class="flat-cards-table__checkbox"
        [value]="isRangeOutFlatsSelected"
        (change)="onRangeOutFlatsSelect($event)"></mat-checkbox>

      <h4 class="flat-cards-table__range-title">
        {{ 'shared.flat.cards.table.out_range' | translate }}
      </h4>
      <h5 class="flat-cards-table address-title">
        {{ address | address }}
      </h5>
      <span class="flat-cards-table__tag" [class.hide]="!isSelected">
        {{ 'shared.flat.cards.table.selected_flats' | translate: { selected: selectedFlats | flatsCount } }}
      </span>
      <button
        class="flat-cards-table__button"
        (click)="onHideOutOfRangeFlats()">
        <mat-icon
          svgIcon="chevron-down"
          class="chevron-down"
          [ngClass]="hideOutOfRangeFlats && 'closed'"></mat-icon>
      </button>
    </div>

    <div
      class="flat-cards-table-items"
      *ngIf="!hideOutOfRangeFlats">
      <ng-container
        *ngFor="let virtualFlatEntry of entranceFlats.virtualFlats | keyvalue">
        <ng-container
          [ngTemplateOutlet]="flatCard"
          [ngTemplateOutletContext]="{
            flat: virtualFlatEntry.value,
            flatNumber: virtualFlatEntry.value.flatNumber,
            numberFieldWidth: numberFieldWidth
          }"></ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template
  #flatCard
  let-flat="flat"
  let-flatNumber="flatNumber"
  let-numberFieldWidth="numberFieldWidth">
  <app-flat-card
    [numberWidth]="numberFieldWidth"
    [serviceType]="serviceType"
    [checked]="!!selectedFlats[flat.flatNumber]">
    <ng-container flat-number>
      <mat-checkbox
        class="flat-cards-table__checkbox"
        [value]="!!selectedFlats[flat.flatNumber]"
        [checked]="!!selectedFlats[flat.flatNumber]"
        (change)="onSelectFlat(flat)"></mat-checkbox>
      {{ flat?.flatNumber ? flat?.flatNumber : flatNumber }}
    </ng-container>

    <app-flat-phone
      flat-phone
      [flat]="flat"
      [address]="address"
      [expandAdditionalPhones]="canExpandAdditionalPhone(flat)"
      (addPhone)="onAddPhone($event.address, $event.flat)"></app-flat-phone>
    <ng-container flat-services>
      <ng-container
        [ngTemplateOutlet]="servicesTemplate"
        [ngTemplateOutletContext]="{
          flat: flat,
          address: address,
          account: flat?.account,
          hasHardwareIntercom: checkIfHasHardwareIntercom(
            entranceFlats.flats[flatNumber]
          )
        }"></ng-container>
    </ng-container>

    <app-flat-status
      flat-status
      [flat]="flat"
      [address]="address"
      [mdWUpBreakpoint]="mdWUpBreakpoint$ | async"></app-flat-status>
  </app-flat-card>
</ng-template>
