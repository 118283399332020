import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { RdaSelectSearchModule } from '@app/shared/components/rda/rda-search-select';
import { IpIntercomGenerateUrlsContainerModule } from '@app/shared/components/keys/ip-intercom-generate-urls-container';
import { BottomSheetWrapperModule } from '@app/shared/templates';
import { IntercomKeysEditContainerComponent } from './intercom-keys-edit-container.component';
import { IntercomKeysUrlsGeneratorBottomSheetComponent } from './intercom-keys-urls-generator-bottom-sheet';
import {
    IpSputnikGenerateUrlsContainerModule
} from '@app/shared/components/keys/ip-sputnik-generate-urls-container';
import {NgxTranslateModule} from '@app/translate/translate.module';
import { SelectSearchModule } from '@app/shared/containers';

@NgModule({
    imports: [
        SelectSearchModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        BottomSheetWrapperModule,
        IpIntercomGenerateUrlsContainerModule,
        RdaSelectSearchModule,
        IpSputnikGenerateUrlsContainerModule,
        NgxTranslateModule
    ],
    declarations: [
        IntercomKeysEditContainerComponent,
        IntercomKeysUrlsGeneratorBottomSheetComponent
    ],
    exports: [IntercomKeysEditContainerComponent]
})
export class IntercomKeysEditContainerModule { }
