import { Address } from '@app/shared/models';
import { AddressFormatter } from '@app/shared/services';

export class ServiceEntrancesFormatter {
    static formatEntrances(
      addresses: Address[],
      addressFormatter: AddressFormatter
    ): Address[] {
        if (!addresses?.length) {
            return [];
        }

        return addresses
            .sort((firstAddress, secondAddress) => {
                const firstAddressString = addressFormatter?.formatAddress(firstAddress);
                const secondAddressString = addressFormatter?.formatAddress(secondAddress);
                return firstAddressString?.localeCompare(secondAddressString);
            })
            .slice();
    }
}
