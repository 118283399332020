<ng-container *ngIf="(serviceId$ | async) !== null; else emptyPage">
  <app-service-navbar
    [pageMode]="pageMode$ | async"
    (pageModeChange)="onChangePageMode($event.pageMode)"
  >
</app-service-navbar>

  <ng-container [ngSwitch]="pageMode$ | async">

    <ng-container *ngSwitchCase="pageMode.ABONENTS">
      <app-gate-flats
        [showPrefix]="true"
        [serviceId]="serviceId$ | async"
        [companyId]="(company$ | async)?.id"
        [serviceType]="serviceType"
        [addresses]="entrances$ | async"
        [loading]="abonentsLoading$ | async"
        [flatsFilters]="flatsFilters$ | async"
        [tariff]="serviceTariff$ | async"
        [dependantServices]="dependantServices$ | async"
        [flats]="flats$ | async"
        [enabled]="(entrances$ | async)?.length > 0"
        (manageClose)="onManageClose()"
      ></app-gate-flats>
    </ng-container>

    <ng-container *ngSwitchCase="pageMode.SETTINGS">
      <app-service-rda-warning
        [rdas]="rdas$ | async"
        [componentName]="serviceType"
        [serviceName]="(serviceCustomName$ | async) ?? (serviceName$ | async)"
      ></app-service-rda-warning>

      <app-service-cameras-warning
        [cameras]="cameras$ | async"
      ></app-service-cameras-warning>

      <app-service-config
        [company]="company$ | async"
        [serviceType]="serviceType"
        [rdas]="rdas$ | async"
        [supportHidden]="!(rdas$ | async)?.length || !haveUserSupportAccess"
        [emergencyHidden]="!(rdas$ | async)?.length"
        [customName]="serviceCustomName$ | async"
        [name]="serviceName$ | async"
        (openWizard)="onOpenWizard()"
      ></app-service-config>

      <app-service-entrances
        [showPrefix]="true"
        [entrances]="entrances$ | async"
        [serviceType]="serviceType"
        [displayedColumns]="['address', 'prefix', 'range', 'additionalRanges', 'actions']"
      ></app-service-entrances>

      <app-service-intercoms
        [intercoms]="rdas$ | async"
        [serviceType]="serviceType"
        [addDisabled]="(mode$ | async) === gateMode.INDICES || (mode$ | async) === gateMode.REUSE"
        [translationsDisabled]="true"
        [entrances]="(entrances$ | async)"
        [componentsVersions]="componentsVersions$ | async"
      ></app-service-intercoms>

      <app-service-cameras
        [cameras]="cameras$ | async"
        [serviceType]="serviceType"
        [rdas]="rdas$ | async"
        [addresses]="entrances$ | async"
        [disabled]="!(entrances$ | async)?.length"
      ></app-service-cameras>

      <app-service-intercom-keys
        [rdas]="(rdas$ | async)"
        [keys]="(keys$ | async)"
        [locations]="keysLocations$ | async"
        [serviceType]="serviceType"
      ></app-service-intercom-keys>
    </ng-container>

    <ng-container *ngSwitchCase="pageMode.DELEGATION">
      <app-delegation></app-delegation>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <app-service-activity
        [serviceType]="serviceType"
        [logs]="logs$ | async"
        [logsLoading]="logsLoading$ | async"
        [blocksCount]="blocksCount$ | async"
        [sources]="sources$ | async"
        [timeRange]="timeRange$ | async"
        [extendedMode]="extendedMode$ | async"
        [componentType]="logsComponentType$ | async"
        [totalLogsCount]="totalLogsCount$ | async"
        [currentLogsCount]="currentLogsCount$ | async"
        [selectedLogsSource]="selectedLogsSource$ | async"
        [activeHistoryCameras]="activeHistoryCameras$ | async"
        [activeHistoryIntercoms]="activeHistoryIntercoms$ | async"
      ></app-service-activity>
    </ng-container>

  </ng-container>
</ng-container>

<ng-template #emptyPage>
  <div class="component__empty-data">
    <app-info-block>
      <ng-container info-block-text>
        {{ 'services.gates.page.message.no_data' | translate }}
      </ng-container>
    </app-info-block>
  </div>
</ng-template>
