import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';

import { BreadcrumbService } from '@app/core/services';
import { UrlService } from '@app/shared/services';
import {TranslateService} from '@ngx-translate/core';
import {isEmpty} from 'lodash';
import {SnapshotDataParentRoute} from '@app/shared/models';

export namespace TitleBarComponentN {
  export interface TitleBarAction {
    label: string;
    icon: string;
    disabled: boolean;
    action: (...args) => void;
  }

  export interface TitleBarActionsService {
    actions: TitleBarAction[];
  }
}

@Component({
  selector: 'app-title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TitleBarComponent implements OnInit, OnDestroy {
  constructor(
    private breadcrumbService: BreadcrumbService,
    private changeDetectorRef: ChangeDetectorRef,
    private urlService: UrlService,
    private translate: TranslateService
  ) { }

  get link(): string {
    return this.urlService.links[this.titleHelpButton];
  }
  @Input() public additionalActions: TitleBarComponentN.TitleBarAction[];
  @Input() public snapshotDataParentRoute: SnapshotDataParentRoute;
  public title: string;
  public titleHelpButton: string;
  private breadcrumbServiceSub$: Subscription;

  protected readonly isEmpty = isEmpty;

  ngOnInit() {
    this.breadcrumbServiceSub$ = this.breadcrumbService.title$
      .subscribe((title: string) => {
        const key = 'core.route.' + title;
        const translated = this.translate.instant(key);
        if (this.urlService.links[title]) {
          this.titleHelpButton = title;
        } else {
          this.titleHelpButton = null;
        }
        this.title = translated === key ? title : translated;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.breadcrumbServiceSub$?.unsubscribe();
  }
}
