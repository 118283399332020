import { Injectable } from '@angular/core';
import { ServiceCameraUpdateRequest, ServiceCreateRequest } from '@app/shared/entities/rd';
import { State } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SoftwareIntercomsPageModel } from '../models';
import { ConnectSoftwareIntercomPanelToCamera, CreateSoftwareIntercom, DeleteSoftwareIntercom, DisconnectSoftwareIntercomPanelFromCamera, GetSoftwareIntercomsPage } from './software-intercoms-page.actions';
import { softwareIntercomsPageSelectors } from './software-intercoms-page.selectors';
import { PageData } from '@app/shared/models';

@Injectable()
export class SoftwareIntercomsPageFacade {
    softwareIntercoms$: Observable<SoftwareIntercomsPageModel[]> = this.store.select(softwareIntercomsPageSelectors.selectSoftwareIntercoms);
    pageData$: Observable<PageData> = this.store.select(softwareIntercomsPageSelectors.selectPageData);
    loading$: Observable<boolean> = this.store.select(softwareIntercomsPageSelectors.selectLoading);

    constructor(private store: Store<State>) { }

    getPage(page: number = 0, size: number = 10, name?: string) {
        this.store.dispatch(new GetSoftwareIntercomsPage(page, size, name));
    }

    create(request: ServiceCreateRequest, options: { filterValue: string, pageIndex: number }) {
        this.store.dispatch(new CreateSoftwareIntercom(request, options));
    }

    delete(serviceId: number, options: { pageIndex: number, pageSize: number, filterValue: string }) {
        this.store.dispatch(new DeleteSoftwareIntercom(serviceId, options));
    }

    connectIntercomPanelToCamera(cameraId: number, request: ServiceCameraUpdateRequest) {
        this.store.dispatch(new ConnectSoftwareIntercomPanelToCamera(cameraId, request));
    }

    disconnectIntercomPanelFromCamera(cameraId: number) {
        this.store.dispatch(new DisconnectSoftwareIntercomPanelFromCamera(cameraId));
    }
}
