<div class="steps-block">
  <ng-container *ngFor="let step of steps; let idx = index">
    <div
      class="step-block"
      *ngIf="!step.hidden"
      (click)="onSelectStep(idx)"
      [ngClass]="{
        'step-block-selected': idx === stepIdx,
        'step-block-disabled': step.disabled
      }"
    >
      <p>{{ step.text | translate }}</p>

      <mat-icon
        *ngIf="step.complete && !step.disabled"
        svgIcon="ok"
        class="icon"
      ></mat-icon>
    </div>
  </ng-container>
</div>
