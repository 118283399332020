import { ReducerName } from '@app/store';
import { AuthModuleRootState, AuthModuleState } from '@app/views/auth/store/auth.state';
import { createFeatureSelector, createSelector } from '@ngrx/store';

const authFeatureSelector =
    createFeatureSelector<AuthModuleRootState, AuthModuleState>(ReducerName.AUTH);

const authState = createSelector(
    authFeatureSelector,
    (state: AuthModuleState) => state.auth
);

const getLoading = createSelector(
    authState,
    state => state.loading
);

const getAuthenticated = createSelector(
    authState,
    state => state.authenticated
);

export const authSelector = {
    getLoading,
    getAuthenticated
};
