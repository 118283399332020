import { RdeaDate } from '@app/shared/entities';
import { ChangePasswordRequest, LicenseeReportRequest } from '@app/shared/entities/rd';
import { Action } from '@ngrx/store';

export enum CoreActionTypes {
    CoreGetActiveSignUpsCount = '[Core] CoreGetActiveSignUpsCount',
    CoreGetActiveSignUpsCountSuccess = '[Core] CoreGetActiveSignUpsCount Success',
    CoreGetActiveSignUpsCountFailure = '[Core] CoreGetActiveSignUpsCount Failure',

    CoreGetUnresolvedIssuesCount = '[Core] CoreGetUnresolvedIssuesCount',
    CoreGetUnresolvedIssuesCountSuccess = '[Core] CoreGetUnresolvedIssuesCount Success',
    CoreGetUnresolvedIssuesCountFailure = '[Core] CoreGetUnresolvedIssuesCount Failure',

    CoreGetCompanyPaymentInfo = '[Core] CoreGetCompanyPaymentInfo',
    CoreGetCompanyPaymentInfoSuccess = '[Core] CoreGetCompanyPaymentInfo Success',
    CoreGetCompanyPaymentInfoFailure = '[Core] CoreGetCompanyPaymentInfo Failure',

    CoreGetLicenseeReport = '[Core] CoreGetLicenseeReport',
    CoreGetLicenseeReportSuccess = '[Core] CoreGetLicenseeReport Success',
    CoreGetLicenseeReportFailure = '[Core]CoreGetLicenseeReport Failure',

    CoreGetUsageStatisticsReport = '[Core] CoreGetUsageStatisticsReport',
    CoreGetUsageStatisticsReportSuccess = '[Core] CoreGetUsageStatisticsReport Success',
    CoreGetUsageStatisticsReportFailure = '[Core]CoreGetUsageStatisticsReport Failure',

    CoreChangePassword = '[Core] CoreChangePassword',
    CoreChangePasswordSuccess = '[Core] CoreChangePassword Success',
    CoreChangePasswordFailure = '[Core]CoreChangePassword Failure',

    CoreClearState = '[Core] CoreClearState',
}

export class CoreGetActiveSignUpsCount implements Action {
    readonly type = CoreActionTypes.CoreGetActiveSignUpsCount;

    constructor() { }
}

export class CoreGetActiveSignUpsCountSuccess implements Action {
    readonly type = CoreActionTypes.CoreGetActiveSignUpsCountSuccess;

    constructor(public response: number) { }
}

export class CoreGetActiveSignUpsCountFailure implements Action {
    readonly type = CoreActionTypes.CoreGetActiveSignUpsCountFailure;

    constructor() { }
}

export class CoreGetUnresolvedIssuesCount implements Action {
    readonly type = CoreActionTypes.CoreGetUnresolvedIssuesCount;

    constructor() { }
}

export class CoreGetUnresolvedIssuesCountSuccess implements Action {
    readonly type = CoreActionTypes.CoreGetUnresolvedIssuesCountSuccess;

    constructor(public response: number) { }
}

export class CoreGetUnresolvedIssuesCountFailure implements Action {
    readonly type = CoreActionTypes.CoreGetUnresolvedIssuesCountFailure;

    constructor() { }
}

export class CoreGetCompanyPaymentInfo implements Action {
    readonly type = CoreActionTypes.CoreGetCompanyPaymentInfo;

    constructor(public date: RdeaDate) { }
}

export class CoreGetCompanyPaymentInfoSuccess implements Action {
    readonly type = CoreActionTypes.CoreGetCompanyPaymentInfoSuccess;

    constructor(public response: number) { }
}

export class CoreGetCompanyPaymentInfoFailure implements Action {
    readonly type = CoreActionTypes.CoreGetCompanyPaymentInfoFailure;

    constructor() { }
}

export class CoreGetLicenseeReport implements Action {
    readonly type = CoreActionTypes.CoreGetLicenseeReport;

    constructor(public request: LicenseeReportRequest) { }
}

export class CoreGetLicenseeReportSuccess implements Action {
    readonly type = CoreActionTypes.CoreGetLicenseeReportSuccess;

    constructor() { }
}

export class CoreGetLicenseeReportFailure implements Action {
    readonly type = CoreActionTypes.CoreGetLicenseeReportFailure;

    constructor() { }
}

export class CoreGetUsageStatisticsReport implements Action {
    readonly type = CoreActionTypes.CoreGetUsageStatisticsReport;

    constructor(public request: LicenseeReportRequest) { }
}

export class CoreGetUsageStatisticsReportSuccess implements Action {
    readonly type = CoreActionTypes.CoreGetUsageStatisticsReportSuccess;

    constructor() { }
}

export class CoreGetUsageStatisticsReportFailure implements Action {
    readonly type = CoreActionTypes.CoreGetUsageStatisticsReportFailure;

    constructor() { }
}

export class CoreChangePassword implements Action {
    readonly type = CoreActionTypes.CoreChangePassword;

    constructor(public request: ChangePasswordRequest) { }
}

export class CoreChangePasswordSuccess implements Action {
    readonly type = CoreActionTypes.CoreChangePasswordSuccess;

    constructor() { }
}

export class CoreChangePasswordFailure implements Action {
    readonly type = CoreActionTypes.CoreChangePasswordFailure;

    constructor() { }
}

export class CoreClearState implements Action {
    readonly type = CoreActionTypes.CoreClearState;

    constructor() { }
}

export type CoreActions =
    | CoreGetActiveSignUpsCount
    | CoreGetActiveSignUpsCountSuccess
    | CoreGetActiveSignUpsCountFailure

    | CoreGetUnresolvedIssuesCount
    | CoreGetUnresolvedIssuesCountSuccess
    | CoreGetUnresolvedIssuesCountFailure

    | CoreGetCompanyPaymentInfo
    | CoreGetCompanyPaymentInfoSuccess
    | CoreGetCompanyPaymentInfoFailure

    | CoreGetLicenseeReport
    | CoreGetLicenseeReportSuccess
    | CoreGetLicenseeReportFailure

    | CoreGetUsageStatisticsReport
    | CoreGetUsageStatisticsReportSuccess
    | CoreGetUsageStatisticsReportFailure

    | CoreChangePassword
    | CoreChangePasswordSuccess
    | CoreChangePasswordFailure

    | CoreClearState;
