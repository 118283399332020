import { ActiveHistoryResponse, Camera, KeysResponse, RdaResponse } from '@app/shared/entities/rd';
import { LogsComponentType, LogsResponse } from '@app/shared/models';
import { ServiceActivitySource } from '@app/views/services/models';
import { Action } from '@ngrx/store';
import { ServicesActionTypes } from './services-action-types.actions';

export class DeleteServiceRdaSource implements Action {
    readonly type = ServicesActionTypes.DeleteServiceRdaSource;

    constructor(public rdaUid: string) { }
}

export class GetServiceRdasSources implements Action {
    readonly type = ServicesActionTypes.GetServiceRdasSources;

    constructor(public rdas: RdaResponse[]) { }
}

export class GetServiceRdasSourcesSuccess implements Action {
    readonly type = ServicesActionTypes.GetServiceRdasSourcesSuccess;

    constructor(public response: ServiceActivitySource[]) { }
}

export class GetServiceRdasSourcesFailure implements Action {
    readonly type = ServicesActionTypes.GetServiceRdasSourcesFailure;

    constructor() { }
}

export class DeleteServiceKeySource implements Action {
    readonly type = ServicesActionTypes.DeleteServiceKeySource;

    constructor(public rdaUid: string) { }
}

export class GetServiceKeysSources implements Action {
    readonly type = ServicesActionTypes.GetServiceKeysSources;

    constructor(public request: { intercomUid: string, key: KeysResponse }[]) { }
}

export class GetServiceKeysSourcesSuccess implements Action {
    readonly type = ServicesActionTypes.GetServiceKeysSourcesSuccess;

    constructor(public response: ServiceActivitySource[]) { }
}

export class GetServiceKeysSourcesFailure implements Action {
    readonly type = ServicesActionTypes.GetServiceKeysSourcesFailure;

    constructor() { }
}

export class DeleteServiceCameraSource implements Action {
    readonly type = ServicesActionTypes.DeleteServiceCameraSource;

    constructor(public cameraId: number) { }
}

export class GetServiceCamerasSources implements Action {
    readonly type = ServicesActionTypes.GetServiceCamerasSources;

    constructor(public cameras: Camera[]) { }
}

export class GetServiceCamerasSourcesSuccess implements Action {
    readonly type = ServicesActionTypes.GetServiceCamerasSourcesSuccess;

    constructor(public response: ServiceActivitySource[]) { }
}

export class GetServiceCamerasSourcesFailure implements Action {
    readonly type = ServicesActionTypes.GetServiceCamerasSourcesFailure;

    constructor() { }
}

export class GetServiceRdaActivityBefore implements Action {
    readonly type = ServicesActionTypes.GetServiceRdaActivityBefore;

    constructor() { }
}

export class GetServiceRdaActivity implements Action {
    readonly type = ServicesActionTypes.GetServiceRdaActivity;

    constructor(
        public rdaId: number,
        public startDate: Date,
        public endDate: Date,
        public stepMilliseconds: number
    ) { }
}

export class GetServiceRdaActivitySuccess implements Action {
    readonly type = ServicesActionTypes.GetServiceRdaActivitySuccess;

    constructor(public rdaId: number, public response: ActiveHistoryResponse[]) { }
}

export class GetServiceRdaActivityFailure implements Action {
    readonly type = ServicesActionTypes.GetServiceRdaActivityFailure;

    constructor(public rdaId: number) { }
}

export class GetServiceCameraActivityBefore implements Action {
    readonly type = ServicesActionTypes.GetServiceCameraActivityBefore;

    constructor() { }
}

export class GetServiceCameraActivity implements Action {
    readonly type = ServicesActionTypes.GetServiceCameraActivity;

    constructor(
        public cameraId: number,
        public startDate: Date,
        public endDate: Date,
        public stepMilliseconds: number
    ) { }
}

export class GetServiceCameraActivitySuccess implements Action {
    readonly type = ServicesActionTypes.GetServiceCameraActivitySuccess;

    constructor(public cameraId: number, public response: ActiveHistoryResponse[]) { }
}

export class GetServiceCameraActivityFailure implements Action {
    readonly type = ServicesActionTypes.GetServiceCameraActivityFailure;

    constructor(public cameraId: number) { }
}

export class GetServiceRdaLogs implements Action {
    readonly type = ServicesActionTypes.GetServiceRdaLogs;

    constructor(
        public rdaUid: string,
        public timeRange: number,
        public limit: number,
        public page: number
    ) { }
}

export class GetServiceRdaLogsSuccess implements Action {
    readonly type = ServicesActionTypes.GetServiceRdaLogsSuccess;

    constructor(
        public response: LogsResponse[],
        public extendedResponse: LogsResponse[],
        public totalCount: number,
        public currentCount: number,
        public page: number
    ) { }
}

export class GetServiceRdaLogsFailure implements Action {
    readonly type = ServicesActionTypes.GetServiceRdaLogsFailure;

    constructor() { }
}

export class GetServiceCameraLogs implements Action {
    readonly type = ServicesActionTypes.GetServiceCameraLogs;

    constructor(
        public rdvaUri: string,
        public cameraId: number
    ) { }
}

export class GetServicePrivateCameraLogs implements Action {
    readonly type = ServicesActionTypes.GetServicePrivateCameraLogs;

    constructor(
        public rdaUid: string,
        public cameraId: number,
        public timeRange: number,
        public limit: number,
        public page: number
    ) { }
}

export class GetServiceCameraLogsSuccess implements Action {
    readonly type = ServicesActionTypes.GetServiceCameraLogsSuccess;

    constructor(
        public response: LogsResponse[],
        public extendedResponse: LogsResponse[],
        public totalCount: number,
        public currentCount: number,
        public page: number
    ) { }
}

export class GetServiceCameraLogsFailure implements Action {
    readonly type = ServicesActionTypes.GetServiceCameraLogsFailure;

    constructor() { }
}

export class SetServiceLogsSource implements Action {
    readonly type = ServicesActionTypes.SetServiceLogsSource;

    constructor(public source: ServiceActivitySource) { }
}

export class SetServiceLogsStream implements Action {
    readonly type = ServicesActionTypes.SetServiceLogsStream;

    constructor(public extendedMode: boolean) { }
}

export class SetServiceLogsTimeRange implements Action {
    readonly type = ServicesActionTypes.SetServiceLogsTimeRange;

    constructor(public timeRange: number) { }
}

export class SetServiceComponentType implements Action {
    readonly type = ServicesActionTypes.SetServiceComponentType;

    constructor(public componentType: LogsComponentType) { }
}

export class SetServiceBlocksCount implements Action {
    readonly type = ServicesActionTypes.SetServiceBlocksCount;

    constructor(public blocksCount: number) { }
}

export class SetServiceLogsPage implements Action {
    readonly type = ServicesActionTypes.SetServiceLogsPage;

    constructor(public page: number) { }
}

export type ServicesLogsActions =
    | DeleteServiceKeySource
    | GetServiceKeysSources
    | GetServiceKeysSourcesSuccess
    | GetServiceKeysSourcesFailure

    | DeleteServiceCameraSource
    | GetServiceCamerasSources
    | GetServiceCamerasSourcesSuccess
    | GetServiceCamerasSourcesFailure

    | DeleteServiceRdaSource
    | GetServiceRdasSources
    | GetServiceRdasSourcesSuccess
    | GetServiceRdasSourcesFailure

    | GetServiceRdaActivityBefore
    | GetServiceRdaActivity
    | GetServiceRdaActivitySuccess
    | GetServiceRdaActivityFailure

    | GetServiceCameraActivityBefore
    | GetServiceCameraActivity
    | GetServiceCameraActivitySuccess
    | GetServiceCameraActivityFailure

    | GetServiceRdaLogs
    | GetServiceRdaLogsSuccess
    | GetServiceRdaLogsFailure

    | GetServiceCameraLogs
    | GetServicePrivateCameraLogs
    | GetServiceCameraLogsSuccess
    | GetServiceCameraLogsFailure

    | SetServiceLogsSource
    | SetServiceLogsStream
    | SetServiceLogsTimeRange
    | SetServiceComponentType
    | SetServiceBlocksCount
    | SetServiceLogsPage;
