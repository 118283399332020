import { TARRIFS_PAGE_LINK } from '@app/shared/helpers';
import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-component-connection-content',
  templateUrl: './confirm-component-connection-content.component.html',
  styleUrls: ['./confirm-component-connection-content.component.scss']
})
export class ConfirmComponentConnectionContentComponent {
  readonly tarrifsPageLink: string = TARRIFS_PAGE_LINK;

  confirmed: boolean;

  @Output() private confirmEmit: EventEmitter<{ confirmed: boolean }> = new EventEmitter();

  onConfirm() {
    this.confirmEmit.emit({ confirmed: this.confirmed });
  }
}
