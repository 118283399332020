import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { AddressInfo, Country } from '@app/shared/models';

@Component({
  selector: 'app-custom-address-form',
  templateUrl: './custom-address-form.component.html',
  styleUrls: ['./custom-address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomAddressFormComponent implements OnInit {
  @Output() addressChanged: EventEmitter<{ value: string, type: string }> = new EventEmitter();
  @Input() private country: Country;

  constructor(
    public controlContainer: ControlContainer
  ) { }

  ngOnInit() {
    this.controlContainer.control.get('country').setValue(this.country);
  }

  onKey(event, type: keyof AddressInfo) {
    this.addressChanged.emit({ value: event.target.value, type });
  }
}
