import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { PreviosQueryType } from '@app/shared/entities';
import { Address } from '@app/shared/models';
import { SignUpsPageMode } from '@app/views/abonents/models';
import { ServiceFlat } from '@app/views/services/components';

@Component({
  selector: 'app-flat-status',
  templateUrl: './flat-status.component.html',
  styleUrls: ['./flat-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlatStatusComponent {
  @Input() flat: ServiceFlat;
  @Input() address: Address;
  @Input() mdWUpBreakpoint: boolean;
  public readonly signUpsPageMode: typeof SignUpsPageMode = SignUpsPageMode;
  public readonly queryType: typeof PreviosQueryType = PreviosQueryType;
}
