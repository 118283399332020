import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserRoleType } from '@app/core/models';


@Component({
    selector: 'app-company-contacts',
    templateUrl: './company-contacts.component.html',
    styleUrls: ['./company-contacts.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyContactsComponent implements OnInit {
    @Output() public toggleEdit: EventEmitter<boolean> = new EventEmitter();
    @Input() mails: string;

    public readonly userRoleType = UserRoleType;

    public mailInputs: string[] = [];

    ngOnInit(): void {

        if (this.mails) {
            this.mailInputs = this.mails.split(',').filter(mail => mail !== 'invoice@rosdomofon.com');
        }
    }

    public onToggleEdit(): void {
        this.toggleEdit.emit(true);
    }
}
