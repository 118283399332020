import { Injectable, OnDestroy } from '@angular/core';
import { DialogWrapperStateHelper } from '@app/shared/ui';
import { Actions, ofType } from '@ngrx/effects';
import { takeUntil } from 'rxjs/operators';
import {
  AddServiceCamera,
  AddServiceCameraFailure,
  AddServiceCameraSuccess,
  ServicesActionTypes,
  UpdateServiceCamera,
  UpdateServiceCameraFailure,
  UpdateServiceCameraSuccess
} from '../../../../store';

@Injectable()
export class ServiceCameraPopupService extends DialogWrapperStateHelper<'loading' | 'close' | 'loaded' | 'error'> implements OnDestroy {
    constructor(
        private actions$: Actions
    ) {
        super();
        this.enableStateListeners();
    }

    ngOnDestroy() {
        this.destroy();
    }

    private enableStateListeners() {
        this.actions$
            .pipe(
                ofType<
                    | AddServiceCamera
                    | UpdateServiceCamera
                >(...[
                    ServicesActionTypes.AddServiceCamera,
                    ServicesActionTypes.UpdateServiceCamera
                ]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loading');

        this.actions$
            .pipe(
                ofType<AddServiceCameraSuccess>(ServicesActionTypes.AddServiceCameraSuccess), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'close');

        this.actions$
          .pipe(
            ofType<AddServiceCameraFailure>(ServicesActionTypes.AddServiceCameraFailure), takeUntil(this.onDestroy$)
          )
          .subscribe(() => this.state = 'error');

        this.actions$
            .pipe(
                ofType<
                    | UpdateServiceCameraSuccess
                    | UpdateServiceCameraFailure
                >(...[
                    ServicesActionTypes.UpdateServiceCameraSuccess,
                    ServicesActionTypes.UpdateServiceCameraFailure
                ]),
                takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loaded');
    }
}
