<div class="table-page-container">
  <mat-card>
    <mat-card-content>
      <div class="input-field">
        <mat-form-field appearance="outline" class="none-padding none-margin">
          <input
            matInput
            placeholder="{{ 'pbx-on-rda.placeholder.search' | translate }}"
            [formControl]="filterInput"
            spellcheck="false"
            autocorrect="off"
            autocomplete="off"
            autocapitalize="off"
          >
          <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>

        <button mat-raised-button color="primary-inverse" (click)="onCreate()">
          <mat-icon>add</mat-icon>
          <ng-container *ngIf="!resolution.isMobile">
            {{ 'pbx-on-rda.button.create' | translate }}
          </ng-container>
        </button>
      </div>

      <hr>

      <mat-table
        [dataSource]="(pbxOnRdaList$ | async)?.content"
        *ngIf="(pbxOnRdaList$ | async)?.totalElements > 0; else emptyData"
      >
        <ng-container matColumnDef="pbxOnRdaUid">
          <mat-header-cell *matHeaderCellDef>
            {{ 'pbx-on-rda.field.uid' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="pbxOnRdaUid"
            label-name="{{ 'pbx-on-rda.placeholder.uid' | translate }}"
            *matCellDef="let element"
          >
            {{ element.pbxOnRdaUid }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="uid">
          <mat-header-cell *matHeaderCellDef>
            {{ 'pbx-on-rda.field.uid_sip' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="uid"
            label-name="{{ 'pbx-on-rda.placeholder.uid_sip' | translate }}"
            *matCellDef="let element"
          >
            {{ element.sipAdapter?.uid }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="ip">
          <mat-header-cell *matHeaderCellDef>
            {{ 'pbx-on-rda.field.ip' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="ip"
            label-name="{{ 'pbx-on-rda.field.ip' | translate }}"
            *matCellDef="let element"
          >
            {{ element.sipAdapter?.ip }}:{{ element.sipAdapter?.port }}
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="dns">
          <mat-header-cell *matHeaderCellDef>
            {{ 'pbx-on-rda.field.dns' | translate }}
          </mat-header-cell>
          <mat-cell
            data-label="dns"
            label-name="{{ 'pbx-on-rda.field.dns' | translate }}"
            *matCellDef="let element"
          >
            {{ element.sipAdapter?.dnsName }}
          </mat-cell>
        </ng-container>


        <ng-container matColumnDef="actions">
          <mat-header-cell data-label="actions" *matHeaderCellDef></mat-header-cell>
          <mat-cell data-label="actions" *matCellDef="let element">
            <button
              mat-icon-button
              color="primary-inverse"
              (click)="onInfo(element)"
            >
              <mat-icon>info</mat-icon>
            </button>

            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <div class="triggered-menu-content">
                <button
                  mat-button
                  color="primary"
                  class="triggered-menu-content__button"
                  (click)="onEdit(element)"
                >
                  {{ 'pbx-on-rda.button.edit' | translate }}
                </button>

                <button
                  mat-button
                  color="error"
                  class="triggered-menu-content__button"
                  (click)="onDelete(element)"
                >
                  {{ 'pbx-on-rda.button.delete' | translate }}
                </button>
              </div>
            </mat-menu>
          </mat-cell>
        </ng-container>

        <mat-header-row [style.display]="resolution.isTablet ? 'none' : 'flex'" *matHeaderRowDef="displayedColumns">
          {{ 'pbx-on-rda.field.actions' | translate }}
        </mat-header-row>

        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
      </mat-table>
      <ng-template #emptyData>
        <app-empty-data></app-empty-data>
      </ng-template>

      <mat-paginator
        [hidePageSize]="true"
        [pageSize]="pageSize"
        [showFirstLastButtons]="true"
        [length]="(pbxOnRdaList$ | async)?.totalElements"
      ></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>
