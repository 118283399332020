<div class="form-container">
  <h3>
    {{ 'intercom.pages.order.page.card.form.scans.title' | translate }}
  </h3>
  <p>
    {{ 'intercom.pages.order.page.card.form.scans.subtitle' | translate }}}}
  </p>

  <input
    type="file"
    placeholder="{{ 'intercom.pages.order.page.card.form.scans.field.number' | translate }}"
    ngFileSelect
    [options]="options"
    (uploadOutput)="onUploadOutput($event)"
    multiple
    required
  />

  <div class="action-block">
    <button
      mat-raised-button
      color="primary"
      (click)="onUpload()"
    >
      {{ 'intercom.pages.order.page.card.form.scans.button.upload' | translate }}
    </button>
  </div>
</div>
