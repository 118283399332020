import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

import {PaymentsWizardService, PaymentsWizardStep, PaymentsWizardStepper} from '../../models';
import {PaymentsWizardStore} from '../../store';
import {Observable} from 'rxjs';
import {LocalStorageHelper, LocalStoragePaymentsKey} from '@app/shared/entities';

@Component({
  selector: 'app-payments-wizard-service',
  templateUrl: './payments-wizard-service.component.html',
  styleUrls: ['./payments-wizard-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentsWizardServiceComponent {
  public readonly serviceType: typeof PaymentsWizardService = PaymentsWizardService;
  public service: PaymentsWizardService;

  constructor(
    private store: PaymentsWizardStore
  ) {
    store.state$.subscribe((state) => {
      this.service = state.service;
    });
  }

  updateService() {
    this.store.updateService(this.service);
    LocalStorageHelper.setItem(LocalStoragePaymentsKey.SERVICE, this.service);
  }
}
