import { ServiceResponse } from '@app/shared/entities/rd';
import { Address, ServicesTypes } from '@app/shared/models';
import { ServiceFlat } from './service-flat-connection.model';

export interface ServiceFlatsAddConnectionData {
    addresses?: Address[];
    flat?: Partial<ServiceFlat>;
    serviceType?: ServicesTypes;
    virtualFlat?: boolean;
    servicesSelectionRequired?: boolean;
    hasPhysicalTubeDefault?: boolean;
    hasPhysicalTubeHidden?: boolean;
    defaultService?: Pick<ServiceResponse, 'id' | 'type'>;
    existingConnectionsIds?: number[];
    title?: string;
    componentName?: string;
    noPhone?: boolean;
    responseMessage?: string;
}
