import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UserRoleType } from '@app/core/models';

import { CompanyDataResponse } from '@app/views/company/store/company-page.state';

@Component({
    selector: 'app-company-settings',
    templateUrl: './company-settings.component.html',
    styleUrls: ['./company-settings.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanySettingsComponent {
    @Input() company: CompanyDataResponse;
    @Output() private toggleEdit: EventEmitter<boolean> = new EventEmitter();

    public readonly userRoleType = UserRoleType;

    public onToggleEdit(): void {
        this.toggleEdit.emit(true);
    }


    public get image(): string {
        return `data:image/png;base64,${this.company.banner}`;
    }

    public get logoBg(): string {
        return `url(data:image/png;base64,${this.company.banner})`;
    }

    public get phone(): string {
        return this.company.supportPhone ? `tel:${this.company.supportPhone}` : '-';
    }


    public get autoSignOffer(): string {
        return this.company.autoSignOffer ? this.company.autoSignOffer : '-';
    }


    public get personalAccountLink(): string {
        return this.company.personalAccountLink ? this.company.personalAccountLink : '-';
    }

}
