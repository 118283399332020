import {Injectable, OnDestroy} from '@angular/core';
import {DialogWrapperStateHelper} from '@app/shared/ui';
import {Actions, ofType} from '@ngrx/effects';
import {takeUntil} from 'rxjs/operators';
import {ServiceFacade, ServicesActionTypes} from '../../../../store';

@Injectable()
export class ServiceManagePopupService extends DialogWrapperStateHelper<'loading' | 'close' | 'loaded'> implements OnDestroy {
  constructor(
    private actions$: Actions,
    private serviceFacade: ServiceFacade
  ) {
    super();
    this.enableStateListeners();
  }

  ngOnDestroy() {
    this.destroy();
  }

  private enableStateListeners() {
    this.actions$
      .pipe(
        ofType(ServicesActionTypes.DeleteServiceFlat), takeUntil(this.onDestroy$),
      )
      .subscribe(() => this.state = 'loading');

    this.actions$
      .pipe(
        ofType(ServicesActionTypes.DeleteServiceFlatSuccess), takeUntil(this.onDestroy$)
      )
      .subscribe(async () => {
        this.state = 'close';
        await this.serviceFacade.getConnectionsInit();
      });

    this.actions$
      .pipe(
        ofType(ServicesActionTypes.DeleteServiceFlatFailure), takeUntil(this.onDestroy$)
      )
      .subscribe(() => this.state = 'loaded');
  }
}
