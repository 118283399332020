import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AbonentsFromEntrancesContentModule } from '@app/shared/components/connections';
import { DialogWrapperModule } from '@app/shared/ui/dialog-wrapper';
import { ServiceAbonentsFromEntrancesPopupComponent } from './service-abonents-from-entrances-popup.component';

@NgModule({
    imports: [
        CommonModule,
        DialogWrapperModule,
        AbonentsFromEntrancesContentModule
    ],
    declarations: [
        ServiceAbonentsFromEntrancesPopupComponent
    ],
    exports: [
        ServiceAbonentsFromEntrancesPopupComponent
    ]
})
export class ServiceAbonentsFromEntrancesPopupModule { }
