import { LoginRequest, LoginResponse } from '@app/shared/entities/rd';
import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  Login = '[Auth] Login',
  LoginSuccess = '[Auth] Login Success',
  LoginZammadInfo = '[Auth] Login Zammad Info',
  LoginFinished = '[Auth] Login Finished',
  LoginAuthenticated = '[Auth] Login Authenticated',
  LoginFailure = '[Auth] Login Failure',
  Logout = '[Auth] Logout'
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;

  constructor(public request: LoginRequest) { }
}

export class LoginZammadInfo implements Action {
  readonly type = AuthActionTypes.LoginZammadInfo;

  constructor(public companyName: string) { }
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;

  constructor(public response: LoginResponse) { }
}

export class LoginFinished implements Action {
  readonly type = AuthActionTypes.LoginFinished;

  constructor() { }
}

export class LoginAuthenticated implements Action {
  readonly type = AuthActionTypes.LoginAuthenticated;

  constructor(public authenticated: boolean) { }
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;

  constructor() { }
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;

  constructor(public redirectURL?: string) { }
}

export type AuthActions =
  | Login
  | LoginZammadInfo
  | LoginSuccess
  | LoginFinished
  | LoginAuthenticated
  | LoginFailure
  | Logout;
