import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserRoleType } from '@app/core/models';
import { PermissionsService } from '@app/security/permissions';
import { IntercomType, IpRdaConnectionTypes, PbxOnRdaResponse, RdaResponse, RdaUpdateRequest } from '@app/shared/entities/rd';
import { Dictionary } from '@app/shared/helpers';
import { Address, LocationResponse, ServicesTypes, TranslationTuningResponse } from '@app/shared/models';
import { DialogWrapperData, NavbarLink } from '@app/shared/ui';
import { ServiceFacade } from '@app/views/services/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ServiceIntercomPanelsMode } from '../../../containers';
import { ServiceIntercomPanelsData, ServiceIntercomPbxOnRdaData, ServiceIntercomPopupUpdateStep, ServiceIntercomTranslationsData } from './models';
import { ServiceIntercomPopupBody } from './service-intercom-popup-body.model';
import { ServiceIntercomPopupMode } from './service-intercom-popup-mode';
import { ServiceIntercomPopupService } from './service-intercom-popup.service';
import { ServiceIntercomPopupStore } from './store/service-intercom-popup.store';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-service-intercom-popup',
  templateUrl: './service-intercom-popup.component.html',
  styleUrls: ['./service-intercom-popup.component.scss'],
  providers: [ServiceIntercomPopupService, ServiceIntercomPopupStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceIntercomPopupComponent implements OnInit {
  readonly pageMode: typeof ServiceIntercomPopupMode = ServiceIntercomPopupMode;

  pageState = ServiceIntercomPopupMode.INTERCOM;
  steps$: Observable<NavbarLink[]> = this.popupStore.steps$;
  mode$: Observable<ServiceIntercomPopupMode> = this.popupStore.mode$;
  intercomPanelsMode$: Observable<ServiceIntercomPanelsMode> = this.popupStore.intercomPanelsMode$;
  showMenu$: Observable<boolean> = this.popupStore.showMenu$;

  translationsStepState$: Observable<ServiceIntercomPopupUpdateStep> =
    combineLatest([this.serviceFacade.serviceType$, this.serviceFacade.rdas$, this.popupStore.formChanged$, this.popupStore.selectedIntercomUid$])
      .pipe(
        map(([serviceType, intercoms, formChanged, selectedIntercomUid]: ServiceIntercomTranslationsData) => {
          const noIntercoms: boolean = !intercoms?.length || !selectedIntercomUid;
          const intercomIdx: number = intercoms?.findIndex(rda => rda.uid === selectedIntercomUid);
          const intercom: RdaResponse = !selectedIntercomUid ? null : intercoms?.[intercomIdx] ?? intercoms?.[0] ?? null;
          const ipIntercom: boolean = intercom?.intercomType?.protocol?.ipType;

          let tooltip: string = null;

          if (noIntercoms) {
            tooltip = this.translate.instant('service.intercom.popup.translations.no_intercoms');
          } else if (formChanged) {
            tooltip = this.translate.instant('service.intercom.popup.translations.form_changed');
          } else if (ipIntercom) {
            tooltip = this.translate.instant('service.intercom.popup.translations.ip_intercom');
          }

          return {
            disabled: noIntercoms || ipIntercom || formChanged,
            hidden: serviceType !== ServicesTypes.SOFTWARE_INTERCOM && serviceType !== ServicesTypes.GATE,
            tooltip
          };
        })
      );

  intercomPanelsStepState$: Observable<ServiceIntercomPopupUpdateStep> =
    combineLatest([this.serviceFacade.serviceType$, this.serviceFacade.rdas$, this.popupStore.formChanged$, this.popupStore.selectedIntercomUid$])
      .pipe(
        map(([serviceType, intercoms, formChanged, selectedIntercomUid]: ServiceIntercomPanelsData) => {
          let tooltip: string = null;

          const noIntercoms: boolean = !intercoms?.length || !selectedIntercomUid;
          const intercomIdx: number = intercoms?.findIndex(rda => rda.uid === selectedIntercomUid) ?? 0;
          const intercom: RdaResponse = !selectedIntercomUid ? null : intercoms?.[intercomIdx] ?? intercoms?.[0] ?? null;
          const intercomWithIndicesSupport: boolean = intercom?.intercomType?.protocol?.intercomIndexesRequired;
          const ipIntercom: boolean = intercom?.intercomType?.protocol?.ipType;

          if (noIntercoms) {
            tooltip = this.translate.instant('service.intercom.popup.intercom_panels.no_intercoms');
          } else if (formChanged) {
            tooltip = this.translate.instant('service.intercom.popup.intercom_panels.form_changed');
          } else if (!intercomWithIndicesSupport && !ipIntercom) {
            tooltip = this.translate.instant('service.intercom.popup.intercom_panels.other');
          }

          return {
            disabled: noIntercoms || !intercomWithIndicesSupport && !ipIntercom || formChanged,
            hidden: serviceType !== ServicesTypes.SOFTWARE_INTERCOM && serviceType !== ServicesTypes.GATE,
            tooltip
          };
        })
      );

  rolePermitted$: Observable<boolean> = new Observable(subscriber => {
    subscriber.next(!this.permissions.someRoleContains([UserRoleType.ROLE_DEMO]));
  });

  pbxOnRdaStepState$: Observable<ServiceIntercomPopupUpdateStep> =
    combineLatest([this.rolePermitted$, this.serviceFacade.rdas$, this.popupStore.formChanged$, this.popupStore.selectedIntercomUid$])
      .pipe(
        map(([permitted, intercoms, formChanged, selectedIntercomUid]: ServiceIntercomPbxOnRdaData) => {
          let tooltip: string = null;

          const noIntercoms: boolean = !intercoms?.length || !selectedIntercomUid;
          const intercomIdx: number = intercoms?.findIndex(rda => rda.uid === selectedIntercomUid) ?? 0;
          const intercom: RdaResponse = !selectedIntercomUid ? null : intercoms?.[intercomIdx] ?? intercoms?.[0] ?? null;
          const intercomWithSiptrunkMode: boolean = intercom?.mode === IpRdaConnectionTypes.SIPTRUNK;
          const ipIntercom: boolean = intercom?.intercomType?.protocol?.ipType;

          if (noIntercoms) {
            tooltip = this.translate.instant('service.intercom.popup.pbx_on_rda.no_intercoms');
          } else if (formChanged) {
            tooltip = this.translate.instant('service.intercom.popup.pbx_on_rda.form_changed');
          } else if (!intercomWithSiptrunkMode || !ipIntercom) {
            tooltip = this.translate.instant('service.intercom.popup.pbx_on_rda.other');
          }

          return {
            disabled: noIntercoms || !intercomWithSiptrunkMode || !ipIntercom || formChanged,
            hidden: permitted,
            tooltip
          };
        })
      );

  address$: Observable<Address> = this.serviceFacade.entrances$.pipe(map(addresses => addresses?.[0] ?? null));

  translationTunings$: Observable<TranslationTuningResponse[]> =
    combineLatest([this.serviceFacade.translationTunings$, this.popupStore.selectedIntercomUid$])
      .pipe(
        map(([translationTuningsDict, selectedIntercomUid]: [Dictionary<TranslationTuningResponse[]>, string]) =>
          translationTuningsDict[selectedIntercomUid] ?? null
        )
      );

  intercom$: Observable<RdaResponse> = combineLatest([this.serviceFacade.rdas$, this.popupStore.selectedIntercomUid$])
    .pipe(
      map(([intercoms, selectedIntercomUid]: [RdaResponse[], string]) => {
        if (!selectedIntercomUid) {
          return null;
        }

        const intercomIdx: number = intercoms?.findIndex(rda => rda.uid === selectedIntercomUid);
        return intercoms?.[intercomIdx] ?? intercoms?.[0] ?? null;
      })
    );

  pbxOnRda$: Observable<PbxOnRdaResponse> = combineLatest([this.serviceFacade.pbxOnRda$, this.popupStore.selectedIntercomUid$])
    .pipe(
      map(([pbxOnRdaDict, selectedIntercomUid]: [Dictionary<PbxOnRdaResponse>, string]) => {
        return pbxOnRdaDict[selectedIntercomUid] ?? null;
      })
    );

  intercoms$: Observable<RdaResponse[]> = this.serviceFacade.rdas$;
  intercomTypes$: Observable<IntercomType[]> = this.serviceFacade.intercomTypes$.pipe(
    map(intercomTypes => intercomTypes?.filter(intercomType => this.data.body.onlyIpIntercoms ? intercomType.protocol.ipType : true))
  );
  camerasLocations$: Observable<LocationResponse[]> = this.serviceFacade.camerasLocations$;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<ServiceIntercomPopupBody, { request: RdaUpdateRequest }>,
    public popupService: ServiceIntercomPopupService,
    private permissions: PermissionsService,
    private serviceFacade: ServiceFacade,
    private popupStore: ServiceIntercomPopupStore,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    if (this.data.body.rda) {
      this.popupStore.updateSelectedIntercomUid(this.data.body.rda?.uid);
    }

    this.popupService.addStateChangeListener((state: 'loading' | 'done' | 'loaded' | 'close') => {
      console.log('addStateChangeListener');
      if (state === 'done' || state === 'close') {
        this.popupStore.updateFormChanged(false);
        this.popupStore.updateNewIntercom(false);

        if (state === 'close') {
          this.popupStore.changeIntercomPanelsMode(ServiceIntercomPanelsMode.LIST);
          this.popupStore.changeMenuVisible(true);
        }
      }
    });
  }
}
