<div class="apartment-video-service-settings apartment-video-service-settings__wrapper">
  <p-card class="apartment-video-service-settings__card">
    <div class="about-service__content__wrapper">
      <div class="about-service__header__wrapper">
        {{'apartments_video_surveillance.page.about_service' | translate}}
      </div>
      <div class="about-service__info__wrapper">
        <app-pagination-data-view [config]="ownerDataViewConfig" [data]="ownerDataView"
                                  [loading]="waitOwnerData$"></app-pagination-data-view>
      </div>
      <div class="about-service__footer__wrapper">
        <p-button [label]="'apartments_video_surveillance.page.rename_service' | translate"
                  styleClass="p-button-secondary" (onClick)="renameService()"></p-button>
        <p-button [label]="'apartments_video_surveillance.page.delete_service' | translate"
                  styleClass="p-button-text p-button-danger"
                  (click)="onDeleteItem(ConfirmType.RemovingAVService, serviceInfo?.customName || serviceInfo?.name)"></p-button>
        <p-button [label]="(isServiceBlocked ? 'apartments_video_surveillance.page.unblock_service' : 'apartments_video_surveillance.page.block_service') | translate"
                  styleClass="p-button-text {{isServiceBlocked ? 'p-button-success' : 'p-button-danger'}}"
                  [disabled]="isEmpty(serviceInfo?.accounts)"
                  (click)="onDeleteItem(isServiceBlocked ? ConfirmType.UnblockingAVService : ConfirmType.BlockingAVService, serviceInfo?.customName || serviceInfo?.name)"></p-button>
      </div>
    </div>
  </p-card>

  <p-card class="apartment-video-service-settings__card">
    <div class="cameras-service__content__wrapper">
      <div class="cameras-service__header__wrapper">
        <div class="cameras-service__header--title">
          {{'apartments_video_surveillance.page.cameras' | translate}}
        </div>
        <div class="cameras-service__header__actions__wrapper">
          <p-button icon="pi pi-undo" styleClass="p-button-secondary"
                    [pTooltip]="'apartments_video_surveillance.page.refresh' | translate" tooltipPosition="left"
                    (onClick)="reloadData.emit(true)"></p-button>
          <p-button icon="pi pi-plus" styleClass="p-button-secondary"
                    [pTooltip]="'apartments_video_surveillance.page.add_camera' | translate" tooltipPosition="left"
                    (onClick)="addOrEditCamera()"></p-button>
        </div>
      </div>
      <div class="cameras-service__info__wrapper">
        <app-pagination-data-view [config]="camerasDataViewConfig" [data]="camerasDataView"
                                  [loading]="waitCamerasData$"></app-pagination-data-view>
      </div>
      <div class="cameras-service__footer__wrapper">
      </div>
    </div>
  </p-card>
</div>
