import { HttpErrorResponse } from '@angular/common/http';
import { RdvaPlaylistItem, VideoshotGetRequest } from '@app/shared/entities/integrations';
import { Action } from '@ngrx/store';
import { VideoshotPopupState, VideoshotTimeData } from '../models';

export enum VideoPlayerActionTypes {
    VideoPlayerGetPlaylists = '[VideoPlayer] VideoPlayerGetPlaylists',
    VideoPlayerGetPlaylistsSuccess = '[VideoPlayer] VideoPlayerGetPlaylistsSuccess',
    VideoPlayerGetPlaylistsFailure = '[VideoPlayer] VideoPlayerGetPlaylistsFailure',

    VideoPlayerCreateVideoshotTask = '[VideoPlayer] VideoPlayerCreateVideoshotTask',
    VideoPlayerCreateVideoshotTaskSuccess = '[VideoPlayer] VideoPlayerCreateVideoshotTaskSuccess',
    VideoPlayerCreateVideoshotTaskFailure = '[VideoPlayer] VideoPlayerCreateVideoshotTaskFailure',

    VideoPlayerGetVideoshot = '[VideoPlayer] VideoPlayerGetVideoshot',
    VideoPlayerGetVideoshotSuccess = '[VideoPlayer] VideoPlayerGetVideoshotSuccess',
    VideoPlayerGetVideoshotFailure = '[VideoPlayer] VideoPlayerGetVideoshotFailure',

    VideoPlayerInitVideoshotState = '[VideoPlayer] VideoPlayerInitVideoshotState',
    VideoPlayerChangeVideoshotState = '[VideoPlayer] VideoPlayerChangeVideoshotState',
    VideoPlayerClearState = '[VideoPlayer] VideoPlayerClearState',

    VideoPlayerSelectCurrentSource = '[VideoPlayer] VideoPlayerSelectCurrentSource',

    SaveVideoshotTimeData = '[VideoPlayer] SaveVideoshotTimeData'
}

export class VideoPlayerGetPlaylists implements Action {
    readonly type = VideoPlayerActionTypes.VideoPlayerGetPlaylists;

    constructor(public rdvaUri: string, public cameraId: number, public timeZone: number) { }
}

export class VideoPlayerGetPlaylistsSuccess implements Action {
    readonly type = VideoPlayerActionTypes.VideoPlayerGetPlaylistsSuccess;

    constructor(public response: RdvaPlaylistItem[]) { }
}

export class VideoPlayerGetPlaylistsFailure implements Action {
    readonly type = VideoPlayerActionTypes.VideoPlayerGetPlaylistsFailure;

    constructor() { }
}

export class VideoPlayerCreateVideoshotTask implements Action {
    readonly type = VideoPlayerActionTypes.VideoPlayerCreateVideoshotTask;

    constructor(public request: VideoshotGetRequest) { }
}

export class VideoPlayerCreateVideoshotTaskSuccess implements Action {
    readonly type = VideoPlayerActionTypes.VideoPlayerCreateVideoshotTaskSuccess;

    constructor() { }
}

export class VideoPlayerCreateVideoshotTaskFailure implements Action {
    readonly type = VideoPlayerActionTypes.VideoPlayerCreateVideoshotTaskFailure;

    constructor() { }
}

export class VideoPlayerGetVideoshot implements Action {
    readonly type = VideoPlayerActionTypes.VideoPlayerGetVideoshot;

    constructor(public request: VideoshotGetRequest) { }
}

export class VideoPlayerGetVideoshotSuccess implements Action {
    readonly type = VideoPlayerActionTypes.VideoPlayerGetVideoshotSuccess;

    constructor() { }
}

export class VideoPlayerGetVideoshotFailure implements Action {
    readonly type = VideoPlayerActionTypes.VideoPlayerGetVideoshotFailure;

    constructor(public error: HttpErrorResponse) { }
}

export class VideoPlayerInitVideoshotState implements Action {
    readonly type = VideoPlayerActionTypes.VideoPlayerInitVideoshotState;

    constructor() { }
}

export class VideoPlayerChangeVideoshotState implements Action {
    readonly type = VideoPlayerActionTypes.VideoPlayerChangeVideoshotState;

    constructor(public newState: VideoshotPopupState) {}
}

export class VideoPlayerSelectCurrentSource implements Action {
    readonly type = VideoPlayerActionTypes.VideoPlayerSelectCurrentSource;

    constructor(public source: string) { }
}

export class VideoPlayerClearState implements Action {
    readonly type = VideoPlayerActionTypes.VideoPlayerClearState;

    constructor() { }
}

export class SaveVideoshotTimeData implements Action {
    readonly type = VideoPlayerActionTypes.SaveVideoshotTimeData;

    constructor(public timeData: VideoshotTimeData) {}
}

export type VideoPlayerActions =
    | VideoPlayerGetPlaylists
    | VideoPlayerGetPlaylistsSuccess
    | VideoPlayerGetPlaylistsFailure

    | VideoPlayerCreateVideoshotTask
    | VideoPlayerCreateVideoshotTaskSuccess
    | VideoPlayerCreateVideoshotTaskFailure

    | VideoPlayerGetVideoshot
    | VideoPlayerGetVideoshotSuccess
    | VideoPlayerGetVideoshotFailure

    | VideoPlayerInitVideoshotState
    | VideoPlayerChangeVideoshotState
    | VideoPlayerSelectCurrentSource
    | VideoPlayerClearState

    | SaveVideoshotTimeData;
