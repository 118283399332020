import { HttpErrorResponse } from '@angular/common/http';
import { AddRdasToOrderRequest, AddScansToOrderRequest, CreateOrderRequest, OrderResponse, UpdateOrderRequest } from '@app/views/intercom/models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
    GetOrdersPage = '[Intercom] GetOrdersPage',
    GetOrdersPageSuccess = '[Intercom] GetOrdersPage Success',
    GetOrdersPageFailure = '[Intercom] GetOrdersPage Failure',
    GetOrdersPageClear = '[Intercom] GetOrdersPage Clear',

    CreateOrder = '[Intercom] CreateOrder',
    CreateOrderSuccess = '[Intercom] CreateOrder Success',
    CreateOrderFailure = '[Intercom] CreateOrder Failure',
    CreateOrderClear = '[Intercom] CreateOrder Clear',

    UpdateOrder = '[Intercom] UpdateOrder',
    UpdateOrderSuccess = '[Intercom] UpdateOrder Success',
    UpdateOrderFailure = '[Intercom] UpdateOrder Failure',
    UpdateOrderClear = '[Intercom] UpdateOrder Clear',

    DeleteOrder = '[Intercom] DeleteOrder',
    DeleteOrderSuccess = '[Intercom] DeleteOrder Success',
    DeleteOrderFailure = '[Intercom] DeleteOrder Failure',
    DeleteOrderClear = '[Intercom] DeleteOrder Clear',

    AddRdasToOrder = '[Intercom] AddRdasToOrder',
    AddRdasToOrderSuccess = '[Intercom] AddRdasToOrder Success',
    AddRdasToOrderFailure = '[Intercom] AddRdasToOrder Failure',
    AddRdasToOrderClear = '[Intercom] AddRdasToOrder Clear',

    AddScansToOrder = '[Intercom] AAddScansToOrder',
    AddScansToOrderSuccess = '[Intercom] AddScansToOrder Success',
    AddScansToOrderFailure = '[Intercom] AddScansToOrder Failure',
    AddScansToOrderClear = '[Intercom] AddScansToOrder Clear',
}

export class GetOrdersPage implements Action {
    readonly type = ActionTypes.GetOrdersPage;

    constructor(public page: number = 0, public size: number = 10, public withoutManufacturer: boolean, public search: string) { }
}

export class GetOrdersPageSuccess implements Action {
    readonly type = ActionTypes.GetOrdersPageSuccess;

    constructor(public payload: OrderResponse[], public totalCount: number) { }
}

export class GetOrdersPageFailure implements Action {
    readonly type = ActionTypes.GetOrdersPageFailure;

    constructor(public payload: string) { }
}

export class GetOrdersPageClear implements Action {
    readonly type = ActionTypes.GetOrdersPageClear;

    constructor() { }
}

export class CreateOrder implements Action {
    readonly type = ActionTypes.CreateOrder;

    constructor(public request: CreateOrderRequest) { }
}

export class CreateOrderSuccess implements Action {
    readonly type = ActionTypes.CreateOrderSuccess;

    constructor(public payload: OrderResponse) { }
}

export class CreateOrderFailure implements Action {
    readonly type = ActionTypes.CreateOrderFailure;

    constructor(public payload: HttpErrorResponse) { }
}

export class CreateOrderClear implements Action {
    readonly type = ActionTypes.CreateOrderClear;

    constructor() { }
}

export class UpdateOrder implements Action {
    readonly type = ActionTypes.UpdateOrder;

    constructor(public orderId: number, public request: UpdateOrderRequest) { }
}

export class UpdateOrderSuccess implements Action {
    readonly type = ActionTypes.UpdateOrderSuccess;

    constructor(public orderId: number, public request: UpdateOrderRequest) { }
}

export class UpdateOrderFailure implements Action {
    readonly type = ActionTypes.UpdateOrderFailure;

    constructor(public payload: HttpErrorResponse) { }
}

export class UpdateOrderClear implements Action {
    readonly type = ActionTypes.UpdateOrderClear;

    constructor() { }
}

export class DeleteOrder implements Action {
    readonly type = ActionTypes.DeleteOrder;

    constructor(public orderId: number) { }
}

export class DeleteOrderSuccess implements Action {
    readonly type = ActionTypes.DeleteOrderSuccess;

    constructor() { }
}

export class DeleteOrderFailure implements Action {
    readonly type = ActionTypes.DeleteOrderFailure;

    constructor(public payload: HttpErrorResponse) { }
}

export class DeleteOrderClear implements Action {
    readonly type = ActionTypes.DeleteOrderClear;

    constructor() { }
}

export class AddRdasToOrder implements Action {
    readonly type = ActionTypes.AddRdasToOrder;

    constructor(public orderId: number, public addRdasToOrderRequest: AddRdasToOrderRequest) { }
}

export class AddRdasToOrderSuccess implements Action {
    readonly type = ActionTypes.AddRdasToOrderSuccess;

    constructor() { }
}

export class AddRdasToOrderFailure implements Action {
    readonly type = ActionTypes.AddRdasToOrderFailure;

    constructor(public payload: HttpErrorResponse) { }
}

export class AddRdasToOrderClear implements Action {
    readonly type = ActionTypes.AddRdasToOrderClear;

    constructor() { }
}

export class AddScansToOrder implements Action {
    readonly type = ActionTypes.AddScansToOrder;

    constructor(public orderId: number, public addScansToOrderRequest: AddScansToOrderRequest) { }
}

export class AddScansToOrderSuccess implements Action {
    readonly type = ActionTypes.AddScansToOrderSuccess;

    constructor() { }
}

export class AddScansToOrderFailure implements Action {
    readonly type = ActionTypes.AddScansToOrderFailure;

    constructor(public payload: HttpErrorResponse) { }
}

export class AddScansToOrderClear implements Action {
    readonly type = ActionTypes.AddScansToOrderClear;

    constructor() { }
}

export type Actions =
    | GetOrdersPage
    | GetOrdersPageSuccess
    | GetOrdersPageFailure
    | GetOrdersPageClear
    | CreateOrder
    | CreateOrderSuccess
    | CreateOrderFailure
    | CreateOrderClear
    | UpdateOrder
    | UpdateOrderSuccess
    | UpdateOrderFailure
    | UpdateOrderClear
    | DeleteOrder
    | DeleteOrderSuccess
    | DeleteOrderFailure
    | DeleteOrderClear
    | AddRdasToOrder
    | AddRdasToOrderSuccess
    | AddRdasToOrderFailure
    | AddRdasToOrderClear
    | AddScansToOrder
    | AddScansToOrderSuccess
    | AddScansToOrderFailure
    | AddScansToOrderClear;
