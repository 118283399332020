import {NgModule} from '@angular/core';
import {CameraUriValidationDirective} from './camera-uri-validation.directive';
import {CanAccessDirective} from './can-access.directive';
import {DadataSuggestionsDirective} from './dadata-suggestions.directive';
import {MiddleClickDirective} from './middle-click.directive';
import {PhoneValidationDirective} from './phone-validation.directive';
import {SomeRoleContainsDirective} from './some-role-contains.directive';
import {TimepickerDirective} from './timepicker.directive';
import {VarDirective} from './var.directive';
import {TooltipForTruncatedTextDirective} from '@app/shared/directives/tooltip-for-truncated-text.directive';
import { LowerCaseDirective } from './lower-case.directive';

const pipes = [
  CameraUriValidationDirective,
  CanAccessDirective,
  DadataSuggestionsDirective,
  MiddleClickDirective,
  PhoneValidationDirective,
  SomeRoleContainsDirective,
  TimepickerDirective,
  VarDirective,
  LowerCaseDirective,
  TooltipForTruncatedTextDirective
];

@NgModule({
  declarations: pipes,
  exports: pipes
})
export class SharedDirectivesModule {
}
