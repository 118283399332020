<mat-form-field appearance="outline">
  <mat-label>
    {{ 'shared.video_player.video_shot.popup.field.duration' | translate }}
  </mat-label>
  <input 
    matInput 
    type="text" 
    [formControl]="durationControl" 
    mask="m0:sl"
    [patterns]="durationMaskPatterns"
    [showMaskTyped]="true"
  >
  <mat-error *ngIf="!durationControl?.hasError('required') && durationControl.hasError('minDuration')">
    {{'shared.video_player.video_shot.popup.message.field_min_time' | translate: {text: this.minDuration} }}
  </mat-error>

  <mat-error *ngIf="!durationControl?.hasError('required') && durationControl.hasError('maxDuration')">
    {{'shared.video_player.video_shot.popup.message.field_max_time' | translate: {text: this.maxDuration} }}
  </mat-error>

  <mat-error *ngIf="durationControl?.hasError('required')">
    {{'shared.video_player.video_shot.popup.message.field_required' | translate }}
  </mat-error>

  <mat-error *ngIf="!durationControl?.hasError('required') && durationControl?.hasError('enterAllNumbers')">
    {{'shared.video_player.video_shot.popup.message.field_enter_full_time' | translate }}
  </mat-error>

  <mat-hint>
    {{'shared.video_player.video_shot.popup.message.field_hint_about_time_limits' 
    | translate: {minText: this.minDuration, maxText: this.maxDuration} }}
  </mat-hint>
</mat-form-field>

