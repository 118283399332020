import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '@app/shared/components/snackbar';
import { ChangePasswordRequest } from '@app/shared/entities/rd';
import { PasswordValidators } from '../../password-validators';
import {TranslateService} from '@ngx-translate/core';

class ErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return control.parent.invalid && control.touched;
  }
}

@Component({
  selector: 'app-change-password-content',
  templateUrl: './change-password-content.component.html',
  styleUrls: ['./change-password-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChangePasswordContentComponent {
  private _loading: boolean;
  protected form: UntypedFormGroup = this.initForm();
  protected errorMessages = {
    required: 'shared.change.password.content.message.required',
    passwordRepeatMismatch: 'shared.change.password.content.message.passwords_not_equal',
    passwordIsNotStrong: 'shared.change.password.content.message.password_not_strong',
  };
  protected matcher = new ErrorStateMatcher();

  @Input() 
  set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  get loading(): boolean {
    return this._loading
  }
  
  @Output() 
  private changePassword: EventEmitter<{ oldPassword: string, newPassword: string }> = new EventEmitter();
  
  get oldPassword(): AbstractControl | undefined {
    return this.form?.get('oldPassword')
  }

  get newPassword(): AbstractControl | undefined {
    return this.form?.get('newPassword')
  }

  get newPasswordRepeat(): AbstractControl | undefined {
    return this.form.get('newPasswordRepeat')
  }

  constructor(
    private snackbar: SnackbarService,
    private translate: TranslateService
  ) {}

  onSubmit() {
    if (!this.form.valid) {
      const warningMessage = this.form.errors?.passwordsNotEqual
        ? this.translate.instant('shared.change.password.content.message.passwords_not_equal')
        : this.translate.instant('shared.change.password.content.message.indicate_all_necessary_data')
      ;

      this.snackbar.showMessage(warningMessage, 'info');
      return;
    }

    const { oldPassword, newPassword }: ChangePasswordRequest = this.form.getRawValue();

    this.changePassword.emit({ oldPassword, newPassword });
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        oldPassword: new UntypedFormControl(null, Validators.required),
        newPassword: new UntypedFormControl(null, [
          Validators.required, 
          PasswordValidators.strongPassword
        ]),
        newPasswordRepeat: new UntypedFormControl(null, Validators.required)
      },
      [PasswordValidators.passwordRepeatMismatch]
    );
  }
}
