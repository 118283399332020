import { ChangeDetectionStrategy, Component, forwardRef, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ControlContainer, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { LocalStorageGeneralKey, LocalStorageHelper } from '@app/shared/entities/common';
import { PhonesUtils, PrefixModel, PrefixType } from '@app/shared/entities/rd';

export const CUSTOM_PHONE_INPUT_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  // tslint:disable-next-line: no-use-before-declare
  useExisting: forwardRef(() => PhoneInputComponent),
  multi: true
};

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneInputComponent implements OnInit {
  readonly prefixesList = PhonesUtils.prefixesList;

  // tslint:disable-next-line: no-input-rename
  @Input('without-description')
  @HostBinding('attr.without-description')
  withoutDescription = false;

  @Input() required: boolean = true;
  @Input() disabledPrefix: boolean;
  prefix: PrefixType;
  placeholder: string;

  constructor(
    public controlContainer: ControlContainer
  ) { }

  ngOnInit() {
    const country = LocalStorageHelper.getItem(LocalStorageGeneralKey.COUNTRY);
    const countryShortName: string = country?.shortName ?? null;

    let prefixModel: PrefixModel = this.prefixesList.find(elem => elem.countryShortName === countryShortName);

    if (!prefixModel) {
      prefixModel = this.prefixesList[0];
    }

    this.setPrefix(prefixModel);
    if (this.controlContainer.value.number) {
      this.setValue('number', this.controlContainer.value.number ?? '');
    }
  }

  onPrefixSelected(event: MatSelectChange) {
    this.setPrefix(this.prefixesList.find(prefix => prefix.prefix === event.value));
    this.setValue('number', '');
  }

  private setPrefix(prefixModel: PrefixModel) {

    this.placeholder = prefixModel.mask;
    this.prefix = prefixModel.prefix;

    this.setValue('prefix', this.prefix, true);

    if (this.disabledPrefix) {
      this.getControl('prefix').disable();
    }
  }

  private setValue(name: 'number' | 'prefix', value: string, touched?: boolean) {
    const control = this.getControl(name);

    if (touched) {
      control.markAsTouched();
    }

    control.setValue(value);
  }

  private getControl(name: 'number' | 'prefix'): AbstractControl {
    return this.controlContainer.control.get(name as string);
  }
}
