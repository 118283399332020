<form class="popup" [formGroup]="form" (ngSubmit)="onSubmit()">

  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'shared.change.password.content.field.current_password' | translate }}
    </mat-label>
    <input matInput
      type="password"
      placeholder="{{ 'shared.change.password.content.placeholder.current_password' | translate }}"
      formControlName="oldPassword"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
    />
    <mat-error *ngIf="oldPassword?.hasError('required')">
      {{ errorMessages['required'] | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>
      {{ 'shared.change.password.content.field.new_password' | translate }}
    </mat-label>
    <input matInput
      type="password"
      placeholder="{{ 'shared.change.password.content.placeholder.new_password' | translate }}"
      formControlName="newPassword"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
    />
    <mat-error *ngIf="newPassword?.hasError('required')">
      {{ errorMessages['required'] | translate }}
    </mat-error>
    <mat-error *ngIf="!newPassword?.hasError('required') && newPassword?.hasError('passwordIsNotStrong')">
      {{ errorMessages['passwordIsNotStrong'] | translate }}
    </mat-error>  
  </mat-form-field>

  <mat-form-field appearance="outline"
  [class.mat-form-field-invalid]="form.errors?.passwordRepeatMismatch">
    <mat-label>
      {{ 'shared.change.password.content.field.repeat_password' | translate }}
    </mat-label>
    <input matInput
      type="password"
      placeholder="{{ 'shared.change.password.content.placeholder.repeat_password' | translate }}"
      formControlName="newPasswordRepeat"
      [errorStateMatcher]="matcher"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
    />

    <mat-error *ngIf="form?.hasError('passwordRepeatMismatch') && !newPasswordRepeat?.hasError('required')">
      {{ errorMessages['passwordRepeatMismatch'] | translate}}
    </mat-error>
    <mat-error *ngIf="newPasswordRepeat?.hasError('required')">
      {{ errorMessages['required'] | translate}}
    </mat-error>
  </mat-form-field>

  <div class="popup-actions">
    <button mat-raised-button
      class="popup-actions__button"
      type="submit"
      color="primary"
      [disabled]="loading || !form.valid"
    >
      {{ 'shared.change.password.content.button.submit' | translate }}
    </button>
  </div>
</form>
