import { createSelector } from '@ngrx/store';
import { IntercomModuleState } from '../../reducers/reducers';
import { intercomModuleSelector } from '../intercom-module.state';

const selectShieldTestsState = createSelector(
  intercomModuleSelector,
  (state: IntercomModuleState) => state.getShieldTests
);

export const getShieldTests = createSelector(
  selectShieldTestsState,
  state => state
);

export const getShieldTestsSuccess = createSelector(
  selectShieldTestsState,
  state => state.tests
);

export const getShieldTestsFailure = createSelector(
  selectShieldTestsState,
  state => state.error
);

export const getShieldTestsLoading = createSelector(
  selectShieldTestsState,
  state => state.loading
);
