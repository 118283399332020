import { Injectable, OnDestroy } from '@angular/core';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { Actions, ofType } from '@ngrx/effects';

import { DialogWrapperStateHelper } from '@app/shared/ui';
import { DeleteServiceAbonentAccessSuccess, ServiceFacade } from '@app/views/services/store';
import { ServicesActionTypes } from '../../../../store';
import { ServiceEntrancesFlats, ServiceFlat } from '../../../containers';

@Injectable()
export class ServiceAbonentsDisconnectPopupService extends DialogWrapperStateHelper<'loading' | 'close' | 'loaded'> implements OnDestroy {
    private _entranceId: number;
    public flats:ServiceFlat[];
    
    constructor( private actions$: Actions, private serviceFacade: ServiceFacade ) {
        super();
        this.enableStateListeners();
    }

    ngOnDestroy() {
        this.destroy();
    }

    set entranceId(entranceId: number) {
        this._entranceId = entranceId;
    }

    private enableStateListeners() {
        this.actions$
            .pipe(
                ofType(ServicesActionTypes.DeleteServiceAbonentAccess), takeUntil(this.onDestroy$),
            )
            .subscribe(() => this.state = 'loading');

        this.actions$
            .pipe(
                ofType(ServicesActionTypes.DeleteServiceAbonentAccessSuccess), takeUntil(this.onDestroy$),
                withLatestFrom(this.serviceFacade.flats$)
            )
            .subscribe(([_action, flats]: [DeleteServiceAbonentAccessSuccess, ServiceEntrancesFlats]) => {
                this.flats = Object.values(flats[this._entranceId].flats) as ServiceFlat[];
            });

        this.actions$
            .pipe(
                ofType(...[
                    ServicesActionTypes.DeleteServiceAbonentAccessSuccess,
                    ServicesActionTypes.DeleteServiceAbonentAccessFailure
                ]), takeUntil(this.onDestroy$)
            )
            .subscribe(() => this.state = 'loaded')
    }
}
