import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {SnackbarService} from '@app/shared/components/snackbar';
import {
  IntercomPanelApiService,
  IntercomPanelResponse,
  RdaResponse,
  ServiceApiService,
  ServiceResponse
} from '@app/shared/entities/rd';
import {parseError} from '@app/shared/helpers';
import {LocationResponse} from '@app/shared/models';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {from, of} from 'rxjs';
import {catchError, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {
  AddServiceIntercomPanel,
  AddServiceIntercomPanelFailure,
  AddServiceIntercomPanelSuccess,
  DeleteServiceIntercomPanel,
  DeleteServiceIntercomPanelFailure,
  DeleteServiceIntercomPanelSuccess,
  ServicesActionTypes,
  UpdateServiceIntercomPanel,
  UpdateServiceIntercomPanelFailure,
  UpdateServiceIntercomPanelSuccess
} from '../actions';
import {ServiceFacade} from '../services.facade';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class ServicesIntercomPanelEffects {
  constructor(
    private actions$: Actions,
    private snackbar: SnackbarService,
    private serviceApiService: ServiceApiService,
    private serviceFacade: ServiceFacade,
    private intercomPanelApiService: IntercomPanelApiService,
    private translate: TranslateService
  ) {
  }

  AddServiceIntercomPanel$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddServiceIntercomPanel>(ServicesActionTypes.AddServiceIntercomPanel),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [AddServiceIntercomPanel, number]) =>
        from(this.serviceApiService.connectIntercomPanel(serviceId, action.request))
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercom.panel.message.add_service_intercom_panel.success'),
                'success'
              );
              return new AddServiceIntercomPanelSuccess(action.request);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercom.panel.message.add_service_intercom_panel.failed', {
                  text: parseError(error)
                })
              );
              return of(new AddServiceIntercomPanelFailure());
            })
          )
      )
    )
  );

  AddServiceIntercomPanelSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddServiceIntercomPanelSuccess>(ServicesActionTypes.AddServiceIntercomPanelSuccess),
      tap((action: AddServiceIntercomPanelSuccess) => this.serviceFacade.getServiceRdas())
    ), {dispatch: false}
  );

  UpdateServiceIntercomPanel$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UpdateServiceIntercomPanel>(ServicesActionTypes.UpdateServiceIntercomPanel),
      withLatestFrom(
        this.serviceFacade.camerasLocations$,
        this.serviceFacade.intercomPanels$
      ),
      switchMap(([action, locations, intercomPanels]: [UpdateServiceIntercomPanel, LocationResponse[], IntercomPanelResponse[]]) =>
        this.intercomPanelApiService.update(action.intercomPanelId, action.request)
          .pipe(
            map(() => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercom.panel.message.update_service_intercom_panel.success'),
                'success'
              );

              const intercomPanelBeforeChange: IntercomPanelResponse = intercomPanels.find(intercomPanel => intercomPanel.id === action.intercomPanelId);

              let newLocation: LocationResponse;

              if (action.request.locationId) {
                newLocation = locations.find(location => location.id === action.request.locationId);
              }

              const intercomPanelAfterChange: IntercomPanelResponse = JSON.parse(JSON.stringify(intercomPanelBeforeChange));
              intercomPanelAfterChange.index = action.request.index !== undefined ? action.request.index : null;
              intercomPanelAfterChange.location = action.request.locationId ?
                locations.find(location => location.id === action.request.locationId) : null;

              return new UpdateServiceIntercomPanelSuccess(intercomPanelBeforeChange, intercomPanelAfterChange, newLocation, action.rdaId);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercom.panel.message.update_service_intercom_panel.failed', {
                  text: parseError(error)
                })
              );
              return of(new UpdateServiceIntercomPanelFailure());
            })
          )
      )
    )
  );

  DeleteServiceIntercomPanel$ = createEffect(() =>
    this.actions$.pipe(
      ofType<DeleteServiceIntercomPanel>(ServicesActionTypes.DeleteServiceIntercomPanel),
      withLatestFrom(this.serviceFacade.serviceId$),
      switchMap(([action, serviceId]: [DeleteServiceIntercomPanel, number]) =>
        from(this.serviceApiService.disconnectIntercomPanel(serviceId, action.intercomPanelId))
          .pipe(
            map(() => {
              this.snackbar.showMessage(this.translate.instant(
                'services.intercom.panel.message.delete_service_intercom_panel.success'),
                'success'
              );
              return new DeleteServiceIntercomPanelSuccess(action.intercomPanelId, action.rdaId);
            }),
            catchError((error: HttpErrorResponse) => {
              this.snackbar.showMessage(
                this.translate.instant('services.intercom.panel.message.delete_service_intercom_panel.failed', {
                  text: parseError(error)
                })
              );
              return of(new DeleteServiceIntercomPanelFailure());
            })
          )
      )
    )
  );
}
