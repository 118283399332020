import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {AppLinks, LinksAndroid, LinksHuawei, LinksiOS} from '@app/shared/helpers/app-links.enum';
import {environment} from 'environments/environment';


@Component({
  selector: 'app-company-links',
  templateUrl: './company-links.component.html',
  styleUrls: ['./company-links.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyLinksComponent implements OnInit {
  @Input() appLink: string;
  links: AppLinks;

  public ngOnInit(): void {

    if (this.appLink) {
      const name = this.appLink.split('/').pop();

      switch (name) {
        case 'moydomofon':
          this.links = {
            linkAndroid: LinksAndroid.kz,
            linkiOS: LinksiOS.kz,
            linkHuawei: LinksHuawei.RDKazakhstanBelarus
          };
          break;
        case 'orion':
          this.links = {
            linkAndroid: LinksAndroid.Orion,
            linkHuawei: LinksHuawei.Orion,
            linkiOS: LinksiOS.Orion
          };
          break;
        case 'redcom':
          this.links = {
            linkAndroid: LinksAndroid.Redcom,
            linkHuawei: LinksHuawei.Redcom,
            linkiOS: LinksiOS.Redcom
          };
          break;
        case 'myintercom':
          this.links = {
            linkAndroid: LinksAndroid.RDKazakhstanBelarus,
            linkHuawei: LinksHuawei.RDKazakhstanBelarus,
            linkiOS: LinksiOS.RDKazakhstanBelarus
          };
          break;
        case 'yourintercom':
          this.links = {
            linkAndroid: LinksAndroid.RDKazakhstanBelarus,
            linkHuawei: LinksHuawei.RDKazakhstanBelarus,
            linkiOS: LinksiOS.RDKazakhstanBelarus
          };
          break;
        case 'sibset-domofon':
          this.links = {
            linkAndroid: LinksAndroid.Sibset,
            linkHuawei: LinksHuawei.Sibset,
            linkiOS: LinksiOS.Sibset
          };
          break;
        case 'rosdomofon':
          if (environment.vivotec) {
            this.links = {
              linkAndroid: LinksAndroid.vivo,
              linkiOS: LinksiOS.vivo
            };
            return;
          }
          if (environment.kz) {
            this.links = {
              linkAndroid: LinksAndroid.kz,
              linkiOS: LinksiOS.kz,
              linkHuawei: LinksHuawei.RDKazakhstanBelarus
            };
            return;
          }
          this.links = {
            linkAndroid: LinksAndroid.RDRussia,
            linkHuawei: LinksHuawei.RDRussia,
            linkiOS: LinksiOS.RDRussia
          };
          break;
        default:
          if (environment.vivotec) {
            this.links = {
              linkAndroid: LinksAndroid.vivo,
              linkiOS: LinksiOS.vivo
            };
            return;
          }
          throw new Error('Links not implemented');
      }
    }
  }
}
