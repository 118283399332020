import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocalStorageHelper, LocalStoragePaymentsKey } from '@app/shared/entities';
import { DialogWrapperData } from '@app/shared/ui';
import { ServiceWizardPopupContentComponent } from '@app/views/services/components';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, tap } from 'rxjs/operators';

import { PaymentsWizardStep, PaymentsWizardStepper } from './models';
import { PaymentsWizardStore } from './store';

@Component({
  selector: 'app-payments-wizard',
  templateUrl: './payments-wizard.component.html',
  styleUrls: ['./payments-wizard.component.scss'],
  providers: [PaymentsWizardStore],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaymentsWizardComponent
  extends ServiceWizardPopupContentComponent
  implements OnInit, OnDestroy {
  public readonly stepType: typeof PaymentsWizardStep = PaymentsWizardStep;
  public readonly loading$: Observable<boolean> = this.store.loading$;

  public readonly steps$: Observable<PaymentsWizardStepper[]> =
    this.store.steps$;

  private onDestroy$: Subject<void> = new Subject();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<void, void>,
    private store: PaymentsWizardStore
  ) {
    super();
  }

  ngOnInit(): void {
    this.steps$
      .pipe(
        take(1),
        tap((steps: PaymentsWizardStepper[]) => {
          let lastCompleteStepIdx: number =
            _.findLastIndex(steps, step => step.complete === true) + 1;

          if (lastCompleteStepIdx < 1) {
            lastCompleteStepIdx = 0;
          }

          this.wizardStep = lastCompleteStepIdx;
        })
      )
      .subscribe();

    this.steps$
      .pipe(
        takeUntil(this.onDestroy$),
        tap((steps: PaymentsWizardStepper[]) => {
          this.steps = steps;
          LocalStorageHelper.setItem(
            LocalStoragePaymentsKey.STEPPER,
            this.steps
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onNextStep(): void {
    if (this.wizardStep === PaymentsWizardStep.SERVICE) {
      this.onNextStepService();
      return;
    }

    const wizardStep: PaymentsWizardStep = this.getNextStep(
      this.wizardStep + 1
    );

    if (wizardStep === null) {
      return;
    }

    this.wizardStep = wizardStep;
  }

  public onNextStepService(): void {
    this.store.updateStepState({
      step: PaymentsWizardStep.SERVICE,
      stepState: { complete: true }
    });

    this.store.updateStepState({
      step: PaymentsWizardStep.REGISTRATION,
      stepState: { disabled: false, active: true }
    });

    this.onChangeStep(PaymentsWizardStep.REGISTRATION);
  }

  public onPreviousStep(): void {
    const wizardStep: PaymentsWizardStep = this.getPreviousStep(
      this.wizardStep - 1
    );

    if (wizardStep === null) {
      return;
    }

    this.wizardStep = wizardStep;
  }

  public onSuccess(): void {
    this.data.submit();
  }

  public onChangeStep(wizardStep: PaymentsWizardStep): void {
    this.wizardStep = wizardStep;
  }

  public isNextStepEnabled(): boolean {
    return this.getNextStep(PaymentsWizardStep.INTEGRATION) !== null || this.wizardStep === PaymentsWizardStep.SERVICE;
  }

  public isPreviousStepEnabled(): boolean {
    return this.getPreviousStep() !== null;
  }
}
