import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  IpSputnikGenerateUrlsContainerComponent
} from '@app/shared/components/keys/ip-sputnik-generate-urls-container/ip-sputnik-generate-urls-container.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { InfoBlockModule } from '@app/shared/ui';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
  declarations: [IpSputnikGenerateUrlsContainerComponent],
  exports: [
    IpSputnikGenerateUrlsContainerComponent
  ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        InfoBlockModule,
        NgxTranslateModule
    ]
})
export class IpSputnikGenerateUrlsContainerModule { }
