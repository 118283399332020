<mat-card class="card-container">
  <mat-card-header class="header">
    <div class="title-block">
      <mat-card-title>
        {{ 'entrance.page.rda.keys.card.title' | translate }}
      </mat-card-title>
    </div>
  </mat-card-header>

  <hr>

  <mat-card-content>
    <app-intercom-keys-container
      [keys]="keys"
      [readonly]="true"
      [loading]="loading"
      [rdaUid]="rda.uid"
      [intercomIpType]="rda?.intercomType?.protocol?.ipType"
      [intercomProtocolNumber]="rda?.intercomType?.protocol?.number"
      *ngIf="rda && keys?.length > 0"
    ></app-intercom-keys-container>

    <ng-container *ngIf="keys?.length === 0 && !loading">
      <app-info-block *ngIf="rda; else noRda">
        <ng-container info-block-text>
          {{ 'entrance.page.rda.keys.card.message.add_keys' | translate }}
        </ng-container>
      </app-info-block>

      <ng-template #noRda>
        <app-info-block>
          <ng-container info-block-text>
            {{ 'entrance.page.rda.keys.card.message.no_keys' | translate }}
          </ng-container>
        </app-info-block>
      </ng-template>
    </ng-container>
  </mat-card-content>

</mat-card>
