import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Camera, KeysResponse, RdaResponse } from '@app/shared/entities/rd';
import { CameraLease } from '@app/shared/entities/rd/camera/services/camera-lease.model';
import { Address, LocationResponse } from '@app/shared/models';
import { DialogWrapperData } from '@app/shared/ui';
import { ServiceFacade } from '@app/views/services/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { ServiceCameraPopupBody } from './service-camera-popup-body.model';
import { ServiceCameraPopupService } from './service-camera-popup.service';
import {
  CameraAlreadyExistsPopupService
} from '@app/shared/components/camera/camera-already-exists/service-camera-already-exists-popup/camera-already-exists-popup.service';

@Component({
  selector: 'app-service-camera-popup',
  templateUrl: './service-camera-popup.component.html',
  styleUrls: ['./service-camera-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ServiceCameraPopupService, CameraAlreadyExistsPopupService]
})
export class ServiceCameraPopupComponent implements OnInit {
  rdas$: Observable<RdaResponse[]> = this.serviceFacade.rdas$;
  keys$: Observable<KeysResponse[]> = this.serviceFacade.keys$;
  camerasLocations$: Observable<LocationResponse[]> = this.serviceFacade.camerasLocations$;
  addresses$: Observable<Address[]> = this.serviceFacade.entrances$;
  popupState$: Observable<'loading' | 'close' | 'loaded' | 'error'> = this.popupService.state$;
  onvif$: Observable<boolean> = this.serviceFacade.isActiveOnvif$;
  cameraAlreadyExists$: BehaviorSubject<Camera> = new BehaviorSubject<Camera>(null);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<ServiceCameraPopupBody, { camera: Camera | CameraLease }>,
    protected popupService: ServiceCameraPopupService,
    protected serviceFacade: ServiceFacade,
    protected dialogRef: MatDialogRef<ServiceCameraPopupComponent>,
    protected cameraAlreadyExistsPopupService: CameraAlreadyExistsPopupService
  ) {}

  ngOnInit() {
    this.popupService.addStateChangeListener(state => state === 'close' && this.dialogRef.close());
    this.cameraAlreadyExistsPopupService.addStateChangeListener((response: Camera | 'Failure' | 'Success') => {
      if (typeof response === 'object' && response !== null) {
        this.cameraAlreadyExists$.next(response as Camera);
      }
    });
  }

  onSubmit(camera: Camera) {
    this.data.submit({ camera });
  }

  onSubmitOnvif(camera: Camera) {
    this.data.submitOnvif({camera});
  }

  onClose() {
    this.dialogRef.close();
  }
}
