import { ServicesList } from './services-list.model';
import { Camera, KeysResponse, RdaResponse, ServiceConnection } from '@app/shared/entities/rd';
import { Address, ServicesTypes } from '@app/shared/models';

export interface ServiceResponse {
    id: number;
    name: string;
    customName: string;
    type: ServicesTypes;
    companyId: number;
    entrances: Address[];
    rdas: RdaResponse[];
    cameras: Camera[];
    keys: KeysResponse[];
    connections: ServiceConnection[];
    dependantServices: ServicesList[];
    tariff: number;
}
