import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddressInputMode } from '@app/shared/components';
import { Address, EntranceRegistration, EntranceUpdateRequest, FlatRange } from '@app/shared/models';
import { ServiceFacade } from '@app/views/services/store';
import { GateWizardPopupService } from '../../gate-wizard-popup.service';
import { GateWizardContentEntrancesMode } from './gate-wizard-content-entrances-mode';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-gate-wizard-content-entrances',
  templateUrl: './gate-wizard-content-entrances.component.html',
  styleUrls: ['./gate-wizard-content-entrances.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GateWizardContentEntrancesComponent implements OnInit {
  readonly mode: typeof GateWizardContentEntrancesMode = GateWizardContentEntrancesMode;
  readonly addressInputMode: typeof AddressInputMode = AddressInputMode;

  @Input() addresses!: Address[];
  @Input() serviceId!: number;
  @Input() loading: boolean;
  address: Address;
  addressId: number;
  editContentLabel: string;
  currentMode: GateWizardContentEntrancesMode = GateWizardContentEntrancesMode.LIST;

  @Output() private changePageMode: EventEmitter<{ default: boolean }> = new EventEmitter();

  constructor(
    private popupStateService: GateWizardPopupService,
    private serviceFacade: ServiceFacade,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.popupStateService.addStateChangeListener(state => state === 'close' && this.onBack());
  }

  onAddAddress() {
    this.editContentLabel = this.translate.instant('services.gates.gate.wizard.popup.entrances.add_address.label');
    this.currentMode = GateWizardContentEntrancesMode.EDIT;
    this.changePageMode.emit({ default: false });
  }

  onEditAddress(addressIdx: number) {
    this.editContentLabel = this.translate.instant('services.gates.gate.wizard.popup.entrances.edit_address.label');
    this.address = this.addresses[addressIdx];
    this.currentMode = GateWizardContentEntrancesMode.EDIT;
    this.changePageMode.emit({ default: false });
  }

  onDeleteAddress(addressId: number) {
    this.addressId = addressId;
    this.currentMode = GateWizardContentEntrancesMode.DELETE;
    this.changePageMode.emit({ default: false });
  }

  onAdd(entrance: EntranceRegistration, prefix: string) {
    if (this.address) {
      const entranceUpdateRequest: EntranceUpdateRequest = {
        flatStart: entrance.range[0],
        flatEnd: entrance.range[1]
      };

      if (prefix !== this.address.entrance.prefix) {
        entranceUpdateRequest.prefix = prefix;
        this.serviceFacade.updateServiceEntranceWithPrefix(this.address.entrance.id, entranceUpdateRequest);
      } else {
        this.serviceFacade.updateServiceEntrance(this.address.entrance.id, entranceUpdateRequest);
      }
    } else {
      this.serviceFacade.addServiceEntrance(entrance, prefix);
    }
  }

  onDelete() {
    this.serviceFacade.deleteServiceEntrance(this.addressId);
  }

  onAddRange(range: FlatRange, entranceId: number) {
    this.serviceFacade.addAdditionalFlatRange(entranceId, range);
  }

  onUpdateRange(rangeId: number, rangeToUpdate: FlatRange, entranceId: number) {
    this.serviceFacade.updateAdditionalFlatRange(entranceId, rangeId, rangeToUpdate);
  }

  onDeleteRange(rangeId: number, entranceId: number) {
    this.serviceFacade.deleteAdditionalFlatRange(entranceId, rangeId);
  }

  onBack() {
    this.currentMode = GateWizardContentEntrancesMode.LIST;
    this.address = null;
    this.addressId = null;
    this.changePageMode.emit({ default: true });
  }

  prepareInitialMode(): AddressInputMode {
    if (!this.address) {
      return AddressInputMode.CREATED;
    }

    return null;
  }
}
