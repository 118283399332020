import { createSelector } from '@ngrx/store';
import { SharedModuleStates } from '../../reducers/reducers';
import { sharedModuleSelector } from '../shared-module.state';

export const avaliableIntercomsState = createSelector(
  sharedModuleSelector,
  (state: SharedModuleStates) => state.availableIntercoms
);

export const avaliableIntercomsStateSuccess = createSelector(
  avaliableIntercomsState,
  state => ({ intercoms: state.availableIntercoms, totalCount: state.totalCount })
);

export const avaliableIntercomsStateLoading = createSelector(
  avaliableIntercomsState,
  state => state.loading
);

export const avaliableIntercomsStateError = createSelector(
  avaliableIntercomsState,
  state => state.error
);
