import {animate, keyframes, query, sequence, state, style, transition, trigger} from '@angular/animations';

const $timing = '400ms cubic-bezier(0.5,0.5,0.5,1.0)';

export const appearDisappear = trigger('appearDisappear', [
    transition(':enter', [
        style({transform: 'scale(0.22)', opacity: 0}),
        animate(280, style({transform: 'scale(1)', opacity: 1}))
    ]),
    transition(':leave', [
        style({transform: 'scale(1)', opacity: 1}),
        animate(250, style({transform: 'scale(0.75)', opacity: 0}))
    ])
]);

export const appearDisappearFast = trigger('appearDisappearFast', [
  transition(':enter', [
    style({transform: 'scale(0.1)', opacity: 0}),
    animate('0.2s cubic-bezier(0.25, 0.8, 0.25, 1)', style({transform: 'scale(1)', opacity: 1}))
  ]),
  transition(':leave', [
    style({transform: 'scale(1)', opacity: 1}),
    animate('0.1s cubic-bezier(0.55, 0, 0.55, 0.2)', style({transform: 'scale(0.75)', opacity: 0}))
  ])
]);

export const fadeInFadeOut = trigger('fadeInFadeOut', [
    transition(':enter', [
        style({opacity: 0}),
        animate(280, style({opacity: 1}))
    ]),
    transition(':leave', [
        style({opacity: 1}),
        animate(280, style({opacity: 0}))
    ])
]);

export const bounce = trigger('bounce', [
    transition('* => bouncing', [
        animate('300ms ease-in', keyframes([
            style({transform: 'translate3d(0,0,0)', offset: 0}),
            style({transform: 'translate3d(0,-10px,0)', offset: 0.5}),
            style({transform: 'translate3d(0,0,0)', offset: 1})
        ]))
    ])
]);

export const inflate = trigger('inflate', [
    transition(':enter', [
        style({
            opacity: '0',
            height: '0',
            transform: 'rotateX(110deg)'
        }),
        animate($timing, style('*'))
    ]),
    transition(':leave', [
        animate($timing, style({
            opacity: '0',
            height: '0',
            transform: 'rotateX(120deg)'
        }))
    ])
]);

export const fadeSlideInOut = trigger('fadeSlideInOut', [
    transition(':enter', [
        style({opacity: 0, transform: 'translateY(10px)'}),
        animate('500ms', style({opacity: 1, transform: 'translateY(0)'})),
    ]),
    transition(':leave', [
        animate('500ms', style({opacity: 0, transform: 'translateY(10px)'})),
    ]),
]);

export const fadeInGrow = trigger('fadeInGrow', [
    transition(':enter', [
        query(':enter', [
            style({opacity: 0, transform: 'scale(0.8)'}),
            sequence([
                animate('500ms', style({opacity: 1})),
                animate('200ms ease-in', style({transform: 'scale(1)'}))
            ]),
        ]),
        query(':leave',
            animate('400ms ease-in', style({ transform: 'scale(0.1)', opacity: 0 })),
            { optional: true }
        )
    ])
]);

export const openCloseHew = trigger('openCloseHew', [
  transition(':enter', [
    style({ transform: 'scaleY(0)', opacity: 0, overflow: 'hidden' }),
    animate('.4s ease-in-out', style({ transform: 'scaleY(1)', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ transform: 'scaleY(1)', opacity: 1, overflow: 'hidden' }),
    animate('.4s ease-in-out', style({ transform: 'scaleY(0)', opacity: 0 }))
  ]),
]);

export const openCloseH = trigger('openCloseH', [
  transition(':enter', [style({ height: 0, opacity: 0, overflow: 'hidden' }), animate('.3s ease-in-out', style({ height: '*', opacity: 1 }))]),
  transition(':leave', [style({ height: '*', opacity: 1, overflow: 'hidden' }), animate('.3s ease-in-out', style({ height: 0, opacity: 0 }))]),
]);

export const justFade = trigger('justFade', [
    transition('* <=> *', [
        query(':enter',
            [style({ opacity: 0 }), animate('300ms ease-in', style({ opacity: 1 }))],
            { optional: true }
        ),
        query(':leave',
            animate('300ms ease-in', style({ opacity: 0 })),
            { optional: true }
        )
    ])
]);

export const openClose = trigger('openClose', [
    transition(':enter', [
        style({height: 0, opacity: 0, overflow: 'hidden'}),
        animate('.3s ease-in-out', style({height: '*', opacity: 1}))
    ]),
    transition(':leave', [
        style({height: '*', opacity: 1, overflow: 'hidden'}),
        animate('.3s ease-in-out', style({height: 0, opacity: 0}))
    ]),
]);

export const fromOpacity = trigger('fromOpacity', [
    state('in', style({
        opacity: 1
    })),
    transition('void => *', [
        style({opacity: 0}),
        animate('1000ms')
    ]),
    transition('* => void', [
        style({opacity: 0}),
        animate('1000ms')
    ])
]);
