import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { ConfirmComponentConnectionModule, IntercomKeyDeleteContainerModule, IntercomKeysContainerModule } from '@app/shared/components';
import { DialogWrapperModule, InfoBlockModule } from '@app/shared/ui';
import { ServiceIntercomKeyPopupEditModule } from '../../popups';
import { ServiceIntercomKeysComponent } from './service-intercom-keys.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [
        CommonModule,
        IntercomKeysContainerModule,
        ServiceIntercomKeyPopupEditModule,
        IntercomKeyDeleteContainerModule,
        ConfirmComponentConnectionModule,
        InfoBlockModule,
        DialogWrapperModule,
        MaterialModule,
        NgxTranslateModule
    ],
    declarations: [ServiceIntercomKeysComponent],
    exports: [ServiceIntercomKeysComponent]
})
export class ServiceIntercomKeysModule { }
