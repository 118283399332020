import { Pipe, PipeTransform } from '@angular/core';
import { ServiceFlat } from '@app/views/services/components';

@Pipe({
    name: 'flatsNumbers',
    pure: false
})
export class FlatNumberPipe implements PipeTransform {
    transform(serviceFlats: ServiceFlat[]): string {
        let str = '';
        serviceFlats.forEach(serviceFlat => {
            if (serviceFlat && !serviceFlat.account?.owner?.phone) {
                str += ` ${serviceFlat.flatNumber},`;
            }
        });
        return str.slice(0, -1);
    }
}

