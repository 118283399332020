import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-info-table',
  templateUrl: './info-table.component.html',
  styleUrls: ['./info-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class InfoTableComponent implements AfterViewInit {
  readonly DEFAULT_WIDTH = '500px';
  readonly DEFAULT_TITLE_WIDTH = 'auto';
  readonly TITLE_WIDTH_PROPERTY_NAME = '--info-table-record__title-width';

  @Input() maxWidth = this.DEFAULT_WIDTH;
  @Input() set titleWidthInPx(titleWidthInPx: number) {
    this._titleWidth = titleWidthInPx ? `${titleWidthInPx}px` : this.DEFAULT_TITLE_WIDTH;
    this.infoTableDiv.nativeElement.style.setProperty(this.TITLE_WIDTH_PROPERTY_NAME, this._titleWidth);
  }

  @ViewChild('infoTableDiv', { static: true }) private infoTableDiv: ElementRef<HTMLDivElement>;
  private _titleWidth: string;

  ngAfterViewInit() {
    if (!this._titleWidth) {
      this.infoTableDiv.nativeElement.style.setProperty(this.TITLE_WIDTH_PROPERTY_NAME, this.DEFAULT_TITLE_WIDTH);
    }
  }
}
