import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RdeaDate } from '@app/shared/entities';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { PenetrationReportRequest } from '@app/shared/entities/rd';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-penetration-report-content',
  templateUrl: './penetration-report-content.component.html',
  styleUrls: ['./penetration-report-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PenetrationReportContentComponent implements OnInit {
  readonly ROSDOMOFON_COMPANY_ID = environment.ROSDOMOFON_COMPANY_ID;

  @Input() set loading(loading: boolean) {
    this._loading = loading;
    this.loading ? this.form.disable() : this.form.enable();
  }
  @Input() companyId: number;
  maxDate: Date = new Date();
  form: UntypedFormGroup = this.initForm();

  @Output() private submitContent: EventEmitter<{ request: PenetrationReportRequest }> = new EventEmitter();
  private _loading: boolean;

  constructor(
    public resolution: ResolutionService
  ) {
    this.maxDate = new Date(this.maxDate.setTime(this.maxDate.getTime() - RdeaDate.dayInMilliseconds));
  }

  ngOnInit() {
    if (this.companyId === this.ROSDOMOFON_COMPANY_ID) {
      this.form.addControl('servicesLimitDate', new UntypedFormControl(null));
    }
  }

  get loading(): boolean {
    return this._loading;
  }

  onSubmit() {
    const { firstDate, secondDate, thirdDate, servicesLimitDate }:
      { firstDate: Date, secondDate: Date, thirdDate: Date, servicesLimitDate: Date } = this.form.getRawValue();

    this.submitContent.emit({
      request: {
        firstDate: firstDate ? new RdeaDate(firstDate).toDateTimeString() : null,
        secondDate: secondDate ? new RdeaDate(secondDate).toDateTimeString() : null,
        thirdDate: thirdDate ? new RdeaDate(thirdDate).toDateTimeString() : null,
        servicesLimitDate: servicesLimitDate ? new RdeaDate(servicesLimitDate).toDateTimeString() : null
      }
    });
  }

  private initForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      firstDate: new UntypedFormControl(null),
      secondDate: new UntypedFormControl(null),
      thirdDate: new UntypedFormControl(null)
    });
  }
}
