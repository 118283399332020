import { OFFER_LICENSE_AGREEMENT_KZ, OFFER_LICENSE_AGREEMENT_RU } from "@app/shared/helpers";
import { environment } from "environments/environment";

export class ReportsUtils {
    static readonly OFFER_LICENSE_AGREEMENT = environment.kz ? OFFER_LICENSE_AGREEMENT_KZ : OFFER_LICENSE_AGREEMENT_RU;

    static downLoadFile(data: ArrayBuffer, fileName: string, type?: 'xlsx') {
        const blob: Blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
        const link: HTMLElement = document.createElement('a');
        link['href'] = window.URL.createObjectURL(blob);
        link['download'] = fileName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    static offerLicenseAgreement(licenseAgreement: string): boolean {
        if (!licenseAgreement) {
            return null;
        }

        if (licenseAgreement === this.OFFER_LICENSE_AGREEMENT) {
            return true;
        }

        return false;
    }
}
