<div class="popup service-block-physical-tube-popup">
  <ng-container [ngSwitch]="pageMode$ | async">
    <ng-container *ngSwitchCase="pageModes.CONFIRM">
      <app-info-block>
        <img
          class="service-block-physical-tube-popup-info__image"
          info-block-image
          [src]="resourcesHelper.getResource(resourcePath.INFO_LG) | safeUrl"
          [srcset]="resourcesHelper.getResource(resourcePath.INFO_LG, true) | safeUrl"
        >

        <ng-container info-block-text>
          {{ 'services.popups.block.physical.tube.content.message.confirm' | translate }}
        </ng-container>

        <ng-container *ngIf="text" info-block-secondary-text>
          <p class="info-block__secondary-text">
            {{text}}
          </p>
        </ng-container>
      </app-info-block>

      <div class="popup-actions">
        <button
          mat-raised-button
          color="error"
          class="popup-actions__button"
          (click)="onBlock()"
          [disabled]="loading || (abonentAndAccountsLoading$ | async)"
        >
        <ng-container *ngIf="abonentAndAccountsLoading$ | async; else buttonText">
          <app-component-loader [whiteCircle]="true"></app-component-loader>
        </ng-container>

        <ng-template #buttonText>
          {{ 'services.popups.block.physical.tube.content.button.block' | translate }}
        </ng-template>
        </button>
      </div>
    </ng-container>

    <app-account-selection
      *ngSwitchCase="pageModes.ACCOUNTS_SELECTION"
      [loading]="loading || (abonentAndAccountsLoading$ | async)"
      [abonent]="(abonentAndAccountsResponse$ | async).abonent"
      [accounts]="(abonentAndAccountsResponse$ | async).accounts"
      (accountSelected)="onAccountSelected($event.abonent, $event.account, $event.type)"
      (skipSelection)="onAccountSelected($event.abonent, $event.account, $event.type)"
    ></app-account-selection>
  </ng-container>
</div>
