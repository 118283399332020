<app-component-loader *ngIf="wait" [sizePx]="100"></app-component-loader>

<div class="unblock" *ngIf="{ payments: paymentData | BlockedPayments } as response">
  <ng-container *ngIf="!everythingIsPaid && !wait">
    <p class="unblock__header">
      {{ 'abonent.accounts.unblock.not.payment.account.header.1' | translate }}
    </p>
    <div class="unblock-payments">
      <ng-container *ngFor="let payment of response.payments">
        <div class="unblock-payments-card">
          <ul>
            <li>
              <div class="unblock-payments-card-info">
              <span class="unblock-payments-card-info__description">
                {{payment.paymentData.description}}
              </span>
                <span class="unblock-payments-card-info__date">
                 {{payment.createdAt | date : 'dd.MM.yyyy'}}
              </span>
                <span class="unblock-payments-card-info__price">
                <div class="unblock-payments-card-info__price-container">
                  <img src="assets/images/clock-sm.png" alt="Waiting">
                  <div class="unblock-payments-card-info__price-container-currency">
                    <span>
                      {{payment.paymentData.amount.value | currency : payment.paymentData.amount.currency : 'symbol-narrow'}}
                    </span>
                  </div>
                </div>
              </span>
              </div>
            </li>
          </ul>
        </div>
      </ng-container>
    </div>
    <div class="unblock-action">
      <button
        mat-raised-button
        class="unblock-action__close"
        color="primary-inverse"
      >
        {{ 'abonent.accounts.unblock.not.payment.account.button.cancel' | translate }}
      </button>
      <button
        mat-raised-button
        class="unblock-action__pay"
        color="primary"
        (click)="onPay()"
      >
        {{ 'abonent.accounts.unblock.not.payment.account.button.pay' | translate }}
      </button>
    </div>
  </ng-container>

  <ng-container *ngIf="everythingIsPaid && !wait">
    <div class="unblock-icon">
      <img src="assets/icons/ic-alert.svg" alt="alert">
    </div>
    <p class="unblock__header unblock__header-center">
      {{ 'abonent.accounts.unblock.not.payment.account.header.2' | translate }}
    </p>
    <div class="unblock-action">
      <button
        mat-raised-button
        class="unblock-action__close"
        color="primary-inverse"
        (click)="onClose()"
      >
        {{ 'abonent.accounts.unblock.not.payment.account.button.cancel' | translate }}
      </button>
      <button
        mat-raised-button
        class="unblock-action__unblock"
        (click)="onSubmit()"
      >
        {{ 'abonent.accounts.unblock.not.payment.account.button.submit' | translate }}
      </button>
    </div>
  </ng-container>
</div>
