<ng-container
  *ngIf="{
    company: company$ | async,
    loading: loading$ | async
  } as state">
  <ng-container *ngIf="state.company !== null">
    <section
      class="layout"
      *ngIf="state.loading === false">
      <div class="main">
        <!-- settings -->
        <app-company-settings
          *ngIf="!showSettingsEdit"
          (toggleEdit)="toggleSettingsEdit($event)"
          [company]="state.company"
          class="settings" />
        <app-company-settings-form
          *ngIf="showSettingsEdit"
          (toggleEdit)="toggleSettingsEdit($event)"
          [company]="state.company"
          class="settings" />

        <!-- tarifs -->
        <app-company-tarifs
          class="tarifs"
          [payment]="payment$ | async"
          [loading]="paymentLoading$ | async"
          [smWDownBreakpoint]="smWDownBreakpoint$ | async"
          [company]="state.company" />

        <!-- info -->
        <app-company-info
          class="card-container info"
          [company]="state.company" />
      </div>

      <div class="side">
        <!-- profile-->
        <app-company-profile
          [isBlocked]="state.company.isBlocked"
          [licenseeShort]="state.company.licenseeShort"
          [brandName]="state.company.brandName" />

        <!-- manager-->
        <mat-card class="card-container manager">
          <div class="header">
            <div class="title-block">
              {{ 'company.about.page.your_manager' | translate }}
            </div>
          </div>
          <mat-card-content class="manager-content">
            <div
              class="manager-item"
              *ngIf="state.company?.managerId; else noManager"
            >
              <span>{{ state.company.managerEmail }}</span>
              <a>{{ state.company.managerEmail }}</a>
            </div>
          </mat-card-content>
        </mat-card>

        <!-- contacts-->
        <app-company-contacts
          *ngIf="!showContactsEdit"
          (toggleEdit)="toggleContactsEdit($event)"
          [mails]="state.company.mailToList" />

        <app-company-contacts-edit
          *ngIf="showContactsEdit"
          (toggleEdit)="toggleContactsEdit($event)"
          [mails]="state.company.mailToList" />

        <!-- notifications-->
        <!-- Блок закоментирован до выяснения подробностей
          <app-company-notifications
          [delegationsNotification]="state.company.delegationsNotification"
          [camerasStatesNotification]="state.company.camerasStatesNotification"
          [adaptersStatesNotification]="
            state.company.adaptersStatesNotification
          "></app-company-notifications> -->

        <!-- links-->
        <app-company-links
          [appLink]="state.company.applicationLink"
        ></app-company-links>

        <!-- Docs-->
        <app-company-docs
          *ngIf="!environment.vivotec"
          [licenseAgreement]="
            state.company.licenseAgreement
          "></app-company-docs>
      </div>
    </section>
  </ng-container>

  <ng-container *ngIf="state.loading == true">
    <app-component-loader [sizePx]="60"></app-component-loader>
  </ng-container>
  <ng-container *ngIf="state.company == null">
    <app-company-profile></app-company-profile>
  </ng-container>

  <ng-template #noManager>
    {{ 'company.about.page.message.no_fixed_manager' | translate }}
  </ng-template>
</ng-container>
