import { GitlabComponentsVersions } from '@app/shared/entities/integrations/gitlab';
import { PbxOnRdaResponse } from '@app/shared/entities/rd/pbx-on-rda/models';
import { RdaResponse } from '@app/shared/entities/rd/rda';

export interface IntercomInfoContainerBody {
    intercom: RdaResponse;
    gitlabComponentsVersions?: GitlabComponentsVersions;
    isIpIntercom?: boolean;
    livenessUrl?: string;
    pbxOnRda?: PbxOnRdaResponse;
    rdosLatest?: boolean;
    rdaaLatest?: boolean;
}
