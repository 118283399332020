import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { finalize, mergeAll, takeUntil } from 'rxjs/operators';
import {
  BehaviorSubject,
  defer,
  EMPTY,
  forkJoin,
  from,
  Observable,
  Subject
} from 'rxjs';

import { DialogWrapperData } from '@app/shared/ui';
import { ServiceManagePopupService } from './service-manage-popup.service';
import {
  Account,
  ConnectionCreateRequest,
  ConnectionCreateResponse,
  ConnectionService,
  FlatApiService,
  FlatConnectionResponse,
  ServiceApiService,
  ServiceInfoResponse,
  ServiceResponse
} from '@app/shared/entities/rd';
import {
  AbonentConnectionToolStore,
  ServiceSelectedArgs,
  SnackbarService
} from '@app/shared/components';
import { Dictionary } from '@app/shared/helpers';
import {
  SelectedFlatsWithId,
  ServiceManagePopupModel
} from '@app/views/services/components/popups/connections/service-manage-popup/service-manage-popup.model';
import { Address } from '@app/shared/models';
import { ServiceFacade } from '@app/views/services';
import { ServiceFlat } from '../../../containers';
import {TranslateService} from '@ngx-translate/core';

const LOADING_SPINNER_CIRCLE_SIZE = 25;
const SIZE_OF_EACH_REQUEST_PACK: number = 3;
@Component({
  selector: 'app-service-flat-delete-popup',
  templateUrl: './service-manage-popup.component.html',
  styleUrls: ['./service-manage-popup.component.scss'],
  providers: [ServiceManagePopupService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServiceManagePopupComponent implements OnInit, OnDestroy {
  @Output() popupClose: EventEmitter<void> = new EventEmitter<void>();

  readonly loadingSpinnerCircleSize: number = LOADING_SPINNER_CIRCLE_SIZE;
  public destroy$: Subject<boolean> = new Subject<boolean>();
  public selectedFlats$: Observable<ConnectionCreateResponse>[];
  public services$: Observable<ServiceInfoResponse[]> = this.store.services$;
  public connectedServices$: Observable<Dictionary<boolean>> =
    this.store.connectedServices$;
  public popupState$: Observable<'loading' | 'close' | 'loaded'> =
    this.popupService.state$;
  public isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public flatList: ServiceFlat[] = this.data.body.flatList;
  public addresses: Address[] = this.data.body.addresses;
  public selectedFlatsObj: Dictionary<ServiceFlat[]> = this.data.body.flats;
  public hasPhysicalTube = false;
  public physicalTubeChanged = false;
  public hasHardwareIntercom = false;
  public flatsConnections: FlatConnectionResponse[][];

  public servicesToAdd: Pick<ServiceResponse, 'id' | 'type'>[];
  public servicesForDelete: Pick<ServiceResponse, 'id' | 'type'>[];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogWrapperData<ServiceManagePopupModel, void>,
    private dialogRef: MatDialogRef<ServiceManagePopupComponent>,
    private popupService: ServiceManagePopupService,
    private store: AbonentConnectionToolStore,
    private connectionService: ConnectionService,
    private serviceApi: ServiceApiService,
    private serviceFacade: ServiceFacade,
    private readonly flatApiService: FlatApiService,
    private readonly snackbar: SnackbarService,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.isLoading$.next(true);
    forkJoin(
      this.flatList.map((flat) =>
        flat.flat?.id ? this.flatApiService.getConnections(flat.flat.id) : EMPTY
      )
    )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res) => {
          this.isLoading$.next(false);
          this.flatsConnections = res;
        },
        complete: () => this.isLoading$.next(false)
      });

    const firstFlat = this.flatList.find((flat) => flat?.flat);
    const entranceId = firstFlat.flat.address.entrance.id;
    const flatId = firstFlat?.flat.id;

    if (this.flatList.length === 1 && flatId) {
      this.store.getFlatServices({
        entranceId,
        flatId,
        hasPhysicalTube: this.hasHardwareIntercom
      });
    } else {
      this.serviceApi
        .getEntranceServices(entranceId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((response) => {
          this.store.updateServices(response);
        });
      this.store.updateConnectedServices({
        connectedServices: {},
        hasHardwareIntercom: false
      });
      this.store.updateServicesLoading(false);
    }

    this.popupService.addStateChangeListener(
      (state: 'loading' | 'close' | 'loaded') =>
        state === 'close' && this.dialogRef.close()
    );
  }

  public ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  async onChangeServicesSelection(event: ServiceSelectedArgs) {
    this.servicesToAdd = event.servicesToAdd;
    this.servicesForDelete = event.servicesForDelete;
  }

  public async saveServices() {
    this.isLoading$.next(true);
    const selectedFlatsWithEntranceId: SelectedFlatsWithId[] = [];
    const entranceIds = Object.keys(this.selectedFlatsObj);
    for (let i = 0; i < entranceIds.length; i++) {
      entranceIds.map((id) =>
        this.selectedFlatsObj[id].forEach((selectedFlat) => {
          if (selectedFlat) {
            selectedFlatsWithEntranceId.push({
              id: +entranceIds[i],
              selectedFlat
            });
          }
        })
      );
    }
    this.selectedFlats$ = selectedFlatsWithEntranceId.map((flatObj) =>
      defer(() => {
        const request: Partial<ConnectionCreateRequest> = {
          entranceId: flatObj.id,
          flatId: flatObj.selectedFlat.flat?.id,
          flatNumber: flatObj.selectedFlat?.flatNumber,
          servicesForConnect: this.servicesToAdd,
          servicesForDelete: this.servicesForDelete,
          account: flatObj.selectedFlat?.account as Account,
          phone: flatObj.selectedFlat?.account?.owner?.phone,
          withoutPhoneNumber: !flatObj.selectedFlat?.account?.owner?.phone
        };
        if (this.physicalTubeChanged) {
          request.virtual = !this.hasPhysicalTube;
        }
        if (request.withoutPhoneNumber) {
          return this.connectionService.manageNoPhoneFlat(request);
        }
        return this.connectionService.manageConnections(request);
      })
    );

    from(this.selectedFlats$)
      .pipe(
        mergeAll(SIZE_OF_EACH_REQUEST_PACK),
        takeUntil(this.destroy$),
        finalize(async () => {
          this.store.updateSelectedServices(this.servicesToAdd);
          await this.serviceFacade.getConnectionsInit();
          this.isLoading$.next(false);
          this.snackbar.showMessage(
            this.translate.instant('services.popups.connections.manage.message.save_services.success'),
            'success'
          );
          this.data.submit();
          this.dialogRef.close();
        })
      )
      .subscribe();
  }

  public onChangePhysicalTube(checked: boolean): void {
    this.physicalTubeChanged = true;
    this.hasPhysicalTube = checked;
  }

  public get showInfo(): boolean {
    return this.flatList.some((flat) => flat && !flat.account?.owner?.phone);
  }

  public get ifAllFlatsNoPhone(): boolean {
    const entranceIds = Object.keys(this.selectedFlatsObj);

    for (let i = 0; i < entranceIds.length; i++) {
      const hasAccount = this.selectedFlatsObj[entranceIds[i]].some(
        (selectedFlat) => selectedFlat?.account
      );
      if (hasAccount) {
        return false;
      }
    }
    return true;
  }
}
