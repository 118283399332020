import {Component, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss']
})
export class LanguageSwitchComponent implements OnInit {

  public currentLang: string;

  private languages: Array<string> = [];

  constructor(
    private translate: TranslateService
  ) {
    this.currentLang = translate.currentLang;
  }

  ngOnInit() {
    this.languages = this.translate.getLangs();
  }

  public getLanguages(): Array<string> {
    return this.languages;
  }

  public setLanguage(): void {
      this.translate.use(this.currentLang);
  }
}
