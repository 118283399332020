import { Country } from '@app/shared/models';

export interface ConnectRdaToAddressRequest {
  country: Country;
  city: string;
  street: {
    name: string;
    codeFias: string;
    codeKladr: string;
    universalCode: string;
  };
  house: {
    number: string;
    block: string;
    building: string;
    housing: string;
  };
  entrance: {
    flatStart?: number;
    flatEnd?: number;
    number: string;
  };
}
