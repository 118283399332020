import { PreviosQueryType } from './previos-query-type.enum';

export interface PreviosQueryParams {
  query: string;
  page: number;
  address: string;
  status: string;
  type: PreviosQueryType;
  sort: string;
}
