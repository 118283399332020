import { Country } from '@app/shared/models';

export interface AddressInfo {
  country: Country;
  city: string;
  street: string;
  house: string;
  building: string;
  housing: string;
  block: string;
  fiasCode?: string;
  kladrCode?: string;
  universalCode?: string;
  rawAddress?: string;
}
