import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IntercomType } from '@app/shared/entities/rd';
import { UNSUPPORTED_INTERCOM_TYPE_LINK } from '@app/shared/helpers/constants';
import { GatesPageFacade } from '@app/views/services/submodules/gates/store';
import { GateWizardContentIntercomTypeService } from './gate-wizard-content-intercom-type.service';

@Component({
  selector: 'app-gate-wizard-content-intercom-type',
  templateUrl: './gate-wizard-content-intercom-type.component.html',
  styleUrls: ['./gate-wizard-content-intercom-type.component.scss'],
  providers: [GateWizardContentIntercomTypeService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GateWizardContentIntercomTypeComponent {
  readonly UNSUPPORTED_INTERCOM_TYPE_LINK = UNSUPPORTED_INTERCOM_TYPE_LINK;

  @Input() loading: boolean;
  @Input() disabled: boolean;
  @Input() intercomType: IntercomType;
  @Input() intercomTypes: IntercomType[];

  constructor(
    private gatesPageFacade: GatesPageFacade
  ) { }

  onSelectIntercomType(intercomTypeId: number) {
    this.gatesPageFacade.setIntercomType(
      this.intercomTypes.find(intercomType => intercomType.id === intercomTypeId)
    );
  }
}
