<ng-container [ngSwitch]="currentMode">
  <app-entrances-list-content
    *ngSwitchCase="mode.LIST"
    [addresses]="addresses"
    [loading]="loading"
    (addAddress)="onAddAddress()"
    (editAddress)="onEditAddress($event.addressIdx)"
    (deleteAddress)="onDeleteAddress($event.addressId)"
  ></app-entrances-list-content>

  <app-entrance-edit-container
    *ngSwitchCase="mode.EDIT"
    [address]="address"
    [showPrefix]="true"
    [label]="editContentLabel"
    [initialMode]="prepareInitialMode()"
    [loading]="loading"
    (createEntrance)="onAdd($event.entrance, $event.prefix)"
    (addRange)="onAddRange($event.range, $event.entranceId)"
    (updateRange)="onUpdateRange($event.rangeId, $event.range, $event.entranceId)"
    (deleteRange)="onDeleteRange($event.rangeId, $event.entranceId)"
  ></app-entrance-edit-container>

  <app-delete-entrance-content
    *ngSwitchCase="mode.DELETE"
    [loading]="loading"
    [entranceId]="addressId"
    (deleteEntrance)="onDelete()"
  ></app-delete-entrance-content>

  <button
    mat-fab
    (click)="onBack()"
    color="primary-inverse"
    *ngIf="currentMode === mode.EDIT || currentMode === mode.DELETE"
    class="gate-wizard-content-entrances__button gate-wizard-content-entrances__button--back"
  >
    <mat-icon>arrow_back</mat-icon>
  </button>
</ng-container>
