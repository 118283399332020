import { Pipe, PipeTransform } from '@angular/core';
import { RolesComponentStoreService } from '@app/views/company/pages/company-roles-page/roles-component-store.service';

@Pipe({
  name: 'nativeRole'
})
export class NativeRolePipe implements PipeTransform {
  constructor(private rolesComponentStoreService: RolesComponentStoreService) {}
  transform(value: string[]): string[] {
    return value.map(v => this.rolesComponentStoreService.rolesDictionary[v]).filter(v => v);
  }
}
