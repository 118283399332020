<app-dialog-wrapper
  [componentName]="data.componentName"
  [progress]="(popupState$ | async) === 'loading'"
  [title]="data.title"
>
  <app-camera-edit-content
    [camera]="data.body.camera"
    [keys]="keys$ | async"
    [rdas]="rdas$ | async"
    [addresses]="addresses$ | async"
    [locations]="camerasLocations$ | async"
    [onvif]="onvif$ | async"
    [loading]="(popupState$ | async) === 'loading'"
    [cameraAlreadyExists]="cameraAlreadyExists$ | async"
    (submitCamera)="onSubmit($event.camera)"
    (submitOnvif)="onSubmitOnvif($event.camera)"
    (close)="onClose()"
  ></app-camera-edit-content>
</app-dialog-wrapper>
