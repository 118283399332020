import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogWrapperData } from '@app/shared/ui';

export enum ActionType {
  block,
  unlock,
}

@Component({
  selector: 'app-company-block-user-popup',
  templateUrl: './company-block-user-popup.component.html',
  styleUrls: ['./company-block-user-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompanyBlockUserPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogWrapperData<{ email: string; type: ActionType }, boolean>,
    private dialogRef: MatDialogRef<CompanyBlockUserPopupComponent>
  ) {}

  public onSubmit(response: boolean): void {
    this.data.submit(response);
    this.dialogRef.close();
  }
}
