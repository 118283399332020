import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ServiceCreateRequest } from '@app/shared/entities/rd';
import { ServicesTypes } from '@app/shared/models';
import { DialogWrapperData, DialogWrapperService } from '@app/shared/ui/dialog-wrapper';

@Component({
  selector: 'app-create-service-popup',
  templateUrl: './create-service-popup.component.html',
  styleUrls: ['./create-service-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateServicePopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: DialogWrapperData<{ serviceType: ServicesTypes }, { request: ServiceCreateRequest }>,
    public dialogWrapperService: DialogWrapperService
  ) { }

  onCreateService(request: ServiceCreateRequest) {
    this.data.submit({ request });
  }
}
