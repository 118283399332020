import { GraylogMessage } from './graylog-message.model';

export interface GraylogResponse {
    built_query: string;
    decoration_stats: any;
    fields: string[];
    from: string;
    messages: GraylogMessage[];
    query: string;
    time: number;
    to: string;
    total_results: number;
    used_indices: UsedIndicator[];
}

interface UsedIndicator {
    begin: string;
    calculated_at: string;
    end: string;
    index_name: string;
    took_ms: number;
}
