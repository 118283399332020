import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';

interface Data {
  errorText: string;
  action: 'info' | 'success' | 'error';
  btnText: string;
  onDismiss: () => void;
}

@Component({
  selector: 'app-snackbar',
  styleUrls: ['./snackbar.component.scss'],
  templateUrl: './snackbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SnackbarComponent {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: Data) { }

  onDismiss() {
    this.data.onDismiss();
  }
}
