import { AdapterInfoForKeyResponse } from './adapter-info-for-key.response.model';
import { IntercomKeyRelay } from './intercom-key-relay.type';
import {ServiceResponse} from '@app/shared/entities/rd';

export interface KeysResponse {
    adapterId: string;
    id: number;
    location: {
        id: number,
        name: string
    };
    service?: ServiceResponse;
    adapterInfo: AdapterInfoForKeyResponse;
    releId: IntercomKeyRelay;
    type: number;
    createdAt: number;
    relay: IntercomKeyRelay;
    authUrl: string;
    openDoorUrl: string;
}
