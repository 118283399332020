import { Injectable } from '@angular/core';
import { CoreClearState, CoreGetActiveSignUpsCount, CoreGetActiveSignUpsCountSuccess, CoreGetCompanyPaymentInfo, CoreGetCompanyPaymentInfoSuccess, CoreGetUnresolvedIssuesCount, CoreGetUnresolvedIssuesCountSuccess } from '@app/core/store/core.actions';
import { coreSelector } from '@app/core/store/core.selectors';
import { LocalStorageCoreKey, LocalStorageHelper, RdeaDate } from '@app/shared/entities/common';
import { ChangePasswordRequest, CompanyUtilsService, LicenseeReportRequest } from '@app/shared/entities/rd';
import { State } from '@app/store/reducers';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { CoreChangePassword, CoreGetLicenseeReport, CoreGetUsageStatisticsReport } from './core.actions';

@Injectable()
export class CoreFacade {
    public readonly activeSugnUpsCount$: Observable<number> = this.store.select(coreSelector.activeSignUpsCountState);
    public readonly unresolvedIssuesCountState$: Observable<number> = this.store.select(coreSelector.unresolvedIssuesCountState);
    public readonly paymentValueState$: Observable<number> = this.store.select(coreSelector.paymentValueState);
    public readonly paymentLoadingState$: Observable<boolean> = this.store.select(coreSelector.paymentLoadingState);
    public readonly activeSignUpsState$: Observable<number> = this.store.select(coreSelector.activeSignUpsState);
    public readonly unresolvedIssuesState$: Observable<number> = this.store.select(coreSelector.unresolvedIssuesState);

    constructor(
        private store: Store<State>,
        private companyUtilsService: CompanyUtilsService
    ) { }

    getActiveSignUpsCount() {
        this.store.dispatch(new CoreGetActiveSignUpsCount());
    }

    getUnresolvedIssuesCount() {
        this.store.dispatch(new CoreGetUnresolvedIssuesCount());
    }

    clearStore() {
        this.store.dispatch(new CoreClearState());
    }

    getPaymentInfo(date: RdeaDate) {
        if (this.companyUtilsService.canGetPaymentInfo()) {
            this.store.dispatch(new CoreGetCompanyPaymentInfo(date));
        }
    }

    getLicenseeReport(request: LicenseeReportRequest) {
        this.store.dispatch(new CoreGetLicenseeReport(request));
    }

    getUsageStatisticsReport(request: LicenseeReportRequest) {
        this.store.dispatch(new CoreGetUsageStatisticsReport(request));
    }

    changePassword(request: ChangePasswordRequest) {
        this.store.dispatch(new CoreChangePassword(request));
    }

    addValueToLocalStorage(key: LocalStorageCoreKey, value: any) {
        LocalStorageHelper.setItem(key, value);
    }

    deleteValueFromLocalStorage(key: LocalStorageCoreKey) {
        LocalStorageHelper.deleteItem(key);
    }

    loadValuesFromLocalStorage() {
        Object.keys(LocalStorageCoreKey).forEach(key => {
            const value = LocalStorageHelper.getItem(LocalStorageCoreKey[key]);

            switch (LocalStorageCoreKey[key]) {
                case LocalStorageCoreKey.ACTIVE_SIGNUPS:
                    this.store.dispatch(new CoreGetActiveSignUpsCountSuccess(value));
                    break;
                case LocalStorageCoreKey.UNRESOLVED_ISSUES:
                    this.store.dispatch(new CoreGetUnresolvedIssuesCountSuccess(value));
                    break;
                case LocalStorageCoreKey.PAYMENT_INFO:
                    this.store.dispatch(new CoreGetCompanyPaymentInfoSuccess(value));
                    break;
            }
        });
    }
}
