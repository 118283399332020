import { IntercomsListContentModel } from '@app/shared/components';

export interface IntercomsListContentState {
    selectedAll: boolean;
    intercoms: IntercomsListContentModel[];
}

export const initialIntercomsListContentState = {
    selectedAll: null,
    intercoms: []
};
