import { Injectable } from '@angular/core';
import { RdeaDate, RdeaDateCompareResult } from '@app/shared/entities';
import {
  CronDate,
  CronExpression,
  CronFields,
  DayOfTheMonthRange,
  fieldsToExpression,
  HourRange,
  parseExpression,
  SixtyRange
} from 'cron-parser';

import { CropDateInputParsedExpresstion } from '../models';
import { UntypedFormGroup } from '@angular/forms';
import { SnackbarService } from '@app/shared/components';

export interface IPaymentFormModel {
  apiKey: string;
  shopId: string;
  invoicingDate: string;
  reminingDate: string;
  blockingDate: string;
  paymentPeriodShift: string;
  isRecurringEnabled: boolean;
}

export const DEFAULT_POST_PAYMENT = {
  invoicingDate: '0 0 0 20 * ?',
  reminingDate: '0 0 0 25 * ?',
  blockingDate: '0 0 0 L * ?'
};
export const DEFAULT_PRE_PAYMENT = {
  invoicingDate: '0 0 0 20 * ?',
  reminingDate: '0 0 0 25 * ?',
  blockingDate: '0 0 0 1 * ?'
};

@Injectable()
export class CronDateInputService {

  constructor(private snackbar: SnackbarService) {}

  public getDateFromCronFormat(cron: string, type: 'day' | 'hours' | 'minutes'): number {
    switch (type) {
      case 'day':
        return +cron?.split(' ')[3];
      case 'hours':
        return +cron?.split(' ')[2];
      case 'minutes':
        return +cron?.split(' ')[1];
      default: return null;
    }
  }

  public getLastDayOfMonth(year = new Date().getFullYear(), month = new Date().getMonth() + 1): number {
    return new Date(year, month, 0).getDate();
  }

  public convertExpression(
    time: string,
    day: DayOfTheMonthRange
  ): string | null {
    if (!time || day === null) {
      return null;
    }

    const [hours, minutes] = time.split(':');

    const cronFields: CronFields = {
      second: [0],
      minute: [Number.parseInt(minutes) as SixtyRange],
      hour: [Number.parseInt(hours) as HourRange],
      dayOfMonth: [day],
      month: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      dayOfWeek: [0, 1, 2, 3, 4, 5, 6, 7]
    };

    try {
      return this.convertToServerFormat(
        fieldsToExpression(cronFields).stringify()
      );
    } catch (error) {
      console.error(`--- convertExpression error: ${error}`);
    }

    return null;
  }

  public parseExpression(
    cronExpression: string
  ): CropDateInputParsedExpresstion | null {
    if (!cronExpression) {
      return null;
    }

    cronExpression = this.convertToClientFormat(cronExpression);

    const interval: CronExpression = parseExpression(cronExpression);
    const fields: CronFields = JSON.parse(JSON.stringify(interval.fields));

    return {
      day: fields.dayOfMonth[0],
      hour: fields.hour[0],
      minute: fields.minute[0]
    };
  }

  public getLocalDate(cronExpression: string): RdeaDate | null {
    if (!cronExpression) {
      return null;
    }

    cronExpression = this.convertToClientFormat(cronExpression);

    const interval: CronExpression = parseExpression(cronExpression, {utc: true});
    const cronDate: CronDate = interval.next();

    return new RdeaDate(cronDate.getTime());
  }

  public incorrectInvoicingDate(invoicingDate: CronDate): boolean {
    const compareResult: RdeaDateCompareResult = RdeaDate.compareDates(
      new RdeaDate(invoicingDate.getTime()),
      new RdeaDate(),
      { until: 'minutes' }
    );

    return compareResult === 0;
  }

  public incorrectBlockingDate(
    invoicingDate: CronDate,
    blockingDate: CronDate
  ): boolean {
    const compareResult: RdeaDateCompareResult = RdeaDate.compareDates(
      new RdeaDate(invoicingDate.getTime()),
      new RdeaDate(blockingDate.getTime()),
      { until: 'minutes' }
    );

    return compareResult === 0;
  }

  public extractCronDate(cronExpression: string): CronDate | null {
    cronExpression = this.convertToClientFormat(cronExpression);

    if (!cronExpression) {
      return null;
    }

    const interval: CronExpression = parseExpression(cronExpression);
    return interval.next();
  }

  /**
   *
   * @param cronExpression cron expression in format 'ss hh DD MM dd yy' where
   * ss - seconds,
   * mm - minutes,
   * hh - hours,
   * DD - day of month,
   * MM - month,
   * dd - day of week,
   * yy - year (optional)
   * @returns cron expression in format 'hh DD MM dd' where
   * mm - minutes,
   * hh - hours,
   * DD - day of month,
   * MM - month,
   * dd - day of week
   */
  private convertToClientFormat(cronExpression: string): string | null {
    if (!cronExpression) {
      return null;
    }

    cronExpression = cronExpression.slice(2);

    if (cronExpression.split(' ').length === 6) {
      cronExpression = cronExpression.slice(0, -2);
    }

    return cronExpression;
  }

  /**
   *
   * @param cronExpression cron expression in format 'hh DD MM dd' where
   * mm - minutes,
   * hh - hours,
   * DD - day of month,
   * MM - month,
   * dd - day of week
   * @returns cron expression in format 'ss hh DD MM dd yy' where
   * ss - seconds,
   * mm - minutes,
   * hh - hours,
   * DD - day of month,
   * MM - month,
   * dd - day of week with * replaced to ? for server format,
   * yy - year (optional)
   */
  private convertToServerFormat(cronExpression: string): string | null {
    if (!cronExpression) {
      return null;
    }

    return `0 ${cronExpression.slice(0, -2)} ?`;
  }

  public prepareCronErrors(values: IPaymentFormModel, paymentPeriodShift: boolean): string[] {
    const errorMessages = [];

    const invoicingDate: CronDate | null = this.extractCronDate(
      values.invoicingDate
    );

    if (invoicingDate !== null) {
      const invoicing = this.getDateFromCronFormat(values.invoicingDate, 'day');
      const blocking = this.getDateFromCronFormat(values.blockingDate, 'day');
      const remining = this.getDateFromCronFormat(values.reminingDate, 'day');
      const invoicingDateH = this.getDateFromCronFormat(values.invoicingDate, 'hours');
      const blockingDateH = this.getDateFromCronFormat(values.blockingDate, 'hours');
      const reminingDateH = this.getDateFromCronFormat(values.reminingDate, 'hours');
      if (!paymentPeriodShift) {
        if (values.reminingDate?.includes('L') && invoicing === 28) {
          errorMessages.push(
            'Число выставления счета не может быть равно или больше числа напоминаяния счёта!'
          );
        }
        if (
          invoicing >= blocking ||
          values.invoicingDate?.includes('L')
        ) {
          errorMessages.push(
            'Число выставления счета не может быть больше числа блокировки счета!'
          );
        }
        if (
          remining <= invoicing ||
          remining >= blocking ||
          (values.reminingDate?.includes('L') && !values.invoicingDate?.includes('L')) ||
          values.blockingDate?.includes('L') && remining === 28
        ) {
          errorMessages.push(
            'Число напоминания оплаты должно быть больше числа выставления счета, но меньше числа блокировки!'
          );
        }
      } else {
        if (
          blocking === invoicing &&
          invoicingDateH <= blockingDateH
        ) {
          errorMessages.push(
            'Время блокировки счёта не может быть больше и равно времени выставления счёта!'
          );
        }
        if (
          blocking === invoicing &&
          (
            invoicingDateH - blockingDateH < 6
          )
        ) {
          errorMessages.push(
            'Обязательная разница между датами для выставления счёта, уведомления и блокировки должна быть не менее 6 часов!'
          );
        }
        if (values.reminingDate?.includes('L') && invoicing === 28) {
          errorMessages.push(
            'Число выставления счета не может быть равно или больше числа напоминаяния счёта!'
          );
        }
        if (invoicing < blocking || values.blockingDate?.includes('L')) {
          errorMessages.push(
            'Число выставления счета не может быть меньше числа блокировки счета!'
          );
        }
        if (remining <= invoicing || remining <= blocking || values.invoicingDate?.includes('L')) {
          errorMessages.push(
            'Число напоминания оплаты должно быть больше числа выставления счета и больше числа блокировки!'
          );
        }
      }

      const timeConfiguration = this.correctTimeConfiguration(
        { day: invoicing, hour: invoicingDateH },
        { day: remining, hour: reminingDateH },
        { day: blocking, hour: blockingDateH }
      );
      if (timeConfiguration) {
        errorMessages.push(
          timeConfiguration
        );
      }

      if (this.incorrectInvoicingDate(invoicingDate)) {
        errorMessages.push(
          'День и время выставления счета не могут быть равны текущему дню и времени!'
        );
      }
    }

    return errorMessages;
  }

  public checkErrorAfterSubmit(cronErrorsExists: boolean, state: { form: UntypedFormGroup; fieldsToChecking: Array<string> }): boolean {
    if (cronErrorsExists) {
      this.snackbar.showMessage(
        'Укажите корректные дни и время задач по расписанию',
        'info'
      );
      return true;
    }

    if (!state.form.valid) {
      if (state.fieldsToChecking.some(field => state.form.get(field).invalid)) {
        this.snackbar.showMessage('Укажите корректные даты', 'info');
        return true;
      }

      this.snackbar.showMessage('Укажите все данные', 'info');
      return true;
    }
  }

  protected correctTimeConfiguration(
    invoicing: { day: number, hour: number },
    remining: { day: number, hour: number },
    blocking: { day: number, hour: number }
  ): string | null {
    if (
      invoicing.day - blocking.day === 1 &&
      (24 - invoicing.hour + blocking.hour < 6 || 24 - blocking.hour + invoicing.hour < 6)
    ) {
      return 'Разница между датой создания и датой блокировки должна быть не менее 6-ти часов!';
    }
    if (
      remining.day - invoicing.day === 1 &&
      (24 - remining.hour + invoicing.hour < 6 || 24 - invoicing.hour + remining.hour < 6)
    ) {
      return 'Разница между датой напоминания и датой создания должна быть не менее 6-ти часов!';
    }
    if (
      blocking.day - remining.day === 1 &&
      (24 - blocking.hour + remining.hour < 6 || 24 - remining.hour + blocking.hour < 6)
    ) {
      return 'Разница между датой блокировки и датой напоминания должна быть не менее 6-ти часов!';
    }
    return null;
  }
}
