import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes/shared-pipes.module';
import { StoreModule } from '@ngrx/store';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { IntercomUpdateToolComponent } from './intercom-update-tool.component';
import { IntercomUpdateToolHelper } from './intercom-update-tool.helper';
import { intercomUpdateToolReducers } from './store';
import { IntercomUpdateToolFacade } from './store/intercom-update-tool.facade';
import {NgxTranslateModule} from '@app/translate/translate.module';

const components = [
    IntercomUpdateToolComponent
];

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        MaterialModule,
        ClipboardModule,
        SharedPipesModule,
        ReactiveFormsModule,
        NgxMatTimepickerModule,
        NgxMatNativeDateModule,
        NgxSkeletonLoaderModule,
        NgxMatDatetimePickerModule,
        StoreModule.forFeature('intercom-update-tool', intercomUpdateToolReducers),
        NgxTranslateModule
    ],
    providers: [IntercomUpdateToolFacade, IntercomUpdateToolHelper],
    declarations: components,
    exports: components
})
export class IntercomUpdateToolModule { }
