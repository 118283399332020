import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { SnackbarService } from '@app/shared/components/snackbar';
import { ResolutionService } from '@app/shared/entities/common/mobile-query';
import { PbxOnRdaQuery, PbxOnRdaRequest, PbxOnRdaResponse } from '@app/shared/entities/rd/pbx-on-rda/models';
import { Constants } from '@app/shared/helpers';
import { LoaderService } from '@app/shared/entities/common/loader';
import { DialogWrapperData } from '@app/shared/ui/dialog-wrapper/dialog-wrapper-data.model';
import { PbxOnRdaPageResponse } from '@app/views/pbx-on-rda/models';
import { PbxOnRdaListPageFacade } from '@app/views/pbx-on-rda/store';
import { PbxOnRdaPageActionTypes } from '@app/views/pbx-on-rda/store/pbx-on-rda.actions';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, skip, takeUntil } from 'rxjs/operators';
import { CreatePbxOnRdaFormComponent, DeletePbxOnRdaContentComponent, PbxOnRdaInfoContentComponent } from './containers';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-pbx-on-rda-list-page',
  templateUrl: './pbx-on-rda-list-page.component.html',
  styleUrls: ['./pbx-on-rda-list-page.component.scss']
})
export class PbxOnRdaListPageComponent implements OnInit, OnDestroy {
  readonly pageSize = Constants.PAGE_SIZE;
  readonly displayedColumns: string[] = ['pbxOnRdaUid', 'uid', 'ip', 'dns', 'actions'];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  filterInput: UntypedFormControl = new UntypedFormControl();
  pbxOnRdaList$: Observable<PbxOnRdaPageResponse> = this.pbxOnRdaListPageFacade.pbxOnRdaListPage$;

  private onDestroy$: Subject<void> = new Subject();
  private dialogRef: MatDialogRef<any>;

  constructor(
    public resolution: ResolutionService,
    private dialog: MatDialog,
    private loader: LoaderService,
    private snackbar: SnackbarService,
    private pbxOnRdaListPageFacade: PbxOnRdaListPageFacade,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.initFilter();
    this.initPagination();
    this.initSelectors();
    this.loadPbxList(0);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onCreate() {
    const data: DialogWrapperData<null, PbxOnRdaRequest> = {
      title: this.translate.instant('pbx-on-rda.create.title'),
      componentName: 'PbxOnRdaCreate',
      submit: (request: PbxOnRdaRequest) => {
        this.loader.loaderState = { state: true, type: 'component' };
        this.pbxOnRdaListPageFacade.create(request);
      }
    };

    this.dialogRef = this.dialog.open(CreatePbxOnRdaFormComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  onEdit(pbxOnRda: PbxOnRdaResponse) {
    const data: DialogWrapperData<PbxOnRdaResponse, PbxOnRdaRequest> = {
      title: this.translate.instant('pbx-on-rda.edit.title'),
      componentName: 'PbxOnRdaEdit',
      body: pbxOnRda,
      submit: (event: PbxOnRdaRequest) => {
        this.loader.loaderState = { state: true, type: 'component' };
        this.pbxOnRdaListPageFacade.update(pbxOnRda.pbxOnRdaUid, event);
      }
    };

    this.dialog.open(CreatePbxOnRdaFormComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  onDelete(pbxOnRda: PbxOnRdaResponse) {
    const data: DialogWrapperData<PbxOnRdaResponse, boolean> = {
      title: this.translate.instant('pbx-on-rda.delete.title'),
      componentName: 'PbxOnRdaDelete',
      body: pbxOnRda,
      submit: (event: boolean) => {
        if (event) {
          this.loader.loaderState = { state: true, type: 'component' };
          this.pbxOnRdaListPageFacade.delete(pbxOnRda.pbxOnRdaUid);
        }
      }
    };

    this.dialogRef = this.dialog.open(DeletePbxOnRdaContentComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      data
    });
  }

  onInfo(pbxOnRda: PbxOnRdaResponse) {
    const data: DialogWrapperData<PbxOnRdaResponse, null> = {
      title: this.translate.instant('pbx-on-rda.info.title'),
      componentName: 'PbxOnRdaInfo',
      body: pbxOnRda
    };

    this.dialog.open(PbxOnRdaInfoContentComponent, {
      panelClass: Constants.CUSTOM_DIALOG_CLASS,
      width: this.resolution.isMobile ? '100%' : '500px',
      data
    });
  }

  private initFilter() {
    this.filterInput.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.paginator.pageIndex = 0;
        this.loadPbxList(0);
      });
  }

  private initSelectors() {
    this.pbxOnRdaListPageFacade.loading$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((value: boolean) => this.loader.loaderState = { state: value });

    this.pbxOnRdaListPageFacade.pbxOnRdaListPageState$
      .pipe(takeUntil(this.onDestroy$), skip(1))
      .subscribe(response => {
        if (!response.loading) {
          if (response.error) {
            let message = '';

            switch (response.actions) {
              case PbxOnRdaPageActionTypes.GetPbxOnRdaPage:
                message = this.translate.instant('pbx-on-rda.message.get.error');
                break;
              case PbxOnRdaPageActionTypes.CreatePbxOnRdaPage:
                message = this.translate.instant('pbx-on-rda.message.create.error');
                break;
              case PbxOnRdaPageActionTypes.UpdatePbxOnRdaPage:
                message = this.translate.instant('pbx-on-rda.message.update.error');
                break;
              case PbxOnRdaPageActionTypes.DeletePbxOnRdaPage:
                message = this.translate.instant('pbx-on-rda.message.delete.error');
                break;
            }

            if (message) {
              this.snackbar.showMessage(`${message}: ${response.error}`);
            }

          } else {
            let message = '';

            switch (response.actions) {
              case PbxOnRdaPageActionTypes.GetPbxOnRdaPage:
                break;
              case PbxOnRdaPageActionTypes.CreatePbxOnRdaPage:
                if (this.dialogRef) { this.dialogRef.close(); }
                this.pbxOnRdaListPageFacade.loadPage(0);
                message = this.translate.instant('pbx-on-rda.message.create.success');
                break;
              case PbxOnRdaPageActionTypes.UpdatePbxOnRdaPage:
                message = this.translate.instant('pbx-on-rda.message.update.success');
                break;
              case PbxOnRdaPageActionTypes.DeletePbxOnRdaPage:
                if (this.dialogRef) { this.dialogRef.close(); }
                message = this.translate.instant('pbx-on-rda.message.delete.success');
                this.pbxOnRdaListPageFacade.loadPage(0);
                break;
            }

            if (message) {
              this.snackbar.showMessage(message, 'success');
            }
          }
        }
      });
  }

  private initPagination() {
    this.paginator.page
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event: PageEvent) => this.loadPbxList(event.pageIndex));
  }

  private loadPbxList(page: number) {
    const query: PbxOnRdaQuery = { rdaUid: this.filterInput.value };
    this.pbxOnRdaListPageFacade.loadPage(page, this.pageSize, query);
  }
}
