import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { KeysResponse, RdaResponse } from '@app/shared/entities/rd';

@Component({
  selector: 'app-rda-keys-card',
  templateUrl: './rda-keys-card.component.html',
  styleUrls: ['./rda-keys-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RdaKeysCardComponent {
  @Input() rda!: RdaResponse;
  @Input() keys!: KeysResponse[];
  @Input() loading: boolean;
}
