import { HttpErrorResponse } from '@angular/common/http';
import { ShieldModel } from '@app/views/intercom/models';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  GetShieldModels = '[Intercom] GetShieldModels',
  GetShieldModelsSuccess = '[Intercom] GetShieldModels Success',
  GetShieldModelsFailure = '[Intercom] GetShieldModels Failure',
  GetShieldModelsClear = '[Intercom] GetShieldModels Clear',

  CreateShieldModel = '[Intercom] CreateShieldModel',
  CreateShieldModelSuccess = '[Intercom] CreateShieldModel Success',
  CreateShieldModelFailure = '[Intercom] CreateShieldModel Failure',
  CreateShieldModelClear = '[Intercom] CreateShieldModel Clear',
}

export class GetShieldModels implements Action {
  readonly type = ActionTypes.GetShieldModels;

  constructor() { }
}

export class GetShieldModelsSuccess implements Action {
  readonly type = ActionTypes.GetShieldModelsSuccess;

  constructor(public payload: ShieldModel[]) { }
}

export class GetShieldModelsFailure implements Action {
  readonly type = ActionTypes.GetShieldModelsFailure;

  constructor(public payload: string) { }
}

export class GetShieldModelsClear implements Action {
  readonly type = ActionTypes.GetShieldModelsClear;

  constructor() { }
}

export class CreateShieldModel implements Action {
  readonly type = ActionTypes.CreateShieldModel;

  constructor(public name: string) { }
}

export class CreateShieldModelSuccess implements Action {
  readonly type = ActionTypes.CreateShieldModelSuccess;

  constructor() { }
}

export class CreateShieldModelFailure implements Action {
  readonly type = ActionTypes.CreateShieldModelFailure;

  constructor(public error: HttpErrorResponse) { }
}

export class CreateShieldModelClear implements Action {
  readonly type = ActionTypes.CreateShieldModelClear;

  constructor() { }
}

export type Actions =
  | GetShieldModels
  | GetShieldModelsSuccess
  | GetShieldModelsFailure
  | GetShieldModelsClear
  | CreateShieldModel
  | CreateShieldModelSuccess
  | CreateShieldModelFailure
  | CreateShieldModelClear;
