import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { FlatTariffModule } from '../flat-tariff';
import { FlatServiceComponent } from './flat-service.component';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
    imports: [CommonModule, MaterialModule, SharedPipesModule, FlatTariffModule, NgxTranslateModule],
  declarations: [FlatServiceComponent],
  exports: [FlatServiceComponent]
})
export class FlatServiceModule {}
