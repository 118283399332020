<mat-card class="card-container service-activity">
  <mat-card-header class="header service-activity-header">
    <div class="title-block">
      <mat-card-title>
        {{ 'service.activity.title' | translate }}
      </mat-card-title>
    </div>

    <div class="service-activity-header-actions">
      <button
        mat-raised-button
        color="primary-inverse"
        (click)="isUpdate ? onStopUpdate() : onStartUpdate()"
        [disabled]="!canBeUpdated()"
      >
        <mat-icon *ngIf="!isUpdate; else timerBlock">play_arrow</mat-icon>
        <ng-template #timerBlock>{{ 10 - currentSeconds % 10 }}</ng-template>
      </button>

      <button
        mat-raised-button
        color="primary"
        (click)="onRefresh()"
        [disabled]="!canBeUpdated()"
      >
        <mat-icon>refresh</mat-icon>
      </button>

      <mat-form-field
        appearance="outline"
        class="none-padding none-margin mat-form-field-small"
      >
        <mat-select
          [(ngModel)]="timeRange"
          (selectionChange)="onChangeTimeRange($event.value)"
          [disabled]="!sources?.length"
        >
          <mat-option
            [value]="timeRange.value"
            *ngFor="let timeRange of timeRanges"
          >{{ timeRange.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-card-header>

  <hr>

  <mat-card-content class="service-activity-content">
    <ng-container *ngIf="sources?.length > 0; else noSources">
      <div
        *ngIf="showComponentTypesChips()"
        class="service-activity-content-options"
      >
        <mat-chip-list class="service-activity-content-options-sources">
          <mat-chip
            *ngIf="rdasSources?.length > 0"
            [color]="getChipColor(rdasSources, componentTypes.RDA)"
            [selected]="componentType === componentTypes.RDA"
            (click)="onChangeComponentType(componentTypes.RDA)"
          >
            {{ getIntercomLabel() }}
          </mat-chip>

          <mat-chip
            *ngIf="camerasSources?.length > 0"
            [color]="getChipColor(camerasSources, componentTypes.CAMERA)"
            [selected]="componentType === componentTypes.CAMERA"
            (click)="onChangeComponentType(componentTypes.CAMERA)"
          >
            {{ 'service.activity.field.cameras' | translate }}
          </mat-chip>
        </mat-chip-list>
      </div>

      <app-service-active-history-chart
        [title]="getIntercomLabel()"
        [sources]="rdasSources"
        [blocksCount]="blocksCount"
        [activeHistory]="activeHistoryIntercoms"
        (reloadChart)="onChangeChartStep($event.blocksCount)"
        *ngIf="showIntercomsChart()"
      ></app-service-active-history-chart>

      <app-service-active-history-chart
        title="{{ 'service.activity.history.chart.title' | translate }}"
        [blocksCount]="blocksCount"
        [sources]="camerasSources"
        [activeHistory]="activeHistoryCameras"
        (reloadChart)="onChangeChartStep($event.blocksCount)"
        *ngIf="showCamerasChart()"
      ></app-service-active-history-chart>

      <app-service-logs
        [loading]="logsLoading"
        [selectedSource]="selectedLogsSource"
        [logs]="logs"
        [extendedMode]="extendedMode"
        [sources]="filterSources(componentType)"
        (changeLogsMode)="onChangeExtendedMode($event.extendedMode)"
        (sourceSelected)="onSelectSource($event.source)"
        (scrolledToBottom)="onLoadLogsNextPage()"
      ></app-service-logs>
    </ng-container>

    <ng-template #noSources>
      <app-info-block>
        <ng-container info-block-text>
          {{ showEmptySourcesMessage() }}
        </ng-container>
      </app-info-block>
    </ng-template>
  </mat-card-content>
</mat-card>
