import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogWrapperData } from '@app/shared/ui';

@Component({
  selector: 'app-service-unblock-account-popup',
  templateUrl: './service-unblock-account-popup.component.html',
  styleUrls: ['./service-unblock-account-popup.component.scss']
})
export class ServiceUnblockAccountPopupComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public wrapperData: DialogWrapperData<number, void>,
    protected dialogRef: MatDialogRef<ServiceUnblockAccountPopupComponent>) { }

  public onClose(): void {
    this.dialogRef.close();
  }
}
