import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ShieldModel } from '@app/views/intercom/models';
import { GetRdaModels, GetShieldModels } from '@app/views/intercom/store/actions';
import { getRdaModelsSuccess, getShieldModelsSuccess } from '@app/views/intercom/store/states';
import { RdaModel } from '@app/shared/entities/rd/rda';
import { State } from '@app/store/reducers';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { modelFormGroup } from './model-list.model';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-model-list',
  templateUrl: './model-list.component.html',
  styleUrls: ['./model-list.component.scss']
})
export class ModelListComponent implements OnInit, OnDestroy {
  @Input() model: string;
  @Output() handleModelSelected: EventEmitter<{ modelId: number }>;
  title: string;
  form: UntypedFormGroup;
  filteredModels: Observable<ShieldModel[] | RdaModel[]>;
  private models: ShieldModel[] | RdaModel[];
  private onDestroy$: Subject<void>;

  constructor(
    private store: Store<State>,
    private translate: TranslateService
  ) {
    this.handleModelSelected = new EventEmitter<{ modelId: number }>();
  }

  ngOnInit() {
    this.initForm();
    this.initStore();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  onSelectModel(event: MatSelectChange) {
    this.handleModelSelected.emit({ modelId: event.value });
  }

  onOpenedChange(isOpened) {
    if (!isOpened) {
      this.form.get('filter').setValue('');
    }
  }

  private initStore() {
    this.onDestroy$ = new Subject();
    this.initStoreListeners();
    this.getStoreData();
  }

  private initStoreListeners() {
    if (this.model === 'rda') {
      this.store.pipe(select(getRdaModelsSuccess))
        .subscribe(data => {
          if (data) {
            this.models = data as RdaModel[];
            this.initFilter();
          }
        });
    } else if (this.model === 'shield') {
      this.store.pipe(select(getShieldModelsSuccess))
        .subscribe(data => {
          if (data) {
            this.models = data as ShieldModel[];
            this.initFilter();
          }
        });
    }
  }

  private getStoreData() {
    if (this.model === 'rda') {
      this.title = this.translate.instant('intercom.batches.page.add.batch.form.list.title.rda');
      this.store.dispatch(new GetRdaModels());
    } else if (this.model === 'shield') {
      this.title = this.translate.instant('intercom.batches.page.add.batch.form.list.title.shield');
      this.store.dispatch(new GetShieldModels());
    }
  }

  private initFilter() {
    this.filteredModels = this.form.get('filter').valueChanges
      .pipe(
        startWith<string>(''),
        map(name => name ? this._filter(name) : this.models.slice())
      );
  }

  private _filter(name: string): ShieldModel[] | RdaModel[] {
    const filterValue = name.toLowerCase();
    return this.models.filter(model => model.name.toLowerCase().indexOf(filterValue) === 0);
  }

  private initForm() {
    const formDefinition = modelFormGroup();
    this.form = formDefinition.form;
  }
}
