import {CommonModule, NgOptimizedImage} from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '@app/material.module';
import { SharedPipesModule } from '@app/shared/pipes';
import { ComponentLoaderModule, DialogWrapperModule } from '@app/shared/ui';
import { ReducerName } from '@app/store';
import { LoginContentComponent, LoginPageComponent } from '@app/views/auth/components';
import { AuthEffects, AuthFacade, authReducers } from '@app/views/auth/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ResetPasswordPopupComponent } from './components/reset-password-popup/reset-password-popup.component';
import { ResetPasswordContentComponent } from './components/reset-password-popup/reset-password/reset-password-content.component';
import { SharedModule } from '@app/shared/shared.module';
import {NgxTranslateModule} from '@app/translate/translate.module';

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    RouterModule,
    MaterialModule,
    SharedPipesModule,
    ReactiveFormsModule,
    ComponentLoaderModule,
    StoreModule.forFeature(ReducerName.AUTH, authReducers),
    EffectsModule.forFeature([
        AuthEffects
    ]),
    DialogWrapperModule,
    SharedModule,
    NgxTranslateModule,
    NgOptimizedImage,
  ],
  providers: [
      AuthFacade
  ],
  declarations: [
    LoginPageComponent,
    LoginContentComponent,
    ResetPasswordPopupComponent,
    ResetPasswordContentComponent
  ]
})
export class AuthModule {
    constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
        if (parentModule) {
            throw new Error(`${parentModule} has already been loaded. Import AuthModule module in the AppModule only.`);
        }
    }
}
