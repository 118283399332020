import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MaterialModule } from '@app/material.module';
import { ServiceWizardPopupContentComponent } from './service-wizard-popup-content.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule
    ],
    declarations: [
        ServiceWizardPopupContentComponent
    ],
    exports: [
        ServiceWizardPopupContentComponent
    ]
})
export class ServiceWizardPopupContentModule { }
