<div
  class="menu-nav"
  [class.menu-nav--mobile]="authenticated && (mdWDownBreakpoint$ | async)"
  [class.menu-nav--language]="isShowLanguageSwitch"
>
  <ul class="menu-nav-block">
    <li
      class="menu-nav-block-item"
      [style.display]="(mdWDownBreakpoint$ | async) ? 'block' : 'none'"
      *appRdeaCanAccess="permissionsTreeKey.DASHBOARD">
      <a
        id="dashboard"
        color="primary"
        mat-stroked-button
        (click)="onMenuClick()"
        [routerLink]="['dashboard']"
        class="menu-nav-block-item-link js-menu-nav-block-item-link">
        <mat-icon
          svgIcon="home"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.main' | translate }}</span>
      </a>
    </li>

    <ng-container
      *appSomeRoleContains="[
        userRoleType.ROLE_ENGINEER,
        userRoleType.ROLE_ADMINISTRATOR,
        userRoleType.ROLE_OWNER,
        userRoleType.ROLE_ENGINEER_ENTRANCES,
        userRoleType.ROLE_ENGINEER_CAMERAS,
        userRoleType.ROLE_MANUFACTURER,
        userRoleType.ROLE_ENGINEER_GATES
      ]">
      <hr
        class="menu-nav__divider"
        *ngIf="mdWDownBreakpoint$ | async" />
    </ng-container>

    <li
      class="menu-nav-block-item"
      *appSomeRoleContains="[
        userRoleType.ROLE_ENGINEER,
        userRoleType.ROLE_ADMINISTRATOR,
        userRoleType.ROLE_OWNER,
        userRoleType.ROLE_ENGINEER_ENTRANCES,
        userRoleType.ROLE_MONITORING_ENTRANCES
      ]">
      <a
        mat-stroked-button
        color="primary"
        id="softwareIntercoms"
        (click)="onMenuClick()"
        [routerLink]="['services/software-intercoms']"
        class="menu-nav-block-item-link js-menu-nav-block-item-link">
        <mat-icon
          svgIcon="domofon"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.intercom' | translate }}</span>
      </a>
    </li>

    <li
      class="menu-nav-block-item"
      *appSomeRoleContains="[
        userRoleType.ROLE_ENGINEER,
        userRoleType.ROLE_ADMINISTRATOR,
        userRoleType.ROLE_OWNER,
        userRoleType.ROLE_ENGINEER_VIDEO_SURVEILLANCE,
        userRoleType.ROLE_MONITORING_ENTRANCES
      ]">
      <a
        mat-stroked-button
        id="video"
        color="primary"
        (click)="onMenuClick()"
        [routerLink]="['services/video-surveillance']"
        class="menu-nav-block-item-link js-menu-nav-block-item-link">
        <mat-icon
          svgIcon="video"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.video' | translate }}</span>
      </a>
    </li>

    <li
      class="menu-nav-block-item"
      *appSomeRoleContains="[userRoleType.ROLE_VIDEODISPATCHER, userRoleType.ROLE_DEMO]">
      <a
        mat-stroked-button
        id="video-manager"
        color="primary"
        (click)="onMenuClick()"
        [routerLink]="['services/video-manager']"
        class="menu-nav-block-item-link js-menu-nav-block-item-link">
        <mat-icon
          svgIcon="video2"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.video_manager' | translate }}</span>
      </a>
    </li>

    <li
      class="menu-nav-block-item"
      *appSomeRoleContains="[userRoleType.ROLE_ENGENEER_PERSONAL_SURVEILLANCE]">
      <a mat-stroked-button [routerLink]="['apartment-video-surveillance']" color="primary"
         id="apartment_video_surveillance" class="menu-nav-block-item-link js-menu-nav-block-item-link"
         (click)="onMenuClick()">
        <mat-icon svgIcon="camera" class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.apartment_video_surveillance' | translate }}</span>
      </a>
    </li>

    <li
      class="menu-nav-block-item"
      *appSomeRoleContains="[
        userRoleType.ROLE_ENGINEER,
        userRoleType.ROLE_ADMINISTRATOR,
        userRoleType.ROLE_OWNER,
        userRoleType.ROLE_ENGINEER_GATES,
        userRoleType.ROLE_MONITORING_ENTRANCES,
        userRoleType.ROLE_ENGINEER_ENTRANCES
      ]">
      <a
        mat-stroked-button
        id="gates"
        color="primary"
        (click)="onMenuClick()"
        [routerLink]="['services/gates']"
        class="menu-nav-block-item-link js-menu-nav-block-item-link">
        <mat-icon
          svgIcon="gates"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.gates' | translate }}</span>
      </a>
    </li>

    <hr
      class="menu-nav__divider"
      *appSomeRoleContains="[userRoleType.ROLE_ENGINEER]" />

    <li
      class="menu-nav-block-item"
      *appSomeRoleContains="[userRoleType.ROLE_ENGINEER]">
      <a
        mat-stroked-button
        id="adapters"
        color="primary"
        (click)="onMenuClick()"
        [routerLink]="['intercom/adapters']"
        class="menu-nav-block-item-link js-menu-nav-block-item-link">
        <mat-icon
          svgIcon="adapter"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.adapters' | translate }}</span>
      </a>
    </li>

    <li
      class="menu-nav-block-item"
      *appSomeRoleContains="[
        userRoleType.ROLE_ENGINEER,
        userRoleType.ROLE_ADMINISTRATOR,
        userRoleType.ROLE_ENGINEER_CAMERAS
      ]">
      <a
        mat-stroked-button
        id="cameras"
        color="primary"
        (click)="onMenuClick()"
        [routerLink]="['intercom/cameras']"
        class="menu-nav-block-item-link js-menu-nav-block-item-link">
        <mat-icon
          svgIcon="camera"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.cameras' | translate }}</span>
      </a>
    </li>

    <li
      class="menu-nav-block-item"
      *appSomeRoleContains="[userRoleType.ROLE_DEMO]">
      <a
        id="pbx"
        color="primary"
        mat-stroked-button
        (click)="onMenuClick()"
        [routerLink]="['pbx-on-rda']"
        class="menu-nav-block-item-link js-menu-nav-block-item-link">
        <mat-icon
          svgIcon="pbx"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.pbx_on_rda' | translate }}</span>
      </a>
    </li>

    <li
      class="menu-nav-block-item"
      *appSomeRoleContains="[
        userRoleType.ROLE_ENGINEER,
        userRoleType.ROLE_MONITORING_ENTRANCES,
        userRoleType.ROLE_ADMINISTRATOR
      ]">
      <a
        mat-stroked-button
        color="primary"
        id="entrances"
        (click)="onMenuClick()"
        [routerLink]="['entrances']"
        class="menu-nav-block-item-link js-menu-nav-block-item-link">
        <mat-icon
          svgIcon="entrance"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.entrances' | translate }}</span>
      </a>
    </li>

    <ng-container *appSomeRoleContains="[userRoleType.ROLE_MANUFACTURER]">
      <li class="menu-nav-block-item">
        <a
          mat-stroked-button
          color="primary"
          id="newAdapters"
          class="menu-nav-block-item-link js-menu-nav-block-item-link"
          [routerLink]="['intercom/new-adapters']"
          (click)="onMenuClick()">
          <mat-icon
            svgIcon="adapter"
            class="menu-nav-block-item-link__icon"></mat-icon>
          <span>{{ 'core.menu.button.new_adapters' | translate }}</span>
        </a>
      </li>

      <li class="menu-nav-block-item">
        <a
          mat-stroked-button
          id="shields"
          color="primary"
          class="menu-nav-block-item-link js-menu-nav-block-item-link"
          [routerLink]="['intercom/shields']"
          (click)="onMenuClick()">
          <mat-icon
            svgIcon="shield"
            class="menu-nav-block-item-link__icon"></mat-icon>
          <span>{{ 'core.menu.button.shields' | translate }}</span>
        </a>
      </li>

      <li class="menu-nav-block-item">
        <a
          mat-stroked-button
          id="batches"
          color="primary"
          class="menu-nav-block-item-link js-menu-nav-block-item-link"
          [routerLink]="['intercom/batches']"
          (click)="onMenuClick()">
          <mat-icon
            svgIcon="batch"
            class="menu-nav-block-item-link__icon"></mat-icon>
          <span>{{ 'core.menu.button.parties' | translate }}</span>
        </a>
      </li>

      <li class="menu-nav-block-item">
        <a
          mat-stroked-button
          id="models"
          color="primary"
          class="menu-nav-block-item-link js-menu-nav-block-item-link"
          [routerLink]="['intercom/models']"
          (click)="onMenuClick()">
          <mat-icon
            svgIcon="model"
            class="menu-nav-block-item-link__icon"></mat-icon>
          <span>{{ 'core.menu.button.models' | translate }}</span>
        </a>
      </li>
    </ng-container>

    <hr class="menu-nav__divider" *appSomeRoleContains="[userRoleType.ROLE_ENGINEER]"/>

    <ng-container *ngIf="!environment.vivotec">
      <li *appSomeRoleContains="[userRoleType.ROLE_MANAGER_BILLS, userRoleType.ROLE_ADMINISTRATOR]">
        <a
                mat-stroked-button
                id="payments"
                color="primary"
                class="menu-nav-block-item-link js-menu-nav-block-item-link"
                [routerLink]="['payments']"
                (click)="onMenuClick()">
          <mat-icon
                  svgIcon="wallet"
                  class="menu-nav-block-item-link__icon"></mat-icon>
          <span>{{ 'core.menu.button.invoices' | translate }}</span>
        </a>
      </li>
    </ng-container>

    <li
      class="menu-nav-block-item"
      *appSomeRoleContains="[
        userRoleType.ROLE_ENGINEER,
        userRoleType.ROLE_ADMINISTRATOR,
        userRoleType.ROLE_MANAGER_MAILINGS
      ]">
      <a
        mat-stroked-button
        id="mailing"
        color="primary"
        class="menu-nav-block-item-link js-menu-nav-block-item-link"
        [routerLink]="['mailing/all']"
        (click)="onMenuClick()">
        <mat-icon
          svgIcon="notif"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.mailing_lists' | translate }}</span>
      </a>
    </li>

    <li
      class="menu-nav-block-item"
      *appSomeRoleContains="[
        userRoleType.ROLE_ENGINEER,
        userRoleType.ROLE_ENGINEER_ABONENTS
      ]">
      <a
        mat-stroked-button
        id="abonents"
        color="primary"
        class="menu-nav-block-item-link js-menu-nav-block-item-link"
        [routerLink]="['abonents/subscribers']"
        (click)="onMenuClick()">
        <mat-icon
          svgIcon="ticket"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.subscribers' | translate }}</span>
        <div
          class="menu-nav-block-item-link-badge"
          *ngIf="unresolvedIssues">
          <span class="menu-nav-block-item-link-badge__text">
            {{ unresolvedIssues > 99 ? "!" : unresolvedIssues }}
          </span>
        </div>
      </a>
    </li>

    <ng-container *ngIf="!environment.vivotec">
      <ng-container *appRdeaCanAccess="'abonents_sign_ups'">
        <li
          class="menu-nav-block-item"
          *appSomeRoleContains="[
            userRoleType.ROLE_ENGINEER,
            userRoleType.ROLE_ADMINISTRATOR,
            userRoleType.ROLE_OWNER,
            userRoleType.ROLE_ENGINEER_SIGNUPS
          ]">
          <a
            mat-stroked-button
            id="signUps"
            color="primary"
            class="menu-nav-block-item-link js-menu-nav-block-item-link"
            [routerLink]="['abonents/sign_ups']"
            (click)="onMenuClick()">
            <mat-icon
              svgIcon="invite"
              class="menu-nav-block-item-link__icon"></mat-icon>
            <span>{{ 'core.menu.button.applications' | translate }}</span>
            <div
              class="menu-nav-block-item-link-badge"
              *ngIf="activeRequests">
              <span class="menu-nav-block-item-link-badge__text">!</span>
            </div>
          </a>
        </li>
      </ng-container>
    </ng-container>

    <li
      class="menu-nav-block-item"
      *appSomeRoleContains="[userRoleType.ROLE_MANUFACTURER]">
      <a
        mat-stroked-button
        id="orders"
        color="primary"
        class="menu-nav-block-item-link js-menu-nav-block-item-link"
        [routerLink]="['intercom/orders']"
        (click)="onMenuClick()">
        <mat-icon
          svgIcon="order"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.orders' | translate }}</span>
      </a>
    </li>
    <hr class="menu-nav__divider" />

    <ng-container *ngIf="!environment.vivotec">
      <li class="menu-nav-block-item"
          *appSomeRoleContains="[
          userRoleType.ROLE_SUPPORT,
          userRoleType.ROLE_ADMINISTRATOR,
          userRoleType.ROLE_OWNER,
          ]">
        <a
          mat-stroked-button
          id="support"
          color="primary"
          class="menu-nav-block-item-link js-menu-nav-block-item-link"
          [href]="newsLink"
          [routerLink]="['support']"
          (click)="onMenuClick()">
          <img src="assets/icons/ic-support-tab.svg" alt="error" class="menu-nav-block-item-support_img">
          <span>{{ 'core.menu.button.support' | translate }}</span>
        </a>
      </li>
    </ng-container>

    <li class="menu-nav-block-item" *ngIf="!environment.vivotec">
      <a
        mat-stroked-button
        id="hot_to_buy"
        color="primary"
        class="menu-nav-block-item-link js-menu-nav-block-item-link"
        [href]="howToBuy"
        target="_blank">
        <mat-icon
          svgIcon="coins"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.how_to_buy' | translate }}</span>
      </a>
    </li>
    <li class="menu-nav-block-item" *ngIf="!environment.vivotec">
      <a
        mat-stroked-button
        id="instructions"
        color="primary"
        class="menu-nav-block-item-link js-menu-nav-block-item-link"
        [href]="documentationLink"
        target="_blank">
        <mat-icon
          svgIcon="info"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.instructions' | translate }}</span>
      </a>
    </li>
    <li class="menu-nav-block-item" *ngIf="!environment.vivotec">
      <a
        mat-stroked-button
        id="whats_new"
        color="primary"
        class="menu-nav-news__link"
        [href]="newsLink"
        target="_blank">
        <mat-icon
          svgIcon="star"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.whats_new' | translate }}</span>
      </a>
    </li>
    <li class="menu-nav-block-item" *ngIf="!environment.vivotec">
      <a
        mat-stroked-button
        id="telegram"
        color="primary"
        class="menu-nav-news__link"
        [href]="telegramLink"
        target="_blank">
        <mat-icon
          svgIcon="telegram"
          class="menu-nav-block-item-link__icon"></mat-icon>
        <span>{{ 'core.menu.button.telegram' | translate }}</span>
      </a>
    </li>
    <hr class="menu-nav__divider" *ngIf="!environment.vivotec"/>
    <li class="menu-nav-block-item">
      <div class="menu-nav-block-item-version">
        {{ 'core.menu.version' | translate:{version: appVersion} }}
      </div>
    </li>
  </ul>

  <div class="menu-nav-about">
    <app-language-switch *ngIf="isShowLanguageSwitch" class="language-switch" />
    <a
      color="primary"
      id="about"
      mat-stroked-button
      class="menu-nav-block-item-link js-menu-nav-block-item-link"
      [routerLink]="['partners/about']"
      (click)="onMenuClick()">
      <mat-icon
        svgIcon="user"
        class="menu-nav-block-item-link__icon"></mat-icon>
      <span *ngIf="companyName.length < 12; else shortenName">
        {{ companyName }}
      </span>
    </a>
  </div>
</div>

<ng-template #shortenName>{{ companyName | slice : 0 : 12 }}...</ng-template>
