import { Abonent } from '@app/shared/models';
import { AbonentsDelegationPopupMode } from '../models';

export interface AbonentsDelegationPopupState {
    mode: AbonentsDelegationPopupMode;
    owner: Abonent;
    status: 'loading' | 'loaded' | 'close';
}

export const abonentsDelegationPopupInitialState = {
    mode: 'check',
    status: 'loaded',
    owner: null
};
